import {
  Box,
  Typography,
} from '@mui/material';
import MultiSelector from '../../../../../components/selector/MultiSelector';
import { getSubjectObjectBySubjectName, getSubjectsByUser } from '../../../../../services/SubjectService';
import { getGradesBySubjectId } from '../../../../../services/GradeService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getLessonStandardObject } from '../../actions/LessonStepperSaveHelper';
import { useGetLessonStandardsByUser } from '../../useInitilizeDataForAuthoring';

/**
 * Container for Grade selection in Lesson Save screen
 *
 * @export
 */
export default function GradeSelector() {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const { user } = userDomain.domainData;
  const lessonStandards = useGetLessonStandardsByUser();

  const handleGradesSelected = (event) => {
    const selectedStandards = getLessonStandardObject(lessonData, lessonStandards) || [];
    const gradeLevels = selectedStandards.map((s) => s.standard_grade);
    const found = gradeLevels.some((r) => event.target.value.includes(r));

    const standards = found ? lessonData.attributes.standards : [];
    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        grades: event.target.value,
        standards,
      },
    };
    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  const shouldGradeEnable = () => {
    const subjectsByUser = getSubjectsByUser(user);
    const result = subjectsByUser.find((s) => (
      s.name === lessonData.attributes.subjects[0]
    ));
    return result;
  };

  return (
    <Box width='100%' data-test='grade-selector'>
      <Typography variant='h6'>
        Grade
      </Typography>
      <MultiSelector
        limit={3}
        className='vizzle-grade-dropdown dropdown-small-padding'
        values={lessonData.attributes.grades || []}
        onChange={handleGradesSelected}
        disabled={
          !lessonData.attributes.subjects
          || lessonData.attributes.subjects.length === 0
          || !shouldGradeEnable()
        }
        selectorItems={getGradesBySubjectId(getSubjectObjectBySubjectName(lessonData.attributes.subjects[0] || '').subjectId, lessonStandards)}
      />
    </Box>
  );
}

import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import materialTheme from './materialUITheme';
// eslint-disable-next-line no-unused-vars
import App from './containers/app/App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={materialTheme}>
    <App />
  </ThemeProvider>,
);

// ** IMPORTANT **
// Keep this for a bit as a reference.
// Occasionally, see some stranger errors when using React 18 createRoot(container)
//
//
// import ReactDOM from 'react-dom';
// import { ThemeProvider } from '@mui/material';
// import materialTheme from './materialUITheme';
// // eslint-disable-next-line no-unused-vars
// import App from './containers/app/App';
// import './index.css';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <ThemeProvider theme={materialTheme}>
//     <App />
//   </ThemeProvider>,
//   document.getElementById('root'),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

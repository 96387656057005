import { useNavigate } from 'react-router-dom';
import { EXTENSION_ACTIVITIES_ROUTES } from '../../ExtensionActivitiesConstants';
import ExtensionActivitiesFileList from '../ExtensionActivitiesFileList';
import BackAction from './actions/BackAction';
import './ExtensionActiviteisViewFiles.scss';

export default function ExtensionActiviteisViewFiles() {
  const navigate = useNavigate();
  return (
    <div className='extension-activities-view-files'>
      <div className='toolbars'>
        <BackAction
          // className='extensition-activities-action'
          areaLabel='View files'
          onClick={() => {
            navigate(EXTENSION_ACTIVITIES_ROUTES.VIEW_FILES);
          }}
        />
      </div>
      <div className='file-list-container'>
        <ExtensionActivitiesFileList />
      </div>
    </div>
  );
}

import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import SortingCardThumbnail from '../../../../../components/vizzle/page/sorting/SortingCardThumbnail';
import { getInitialDataForSorting } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import { flatten } from '../../../../../utils/ArrayUtils';
import Logger from '../../../../../utils/Logger';

const initDataForSorting = getInitialDataForSorting();

/**
* Container for Sorting layout
*/
export default function SortingLayout() {
  const promptCardsConfig = flatten(initDataForSorting.categories.map((category) => (
    category.promptCards
  )));

  const responseCardsConfig = flatten(initDataForSorting.categories.map((category) => (
    category.responseCards
  )));

  const props = {
    config: [
      {
        component: (
          <SortingCardThumbnail promptCardsConfig={promptCardsConfig} responseCardsConfig={responseCardsConfig} />
        ),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '42vw' : '40vw',
    height: '53vh',
  };

  return (
    <Box>
      <Box display='flex' mt='20px'>
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-sorting-layout'
        />
      </Box>
    </Box>
  );
}

import { useDomain } from '../../../../../../../../states/AppDomainProvider';

const applyFontStyleByFieldName = ({ cards, fieldValueMappings }) => {
  if (cards && cards.length > 0) {
    cards.forEach((card) => {
      const { text } = card;
      if (text) {
        const { lines } = text;
        if (lines && lines.length > 0) {
          lines.forEach((line) => {
            const { elements } = line;
            if (elements) {
              fieldValueMappings.forEach((fieldValueMapping) => {
                if (fieldValueMapping.isLineLevel) {
                  // eslint-disable-next-line no-param-reassign
                  line[fieldValueMapping.fieldName] = fieldValueMapping.value;
                } else {
                  elements.forEach((element) => {
                    // eslint-disable-next-line no-param-reassign
                    element[fieldValueMapping.fieldName] = fieldValueMapping.value;
                  });
                }
              });
            }
          });
        }
        const updatedText = { ...text };
        // eslint-disable-next-line no-param-reassign
        card.text = updatedText;
      }
    });
  }
};

export const SELECT_ALL_ACTIVITIES_OPTIONS = '0';

export const useApplyFontStyle = ({
  applyToActivities,
}) => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData } = lessonDataMaintenanceDomain.domainData;

  const applyFontStyle = async (fieldValueMappings) => {
    const { pages } = pagesData;
    const updatedPages = pages.map((page) => {
      const {
        activityType,
        cards,
        promptCards,
        responseCards,
        instructions,
        categories,
        gameCards,
        sequenceCards,
        promptText,
        subText,
        submitAnswerText,
      } = page;

      if (applyToActivities !== SELECT_ALL_ACTIVITIES_OPTIONS && activityType !== applyToActivities) {
        return page;
      }

      applyFontStyleByFieldName({ cards, fieldValueMappings });
      applyFontStyleByFieldName({ cards: promptCards, fieldValueMappings });
      applyFontStyleByFieldName({ cards: responseCards, fieldValueMappings });
      if (instructions) {
        if (instructions.text.defaultText) {
          instructions.text.defaultText = false;
        }
        applyFontStyleByFieldName({ cards: [instructions], fieldValueMappings });
      }
      if (categories) {
        categories.forEach((c) => {
          applyFontStyleByFieldName({ cards: c.promptCards, fieldValueMappings });
          applyFontStyleByFieldName({ cards: c.responseCards, fieldValueMappings });
        });
      }
      applyFontStyleByFieldName({ cards: gameCards, fieldValueMappings });
      if (gameCards) {
        gameCards.forEach((gameCard) => {
          const { landingInteraction } = gameCard;
          if (landingInteraction) {
            const { questionCard, answerCards } = landingInteraction;
            if (questionCard) {
              applyFontStyleByFieldName({ cards: [questionCard], fieldValueMappings });
              applyFontStyleByFieldName({ cards: answerCards, fieldValueMappings });
            }
          }
        });
      }
      applyFontStyleByFieldName({ cards: sequenceCards, fieldValueMappings });

      if (promptText) {
        applyFontStyleByFieldName({
          cards: [{
            text: promptText,
          }],
          fieldValueMappings,
        });
        // eslint-disable-next-line no-param-reassign
        page.promptText = { ...page.promptText };
      }
      if (subText) {
        applyFontStyleByFieldName({
          cards: [{
            text: subText,
          }],
          fieldValueMappings,
        });
        // eslint-disable-next-line no-param-reassign
        page.subText = { ...page.subText };
      }
      if (submitAnswerText) {
        applyFontStyleByFieldName({
          cards: [{
            text: submitAnswerText,
          }],
          fieldValueMappings,
        });
        // eslint-disable-next-line no-param-reassign
        page.submitAnswerText = { ...page.submitAnswerText };
      }
      return {
        ...page,
      };
    });

    await lessonDataMaintenanceDomain.updatePagesData({ pages: updatedPages });
  };

  return {
    applyFontStyle,
  };
};

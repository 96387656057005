import PropTypes from 'prop-types';
import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import { useGetSchoolSelectorTable, useHandleSelectedDistrict } from './TeacherSchoolRosterSchoolSelectorHooks';
import './TeacherSchoolRosterSchoolSelector.scss';
import SearchDistrictAction from '../../../../components/actions/search-district/SearchDistrictAction';

const TeacherSchoolRosterSchoolSelector = ({
  initSelectedSchoolIds,
  onDataChange,
  disabledSchoolIds,
  sortBySelectedIds,
  loading,
}) => {
  const { selectedDistrictIds, onFilterDistrictChange } = useHandleSelectedDistrict();

  const {
    tableConfig,
    schoolTableData,
  } = useGetSchoolSelectorTable({
    initSelectedSchoolIds,
    disabledSchoolIds,
    onDataChange,
    sortBySelectedIds,
    selectedDistrictIds,
  });

  return (
    <div className='teacher-school-roster-school-selector'>
      <SearchAndSortableTable
        containerClassName='student-selector-table'
        config={tableConfig}
        tableData={schoolTableData}
        searchPlaceholder='SchoolName'
        preToolbarAction={[
          <SearchDistrictAction
            key='admin-school-roster-search-district'
            filterValue={selectedDistrictIds}
            onFilterChange={onFilterDistrictChange}
            isMultiple
          />,
        ]}
        initTableState={sortBySelectedIds ? {
          order: 'desc',
          orderBy: 'isSelected',
        } : {
          order: 'desc',
          orderBy: 'schoolName',
        }}
        searchField='schoolName'
        autoResetTableState={false}
        loading={loading}
      />
    </div>
  );
};

TeacherSchoolRosterSchoolSelector.defaultProps = {
  initSelectedSchoolIds: [],
  onDataChange: () => null,
  sortBySelectedIds: false,
  loading: false,
  disabledSchoolIds: [],
};

TeacherSchoolRosterSchoolSelector.propTypes = {
  initSelectedSchoolIds: PropTypes.array,
  onDataChange: PropTypes.func,
  sortBySelectedIds: PropTypes.bool,
  loading: PropTypes.bool,
  disabledSchoolIds: PropTypes.array,
};

export default TeacherSchoolRosterSchoolSelector;

/* eslint-disable no-unused-vars */
import PlayLessonAction from '../../../../lessons/lessonactions/PlayLessonAction';
import ShareLinkLessonAction from '../../../../lessons/lessonactions/ShareLinkLessonAction';
import MyLessonCopyToEditAction from './MyLessonCopyToEditAction';
import DropdownAction from '../../../../lessons/lessonactions/dropdown/DropdownAction';
import ArchiveUnarchiveMenuItem from './dropdown/ArchiveUnarchiveMenuItem';
import PrintLessonMenuItem from '../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import MyLessonsAssignLessonsAction from './MyLessonsAssignLessonsAction';
import LessonPreviewMenuItem from '../../../../lessons/lessonactions/dropdown/LessonPreviewMenuItem';
import { isMobile } from '../../../../../utils/DeviceUtils';
import PlayLessonDetailAction from '../../../../lessons/lessonactions/PlayLessonDetailAction';
import MyLessonAddRemoveFromGroupPlay from './MyLessonAddRemoveFromGroupPlay';

/**
 * Lesson card detail action
 */
export const createLessonCardDetailAction = (lesson, currentUser, rowIndex, closeDetailPanelFunc, moveCardIndexFunc, cardIndex, userProfile, terminologies, selectedDates) => {
  const actions = [];

  actions.push(
    (<PlayLessonAction
      label='PLAY'
      lesson={lesson}
      key={`my-lessons-lesson-play-${lesson.id}`}
      user={currentUser}
      userProfile={userProfile}
      terminologies={terminologies}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`my-lessons-lesson-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<MyLessonAddRemoveFromGroupPlay
      lesson={lesson}
      key={`my-lessons-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    (<LessonPreviewMenuItem
      key={`my-lessons-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      userProfile={userProfile}
      terminologies={terminologies}
    />),
  ];

  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`my-lessons-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={userProfile}
      />
    ));
  }

  dropdownActions.push((
    <ArchiveUnarchiveMenuItem
      key={`my-lessons-dropdown-archive-unarchive-${lesson.id}`}
      lesson={lesson}
      onActionComplete={closeDetailPanelFunc}
    />
  ));

  actions.push(
    (
      <DropdownAction
        key={`my-lessons-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  actions.push(
    (
      <MyLessonCopyToEditAction
        lesson={lesson}
        currentUser={currentUser}
        rowIndex={rowIndex}
        moveCardIndexFunc={moveCardIndexFunc}
        cardIndex={cardIndex}
        key={`my-lessons-lesson-copy-to-edit-${lesson.id}`}
      />
    ),
  );

  actions.push(
    (
      <MyLessonsAssignLessonsAction
        lessons={[lesson]}
        selectedLessonIds={[lesson.id]}
        key={`my-lesson-assign-to-others-${lesson.id}`}
        selectedDates={selectedDates}
      />
    ),
  );
  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, user, userProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`my-lesson-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

import { useEffect } from 'react';
import { initAccessibe, showAccessibeChatIcon } from '../../services/AccessibeService';
import LiveChatService from '../../services/LiveChatService';
import { useDomain } from '../../states/AppDomainProvider';
import ObjectUtils from '../../utils/ObjectUtils';
import { isTeacher } from '../../utils/User';

export const useInitializationWhenLogin = () => {
  const { userDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;
  useEffect(() => {
    if (!ObjectUtils.isEmpty(user)) {
      if (isTeacher(user)) {
        LiveChatService.initializeLiveChatService(user);
      }
      if (userProfile && userProfile.accessibeOn) {
        initAccessibe({ forTeacher: isTeacher(user) });
        showAccessibeChatIcon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userProfile]);
  return { user };
};

import PropTypes from 'prop-types';
import {
  TextField,
  Typography,
} from '@mui/material';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import useSettingForm from './useSettingForm';
import './SettingTextFieldForm.scss';

const SettingTextFieldForm = ({
  label,
  subLabel,
  attributeNameToSave,
  minLength,
}) => {
  const {
    loading,
    value,
    handleOnChange,
    handleOnClick,
  } = useSettingForm(label, attributeNameToSave);

  return (
    <div className='setting-text-field-form'>
      <Typography variant='subtitle1' className='label'>
        {label}
      </Typography>
      <div className='input-container'>
        <TextField
          value={value === null || value === undefined ? '' : value}
          variant='outlined'
          fullWidth
          onChange={handleOnChange}
        />
        <Typography variant='h6' className='sub-label'>
          {subLabel}
        </Typography>
      </div>
      <ButtonWithSpinner
        loading={loading}
        className='apply-button'
        label='Apply'
        disabled={value === null || value === undefined || value.length < minLength}
        onClick={() => handleOnClick({
          [attributeNameToSave]: value,
        })}
      />
    </div>
  );
};

SettingTextFieldForm.defaultProps = {
  minLength: 1,
  subLabel: '',
};

SettingTextFieldForm.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  attributeNameToSave: PropTypes.string.isRequired,
  minLength: PropTypes.number,
};

export default SettingTextFieldForm;

import { useRef, useState } from 'react';
import { Button, MenuItem, Menu } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import VizzleLogo from '../../../../static/images/Vizzle_logo.svg';
import './AppBarLogo.scss';
import EduMenuItem from './menu-item/EduMenuItem';
import { useDomain } from '../../../../states/AppDomainProvider';

function AppBarLogo() {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  if (!user.enableSso) {
    return <img src={VizzleLogo} alt='Vizzle logo' className='vizzle-logo' />;
  }

  const handleToggle = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    // <VizzleLogo className='app-bar-logo' />
    <div className='app-bar-logo'>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MenuItem>
          <img src={VizzleLogo} alt='Vizzle logo' className='vizzle-logo' />
          <ExpandMoreIcon className='expand-icon' />
        </MenuItem>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{
          paper: 'app-logo-menu-list-popover',
        }}
      >
        <EduMenuItem />
      </Menu>
    </div>
  );
}

export default AppBarLogo;

import { useEffect, useState, useRef } from 'react';
import { playAudio, stopAudio } from '../../../../../components/vizzle/media/AudioPlayer';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import Logger from '../../../../../utils/Logger';
import { useLessonPlay } from '../../../context/LessonPlayContextProvider';
import TextToSpeechService from '../../../../../services/tts/TextToSpeechService';

export const useOpenInstructionDialog = ({ instructions, isCurrentPage, textLineId, onInstructionFinished, closeDelay, alwaysShowInstruction, isFinished }) => {
  const [openInstructions, setOpenInstructions] = useState({
    open: false,
    hasOpen: false,
  });

  const [audioPlaying, setAudioPlaying] = useState(false);

  const hasOpen = useRef(false);

  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;

  const textToSpeechService = new TextToSpeechService(userProfile.tts.ttsMode);

  const { playTextToSpeech, cancelTts } = textToSpeechService.getTtsService();

  useEffect(() => {
    const timer = [];
    const showInstruction = async () => {
      if (isCurrentPage && (!openInstructions.hasOpen || (alwaysShowInstruction && !isFinished))) {
        try {
          setOpenInstructions({
            open: true,
            hasOpen: true,
          });

          await ObjectUtils.delay(500);

          const instructionLength = instructions.text.lines.length;
          const elementToPlayTts = [];
          for (let i = 0; i < instructionLength; i++) {
            const el = document.querySelector(`#${textLineId}-${i}`);
            elementToPlayTts.push(el);
          }
          let closeDialogDelay = closeDelay || 3000;

          const isRecordedAudio = (instructions.audio && (instructions.audio.indexOf('recorded-audio') >= 0 || instructions.audio.indexOf('recording') >= 0));
          if (!isRecordedAudio && userProfile.tts.enabled && elementToPlayTts && elementToPlayTts.length > 0) {
            await playTextToSpeech(elementToPlayTts, userProfile.tts);
            closeDialogDelay = closeDelay || 700;
          }

          const isTtsAudio = instructions.audio && (instructions.audio.indexOf('tts-audio') >= 0 || instructions.audio.indexOf('tts') >= 0);

          if (instructions.audio && !isTtsAudio) {
            await playAudio(instructions.audio, () => {
              setAudioPlaying(true);
            });
            setAudioPlaying(false);
            closeDialogDelay = 700;
          }
          const timer1 = ObjectUtils.setTimeout(() => {
            setOpenInstructions({
              open: false,
              hasOpen: true,
            });
            hasOpen.current = true;
          }, closeDialogDelay);

          ObjectUtils.setTimeout(() => {
            if (onInstructionFinished) {
              onInstructionFinished();
            }
          }, closeDialogDelay + 500);
          timer.push(timer1);
        } catch (e) {
          Logger.logError(e);
          setOpenInstructions({
            open: false,
            hasOpen: true,
          });
          hasOpen.current = true;
        }
      }
    };

    // Got an error on iOS when putting showInstuction method inside a delay method.
    if (instructions && (instructions.text || instructions.audio)) {
      showInstruction();
    }

    return (() => {
      timer.forEach((t) => {
        clearTimeout(t);
      });
      setOpenInstructions(() => ({
        open: false,
        hasOpen: true,
      }));
    });
    // eslint-disable-next-line
  }, [isCurrentPage]);

  const handleOnInstructionClosed = () => {
    cancelTts();
    stopAudio();
    hasOpen.current = true;
    setOpenInstructions({
      ...openInstructions,
      open: false,
    });
    ObjectUtils.setTimeout(() => {
      if (onInstructionFinished) {
        onInstructionFinished();
      }
    }, 200);
  };

  return {
    openInstructions,
    handleOnInstructionClosed,
    audioPlaying,
    hasOpen,
  };
};

import axios from 'axios';
import vizzleAxios from './service';

/**
 * Update user assets
 */
export const updateUserAssets = async ({
  file,
  fileName,
  description,
  thumbnailUrl,
  source,
  keywords,
  makePublic,
}) => {
  if (!file) {
    Promise.resolve();
  }
  const url = '/media';
  /**
   * description is the file name that is generated when it is uploaded
   * fileName is the path where the file is uploaded
   * name is the original name of the file
   */
  const data = {
    name: file.name,
    fileName: fileName || file.name,
    description: description || file.name,
    assetType: file.type.split('/').shift().toUpperCase(),
    fileSize: file.size,
    mimeType: file.type,
    thumbnailUrlForVideo: thumbnailUrl,
    source,
    keywords,
    makePublic,
  };

  return vizzleAxios.post(url, data);
};

export const getUserAssets = async (search, limit = 20, offset = 0, type) => {
  const url = `/media/teacher?search=${search}&limit=${limit}&offset=${offset}&type=${type}`;
  return vizzleAxios.get(url);
};

export const getVizzleLibrary = async (search, limit = 20, offset = 0) => {
  const url = `/media/vizzle/library?search=${search}&limit=${limit}&offset=${offset}`;
  return vizzleAxios.get(url);
};

export const updateUsedMedia = async (mediaId) => {
  const url = `/media/${mediaId}/used`;
  await vizzleAxios.put(url);
};

export const uploadMedia = async (file, recorded) => {
  if (!file) {
    return Promise.resolve();
  }

  const data = new FormData();
  data.append('file', file);

  let url = `${process.env.REACT_APP_MEDIA_API_URL}/media/upload`;
  if (recorded) {
    url = `${url}?recorded=true`;
  }

  const result = await vizzleAxios({
    method: 'PUT',
    url,
    data,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return result.data.data;
};

export const uploadMediaFiles = async (files, recorded) => {
  if (!files || files.length === 0) {
    return Promise.resolve();
  }

  const data = new FormData();

  files.forEach((file) => {
    data.append('files', file);
  });

  let url = `${process.env.REACT_APP_MEDIA_API_URL}/media/upload/multiple`;
  if (recorded) {
    url = `${url}?recorded=true`;
  }

  const result = await vizzleAxios({
    method: 'PUT',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return result.data.data;
};

export const getFileAsBlob = async (fileKey) => {
  const result = await vizzleAxios({
    method: 'get',
    responseType: 'blob',
    url: `${process.env.REACT_APP_MEDIA_API_URL}/media/file/download?prefix=${fileKey}`,
  });
  const { data } = result;
  return data;
};

export const getJsonData = async (url) => {
  const result = await axios.get(url);
  return result.data;
};

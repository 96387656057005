import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import StudentSelector from '../../../../lessons/student-selector/StudentSelector';
import './StudentGroupForm.scss';

export const studentGroupFormValidator = (studentGroupData) => (
  studentGroupData && Boolean(studentGroupData.name) && studentGroupData.students && studentGroupData.students.length > 0
);

const StudentGroupForm = ({
  studentGroupName,
  selectedStudentIds,
  onDataChange,
}) => {
  const handleOnGroupNameChange = (e) => {
    const updatedData = {
      name: e.target.name,
      value: e.target.value,
    };
    onDataChange(updatedData);
  };

  const handleOnStudentSelectionChange = (updatedSelectedStudentIds) => {
    const updatedData = {
      name: 'students',
      value: [...updatedSelectedStudentIds].sort(),
    };
    onDataChange(updatedData);
  };

  const groupNameInput = useMemo(() => (
    <TextField
      label='Group Name'
      value={studentGroupName || ''}
      name='name'
      onChange={handleOnGroupNameChange}
      inputProps={{
        maxLength: 255,
      }}
      required
      variant='standard'
      autoFocus
      data-test='group-name'
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [studentGroupName]);

  const studentSelectInput = useMemo(() => (
    <StudentSelector
      className='student-selector-container'
      selectedStudentIds={selectedStudentIds}
      onStudentSelectionChange={handleOnStudentSelectionChange}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [selectedStudentIds]);
  return (
    <div className='student-group-form'>
      {groupNameInput}
      <div className='student-selector-table-container'>
        {studentSelectInput}
      </div>
    </div>
  );
};

StudentGroupForm.defaultProps = {
  studentGroupName: '',
  selectedStudentIds: [],
  onDataChange: () => null,
};

StudentGroupForm.propTypes = {
  studentGroupName: PropTypes.string,
  selectedStudentIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onDataChange: PropTypes.func,
};

export default StudentGroupForm;

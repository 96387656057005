import { useEffect, useMemo, useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import GameActivityUtils from '../../../../components/vizzle/page/game/GameActivityUtils';
import { playerPieceItems } from '../../../../components/vizzle/page/game/setup/PlayerPieceData';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import { playAudio } from '../../../../components/vizzle/media/AudioPlayer';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
  TILE_TYPE_FORWARD,
  TILE_TYPE_BACKWARD_2_SPACES,
} from '../../../../components/vizzle/page/game/GameConstants';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { isTtsPlaying } from '../../../../services/tts/TextToSpeechServiceV2';

export const useInitializeData = () => ({
  gamePlayActivityRef: useRef(null),
  moveToCardIndexRef: useRef({}),
  playerSetupRef: useRef(null),
  isSpinningRef: useRef(false),
  wheelSpinnerRef: useRef(null),
  playActivityInteractionPopupRef: useRef(null),
  specialtyCardPopupRef: useRef(null),
});

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
    };
  }, []);

  return componentIds;
};

export const useGenerateCellCardRefs = () => {
  const gameCellCardRefs = [];
  const setGameCellCardRefs = (ref, index) => {
    if (ref) {
      gameCellCardRefs[index] = ref.getElement();
    }
  };

  const mediaRefs = [];
  const setMediaRefs = (ref, index) => {
    if (ref) {
      mediaRefs[index] = ref;
    }
  };

  return {
    gameCellCardRefs,
    setGameCellCardRefs,
    mediaRefs,
    setMediaRefs,
  };
};

export const usePlayerSetup = ({
  id,
  isCurrentPage,
  mediaRefs,
  tts,
  onTriggerSwitchScan,
}) => {
  const hasSetupDialogShown = useRef(false);

  const [playerSetup, setPlayerSetup] = useState({
    open: false,
    players: [],
    showSpinner: false,
  });

  const handleOnPlayClick = async (e, players) => {
    e.stopPropagation();
    LessonPlayActivityUtils.stopMedias();
    const updatedPlayers = players.map((p, index) => ({
      ...p,
      currentCardIndex: 0,
      playerPieceImage: playerPieceItems.find((i) => i.value === p.selectedPiece).image,
      elementId: `player-${id}-${index}`,
    }));

    // initialization of game
    setPlayerSetup({
      ...playerSetup,
      open: false,
      players: updatedPlayers,
      currentPlayer: 0,
    });

    hasSetupDialogShown.current = true;

    ObjectUtils.setTimeout(() => {
      if (tts.enabled && mediaRefs[0] && !isTtsPlaying()) {
        mediaRefs[0].playMedia();
      }
    }, 10);

    ObjectUtils.setTimeout(() => {
      onTriggerSwitchScan();
    }, 50);
  };

  useEffect(() => {
    if (isCurrentPage && playerSetup.players.length === 0) {
      setPlayerSetup({
        ...playerSetup,
        open: true,
        showSpinner: false,
      });
    }
    // eslint-disable-next-line
  }, [isCurrentPage]);

  return {
    playerSetup,
    setPlayerSetup,
    handleOnPlayClick,
    hasSetupDialogShown,
  };
};

export const useWheelSpinner = ({
  gamePlayActivityRef,
  playerSetup,
  setPlayerSetup,
  data,
  moveToCardIndexRef,
  gameCellCardRefs,
  mediaRefs,
  onTriggerSwitchScan,
  isSpinningRef,
}) => {
  const handleOnSpinnerStart = () => {
    setPlayerSetup({
      ...playerSetup,
      showSpinner: true,
    });
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(gamePlayActivityRef, true);
    // eslint-disable-next-line no-param-reassign
    isSpinningRef.current = true;
    onTriggerSwitchScan();
  };

  const handleOnSpinnerEnd = (spinnerIndex, useIndex) => {
    const numberOfMoves = useIndex ? spinnerIndex : GameActivityUtils.getNumberOfMove(spinnerIndex, data, playerSetup);
    LessonPlayActivityUtils.disableComponent(gamePlayActivityRef, false);
    const newState = {
      ...playerSetup,
    };
    const newPlayersState = [...newState.players];
    const { currentCardIndex } = newPlayersState[playerSetup.currentPlayer];
    // eslint-disable-next-line no-param-reassign
    moveToCardIndexRef.current = Math.max(currentCardIndex + numberOfMoves, 0);
    if (data.moveToCardIndicator) {
      gameCellCardRefs[moveToCardIndexRef.current].classList.add('highlight--blink');
    }

    // eslint-disable-next-line no-param-reassign
    gameCellCardRefs[moveToCardIndexRef.current].dataset.cellToMoveTo = true;

    const mediaElement = mediaRefs[moveToCardIndexRef.current];
    if (mediaElement && mediaElement.elementRef) {
      mediaElement.elementRef.classList.add('disable-cell');
    }
    // eslint-disable-next-line no-param-reassign
    isSpinningRef.current = false;
    onTriggerSwitchScan();
  };
  return {
    handleOnSpinnerStart,
    handleOnSpinnerEnd,
  };
};

export const useGamePlay = ({
  data,
  playerSetup,
  setPlayerSetup,
  moveToCardIndexRef,
  gameCellCardRefs,
  mediaRefs,
  handleOnSpinnerEnd,
  onCorrectAnswerSelected,
  onActivityFinished,
  onInteractionOpen,
  onTriggerSwitchScan,
  isPreview,
}) => {
  const [landingInteractionPopup, setLandingInteractionPopup] = useState({
    open: false,
    landingInteraction: null,
    onClose: null,
  });

  const [specialtyCardPopup, setSpecialtyCardPopup] = useState({
    open: false,
    gameSpaceType: null,
  });

  const [isBlocking, setIsBlocking] = useState(false);
  const movePieceTimer = useRef(null);

  useEffect(() => {
    if (movePieceTimer.current) {
      movePieceTimer.current.stop();
    }
  }, []);

  const handleOnMediaPlayFinishForClick = async (e, index) => {
    e.stopPropagation();
    if (isPreview) {
      if (TILE_TYPE_STANDARD === data.gameCards[index].gameSpaceType) {
        const { landingInteraction } = data.gameCards[index];
        onInteractionOpen(landingInteraction);
        setLandingInteractionPopup({
          open: true,
          landingInteraction,
          onClose: () => null,
        });
      } else {
        setSpecialtyCardPopup({
          open: true,
          gameSpaceType: data.gameCards[index].gameSpaceType,
          onCloseCallback: () => null,
        });
      }
    }
  };

  const handleOnCardClick = async (e, index) => {
    e.stopPropagation();
    if (index !== moveToCardIndexRef.current) {
      handleOnMediaPlayFinishForClick(e, index);
      return;
    }

    if (!landingInteractionPopup.open && !specialtyCardPopup.open) {
      LessonPlayActivityUtils.stopMedias();
    }
    setIsBlocking(true);
    gameCellCardRefs[moveToCardIndexRef.current].classList.remove('highlight--blink');
    // eslint-disable-next-line no-param-reassign
    gameCellCardRefs[moveToCardIndexRef.current].dataset.cellToMoveTo = false;
    const mediaElement = mediaRefs[moveToCardIndexRef.current];
    if (mediaElement && mediaElement.elementRef) {
      mediaElement.elementRef.classList.remove('disable-cell');
    }

    const newState = {
      ...playerSetup,
    };

    const result = await GameActivityUtils.movePlayerPiece(
      playerSetup.players[playerSetup.currentPlayer],
      moveToCardIndexRef.current,
      gameCellCardRefs,
    );

    const { timer, promise } = result;
    movePieceTimer.current = timer;

    const success = await promise;
    if (success) {
      if (mediaElement && mediaElement.elementRef) {
        await mediaElement.playMedia({ onlyTts: true });
      }

      if (data.landingAudio) {
        try {
          await playAudio(data.landingAudio);
        } catch (ex) {
          // ignore
        }
      }

      const { landingInteraction, gameSpaceType } = data.gameCards[moveToCardIndexRef.current];
      const newPlayersState = [...newState.players];
      const newPlayerState = newPlayersState[playerSetup.currentPlayer];
      newPlayerState.currentCardIndex = moveToCardIndexRef.current;

      if (TILE_TYPE_LOSE_A_TURN === gameSpaceType) {
        newPlayerState.loseATurn = true;
      }
      const nextPlayerState = GameActivityUtils.getNextPlayerState(playerSetup, data.gameCards.length, gameSpaceType);

      if (TILE_TYPE_STANDARD === data.gameCards[moveToCardIndexRef.current].gameSpaceType) {
        const landingInteractionObj = data.gameCards[moveToCardIndexRef.current].landingInteraction;
        if (landingInteractionObj && (landingInteractionObj.questionCard || (landingInteractionObj.question && landingInteractionObj.question.question))) {
          onInteractionOpen(landingInteraction);
          setLandingInteractionPopup({
            open: true,
            landingInteraction,
            onClose: () => {
              setPlayerSetup({
                ...newState,
                ...nextPlayerState,
                showSpinner: false,
              });
              ObjectUtils.setTimeout(() => {
                onTriggerSwitchScan();
              }, 50);
            },
          });
          onTriggerSwitchScan();
        } else {
          setPlayerSetup({
            ...newState,
            ...nextPlayerState,
            showSpinner: false,
          });

          if (moveToCardIndexRef.current === gameCellCardRefs.length - 1) {
            await onCorrectAnswerSelected({ isFinished: false });
          }
          // Start and Finish cards are standard cards. No next player can play.
          if (nextPlayerState.currentPlayer < 0) {
            ObjectUtils.setTimeout(async () => {
              onActivityFinished({ toNextPage: true, alwaysGoToNext: true });
            }, 500);
          } else {
            ObjectUtils.setTimeout(() => {
              onTriggerSwitchScan();
            }, 50);
          }
        }
      } else {
        setSpecialtyCardPopup({
          open: true,
          gameSpaceType: data.gameCards[moveToCardIndexRef.current].gameSpaceType,
          onCloseCallback: async () => {
            if (TILE_TYPE_FORWARD === data.gameCards[moveToCardIndexRef.current].gameSpaceType) {
              // eslint-disable-next-line no-param-reassign
              moveToCardIndexRef.current++;
              handleOnSpinnerEnd(1, true);
            } else if (TILE_TYPE_BACKWARD_2_SPACES === data.gameCards[moveToCardIndexRef.current].gameSpaceType) {
              // eslint-disable-next-line no-param-reassign
              moveToCardIndexRef.current = Math.max(0, moveToCardIndexRef.current - 2);
              handleOnSpinnerEnd(-2, true);
            } else {
              setPlayerSetup({
                ...newState,
                ...nextPlayerState,
                showSpinner: false,
              });
            }
            ObjectUtils.setTimeout(() => {
              onTriggerSwitchScan();
            }, 50);
          },
        });
        ObjectUtils.setTimeout(() => {
          onTriggerSwitchScan();
        }, 50);
      }
    }
    setIsBlocking(false);
  };

  const handleOnSpecialtyCardClose = () => {
    LessonPlayActivityUtils.stopMedias();
    setSpecialtyCardPopup(false);
    if (specialtyCardPopup && specialtyCardPopup.onCloseCallback) {
      specialtyCardPopup.onCloseCallback();
    }
  };

  const handleOnLandingInteractionPopupClose = () => {
    LessonPlayActivityUtils.stopMedias();
    if (landingInteractionPopup.onClose) {
      landingInteractionPopup.onClose();
    }
    setLandingInteractionPopup({
      open: false,
      landingInteraction: null,
      onClose: null,
    });
  };

  return {
    handleOnCardClick,
    landingInteractionPopup,
    handleOnLandingInteractionPopupClose,
    specialtyCardPopup,
    handleOnSpecialtyCardClose,
    handleOnMediaPlayFinishForClick,
    isBlocking,
  };
};

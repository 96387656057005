import PropTypes from 'prop-types';
import './RejectedAnswer.scss';

/**
 * Sub-component for displaying Reject answer animation
 */
export default function RejectedAnswer({ noBorder }) {
  return (
    <div className={`rejected-answer ${noBorder ? '' : 'rejected-answer-border'}`}>
      <div className='rejected-answer-icon'>
        X
      </div>
    </div>
  );
}

RejectedAnswer.defaultProps = {
  noBorder: false,
};

RejectedAnswer.propTypes = {
  noBorder: PropTypes.bool,
};

import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartContainer from './ChartContainer';

const LineChart = ({
  title,
  data,
  categories,
  plotColor,
}) => (
  <ChartContainer title={title} isDataAvailable={data && data.length > 0} className='line-chart'>
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: '100%', height: '100%' } }}
      options={{
        title: {
          text: '',
        },
        legend: { enabled: false },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories,
        },
        yAxis: {
          title: '',
        },
        plotOptions: {
          series: {
            color: plotColor,
            tooltip: false,
          },
        },
        series: [{
          data,
        }],
        tooltip: {
          formatter: function fomatterFn() {
            if (!categories && categories.length === 0) {
              return '';
            }
            const firstCategiry = categories[0];
            // eslint-disable-next-line react/no-this-in-sfc
            return `<b>${this.y}</b> Activity Plays from <b>${firstCategiry}</b> to <b>${this.x}</b>`;
          },
        },
      }}
    />
  </ChartContainer>
);

LineChart.defaultProps = {
  title: '',
  data: [],
  categories: [],
  plotColor: '#66B767',
};

LineChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  categories: PropTypes.any,
  plotColor: PropTypes.string,
};

export default LineChart;

import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import FileUploadPlaceholder from './FileUploadPlaceholder';
import './ImageAndVideoUploader.scss';
import { showImageAt, preloadVideo } from './VideoToImage';
import { resizeImage } from './ResizeImage';

const getFileType = (file) => (
  file.type.split('/').shift()
);

const IMAGE_TYPE = 'image';
const VIDEO_TYPE = 'video';

const oneMB = 1048576;

const getInitPlaceholderMessage = (maxFileSize, isVideoIncluded) => ({
  title: `Drag and drop an image${isVideoIncluded ? '/video' : ''} or use the above buttons`,
  subTitle: isVideoIncluded ? `File size limit is ${maxFileSize} MB with mp4 extension.` : '',
  error: '',
});
/**
 * Component for uploading an image
 * props
 * onApply: function handler when the Apply button is clicked,
 * loading: whether or not the component is loading
 * fileAccept: The accept attribute specifies a filter for what file types the user can pick from the file input dialog box
 * maxFileSize: maximum file size in MB
 * @export
 */
export default function ImageAndVideoUploader({
  onSelect,
  onRemove,
  loading,
  fileAccept,
  maxFileSize,
  disabledRemove,
  className,
  setBrowseImageRef,
  isVideoIncluded,
  isInlineRemoveButton,
  imageUrl,
}) {
  const uploaderRef = useRef(null);

  const [placeholderMessages, setPlaceholderMessages] = useState(getInitPlaceholderMessage(maxFileSize, isVideoIncluded));

  const fileSize = maxFileSize * oneMB;

  const handleSelectedFiles = async (files) => {
    if (!files || files.length === 0) {
      setPlaceholderMessages({
        ...placeholderMessages,
        error: 'The selected file type is not supported.',
      });
      return;
    }
    const file = files[0];
    if (IMAGE_TYPE === getFileType(file)) {
      setPlaceholderMessages(getInitPlaceholderMessage(maxFileSize, isVideoIncluded));
      const resizeImageFile = await resizeImage(file);
      await onSelect({
        imageFile: resizeImageFile,
        imageUrl: URL.createObjectURL(file),
        videoFile: null,
      });
    } else if (VIDEO_TYPE === getFileType(file)) {
      if (file.size > fileSize) {
        /**
         * Fix for ios that the video will not be automatically starts
         * without a user gesture.
         */
        setPlaceholderMessages({
          ...placeholderMessages,
          error: `File size cannot exceed ${maxFileSize} MB`,
        });
      } else {
        const filePath = URL.createObjectURL(file);
        preloadVideo(filePath);
        // Convert to image
        const img = await showImageAt(0, filePath);
        setPlaceholderMessages(getInitPlaceholderMessage(maxFileSize, isVideoIncluded));
        await onSelect({
          imageFile: img.imgFile,
          imageUrl: img.imgUrl,
          videoFile: file,
        });
      }
    }
  };

  useEffect(() => {
    if (uploaderRef.current) {
      setBrowseImageRef(uploaderRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaderRef]);

  return (
    <div className={`image-video-uploader-component ${className}`}>
      <div className='image-video-uploader-preview'>
        <Dropzone
          disabled={loading}
          onDrop={handleSelectedFiles}
          ref={uploaderRef}
          multiple={false}
          accept={fileAccept}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <Card
              className='image-video-uploader-preview-card'
              {...getRootProps()}
              data-test='image-drop-zone'
            >
              <input {...getInputProps()} />
              <CardActionArea
                disabled={loading}
                className='image-video-uploader-preview-card-action'
              >
                <div className={isDragActive ? 'image-video-uploader-drag-active' : ''} />
                {imageUrl ? (
                  <CardMedia className='image-preview' image={imageUrl} component='img' />
                ) : (
                  <FileUploadPlaceholder {...placeholderMessages} />
                )}

              </CardActionArea>

              {isInlineRemoveButton && (
                <div className='image-video-uploader-actions-inline'>
                  <Button
                    variant='contained'
                    className='action-button btn-primary vizzle-browse-image-video-remove'
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(e);
                    }}
                    label='Remove'
                    disabled={disabledRemove}
                    data-test='remove-image-action'
                  >
                    Remove
                  </Button>
                </div>
              )}
            </Card>
          )}

        </Dropzone>
      </div>
      {!isInlineRemoveButton && (
        <div className='image-video-uploader-actions'>
          <Button
            variant='contained'
            className='action-button btn-primary vizzle-browse-image-video-remove'
            onClick={onRemove}
            label='Remove'
            disabled={disabledRemove}
            data-test='remove-image-action'
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
}

ImageAndVideoUploader.defaultProps = {
  onSelect: () => { },
  onRemove: () => { },
  loading: false,
  fileAccept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/jpg': ['.jpeg', '.jpg'],
    'image/gif': ['.gif'],
  },
  maxFileSize: 200,
  disabledRemove: false,
  className: '',
  setBrowseImageRef: () => { },
  isVideoIncluded: true,
  isInlineRemoveButton: false,
  imageUrl: null,
};

ImageAndVideoUploader.propTypes = {
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  loading: PropTypes.bool,
  fileAccept: PropTypes.object,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  disabledRemove: PropTypes.bool,
  setBrowseImageRef: PropTypes.func,
  isVideoIncluded: PropTypes.bool,
  isInlineRemoveButton: PropTypes.bool,
  imageUrl: PropTypes.string,
};

import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AddButton from '../../../components/buttons/AddButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import AddTeachersForm from './forms/AddTeachersForm';
import { useAddTeacherData } from './TeacherActionHooks';
import { useAdmin } from '../../../context/AdminContextProvider';

const AddTeacherAction = ({ onTeacherAddSuccess }) => {
  const [open, setOpen] = useState(false);

  const [isDataValid, setIsDataValid] = useState(false);

  const data = useRef(null);

  const { adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;

  const { loading, addTeacherData } = useAddTeacherData({
    setOpen,
    onTeacherAddSuccess,
  });

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleOnDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    data.current = updatedData;
  };

  const confirmActions = () => {
    const buttons = [
      {
        onClick: handleOnCancel,
        className: 'btn-danger margin-right',
        title: 'Cancel',
        loading,
      },
      {
        onClick: () => {
          addTeacherData(data.current);
        },
        className: 'btn-primary action-button',
        title: 'Save',
        disabled: !isDataValid,
        loading,
      },
    ];
    return buttons;
  };

  const teacherData = {
    firstName: '',
    lastName: '',
    userName: '',
    schoologyId: null,
  };

  return (
    <>
      <AddButton
        ariaLabel='Add Teacher'
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedSchoolId}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='add-teacher-dialog'
          onClose={handleOnCancel}
          title='Add Teacher'
          content={<AddTeachersForm teacherData={teacherData} onDataChange={handleOnDataChange} />}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

AddTeacherAction.defaultProps = {
  onTeacherAddSuccess: () => null,
};

AddTeacherAction.propTypes = {
  onTeacherAddSuccess: PropTypes.func,
};

export default AddTeacherAction;

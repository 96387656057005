import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import LessonStandardList from '../../../card/LessonStandardList';
import ObjectUtils from '../../../../utils/ObjectUtils';
import DefaultImage from '../../../../static/images/default_thumbnail.png';
import './LessonDetail.scss';
import CustomTooltip from '../../../card/CustomTooltip';

const LessonDetail = ({
  lesson,
  currentUser,
  currentUserProfle,
  included,
  actions,
  cardLabels,
  lessonTagsComponent,
  editSubjectAction,
  lessonDetailActions,
  addtionalDetailPanel,
}) => {
  const lessonPages = lesson.attributes.pages || {};
  const lessonPageKeys = Object.keys(lessonPages);

  return (
    <div
      className='lesson-detail-container'
      key={`lesson-detail-id-${lesson.id}`}
      data-test={`lesson-detail-container-${lesson.attributes.uuid}`}
    >
      <div className='label-area'>
        {cardLabels.map((label) => (
          (label)
        ))}
      </div>
      <div className='action-area'>
        {actions.map((action) => (
          (action)
        ))}
      </div>
      <div className='card-image' style={{ backgroundImage: `url(${lesson.attributes.thumbnailUrl || DefaultImage})` }}>
        {lessonDetailActions && (
          lessonDetailActions.map((l) => l)
        )}
        <div className='arrow-up-lesson-detail'>&nbsp;</div>
      </div>

      <div className='lesson-summary'>
        <div className='play-description' data-private>
          Playing will record data to {currentUser.userName}
        </div>
        <div className='lesson-id-container'>
          <div className='lesson-id' data-test='lesson-id'>
            # {lesson.id}
          </div>
          {
            !lesson.specialtyContent || ObjectUtils.isEmpty(lesson.specialtyContent)
              ? ''
              : <img className='lesson-card-specialty-icon' src={lesson.specialtyContent.cardImage} alt='Specialty Logo' />
          }
        </div>
      </div>

      <div className='lesson-name'>
        <Typography variant='h2' gutterBottom className='lesson-name-and-icon' data-test='lesson-detail-title'>
          {lesson.attributes.feedbackLoopValue && (
            <CustomTooltip className='action-button-tooltip' title='Feedback loop' aria-label='Feedback loop'>
              <LoopIcon fontSize='large' className='icon' />
            </CustomTooltip>

          )}
          {lesson.attributes.name}
        </Typography>
        <Typography variant='subtitle1' gutterBottom data-test='lesson-detail-description'>
          {lesson.attributes.description}
        </Typography>
      </div>

      {lessonTagsComponent ? (
        <div className='goals-objectives'>
          {lessonTagsComponent}
        </div>
      ) : null}

      <Divider className='divider' />

      {
        !lesson.specialtyContent || ObjectUtils.isEmpty(lesson.specialtyContent)
          ? null
          : (
            <>
              <div className='lesson-specialty-description'>
                <div className='description'>
                  {lesson.specialtyContent.description}
                </div>
                <a target='_blank' rel='noopener noreferrer' href={lesson.specialtyContent.url}>
                  {lesson.specialtyContent.url}
                </a>
              </div>
              <Divider className='divider' />
            </>
          )
      }

      <LessonStandardList lesson={lesson} included={included} currentUser={currentUser} currentUserProfle={currentUserProfle} />
      {addtionalDetailPanel}
      <div className='bottom'>
        {editSubjectAction || (
          lesson.attributes.subjects[0] && (<div className='subject-name' data-test={`subject-chip-${lesson.attributes.subjects[0]}`}>{lesson.attributes.subjects[0]}</div>)
        )}
        {lessonPageKeys.length > 0 ? (
          <>
            {lessonPageKeys.sort().map((key) => lessonPages[key] > 0 && (
              <div data-test={`page-type-chip-${key}`} className='page' key={`lesson-page-detail-${lesson.id}-${key}`}>
                {key}
                &nbsp;(
                <i>
                  {lessonPages[key]} slide{lessonPages[key] > 1 ? 's' : ''}
                </i>
                )
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

LessonDetail.defaultProps = {
  lesson: {},
  included: [],
  currentUser: {},
  currentUserProfle: {},
  actions: [],
  cardLabels: [],
  lessonTagsComponent: null,
  editSubjectAction: null,
  lessonDetailActions: null,
  addtionalDetailPanel: null,
};

LessonDetail.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    specialtyContent: PropTypes.object,
  }),
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  currentUser: PropTypes.shape({
    userName: PropTypes.string,
  }),
  currentUserProfle: PropTypes.shape({
    id: PropTypes.number,
  }),
  actions: PropTypes.arrayOf(PropTypes.node),
  cardLabels: PropTypes.arrayOf(PropTypes.node),
  lessonTagsComponent: PropTypes.node,
  editSubjectAction: PropTypes.node,
  lessonDetailActions: PropTypes.arrayOf(PropTypes.node),
  addtionalDetailPanel: PropTypes.node,
};
export default LessonDetail;

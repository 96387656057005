import { Howl } from 'howler';
import { useState, useEffect } from 'react';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import Logger from '../../../utils/Logger';
import { getJsonData } from '../../../services/MediaService';

export const reinforcerObject = {};

export const useInitReinforcer = () => {
  const [reinforceState, setReinforceState] = useState({
    open: false,
    reinforceId: '',
    promise: null,
  });

  reinforcerObject.reinforceState = reinforceState;
  reinforcerObject.setReinforceState = setReinforceState;

  return {
    reinforceState,
    setReinforceState,
  };
};

export const useGetLottieAnimationData = () => {
  const [animationData, setAnimationData] = useState(null);
  const { loading, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const getLottieAnimationData = async (url) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getJsonData(url);
        setAnimationData(result);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    animationData,
    setAnimationData,
    loading,
    getLottieAnimationData,
  };
};

export const usePreloadReinforcer = ({ reinforcerList, enableAudio }) => {
  useEffect(() => {
    reinforcerList.forEach((reinforcer) => {
      if (!reinforcer) {
        return;
      }
      const { manifest } = reinforcer;
      if (enableAudio) {
        const sounds = manifest.filter((m) => m.id === 'sound');
        sounds.forEach((s) => {
          if (s && s.src) {
            // eslint-disable-next-line no-unused-vars
            const sound = new Howl({
              src: s.src,
              preload: true,
            });
          }
        });
      }

      const spritesheetData = manifest.find((m) => m.id === 'spritesheetData');
      if (spritesheetData) {
        getJsonData(spritesheetData.src);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reinforcerList]);
};

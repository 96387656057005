import { useEffect } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useMyLessonsContext } from './context/MyLessonsContextProvider';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { getMyLessons } from '../../../../services/LessonService';
import { getAllLessonTagsForTeacher } from '../../../../services/LessonTagService';
import { mapLessonsIntoSubject, sortLessonAttributes } from '../../../../utils/LessonsMapping';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { GRADES_FOR_FILTER, ACTIVITIES_FILTER } from '../../../../constants/LessonStandardConstant';
import { FULL_SORTED_SUBJECT_CONSTANTS, SUBJECT_CONSTANTS } from '../../../../constants/LessonConstant';

export const SORT_LESSONS_BY = {
  name: 'name',
  recentlyAdded: 'recentlyAdded',
};

export const useFetchMyLessons = () => {
  const { uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { loading, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const { myLessonsDomain } = useMyLessonsContext();

  const fetchMyLessons = (showLoader, selectedLessonIds = []) => {
    if (showLoader) {
      uiDomain.showLoader('Fetching activities for your list', LOADER_TYPE.RISE_LOADER);
    }
    callRemoteServiceWrapper(async () => {
      const myLessonsPromise = getMyLessons();
      const lessonTagslessonTagsPromise = getAllLessonTagsForTeacher(user.id);
      const [myLessons, lessonTagslessonTags] = await Promise.all([myLessonsPromise, lessonTagslessonTagsPromise]);

      if (myLessons.data && myLessons.data.length > 0) {
        const subjectListWithLessons = mapLessonsIntoSubject(myLessons, user);
        myLessonsDomain.updateLessonAndTags(
          subjectListWithLessons,
          myLessons.included,
          lessonTagslessonTags.data,
          selectedLessonIds,
        );
      } else {
        myLessonsDomain.updateLessonAndTags([], myLessons.included, lessonTagslessonTags.data || [], []);
      }
      if (showLoader) {
        uiDomain.hideLoader();
      }
    });
  };

  return {
    loading,
    fetchMyLessons,
  };
};

export const usePrepareMyLessons = () => {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData, lessonSortOption } = myLessonsDomain.domainData;
  const {
    loading,
    fetchMyLessons,
  } = useFetchMyLessons();

  useEffect(() => {
    fetchMyLessons(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredLessonData = { ...lessonData };
  const { filter, lessons } = filteredLessonData;

  const result = lessons.find((l) => {
    const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((sc) => sc.name === l.subject) || SUBJECT_CONSTANTS.misc;
    return subject.subjectId === filter.selectedSubject;
  });

  if (result) {
    if (lessonSortOption === SORT_LESSONS_BY.recentlyAdded) {
      result.lessons = result.lessons.sort((a, b) => {
        const dateA = a.meta.assignedDate;
        const dateB = b.meta.assignedDate;
        return dateB - dateA;
      });
    } else {
      result.lessons = sortLessonAttributes(result.lessons, 'name');
    }
    filteredLessonData.lessons = [result];
  } else {
    filteredLessonData.lessons = [];
  }

  const { selectedSubject } = myLessonsDomain.domainData.lessonData.filter;
  const sn = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === selectedSubject);
  const currentSelectedSubjectName = sn ? sn.name : '';

  return {
    loading,
    lessonData,
    filteredLessonData,
    fetchMyLessons,
    currentSelectedSubjectName,
  };
};

export const useGetActiveGoals = () => {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonTags } = myLessonsDomain.domainData;
  const activeGoals = lessonTags.filter((tag) => (!tag.meta.archived));
  return activeGoals;
};

export const useGetFilterOptions = (activeGoals) => {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonTags, lessonData } = myLessonsDomain.domainData;

  const goalsOptions = lessonTags ? activeGoals.map((tag) => ({ key: tag.id, name: tag.attributes.name, value: tag.id })) : [];
  if (activeGoals && activeGoals.length > 0) {
    goalsOptions.unshift({
      key: 'no-tag',
      name: 'Activities without Tags',
      value: 'empty-tag',
    });
  }
  const multiSelectFilterConfig = [
    {
      label: 'My Tags',
      subTitle: 'Filter Tags',
      options: goalsOptions,
      className: 'lesson-tags-filter',
      keyName: 'lessonTags',
      getValue: (lesson) => (
        lesson
          && lesson.relationships
          && lesson.relationships.myListTags
          && lesson.relationships.myListTags.data.length > 0 ? lesson.relationships.myListTags.data.map((t) => t.id) : ['empty-tag']),
    },
    {
      label: 'Grade',
      options: GRADES_FOR_FILTER,
      className: 'grade-filter',
      keyName: 'gradeLevel',
      getValue: (lesson) => {
        if (!lesson.attributes.gradeLevel) {
          return 'undefined';
        }
        return lesson.attributes.gradeLevel.toLowerCase();
      },
      filterable: false,
    },
    {
      label: 'Activity',
      options: ACTIVITIES_FILTER,
      className: 'activity-filter',
      keyName: 'activities',
      getValue: (lesson) => lesson.attributes.activities,
      filterable: false,
    },
  ];

  const handleOnFilterChange = async (filterValue) => {
    await myLessonsDomain.updateLessonFilterValue(filterValue);
  };

  const { archived } = lessonData.lessonFilterValue;
  useEffect(() => {
    myLessonsDomain.updateSelectedLessonIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  return {
    handleOnFilterChange,
    multiSelectFilterConfig,
  };
};

export const myListTags = 'myListTags';
export const useHandleLessonTag = () => {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData, lessonTags } = myLessonsDomain.domainData;

  const getLessonTagIds = (lesson) => (lesson && lesson.relationships && lesson.relationships.myListTags ? lesson.relationships.myListTags.data.map((t) => t.id) : []);
  const handleOnTagDelete = (deletedTag, lesson) => {
    const updatedTags = lesson.relationships.myListTags.data.filter((t) => t.id !== deletedTag.id);
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.myListTags.data = updatedTags;
    myLessonsDomain.updateLessons([...lessonData.lessons], lessonData.included);
  };

  const fillInRelationObject = (lesson) => {
    if (!lesson.relationships) {
      // eslint-disable-next-line no-param-reassign
      lesson.relationships = {};
    }
    if (!lesson.relationships.myListTags) {
      // eslint-disable-next-line no-param-reassign
      lesson.relationships.myListTags = { data: [] };
    }
  };

  const handleOnTagSelect = (selectedTag, lesson) => {
    fillInRelationObject(lesson);
    const tags = lesson.relationships.myListTags.data;
    tags.push({ type: myListTags, id: selectedTag.id });
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.myListTags.data = tags;
    myLessonsDomain.updateLessons([...lessonData.lessons], lessonData.included);
  };

  const handleOnTagAdd = async (newTag, lesson) => {
    fillInRelationObject(lesson);
    const tags = lesson.relationships.myListTags.data;
    tags.push({ type: myListTags, id: newTag.id });
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.myListTags.data = tags;
    await myLessonsDomain.updateLessonTag([newTag, ...lessonTags]);
    await myLessonsDomain.updateLessons([...lessonData.lessons], [...lessonData.included, newTag]);
  };

  return {
    getLessonTagIds,
    handleOnTagDelete,
    handleOnTagSelect,
    handleOnTagAdd,
  };
};

export const useLessonSelect = () => {
  const { myLessonsDomain } = useMyLessonsContext();

  const handleOnLessonSelect = async (lesson) => {
    const { lessonData } = myLessonsDomain.domainData;
    const { selectedLessonIds } = lessonData;
    const matchedLessonId = selectedLessonIds.findIndex((selectedId) => selectedId === lesson.id);
    let updatedSelectedIds = [...selectedLessonIds];
    if (matchedLessonId >= 0) {
      updatedSelectedIds = selectedLessonIds.filter((selectedId) => selectedId !== lesson.id);
    } else {
      updatedSelectedIds.push(lesson.id);
    }

    await myLessonsDomain.updateSelectedLessonIds(updatedSelectedIds);
  };

  const handleOnLessonClear = async () => {
    await myLessonsDomain.updateSelectedLessonIds([]);
  };

  return {
    handleOnLessonSelect,
    handleOnLessonClear,
  };
};

export const useHandleTeacherCourseAssigment = () => {
  const { myLessonsDomain } = useMyLessonsContext();
  const {
    selectedDates,
    lessonData,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
  } = myLessonsDomain.domainData;

  const handleOnSelectedDatesApply = (internalSelectedDates) => myLessonsDomain.updateSelectedDates(internalSelectedDates);
  const handleOnStartLoading = () => myLessonsDomain.updateAssignedStudentsCoursesLoading(true);

  const subjectId = lessonData.filter.selectedSubject;
  const onDataLoad = (data) => myLessonsDomain.updateAssignedStudentsCourses(data);

  const handleOnFilterCriteriaAssignedStudentsCoursesUpdate = (updatedFilter) => myLessonsDomain.updateFilterCriteriaAssignedStudentsCourses(updatedFilter);

  return {
    selectedDates,
    handleOnSelectedDatesApply,
    handleOnStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
    handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  };
};

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import './SearchSwitch.scss';
import { URL_SEARCH_STUDENT, URL_SEARCH_TEACHER } from '../../AdminSearchConstants';

const SearchSwitch = () => {
  const location = useLocation();
  const path = location.pathname && location.pathname.split('/');
  const navigate = useNavigate();
  const lastPath = path[path.length - 1];

  return (
    <div className='search-switch'>
      <ToggleButtonGroup
        size='small'
        value={lastPath}
        exclusive
        className='switch-toggle'
        onChange={(_f, newValue) => {
          navigate(newValue);
        }}
      >
        <ToggleButton value={URL_SEARCH_TEACHER}>
          TEACHER
        </ToggleButton>
        <ToggleButton value={URL_SEARCH_STUDENT}>
          STUDENT
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default SearchSwitch;

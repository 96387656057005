import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './Buttons.scss';
import CustomTooltip from '../../../../components/card/CustomTooltip';

const AddButton = ({
  ariaLabel,
  onClick,
  disabled,
}) => (
  <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
    <span>
      <IconButton className='admin-action-icon-button' disabled={disabled} aria-label={ariaLabel} onClick={onClick}>
        <AddCircleIcon />
      </IconButton>
    </span>
  </CustomTooltip>
);

AddButton.defaultProps = {
  ariaLabel: '',
  onClick: () => null,
  disabled: false,
};

AddButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AddButton;

import Logger from '../utils/Logger';
// eslint-disable-next-line import/no-cycle
import { reigsterTextToSpeechAutoPlay } from './tts/TextToSpeechService';
/** Create a singleton for audio object */
// eslint-disable-next-line import/no-mutable-exports
export let audio;
if (!audio) {
  audio = new Audio();
}

/** Create a singleton for video object */
// eslint-disable-next-line import/no-mutable-exports
export let video;
if (!video) {
  video = document.createElement('video');
  video.playsInline = true;
  video.controls = true;
  video.autoPlay = true;
  video.muted = true;
  video.className = 'video-player';
}

const preloadVideo = () => {
  video.src = '/media/register.mp3';
  video.muted = true;
  video.play().catch((e) => {
    Logger.logError(e);
    video.src = null;
    // Do nothing
  });
  video.onended = () => {
    video.src = null;
  };
};

const preloadAudio = () => {
  audio.src = '/media/register.mp3';
  audio.muted = true;
  audio.play().catch((e) => {
    Logger.logError(e);
    audio.src = null;
  });
  audio.onended = () => {
    audio.src = null;
  };
};

// eslint-disable-next-line import/no-mutable-exports
export let hasRegistered = false;

export const registerMedia = async () => {
  if (!hasRegistered) {
    preloadVideo();
    preloadAudio();
    reigsterTextToSpeechAutoPlay();
    hasRegistered = true;
  }
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import './SmallAudioUploader.scss';

const oneMB = 1048576;
const maxFileSize = 50;
const fileSize = maxFileSize * oneMB;

const SmallAudioUploader = ({
  audioUrl,
  onSelect,
  title,
  placeHolder,
  onRemove,
  disabledRemove,
}) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  const handleSelectedFiles = async (files) => {
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    if (file.size > fileSize) {
      return;
    }
    await onSelect(file);
  };

  return (
    <div className='small-audio-selector-container'>
      {audioUrl ? (
        <div className='audio-player'>
          <audio controls src={audioUrl} ref={audioRef}>
            <track kind='captions' />
          </audio>
          <Button
            variant='contained'
            className='btn-primary vizzle-small-audio-uploader-remove remove-button'
            onClick={onRemove}
            label='Remove'
            disabled={disabledRemove}
          >
            Remove
          </Button>
        </div>
      ) : (
        <fieldset className='small-audio-selector'>
          <legend>{title}</legend>
          <Dropzone
            multiple={false}
            accept='.mp3'
            onDrop={handleSelectedFiles}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Card
                className={`audio-selector-drop-area-card ${isDragActive ? 'audio-uploader-drag-active' : ''}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <CardActionArea className='audio-selector-drop-area-card-action'>
                  <CardContent>
                    {placeHolder}
                  </CardContent>
                </CardActionArea>
              </Card>
            )}

          </Dropzone>
        </fieldset>
      )}
    </div>
  );
};

SmallAudioUploader.defaultProps = {
  audioUrl: null,
  onSelect: () => { },
  title: '',
  placeHolder: '',
  onRemove: () => { },
  disabledRemove: false,
};

SmallAudioUploader.propTypes = {
  audioUrl: PropTypes.string,
  onSelect: PropTypes.func,
  title: PropTypes.string,
  placeHolder: PropTypes.string,
  onRemove: PropTypes.func,
  disabledRemove: PropTypes.bool,
};

export default SmallAudioUploader;

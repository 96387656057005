import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextToSpeechSettings from './TextToSpeechSettings';
import ScreenReaderSettings from './ScreenReaderSettings';
import SwitchKeyboardSettings from './SwitchKeyboardSettings';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';
import { guideSwitchKeyboardAccessibility } from '../../../../texts/TooltipText';
import AccessibeSettings from './AccessibeSettings';

const AccessibilitySettings = () => {
  const components = [
    {
      title: 'Text to Speech',
      component: <TextToSpeechSettings />,
    },
    {
      title: 'Switch/Keyboard Accessibility',
      component: <SwitchKeyboardSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...guideSwitchKeyboardAccessibility} />,
    },

    {
      title: 'JAWS® or other Screen Reader',
      component: <ScreenReaderSettings />,
    },

    {
      title: 'Display Accessibility Widget',
      component: <AccessibeSettings />,
    },
  ];

  return (
    <Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='space-between'>
      {components.map((entry, index) => (
        <Box
          p={1}
          key={`student-setting-entry-${index}`}
          data-test={`accessibility-${entry.title}`}
        >
          <Box display='flex' mr='15px' alignItems='center'>
            <Typography variant='subtitle1' fontWeight={500} className='nested'>
              {entry.title}
            </Typography>
            {entry.tooltip}
          </Box>
          {entry.component}
        </Box>
      ))}
    </Box>
  );
};
export default AccessibilitySettings;

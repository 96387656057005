import { FormControl, MenuItem, Paper, Select } from '@mui/material';
import LoadingSpinner from '../../../../../components/loaders/LoadingSpinner';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { US_STATES } from '../../../../../constants/StateConstant';
import { uniqueByAttributes } from '../../../../../utils/ArrayUtils';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import './TopicStandards.scss';
import { defaultStateValue, useGetStandardsByState, useHandleStateChange } from './TopicStandardsHooks';

export default function TopicStandards() {
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;

  const {
    topicStandardState,
    handleStateChange,
  } = useHandleStateChange();

  const {
    loading,
    topicStandards,
  } = useGetStandardsByState({ state: topicStandardState });
  if (!hasPerformSearch) {
    return (<TeacherCourseInstruction />);
  }

  return (
    <div className='topic-standards' data-test='topic-standards'>
      <FormControl variant='outlined' className='state-selector-container'>
        <Select
          name='state'
          value={topicStandardState || defaultStateValue}
          onChange={handleStateChange}
        >
          <MenuItem key={defaultStateValue} value={defaultStateValue} disabled>
            State
          </MenuItem>
          {
            US_STATES.map((state) => (
              <MenuItem key={state.value} value={state.value}>[{state.value}] {state.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>

      <div className='standard-container'>
        {(loading) && (
          <div className='loading-container'>
            <LoadingSpinner message={`Loading standards for ${topicStandardState}`} />
          </div>
        )}
        {!loading && topicStandards && topicStandards.length === 0 && (
          <NoLessonPaper message='No Standards Available' />
        )}
        {!loading && topicStandards && topicStandards.length > 0 && (
          <Paper elevation={3} className='standard-list-paper'>
            <ul className='standard-list' data-test='standard-list'>
              {
                uniqueByAttributes(topicStandards, 'standardCode').map((standard) => (
                  <li key={`lesson-standard-${standard.id}`} data-test={`lesson-standard-${standard.id}`}>
                    <div className='standard-code' data-test='standard-code'>
                      {standard.attributes.standardCode}
                    </div>
                    <div className='standard-description' data-test='standard-description'>
                      {standard.attributes.standardDescription}
                    </div>
                  </li>

                ))
              }
            </ul>
          </Paper>
        )}
      </div>
    </div>
  );
}

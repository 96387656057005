import PropTypes from 'prop-types';
import './AnswerKeyMoveIndicator.scss';
import DropdownInput from '../../../selector/DropdownInput';

function AnswerKeyMoveIndicator({
  onChange,
  label,
  dropdownValues,
  value,
}) {
  return (
    <div className='answer-key-move-indicator' data-test='answer-key-move-indicator'>
      <DropdownInput
        className='answer-key-move-indicator-dropdown'
        dropdownValues={dropdownValues}
        label={label}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

AnswerKeyMoveIndicator.defaultProps = {
  dropdownValues: [],
  label: '',
  onChange: () => null,
  value: '',
};

AnswerKeyMoveIndicator.propTypes = {
  dropdownValues: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default AnswerKeyMoveIndicator;

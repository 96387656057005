import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import LessonCardListDetail from '../../../../../components/vizzle/cardlistdetail/lesson/LessonCardListDetail';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { calculateCardSize } from '../../../../../utils/CardSizeCalculator';
import { createLessonCardAction, createLessonCardLabel } from './actions/TeacherCourseLessonCardActionBuilder';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/TeacherCourseLessonCardDetailActionBuilder';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
// import TeacherCourseLessonsFilter from './TeacherCourseLessonsFilter';
import { useGetLessonData, useHandleTeacherCourseAssigment, useLessonSelect } from './TeacherCourseLessonsHooks';
import TeacherCoursesMultiSelectionActions from './actions/TeacherCoursesMultiSelectionActions';
import EditUnitAction from './actions/EditUnitAction';
import EditTopicAction from './actions/EditTopicAction';
// import TeacherAssignedStudentCoursePanel from '../../../../lessons/assigned-student-courses/TeacherAssignedStudentCoursePanel';
import TeacherCoursesRowActions from './actions/TeacherCoursesRowActions';
import './TeacherCourseLessons.scss';
import CourseActiveAction from './actions/CourseActiveAction';

const standardCardSize = calculateCardSize();
const cardSize = {
  width: standardCardSize.width * 0.84,
  slidesToShow: standardCardSize.slidesToShow,
  slidesToScroll: standardCardSize.slidesToScroll - 1,
};

const TeacherCourseLessons = ({
  excludeLessonTypes,
  includeLessonTypes,
  isCourseDetailEditable,
  noLessonMessage,
  subjectTitle,
}) => {
  const {
    hasPerformSearch,
    filteredLessonData,
    currentSelectedSubjectName,
  } = useGetLessonData({
    excludeLessonTypes,
    includeLessonTypes,
    subjectTitle,
  });

  const {
    handleOnLessonSelect,
    selectedLessonIds,
    handleOnLessonClear,
  } = useLessonSelect();

  const {
    selectedDates,
    // handleOnSelectedDatesApply,
    // handleOnStartLoading,
    // subjectId,
    // onDataLoad,
    // assignedStudentCourses,
    // filterCriteriaAssignedStudentCourses,
    // assignedStudentCoursesLoading,
    // handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  } = useHandleTeacherCourseAssigment();

  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const lessonDetails = useMemo(() => (
    <LessonCardListDetail
      largeSingleRowMode
      currentSelectedSubjectName={subjectTitle || currentSelectedSubjectName}
      lessonData={filteredLessonData}
      cardSize={cardSize}
      noLessonMessage={noLessonMessage}
      noFilter
      createCardAction={(lesson) => createLessonCardAction(lesson, user, userProfile, terminologies)}
      createCardDetailAction={(
        lesson,
      ) => (
        createLessonCardDetailAction(lesson, user, userProfile, terminologies, selectedDates)
      )}
      currentUser={user}
      currentUserProfle={userProfile}
      createLessonDetailAction={(lesson) => createLessonCardDetailonPlanelAction(lesson, user, userProfile, terminologies)}
      onLessonSelect={handleOnLessonSelect}
      selectedLessonIds={selectedLessonIds}
      createCardLabel={(lesson) => createLessonCardLabel(lesson)}
      createCardDetailLabel={(lesson) => createLessonCardLabel(lesson)}
      createRowAction={(rowIndex) => (
        <TeacherCoursesRowActions
          rowIndex={rowIndex}
          selectedLessonIds={selectedLessonIds}
          onClearClick={handleOnLessonClear}
          filteredLessonData={filteredLessonData}
        />
      )}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [filteredLessonData, selectedLessonIds]);

  return (
    <div className='teacher-course-lessons'>

      {hasPerformSearch ? (
        <>
          <div className='lessons'>
            {/* <TeacherCourseLessonsFilter /> */}
            {isCourseDetailEditable && user.userModerator && (
              <>
                <Box mt='15px'>
                  <CourseActiveAction />
                </Box>
                <Box mt='15px' display='flex' alignItems='center'>
                  <EditUnitAction />
                </Box>
                <Box mt='5px'>
                  <EditTopicAction />
                </Box>
              </>
            )}
            {lessonDetails}
            <TeacherCoursesMultiSelectionActions
              onClearClick={handleOnLessonClear}
              selectedLessonIds={selectedLessonIds}
              excludeLessonTypes={excludeLessonTypes}
              includeLessonTypes={includeLessonTypes}
            />
          </div>
          {/* <TeacherAssignedStudentCoursePanel
            selectedDates={selectedDates}
            onSelectedDatesApply={handleOnSelectedDatesApply}
            onStartLoading={handleOnStartLoading}
            subjectId={subjectId}
            onDataLoad={onDataLoad}
            assignedStudentCourses={assignedStudentCourses}
            filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
            loading={assignedStudentCoursesLoading}
            onFilterCriteriaAssignedStudentsCoursesUpdate={handleOnFilterCriteriaAssignedStudentsCoursesUpdate}
            canArchive={false}
          /> */}
          <br />
        </>
      ) : (
        <TeacherCourseInstruction />
      )}
    </div>
  );
};

TeacherCourseLessons.defaultProps = {
  excludeLessonTypes: [],
  includeLessonTypes: [],
  isCourseDetailEditable: false,
  noLessonMessage: 'No Activities Found.',
  subjectTitle: undefined,
};

TeacherCourseLessons.propTypes = {
  excludeLessonTypes: PropTypes.arrayOf(PropTypes.string),
  includeLessonTypes: PropTypes.arrayOf(PropTypes.string),
  isCourseDetailEditable: PropTypes.bool,
  noLessonMessage: PropTypes.string,
  subjectTitle: PropTypes.string,
};

export default TeacherCourseLessons;

import { Children } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Cancel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ButtonList from '../../../buttons/ButtonList';
import PageDataNavigator from './PageDataNavigator';
import { calculateCardThumbnailSize } from '../../../../utils/CardSizeCalculator';
import './ContainerLessonPageDataList.scss';

/**
 * Component for displaying lesson data pages list with actions
 */
export default function ContainerLessonPageDataList({
  actionConfigs,
  actionAlign,
  children,
  onSelect,
  selectedIndex,
  onDelete,
  onDragEnd,
  className,
  disableDeleteIndex,
}) {
  const cardSize = calculateCardThumbnailSize();

  /**
   * When any button is clicked (Add, Copy),
   * set the focusOnSelect to true, to move the new selected index.
   */
  const buttonActionConfigs = actionConfigs.map((config) => ({
    ...config,
    onClick: () => {
      config.onClick();
    },
  }));

  const handleSelect = (index) => {
    onSelect(index);
  };

  const handleDelete = (index) => {
    onDelete(index);
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    onDragEnd(result.source, result.destination);
  };

  return (
    <div className={`container-lesson-page-data-list ${className}`}>
      <ButtonList
        config={buttonActionConfigs}
        align={actionAlign}
        className='lesson-page-data-button-list vizzle-page-data-actions'
        componentKey='lesson-page-data-button-list'
      />

      <PageDataNavigator elementId='lesson-page-data-droppable-component' numberOfCards={children.length} selectedCard={selectedIndex}>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className='lesson-page-data-droppable'
                id='lesson-page-data-droppable-component'
              >
                {children ? Children.map(children, (child, index) => (
                  <Draggable draggableId={`lesson-page-item-${index}`} index={index}>
                    {(providedItem, snapshotItem) => (
                      <div
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                        {...providedItem.dragHandleProps}
                        className={`lesson-page-data-card-wrapper ${snapshotItem.isDragging ? 'lesson-page-data-dragged-item' : ''} vizzle-lesson-dnd-thumbnail-${index}`}
                        id={`lesson-page-item-draggable-${index}`}
                        data-test={`lesson-page-item-${index}`}
                      >
                        {
                          children.length > 1 && !disableDeleteIndex.includes(index)
                            ? (
                              <DeleteIcon
                                data-test={`lesson-card-remove-action-${index}`}
                                onClick={() => { handleDelete(index); }}
                                className={`lesson-page-data-card-delete-button vizzle-lesson-card-remove-btn-${index}`}
                              />
                            ) : ''
                        }
                        <Box className='page-number' onMouseDown={() => { handleSelect(index); }}>
                          {index + 1}
                        </Box>
                        <Card
                          className={`lesson-page-data-card ${index === selectedIndex ? 'lesson-page-data-card-selected' : ''} vizzle-lesson-card-thumbnail-${index}`}
                          style={{ height: cardSize.height, width: cardSize.width }}
                          onMouseDown={() => { handleSelect(index); }}
                        >
                          {child}
                        </Card>
                      </div>
                    )}
                  </Draggable>
                )) : ''}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      </PageDataNavigator>

    </div>
  );
}

ContainerLessonPageDataList.defaultProps = {
  actionAlign: 'vertical',
  children: <div />,
  onSelect: () => { },
  selectedIndex: null,
  onDelete: () => { },
  onDragEnd: () => { },
  className: '',
  disableDeleteIndex: [],
};

ContainerLessonPageDataList.propTypes = {
  actionConfigs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  })).isRequired,
  actionAlign: PropTypes.oneOf(['vertical', 'horizental']),
  children: PropTypes.node,
  onSelect: PropTypes.func,
  selectedIndex: PropTypes.number,
  onDelete: PropTypes.func,
  onDragEnd: PropTypes.func,
  className: PropTypes.string,
  disableDeleteIndex: PropTypes.arrayOf(PropTypes.number),
};

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useMyLessonsContext } from '../context/MyLessonsContextProvider';
import ClearLessonSelectionAction from '../../../../lessons/lessonactions/lesson-multi-selection-actions/ClearLessonSelectionAction';
import { SORT_LESSONS_BY } from '../MyLessonsHook';
import './MyLessonsRowActions.scss';
import DropdownInput from '../../../../../components/selector/DropdownInput';

const MyLessonsRowActions = ({
  onClearClick,
  selectedLessonIds,
  lessonCardDetailRef,
}) => {
  const { myLessonsDomain } = useMyLessonsContext();

  return (
    <div className='my-lessons-row-actions'>
      <DropdownInput
        label='Sort Activities by'
        dropdownValues={[{
          id: SORT_LESSONS_BY.name,
          name: 'Name',
        },
        {
          id: SORT_LESSONS_BY.recentlyAdded,
          name: 'Recently Added',
        },
        ]}
        name='selectedSubject'
        value={myLessonsDomain.domainData.lessonSortOption || SORT_LESSONS_BY.name}
        containerClass='lesson-sort-dropdown-container'
        className='lesson-sort-dropdown'
        onChange={async (e) => {
          myLessonsDomain.updateLessonSortOption(e.target.value);
        }}
        data-test='sort-by-lessons-menu'
      />
      <Button
        variant='contained'
        className='btn-primary select-all'
        onClick={() => {
          const currentDisplayData = lessonCardDetailRef && lessonCardDetailRef.current.getCurrentDisplayData();
          const selectedIds = currentDisplayData[0].lessons.map((l) => l.id);
          myLessonsDomain.updateSelectedLessonIds(selectedIds);
        }}
        data-test='select-all-lessons-action'
      >
        SELECT ALL
      </Button>
      <ClearLessonSelectionAction
        className='clear-selection'
        onClearClick={onClearClick}
        selectedLessonIds={selectedLessonIds}
      />
    </div>
  );
};

MyLessonsRowActions.defaultProps = {
  onClearClick: () => { },
  selectedLessonIds: [],
  lessonCardDetailRef: {},
};

MyLessonsRowActions.propTypes = {
  onClearClick: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  lessonCardDetailRef: PropTypes.any,
};

export default MyLessonsRowActions;

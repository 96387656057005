import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';
import ConfirmationDialog from '../../../../../../components/dialog/ConfirmationDialog';
import { useDeleteExternalLink } from '../ExternalLinkHooks';
import './DeleteExternalLink.scss';

const DeleteExternalLink = ({ externalLink }) => {
  const {
    deleteLink,
  } = useDeleteExternalLink();

  const [openDialog, setOpenDialog] = useState();
  const handleOnConfirm = () => {
    setOpenDialog(false);
    deleteLink(externalLink);
  };

  return (
    <>
      <IconButton
        aria-label='delete'
        className='delete-external-link'
        onClick={() => {
          setOpenDialog(true);
        }}
        data-test={`delete-external-link${externalLink.id}`}
      >
        <DeleteIcon className='delete-external-link-icon' />
      </IconButton>
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title='Delete External link'
          contentText={(
            <div className='delete-confirmation-content'>
              Are you sure you want to delete this link?
              <div className='to-delete-link'>
                {externalLink.attributes.link}
              </div>
            </div>
          )}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={handleOnConfirm}
          onCancel={() => { setOpenDialog(false); }}
        />
      ) : null}
    </>
  );
};

DeleteExternalLink.propTypes = {
  externalLink: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string,
  }).isRequired,
};

export default DeleteExternalLink;

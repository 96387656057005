import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useDebounce } from '../../utils/ReactUtil';
import './SearchTextField.scss';

const SearchTextField = ({
  filteredText,
  onFilterUpdate,
}) => {
  const [keyword, setKeyword] = useState(filteredText);
  const debouncedKeywordValue = useDebounce(keyword, 600);

  const handleTextChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleClearKeywordClick = () => {
    setKeyword('');
  };

  useEffect(() => {
    onFilterUpdate(debouncedKeywordValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywordValue]);

  return (
    <div className='search-lessons-filter-text' data-test='search-text-field'>
      <FilterListIcon />
      <Input
        type='text'
        placeholder='Filter By Keyword'
        onChange={handleTextChange}
        variant='outlined'
        value={keyword || ''}
        className='input-field'
        endAdornment={
          keyword ? (
            <InputAdornment position='end' className='clear-keyword-button'>
              <IconButton
                aria-label='Clear keyword'
                onClick={handleClearKeywordClick}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position='end'>
              <SearchIcon className='search-icon' />
            </InputAdornment>
          )
        }
        data-test='filter-by-keyword-input'
      />
    </div>
  );
};

SearchTextField.defaultProps = {
  onFilterUpdate: () => null,
  filteredText: '',
};

SearchTextField.propTypes = {
  filteredText: PropTypes.string,
  onFilterUpdate: PropTypes.func,
};

export default SearchTextField;

import { useEffect, useRef, useState } from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Badge, Portal } from '@mui/material';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ChatPanel from './ChatPanel';
import './ChatWidget.scss';

const getButtonLabel = ({ isButton, open }) => {
  if (isButton) {
    return 'Chat';
  }

  return open ? <CloseIcon fontSize='large' /> : <ChatIcon fontSize='large' />;
};
export default function ChatWidget({
  title,
  open,
  onOpen,
  onSubmit,
  dataRecords,
  showBadge,
  buttonMode,
  defaultOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef();
  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
    onOpen(!open, showBadge);
  };

  const id = open ? 'chat-popover' : undefined;

  useEffect(() => {
    if (buttonRef.current && defaultOpen) {
      buttonRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonRef]);

  const chatWidget = ({ className, isButton } = {}) => (
    <div className={`chat-widget ${className}`}>
      <Badge badgeContent={showBadge ? <PriorityHighIcon /> : undefined} className='badge-content'>
        <Button
          aria-describedby={id}
          variant='contained'
          onClick={handleClick}
          className={`${isButton ? 'btn-primary chat-button-button-mode' : 'btn-secondary chat-button'}`}
          ref={buttonRef}
        >
          {getButtonLabel({ open, isButton })}
        </Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={`chat-container ${isButton ? 'right-panel' : 'normal'}`}
      >

        <ChatPanel
          title={title}
          onSubmit={onSubmit}
          dataRecords={dataRecords}
          displayCloseIcon
          onClose={() => {
            setAnchorEl(null);
            onOpen(false, showBadge);
          }}
        />
      </Popover>
    </div>
  );

  if (buttonMode) {
    return chatWidget({ className: 'chat-widget-button', isButton: true });
  }
  return (
    <Portal container={document.body}>
      {chatWidget()}
    </Portal>
  );
}

ChatWidget.defaultProps = {
  title: '',
  open: false,
  onOpen: () => null,
  onSubmit: () => null,
  dataRecords: [],
  showBadge: false,
  buttonMode: false,
  defaultOpen: false,
};

ChatWidget.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  dataRecords: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    text: PropTypes.string,
    date: PropTypes.string,
    sender: PropTypes.string,
  })),
  showBadge: PropTypes.bool,
  buttonMode: PropTypes.bool,
  defaultOpen: PropTypes.bool,
};

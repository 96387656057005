import { useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncLoader from 'react-spinners/SyncLoader';
import SmallAudioUploader from '../../../../../../components/vizzle/uploader/SmallAudioUploader';
import NotificationBlocker from '../../../../../../components/blocker/NotificationBlocker';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { UNAUTHORIZED } from '../../../../../../AppConstants';
import { uploadMedia } from '../../../../../../services/MediaService';
import Logger from '../../../../../../utils/Logger';

/**
 * Container for uploading an audio file from the user's computer
 *
 * @export
 */
export default function BrowseAudioWithSmallUploaders({
  audio,
  onSelect,
  onRemove,
  title,
  placeHolder,
}) {
  const [uploadState, setUploadState] = useState({
    isUploading: false,
    uploadingMessage: '',
  });
  const { uiDomain } = useDomain();

  const handleOnAudioSelected = async (data) => {
    setUploadState({
      isUploading: true,
      uploadingMessage: 'Please wait while the audio is being uploaded.',
    });

    try {
      const audioPromise = await uploadMedia(data);
      await onSelect(audioPromise.attributes.s3Url);
    } catch (e) {
      if (e.response && UNAUTHORIZED !== e.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
      }
      Logger.logError({
        ERROR: e,
      });
    }
    setUploadState({
      isUploading: false,
      uploadingMessage: '',
    });
  };

  const handleOnAudioRemoved = async () => {
    await onRemove();
  };

  const noAudio = audio === null || audio === undefined;

  return (
    <>
      <SmallAudioUploader
        onSelect={handleOnAudioSelected}
        onRemove={handleOnAudioRemoved}
        disabledRemove={noAudio}
        audioUrl={audio}
        title={title}
        placeHolder={placeHolder}
      />
      <NotificationBlocker
        show={uploadState.isUploading}
        loaderIcon={<SyncLoader color='#3b94d1' />}
        Icon={CloudUploadIcon}
        message={uploadState.uploadingMessage}
      />
    </>
  );
}

BrowseAudioWithSmallUploaders.defaultProps = {
  audio: null,
  onSelect: () => { },
  onRemove: () => { },
  title: '',
  placeHolder: '',
};

BrowseAudioWithSmallUploaders.propTypes = {
  audio: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  title: PropTypes.string,
  placeHolder: PropTypes.string,
};

import PropTypes from 'prop-types';
import Painterro from 'painterro';
import { useLayoutEffect, useRef } from 'react';
import './DrawingEditor.scss';
import ObjectUtils from '../../../../../../utils/ObjectUtils';

// eslint-disable-next-line max-len
const defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAGQCAQAAAD8LMy/AAAEgUlEQVR42u3VMQEAAAjDMOZf9NDATSKhT9MOAJzFQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAzEQAAwEAAMBwEAAMBAAMBAADAQAAwHAQAAwEAAwEAAMBAADAcBAADAQADAQAAwEAAMBwEAAMBAAMBAADAQAAwHAQAAwEAAwEAAMBAADAcBAADAQADAQAAwEAAMBwEAAMBAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBARADAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAwEAAMBwEAAMBAADAQADAQAAwHAQAAwEAAMBAAMBAADAcBAADAQAAwEAAwEAAMBwEAAMBAADAQADAQAAwHAQAAwEAAMBAAMBAADAcBAADAQAAwEAAwEAAMBwEAAMBAADAQADAQAAwHAQAAwEAAwEAAMBAADAcBAADAQADAQAAwEAAMBwEAAMBAAMBAADAQAAwHAQAAwEAAwEAAMBAADAcBAADAQADAQAAwEAAMBwEAAMBAAMBAADAQAAwHAQAAwEAAwEAAMBAADAcBAADAQAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAwEAAMBwEAAMBAADAQADAQAAwHAQAAwEAAMBAAMBAADAcBAADAQAAwEAAwEAAMBwEAAMBAADAQADAQAAwHAQAAwEAAMBAAMBAADAcBAADAQAAwEAAwEAAMBwEAAMBAADMRAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwHAQADAQAAwEAAMBAADAcBAAMBAADAQAAwEAAMBwEAAwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADAQAAwEAAwHAQAAwEAAMBAADAQADAcBAADAQAAwEAAMBAAMBwEAAMBAADASArxaizx6eRkQKPQAAAABJRU5ErkJggg==';
const paintTerroContainer = 'paint-terro-container';

const DrawingEditor = ({
  imageUrl,
  onImageChange,
  drawingImage,
}) => {
  const paintTerroRef = useRef(null);

  useLayoutEffect(() => {
    paintTerroRef.current = Painterro({
      id: paintTerroContainer,
      defaultPrimitiveShadowOn: false,
      defaultTextStrokeAndShadow: false,
      shadowScale: 0,
      defaultSize: 'fill',
      defaultTool: 'brush',
      onChange: onImageChange,
      backplateImgUrl: !imageUrl && drawingImage ? null : imageUrl || defaultImage,
      hiddenTools: ['select', 'pixelize', 'crop', 'line', 'arrow', 'open', 'close', 'zoomin', 'zoomout', 'rotate', 'resize', 'settings', 'redo', 'save'],
    });

    // Override the mouseMove to focus only the drawing component
    const mouseMove = paintTerroRef.current.documentHandlers.mousemove;
    paintTerroRef.current.documentHandlers.mousemove = (e) => {
      if (e.target.classList.contains('ptro-crp-el') || e.target.classList.contains('ptro-center-tablecell')) {
        mouseMove(e);
      }
    };

    paintTerroRef.current.show(drawingImage);

    ObjectUtils.setTimeout(() => {
      const ptroSubtrate = document.querySelector('.ptro-substrate');
      const wrapper = document.querySelector('.ptro-wrapper');
      wrapper.style.width = `${ptroSubtrate.offsetWidth}px`;
      wrapper.style.height = `${ptroSubtrate.offsetHeight}px`;

      document.querySelector('.ptro-crp-el').style.left = 0;
      document.querySelector('.ptro-crp-el').style.top = 0;
      document.querySelector('.ptro-substrate').style.left = 0;
      document.querySelector('.ptro-substrate').style.top = 0;
    }, 100);
    return () => {
      paintTerroRef.current.hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={paintTerroContainer}
      className='drawing-editor'
    />
  );
};

DrawingEditor.defaultProps = {
  onImageChange: () => null,
  imageUrl: null,
  drawingImage: null,
};

DrawingEditor.propTypes = {
  imageUrl: PropTypes.string,
  onImageChange: PropTypes.func,
  drawingImage: PropTypes.string,
};

export default DrawingEditor;

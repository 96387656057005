/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */

import { AudioConfig, AudioOutputStream, SpeechConfig, SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';
import Logger from '../../utils/Logger';
// eslint-disable-next-line import/no-cycle
import { isAudioPlaying, playAudio, stopAudio } from '../../components/vizzle/media/AudioPlayer';
import { getSpeechToken } from '../SpeechTokenService';
import ObjectUtils from '../../utils/ObjectUtils';

/* Get all available voice types */
export const VOICE_TYPES = [
  { key: 'en-US-JennyNeural', value: 0, name: 'Jenny (Female, United States)' },
  { key: 'en-US-AmberNeural', value: 1, name: 'Amber (Female, United States)' },
  { key: 'en-US-NancyNeural', value: 2, name: 'Nancy (Female, United States)' },
  { key: 'en-US-JaneNeural', value: 3, name: 'Jane (Female, United States)' },
  { key: 'en-US-RogerNeural', value: 4, name: 'Roger (Male, United States)' },
  { key: 'en-US-SteffanNeural', value: 5, name: 'Steffan (Male, United States)' },
  { key: 'en-US-DavisNeural', value: 6, name: 'Davis (Male, United States)' },
  { key: 'en-US-GuyNeural', value: 7, name: 'Guy (Male, United States)' },
  // { key: 'es-MX-NuriaNeural', value: 8, name: 'Nuria (Female, Mexico)' },
  // { key: 'es-MX-JorgeNeural', value: 9, name: 'Jorge (Male, Mexico)' },
  // { key: 'es-CO-SalomeNeural', value: 10, name: 'Saloma (Female, Colombia)' },
  // { key: 'es-CO-GonzaloNeural', value: 11, name: 'Gonzalo (Male, Colombia)' },
  // { key: 'es-ES-LaiaNeural', value: 12, name: 'Laia (Female, Spain)' },
  // { key: 'es-ES-ArnauNeural', value: 13, name: 'Arnau (Male, Spain)' },
  // { key: 'es-DO-RamonaNeural', value: 14, name: 'Ramona (Female, Dominican Republic)' },
  // { key: 'es-DO-EmilioNeural', value: 15, name: 'Emilio (Male, Dominican Republic)' },
];

// export const HIGHLIGHT_COLORS = [
//   { key: 'Orange', value: '#ef7c22', name: 'Orange' },
//   { key: 'Blue', value: '#509fd6', name: 'Blue' },
//   { key: 'Green', value: '#78b543', name: 'Green' },
//   { key: 'Yellow', value: '#f4f442', name: 'Yellow' },
// ];

// export const TTS_DEFAULT_VALUE = {
//   rate: 10,
//   volume: 10,
//   voice: 0,
//   highlightColor: HIGHLIGHT_COLORS[0].value,
// };

/* Regular expression for number x number */
const MULTIPLY_REGEXP = /[(]*[-]*\d+[ ]*(x|X)[ ]*[(]*[-]*\d+[)]*([ ]*(x|X)[ ]*[-]*\d+)*[)]*/;
const PLUS_REGEXP = /[0-9a-zA-Z]+([ ])*\+([ ])*[0-9a-zA-Z]+(([ ])*\+([ ])*[0-9a-zA-Z]+)*/g;
const MINUS_DASH_REGEXP = /[a-zA-Z]+([ ])*-([ ])*[a-zA-Z]+(([ ])*-([ ])*[a-zA-Z]+)*/g;
const MINUS_REGEXP = /[0-9a-zA-Z]+([ ])*-([ ])*[0-9a-zA-Z]+(([ ])*-([ ])*[0-9a-zA-Z]+)*/g;
const MINUS_ONE_DIGIT_REGEXP = /-[ ]([ ])*\d/;
const NEGATIVE_REGEXP = /([ ]|\n)*-\d/;
const NEGATIVE_IN_EQUATION_REGEXP = /([ ]|\n)*-\d/g;
const BLANG_SPACE_REGEXP = /(=|\n)([ ])*___*([ ]|\n)*/;
const BLANK_REGEXP = /__*([ ]*__*)/;
const CONSECUTIVE_DASH_REGEXP = /--+/;
const CONSECUTIVE_DOTS_REGEXP = /\.\.+/;
const QUESTION_MARK_REGEXT = /\\?/;
// eslint-disable-next-line no-useless-escape
const EXCLAMATION_MARK_REGEXT = /\!/;
const ABSOLUTE_VALUE = /\|[ ]*-?\d+\|/g;
const PIPES = /\|\|/g;

/* A functon to convert
  * string of number x number to number times number
  * so that tts could read out 'times' instead of 'by'
  */
const replaceString = (regResult, regToReplace, newWord, result) => {
  for (let i = 0; i < regResult.length; i++) {
    let match = regResult[i];
    if (match) {
      match = match.replace(/\+/g, '\\+');
      let newSentence = match.replace(regToReplace, newWord);
      newSentence = newSentence.replace(/\\/g, '');
      const regex = new RegExp(match, 'g');
      result = result.replace(regex, newSentence);
    }
  }
  return result;
};

const checkAndConvertText = (text) => {
  let result = text;
  let test = text.match(MULTIPLY_REGEXP);

  if (test) {
    result = replaceString(test, /(x|X)/g, 'times', result);
    test = result.match(NEGATIVE_IN_EQUATION_REGEXP);
    if (test) {
      result = replaceString(test, /-/g, ' negative ', result);
    }
  }

  test = result.match(BLANG_SPACE_REGEXP);
  if (test) {
    result = result.replace(/_+/g, ' ');
  }

  test = result.match(BLANK_REGEXP);
  if (test) {
    result = result.replace(/_+/g, 'blank');
  }

  test = result.match(CONSECUTIVE_DASH_REGEXP);
  if (test) {
    result = result.replace(/--+/, ' ');
  }

  test = result.match(CONSECUTIVE_DOTS_REGEXP);
  if (test) {
    result = result.replace(/\.\.+/, ' ');
  }

  test = result.match(QUESTION_MARK_REGEXT);
  if (test) {
    result = result.replace(/\?/, ' ');
  }

  test = result.match(EXCLAMATION_MARK_REGEXT);
  if (test) {
    // eslint-disable-next-line no-useless-escape
    result = result.replace(/\!/g, ' ');
  }

  test = result.match(ABSOLUTE_VALUE);
  if (test) {
    for (let i = 0; i < test.length; i++) {
      const el = test[i];
      let newText = el.replace(/\|/g, '');
      newText = newText.replace('-', 'negative ');
      newText = `absolute ${newText} `;
      result = result.replace(el, newText);
    }
  }

  test = result.match(PIPES);
  if (test) {
    result = result.replace(/\|\|/g, ' pipes ');
  }

  test = result.match(PLUS_REGEXP);
  if (test) {
    result = replaceString(test, /\+/g, ' plus ', result);
  }

  test = result.match(MINUS_DASH_REGEXP);
  if (test) {
    result = replaceString(test, /-/g, ' ', result);
  }

  test = result.match(MINUS_REGEXP) || result.match(MINUS_ONE_DIGIT_REGEXP);
  if (test) {
    if (test[0].indexOf(' ') === -1) {
      result = replaceString(test, /-/g, '', result);
    } else {
      result = replaceString(test, /-/g, ' minus ', result);
    }
  }

  test = result.match(NEGATIVE_REGEXP);
  if (test) {
    result = replaceString(test, /-/g, ' negative ', result);
  }

  // Fix for symbol
  test = result.match(/</g);
  if (test) {
    result = replaceString(test, /</g, ' less than ', result);
  }
  test = result.match(/>/);
  if (test) {
    result = replaceString(test, />/g, ' greater than ', result);
  }
  if (result) {
    return result;
  }
  return text;
};

const cleanupText = (text) => {
  text = text.replace(/<br>/gi, ' ');
  text = text.replace(/ \./gi, ' ');
  return text;
};

const waitFor = (ms) => new Promise((resolve) => {
  const t = setTimeout(() => {
    clearTimeout(t);
    resolve();
  }, ms);
});

const arrayBufferToBase64 = (binary) => {
  const blob = new Blob([binary], { type: 'audio/wav' });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};

const cleanupClass = () => {
  const elements = document.querySelectorAll('.tts-playing');
  if (elements && elements.length > 0) {
    elements.forEach((e) => {
      e.style.boxShadow = '';
      e.classList.remove('tts-playing');
    });
  }
};

const addHighlightClass = (el, highlightColor) => {
  el.style.setProperty('box-shadow', `0px 0px 8px 8px ${highlightColor}`, 'important');
  el.classList.add('tts-playing');
};

const getAudioUrlCacheKey = (voiceType, message) => `${voiceType}-${message}`;

let synthesizer;
let speechConfig;
const audioUrlCache = [];

let cancel = false;
const WAIT_TIME = 5000;

const playText = (el,
  {
    // rate,
    volume,
    voiceType,
    highlightColor,
  },
) => {
  if (!el) {
    return Promise.resolve();
  }
  try {
    const text = el.innerText;
    const cleanText = cleanupText(text);
    const convertedText = checkAndConvertText(cleanText);

    return new Promise((resolve, reject) => {
      if (convertedText.trim().length === 0) {
        resolve();
        return;
      }

      let startTimeout;
      try {
        const audioCacheKey = getAudioUrlCacheKey(voiceType.key, convertedText);
        const audioCacheUrl = audioUrlCache[audioCacheKey];
        if (audioCacheUrl) {
          playAudio(audioCacheUrl, () => {
            addHighlightClass(el, highlightColor);
          }, {
            rate: 1, volume,
          })
            .catch(() => { })
            .finally(() => {
              cleanupClass();
              resolve();
            });
          return;
        }

        const stream = AudioOutputStream.createPullStream();
        const audioConfig = AudioConfig.fromStreamOutput(stream);
        // The language of the voice that speaks.
        speechConfig.speechSynthesisVoiceName = voiceType.key;
        synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

        startTimeout = ObjectUtils.setTimeout(async () => {
          if (convertedText.trim().length === 0) {
            return;
          }
          Logger.logWhenDebugModeIsOn(`Cannot start tts within ${WAIT_TIME / 1000} seconds.`);
          clearTimeout(startTimeout);
          await waitFor(100);
          resolve();
        }, WAIT_TIME + 50);

        synthesizer.speakTextAsync(
          convertedText,
          async (result) => {
            try {
              clearTimeout(startTimeout);
              if (cancel) {
                resolve();
                return;
              }
              stream.close();
              synthesizer.close();
              if (!result.audioData) {
                resolve();
                return;
              }
              if (result.audioData.byteLength === 0) {
                resolve();
                return;
              }
              const audioUrl = arrayBufferToBase64(result.audioData);
              audioUrlCache[getAudioUrlCacheKey(voiceType.key, convertedText)] = audioUrl;

              await playAudio(audioUrl, () => {
                addHighlightClass(el, highlightColor);
              }, {
                rate: 1, volume,
              });
            } catch (_e) {
              // ignore
            } finally {
              cleanupClass();
              resolve();
            }
          },
          (error) => {
            clearTimeout(startTimeout);
            Logger.logError(error);
            stream.close();
            synthesizer.close();
            cleanupClass();
            reject();
          },
        );
      } catch (e) {
        clearTimeout(startTimeout);
        Logger.logError(e);
        cleanupClass();
        resolve();
      }
    });
  } catch (e) {
    Logger.logError(e);
    return Promise.resolve();
  }
};

const getSpeechConfig = async () => {
  const token = await getSpeechToken();
  return SpeechConfig.fromAuthorizationToken(token, process.env.REACT_APP_TTS_REGION);
};

const cancelTtsProcess = async () => {
  try {
    if (isAudioPlaying()) {
      await stopAudio();
      synthesizer.close();
    }
  } catch (_e) {
    // ignore
  }
};

export const cancelTts = () => {
  cancel = true;
  cancelTtsProcess();
  cleanupClass();
};

export const playTextToSpeech = async (elList = [], config) => (
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      cancel = false;
      await cancelTtsProcess();
      await waitFor(100);

      speechConfig = await getSpeechConfig();

      const { highlightColor, rate, volume, voice } = config;
      const voiceType = VOICE_TYPES.find((v) => v.value === voice);
      const ttConfig = {
        rate: rate / 10,
        volume: volume / 10,
        voiceType,
        highlightColor,
      };

      for (let index = 0; index < elList.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await playText(elList[index], ttConfig);
        if (cancel) {
          return;
        }
      }
      resolve();
    } catch (e) {
      reject(e);
    }
  }));

export const readText = async (textToRead, config) => {
  if (!config.enabled) {
    return;
  }
  await cancelTtsProcess();
  speechConfig = await getSpeechConfig();
  try {
    cancel = false;
    const stream = AudioOutputStream.createPullStream();
    const audioConfig = AudioConfig.fromStreamOutput(stream);
    synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
    synthesizer.speakTextAsync(
      textToRead,
      async (result) => {
        stream.close();
        synthesizer.close();
        try {
          if (!result.audioData) {
            return;
          }
          await playAudio(arrayBufferToBase64(result.audioData));
        } catch (_e) {
          // ignore
        }
      },
      (error) => {
        Logger.logError(error);
        stream.close();
        synthesizer.close();
      },
    );
  } catch (e) {
    Logger.logError(e);
  }
};

export const isTtsPlaying = () => isAudioPlaying();

export const reigsterTextToSpeechAutoPlay = () => { getSpeechToken(); };

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import TextEditor from '../../../../../../components/text/TextEditor';
import { convertVizzleTextLineToHtml, convertHtmlToVizzleTextLine } from '../../../../../../utils/TextLineConvertor';
import { updateAuthoringProfile } from '../../../../../../services/UserProfileService';
import { DEFAULT_TEXT_FORMAT } from '../../../../../../AppConstants';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import MatchEquationEditorDialog from './MatchEquationEditorDialog';
import { getDefaultText as getText } from '../../../../../../utils/activitytype/ActivityTypeInitialData';
import { DELEMETER, getTextArray } from '../../../../../../components/vizzle/text/TextLinesUtils';
import ApplyTextStyleAction from './actions/apply-text-style/ApplyTextStyleAction';
import { FONT_FAMILY_LIST } from '../../../../../../constants/TextEditorConstants';
import TextEditorWarningLabel from './actions/text-editor-warning/TextEditorWarningLabel';
import { flatten } from '../../../../../../utils/ArrayUtils';

const DEFAULT_FONT_FAMILY = 'Quattrocento';

/**
 * Container for text and media formatting etitor
 *
 * @export
 * @param {*} { text }
 * @returns
 */
export default function TextAndMediaFormatting({
  text,
  onTextChange,
  onTextApply,
  onTextRemoved,
  disabled,
  includeMath,
  includeInlineOption,
  includeTextAlign,
  showApplyButton,
  showRemoveTextButton,
  showApplyStyleLessonButton,
  useUserProfileAsDefault,
  selectAll,
  textEditorWarning,
}) {
  const [state, setState] = useState({});
  const { authoringProfileDomain, userDomain } = useDomain();
  const { profile } = authoringProfileDomain.domainData;
  const [openDialog, setOpenDialog] = useState(false);

  const { user } = userDomain.domainData;
  const getDefaultText = () => {
    // Get default style
    const { attributes } = profile;
    const defaultText = {
      blocks: [
        {
          key: '8i090',
          text: ' ',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              offset: 0,
              length: 1,
              style: `fontfamily-${useUserProfileAsDefault ? attributes.fontName : 'Josefin Slab'}`,
            },
            {
              offset: 0,
              length: 1,
              style: `fontsize-${useUserProfileAsDefault ? attributes.fontSize : 18}`,
            },
            {
              offset: 0,
              length: 1,
              style: `color-${useUserProfileAsDefault ? attributes.fontColor : '#000000'}`,
            },
          ],
          entityRanges: [],
          data: {
            // text-align: 'center',
          },
        },
      ],
      entityMap: {},
    };
    if (useUserProfileAsDefault && attributes.bold) {
      defaultText.blocks[0].inlineStyleRanges.push({
        offset: 0,
        length: 1,
        style: 'BOLD',
      });
    }

    if (useUserProfileAsDefault && attributes.italic) {
      defaultText.blocks[0].inlineStyleRanges.push({
        offset: 0,
        length: 1,
        style: 'ITALIC',
      });
    }
    if (useUserProfileAsDefault) {
      defaultText.blocks[0].data['text-align'] = attributes.indent;
    }

    return defaultText;
  };

  const lines = text && text.lines;
  useEffect(() => {
    let contentState;
    if (text && text.lines && text.lines.length > 0) {
      const htmlBlock = convertVizzleTextLineToHtml(text, FONT_FAMILY_LIST, DEFAULT_FONT_FAMILY);
      const contentBlock = htmlToDraft(htmlBlock);
      contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    } else {
      contentState = convertFromRaw(getDefaultText());
    }

    const editorState = EditorState.createWithContent(contentState);
    setState({
      editorState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, lines]);

  const getFontStyle = (style) => {
    if (!style) {
      return style;
    }
    return style.split('-')[1];
  };

  const updateFontSettings = async (editorStateChange) => {
    const { attributes } = profile;
    const currentStyles = editorStateChange.getCurrentInlineStyle();
    const fontName = getFontStyle(currentStyles.filter((style) => style.match(/^fontfamily-/)).last()) || DEFAULT_TEXT_FORMAT.fontName;
    const fontSize = getFontStyle(currentStyles.filter((style) => style.match(/^fontsize-/)).last()) || DEFAULT_TEXT_FORMAT.fontSize;
    const fontColor = getFontStyle(currentStyles.filter((style) => style.match(/^color-/)).last()) || DEFAULT_TEXT_FORMAT.fontColor;
    const bold = currentStyles.filter((style) => style.match(/^BOLD/)).last();
    const italic = currentStyles.filter((style) => style.match(/^ITALIC/)).last();

    const { blocks } = convertToRaw(editorStateChange.getCurrentContent());
    const block = blocks[0];
    const textAlign = block.data['text-align'] || DEFAULT_TEXT_FORMAT.indent;
    const newAttriutes = {
      ...attributes,
      fontName,
      fontSize,
      fontColor,
      bold: bold === 'BOLD',
      italic: italic === 'ITALIC',
      indent: textAlign,
    };

    if (JSON.stringify(newAttriutes) !== JSON.stringify(attributes)) {
      /* There is some changes in font-setting. */
      const newProfile = {
        ...profile,
        attributes: {
          ...newAttriutes,
        },
      };
      await authoringProfileDomain.updateAuthoringProfile(newProfile);
      updateAuthoringProfile(newProfile);
    }
  };

  const getTextLines = async (editorStateChange) => {
    if (editorStateChange.getCurrentContent().getPlainText() === '') {
      await onTextRemoved();
      return;
    }
    // eslint-disable-next-line consistent-return
    return convertHtmlToVizzleTextLine(draftToHtml(convertToRaw(editorStateChange.getCurrentContent())));
  };

  const onEditorStateChange = async (editorStateChange) => {
    if (editorStateChange.getCurrentContent().getPlainText() === '') {
      setState({
        editorState: EditorState.moveFocusToEnd(EditorState.createWithContent(convertFromRaw(getDefaultText()))),
      });
    } else {
      if (useUserProfileAsDefault) {
        updateFontSettings(editorStateChange);
      }
      setState({
        editorState: editorStateChange,
      });
    }

    const textLines = await getTextLines(editorStateChange);
    onTextChange(textLines);
  };

  const onChange = async () => {
    const textLines = await getTextLines(state.editorState);
    if (text && text.ttsText) {
      const newText = textLines ? flatten(textLines.map((t) => t.elements)).map((t) => t.text).join(' ') : '';
      const textArray = getTextArray(newText);
      const equations = textArray.filter((t) => !t.isText).map((e) => e.text);
      // eslint-disable-next-line no-param-reassign
      Object.keys(text.ttsText).forEach((t) => {
        if (!t.isText && !equations.includes(t)) {
          // eslint-disable-next-line no-param-reassign
          delete text.ttsText[t];
        }
      });
    }

    await onTextApply(textLines || []);
  };

  const noText = text === null || text === undefined || !text.lines || text.lines.length === 0;

  const handleRemoveText = async () => {
    // eslint-disable-next-line react/prop-types, no-param-reassign
    await onTextRemoved();
  };

  return (
    <>
      <Box display='flex' flexDirection='column' height='100%' width='100%' data-test='text-editor-wysiwyg'>
        <TextEditor
          editorState={state.editorState}
          onEditorStateChange={onEditorStateChange}
          onApply={onChange}
          onRemove={handleRemoveText}
          disabledRemove={noText}
          className='vizzle-text-editor'
          disabled={disabled}
          fontFamilyList={FONT_FAMILY_LIST}
          selectAll={selectAll}
          additionalActionChildren={includeMath && (
            <Button
              variant='contained'
              className='btn-primary vizzle-text-math-equation-editor'
              onClick={async (e) => {
                await onChange(e);
                setOpenDialog(true);
              }}
              data-test='open-math-editor-action'
            >
              Open Math Editor
            </Button>
          )}
          includeInlineOption={includeInlineOption}
          includeTextAlign={includeTextAlign}
          toolbarCustomButtons={showApplyStyleLessonButton && user.userModerator ? [
            <ApplyTextStyleAction key='apply-text-style-globally' />,
          ] : undefined}
          showApplyButton={showApplyButton}
          showRemoveTextButton={showRemoveTextButton}
        />
        <TextEditorWarningLabel {...textEditorWarning} />

      </Box>
      {openDialog && (
        <MatchEquationEditorDialog
          openDialog={openDialog}
          onClose={() => { setOpenDialog(false); }}
          onApply={async (value, updatedTtsText, shouldCloseDialog) => {
            // eslint-disable-next-line react/prop-types, no-param-reassign
            text.ttsText = updatedTtsText;
            const textLines = text && text.lines ? text.lines : [];
            if (value) {
              const newText = getText(`${DELEMETER}${value}${DELEMETER}`, 'left').lines[0];
              await onTextApply([
                ...textLines,
                newText,
              ]);
            } else {
              await onTextApply([
                ...textLines,
              ]);
            }
            if (shouldCloseDialog) {
              setOpenDialog(false);
            }
            // setOpenDialog(false);
          }}
          textToRead={text && text.lines ? flatten(text.lines.map((t) => t.elements)).map((t) => t.text).join(' ') : ''}
          ttsText={text.ttsText}
        />
      )}
    </>
  );
}

TextAndMediaFormatting.defaultProps = {
  onTextApply: () => { },
  onTextRemoved: () => { },
  text: { lines: [] },
  disabled: false,
  includeMath: true,
  includeInlineOption: true,
  includeTextAlign: true,
  showApplyButton: true,
  showRemoveTextButton: true,
  showApplyStyleLessonButton: true,
  onTextChange: () => null,
  useUserProfileAsDefault: true,
  selectAll: false,
  textEditorWarning: {
    show: false,
    message: '',
  },
};

TextAndMediaFormatting.propTypes = {
  // currentSelectedBook: PropTypes.number.isRequired,
  text: PropTypes.shape({
    lines: PropTypes.array,
    ttsText: PropTypes.object,
  }),
  onTextApply: PropTypes.func,
  onTextRemoved: PropTypes.func,
  disabled: PropTypes.bool,
  includeMath: PropTypes.bool,
  includeInlineOption: PropTypes.bool,
  includeTextAlign: PropTypes.bool,
  showApplyButton: PropTypes.bool,
  showRemoveTextButton: PropTypes.bool,
  showApplyStyleLessonButton: PropTypes.bool,
  onTextChange: PropTypes.func,
  useUserProfileAsDefault: PropTypes.bool,
  selectAll: PropTypes.bool,
  textEditorWarning: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
  }),
};

import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Fade,
} from '@mui/material';
import spinnerArrow from '../../../../../../static/images/activities/game/spinner.png';
import spinnerSound from '../../../../../../static/sounds/activities/game/spinner.mp3';
import outlineTopRight from '../../../../../../static/images/activities/game/outline_top_right.png';
import outlineTopLeft from '../../../../../../static/images/activities/game/outline_top_left.png';
import outlineBottomRight from '../../../../../../static/images/activities/game/outline_bottom_right.png';
import outlineBottomLeft from '../../../../../../static/images/activities/game/outline_bottom_left.png';
import ObjectUtils from '../../../../../../utils/ObjectUtils';
import { playAudio } from '../../../../../../components/vizzle/media/AudioPlayer';
import GameActivityUtils from '../../../../../../components/vizzle/page/game/GameActivityUtils';
import { SPINNER_BOARD_NUMBER } from '../../../../../../components/vizzle/page/game/GameConstants';
import './GamePlayWheelSpinner.scss';
import PlayerTurn from './PlayerTurn';

const MAX_SPIN = 4;
const ROTATE_ROUND = 3;
const OUTLINES = [
  outlineTopRight,
  outlineBottomRight,
  outlineBottomLeft,
  outlineTopLeft,
];

const WheelSpinner = forwardRef(({
  playerState,
  spinner,
  numberOfgameCards,
  onSpinnerStart,
  onSpinnerEnd,
  showSpinner,
}, ref) => {
  const [rotateTo, setRotateTo] = useState(0);

  const spinnerArrowRefEl = useRef(null);
  const currentPlayer = playerState.players[playerState.currentPlayer] || {};
  useEffect(() => {
    if (!showSpinner) {
      setRotateTo(0);
    }
    // eslint-disable-next-line
  }, [showSpinner]);

  const handleOnClickPlayerTurn = async (e) => {
    e.stopPropagation();
    onSpinnerStart();

    const promise = playAudio(spinnerSound);
    const spinTo = Math.floor(Math.random() * MAX_SPIN) + 1;
    const numberOfMoves = currentPlayer.currentCardIndex + spinTo > numberOfgameCards - 1 ? numberOfgameCards - currentPlayer.currentCardIndex - 1 : spinTo;
    ObjectUtils.setTimeout(() => {
      spinnerArrowRefEl.current.classList.add('rotate-full');
      spinnerArrowRefEl.current.style.transform = `rotate(${(ROTATE_ROUND * 360) + (((numberOfMoves - 1) * 90) + 45)}deg)`;
    }, 0);
    ObjectUtils.setTimeout(() => {
      setRotateTo(numberOfMoves);
    }, 1500);
    try {
      await promise;
      onSpinnerEnd(numberOfMoves);
    } catch (ex) {
      // ignore
      onSpinnerEnd(numberOfMoves);
    }
  };

  const playerTurnRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => (playerTurnRef.current ? [playerTurnRef.current.getEnableElement()] : []),
  }));

  const spinnerWheel = () => (
    <Fade timeout={1000} in>
      <Box className='spinning-board' data-test='spinning-board'>
        <img
          src={GameActivityUtils.getSpinnerBoard(spinner)}
          alt='Spinning Board'
        />
        <img
          className={`spinner-img rotate-${rotateTo}`}
          src={spinnerArrow}
          alt='Spinning Arrow'
          ref={spinnerArrowRefEl}
        />
        {
          rotateTo > 0 ? (
            <img
              className='board-outline'
              src={OUTLINES[rotateTo - 1]}
              alt='Spinning Board Outline'
            />
          ) : ''
        }
      </Box>
    </Fade>
  );

  return (
    <div className='game-play-wheel-spinner' data-test='game-play-wheel-spinner'>
      {
        showSpinner || !currentPlayer.playerPieceImage ? spinnerWheel() : (
          <PlayerTurn
            currentPlayer={currentPlayer}
            handleOnClickPlayerTurn={handleOnClickPlayerTurn}
            numberOfgameCards={numberOfgameCards}
            ref={playerTurnRef}
            background={spinnerWheel()}
          />
        )
      }
    </div>
  );
});

WheelSpinner.defaultProps = {
  playerState: {},
  spinner: SPINNER_BOARD_NUMBER,
  numberOfgameCards: 20,
  onSpinnerStart: () => { },
  onSpinnerEnd: () => { },
  showSpinner: false,
};

WheelSpinner.propTypes = {
  playerState: PropTypes.shape({
    currentPlayer: PropTypes.number,
    players: PropTypes.array,
  }),
  spinner: PropTypes.number,
  numberOfgameCards: PropTypes.number,
  onSpinnerStart: PropTypes.func,
  onSpinnerEnd: PropTypes.func,
  showSpinner: PropTypes.bool,
};

export default WheelSpinner;

import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import MatchingLayoutSelector from '../../../../../components/vizzle/page/matching/MatchingLayoutSelector';
import ChooseNumberOfCards from '../media/editor/matching/ChooseNumberOfCards';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';

/**
 * Container for Matching layout
 */
export default function MatchingLayout() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const handleOnSliderChange = (event, value) => {
    event.stopPropagation();
    const currentResponseCards = [...selectedPage.responseCards];
    const currentNumberOfResponseCards = selectedPage.responseCards.filter((card) => (
      !card.hide
    )).length;
    if (currentNumberOfResponseCards > value) {
      for (let i = value; i < currentNumberOfResponseCards; i++) {
        currentResponseCards[i].hide = true;
      }
    } else if (currentNumberOfResponseCards < value) {
      for (let i = 0; i < value; i++) {
        if (!currentResponseCards[i]) {
          currentResponseCards[i] = {};
        }
        currentResponseCards[i].hide = false;
      }
    }

    const newPageData = {
      ...selectedPage,
      responseCards: currentResponseCards,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
  };

  const props = {
    config: [
      {
        component: (
          <MatchingLayoutSelector
            responseCards={selectedPage ? selectedPage.responseCards.filter((card) => (
              !card.hide
            )) : null}
          />),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '42vw' : '32vw',
    height: '48vh',
  };

  return (
    <Box>
      <br />
      <Box display='flex' mt='10px'>
        <br />
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-matching-layout'
        />

        <Box width='30vw' my='auto' ml='15px' data-test='number-of-cards-slider'>
          <ChooseNumberOfCards
            onSliderChange={handleOnSliderChange}
            value={
              selectedPage && selectedPage.responseCards ? selectedPage.responseCards.filter((card) => (
                !card.hide
              )).length : null
            }
          />
        </Box>
      </Box>
    </Box>
  );
}

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import ClearLessonSelectionAction from '../../../../lessons/lessonactions/lesson-multi-selection-actions/ClearLessonSelectionAction';
import './StudentLessonsRowActions.scss';
import { findMtssLesson, useGetAllLessonsFromProfile } from '../StudentLessonsHooks';

const StudentLessonsRowActions = ({
  onClearClick,
  selectedLessonIds,
  lessonCardDetailRef,
}) => {
  const { myStudentDomain } = useStudentContext();
  const allLessonsFromProfile = useGetAllLessonsFromProfile();
  return (
    <div className='student-lessons-row-actions'>
      <Button
        variant='contained'
        className='btn-primary select-all'
        onClick={() => {
          const currentDisplayData = lessonCardDetailRef && lessonCardDetailRef.current.getCurrentDisplayData();
          const selectedIds = currentDisplayData[0]
            .lessons
            .filter((lesson) => {
              const matchedLesson = findMtssLesson(lesson, allLessonsFromProfile);
              return !matchedLesson;
            })
            .map((l) => l.id);
          myStudentDomain.updateSelectedLessonIds(selectedIds);
        }}
        data-test='student-lessons-row-actions-select-all'
      >
        SELECT ALL
      </Button>
      <ClearLessonSelectionAction
        className='clear-selection'
        onClearClick={onClearClick}
        selectedLessonIds={selectedLessonIds}
      />
    </div>
  );
};

StudentLessonsRowActions.defaultProps = {
  onClearClick: () => { },
  selectedLessonIds: [],
  lessonCardDetailRef: {},
};

StudentLessonsRowActions.propTypes = {
  onClearClick: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  lessonCardDetailRef: PropTypes.any,
};

export default StudentLessonsRowActions;

import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import { getPageStyle } from '../../../../../components/vizzle/page/common/LessonPageUtil';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './SortingActivityResponse.scss';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';
import { useDetermineClickOrTouchEventForDnd } from '../../../../hooks/DndClickOrTouchHooks';

const SortingActivityResponse = forwardRef(({
  responseCard,
  responseCardId,
  disablePlayMedia,
  onClick,
  onResponseSwitchAccessSelected,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const responseRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => responseRef.current,
  }));

  useRegisterSwitchAccessSelectEvent(responseRef, () => {
    onResponseSwitchAccessSelected();
  });

  const handleEvent = useDetermineClickOrTouchEventForDnd(
    (e) => {
      if (onClick) {
        onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
      }
    },
    true,
  );

  return (
    <Box
      key={responseCardId}
      id={responseCardId}
      boxShadow={2}
      className='sorting-activity-response-card'
      style={getPageStyle(responseCard, true)}
      ref={responseRef}
      data-skip-switch-access-click='true'
      {...handleEvent}
      data-test='sorting-activity-response-card'
    >
      {disablePlayMedia ? '' : (
        <MediaPlayer
          audioUrl={responseCard.audio}
          videoUrl={responseCard.video}
          parentElementId={responseCardId}
          className='response-media'
          textToRead={elementToPlayTts}
          tts={ttsConfig}
          forDndElement
        />
      )}
      {responseCard.image ? <CardMedia image={responseCard.image} className='response-image' data-test='response-image' /> : ''}

      {responseCard.text ? (
        <div className='response-text' data-test='response-text'>
          <TextLines
            componentKey={`text-line-${responseCardId}`}
            textLines={responseCard.text}
            ref={setTextLineRef}
          />
        </div>
      ) : ''}
    </Box>
  );
});

export default SortingActivityResponse;

SortingActivityResponse.defaultProps = {
  responseCard: {},
  responseCardId: '',
  disablePlayMedia: false,
  onClick: () => { },
  onResponseSwitchAccessSelected: () => { },
};

SortingActivityResponse.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  responseCardId: PropTypes.string,
  disablePlayMedia: PropTypes.bool,
  onClick: PropTypes.func,
  onResponseSwitchAccessSelected: PropTypes.func,
};

import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import Settings from './settings/Settings';
import './MySettings.scss';

const MySettings = () => (
  <VizzleMainContainer className='main-with-background-container flex-box'>
    <div className='my-settings'>
      <TeacherTopAppbar />
      <div className='container'>
        <div className='my-settings-container'>
          <Settings />
        </div>
      </div>
    </div>
  </VizzleMainContainer>
);
export default MySettings;

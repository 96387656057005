import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import VideoCamIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TextLines from '../../../text/TextLines';
import './CardEditor.scss';
import { SECTION_AUDIO, SECTION_IMAGE, SECTION_NONE, SECTION_TEXT } from '../CardEditorConstants';

/**
 * Lesson activity for Book
 */
export default function CardEditor({
  config,
  children,
  className,
  style,
  isSelected,
  selectedSection,
  onImageSelected,
  onTextSelected,
  onAudioSelected,
  isImageIncluded,
  isAudioIncluded,
  isVideoIncluded,
  disableAudio,
  textButtonLabel,
  ...props
}) {
  return (
    <div data-test='card-editor' {...props} className={`card-editor-component card-editor ${className}`} style={style}>
      <Card
        className={`
                card-editor
                ${isSelected ? 'card-editor-selected' : ''}
              `}
      >
        {children}

        {isImageIncluded && (
          <CardActionArea
            className={`
                  card-editor-image-action 
                  ${selectedSection === SECTION_IMAGE ? 'card-editor-section-selected' : ''} 
                  `}
            onClick={() => { onImageSelected(SECTION_IMAGE); }}
            data-test='image-selector'
          >
            {
              config.image ? (
                <CardMedia
                  className='card-editor-image'
                  image={config.image}
                  title='Card Editor'
                >
                  {
                    config.video ? <VideoCamIcon className='card-editor-with-video-icon' /> : ''
                  }

                </CardMedia>
              )
                : (
                  <Box
                    component='div'
                    className='card-editor-placeholder'
                  >
                    Image{isVideoIncluded ? '/Video' : ''}
                  </Box>
                )
            }
          </CardActionArea>
        )}
        <CardActionArea
          className={`
                  card-editor-text-action 
                  ${!isImageIncluded && !isAudioIncluded ? 'large' : ''} 
                  ${selectedSection === SECTION_TEXT ? 'card-editor-section-selected' : ''} 
                  `}
          onClick={() => { onTextSelected(SECTION_TEXT); }}
          data-test='text-selector'
        >
          {config.text
            ? (
              <Box className='card-editor-with-value'>
                <TextLines textLines={config.text} isThumbnail />
              </Box>
            ) : (
              <Box className='card-editor-placeholder'>{textButtonLabel}</Box>
            )}
        </CardActionArea>

        {isAudioIncluded && (
          <CardActionArea
            className={`
                  card-editor-audio-action 
                  ${selectedSection === SECTION_AUDIO ? 'card-editor-section-selected' : ''} 
                  `}
            onClick={() => { onAudioSelected(SECTION_AUDIO); }}
            data-test='audio-selector'
            disabled={disableAudio}
          >
            {config.audio
              ? (
                <Box className='card-editor-with-value card-editor-with-audio'>
                  <VolumeUpIcon className='card-editor-with-audio-icon' />
                  {/* <Box mx='auto' className='card-editor-audio-file'>{c.audio}</Box> */}
                </Box>
              ) : (
                <Box
                  component='div'
                  className='card-editor-placeholder'
                >
                  Audio
                </Box>
              )}
          </CardActionArea>
        )}
      </Card>
    </div>
  );
}

CardEditor.defaultProps = {
  config: {},
  children: null,
  className: '',
  style: null,
  selectedSection: SECTION_IMAGE,
  isSelected: false,
  onImageSelected: () => { },
  onTextSelected: () => { },
  onAudioSelected: () => { },
  isImageIncluded: true,
  isAudioIncluded: true,
  isVideoIncluded: true,
  textButtonLabel: 'Text',
  disableAudio: false,
};

CardEditor.propTypes = {
  config: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO, SECTION_NONE]),
  onImageSelected: PropTypes.func,
  onTextSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
  isImageIncluded: PropTypes.bool,
  isAudioIncluded: PropTypes.bool,
  isVideoIncluded: PropTypes.bool,
  textButtonLabel: PropTypes.string,
  disableAudio: PropTypes.bool,
};

import {
  SUBJECT_CONSTANTS,
  READING_SUBJECT,
  WRITING_SUBJECT,
} from '../../../../constants/LessonConstant';

export const SEARCH_BAR_DROPDOWN_VALUES = {
  subjects: [
    { key: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
    { key: SUBJECT_CONSTANTS.social.subjectId, value: SUBJECT_CONSTANTS.social.subjectId, name: SUBJECT_CONSTANTS.social.name },
    { key: SUBJECT_CONSTANTS.science.subjectId, value: SUBJECT_CONSTANTS.science.subjectId, name: SUBJECT_CONSTANTS.science.name },
    { key: READING_SUBJECT.subjectId, value: READING_SUBJECT.subjectId, name: READING_SUBJECT.name },
    { key: WRITING_SUBJECT.subjectId, value: WRITING_SUBJECT.subjectId, name: WRITING_SUBJECT.name, noUnits: true },
  ],
  grades: [
    { key: 'K', name: 'K', value: 'K' },
    { key: '1', name: '1', value: '1' },
    { key: '2', name: '2', value: '2' },
    { key: '3', name: '3', value: '3' },
    { key: '4', name: '4', value: '4' },
    { key: '5', name: '5', value: '5' },
    { key: '6', name: '6', value: '6' },
    { key: '7', name: '7', value: '7' },
    { key: '8', name: '8', value: '8' },
    { key: 'High School', name: 'High School', value: 'High School' },
  ],
  units: [
    { key: '1', name: '1 (First 9 Weeks)', value: '1' },
    { key: '2', name: '2 (Second 9 Weeks)', value: '2' },
    { key: '3', name: '3 (Third 9 Weeks)', value: '3' },
    { key: '4', name: '4 (Fourth 9 Weeks)', value: '4' },
  ],
};

export const SEARCH_BAR_DROPDOWN_VERTICAL_ALIGNMENT_VALUES = {
  subjects: [
    { key: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
    { key: SUBJECT_CONSTANTS.social.subjectId, value: SUBJECT_CONSTANTS.social.subjectId, name: SUBJECT_CONSTANTS.social.name },
    { key: SUBJECT_CONSTANTS.science.subjectId, value: SUBJECT_CONSTANTS.science.subjectId, name: SUBJECT_CONSTANTS.science.name },
    { key: SUBJECT_CONSTANTS.english.subjectId, value: SUBJECT_CONSTANTS.english.subjectId, name: SUBJECT_CONSTANTS.english.name },
  ],
};

export const SEARCH_BAR_DROPDOWN_VALUES_FLORIDA_TEACHER = {
  subjects: [
    { key: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
    { key: SUBJECT_CONSTANTS.english.subjectId, value: SUBJECT_CONSTANTS.english.subjectId, name: 'ELA' },
  ],
  grades: [
    { key: 'K', name: 'K', value: 'K' },
    { key: '1', name: '1', value: '1' },
    { key: '2', name: '2', value: '2' },
    { key: '3', name: '3', value: '3' },
    { key: '4', name: '4', value: '4' },
    { key: '5', name: '5', value: '5' },
    { key: '6-8', name: '6-8', value: '6-8', customUnit: 'unitsFor6To8Grade' },
    { key: 'High School', name: 'High School', value: 'High School' },
  ],
  units: [
    { key: '1', value: '1', name: 'August' },
    { key: '2', value: '2', name: 'September' },
    { key: '3', value: '3', name: 'October' },
    { key: '4', value: '4', name: 'November/December' },
    { key: '5', value: '5', name: 'January/February' },
    { key: '6', value: '6', name: 'February/March' },
    { key: '7', value: '7', name: 'April' },
    { key: '8', value: '8', name: 'May' },
  ],
};

export const CUSTOM_UNITS = {
  unitsFor6To8Grade: [
    { key: '1', value: '1', name: '1st Quarter' },
    { key: '2', value: '2', name: '2nd Quarter' },
    { key: '3', value: '3', name: '3rd Quarter' },
    { key: '4', value: '4', name: '4th Quarter' },
  ],
};

export const standardLessonLevels = [
  { key: 0, name: 'All', value: null },
  { key: 1, name: 'Level 1', value: '1' },
  { key: 2, name: 'Level 2', value: '2' },
  { key: 3, name: 'Level 3', value: '3' },
];

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import './DistrictForm.scss';
import { US_STATES } from '../../../../../../constants/StateConstant';
import { EMAIL_REGEXP } from '../../../../../../constants/Regex';
import DropdownInput from '../../../../../../components/selector/DropdownInput';
import { useDomain } from '../../../../../../states/AppDomainProvider';

export const validateDistrictData = (data) => data
  && data.name
  && data.state
  && data.accountManager
  && EMAIL_REGEXP.test(data.accountManager)
  && ((!data.paidAccount) || (data.paidAccount && data.churnzeroId));

const ACCOUNT_TYPE_OPTIONS = [{
  id: 'PAID',
  name: 'PAID',
},
{
  id: 'TRIAL',
  name: 'TRIAL',
},
{
  id: 'DEMO',
  name: 'DEMO',
},
{
  id: 'PILOT',
  name: 'PILOT',
},
];

const DistrictForm = ({
  districtData,
  onDataChange,
  readOnly,
}) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  const [internalData, setInternalData] = useState({
    ...districtData,
  });

  const [emailHelperText, setEmailHelperText] = useState(false);

  const handleOnChange = (e) => {
    const updatedData = {
      ...internalData,
      [e.target.name]: e.target.value,
    };
    setInternalData(updatedData);

    const isDataValid = validateDistrictData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  const handleOnCheckboxChange = (e) => {
    if (readOnly) {
      return;
    }
    const updatedData = {
      ...internalData,
      [e.target.name]: e.target.checked,
    };
    setInternalData(updatedData);

    const isDataValid = validateDistrictData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const result = EMAIL_REGEXP.test(value);
    if (result) {
      setEmailHelperText(false);
    } else {
      setEmailHelperText(true);
    }

    const updatedData = {
      ...internalData,
      [e.target.name]: e.target.value,
    };
    setInternalData(updatedData);
    const isDataValid = validateDistrictData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  return (
    <div className='admin-district-form'>
      <TextField
        label='Name'
        value={internalData.name || ''}
        name='name'
        onChange={handleOnChange}
        inputProps={{
          maxLength: 255,
          readOnly,
        }}
        variant='standard'
      />
      <FormControl variant='outlined'>
        <Select
          name='state'
          value={internalData && internalData.state ? internalData.state : 'none'}
          onChange={handleOnChange}
          inputProps={{
            readOnly,
          }}
        >
          <MenuItem key='none' value='none' disabled>
            State
          </MenuItem>
          {
            US_STATES.map((state) => (
              <MenuItem key={state.value} value={state.value}>[{state.value}] {state.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <DropdownInput
        dropdownValues={ACCOUNT_TYPE_OPTIONS}
        name='accountType'
        value={internalData.accountType}
        onChange={handleOnChange}
        inlineLabel='Account Type'
        inputProps={{
          readOnly,
        }}
      />

      <TextField
        label='Account Manager'
        value={internalData.accountManager || ''}
        name='accountManager'
        onChange={handleEmailChange}
        error={emailHelperText}
        helperText={emailHelperText && 'Invalid email format'}
        inputProps={{
          maxLength: 255,
          readOnly,
        }}
      />
      {userSuperAdmin && (
        <TextField
          label='Churn Zero ID'
          value={internalData.churnzeroId || ''}
          name='churnzeroId'
          onChange={handleOnChange}
          inputProps={{
            maxLength: 255,
            readOnly,
          }}
        />
      )}
      <div className='checkbox-container'>
        <FormControlLabel
          control={
            (
              <Checkbox
                checked={!internalData && internalData.paidAccount ? internalData.paidAccount : false}
                onChange={handleOnCheckboxChange}
                name='paidAccount'
                color='primary'
              />
            )
          }
          label='Paid Account'
        />
        {(userSuperAdmin) && (
          <>
            <FormControlLabel
              control={
                (
                  <Checkbox
                    checked={internalData && internalData.active ? internalData.active : false}
                    onChange={handleOnCheckboxChange}
                    name='active'
                    color='primary'
                  />
                )
              }
              label='Active'
            />

            <FormControlLabel
              control={
                (
                  <Checkbox
                    checked={internalData && internalData.createStudent ? internalData.createStudent : false}
                    onChange={handleOnCheckboxChange}
                    name='createStudent'
                    color='primary'
                  />
                )
              }
              label='Create Student'
            />

            <FormControlLabel
              control={
                (
                  <Checkbox
                    checked={internalData && internalData.leadTeacher ? internalData.leadTeacher : false}
                    onChange={handleOnCheckboxChange}
                    name='leadTeacher'
                    color='primary'
                  />
                )
              }
              label='Lead Teacher'
            />
          </>
        )}
      </div>
    </div>
  );
};

DistrictForm.defaultProps = {
  districtData: {},
  onDataChange: () => null,
  readOnly: false,
};

DistrictForm.propTypes = {
  districtData: PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.string,
    accountManager: PropTypes.string,
  }),
  onDataChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default DistrictForm;

import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
} from '@mui/material';
import LessonPlay from '../../../lessonplay/LessonPlay';
import './LessonPlayMenuItem.scss';

const LessonPlayMenuItem = forwardRef(({
  onClick,
  lesson,
  recordDataTo,
  user,
  userProfile,
  terminologies,
}, ref) => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    onClick();
    setOpen(true);
  };

  return (
    <>
      <MenuItem className='lesson-play-menu-item' onClick={handleOnClick} ref={ref}>
        <div className='lesson-play-label'>
          Play
          {recordDataTo && (
            <div className='record-data-to'>
              Will record data to <br />
              {recordDataTo}
            </div>
          )}
        </div>
      </MenuItem>
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
        />
      )}
    </>
  );
});

LessonPlayMenuItem.defaultProps = {
  lesson: {},
  onClick: () => { },
  recordDataTo: null,
  user: null,
  userProfile: null,
  terminologies: {},
};
LessonPlayMenuItem.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }),
  onClick: PropTypes.func,
  recordDataTo: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
};

export default LessonPlayMenuItem;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './DeleteSelectedAssignmentsAction.scss';
import ConfirmationDialog from '../../../../../components/dialog/ConfirmationDialog';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { deleteSelectedLessonAssignment } from '../../../../../services/CourseService';

/**
 * Lock Lesson Action Container
 */
export default function DeleteSelectedAssignmentsAction({
  selectedLessonIds,
  className,
  student,
  subjectId,
  onDeleteSuccess,
}) {
  const [openDialog, setOpenDialog] = useState();
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const handleOnConfirm = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await deleteSelectedLessonAssignment(student.id, subjectId, selectedLessonIds);
        await onDeleteSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Activity deleted for ${student.firstName} ${student.lastName}`);
        setOpenDialog(false);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return (
    <>
      <Tooltip title='Delete Selected Assignment' aria-label='Delete Selected Assignment'>
        <div>
          <Button
            onClick={() => { setOpenDialog(true); }}
            className={`btn-danger delete-selected-assignments-action ${className}`}
            size='small'
            variant='contained'
            disabled={!selectedLessonIds || selectedLessonIds.length === 0}
            data-test='delete-selected-assignment'
          >
            <DeleteIcon />
          </Button>
        </div>
      </Tooltip>
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title='Delete Selected Assignments'
          contentText={`Are you sure want to delete all assignments for the selected ${selectedLessonIds.length > 1 ? 'activities' : 'activity'} for ${student.firstName} ${student.lastName}?`}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={handleOnConfirm}
          onCancel={() => { setOpenDialog(false); }}
        />
      ) : null}
    </>
  );
}

DeleteSelectedAssignmentsAction.defaultProps = {
  selectedLessonIds: [],
  className: '',
  onDeleteSuccess: () => null,
};

DeleteSelectedAssignmentsAction.propTypes = {
  selectedLessonIds: PropTypes.array,
  className: PropTypes.string,
  subjectId: PropTypes.number.isRequired,
  student: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onDeleteSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import AssignLessonActionWithDateSelectionV2 from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/AssignLessonActionWithDateSelectionV2';
import { useTeacherCurriculum } from '../../../context/TeacherCurriculumContextProvider';
import {
  useReloadLessonsByUuids,
} from '../CurriculumLessonsHooks';

export default function TeacherCurriculumAssignLessonsAction({
  lessons,
  selectedLessonIds,
  isAssignAutoTag,
}) {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { reloadLessonsByUuids } = useReloadLessonsByUuids(teacherCurriculumDomain);
  const { lessonData } = teacherCurriculumDomain.domainData;
  const tags = isAssignAutoTag ? lessonData && lessonData.meta.tags : null;

  return (
    <AssignLessonActionWithDateSelectionV2
      lessons={lessons}
      selectedLessonIds={selectedLessonIds}
      buttonLabel='Assign'
      showAddToMyList
      reloadDataFunc={async () => {
        const lessonsToReload = lessons.filter((l) => selectedLessonIds.includes(l.id));
        await reloadLessonsByUuids(lessonsToReload);
      }}
      tagNameForAutoSaving={tags}
      objectiveType='CURRICULUM'
    />
  );
}

TeacherCurriculumAssignLessonsAction.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  isAssignAutoTag: true,
};

TeacherCurriculumAssignLessonsAction.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  isAssignAutoTag: PropTypes.bool,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InstructionDialog from '../common/instruction/InstructionDialog';
import { useOpenInstructionDialog } from '../common/instruction/InstructionHooks';
import SortingPrompt from './subcomponents/SortingPrompt';
import SortingResponse from './subcomponents/SortingResponse';
import SelectedResponseCards from './subcomponents/SelectedResponseCards';
import './Sorting.scss';
import {
  showDropRejection,
  clearSelectorValue,
  dragAndDropSensor,
  handleOnResponseCardClick,
  handleOnPromptResponseCardAreaSelected,
  getCurrentDroppableId,
} from './SortingUtils';

import RejectedAnswer from '../common/RejectedAnswer';

export default function Sorting({
  pageIndex,
  currentIndex,
  instructions,
  promptCards,
  responseCards,
}) {
  const [selectedResponseCards, setSelectedResponseCards] = useState([]);

  const {
    openInstructions,
    handleOnInstructionClosed,
  } = useOpenInstructionDialog(instructions, currentIndex === pageIndex, clearSelectorValue);

  const getRejectedResponseCardId = (cardIndex) => (
    `sorting-component-response-reject-${pageIndex}-${cardIndex}`
  );

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      return;
    }
    const sourceIndex = source.index;
    const destinationDroppingId = getCurrentDroppableId() || destination.droppableId;
    const destinationIndexStr = destinationDroppingId.substring(destinationDroppingId.lastIndexOf('-') + 1);
    // 10 -> decimal number
    const destinationIndex = parseInt(destinationIndexStr, 10);
    if (promptCards[destinationIndex].key === responseCards[sourceIndex].key) {
      setSelectedResponseCards([
        ...selectedResponseCards,
        sourceIndex,
      ]);
      clearSelectorValue();
    } else {
      const rejectComponentId = getRejectedResponseCardId(sourceIndex);
      showDropRejection(rejectComponentId, destinationDroppingId);
    }

    document.getElementById(`sorting-component-${pageIndex}`).focus();
  };

  return (
    <>
      <InstructionDialog
        open={openInstructions.open}
        instructions={instructions}
        onClose={handleOnInstructionClosed}
      />
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        sensors={[dragAndDropSensor]}
      >
        <div className='sorting-component' id={`sorting-component-${pageIndex}`}>
          <Droppable
            droppableId={`sorting-component-main-droppable-container-${pageIndex}`}
            direction='horizontal'
            isDropDisabled
          >
            {(sortingProvided) => (
              <div
                ref={sortingProvided.innerRef}
                {...sortingProvided.droppableProps}
                className='sorting-prompt-draggable'
              >
                <div className='sorting-prompt-area'>
                  {promptCards.map((promptCard, index) => {
                    const promptId = `sorting-prompt-${pageIndex}-${index}`;
                    const drappableKeyId = `sorting-component-droppable-container-${pageIndex}-${index}`;
                    return (
                      <Droppable
                        key={drappableKeyId}
                        droppableId={drappableKeyId}
                        direction='horizontal'
                      >
                        {(provided, snapshot) => (
                          <SortingPrompt
                            promptCardId={promptId}
                            promptCard={promptCard}
                            isRightAlignLayout={index % 2 === 1}
                            onResponseCardsAreaClick={(e) => {
                              handleOnPromptResponseCardAreaSelected(e, drappableKeyId);
                            }}
                          >
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className={`
                                selected-response-cards 
                                ${snapshot.isDraggingOver ? 'sorting-selected-component' : 'sorting-component-response-card-non-drag-items'}
                                `}
                            >
                              <SelectedResponseCards
                                pageIndex={pageIndex}
                                promptKey={promptCard.key}
                                selectedResponseCards={selectedResponseCards}
                                responseCards={responseCards}
                              />
                              <div className='sorting-component-prompt-reject' id={drappableKeyId} />
                            </div>
                            {provided.placeholder}
                          </SortingPrompt>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
                <div className='sorting-response-area-container'>
                  <div className='sorting-response-cards'>
                    {
                      responseCards.map((responseCard, index) => {
                        const cardId = `${pageIndex}-${index}`;
                        const responseKeyId = `sorting-response-key-${cardId}`;
                        const responseId = `sorting-response-${cardId}`;
                        const draggableId = `sorting-drag-item-${cardId}`;
                        return (
                          <div key={responseKeyId} className={`response-card-place-holder response-margin-${responseCards.length}-responses`}>
                            {selectedResponseCards.includes(index) ? '' : (
                              <Draggable
                                key={draggableId}
                                draggableId={draggableId}
                                index={index}
                                isDragDisabled={selectedResponseCards.includes(index)}
                              >
                                {(providedItem, snapshotItem) => (
                                  <div
                                    ref={providedItem.innerRef}
                                    {...providedItem.draggableProps}
                                    {...providedItem.dragHandleProps}
                                    id={`sorting-response-item-${cardId}`}
                                    key={`sorting-response-item-${cardId}`}
                                    className={`sorting-response-draggable 
                                  ${snapshotItem.isDragging ? 'sorting-selected-component' : 'sorting-component-response-card-non-drag-items'}
                                `}
                                  >
                                    <SortingResponse
                                      responseCard={responseCard}
                                      responseCardId={responseId}
                                      onClick={(e) => {
                                        handleOnResponseCardClick(e, responseId, draggableId);
                                      }}
                                    />
                                    <div className='sorting-component-response-reject' id={getRejectedResponseCardId(index)}>
                                      <RejectedAnswer noBorder />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {sortingProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
}

Sorting.defaultProps = {
  instructions: {},
  promptCards: [],
  responseCards: [],
};

Sorting.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
};

import {
  Box,
  Typography,
} from '@mui/material';
import BookTextAndMedia from './media/BookTextAndMedia';
import MatchingTextAndMedia from './media/MatchingTextAndMedia';
import SortingTextAndMedia from './media/SortingTextAndMedia';
import GameTextAndMedia from './media/GameTextAndMedia';
import SequencingTextAndMedia from './media/SequencingTextAndMedia';
import EditableLessonTitleContainer from './EditableLessonTitleContainer';
import ReviewerNotes from '../actions/reviewer/ReviewerNotes';
import { useDomain } from '../../../../states/AppDomainProvider';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../../AppConstants';
import CauseAndEffectTextAndMedia from './media/CauseAndEffectTextAndMedia';
import FillInTheBlankPageTextAndMedia from './media/FillInTheBlankPageTextAndMedia';
import CategorizingTextAndMedia from './media/CategorizingTextAndMedia';
import FlexibleMatchingTextAndMedia from './media/FlexibleMatchingTextAndMedia';
import MultipleChoiceTextAndMedia from './media/MultipleChoiceTextAndMedia';
import MultipleChoiceV2TextAndMedia from './media/MultipleChoiceV2TextAndMedia';

export default function LessonTextAndMedia() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;

  const selectedPageData = pagesData.pages[currentSelectedPagesData];
  const activityType = selectedPageData ? selectedPageData.activityType : null;

  const getComponentByActivityType = () => {
    switch (activityType) {
      case ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA:
        return <BookTextAndMedia />;
      case ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA:
        return <BookTextAndMedia />;
      case ACTIVITY_TYPE_MATCHING_ACTIVITY:
        return <MatchingTextAndMedia />;
      case ACTIVITY_TYPE_SORTING_ACTIVITY:
        return <SortingTextAndMedia />;
      case ACTIVITY_TYPE_GAME_ACTIVITY:
        return <GameTextAndMedia />;
      case ACTIVITY_TYPE_SEQUENCING_ACTIVITY:
        return <SequencingTextAndMedia />;
      case ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY:
        return <CauseAndEffectTextAndMedia />;
      case ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE:
        return <FillInTheBlankPageTextAndMedia />;
      case ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING:
        return <FillInTheBlankPageTextAndMedia />;
      case ACTIVITY_TYPE_CATEGORIZING_ACTIVITY:
        return <CategorizingTextAndMedia />;
      case ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY:
        return <FlexibleMatchingTextAndMedia />;
      case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY:
        return <MultipleChoiceTextAndMedia />;
      case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2:
        return <MultipleChoiceV2TextAndMedia />;
      default:
        return (
          <Typography variant='h6'>
            Please select a layout type.
          </Typography>
        );
    }
  };

  return (
    <div>
      <Box display='flex' pt='30px'>
        <EditableLessonTitleContainer />
        <Box mt='17px' ml='10px'>
          <ReviewerNotes />
        </Box>
      </Box>
      <br />

      {getComponentByActivityType()}
    </div>
  );
}

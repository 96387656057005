import { useEffect } from 'react';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { getLessonPagesData } from '../../../services/LessonService';
import { getActivityCardPreviewDataByActivityType } from '../../../utils/activitytype/ActivityTypesUtil';
import { useLessonPlay, initDomains } from '../context/LessonPlayContextProvider';
import Logger from '../../../utils/Logger';
import { flatten, uniqueObject } from '../../../utils/ArrayUtils';
import { TTS_DEFAULT_VALUE } from '../../../services/tts/TextToSpeechService';
import { getTerminologiesWithNoUser } from '../../../services/UserProfileService';
import { isStudent } from '../../../utils/User';
import { UNDER_REVISION_ID } from '../../mystudent/components/tracking/actions/LessonTrackingConstants';
import { getAnswerFreeForm, getLessonPlaySnapshot, getLessonPlayStatus } from '../../../services/LessonPlayService';
import { CELEBRATION_STYLE_KEY, REINFORCER_STYLE_KEY } from '../../../constants/PlayPreferenceConstant';
// import { useGetFeedbackChat } from '../components/lesson-activity-play/LessonPlayFeedbackChatHooks';

const loadImage = (src) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => {
    resolve();
  };
  image.onerror = () => {
    reject();
  };
  image.src = src;
});

export const preloadImages = async (pageData) => {
  // preload the images
  if (pageData.pages && pageData.pages.length > 0) {
    const imagePromises = [];

    const images = flatten(pageData.pages.map((page) => (page.images)) || []) || [];
    const imagesNotNull = images.filter((image) => (
      image !== null && image !== undefined
    ));
    const uniqueImages = uniqueObject(imagesNotNull);
    uniqueImages.forEach((image) => {
      imagePromises.push(loadImage(image));
    });
    try {
      await Promise.all(imagePromises);
    } catch (_e) {
      // ignore error
    }
  }
};

const defaultUserProfile = {
  celebrations: [],
  reinforcers: [],
  switchAccessibility: 0,
  tts: {
    enabled: true,
    ...TTS_DEFAULT_VALUE,
  },
  interactionType: 2,
  reinforcerStyle: REINFORCER_STYLE_KEY.animated,
  celebrationStyle: CELEBRATION_STYLE_KEY.interactive,
  reinforcerSound: true,
  celebrationSound: true,
  playerWorld: undefined,
  prompt: true,
  maxMatchingChoices: 6,
};

const sortByPageNumber = (arr) => (
  arr.sort((a, b) => a.attributes.pageNumber.toString().localeCompare(b.attributes.pageNumber))
);

export const usePrepareLessonData = ({
  lessonUuid,
  user,
  userProfile,
  terminologies,
  isPreviewMode,
  isAuth,
  initialPageIndex,
  isPlayable,
}) => {
  initDomains();
  const { lessonPlayDomain } = useLessonPlay();

  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper(true);

  // const {
  //   getFeedbackChat,
  // } = useGetFeedbackChat();

  useEffect(() => {
    if (isAuth) {
      return;
    }
    const getLessonData = async () => {
      try {
        await callRemoteServiceWrapper(async () => {
          let userProfileToUse = JSON.parse(JSON.stringify(userProfile));
          if (!userProfile.id) {
            userProfileToUse = { ...defaultUserProfile };
          }

          let initialLessonPlayId = null;
          let initialPlayHistoryId = null;
          let lastPageCompleted = 0;
          let playedLesson = null;
          let singleAnswer = false;
          let isError = false;

          // const promises = [];
          if (isStudent(user)) {
            for (let i = 0; i < userProfileToUse.subjects.length; i++) {
              const s = userProfileToUse.subjects[i];
              playedLesson = s.lessons.find((l) => l.uuid === lessonUuid);
              if (playedLesson) {
                break;
              }
            }
            if (playedLesson) {
              singleAnswer = playedLesson.singleAnswer;
              // getFeedbackChat({
              //   showChatWidget: userProfileToUse.feedbackChat,
              //   studentId: user.id,
              //   lessonId: playedLesson.id,
              //   hideWidgetWhenNoFeedbackChat: false,
              // });

              const lessonPlayStatus = await getLessonPlayStatus(playedLesson.id);
              if (lessonPlayStatus && lessonPlayStatus.currentHistoryId && lessonPlayStatus.currentLessonPlayId) {
                initialPlayHistoryId = lessonPlayStatus.currentHistoryId;
                initialLessonPlayId = lessonPlayStatus.currentLessonPlayId;
                lastPageCompleted = lessonPlayStatus.lastPageCompleted;
              }

              // Disable reinforcers and Red X for assessments
              if (playedLesson.assessment) {
                userProfileToUse.reinforcers = [];
                userProfileToUse.prompt = false;
                userProfileToUse.reinforcerStyle = REINFORCER_STYLE_KEY.none;
              }
            }
          }

          let lessonPageData = null;
          if (initialPlayHistoryId && initialLessonPlayId) {
            try {
              lessonPageData = await getLessonPlaySnapshot({ studentHistoryId: initialPlayHistoryId });
              if (lastPageCompleted >= lessonPageData.pages.length) {
                lastPageCompleted = lessonPageData.pages.length - 1;
              }
            } catch (e) {
              // There is an error while getting the snapshot data
              // It needs it to act like there’s no currentStudentHistoryId available
              Logger.logError(e);
              // initialPlayHistoryId = null;
              // initialLessonPlayId = null;
              // lastPageCompleted = 0;
              isError = true;
              lessonPageData = await getLessonPagesData(lessonUuid, userProfileToUse.maxMatchingChoices, userProfileToUse.switchAccessibility);
            }
          } else {
            lessonPageData = await getLessonPagesData(lessonUuid, userProfileToUse.maxMatchingChoices, userProfileToUse.switchAccessibility);
          }

          let terminologiesToUse = terminologies;

          if (!userProfile.id) {
            terminologiesToUse = await getTerminologiesWithNoUser();
            const { reinforcers: reinforcerList, celebrations: celebrationList } = terminologiesToUse;
            const reinforcers = [];
            reinforcerList.forEach((r) => {
              if (r.id) {
                reinforcers.push(r.code);
              }
            });
            userProfileToUse.reinforcers = reinforcers;

            const celebrations = celebrationList.map((_c, index) => index + 1);
            userProfileToUse.celebrations = celebrations;
          }

          let freeformAnswerData = [];
          let currentPageIndex = initialPageIndex || lastPageCompleted || 0;
          const activityCardPreviewDataByActivityType = getActivityCardPreviewDataByActivityType(lessonPageData, true);

          if (isStudent(user)) {
            const allLessons = userProfile.subjects.map((s) => s.lessons);
            const flattenAllLessons = flatten(allLessons);
            const selectedLesson = flattenAllLessons.find((l) => l.uuid === lessonUuid);
            if (selectedLesson && selectedLesson.reviewStatus === UNDER_REVISION_ID) {
              userProfileToUse.celebrations = [];
              freeformAnswerData = await getAnswerFreeForm({ studentHistoryId: selectedLesson.previousStudentHistoryId });
              freeformAnswerData = freeformAnswerData.data;
              if (freeformAnswerData && freeformAnswerData.length > 0) {
                const sortedFreeformAnswerData = sortByPageNumber(freeformAnswerData);
                const teacherComment = sortedFreeformAnswerData.find((sffa) => {
                  if (sffa && sffa.attributes && sffa.attributes.comments) {
                    return true;
                  }
                  return false;
                });
                if (teacherComment) {
                  currentPageIndex = teacherComment.attributes.pageNumber - 1;
                }

                sortedFreeformAnswerData.forEach((sffa) => {
                  if (sffa && sffa.attributes && sffa.attributes.answer) {
                    const index = sffa.attributes.pageNumber - 1;
                    const cardIndex = sffa.attributes.cardNumber - 1;
                    activityCardPreviewDataByActivityType[index].layoutConfig[cardIndex].drawingImage = sffa.attributes.answer.image;
                    activityCardPreviewDataByActivityType[index].layoutConfig[cardIndex].isAnswerImage = true;
                    activityCardPreviewDataByActivityType[index].layoutConfig[cardIndex].text = sffa.attributes.answer.text;
                  }
                });
              }
            }
          }

          const lesson = {
            lessonId: lessonPageData.lessonId || (playedLesson && playedLesson.id),
            lessonUuid,
          };
          await lessonPlayDomain.updateLessonPagesData({
            lesson,
            lessonPagesData: activityCardPreviewDataByActivityType,
            user,
            userProfile: userProfileToUse,
            terminologies: terminologiesToUse,
            isPreviewMode,
            currentPageIndex,
            isPlayable: isPlayable === null || isPlayable === undefined ? true : isPlayable,
            lessonPagesDataSnapshot: lessonPageData,
            freeformAnswerData,
            initialLessonPlayId,
            initialPlayHistoryId,
            singleAnswer,
            isError,
          });
          await preloadImages(lessonPageData);
        });
      } catch (e) {
        Logger.logError(e);
      }
    };

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('start-lesson-play');
    }
    getLessonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
  };
};

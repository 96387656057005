import vizzleAxios from './service';
import ObjectUtils from '../utils/ObjectUtils';

export const getClassReport = async (criteria) => {
  const queryString = ObjectUtils.convertJsonToQueryString(criteria);
  const url = `/reporting/classReport?${queryString}`;

  const result = await vizzleAxios.get(url);
  return result.data.data;
};

export const getStudentReport = async (criteria) => {
  const queryString = ObjectUtils.convertJsonToQueryString(criteria);
  const url = `/reporting/studentLessonPlays?${queryString}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

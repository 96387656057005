import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog';
import ToggleForm from '../../components/toggle/ToggleForm';

const content = (
  <>
    <Box>
      When the JAWS® or other Screen Reader option is selected,
    </Box>
    <Box>
      Text to Speech and Switch/Keyboard Accessibility are also both enabled.
    </Box>
    <Box mt='10px'>
      Matching Activity can only be played in one-click mode when Switch/Keyboard Accessibility is turned on.
    </Box>
  </>
);
const ScreenReader = ({ profile, onConfirmCallback, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.screenReaderOn);
  const isScreenReaderEnabled = profile.screenReaderOn;
  // When mounting and changing a student.
  useEffect(() => {
    setToggle(isScreenReaderEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const [openDialog, setOpenDialog] = useState(false);
  const onConfirm = () => {
    onConfirmCallback();
    setOpenDialog(false);
  };

  const onCancel = () => {
    setToggle(false);
    setOpenDialog(false);
  };

  const dialogProps = {
    open: openDialog,
    title: 'JAWS® or other Screen Reader',
    contentText: content,
    confirmLabel: 'OK',
    cancelLabel: 'Cancel',
    onConfirm: async () => {
      await onConfirm();
    },
    onCancel: () => {
      onCancel();
    },
  };

  const handleScreenReaderOnchange = (field, newValue) => {
    if (newValue === null) return;
    if (newValue) {
      setToggle(newValue);
      setOpenDialog(newValue);
    } else {
      setToggle(newValue);
      setOpenDialog(newValue);
      onChangeCallback(newValue);
    }
  };

  return (
    <>
      <ToggleForm
        value={toggle}
        handleOnChange={handleScreenReaderOnchange}
        data-test='screen-reader-toggle'
      />
      <ConfirmationDialog
        open={dialogProps.open}
        title={dialogProps.title}
        contentText={dialogProps.contentText}
        confirmLabel={dialogProps.confirmLabel}
        cancelLabel={dialogProps.cancelLabel}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
        data-test='screen-reader-toggle-confirmation'
      />
    </>
  );
};

ScreenReader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onConfirmCallback: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default ScreenReader;

import { useEffect, useState, useImperativeHandle, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import UpdateButton from '../../../components/buttons/UpdateButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import LicenseForm, { validateLicenseData } from './forms/LicenseForm';
import { useUpdateLicenseData, usePrepareData } from './LicenseActionHooks';

const UpdateSchoolAction = forwardRef(({
  onLicenseUpdateSuccess,
}, ref) => {
  const [open, setOpen] = useState(false);

  const { loading, updateLicenseData } = useUpdateLicenseData({
    setOpen,
    onLicenseUpdateSuccess,
  });
  const { selectedLicenseIds, selectedLicenseData } = usePrepareData();
  const [isDataValid, setIsDataValid] = useState(false);
  const data = useRef(null);

  useEffect(() => {
    if (open) {
      setIsDataValid(validateLicenseData(selectedLicenseData));
      data.current = selectedLicenseData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useImperativeHandle(ref, () => ({
    openUpdateLicense: () => {
      setOpen(true);
    },
  }));

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleOnDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    data.current = updatedData;
  };

  const confirmActions = () => {
    const buttons = [
      {
        onClick: handleOnCancel,
        className: 'btn-danger margin-right',
        title: 'Cancel',
        loading,
      },
      {
        onClick: () => {
          updateLicenseData(data.current);
        },
        className: 'btn-primary action-button',
        title: 'Save',
        disabled: !isDataValid,
        loading,
      },
    ];
    return buttons;
  };

  return (
    <>
      <UpdateButton
        ariaLabel='Update License'
        onClick={() => {
          setOpen(true);
        }}
        disabled={selectedLicenseIds.length !== 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='update-license-dialog'
          onClose={handleOnCancel}
          title='Update License'
          content={(
            <LicenseForm
              licenseData={selectedLicenseData}
              onDataChange={handleOnDataChange}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
});

UpdateSchoolAction.defaultProps = {
  onLicenseUpdateSuccess: () => null,
};

UpdateSchoolAction.propTypes = {
  onLicenseUpdateSuccess: PropTypes.func,
};

export default UpdateSchoolAction;

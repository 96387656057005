import vizzleAxios from './service';

import {
  STAAR_ALTERNATE,
  LACO,
  FL_AP,
} from '../constants/LessonConstant';
import ObjectUtils from '../utils/ObjectUtils';
import Logger from '../utils/Logger';

/**
 * Get Authored lesson data of the teacher
 */
export const getAuthoredLessons = async (lessonSource) => {
  const timestamp = Date.now().toString();
  const url = `/users/authoredLessons?q=${timestamp}&type=${lessonSource}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

/**
 * Create a new lesson
 *
 * @param title lesson title
 * @param description description
 * @param standards lesson standards
 * @param appId the app id
 *              2 -> Matching
 *              3 -> Game
 *              4 -> Book
 *              13 -> Sorting
 */
export const createNewLesson = async (data) => {
  const url = '/lessons';
  const result = await vizzleAxios.post(url, data);
  return result;
};

/**
 * Update an existing lesson
 *
 * @param title title
 * @param description description
 * @param appId the app id
 *               2 -> Matching
 *              3 -> Game
 *              4 -> Book
 *              13 -> Sorting
 * @param standards lesson standards
 * @param lessonUuid lesson lessonUuid
 */
export const updateLesson = async (lessonUuid, data) => {
  const url = `/lessons/${lessonUuid}`;
  const result = await vizzleAxios.put(url, data);
  return result;
};

/**
 * Get lesson pages data by uuid
 *
 * @param lessonUuid lesson uuid
 *
 */
export const getLessonPagesData = async (lessonUuid, maxMatchingChoices = 0, switchAccess = 0) => {
  let url = `/lessondata/lesson/${lessonUuid}`;
  url = `${url}?maxMatchingChoices=${maxMatchingChoices}&switchAccess=${switchAccess}`;

  const result = await vizzleAxios.get(url);
  return result.data;
};

/**
 * Get lesson pages data by lesson id from the temp table
 *
 * @param lessonUuid lesson id
 *
 */
export const getLessonPageDataFromTempTable = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/newerTempVersion`;
  const result = await vizzleAxios.get(url);
  return result;
};

export const updateLessonToAws = async (lessonId, data) => {
  try {
    const url = `${process.env.REACT_APP_MEDIA_API_URL}/media/upload/lessondata`;
    await vizzleAxios.put(url, {
      ...data,
      lessonId,
    });
  } catch (e) {
    Logger.logWarn(e);
  }
};

/**
 * Permanently save the lesson page data
 *
 * @param lessonUuid lesson id
 * @param lessonPageData lesson data to save
 */
export const permanentlySaveLessonPageData = async (lessonUuid, lessonPageData, lessonId) => {
  if (lessonId) {
    await updateLessonToAws(lessonId, lessonPageData);
  }
  const url = `lessons/data/${lessonUuid}?type=perm`;
  const result = await vizzleAxios.put(url, lessonPageData);
  return result;
};

/**
 * Save the lesson page data to temp table
 *
 * @param lessonUuid lesson id
 * @param lessonPageData lesson data to save
 */
export const saveLessonPageDataToTempTable = async (lessonUuid, lessonPageData) => {
  const url = `lessons/data/${lessonUuid}?type=temp`;
  const result = await vizzleAxios.put(url, lessonPageData);
  return result;
};

/**
 * Submit a lesson to the vizzle library
 *
 * @param lessonUuid lesson id
 */
export const submitLessonToVizzleLibrary = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/library`;
  const result = await vizzleAxios.put(url);
  return result;
};

/**
 * update lesson status
 *
 * @param lessonUuid lesson id
 * @param data data to save
 */
export const updateLessonStatus = async (lessonUuid, data) => {
  const url = `lessons/${lessonUuid}/status`;
  const result = await vizzleAxios.put(url, data);
  return result;
};

/**
 * Get lesson details by lesson id
 * @param {*} lessonId
 */
export const getLessonByUuid = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}`;
  const result = await vizzleAxios.get(url);
  return result;
};

/**
 * Get lesson details by lesson id
 * @param {*} lessonId
 */
export const getLessonByUuids = async (lessonUuids) => {
  const url = '/lessons/details';
  const result = await vizzleAxios.post(url, {
    lessonUuids,
  });
  return result;
};

/**
 * Copy a new lesson from an existing lesson
 * @param {*} lessonTitle prefered lesson title
 * @param {*} lessonId id of the lesson to copy
 */
export const copyLesson = async (lessonTitle, lessonUuid, fromStudentId) => {
  let url = `/lessons/${lessonUuid}/copy/authoring`;
  if (fromStudentId) {
    url = `${url}?fromStudent=${fromStudentId}`;
  }
  const result = await vizzleAxios.post(url, { title: lessonTitle });
  return result;
};

/**
 * Delete a lesson
 * @param {*} lessonUuid id of the lesson to delete
 */

export const deleteLesson = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/delete/authoring`;
  const result = await vizzleAxios.put(url);
  return result;
};

/**
 * Get teacher's lessons
 */
export const getRecommendations = async (searchNode) => {
  const url = `/recommendations?_include=student-assignments&sizePerCategory=12&searchNode=${searchNode}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

/**
 * Get teacher's lessons
 */
export const getMyLessons = async () => {
  const url = '/users/me/lessons?_include=student-assignments,myListTags';
  const result = await vizzleAxios.get(url);
  return result.data;
};

/**
 * Get student's lesson by student id
 * @param {G} studentId student id
 */
export const getStudentLessons = async (studentId, subjectId) => {
  const url = `/users/${studentId}/lessons/all?subject=${subjectId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getLessonsOrder = async (studentId, subjectId) => {
  const url = `/lessonOrders/?filter[studentId]=${studentId}&filter[subjectId]=${subjectId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const saveLessonsOrder = async (lessonOrderId, orderedLessonIds, orderModifier = 1, restartSequence = true) => {
  const url = `/lessonOrders/${lessonOrderId}`;
  const dataToSave = {
    type: 'lessonOrders',
    id: lessonOrderId,
    attributes: {
      orderModifier,
      restartSequence,
    },
    relationships: {
      lessons: {
        data: orderedLessonIds.map((id) => (
          {
            type: 'lessons',
            id,
          }
        )),
      },
    },
  };

  const result = await vizzleAxios.put(url, { data: dataToSave });
  return result;
};

/**
 * Search lesson by criteria
 *
 * @param {*} criteria
 */
export const searchLessons = async (criteria, searchNode, standardScopes) => {
  let url = `/lessons?page[number]=${criteria.pageNumber || 1}&_include=student-assignments`;
  const { keyword, contentType, lessonsWithoutImages, lessonsWithFeedbackLoop } = criteria;
  if (keyword) {
    url = ObjectUtils.appendQueryString(keyword, 'keywords', url);
  }

  const scopeCodes = (standardScopes || []).map((sc) => sc.scopeCode);
  if (scopeCodes && scopeCodes.length > 0 && scopeCodes.findIndex((s) => s === contentType) >= 0) {
    url = `${url}&scenario=standard-based-search`;
    const { contentTypeSelectorValue: { subjects, grades, standards, levels } } = criteria;
    url = ObjectUtils.appendQueryString(subjects, 'filter[subjectId]', url);
    url = ObjectUtils.appendQueryString(grades, 'filter[standardGradeLevel]', url);
    if (standards) {
      url = ObjectUtils.appendQueryString(standards.map((s) => encodeURIComponent(s.value)).join(','), 'filter[standardCode]', url);
    }
    url = ObjectUtils.appendQueryString(levels, 'filter[standardLessonLevel]', url);

    if (LACO.value === contentType || FL_AP.value === contentType) {
      url = ObjectUtils.appendQueryString(contentType, 'filter[standardAppliedScope]', url);
    }
  } else if (STAAR_ALTERNATE.value === contentType) {
    url = ObjectUtils.appendQueryString(contentType, 'filter[specialtyContentCode]', url);
    const { contentTypeSelectorValue: { grades, topics } } = criteria;
    url = ObjectUtils.appendQueryString(grades, 'filter[staarAltGradeRange]', url);
    url = ObjectUtils.appendQueryString(topics, 'filter[staarAltTopic]', url);
  } else {
    const { contentTypeSelectorValue: { subjects, grades, activities } } = criteria;
    url = ObjectUtils.appendQueryString(subjects, 'filter[subjectId]', url);
    url = ObjectUtils.appendQueryString(grades, 'filter[gradeLevel]', url);
    url = ObjectUtils.appendQueryString(activities, 'filter[activity]', url);
  }

  url = ObjectUtils.appendQueryString(lessonsWithoutImages, 'filter[lessonsWithoutImagesOnly]', url);
  if (lessonsWithFeedbackLoop) {
    url = ObjectUtils.appendQueryString('yes', 'filter[feedbackLoop]', url);
  }
  url = `${url}&searchNode=${searchNode}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const searchLessonsByScenario = async ({ criteria, searchNode, scenario }) => {
  const { subjectId, grades, topicIds, standardCodes, lessonLevel, keywords, ignoreTopics } = criteria;
  const url = '/lessons';
  const result = await vizzleAxios.get(url, {
    params: {
      'page[number]': criteria.pageNumber || 1,
      _include: 'student-assignments',
      scenario,
      'filter[subjectId]': subjectId || undefined,
      'filter[gradeLevel]': grades && grades.length > 0 ? grades.join(',') : null,
      'filter[topic]': topicIds && topicIds.length > 0 ? topicIds.join(',') : null,
      'filter[standardLessonLevel]': lessonLevel,
      'filter[standardCode]': standardCodes && standardCodes.length > 0 ? standardCodes.join(',') : null,
      'filter[ignoreTopicLessons]': ignoreTopics,
      keywords,
      searchNode,
    },
  });
  return result.data;
};

/**
 * Lock/Unlock lessons by student id and lesson id
 *
 * @param studentid student id
 * @param lessonIds array of lesson id
 */
export const lockUnlockLesson = async (studentId, lessonIds, isPlayable) => {
  const operation = isPlayable ? 'switchToPlayable' : 'switchToUnplayable';
  const url = `/users/${studentId}/lessons?operation=${operation}`;
  const result = await vizzleAxios.post(url, { lessonIds });
  return result;
};

/**
 * Archive/Unarchive lessons by user id. The user id can be either student id or teacher id
 *
 * @param lessons list lessons
 * @param archivedFlag array of lesson ids
 * @param userId either student id or teacher id
 */
export const archiveLessons = async (lessonIds, archivedFlag, userId) => {
  const operation = archivedFlag ? 'archive' : 'unarchive';
  const url = `/users/${userId}/lessons?operation=${operation}`;
  const result = await vizzleAxios.post(url, { lessonIds });
  return result;
};

/**
 * Assign a lesson to my lesson with lesson id
 * @param {*} lessonId lesson ids to assign
 * @param {*} userId user id
 */
export const assignLesson = async (lessonIds, userId, fromStudentId) => {
  let url = `/users/${userId}/lessons?operation=add`;
  if (fromStudentId) {
    url = `${url}&fromStudent=${fromStudentId}`;
  }
  const result = await vizzleAxios.post(url, { lessonIds });
  return result;
};

/**
 * Share a lesson with reviewer
 *
 * @param lessonUuid lesson id
 */
export const shareLessonWithReviewer = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/reviewer`;
  const result = await vizzleAxios.put(url);
  return result;
};

/**
 * Share a lesson with reviewer
 *
 * @param lessonUuid lesson id
 */
export const completeReview = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/reviewComplete`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const isBeingReviewed = async (lessonUuid) => {
  const url = `/lessons/${lessonUuid}/isReviewing`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const lockLessonForEditing = async (lessonUuid) => {
  const url = `/lessons/lock/${lessonUuid}`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const unlockLessonForEditing = async (lessonUuid) => {
  const url = `/lessons/unlock/${lessonUuid}`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const checkLessonIsLocked = async (lessonUuid) => {
  const url = `/lessons/lock/${lessonUuid}/status`;
  const result = await vizzleAxios.get(url);
  return result;
};

export const getEmailLessonPlanForStudent = async (studentId, startDate, endDate) => {
  const url = `${process.env.REACT_APP_REPORTING_API_URL}/users/${studentId}/lessonplan/email?startDate=${startDate}&endDate=${endDate}`;
  const result = await vizzleAxios.get(url);
  return result;
};

export const copyLessonPageToAnotherLesson = async ({ pageData, toLesson }) => {
  const url = '/lessons/page/copy';
  const result = await vizzleAxios.put(url, {
    page: pageData,
    lessonId: toLesson.id,
  });
  return result;
};

export const copyLessonMultiplePagesToAnotherLesson = async ({ pages, toLesson }) => {
  const url = '/lessons/pages/copy';
  const result = await vizzleAxios.put(url, {
    pages,
    lessonId: toLesson.id,
  });
  return result;
};

export const saveFeedbackLoopValue = async ({ lessonUuid, toggleValue }) => {
  const url = `lessons/${lessonUuid}/feedback/toggle`;
  const result = await vizzleAxios.put(url, {
    toggleValue,
  });
  return result;
};

import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import MediaPlayer from '../../../media/MediaPlayer';
import TextLines from '../../../text/TextLines';
import './SequencingResponse.scss';

export default function SequencingResponse({
  responseCard,
  responseCardId,
  disablePlayMedia,
  onClick,
}) {
  return (
    <Box
      id={responseCardId}
      boxShadow={2}
      className='sequencing-response-card'
      onClick={onClick}
    >
      {disablePlayMedia ? '' : (
        <MediaPlayer
          audioUrl={responseCard.audio}
          videoUrl={responseCard.video}
          parentElementId={responseCardId}
          className='response-media'
        />
      )}
      {responseCard.image ? <CardMedia image={responseCard.image} className='response-image' /> : ''}

      {responseCard.text ? (
        <div className={`response-text ${responseCard.image ? 'text-with-image' : ''}`}>
          <TextLines textLines={responseCard.text} />
        </div>
      ) : ''}
    </Box>
  );
}

SequencingResponse.defaultProps = {
  responseCard: {},
  responseCardId: '',
  disablePlayMedia: false,
  onClick: () => {},
};

SequencingResponse.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  responseCardId: PropTypes.string,
  disablePlayMedia: PropTypes.bool,
  onClick: PropTypes.func,
};

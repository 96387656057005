import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
} from '@mui/material';
import VideoCamIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TextLines from '../../text/TextLines';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
} from './GameConstants';
import GameActivityUtils from './GameActivityUtils';
import './GameCardGrids.scss';

const GameCardGrids = ({
  gameCards,
  selectedCardIndex,
  onCardSelected,
  spinner,
}) => {
  const handleOnCardSelected = (e, cardIndex) => {
    onCardSelected(e, cardIndex);
  };

  return (
    <div className='game-card-grid-editor'>
      <div className={`game-grid game-card-layout game-card-layout-${gameCards.length}`}>
        {gameCards.map((cell, index) => {
          const imageToUse = cell.gameSpaceType === TILE_TYPE_STANDARD ? cell.image : GameActivityUtils.getSpaceTypeImage(spinner, cell.gameSpaceType);
          const extraImage = cell.gameSpaceType === TILE_TYPE_LOSE_A_TURN ? GameActivityUtils.getSpaceTypeExtraImage(cell.gameSpaceType) : '';
          return (
            <div
              key={`game-card-grid-card-${index}`}
              className='card-container'
              style={{ gridArea: `card${index}` }}
            >
              <Card
                className={`
                  game-card 
                  ${index === selectedCardIndex ? 'card-selected' : ''} 
                  ${cell.text || imageToUse ? '' : 'place-holder'}
                  `}
              >
                <Box
                  className={`card-action-selector ${cell.disabled ? 'card-disabled' : ''}`}
                  onClick={(e) => handleOnCardSelected(e, index)}
                  disabled={cell.disabled}
                >
                  {imageToUse || cell.audio ? (
                    <div className={`card-image-container ${cell.audio && !imageToUse ? 'no-height' : ''}`}>
                      <CardMedia
                        className={`card-image ${cell.text ? 'image-with-text' : ''}`}
                        image={imageToUse}
                        title='Card Image'
                      >
                        <div className='media-icons'>
                          {cell.audio ? <VolumeUpIcon className='card-with-media-icon' /> : ''}
                          {cell.video ? <VideoCamIcon className='card-with-media-icon' /> : ''}
                        </div>
                        {extraImage ? (
                          <img
                            className='extra-icon'
                            src={extraImage}
                            alt='Extra'
                          />
                        ) : ''}
                      </CardMedia>
                    </div>
                  ) : ''}
                  {cell.text ? (
                    <div className={`card-text-container ${imageToUse ? 'image-with-text' : 'only-text'}`}>
                      <TextLines textLines={cell.text} isThumbnail />
                    </div>
                  ) : ''}
                  {!cell.text && !imageToUse ? <div>{index + 1}</div> : ''}
                </Box>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

GameCardGrids.defaultProps = {
  gameCards: [],
  onCardSelected: () => { },
  selectedCardIndex: null,
  spinner: TILE_TYPE_STANDARD,
};

GameCardGrids.propTypes = {
  gameCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  onCardSelected: PropTypes.func,
  selectedCardIndex: PropTypes.number,
  spinner: PropTypes.number,
};

export default GameCardGrids;

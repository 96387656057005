import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { SEASONAL_FILTER_OPTIONS } from '../../../constants/PlayPreferenceConstant';
import './Seasonal.scss';
import DropdownInput from '../../../components/selector/DropdownInput';

const seasonalFilterOptions = SEASONAL_FILTER_OPTIONS.map((s) => ({
  id: s.value,
  name: s.name,
}));
const Seasonal = ({ profile, onChangeCallback, loading }) => {
  const [filter, setFilter] = useState(profile.filter);
  // When mounting and changing a profile.
  useEffect(() => {
    setFilter(profile.filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const onFilterChange = async (event) => {
    setFilter(event.target.value);
    onChangeCallback(event.target.value);
  };

  return (
    <Box display='flex' className='seasonal-filter-flexbox' data-test='sesonal-filter-container'>
      <FormControl variant='outlined'>
        <DropdownInput
          dropdownValues={seasonalFilterOptions}
          label='Seasonal Filter'
          labelVariant='h6'
          value={filter}
          onChange={onFilterChange}
          className='seasonal-filter-select'
          data-test='seasonal-filter-select'
        />
        {loading ? <CircularProgress className='saving-icon' /> : null}
      </FormControl>
    </Box>
  );
};

Seasonal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Seasonal;

import { useDomain } from '../../../../../states/AppDomainProvider';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { updateUserProfile } from '../../../../../services/UserProfileService';
import {
  useGetRemoteServiceWrapper,
} from '../../../../hooks/RemoteServiceHooks';

const useSettingForm = (label) => {
  const { uiDomain } = useDomain();
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const updateProfileSettingsOnChange = async (studentId, profile) => {
    await callRemoteServiceWrapper(async () => {
      await updateUserProfile(studentId, profile);
      await uiDomain.showSnackbar(true, 'info', 3000, `${label} has been updated.`);
      await myStudentDomain.updateSelectedStudentProfile({ ...selectedStudentProfile, ...profile });
    });
  };

  return {
    loading,
    updateProfileSettingsOnChange,
  };
};

export default useSettingForm;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Typography } from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import './FlexibleMatchingLayout.scss';
import './FlexibleMatchingEditor.scss';
import CardEditor from '../common/card-editor/CardEditor';
import {
  AREA_PROMPT_CARDS,
  AREA_PROMPT_TEXT,
  AREA_PROMPT_TEXT_CARD,
  AREA_RESPONSE_CARDS,
  AREA_SUBMIT_ANSWER_TEXT,
  AREA_SUB_TEXT,
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_TEXT,
} from '../common/CardEditorConstants';
import { layoutDropdownValues } from './FlexibleMatchingConstants';
import MatchingQuestionKey from '../common/question-key/MatchingQuestionKey';
import MatchingAnswerKey from '../common/question-key/MatchingAnswerKey';

export default function FlexibleMatchingEditor({
  selectedArea,
  selectedSection,
  selectedCardIndex,
  onAreaAndSectionSelected,
  questionKey,
  onQuestionKeyChange,
  promptText,
  layout,
  promptCards,
  subText,
  responseCards,
  onAnswerKeyChange,
  submitAnswerText,
}) {
  const id = useMemo(() => uuidv1(), []);

  const handleonAnswerKeyChange = (index, newKey) => {
    const responseCardKeys = responseCards.map(((c) => c.key));
    responseCardKeys[index] = newKey;
    onAnswerKeyChange(responseCardKeys);
  };

  return (
    <div data-test='advanced-matching' className='flexi-matching-layout flexible-matching-editor-component'>
      <MatchingQuestionKey
        questionKey={questionKey}
        onKeyChange={onQuestionKeyChange}
        className='flexi-matching-question-key'
      />
      <Tabs
        value={[AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(selectedArea) ? AREA_PROMPT_TEXT_CARD : selectedArea}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_event, newValue) => {
          let area = newValue;
          if (newValue === AREA_PROMPT_TEXT_CARD) {
            area = AREA_PROMPT_TEXT;
          }
          onAreaAndSectionSelected(area, SECTION_TEXT);
        }}
      >
        <Tab label='Prompt Text' value={AREA_PROMPT_TEXT_CARD} data-test='prompt-text-tab' />
        <Tab label='Prompts' value={AREA_PROMPT_CARDS} data-test='prompt-tab' />
        <Tab label='Submit Text' value={AREA_SUBMIT_ANSWER_TEXT} data-test='submit-answer-tab' />
        <Tab label='Answers' value={AREA_RESPONSE_CARDS} data-test='response-cards-tab' />
      </Tabs>
      <div className='editor-content-container'>
        {[AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(selectedArea) && (
          <div className='prompt-text-container'>
            <Typography>Prompt Text</Typography>
            <CardEditor
              className='flexi-matching-prompt-text'
              config={{
                text: promptText,
              }}
              isSelected={selectedArea === AREA_PROMPT_TEXT}
              selectedSection={selectedSection}
              onTextSelected={() => {
                onAreaAndSectionSelected(AREA_PROMPT_TEXT, SECTION_TEXT, 0);
              }}
              isVideoIncluded={false}
              isImageIncluded={false}
              isAudioIncluded={false}
              data-test='prompt-text-editor'
            />
            <Typography className='sub-text-prompt-text'>Sub Text</Typography>
            <CardEditor
              className='flexi-matching-sub-text'
              config={{
                text: subText,
              }}
              isSelected={selectedArea === AREA_SUB_TEXT}
              selectedSection={selectedSection}
              onTextSelected={() => {
                onAreaAndSectionSelected(AREA_SUB_TEXT, SECTION_TEXT, 0);
              }}
              isVideoIncluded={false}
              isImageIncluded={false}
              isAudioIncluded={false}
              data-test='sub-text-editor'
            />
          </div>
        )}

        {[AREA_SUBMIT_ANSWER_TEXT].includes(selectedArea) && (
          <div className='submit-answer-text'>
            <CardEditor
              config={{
                text: submitAnswerText,
              }}
              isSelected={selectedArea === AREA_SUBMIT_ANSWER_TEXT}
              selectedSection={selectedSection}
              onTextSelected={() => {
                onAreaAndSectionSelected(AREA_SUBMIT_ANSWER_TEXT, SECTION_TEXT, 0);
              }}
              isVideoIncluded={false}
              isImageIncluded={false}
              isAudioIncluded={false}
              data-test='submit-text-editor'
            />
          </div>
        )}

        {[AREA_PROMPT_CARDS].includes(selectedArea) && (
          <div className='prompt-area' data-test='prompt-cards-container'>
            <div className={`prompt-cards-container ${layoutDropdownValues.find((l) => l.id === layout).className}`}>
              {
                promptCards.map((p, index) => (
                  <CardEditor
                    key={`flexi-matching-layout-edit-prompt-card-key-${index}-${id}`}
                    className='flexi-matching-prompt-card'
                    style={{ gridArea: `card-${index + 1}` }}
                    config={{
                      text: p.text,
                      image: p.image,
                      video: p.video,
                      audio: p.audio,
                    }}
                    isSelected={selectedArea === AREA_PROMPT_CARDS && selectedCardIndex === index}
                    selectedSection={selectedSection}
                    onTextSelected={() => {
                      onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_TEXT, index);
                    }}
                    onImageSelected={() => {
                      onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_IMAGE, index);
                    }}
                    onAudioSelected={() => {
                      onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_AUDIO, index);
                    }}
                  />
                ))
              }
            </div>
          </div>
        )}
        {[AREA_RESPONSE_CARDS].includes(selectedArea) && (
          <div className='response-cards-container' data-test='response-cards-container'>
            {responseCards.map((card, index) => (
              <CardEditor
                key={`flexi-matching-layout-edit-response-card-key-${index}-${id}-${card.key}`}
                className='flexi-matching-response-card'
                config={{
                  text: card.text,
                  image: card.image,
                  video: card.video,
                  audio: card.audio,
                }}
                isSelected={selectedArea === AREA_RESPONSE_CARDS && selectedCardIndex === index}
                selectedSection={selectedSection}
                onTextSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_TEXT, index);
                }}
                onImageSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_IMAGE, index);
                }}
                onAudioSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_AUDIO, index);
                }}
              >
                <MatchingAnswerKey
                  value={card.key}
                  onKeyChange={(value) => { handleonAnswerKeyChange(index, value); }}
                  disabled={index === 0}
                  isCorrect={questionKey === card.key}
                  data-test={`answer-key-${index}`}
                />
              </CardEditor>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

FlexibleMatchingEditor.defaultProps = {
  selectedArea: AREA_PROMPT_TEXT,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
  onAreaAndSectionSelected: () => null,
  questionKey: null,
  onQuestionKeyChange: () => null,
  promptText: null,
  layout: layoutDropdownValues[0].className,
  promptCards: [{}],
  subText: null,
  responseCards: [{}, {}],
  onAnswerKeyChange: () => null,
  submitAnswerText: null,
};

FlexibleMatchingEditor.propTypes = {
  selectedArea: PropTypes.oneOf([AREA_PROMPT_TEXT, AREA_SUB_TEXT, AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS, AREA_SUBMIT_ANSWER_TEXT]),
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  selectedCardIndex: PropTypes.number,
  onAreaAndSectionSelected: PropTypes.func,
  questionKey: PropTypes.string,
  onQuestionKeyChange: PropTypes.func,
  promptText: PropTypes.object,
  layout: PropTypes.string,
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
  })),
  subText: PropTypes.object,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
  })),
  onAnswerKeyChange: PropTypes.func,
  submitAnswerText: PropTypes.object,
};

/* eslint-disable react/jsx-no-target-blank */
import SendIcon from '@mui/icons-material/Send';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useTeacherCurriculum } from '../../../common/context/TeacherCurriculumContextProvider';
import './LessonPlans.scss';

const LessonPlans = () => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonPlans } = teacherCurriculumDomain.domainData;

  return (
    <div className='lesson-plans'>
      {lessonPlans && lessonPlans.length > 0 ? (
        <ul>
          {lessonPlans.map((plan) => (
            <li key={`lesson-plan-${plan.pdfLink}`}>
              <SendIcon className='icon' />
              <a href={plan.pdfLink} target='_blank'>
                {plan.lessonPlanName}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <NoLessonPaper message='No Activity Plans Available' />
      )}
    </div>
  );
};

export default LessonPlans;

import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import SequencingThumbnail from '../../../../../components/vizzle/page/sequencing/SequencingThumbnail';
import { getInitialDataForSequencing } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import Logger from '../../../../../utils/Logger';
import WatchQuickVideo from '../../../../../components/vizzle/media/WatchQuickVideo';

const initDataForSequencing = getInitialDataForSequencing();

/**
* Container for Sequencing layout
*/
export default function SequencingLayout() {
  const props = {
    config: [
      {
        component: (
          <SequencingThumbnail
            sequenceCards={initDataForSequencing.sequenceCards}
            responseCards={initDataForSequencing.responseCards}
          />
        ),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '53vw' : '45vw',
    height: '53vh',
  };

  return (
    <Box>
      <Box display='flex' mt='20px'>
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-sorting-layout'
        />

        <Box alignSelf='center' ml='20px'>
          <WatchQuickVideo
            videoUrl='https://assets.monarchteachtech.com/in-app-help-videos/in-app-help-videos/Sequencing%20How-To.mp4'
          />
        </Box>
      </Box>
    </Box>
  );
}

import { readText } from '../../services/tts/TextToSpeechServiceV2';
import { useLessonPlay } from '../lessonplay/context/LessonPlayContextProvider';

export const useScreenReader = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile: { screenReaderOn, tts } } = lessonPlayDomain.domainData;
  let readWhenScreenReaderIsOn = () => {};
  if (screenReaderOn) {
    readWhenScreenReaderIsOn = (element) => {
      readText(element.attributes.alt.nodeValue, tts);
    };
  }

  let readTextWhenScreenReaderIsOn = () => { };
  if (screenReaderOn) {
    readTextWhenScreenReaderIsOn = (text) => {
      readText(text, tts);
    };
  }

  return {
    screenReaderOn,
    readWhenScreenReaderIsOn,
    readTextWhenScreenReaderIsOn,
  };
};

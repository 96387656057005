import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import { logout } from '../../../services/LoginService';
import './UserVerifyInvalidToken.scss';

const UserVerifyInvalidToken = ({
  errorMessage,
}) => (
  <div className='user-verify-invalid-token'>
    <div className='message'>
      {errorMessage}
    </div>
    <div className='actions'>
      <ButtonWithSpinner
        className='btn-primary'
        label='Return to Login Page'
        fullWidth
        onClick={() => {
          logout(false);
        }}
      />
    </div>
  </div>
);

UserVerifyInvalidToken.defaultProps = {
  errorMessage: '',
};

UserVerifyInvalidToken.propTypes = {
  errorMessage: PropTypes.string,
};

export default UserVerifyInvalidToken;

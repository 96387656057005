import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Alert from '@mui/material/Alert';
import ProfileBox from '../../../../../components/box/ProfileBox';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useDomain } from '../../../../../states/AppDomainProvider';
import './CreateStudentAction.scss';
import {
  MESSAGE_PASSWORD_MISMATCHED,
  MESSAGE_DUPLICATED_NAME,
  useCreateStudentAction,
  useCreateButton,
} from './CreateStudentActionHooks';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import { stateIdentifierTooltipText } from '../../../../../texts/TooltipText';

const CreateStudentAction = ({ listStudents, onStudentCreated }) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const {
    showCreateStudentPanel,
    handleOnCreateButtonClick,
    closeDialog,
  } = useCreateButton();

  const {
    loading,
    student,
    booleanLogic,
    helperText,
    confirmButtons,
    onStudentChange,
    onPasswordChange,
    onConfirmPasswordChange,
    onCancel,
    onSave,
    onClear,
    onCloseAlert,
  } = useCreateStudentAction({
    closeDialog,
    listStudents,
    onStudentCreated,
  });

  if (!user.canCreateStudent) {
    return null;
  }

  const content = () => (
    <>
      {booleanLogic.showAlert ? <Alert severity='error' onClose={onCloseAlert}>{MESSAGE_PASSWORD_MISMATCHED}</Alert> : null}
      {confirmButtons.open ? <Alert severity='error'>{MESSAGE_DUPLICATED_NAME}</Alert> : null}
      <ProfileBox className='create-student-prfile-box' label='First Name' name='firstName' value={student.firstName} onChange={onStudentChange} helperText={helperText.firstName} isEdit isRequired />
      <ProfileBox className='create-student-prfile-box' label='Last Name' name='lastName' value={student.lastName} onChange={onStudentChange} helperText={helperText.lastName} isEdit isRequired />
      {/* <ProfileBox className='create-student-prfile-box' label='SIS ID (Optional)' name='externalId' value={student.externalId} onChange={onStudentChange} isEdit /> */}
      <ProfileBox
        className='create-student-prfile-box'
        label='State Identifier'
        name='stateIdentifier'
        value={student.stateIdentifier}
        onChange={onStudentChange}
        helperText={helperText.stateIdentifier}
        isEdit
        isRequired
        endDecorator={(
          <TooltipWithIndicator {...stateIdentifierTooltipText} />
        )}
      />
      {(user.schoologyFlag || user.userAdmin) && (
        <ProfileBox className='create-student-prfile-box' label='Schoology ID (Optional)' name='schoologyId' value={student.schoologyId} onChange={onStudentChange} isEdit />
      )}
      <ProfileBox className='create-student-prfile-box' label='Username' name='userName' value={student.userName} onChange={onStudentChange} helperText={helperText.userName} isEdit isRequired />
      <ProfileBox
        className='create-student-prfile-box'
        label='Password'
        name='password'
        type='password'
        value={student.password}
        onChange={onPasswordChange}
        helperText={helperText.password}
        isEdit
        isRequired
      />
      <ProfileBox
        label='Confirm Password'
        name='confirmPassword'
        value={student.confirmPassword}
        onChange={onConfirmPasswordChange}
        type='password'
        helperText={helperText.confirmPassword}
        isEdit
        className='create-student-prfile-box'
        isRequired
      />
    </>
  );

  const confirmActions = () => {
    const buttons = [{
      onClick: confirmButtons.onCancel,
      className: 'btn-danger margin-right',
      title: 'No',
      disabled: false,
      loading,
    }, {
      onClick: confirmButtons.onConfirm,
      className: 'btn-primary action-button',
      title: 'Yes',
      disabled: false,
      loading,
    },
    ];
    return buttons;
  };

  const actions = () => {
    const buttons = [{
      onClick: onClear,
      className: 'btn-danger margin-right',
      title: 'Clear',
      disabled: loading,
      loading,
    }, {
      onClick: onSave,
      className: 'btn-primary action-button',
      title: 'SAVE',
      disabled: booleanLogic.disableButton,
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <Button
        data-test='create-student-action'
        variant='contained'
        onClick={handleOnCreateButtonClick}
        className='btn-primary add-student-dialog-bt'
        startIcon={<PersonAddIcon />}
      >
        CREATE STUDENT
      </Button>
      {showCreateStudentPanel && (
        <CustomDialog
          openDialog={showCreateStudentPanel}
          className={`create-student-dialog ${user.schoologyFlag ? 'with-schoology-id' : ''}`}
          onClose={onCancel}
          title='Create Student'
          content={content()}
          displayCloseIcon
          actions={confirmButtons.open ? confirmActions() : actions()}
        />
      )}

    </>
  );
};

CreateStudentAction.defaultProps = {
  listStudents: [],
  onStudentCreated: () => { },
};

CreateStudentAction.propTypes = {
  listStudents: PropTypes.arrayOf(PropTypes.any),
  onStudentCreated: PropTypes.func,
};

export default CreateStudentAction;

import PropTypes from 'prop-types';
import { useGetLessonData } from '../TeacherCourseLessonsHooks';
import { flatten, unique } from '../../../../../../utils/ArrayUtils';
import TeacherCourseAssignLessonsAction from './TeacherCourseAssignLessonsAction';
import './TeacherCoursesMultiSelectionActions.scss';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import LessonsAddToGroupPlay from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/LessonsAddToGroupPlay';

// eslint-disable-next-line arrow-body-style
const TeacherCoursesMultiSelectionActions = ({
  selectedLessonIds,
  excludeLessonTypes,
  includeLessonTypes,
}) => {
  const {
    filteredLessonData,
  } = useGetLessonData({
    excludeLessonTypes,
    includeLessonTypes,
  });

  const lessons = filteredLessonData.lessons || [];
  const allLessons = unique(flatten(lessons.map((subjectWithLessons) => subjectWithLessons.lessons)), 'id') || [];

  const { teacherCourseDomain } = useTeacherCourses();
  const { selectedDates } = teacherCourseDomain.domainData;

  return (
    <div className='teacher-courses-multi-selection-actions'>
      <LessonsAddToGroupPlay
        lessons={allLessons}
        selectedLessonIds={selectedLessonIds}
        // onSaveSuccess={() => {
        //   teacherCourseDomain.updateLessonsData([...lessons], [...included]);
        // }}
      />
      <TeacherCourseAssignLessonsAction
        lessons={allLessons}
        selectedLessonIds={selectedLessonIds}
        selectedDates={selectedDates}
      />
    </div>
  );
};

TeacherCoursesMultiSelectionActions.defaultProps = {
  selectedLessonIds: [],
  excludeLessonTypes: [],
  includeLessonTypes: [],
};

TeacherCoursesMultiSelectionActions.propTypes = {
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  excludeLessonTypes: PropTypes.arrayOf(PropTypes.string),
  includeLessonTypes: PropTypes.arrayOf(PropTypes.string),
};

export default TeacherCoursesMultiSelectionActions;

import BaseDomain from './BaseDomain';
import { DEFAULT_TEXT_FORMAT } from '../../AppConstants';

const AUTHORING_PROFILE_UPDATE_PROFILE = 'AUTHORING_PROFILE_UPDATE_PROFILE';
const reducer = (state, action) => {
  switch (action.type) {
    case AUTHORING_PROFILE_UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  profile: {
    type: 'authoringprofile',
    id: null,
    attributes: {
      ...DEFAULT_TEXT_FORMAT,
    },
  },
};

const domainName = 'authoringProfileDomain';

/**
 * Domain class for user management domain
 *
 * @export
 * @class UserDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor() {
    super(domainName, initialState, reducer);
  }

  /**
   * Update authoring profile information
   *
   * @param {*} profile
   * @returns
   */
  async updateAuthoringProfile(profile) {
    return this.dispatch({
      type: AUTHORING_PROFILE_UPDATE_PROFILE,
      payload: profile,
    });
  }
}

/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CardAction from '../../../../components/card/CardAction';
import { useAddRemoveFromGroupPlay } from './AddRemoveFromGroupPlayHooks';
import './AddToGroupPlay.scss';
import { useDomain } from '../../../../states/AppDomainProvider';
import ChurnZeroService from '../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../constants/ChurnZeroConstant';

/**
 * Share Link Lesson Action Container
 */
export default function AddToGroupPlay({
  lesson,
  onSaveSuccess,
}) {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { addLessonToGroupPlay } = useAddRemoveFromGroupPlay(lesson, onSaveSuccess);
  const handleOnAddToGroupPlay = () => {
    addLessonToGroupPlay();
    ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'Prep for group inst.', user);
  };

  return (
    <CardAction
      icon={<AddCircleIcon />}
      label='GROUP INSTRUCTION'
      ariaLabel='Add to Group Instruction'
      title='Add to Group Instruction'
      className='add-to-group-play-action'
      actionHandler={handleOnAddToGroupPlay}
      data-test='add-to-group-play-action'
    />
  );
}

AddToGroupPlay.defaultProps = {
  onSaveSuccess: () => { },
};

AddToGroupPlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  onSaveSuccess: PropTypes.func,
};

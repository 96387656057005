export const getImageDimension = (url) => {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = () => {
      reject(new Error(`Cannot load the image ${url}`));
    };
    img.src = url;
  });
  return promise;
};

export default getImageDimension;

import { useEffect, useState } from 'react';
import {
  Box,
  Fade,
  Typography,
} from '@mui/material';
import {
  useLocation,
} from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import VizzleLogo from '../../vizzle_NoTag.svg';
import { loginWithProvider, logout } from '../../services/LoginService';
import Logger from '../../utils/Logger';
import { usePrepareData } from './LoginHooks';
import ButtonWithSpinner from '../../components/buttons/ButtonWithSpinner';

/**
 * Login with vizzle token container
 *
 * @param {*} props passed in from parent component
 */
const loadingMessage = 'Loading, please wait';
const errorMessage = 'Sorry, your request could not be processed.';

export default function LoginWithClever() {
  const [showError, setShowError] = useState({
    show: false,
    message: null,
  });
  const query = new URLSearchParams(useLocation().search);

  const {
    prepareData,
  } = usePrepareData();

  useEffect(() => {
    const handleLoginFailed = (message) => {
      Logger.logError('login with token failed: redirect to login');
      setShowError({
        show: true,
        message,
      });
    };

    const login = async () => {
      const cleverCode = query.get('code');
      Logger.logWhenDebugModeIsOn({
        CLEVER_CODE: cleverCode,
      });
      try {
        const result = await loginWithProvider('Clever', cleverCode);
        if (result.success) {
          Logger.logWhenDebugModeIsOn('Login with clever successfully');
          prepareData(result);
        } else {
          handleLoginFailed(result.message);
        }
      } catch (error) {
        Logger.logError(error);
        const message = error.response && error.response.data ? error.response.data : '';
        handleLoginFailed(message);
      }
    };

    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' flexDirection='column' width='100vw' height='100vh'>
      <Box mx='auto' mt='120px'>
        <img src={VizzleLogo} alt='Vizzle Logo' width='250px' />
      </Box>
      {showError.show ? (
        <Fade timeout={1500} in>
          <Box display='flex' flexDirection='column' mt='80px' mx='auto'>
            <Typography variant='h5'>
              {errorMessage}
            </Typography>
            <Typography variant='h6' gutterBottom>
              {showError.message}
            </Typography>
            <Box mt='20px' width='250px' ml='auto' mr='auto'>
              <ButtonWithSpinner
                className='btn-primary'
                label='Return to Login Page'
                fullWidth
                onClick={() => {
                  logout(false);
                }}
              />
            </Box>
          </Box>
        </Fade>
      ) : (
        <Box mt='50px' mx='auto'>
          <Box width='100px' height='100px' mx='auto'>
            <PacmanLoader size={50} color='#3b94d1' css={{ margin: 'auto' }} />
          </Box>
          <Box mt='20px'>
            <Typography variant='h5' gutterBottom>
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardMedia,
} from '@mui/material';
import './FillInTheBlankPageThumbnail.scss';
import TextLines from '../../../text/TextLines';
import { BLANK_SPACE } from '../FillInTheBlankUtils';

/**
 * Lesson activity for Fill in the Blank Page
 */
export default function FillInTheBlankPageThumbnail({ cardConfig, isSmallThumbnail }) {
  return (
    <div className='fill-in-the-blank-page-thumbnail-component vizzle-fill-in-the-blank-page-thumbnail'>
      {
        cardConfig.map((c, index) => {
          const caredId = uuidv1();
          return (
            <Card
              id={caredId}
              key={caredId}
              className={`fill-in-the-blank-page-thumbnail vizzle-fitb-thumbnail-page-${index}`}
            >
              {
                c.image ? (
                  <CardMedia
                    className='fill-in-the-blank-page-thumbnail-image'
                    image={c.image}
                    title='Fill in the Blank'
                  />
                )
                  : (
                    <Box
                      component='div'
                      className='fill-in-the-blank-page-thumbnail-placeholder'
                    >
                      Image/Video
                    </Box>
                  )
              }
              <div className={`${isSmallThumbnail ? 'small-gap' : 'gap'}`} />
              {
                c.fitbList && c.fitbList.length > 0 ? (
                  <div className={`fill-in-the-blank-questions-container ${isSmallThumbnail ? 'small-padding' : ''}`}>
                    {/* {c.fitbList[0].question.question} */}
                    <Box display='flex' alignItems='baseline' position='relative'>
                      <TextLines textLines={c.fitbList[0].question.preBlank} isThumbnail={isSmallThumbnail} />
                      {BLANK_SPACE}
                      <TextLines textLines={c.fitbList[0].question.postBlank} isThumbnail={isSmallThumbnail} />
                    </Box>
                    {c.fitbList.length > 1 && (
                      <div>
                        {c.fitbList.length - 1} more
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='fill-in-the-blank-page-thumbnail-placeholder'>
                    Fill in the Blank Questions
                  </div>
                )
              }
            </Card>
          );
        })
      }
    </div>
  );
}

FillInTheBlankPageThumbnail.defaultProps = {
  cardConfig: [{
    card: {
      audio: null,
      image: null,
      video: null,
    },
    fitbList: [],
  }],
  isSmallThumbnail: false,
};

FillInTheBlankPageThumbnail.propTypes = {
  cardConfig: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.shape({
        audio: PropTypes.string,
        image: PropTypes.string,
        video: PropTypes.string,
      }),
      fitbList: PropTypes.arrayOf(PropTypes.shape({
        answers: PropTypes.array,
        question: PropTypes.shape({
          question: PropTypes.string,
        }),
      })),
    }),
  ),
  isSmallThumbnail: PropTypes.bool,
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TransparentBlocker from '../../../blocker/TransparentBlocker';
import MatchingPrompts from './subcomponents/MatchingPrompts';
import MatchingResponse from './subcomponents/MatchingResponse';
import RejectedAnswer from '../common/RejectedAnswer';
import './Matching.scss';
import { MAX_ANSWER_CARDS } from '../../../../AppConstants';
import {
  disableComponent,
  getDroppingAnimateClass,
  showDropRejection,
} from './MatchingUtil';
import { stopAudio } from '../../media/AudioPlayer';

/**
 * Lesson activity for Matching layout
 */
export default function Matching({
  promptCards,
  responseCards,
  pageIndex,
  onCorrectAnswerSelected,
  forPrinting,
}) {
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(-1);
  const [selectedWrongResponses, setSelectedWrongResponses] = useState([]);

  const vizzleComponentPreviewId = `vizzle-matching-preview-${pageIndex}`;
  const prompCardDropableId = `matching-component-dropable-placeholder-${pageIndex}`;
  const rejectDroppedCardId = `matching-component-dropable-rejected-dropped-card-${pageIndex}`;

  const getPromptCardPlaceholder = () => (
    selectedResponseIndex < 0 ? (
      <Droppable droppableId={prompCardDropableId} direction='horizontal'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`matching-component-prompts-area-question-mark-container 
            ${snapshot.isDraggingOver ? 'vizzle-selected-component' : ''}
            `}
          >
            <div className='matching-component-prompts-area-question-mark'>?</div>
            <div className='matching-component-hidden'>{provided.placeholder}</div>

            <div className='matching-component-prompts-reject' id={rejectDroppedCardId}>
              <RejectedAnswer />
            </div>
          </div>
        )}
      </Droppable>

    ) : (
      <MatchingResponse
        responseCard={responseCards[selectedResponseIndex]}
        index={selectedResponseIndex}
        cardId={`matching-selected-response-${pageIndex}`}
      />
    )
  );

  const handleOnDragStart = () => {
    stopAudio();
    disableComponent(vizzleComponentPreviewId, true);
  };

  const handleOnDragEnd = (result) => {
    disableComponent(vizzleComponentPreviewId, false);
    const { source, destination } = result;
    if (!source || !destination) {
      return;
    }

    const { index } = source;
    const { droppableId } = destination;
    if (prompCardDropableId === droppableId) {
      if (responseCards[index].isCorrect) {
        setSelectedResponseIndex(index);
        onCorrectAnswerSelected();
      } else {
        setSelectedWrongResponses([
          ...selectedWrongResponses,
          index,
        ]);
      }
    }
  };

  useEffect(() => {
    if (selectedWrongResponses.length > 0) {
      showDropRejection(rejectDroppedCardId);
    }
    // eslint-disable-next-line
  }, [selectedWrongResponses]);

  return (
    <DragDropContext
      onDragStart={handleOnDragStart}
      onDragEnd={handleOnDragEnd}
    >
      <div className='matching-component vizzle-matching-preview'>
        <TransparentBlocker show={selectedResponseIndex >= 0} />
        <div
          id={vizzleComponentPreviewId}
          className='matching-component-prompts'
        >
          <MatchingPrompts
            promptCards={promptCards}
            renderQuestionPlaceholder={getPromptCardPlaceholder()}
            forPrinting={forPrinting}
          />
        </div>
        <Droppable droppableId={`matching-component-draggable-container-${pageIndex}`} direction='horizontal' isDropDisabled>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='matching-component-responses'
            >
              <div className='matching-component-responses-area'>
                {
                  responseCards.map((card, index) => {
                    const cardId = (pageIndex * MAX_ANSWER_CARDS) + index;
                    // For media player to hilight the desired element
                    const responseCardId = `matching-drag-item-container-${cardId}`;
                    return (
                      <div
                        key={`matching-drag-item-container-${cardId}`}
                        id={responseCardId}
                        className={`matching-response-drag-container response-margin-${responseCards.length}-responses`}
                      >
                        {index !== selectedResponseIndex ? (
                          <Draggable
                            key={`matching-drag-item-${cardId}`}
                            draggableId={`matching-drag-item-${cardId}`}
                            index={index}
                            isDragDisabled={selectedResponseIndex >= 0 || selectedWrongResponses.findIndex((s) => (s === index)) >= 0}
                          >
                            {(providedItem, snapshotItem) => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                id={`matching-response-item-${cardId}`}
                                key={`matching-response-item-${cardId}`}
                                className={`
                              matching-component-response-card
                              ${snapshotItem.isDragging ? 'vizzle-selected-component' : 'matching-component-response-card-non-drag-items'} 
                              ${getDroppingAnimateClass(snapshotItem.isDropAnimating, snapshotItem.isDragging, card.isCorrect)}
                              ${selectedWrongResponses.findIndex((s) => (s === index)) >= 0 ? 'matching-component-disabled matching-component-fading' : ''}
                              `}
                              >
                                <MatchingResponse responseCard={card} index={index} cardId={responseCardId} />
                              </div>
                            )}
                          </Draggable>
                        ) : <Card className='matching-component-full-height' />}

                        {provided.placeholder}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

Matching.defaultProps = {
  promptCards: [{}],
  responseCards: [{}, {}, {}, {}, {}],
  pageIndex: 0,
  onCorrectAnswerSelected: () => { },
  forPrinting: false,
};

Matching.propTypes = {
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),

  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    isCorrect: PropTypes.bool,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),

  pageIndex: PropTypes.number,
  onCorrectAnswerSelected: PropTypes.func,
  forPrinting: PropTypes.bool,
};

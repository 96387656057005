import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import SortingEditor from '../../../../../components/vizzle/page/sorting/SortingEditor';
import {
  AREA_INSTRUCTIONS,
  AREA_PROMPT_CARDS,
  AREA_RESPONSE_CARDS,
  SECTION_IMAGE,
  SECTION_TEXT,
  SECTION_AUDIO,
  SECTION_ANSWER_KEY,
} from '../../../../../components/vizzle/page/sorting/SortingConstants';
import { availableSortingKeys, getInitialDataForSorting } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { flatten, sort } from '../../../../../utils/ArrayUtils';
import {
  checkHasNon18FontSize,
  checkHasNonBoldFontWeight,
  checkHasNonJosefinSlab,
  getExceedCharLimitMessage,
  getTotalCharsLength,
  useHandleTextEditorWarning,
} from '../../hooks/TextEditorWarningHooks';

const initState = {
  selectedArea: AREA_INSTRUCTIONS,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: null,
};

/**
 * Container for adding text, image, video and audio for Sorting page
 */
export default function SortingTextAndMedia() {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const instructionsConfig = {
    text: selectedPage.instructions.text,
    audio: selectedPage.instructions.audio,
    onSelected: (e, selectedArea, selectedSection) => {
      setCurrentSelectedData({
        selectedArea,
        selectedSection,
      });
    },
  };

  const promptCardsConfig = {
    promptCards: flatten(selectedPage.categories.map((category) => (
      category.promptCards
    ))),
    onSelected: (e, selectedArea, selectedSection, selectedCardIndex) => {
      setCurrentSelectedData({
        selectedArea,
        selectedSection,
        selectedCardIndex,
      });
    },
  };

  const responseCardsConfig = {
    responseCards: sort(flatten(selectedPage.categories.map((category) => (
      category.responseCards
    ))), 'sequenceNum'),
    availableSortingKeys,
    onKeyChanged: async (e, cardIndex) => {
      e.stopPropagation();
      responseCardsConfig.responseCards[cardIndex].key = e.target.value;

      // Re-arrange the response cards in prompt cards
      selectedPage.categories.forEach((category) => {
        // eslint-disable-next-line no-param-reassign
        category.responseCards = [];
      });

      responseCardsConfig.responseCards.forEach((responseCard) => {
        let matchedPromptIndex = promptCardsConfig.promptCards.findIndex((promptCard) => (
          promptCard.key === responseCard.key
        ));

        matchedPromptIndex = matchedPromptIndex < 0 ? promptCardsConfig.promptCards.length - 1 : matchedPromptIndex;
        selectedPage.categories[matchedPromptIndex].responseCards.push(responseCard);
      });
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    },
    onSelected: (e, selectedArea, selectedSection, selectedCardIndex) => {
      setCurrentSelectedData({
        selectedArea,
        selectedSection,
        selectedCardIndex,
      });
    },
  };

  const getText = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_INSTRUCTIONS:
        return selectedPage.instructions.text;
      case AREA_PROMPT_CARDS:
        return promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex] && promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex].text;
      case AREA_RESPONSE_CARDS:
        return responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex] && responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex].text;
      default:
        return {};
    }
  };

  const onTextApply = async (textLines) => {
    const newTextLines = textLines.map((e) => ({
      ...e,
    }));

    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      if (!selectedPage[currentSelectedData.selectedArea].text) {
        selectedPage[currentSelectedData.selectedArea].text = {};
      }
      selectedPage[currentSelectedData.selectedArea].text.defaultText = false;
      selectedPage[currentSelectedData.selectedArea].text.lines = newTextLines;
    } else if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex];
      if (!selectedCard.text) {
        selectedCard.text = {};
      }
      selectedCard.text.lines = newTextLines;
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex];
      if (!selectedCard.text) {
        selectedCard.text = {};
      }
      selectedCard.text.lines = newTextLines;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }
    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }
    const hasNon18FontSize = checkHasNon18FontSize(textLines);
    if (hasNon18FontSize) {
      closeTextEditorWarning();
      return;
    }

    if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (hasNonBoldFontWeight) {
        closeTextEditorWarning();
        return;
      }

      const charLimit = 24;
      const totalCharsLength = getTotalCharsLength(textLines);
      if (totalCharsLength > charLimit) {
        showTextEditorWarning(getExceedCharLimitMessage(charLimit));
        return;
      }
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      const charLimit = 33;
      const totalCharsLength = getTotalCharsLength(textLines);
      if (totalCharsLength > charLimit) {
        showTextEditorWarning(getExceedCharLimitMessage(charLimit));
        return;
      }
    }
    closeTextEditorWarning();
    // const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    // if (!hasNonJosefinSlab) {
    //   const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //   if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
    //     const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
    //     if (!hasNonBoldFontWeight) {
    //       // Response card
    //       const lineLimit = 3;
    //       if (textLines && textLines.length > lineLimit) {
    //         showTextEditorWarning(getExceedLineLimit(lineLimit));
    //         return;
    //       }
    //       const charLimit = 8;
    //       const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //       if (exceedCharLimit) {
    //         showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //         return;
    //       }
    //     }
    //   } else if (!hasNon18FontSize) {
    //     // Response card
    //     const lineLimit = 3;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 11;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // }
    // closeTextEditorWarning();
  };

  useEffect(() => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      const { text } = selectedPage.instructions;
      onTextChange(text && text.lines);
    } else if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex];
      const { text } = selectedCard;
      onTextChange(text && text.lines);
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex];
      const { text } = selectedCard;
      onTextChange(text && text.lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  const onTextRemoved = async () => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      const card = {
        ...selectedPage[currentSelectedData.selectedArea],
        text: getInitialDataForSorting().instructions.text,
      };
      selectedPage[currentSelectedData.selectedArea] = card;
    } else if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex].text = null;
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex].text = null;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const getAudio = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_INSTRUCTIONS:
        return selectedPage.instructions.audio;
      case AREA_PROMPT_CARDS:
        return promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex] && promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex].audio;
      case AREA_RESPONSE_CARDS:
        return responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex] && responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex].audio;
      default:
        return null;
    }
  };

  const handleOnAudioApply = async (audioData) => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      selectedPage[AREA_INSTRUCTIONS].audio = audioData;
    } else if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex].audio = audioData;
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex].audio = audioData;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const getImage = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_PROMPT_CARDS:
        return promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex] && promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex].image;
      case AREA_RESPONSE_CARDS:
        return responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex] && responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex].image;
      default:
        return null;
    }
  };

  const onImageAndVideoSelected = async (media) => {
    if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = promptCardsConfig.promptCards[currentSelectedData.selectedCardIndex];
      selectedCard.image = media.selectedImageUrl;
      selectedCard.thumbNailImage = media.selectedImageUrl;
      selectedCard.video = media.selectedVideoUrl;
    } else if (AREA_RESPONSE_CARDS === currentSelectedData.selectedArea) {
      const selectedCard = responseCardsConfig.responseCards[currentSelectedData.selectedCardIndex];
      selectedCard.image = media.selectedImageUrl;
      selectedCard.thumbNailImage = media.selectedImageUrl;
      selectedCard.video = media.selectedVideoUrl;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <SortingEditor
            promptCardsConfig={promptCardsConfig}
            responseCardsConfig={responseCardsConfig}
            instructionsConfig={instructionsConfig}
            selectedSection={currentSelectedData}
          />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%' width='100%'>
                <TextAndMediaFormatting
                  text={getText()}
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemoved}
                  onTextChange={onTextChange}
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {(currentSelectedData.selectedSection === SECTION_IMAGE
              || currentSelectedData.selectedSection === SECTION_ANSWER_KEY) && (
                <AddPhotoOrVideo
                  image={getImage()}
                  onSelect={onImageAndVideoSelected}
                  onRemove={() => { onImageAndVideoSelected({}); }}
                />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={getAudio()}
                onAudioApply={(audioData) => { handleOnAudioApply(audioData); }}
                onAudioRemoved={() => { handleOnAudioApply(null); }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import './UrlLink.scss';

export default function UrlLink({ title, url, subUrls, className, dataTest, ...props }) {
  const isSelected = [url, ...subUrls].includes(`/${window.location.pathname.split('/')[1]}`);
  return (
    <Button
      sx={{
        textTransform: 'capitalize',
      }}
      {...props}
      className={`${className} url-link ${isSelected ? 'url-selected-link' : ''}`}
      data-test={`url-list-${dataTest || title}`}
    >
      {title}
    </Button>
  );
}

UrlLink.defaultProps = {
  title: '',
  url: '',
  subUrls: [],
  className: '',
  dataTest: '',
};

UrlLink.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  url: PropTypes.string,
  subUrls: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  dataTest: PropTypes.string,
};

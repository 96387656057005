import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Checkbox, FormControlLabel } from '@mui/material';
import DropdownInput from '../../../../../../components/selector/DropdownInput';
import SearchLicenseUserAction from '../search-license-user/SearchLicenseUserAction';
import DateFromToForm from '../../../../../../components/form/DateFromToForm';
import { dateFormatMMddyyyy } from '../../../../../../AppConstants';
import { licenseTypeOptions, quantityOptions } from './LicenseConstant';
import './LicenseForm.scss';

// eslint-disable-next-line max-len
export const validateLicenseData = (data) => data && data.teacherId !== null && data.quantity !== null && data.type !== '' && data.active !== null && data.startDate !== null && data.expirationDate !== null;

const LicenseForm = ({
  licenseData,
  onDataChange,
}) => {
  const [internalData, setInternalData] = useState({ ...licenseData });

  const updateDataChange = (updatedData) => {
    setInternalData(updatedData);
    const request = { ...updatedData, type: licenseTypeOptions.filter((item) => item.id === updatedData.type)[0].name };
    const isDataValid = validateLicenseData(request);
    onDataChange(request, isDataValid);
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const updatedData = { ...internalData, [name]: name === 'active' ? e.target.checked : e.target.value };
    updateDataChange(updatedData);
  };

  const handleOnUserChange = (selectedUser) => {
    const updatedData = { ...internalData, teacherId: selectedUser.key };
    updateDataChange(updatedData);
  };

  const handleOnDateChange = (date, field) => {
    const updatedData = { ...internalData };
    if (field === 'startDate') {
      updatedData.startDate = date;
    } else {
      updatedData.expirationDate = date;
    }

    setInternalData(updatedData);
    updateDataChange(updatedData);
  };

  return (
    <div className='admin-license-form'>
      <FormControl variant='outlined'>
        <SearchLicenseUserAction
          name='teacherId'
          filterValue={internalData.teacherId}
          onFilterChange={handleOnUserChange}
          isMultiple={false}
        />
        <FormControlLabel control={<Checkbox onChange={handleOnChange} name='active' color='primary' checked={internalData.active} />} label='Active' />
        <DropdownInput
          dropdownValues={quantityOptions}
          name='quantity'
          value={internalData.quantity}
          onChange={handleOnChange}
          inlineLabel='Quantity'
        />
        <DropdownInput
          dropdownValues={licenseTypeOptions}
          name='type'
          value={internalData.type}
          onChange={handleOnChange}
          inlineLabel='License Type'
        />

        <DateFromToForm
          startDate={internalData.startDate}
          endDate={internalData.expirationDate}
          dateFormat={dateFormatMMddyyyy}
          onChange={handleOnDateChange}
          displayTime={false}
          className='license-date-form-to-container'
          endDateLabel='Expiration Date'
          disableToolbar={false}
        />
      </FormControl>
    </div>
  );
};

LicenseForm.defaultProps = {
  licenseData: {},
  onDataChange: () => null,
};

LicenseForm.propTypes = {
  licenseData: PropTypes.shape({
    teacherId: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.string,
    quantity: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    expirationDate: PropTypes.instanceOf(Date),
  }),
  onDataChange: PropTypes.func,
};

export default LicenseForm;

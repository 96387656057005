/* eslint-disable max-len */
import { useMemo } from 'react';
import VizzleMainContainer from '../../vizzle/VizzleMainContainer';
import CustomDialog from '../../../components/dialog/CustomDialog';
import {
  useTermsOfUse,
} from './TermsOfUseHooks';
import './TermsOfUse.scss';
import { logout } from '../../../services/LoginService';

const privacyEmail = 'privacy@rethinkfirst.com.';
const supportRethinkEmail = 'support@rethinkfirst.com';
const infoRethinkEmail = 'info@rethinkfirst.com';

const privacyNoticeLink = 'https://www.rethinked.com';
const copyRightPolicyLink = 'https://www.rethinked.com';

const TermsOfUse = () => {
  const content = useMemo(() => (
    <div className='content'>
      <h1>Terms of Use</h1>

      <p>
        PLEASE READ CAREFULLY: THESE TERMS ARE A BINDING AGREEMENT FOR THE USE OF THE SERVICES. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT ACCESS OR USE THE SERVICE.
      </p>

      <section>
        <b>INTRODUCTION</b>
        <ul className='margin-top-remove'>
          <li><b>Who we are.</b> Rethink Autism, Inc. (“Rethink”) through its operating division, RethinkED, operates the Websites https://www.rethinked.com/ and nextgen.govizzle.com and the RethinkED mobile App.</li>
          <li><b>Our Services.</b> The Service means the RethinkED Websites and the App and all their components including but not limited to content and tools included. The Terms mean these terms of use that describe how you may access and use the Service and they also include the RethinkED Privacy Notice that explains how we collect, use and protect personal information. These Terms do not cover any other services and products of Rethink except for those expressly described.</li>
          <li><b>You and Us.</b> When we refer to we, us or our, we mean RethinkED. When we refer to you or your we mean you, the person accessing or using the Service. Your Organization means the School District, your School or another organization, as applicable, that is our customer and that either directly or indirectly, employs you, engages you, enrolls your child or a child under your care as a student, provides your child or a child under your care with services, provides you or your school with services or that is otherwise affiliated with you or your school.</li>
        </ul>
      </section>

      <section>
        <b>YOUR ELIGIBILITY TO ACCESS AND USE THE SERVICE</b>
        <ul className='margin-top-remove'>
          <li><b>Non-Registered Users.</b> You do not need to be a registered user to access the public portion of the Service (e.g. landing pages, blogs, etc.), but you are still subject to these Terms in so far as they apply to the public portion of the Service.</li>
          <li><b>Registered Users.</b> Access to the subscription-only Service (i.e. password protected portion) requires registration and is made available only through your Organization with whom we have a separate agreement for your access and use. If there is any conflict between our agreement with your Organization and these Terms, then our agreement with your Organization will prevail.</li>
          <li><b>Minimum Age.</b> Except as noted in Section 4.4 below, both the public and subscription-only portions of the Service are limited to individuals who are 18 years of age or older.</li>
        </ul>
      </section>

      <section>
        <b>UNDERSTANDING THESE TERMS OF USE</b>
        <ul className='margin-top-remove'>
          <li><b>This is a binding agreement.</b> We are willing to provide the Service to you on the condition that you first accept these Terms and comply with all the obligations and standards they set. These Terms are a binding agreement between you and us. When you click through these terms (e.g. on the “I agree” or a similar button or check box), or when you otherwise access or use the service, you signify to us that you have read, understand and agree to be bound by the Terms, whether or not you are a registered user. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICE.</li>
          <li><b>We may modify these Terms.</b> We reserve the right to change all or any part these Terms. A material change to the Terms will become effective when posted. If you continue using the service after the change is implemented, you are indicating your agreement to it. IF YOU DO NOT AGREE TO SUCH CHANGE YOU MUST STOP USING THE SERVICE.</li>
          <li><b>We may change the Service.</b> We may at any time and in our sole discretion change, modify, add, or discontinue the Service or any portion or component of the Service. Any material changes will be posted.</li>
          <li><b>We may use service providers.</b> We may use Subcontractors which means third party service provides, including our affiliates in the performance of our obligations and the Services and we will be responsible for the actions of such Subcontractors.</li>
          <li><b>We may require additional confirmation from you.</b> We may, from time to time, request that you confirm, including in writing, your agreement to and compliance with any part of the Terms as a condition to your continued access to and use of the Services.</li>
          <li><b>Supplemental Terms.</b> The use of certain Services may be subject to additional Supplemental Terms which means additional terms, conditions, or restrictions, imposed by Rethink or by third party providers which will be posted on those pages from which they may be accessed. You agree to comply with all such Supplemental Terms and that if they conflict with these Terms, the Supplemental Terms will prevail with respect to such Service or any of its components. All such Supplemental Terms do not amend these Terms.</li>
        </ul>
      </section>

      <section>
        <b>YOUR ACCOUNT</b>
        <ul className='margin-top-remove'>
          <li>
            <b>Setting up a User Account.</b> When creating your User Account you will be prompted to provide information, including personally identifiable information; please check the <a href={privacyNoticeLink}>RethinkED Privacy Notice</a> to see how personally identifiable information is collected used and protected. You agree:
            <ul>
              <li>to provide accurate, current, and complete information;</li>
              <li>not to register on behalf of an individual other than yourself;</li>
              <li>not to use another user’s account; and</li>
              <li>to maintain the confidentiality and security of your password and user ID.</li>
            </ul>
          </li>
          <li>
            <b>You are responsible for your User Account.</b> You are fully responsible for any use of your User Account and for all activities that occur using your password or user ID, whether or not such use is authorized by you. If you know or suspect that anyone other than you knows your User Account login details, you must immediately notify us at <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>.
          </li>
          <li>
            <b>Control of Access and Use Rights.</b> Your Organization alone is responsible for setting your Permissions which means designating and re-designating the access and usage rights level for your account (e.g. full/partial access, admin rights, view-only, etc.) including, but not limited to setting Permissions based on your role and the sensitivity of data which you may input or access. Such Permissions can include access to certain functionality of the Service which may prompt you to provide additional information about yourself or others (e.g. Child Profile, Student Cabinet, etc.). Please check the <a href={privacyNoticeLink}>RethinkED Privacy Notice</a> to see how personally identifiable information is collected used and protected by Rethink. All questions about your <b>Permissions</b> and how your Organization may collect, use, and protect such information should be directed to your Organization.
          </li>
          <li>
            <b>Student Center.</b> The Student Center is the only part of our Services intended for use by children, and its use is initiated and controlled solely by your Organization and is subject to obtaining parental consent in compliance with applicable laws. Please check the <a href={privacyNoticeLink}>RethinkED Privacy Notice</a> for additional information on the Student Center, the collection, use and disclosure of student information and exercising parental rights to access student’s information, request its deletion, or opt out of future collection.
          </li>
        </ul>
      </section>

      <section>
        <b>AUTHORIZED USE OF THE SERVICE</b>
        <ul className='margin-top-remove'>
          <li>
            <b>Your use is conditioned on your compliance with these Terms.</b> You agree to act in good faith and use the Service and in accordance with the Terms and applicable laws. You shall no
            <ul>
              <li>modify, distribute, transmit, display, perform or create derivative works from or based on the Service or any components thereof;</li>
              <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available, whether or not as “paid for” service, and regardless of the method of dissemination, the functionality or any component of the Services to any third party, including on or in connection with the internet or any time-sharing, service bureau, software as a service, hosting, cloud, or other technology or service;</li>
              <li>reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any source code of the Services;</li>
              <li>use manual process, automatic device or application or extraction tool to access, monitor, use, download, retrieve, index, extract, scrape, or data mine;</li>
              <li>copy, frame, mirror, or in any way reproduce or circumvent the navigational structure or presentation of the Service or any component thereof or otherwise defeat, bypass, or circumvent any other protections of Rethink’s intellectual property rights in the Service;</li>
              <li>bypass or breach any security device or protection used by the Services or otherwise access or use the Services;</li>
              <li>input, upload, transmit, activate or otherwise provide to or through the Services any malware or harmful code or virus;</li>
              <li>damage, destroy, deface, disrupt, disable, impair, interfere with, or otherwise disrupt impede, overburden or harm in any manner the Services or Rethink’s provision of products and services to any person or entity;</li>
              <li>remove, delete, alter, or obscure any trademarks, copyright and attribution notices, warranties, or disclaimers, or any other intellectual property rights notices from the Services or any component thereof;</li>
              <li>access or use the Services in any manner or for any purpose or that infringes, misappropriates, or otherwise violates intellectual property rights or other rights of any person or entity, or that otherwise violates any applicable law;</li>
              <li>generate, upload or share User Materials (as explained below) that are defamatory, obscene, promote bigotry, racism, misogyny, religious or ethnic hatred or are otherwise libelous, unlawful, or tortious, including stalking, harassing, or threatening others with violence or abuse and transmitting abusive commercial solicitation, such as “junk” messages, spam and pyramid schemes to other users of the Service;</li>
              <li>access or use the Services for purposes of monitoring their availability, performance or functionality, or for benchmarking against third party services or competitive analysis of the Services, or for the development, provision, or use of a competing service or any other purpose that is to Rethink’s detriment or commercial disadvantage;</li>
              <li>access or use the Services in, or in association with, the design, construction, maintenance, or operation of any safety response systems or other safety-critical applications, or any other use or application in which the use or failure of the Services could lead to personal injury or severe physical or property damage;</li>
              <li>otherwise access to or use the Services beyond the scope of the use granted by Rethink.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <b>USER MATERIALS</b>
        <ul className='margin-top-remove'>
          <li><b>User Information and Materials.</b> Depending on your Permissions, you may upload and maintain on the Service User Materials which means any data (including personal data of individuals), information, content and materials that are relevant and necessary for your use of the Service. Personal data of individuals must be entered only in specifically designated forms and/or fields on the Service. You are solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of such User Materials and their inclusion and utilization in the Service. You must ensure that you have all necessary legal rights and authority to the User Materials and that such materials would not violate any law or rights of any individual. Your initial and continued ability to access, use, add, share, transmit and remove User Materials depends on the Permissions granted by your Organization. When sharing or transmitting User Materials via the Service, you are responsible to ensure that such sharing and transmission is appropriate and legal. You are also responsible for any backup, recovery or other steps required to ensure that your User Materials are recoverable in the case of data loss.</li>
          <li><b>Our rights with respect to User Materials.</b> We have no ownership rights in User Materials. You grant us and our Subcontractors permission, to access, use, reproduce, modify, perform, display, distribute and process User Materials as is reasonably necessary to perform the Service.</li>
          <li><b>We have no obligations to monitor User Materials and Usage.</b> We have no obligation to monitor or investigate your access to and usage of the Services or User Materials. However, we reserve the right, in our sole discretion and without further notice to you, to monitor or investigate a User Account if we believe, that it is necessary to:
            <ul>
              <li>comply with any applicable law, including requests by law enforcement or government agencies;</li>
              <li>to respond to notices of alleged infringement in accordance with <a href={copyRightPolicyLink}>Rethink Copyright Policy.</a></li>
              <li>to verify compliance with the Terms;</li>
              <li>protect the rights, property or safety of Rethink, your Organization, users and/or the public.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <b>OUR PROPRIETARY RIGHTS</b>
        <ul className='margin-top-remove'>
          <li><b>Our Intellectual Property.</b> We own all right, title and interest, including all intellectual property rights in the Service and all content and tools including, but not limited to software, data, graphics, images, logos, audio, video, design, and any and all other features and elements, in any media or format, that are or may in the future become available through the Service, all of which are protected by United States and international intellectual property laws and other applicable laws.</li>
          <li><b>No right to use our trademarks and logos.</b> You shall not use Rethink or its affiliates trade names, trademarks and service marks, alone or in connection with any other words or logos without Rethink&apos;s consent and if such consent is granted all uses shall inure solely to the benefit of Rethink and its affiliates and you shall acquire no right, title or interest in them or goodwill associated with them and shall refrain from impairing Rethink or its affiliates&apos; rights.</li>
          <li><b>Statistical Information.</b> We may derive and compile, either manually or automatically, Statistical Information which means anonymized and aggregated data related to the performance, operation and use of the Services including by you, and use such Statistical Information for our business purposes, including for operations management, for research and development and for sharing with relevant parties. We own the rights in and to such Statistical Information. For additional information on our collection and use of Statistical Information please check the <a href={privacyNoticeLink}>RethinkED Privacy Notice.</a></li>
          <li><b>You have a limited right to download.</b> You may use the functionality of the Service to download or print a reasonable number of copies of reports and Rethink content to which you have Permission to access, for your internal business purposes, provided that you include all copyright and other proprietary rights notices and attribution.</li>
          <li><b>How to request permission to use our content.</b> If you would like to use any content from the Service that isn&apos;t expressly permitted in these Terms, please contact us by writing to Rethink Autism, Inc. Attn: Copyright Agent, 49 W. 27th St., 8th Fl. New York, NY 10001.</li>
          <li><b>Reservation of rights by us.</b> You shall not use or exploit our Service in any manner, except as expressly permitted. All rights not expressly granted to you are reserved by us. There are no implied licenses to the Service or its components.</li>
        </ul>
      </section>

      <section>
        <b>SUSPENSION AND TERMINATION</b>
        <ul className='margin-top-remove'>
          <li>
            <b>We have the right to terminate or suspend your User Account.</b> If you breach any of the Terms, pose a threat to the functionality, security, integrity or availability of the Services, violate any applicable laws, or if needed to protect the rights, property and safety of Rethink, your Organization, other users or the public, we may, in our discretion. immediately do any or all of the following (without limitation):
            <ul>
              <li>issue a warning to you;</li>
              <li>suspend or terminate your User Account; and/or</li>
              <li>take legal action against you.</li>
            </ul>
          </li>
          <li><b>Termination or expiration of our agreement with your Organization.</b> We make the Service available to you pursuant to an agreement with your Organization. If that agreement expires or terminates for any reason, we will terminate your access to the Service.</li>
          <li><b>Violation of laws by you.</b> Any unauthorized use of the Service or related to the Service may also violate laws in the United States and other countries, and we may cooperate with the applicable authorities, including by disclosing your identity and other Client Materials, as set forth in the RethinkED Privacy Notice.</li>
        </ul>
      </section>

      <section>
        <b>OUR LIABILITY</b>
        <ul className='margin-top-remove'>
          <li><b>Liabilities that we cannot Limit.</b> Nothing in these Terms excludes or limits our liability where it would be unlawful to do so, and nothing in these Terms affects any of your statutory rights.</li>
          <li><b>What we Cannot Guarantee.</b> Some jurisdictions do not allow the exclusion of implied warranties, therefore some of the following exclusions may not apply to you. EXCEPT FOR THE REPRESENTATIONS AND WARRANTIES EXPRESSLY SET FORTH IN THIS AGREEMENT, (A) WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES AND TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR USE (WHETHER OR NOT THE PURPOSE OR USE HAS BEEN DISCLOSED), WARRANTIES OF TITLE AND NON-INFRINGEMENT, ANY IMPLIED INDEMNIFICATION OBLIGATIONS, OR OTHER WARRANTIES THAT ARISE FROM TRADE USAGE OR CUSTOM, WITH RESPECT TO ANY ASPECTS OF THE SERVICE AND ANY COMPONENTS THEREOF OR ITS USE IN CONJUNCTION WITH USER MATERIALS OR THE OUTPUT OR RESULTS OBTAINED FROM SUCH USE, ANY DECISIONS MADE OR ACTIONS TAKEN IN RELIANCE THEREUPON OR AS TO THE PERFORMANCE THEREOF (WHETHER PERFORMED IN WHOLE, PART OR NOT AT ALL); (B) WE DO NOT GUARANTEE THE ADEQUACY, ACCURACY, TIMELINESS OR COMPLETENESS OF THE SERVICE OR ANY COMPONENTS OR OUTPUT THEREOF OR THAT IT WILL BE ERROR FREE OR FREE FROM MALWARE OR OTHER INFIRMITY OR CORRUPTION AND THEREFORE, WE SHALL NOT BE SUBJECT TO ANY DAMAGES OR LIABILITY FOR ANY ERRORS, OMISSIONS OR DELAYS THEREIN; AND (C) THE SERVICE AND ALL COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE IS AT YOUR OWN RISK.</li>
          <li><b>We do not offer medical advice.</b> This Service does not provide medical advice. The service is not intended to constitute: (a) the practice of medicine or the provision of health care diagnosis or treatment, (b) the creation of a physician-patient or clinical relationship, or (c) an endorsement, recommendation or sponsorship of any medical product or service by us. Additionally, this Service should not be used to attempt to diagnose a medical condition, or to attempt to assess the stage or other medical aspect of any person diagnosed with a medical condition. ALL CONTENT INCLUDED IN THE SERVICE IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO SUBSTITUTE MEDICAL PROFESSIONAL ADVICE. You should consult a medical professional with any questions you may have regarding a medical condition or treatment. Do not delay seeking advice from a medical professional because of content received from this Service. Do not use the Service for emergency medical services. In an emergency, call 911, your local emergency assistance number and/or your personal physician.</li>
          <li><b>We are not liable for third party services and products.</b> The Service may require the utilization of or integration with products, services and data contain that are made available by third party providers or may include links to third party websites. We do not control such third party products and services and assume no liability with respect to any of them. Your access and use rights to such third party products and websites may involve additional agreements between such third party provider and your Organization or may require you to agree to additional terms and conditions. All such additional, terms, conditions or agreements do not alter these Terms and you will be responsible for your use of such third party products and services, any associated costs and fees and any risks arising.</li>
          <li><b>We make no additional promises.</b> No opinion, advice or statement by us (including our officers, directors, employees, agents and affiliates) or by our providers or by any users, whether made on the Service, or related to it or otherwise, will create any representation or warranty or obligation under these Terms.</li>
          <li><b>Limitations on our liability.</b> IF YOU ARE DISSATISFIED WITH THE SERVICE YOUR REMEDY IS TO STOP USING IT. In no event shall we or our affiliates be liable to you or any third party for damages of any kind, including, but not limited to, any indirect, special, incidental, exemplary, punitive or consequential damages, including but not limited to loss of profits, lost time or goodwill, even if advised of the possibility of such damages, whether in contract, tort, strict liability or otherwise. Our liability will not exceed, in the aggregate for all claims, the lesser of: (i) the annual amount, paid or payable by your Organization pro-rated for your use of the Service, and (ii) $1,000. These limitations do not apply if we are grossly negligent, act willfully or where we cause bodily injury. Some laws do not allow the limitation or exclusion of liability, so these limits may not apply to you.</li>
        </ul>
      </section>

      <section>
        <b>INDEMNIFICATION</b>
        <ul className='margin-top-remove'>
          <li>
            <b>Your indemnification obligation to us.</b> You agree to indemnify and hold us harmless (including our officers, directors, equity holders, employees, agents, representatives and affiliates) and our providers, from and against any and all claims, damages, losses, costs (including reasonable attorneys’ fees), or other expenses that arise directly or indirectly out of or from: (a) any activity on your User Account in violation of the Terms, (b) your violations of applicable third party terms and agreements, if any, (c) your violations of applicable law, (d) your violation of the rights, including, intellectual property and privacy rights of any third party, (e) your User Materials, including without limitation your failure to secure all necessary rights, licenses and approvals such that we can utilize them as part of the Service, (f) the provision of services to students. You agree to cooperate fully in any Rethink inquiry concerning actual, alleged, or potential violations of the Terms.
          </li>
        </ul>
      </section>

      <section>
        <b>OTHER IMPORTANT TERMS</b>
        <ul className='margin-top-remove'>
          <li>
            <b>What happens if we have a legal dispute?</b>
            <ul>
              <li>We are based in New York State and therefore, the Terms are governed by and interpreted in accordance with the laws of the State of New York. These Terms are not governed by the United Nations Convention on Contracts for the International Sale of Goods or by the Uniform Computer Information Transactions Act, and both are expressly disclaimed.</li>
              <li>In connection with any dispute or litigation arising out of or relating to the Terms, you consent to the exclusive jurisdiction and venue in New York State courts located in the County of New York, in the borough of Manhattan and in the federal courts located in the Southern District of New York.</li>
              <li>We will be allowed to bring action against you in any jurisdiction.</li>
            </ul>
          </li>
          <li><b>Circumstances outside our control.</b> While we will endeavor to provide the Service to you, you understand and agree that we would not be liable for delays or failure in the performance or delivery of Services due to acts of God, unforeseen circumstances or other causes beyond our reasonable control.</li>
          <li><b>Sections that survive termination of these Terms.</b> All sections of the Terms which by their nature survive termination shall continue in full force and effect beyond any termination of the Terms, including, without limitation, all provisions which relate to proprietary rights, disclaimers, limitation of liability, waiver and indemnification.</li>
          <li><b>Severability of terms.</b> If one or more of the provisions of these Terms shall be held unenforceable by a court in any applicable jurisdiction respect, then such provision shall be deleted from the Terms to the minimum extent, and the remaining provisions will continue in full force and effect. Any such judicial interpretation requiring limitation or deletion of a provision shall be valid only in the jurisdiction in which such interpretation is made.</li>
          <li><b>Conduct does not waive rights.</b> No waiver by us of any term or condition set out in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by us to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.</li>
        </ul>
      </section>

      <section>
        <b>NOTIFICATIONS AND CONTACT INFORMATION</b>
        <ul className='margin-top-remove'>
          <li><b>How we will notify you of changes.</b> Rethink may place a notice across its pages to alert you to certain changes such as material modifications to the Terms or the Service. Alternatively, notice may be in the form of an email, telephone or via postal services.</li>
          <li><b>Our Contact Information.</b></li>
          <ul>
            <li>If you have any questions relating to the Terms or the Service or any other Rethink products and services, please contact: <a href={`mailto:${infoRethinkEmail}`}>{infoRethinkEmail}</a> or write to our address below.</li>
            <li>If you have any reason for a complaint, please contact <a href={`mailto:${supportRethinkEmail}`}>{supportRethinkEmail}</a> or write to our address below and we will endeavor to resolve the issue.</li>
            <li>Our address is: Rethink First, 49 W. 27th St., 8th Fl. New York, NY 10001. You agree that the only way to provide us legal notice is at the addresses provided above.</li>
          </ul>
        </ul>
      </section>

      <section>
        <b>Effective Date.</b> Effective Date. This version of the Terms is effective as of August 1, 2020.
      </section>
    </div>
  ), []);

  const {
    handleAcceptTermsOfUse,
    loading,
    tosFlag,
  } = useTermsOfUse();
  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div>
        <CustomDialog
          className='terms-of-service'
          title={tosFlag ? (
            <div className='already-accepted'>
              You have already accepted VIZZLE© TERMS OF USE.
            </div>
          ) : null}
          openDialog
          displayCloseIcon={false}
          content={content}
          actions={
            tosFlag ? [] : [
              {

                title: 'I ACCEPT',
                color: 'primary',
                className: 'btn-green-background action-button accept-button',
                loading,
                onClick: handleAcceptTermsOfUse,
              },
              {

                title: 'I DO NOT ACCEPT',
                color: 'primary',
                className: 'btn-danger action-button',
                disabled: loading,
                onClick: () => {
                  logout(false);
                },
              },
            ]
          }
        />
      </div>
    </VizzleMainContainer>
  );
};

export default TermsOfUse;

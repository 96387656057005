import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import VideoCamIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import './FillInTheBlankPageEditor.scss';

const SECTION_IMAGE = 'image';
const SECTION_AUDIO = 'audio';
const SECTION_FITB = 'fitb';

/**
 * Lesson activity for Book
 */
export default function FillInTheBlankPageEditor({
  cardConfig,
  selectedPageIndex,
  selectedSection,
  onImageSelected,
  onAudioSelected,
  onFitbSelected,
}) {
  return (
    <div className='fitb-page-editor-component vizzle-fitb-card-editor'>
      {
        cardConfig.map((c, index) => {
          const caredId = uuidv1();
          return (
            <Card
              id={caredId}
              key={caredId}
              className={`
              fitb-page-editor
              ${selectedPageIndex === index ? 'fitb-card-editor-selected' : ''}
              vizzle-book-card-editor-page-${index}
            `}
            >
              <CardActionArea
                className={`fitb-page-editor-image-action 
                  ${selectedSection === SECTION_IMAGE ? 'fitb-page-editor-section-selected' : ''} 
                  vizzle-fitb-page-editor-image`}
                onClick={() => { onImageSelected(index, SECTION_IMAGE); }}
              >
                {
                  c.image ? (
                    <CardMedia
                      className='fitb-page-editor-image'
                      image={c.image}
                      title='Book'
                    >
                      {
                        c.video ? <VideoCamIcon className='fitb-page-editor-with-video-icon' /> : ''
                      }

                    </CardMedia>
                  )
                    : (
                      <Box
                        component='div'
                        className='fitb-page-editor-placeholder'
                      >
                        Image/Video
                      </Box>
                    )
                }
              </CardActionArea>
              <CardActionArea
                className={`fitb-page-editor-action 
                  ${selectedSection === SECTION_AUDIO ? 'fitb-page-editor-section-selected' : ''} 
                  `}
                onClick={() => { onAudioSelected(index, SECTION_AUDIO); }}
              >
                {c.audio
                  ? (
                    <Box className='fitb-page-editor-with-value'>
                      <VolumeUpIcon className='fitb-page-editor-with-icon' />
                    </Box>
                  ) : (
                    <Box
                      component='div'
                      className='fitb-page-editor-placeholder'
                    >
                      Audio
                    </Box>
                  )}
              </CardActionArea>
              <CardActionArea
                className={`fitb-page-editor-action 
                  ${selectedSection === SECTION_FITB ? 'fitb-page-editor-section-selected' : ''} 
                  `}
                onClick={() => { onFitbSelected(index, SECTION_FITB); }}
              >
                {c.fitbList && c.fitbList.length > 0
                  ? (
                    <Box className='fitb-page-editor-with-value'>
                      There are {c.fitbList.length} Fill in the Blank.
                    </Box>
                  ) : (
                    <Box
                      component='div'
                      className='fitb-page-editor-placeholder'
                    >
                      Fill in the Blank Questions
                    </Box>
                  )}
              </CardActionArea>
            </Card>
          );
        })
      }
    </div>
  );
}

FillInTheBlankPageEditor.defaultProps = {
  cardConfig: {
    card: {
      audio: null,
      image: null,
      video: null,
    },
    fitbList: [],
  },
  selectedPageIndex: 0,
  selectedSection: SECTION_IMAGE,
  onImageSelected: () => { },
  onFitbSelected: () => { },
  onAudioSelected: () => { },
};

FillInTheBlankPageEditor.propTypes = {
  cardConfig: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.shape({
        audio: PropTypes.string,
        image: PropTypes.string,
        video: PropTypes.string,
      }),
      fitbList: PropTypes.arrayOf(PropTypes.shape({
        answers: PropTypes.array,
        question: PropTypes.shape({
          question: PropTypes.string,
        }),
      })),
    }),
  ),
  selectedPageIndex: PropTypes.number,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_FITB, SECTION_AUDIO]),
  onImageSelected: PropTypes.func,
  onFitbSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
};

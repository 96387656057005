import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../text/TextLines';
import MediaPlayer from '../../../media/MediaPlayer';
import { getPageStyle } from '../../common/LessonPageUtil';
import './MatchingResponse.scss';

/**
 * Matching responses
 */
export default function MatchingResponse({ responseCard, index, cardId }) {
  return (
    <Card
      className='matching-component-card'
      style={getPageStyle(responseCard, true)}
    >
      {
        responseCard.image ? (
          <CardMedia
            className='matching-component-image'
            image={responseCard.image}
            title='Matching'
          />
        ) : ''
      }
      {responseCard.text
        ? (
          <CardContent className='matching-component-text'>

            <TextLines textLines={responseCard.text} />

          </CardContent>
        ) : ''}
      {
        (responseCard.audio || responseCard.video)
        && (
          <MediaPlayer
            audioUrl={responseCard.audio}
            videoUrl={responseCard.video}
            parentElementId={cardId}
            className={`vizzle-matching-responses-page-${index}-media-btn`}
          />
        )
      }
    </Card>
  );
}

MatchingResponse.defaultProps = {
  responseCard: {},
};

MatchingResponse.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  cardId: PropTypes.string.isRequired,
};

import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AddButton from '../../../components/buttons/AddButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import LicenseForm from './forms/LicenseForm';
import { useAddLicenseData } from './LicenseActionHooks';
import DateUtils from '../../../../../utils/DateUtils';
import { useAdmin } from '../../../context/AdminContextProvider';

const currentDate = DateUtils.getCurrentDate();
const currentDatePlus30Days = DateUtils.get30DaysFromCurrentDate();

const AddLicenseAction = ({
  onLicenseAddSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const [isDataValid, setIsDataValid] = useState(false);

  const data = useRef(null);

  const { adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;

  const { loading, addLicenseData } = useAddLicenseData({
    setOpen,
    onLicenseAddSuccess,
  });

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleOnDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    data.current = updatedData;
  };

  const confirmActions = () => {
    const buttons = [
      {
        onClick: handleOnCancel,
        className: 'btn-danger margin-right',
        title: 'Cancel',
        loading,
      },
      {
        onClick: () => {
          addLicenseData(data.current);
        },
        className: 'btn-primary action-button',
        title: 'Save',
        disabled: !isDataValid,
        loading,
      },
    ];
    return buttons;
  };

  const licenseData = {
    teacherId: null,
    active: true,
    quantity: 7,
    type: 'PAID',
    startDate: currentDate,
    expirationDate: currentDatePlus30Days,
  };

  return (
    <>
      <AddButton
        ariaLabel='Add License'
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedSchoolId}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='add-license-dialog'
          onClose={handleOnCancel}
          title='Details: Add License'
          content={(
            <LicenseForm
              licenseData={licenseData}
              onDataChange={handleOnDataChange}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

AddLicenseAction.defaultProps = {
  onLicenseAddSuccess: () => null,
};

AddLicenseAction.propTypes = {
  onLicenseAddSuccess: PropTypes.func,
};

export default AddLicenseAction;

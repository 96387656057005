import PropTypes from 'prop-types';
import CopyToEditAction from '../../../../lessons/lessonactions/CopyToEditAction';
import { useMyLessonsContext } from '../context/MyLessonsContextProvider';
import { sortLessonByName } from '../../../../../utils/LessonsMapping';

/**
 * Copy to edit Lesson Action
 */
export default function MyLessonCopyToEditAction({ currentUser, lesson, rowIndex, moveCardIndexFunc, cardIndex }) {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData: { lessons, included } } = myLessonsDomain.domainData;

  const handleOnCopySuccess = async (coppiedLesson) => {
    // Add to the current row
    const subject = coppiedLesson.attributes.subjects[0];
    const subjectRowIndex = subject ? lessons.findIndex((l) => l.subject === subject) : lessons.length - 1;

    const currentRowLessons = lessons[subjectRowIndex].lessons;
    currentRowLessons.push(coppiedLesson);
    lessons[subjectRowIndex].lessons = sortLessonByName(currentRowLessons);

    await myLessonsDomain.updateLessons([...lessons], included);
    let index = cardIndex + 1;
    if (rowIndex > 0) {
      const result = lesson.attributes.name.trim().toLowerCase().localeCompare(coppiedLesson.attributes.name, undefined, { numeric: true, sensitivity: 'base' });
      index = result === -1 ? cardIndex : cardIndex + 1;
    }

    moveCardIndexFunc(index);
  };

  return (
    <CopyToEditAction
      lesson={lesson}
      currentUser={currentUser}
      onCopySuccess={handleOnCopySuccess}
    />
  );
}

MyLessonCopyToEditAction.defaultProps = {
  moveCardIndexFunc: () => {},
};

MyLessonCopyToEditAction.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  rowIndex: PropTypes.number.isRequired,
  moveCardIndexFunc: PropTypes.func,
  cardIndex: PropTypes.number.isRequired,
};

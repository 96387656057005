import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import './ButtonSelector.scss';

/**
 * Radio buttons with button selection style that
 * allow the user to select one option from a set
 *
 * @param props of array of config object. An example is
 * config: [
 *  {
 *    label: 'Build A Book',
 *    onClick: () => { },
 *    dataValue: 'buildABook',
 *  },
 *  {
 *    label: 'Matching',
 *    onClick: () => { },
 *    dataValue: 'matching',
 *    disabled: true,
 *  },
 * ]
 *
 */
export default function ButtonSelector({
  config,
  selectedValue,
  onSelect,
  alignment,
  className,
  ...props
}) {
  const handleOnClick = (dataValue) => {
    if (dataValue === selectedValue) {
      return;
    }

    onSelect(dataValue);
  };

  const alignmentClass = {
    horizental: 'button-selector-component-horizental',
    vertical: 'button-selector-component-vertical',
  };

  return (
    <div {...props} className={`${alignmentClass[alignment]} ${className}`}>
      {config.map((c, index) => (
        <Button
          data-test={`button-${c.dataValue}`}
          key={c.dataValue}
          disabled={c.disabled}
          onClick={() => handleOnClick(c.dataValue)}
          variant='contained'
          className={`vizzle-selector-${index} button-selector btn-primary ${selectedValue === c.dataValue ? 'btn-selected' : ''}`}
        >
          {c.label}
        </Button>
      ))}
    </div>
  );
}

ButtonSelector.defaultProps = {
  selectedValue: null,
  onSelect: () => { },
  alignment: 'horizental',
  className: '',
};

ButtonSelector.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    dataValue: PropTypes.any,
    disabled: PropTypes.bool,
  })).isRequired,
  // eslint-disable-next-line
  selectedValue: PropTypes.any,
  onSelect: PropTypes.func,
  alignment: PropTypes.oneOf(['horizental', 'vertical']),
  className: PropTypes.string,
};

import { useEffect } from 'react';
import { useDomain } from '../../../states/AppDomainProvider';
import ObjectUtils from '../../../utils/ObjectUtils';
import { getLessonStandards } from '../../../services/LessonStandardService';
import { useLessonPlay } from '../../lessonplay/context/LessonPlayContextProvider';
import { CELEBRATION_STYLE_KEY } from '../../../constants/PlayPreferenceConstant';

/**
 * Init data for the application
 *
 * @export
 * @returns
 */
export default function useInitilizeDataForAuthoring() {
  const { lessonStandardDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const getLessonStandardList = async (state) => {
    const { lessonStandards } = lessonStandardDomain.domainData;
    if ((!user.userModerator && ObjectUtils.isEmpty(lessonStandards)) || user.userModerator || user.unitCreator) {
      await lessonStandardDomain.updateIsLoading(true);
      const standards = await getLessonStandards(false, state);
      await lessonStandardDomain.updateLessonStandards(standards, false);
    }
  };

  useEffect(() => {
    // there are too many states standards for moderator users, we need to force to user to first choose a state.
    if (user.userModerator || user.unitCreator) {
      return;
    }
    getLessonStandardList();
    // eslint-disable-next-line
  }, []);
  return {
    getLessonStandardList,
    userModerator: user.userModerator,
  };
}

export const useGetLessonStandardsByUser = () => {
  const { lessonStandardDomain } = useDomain();
  const { lessonStandards } = lessonStandardDomain.domainData;
  return lessonStandards;
};

const basicThemeFolder = '_basic_';
const preferencePath = 'preference/';
const lottiePath = 'lottie/';

export const usePrepareLessonPlayDomain = () => {
  const {
    userDomain,
  } = useDomain();

  const {
    lessonPlayDomain,
  } = useLessonPlay();

  useEffect(() => {
    const { user, terminologies, userProfile } = userDomain.domainData;

    const { reinforcers } = terminologies;
    const coppiedReinforcers = JSON.parse(JSON.stringify(reinforcers));

    const orginalReinforcers = coppiedReinforcers
      .filter((reinforcer) => reinforcer.id.trim().length > 0)
      .map((reinforcer) => {
        const { manifest } = reinforcer;
        manifest.forEach((m) => {
          const { src } = m;
          const path = src.substring(src.indexOf(preferencePath)).replace(preferencePath, '').replace(lottiePath, '');
          const themeFolder = path.substring(0, path.indexOf('/'));
          if (themeFolder !== basicThemeFolder) {
            const newSrc = src.replace(`/${themeFolder}/`, `/${basicThemeFolder}/`);
            // eslint-disable-next-line no-param-reassign
            m.src = newSrc;
          }
        });

        return reinforcer;
      });

    lessonPlayDomain.updateLessonPagesData({
      lesson: {},
      lessonPagesData: [],
      user,
      userProfile: {
        ...userProfile,
        prompt: true,
        tts: {
          ...userProfile.tts,
          enabled: true,
        },
        switchAccessibility: 0,
        interactionType: 2,
        playerWorld: undefined,
        reinforcerStyle: userProfile.reinforcerStyle,
        celebrationStyle: CELEBRATION_STYLE_KEY.none,
        reinforcerSound: userProfile.reinforcerSound,
        celebrationSound: 0,
      },
      terminologies: {
        ...terminologies,
        reinforcers: orginalReinforcers,
      },
      isPreviewMode: true,
      lessonPagesDataSnapshot: [],
      isPlayable: true,
      currentPageIndex: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

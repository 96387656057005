import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@mui/material';
import MatchingEditor from '../../../../../../../../components/vizzle/page/matching/MatchingEditor';
import ChooseNumberOfCards from '../../matching/ChooseNumberOfCards';
import { useDomain } from '../../../../../../../../states/AppDomainProvider';
import { AREA_PROMPT_CARDS, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../../../../../../../../components/vizzle/page/common/CardEditorConstants';
import { getInitialDataForMatching } from '../../../../../../../../utils/activitytype/ActivityTypeInitialData';
import GrayTransparentBlocker from '../../../../../../../../components/blocker/GrayTransparentBlocker';
import TextAndMediaFormatting from '../../TextAndMediaFormatting';
import AddPhotoOrVideo from '../../AddPhotoOrVideo';
import AddAudio from '../../AddAudio';
import { landingInteractionTypes } from './LandingInteractionUtils';
import { isResponseCardEmpty } from '../../../../../../../../utils/activitytype/ActivityTypeEmptyChecker';
import { flatten, shuffle, uniqueObject } from '../../../../../../../../utils/ArrayUtils';
import { checkHasNon18FontSize, checkHasNonJosefinSlab, getExceedCharLimitMessage, getTotalCharsLength, useHandleTextEditorWarning } from '../../../../../hooks/TextEditorWarningHooks';

const MatchingPopupEditor = ({ data, onDataChange }) => {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const [blockScreen, setBlockScreen] = useState(false);
  const initState = {
    selectedArea: AREA_PROMPT_CARDS,
    selectedCardIndex: 0,
    selectedSection: SECTION_IMAGE,
  };
  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const matchingEditorProps = {
    selectedSection: {
      ...currentSelectedData,
    },
    onCardSelected: (selectedSection) => {
      setCurrentSelectedData(selectedSection);
    },
    promptCards: [{
      text: data.questionCard ? data.questionCard.text : null,
      image: data.questionCard ? data.questionCard.image : '',
      audio: data.questionCard ? data.questionCard.audio : '',
      video: data.questionCard ? data.questionCard.video : '',
      questionKey: data.questionCard ? data.questionCard.key : '',
    }],
    responseCards: (data.answerCards || []).filter((card) => (
      !card.hide
    )).map((card, index) => ({
      text: card.text,
      image: card.image,
      audio: card.audio,
      video: card.video,
      answerKey: data.answerCards[index].answerKey,
    })),
    onQuestionKeyChange: async (questionKeys) => {
      const currentQuestionKey = data.questionCard.key || '';
      const questionKey = questionKeys[0];
      if (currentQuestionKey === questionKey) {
        return;
      }
      const newData = {
        ...data,
        questionCard: {
          ...data.questionCard,
          key: questionKey,
        },
      };

      const otherMatchingPages = selectedPage.gameCards
        .filter((p) => (
          p
          && p.landingInteraction
          && p.landingInteraction.answerCards
          && p.landingInteraction.type === landingInteractionTypes[2].value
        ));
      if (otherMatchingPages && otherMatchingPages.length > 0
        && questionKey) {
        let answerCardPool = shuffle(
          flatten(otherMatchingPages
            .map((p) => (p.landingInteraction.answerCards)))
            .filter((card) => (
              card
              && !card.hide
              && !isResponseCardEmpty(card)
              && questionKey !== card.answerKey
            )),
        );

        // Remove the unwanted attributes
        answerCardPool = answerCardPool.map((c) => {
          const { correctAnswer, ...rest } = c;
          return rest;
        });

        // Remove duplicated objects
        answerCardPool = uniqueObject(answerCardPool);

        // pre-populate the response cards
        const responseCardsLength = matchingEditorProps.responseCards.length;
        // Do not copy the first answer card
        for (let i = 1; i < responseCardsLength; i++) {
          const answerCard = answerCardPool[i - 1];
          if (isResponseCardEmpty(data.answerCards[i]) && answerCard) {
            const dataToCopy = JSON.parse(JSON.stringify(answerCard));
            newData.answerCards[i] = dataToCopy;
          }
        }
      }

      // This answer key of the first response must the same as question key
      // eslint-disable-next-line prefer-destructuring
      newData.answerCards[0].answerKey = questionKey;

      onDataChange(newData);
    },
    onAnswerKeyChange: async (answerKeys) => {
      const newData = {
        ...data,
      };
      answerKeys.forEach((key, index) => {
        newData.answerCards[index].answerKey = key;
      });
      onDataChange(newData);
    },
  };

  const handleOnSliderChange = (event, value) => {
    event.stopPropagation();
    const currentResponseCards = [...data.answerCards];
    const currentNumberOfResponseCards = data.answerCards.filter((card) => (
      !card.hide
    )).length;
    if (currentNumberOfResponseCards > value) {
      for (let i = value; i < currentNumberOfResponseCards; i++) {
        currentResponseCards[i].hide = true;
      }
    } else if (currentNumberOfResponseCards < value) {
      for (let i = 0; i < value; i++) {
        if (!currentResponseCards[i]) {
          currentResponseCards[i] = {};
        }
        currentResponseCards[i].hide = false;
      }
    }

    const newData = {
      ...data,
      answerCards: [
        ...currentResponseCards,
      ],
    };
    onDataChange(newData);
  };

  useEffect(() => {
    if (!data.answerCards || data.answerCards.length === 0) {
      const initialData = getInitialDataForMatching();

      const newData = {
        ...data,
        questionCard: initialData.promptCards[0],
        answerCards: [
          ...initialData.responseCards,
        ],
      };
      onDataChange(newData);
    }
    // eslint-disable-next-line
  }, []);

  const questionKey = data.questionCard ? data.questionCard.key : null;
  useEffect(() => {
    if (!questionKey) {
      setBlockScreen(true);
    } else {
      setBlockScreen(false);
    }
    return () => {
      setBlockScreen(false);
    };
    // eslint-disable-next-line
  }, [questionKey]);

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }
    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }
    const hasNon18FontSize = checkHasNon18FontSize(textLines);
    if (hasNon18FontSize) {
      closeTextEditorWarning();
      return;
    }

    const charLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 50 : 54;
    const totalCharsLength = getTotalCharsLength(textLines);
    if (totalCharsLength > charLimit) {
      showTextEditorWarning(getExceedCharLimitMessage(charLimit));
      return;
    }
    closeTextEditorWarning();
    // const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    // if (!hasNonJosefinSlab) {
    //   const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //   if (!hasNon18FontSize) {
    //     const lineLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 2 : 3;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 25 : 18;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // }
    // closeTextEditorWarning();
  };

  useEffect(() => {
    const fieldName = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 'questionCard' : 'answerCards';
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS && data.questionCard) {
      const { text } = data.questionCard;
      onTextChange(text && text.lines);
    } else if (data[fieldName]) {
      const card = data[fieldName][currentSelectedData.selectedCardIndex];
      if (card) {
        const { text } = card;
        onTextChange(text && text.lines);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  const onTextApply = (textLines) => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      if (!newData.questionCard.text) {
        newData.questionCard.text = {};
      }
      newData.questionCard.text.lines = [...textLines];
    } else {
      if (!newData.answerCards[currentSelectedData.selectedCardIndex].text) {
        newData.answerCards[currentSelectedData.selectedCardIndex].text = {};
      }
      newData.answerCards[currentSelectedData.selectedCardIndex].text.lines = [...textLines];
    }
    onDataChange(newData);
  };

  const onTextRemove = () => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      newData.questionCard.text = null;
    } else {
      newData.answerCards[currentSelectedData.selectedCardIndex].text = null;
    }
    onDataChange(newData);
    closeTextEditorWarning();
  };

  const getImage = () => {
    if (!data.questionCard) {
      return '';
    }
    return currentSelectedData.selectedArea === AREA_PROMPT_CARDS
      ? data.questionCard.image
      : data.answerCards[currentSelectedData.selectedCardIndex].image;
  };

  const onImageApply = (media) => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      newData.questionCard.image = media.selectedImageUrl;
      newData.questionCard.video = media.selectedVideoUrl;
    } else {
      newData.answerCards[currentSelectedData.selectedCardIndex].image = media.selectedImageUrl;
      newData.answerCards[currentSelectedData.selectedCardIndex].video = media.selectedVideoUrl;
    }
    onDataChange(newData);
  };

  const onImageRemove = () => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      newData.questionCard.image = null;
      newData.questionCard.video = null;
    } else {
      newData.answerCards[currentSelectedData.selectedCardIndex].image = null;
      newData.answerCards[currentSelectedData.selectedCardIndex].video = null;
    }
    onDataChange(newData);
  };

  const onAudioApply = (audioData) => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      newData.questionCard.audio = audioData;
    } else {
      newData.answerCards[currentSelectedData.selectedCardIndex].audio = audioData;
    }
    onDataChange(newData);
  };

  const onAudioRemoved = () => {
    const newData = {
      ...data,
    };
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      newData.questionCard.audio = null;
    } else {
      newData.answerCards[currentSelectedData.selectedCardIndex].audio = null;
    }
    onDataChange(newData);
  };

  return (
    <Grid container spacing={3} wrap='nowrap' style={{ height: 'inherit', padding: '8px' }}>
      <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit' }}>
        <Box width='100%' my='auto' ml='15px' display='flex' flexDirection='row'>
          <ChooseNumberOfCards
            flexDirection='row'
            sliderWidth='50%'
            onSliderChange={handleOnSliderChange}
            value={
              data.answerCards ? data.answerCards.filter((card) => (
                !card.hide
              )).length : null
            }
          />
        </Box>
        <Box height='85%'>
          <MatchingEditor {...matchingEditorProps} />
        </Box>
      </Grid>
      <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit' }}>
        <Box height='100%' className='center'>
          {currentSelectedData.selectedSection === SECTION_TEXT && (
            <Box height='100%'>
              <TextAndMediaFormatting
                text={
                  currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                    ? data.questionCard.text
                    : data.answerCards[currentSelectedData.selectedCardIndex].text
                }
                onTextApply={onTextApply}
                onTextRemoved={onTextRemove}
                onTextChange={onTextChange}
                textEditorWarning={textEditorWarning}
              />
              <br />
            </Box>
          )}
          {currentSelectedData.selectedSection === SECTION_IMAGE && (
            <AddPhotoOrVideo
              image={getImage()}
              onSelect={onImageApply}
              onRemove={onImageRemove}
            />
          )}
          {currentSelectedData.selectedSection === SECTION_AUDIO && (
            <AddAudio
              audio={
                currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                  ? data.questionCard.audio
                  : data.answerCards[currentSelectedData.selectedCardIndex].audio
              }
              onAudioApply={onAudioApply}
              onAudioRemoved={onAudioRemoved}
            />
          )}
        </Box>
      </Grid>
      <GrayTransparentBlocker show={blockScreen} />
    </Grid>
  );
};

MatchingPopupEditor.defaultProps = {
  data: { questionCard: {} },
  onDataChange: () => { },
};

MatchingPopupEditor.propTypes = {
  data: PropTypes.shape({
    questionCard: PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
      key: PropTypes.string,
    }),
    answerCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
      answerKey: PropTypes.string,
    })),
  }),
  onDataChange: PropTypes.func,
};

export default MatchingPopupEditor;

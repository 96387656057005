import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import { getPageStyle } from '../common/LessonPageUtil';
import './BookCardThumbnail.scss';

/**
 * Lesson activity for Book
 */
export default function BookCardThumbnail({ layoutConfig }) {
  return (
    <div className='book-card-thumbnail-component vizzle-book-card-thumbnail'>
      {
        layoutConfig.map((c, index) => {
          const caredId = uuidv1();
          return (
            <Card
              id={caredId}
              key={caredId}
              className={`book-card-thumbnail vizzle-book-card-thumbnail-page-${index}`}
              style={getPageStyle(c)}
            >
              {
                c.image ? (
                  <CardMedia
                    className='book-card-thumbnail-image'
                    image={c.image}
                    title='Book'
                  />
                )
                  : (
                    <Box
                      component='div'
                      className='book-card-thumbnail-placeholder'
                    >
                      Image/Video
                    </Box>
                  )
              }
              <CardContent className={`book-card-thumbnail-text ${!c.text ? 'book-card-thumbnail-text-background' : ''}`}>
                {c.text
                  ? (
                    <Box className='book-card-thumbnail-text-with-value'>
                      <TextLines textLines={c.text} isThumbnail />
                    </Box>
                  ) : (
                    <Box className='book-card-thumbnail-placeholder-text'>Text</Box>
                  )}
              </CardContent>
            </Card>
          );
        })
      }
    </div>
  );
}

BookCardThumbnail.defaultProps = {
  layoutConfig: [],
};

BookCardThumbnail.propTypes = {
  layoutConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
};

import BaseDomain from '../../../states/domains/BaseDomain';

const EXTENSION_ACTIVITIES_UPDATE_DATA = 'EXTENSION_ACTIVITIES_UPDATE_DATA';
const EXTENSION_ACTIVITIES_UPDATE_SELECTED_ACTIVITY_IDS = 'EXTENSION_ACTIVITIES_UPDATE_SELECTED_ACTIVITY_IDS';
const EXTENSION_ACTIVITIES_UPDATE_DATA_AND_SELECTED_ACTIVITY_IDS = 'EXTENSION_ACTIVITIES_UPDATE_DATA_AND_SELECTED_ACTIVITY_IDS';

const initialState = {
  extensionActivities: { activities: [] },
  selectedActivityIds: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case EXTENSION_ACTIVITIES_UPDATE_DATA:
      return {
        ...state,
        extensionActivities: action.payload,
      };
    case EXTENSION_ACTIVITIES_UPDATE_SELECTED_ACTIVITY_IDS:
      return {
        ...state,
        selectedActivityIds: action.payload,
      };
    case EXTENSION_ACTIVITIES_UPDATE_DATA_AND_SELECTED_ACTIVITY_IDS:
      return {
        ...state,
        extensionActivities: {
          ...state.extensionActivities,
          activities: action.payload.activities,
        },
        selectedActivityIds: action.payload.selectedIds,
      };
    default:
      return state;
  }
};

const domainName = 'extensionActivitiesDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateExtensionActivitiesData(extensionActivitiesData) {
    return this.dispatch({
      type: EXTENSION_ACTIVITIES_UPDATE_DATA,
      payload: extensionActivitiesData,
    });
  }

  async updateSelectedExtensionActivityIds(selectedIds) {
    return this.dispatch({
      type: EXTENSION_ACTIVITIES_UPDATE_SELECTED_ACTIVITY_IDS,
      payload: selectedIds,
    });
  }

  async updateExtensionActivitiesDataAndSelectedId(activities, selectedIds) {
    return this.dispatch({
      type: EXTENSION_ACTIVITIES_UPDATE_DATA_AND_SELECTED_ACTIVITY_IDS,
      payload: {
        activities,
        selectedIds,
      },
    });
  }
}

import { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import Accessibility from './accessibility/Accessibility';
import InteractionSettings from './interaction/Interaction';
import StudentInfo from './profile/StudentInfo';
// import SeasonalFilter from './seasonal/SeasonalFilter';
import CelebrationSetting from './celebrations/CelebrationSetting';
import ReinforcersSetting from './reinforcers/ReinforcersSetting';
import ThemeSettingV2 from './theme/ThemeSettingV2';
import PlayModeSetting from './play-mode/PlayModeSetting';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import './MyStudentsSettings.scss';
import { studentViewName } from '../../../../services/StudentService';
import ChurnZeroService from '../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../constants/ChurnZeroConstant';
import { updateCredentialTooltipText } from '../../../../texts/TooltipText';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';

const MyStudentSettings = () => {
  const { uiDomain, studentsDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { selectedStudentProfile, loadingStudent } = myStudentDomain.domainData;
  const { b2cId } = selectedStudentProfile;
  const isB2cId = Boolean(b2cId);

  useEffect(() => {
    if (loadingStudent) {
      uiDomain.showLoader('Getting student profile', LOADER_TYPE.HASH_LOADER);
    } else {
      uiDomain.hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStudent]);
  const components = [
    { title: 'Student Information', component: <StudentInfo />, tooltip: isB2cId ? updateCredentialTooltipText : null },
    { title: 'Play Mode', component: <PlayModeSetting /> },
    { title: 'Interaction Options', component: <InteractionSettings /> },
    { title: 'Accessibility Options', component: <Accessibility /> },
    // { title: 'Seasonal Filter', component: <SeasonalFilter /> },
    { title: 'Theme', component: <ThemeSettingV2 /> },
    { title: 'Celebrations', component: <CelebrationSetting /> },
    { title: 'Reinforcers', component: <ReinforcersSetting /> },
  ];

  useEffect(() => {
    if (currentSelectedStudentId) {
      studentViewName(currentSelectedStudentId);
    }
  }, [currentSelectedStudentId]);

  useEffect(() => {
    if (currentSelectedStudentId && user) {
      ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.SETUP, 'Visit student preferences', user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className='my-student-settings'>
      <div className='student-settings-list'>
        {selectedStudentProfile && selectedStudentProfile.id
          ? components.map((entry, index) => (
            <div
              key={`student-setting-entry-${index}`}
              className='student-setting-entry'
            >
              <Typography variant='h4' className='student-setting-title-label'>
                <Stack direction='row' gap='10px' alignItems='center'>
                  {entry.title} {entry.tooltip ? <TooltipWithIndicator {...updateCredentialTooltipText} /> : null}
                </Stack>
              </Typography>
              {entry.component}
            </div>
          ))
          : null}
      </div>
    </div>
  );
};
export default MyStudentSettings;

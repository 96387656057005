import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import './ResetPasswordConfirmDialog.scss';

export default function ResetPasswordConfirmDialog({ open, onClose, onNext }) {
  return (
    <CustomDialog
      openDialog={open}
      className='reset-password-comfirm-dialog'
      onClose={() => {
        onClose();
      }}
      content={(
        <div className='reset-password-confirm'>
          <Typography variant='h6'>Reset Password</Typography>
          <Typography>Are you sure you want to reset your password?</Typography>
        </div>
      )}
      displayCloseIcon
      actions={[{
        onClick: () => {
          onNext();
        },
        className: 'btn-primary',
        title: 'Next',
      },
      ]}
    />
  );
}

ResetPasswordConfirmDialog.defaultProps = {
  open: false,
  onClose: () => { },
  onNext: () => { },
};

ResetPasswordConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
};

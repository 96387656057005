/* eslint-disable no-param-reassign */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil from '../CelebrationUtil';

const CANVAS_WIDTH = 800;
const FORCE_LAUNCH_TIME = 7000;
const MAX_PARTICLE_COUNT = 200;
const PARTICLES_PER_FRAME = 1;
const PARTICLE_SCALE = 0.75;
const PARTICLE_GROWTH = 1.01;
const PARTICLE_LIFE = 120;

const RocketCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    tweens: [],
    timeouts: [],
    frame: 0,
    countdownSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'countdown')].sound,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
  };

  const playCountdownSound = () => {
    if (app.countdownSound) {
      app.countdownSound.currentTime = 0;
      app.countdownSound.play({ start: 0, volume: 1 });
    }
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.currentTime = 0;
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const loadBitmaps = () => {
    app.background = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'background')].texture);
    app.flame = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'flame')].texture);
    app.flameBounds = app.flame.getBounds();
    app.rocket = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'rocket')].texture);
    app.rocketBounds = app.rocket.getBounds();
    app.gantry = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'gantry')].texture);
    app.launchpad = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'launchpad')].texture);
    app.backgroundBounds = app.background.getBounds();
    app.cloud = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'cloud')].texture);
    app.cloudBounds = app.cloud.getBounds();
    app.button = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'button')].texture);
    app.buttonBounds = app.button.getBounds();
    app.launchButton = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'launchButton')].texture);
    app.launchButtonBounds = app.launchButton.getBounds();
    app.ufo = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'ufo')].texture);
    app.ufoBounds = app.ufo.getBounds();
    app.goodJob = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'goodJob')].texture);
    app.goodJobBounds = app.goodJob.getBounds();
  };

  const createBackground = () => {
    app.background.x = 0;
    app.background.y = 0;
    app.wrapper.addChild(app.background);
  };

  const createRocketGroup = () => {
    app.rocketGroup = new PIXI.Container();
    app.rocketGroup.x = app.backgroundBounds.width / 2 - app.rocketBounds.width / 2;
    app.rocketGroup.y = app.backgroundBounds.height - app.rocketBounds.height - 156;
    app.wrapper.addChild(app.rocketGroup);

    app.flame.x = app.rocketBounds.width / 2 - app.flameBounds.width / 2;
    app.flame.y = app.rocketBounds.height - 85;
    app.flame.visible = false;
    app.rocketGroup.addChild(app.flame);

    app.rocket.x = 0;
    app.rocket.y = 0;
    app.rocketGroup.addChild(app.rocket);
  };

  const createLaunchpad = () => {
    createRocketGroup();

    app.gantry.x = app.backgroundBounds.width / 2 - 90;
    app.gantry.y = app.backgroundBounds.height - 490;
    app.wrapper.addChild(app.gantry);

    app.launchpad.x = 0;
    app.launchpad.y = app.backgroundBounds.height - app.launchpad.getBounds().height;
    app.wrapper.addChild(app.launchpad);
  };

  const createButton = (argIndex, argTint) => {
    const buttonGroup = new PIXI.Container();
    // eslint-disable-next-line no-mixed-operators
    buttonGroup.x = app.backgroundBounds.width * (argIndex + 0.5) / 8 - app.buttonBounds.width / 2;
    buttonGroup.y = app.backgroundBounds.height - app.buttonBounds.height - 10;

    const button = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'button')].texture);
    button.x = 0;
    button.y = 0;

    buttonGroup.addChild(button);

    const rocketThumb = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'rocket')].texture);
    rocketThumb.scale.x = 0.333;
    rocketThumb.scale.y = 0.333;
    rocketThumb.x = app.buttonBounds.width / 2 - app.rocketBounds.width / 2 / 3;
    rocketThumb.y = app.buttonBounds.height / 2 - app.rocketBounds.height / 2 / 3;

    const filter = new PIXI.filters.ColorMatrixFilter();
    const { matrix } = filter;

    // eslint-disable-next-line prefer-destructuring
    matrix[0] = argTint[0];
    // eslint-disable-next-line prefer-destructuring
    matrix[6] = argTint[1];
    // eslint-disable-next-line prefer-destructuring
    matrix[12] = argTint[2];

    rocketThumb.filters = [filter];
    buttonGroup.addChild(rocketThumb);

    app.colorButtonsGroup.addChild(buttonGroup);

    buttonGroup.interactive = true;
    buttonGroup.on('click', () => {
      if (!app.launched) {
        app.rocket.filters = [filter];
      }
    });

    buttonGroup.on('tap', () => {
      if (!app.launched) {
        app.rocket.filters = [filter];
      }
    });
  };

  const showGoodJob = () => {
    const ufoTween = new TWEEN.Tween(app.ufo);
    ufoTween.to({
      x: -app.ufoBounds.width,
    }, 3000);
    ufoTween.easing(TWEEN.Easing.Linear.None);
    ufoTween.onComplete(() => {
      const onFinishTimeout = setTimeout(() => {
        clearTimeout(onFinishTimeout);
        onFinish();
      }, 500);
      app.timeouts.push(onFinishTimeout);
    });
    app.tweens.push(ufoTween);
    ufoTween.start();

    const goodJobTween = new TWEEN.Tween(app.goodJob);
    goodJobTween.to({
      alpha: 1,
    }, 500);
    goodJobTween.easing(TWEEN.Easing.Linear.None);
    app.tweens.push(goodJobTween);
    goodJobTween.start();
    playClappingSound();
  };

  const launch = () => {
    app.clouds = [];

    app.flame.visible = true;

    const wrapperTween = new TWEEN.Tween(app.wrapper);
    wrapperTween.to({
      y: 0,
    }, 8000);
    wrapperTween.easing(TWEEN.Easing.Exponential.In);
    wrapperTween.delay(500);
    app.tweens.push(wrapperTween);
    wrapperTween.start();

    const rocketGroupTween = new TWEEN.Tween(app.rocketGroup);
    rocketGroupTween.to({
      y: -app.rocketBounds.height - 400,
    }, 8000);
    rocketGroupTween.delay(500);
    rocketGroupTween.easing(TWEEN.Easing.Exponential.In);
    rocketGroupTween.onComplete(() => {
      showGoodJob();
    });
    app.tweens.push(rocketGroupTween);
    rocketGroupTween.start();
  };

  const startLaunch = () => {
    if (app.forceLaunchTimeout) {
      clearTimeout(app.forceLaunchTimeout);
    }

    if (!app.launched) {
      app.launched = true;

      const launchButtonTween = new TWEEN.Tween(app.launchButton);
      launchButtonTween.to({
        alpha: 0,
      }, 500);
      launchButtonTween.easing(TWEEN.Easing.Quadratic.Out);
      app.tweens.push(launchButtonTween);
      launchButtonTween.start();

      const colorButtonsGroupTween = new TWEEN.Tween(app.colorButtonsGroup);
      colorButtonsGroupTween.to({
        alpha: 0,
      }, 500);
      colorButtonsGroupTween.easing(TWEEN.Easing.Quadratic.Out);
      app.tweens.push(colorButtonsGroupTween);
      colorButtonsGroupTween.start();

      const gantryTween = new TWEEN.Tween(app.gantry);
      gantryTween.to({
        x: app.backgroundBounds.width / 2 - 130,
      }, 2000);
      gantryTween.easing(TWEEN.Easing.Quadratic.Out);
      gantryTween.onComplete(() => {
        const gantryCompleteTimeout = setTimeout(() => {
          clearTimeout(gantryCompleteTimeout);
          launch();
        }, 8800);
        app.timeouts.push(gantryCompleteTimeout);
      });
      app.tweens.push(gantryTween);
      gantryTween.start();

      playCountdownSound();
    }
  };

  function start() {
    app.forceLaunchTimeout = setTimeout(() => {
      startLaunch();
    }, FORCE_LAUNCH_TIME);
    app.timeouts.push(app.forceLaunchTimeout);
  }

  const createButtons = () => {
    app.colorButtonsGroup = new PIXI.Container();
    app.wrapper.addChild(app.colorButtonsGroup);

    createButton(0, [1, 0.67, 0.67]); // red
    createButton(1, [1, 0.83, 0.67]); // orange
    createButton(2, [1, 1, 0.67]); // yellow
    createButton(3, [0.67, 1, 0.67]); // green
    createButton(4, [0.67, 0.67, 1]); // blue
    createButton(5, [0.83, 0.67, 1]); // purple
    createButton(6, [1, 1, 1]); // white
    createButton(7, [0.5, 0.5, 0.5]); // black

    app.launchButton.x = 4;
    app.launchButton.y = app.backgroundBounds.height - app.buttonBounds.height - 10 - app.launchButtonBounds.height - 10;
    app.wrapper.addChild(app.launchButton);

    app.launchButton.interactive = true;
    app.launchButton.on('click', () => {
      startLaunch();
    });
    app.launchButton.on('tap', () => {
      startLaunch();
    });
  };

  const createUfo = () => {
    app.ufo.x = app.backgroundBounds.width + app.ufoBounds.width / 2;
    app.ufo.y = 500;

    app.wrapper.addChild(app.ufo);

    app.goodJob.x = app.backgroundBounds.width / 2 - app.goodJobBounds.width / 2;
    app.goodJob.y = -20;
    app.goodJob.alpha = 0;

    app.wrapper.addChild(app.goodJob);
  };

  const createStage = () => {
    app.wrapper = new PIXI.Container();
    app.wrapper.scale.x = app.canvas.view.width / app.backgroundBounds.width;
    app.wrapper.scale.y = app.wrapper.scale.x;
    app.wrapper.y = app.canvas.view.height - (app.wrapper.scale.y * (app.backgroundBounds.height - 10));
    app.canvas.stage.addChild(app.wrapper);

    createBackground();
    createLaunchpad();
    createUfo();
    createButtons();

    app.launched = false;
  };

  const createCloudParticle = () => {
    app.clouds.push(new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'cloud')].texture));
    app.clouds[app.clouds.length - 1].x = app.backgroundBounds.width / 2;
    app.clouds[app.clouds.length - 1].y = app.backgroundBounds.height - 190;

    let angle = Math.random() * 30;
    if (Math.random() < 0.5) {
      angle = 180 - angle;
    }

    const speed = Math.random() * 5 + 2;

    app.clouds[app.clouds.length - 1].dx = Math.cos((angle * 3.14159) / 180) * speed;
    app.clouds[app.clouds.length - 1].dy = -Math.sin((angle * 3.14159) / 180) * speed;
    app.clouds[app.clouds.length - 1].rotation = Math.random() * 0.01;
    app.clouds[app.clouds.length - 1].dRotation = (Math.random() - 0.8);
    app.clouds[app.clouds.length - 1].anchor.set(0.5);
    app.clouds[app.clouds.length - 1].scale.x = PARTICLE_SCALE;
    app.clouds[app.clouds.length - 1].scale.y = PARTICLE_SCALE;
    app.clouds[app.clouds.length - 1].life = 0;
    app.wrapper.addChild(app.clouds[app.clouds.length - 1]);
  };

  const updateCloudParticle = (argCloudParticle) => {
    argCloudParticle.x += argCloudParticle.dx;
    argCloudParticle.y += argCloudParticle.dy;
    argCloudParticle.rotation += argCloudParticle.dRotation;
    argCloudParticle.scale.x *= PARTICLE_GROWTH;
    argCloudParticle.scale.y *= PARTICLE_GROWTH;
    argCloudParticle.life++;

    if (argCloudParticle.life > PARTICLE_LIFE) {
      argCloudParticle.visible = false;
    }
  };

  const tick = () => {
    app.frame++;
    app.flame.scale.y = Math.sin(app.frame) * 0.25 + 0.75;

    if (app.clouds) {
      if (app.clouds.length < MAX_PARTICLE_COUNT) {
        for (let i = 0; i < PARTICLES_PER_FRAME; i++) {
          createCloudParticle();
        }
      }
      app.clouds.forEach((cloud) => {
        updateCloudParticle(cloud);
      });
    }
  };

  useEffect(() => {
    /**
     * Note: Rocket celebration does not need
     * useSetupTimeout as rocket will be launched automatically.
     */
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundAlpha: 0,
      });
    }

    loadBitmaps();
    createStage();
    start();
    app.canvas.ticker.add(tick);

    return () => {
      if (app.countdownSound) {
        app.countdownSound.pause();
      }
      if (app.clappingSound) {
        app.clappingSound.pause();
      }

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

RocketCelebration.defaultProps = {
  onFinish: () => { },
};

RocketCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default RocketCelebration;

import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@mui/material';
import TWEEN from '@tweenjs/tween.js';
import { usePreloadCelebration } from './CelebrationHooks';
import { celebrationsMap } from './CelebrationMapping';
import './Celebration.scss';
import Logger from '../../../utils/Logger';
import { useDisableRightClick } from '../TerminologiesHooks';
import LoadingPlaceHolder from '../LoadingPlaceHolder';

const Celebration = ({
  celebrationData,
  onFinish,
  show,
  enableAudio,
}) => {
  const [ready, setReady] = useState(false);
  const celebrationList = useRef([celebrationData]);
  const pixiLoader = usePreloadCelebration(celebrationList.current, enableAudio);
  const CelebrationComponent = celebrationsMap[celebrationData.id];

  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  useDisableRightClick(ready && show);
  useEffect(() => (() => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
  }), []);

  if (pixiLoader && intervalRef.current == null) {
    intervalRef.current = setInterval(() => {
      let resources = Object.keys(pixiLoader.resources).filter((key) => key.startsWith(celebrationData.id));
      if (!enableAudio) {
        resources = resources.filter((r) => {
          const resource = pixiLoader.resources[r];
          return !(['mp3'].includes(resource.extension) || (resource && resource.data && resource.data.nodeName && resource.data.nodeName.toLowerCase() === 'audio'));
        });
      }
      const notCompleted = resources.length > 0 && resources.findIndex((r) => (pixiLoader.resources[r].isLoading));
      if (notCompleted < 0) {
        clearInterval(intervalRef.current);
        clearTimeout(timeoutRef.current);
        setReady(true);
      }
    }, 100);
  }

  // Setup the animation loop.
  function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
  }
  requestAnimationFrame(animate);

  if (!show) {
    return null;
  }

  // if the resurces cannot be loaded within 30 seconds, then skip the celebration
  if (!timeoutRef.current) {
    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
      if (!ready) {
        Logger.logError(`Unable to load celebration ${celebrationData.id}`);
        onFinish();
      }
    }, 30000);
  }

  return (
    <div className='celebration-container'>
      {ready ? (
        <Slide in direction='left' timeout={500}>
          <div className='celebration' data-test='celebration-container'>
            <CelebrationComponent
              pixiLoader={pixiLoader}
              celebrationData={celebrationData}
              onFinish={onFinish}
            />
          </div>
        </Slide>
      ) : (
        <LoadingPlaceHolder message='Celebration is loading.' />
      )}
    </div>
  );
};

Celebration.defaultProps = {
  show: false,
  onFinish: () => { },
  enableAudio: false,
};

Celebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    manifest: PropTypes.array,
  }).isRequired,
  show: PropTypes.bool,
  onFinish: PropTypes.func,
  enableAudio: PropTypes.bool,
};

export default Celebration;

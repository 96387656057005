import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  useInitializeData,
  useGenerateComponentIds,
  useGenerateCellCardRefs,
  usePlayerSetup,
  useWheelSpinner,
  useGamePlay,
} from './GamePlayActivityHooks';
import GamePlayActivityCellCard from './components/GamePlayActivityCellCard';
import '../../../../components/vizzle/page/game/GameCards.scss';
import './GamePlayActivity.scss';
import MediaPlayer from '../../../../components/vizzle/media/MediaPlayer';
import PlayerPieces from '../../../../components/vizzle/page/game/gameplay/PlayerPieces';
import GamePlayWheelSpinner from './components/game-play/GamePlayWheelSpinner';
import GamePlayActivityPlayerSetup from './components/setup/GamePlayActivityPlayerSetup';
import SpecialtyCardPopup from '../../../../components/vizzle/page/game/gameplay/SpecialtyCardPopup';
import PlayActivityInteractionPopup from '../play-interaction/PlayActivityInteractionPopup';
import { useTtsPlayForElement } from '../../utils/UseTtsPlay';
import { TILE_TYPE_STANDARD } from '../../../../components/vizzle/page/game/GameConstants';
import ObjectUtils from '../../../../utils/ObjectUtils';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';

const GamePlayActivity = forwardRef(({
  data,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onInteractionOpen,
  isCurrentPage,
  onTriggerSwitchScan,
  isPreview,
}, ref) => {
  const {
    gamePlayActivityRef,
    moveToCardIndexRef,
    playerSetupRef,
    wheelSpinnerRef,
    playActivityInteractionPopupRef,
    specialtyCardPopupRef,
    isSpinningRef,
  } = useInitializeData();

  const {
    id,
  } = useGenerateComponentIds();

  const {
    gameCellCardRefs,
    setGameCellCardRefs,
    mediaRefs,
    setMediaRefs,
  } = useGenerateCellCardRefs();

  const {
    tts,
    playTts,
  } = useTtsPlayForElement();

  const {
    playerSetup,
    setPlayerSetup,
    handleOnPlayClick,
    hasSetupDialogShown,
  } = usePlayerSetup({
    id,
    isCurrentPage,
    mediaRefs,
    tts,
    onTriggerSwitchScan,
  });

  const {
    handleOnSpinnerStart,
    handleOnSpinnerEnd,
  } = useWheelSpinner({
    gamePlayActivityRef,
    playerSetup,
    setPlayerSetup,
    data,
    moveToCardIndexRef,
    gameCellCardRefs,
    mediaRefs,
    onTriggerSwitchScan,
    isSpinningRef,
  });

  const {
    handleOnCardClick,
    landingInteractionPopup,
    handleOnLandingInteractionPopupClose,
    specialtyCardPopup,
    handleOnSpecialtyCardClose,
    handleOnMediaPlayFinishForClick,
    isBlocking,
  } = useGamePlay({
    id,
    data,
    playerSetup,
    setPlayerSetup,
    moveToCardIndexRef,
    gameCellCardRefs,
    mediaRefs,
    handleOnSpinnerEnd,
    onCorrectAnswerSelected,
    onActivityFinished,
    onInteractionOpen,
    onTriggerSwitchScan,
    isPreview,
  });

  useImperativeHandle(ref, () => ({
    skipAppBar: () => !hasSetupDialogShown.current || playerSetup.showSpinner || isSpinningRef.current,
    getEnableElements: async () => {
      await ObjectUtils.delay(100);

      if (landingInteractionPopup.open) {
        return playActivityInteractionPopupRef.current.getEnableElements();
      }
      if (specialtyCardPopup.open) {
        return specialtyCardPopupRef.current.getEnableElements();
      }
      const toMoveToIndex = gameCellCardRefs.findIndex((g) => g.dataset.cellToMoveTo === 'true');
      if (toMoveToIndex >= 0) {
        return [gameCellCardRefs[toMoveToIndex]];
      } else if (hasSetupDialogShown.current === false) {
        return playerSetupRef.current.getEnableElements();
      } else if (playerSetup.showSpinner) {
        return [];
      } else {
        return wheelSpinnerRef.current.getEnableElements();
      }
    },
  }));

  const { gameCards } = data;

  return (
    <div className='game-activity-card-grid' data-test='game-play-activity' ref={gamePlayActivityRef}>
      <TransparentBlocker show={isBlocking} />
      <div className={`game-grid game-card-layout game-card-layout-${gameCards.length}`}>
        {gameCards.map((cell, index) => {
          const cellKey = `game-card-grid-card-${id}-${index}`;
          const textLineId = `text-line-${cellKey}`;
          const elementToPlayTts = [];
          const isStandardCard = cell.gameSpaceType === TILE_TYPE_STANDARD;

          if (isStandardCard && cell.text && cell.text.lines && cell.text.lines.length > 0) {
            for (let i = 0; i < cell.text.lines.length; i++) {
              const el = document.querySelector(`#${textLineId}-${i}`);
              if (el && el.textContent.trim().length > 0) {
                elementToPlayTts.push(el);
              }
            }
          } else {
            const el = document.querySelector(`#${textLineId}-0`);
            if (el && el.textContent.trim().length > 0) {
              elementToPlayTts.push(el);
            }
          }

          const containerId = `game-cell-container-${id}-${index}`;
          return (
            <div
              key={cellKey}
              className='card-container'
              style={{ gridArea: `card${index}` }}
              id={containerId}
              data-test={`game-cell-container-${id}`}
            >
              <GamePlayActivityCellCard
                id={id}
                cardIndex={index}
                spinner={data.spinner}
                text={cell.text}
                image={cell.image}
                video={cell.video}
                audio={cell.audio}
                borderColor={cell.borderColor}
                bgColor={cell.bgColor}
                gameSpaceType={cell.gameSpaceType}
                ref={(r) => {
                  setGameCellCardRefs(r, index);
                }}
                onCardClick={handleOnCardClick}
                textLineId={textLineId}
              />
              <PlayerPieces
                containerIndex={index}
                players={playerSetup.players}
                currentPlayer={playerSetup.currentPlayer}
                iconHeight={`${32 / gameCards.length}em`}
                pageIndex={id}

              />
              {
                (cell.audio || cell.video || (tts.enabled && elementToPlayTts.length > 0))
                && (
                  <MediaPlayer
                    id={`vizzle-game-card-page-${id}-${index}-media`}
                    audioUrl={cell.audio}
                    videoUrl={cell.video}
                    parentElementId={containerId}
                    highlightIcon
                    className={`small-icon vizzle-game-card-page-${id}-${index}-media`}
                    ref={(r) => { setMediaRefs(r, index); }}
                    textToRead={elementToPlayTts}
                    tts={tts}
                    omitPlayIcon={!isStandardCard}
                    onMediaPlayFinishForClick={(e) => handleOnMediaPlayFinishForClick(e, index)}
                  />
                )
              }
            </div>
          );
        })}
        <div className='wheel-spinner-container'>
          {playerSetup.players.length > 0 ? (
            <GamePlayWheelSpinner
              ref={wheelSpinnerRef}
              playerState={playerSetup}
              spinner={data.spinner}
              numberOfgameCards={data.gameCards.length}
              onSpinnerStart={handleOnSpinnerStart}
              onSpinnerEnd={handleOnSpinnerEnd}
              showSpinner={playerSetup.showSpinner}
            />
          ) : ''}

        </div>
      </div>
      <GamePlayActivityPlayerSetup
        open={playerSetup.open}
        onSetupFinish={handleOnPlayClick}
        ref={playerSetupRef}
      />

      {landingInteractionPopup.landingInteraction && (
        <PlayActivityInteractionPopup
          open={landingInteractionPopup.open}
          onClose={handleOnLandingInteractionPopupClose}
          landingInteraction={landingInteractionPopup.landingInteraction}
          onCorrectAnswerSelected={onCorrectAnswerSelected}
          onIncorrectAnswerSelected={onIncorrectAnswerSelected}
          ref={playActivityInteractionPopupRef}
        />
      )}

      <SpecialtyCardPopup
        open={specialtyCardPopup.open}
        onClose={handleOnSpecialtyCardClose}
        gameSpaceType={specialtyCardPopup.gameSpaceType}
        spinner={data.spinner}
        onCardClick={tts.enabled ? (_e, titleElement) => {
          playTts(titleElement);
        } : null}
        ref={specialtyCardPopupRef}
      />
    </div>
  );
});

GamePlayActivity.defaultProps = {
  data: {
    spinner: 1,
    landingAudio: null,
    gameCards: [],
  },
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  onInteractionOpen: () => { },
  isCurrentPage: false,
  onTriggerSwitchScan: () => { },
  isPreview: false,
};

GamePlayActivity.propTypes = {
  data: PropTypes.shape({
    spinner: PropTypes.number,
    landingAudio: PropTypes.string,
    moveToCardIndicator: PropTypes.bool,
    gameCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      video: PropTypes.string,
      audio: PropTypes.string,
      gameSpaceType: PropTypes.number,
      landingInteraction: PropTypes.shape({
        type: PropTypes.string,
        questionCard: PropTypes.object,
        answerCards: PropTypes.array,
      }),
    })),
  }),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onInteractionOpen: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  onTriggerSwitchScan: PropTypes.func,
  isPreview: PropTypes.bool,
};

export default GamePlayActivity;

import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import MatchingType from '../../../../settings/interaction/MatchingType';

const MatchingTypeSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Matching Type');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      interactionType: newValue,
    });
  };

  return (
    <MatchingType
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default MatchingTypeSettings;

import { useRef, useImperativeHandle, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import {
  TILE_TYPE_STANDARD,
  SPINNER_BOARD_NUMBER,
} from '../GameConstants';
import GameActivityUtils from '../GameActivityUtils';
import './SpecialtyCardPopup.scss';
import { useRegisterEvent } from '../../../../../containers/switch-access/SwitchAccessHooks';
import ObjectUtils from '../../../../../utils/ObjectUtils';

const SpecialtyCardPopup = forwardRef(({
  open,
  onClose,
  gameSpaceType,
  spinner,
  onCardClick,
}, ref) => {
  const cardText = GameActivityUtils.getSpecialCardText(gameSpaceType);
  const imageToUse = GameActivityUtils.getSpaceTypeImage(spinner, gameSpaceType);
  const extraImage = GameActivityUtils.getSpaceTypeExtraImage(gameSpaceType);
  const titleRef = useRef(null);
  const okButtonRef = useRef(null);
  const cleanUpEvent = useRef(null);

  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  useEffect(() => {
    const registerEvent = async () => {
      if (open) {
        await ObjectUtils.delay(100);
        cleanUpEvent.current = registerSwitchEvent('switchAccessScan', titleRef, () => {
          titleRef.current.parentElement.click();
        });
      }
    };

    registerEvent();
    return () => {
      if (cleanUpEvent.current) {
        cleanUpEvent.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, titleRef.parent]);

  const handleOnClick = (e) => {
    if (onCardClick) {
      onCardClick(e, titleRef.current);
    }
  };

  useImperativeHandle(ref, () => ({
    getEnableElements: () => [
      titleRef.current,
      okButtonRef.current,
    ],
  }));

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      className='game-specialty-card-popup'
      disableEscapeKeyDown
      data-test='game-specialty-card-popup'
    >
      <DialogContent
        onClick={handleOnClick}
        style={{ height: 'inherit' }}
        className={`content ${onCardClick ? 'clickable' : ''}`}
        data-test='specialty-card-content'
      >

        <Box className='content-title' data-test='content-title' ref={titleRef} data-skip-switch-access-click='true' data-switch-access-scan='true'>
          {cardText}
        </Box>
        <div className='content-image' data-test='content-image'>
          <img
            className='image'
            src={imageToUse}
            alt={cardText}
          />
          {extraImage ? (
            <img
              className='extra-icon'
              src={extraImage}
              alt={cardText}
            />
          ) : ''}
        </div>

      </DialogContent>
      <DialogActions className='action-area' data-test='action-area'>
        <Button
          onClick={onClose}
          color='primary'
          className='btn-primary game-specialty-card-dialog-button'
          ref={okButtonRef}
        >
          OKAY
        </Button>
      </DialogActions>
    </Dialog>
  );
});

SpecialtyCardPopup.defaultProps = {
  open: false,
  onClose: () => { },
  gameSpaceType: TILE_TYPE_STANDARD,
  spinner: SPINNER_BOARD_NUMBER,
  onCardClick: null,
};

SpecialtyCardPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  gameSpaceType: PropTypes.number,
  spinner: PropTypes.number,
  onCardClick: PropTypes.func,
};

export default SpecialtyCardPopup;

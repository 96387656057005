import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const CANVAS_WIDTH = 800;
const CANDY_TO_ADD = 400;
const TWEEN_ROTATIONAL_FACTOR = 0.25;
const TWEEN_TIME = 300;
const SHOW_FINISH_MESSAGE_TIME = 3000;

const PinataCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    startTime: new Date().getTime(),
    canvas: null,
    batIndex: 0,
    allowSwing: true,
    hits: 0,
    pinatasKilled: 0,
    candyCollection: [],
    tweens: [],
    timeouts: [],
    intervals: [],
    hitSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'hit')].sound,
    releaseSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'release')].sound,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
  };

  const playHitSound = (volume) => {
    if (app.hitSound) {
      app.hitSound.volume = Math.min(volume, 1);
      app.hitSound.play({ start: 0, volume: app.hitSound.volume });
    }
  };

  const playReleaseSound = () => {
    if (app.releaseSound) {
      app.releaseSound.play({ start: 0, volume: 1 });
    }
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const chooseTheme = () => {
    const month = new Date().getMonth();
    app.backgroundString = 'Spring';
    if (month === 9 || month === 10) {
      app.backgroundString = 'Fall';
    } else if (month === 0 || month === 1 || month === 2 || month === 11) {
      app.backgroundString = 'Winter';
    }
  };

  const loadBitmaps = () => {
    app.background = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.backgroundString}-background`)].texture);
    app.sheet = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'spritesheetData')].spritesheet;

    app.pinataRope = new PIXI.AnimatedSprite(app.sheet.animations.pinata_rope);
    app.pinataBody = new PIXI.AnimatedSprite(app.sheet.animations.pinata_body);
    app.pinata1 = new PIXI.AnimatedSprite(app.sheet.animations.pinata1);
    app.pinata2 = new PIXI.AnimatedSprite(app.sheet.animations.pinata2);
    app.pinata3 = new PIXI.AnimatedSprite(app.sheet.animations.pinata3);
    app.pinata4 = new PIXI.AnimatedSprite(app.sheet.animations.pinata4);
    app.pinata5 = new PIXI.AnimatedSprite(app.sheet.animations.pinata5);
    app.pinata6 = new PIXI.AnimatedSprite(app.sheet.animations.pinata6);
    app.bat1 = new PIXI.AnimatedSprite(app.sheet.animations.bat1);
    app.bat2 = new PIXI.AnimatedSprite(app.sheet.animations.bat2);
    app.bat3 = new PIXI.AnimatedSprite(app.sheet.animations.bat3);
    app.bat4 = new PIXI.AnimatedSprite(app.sheet.animations.bat4);
    app.bat5 = new PIXI.AnimatedSprite(app.sheet.animations.bat5);
    app.message = new PIXI.AnimatedSprite(app.sheet.animations.message);

    for (let i = 0; i < CANDY_TO_ADD; i++) {
      const random = Math.floor(Math.random() * 5 + 1);
      const candyObj = {};
      candyObj.elem = new PIXI.AnimatedSprite(app.sheet.animations[`candy${random}`]);
      candyObj.alive = true;
      app.candyCollection.push(candyObj);
    }

    app.backgroundBounds = app.background.getBounds();
    app.pinataBounds = app.pinata1.getBounds();
    app.batBounds = app.bat1.getBounds();
    app.pinataRopeBounds = app.pinataRope.getBounds();
    app.pinataBodyBounds = app.pinataBody.getBounds();
    app.messageBounds = app.message.getBounds();
  };

  const createBackground = () => {
    app.background.x = 0;
    app.background.y = 0;
    app.wrapper.addChild(app.background);
  };

  const createPinata = () => {
    app.pinataRotateContainer = new PIXI.Container();
    app.pinataRotateContainer.width = app.pinataBounds.width;
    app.pinataRotateContainer.x = app.backgroundBounds.width / 2 - app.pinataBounds.width / 2;
    app.pinataRotateContainer.y = -app.pinataBounds.height - 230;
    app.wrapper.addChild(app.pinataRotateContainer);

    app.pinata1.y = app.pinataBounds.height;
    app.pinata2.y = app.pinataBounds.height;
    app.pinata3.y = app.pinataBounds.height;
    app.pinata4.y = app.pinataBounds.height;
    app.pinata5.y = app.pinataBounds.height;
    app.pinata6.y = app.pinataBounds.height;
    app.pinata2.alpha = 0;
    app.pinata3.alpha = 0;
    app.pinata4.alpha = 0;
    app.pinata5.alpha = 0;
    app.pinata6.alpha = 0;

    app.pinataRotateContainer.addChild(app.pinata1);
    app.pinataRotateContainer.addChild(app.pinata2);
    app.pinataRotateContainer.addChild(app.pinata3);
    app.pinataRotateContainer.addChild(app.pinata4);
    app.pinataRotateContainer.addChild(app.pinata5);
    app.pinataRotateContainer.addChild(app.pinata6);

    app.pinataRope.alpha = 0;
    app.pinataBody.alpha = 0;
    app.pinataRope.x = app.pinataBounds.width / 2 - app.pinataRopeBounds.width - 13;
    app.pinataRope.y = app.pinata1.y;
    app.pinataBody.y = app.pinata1.y + app.pinataBounds.height - app.pinataBodyBounds.height - 20;

    app.pinataRotateContainer.addChild(app.pinataRope);
    app.pinataRotateContainer.addChild(app.pinataBody);
  };

  const createCandy = () => {
    const startingXPos = app.pinataRotateContainer.x + app.pinataBounds.width / 2 + 10;
    for (let i = 0; i < CANDY_TO_ADD; i++) {
      const candyObj = app.candyCollection[i];

      const randomXPos = Math.floor(Math.random() * 80) + 1;
      candyObj.elem.x = startingXPos - randomXPos;
      const randomYPos = Math.floor(Math.random() * 50) + 1;
      candyObj.elem.y = 370 + randomYPos;
      candyObj.elem.alpha = 0;
      app.wrapper.addChildAt(candyObj.elem, 1);
    }
  };

  const createBat = () => {
    app.batContainer = new PIXI.Container();
    app.batContainer.x = app.backgroundBounds.width - app.batBounds.width + 70;
    app.batContainer.y = app.backgroundBounds.height - app.batBounds.height + 70;
    app.wrapper.addChild(app.batContainer);

    app.bat1.alpha = 0;
    app.bat2.alpha = 0;
    app.bat3.alpha = 0;
    app.bat4.alpha = 0;
    app.bat5.alpha = 0;

    app.batContainer.addChild(app.bat1);
    app.batContainer.addChild(app.bat2);
    app.batContainer.addChild(app.bat3);
    app.batContainer.addChild(app.bat4);
    app.batContainer.addChild(app.bat5);
  };

  const createMessage = () => {
    app.message.x = app.backgroundBounds.width / 2 - app.messageBounds.width / 2;
    app.message.y = -app.messageBounds.height;
    app.wrapper.addChild(app.message);
  };

  const showFinishMessage = () => {
    const messageTween = new TWEEN.Tween(app.message);
    messageTween.to({
      y: app.message.y + app.messageBounds.height - 300,
    }, 600);
    messageTween.easing(TWEEN.Easing.Quadratic.Out);
    app.tweens.push(messageTween);
    messageTween.start();
    playClappingSound();

    app.completeTimeout = setTimeout(() => {
      onFinish();
    }, SHOW_FINISH_MESSAGE_TIME);
  };

  const showFinish = () => {
    app.allowSwing = false;

    app.pinata1.alpha = 0;
    app.pinata2.alpha = 0;
    app.pinata3.alpha = 0;
    app.pinata4.alpha = 0;
    app.pinata5.alpha = 0;
    app.pinata6.alpha = 0;
    app.pinataBody.alpha = 1;
    app.pinataRope.alpha = 1;

    playReleaseSound();

    const tweenTime = 600;

    const pinataBodyTween = new TWEEN.Tween(app.pinataBody);
    pinataBodyTween.to({
      y: app.pinataBody.y + 1500,
      rotation: 2,
    }, tweenTime);
    pinataBodyTween.easing(TWEEN.Easing.Quadratic.In);
    app.tweens.push(pinataBodyTween);
    pinataBodyTween.start();

    const pinataRopeTween = new TWEEN.Tween(app.pinataRope);
    pinataRopeTween.to({
      y: app.pinataRope.y - 500,
    }, tweenTime / 2);
    pinataRopeTween.delay(tweenTime - 200);
    pinataRopeTween.easing(TWEEN.Easing.Quadratic.In);
    pinataRopeTween.onComplete(() => {
      showFinishMessage();
    });
    app.tweens.push(pinataRopeTween);
    pinataRopeTween.start();
  };

  const pinataHit = () => {
    app.hits++;
    app.lastHit = app.hits === app.pinataHitsAllowed;

    if (app.lastHit) {
      let hitIntervalCount = 0;
      let addVolume = 0;
      const hitInterval = setInterval(() => {
        playHitSound(1 + addVolume);
        hitIntervalCount++;
        addVolume += 0.5;

        if (hitIntervalCount === 5) {
          clearInterval(hitInterval);
        }
      }, 150);
      app.intervals.push(hitInterval);
    } else {
      playHitSound(1);
    }

    let candyElemsUpdated = 0;
    const candyElemsToUpdate = app.lastHit ? (app.candyCollection.length - app.pinatasKilled) : 40;
    for (let i = 0; i < app.candyCollection.length; i++) {
      const candyObj = app.candyCollection[i];
      if (candyObj.alive && candyElemsUpdated < candyElemsToUpdate) {
        candyElemsUpdated++;
        app.pinatasKilled++;
        candyObj.alive = false;
        candyObj.elem.alpha = 1;

        const randomRotation = Math.floor((Math.random() * 150) + 1);
        const randomSpeed = Math.floor((Math.random() * 800) + 1);

        const randomXDirection = Math.floor((Math.random() * 2) + 1);
        const randomXPosition = Math.floor((Math.random() * 2000) + 1);

        const randomYDirection = Math.floor((Math.random() * 2) + 1);
        const randomYPosition = Math.floor((Math.random() * 500) + 1);

        const candyElementTween = new TWEEN.Tween(candyObj.elem);
        candyElementTween.to({
          x: (randomXDirection === 2 ? randomXPosition : -randomXPosition),
          y: (randomYDirection === 2 ? (800 + randomYPosition) : -(800 + randomYPosition)),
          rotation: randomRotation,
        }, randomSpeed + 500);
        candyElementTween.easing(TWEEN.Easing.Quadratic.Out);
        app.tweens.push(candyElementTween);
        candyElementTween.start();
      }
    }

    app.pinata1.alpha = 0;
    app.pinata2.alpha = 0;
    app.pinata3.alpha = 0;
    app.pinata4.alpha = 0;
    app.pinata5.alpha = 0;
    app.pinata6.alpha = 0;

    switch (app.hits) {
      case 1:
        app.pinata2.alpha = 1;
        break;
      case 2:
        app.pinata3.alpha = 1;
        break;
      case 3:
        app.pinata4.alpha = 1;
        break;
      case 4:
        app.pinata5.alpha = 1;
        break;
      case 5:
        app.pinata6.alpha = 1;
        break;
      default:
        app.pinata6.alpha = 1;
    }

    const pinatHitBounce7Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce7Tween.to({
      rotation: 0,
    }, TWEEN_TIME);
    pinatHitBounce7Tween.onComplete(() => {
      if (app.lastHit) {
        showFinish();
      } else {
        app.allowSwing = true;
      }
    });

    const pinatHitBounce6Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce6Tween.to({
      rotation: -TWEEN_ROTATIONAL_FACTOR / 4,
    }, TWEEN_TIME / 1.5);
    pinatHitBounce6Tween.chain(pinatHitBounce7Tween);

    const pinatHitBounce5Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce5Tween.to({
      rotation: 0,
    }, TWEEN_TIME / 1.5);
    pinatHitBounce5Tween.chain(pinatHitBounce6Tween);

    const pinatHitBounce4Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce4Tween.to({
      rotation: TWEEN_ROTATIONAL_FACTOR / 3,
    }, TWEEN_TIME / 1.75);
    pinatHitBounce4Tween.chain(pinatHitBounce5Tween);

    const pinatHitBounce3Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce3Tween.to({
      rotation: 0,
    }, TWEEN_TIME / 1.5);
    pinatHitBounce3Tween.chain(pinatHitBounce4Tween);

    const pinatHitBounce2Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce2Tween.to({
      rotation: -TWEEN_ROTATIONAL_FACTOR,
    }, TWEEN_TIME / 1.25);
    pinatHitBounce2Tween.chain(pinatHitBounce3Tween);

    const pinatHitBounce1Tween = new TWEEN.Tween(app.pinataRotateContainer);
    pinatHitBounce1Tween.to({
      rotation: 0,
    }, TWEEN_TIME);
    pinatHitBounce1Tween.chain(pinatHitBounce2Tween);

    const pinataRotateContainerTween = new TWEEN.Tween(app.pinataRotateContainer);
    pinataRotateContainerTween.to({
      rotation: TWEEN_ROTATIONAL_FACTOR,
    }, TWEEN_TIME);
    pinataRotateContainerTween.easing(TWEEN.Easing.Quadratic.Out);
    pinataRotateContainerTween.chain(pinatHitBounce1Tween);

    app.tweens.push(pinataRotateContainerTween);
    pinataRotateContainerTween.start();
  };

  const swingBat = () => {
    if (app.allowSwing) {
      app.allowSwing = false;
      const swingInterval = setInterval(() => {
        app.bat1.alpha = 0;
        app.bat2.alpha = 0;
        app.bat3.alpha = 0;
        app.bat4.alpha = 0;
        app.bat5.alpha = 0;

        app.batIndex++;
        if (app.batIndex === 1 || app.batIndex === 10) {
          app.bat1.alpha = 1;
        } else if (app.batIndex === 2 || app.batIndex === 9) {
          app.bat2.alpha = 1;
        } else if (app.batIndex === 3 || app.batIndex === 8) {
          app.bat3.alpha = 1;
        } else if (app.batIndex === 4 || app.batIndex === 7) {
          app.bat4.alpha = 1;
        } else if (app.batIndex === 5 || app.batIndex === 6) {
          app.bat5.alpha = 1;
        }

        if (app.batIndex === 5) {
          pinataHit();
        }

        if (app.batIndex === 11) {
          app.batIndex = 0;
          clearInterval(swingInterval);
        }
      }, 35);
      app.intervals.push(swingInterval);
    }
  };

  const createStage = () => {
    app.pinataHitsAllowed = Math.floor(Math.random() * 4 + 1) + 3;

    app.wrapper = new PIXI.Container();
    app.wrapper.scale.x = app.canvas.view.width / app.backgroundBounds.width;
    app.wrapper.scale.y = app.wrapper.scale.x;
    app.wrapper.y = app.canvas.view.height - app.backgroundBounds.height * app.wrapper.scale.y + 120;
    app.canvas.stage.addChild(app.wrapper);

    createBackground();
    createPinata();
    createCandy();
    createBat();
    createMessage();

    app.pinataRotateContainer.interactive = true;
    app.pinataRotateContainer.on('click', swingBat);
    app.pinataRotateContainer.on('tap', swingBat);
  };

  useSetupTimeout(canvas, showFinishMessage);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundAlpha: 0,
      });
      chooseTheme();
      loadBitmaps();
      createStage();
    }

    return () => {
      if (app.hitSound) {
        app.hitSound.stop();
      }
      if (app.releaseSound) {
        app.releaseSound.stop();
      }
      if (app.clappingSound) {
        app.clappingSound.stop();
      }

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.intervals.forEach((t) => {
        clearInterval(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

PinataCelebration.defaultProps = {
  onFinish: () => { },
};

PinataCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default PinataCelebration;

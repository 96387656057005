import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartContainer from './ChartContainer';

const PieChart = ({
  title,
  data,
  colors,
}) => (
  <ChartContainer title={title} isDataAvailable={data && data.length > 0 && data.findIndex((d) => d.y > 0) >= 0} className='large-pie-chart'>
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: data.length === 1 ? '77%' : '100%', height: data.length === 1 ? '77%' : '100%' } }}
      options={{
        chart: {
          type: 'pie',
          polar: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            innerSize: '0%',
            dataLabels: {
              enabled: true,
              distance: 3,
              format: '{point.y:.0f}',
            },
            showInLegend: true,
            cursor: 'pointer',
          },
        },
        legend: {
          enabled: true,
          useHTML: true,
          labelFormatter: function labelFormatterFn() {
            // eslint-disable-next-line react/no-this-in-sfc
            return `${this.name} (${this.y})`;
          },
        },
        credits: {
          enabled: false,
        },
        colors,
        title: {
          text: '',
        },
        tooltip: { enabled: false },
        series: [{
          data,
        }],
      }}
    />
  </ChartContainer>
);

PieChart.defaultProps = {
  title: '',
  data: [],
  colors: ['#66B767', '#ED1C24'],
};

PieChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default PieChart;

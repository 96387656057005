import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Fade,
  Typography,
} from '@mui/material';
import PacmanLoader from 'react-spinners/PacmanLoader';
import VizzleLogo from '../../vizzle_NoTag.svg';
import { loginWithVizzleToken, logout } from '../../services/LoginService';
import { URL_LESSON_FINISHED, URL_MY_CLASSROOM, URL_MY_STUDENT } from '../../routes/RouteConstants';
import ObjectUtils from '../../utils/ObjectUtils';
import { useDomain } from '../../states/AppDomainProvider';
import Logger from '../../utils/Logger';
import { usePrepareData } from './LoginHooks';
import { isTeacher } from '../../utils/User';
import { MY_CLASSROOM_ROUTES } from '../myclassroom/MyClassRoomConstant';

/**
 * Login with vizzle token container
 *
 * @param {*} props passed in from parent component
 */
const loadingMessage = 'Loading, please wait';
const errorMessage = 'An error occurs, please login with you username and password.';

export default function LoginWithVizzleToken() {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { studentsDomain, userDomain } = useDomain();

  const query = new URLSearchParams(useLocation().search);
  const hideToolbar = query.get('hideToolbar');

  const {
    prepareDataForTeacher,
    prepareDataForStudent,
  } = usePrepareData();
  useEffect(() => {
    const handleLoginFailed = () => {
      Logger.logError('login with token failed: redirect to login');
      setShowError(true);
      ObjectUtils.setTimeout(() => {
        logout(false);
      }, 3500);
    };

    const login = async () => {
      const { search } = location.state || location;
      const params = new URLSearchParams(search);
      const vizzleToken = params.get('vizzle-token');
      Logger.logWhenDebugModeIsOn({
        VIZZLE_TOKEN: vizzleToken,
      });
      try {
        const result = await loginWithVizzleToken(vizzleToken);
        if (result.success) {
          Logger.logWhenDebugModeIsOn('success: redirect to authoring screen');
          if (isTeacher(result)) {
            await prepareDataForTeacher({ ...result, mfaLoggedIn: true, hideToolbar: hideToolbar === 'true' });
          } else {
            await prepareDataForStudent({ ...result, hideToolbar: hideToolbar === 'true' });
          }

          if (params.get('switch-to-new-vizzle')) {
            userDomain.updateSwitchToNewVizzle(true);
          }

          const redirectTo = params.get('redirect-to');
          if (redirectTo) {
            if (redirectTo === URL_MY_STUDENT.replace('/', '')) {
              const { students } = studentsDomain.domainData;
              const student = students.find((s) => s.id === Number(params.get('studentId')));
              navigate(
                URL_MY_STUDENT,
                {
                  state: {
                    student,
                  },
                },
              );
            } else {
              navigate(
                `/${redirectTo}`,
                {
                  state: {
                    exitPath: URL_LESSON_FINISHED,
                  },
                },
              );
            }
          } else {
            navigate(`${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.AUTHORED_LESSONS}`);
          }
        } else {
          handleLoginFailed();
        }
      } catch (error) {
        Logger.logError(error);
        handleLoginFailed();
      }
    };

    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' flexDirection='column' width='100vw' height='100vh'>
      <Box mx='auto' mt='120px'>
        <img src={VizzleLogo} alt='Vizzle Logo' width='250px' />
      </Box>
      {showError ? (
        <Fade timeout={1500} in>
          <Box display='flex' flexDirection='column' mt='80px' mx='auto'>
            <Typography variant='h5' gutterBottom>
              {errorMessage}
            </Typography>
          </Box>
        </Fade>
      ) : (
        <Box mt='50px' mx='auto'>
          <Box width='100px' height='100px' mx='auto'>
            <PacmanLoader size={50} color='#3b94d1' css={{ margin: 'auto' }} />
          </Box>
          <Box mt='20px'>
            <Typography variant='h5' gutterBottom>
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

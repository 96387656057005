import vizzleAxios from './service';

export const generateMfa = async () => {
  const url = '/users/mfa/generate';
  const result = await vizzleAxios({
    method: 'get',
    url,
    responseType: 'blob',
  });

  return result.data;
};

export const validateKey = async (username, code) => {
  const url = '/users/mfa/validate/key';
  const result = await vizzleAxios.post(url, {
    username,
    code,
  });
  return result.data;
};

import CreateExternalLink from './CreateExternalLink';
import ExternalLinkTable from './ExternalLinkTable';
import './ExternalLinks.scss';

const ExternalLinks = () => (
  <div className='external-links'>
    <CreateExternalLink />
    <ExternalLinkTable />
  </div>
);

export default ExternalLinks;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './AuthoredLessonsFilter.scss';
import { URL_LESSON_CREATE } from '../../../../../routes/RouteConstants';
import { FILTER_STATUS } from '../AuthoredLessonHooks';
import AuthoredLessonPendingFilter from './AuthoredLessonPendingFilter';
import AuthoredLessonCourseFilter from './AuthoredLessonCourseFilter';
import SearchTextField from '../../../../../components/form/SearchTextField';
import { useAuthoredLessonsDomain } from '../stores/AuthoredLessonsDomainProvider';

const AuthoredLessonsFilter = () => {
  const navigate = useNavigate();

  const { authoredLessonsSearchCriteriaDomain } = useAuthoredLessonsDomain();
  const { selectedPendingStatus, filteredText } = authoredLessonsSearchCriteriaDomain.domainData;

  return (
    <div className='authored-lessons-filter'>
      <Accordion defaultExpanded className='accordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='lesson-filter-panel'
          id='lesson-filter-panel'
          className='summary-panel'
        >
          <div className='accordion-summary'>
            <Typography>Filters</Typography>
            <div className='create-button'>
              <Button
                className='btn-primary'
                // href={URL_LESSON_CREATE}
                onClick={(e) => { e.stopPropagation(); navigate(URL_LESSON_CREATE); }}
                data-test='create-new-lesson-action'
              >
                Create a new activity
              </Button>
            </div>
          </div>

        </AccordionSummary>
        <AccordionDetails>
          <div className='accordion-detail'>
            <SearchTextField
              filteredText={filteredText}
              onFilterUpdate={(newValue) => { authoredLessonsSearchCriteriaDomain.updateFilterText(newValue); }}
            />
            <AuthoredLessonPendingFilter />
          </div>
          <div>
            {selectedPendingStatus === FILTER_STATUS[2].value && (
              <AuthoredLessonCourseFilter />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AuthoredLessonsFilter;

import {
  ButtonBase,
} from '@mui/material';
import './ForgotPassword.scss';
import UsernameToResetForm from './UsernameToResetForm';
import {
  DIALOG_STATE,
  useDialogControl,
  useHandleSnackbar,
} from './ForgotPasswordHooks';
import StudentResetPassword from './StudentResetPassword';
import EmailSentNotification from './EmailSentNotification';
import VizzleSnackbar from '../../../components/snackbar/VizzleSnackbar';

const ForgotPassword = () => {
  const {
    state,
    handleOnForgotPasswordClick,
    handleOnClose,
    handleNextSubmit,
    handleStudentBack,
    loading,
  } = useDialogControl();

  const {
    snackbar,
    onSnackbarClose,
  } = useHandleSnackbar();

  return (
    <>
      <ButtonBase
        className='forgot-password-button'
        onClick={handleOnForgotPasswordClick}
        data-test='forgot-password-link'
      >
        Forgot your password?
      </ButtonBase>
      {
        state === DIALOG_STATE.RESET_PASSWORD && (
          <UsernameToResetForm
            openDialog
            onClose={handleOnClose}
            onSubmit={handleNextSubmit}
            loading={loading}
          />
        )
      }
      {
        state === DIALOG_STATE.EMAIL_SENT && (
          <EmailSentNotification
            openDialog
            onClose={handleOnClose}
          />
        )
      }
      {
        state === DIALOG_STATE.STUDENT && (
          <StudentResetPassword
            openDialog
            onClose={handleOnClose}
            backFunction={handleStudentBack}
          />
        )
      }
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </>
  );
};

export default ForgotPassword;

import PropTypes from 'prop-types';
import LockIcon from '@mui/icons-material/Lock';
import CardAction from '../../../../../../components/card/CardAction';
import './ViewMtssPlanAction.scss';
import { getSubjectId } from '../../../../../../utils/LessonsMapping';
import { findMtssLesson, useGetAllLessonsFromProfile } from '../../StudentLessonsHooks';

/**
 * Lock Lesson Action Container
 */
export default function ViewMtssPlanAction({ className, label, userProfile, lesson }) {
  const { ssoBaseUrl, eduIdentifier } = userProfile;
  const subjectId = getSubjectId(lesson);
  const allLessonsFromProfile = useGetAllLessonsFromProfile();
  const mttsLesson = findMtssLesson(lesson, allLessonsFromProfile);
  const { mtss } = mttsLesson;

  return (
    <CardAction
      icon={<LockIcon />}
      label={label}
      actionHandler={() => {
        window.open(`${ssoBaseUrl}/core/students/${eduIdentifier}/vizzlemtss/${mtss}/${subjectId}`, '_blank');
      }}
      className={`view-mtss-plan-action ${className} ${label ? 'with-label' : ''}`}
    />
  );
}

ViewMtssPlanAction.defaultProps = {
  className: '',
  label: '',
  // updateLessonFunction: () => {},
};

ViewMtssPlanAction.propTypes = {
  userProfile: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
};

import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import {
  Box,
} from '@mui/material';
import './ColorPicker.scss';

/**
 * Custom color picker for WYSIWYG text editor
 *
 * @export
 * @param {*} props
 */
export default function ColorPicker(props) {
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onChangeComplete = (color) => {
    const { onChange } = props;
    onChange('color', color.hex);
  };

  const renderModal = () => {
    const {
      config: { popupClassName },
      currentState: { color },
    } = props;
    return (
      <Box
        component='div'
        className={`rdw-colorpicker-modal ${popupClassName}`}
        onClick={stopPropagation}
      >
        <span className='rdw-colorpicker-modal-options'>
          <SketchPicker color={color || 'black'} onChangeComplete={onChangeComplete} />
        </span>
      </Box>
    );
  };

  const {
    config: { icon },
    expanded,
    onExpandEvent,
  } = props;
  return (
    <div
      className='rdw-colorpicker-wrapper'
      aria-haspopup='true'
      aria-expanded={expanded}
      aria-label='rdw-color-picker'
      title='Color Picker'
      data-test='color-picker'
    >
      <Box
        component='div'
        onClick={onExpandEvent}
        className='rdw-option-wrapper bordered-option-classname'
      >
        <img
          src={icon}
          alt=''
        />
      </Box>
      {expanded ? renderModal() : undefined}
    </div>
  );
}

ColorPicker.defaultProps = {
  onChange: () => {},
  config: {
    popupClassName: '',
    icon: null,
  },
  currentState: {
    color: 'color',
  },
  expanded: false,
  onExpandEvent: () => {},
};

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  config: PropTypes.shape({
    popupClassName: PropTypes.string,
    icon: PropTypes.any,
  }),
  currentState: PropTypes.shape({
    color: PropTypes.string,
    bgColor: PropTypes.string,
  }),
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func,
};

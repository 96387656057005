import { useMemo } from 'react';
import { useAdmin } from '../../../../context/AdminContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { searchDataByCriteria } from '../../../../../../services/AdminService';
import Logger from '../../../../../../utils/Logger';

export const useSearchStudent = () => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { searchStudentDomain } = useAdmin();

  const searchStudentByCriteria = async (criteria) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await searchDataByCriteria('student', criteria);
        searchStudentDomain.updateCriteriaAndStudentList(criteria, result);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      searchStudentDomain.updateCriteriaAndTeacherList(criteria, {
        data: [],
        included: [],
      });
    }
  };

  return {
    searchStudentByCriteria,
    loading,
  };
};

export const useSearchStudentTable = () => {
  const tableConfig = [
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '10%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '10%',
    },
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '10%',
    },
    {
      title: 'District',
      align: 'left',
      dataField: 'vizzleDistrictName',
      width: '17%',
    },
    {
      title: 'School',
      align: 'left',
      dataField: 'school',
      width: '18%',
    },
  ];

  const { searchStudentDomain } = useAdmin();
  const { studentList, hasLoaded } = searchStudentDomain.domainData;

  const tableData = useMemo(() => {
    const { data, included } = studentList;
    const result = data.map((d) => {
      const mappedData = {
        id: d.id,
        ...d.attributes,
      };

      const { relationships: { school } } = d;
      const schoolRelationshipData = school.data;

      const schoolObjectData = included.find((b) => b.id.toString() === schoolRelationshipData.id);
      if (schoolObjectData) {
        mappedData.school = schoolObjectData.attributes.name;
        mappedData.vizzleDistrictName = schoolObjectData.attributes.vizzleDistrictName;
      }

      return mappedData;
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentList]);

  return {
    tableConfig,
    tableData,
    hasLoaded,
  };
};

export const useHandleSearch = ({
  searchStudentByCriteria,
}) => {
  const { searchStudentDomain } = useAdmin();
  const { criteria } = searchStudentDomain.domainData;

  const handleOnSearchClick = (searchCriteria) => {
    searchStudentByCriteria(searchCriteria);
  };

  const handleOnSearchClear = (searchCriteria) => {
    searchStudentDomain.updateCriteria(searchCriteria);
  };

  return {
    criteria,
    handleOnSearchClick,
    handleOnSearchClear,
  };
};

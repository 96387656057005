import { useEffect, useState } from 'react';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { createStudent } from '../../../../../services/StudentService';

const templateStudent = {
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  confirmPassword: '',
  externalId: '',
  schoologyId: '',
};

export const MESSAGE_PASSWORD_MISMATCHED = 'Password and Confirm Password do not match.';
export const MESSAGE_DUPLICATED_NAME = 'There is another Student with the same first & last name. Are you sure you want to proceed?';
const MESSAGE_USER_WARNING = 'This Username already exists. Please try a different one.';

const invalidPassword = 'Password must be at least 4 characters';
const invalidConfirmPassword = 'Confirm Password must be at least 4 characters';
const invalidProfileFieldMessages = {
  userName: 'Username must be at least 4 characters',
  firstName: 'First name is required',
  lastName: 'Last name is required',
  stateIdentifier: 'State identifier is required',
  password: 'Password is required',
};

const templateConfirmButton = {
  open: false,
  onCancel: () => { },
  onConfirm: () => { },
};

export const useCreateStudentAction = ({
  closeDialog,
  listStudents,
  onStudentCreated,
}) => {
  const { studentsDomain, uiDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const [student, setStudent] = useState(templateStudent);
  const [booleanLogic, setBooleanLogic] = useState({
    validPassword: false,
    validConfirmPassword: false,
    showAlert: false,
    disableButton: true,
  });
  const [helperText, setHelperText] = useState({
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    userName: '',
    stateIdentifier: '',
  });
  const [saveObject, setSaveObject] = useState({});

  const [confirmButtons, setConfirmButtons] = useState(templateConfirmButton);

  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  // eslint-disable-next-line max-len
  const validateFieldsProfile = (studentToValidate = student) => (studentToValidate.firstName && studentToValidate.lastName && studentToValidate.userName && studentToValidate.password && studentToValidate.stateIdentifier);

  useEffect(() => {
    if (booleanLogic.validPassword && booleanLogic.validConfirmPassword) {
      const emptyHelperText = (!helperText.firstName && !helperText.lastName && !helperText.userName && !helperText.stateIdentifier);
      if (student.password === student.confirmPassword) {
        if (emptyHelperText && validateFieldsProfile()) {
          setBooleanLogic({ ...booleanLogic, disableButton: false, showAlert: false });
        } else {
          setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: false });
        }
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student.password, student.confirmPassword]);

  const validateHelperText = (errorMessage, updatedStudent) => {
    const emptyProfileHelperText = (!errorMessage.firstName && !errorMessage.lastName && !errorMessage.userName && !errorMessage.stateIdentifier);
    if (emptyProfileHelperText) {
      const emptyPasswordError = (!errorMessage.password && !errorMessage.confirmPassword);
      if (emptyPasswordError && student.password === student.confirmPassword && validateFieldsProfile(updatedStudent)) {
        setBooleanLogic({ ...booleanLogic, disableButton: false });
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
  };

  const validateFieldInput = (e, updatedStudent) => {
    const errorText = helperText;
    if (e.target.value) {
      switch (e.target.name) {
        case 'userName':
          if (e.target.value.length >= 4) {
            errorText.userName = '';
            setHelperText({ ...errorText });
          } else {
            errorText.userName = invalidProfileFieldMessages.userName;
            setHelperText({ ...errorText });
          }
          break;
        case 'firstName':
          errorText.firstName = '';
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = '';
          setHelperText({ ...errorText });
          break;
        case 'stateIdentifier':
          errorText.stateIdentifier = '';
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    } else {
      switch (e.target.name) {
        case 'userName':
          errorText.userName = invalidProfileFieldMessages.userName;
          setHelperText({ ...errorText });
          break;
        case 'firstName':
          errorText.firstName = invalidProfileFieldMessages.firstName;
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = invalidProfileFieldMessages.lastName;
          setHelperText({ ...errorText });
          break;
        case 'stateIdentifier':
          errorText.stateIdentifier = invalidProfileFieldMessages.stateIdentifier;
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    }
    validateHelperText(errorText, updatedStudent);
  };

  const onStudentChange = (e) => {
    const updatedStudent = { ...student, [e.target.name]: e.target.value };
    setStudent({ ...student, [e.target.name]: e.target.value });
    setSaveObject({ ...saveObject, [e.target.name]: e.target.value });
    validateFieldInput(e, updatedStudent);
    if (confirmButtons.open) {
      setConfirmButtons(templateConfirmButton);
    }
  };

  const onPasswordChange = (e) => {
    setStudent({ ...student, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && e.target.value.length >= 4) {
      setBooleanLogic({ ...booleanLogic, validPassword: true });
      setHelperText({ ...helperText, password: '' });
      setSaveObject({ ...saveObject, password: e.target.value });
    } else {
      setBooleanLogic({ ...booleanLogic, validPassword: false });
      setHelperText({ ...helperText, password: invalidPassword });
      delete saveObject.password;
      setSaveObject({ ...saveObject });
    }
  };

  const onConfirmPasswordChange = (e) => {
    setStudent({ ...student, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && e.target.value.length >= 4) {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: true });
      setHelperText({ ...helperText, confirmPassword: '' });
    } else {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: false });
      setHelperText({ ...helperText, confirmPassword: invalidConfirmPassword });
    }
  };

  const clearState = () => {
    setBooleanLogic({
      validPassword: false,
      validConfirmPassword: false,
      showAlert: false,
      disableButton: true,
    });
    setHelperText({
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      userName: '',
      stateIdentifier: '',
    });
    setSaveObject({});
  };

  const onClear = () => {
    setStudent({ ...templateStudent });
    clearState();
    setConfirmButtons(templateConfirmButton);
  };

  const onCancel = () => {
    onClear();
    closeDialog();
  };

  const isNameDuplicated = (filterFn) => {
    if (listStudents && listStudents.length > 0) {
      const result = listStudents.filter(filterFn);
      if (result && result.length > 0) {
        return true;
      }
    }
    return false;
  };

  const compareFullName = (originalFullName, studentData) => {
    const targetFullName = `${studentData.attributes.firstName} ${studentData.attributes.lastName}`;
    if (originalFullName === targetFullName) {
      return true;
    } else {
      return false;
    }
  };

  const compareUserName = (originalUsername, studentData) => {
    if (originalUsername === studentData.attributes.userName) {
      return true;
    } else {
      return false;
    }
  };

  const onConfirmNo = () => {
    setConfirmButtons({ ...confirmButtons, open: false });
  };

  const createNewStudent = async (studentData) => {
    const fullName = `${studentData.firstName} ${studentData.lastName}`;
    uiDomain.showLoader(`Student ${fullName} is adding`, LOADER_TYPE.HASH_LOADER);
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await createStudent(student);
        const newStudent = result.data.data;
        const studentTemplate = {
          id: Number(newStudent.id),
          firstName: newStudent.attributes.firstName,
          lastName: newStudent.attributes.lastName,
          userName: newStudent.attributes.userName,
          masteryNotificationUnread: false,
        };
        students.push(studentTemplate);
        await studentsDomain.updateStudentList(students);
        await uiDomain.hideLoader();
        onStudentCreated();
        await uiDomain.showSnackbar(true, 'info', 3000, `Student ${fullName} is created `);
        onCancel();
      });
    } catch (e) {
      await uiDomain.hideLoader();
    }
  };

  const onConfirmYes = async (studentData) => {
    setConfirmButtons({ ...confirmButtons, open: false });
    await createNewStudent(studentData);
  };

  const onSave = async () => {
    const fullName = `${student.firstName} ${student.lastName}`;
    const isFullNameDuplicated = isNameDuplicated((data) => compareFullName(fullName, data));
    const isUserNameDuplicated = isNameDuplicated((data) => compareUserName(student.userName, data));
    if (isUserNameDuplicated) {
      await uiDomain.showSnackbar(true, 'error', 3000, MESSAGE_USER_WARNING);
    } else if (isFullNameDuplicated) {
      setConfirmButtons({ open: true, onCancel: onConfirmNo, onConfirm: () => onConfirmYes(student) });
    } else {
      await createNewStudent(student);
    }
  };

  const onCloseAlert = () => {
    setBooleanLogic({ ...booleanLogic, showAlert: false });
  };

  return {
    loading,
    student,
    booleanLogic,
    helperText,
    confirmButtons,
    onStudentChange,
    onPasswordChange,
    onConfirmPasswordChange,
    onClear,
    onCancel,
    onSave,
    onCloseAlert,
  };
};

export const useCreateButton = () => {
  const [showCreateStudentPanel, setShowCreateStudentPanel] = useState(false);
  const handleOnCreateButtonClick = () => {
    setShowCreateStudentPanel(true);
  };

  const closeDialog = () => {
    setShowCreateStudentPanel(false);
  };

  return {
    showCreateStudentPanel,
    handleOnCreateButtonClick,
    closeDialog,
  };
};

import Logger from './Logger';

/**
 * To calculate the card size based on the width of the screen
 * @returns the width of the cards and number of cards can be displayed
 * Example
 * {
 *  width: 205,
 *  numberOfCardsToDisplay: 4
 * }
 */
export function calculateCardSize() {
  let width = 205;

  if (window.innerWidth <= 1024) {
    width = 186;
  } else if (window.innerWidth > 1024 && window.innerWidth <= 1550) {
    width = 205;
  } else if (window.innerWidth > 1550) {
    width = 225;
  }

  const offset = 30;
  const slidesToShow = window.innerWidth / (width + offset);

  const result = {
    width,
    slidesToShow,
    slidesToScroll: Math.floor(slidesToShow),
  };
  Logger.logWhenDebugModeIsOn({
    CARD_SIZE: result,
  });
  return result;
}

/**
 * To calculate the card size based on the width of the screen
 * @returns the width of the cards and number of cards can be displayed
 * Example
 * {
 *  width: 115,
 *  height: 85
 *  numberOfCardsToDisplay: 4
 * }
 */
export function calculateCardThumbnailSize() {
  /** Defind the width, offset and ratio */
  let width = 127;
  let ratio = 1.8;

  if (window.innerWidth <= 1024) {
    width = 121;
    ratio = 1.6;
  } else if (window.innerWidth > 1024 && window.innerWidth <= 1550) {
    width = 145;
  } else if (window.innerWidth > 1550) {
    width = 162;
  }

  const height = Math.round(width / ratio);

  const result = {
    width,
    height,
  };
  return result;
}
export default calculateCardSize;

import { Navigate, Route, Routes } from 'react-router-dom';
import SearchSwitch from './components/search-switch/SearchSwitch';
import { URL_ADMIN } from '../../../../routes/RouteConstants';
import { URL_SEARCH_STUDENT, URL_SEARCH_TEACHER } from './AdminSearchConstants';
import './AdminSearch.scss';
import SearchStudent from './components/search-resources/SearchStudent';
import SearchTeacher from './components/search-resources/SearchTeacher';

const AdminSearch = () => {
  const basePath = `${URL_ADMIN}/search`;
  return (
    <div className='admin-search'>
      <SearchSwitch />
      <Routes>
        <Route
          exact
          path={`${URL_SEARCH_TEACHER}`}
          element={<SearchTeacher />}
        />
        <Route
          exact
          path={`${URL_SEARCH_STUDENT}`}
          element={<SearchStudent />}
        />
        <Route
          exact
          path='*'
          element={<Navigate to={`${basePath}/${URL_SEARCH_TEACHER}`} />}
        />
      </Routes>
    </div>
  );
};

export default AdminSearch;

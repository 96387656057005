import Timer from '../../../../utils/Timer';
import boardNumbered from '../../../../static/images/activities/game/board_numbered.png';
import boardNumberedFriendly from '../../../../static/images/activities/game/board_color_friendly.png';
import boardColorPrimary from '../../../../static/images/activities/game/board_color_primary.png';
import boardNumberedPastel from '../../../../static/images/activities/game/board_color_pastel.png';
import boardNumberedOlder from '../../../../static/images/activities/game/board_color_older.png';
import boardMoveBackward from '../../../../static/images/activities/game/board_move_backward.png';
import boardMoveForward from '../../../../static/images/activities/game/board_move_forward.png';
import boardLooseATurn from '../../../../static/images/activities/game/board_lose_a_turn.png';
import spinnerArrow from '../../../../static/images/activities/game/spinner.png';
import {
  TILE_TYPE_SPIN_AGAIN,
  TILE_TYPE_LOSE_A_TURN,
  TILE_TYPE_FORWARD,
  TILE_TYPE_BACKWARD_2_SPACES,
  SPINNER_BOARD_NUMBER,
  SPINNER_BOARD_COLOR_PRIMARY,
  SPINNER_BOARD_COLOR_FRIENDLY,
  SPINNER_BOARD_COLOR_PASTEL,
  SPINNER_BOARD_COLOR_OLDER,
  SPINNER_COLOR_MAP,
} from './GameConstants';

const getSpinnerBoard = (spinner) => {
  switch (spinner) {
    case SPINNER_BOARD_NUMBER:
      return boardNumbered;
    case SPINNER_BOARD_COLOR_FRIENDLY:
      return boardNumberedFriendly;
    case SPINNER_BOARD_COLOR_PRIMARY:
      return boardColorPrimary;
    case SPINNER_BOARD_COLOR_PASTEL:
      return boardNumberedPastel;
    case SPINNER_BOARD_COLOR_OLDER:
      return boardNumberedOlder;
    default:
      return boardNumbered;
  }
};

const getNextPlayerState = (playersState, numberOfGameCards, gameSpaceType) => {
  if (TILE_TYPE_SPIN_AGAIN === gameSpaceType || playersState.players.length === 1) {
    return {
      ...playersState,
      currentPlayer: playersState.players[playersState.currentPlayer].currentCardIndex === numberOfGameCards - 1 ? -1 : playersState.currentPlayer,
    };
  }

  const nextAvailablePlayer = playersState.players.findIndex((p) => (
    p.currentCardIndex < numberOfGameCards - 1
  ));
  if (nextAvailablePlayer < 0) {
    return {
      ...playersState,
      currentPlayer: -1,
    };
  }

  let nextPlayer = playersState.currentPlayer;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    nextPlayer++;
    if (nextPlayer >= playersState.players.length) {
      nextPlayer = 0;
    }
    if (playersState.players[nextPlayer].currentCardIndex < numberOfGameCards - 1) {
      break;
    }
  }

  if (playersState.players[nextPlayer].loseATurn) {
    const newState = {
      ...playersState,
      currentPlayer: nextPlayer,
    };
    newState.players[nextPlayer].loseATurn = false;
    return getNextPlayerState(newState, numberOfGameCards, gameSpaceType);
  } else {
    return {
      ...playersState,
      currentPlayer: nextPlayer,
    };
  }
};

const movePlayerPieceWithAnimation = (currentPlayer, moveToCardIndex, currentCardElement, getMoveToCard) => {
  const { elementId } = currentPlayer;
  const playerPieceElement = document.getElementById(elementId);

  // const currentCardElement = document.getElementById(`game-card-${pageIndex}-${currentPlayer.currentCardIndex}`);
  // Get position of the current card
  const distanceIconAndStartLeft = currentCardElement.getBoundingClientRect().left;
  const distanceIconAndStartTop = currentCardElement.getBoundingClientRect().top;

  playerPieceElement.style.position = 'absolute';
  playerPieceElement.style.height = '7em';
  playerPieceElement.style.zIndex = 200;
  playerPieceElement.style.left = '10px';

  let timer = null;
  const promise = new Promise((resolve) => {
    let i = 1;
    const isMovingForward = currentPlayer.currentCardIndex < moveToCardIndex;
    let movePositionCounter = isMovingForward ? 1 : -1;
    let numberOfMoves = moveToCardIndex - currentPlayer.currentCardIndex;
    if (numberOfMoves < 0) {
      numberOfMoves *= -1;
    }

    // eslint-disable-next-line no-param-reassign
    timer = new Timer(() => {
      if (i > numberOfMoves) {
        timer.stop();
        playerPieceElement.style.height = null;
        // successfully plays the animation
        resolve(true);
        return;
      }

      const moveToCard = getMoveToCard(currentPlayer.currentCardIndex + movePositionCounter);
      if (!moveToCard) {
        timer.stop();
        // failed to play all animation, no need to update the state
        resolve(false);
        return;
      }
      // find distance of the card to move to
      const distanceLeft = moveToCard.getBoundingClientRect().left;
      const distanceTop = moveToCard.getBoundingClientRect().top;

      // Calculate the distance between the current card and the target card
      const moveToLeft = distanceLeft - distanceIconAndStartLeft;
      const moveToTop = distanceTop - distanceIconAndStartTop;
      playerPieceElement.style.transform = `translate(${moveToLeft}px, ${moveToTop}px)`;
      i++;
      movePositionCounter = isMovingForward ? ++movePositionCounter : --movePositionCounter;
    }, 800);
    timer.start();
  });

  return {
    promise,
    timer,
  };
};

/**
 * Utility for Game Activity
 */
export default {
  getNextPlayerState: (playersState, numberOfGameCards, gameSpaceType) => (
    getNextPlayerState(playersState, numberOfGameCards, gameSpaceType)
  ),

  cancelAnimation: (timerRef) => {
    if (timerRef) {
      timerRef.stop();
    }
  },

  movePlayerPieceWithAnimation: (currentPlayer, moveToCardIndex, pageIndex) => {
    const currentCardElement = document.getElementById(`game-card-${pageIndex}-${currentPlayer.currentCardIndex}`);
    return movePlayerPieceWithAnimation(currentPlayer, moveToCardIndex, currentCardElement, (moveToIndex) => document.getElementById(`game-card-${pageIndex}-${moveToIndex}`));
  },

  movePlayerPiece: (currentPlayer, moveToCardIndex, gameCellCardRefs) => {
    const currentCardElement = gameCellCardRefs[currentPlayer.currentCardIndex];
    return movePlayerPieceWithAnimation(currentPlayer, moveToCardIndex, currentCardElement, (moveToIndex) => gameCellCardRefs[moveToIndex]);
  },

  getSpecialCardText: (gameSpaceType) => {
    switch (gameSpaceType) {
      case TILE_TYPE_LOSE_A_TURN:
        return 'Lose a turn';
      case TILE_TYPE_SPIN_AGAIN:
        return 'spin again';
      case TILE_TYPE_FORWARD:
        return 'move forward 1 space';
      case TILE_TYPE_BACKWARD_2_SPACES:
        return 'move backward 2 spaces';
      default:
        return '';
    }
  },

  getSpinnerBoard: (spinner) => (
    getSpinnerBoard(spinner)
  ),

  getSpaceTypeImage: (spinner, gameSpaceType) => {
    if (gameSpaceType === TILE_TYPE_LOSE_A_TURN || gameSpaceType === TILE_TYPE_SPIN_AGAIN) {
      return getSpinnerBoard(spinner);
    }
    if (gameSpaceType === TILE_TYPE_FORWARD) {
      return boardMoveForward;
    }
    if (gameSpaceType === TILE_TYPE_BACKWARD_2_SPACES) {
      return boardMoveBackward;
    }
    return '';
  },

  getSpaceTypeExtraImage: (gameSpaceType) => {
    if (gameSpaceType === TILE_TYPE_SPIN_AGAIN) {
      return spinnerArrow;
    }
    if (gameSpaceType === TILE_TYPE_LOSE_A_TURN) {
      return boardLooseATurn;
    }
    return '';
  },

  getNumberOfMove: (num, gameData, playersState) => {
    if (gameData.spinner === SPINNER_BOARD_NUMBER) {
      return num;
    }

    const color = SPINNER_COLOR_MAP[gameData.spinner][num - 1];
    // Get current card index of the current player
    const { currentCardIndex } = playersState.players[playersState.currentPlayer];
    let count = 1;
    for (let i = currentCardIndex; i < gameData.gameCards.length; i++) {
      if (gameData.gameCards[i].borderColor === color) {
        break;
      }
      count++;
    }
    if (count + currentCardIndex >= gameData.gameCards.length) {
      count = gameData.gameCards.length - currentCardIndex - 1;
    }
    return count;
  },
};

export const GradeSort = [
  'Pre-K', 'K', '1', '2', '3', '4', '5', '6',
  '7', '8', '9', '10', '11', '12', '9-10', '9-11', '9-12', '10-11', '10-12', '11-12',
];

export const GRADE_LIST = ['Pre-K', 'K', '1', '2', '3', '4', '5', '6', '7', '8'];

export const GRADE_LIST_WITHOUT_STANDARDS = ['Pre-K', 'High School - Other'];

export const GRADE_LIST_IGNORE_TOPICS = ['High School - Other'];

export const GRADE_BAND_MAPPING = {
  'Pre-K': 'Pre-K',
  'High School - Other': '9-12',
};

export const gradeListOptions = ['K', '1', '2', '3', '4', '5', '6',
  '7', '8', '9', '10', '11', '12'].map((g) => ({
  id: g,
  name: g,
}));

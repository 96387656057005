export const FONT_FAMILY_LIST = [
  'Alegreya',
  'Josefin Slab',
  'KG Primary Penmanship',
  'KG Primary Penmanship Lined',
  'KG Primary Dots Lined',
  'Quattrocento',
];

export const FONT_SIZE_LIST = [8, 9, 10, 11, 12, 14, 16, 18, 22, 24, 30, 36, 48, 60, 72, 96];

export const FONT_FORMAT_LIST = ['bold', 'italic', 'underline'];

import { useEffect, useState } from 'react';
import { getStandardListByTopicIds } from '../../../../../services/LessonStandardService';
import { sortByAttributes } from '../../../../../utils/ArrayUtils';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';

export const defaultStateValue = 'none';

export const useHandleStateChange = () => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { topicStandardState } = teacherCourseDomain.domainData;

  const handleStateChange = (e) => {
    teacherCourseDomain.updateTopicStandardState(e.target.value);
  };

  return {
    topicStandardState,
    handleStateChange,
  };
};

export const useGetStandardsByState = ({
  state,
}) => {
  const [topicStandards, setTopicStandards] = useState();
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { topicId } = searchCriteria;

  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  useEffect(() => {
    callRemoteServiceWrapper(async () => {
      if (topicId && state !== defaultStateValue) {
        const result = await getStandardListByTopicIds({ topicIds: [topicId], state });
        setTopicStandards(sortByAttributes(result, 'standardCode'));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId, state]);

  return {
    loading,
    topicStandards,
  };
};

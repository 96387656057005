import { createContext, useContext } from 'react';
import GroupPlayDomain from './GroupPlayDomain';

const domains = [
  new GroupPlayDomain(),
];

/*-----------------------------------------------------------------------*/

let GroupPlayContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  GroupPlayContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useGroupPlay = () => useContext(GroupPlayContextProvider);

import PropTypes from 'prop-types';
import AddRemoveFromGroupPlay from '../../../../../lessons/lessonactions/add-remove-from-group-play/AddRemoveFromGroupPlay';
import { useStudentContext } from '../../../../context/MyStudentContextProvider';
import Logger from '../../../../../../utils/Logger';

export default function LessonTrackingAddRemoveFromGroupPlay({
  lesson,
}) {
  const { myStudentDomain } = useStudentContext();
  const { masterObjectiveData } = myStudentDomain.domainData;

  return (
    <AddRemoveFromGroupPlay
      lesson={lesson}
      onSaveSuccess={async (updatedLesson) => {
        Logger.isDebugMode({
          UPDATED_LESSON: updatedLesson,
        });
        myStudentDomain.updateMasteryObjectives([...masterObjectiveData.masterObjectives], masterObjectiveData.included);
      }}
    />
  );
}

LessonTrackingAddRemoveFromGroupPlay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }).isRequired,
};

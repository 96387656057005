import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import { getDroppingAnimateClass } from '../../../../components/vizzle/page/matching/MatchingUtil';
import MatchingPromptActivity from './components/MatchingPromptActivity';
import PromptCardPlaceholder from './components/PromptCardPlaceholder';
import MatchingResponseActivity from './components/MatchingResponseActivity';
import {
  useGenerateComponentIds,
  useInitializeData,
  useDragAndDropSubmitAnswer,
  useTwoClickSubmitAnswer,
  useOneClickSubmitAnswer,
  useAutoPlay,
  usePrepareViewCorrectSubmissions,
} from './MatchingActivityHooks';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import ReplayMatchingActivityAction from './components/ReplayMatchingActivityAction';
import '../activities-common.scss';
import './MatchingActivity.scss';
import { flatten } from '../../../../utils/ArrayUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

/**
 * Lesson activity for Book
 */
const MatchingActivity = forwardRef(({
  className,
  promptCards,
  responseCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  isCurrentPage,
  initialCorrectAnswer,
  initialIncorrectAnswers,
  pageIndex,
}, ref) => {
  const rejectedAnswerResponseRef = useRef(null);
  const matchingPromptRef = useRef(null);
  const matchingResponseRefs = [];
  const replayButtonRef = useRef(null);

  const setMatchingResponseRefs = (r, index) => {
    if (r) {
      matchingResponseRefs[index] = r;
    }
  };

  const {
    id,
    prompCardDropableId,
  } = useGenerateComponentIds();

  const {
    selectedResponseIndex,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    isDraggable,
    setIsDraggable,
    dragAndDropApiRef,
    matchingActivityRef,
    singleAnswer,
  } = useInitializeData({ initialCorrectAnswer, initialIncorrectAnswers });

  const commonSubmiAnswerParams = {
    matchingActivityRef,
    prompCardDropableId,
    rejectedAnswerResponseRef,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
  };

  const {
    handleOnDragStart,
    handleOnDragEnd,
  } = useDragAndDropSubmitAnswer({
    responseCards,
    ...commonSubmiAnswerParams,
  });

  const {
    handleOnPromptCardClick,
    handleOnResponseCardClick: handleOnReponseCardClickTwoClickMode,
  } = useTwoClickSubmitAnswer({
    matchingPromptRef,
    dragAndDropApiRef,
    prompCardDropableId,
    setIsDraggable,
  });

  const {
    handleOnResponseCardClick: handleOnReponseCardClickOneClickMode,
  } = useOneClickSubmitAnswer({
    dragAndDropApiRef,
    prompCardDropableId,
    setIsDraggable,
  });

  const handleOnResponseCardClick = handleOnReponseCardClickTwoClickMode || handleOnReponseCardClickOneClickMode;

  const {
    internalIsMediaPlaying,
    internalIsMediaPlayingRef,
    isAutoPlay,
    responseCardsAnimation,
    replayCards,
  } = useAutoPlay({
    matchingActivityRef,
    selectedResponseIndex,
    matchingPromptRef,
    matchingResponseRefs,
    isCurrentPage,
    responseCards,
  });

  usePrepareViewCorrectSubmissions({
    initialCorrectAnswer,
    setSelectedResponseIndex,
    setSelectedWrongResponses,
    responseCards,
    onActivityFinished,
    pageIndex,
  });

  const isFinished = (singleAnswer && (selectedResponseIndex >= 0 || (selectedWrongResponses && selectedWrongResponses.length > 0)))
    || selectedResponseIndex >= 0;

  const getPromptCardPlaceholder = () => {
    if (selectedResponseIndex < 0) {
      return (
        <Droppable droppableId={prompCardDropableId} direction='horizontal' data-test='matching-activity-submit-box'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={prompCardDropableId}
              className={`place-holder-container
               ${snapshot.isDraggingOver ? 'vizzle-selected-component' : ''}
              `}
            >
              <div className='matching-component-hidden'>{provided.placeholder}</div>
              <PromptCardPlaceholder />
              <RejectedAnswerResponse ref={rejectedAnswerResponseRef} />
            </div>
          )}
        </Droppable>
      );
    } else {
      const { audio, video, ...rest } = responseCards[selectedResponseIndex];
      return (
        <div>
          <MatchingResponseActivity
            responseCard={rest}
            index={selectedResponseIndex}
            cardId={`matching-activity-selected-response-${id}`}
            withTts={false}
          />
          <SubmittedAnswerIndicator correctSubmission={rest.correctSubmission} />
        </div>

      );
    }
  };

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      ObjectUtils.delay(100);
      if (isFinished || internalIsMediaPlayingRef.current) {
        return [];
      } else {
        const remainingAnswers = matchingResponseRefs.filter((_m, index) => !selectedWrongResponses.includes(index));
        const result = flatten(remainingAnswers.map((m) => m.getEnableElements()));
        await ObjectUtils.delay(50);
        if (replayButtonRef.current && replayButtonRef.current.getEnableElements()) {
          result.push(replayButtonRef.current.getEnableElements());
        }
        return result;
      }
    },
  }));

  return (
    <DragDropContext
      onDragStart={handleOnDragStart}
      onDragEnd={handleOnDragEnd}
      sensors={[(sensorApi) => {
        dragAndDropApiRef.current = sensorApi;
      }]}
    >
      <div className={`matching-activity ${className}`} ref={matchingActivityRef} data-test='matching-activity'>
        <TransparentBlocker show={isFinished} />
        {isAutoPlay && !internalIsMediaPlaying && (
          <div className='replay-button-container'>
            <ReplayMatchingActivityAction onClick={replayCards} disabled={isFinished || internalIsMediaPlaying} ref={replayButtonRef} />
          </div>
        )}
        <div
          className='matching-activity-prompt-area-container'
        >
          <MatchingPromptActivity
            promptCard={promptCards[0]}
            renderQuestionPlaceholder={getPromptCardPlaceholder()}
            onClick={handleOnPromptCardClick}
            ref={matchingPromptRef}
          />
        </div>
        <Droppable droppableId={`matching-component-draggable-container-${id}`} direction='horizontal' isDropDisabled>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='matching-activity-responses'
              data-test='matching-response-responses'
            >
              <div className='matching-activity-responses-area'>
                {
                  responseCards.map((card, index) => {
                    const cardId = `${id}-${index}`;
                    const responseCardId = `matching-response-item-${cardId}`;
                    const responseCardDraggableId = `matching-drag-item-${cardId}`;
                    return (
                      <div
                        key={`matching-drag-item-container-${cardId}`}
                        id={responseCardDraggableId}
                        className={`response-card-container item-placeholder response-margin-${responseCards.length}-responses`}
                        data-test={`matching-response-responses-${index}`}
                      >
                        {index !== selectedResponseIndex && (
                          <Slide direction='left' in={responseCardsAnimation.responseCardIn[index]} timeout={responseCardsAnimation.timeout}>
                            <div
                              className={`slider-container ${internalIsMediaPlaying || isFinished ? 'fading' : ''}`}
                            >
                              <Draggable
                                key={`matching-drag-item-${cardId}`}
                                draggableId={responseCardDraggableId}
                                index={index}
                                isDragDisabled={
                                  !isDraggable
                                  || (selectedResponseIndex >= 0 || selectedWrongResponses.findIndex((s) => (s === index)) >= 0)
                                }
                                shouldRespectForcePress={false}
                                timeForLongPress={0}
                              >
                                {(providedItem, snapshotItem) => (
                                  <div
                                    ref={providedItem.innerRef}
                                    {...providedItem.draggableProps}
                                    {...providedItem.dragHandleProps}
                                    id={responseCardId}
                                    key={`matching-response-item-${cardId}`}
                                    className={`
                              matching-component-response-card-container
                              ${snapshotItem.isDragging ? 'vizzle-selected-component' : 'non-drag-items'} 
                              ${getDroppingAnimateClass(snapshotItem.isDropAnimating, snapshotItem.isDragging, card.isCorrect)}
                              ${selectedWrongResponses.findIndex((s) => (s === index)) >= 0 ? 'matching-component-disabled matching-component-fading' : ''}
                              `}
                                    style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                                  >
                                    <MatchingResponseActivity
                                      responseCard={card}
                                      index={index}
                                      cardId={responseCardId}
                                      onClick={(e, isTtsAbleToPlay) => handleOnResponseCardClick && handleOnResponseCardClick(e, isTtsAbleToPlay, card, responseCardId, responseCardDraggableId)}
                                      ref={(r) => setMatchingResponseRefs(r, index)}
                                      disablePlay={isAutoPlay}
                                      isDraggable={isDraggable}
                                    />
                                    <SubmittedAnswerIndicator correctSubmission={card.correctSubmission} />
                                  </div>
                                )}

                              </Draggable>
                            </div>
                          </Slide>
                        )}
                        {provided.placeholder}
                      </div>

                    );
                  })
                }
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
});

export default MatchingActivity;

MatchingActivity.defaultProps = {
  className: '',
  promptCards: [{}],
  responseCards: [{}, {}, {}, {}, {}],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  isCurrentPage: false,
  initialCorrectAnswer: -1,
  initialIncorrectAnswers: [],
  pageIndex: -1,
};

MatchingActivity.propTypes = {
  className: PropTypes.string,
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    isCorrect: PropTypes.bool,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  initialCorrectAnswer: PropTypes.number,
  initialIncorrectAnswers: PropTypes.arrayOf(PropTypes.number),
  pageIndex: PropTypes.number,
};

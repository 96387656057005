import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../AppConstants';
import { getInitialDataForGame } from './ActivityTypeInitialData';
import { flatten } from '../ArrayUtils';

/**
 * Check whether the card is empty or not
 * @param {*} card
 */
export const isCardEmpty = ((card) => (
  !card.text && !card.audio && !card.image
));

/**
 * Check whether the response card is empty or not
 * @param {*} response card
 */
export const isResponseCardEmpty = ((card) => (
  isCardEmpty(card) && !card.answerKey
));

/**
 * Check whether the prompt card is empty or not
 * @param {*} prompt card
 */
const isPropmtCardEmpty = ((card) => isCardEmpty(card) && !card.questionKey);

/**
 * Check whether the sequence card is empty or not
 * @param {*} sequence card
 */
const isSequenceCardEmpty = ((card) => isCardEmpty(card));

/**
 * Check whether book lesson page is empty or not
 * @param {*} pageData book page data
 */
const isBuildABookEmpty = (pageData) => {
  // Find which card in the book cards arrays has some value
  const notEmptyIndex = pageData.cards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isCardEmpty(card)));
  return notEmptyIndex < 0;
};

/**
 * Check whether mathcing activity page is empty or not
 * @param {*} pageData matching activity page data
 */
const isMatchingActivityEmpty = (pageData) => {
  // Find which card in the promt cards arrays has some value
  const notEmptyPromptIndex = pageData.promptCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isPropmtCardEmpty(card)));

  // Find which card in the response cards arrays has some value
  const notEmptyResponseIndex = pageData.responseCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isResponseCardEmpty(card) && !card.key));

  return notEmptyPromptIndex < 0 && notEmptyResponseIndex < 0;
};

/**
 * Check whether sorting activity page is empty or not
 * @param {*} pageData sorting activity page data
 */
const isSortingActivityEmpty = (pageData) => {
  const { instructions } = pageData;
  const isInstructionsEmpty = instructions.text.defaultText && !instructions.audio && !instructions.image;

  const promptCards = flatten(pageData.categories.map((category) => (
    category.promptCards
  )));
  const notEmptyPromptIndex = promptCards.findIndex((card) => (!isPropmtCardEmpty(card)));

  const responseCards = flatten(pageData.categories.map((category) => (
    category.responseCards
  )));
  const notEmptyResponseIndex = responseCards.findIndex((card) => (!isResponseCardEmpty(card)));
  return isInstructionsEmpty && notEmptyPromptIndex < 0 && notEmptyResponseIndex < 0;
};

/**
 * Check whether game activity page is empty or not
 * @param {*} pageData sorting activity page data
 */
const isGameActivityEmpty = (pageData) => JSON.stringify(pageData) === JSON.stringify(getInitialDataForGame());

const isSequencingActivityEmpty = (pageData) => {
  const { instructions } = pageData;
  const isInstructionsEmpty = instructions.text.defaultText && !instructions.audio && !instructions.image;

  const notEmptyPromptIndex = pageData.sequenceCards.findIndex((card) => (!isSequenceCardEmpty(card)));

  const notEmptyResponseIndex = pageData.responseCards.findIndex((card) => (!isCardEmpty(card)));
  return isInstructionsEmpty && notEmptyPromptIndex < 0 && notEmptyResponseIndex < 0;
};

const isCauseAndEffectActivityEmpty = (pageData) => {
  const notEmptyCauseIndex = pageData.causeCards.findIndex((card) => (!isCardEmpty(card)));
  const notEmptyEffectIndex = pageData.effectCards.findIndex((card) => (!isCardEmpty(card)));
  return notEmptyCauseIndex < 0 && notEmptyEffectIndex < 0;
};

const isFillInTheBlankActivityEmpty = (pageData) => {
  const notEmptyIndex = pageData.cards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isCardEmpty(card)));
  return notEmptyIndex < 0;
};

const isCategorizingActivityEmpty = (pageData) => {
  const { instructions, promptCards, responseCards } = pageData;
  const isInstructionsEmpty = !instructions.text && !instructions.audio && !instructions.image;
  const notEmptyPromptIndex = promptCards.findIndex((card) => (!isPropmtCardEmpty(card)));
  const notEmptyResponseIndex = responseCards.findIndex((card) => (!isResponseCardEmpty(card)));
  return isInstructionsEmpty && notEmptyPromptIndex < 0 && notEmptyResponseIndex < 0;
};

const isFlixiMatchingActivityEmpty = (pageData) => {
  const {
    promptCards,
    responseCards,
    promptText,
    subText,
    key,
  } = pageData;

  const isTextEmpty = promptText === null && subText === null && !key;

  // Find which card in the promt cards arrays has some value
  const notEmptyPromptIndex = promptCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isPropmtCardEmpty(card)));

  // Find which card in the response cards arrays has some value
  const notEmptyResponseIndex = responseCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isResponseCardEmpty(card) && !card.key));

  return notEmptyPromptIndex < 0 && notEmptyResponseIndex < 0 && isTextEmpty;
};

const isMultipleChoiceActivityEmpty = (pageData) => {
  const isQuestionCardEmpty = isPropmtCardEmpty(pageData.questionCard);

  // Find which card in the response cards arrays has some value
  const notEmptyResponseIndex = pageData.answerCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isResponseCardEmpty(card) && !card.key));

  return isQuestionCardEmpty && notEmptyResponseIndex < 0;
};

const isMultipleChoiceActivityV2Empty = (pageData) => {
  const {
    questionCard,
    answerCards,
  } = pageData;

  const isQuestionCardEmpty = isPropmtCardEmpty(questionCard);

  // Find which card in the response cards arrays has some value
  const notEmptyResponseIndex = answerCards
    .filter((card) => (!card.hide))
    .findIndex((card) => (!isResponseCardEmpty(card) && !card.key));

  return isQuestionCardEmpty && notEmptyResponseIndex < 0;
};

const mapper = {};
mapper[ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA] = isBuildABookEmpty;
mapper[ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA] = isBuildABookEmpty;
mapper[ACTIVITY_TYPE_MATCHING_ACTIVITY] = isMatchingActivityEmpty;
mapper[ACTIVITY_TYPE_SORTING_ACTIVITY] = isSortingActivityEmpty;
mapper[ACTIVITY_TYPE_GAME_ACTIVITY] = isGameActivityEmpty;
mapper[ACTIVITY_TYPE_SEQUENCING_ACTIVITY] = isSequencingActivityEmpty;
mapper[ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY] = isCauseAndEffectActivityEmpty;
mapper[ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE] = isFillInTheBlankActivityEmpty;
mapper[ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING] = isFillInTheBlankActivityEmpty;
mapper[ACTIVITY_TYPE_CATEGORIZING_ACTIVITY] = isCategorizingActivityEmpty;
mapper[ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY] = isFlixiMatchingActivityEmpty;
mapper[ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY] = isMultipleChoiceActivityEmpty;
mapper[ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2] = isMultipleChoiceActivityV2Empty;

/**
 * Check whether page data is empty or not
 * @param {*} pageData page data
 */
export const checkEmptyActivity = (pageData) => {
  const checker = mapper[pageData.activityType];
  if (checker) {
    return checker(pageData);
  }
  return true;
};

export default checkEmptyActivity;

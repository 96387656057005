import PropTypes from 'prop-types';
import { Box, Typography, TextField, MenuItem, CircularProgress } from '@mui/material';
import './DropdownInput.scss';

export default function DropdownInput({
  label,
  dropdownValues,
  loading,
  inlineLabel,
  containerClass,
  labelVariant,
  ...rest
}) {
  return (
    <Box width='100%' className={`dropdown-input ${containerClass}`}>
      {loading && (
        <CircularProgress size={25} className={`spinner ${label ? '' : 'no-label'}`} />
      )}
      {label && (
        <Typography variant={labelVariant}>{label}</Typography>
      )}
      <TextField
        select
        variant='outlined'
        fullWidth
        label={inlineLabel}
        data-private
        {...rest}
      >
        {dropdownValues ? dropdownValues.map((l) => (
          <MenuItem
            key={l.id}
            value={l.id}
            data-test={`selector-option-${l.id}`}
            disabled={l.disabled}
            data-private
          >
            {l.name}
          </MenuItem>
        ))
          : <div />}
      </TextField>
    </Box>
  );
}

DropdownInput.defaultProps = {
  label: '',
  inlineLabel: undefined,
  dropdownValues: [],
  loading: false,
  containerClass: '',
  labelVariant: 'h5',
};

DropdownInput.propTypes = {
  label: PropTypes.string,
  inlineLabel: PropTypes.string,
  dropdownValues: PropTypes.array,
  loading: PropTypes.bool,
  containerClass: PropTypes.string,
  labelVariant: PropTypes.string,
};

import { Children, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LessonNavigator.scss';
import { Button, Toolbar } from '@mui/material';
import { isMobile } from '../../utils/DeviceUtils';

/**
 * Lesson data navigator
 * @param {*}
 */
export const TOOLBAR_ACTIONS_ID = 'toolbar-actions-placeholder-id';
export const TOOLBAR_NEXT_ACTION_ID = 'toolbar-next-action-id';
export default function LessonNavigator({
  children,
  afterSlideChange,
  beforeSlideChange,
  initialSlide,
  className,
  toolbarChildren,
}) {
  const sliderRef = useRef(null);
  const currentSlide = useRef(initialSlide);

  const [state, setState] = useState({
    previousButtonDisable: initialSlide === 0,
    nextButtonDisable: initialSlide === children.length - 1,
  });

  useEffect(() => {
    if (children.length > 0) {
      setState({
        ...state,
        nextButtonDisable: initialSlide === children.length - 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.length]);

  const handleAfterSlideChange = (newIndex) => {
    afterSlideChange(newIndex);
    currentSlide.current = newIndex;
    setState({
      ...state,
      previousButtonDisable: newIndex <= 0,
      nextButtonDisable: (newIndex + 1) >= children.length,
    });
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    className: 'lesson-navigate-slider',
    afterChange: handleAfterSlideChange,
    beforeChange: beforeSlideChange,
    swipe: false,
    initialSlide,
    arrows: false,
    ref: sliderRef,
    accessibility: false,
  };

  const next = () => {
    if (!state.nextButtonDisable) {
      sliderRef.current.slickGoTo(currentSlide.current + 1);
    }
  };

  const previous = () => {
    if (!state.previousButtonDisable) {
      sliderRef.current.slickGoTo(currentSlide.current - 1);
    }
  };

  return (
    <div className={`lesson-navigate-component ${className}`}>
      <Slider {...settings}>
        {Children.map(children, (child) => (
          <div className='lesson-navigator-element-container'>
            {child}
          </div>
        ))}
      </Slider>
      <div className='lesson-navigate-bottom-bar'>
        <Toolbar className='lesson-navigate-toolbar'>
          {!state.previousButtonDisable && (
            <Button
              className={`navigation-button ${isMobile() ? 'navigation-button-mobile' : ''}`}
              disableRipple
              disableElevation
              disableFocusRipple
              onClick={previous}
              alt='Go Backward'
              data-test='navigation-botton-backward'
            >
              <ArrowBackIcon className='icon' />
            </Button>
          )}
          <div className='right container'>
            <div className='hide' id={TOOLBAR_ACTIONS_ID} />
            {toolbarChildren}
            {!state.nextButtonDisable && (
              <Button
                id={TOOLBAR_NEXT_ACTION_ID}
                className={`navigation-button ${isMobile() ? 'navigation-button-mobile' : ''}`}
                disableRipple
                disableElevation
                disableFocusRipple
                onClick={next}
                alt='Go Forward'
                data-test='navigation-botton-forward'
              >
                <ArrowForwardIcon className='icon' />
              </Button>
            )}
          </div>
        </Toolbar>
      </div>
    </div>
  );
}

LessonNavigator.defaultProps = {
  children: <div />,
  afterSlideChange: () => { },
  beforeSlideChange: () => { },
  className: '',
  initialSlide: 0,
  toolbarChildren: null,
};

LessonNavigator.propTypes = {
  children: PropTypes.node,
  afterSlideChange: PropTypes.func,
  beforeSlideChange: PropTypes.func,
  className: PropTypes.string,
  initialSlide: PropTypes.number,
  toolbarChildren: PropTypes.node,
};

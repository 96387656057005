import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  SearchOutlined as SearchOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useDebounce } from '../../../../../utils/ReactUtil';

const subtitle = 'To Add Students, please click on the green + icon';
const StudentSearchBox = ({ listStudents, updateSearchResult }) => {
  const [searchStudent, setSearchStudent] = useState('');
  const searchDebounce = useDebounce(searchStudent, 400);

  const onSearchChanged = (e) => {
    setSearchStudent(e.target.value);
  };

  const onFilter = () => {
    if (searchDebounce && searchDebounce.trim()) {
      const search = searchDebounce.trim().toLowerCase();
      const filtered = listStudents.filter((item) => {
        const { userName, firstName, lastName, externalId } = item.attributes;
        const name = `${firstName} ${lastName}`.toLowerCase();
        if (name.indexOf(search) > -1) {
          return true;
        }
        if (userName.toLowerCase().indexOf(search) > -1) {
          return true;
        }
        if (externalId) {
          return externalId.toLowerCase().indexOf(search) > -1;
        }
        return false;
      });
      updateSearchResult(filtered);
    } else {
      updateSearchResult(listStudents);
    }
  };

  const handleClearKeywordClick = () => {
    setSearchStudent('');
  };

  useEffect(() => {
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounce, listStudents]);

  return (
    <>
      <SearchOutlinedIcon />
      <TextField
        className='header-action-search-txtfield'
        data-private
        placeholder='Search existing students'
        onChange={onSearchChanged}
        value={searchStudent}
        variant='standard'
        InputProps={{
          endAdornment:
            searchStudent && searchStudent.length > 0 ? (
              <InputAdornment position='end' className='clear-keyword-button'>
                <IconButton
                  aria-label='Clear keyword'
                  onClick={handleClearKeywordClick}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}

      />
      <Typography gutterBottom variant='subtitle1' component='p' className='search-existing-student-subtitle'>
        {subtitle}
      </Typography>
    </>
  );
};

StudentSearchBox.propTypes = {
  listStudents: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSearchResult: PropTypes.func.isRequired,
};

export default StudentSearchBox;

import { useRef, useEffect } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useLocation,
} from 'react-router-dom';

import { useDomain } from '../states/AppDomainProvider';
import { PrivateRoute } from './PrivateRoute';
import LoginContainer from '../containers/login/Login';
import LandingLogin from '../containers/login/LandingLogin';
import ResetPassword from '../containers/login/reset-password/ResetPassword';
import LoginWithClever from '../containers/login/LoginWithClever';
import Logger from '../utils/Logger';
import {
  URL_LESSON,
  URL_LESSON_FINISHED,
  URL_LOGIN,
  URL_LANDING_LOGIN,
  URL_LOGIN_WITH_CLEVER_CODE,
  URL_LOGIN_WITH_VIZZLE_TOKEN,
  URL_RESET_PASSWORD,
  URL_USER_VERIFY,
  URL_LOGIN_FROM_RETHINK_WITH_ACCESS_TOKEN,
  URL_LOGIN_FROM_RETHINK_MAPP,
  URL_LOGIN_WITH_CLASS_LINK,
  URL_VIZZLE_LOGIN,
  URL_LOGIN_RETHINK_SIGNIN_OIDC,
} from './RouteConstants';
import { isTeacher, isMfaUser } from '../utils/User';
import getTeacherRoutes from './config/TeacherRoutes';
import getStudentRoutes from './config/StudentRoutes';
import { generateMfaRoutes } from './config/MfaRoutes';
import LoginWithVizzleToken from '../containers/login/LoginWithVizzleToken';
import LessonPlayMain from '../containers/lessonplay/lesson-play-by-environment/LessonPlayMain';
import UserVerify from '../containers/login/user-verify/UserVerify';
import LessonFinished from '../containers/lessonplay/LessonFinished';
import LoginRethinkSsoWithAccessToken from '../containers/login/LoginRethinkSsoWithAccessToken';
import LoginWithClassLink from '../containers/login/LoginWithClassLink';
import UnderMaintenancePage from '../containers/landing/UnderMaintenancePage';
import LoginRethinkSignInOidc from '../containers/login/rethink-open-id/LoginRethinkSignInOidc';
import LoginRethinkOpenId from '../containers/login/rethink-open-id/LoginRethinkOpenIdWithToken';

/**
 * Create routers of the application
 */
export const RouterConfiguration = () => {
  const location = useLocation();
  const currentLocation = useRef(null);

  useEffect(() => {
    const currentLocationInfo = currentLocation.current;
    const url = window.location.href;
    currentLocation.current = {
      location,
      url,
    };

    Logger.logWhenDebugModeIsOn({
      CURRENT_LOCATION: currentLocationInfo,
      NEW_URL: currentLocation.current,
    });
  }, [location]);

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  // const availableRoutes = useMemo(() => {
  //   if (isTeacher(user)) {
  //     return getTeacherRoutes(user);
  //   } else {
  //     return getStudentRoutes();
  //   }
  // }, [user]);

  const availableRoutes = isTeacher(user) ? getTeacherRoutes(user) : getStudentRoutes();

  const isMfa = isMfaUser(user);

  return (
    <Routes>
      {process.env.REACT_APP_UNDER_MAINTENANCE === 'true' ? (
        <>
          <Route
            path='/'
            element={(
              <UnderMaintenancePage />
            )}
          />,
          <Route
            path='*'
            caseSensitive={false}
            element={<Navigate to='/' replace />}
          />
        </>
      ) : (
        <>
          <Route path={URL_LOGIN} element={<LoginRethinkOpenId />} />
          <Route path={URL_LOGIN_RETHINK_SIGNIN_OIDC} element={<LoginRethinkSignInOidc />} />
          <Route path={URL_VIZZLE_LOGIN} element={<LoginContainer />} />
          <Route path={URL_LANDING_LOGIN} element={<LandingLogin />} />
          <Route path={URL_LOGIN_WITH_VIZZLE_TOKEN} element={<LoginWithVizzleToken />} />
          <Route path={URL_RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={URL_USER_VERIFY} element={<UserVerify />} />
          <Route path={URL_LOGIN_WITH_CLEVER_CODE} element={<LoginWithClever />} />
          <Route path={URL_LOGIN_WITH_CLASS_LINK} element={<LoginWithClassLink />} />
          <Route path={URL_LESSON} element={<LessonPlayMain />} />
          <Route path={URL_LESSON_FINISHED} element={<LessonFinished />} />
          <Route path={URL_LOGIN_FROM_RETHINK_WITH_ACCESS_TOKEN} element={<LoginRethinkSsoWithAccessToken />} />
          <Route path={URL_LOGIN_FROM_RETHINK_MAPP} element={<LoginContainer forRethink />} />
          {isMfa ? (
            generateMfaRoutes(availableRoutes, user)
          ) : (
            availableRoutes.map((route) => (<Route key={`route-${route.path}`} path={route.path} element={<PrivateRoute {...route} />} />))
          )}

          <Route
            path='*'
            caseSensitive={false}
            element={<Navigate to={URL_LOGIN} replace />}
          />
        </>
      )}

    </Routes>
  );
};

export default RouterConfiguration;

import { TTS_MODE_AZURE, TTS_MODE_RV } from '../../../services/tts/TextToSpeechService';
import TextToSpeechSettingsFormV1 from './TextToSpeechSettingsFormV1';
import TextToSpeechSettingsFormV2 from './TextToSpeechSettingsFormV2';

const components = {
  [TTS_MODE_RV]: TextToSpeechSettingsFormV1,
  [TTS_MODE_AZURE]: TextToSpeechSettingsFormV2,
};

export default function getTextToSpeechSettingsForm(ttsMode) {
  const ttsComponent = components[ttsMode];
  return ttsComponent || TextToSpeechSettingsFormV1;
}

import VizzleMainContainer from '../../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../../appbar/TeacherTopAppbar';
import TeacherCurriculumSearchBar from './components/TeacherCurriculumSearchBar';
import TeacherCurriculumInstruction from '../common/components/instruction/TeacherCurriculumInstruction';
import TeacherCurriculumMainTab from './components/TeacherCurriculumMainTab';
import { initDomains, useTeacherCurriculum } from '../common/context/TeacherCurriculumContextProvider';
import './TeacherCurriculum.scss';

const TeacherCurriculum = () => {
  initDomains();
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { hasPerformSearched } = teacherCurriculumDomain.domainData;

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div className='teacher-curriculum'>
        <TeacherTopAppbar />
        <TeacherCurriculumSearchBar />
        {
          hasPerformSearched ? (
            <TeacherCurriculumMainTab />
          ) : (
            <TeacherCurriculumInstruction
              message='To get started, choose a Subject, Grade, and Section from the filters above.'
            />
          )
        }
      </div>
    </VizzleMainContainer>
  );
};

export default TeacherCurriculum;

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  FormControlLabel,
  Switch,
} from '@mui/material';
import './VizzleSwitch.scss';

const color = '#FBAB30';

const VizzleStyleSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 55,
  height: 26,
  padding: 0,
  overflow: 'visible',
  margin: '8px',
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: color,
        opacity: 1,
        border: 'none',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'white',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    color: 'white',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: '1px solid #bdbdbd',
    backgroundColor: 'white',
    opacity: 1,
    boxSizing: 'border-box',
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

/**
 * Switch with Vizzle theme
 */
export default function VizzleSwitch({
  labelWhenSelected,
  labelWhenUnselected,
  checked,
  ...props
}) {
  return (
    <FormControlLabel
      className='vizzle-switch-form-control'
      control={(
        // <Switch
        //   classes={{
        //     root: classes.root,
        //     switchBase: classes.switchBase,
        //     thumb: classes.thumb,
        //     track: classes.track,
        //     checked: classes.checked,
        //   }}
        //   disableRipple
        //   checked={checked}
        //   {...props}
        // />
        <VizzleStyleSwitch disableRipple checked={checked} {...props} />
      )}
      label={checked ? labelWhenSelected : labelWhenUnselected}
    />
  );
}

VizzleSwitch.defaultProps = {
  labelWhenSelected: '',
  labelWhenUnselected: '',
  checked: false,
};

VizzleSwitch.propTypes = {
  labelWhenSelected: PropTypes.string,
  labelWhenUnselected: PropTypes.string,
  checked: PropTypes.bool,
};

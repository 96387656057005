import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NotificationDialog from '../../../components/dialog/NotificationDialog';
import {
  MATCHING_ACTIVITY_DRAG_AND_DROP,
  MATCHING_ACTIVITY_ONE_CLICK,
  MATCHING_ACTIVITY_TWO_CLICK,
} from '../../../constants/SettingConstant';

const warningMatchingType = 'Matching Type cannot be changed because Switch Access is currently ON.';
const MatchingType = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.interactionType);
  const matchingType = profile.interactionType;
  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(matchingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id, profile.switchAccessibility]);

  const [warningShow, setWarningShow] = useState(false);
  const onCloseWarningShow = () => {
    setWarningShow(false);
  };

  const warningDialog = {
    open: warningShow,
    onClose: onCloseWarningShow,
    confirmLabel: 'OK',
    message: warningMatchingType,
  };

  const handleMatchingTypeOnchange = (field, newValue) => {
    if (newValue === null) return;
    if (profile.switchAccessibility) {
      setWarningShow(true);
      return;
    }
    setToggle(newValue);
    onChangeCallback(newValue);
  };

  return (
    <>
      <ToggleButtonGroup size='small' value={toggle} onChange={handleMatchingTypeOnchange} exclusive className='setting-interaction-toggle'>
        <ToggleButton value={MATCHING_ACTIVITY_DRAG_AND_DROP}>
          DRAG & DROP
        </ToggleButton>
        <ToggleButton value={MATCHING_ACTIVITY_ONE_CLICK}>
          1-CLICK (AUTO-PLAY)
        </ToggleButton>
        <ToggleButton value={MATCHING_ACTIVITY_TWO_CLICK}>
          2-CLICK
        </ToggleButton>
      </ToggleButtonGroup>
      <NotificationDialog
        open={warningDialog.open}
        onClose={warningDialog.onClose}
        title={warningDialog.title}
        confirmLabel={warningDialog.confirmLabel}
        message={warningDialog.message}
        Icon={HighlightOffIcon}
      />
    </>
  );
};

MatchingType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default MatchingType;

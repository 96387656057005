import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ScreenReader from '../../../../settings/ScreenReader';

const ScreenReaderSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('JAWS® or other Screen Reader');
  const { studentsDomain, userDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;
  const { userProfile } = userDomain.domainData;

  const onConfirm = () => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      screenReaderOn: true,
      switchAccessibility: 1,
      interactionType: 1,
      tts: { ...userProfile.tts, enabled: true },
    });
  };

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      screenReaderOn: newValue,
    });
  };

  return (
    <ScreenReader
      profile={selectedStudentProfile}
      onConfirmCallback={onConfirm}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default ScreenReaderSettings;

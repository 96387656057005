import PlayLessonAction from '../../../../lessons/lessonactions/PlayLessonAction';
import EditLessonAction from '../../../../lessons/lessonactions/EditLessonAction';

/**
 * Lesson card action
 */
// eslint-disable-next-line import/prefer-default-export
export const createLessonCardAction = (lesson, user, userProfile, terminologies) => {
  const actions = [(
    <PlayLessonAction
      lesson={lesson}
      className='top-left'
      key={`my-lessons-lesson-play-${lesson.id}`}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />
  )];

  if (lesson.attributes.systemId === 10000 && !lesson.attributes.libraryLessonFlag) {
    actions.push(
      <EditLessonAction
        lesson={lesson}
        key={`student-lesson-edit-${lesson.id}`}
        className='bottom-right'
      />,
    );
  }
  if (lesson.meta.archived) {
    return actions;
  }

  return actions;
};

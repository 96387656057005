import { useEffect, useState } from 'react';
import { flatten } from '../../../../utils/ArrayUtils';

/**
 * Filter lesson by keyword
 * @param {*} keyword keyword to filter
 * @param {*} lesson lessonto filter
 */
const filterByKeyword = (keyword, lesson) => {
  const searchInText = (key, text) => key && text && text.toLowerCase().indexOf(key.toLowerCase()) >= 0;

  const searchInArray = (key, array) => array.find((el) => searchInText(key, el));

  const keyString = keyword.split(' ');
  /**
    * If keywords for 'matching food', if those 5 fields have both these words,
    * then the lesson should be returned.
    * If it has only one but not the other, then it should not be returned
    *
    * E.g. Matching might be the activity type while food might be a part of the lesson name.
    * If that's the case, then this lesson should be returned. However,
    * if there's only food in the lesson name but Matching is not available in any of those other 5 fields,
    * then we should ignore this lesson
    */
  const matchedIndex = keyString.findIndex((keyStringSearch) => {
    const isNameMatch = searchInText(keyStringSearch, lesson.attributes.name);
    const isLessonIdMatch = searchInText(keyStringSearch, lesson.id.toString());
    const isGradeMatch = searchInText(keyStringSearch, lesson.attributes.gradeLevel);
    const isDescriptionMatch = searchInText(keyStringSearch, lesson.attributes.description);
    const isSubjectsMatch = searchInArray(keyStringSearch, lesson.attributes.subjects);
    return isNameMatch || isLessonIdMatch || isGradeMatch || isDescriptionMatch || isDescriptionMatch || isSubjectsMatch;
  });
  return matchedIndex >= 0;
};

export const useLessonCardFilter = ({
  lessonData,
  multiSelectFilterConfig,
  onFilterChange,
  noFilter,
  withArchiveFilter,
}) => {
  const { lessons, lessonFilterValue } = lessonData;
  const [filteredLessons, setFilteredLessons] = useState(lessons);
  const [hasLesson, setHasLesson] = useState(true);
  const [filterValue, setFilterValue] = useState(lessonFilterValue);

  const handleOnFilterChange = async (newFilterValue) => {
    await setFilterValue({ ...newFilterValue });
  };

  const performFilter = () => {
    const filterResult = lessons.map((subjectWithLessons) => {
      const { subject, doNotShowInArchive } = subjectWithLessons;
      const { archived, keyword } = filterValue;
      if (archived && doNotShowInArchive) {
        return {
          subject,
          lessons: [],
        };
      }
      const trimedKeyword = keyword ? keyword.trim() : '';
      const result = subjectWithLessons.lessons.filter((lesson) => {
        const archivedMatch = !withArchiveFilter || lesson.meta.archived === (archived || false);
        const keywordMatch = trimedKeyword && trimedKeyword.length > 2 ? filterByKeyword(trimedKeyword, lesson) : true;

        let multiSelected = true;
        if (multiSelectFilterConfig && multiSelectFilterConfig.length > 0) {
          const matchedResults = multiSelectFilterConfig
            .map((multiSelect) => {
              const valueToCompare = multiSelect.getValue(lesson);
              const selectedValues = filterValue[multiSelect.keyName];
              if (!selectedValues || selectedValues.length === 0) {
                // no selected values, assumes it matched
                return true;
              }

              const values = flatten(selectedValues.map((s) => s.value));
              if (Array.isArray(valueToCompare)) {
                return valueToCompare.some((v) => values.includes(v));
              } else {
                return values.includes(valueToCompare);
              }
            });
          multiSelected = matchedResults.reduce((total, value) => total && value);
        }

        return archivedMatch && keywordMatch && multiSelected;
      });
      return {
        subject,
        lessons: result,
      };
    });

    if (filterResult && filterResult.length > 0) {
      const flattenLessons = flatten(filterResult.map((subjectWithLessons) => subjectWithLessons.lessons));
      setHasLesson(flattenLessons.length > 0);
    } else {
      setHasLesson(false);
    }
    setFilteredLessons(filterResult);
    return () => {
      setHasLesson(true);
    };
  };

  useEffect(() => {
    if (!noFilter && JSON.stringify(lessonFilterValue) !== JSON.stringify(filterValue)) {
      performFilter();
      onFilterChange(filterValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  useEffect(() => {
    if (!noFilter) {
      performFilter();
    } else {
      if (!lessons || lessons.length === 0 || lessons.findIndex((l) => l.lessons && l.lessons.length > 0) < 0) {
        setHasLesson(false);
      } else {
        setHasLesson(true);
      }
      setFilteredLessons([...lessons]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons]);

  return {
    filteredLessons,
    hasLesson,
    filterValue,
    handleOnFilterChange,
  };
};

export default useLessonCardFilter;

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
} from '@mui/material';

import './ButtonWithSpinner.scss';

/**
 * Button component that its label can change to spinning icon when
 * it is loading
 *
 * Props:
 * label - Required : Label of the button when it is not displaying spinning icon
 * loading - Required: Whether the button is showing the spinning icon
 * className: class name
 * size: size of spinning icon
 */
const ButtonWithSpinner = forwardRef(({
  className,
  label,
  loading,
  size,
  variant,
  buttonClass,
  ...rest
}, ref) => {
  const { onClick, ...restNoOnClick } = rest;
  return (
    loading
      ? (
        <Button {...restNoOnClick} variant={variant} className={`btn-primary button-with-spinner ${className}`}>
          <CircularProgress size={size} className='btn-spinning' />
        </Button>
      ) : (
        <Button {...rest} ref={ref} variant={variant} className={`${buttonClass} button-with-spinner ${className}`}>
          {label}
        </Button>
      )
  );
});

export default ButtonWithSpinner;

ButtonWithSpinner.defaultProps = {
  className: '',
  size: 25,
  loading: false,
  variant: 'contained',
  buttonClass: 'btn-primary',
};

ButtonWithSpinner.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  buttonClass: PropTypes.string,
};

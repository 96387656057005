import PropTypes from 'prop-types';
import {
  Button, Tab, Tabs,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import './CopyLessonMultiplePagesAction.scss';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import {
  useCopyPageToAnotherLesson,
  useHandleDialog,
  useHandleLessonPagesSelection,
  useHandleLessonSelect,
  useHandleTab,
  usePrepareSearchLessonsData,
} from './CopyLessonMultiplePagesActionActionHooks';
import SearchLessonList from '../search-lesson-list/SearchLessonList';
import LessonPagesList from '../lesson-pages-list/LessonPagesList';
import LiveChatService from '../../../../../../services/LiveChatService';
import ObjectUtils from '../../../../../../utils/ObjectUtils';

const CopyLessonMultiplePagesAction = ({
  currentIndex,
}) => {
  const {
    tabValue,
    handleOnTabChange,
  } = useHandleTab();

  const {
    open,
    handleOnClose,
    openDialog,
  } = useHandleDialog({
    handleOnTabChange,
  });

  const {
    loading,
    loadSearchLessonsData,
  } = usePrepareSearchLessonsData({
    open,
  });

  const {
    selectedLessonId,
    handleOnLessonSelect,
  } = useHandleLessonSelect({
    open,
  });

  const {
    selectedLessonPageIndexes,
    handleOnSelectePage,
  } = useHandleLessonPagesSelection({
    currentIndex,
    open,
  });

  const {
    copyPageToAnotherLesson,
    saving,
  } = useCopyPageToAnotherLesson({
    selectedLessonPageIndexes,
    selectedLessonId,
    handleOnClose,
  });

  return (
    <>
      <Button
        variant='contained'
        startIcon={<FileCopyIcon />}
        className='btn-primary-rounded-border margin-right-medium'
        onClick={openDialog}
        data-test='copy-lesson-page'
      >
        Copy page
      </Button>
      {open && (
        <CustomDialog
          fullScreen
          className='copy-lesson-multiple-pages-action-dialog'
          openDialog={open}
          title='Search Lessons'
          onClose={handleOnClose}
          content={
            (
              <div className='container'>
                <Tabs
                  value={tabValue}
                  onChange={handleOnTabChange}
                  variant='scrollable'
                  scrollButtons='auto'
                  indicatorColor='primary'
                  textColor='primary'
                  aria-label='Teacher Course Tabs'
                >
                  <Tab
                    label='Select Lesson'
                    data-test='copy-lesson-multiple-pages-select-lesson'
                  />
                  <Tab
                    label='Select Pages'
                    data-test='copy-lesson-multiple-pages-select-pages'
                  />
                </Tabs>
                <div className={`tab-container ${tabValue !== 0 ? 'hide' : ''}`}>
                  <SearchLessonList
                    selectedLessonId={selectedLessonId}
                    onLessonSelect={handleOnLessonSelect}
                    loadSearchLessonsData={loadSearchLessonsData}
                    loading={loading}
                  />
                </div>
                <div className={`tab-container ${tabValue !== 1 ? 'hide' : ''}`}>
                  <LessonPagesList
                    selectedPageIndexes={selectedLessonPageIndexes}
                    onSelectePage={handleOnSelectePage}
                  />
                </div>
              </div>
            )
          }
          actions={[{
            onClick: handleOnClose,
            className: 'btn-danger margin-right action-button',
            title: 'Cancel',
            loading: saving || loading,
          }, {
            onClick: copyPageToAnotherLesson,
            className: 'btn-primary action-button',
            title: 'Copy',
            disabled: !selectedLessonId || !selectedLessonPageIndexes || selectedLessonPageIndexes.length === 0,
            loading: saving,
          },
          ]}
          onEntered={() => {
            LiveChatService.hideLiveChatIcon();
          }}
          onExisting={() => {
            ObjectUtils.setTimeout(() => {
              LiveChatService.hideLiveChatIcon();
            });
          }}
        />
      )}

    </>
  );
};

CopyLessonMultiplePagesAction.defaultProps = {
  currentIndex: 0,
};

CopyLessonMultiplePagesAction.propTypes = {
  currentIndex: PropTypes.number,
};

export default CopyLessonMultiplePagesAction;

import FireflyCelebration from './celebrations/FireflyCelebration';
import CandyExplodeCelebration from './celebrations/CandyExplodeCelebration';
import AirHockeyCelebration from './celebrations/AirHockeyCelebration';
import PinataCelebration from './celebrations/PinataCelebration';
import RocketCelebration from './celebrations/RocketCelebration';
import MagicWandCelebration from './celebrations/MagicWandCelebration';
import FireworksCelebration from './celebrations/FireworksCelebration';
import BubbleCelebration from './celebrations/BubbleCelebration';

export const celebrationsMap = {
  firefly: FireflyCelebration,
  candyExplode: CandyExplodeCelebration,
  airhockey: AirHockeyCelebration,
  pinata: PinataCelebration,
  rocket: RocketCelebration,
  magicwand: MagicWandCelebration,
  fireworks: FireworksCelebration,
  bubbles: BubbleCelebration,
};

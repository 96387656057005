import { Stack, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { getRandomMessage } from '../../../../constants/PlayPreferenceConstant';

export default function CorrectSubmissionMessage() {
  return (
    <Stack direction='row' gap={1}>
      <TaskAltIcon htmlColor='white' fill='white' />
      <Typography color='white' fontWeight={500}>{getRandomMessage()}</Typography>
    </Stack>
  );
}

import { getLessonPagesData } from '../../services/LessonService';
import { getActivityCardPreviewDataByActivityType } from '../../utils/activitytype/ActivityTypesUtil';

const usePrintLesson = () => {
  const getLessonDataPage = async (lesson, userProfile) => {
    const { uuid } = lesson.attributes;
    const { maxMatchingChoices } = userProfile;
    const result = await getLessonPagesData(uuid, maxMatchingChoices);
    const previewData = getActivityCardPreviewDataByActivityType(result);
    return previewData;
  };

  return {
    getLessonDataPage,
  };
};

export default usePrintLesson;

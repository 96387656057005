import { getExportTeahertData } from '../../../../../services/AdminService';
import ExportData from '../../../components/actions/export/ExportData';
import { useAdmin } from '../../../context/AdminContextProvider';

const ExportTeachersAction = () => {
  const { schoolsDomain } = useAdmin();
  const { selectedSchoolIds } = schoolsDomain.domainData;
  return (
    <ExportData
      getDataFunction={async () => getExportTeahertData(selectedSchoolIds)}
      ariaLabel='Export Teachers'
      buttonLabel='Export Teachers'
      disabled={!selectedSchoolIds || selectedSchoolIds.length === 0}
      exportFileName='teachers-data-report.xlsx'
      exportingMessage='Exporting Teachers Data'
    />
  );
};

export default ExportTeachersAction;

import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Divider,
  Typography,
} from '@mui/material';
import './SimpleTable.scss';

/**
 * Table component that can be exported to csv and pdf file.
 */
export default function SimpleTable({
  className,
  tableClassName,
  config,
  tableData,
  header,
  noTableHeader,
}) {
  return (
    <div className={`simple-table-component ${className}`}>
      {header ? (
        <div className='table-header' data-test='table-header'>
          <Typography variant='h6'>
            {header}
          </Typography>
          <Divider />
        </div>
      ) : null}
      <TableContainer component={Paper} className={`simple-table ${tableClassName}`} data-test='table-contaier'>
        <Table size='small'>
          {!noTableHeader && (
            <TableHead className='header'>
              <TableRow>
                {config.map((headCell) => (
                  <TableCell
                    key={`header-${headCell.dataField}`}
                    style={{ width: headCell.width }}
                    className='header-cell'
                  >
                    {headCell.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <TableRow key={`table-data-row-${rowIndex}`}>
                {
                  config.map((c, cellIndex) => (
                    <TableCell
                      key={`table-data-${c.dataField}-row-${rowIndex}-cell-${cellIndex}`}
                      align={c.align}
                      className='table-cell'
                      data-private
                      style={{ width: c.width }}
                    >
                      {c.dataRenderer ? c.dataRenderer(row) : row[c.dataField]}
                    </TableCell>
                  ))
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

SimpleTable.defaultProps = {
  className: '',
  tableClassName: '',
  tableData: [],
  config: [],
  header: null,
  noTableHeader: false,
};

SimpleTable.propTypes = {
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    dataRenderer: PropTypes.func,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  header: PropTypes.string,
  noTableHeader: PropTypes.bool,
};

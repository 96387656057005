import { useEffect, useState } from 'react';
import {
  CircularProgress,
} from '@mui/material';
import { getAppVersion } from '../../services/UserProfileService';
import Logger from '../../utils/Logger';
import './AppVersion.scss';

const AppVersion = () => {
  const [loading, setLoading] = useState(true);
  const [version, setVersion] = useState('');
  const [error, setError] = useState('');

  const getVersion = async () => {
    try {
      setLoading(true);
      const v = await getAppVersion();
      setVersion(v);
    } catch (e) {
      setError(e);
      Logger.logError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      getVersion();
    } catch (e) {
      Logger.logError(e);
    }
  }, []);

  if (loading) {
    return (
      <div className='app-version app-version-loading'>
        <CircularProgress size={25} className='spinner' />
        Loading app version
      </div>
    );
  }
  if (error) {
    return (
      <div className='app-version app-version-error'>
        An error occure while loading the app version
      </div>
    );
  }
  return (
    <div className='app-version'>
      Version {version}
    </div>
  );
};

export default AppVersion;

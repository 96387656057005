import BaseDomain from '../../../states/domains/BaseDomain';

const LESSON_PLAY_CHAT_UPDATE_CHAT_WIDGET = 'LESSON_PLAY_CHAT_UPDATE_CHAT_WIDGET';
const LESSON_PLAY_CHAT_UPDATE_FEEDBACK_CHAT = 'LESSON_PLAY_CHAT_UPDATE_FEEDBACK_CHAT';

const initialState = {
  showChatWidget: false,
  feedbackChat: [],
  studentId: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case LESSON_PLAY_CHAT_UPDATE_CHAT_WIDGET:
      return {
        ...state,
        feedbackChat: action.payload.feedbackChat,
        showChatWidget: action.payload.showChatWidget,
        studentId: action.payload.studentId,
      };
    case LESSON_PLAY_CHAT_UPDATE_FEEDBACK_CHAT:
      return {
        ...state,
        feedbackChat: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'lessonPlayChatDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateChatData({ showChatWidget, feedbackChat, studentId }) {
    return this.dispatch({
      type: LESSON_PLAY_CHAT_UPDATE_CHAT_WIDGET,
      payload: {
        showChatWidget,
        feedbackChat,
        studentId,
      },
    });
  }

  async updateFeedbackChat(feedbackChat) {
    return this.dispatch({
      type: LESSON_PLAY_CHAT_UPDATE_FEEDBACK_CHAT,
      payload: feedbackChat,
    });
  }
}

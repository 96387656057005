import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import QuestionKey from './QuestionKey';
import TooltipWithIndicator from '../../../../tooltip/TooltipWithIndicator';
import { matchingQuestionKeyTooltipText } from '../../../../../texts/TooltipText';
import './MatchingQuestionKey.scss';

export default function MatchingQuestionKey({
  questionKey,
  onKeyChange,
  className,
}) {
  return (
    <Box className={`matching-editor-question-key ${className}`} data-test='question-key-editor'>
      <QuestionKey value={questionKey} onKeyChange={onKeyChange} />
      <TooltipWithIndicator
        className='matching-editor-question-tooltip vizzle-question-key-tooltip'
        {...matchingQuestionKeyTooltipText}
      />
    </Box>
  );
}

MatchingQuestionKey.defaultProps = {
  questionKey: undefined,
  onKeyChange: () => null,
  className: '',
};

MatchingQuestionKey.propTypes = {
  questionKey: PropTypes.string,
  onKeyChange: PropTypes.func,
  className: PropTypes.string,
};

import { useRef } from 'react';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import StudentUsernamesPrint from '../../../../../components/vizzle/print/StudentUsernamesPrint';
import { useDomain } from '../../../../../states/AppDomainProvider';
import './PrintStudents.scss';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import { isMobile } from '../../../../../utils/DeviceUtils';

const PrintStudents = () => {
  const { studentsDomain, userDomain, uiDomain } = useDomain();
  const { students } = studentsDomain.domainData;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
  });

  if (!students || students.length === 0) {
    return null;
  }
  const { user } = userDomain.domainData;

  const handleOnClick = () => {
    uiDomain.showLoader((
      <div className='print-waiting-message' data-private>
        Preparing students for<br />
        <b>{user.userName}</b><br />
        for printing
      </div>
    ), LOADER_TYPE.HASH_LOADER);

    ObjectUtils.setTimeout(() => {
      uiDomain.hideLoader();
      handlePrint();
    }, 1500);
  };

  if (isMobile()) {
    return null;
  }

  return (
    <>
      <Button
        data-test='print-student-action'
        variant='contained'
        className='btn-primary print-usernames-button'
        startIcon={<PrintIcon />}
        onClick={handleOnClick}
      >
        PRINT ROSTER
      </Button>
      <div style={{ display: 'none' }}>
        <StudentUsernamesPrint ref={componentRef} students={students} currentUser={user} />
      </div>
    </>
  );
};

export default PrintStudents;

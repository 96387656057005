import { useEffect, useRef, useState } from 'react';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { getStudentPlayHistory, submitLessonEvent, submitLessonResponseEvent } from '../../../../services/LessonPlayService';
import {
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../../AppConstants';

const LESSON_START = 'LESSON_START';
const RESPONSE = 'RESPONSE';
const EXIT_LESSON = 'EXIT_LESSON';
const LESSON_END = 'LESSON_END';
const LESSON_PREVIEW = 'LESSON_PREVIEW';

const createRegisteredLesson = (lesson, userPlayingLesson, userLogin, initialLessonPlayId) => {
  const timeStamp = String(Math.floor(new Date().getTime() / 1000));

  const registeredLesson = {
    lessonId: lesson.lessonId.toString(),
    lessonPlayId: timeStamp,
    lessonPlaySequenceNum: 1,
  };

  if (userPlayingLesson.userType === 'Teacher') {
    registeredLesson.teacherId = userPlayingLesson.id;
  } else {
    registeredLesson.studentId = userPlayingLesson.id;
    if (userLogin.userType === 'Teacher') {
      registeredLesson.teacherId = userLogin.id;
    }
    registeredLesson.lessonPlayId = `${timeStamp}${registeredLesson.studentId}`;
  }

  registeredLesson.lessonPlayId = initialLessonPlayId || Number(registeredLesson.lessonPlayId);
  return registeredLesson;
};

const getTimeStamp = () => (new Date()).getTime();

const createLessonEvent = (registeredLesson) => {
  const lessonEvent = {};
  lessonEvent.lessonPlayId = registeredLesson.lessonPlayId;
  lessonEvent.studentId = registeredLesson.studentId;
  lessonEvent.lessonId = registeredLesson.lessonId;
  lessonEvent.teacherId = registeredLesson.teacherId;
  lessonEvent.lessonPlaySequenceNum = registeredLesson.lessonPlaySequenceNum;
  return lessonEvent;
};

const createResponseEvent = (registeredLesson, timeStamp = getTimeStamp()) => {
  const responseEvent = {};
  // eslint-disable-next-line no-param-reassign
  registeredLesson.questionIndex++;
  responseEvent.eventType = RESPONSE;
  responseEvent.lessonPlayId = registeredLesson.lessonPlayId;
  responseEvent.studentId = registeredLesson.studentId;
  responseEvent.lessonId = registeredLesson.lessonId;
  responseEvent.teacherId = registeredLesson.teacherId;
  responseEvent.questionIndex = registeredLesson.questionIndex;
  responseEvent.startTime = timeStamp;
  responseEvent.responses = [];
  return responseEvent;
};

const start = async (lessonEvent, setStudentHistoryId, initialPlayHistoryId) => {
  await submitLessonEvent(lessonEvent);
  let studentHistoryId = initialPlayHistoryId;
  if (!studentHistoryId) {
    studentHistoryId = await getStudentPlayHistory({ lessonPlayId: lessonEvent.lessonPlayId });
  }
  setStudentHistoryId(studentHistoryId);
};

export const useTrackingLessonStartAndEnd = ({
  onLessonEnd,
}) => {
  const registeredLessonRef = useRef(null);
  const isLessonCompleteRef = useRef(false);
  const [studentHistoryId, setStudentHistoryId] = useState(null);

  const { userDomain } = useDomain();
  const { user: userLogin } = userDomain.domainData;

  const { lessonPlayDomain } = useLessonPlay();
  const { user: userPlayingLesson,
    isPreviewMode,
    isPlayable,
    lesson,
    initialLessonPlayId,
    initialPlayHistoryId,
  } = lessonPlayDomain.domainData;

  // LESSON_START
  useEffect(() => {
    if (!userLogin.id || !isPlayable) {
      return;
    }
    registeredLessonRef.current = createRegisteredLesson(lesson, userPlayingLesson, userLogin, initialLessonPlayId);
    const lessonEvent = createLessonEvent(registeredLessonRef.current);
    lessonEvent.lessonComplete = false;
    if (isPreviewMode) {
      lessonEvent.eventType = LESSON_PREVIEW;
    } else {
      lessonEvent.eventType = LESSON_START;
      lessonEvent.startTime = getTimeStamp();
    }
    Logger.logWhenDebugModeIsOn({
      LESSON_START: {
        registeredLesson: registeredLessonRef.current,
        lessonEvent,
      },
    });
    registeredLessonRef.current.questionIndex = 0;
    registeredLessonRef.current.lessonPlaySequenceNum++;

    // submit event in background
    if (isPlayable && !initialPlayHistoryId) {
      start(lessonEvent, setStudentHistoryId, initialPlayHistoryId);
    } else {
      setStudentHistoryId(initialPlayHistoryId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLessonEnd = () => {
    if (!userLogin.id) {
      return;
    }
    // LESSON_END
    if (!isPreviewMode && isPlayable) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (isLessonCompleteRef.current) {
        // LESSON END
        const lessonEndEvent = createLessonEvent(registeredLessonRef.current);
        lessonEndEvent.eventType = LESSON_END;
        lessonEndEvent.endTime = getTimeStamp();
        lessonEndEvent.lessonComplete = true;

        Logger.logWhenDebugModeIsOn({
          LESSON_END: {
            lessonEndEvent,
          },
        });
        if (onLessonEnd) {
          onLessonEnd();
        }
        submitLessonEvent(lessonEndEvent);
      } else {
        // EXIT LESSON
        const responseEvent = createResponseEvent(registeredLessonRef.current);
        responseEvent.eventType = EXIT_LESSON;
        responseEvent.questionIndex = 0;
        delete responseEvent.startTime;
        const dataPayload = {
          celebrationCount: 0,
          correctCount: 0,
          endTime: getTimeStamp(),
          lessonPlaySequenceNum: 0,
          reinforcerCount: 0,
          startTime: getTimeStamp(),
        };
        responseEvent.responses.push(dataPayload);
        submitLessonResponseEvent(responseEvent);
      }
    }
  };

  return {
    isLessonCompleteRef,
    registeredLessonRef,
    studentHistoryId,
    handleLessonEnd,
  };
};

let trackedEndTime = null;

export const useTrackingLessonResponse = (registeredLessonRef) => {
  const { lessonPlayDomain } = useLessonPlay();
  const lessonPlayActivityRef = useRef([]);
  const { currentPageIndex, lessonPagesData, isPlayable } = lessonPlayDomain.domainData;

  const { userDomain } = useDomain();
  const { user: userLogin } = userDomain.domainData;
  const startResponseEvent = (isMultipleResponses, responseCards) => {
    if (!userLogin.id || !isPlayable) {
      return;
    }

    if (isMultipleResponses) {
      const timeStamp = getTimeStamp();
      const responseEvents = responseCards.map(() => createResponseEvent(registeredLessonRef.current), timeStamp);
      lessonPlayActivityRef.current[currentPageIndex] = responseEvents;
    } else {
      const responseEvent = createResponseEvent(registeredLessonRef.current);
      lessonPlayActivityRef.current[currentPageIndex] = responseEvent;
    }
  };

  useEffect(() => {
    const pageData = lessonPagesData[currentPageIndex];
    trackedEndTime = null;
    if ([
      ACTIVITY_TYPE_MATCHING_ACTIVITY,
      ACTIVITY_TYPE_SORTING_ACTIVITY,
      ACTIVITY_TYPE_GAME_ACTIVITY,
      ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
      ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
      ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
      ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
      ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
      ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
    ].includes(pageData.activityType)) {
      const responseEventForPage = lessonPlayActivityRef.current[currentPageIndex];
      if (!responseEventForPage) {
        const isMultipleResponses = [
          ACTIVITY_TYPE_SORTING_ACTIVITY,
          ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
          ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
          ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
        ].includes(pageData.activityType);

        const responseCards = [
          ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
        ].includes(pageData.activityType) ? pageData.causeAndEffectCards : pageData.responseCards;

        startResponseEvent(isMultipleResponses, responseCards);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  const responseObject = (responseEvent, index) => {
    if (Array.isArray(responseEvent)) {
      return responseEvent[index];
    }
    return responseEvent;
  };

  const getStarttime = (responseEvent, defaultStartTime) => {
    if (Array.isArray(responseEvent)) {
      return trackedEndTime || defaultStartTime;
    }
    return defaultStartTime;
  };

  const insertCorrectResponse = async (responseIndex, once, isFinished = false) => {
    if (!userLogin.id || !isPlayable) {
      return;
    }
    const responseEvent = responseObject(lessonPlayActivityRef.current[currentPageIndex], responseIndex);
    if (once && responseEvent.responses.length > 0) {
      return;
    }
    const startTime = getStarttime(lessonPlayActivityRef.current[currentPageIndex], responseEvent.startTime);
    const response = {};
    response.lessonPlaySequenceNum = registeredLessonRef.current.lessonPlaySequenceNum;
    response.correctCount = 1;
    response.celebrationCount = 0;
    response.reinforcerCount = 1;
    response.startTime = startTime;
    const currentTime = getTimeStamp();
    trackedEndTime = currentTime;
    response.endTime = currentTime;
    // eslint-disable-next-line no-param-reassign
    registeredLessonRef.current.lessonPlaySequenceNum++;
    responseEvent.lastPageCompleted = isFinished ? currentPageIndex + 1 : undefined;
    responseEvent.responses.push(response);
    await submitLessonResponseEvent(responseEvent);
    responseEvent.responses = [];
  };

  const insertIncorrectResponse = async (responseIndex, once, isFinished = false) => {
    if (!userLogin.id || !isPlayable) {
      return;
    }
    const responseEvent = responseObject(lessonPlayActivityRef.current[currentPageIndex], responseIndex);

    if (once && responseEvent.responses.length > 0) {
      return;
    }
    const startTime = getStarttime(lessonPlayActivityRef.current[currentPageIndex], responseEvent.startTime);
    const response = {};
    response.lessonPlaySequenceNum = registeredLessonRef.current.lessonPlaySequenceNum;
    response.correctCount = 0;
    response.celebrationCount = 0;
    response.reinforcerCount = 0;
    response.startTime = startTime;
    const currentTime = getTimeStamp();
    trackedEndTime = currentTime;
    response.endTime = currentTime;
    // eslint-disable-next-line no-param-reassign
    registeredLessonRef.current.lessonPlaySequenceNum++;
    responseEvent.lastPageCompleted = isFinished ? currentPageIndex + 1 : undefined;
    responseEvent.responses.push(response);

    if (once) {
      await submitLessonResponseEvent(responseEvent);
    }
  };
  return {
    startResponseEvent,
    insertCorrectResponse,
    insertIncorrectResponse,
  };
};

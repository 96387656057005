import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const COLORS = ['#1c177a', '#c8e5fc'];
const BACKGROUND_GRADIENT_INTENSITY = 20;

const FLOATING_ISLAND_SCALE = 0.8;
const GENDERS = ['a_female_', 'a_male_', 'b_female_', 'b_male_'];

const TreehouseTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    flags: [],
    spritesPlay: [],
    tweens: [],
    timeouts: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  const adjustFloatingIsland = () => {
    app.floatingIslandContainer.scale.x = FLOATING_ISLAND_SCALE;
    app.floatingIslandContainer.scale.y = 0.78;

    app.floatingIsland.x = app.canvas.view.width - app.floatingIsland.getBounds().width / 2;
    app.floatingIsland.y += (350);
    app.floatingIslandHighlight.x = app.floatingIsland.x;
    app.floatingIslandHighlight.y = app.floatingIsland.y;
  };

  const chooseRandomGender = () => {
    const genders = GENDERS.filter((g) => g !== app.gender);
    const random = Math.floor(Math.random() * genders.length) + 1;
    app.gender = genders[random - 1];
  };

  const swingFWD = () => {
    const rotation = 0.32;
    const swingerTween = new TWEEN.Tween(app.swinger)
      .to({ rotation: -rotation }, 1500)
      .easing(TWEEN.Easing.Linear.None)
      .onStart(() => {
        app.swinger.gotoAndStop(1);
      })
      .chain(new TWEEN.Tween(app.swinger)
        .to({ rotation }, 3000)
        .easing(TWEEN.Easing.Linear.None)
        .onStart(() => {
          app.swinger.gotoAndStop(0);
        })
        .chain(new TWEEN.Tween(app.swinger)
          .to({ rotation: 0 }, 1500)
          .easing(TWEEN.Easing.Linear.None)
          .onStart(() => {
            app.swinger.gotoAndStop(1);
          })
          .onComplete(() => {
            swingerTween.start();
          })));

    const swingTween = new TWEEN.Tween(app.swing)
      .to({ rotation: -rotation }, 1500)
      .easing(TWEEN.Easing.Linear.None)
      .chain(new TWEEN.Tween(app.swing)
        .to({ rotation }, 3000)
        .easing(TWEEN.Easing.Linear.None)
        .chain(new TWEEN.Tween(app.swing)
          .to({ rotation: 0 }, 1500)
          .easing(TWEEN.Easing.Linear.None)
          .onComplete(() => {
            swingTween.start();
          })));

    swingerTween.start();
    swingTween.start();
    app.tweens.push(swingerTween);
    app.tweens.push(swingTween);
  };

  const createBlackFireParticle = (x, y) => {
    const particle = new PIXI.AnimatedSprite(app.sheet.animations.blackfire);
    particle.x = x;
    particle.y = y;
    particle.anchor.set(0.5);
    app.floatingIslandContainer.addChild(particle);

    const blackFireParticleTween = new TWEEN.Tween(particle)
      .to({ alpha: 0.6, scaleX: 0.8, scaleY: 0.8 }, 100)
      .easing(TWEEN.Easing.Linear.None)
      .repeat(Infinity);

    const blackFireParticleScaleTween = new TWEEN.Tween(particle.scale)
      .to({ x: 0.8, y: 0.8 }, 100)
      .easing(TWEEN.Easing.Linear.None)
      .repeat(Infinity);

    app.tweens.push(blackFireParticleTween);
    app.tweens.push(blackFireParticleScaleTween);
    blackFireParticleTween.start();
    blackFireParticleScaleTween.start();
  };

  const addBlackfire = () => {
    createBlackFireParticle(380, 225);
    createBlackFireParticle(435, 125);
    createBlackFireParticle(495, 195);
    createBlackFireParticle(905, 255);
    createBlackFireParticle(1000, 165);
  };

  const addWerewolfDabs = () => {
    app.werewolf = new PIXI.AnimatedSprite(app.sheet.animations.werewolf);
    app.werewolfBounds = app.werewolf.getBounds();
    app.werewolf.x = 630; // move 630 from to 770
    app.werewolf.y = 525;
    app.werewolf.scale.x = 0.5;
    app.werewolf.scale.y = 0.5;
    app.werewolf.animationSpeed = 0.2;
    app.spritesPlay.push(app.werewolf);
    app.floatingIslandContainer.addChild(app.werewolf);
  };

  const addSprites = () => {
    // add in this order island and highlight plus progress bar(already done in lesson-selection.directive.js),
    // treehouse, swing, tireswing, various kids animations

    app.swing = new PIXI.AnimatedSprite(app.sheet.animations.swing);
    app.swingBounds = app.swing.getBounds();
    app.swing.anchor.set(0.5, 0);
    app.swing.x = app.floatingIsland.x + 440;
    app.swing.y = 330;
    app.swing.scale.y = 0.8;
    app.floatingIslandContainer.addChild(app.swing);

    app.faller = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}faller`]);
    app.faller.x = 550;
    app.faller.y = 540;
    app.faller.animationSpeed = 0.1;
    app.faller.alpha = 0;
    app.floatingIslandContainer.addChild(app.faller);

    app.bear = new PIXI.AnimatedSprite(app.sheet.animations.bear);
    app.bearBounds = app.bear.getBounds();
    app.bear.x = 628; // move 630 from to 750
    app.bear.y = 520;
    app.bear.animationSpeed = 0.07;
    app.spritesPlay.push(app.bear);
    app.floatingIslandContainer.addChild(app.bear);

    app.treehouse = new PIXI.AnimatedSprite(app.sheet.animations.treehouse);
    app.treehouse.scale.x = 0.97;
    app.treehouse.scale.y = 0.94;
    app.treehouseBounds = app.treehouse.getBounds();
    app.treehouse.x = app.floatingIsland.x - app.floatingIsland.getBounds().width / 2 - 85;
    app.treehouse.y = (app.floatingIsland.y - app.treehouseBounds.height - app.floatingIsland.getBounds().height / 2 + 30);

    addBlackfire();
    if (app.sheet.animations.werewolf) {
      addWerewolfDabs();
    }

    app.floatingIslandContainer.addChild(app.treehouse);

    if (innerLessonsCompleted === 0) {
      app.egg = new PIXI.AnimatedSprite(app.sheet.animations.egg);
      app.egg.anchor.set(0.5);
      app.egg.x = app.treehouse.x + app.treehouseBounds.width / 2 - 90;
      app.egg.y = 190;
      app.egg.animationSpeed = 0.06;
    } else {
      app.egg = new PIXI.AnimatedSprite(app.sheet.animations.egg);
      app.eggBounds = app.egg.getBounds();
      app.egg.anchor.set(0.5);
      app.egg.x = app.treehouse.x + app.treehouseBounds.width / 2 - 90;
      app.egg.y = 190;
      app.egg.animationSpeed = 0.07;
      app.egg.loop = false;

      app.tweet = new PIXI.AnimatedSprite(app.sheet.animations.tweet);
      app.tweet.anchor.set(0.5);
      app.tweet.x = app.egg.x;
      app.tweet.y = app.egg.y;
      app.tweet.animationSpeed = 0.18;

      app.egg.onComplete = () => {
        app.tweet.play();
        app.spritesPlay.push(app.tweet);
        app.floatingIslandContainer.removeChild(app.egg);
        app.floatingIslandContainer.addChildAt(app.tweet, app.floatingIslandContainer.getChildIndex(app.treehouse) + 1);
      };
    }

    app.egg.play();
    app.spritesPlay.push(app.egg);
    app.floatingIslandContainer.addChild(app.egg);

    if (innerLessonsCompleted >= 1) {
      app.owl = new PIXI.AnimatedSprite(app.sheet.animations.owl);
      app.owlBounds = app.owl.getBounds();
      app.owl.x = app.treehouse.x + app.treehouseBounds.width / 2 - 155;
      app.owl.y = 265;
      app.owl.animationSpeed = 0.25;
      app.spritesPlay.push(app.owl);
      app.floatingIslandContainer.addChild(app.owl);
    }

    app.swinger = new PIXI.AnimatedSprite(app.sheet.animations.swinger);
    app.swingerBounds = app.swinger.getBounds();
    app.swinger.anchor.set(0.5, -1.8);
    app.swinger.x = app.swing.x + 5;
    app.swinger.y = app.swing.y;
    app.swinger.animationSpeed = 0.06;
    app.floatingIslandContainer.addChild(app.swinger);
    swingFWD();

    app.waver = new PIXI.AnimatedSprite(app.sheet.animations.waver);
    app.waverBounds = app.waver.getBounds();
    app.waver.x = 520;
    app.waver.y = 305;
    app.waver.animationSpeed = 0.07;
    app.spritesPlay.push(app.waver);
    app.waver.play();
    app.floatingIslandContainer.addChild(app.waver);

    app.sleeper = new PIXI.AnimatedSprite(app.sheet.animations.sleeper);
    app.sleeperBounds = app.sleeper.getBounds();
    app.sleeper.x = 505;
    app.sleeper.y = -10;
    app.sleeper.animationSpeed = 0.04;
    app.spritesPlay.push(app.sleeper);
    app.sleeper.play();
    app.floatingIslandContainer.addChild(app.sleeper);

    app.chewing = new PIXI.AnimatedSprite(app.sheet.animations.chewing);
    app.chewingBounds = app.chewing.getBounds();
    app.chewing.x = 895;
    app.chewing.y = 20;
    app.chewing.animationSpeed = 0.08;
    app.spritesPlay.push(app.chewing);
    app.chewing.play();
    app.floatingIslandContainer.addChild(app.chewing);

    app.gum = new PIXI.AnimatedSprite(app.sheet.animations.gum);
    app.gumBounds = app.gum.getBounds();
    app.gum.x = app.chewing.x;
    app.gum.y = app.chewing.y;
    app.gum.alpha = 0;
    app.floatingIslandContainer.addChild(app.gum);

    app.bubblegum = new PIXI.AnimatedSprite(app.sheet.animations.bubble);
    app.bubblegumBounds = app.bubblegum.getBounds();
    app.bubblegum.x = app.chewing.x + 26;
    app.bubblegum.y = app.chewing.y + 42;
    app.bubblegum.anchor.set(1);
    app.bubblegum.alpha = 0;
    app.floatingIslandContainer.addChild(app.bubblegum);

    app.sitter = new PIXI.AnimatedSprite(app.sheet.animations.sitter);
    app.sitterBounds = app.sitter.getBounds();
    app.sitter.x = app.chewing.x + 50;
    app.sitter.y = app.chewing.y + 35;
    app.sitter.animationSpeed = 0.05;
    app.spritesPlay.push(app.sitter);
    app.sitter.play();
    app.floatingIslandContainer.addChild(app.sitter);
  };

  const addhalloweenAnimations = () => {
    chooseRandomGender();
    if (app.sheet.animations[`${app.gender}monster_runner`]) {
      const monsterKid = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}monster_runner`]);
      const monsterKidBounds = monsterKid.getBounds();
      monsterKid.anchor.set(0.5);
      const monsterKidX = 890;
      const monsterKidY = app.floatingIsland.y - app.floatingIsland.getBounds().height / 2 - monsterKidBounds.height + 40;
      monsterKid.x = monsterKidX;
      monsterKid.y = monsterKidY;
      monsterKid.animationSpeed = 0.115;
      monsterKid.play();
      app.spritesPlay.push(monsterKid);
      app.floatingIslandContainer.addChild(monsterKid);

      const monsterKidTween = new TWEEN.Tween(monsterKid)
        .to({ x: monsterKidX + 120 }, 2800)
        .easing(TWEEN.Easing.Linear.None)
        .onStart(() => {
          monsterKid.scale.x = -1;
        })
        .chain(new TWEEN.Tween(monsterKid)
          .to({ x: monsterKidX }, 2800)
          .easing(TWEEN.Easing.Linear.None)
          .onStart(() => {
            monsterKid.scale.x = 1;
          })
          .onComplete(() => {
            monsterKidTween.start();
          }));

      app.tweens.push(monsterKidTween);
      monsterKidTween.start();
    }

    chooseRandomGender();
    if (app.sheet.animations[`${app.gender}costume_kid`]) {
      const costumeKid = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}costume_kid`]);
      const costumeKidBounds = costumeKid.getBounds();
      costumeKid.anchor.set(0.5);

      const costumeKidX = 935;
      const costumeKidY = app.floatingIsland.y - app.floatingIsland.getBounds().height / 2 - costumeKidBounds.height - 100;

      const costumeKidToX = 315;
      const costumeKidToY = 170;

      costumeKid.x = costumeKidX;
      costumeKid.y = costumeKidY;
      app.floatingIslandContainer.addChild(costumeKid);

      const createUpDownTween = (y, chainTween, onComplete) => {
        const lastChain = new TWEEN.Tween(costumeKid)
          .to({ y }, 400);
        if (chainTween) {
          lastChain.chain(chainTween);
        }
        if (onComplete) {
          lastChain.onComplete(onComplete);
        }
        const customeKidUpDownTween = new TWEEN.Tween(costumeKid)
          .to({ y: y + 30 }, 400)
          .easing(TWEEN.Easing.Linear.None)
          .chain(new TWEEN.Tween(costumeKid)
            .to({ y }, 400)
            .chain(new TWEEN.Tween(costumeKid)
              .to({ y: y + 30 }, 400)
              .chain(new TWEEN.Tween(costumeKid)
                .to({ y }, 400)
                .chain(new TWEEN.Tween(costumeKid)
                  .to({ y: y + 30 }, 400)
                  .chain(lastChain)))));
        return customeKidUpDownTween;
      };

      const costumeKidTween = new TWEEN.Tween(costumeKid)
        .to({ x: costumeKidToX, y: costumeKidToY, rotation: -0.3 }, 4000)
        .easing(TWEEN.Easing.Linear.None)
        .onStart(() => {
          costumeKid.scale.x = 1;
        })
        .chain(new TWEEN.Tween(costumeKid)
          .to({ rotation: 0 }, 200)
          .chain(
            createUpDownTween(
              costumeKidToY,
              new TWEEN.Tween(costumeKid)
                .to({ x: costumeKidX, y: costumeKidY, rotation: 0.3 }, 4000)
                .easing(TWEEN.Easing.Linear.None)
                .onStart(() => {
                  costumeKid.scale.x = -1;
                })
                .chain(new TWEEN.Tween(costumeKid)
                  .to({ rotation: 0 }, 200)
                  .chain(createUpDownTween(costumeKidY, null, () => { costumeKidTween.start(); }))),
            ),
          ));

      costumeKidTween.start();
      app.tweens.push(costumeKidTween);
    }

    if (innerLessonsCompleted >= 2 && app.werewolf) {
      const werewolfX = app.werewolf.x;
      const werewolfTween = new TWEEN.Tween(app.werewolf)
        .delay(1500)
        .to({ x: werewolfX + 150 }, 2000)
        .easing(TWEEN.Easing.Linear.None)
        .onStart(() => {
          app.werewolf.gotoAndPlay(0);
        })
        .onComplete(() => {
          app.werewolf.gotoAndStop(0);
        })
        .chain(new TWEEN.Tween(app.werewolf)
          .delay(1000)
          .to({ x: werewolfX }, 1000)
          .onComplete(() => {
            werewolfTween.start();
          }));
      app.tweens.push(werewolfTween);
      werewolfTween.start();
    }
  };

  const addRunningKidAnimations = () => {
    const climber = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}climber`]);
    climber.anchor.set(0.5);
    climber.animationSpeed = 0.1;
    climber.alpha = 0;
    app.floatingIslandContainer.addChild(climber);

    const runner = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}runner`]);
    runner.anchor.set(0.5);
    runner.animationSpeed = 0.1;
    runner.alpha = 0;
    app.floatingIslandContainer.addChild(runner);
    app.spritesPlay.push(runner);

    const runnerIndex = app.floatingIslandContainer.getChildIndex(runner);

    const fallerX = app.faller.x;
    const fallerY = app.faller.y;

    const jumperX = app.faller.x + 10;
    const jumperY = app.faller.y + 100;

    const jumpToX = jumperX - 150;
    const jumpToY = jumperY;

    const runLeftToX = jumpToX - 80;
    const runLeftToY = jumpToY + 5;

    const runRightToX1 = runLeftToX + 170;
    const runRightToY1 = runLeftToY + 45;

    const runToStairX = runRightToX1 + 253;
    const runToStairY = runRightToY1 - 35;

    const climbToX1 = runToStairX + 28;
    const climbToY1 = runToStairY - 230;

    const runRightToX2 = climbToX1 + 140;
    const runRightToY2 = climbToY1;

    const runLeftToX2 = runRightToX2 - 90;
    const runLeftToY2 = runRightToY2;

    const climbToX2 = runLeftToX2;
    const climbToY2 = runLeftToY2 - 130;

    const runLeftToX3 = climbToX2 - 80;
    const runLeftToY3 = climbToY2;

    const jumpToX2 = app.waver.x + 80;
    const jumpToY2 = app.waver.y + 50;

    const runnerAfterJumpToX = jumpToX2 - 10;
    const runnerAfterJumpToY = jumpToY2 - 20;

    const jumper = new PIXI.AnimatedSprite(app.sheet.animations[`${app.gender}jumper`]);
    jumper.anchor.set(0.5);
    jumper.x = jumperX;
    jumper.y = jumperY;
    jumper.alpha = 0;
    app.floatingIslandContainer.addChildAt(jumper, app.floatingIslandContainer.getChildIndex(app.faller));

    const jumoutTime = 300;

    const jumpOutTween = new TWEEN.Tween(jumper)
      .delay(2000)
      .to({ x: jumperX - 80, y: jumperY - jumper.getBounds().height - 10 }, jumoutTime)
      .easing(TWEEN.Easing.Linear.None)
      .onStart(() => {
        chooseRandomGender();
        app.faller.textures = app.sheet.animations[`${app.gender}faller`];
        app.faller.x = fallerX;
        app.faller.y = fallerY;
        app.faller.alpha = 0;

        jumper.textures = app.sheet.animations[`${app.gender}jumper`];
        jumper.x = jumperX;
        jumper.y = jumperY;
        jumper.alpha = 1;
        jumper.scale.x = 1;

        climber.textures = app.sheet.animations[`${app.gender}climber`];
        climber.alpha = 0;
        climber.scale.x = 1;
        climber.x = runToStairX;
        climber.y = runToStairY;

        runner.textures = app.sheet.animations[`${app.gender}runner`];
        runner.alpha = 0;
        runner.scale.x = 1;
        runner.x = jumpToX;
        runner.y = jumpToY;
        app.floatingIslandContainer.setChildIndex(runner, runnerIndex);
        app.floatingIslandContainer.setChildIndex(jumper, app.floatingIslandContainer.getChildIndex(app.faller));
      })
      .onComplete(() => {
        app.floatingIslandContainer.setChildIndex(jumper, app.floatingIslandContainer.children.length - 1);
      })
      .chain(new TWEEN.Tween(jumper)
        .to({ x: jumpToX, y: jumpToY }, 200)
        .chain(new TWEEN.Tween(runner)
          .onStart(() => {
            jumper.alpha = 0;
            runner.alpha = 1;
            runner.gotoAndPlay(0);
          })
          .to({ x: runLeftToX, y: runLeftToY }, 500)
          .chain(new TWEEN.Tween(runner)
            .onStart(() => { runner.scale.x = -1; })
            .to({ x: runRightToX1, y: runRightToY1 }, 1200)
            .chain(new TWEEN.Tween(runner)
              .to({ x: runToStairX, y: runToStairY }, 1300)
              .chain(new TWEEN.Tween(climber)
                .to({ x: climbToX1, y: climbToY1 }, 1000)
                .onStart(() => {
                  climber.alpha = 1;
                  climber.gotoAndPlay(0);
                  runner.alpha = 0;
                  runner.gotoAndStop(0);
                })
                .onComplete(() => {
                  climber.alpha = 0;
                  climber.gotoAndStop(0);
                  runner.alpha = 1;
                  runner.gotoAndPlay(0);
                  runner.x = climbToX1;
                  runner.y = climbToY1;
                })
                .chain(new TWEEN.Tween(runner)
                  .to({ x: runRightToX2, y: runRightToY2 }, 1000)
                  .chain(new TWEEN.Tween(runner)
                    .onStart(() => {
                      runner.scale.x = 1;
                    })
                    .to({ x: runLeftToX2, y: runLeftToY2 }, 500)
                    .onComplete(() => {
                      climber.alpha = 1;
                      climber.gotoAndPlay(0);
                      climber.x = runLeftToX2;
                      climber.y = runLeftToY2;
                      climber.scale.x = -1;
                      runner.alpha = 0;
                      runner.gotoAndStop(0);
                    })
                    .chain(new TWEEN.Tween(climber)
                      .to({ x: climbToX2, y: climbToY2 }, 700)
                      .onComplete(() => {
                        runner.alpha = 1;
                        runner.gotoAndPlay(0);
                        runner.x = climbToX2;
                        runner.y = climbToY2;
                        runner.scale.x = 1;
                        climber.alpha = 0;
                        climber.gotoAndStop(0);
                      })
                      .chain(new TWEEN.Tween(runner)
                        .to({ x: runLeftToX3, y: runLeftToY3 }, 700)
                        .onComplete(() => {
                          runner.alpha = 0;
                          runner.gotoAndStop(0);
                          jumper.alpha = 1;
                          app.floatingIslandContainer.setChildIndex(jumper, app.floatingIslandContainer.getChildIndex(app.waver));
                          jumper.x = runLeftToX3 - 5;
                          jumper.y = runLeftToY3 - 5;
                        })
                        .chain(new TWEEN.Tween(jumper)
                          .to({ x: runLeftToX3 - 50, y: runLeftToY3 - 40 }, 300)
                          .onStart(() => {
                            if (app.owl) {
                              app.owl.gotoAndPlay(0);
                            }
                          })
                          .chain(new TWEEN.Tween(jumper)
                            .to({ x: jumpToX2, y: jumpToY2 }, 400)
                            .onComplete(() => {
                              if (app.owl) {
                                app.owl.gotoAndStop(0);
                              }
                              jumper.alpha = 0;
                              jumper.gotoAndStop(0);
                              runner.alpha = 1;
                              runner.x = runnerAfterJumpToX;
                              runner.y = runnerAfterJumpToY;
                              app.floatingIslandContainer.setChildIndex(runner, app.floatingIslandContainer.getChildIndex(app.waver) - 1);
                              runner.gotoAndPlay(0);
                            })
                            .chain(new TWEEN.Tween(runner)
                              .to({ x: runnerAfterJumpToX - 65 }, 500)
                              .chain(new TWEEN.Tween(runner)
                                .to({ x: runnerAfterJumpToX - 15 }, 500)
                                .onStart(() => {
                                  runner.scale.x = -1;
                                })
                                .onComplete(() => {
                                  runner.alpha = 0;
                                  runner.gotoAndStop(0);
                                  jumper.alpha = 1;
                                  jumper.x = runnerAfterJumpToX - 15;
                                  jumper.y = runnerAfterJumpToY;
                                  jumper.scale.x = -1;
                                })
                                .chain(new TWEEN.Tween(jumper)
                                  .to({ x: runnerAfterJumpToX + 15, y: runnerAfterJumpToY - 5 }, 200)
                                  .onComplete(() => {
                                    jumper.alpha = 0;
                                    app.faller.x = runnerAfterJumpToX - 30;
                                    app.faller.y = runnerAfterJumpToY - 60;
                                    app.faller.alpha = 1;
                                  })
                                  .chain(new TWEEN.Tween(app.faller)
                                    .to({ x: fallerX, y: fallerY }, 600)
                                    .onComplete(() => {
                                      jumpOutTween.start();
                                    }))))))))))))))));

    jumpOutTween.start();
    app.tweens.push(jumpOutTween);
  };

  const blowBubble = () => {
    app.bubblegum.scale.x = 0.01;
    app.bubblegum.scale.y = 0.01;
    const blowBubleTween = new TWEEN.Tween(app.bubblegum.scale)
      .delay(10000)
      .to({ x: 1, y: 1 }, 1000)
      .easing(TWEEN.Easing.Linear.None)
      .onStart(() => {
        app.chewing.alpha = 0;
        app.bubblegum.alpha = 1;
        app.gum.alpha = 1;
      })
      .chain(new TWEEN.Tween(app.bubblegum.scale)
        .to({ x: 0, y: 0 }, 1)
        .onComplete(() => {
          app.bubblegum.alpha = 0;
          app.gum.alpha = 0;
          app.chewing.alpha = 1;
          app.bubblegum.scale.x = 0.01;
          app.bubblegum.scale.y = 0.01;
          blowBubleTween.start();
        }));

    blowBubleTween.start();
    app.tweens.push(blowBubleTween);
  };

  const beary = () => {
    if (innerLessonsCompleted >= 2) {
      const bearX = app.bear.x;
      const bearTween = new TWEEN.Tween(app.bear)
        .delay(4000)
        .to({ x: bearX + 140 }, 2000)
        .easing(TWEEN.Easing.Linear.None)
        .onComplete(() => {
          app.bear.gotoAndPlay(0);
        })
        .chain(new TWEEN.Tween(app.bear)
          .to({ x: bearX }, 2000)
          .delay(1500)
          .onComplete(() => {
            app.bear.gotoAndStop(0);
            bearTween.start();
          }));
      bearTween.start();
      app.tweens.push(bearTween);
    }
  };

  const weatherBegin = () => {
    const snowflake = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    const container = new PIXI.Container();
    container.addChild(snowflake);
    container.x = Math.random() * (app.canvas.view.width / FLOATING_ISLAND_SCALE);

    const duration = (Math.random() * 1000) + 1500;

    const snowFlakeTween = new TWEEN.Tween(snowflake)
      .delay(15)
      .to({ x: 15, alpha: 0.8 }, duration)
      .easing(TWEEN.Easing.Linear.None)
      .chain(new TWEEN.Tween(snowflake)
        .delay(15)
        .to({ x: 0, alpha: 1 }, duration)
        .onComplete(() => {
          snowFlakeTween.start();
        }));
    snowFlakeTween.start();
    app.tweens.push(snowFlakeTween);

    const snowFlakeYTween = new TWEEN.Tween(snowflake)
      .to({ y: app.canvas.view.height + 500 }, 10000)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        app.floatingIslandContainer.removeChild(container);
      });

    snowFlakeYTween.start();
    app.tweens.push(snowFlakeYTween);
    app.floatingIslandContainer.addChild(container);
  };

  const addSnowKids = () => {
    const femalethrower = new PIXI.AnimatedSprite(app.sheet.animations.a_female_thrower);
    const malethrower = new PIXI.AnimatedSprite(app.sheet.animations.a_male_thrower);
    femalethrower.x = 320;
    femalethrower.y = 700;
    malethrower.x = femalethrower.x + 270;
    malethrower.y = femalethrower.y;

    femalethrower.animationSpeed = 0.2;
    malethrower.animationSpeed = 0.2;
    femalethrower.play();
    malethrower.play();
    app.floatingIslandContainer.addChild(malethrower);
    app.floatingIslandContainer.addChild(femalethrower);
  };

  const showRandomScene = () => {
    adjustFloatingIsland();
    chooseRandomGender();
    addSprites();
    addhalloweenAnimations();
    addRunningKidAnimations();
    blowBubble();
    beary();

    // Winter theme
    addSnowKids();
    app.interval = setInterval(weatherBegin, 30);
  };

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas } = ThemeUtil.initCanvasWithBackground(
        canvas,
        COLORS,
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick);
      const progressBar = {
        barBackground: 'progressbar_background_treehouse',
        progressBar: 'progressbar_treehouse',
        progressAvatar: 'treehouse_avatar',
        dotType: 'circle',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );
      ThemeUtil.addFloatableObjects(app, 4000, false);
      showRandomScene();
    }

    return () => {
      ThemeUtil.stopFloatableObjects(app);
      clearInterval(app.interval);
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

TreehouseTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

TreehouseTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default TreehouseTheme;

import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import Logger from '../../../../../utils/Logger';
import CauseAndEffectThumbnail from '../../../../../components/vizzle/page/causeandeffect/CauseAndEffectThumbnail';
import { getInitialDataForCauseAndEffect } from '../../../../../utils/activitytype/ActivityTypeInitialData';

export default function CauseAndEffectLayout() {
  const initialData = getInitialDataForCauseAndEffect();

  const props = {
    config: [
      {
        component: (<CauseAndEffectThumbnail causeCards={initialData.causeCards} effectCards={initialData.effectCards} />),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '55vw' : '50vw',
    height: '52vh',
  };

  return (
    <Box display='flex' mt='20px'>
      <br />
      <CardSelector
        {...props}
        selectedIndex={0}
        className='vizzle-matching-layout'
      />
    </Box>
  );
}

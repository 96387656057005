import { useRef } from 'react';
import { getDistrictTeachers, getActivityReports } from '../../../../services/AdminService';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../context/AdminContextProvider';

export const useGetTeacherList = () => {
  const { adminDataDomain } = useAdmin();
  const { isTeacherListLoading } = adminDataDomain.domainData;
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getTeacherListData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        adminDataDomain.updateTeacherListLoading(true);
        const { searchCriteria, selectedDates } = adminDataDomain.domainData;
        const teacherListData = await getDistrictTeachers(searchCriteria, selectedDates);
        adminDataDomain.updateTeacherList(teacherListData, false);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      adminDataDomain.updateTeacherList([], false);
    }
  };

  return {
    loading: isTeacherListLoading,
    getTeacherListData,
  };
};

export const useGetActivityReports = () => {
  const { adminDataDomain } = useAdmin();
  const { isActivityReportLoading } = adminDataDomain.domainData;
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getActivityReportsData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        adminDataDomain.updateActivityReportsLoading(true);
        const { searchCriteria, selectedDates, selectedTeacherId } = adminDataDomain.domainData;
        const activityReportsData = await getActivityReports(searchCriteria, selectedDates, selectedTeacherId);
        adminDataDomain.updateActivityReports(activityReportsData, false);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      adminDataDomain.updateActivityReports({}, false);
    }
  };

  return {
    loading: isActivityReportLoading,
    getActivityReportsData,
  };
};

export const useGetTeacherLabel = () => {
  const cacheTeacherName = useRef(null);
  const { adminDataDomain } = useAdmin();
  const { searchCriteria, selectedTeacherId } = adminDataDomain.domainData;

  const getTeacherLabel = () => {
    let label = 'All Teachers';
    if (searchCriteria.teachersLoggingIn && !searchCriteria.teachersWithoutLogins) {
      label = 'Teachers Logging in';
    }

    if (!searchCriteria.teachersLoggingIn && searchCriteria.teachersWithoutLogins) {
      label = 'Teachers Without Logins';
    }
    return label;
  };

  const getTeacherLabelOrName = () => {
    if (selectedTeacherId !== null) {
      const { teacherList } = adminDataDomain.domainData;
      const teacher = teacherList.find((t) => t.id === selectedTeacherId);
      if (!teacher) {
        return cacheTeacherName.current;
      }

      cacheTeacherName.current = `${teacher.attributes.firstName} ${teacher.attributes.lastName}`;
      return cacheTeacherName.current;
    }

    return getTeacherLabel();
  };

  const getTeacher = () => {
    if (selectedTeacherId !== null) {
      const { teacherList } = adminDataDomain.domainData;
      const teacher = teacherList.find((t) => t.id === selectedTeacherId);
      return teacher;
    }
    return null;
  };

  return {
    getTeacher,
    getTeacherLabel,
    getTeacherLabelOrName,
  };
};

export const useGetLastLoggedIn = () => {
  const { adminDataDomain } = useAdmin();
  const { activityReports: { attributes } } = adminDataDomain.domainData;
  if (!attributes) {
    return '';
  }

  const { daysSinceLastLogon } = attributes;
  let lastLoggedInMessage = '';
  if (daysSinceLastLogon !== undefined) {
    if (daysSinceLastLogon === 0) {
      lastLoggedInMessage = '(Last logged in Today)';
    } else if (daysSinceLastLogon > 0) {
      lastLoggedInMessage = `(Last logged in ${daysSinceLastLogon} days ago)`;
    }
  }

  return lastLoggedInMessage;
};

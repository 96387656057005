import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import CustomDialog from '../../components/dialog/CustomDialog';
import Theme from '../terminologies/theme/Theme';
import StudentLandingTopAppbar from '../appbar/StudentLandingTopAppbar';
import StudentLessonSummary from '../landing/landing-student/student-lessons-summary/StudentLessonSummary';
import { usePrepareStudentLessonsSummary } from '../landing/landing-student/student-lessons-summary/StudentLessonSummaryHooks';
import { useGetLessonSelectionData, useHandleTheme, useCheckLessonComplete, useGetElementsForSwitchAccess, usePrepareUserProfile } from './ThemeLessonSelectionHooks';
import { initSwitchAccessDomains } from '../switch-access/context/SwitchAccessContextProvider';
import { useRegisterSwitchKeys } from '../switch-access/SwitchAccessHooks';
import './ThemeLessonSelection.scss';
import { initDomains as initLessonPlayDomain } from '../lessonplay/context/LessonPlayContextProvider';
import { useDomain } from '../../states/AppDomainProvider';

const ThemeLessonSelection = () => {
  initLessonPlayDomain();
  initSwitchAccessDomains();

  usePrepareUserProfile();
  const {
    showCompleteMessage,
    handleCompleteMessageDialogClose,
  } = useCheckLessonComplete();

  const {
    lessonsSummaryLoading,
    studentLessonsSummary,
  } = usePrepareStudentLessonsSummary();

  const {
    themeData,
    subjectId,
    lessons,
    startIndex,
    subjectNameId,
  } = useGetLessonSelectionData();

  const {
    themeTransition,
    onThemeClick,
    gobackFunction,
  } = useHandleTheme(lessons[startIndex]);

  const { userDomain } = useDomain();
  const { userProfile, user } = userDomain.domainData;
  const { switchAccessibility } = userProfile;
  useRegisterSwitchKeys(switchAccessibility);

  const {
    topAppbarRef,
    getElementsForSwitchAccess,
    lessonCompleteButtonRef,
  } = useGetElementsForSwitchAccess({
    showCompleteMessage,
    onThemeClick,
  });

  const startIndexNumber = Number(startIndex);

  return (
    <>
      <VizzleMainContainer className='main-with-blue-background'>
        <div className='lesson-selection'>
          {themeTransition.in && (
            <StudentLandingTopAppbar
              className='transparent-background'
              exitLabel='Exit'
              exitFunction={gobackFunction}
              ref={topAppbarRef}
              hideToolbar={user.hideToolbar}
            />
          )}

          {!lessonsSummaryLoading && studentLessonsSummary && (
            <div className='student-lessons-summary-banner'>
              <StudentLessonSummary data={studentLessonsSummary[subjectId]} subjectId={subjectNameId} />
            </div>
          )}
          <Theme
            themeData={themeData}
            lessonsCompleted={Math.max(0, startIndexNumber)}
            lessonsLength={lessons.length}
            themeTransition={themeTransition}
            onClick={onThemeClick}
            onReady={getElementsForSwitchAccess}
          />
        </div>
      </VizzleMainContainer>
      {showCompleteMessage && (
        <CustomDialog
          className='lesson-complete-dialog'
          openDialog={showCompleteMessage}
          onClose={handleCompleteMessageDialogClose}
          title={
            <div className='title'>Congratulations!</div>
          }
          content={(
            <div className='content'>
              <SentimentSatisfiedAltIcon className='icon' />
              <div className='message'>
                Activities completed.
              </div>
            </div>
          )}
          displayCloseIcon={false}
          actions={[{
            title: 'Close',
            onClick: handleCompleteMessageDialogClose,
            color: 'primary',
            className: 'btn-primary margin-x-auto',
            ref: lessonCompleteButtonRef,
          }]}
        />
      )}
    </>
  );
};

export default ThemeLessonSelection;

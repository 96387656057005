import CsvTableExport from '../../../../../../components/export/CsvTableExport';
import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import ExportData from '../buttons/ExportTableData';
import SearchForm from '../form/SearchForm';
import { useSearchStudent, useSearchStudentTable, useHandleSearch } from './SearchStudentHooks';
import '../../../../AdminCommon.scss';

const SearchStudent = () => {
  const {
    tableData,
    tableConfig,
    hasLoaded,
  } = useSearchStudentTable();

  const {
    searchStudentByCriteria,
    loading,
  } = useSearchStudent();

  const {
    criteria,
    handleOnSearchClick,
    handleOnSearchClear,
  } = useHandleSearch({
    searchStudentByCriteria,
  });

  return (
    <div className='search-student relative-container full-height'>
      <SearchAndSortableTable
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'firstName',
        }}
        toolbarActions={[
          <SearchForm
            key='search-student-search-form'
            initCriteria={criteria}
            onSearchClick={handleOnSearchClick}
            onSearchClear={handleOnSearchClear}
          />,
          <CsvTableExport
            key='search-student-search-export'
            config={tableConfig}
            tableData={tableData}
            filename='Vizzle NextGen Students.csv'
            linkComponent={<ExportData ariaLabel='Export Students' />}
          />,
        ]}
        loading={loading}
        autoResetTableState
        filterable={false}
        noDataMessage={hasLoaded ? 'No results found' : ''}
      />
    </div>
  );
};

export default SearchStudent;

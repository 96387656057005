import { isMobile } from '../../../../../../utils/DeviceUtils';
import PlayLessonDetailAction from '../../../../../lessons/lessonactions/PlayLessonDetailAction';
import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';
import ShareLinkLessonAction from '../../../../../lessons/lessonactions/ShareLinkLessonAction';
import LessonPlayMenuItem from '../../../../../lessons/lessonactions/dropdown/LessonPlayMenuItem';
import PrintLessonMenuItem from '../../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import DropdownAction from '../../../../../lessons/lessonactions/dropdown/DropdownAction';
import GroupPlayAddRemoveFromGroupPlay from './GroupPlayAddRemoveFromGroupPlay';

/**
 * Lesson card detail action
 */
export const createLessonCardDetailAction = (lesson, currentUser, currentUserProfile, closeFunction, terminologies) => {
  const actions = [];

  actions.push(
    (<PreviewLessonAction
      label='Preview'
      lesson={lesson}
      key={`lessons-group-play-lesson-preview-${lesson.id}`}
      user={currentUser}
      userProfile={currentUserProfile}
      terminologies={terminologies}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`lessons-group-play-lesson-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<GroupPlayAddRemoveFromGroupPlay
      lesson={lesson}
      onSuccess={closeFunction}
      key={`lessons-group-play-lesson-add-remove-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    <LessonPlayMenuItem
      key={`search-library-result-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      recordDataTo={currentUser.userName}
      user={currentUser}
      userProfile={currentUserProfile}
      terminologies={terminologies}
    />,
  ];
  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`lessons-group-play-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={currentUserProfile}
      />
    ));
  }

  actions.push(
    (
      <DropdownAction
        key={`lessons-group-play-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, user, userProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`lesson-group-play-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

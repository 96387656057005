import { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import PropTypes from 'prop-types';
import BounceLoader from 'react-spinners/BounceLoader';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import CardAction from '../../../../../components/card/CardAction';
import { copyLesson } from '../../../../../services/LessonService';
import { UNAUTHORIZED } from '../../../../../AppConstants';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { createLessonObject } from '../../../../../utils/LessonsMapping';
import Logger from '../../../../../utils/Logger';

const DIALOG_STATE_INIT = 0;
const DIALOG_STATE_COPYING = 1;

/**
 * Copy Lesson Action Container
 */
export default function CopyLessonAction({
  lesson,
  onOpenCopyLesson,
  onCloseCopyLesson,
  className,
}) {
  const [state, setState] = useState({
    dialogOpen: false,
    isCopying: false,
    dialogState: DIALOG_STATE_INIT,
  });

  const [lessonTitle, setLessonTitle] = useState(lesson.attributes.name);

  const { authoredLessonDomain, uiDomain, userDomain } = useDomain();
  const { domainData } = authoredLessonDomain;
  const { authoredLessons, includedData } = domainData;
  const { user } = userDomain.domainData;

  const handleClickOpen = () => {
    onOpenCopyLesson();
    setState({
      ...state,
      dialogOpen: true,
    });
  };

  const handleClose = () => {
    onCloseCopyLesson();
    setState({
      ...state,
      dialogOpen: false,
    });
  };

  const handleChange = (event) => {
    setLessonTitle(event.target.value.substring(0, 255));
  };

  const handleOk = async () => {
    setState({
      ...state,
      isCopying: true,
      dialogState: DIALOG_STATE_COPYING,
    });

    try {
      const newLesson = await copyLesson(lessonTitle, lesson.attributes.uuid);
      onCloseCopyLesson();
      const lessonData = newLesson.data.data;

      const rowToAdd = authoredLessons.find((al) => (
        al.subject === lessonData.attributes.subjects[0]
      ));

      const lessonObject = createLessonObject(lessonData, user);
      const lessonDataToadd = {
        id: lessonObject.id,
        attributes: lessonObject.attributes,
        specialtyContent: lessonObject.specialtyContent,
        relationships: lessonObject.relationships,
        type: 'lessons',
      };
      if (!rowToAdd) {
        const miscRow = authoredLessons.find((l) => (
          l.subject === 'Misc.'
        ));
        miscRow.lessons.unshift(lessonDataToadd);
      } else {
        rowToAdd.lessons.unshift(lessonDataToadd);
      }
      setState({
        ...state,
        dialogOpen: false,
      });
      await authoredLessonDomain.updateAuthoredLessons([...authoredLessons], includedData);
      await uiDomain.showSnackbar(true, 'info', 3000, 'Copy Complete!');
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
      }
      Logger.logError({
        ERROR: error,
      });
    }
  };

  const getDialogContent = () => {
    switch (state.dialogState) {
      case DIALOG_STATE_INIT:
        return (
          <DialogContent>
            <DialogContentText style={{ width: 450 }}>
              Enter a new title for your lesson.
            </DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Activity Title'
              type='text'
              fullWidth
              value={lessonTitle}
              onChange={handleChange}
            />
          </DialogContent>
        );
      case DIALOG_STATE_COPYING:
        return (
          <DialogContent>
            <Box display='flex' flexDirection='column'>
              <Box mx='auto' mb='10px' width='55px' height='55px'>
                <BounceLoader color='#3b94d1' size={50} />
              </Box>
              <DialogContentText style={{ width: 450, textAlign: 'center' }}>
                Please wait while the activity is being copied.
              </DialogContentText>
            </Box>
          </DialogContent>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <CardAction
        icon={<FileCopyIcon />}
        actionHandler={() => { handleClickOpen(); }}
        className={`top-left ${className}`}
      />
      <Dialog open={state.dialogOpen} aria-labelledby='copy-lesson'>
        <DialogContent>
          {getDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={state.isCopying} color='primary'>
            Cancel
          </Button>
          <ButtonWithSpinner
            label='Ok'
            loading={state.isCopying}
            onClick={handleOk}
            color='primary'
            disabled={!lessonTitle}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

CopyLessonAction.defaultProps = {
  onOpenCopyLesson: () => {},
  onCloseCopyLesson: () => {},
  className: '',
};

CopyLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  onOpenCopyLesson: PropTypes.func,
  onCloseCopyLesson: PropTypes.func,
  className: PropTypes.string,
};

/**
 * Utility for Object
 */
export default {
  /**
   * wheter the object is an empty object, no property in the object
   */
  isEmpty: (o) => o != null && o && Object.keys(o).length === 0,

  /** Run the specified function in setTimeout */
  setTimeout: (f, duration) => {
    if (!f) {
      return null;
    }
    const timer = setTimeout(() => {
      clearTimeout(timer);
      f();
    }, duration);
    return timer;
  },

  /**
   * Convert json object into query string
   */
  convertJsonToQueryString: (params) => {
    const queryParams = [];
    Object.keys(params).forEach((key) => {
      if (params[key] !== null && params[key] !== undefined && params[key].toString().length > 0) {
        queryParams.push(`${key}=${encodeURIComponent(params[key])}`);
      }
    });
    return queryParams.join('&');
  },

  appendQueryString: (value, queryUrlString, url) => {
    let urlString = url;
    if (value) {
      if (typeof value === 'boolean') {
        urlString = `${urlString}&${queryUrlString}=${encodeURIComponent(value)}`;
      } else if (Array.isArray(value)) {
        const valueToSearch = value.map((v) => v.value);
        urlString = `${urlString}&${queryUrlString}=${encodeURIComponent(valueToSearch)}`;
      } else if (value.length > 0) {
        urlString = `${urlString}&${queryUrlString}=${encodeURIComponent(value)}`;
      } else if (value !== null && value !== undefined) {
        urlString = `${urlString}&${queryUrlString}=${encodeURIComponent(value)}`;
      }
    }
    return urlString;
  },

  delay: (duration) => {
    if (!duration) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        resolve();
      }, duration);
    });
  },
  downloadFileFromData: (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },
};

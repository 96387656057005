import PropTypes from 'prop-types';
import FilterableMultiSelector from '../../../../../components/selector/FilterableMultiSelector';
import { usePrepareData } from './SearchSchoolActionHooks';
import './SearchSchoolAction.scss';

const SearchSchoolAction = ({
  districtId,
  schoolsData,
  onSchoolsLoaded,
  onFilterChange,
  filterValue,
  isMultiple,
  className,
  popperClassName,
  disabled,
}) => {
  const {
    transformedSchools,
    loading,
  } = usePrepareData({ districtId, schoolsData, onSchoolsLoaded });

  const handleMultiSelectorApply = (value) => {
    if (value && filterValue && value.key === filterValue) {
      return;
    }
    onFilterChange(value);
  };

  return (
    <FilterableMultiSelector
      popperClassName={popperClassName}
      className={`search-school-action ${className}`}
      key='filter-selector-school'
      onApply={(value) => {
        handleMultiSelectorApply(value);
      }}
      label='Please select school'
      initFilterKeyValues={
        Array.isArray(filterValue) ? filterValue : filterValue && [filterValue]
      }
      options={transformedSchools}
      filterable
      isMultiple={isMultiple}
      subTitle='Filter School'
      disabled={disabled || transformedSchools.length <= 1}
      loading={loading}
    />
  );
};

SearchSchoolAction.defaultProps = {
  districtId: null,
  schoolsData: [],
  onSchoolsLoaded: () => null,
  onFilterChange: () => { },
  filterValue: null,
  isMultiple: false,
  className: '',
  popperClassName: '',
  disabled: false,
};

SearchSchoolAction.propTypes = {
  districtId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  schoolsData: PropTypes.arrayOf(PropTypes.any),
  onSchoolsLoaded: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isMultiple: PropTypes.bool,
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchSchoolAction;

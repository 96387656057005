/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import BookmarkLessonAction from '../../../../../lessons/lessonactions/BookmarkLessonAction';
import { useTeacherCurriculum } from '../../../context/TeacherCurriculumContextProvider';

/**
 * Share Link Lesson Action Container
 */
export default function CurriculumBookmarkAction({ lesson, className, showLabel }) {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonData } = teacherCurriculumDomain.domainData;

  return (
    <BookmarkLessonAction
      lesson={lesson}
      showLabel={showLabel}
      className={className}
      onSaveSuccess={() => {
        // eslint-disable-next-line no-param-reassign
        lesson.meta.assigned = true;
        // eslint-disable-next-line no-param-reassign
        lesson.meta.archived = false;
        teacherCurriculumDomain.updateLessonData([...lessonData.lessons], [...lessonData.included]);
      }}
    />
  );
}

CurriculumBookmarkAction.defaultProps = {
  showLabel: false,
  className: '',
};

CurriculumBookmarkAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
};

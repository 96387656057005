import { useRef, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardMedia,
  Dialog,
  DialogContent,
} from '@mui/material';
import { video } from '../../../services/MediaRegistrationService';
import './VideoPlayer.scss';
import ObjectUtils from '../../../utils/ObjectUtils';

/**
 * This is a fix for ios that the video cannot be automatically played.
 * The video can loaded when the user has interaction with the software e.g. tap the screen.
 * Use this method to preload the video when the user click, tap a button.
 * */
export const preloadVideo = (videoUrl) => {
  video.src = videoUrl;
  video.muted = true;
  video.play().catch(() => {
    // Do nothing
  });
  const timerPlay = setTimeout(() => {
    video.pause();
    clearTimeout(timerPlay);
  }, 250);
};

/**
 * Video player
 */
const VideoPlayer = forwardRef(({
  videoUrl,
  open,
  handleClose,
  playsVideoInline,
  onInlineVideoPlay,
  onInlineVideoStop,
  onInlineVideoError,
  videoPoster,
  onDialogVideoEnd,
  forPrinting,
}, ref) => {
  const videoRefEl = useRef(null);

  const handleCloseFunction = (e) => {
    if (e) {
      e.stopPropagation();
    }
    video.src = null;
    handleClose();
  };

  const handleEntered = () => {
    video.src = videoUrl;
    video.muted = false;
    video.play().catch(() => {
      // Do nothing
    });
    videoRefEl.current.appendChild(video);
    video.onended = function onseeked() {
      ObjectUtils.setTimeout(() => {
        handleCloseFunction();
        onDialogVideoEnd();
      }, 2000);
    };
  };

  const inlineVideoId = useMemo(() => uuidv1(), []);

  return (
    playsVideoInline ? (
      <div className='video-player-inline-video-container'>
        <CardMedia
          id={inlineVideoId}
          className='video-player-inline-video'
          component='video'
          src={videoUrl}
          autoPlay={!forPrinting}
          muted
          playsInline
          controls
          onPlay={onInlineVideoPlay}
          onEnded={onInlineVideoStop}
          onPause={onInlineVideoStop}
          onError={onInlineVideoError}
          poster={videoPoster}
          ref={ref}
        />
      </div>
    ) : (
      <Dialog
        open={open}
        onClose={handleCloseFunction}
        className='video-player-dialog'
        TransitionProps={{
          onEntered: handleEntered,
        }}
        data-test='video-player-dialog'
      >
        {
          videoUrl ? (
            <DialogContent className='video-player-dialog-content'>
              <Card ref={videoRefEl} className='video-player-dialog-card' />
            </DialogContent>
          ) : ''
        }
      </Dialog>
    )
  );
});

VideoPlayer.defaultProps = {
  videoUrl: null,
  open: false,
  handleClose: () => { },
  onInlineVideoPlay: () => { },
  onInlineVideoStop: () => { },
  onInlineVideoError: () => { },
  playsVideoInline: false,
  videoPoster: null,
  onDialogVideoEnd: () => { },
  forPrinting: false,
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  playsVideoInline: PropTypes.bool,
  onInlineVideoPlay: PropTypes.func,
  onInlineVideoStop: PropTypes.func,
  onInlineVideoError: PropTypes.func,
  videoPoster: PropTypes.string,
  onDialogVideoEnd: PropTypes.func,
  forPrinting: PropTypes.bool,
};

export default VideoPlayer;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import ButtonSelector from '../../../../../../../components/selector/ButtonSelector';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import TextAndMediaFormatting from '../TextAndMediaFormatting';
import AddPhotoOrVideo from '../AddPhotoOrVideo';
import AddAudio from '../AddAudio';
import {
  TILE_TYPE_STANDARD,
} from '../../../../../../../components/vizzle/page/game/GameConstants';

const TEXT = 0;
const IMAGE = 1;
const AUDIO = 2;

const GameTextAndMediaEditor = ({ selectedCardIndex }) => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];
  const currentGameCard = selectedPage.gameCards[selectedCardIndex];

  const [selectedOption, setSelectedOption] = useState(0);

  const buttonSelectorProps = {
    config: [
      {
        label: 'Text',
        dataValue: TEXT,
        disabled: currentGameCard.gameSpaceType !== TILE_TYPE_STANDARD,
      },
      {
        label: 'Image/Video',
        dataValue: IMAGE,
        disabled: currentGameCard.gameSpaceType !== TILE_TYPE_STANDARD,
      },
      {
        label: 'Audio',
        dataValue: AUDIO,
        disabled: currentGameCard.gameSpaceType !== TILE_TYPE_STANDARD,
      },
    ],
    onSelect: (dataValue) => {
      setSelectedOption(dataValue);
    },
    selectedValue: selectedOption,
    className: 'vizzle-game-content-editor-section-selector',
  };

  const handleOnTextApply = async (textLines) => {
    let { text } = selectedPage.gameCards[selectedCardIndex];
    if (!text) {
      text = {};
    }
    text.lines = textLines;
    selectedPage.gameCards[selectedCardIndex].text = text;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const handleOnTextRemoved = async () => {
    selectedPage.gameCards[selectedCardIndex].text = null;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageApply = async (media) => {
    selectedPage.gameCards[selectedCardIndex].image = media.selectedImageUrl;
    selectedPage.gameCards[selectedCardIndex].video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    selectedPage.gameCards[selectedCardIndex].image = null;
    selectedPage.gameCards[selectedCardIndex].video = null;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioApply = async (audioData) => {
    selectedPage.gameCards[selectedCardIndex].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    selectedPage.gameCards[selectedCardIndex].audio = null;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  useEffect(() => {
    if (currentGameCard.gameSpaceType !== 1) {
      setSelectedOption(TEXT);
    }
    // eslint-disable-next-line
  }, [currentGameCard.gameSpaceType]);

  return (
    <Box display='block' position='relative' height='100%'>
      <Box mt='15px'>
        <ButtonSelector
          {...buttonSelectorProps}
        />
      </Box>
      <Box mt='10px' height='100%'>
        {selectedOption === TEXT
          && (
            <Box height='99%' width='100%'>
              <TextAndMediaFormatting
                text={currentGameCard.text}
                onTextApply={handleOnTextApply}
                onTextRemoved={handleOnTextRemoved}
                disabled={currentGameCard.gameSpaceType !== TILE_TYPE_STANDARD}
              />
            </Box>
          )}
        {selectedOption === IMAGE
          && (
            <Box height='85%'>
              <AddPhotoOrVideo
                image={currentGameCard.image}
                onRemove={onImageRemove}
                onSelect={onImageApply}
                disabled={currentGameCard.gameSpaceType !== TILE_TYPE_STANDARD}
              />
            </Box>
          )}
        {selectedOption === AUDIO
          && (
            <Box height='85%'>
              <AddAudio
                audio={currentGameCard.audio}
                onAudioRemoved={onAudioRemoved}
                onAudioApply={onAudioApply}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

GameTextAndMediaEditor.defaultProps = {
  selectedCardIndex: 0,
};

GameTextAndMediaEditor.propTypes = {
  selectedCardIndex: PropTypes.number,
};

export default GameTextAndMediaEditor;

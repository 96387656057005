import {
  Card,
} from '@mui/material';
import './BlankActivityCard.scss';

/**
 * Component for displaying blank activity card
 * @param {*} mode the mode of the card
 */
export default function BlankActivityCard() {
  return (
    <Card className='blank-activity-card blank-activity-card-preview' />
  );
}

import BaseDomain from '../../../states/domains/BaseDomain';

const LICENSES_UPDATE_SELECTED_LICENSE_IDS = 'LICENSES_UPDATE_SELECTED_LICENSE_IDS';

const initialState = {
  selectedLicenseIds: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case LICENSES_UPDATE_SELECTED_LICENSE_IDS:
      return {
        ...state,
        selectedLicenseIds: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'licensesDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSelectedLicenseIds(licenseIds) {
    return this.dispatch({
      type: LICENSES_UPDATE_SELECTED_LICENSE_IDS,
      payload: licenseIds,
    });
  }
}

import { useEffect, useState } from 'react';
import { getLessonCourses } from '../../../services/CourseService';
import { getLessonLevels } from '../../../services/LessonStandardService';
import { useDomain } from '../../../states/AppDomainProvider';
import { sort } from '../../../utils/ArrayUtils';
import Logger from '../../../utils/Logger';
import ObjectUtils from '../../../utils/ObjectUtils';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../context/TeacherCourseContextProvider';

export const useGetLessonCourseData = () => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getCourseData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getLessonCourses();
        const sortedResult = sort(result.data, 'name');
        await teacherCourseDomain.updateLessonCourses(sortedResult);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    lessonCourseLoading: loading,
    lessonCourses,
    getCourseData,
  };
};

export const usePrepareLessonCourseData = () => {
  const {
    lessonCourseLoading,
    lessonCourses,
    getCourseData,
  } = useGetLessonCourseData();

  useEffect(() => {
    if (!lessonCourses) {
      getCourseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lessonCourseLoading,
    lessonCourses,
  };
};

export const useHandleFormChange = ({ courseDrowdown }) => {
  const [localSearchCriteria, setLocalSearchCriteria] = useState({
    courseId: '',
    unitId: '',
    topicId: '',
    lessonLevel: getLessonLevels()[0].id,
  });

  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;

  useEffect(() => {
    if (!ObjectUtils.isEmpty(searchCriteria)) {
      setLocalSearchCriteria({
        ...searchCriteria,
      });
    }
  }, [searchCriteria]);

  useEffect(() => {
    if (courseDrowdown && courseDrowdown.length === 1) {
      setLocalSearchCriteria((previousState) => ({
        ...previousState,
        courseId: courseDrowdown[0].id,
      }));
    }
  }, [courseDrowdown]);

  const handleOnCourseDataChange = (e, additionalData = {}) => {
    setLocalSearchCriteria({
      ...localSearchCriteria,
      [e.target.name]: e.target.value,
      ...additionalData,
    });
  };

  const handleOnLessonLevelChange = (e) => {
    setLocalSearchCriteria({
      ...localSearchCriteria,
      [e.target.name]: e.target.value,
    });
  };

  return {
    localSearchCriteria,
    handleOnCourseDataChange,
    handleOnLessonLevelChange,
  };
};

export const sortGrade = (arr) => {
  let kList = [];
  let highSchoolList = [];
  let middleSchoolList = [];
  let others = [];
  arr.forEach((a) => {
    if (a.name.toLowerCase() === 'k' || a.name.toLowerCase().startsWith('k')) {
      kList.push(a);
    } else if (a.name.toLowerCase().startsWith('high school')) {
      highSchoolList.push(a);
    } else if (a.name.toLowerCase().startsWith('middle school')) {
      middleSchoolList.push(a);
    } else {
      others.push(a);
    }
  });

  kList = kList.sort((a, b) => a.name.localeCompare(b.name));
  highSchoolList = highSchoolList.sort((a, b) => a.name.localeCompare(b.name));
  middleSchoolList = middleSchoolList.sort((a, b) => a.name.localeCompare(b.name));
  others = others.sort((a, b) => a.name.localeCompare(b.name));

  const gradeListOthers = middleSchoolList.length > 0 ? others.filter((o) => !['6', '7', '8'].includes(o.name)) : others;

  return [
    ...kList,
    ...gradeListOthers,
    ...middleSchoolList,
    ...highSchoolList,
  ];
};

export const getCourseDropdownOptions = ({ subjectId, lessonCourses }) => {
  if (!lessonCourses || !subjectId) {
    return [];
  }

  const courseWithSelectedSubject = lessonCourses.filter((l) => l.subject === Number(subjectId) && Boolean(l.grade));
  const courseOptions = courseWithSelectedSubject.map((c) => ({
    id: c.id,
    name: c.grade,
  }));
  return sortGrade(courseOptions);
};

export const useGetCourseDropdown = (subjectId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  return getCourseDropdownOptions({ subjectId, lessonCourses });
};

export const getUnitDropdownOptions = ({ courseId, lessonCourses }) => {
  if (!lessonCourses || !courseId) {
    return [];
  }

  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  return course ? course.units : [];
};

export const useGetUnitDropdownList = (courseId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  return getUnitDropdownOptions({ courseId, lessonCourses });
};

export const getTopicDropdownOptions = ({ courseId, unitId, lessonCourses }) => {
  if (!lessonCourses || !courseId || !unitId) {
    return [];
  }
  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  if (!course) {
    return [];
  }
  const { units } = course;

  const unit = units.find((u) => u.id === unitId);
  return unit ? unit.topics : [];
};

export const useGetTopicDropdownList = (courseId, unitId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  return getTopicDropdownOptions({ courseId, unitId, lessonCourses });
};

export const useGetIsStandardTemplateFeatureAvilable = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const {
    courseId,
    unitId,
  } = searchCriteria;

  const selectedCourse = (lessonCourses || []).find((l) => l.id === courseId) || {};

  const { units } = selectedCourse;
  const selectedUnit = (units || []).find((u) => u.id === unitId) || '';
  if (selectedUnit === null || selectedUnit === undefined) {
    return true;
  }
  return !selectedUnit.generateUnitGuideFromTopics;
};

export const useGetIsLessonTemplateFeatureAvailable = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  return user.userModerator || user.unitCreator;
};

import useSettingForm from '../form/useSettingForm';
import Accessibe from '../../../settings/Accessibe';
import { useDomain } from '../../../../states/AppDomainProvider';
import { destroyAccessibe, initAccessibe, showAccessibeChatIcon } from '../../../../services/AccessibeService';

const AccessibeSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Accessibility');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange({ accessibeOn: newValue });
    if (newValue) {
      initAccessibe({ forTeacher: true });
      showAccessibeChatIcon();
    } else {
      destroyAccessibe();
    }
  };

  return (
    <Accessibe
      profile={userProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default AccessibeSettings;

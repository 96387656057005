import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import './ConfirmationDialog.scss';
import ButtonWithSpinner from '../buttons/ButtonWithSpinner';

/**
 * Confirmation dialog component.
 * Dialogs inform users about a task and can contain critical information, require decisions
 */
export default function ConfirmationDialog({
  open,
  title,
  contentText,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  confirmClass,
  cancelClass,
  loading,
  ...rest
}) {
  return (
    <Dialog
      {...rest}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {
        title
          ? <DialogTitle className='vizzle-dialog-title' data-test='title'>{title}</DialogTitle> : ''
      }
      <DialogContent>
        <div className='vizzle-dialog-content' data-private>
          {contentText}
        </div>
      </DialogContent>
      <DialogActions className='vizzle-dialog-actions'>
        <ButtonWithSpinner
          label={cancelLabel}
          onClick={onCancel}
          color='primary'
          data-test='confirm-dialog-cancel-button'
          className={`vizzle-dialog-actions-cancel btn-danger ${cancelClass}`}
          loading={loading}
        />
        <ButtonWithSpinner
          label={confirmLabel}
          onClick={onConfirm}
          color='primary'
          data-test='confirm-dialog-confirm-button'
          className={`vizzle-dialog-actions-confirm btn-primary ${confirmClass}`}
          autoFocus
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  open: false,
  title: null,
  confirmLabel: 'OK',
  cancelLabel: 'Cancel',
  confirmClass: '',
  cancelClass: '',
  loading: false,
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
  loading: PropTypes.bool,
};

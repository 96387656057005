import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './SequencingActivityResponse.scss';
import { useDetermineClickOrTouchEventForDnd } from '../../../../hooks/DndClickOrTouchHooks';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';

const SequencingActivityResponse = forwardRef(({
  responseCard,
  responseCardId,
  disablePlayMedia,
  onClick,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const responseRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => responseRef.current,
  }));

  const handleEvent = useDetermineClickOrTouchEventForDnd(
    (e) => {
      if (onClick) {
        onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
      }
    },
    true,
  );
  useRegisterSwitchAccessSelectEvent(responseRef, () => {
    onClick(null, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
  });

  return (
    <Box
      id={responseCardId}
      key={responseCardId}
      boxShadow={2}
      className='sequencing-activity-response-card'
      // onClick={(e) => onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0)}
      ref={responseRef}
      {...handleEvent}
      data-skip-switch-access-click='true'
    >
      {disablePlayMedia ? '' : (
        <MediaPlayer
          audioUrl={responseCard.audio}
          videoUrl={responseCard.video}
          parentElementId={responseCardId}
          className='response-media'
          textToRead={elementToPlayTts}
          tts={ttsConfig}
          forDndElement
        />
      )}
      {responseCard.image ? <CardMedia data-test='response-image' image={responseCard.image} className='response-image' /> : ''}

      {responseCard.text ? (
        <div data-test='response-text' className={`response-text ${responseCard.image ? 'text-with-image' : ''}`}>
          <TextLines
            textLines={responseCard.text}
            ref={setTextLineRef}
            componentKey={`text-line-${responseCardId}`}
          />
        </div>
      ) : ''}
    </Box>
  );
});

export default SequencingActivityResponse;

SequencingActivityResponse.defaultProps = {
  responseCard: {},
  responseCardId: '',
  disablePlayMedia: false,
  onClick: () => { },
};

SequencingActivityResponse.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  responseCardId: PropTypes.string,
  disablePlayMedia: PropTypes.bool,
  onClick: PropTypes.func,
};

import { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CloseIcon from '@mui/icons-material/Close';
import './CardDetailContainer.scss';
import LiveChatService from '../../../services/LiveChatService';

const CardDetailContainer = forwardRef(({
  open,
  selectedIndex,
  onClose,
  dataList,
  createCardFunction,
  className,
}, ref) => {
  const sliderRef = useRef(null);
  const currentSlide = useRef(selectedIndex);

  const [state, setState] = useState({
    previousButtonDisable: selectedIndex === 0,
    nextButtonDisable: selectedIndex === dataList.length - 1,
  });

  const dataListLength = dataList.length;

  const afterSliderChage = (newIndex) => {
    currentSlide.current = newIndex;
    setState({
      ...state,
      previousButtonDisable: newIndex <= 0,
      nextButtonDisable: (newIndex + 1) >= dataListLength,
    });
  };

  const next = () => {
    if (!state.nextButtonDisable) {
      sliderRef.current.slickGoTo(currentSlide.current + 1);
    }
  };

  const previous = () => {
    if (!state.previousButtonDisable) {
      sliderRef.current.slickGoTo(currentSlide.current - 1);
    }
  };

  useImperativeHandle(ref, () => ({
    goToSlide: (index, dontAnimate = false) => {
      sliderRef.current.slickGoTo(index, dontAnimate);
    },
  }));

  const settings = {
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    lazyLoad: 'ondemand',
    className: 'card-detail-slider',
    infinite: false,
    initialSlide: selectedIndex,
    draggable: false,
    ref: sliderRef,
    arrows: false,
    afterChange: afterSliderChage,
  };

  useEffect(() => {
    if (open) {
      LiveChatService.hideLiveChatIcon();
    } else {
      LiveChatService.showLiveChatIcon();
    }
    return () => {
      LiveChatService.showLiveChatIcon();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      scroll='body'
      className={`card-detail-container ${className}`}
    >
      <DialogActions className='action-area'>
        <Button onClick={onClose} color='primary' data-test='card-detail-container-close-action'>
          Close
          <CloseIcon />
        </Button>
      </DialogActions>
      {dataList ? (
        <DialogContent className='content'>
          <IconButton
            aria-label='Prevous'
            component='span'
            className={`detail-navigator-button previous ${state.previousButtonDisable ? 'disabled' : ''}`}
            onClick={previous}
            data-test='detail-navigator-button-previous'
          >
            <PlayCircleFilledIcon />
          </IconButton>
          <Slider {...settings}>
            {dataList.map((data, index) => (
              createCardFunction(data, index)
            ))}
          </Slider>
          <IconButton
            aria-label='Next'
            component='span'
            className={`detail-navigator-button next ${state.nextButtonDisable ? 'disabled' : ''}`}
            data-test='detail-navigator-button-next'
            onClick={next}
          >
            <PlayCircleFilledIcon />
          </IconButton>
        </DialogContent>
      ) : null}
    </Dialog>
  );
});

CardDetailContainer.defaultProps = {
  open: false,
  selectedIndex: -1,
  onClose: () => { },
  className: '',
};

CardDetailContainer.propTypes = {
  open: PropTypes.bool,
  selectedIndex: PropTypes.number,
  onClose: PropTypes.func,
  dataList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    specialtyContent: PropTypes.object,
  })).isRequired,
  createCardFunction: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CardDetailContainer;

// hook functions for handling the container
export const useCardDetail = () => {
  const [openCardDetail, setOpenCardDetail] = useState({
    open: false,
    selectedIndex: null,
    rowIndex: null,
  });

  const handleOnViewDetailClick = (selectedIndex, rowIndex) => {
    setOpenCardDetail({
      open: true,
      selectedIndex,
      rowIndex,
    });
  };

  const handleOnCloseDetail = () => {
    setOpenCardDetail({
      open: false,
      selectedIndex: null,
      rowIndex: null,
    });
  };

  return {
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openCardDetail,
    setOpenCardDetail,
  };
};

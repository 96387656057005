/**
 * Filter lesson by keyword
 * @param {*} keyword keyword to filter
 * @param {*} lesson lessonto filter
 */
export const filterByKeyword = (keyword, lesson) => {
  const searchInText = (key, text) => key && text && text.toLowerCase().indexOf(key.toLowerCase()) >= 0;

  const searchInArray = (key, array) => array.find((el) => searchInText(key, el));

  const keyString = keyword.split(' ');
  /**
    * If keywords for 'matching food', if those 5 fields have both these words,
    * then the lesson should be returned.
    * If it has only one but not the other, then it should not be returned
    *
    * E.g. Matching might be the activity type while food might be a part of the lesson name.
    * If that's the case, then this lesson should be returned. However,
    * if there's only food in the lesson name but Matching is not available in any of those other 5 fields,
    * then we should ignore this lesson
    */
  const matchedIndex = keyString.findIndex((keyStringSearch) => {
    const isNameMatch = searchInText(keyStringSearch, lesson.attributes.name);
    const isLessonIdMatch = searchInText(keyStringSearch, lesson.id.toString());
    const isGradeMatch = searchInText(keyStringSearch, lesson.attributes.gradeLevel);
    const isDescriptionMatch = searchInText(keyStringSearch, lesson.attributes.description);
    const isSubjectsMatch = searchInArray(keyStringSearch, lesson.attributes.subjects);
    return isNameMatch || isLessonIdMatch || isGradeMatch || isDescriptionMatch || isDescriptionMatch || isSubjectsMatch;
  });
  return matchedIndex >= 0;
};

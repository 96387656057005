import PropTypes from 'prop-types';
import {
  Slider,
  Typography,
} from '@mui/material';
import './VizzleSlider.scss';

const VizzleSlider = ({
  className,
  label,
  value,
  min,
  max,
  name,
  ...props
}) => (
  <div className='vizzle-slider' data-test={`vizzle-slider-${name}`}>
    {label && (
      <Typography gutterBottom className='label' data-test='label'>
        {label}
      </Typography>
    )}

    <Slider
      aria-labelledby='discrete-slider'
      valueLabelDisplay='auto'
      marks
      min={min}
      max={max}
      className={`${className} slider`}
      value={value}
      {...props}
      data-test={`slider-${name}`}
    />
    <div className='value' data-test='vizzle-slider-value'>
      {value}
    </div>
    <div className='range'>
      (
      {min}
      &nbsp;
      -
      &nbsp;
      {max}
      )
    </div>
  </div>
);

VizzleSlider.defaultProps = {
  className: '',
  label: '',
  value: 0,
  min: 0,
  max: 10,
  name: '',
};

VizzleSlider.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
};

export default VizzleSlider;

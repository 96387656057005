import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useArchiveUnarchiveObjective } from './ObjectiveDetailActionHooks';
import './ObjectiveDetailAction.scss';

const UnarchiveObjectiveAction = ({
  objective,
  rowIndex,
  onSaveSuccess,
}) => {
  const {
    archivedObjective,
  } = useArchiveUnarchiveObjective(objective, rowIndex, onSaveSuccess);

  return (
    <Button
      className='objective-detail-action'
      variant='contained'
      color='primary'
      onClick={() => { archivedObjective(false); }}
    >
      Unarchive
      <br />
      Goal/Obj
    </Button>
  );
};

UnarchiveObjectiveAction.defaultProps = {
  onSaveSuccess: () => { },
};

UnarchiveObjectiveAction.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  onSaveSuccess: PropTypes.func,
  rowIndex: PropTypes.number.isRequired,
};

export default UnarchiveObjectiveAction;

import PropTypes from 'prop-types';
import DefaultImage from '../../../../../static/images/default_thumbnail.png';
import './ObjectiveLessonplay.scss';

const ObjectiveLessonplay = ({
  lesson,
  averagePercentageCorrect,
}) => (
  <div className='objective-lesson-play'>
    <div
      className='lesson-image'
      style={{ backgroundImage: `url(${lesson.attributes.thumbnailUrl || DefaultImage})` }}
    />
    <div className='lesson-name'>
      {lesson.attributes.name}
    </div>
    <div className='percentage-correct'>
      {averagePercentageCorrect.average > 0 ? (
        <div className='lesson-played'>
          <div className='percentage'>
            {averagePercentageCorrect.average}
            %
          </div>
          <div className='average'>
            Average for last <b>{averagePercentageCorrect.numberofPlays}</b> plays
          </div>
        </div>
      ) : (
        <div className='no-lesson-played'>
          No Activity Plays Yet with Answered Questions
        </div>
      )}
    </div>
  </div>
);

ObjectiveLessonplay.defaultProps = {
  lesson: {},
  averagePercentageCorrect: {
    numberofPlays: 0,
    average: 0,
  },
};

ObjectiveLessonplay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }),
  averagePercentageCorrect: PropTypes.shape({
    numberofPlays: PropTypes.number,
    average: PropTypes.number,
  }),
};

export default ObjectiveLessonplay;

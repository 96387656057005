import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl } from '@mui/material';
import { CELEBRATION_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';
import DropdownInput from '../../../../components/selector/DropdownInput';
import './CelebrationStyleSetting.scss';

const celebrationOptions = [
  {
    id: CELEBRATION_STYLE_KEY.interactive, name: 'Interactive',
  },
  {
    id: CELEBRATION_STYLE_KEY.simple, name: 'Simple',
  },
  {
    id: CELEBRATION_STYLE_KEY.none, name: 'None',
  },
];

export default function CelebrationStyleSetting({ profile, onChangeCallback }) {
  const [celebrationStyle, setCelebrationStyle] = useState(profile.celebrationStyle);
  return (
    <Box display='flex' className='celebration-style-settings' data-test='celebration-style-settings-container'>
      <FormControl variant='outlined'>
        <DropdownInput
          dropdownValues={celebrationOptions}
          label='Select Style'
          labelVariant='h6'
          value={celebrationStyle}
          onChange={(e) => {
            setCelebrationStyle(e.target.value);
            onChangeCallback(e.target.value);
          }}
          className='celebration-style-dropdown-select'
          data-test='celebration-style-settings-selector'
        />
      </FormControl>
    </Box>
  );
}

CelebrationStyleSetting.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../text/TextLines';
import './CauseAndEffectActivityCard.scss';

const CauseAndEffectActivityCard = ({
  card,
  cardId,
}) => (
  <Box
    id={cardId}
    key={cardId}
    boxShadow={2}
    className='cause-and-effect-card'
  >

    {card.image ? <CardMedia image={card.image} className='cause-and-effect-card-image' /> : ''}

    {card.text ? (
      <div className={`cause-and-effect-card-text ${card.image ? 'text-with-image' : ''}`}>
        <TextLines
          textLines={card.text}
          componentKey={`text-line-${cardId}`}
        />
      </div>
    ) : ''}
  </Box>
);

export default CauseAndEffectActivityCard;

CauseAndEffectActivityCard.defaultProps = {
  card: {},
  cardId: '',
};

CauseAndEffectActivityCard.propTypes = {
  card: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  cardId: PropTypes.string,
};

import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import PropTypes from 'prop-types';
import BounceLoader from 'react-spinners/BounceLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import CardAction from '../../../../../components/card/CardAction';
import { deleteLesson } from '../../../../../services/LessonService';
import { UNAUTHORIZED } from '../../../../../AppConstants';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';

const DIALOG_STATE_INIT = 0;
const DIALOG_STATE_DELETING = 1;

/**
 * Delete Lesson Action Container
 */
export default function DeleteLessonAction({
  lesson,
  onOpenDeleteLesson,
  onCloseDeleteLesson,
  className,
}) {
  const [state, setState] = useState({
    dialogOpen: false,
    isDeleting: false,
    dialogState: DIALOG_STATE_INIT,
  });

  const { authoredLessonDomain, uiDomain } = useDomain();
  const { domainData } = authoredLessonDomain;
  const { authoredLessons, includedData } = domainData;

  const handleClickOpen = () => {
    onOpenDeleteLesson();
    setState({
      ...state,
      dialogOpen: true,
    });
  };

  const handleClose = () => {
    onCloseDeleteLesson();
    setState({
      ...state,
      dialogOpen: false,
    });
  };

  const handleOk = async () => {
    setState({
      ...state,
      isDeleting: true,
      dialogState: DIALOG_STATE_DELETING,
    });

    try {
      await deleteLesson(lesson.attributes.uuid);
      onCloseDeleteLesson();
      Logger.logWhenDebugModeIsOn('activity deleted');
      let rowToDeleteFrom;

      if (lesson.attributes.subjects[0] && lesson.attributes.subjects[0] !== 'Misc.') {
        Logger.logWhenDebugModeIsOn('activity not misc lesson');
        rowToDeleteFrom = authoredLessons.find((dl) => (
          dl.subject === lesson.attributes.subjects[0]
        ));
      } else {
        Logger.logWhenDebugModeIsOn('is misc activity');
        rowToDeleteFrom = authoredLessons.find((dl) => (
          dl.subject === 'Misc.'
        ));
        Logger.logWhenDebugModeIsOn('deleted from misc row');
      }

      rowToDeleteFrom.lessons = rowToDeleteFrom.lessons.filter((ele) => (
        ele.attributes.uuid !== lesson.attributes.uuid
      ));

      setState({
        ...state,
        dialogOpen: false,
      });

      await authoredLessonDomain.updateAuthoredLessons([...authoredLessons], includedData);
      await uiDomain.showSnackbar(true, 'info', 3000, 'Activity Deleted');
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
      }
      setState({
        ...state,
        dialogOpen: false,
      });
      Logger.logError({
        ERROR: error,
      });
    }
  };

  const getDialogContent = () => {
    switch (state.dialogState) {
      case DIALOG_STATE_INIT:
        return (
          <DialogContent>
            <DialogContentText style={{ width: 450 }}>
              Are you sure you want to delete your Lesson?
            </DialogContentText>
          </DialogContent>
        );
      case DIALOG_STATE_DELETING:
        return (
          <DialogContent>
            <Box display='flex' flexDirection='column'>
              <Box mx='auto' mb='10px' width='55px' height='55px'>
                <BounceLoader color='#3b94d1' size={50} />
              </Box>
              <DialogContentText style={{ width: 450, textAlign: 'center' }}>
                Please wait while the activity is being deleted.
              </DialogContentText>
            </Box>
          </DialogContent>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <CardAction
        icon={<DeleteIcon />}
        actionHandler={() => { handleClickOpen(); }}
        className={`top-right ${className}`}
      />
      <Dialog open={state.dialogOpen} aria-labelledby='delete-lesson'>
        <DialogContent>
          {getDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={state.isDeleting} color='primary' className='btn-primary-rounded-border'>
            Cancel
          </Button>
          <ButtonWithSpinner
            label='Ok'
            loading={state.isDeleting}
            onClick={handleOk}
            color='primary'
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteLessonAction.defaultProps = {
  onOpenDeleteLesson: () => { },
  onCloseDeleteLesson: () => { },
  className: '',
};

DeleteLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  onOpenDeleteLesson: PropTypes.func,
  onCloseDeleteLesson: PropTypes.func,
  className: PropTypes.string,
};

import { usePrepareData, useHandleSelectedDistrict, useSchoolTable, useHandleSchoolTable, /* useSchoolBatchUpload, */ useGetSchoolData } from './SchoolsHooks';
import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
// import AddSchoolAction from './actions/AddSchoolAction';
// import UpdateSchoolAction from './actions/UpdateSchoolAction';
import SearchDistrictAction from '../../components/actions/search-district/SearchDistrictAction';
import ExportStudentsAction from './actions/ExportStudentsAction';
import ExportTeachersAction from './actions/ExportTeachersAction';
// import ImportLicensesAction from '../../components/actions/import/import-license/ImportLicensesAction';
import '../../AdminCommon.scss';
import ViewSchoolAction from './actions/ViewSchoolAction';

const Schools = () => {
  const { tableConfig, tableRef } = useSchoolTable();
  const { getSchoolsDataByDistrictIds, loading } = useGetSchoolData({
    tableRef,
  });
  const { schools } = usePrepareData({
    getSchoolsDataByDistrictIds,
  });
  const { selectedDistrictId, onFilterDistrictChange } = useHandleSelectedDistrict({
    getSchoolsDataByDistrictIds,
  });
  const { selectedSchoolIds, handleOnSelectedRowIds, updateSchoolRef } = useHandleSchoolTable();
  // const { importSchoolBatch } = useSchoolBatchUpload();
  return (
    <div className='admin-tab-schools full-height'>
      <SearchAndSortableTable
        ref={tableRef}
        preToolbarAction={[
          <SearchDistrictAction
            key='admin-school-search-district'
            filterValue={selectedDistrictId}
            onFilterChange={onFilterDistrictChange}
          />,
        ]}
        config={tableConfig}
        tableData={schools}
        initTableState={{
          order: 'desc',
          orderBy: 'name',
        }}
        searchPlaceholder='Filter by School Name'
        searchField='name'
        dataId='id'
        selectedRowIds={selectedSchoolIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        loading={loading}
        toolbarActions={[
          // <AddSchoolAction
          //   key='admin-add-school-action'
          //   onSchoolAddSuccess={(params) => getSchoolsDataByDistrictIds(params, false, true)}
          // />,
          // <UpdateSchoolAction
          //   key='admin-update-school-action'
          //   ref={updateSchoolRef}
          //   onSchoolUpdateSuccess={(params) => getSchoolsDataByDistrictIds(params, false, true)}
          // />,
          // selectedDistrictId !== null && selectedDistrictId !== undefined ? (
          //   <ImportLicensesAction
          //     key='admin-school-import-licenses'
          //     id='admin-school-import-licenses-id'
          //     uploadServiceFunction={importSchoolBatch}
          //     ariaLabel='Import School'
          //     uploadingMessage='Uploading Schools.'
          //     onUploadSuccess={async () => { getSchoolsDataByDistrictIds(selectedDistrictId, false, true); }}
          //     failedToUploadTitle='Failed schools'
          //   />
          // ) : null,
          <ViewSchoolAction
            key='admin-view-school-action'
            ref={updateSchoolRef}
          />,
          <ExportTeachersAction key='admin-school-export-teachers' />,
          <ExportStudentsAction key='admin-school-export-students' />,
        ]}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Schools;

import PIXI_SOUND from 'pixi-sound';
// eslint-disable-next-line import/no-cycle
import { audio, hasRegistered } from '../../../services/MediaRegistrationService';
import Logger from '../../../utils/Logger';

let sound = null;
let currentPromise = null;

export const playRegisteredAudio = () => {
  const promise = new Promise((resolve) => {
    audio.src = '';
    audio.src = '/media/register.mp3';
    audio.currentTime = 0;
    audio.muted = true;
    const p = audio.play();
    if (p) {
      // eslint-disable-next-line prefer-promise-reject-errors
      p.catch((e) => {
        audio.src = null;
        Logger.logError(e);
        resolve();
      });
    }
    audio.onended = () => {
      audio.src = null;
      resolve();
    };
  });
  return promise;
};

export const playAudio = (audioUrl, onStart = () => { }, { rate, volume } = { rate: 1, volume: 1 }) => {
  const promise = new Promise((resolve, reject) => {
    currentPromise = {
      resolve,
      reject,
    };
    if (!hasRegistered) {
      const t = setTimeout(() => {
        clearTimeout(t);
        resolve();
      }, 3000);
      return;
    }

    let timeout;
    try {
      sound = PIXI_SOUND.Sound.from({
        url: audioUrl,
        autoPlay: true,
        singleInstance: true,
        complete: () => {
          clearTimeout(timeout);
          resolve();
        },
        loaded: () => {
          clearTimeout(timeout);
          onStart();
        },
        speed: rate,
        volume,
      });

      // If the audio cannot be start within 5 seconds.
      timeout = setTimeout(() => {
        if (!sound.isPlaying) {
          clearTimeout(timeout);
          resolve();
        }
      }, 5000);
    } catch (e) {
      resolve(e);
    }
  });
  return promise;
};

export const stopAudio = () => {
  if (sound) {
    sound.stop();
  }
  if (currentPromise) {
    currentPromise.reject({
      isCancel: true,
    });
  }

  PIXI_SOUND.stopAll();
};

export const isAudioPlaying = () => sound && sound.isPlaying;

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../states/AppDomainProvider';
import Logger from '../../../utils/Logger';
import { validateKey } from '../../../services/MfaService';
import { usePrepareData } from '../LoginHooks';
import ObjectUtils from '../../../utils/ObjectUtils';
import { URL_MY_CLASSROOM } from '../../../routes/RouteConstants';
import { MY_CLASSROOM_ROUTES } from '../../myclassroom/MyClassRoomConstant';

export const useHandleSnackbar = () => {
  const { uiDomain } = useDomain();
  const {
    snackbar,
  } = uiDomain.domainData;

  const onSnackbarClose = () => {
    uiDomain.closeSnackbar();
  };

  return {
    snackbar,
    onSnackbarClose,
  };
};

export const useHandleCodeValue = () => {
  const [value, setValue] = useState('');

  const handleOnTextChange = (e) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(e.target.value)) {
      setValue(e.target.value);
    }
  };

  return {
    value,
    handleOnTextChange,
  };
};

export const useSubmitCode = () => {
  const [showError, setShowError] = useState(false);
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const [slide, setSlide] = useState({
    in: true,
    direction: 'left',
  });

  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const {
    prepareDataForTeacher,
    redirectUserToLandingPage,
  } = usePrepareData();

  const location = useLocation();
  const { redirecTo } = location.state || location;
  const navigate = useNavigate();

  const login = async (code) => {
    try {
      setShowError(false);
      await callRemoteServiceWrapper(async () => {
        const result = await validateKey(user.userName, parseInt(code, 10));
        if (result) {
          const { students } = await prepareDataForTeacher({ ...user, mfaLoggedIn: true });
          setSlide({
            in: false,
            direction: 'right',
          });
          ObjectUtils.setTimeout(() => {
            setSlide({
              in: false,
              direction: 'right',
            });

            if (redirecTo) {
              navigate(
                redirecTo.startsWith('/') ? redirecTo : `/${redirecTo}`,
                {
                  state: {
                    exitPath: `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.MY_LESSONS}`,
                  },
                },
              );
            } else {
              redirectUserToLandingPage(students, user);
            }
          }, 1000);
        } else {
          setShowError(true);
        }
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    showError,
    loading,
    login,
    slide,
  };
};

// import LandingStudent from '../../containers/landing/LandingStudent';
import LandingStudentV2 from '../../containers/landing/LandingStudentV2';
import LoginForRethink from '../../containers/login/LoginForRethink';
import ThemeLessonSelection from '../../containers/theme-lesson-selection/ThemeLessonSelection';

import {
  URL_LANDING_STUDENT,
  URL_LESSON_SELECTION,
  URL_LOGIN_RETHINK,
} from '../RouteConstants';

const getStudentRoutes = () => [
  { path: URL_LANDING_STUDENT, element: <LandingStudentV2 /> },
  { path: URL_LESSON_SELECTION, element: <ThemeLessonSelection /> },
  { path: URL_LOGIN_RETHINK, element: <LoginForRethink /> },
];

export default getStudentRoutes;

/* eslint-disable no-unused-vars */
import { isPaidAccount } from '../utils/User';

export default {
  initChurnZero: (user, userProfile) => {
    // if (isPaidAccount(user)) {
    //   // window.ChurnZero.debug();
    //   window.ChurnZero.push(['setAppKey', user.churnzeroKey]);
    //   window.ChurnZero.push(['setContact', user.externalAccountId, user.userName]);
    //   window.ChurnZero.push(['setModule', 'Vizzle', 'Vizzle User Login']);
    //   window.ChurnZero.push(['setAttribute', 'account', 'Vizzle', true]);
    //   window.ChurnZero.push(['setAttribute', 'Contact', {
    //     firstName: user.firstName,
    //     lastName: user.lastName,
    //     emailAddress: userProfile.emailAddress,
    //     Activevizzleuser: userProfile.activeFlag,
    //     vizzle_role: user.adminCommunication ? 'Admin' : 'Teacher',
    //   }]);

    //   // Turn on debug mode
    //   // window.ChurnZero.verify();
    // }
  },

  trackEvent: (eventName, description, user) => {
    // if (isPaidAccount(user)) {
    //   window.ChurnZero.push(['trackEvent', eventName, description]);
    // }
  },
};

import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { hideAccessibeChatIcon, showAccessibeChatIcon } from '../../services/AccessibeService';
import NewVizzleIframe from '../../components/vizzle/container/NewVizzleIframe';
import { useDomain } from '../../states/AppDomainProvider';
import LessonPlay from '../lessonplay/LessonPlay';
import { useUpdateLessonStartIndex } from '../lessonplay/activities/utils/LessonPlayUtils';
import { initSwitchAccessDomains, useSwitchAccess } from '../switch-access/context/SwitchAccessContextProvider';
import CelebrationService from '../terminologies/celebration/CelebrationService';
import CelebrationDialog from '../terminologies/celebration/CelebrationDialog';
import NotificationDialog from '../../components/dialog/NotificationDialog';
import { useHandleSessionExpired } from '../hooks/HandleSessionExpiredHook';

const LandingStudentV2 = () => {
  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const [open, setOpen] = useState(false);
  const [lesson, setLesson] = useState();

  const updateLessonStartIndex = useUpdateLessonStartIndex();
  useEffect(() => {
    showAccessibeChatIcon();
    return () => {
      hideAccessibeChatIcon();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  initSwitchAccessDomains();
  const { switchAccessDomain } = useSwitchAccess();
  useEffect(() => {
    const register = (e) => {
      const { data } = e;
      if (data.event === 'play-lesson') {
        setLesson(data.data);
        setOpen(true);
      } else if (data.event === 'has-dialog-shown') {
        switchAccessDomain.updateShowDialog(false, data.data.hasDialogShown);
      } else if (data.event === 'show-celebration-dialog') {
        CelebrationService.playCelebration(data.data);
      }
    };
    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newVizzleIframeRef = useRef();
  const {
    notificationDialog,
    onNotificationDialogClose,
  } = useHandleSessionExpired();

  return (
    <>
      <Box width='100%' height='100vh' overflow='hidden'>
        <NewVizzleIframe
          path='/interim-student'
          payload={{}}
          ref={newVizzleIframeRef}
        />
      </Box>
      <CelebrationDialog
        celebrationList={terminologies.celebrations}
        enableAudio={userProfile.celebrationSound}
      />
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          onLessonFinished={async (isLessonComplete) => {
            await updateLessonStartIndex(isLessonComplete, lesson.attributes.uuid, userProfile);
            newVizzleIframeRef.current.postMessageToIFrame('update-user-profile', userProfile);
            newVizzleIframeRef.current.postMessageToIFrame('lesson-finished');
          }}
          lesson={lesson}
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
          onChatWidgetOpen={() => {
            let playedLesson = null;
            for (let i = 0; i < userProfile.subjects.length; i++) {
              const s = userProfile.subjects[i];
              // eslint-disable-next-line no-loop-func
              playedLesson = s.lessons.find((l) => l.uuid === lesson.attributes.uuid);
              if (playedLesson) {
                break;
              }
            }
            playedLesson.unreadChat = false;
          }}
        />
      )}
      <NotificationDialog {...notificationDialog} onClose={onNotificationDialogClose} />
    </>
  );
};

export default LandingStudentV2;

import PropTypes from 'prop-types';
import FilterableMultiSelector from '../../../../../components/selector/FilterableMultiSelector';
import './SearchDistrictAction.scss';
import { usePrepareData } from './SearchDistrictActionHooks';

const SearchDistrictAction = ({
  onFilterChange,
  filterValue,
  isMultiple,
  className,
  popperClassName,
  disabled,
  ableToUnselectedInSingleMode,
}) => {
  const {
    transformDistricts,
    loading,
  } = usePrepareData();

  const handleMultiSelectorApply = (value) => {
    if (value && filterValue && value.key === filterValue) {
      return;
    }
    onFilterChange(value);
  };

  return (
    <FilterableMultiSelector
      popperClassName={popperClassName}
      className={`search-district-action ${className}`}
      key='filter-selector-district'
      onApply={(value) => {
        handleMultiSelectorApply(value);
      }}
      label='Please select district'
      initFilterKeyValues={
        Array.isArray(filterValue) ? filterValue : filterValue && [filterValue]
      }
      options={transformDistricts}
      filterable
      isMultiple={isMultiple}
      subTitle='Filter District'
      disabled={disabled || transformDistricts.length <= 1}
      loading={loading}
      ableToUnselectedInSingleMode={ableToUnselectedInSingleMode}
    />
  );
};

SearchDistrictAction.defaultProps = {
  onFilterChange: () => { },
  filterValue: null,
  isMultiple: false,
  className: '',
  popperClassName: '',
  disabled: false,
  ableToUnselectedInSingleMode: false,
};

SearchDistrictAction.propTypes = {
  onFilterChange: PropTypes.func,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isMultiple: PropTypes.bool,
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  disabled: PropTypes.bool,
  ableToUnselectedInSingleMode: PropTypes.bool,
};

export default SearchDistrictAction;

/* eslint-disable max-len */
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import ColorPicker from './custom/ColorPicker';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';
import { FONT_FORMAT_LIST, FONT_SIZE_LIST } from '../../constants/TextEditorConstants';
import ObjectUtils from '../../utils/ObjectUtils';

/**
 * A Wysiwyg text editor
 */
export default function TextEditor({
  focus,
  editorState,
  onEditorStateChange,
  onApply,
  onRemove,
  disabledApply,
  disabledRemove,
  className,
  disabled,
  fontFamilyList,
  additionalActionChildren,
  includeInlineOption,
  includeTextAlign,
  toolbarCustomButtons,
  showApplyButton,
  showRemoveTextButton,
  stripPastedStyles,
  selectAll,
}) {
  const editorRef = useRef(null);

  const setEditorReference = (ref) => {
    editorRef.current = ref;
    if (focus && ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    if (editorRef.current && selectAll) {
      editorRef.current.focus();
      ObjectUtils.setTimeout(() => {
        document.execCommand('selectAll', false, null);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  const options = ['fontFamily', 'fontSize'];
  if (includeInlineOption) {
    options.push('inline');
  }

  options.push('colorPicker');
  if (includeTextAlign) {
    options.push('textAlign');
  }
  return (
    <div className={`text-editor-component-root ${className}`}>
      <Editor
        readOnly={disabled}
        editorState={editorState}
        editorRef={setEditorReference}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName='text-editor-component'
        editorClassName='text-editor-editor'
        stripPastedStyles={stripPastedStyles}
        // handlePastedText={() => false}
        toolbar={{
          options,
          inline: {
            options: FONT_FORMAT_LIST,
            bold: { className: 'bordered-option-classname' },
            italic: { className: 'bordered-option-classname' },
            underline: { className: 'bordered-option-classname' },
          },
          colorPicker: {
            popupClassName: 'text-editor-color-picker',
            component: ColorPicker,
          },

          blockType: {
            className: 'bordered-option-classname',
          },
          fontSize: {
            options: FONT_SIZE_LIST,
            className: 'bordered-option-classname text-editor-font-size-display',
          },
          fontFamily: {
            options: [
              ...fontFamilyList,
            ],
            className: 'text-editor-font-family-display',
            dropdownClassName: 'bordered-option-classname text-editor-font-family-list',
          },
          textAlign: {
            left: { className: 'text-editor-align-left' },
            center: { className: 'text-editor-align-center' },
            right: { className: 'text-editor-align-right' },
            justify: { className: 'text-editor-align-justify' },
          },
        }}
        toolbarCustomButtons={toolbarCustomButtons}
      />
      <div className='text-editor-actions'>
        <div className='additional-actions-container'>
          {additionalActionChildren}
        </div>
        <div className='actions-container'>
          {showApplyButton && (
            <Button
              variant='contained'
              className='btn-primary margin-right-medium vizzle-text-editor-apply'
              onClick={onApply}
              disabled={disabledApply || disabled}
              data-test='apply-text-action'
            >
              Apply
            </Button>
          )}

          {showRemoveTextButton && (
            <Button
              variant='contained'
              className='btn-primary vizzle-text-editor-remove'
              disabled={disabledRemove || disabled}
              onClick={onRemove}
              data-test='remove-text-action'
            >
              Remove
            </Button>
          )}

        </div>
      </div>
    </div>
  );
}

TextEditor.defaultProps = {
  focus: false,
  onEditorStateChange: () => { },
  onApply: () => { },
  onRemove: () => { },
  editorState: null,
  disabledApply: false,
  disabledRemove: false,
  className: '',
  disabled: false,
  fontFamilyList: [],
  additionalActionChildren: null,
  includeInlineOption: true,
  includeTextAlign: true,
  toolbarCustomButtons: [],
  showApplyButton: true,
  showRemoveTextButton: true,
  selectAll: false,
  stripPastedStyles: true,
};

TextEditor.propTypes = {
  focus: PropTypes.bool,
  onEditorStateChange: PropTypes.func,
  onApply: PropTypes.func,
  onRemove: PropTypes.func,
  // eslint-disable-next-line
  editorState: PropTypes.any,
  disabledApply: PropTypes.bool,
  disabledRemove: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fontFamilyList: PropTypes.arrayOf(PropTypes.string),
  additionalActionChildren: PropTypes.node,
  includeInlineOption: PropTypes.bool,
  includeTextAlign: PropTypes.bool,
  toolbarCustomButtons: PropTypes.arrayOf(PropTypes.node),
  showApplyButton: PropTypes.bool,
  showRemoveTextButton: PropTypes.bool,
  selectAll: PropTypes.bool,
  stripPastedStyles: PropTypes.bool,
};

import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getCorrectPercentages } from '../../../../../utils/MasterObjectiveMapping';

const ObjectiveLineChart = ({
  objective,
  included,
  maxXAxis,
}) => {
  const { attributes } = objective;
  const plotColor = objective.attributes.masteredStatus === 'criteria-met' ? '#5DB572' : '#000000';
  const correctPercentages = getCorrectPercentages(objective, included, maxXAxis);
  const maxX = maxXAxis || correctPercentages.length < 10 ? 10 : correctPercentages.length;

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%' } }}
      options={{
        title: {
          text: '',
        },
        legend: { enabled: false },
        credits: {
          enabled: false,
        },
        xAxis: {
          visible: false,
          max: maxX,
          min: 0,
        },
        yAxis: {
          max: 100,
          min: 0,
          plotLines: [{
            color: '#BFBEBF',
            width: 2,
            value: attributes.percentCorrect,
            dashStyle: 'dash',
          }],
          title: '',
        },
        plotOptions: {
          series: {
            color: plotColor,
            tooltip: false,
          },
        },
        tooltip: {
          // eslint-disable-next-line func-names, object-shorthand
          formatter: function () {
            // eslint-disable-next-line react/no-this-in-sfc
            const { options: { lessonName, playDate, y } } = this.point;
            const lesson = lessonName ? `Activity : <b>${lessonName}</b><br/>` : '';
            const playDateString = playDate ? `Played On : <b>${playDate}</b><br/>` : '';
            const scoreString = y ? `Score : <b> ${y} </b>` : '';

            return `${lesson}${playDateString}${scoreString}`;
          },
        },
        series: [{
          data: correctPercentages,
        }],
      }}
    />
  );
};

ObjectiveLineChart.defaultProps = {
  objective: {},
  included: [],
  maxXAxis: null,
};

ObjectiveLineChart.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    specialtyContent: PropTypes.object,
  }),
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  maxXAxis: PropTypes.number,
};

export default ObjectiveLineChart;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FULL_SORTED_SUBJECT_CONSTANTS } from '../../../../../constants/LessonConstant';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';

export const useGetLessonData = ({
  excludeLessonTypes,
  includeLessonTypes,
  subjectTitle,
}) => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonData } = teacherCourseDomain.domainData;
  const { hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;

  const { lessons, included } = lessonData;
  const filteredLessonsBySubject = lessons.map((lessonBySubject) => {
    const { lessons: lessonsInSubject } = lessonBySubject;

    let filteredLessons = lessonsInSubject;
    if (excludeLessonTypes && excludeLessonTypes.length > 0) {
      filteredLessons = filteredLessons.filter((lesson) => !excludeLessonTypes.includes(lesson.attributes.lessonType));
    }

    if (includeLessonTypes && includeLessonTypes.length > 0) {
      filteredLessons = filteredLessons.filter((lesson) => includeLessonTypes.includes(lesson.attributes.lessonType));
    }

    return {
      subject: subjectTitle || lessonBySubject.subject,
      lessons: filteredLessons,
    };
  });

  const { subjectId } = useParams();
  const sn = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => Number(s.subjectId) === Number(subjectId));
  const currentSelectedSubjectName = sn ? sn.name : '';

  return {
    hasPerformSearch,
    filteredLessonData: {
      lessons: filteredLessonsBySubject,
      included,
    },
    currentSelectedSubjectName,
  };
};

export const useHandleTeacherCourseAssigment = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const {
    selectedDates,
    lessonCourses,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
  } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const handleOnSelectedDatesApply = (internalSelectedDates) => teacherCourseDomain.updateSelectedDates(internalSelectedDates);
  const handleOnStartLoading = () => teacherCourseDomain.updateAssignedStudentsCoursesLoading(true);

  const course = (lessonCourses || []).find((lc) => lc.id === searchCriteria.courseId) || {};
  const subjectId = course.subject;

  const onDataLoad = (data) => teacherCourseDomain.updateAssignedStudentsCourses(data);

  const handleOnFilterCriteriaAssignedStudentsCoursesUpdate = (updatedFilter) => teacherCourseDomain.updateFilterCriteriaAssignedStudentsCourses(updatedFilter);
  return {
    selectedDates,
    handleOnSelectedDatesApply,
    handleOnStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
    handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  };
};

export const useLessonSelect = () => {
  const { teacherCourseDomain } = useTeacherCourses();

  useEffect(() => async () => {
    await teacherCourseDomain.updateSelectedLessonIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnLessonSelect = async (lesson) => {
    const { selectedLessonIds } = teacherCourseDomain.domainData;
    const matchedLessonId = selectedLessonIds.findIndex((selectedId) => selectedId === lesson.id);
    let updatedSelectedIds = [...selectedLessonIds];
    if (matchedLessonId >= 0) {
      updatedSelectedIds = selectedLessonIds.filter((selectedId) => selectedId !== lesson.id);
    } else {
      updatedSelectedIds.push(lesson.id);
    }

    await teacherCourseDomain.updateSelectedLessonIds(updatedSelectedIds);
  };

  const handleOnLessonClear = async () => {
    await teacherCourseDomain.updateSelectedLessonIds([]);
  };

  return {
    handleOnLessonSelect,
    handleOnLessonClear,
    selectedLessonIds: teacherCourseDomain.domainData.selectedLessonIds,
  };
};

export const useGetCourse = (courseId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  if (!lessonCourses || !courseId) {
    return null;
  }
  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  return course;
};

export const useGetUnit = (courseId, unitId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  if (!lessonCourses || !courseId) {
    return [];
  }

  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  const { units } = course;
  return units.find((u) => u.id === unitId);
};

export const useGetTopic = (courseId, unitId, topicId) => {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  if (!lessonCourses || !courseId) {
    return [];
  }

  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  const { units } = course;
  const unit = (units || []).find((u) => u.id === unitId);
  if (!unit) {
    return null;
  }
  const { topics } = unit;
  const topic = (topics || []).find((t) => t.id === topicId);
  return topic;
};

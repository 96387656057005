import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './ToggleForm.scss';

const ToggleForm = ({
  value,
  handleOnChange,
  cssClassName,
  disabled,
  onLabel,
  offLabel,
  ...rest
}) => (
  <ToggleButtonGroup {...rest} size='small' value={value} onChange={handleOnChange} exclusive className={`setting-toggle ${cssClassName}`}>
    <ToggleButton disabled={disabled} value>
      {onLabel}
    </ToggleButton>
    <ToggleButton disabled={disabled} value={false}>
      {offLabel}
    </ToggleButton>
  </ToggleButtonGroup>
);

ToggleForm.defaultProps = {
  value: false,
  handleOnChange: () => { },
  cssClassName: '',
  disabled: false,
  onLabel: 'On',
  offLabel: 'Off',
};

ToggleForm.propTypes = {
  value: PropTypes.bool,
  handleOnChange: PropTypes.func,
  cssClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
};

export default ToggleForm;

import { useMemo } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useAdmin } from '../../../../context/AdminContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { searchDataByCriteria } from '../../../../../../services/AdminService';
import Logger from '../../../../../../utils/Logger';

export const useSearchTeacher = () => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { searchTeacherDomain } = useAdmin();

  const searchTeacherByCriteria = async (criteria) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await searchDataByCriteria('teacher', criteria);
        searchTeacherDomain.updateCriteriaAndTeacherList(criteria, result);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      searchTeacherDomain.updateCriteriaAndTeacherList(criteria, {
        data: [],
        included: [],
      });
    }
  };

  return {
    searchTeacherByCriteria,
    loading,
  };
};

export const useSearchTeacherTable = () => {
  const tableConfig = [
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '10%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '11%',
    },
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '13%',
    },
    {
      title: 'District',
      align: 'left',
      dataField: 'vizzleDistrictName',
      width: '18%',
    },
    {
      title: 'School',
      align: 'left',
      dataField: 'school',
      width: '18%',
    },
    {
      title: 'License Expiration',
      align: 'center',
      dataField: 'expirationDate',
      width: '10%',
    },
    {
      title: 'License Type',
      align: 'left',
      dataField: 'licenseType',
      width: '7%',
    },
  ];

  const tableConfigForTable = [
    ...tableConfig,
    {
      title: 'Active',
      align: 'center',
      dataField: 'active',
      width: '5%',
      dataRenderer: (data) => {
        if (data.active) {
          return <CheckBoxIcon className='checkbox-icon' />;
        } else {
          return <CheckBoxOutlineBlankIcon className='checkbox-icon' />;
        }
      },
    },
  ];

  const tableConfigForCsv = [
    ...tableConfig,
    {
      title: 'Active',
      align: 'center',
      dataField: 'active',
      width: '10%',
      dataRenderer: (data) => {
        if (data.active) {
          return 'Y';
        } else {
          return 'N';
        }
      },
    },
  ];

  const { searchTeacherDomain } = useAdmin();
  const { teacherList, hasLoaded } = searchTeacherDomain.domainData;

  const tableData = useMemo(() => {
    const { data, included } = teacherList;
    const result = data.map((d) => {
      const mappedData = {
        id: d.id,
        ...d.attributes,
      };

      const { relationships: { school, license } } = d;
      const schoolRelationshipData = school.data;
      const licenseRelationshipData = license.data;

      const schoolObjectData = included.find((b) => b.id.toString() === schoolRelationshipData.id);
      if (schoolObjectData) {
        mappedData.school = schoolObjectData.attributes.name;
        mappedData.vizzleDistrictName = schoolObjectData.attributes.vizzleDistrictName;
      }

      const licenseObjectData = included.find((l) => l.id.toString() === licenseRelationshipData.id);
      if (licenseObjectData) {
        mappedData.expirationDate = licenseObjectData.attributes.expirationDate;
        mappedData.licenseType = licenseObjectData.attributes.type;
        mappedData.active = licenseObjectData.attributes.active;
      }
      return mappedData;
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherList]);

  return {
    tableConfigForTable,
    tableConfigForCsv,
    tableData,
    hasLoaded,
  };
};

export const useHandleSearch = ({
  searchTeacherByCriteria,
}) => {
  const { searchTeacherDomain } = useAdmin();
  const { criteria } = searchTeacherDomain.domainData;

  const handleOnSearchClick = (searchCriteria) => {
    searchTeacherByCriteria(searchCriteria);
  };

  const handleOnSearchClear = (searchCriteria) => {
    searchTeacherDomain.updateCriteria(searchCriteria);
  };

  return {
    criteria,
    handleOnSearchClick,
    handleOnSearchClear,
  };
};

export const useHandleTeacherTable = () => {
  const { searchTeacherDomain } = useAdmin();
  const { selectedTeacherIds, teacherList } = searchTeacherDomain.domainData;

  const handleOnSelectedRowIds = (rowIds) => {
    searchTeacherDomain.updateSelectedTeacherIds(rowIds);
  };

  const selectedTeachers = teacherList.data.filter((t) => selectedTeacherIds.includes(t.id));

  return {
    selectedTeacherIds,
    handleOnSelectedRowIds,
    selectedTeachers,
  };
};

export const useGetDefaultDistrict = () => {
  const { districtsDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  if (districts && districts.length === 1) {
    const district = districts[0];
    const defaultDistrict = {
      key: district.id,
      name: district.name,
    };
    return defaultDistrict;
  }
  return undefined;
};

import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VizzleAppbar from '../../../../components/appbar/VizzleAppbar';
import './LessonActivityTopBar.scss';
import { useRegisterSwitchAccessScanEvent } from '../../../switch-access/SwitchAccessHooks';
import { useScreenReader } from '../../../screen-reader/ScreenReaderHooks';

/**
 * Play Lesson Action Container
 */
const LessonActivityTopBar = forwardRef(({
  currentPage,
  totalPage,
  onExit,
  showExitButton,
  createAdditionalComponents,
}, ref) => {
  const exitButtonRef = useRef(null);

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  useImperativeHandle(ref, () => ({
    getEnableElements: () => (exitButtonRef.current ? [exitButtonRef.current] : []),
  }));

  useRegisterSwitchAccessScanEvent(exitButtonRef, () => {
    readWhenScreenReaderIsOn(exitButtonRef.current);
  });

  return (
    <VizzleAppbar appbarPosition='top' className='lesson-activity-top-bar'>
      <Toolbar className='lesson-activity-toolbar'>
        <Typography variant='h6' className='title' data-test='page-location'>
          Page {currentPage} of {totalPage}
        </Typography>
        {showExitButton && (
          <div className='button-container'>
            {createAdditionalComponents(currentPage)}
            <Box width='170px'>
              <Button
                ref={exitButtonRef}
                fullWidth
                className='exit-button'
                endIcon={<CloseIcon />}
                onClick={onExit}
                alt='Exit Activity'
                data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
                data-test='lesson-play-exit-action'
              >
                Exit Activity
              </Button>
            </Box>
          </div>
        )}
      </Toolbar>
    </VizzleAppbar>
  );
});

export default LessonActivityTopBar;

LessonActivityTopBar.defaultProps = {
  totalPage: 0,
  currentPage: 0,
  onExit: () => { },
  showExitButton: true,
  createAdditionalComponents: null,
};

LessonActivityTopBar.propTypes = {
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  onExit: PropTypes.func,
  showExitButton: PropTypes.bool,
  createAdditionalComponents: PropTypes.func,
};

import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import './MultiSelector.scss';

/**
 * Select components are used for collecting user provided information from a list of options.
 */
export default function MultiSelector({
  selectorItems,
  values,
  className,
  onChange,
  disabled,
  placeholderWhenEmpty,
  ...props
}) {
  return selectorItems && selectorItems.length > 0 ? (
    <Select
      multiple
      value={values}
      variant='outlined'
      autoWidth
      onChange={onChange}
      className={`multi-selector ${className}`}
      data-private
      renderValue={(selected) => selected.join(', ')}
      disabled={disabled}
      {...props}
    >
      {selectorItems && selectorItems.length > 0 && selectorItems.map((object) => (
        <MenuItem style={{ whiteSpace: 'normal' }} key={object.value} value={object.value}>
          <Checkbox checked={values.indexOf(object.value) > -1} />
          <ListItemText data-private primary={object.name} />
        </MenuItem>
      ))}
    </Select>
  ) : (
    <Select
      value=''
      variant='outlined'
      autoWidth
      displayEmpty
      className={`multi-selector ${className} multi-selector-diabled`}
      disabled
    >
      <MenuItem value='' disabled>{placeholderWhenEmpty}</MenuItem>
    </Select>
  );
}

MultiSelector.defaultProps = {
  values: [],
  className: '',
  onChange: () => { },
  disabled: false,
  placeholderWhenEmpty: '',
};

MultiSelector.propTypes = {
  selectorItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholderWhenEmpty: PropTypes.string,
};

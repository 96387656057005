import { useEffect } from 'react';
import { getSchoolsByDistrictId } from '../../../../../services/AdminService';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import Logger from '../../../../../utils/Logger';
import { sort } from '../../../../../utils/ArrayUtils';

const transformSchoolData = (item) => ({
  key: item.id,
  name: item.attributes.name,
  value: item.attributes.name,
});

export const usePrepareData = ({
  districtId,
  schoolsData,
  onSchoolsLoaded,
}) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const getSchoolsDataByDistrictIds = async () => {
    if (!districtId) {
      return;
    }

    if (schoolsData && schoolsData.length > 0) {
      const sampleSchoolData = schoolsData[0];
      if (districtId === sampleSchoolData.relationships.district.data.id) {
        return;
      }
    }
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getSchoolsByDistrictId(districtId);
        if (result) {
          onSchoolsLoaded(result.data, districtId);
        } else {
          onSchoolsLoaded([], districtId);
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    getSchoolsDataByDistrictIds();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtId]);

  const transformedSchools = schoolsData && schoolsData.length > 0 ? schoolsData.map((s) => transformSchoolData(s)) : [];
  return {
    loading,
    transformedSchools: sort(transformedSchools, 'name'),
  };
};

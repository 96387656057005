import { useDomain } from '../../../../../states/AppDomainProvider';
import { isTexasTeacher, isFloridaTeacher } from '../../../../../utils/User';
import videoPoster from '../../../../../static/images/curriculum-video-image.png';
import './TeacherCurriculumInstruction.scss';

const TeacherCurriculumInstruction = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  let videoLink = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/Non-Texas/Assign+and+Track.mp4`;
  let instructionalText = '';
  if (isTexasTeacher(user)) {
    videoLink = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/Texas/CurriculumOverviewTX.mp4`;
    instructionalText = 'Our curriculum was built by Lubbock ISD, and is designed specifically for Texas students.';
  } else if (isFloridaTeacher(user)) {
    videoLink = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/Florida/FL Curriculum Overview.mp4`;
    instructionalText = 'Our curriculum was built by Pinellas Schools, and is designed specifically for Florida students.';
  }

  return (
    <div className='teacher-curriculum-instruction'>
      <p className='no-top-margin no-bottom-margin main-heading'>
        To get started, choose a Subject, Grade, and Section from the filters above.
      </p>
      <p className='no-bottom-margin subtitle-heading'>
        {instructionalText}
      </p>
      <p className='no-top-margin subtitle-heading'>
        Watch this video to learn more!
      </p>
      <div className='video-container'>
        <video
          poster={videoPoster}
          controls
          src={videoLink}
          type='video/mp4'
        >
          <track kind='captions' src={videoLink} />
        </video>
      </div>
    </div>
  );
};

export default TeacherCurriculumInstruction;

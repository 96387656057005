import { useState } from 'react';
import PropTypes from 'prop-types';
import DropdownInput from '../../../../../../../../../../components/selector/DropdownInput';
import { FONT_FAMILY_LIST } from '../../../../../../../../../../constants/TextEditorConstants';
import { useApplyFontStyle } from '../../ApplyTextStyleActionHooks';
import EntryContainer from '../EntryContainer';
import './Form.scss';

const fontFamilyListOptions = FONT_FAMILY_LIST.map((s) => ({
  id: s,
  name: s,
}));

export default function FontFamilyForm({ applyToActivities }) {
  const [value, setValue] = useState('');

  const {
    applyFontStyle,
  } = useApplyFontStyle({
    applyToActivities,
  });
  return (
    <EntryContainer
      data-test='font-family-form'
      label='Font Family'
      onApply={async () => {
        await applyFontStyle([{
          fieldName: 'fontFamily',
          value,
        }]);
        setValue('');
      }}
      disabled={!value}
    >
      <DropdownInput
        dropdownValues={fontFamilyListOptions}
        name='font'
        value={value}
        className='text-selector-dropdown'
        onChange={async (e) => {
          setValue(e.target.value);
        }}
      />
    </EntryContainer>
  );
}

FontFamilyForm.propTypes = {
  applyToActivities: PropTypes.string.isRequired,
};

/** Convert jsx inline style (camel case) to css style (kebab case) */
const jsxStyleToCss = (string) => (
  string
    .replace(/"/g, '')
    .replace(/,/g, ';')
    .replace(/{/g, '')
    .replace(/}/g, '')
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase()
);

const cssToJsxStyle = (string) => (
  string.trim().replace(/(-\w)/g, (m) => (m[1].toUpperCase()))
);

const removeOpenAndCloseTag = (string) => (
  string
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
);

const convertStyleObjectToStyleString = (style) => {
  let result = '';
  Object.keys(style).forEach((s) => {
    result = `${result} ${jsxStyleToCss(s)}: ${style[s]};`;
  });
  return result;
};

/**
 * Convert object of vizze text line into html format
 */
export const convertVizzleTextLineToHtml = (textLines, fontFamilyList, defaultFontFamily) => {
  let result = '';
  textLines.lines.forEach((line) => {
    const { elements, ...style } = line;
    const pTag = document.createElement('p');
    const { fontFamily, ...styleWithoutFontFamily } = style;
    const styleString = convertStyleObjectToStyleString(styleWithoutFontFamily);
    pTag.setAttribute('style', styleString);

    // Convert font weight, font style and text decoration to
    // strong, em and ins tag as draft js does not accept these styles
    elements.forEach((e, index) => {
      const spanTag = document.createElement('span');
      const { text, ...elementStyle } = e;
      if (text.trim().length === 0 && pTag.children.length > 0) {
        if (index - 1 < pTag.children.length) {
          const c = pTag.children[index - 1];
          if (c.children && c.children.length > 0) {
            c.children[0].innerHTML = `${c.children[0].innerHTML}${removeOpenAndCloseTag(text)}`;
          } else {
            c.innerHTML = `${c.innerHTML}${removeOpenAndCloseTag(text)}`;
          }
        }
      } else {
        let elementPointer = spanTag;
        if (elementStyle.fontWeight === 'bold') {
          const strong = document.createElement('strong');
          elementPointer.appendChild(strong);
          elementPointer = strong;
        }
        if (elementStyle.fontStyle === 'italic') {
          const em = document.createElement('em');
          elementPointer.appendChild(em);
          elementPointer = em;
        }
        if (elementStyle.textDecoration === 'underline') {
          const ins = document.createElement('ins');
          elementPointer.appendChild(ins);
          elementPointer = ins;
        }

        elementPointer.innerHTML = removeOpenAndCloseTag(text);
        if (!fontFamilyList.includes(elementStyle.fontFamily)) {
          elementStyle.fontFamily = defaultFontFamily;
        }
        const spanStyle = convertStyleObjectToStyleString(elementStyle);
        spanTag.setAttribute('style', spanStyle);

        pTag.appendChild(spanTag);
      }
    });

    result = `${result} ${pTag.outerHTML}`;
  });
  return result;
};

const createStyleObject = (styleElementString) => {
  const styleElements = styleElementString.split(';');
  let result = {};
  styleElements.forEach((s) => {
    if (s) {
      const [key, value] = s.split(':');
      result[cssToJsxStyle(key)] = value.trim();
    }
  });

  const { color } = result;
  if (color) {
    result = {
      ...result,
      color: color.toString(),
    };
  }
  return result;
};

const findElement = (elmentToFind, el) => {
  const element = elmentToFind.getElementsByTagName(el);
  return element && element.length > 0;
};

const defaultLineProps = {
  color: '#000000',
  // fontFamily: 'Quattrocento',
  textAlign: 'left',
};

const defaultElementProps = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  textDecoration: 'none',
};

const removeLineEmpty = (textLines) => {
  const textLineReverse = textLines.reverse();
  for (let i = 0; i < textLineReverse.length; i++) {
    const line = textLineReverse[i].elements;
    if (line.length === 0) {
      textLineReverse.splice(i, 1);
      i--;
    } else {
      const lineReverse = line.reverse();
      for (let j = 0; i < lineReverse.length; j++) {
        if (lineReverse[j].text === '') {
          lineReverse.splice(j, 1);
          j--;
        } else {
          line.reverse();
          return textLineReverse.reverse();
        }
      }
    }
  }
  return textLineReverse.reverse();
};

const removeSpaceBlankText = (elements) => {
  const element = elements.slice(-1)[0];
  if (element) element.text = element.text.trimEnd();
};

const removeSpaceAndLineBlank = (textLines) => {
  const resulTextLines = removeLineEmpty(textLines);
  const lastLine = resulTextLines.slice(-1)[0].elements;
  removeSpaceBlankText(lastLine);
  return resulTextLines;
};

export const convertHtmlToVizzleTextLine = (html) => {
  const htmlLines = html.replace(/&nbsp;/g, ' ').split('\n');
  const lines = [];
  htmlLines.forEach((htmlLine) => {
    if (htmlLine) {
      const htmlObject = new DOMParser().parseFromString(htmlLine, 'text/xml');
      let styleObject = {};
      const pElement = htmlObject.children[0];
      if (pElement.attributes.style) {
        // Style
        styleObject = createStyleObject(pElement.attributes.style.nodeValue);
      }

      const elements = [];
      pElement.childNodes.forEach((span) => {
        let elementStyleObject = {};
        if (span.attributes && span.attributes.style) {
          // Style
          elementStyleObject = createStyleObject(span.attributes.style.nodeValue);
        }

        // No bold, italic and underline
        if (span.childNodes.length === 0) {
          elements.push({
            ...defaultElementProps,
            ...elementStyleObject,
            text: span.data,
          });
        } else {
          // Convert strong, em and ins tag to css style
          span.childNodes.forEach((spanChild) => {
            const elementStyleObjectChild = { ...elementStyleObject };
            if (spanChild.tagName) {
              if (spanChild.tagName === 'strong') {
                elementStyleObjectChild.fontWeight = 'bold';
                const emStyle = findElement(spanChild, 'em');
                const insStyle = findElement(spanChild, 'ins');
                if (emStyle) {
                  elementStyleObjectChild.fontStyle = 'italic';
                }
                if (insStyle) {
                  elementStyleObjectChild.textDecoration = 'underline';
                }
              } else if (spanChild.tagName === 'em') {
                elementStyleObjectChild.fontStyle = 'italic';
                const strongStyle = findElement(spanChild, 'strong');
                const insStyle = findElement(spanChild, 'ins');
                if (strongStyle) {
                  elementStyleObjectChild.fontWeight = 'bold';
                }
                if (insStyle) {
                  elementStyleObjectChild.textDecoration = 'underline';
                }
              } else if (spanChild.tagName === 'ins') {
                elementStyleObjectChild.textDecoration = 'underline';
                const underlineStyle = findElement(spanChild, 'strong');
                const emStyle = findElement(spanChild, 'em');
                if (underlineStyle) {
                  elementStyleObjectChild.fontWeight = 'bold';
                }
                if (emStyle) {
                  elementStyleObjectChild.fontStyle = 'italic';
                }
              }

              elements.push({
                ...defaultElementProps,
                ...elementStyleObjectChild,
                text: spanChild.lastChild.textContent,
              });
            } else {
              elements.push({
                ...defaultElementProps,
                ...elementStyleObjectChild,
                text: spanChild.data,
              });
            }
          });
        }
      });

      if (elements.length > 0) {
        if (elements[0].text.startsWith(' ')) {
          elements[0].text = elements[0].text.substring(1, elements[0].text.length);
        }

        const lastElement = elements.length - 1;
        if (elements[lastElement].text.endsWith(' ')) {
          elements[lastElement].text = elements[lastElement].text.substring(0, elements[lastElement].text.length - 1);
        }
      }
      lines.push({
        ...defaultLineProps,
        ...styleObject,
        elements,
      });
    }
  });
  const resultLines = removeSpaceAndLineBlank(lines);
  return resultLines;
};

import PropTypes from 'prop-types';
import { FormControl, TextField } from '@mui/material';
import { usePasswordValidator } from '../../../../hooks/AdminUtilityHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import {
  useStudentFormControl,
} from './StudentFormHook';
import './AddStudentForm.scss';
import TooltipWithIndicator from '../../../../../../components/tooltip/TooltipWithIndicator';
import { stateIdentifierTooltipText } from '../../../../../../texts/TooltipText';

const AddStudentForm = ({ onDataChange, studentData }) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userAdmin,
    userSuperAdmin,
    schoologyFlag,
  } = user;

  const {
    internalData,
    confirmPassword,
    usernameError,
    handleOnChange,
    validateUsername,
    handleConfirmPassword,
  } = useStudentFormControl({ initData: studentData, onDataChange });

  const {
    validatePassword,
    passwordError,
  } = usePasswordValidator();

  return (
    <div className='admin-add-student-form'>
      <FormControl variant='outlined' autoComplete='off'>
        <TextField
          label='First Name'
          name='firstName'
          value={internalData.firstName || ''}
          onChange={handleOnChange}
          className='form-student-first-name '
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          variant='standard'
          required
        />
        <TextField
          label='Last Name'
          name='lastName'
          value={internalData.lastName || ''}
          onChange={handleOnChange}
          className='form-student-last-name'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          variant='standard'
          required
        />
        {/* <TextField
          label='SIS ID (Optional)'
          name='externalId'
          value={internalData.externalId || ''}
          onChange={handleOnChange}
          className='form-student-sis-id'
          inputProps={{
            maxLength: 255,
          }}
          variant='standard'
        /> */}
        {(schoologyFlag || userSuperAdmin || userAdmin) && (
          <TextField
            label='Schoology ID (optional)'
            name='schoologyId'
            value={internalData.schoologyId || ''}
            onChange={handleOnChange}
            className='form-student-schoologyId'
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
          />
        )}
        <TextField
          label='State Identifier'
          name='stateIdentifier'
          value={internalData.stateIdentifier || ''}
          onChange={handleOnChange}
          className='form-student-schoologyId'
          InputProps={{
            maxLength: 255,
            'data-private': 'redact',
            endAdornment: (
              <TooltipWithIndicator {...stateIdentifierTooltipText} />
            ),
          }}
          variant='standard'
          required
        />
        <TextField
          label='Username'
          name='userName'
          value={internalData.userName || ''}
          onChange={handleOnChange}
          onBlur={validateUsername}
          className='form-student-userName'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          error={Boolean(usernameError)}
          helperText={usernameError}
          variant='standard'
          required
        />
        <TextField
          label='Student Password'
          name='password'
          type='password'
          value={internalData.password || ''}
          onChange={handleOnChange}
          onBlur={() => {
            validatePassword(internalData.password, confirmPassword);
          }}
          className='form-student-password'
          autoComplete='new-password'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          error={passwordError.password !== null}
          helperText={passwordError.password}
          variant='standard'
          required
        />
        <TextField
          label='Confirm Password'
          name='confirmPassword'
          type='password'
          value={confirmPassword || ''}
          onChange={handleConfirmPassword}
          onBlur={() => {
            validatePassword(internalData.password, confirmPassword);
          }}
          className='form-student-confirmPassword'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          error={passwordError.confirmPassword !== null}
          helperText={passwordError.confirmPassword}
          variant='standard'
          required
        />
      </FormControl>
    </div>
  );
};

AddStudentForm.defaultProps = {
  studentData: null,
  onDataChange: () => null,
};

AddStudentForm.propTypes = {
  studentData: PropTypes.object,
  onDataChange: PropTypes.func,
};
export default AddStudentForm;

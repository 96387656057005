import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import ClearLessonSelectionAction from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/ClearLessonSelectionAction';
import ReOrderLessonsAction from './ReOrderLessonsAction';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import './TeacherCoursesRowActions.scss';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';

// eslint-disable-next-line arrow-body-style
const TeacherCoursesRowActions = ({
  onClearClick,
  selectedLessonIds,
  rowIndex,
  filteredLessonData,
}) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { teacherCourseDomain } = useTeacherCourses();

  return (
    <div className='teacher-courses-row-actions'>
      {user.userModerator && (
        <ReOrderLessonsAction rowIndex={rowIndex} />
      )}

      <Button
        variant='contained'
        className='btn-primary'
        onClick={() => {
          const lessonIds = filteredLessonData.lessons[0].lessons.map((l) => l.id);
          teacherCourseDomain.updateSelectedLessonIds(lessonIds);
        }}
      >
        SELECT ALL
      </Button>
      <ClearLessonSelectionAction
        className='clear-selection'
        onClearClick={onClearClick}
        selectedLessonIds={selectedLessonIds}
        clearSelectedLessonTitle='Deselect activities'
      />
    </div>
  );
};

TeacherCoursesRowActions.defaultProps = {
  onClearClick: () => { },
  selectedLessonIds: [],
  filteredLessonData: {
    lessons: [],
    included: [],
  },
};

TeacherCoursesRowActions.propTypes = {
  onClearClick: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  rowIndex: PropTypes.number.isRequired,
  filteredLessonData: PropTypes.shape({
    lessons: PropTypes.array,
    included: PropTypes.array,
  }),
};

export default TeacherCoursesRowActions;

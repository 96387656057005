import { useState, useEffect } from 'react';
import {
  Button, TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CustomDialog from '../../../../components/dialog/CustomDialog';
import './SearchBarAction.scss';
import './AddUnitAction.scss';
import { useAddNewUnit } from './AddUnitActionHooks';
import CancelDialog from './dialog/CancelDialog';
import AddTopicDialog from './dialog/topic/AddTopicDialog';
import GenerateLessonsDialog from './dialog/generate-lessons/GenerateLessonsDialog';

const AddUnitAction = ({
  subjectId,
  courseId,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [openAddTopicDialogData, setOpenAddTopicDialog] = useState({
    open: false,
    unitId: '',
  });

  const [openGenerateLessonDialog, setOpenGenerateLessonDialog] = useState({
    open: false,
    topicId: null,
  });

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [unitName, setUnitName] = useState('');

  useEffect(() => {
    if (!openDialog) {
      setUnitName('');
      setOpenAddTopicDialog({
        open: false,
        unitId: '',
      });
    }
  }, [openDialog]);

  const {
    saving,
    handleOnSave,
  } = useAddNewUnit({
    courseId,
    setOpenDialog,
    setOpenAddTopicDialog,
  });

  return (
    <>
      <Button
        variant='contained'
        className='btn-green-background add-button'
        onClick={() => setOpenDialog(true)}
        disabled={!courseId}
        startIcon={<EditIcon />}
        data-test='add-unit-action'
      >
        Add Unit
      </Button>
      {openDialog && (
        <CustomDialog
          className='teacher-course-add-unit-dialog'
          openDialog={openDialog}
          onClose={() => setOpenDialog(false)}
          displayCloseIcon={false}
          title='Add a new Unit'
          content={(
            <div className='dialog-content'>
              <TextField
                onChange={(e) => { setUnitName(e.target.value); }}
                label='Enter name of new Unit'
                fullWidth
                value={unitName}
                inputProps={{
                  maxLength: 255,
                }}
                autoFocus
              />
            </div>
          )}
          actions={[
            {
              title: 'Cancel',
              color: 'primary',
              className: 'btn-danger action-button wide-button left-button',
              onClick: () => { if (unitName) { setOpenCancelDialog(true); } else { setOpenDialog(false); } },
              disabled: saving,
              loading: saving,
            },
            {
              title: 'Continue',
              color: 'primary',
              className: 'btn-primary wide-button action-button',
              disabled: !unitName || saving,
              onClick: () => { handleOnSave(unitName); },
              loading: saving,
            },
          ]}
        />
      )}

      {openAddTopicDialogData.open && (
        <AddTopicDialog
          open={openAddTopicDialogData.open}
          onClose={() => {
            setOpenAddTopicDialog({
              open: false,
              unitId: '',
            });
          }}
          courseId={courseId}
          unitId={openAddTopicDialogData.unitId}
          onSaveSuccess={(newTopics) => {
            setOpenGenerateLessonDialog({
              open: true,
              topicId: newTopics.id,
            });
          }}
        />
      )}

      {openGenerateLessonDialog.open && (
        <GenerateLessonsDialog
          open={openGenerateLessonDialog.open}
          onClose={() => {
            setOpenGenerateLessonDialog({
              open: false,
            });
            setOpenAddTopicDialog({
              open: false,
              unitId: '',
            });
          }}
          subjectId={subjectId}
          courseId={courseId}
          unitId={openAddTopicDialogData.unitId}
          topicId={openGenerateLessonDialog.topicId}
          lessonLevel={1}
        />
      )}

      {openCancelDialog && (
        <CancelDialog
          open={openCancelDialog}
          title='Cacel adding a new Unit'
          onClose={() => { setOpenCancelDialog(false); }}
          onCancel={() => { setOpenDialog(false); }}
        />
      )}
    </>
  );
};

AddUnitAction.defaultProps = {
  subjectId: 0,
  courseId: 0,
};

AddUnitAction.propTypes = {
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AddUnitAction;

import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import GridListComponent from '../../../grid/GridList';
import ObjectiveCard from './ObjectiveCard';
import ObjectiveFilter from './ObjectiveFilter';
import { calculateCardSize } from '../../../../utils/CardSizeCalculator';
import { useObjectCardFilter } from './ObjectiveCardListDetailHooks';
import CardDetailContainer, { useCardDetail } from '../CardDetailContainer';
import ObjectiveDetail from './ObjectiveDetail';
import './ObjectiveCardListDetail.scss';

const cardSize = calculateCardSize();
const ObjectiveCardListDetail = forwardRef(({
  masterObjectiveData,
  multiSelectFilterConfig,
  onFilterChange,
  currentUser,
  filterChildren,
  editGoalNameAction,
  createCardDetailAction,
  editMasterySetAndConsecutiveLessonAction,
  onLessonSelect,
}, ref) => {
  const { included } = masterObjectiveData;
  const {
    filteredObjectives,
    filterValue,
    handleOnFilterChange,
  } = useObjectCardFilter(masterObjectiveData, multiSelectFilterConfig, onFilterChange);

  const {
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openCardDetail,
    setOpenCardDetail,
  } = useCardDetail();

  useImperativeHandle(ref, () => ({
    closeObjectiveCardDetail: () => {
      setOpenCardDetail({
        open: false,
        selectedIndex: null,
        rowIndex: null,
      });
    },
    getCurrentOpenObjective: () => filteredObjectives[openCardDetail.rowIndex].objectives[openCardDetail.selectedIndex],
  }));

  const objectiveList = useMemo(() => (
    <>
      <ObjectiveFilter
        filterValue={filterValue}
        onFilterChange={handleOnFilterChange}
        multiSelectFilterConfig={multiSelectFilterConfig}
      >
        {filterChildren}
      </ObjectiveFilter>
      {filteredObjectives && filteredObjectives.length > 0 ? (
        filteredObjectives.map((objectivesWithTitle, rowIndex) => (
          objectivesWithTitle.objectives.length > 0
            ? (
              <GridListComponent
                key={`objective-list-${objectivesWithTitle.objectiveRowTitle}-${JSON.stringify(filterValue)}`}
                titleName={objectivesWithTitle.objectiveRowTitle}
                // slidesToShow={cardSize.slidesToShow - 1}
                slidesToScroll={cardSize.slidesToScroll - 1}
                gradient={false}
                data-test={`lesson-list-row-${objectivesWithTitle.objectiveRowTitle}`}
              >
                {
                  objectivesWithTitle.objectives.map((objective, cardIndex) => (
                    <ObjectiveCard
                      key={`object-${objective.id}`}
                      masterObjectiveData={objective}
                      included={included}
                      width={cardSize.width}
                      onClick={() => handleOnViewDetailClick(cardIndex, rowIndex)}
                    />
                  ))
                }
              </GridListComponent>
            ) : null
        ))
      ) : null}
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [filteredObjectives]);

  const cardSlide = useRef(null);

  const objectiveDetail = () => (
    openCardDetail.open ? (
      <CardDetailContainer
        ref={cardSlide}
        className='objective-detail-card-container'
        open={openCardDetail.open}
        selectedIndex={openCardDetail.selectedIndex}
        onClose={handleOnCloseDetail}
        dataList={filteredObjectives[openCardDetail.rowIndex].objectives}
        createCardFunction={(objective) => (
          <ObjectiveDetail
            key={`objective-detail-${objective.id}`}
            objective={objective}
            currentUser={currentUser}
            included={masterObjectiveData.included}
            editMasterySetAndConsecutiveLessonAction={
              objective.meta.archived ? null
                : editMasterySetAndConsecutiveLessonAction(objective, openCardDetail.rowIndex, (cardIndex) => {
                  cardSlide.current.goToSlide(cardIndex, true);
                }, (newRowIndex) => {
                  setOpenCardDetail({
                    ...openCardDetail,
                    selectedIndex: 0,
                    rowIndex: newRowIndex,
                  });
                })
            }
            editGoalNameAction={objective.meta.archived ? null
              : editGoalNameAction(objective, openCardDetail.rowIndex,
                (cardIndex) => {
                  cardSlide.current.goToSlide(cardIndex, true);
                })}
            actions={createCardDetailAction(objective, openCardDetail.rowIndex, () => {
              handleOnCloseDetail();
            }, masterObjectiveData.included)}
            onLessonSelect={onLessonSelect}
          />
        )}
      />
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <Fade in>
      <div>
        {objectiveList}
        {objectiveDetail()}
      </div>
    </Fade>
  );
});

ObjectiveCardListDetail.defaultProps = {
  masterObjectiveData: {
    masterObjectives: [],
    included: [],
    objectiveFilterValue: {},
  },
  multiSelectFilterConfig: [],
  onFilterChange: () => { },
  currentUser: {},
  filterChildren: null,
  editGoalNameAction: () => { },
  createCardDetailAction: () => ([]),
  editMasterySetAndConsecutiveLessonAction: () => { },
  onLessonSelect: null,
};

ObjectiveCardListDetail.propTypes = {
  masterObjectiveData: PropTypes.shape({
    masterObjectives: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
    })),
    included: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.object,
    })),
    objectiveFilterValue: PropTypes.object,
  }),
  multiSelectFilterConfig: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    subTitle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.any,
      name: PropTypes.any,
      value: PropTypes.any,
    })),
    className: PropTypes.string,
    keyName: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    filterable: PropTypes.bool,
  })),
  onFilterChange: PropTypes.func,
  currentUser: PropTypes.shape({
    userName: PropTypes.string,
  }),
  filterChildren: PropTypes.node,
  editGoalNameAction: PropTypes.func,
  createCardDetailAction: PropTypes.func,
  editMasterySetAndConsecutiveLessonAction: PropTypes.func,
  onLessonSelect: PropTypes.func,
};

export default ObjectiveCardListDetail;

import { useState } from 'react';
import { Alert, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import VizzleLogo from '../../../../../../vizzle_NoTag.svg';
import './ResetPasswordFormDialog.scss';
import PasswordUtils, { invalidConfirmPassword, invalidPassword, passwordMismatch } from '../../../../../../utils/PasswordUtils';

export default function ResetPasswordFormDialog({ open, onClose, onConfirm }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passowordError, setPasswordError] = useState('');

  const validatePassword = (newPassword, newConfirmPassword) => {
    let error = '';

    if (newPassword && !PasswordUtils.validatePassword(newPassword)) {
      error = invalidPassword;
    } else if (newConfirmPassword && !PasswordUtils.validatePassword(newConfirmPassword)) {
      error = invalidConfirmPassword;
    } else if (newPassword && newConfirmPassword && (newPassword !== newConfirmPassword)) {
      error = passwordMismatch;
    }
    setPasswordError(error);
  };

  return (
    <CustomDialog
      openDialog={open}
      className='reset-password-form-dialog'
      onClose={() => {
        onClose();
      }}
      content={(
        <div className='reset-password-form'>
          <img src={VizzleLogo} alt='Vizzle logo' className='vizzle-logo' />
          <Typography variant='h6'>Reset Password</Typography>
          {Boolean(passowordError) && <Alert severity='error'>{passowordError}</Alert>}

          <TextField
            label='New Password'
            type='password'
            autoComplete='new-password'
            fullWidth
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onBlur={(e) => {
              validatePassword(e.target.value, confirmPassword);
            }}
          />
          <TextField
            label='Confirm Password'
            type='password'
            autoComplete='off'
            fullWidth
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            onBlur={(e) => {
              validatePassword(password, e.target.value);
            }}
          />
        </div>
      )}
      displayCloseIcon
      actions={[{
        onClick: () => {
          onConfirm(password);
        },
        className: 'btn-primary',
        title: 'RESET',
        disabled: Boolean(passowordError) || Boolean(!password) || Boolean(!confirmPassword),
      },
      ]}
    />
  );
}

ResetPasswordFormDialog.defaultProps = {
  open: false,
  onClose: () => { },
  onConfirm: () => { },
};

ResetPasswordFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

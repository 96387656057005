import LoadingSpinner from '../../../../../../components/loaders/LoadingSpinner';
import { useGetAssignedStudentCourses } from '../../../../../lessons/assigned-student-courses/AssignedStudentCoursesHooks';
import { useMyClassroom } from '../../../../context/MyClassRoomContextProvider';
import { useMyAssignmentLoadData } from '../AssignmentsOverviewDateSelectorHooks';
import StudentAssignmentDialog from '../student-assignment/StudentAssignmentDialog';
import AssignedStudentCoursesCard from './AssignedStudentCoursesCard';
import './AssignedStudentCoursesList.scss';
import { useGetAssignmentsInWeek, useHandleViewAssignStudentCourses } from './AssignedStudentCoursesListHooks';

const AssignedStudentCoursesList = () => {
  const {
    assignedStudentCoursesCardData,
    assignedStudentCoursesLoading,
  } = useGetAssignmentsInWeek();

  const {
    assignmnetDialog,
    openAssignmentDialog,
    closeAssignmentDialog,
  } = useHandleViewAssignStudentCourses();

  const {
    handleOnStartLoading,
    handleOnDataLoad,
  } = useMyAssignmentLoadData();

  const { myAssignmentsDomain } = useMyClassroom();
  const { filter, selectedDates } = myAssignmentsDomain.domainData;

  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading: handleOnStartLoading,
    subjectId: filter.selectedSubject,
    onDataLoad: handleOnDataLoad,
    assignedStudentCourses: assignedStudentCoursesCardData,
  });

  return (
    <>
      <div className='assigned-student-courses-list'>
        {assignedStudentCoursesLoading
          ? (
            <div className='loading-spinner-container' data-test='loading-spinner-container'>
              <LoadingSpinner message='Loading' />
            </div>
          )
          : (
            assignedStudentCoursesCardData && assignedStudentCoursesCardData.length > 0 && (
              <div className='list-container'>
                {assignedStudentCoursesCardData.map((data, index) => (
                  <AssignedStudentCoursesCard
                    key={`assigned-student-course-card-${index}`}
                    data={data}
                    onViewClick={() => {
                      openAssignmentDialog(data);
                    }}
                  />
                ))}
              </div>
            )
          )}
      </div>
      {assignmnetDialog.open && (
        <StudentAssignmentDialog
          open={assignmnetDialog.open}
          data={assignedStudentCoursesCardData.find((a) => a.date === assignmnetDialog.data.date)}
          onClose={closeAssignmentDialog}
          onTableActionSuccess={async () => {
            getAssignedStudentData(selectedDates, false);
          }}
          loading={assignedStudentCoursesLoading}
        />
      )}
    </>
  );
};

export default AssignedStudentCoursesList;

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import './OffButtonForm.scss';

const OffButtonForm = ({ label, loading, handleOnClick, ...rest }) => (
  <div {...rest} className='off-button-form'>
    <div className='label-container' data-url='set-to-aff-label'>
      <Typography variant='subtitle1' className='label'>
        Set All {label} to Off
      </Typography>
    </div>
    <div className='off-button'>
      <ButtonWithSpinner data-url='set-to-aff-action' loading={loading} className='off-button' label='OFF' onClick={handleOnClick} />
    </div>
  </div>
);

OffButtonForm.defaultProps = {
  label: '',
  loading: false,
  handleOnClick: () => {},
};

OffButtonForm.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default OffButtonForm;

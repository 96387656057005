import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';

export const useHandleFileUpload = ({
  uploadServiceFunction,
  onUploadSuccess,
  isMultiple,
}) => {
  const { uiDomain } = useDomain();

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnFileSelect = async (e) => {
    const { files } = e.target;
    const file = e.target.files[0];

    try {
      await callRemoteServiceWrapper(async () => {
        if (files.length === 1) {
          uiDomain.showLoader(`Uploading ${file.name}.`, LOADER_TYPE.BOUNCE_LOADER);
        } else if (files.length > 1) {
          uiDomain.showLoader(`Uploading ${files.length} files.`, LOADER_TYPE.BOUNCE_LOADER);
        }

        if (isMultiple) {
          await uploadServiceFunction(files);
        } else {
          await uploadServiceFunction(file);
        }
        await uiDomain.hideLoader();
        onUploadSuccess();
      });
    } catch (error) {
      Logger.logWhenDebugModeIsOn(error);
      await uiDomain.hideLoader();
    }
  };

  return {
    handleOnFileSelect,
  };
};

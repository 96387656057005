import { useNavigate } from 'react-router-dom';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../states/AppDomainProvider';
import { usePrepareData } from '../LoginHooks';
import { acceptTermsOfUse } from '../../../services/UserProfileService';
import {
  URL_ADMIN,
  URL_MY_CLASSROOM,
} from '../../../routes/RouteConstants';
import Logger from '../../../utils/Logger';
import { MY_CLASSROOM_ROUTES } from '../../myclassroom/MyClassRoomConstant';

export const useTermsOfUse = () => {
  const navigate = useNavigate();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    prepareDataForTeacher,
  } = usePrepareData();

  const handleAcceptTermsOfUse = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await acceptTermsOfUse();
        user.tosFlag = true;
        await prepareDataForTeacher({ ...user });
      });
      if (user.userAdmin) {
        navigate(URL_ADMIN);
      } else {
        navigate(`${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.STUDENT_MANAGEMENT}`);
      }
    } catch (e) {
      Logger.logError(e);
    }
  };

  return {
    tosFlag: user.tosFlag,
    handleAcceptTermsOfUse,
    loading,
  };
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import {
  MATCHING_ACTIVITY_DRAG_AND_DROP,
} from '../../../../constants/SettingConstant';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
      componentId: `flexible-matching-activity-component-${id}`,
      prompCardDropableId: `promp-placeholder-${id}`,
    };
  }, []);

  return componentIds;
};

export const useInitializeRefs = () => {
  const flexibleMatchingPromptRefs = [];
  const setFlexibleMatchingPromptRefs = (r, index) => {
    if (r) {
      flexibleMatchingPromptRefs[index] = r;
    }
  };

  const flexibleMatchingResponseRefs = [];
  const setFlexibleMatchingResponseRefs = (r, index) => {
    if (r) {
      flexibleMatchingResponseRefs[index] = r;
    }
  };

  return ({
    flexibleMatchingActivityRef: useRef(null),
    dragAndDropApiRef: useRef(null),
    flexibleMatchingPromptRefs,
    setFlexibleMatchingPromptRefs,
    flexibleMatchingResponseRefs,
    setFlexibleMatchingResponseRefs,
  });
};

export const useGenerateRejectedAnswerRefs = () => {
  const rejectedAnswerPromptRef = useRef(null);

  return {
    rejectedAnswerPromptRef,
  };
};

export const useInitializeData = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile, singleAnswer } = lessonPlayDomain.domainData;
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(-1);
  const [selectedWrongResponses, setSelectedWrongResponses] = useState([]);

  const [isDraggable, setIsDraggable] = useState(userProfile.interactionType === MATCHING_ACTIVITY_DRAG_AND_DROP);

  return {
    selectedResponseIndex,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    isDraggable,
    setIsDraggable,
    singleAnswer,
  };
};

const submitAnswer = async ({
  questionKey,
  responseCard,
  index,
  setSelectedResponseIndex,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  selectedWrongResponses,
  setSelectedWrongResponses,
  showDropRejection,
  rejectedAnswerPromptRef,
  singleAnswer,
}) => {
  // The single answer lesson can submit the answer only once and move to the next activity
  if (singleAnswer) {
    if (responseCard.correctAnswer || responseCard.key === questionKey) {
      setSelectedResponseIndex(index);
      await onCorrectAnswerSelected({ insertResponse: true, responseIndex: index, once: false, isFinished: true, showReinforcer: true });
      onActivityFinished();
    } else {
      await showDropRejection(rejectedAnswerPromptRef.current);
      setSelectedWrongResponses([
        ...selectedWrongResponses,
        index,
      ]);
      await onIncorrectAnswerSelected(index, true, true);
      onActivityFinished({ toNextPage: true, alwaysGoToNext: true });
    }
    return;
  }

  if (responseCard.key === questionKey) {
    setSelectedResponseIndex(index);
    await onCorrectAnswerSelected({ insertResponse: true, responseIndex: index, once: false, isFinished: true, showReinforcer: true });
    onActivityFinished();
  } else {
    await showDropRejection(rejectedAnswerPromptRef.current);
    setSelectedWrongResponses([
      ...selectedWrongResponses,
      index,
    ]);
    onIncorrectAnswerSelected(index);
  }
};

export const useDragAndDropSubmitAnswer = ({
  flexibleMatchingActivityRef,
  questionKey,
  prompCardDropableId,
  responseCards,
  rejectedAnswerPromptRef,
  setSelectedResponseIndex,
  selectedWrongResponses,
  setSelectedWrongResponses,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;
  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = () => {
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(flexibleMatchingActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    try {
      const { source, destination } = result;
      if (!source || !destination) {
        LessonPlayActivityUtils.disableComponent(flexibleMatchingActivityRef, false);
        return;
      }

      const { index } = source;
      const { droppableId } = destination;
      if (prompCardDropableId === droppableId) {
        await submitAnswer({
          questionKey,
          responseCard: responseCards[index],
          index,
          setSelectedResponseIndex,
          onCorrectAnswerSelected,
          onIncorrectAnswerSelected,
          onActivityFinished,
          selectedWrongResponses,
          setSelectedWrongResponses,
          showDropRejection,
          rejectedAnswerPromptRef,
          singleAnswer,
        });
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      LessonPlayActivityUtils.disableComponent(flexibleMatchingActivityRef, false);
    }
  };

  return {
    handleOnDragStart,
    handleOnDragEnd,
  };
};

export const useTwoClickSubmitAnswer = ({
  flexibleMatchingActivityRef,
  prompCardDropableId,
  dragAndDropApiRef,
  setIsDraggable,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const currentSelectedIndex = useRef(null);

  let handleOnPromptCardClick = null;
  let handleOnResponseCardClick = null;

  if (!(MATCHING_ACTIVITY_DRAG_AND_DROP === userProfile.interactionType)) {
    handleOnPromptCardClick = () => {
      if (currentSelectedIndex.current) {
        setIsDraggable(true);
        flexibleMatchingActivityRef.current.classList.remove('flexi-matching-prompt-selectable');
        LessonPlayActivityUtils.stopMedias();
        const dragAndDropApi = dragAndDropApiRef.current;
        const triggerDragAndDrop = async () => {
          document.getElementById(currentSelectedIndex.current.cardId).classList.remove('flexi-matching-selected-card');
          await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedIndex.current.cardId, currentSelectedIndex.current.draggableId, prompCardDropableId);
          setIsDraggable(false);
          currentSelectedIndex.current = null;
        };

        ObjectUtils.setTimeout(() => {
          triggerDragAndDrop();
        });
      }
    };

    handleOnResponseCardClick = (_e, isTtsAbleToPlay, responseCard, cardId, responseCardDraggableId) => {
      if (currentSelectedIndex.current && currentSelectedIndex.current.cardId === cardId) {
        LessonPlayActivityUtils.stopMedias();
        flexibleMatchingActivityRef.current.classList.remove('flexi-matching-prompt-selectable');
        document.getElementById(currentSelectedIndex.current.cardId).classList.remove('flexi-matching-selected-card');
        currentSelectedIndex.current = null;
      } else {
        if (!responseCard.audio && !responseCard.video && !isTtsAbleToPlay) {
          LessonPlayActivityUtils.stopMedias();
        }

        if (currentSelectedIndex.current) {
          flexibleMatchingActivityRef.current.classList.remove('flexi-matching-prompt-selectable');
          document.getElementById(currentSelectedIndex.current.cardId).classList.remove('flexi-matching-selected-card');
        }
        currentSelectedIndex.current = {
          cardId,
          draggableId: responseCardDraggableId,
        };
        flexibleMatchingActivityRef.current.classList.add('flexi-matching-prompt-selectable');
        document.getElementById(cardId).classList.add('flexi-matching-selected-card');
      }
    };
  }

  return {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  };
};

export const useSwitchAccessSubmitAnswer = ({
  dragAndDropApiRef,
  prompCardDropableId,
  setIsDraggable,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility } = userProfile;

  const handleResponseSwitchAccessSelect = switchAccessibility ? (responseId, draggableId) => {
    LessonPlayActivityUtils.stopMedias();
    const dragAndDropApi = dragAndDropApiRef.current;
    const triggerDragAndDrop = async () => {
      await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, responseId, draggableId, prompCardDropableId);
      setIsDraggable(false);
    };

    setIsDraggable(true);
    ObjectUtils.setTimeout(() => {
      triggerDragAndDrop();
    });
  } : () => { };
  return {
    handleResponseSwitchAccessSelect,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  setSelectedResponseIndex,
  setSelectedWrongResponses,
  responseCards,
  onActivityFinished,
  pageIndex,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;

  useEffect(() => {
    setSelectedResponseIndex(() => {
      const result = responseCards.findIndex((r) => (r.correctSubmission !== null && r.correctSubmission));
      const isFinished = result >= 0;
      if (isFinished) {
        onActivityFinished({ toNextPage: false, pageIndex });
      }
      return result;
    });

    const incorrectAnswers = [];

    responseCards.forEach((r, index) => {
      const result = r.correctSubmission !== null && r.correctSubmission === false;
      if (result) {
        incorrectAnswers.push(index);
      }
    });
    setSelectedWrongResponses(incorrectAnswers);
    if (singleAnswer && incorrectAnswers.length > 0) {
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

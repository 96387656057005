import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import './LoadingPlaceHolder.scss';

const LoadingPlaceHolder = ({
  message,
}) => (
  <div className='loading-placeholder-loading'>
    <CircularProgress size={40} />
    <div className='loading-message'>
      {message}
    </div>
  </div>
);

LoadingPlaceHolder.defaultProps = {
  message: '',
};

LoadingPlaceHolder.propTypes = {
  message: PropTypes.string,
};

export default LoadingPlaceHolder;

import { useRef, useMemo, useState, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import Logger from '../../../../utils/Logger';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';

export const useInitializeData = () => ({
  sortingActivityRef: useRef(null),
  dragAndDropApiRef: useRef(null),
});

export const useGenerateRejectedAnswerRefs = () => {
  const rejectedAnswerPromptRefs = [];
  const setRejectedAnswerPromptRefs = (ref) => {
    if (ref) {
      rejectedAnswerPromptRefs.push(ref);
    }
  };

  const rejectedAnswerResponseRefs = [];
  const setRejectedAnswerResponseRefs = (ref, index) => {
    if (ref) {
      rejectedAnswerResponseRefs[index] = ref;
    }
  };

  return {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  };
};

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
      componentId: `sorting-activity-component-${id}`,
      droppableId: `sorting-component-main-droppable-container-${id}`,
      textLineId: `sorting-activity-component-${id}-textlines`,
    };
  }, []);

  return componentIds;
};

export const useDragAndDropSubmitAnswer = ({
  sortingActivityRef,
  promptCards,
  rejectedAnswerPromptRefs,
  responseCards,
  rejectedAnswerResponseRefs,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
}) => {
  const [selectedResponseCards, setSelectedResponseCards] = useState([]);
  const isFinished = useRef(false);

  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = () => {
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(sortingActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      LessonPlayActivityUtils.disableComponent(sortingActivityRef, false);
      return;
    }

    try {
      const sourceIndex = source.index;
      const destinationDroppingId = destination.droppableId;
      const destinationIndexStr = destinationDroppingId.substring(destinationDroppingId.lastIndexOf('-') + 1);
      // 10 -> decimal number
      const destinationIndex = parseInt(destinationIndexStr, 10);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(destinationIndex)) {
        return;
      }
      if (promptCards[destinationIndex].key === responseCards[sourceIndex].key) {
        const updatedSelectdResponseCards = [
          ...selectedResponseCards,
          sourceIndex,
        ];
        setSelectedResponseCards(updatedSelectdResponseCards);
        isFinished.current = updatedSelectdResponseCards.length >= responseCards.length;
        await onCorrectAnswerSelected({
          insertResponse: true,
          responseIndex: sourceIndex,
          once: false,
          isFinished: isFinished.current,
          showReinforcer: isFinished.current,
        });
        if (isFinished.current) {
          LessonPlayActivityUtils.removeSelectedElementClass();
          onActivityFinished();
        }
      } else {
        onIncorrectAnswerSelected(sourceIndex);
        showDropRejection(rejectedAnswerPromptRefs[destinationIndex]);
        showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      LessonPlayActivityUtils.disableComponent(sortingActivityRef, false);
    }
  };
  return {
    handleOnDragStart,
    handleOnDragEnd,
    selectedResponseCards,
    isFinished,
    setSelectedResponseCards,
  };
};

export const useClickSubmitAnswer = ({
  sortingActivityRef,
  dragAndDropApiRef,
}) => {
  const currentSelectedIndex = useRef(null);

  const handleOnPromptCardClick = (_e, prompCardDropableId) => {
    if (currentSelectedIndex.current) {
      LessonPlayActivityUtils.stopMedias();

      const dragAndDropApi = dragAndDropApiRef.current;
      const triggerDragAndDrop = async () => {
        await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedIndex.current.responseId, currentSelectedIndex.current.draggableId, prompCardDropableId);
        // clear selected value
        if (currentSelectedIndex.current) {
          sortingActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
          const el = document.getElementById(currentSelectedIndex.current.responseId);
          if (el) {
            el.classList.remove('vizzle-selected-component-medium');
          }
          currentSelectedIndex.current = null;
        }
      };

      ObjectUtils.setTimeout(() => {
        triggerDragAndDrop();
      });
    }
  };

  const handleOnResponseCardClick = (_e, isTtsAbleToPlay, responseCard, responseId, draggableId) => {
    if (!responseCard.audio && !responseCard.video && !isTtsAbleToPlay) {
      LessonPlayActivityUtils.stopMedias();
    }

    if (!currentSelectedIndex.current || currentSelectedIndex.current.cardId !== responseId) {
      if (currentSelectedIndex.current && document.getElementById(currentSelectedIndex.current.responseId)) {
        document.getElementById(currentSelectedIndex.current.responseId).classList.remove('vizzle-selected-component-medium');
      }
      currentSelectedIndex.current = {
        responseId,
        draggableId,
      };
      sortingActivityRef.current.classList.add('prompt-selected-response-cards-hover');
      document.getElementById(responseId).classList.add('vizzle-selected-component-medium');
    }
  };

  return {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  };
};

export const useSwitchAccessSubmitAnswer = ({
  sortingPromptRefs,
  onTriggerSwitchScan,
  dragAndDropApiRef,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility } = userProfile;

  const selectedPromptIndex = useRef(null);
  const handlePromptSwitchAccessSelect = switchAccessibility ? (index, drappableKeyId) => {
    const el = sortingPromptRefs.map((s) => s.getEnableElements());
    LessonPlayActivityUtils.addClassToTheSelectedElement(el[index]);
    selectedPromptIndex.current = {
      index,
      drappableKeyId,
    };
    onTriggerSwitchScan();
  } : () => { };

  const handleResponseSwitchAccessSelect = switchAccessibility ? (responseId, draggableId) => {
    LessonPlayActivityUtils.stopMedias();
    const dragAndDropApi = dragAndDropApiRef.current;
    const triggerDragAndDrop = async () => {
      await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, responseId, draggableId, selectedPromptIndex.current.drappableKeyId);
    };

    ObjectUtils.setTimeout(() => {
      triggerDragAndDrop();
    });
  } : () => { };
  return {
    selectedPromptIndex,
    handlePromptSwitchAccessSelect,
    handleResponseSwitchAccessSelect,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  setSelectedResponseCards,
  responseCards,
  isFinished,
  onActivityFinished,
  pageIndex,
}) => {
  useEffect(() => {
    const result = [];
    responseCards.forEach((r, index) => {
      if (r.correctSubmission !== null && r.correctSubmission !== undefined) {
        result.push(index);
      }
    });

    // eslint-disable-next-line no-param-reassign
    isFinished.current = result.length >= responseCards.length;
    if (isFinished.current) {
      onActivityFinished({ toNextPage: false, pageIndex });
    }

    setSelectedResponseCards(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';
import MultiSelectorWithSearch from '../../../../../components/selector/MultiSelectorWithSearch';
import { useDomain } from '../../../../../states/AppDomainProvider';

/**
 * Container for Standard selection in Lesson Save screen
 *
 * @export
 */
export default function LessonStandardSelector({
  lessonStandards,
  disabled,
  onStandardSelected,
  placeholderWhenEmpty,
}) {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const handleStandardSelected = async (event, newValues) => {
    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        standards: newValues.map((v) => v.value),
      },
    };
    await lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
    onStandardSelected();
  };

  return (
    <Box width='100%' data-test='lesson-standard-selector'>
      <Typography variant='h6'>
        Activity Standard
      </Typography>
      <MultiSelectorWithSearch
        className='vizzle-lesson-standard-dropdown'
        values={lessonData.attributes.standards || []}
        onChange={handleStandardSelected}
        selectorItems={lessonStandards}
        disabled={disabled}
        placeholderWhenEmpty={placeholderWhenEmpty}
        showSelectedValueAtTheTop
      />
    </Box>
  );
}

LessonStandardSelector.defaultProps = {
  disabled: false,
  onStandardSelected: () => { },
  placeholderWhenEmpty: '',
};

LessonStandardSelector.propTypes = {
  lessonStandards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  onStandardSelected: PropTypes.func,
  placeholderWhenEmpty: PropTypes.string,
};

import TermsOfUse from '../../containers/login/tos/TermsOfUse';
import LessonUpdate from '../../containers/lessons/lessonmaintenance/LessonUpdate';
import LessonCreate from '../../containers/lessons/lessonmaintenance/LessonCreate';
import MyClassRoom from '../../containers/myclassroom/MyClassRoom';
import MyStudent from '../../containers/mystudent/MyStudent';
import MySettings from '../../containers/mysettings/MySettings';
// import SearchLessons from '../../containers/search-lessons/SearchLessons';
import TeacherCurriculum from '../../containers/curriculum/teacher-curriculum/TeacherCurriculum';
// import TeacherCurriculumVerticalAlignment from '../../containers/curriculum/vertical-alignment/TeacherCurriculumVerticalAlignment';
import Courses from '../../containers/courses/TeacherCourse';
import LoginForRethink from '../../containers/login/LoginForRethink';

import {
  URL_LESSON_UPDATE,
  URL_LESSON_CREATE,
  URL_MY_CLASSROOM,
  URL_LESSONS,
  URL_MY_STUDENT,
  URL_MY_SETTINGS,
  // URL_TEACHER,
  URL_TERMS_OF_USE,
  URL_TEACHER_CURRICULUM,
  // URL_TEACHER_VERTICAL_CURRICULUM,
  URL_ADMIN,
  URL_SKILLS_SUITE,
  URL_COURSES,
  URL_LOGIN_RETHINK,
  URL_GETTING_STARTED,
} from '../RouteConstants';
import AdminHome from '../../containers/admin/AdminHome';
import GettingStarted from '../../containers/getting-started/GettingStarted';
import TeacherCourseSkillsSuite from '../../containers/courses/TeacherCourseSkillsSuite';
import SearchLessonsV2 from '../../containers/search-lessons-v2/SearchLessonsV2';

const getTeacherRoutes = (user) => {
  if (user.tosFlag) {
    const result = [
      { path: URL_LESSON_UPDATE, element: <LessonUpdate /> },
      { path: URL_LESSON_CREATE, element: <LessonCreate /> },
      { path: `${URL_MY_CLASSROOM}/*`, element: <MyClassRoom /> },
      { path: URL_LESSONS, element: <SearchLessonsV2 /> },
      { path: `${URL_MY_STUDENT}/*`, element: <MyStudent /> },
      { path: URL_MY_SETTINGS, element: <MySettings /> },
      // { path: `${URL_TEACHER}/*`, element: <SearchLessons /> },
      { path: URL_TERMS_OF_USE, element: <TermsOfUse /> },
      { path: URL_LOGIN_RETHINK, element: <LoginForRethink /> },
      { path: `${URL_SKILLS_SUITE}/*`, element: <TeacherCourseSkillsSuite /> },
      { path: `${URL_COURSES}/*`, element: <Courses /> },
      { path: URL_GETTING_STARTED, element: <GettingStarted /> },
    ];
    if (user.showCurriculum) {
      result.push({ path: URL_TEACHER_CURRICULUM, element: <TeacherCurriculum /> });
    } else if (user.showVerticalAlignment) {
      // result.push({ path: URL_TEACHER_VERTICAL_CURRICULUM, element: <TeacherCurriculumVerticalAlignment /> });
    }
    if (user.userAdmin) {
      result.push({ path: `${URL_ADMIN}/*`, element: <AdminHome /> });
    }
    return result;
  } else {
    return [
      { path: URL_TERMS_OF_USE, element: <TermsOfUse /> },
    ];
  }
};

export default getTeacherRoutes;

import { useEffect } from 'react';
import { Paper } from '@mui/material';
import DropdownInput from '../../../../components/selector/DropdownInput';
import './MyLessonsFilter.scss';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useMyLessonsContext } from './context/MyLessonsContextProvider';
import { useGetSubjectsByUserComponents } from '../../../hooks/SubjectsByUsersHook';
import { USER_COMPONENT_SKILL_SUITE } from '../../../../utils/User';

const MyLessonsFilter = () => {
  const { userDomain } = useDomain();
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData } = myLessonsDomain.domainData;

  const subjectListOptions = useGetSubjectsByUserComponents(true);

  useEffect(() => {
    const { userProfile } = userDomain.domainData;
    const { components } = userProfile;
    if (components.length === 1 && String(components[0].identifier) === USER_COMPONENT_SKILL_SUITE) {
      myLessonsDomain.updateSelectedLessonIdsAndFilter([], {
        ...lessonData.filter,
        selectedSubject: subjectListOptions[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className='my-lessons-filter'>
      <div className='subject-selector'>
        <DropdownInput
          dropdownValues={subjectListOptions}
          name='selectedSubject'
          value={lessonData.filter.selectedSubject ? lessonData.filter.selectedSubject : ''}
          className='subject-selector-dropdown'
          onChange={async (e) => {
            await myLessonsDomain.updateSelectedLessonIdsAndFilter([], {
              ...lessonData.filter,
              selectedSubject: e.target.value,
            });
          }}
          data-test='my-lessons-subject-dropdown'
        />
      </div>
    </Paper>
  );
};

export default MyLessonsFilter;

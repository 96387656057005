import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import UpdateButton from '../../../components/buttons/UpdateButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useAdmin } from '../../../context/AdminContextProvider';
import UpdateTeacherPanel from './forms/UpdateTeacherPanel';
import { useGetDistrictAndSchool, useGetUserDataById } from '../../../hooks/AdminUtilityHooks';
import { validateTeacherData } from './forms/update-teacher/UpdateTeacherForm';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { transformTeacherDataToTeacherFormObject, useSaveEditTeacher } from './TeacherActionHooks';
import './UpdateTeacherAction.scss';

const UpdateTeacherAction = forwardRef(({
  onTeacherUpdateSuccess,
}, ref) => {
  const [open, setOpen] = useState(false);

  const [isDataValid, setIsDataValid] = useState(false);

  // const teacherFormDataRef = useRef(null);
  const [teacherFormDataState, setTeacherFormDataState] = useState();
  const teacherSelectedStudentIdsRef = useRef(null);

  const { adminTeachersDomain } = useAdmin();
  const { selectedTeacherIds } = adminTeachersDomain.domainData;

  const handleOnCancel = () => {
    setOpen(false);
    setTeacherFormDataState();
  };

  const handleOnTeacherDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    setTeacherFormDataState(updatedData);
    // teacherFormDataRef.current = updatedData;
  };

  const handleOnStudentSelect = (updatedData) => {
    teacherSelectedStudentIdsRef.current = updatedData;
  };

  useImperativeHandle(ref, () => ({
    openUpdateTeacher: () => {
      setOpen(true);
    },
  }));

  const {
    getUserData,
    loading: teacherDataLoading,
    userData: teacherDataObject,
  } = useGetUserDataById();

  useEffect(() => {
    if (open) {
      getUserData(selectedTeacherIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  const {
    selectedDistrict,
    selectedSchool,
  } = useGetDistrictAndSchool();

  useEffect(() => {
    const teacherFormData = transformTeacherDataToTeacherFormObject(teacherDataObject);
    // teacherFormDataRef.current = teacherFormData;
    // teacherFormDataRef.current.district = selectedDistrict;
    // teacherFormDataRef.current.school = selectedSchool;

    const newState = teacherFormData;
    newState.district = selectedDistrict;
    newState.school = selectedSchool;
    setTeacherFormDataState(newState);

    const selectedStudents = teacherDataObject && teacherDataObject.data.relationships ? teacherDataObject.data.relationships.students.data : [];
    const selectedStudentIds = selectedStudents.map((s) => s.id);
    teacherSelectedStudentIdsRef.current = selectedStudentIds;
    setIsDataValid(validateTeacherData(teacherFormData, userSuperAdmin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherDataObject]);

  const {
    saveEditTeacher,
    loading: saving,
  } = useSaveEditTeacher({
    closeDialog: handleOnCancel,
    onTeacherUpdateSuccess,
  });

  const confirmActions = [{
    onClick: handleOnCancel,
    className: 'btn-danger margin-right',
    title: 'Cancel',
    loading: saving,
  }, {
    onClick: () => {
      saveEditTeacher(teacherDataObject, teacherFormDataState, teacherSelectedStudentIdsRef);
    },
    className: 'btn-primary action-button',
    title: 'Save',
    disabled: !isDataValid,
    loading: saving,
  },
  ];

  return (
    <>
      <UpdateButton
        ariaLabel='Update Teacher'
        onClick={() => { setOpen(true); }}
        disabled={!selectedTeacherIds || selectedTeacherIds.length !== 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='teacher-update-teacher-dialog'
          onClose={handleOnCancel}
          title='Edit Teacher'
          content={(
            <UpdateTeacherPanel
              teacherFormData={teacherFormDataState}
              teacherDataObject={teacherDataObject}
              teacherSelectedStudentIds={teacherSelectedStudentIdsRef.current}
              loading={teacherDataLoading}
              onTeacherFormDataChange={handleOnTeacherDataChange}
              onStudentSelect={handleOnStudentSelect}
            />
          )}
          displayCloseIcon
          actions={confirmActions}
        />
      )}
    </>
  );
});

UpdateTeacherAction.defaultProps = {
  onTeacherUpdateSuccess: () => null,
};

UpdateTeacherAction.propTypes = {
  onTeacherUpdateSuccess: PropTypes.func,
};
export default UpdateTeacherAction;

import Logger from './Logger';

/**
 * Session name for user token
 */
const SESSION_NAME_USER_TOKEN = 'SESSION_NAME_USER_TOKEN';

/**
 * Session name for user login data
 */
const SESSION_NAME_USER_LOGIN = 'SESSION_NAME_USER_LOGIN';

const LOCAL_STORAGE_LESSON_RESPONSE_EVENTS = 'LOCAL_STORAGE_LESSON_RESPONSE_EVENTS';

export const setLocalStorageLessonResponseEvents = (item) => {
  sessionStorage.setItem(LOCAL_STORAGE_LESSON_RESPONSE_EVENTS, JSON.stringify(item));
};

export const getLocalStorageLessonResponseEvents = () => (
  JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_LESSON_RESPONSE_EVENTS))
);

/**
 * Save data to session storage
 * @param {*} item item to set to the VizzleAuthoringWeb memory storage
 */
export const setVizzleAuthoringWebItem = (key, item) => {
  sessionStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get the data from the memory from VizzleAuthoringWeb memory storage
 */
export const getVizzleAuthoringWebItem = (key) => (
  JSON.parse(sessionStorage.getItem(key))
);

/**
 * Remove VizzleAuthoringWeb memory storage
 */
export const removeVizzleAuthoringWebSession = () => {
  sessionStorage.removeItem(SESSION_NAME_USER_TOKEN);

  const responseEvents = getLocalStorageLessonResponseEvents();
  sessionStorage.clear();
  setLocalStorageLessonResponseEvents(responseEvents);
};

/**
  * Save the token to the VizzleAuthoringWeb memory storage
  * @param {*} token to save
  */
export const getUserToken = () => (getVizzleAuthoringWebItem(SESSION_NAME_USER_TOKEN));

/**
 * Save the token to the VizzleAuthoringWeb memory storage
 * @param {*} token to save
 */
export const saveTokenToMemory = (token) => {
  Logger.logWhenDebugModeIsOn({
    OLD_TOKEN: getUserToken(),
    NEW_TOKEN: token,
  });
  setVizzleAuthoringWebItem(SESSION_NAME_USER_TOKEN, token);
};

/**
 * Save the user login data to the session
 */
export const saveUserLoginData = (userLoginData) => {
  Logger.logWhenDebugModeIsOn({
    USER_LOGIN: userLoginData,
  });
  localStorage.setItem(SESSION_NAME_USER_LOGIN, JSON.stringify(userLoginData));
};

/**
 * Get the user login data to the session
 */
export const getUserLoginData = () => (
  JSON.parse(localStorage.getItem(SESSION_NAME_USER_LOGIN))
);

export const clearUserLoginData = () => {
  localStorage.removeItem(SESSION_NAME_USER_LOGIN);
};

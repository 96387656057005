import { Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import TeacherCourseMainTab from './components/TeacherCourseMainTab';
import { initDomains } from './context/TeacherCourseContextProvider';
import './TeacherCourseSkillsSuite.scss';
import BackToPreviousLink from '../../components/navigator/back/BackToPreviousLink';
import TeacherCourseSearchBar from './components/TeacherCourseSearchBar';
import { nonAcademicSubjectList } from './components/TeacherCourseSkillsSuiteMenu';

const TeacherCourseSkillsSuite = () => {
  initDomains();
  const { subjectId } = useParams();
  const currentSelectSubject = [...nonAcademicSubjectList].find((s) => s.subjectId === Number(subjectId));
  const [searchParams] = useSearchParams();
  const backParam = searchParams.get('back');

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div className='teacher-course-skills-suite'>
        <TeacherTopAppbar />
        <Typography variant='h6' className='subject-title'>
          {currentSelectSubject.name}
        </Typography>
        <div className='content'>
          <div className='search-panel'>
            <div className='back-to-previous-container'>
              {Boolean(backParam) && (
                <BackToPreviousLink />
              )}
            </div>
            <TeacherCourseSearchBar />
          </div>
          <TeacherCourseMainTab />
        </div>
      </div>
    </VizzleMainContainer>
  );
};

export default TeacherCourseSkillsSuite;

import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Slide } from '@mui/material';
import TWEEN from '@tweenjs/tween.js';
import { usePreloadTheme } from './ThemeHooks';
import { themesMap } from './ThemeMapping';
import './Theme.scss';
import { useDisableRightClick } from '../TerminologiesHooks';

const Theme = ({
  themeData,
  onClick,
  lessonsCompleted,
  lessonsLength,
  themeTransition,
  onReady,
}) => {
  const [ready, setReady] = useState(false);
  const themeList = useRef([themeData]);
  const pixiLoader = usePreloadTheme(themeList.current);
  const ThemeComponent = themesMap[themeData.id];

  const intervalRef = useRef(null);

  useDisableRightClick(ready);

  useEffect(() => (() => {
    clearInterval(intervalRef.current);
  }), []);

  if (pixiLoader && intervalRef.current == null) {
    pixiLoader.onComplete.add(() => {
      intervalRef.current = setInterval(() => {
        const resources = Object.keys(pixiLoader.resources).filter((key) => key.startsWith(themeData.id));
        const notCompleted = resources.length > 0 && resources.findIndex((r) => (pixiLoader.resources[r].isLoading));
        if (notCompleted < 0) {
          clearInterval(intervalRef.current);
          setReady(true);
        }
      }, 100);
    });
  }

  useEffect(() => {
    if (ready) {
      onReady();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  // Setup the animation loop.
  function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
  }
  requestAnimationFrame(animate);

  return (
    <div className='theme-player'>
      {ready ? (
        <Slide {...themeTransition} timeout={700}>
          <div className='theme-container' data-test='theme-component'>
            <ThemeComponent
              pixiLoader={pixiLoader}
              themeData={themeData}
              onClick={onClick}
              lessonsCompleted={lessonsCompleted}
              lessonsLength={lessonsLength}
            />
          </div>
        </Slide>
      ) : (
        <div className='loading'>
          <CircularProgress size={40} />
          <div className='loading-message'>
            Theme is loading.
          </div>
        </div>
      )}
    </div>
  );
};

Theme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
  themeTransition: {
    in: true,
    direction: 'left',
  },
  onReady: () => {},
};

Theme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    manifest: PropTypes.array,
  }).isRequired,
  onClick: PropTypes.func,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
  themeTransition: PropTypes.shape({
    in: PropTypes.bool,
    direction: PropTypes.string,
  }),
  onReady: PropTypes.func,
};

export default Theme;

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import DistrictForm from './forms/DistrictForm';
import { useGetUpdateDistrictHandler } from './DistrictActionHooks';
import ViewButton from '../../../components/buttons/ViewButton';

const ViewDistrictAction = forwardRef((_props, ref) => {
  const [open, setOpen] = useState(false);

  const {
    selectedDistrictIds,
    selectedDistrictObject,
    loading,
  } = useGetUpdateDistrictHandler({
    setOpen,
  });

  const data = useRef(null);

  useEffect(() => {
    if (open) {
      data.current = selectedDistrictObject;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useImperativeHandle(ref, () => ({
    openUpdateDistrict: () => {
      setOpen(true);
    },
  }));

  const handleOnCancel = () => {
    setOpen(false);
  };

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Close',
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <ViewButton
        ariaLabel='View District'
        onClick={() => { setOpen(true); }}
        disabled={!selectedDistrictIds || selectedDistrictIds.length !== 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='update-district-dialog'
          onClose={handleOnCancel}
          title='Update District'
          content={(
            <DistrictForm
              districtData={selectedDistrictObject}
              readOnly
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
});

export default ViewDistrictAction;

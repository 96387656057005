import BaseDomain from '../../../states/domains/BaseDomain';

const TEACHER_COURSE_LESSON_COURSES = 'TEACHER_COURSE_LESSON_COURSES';
const TEACHER_COURSE_UPDATE_LESSON_DATA = 'TEACHER_COURSE_UPDATE_LESSON_DATA';
const TEACHER_COURSE_UPDATE_LESSON_LIST_DATA = 'TEACHER_COURSE_UPDATE_LESSON_LIST_DATA';
const TEACHER_COURSE_SELECTED_LESSON_IDS = 'TEACHER_COURSE_SELECTED_LESSON_IDS';
const TEACHER_COURSE_SELECTED_DATES = 'TEACHER_COURSE_SELECTED_DATES';
const TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING = 'TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING';
const TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES = 'TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES';
const TEACHER_COURSE_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES = 'TEACHER_COURSE_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES';
const TEACHER_COURSE_UPDATE_LESSONS_DATA = 'TEACHER_COURSE_UPDATE_LESSONS_DATA';
const TEACHER_COURSE_UPDATE_SEARCH_CRITERIA = 'TEACHER_COURSE_UPDATE_SEARCH_CRITERIA';
const TEACHER_COURSE_UPDATE_TOPIC_STANDARD_STATE = 'TEACHER_COURSE_UPDATE_TOPIC_STANDARD_STATE';

export const defaultFilterCriteriaAssignedStudentCourses = {
  studentIds: null,
  lessonIds: null,
};

const initialState = {
  searchCriteria: {},
  lessonData: {
    lessons: [],
    included: [],
  },
  lessonCourses: null,
  hasPerformSearch: false,
  selectedLessonIds: [],
  selectedDates: {},
  assignedStudentCoursesLoading: false,
  filterCriteriaAssignedStudentCourses: JSON.parse(JSON.stringify(defaultFilterCriteriaAssignedStudentCourses)),
  assignedStudentCourses: [],
  standards: [],
  generatedLessons: {
    data: [],
    included: [],
  },
  activeStep: 0,
  topicStandardState: 'none',
};

const reducer = (state, action) => {
  switch (action.type) {
    case TEACHER_COURSE_LESSON_COURSES:
      return {
        ...state,
        lessonCourses: action.payload,
      };

    case TEACHER_COURSE_UPDATE_LESSON_DATA:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload.lessonData,
        },
        hasPerformSearch: action.payload.hasPerformSearch,
        searchCriteria: action.payload.searchCriteria,
        selectedLessonIds: action.payload.selectedLessonIds,
        selectedDates: action.payload.selectedDates,
        assignedStudentCourses: action.payload.assignedStudentCourses,
        filterCriteriaAssignedStudentCourses: action.payload.filterCriteriaAssignedStudentCourses,
        assignedStudentCoursesLoading: action.payload.assignedStudentCoursesLoading,
        standards: action.payload.standards,
        generatedLessons: action.payload.generatedLessons,
      };
    case TEACHER_COURSE_UPDATE_LESSON_LIST_DATA:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload.lessonData,
        },
      };
    case TEACHER_COURSE_SELECTED_LESSON_IDS:
      return {
        ...state,
        selectedLessonIds: action.payload,
      };
    case TEACHER_COURSE_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload,
      };
    case TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING:
      return {
        ...state,
        assignedStudentCoursesLoading: action.payload,
      };
    case TEACHER_COURSE_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        filterCriteriaAssignedStudentCourses: action.payload,
      };
    case TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        assignedStudentCourses: action.payload.assignedStudentCourses,
        assignedStudentCoursesLoading: action.payload.assignedStudentCoursesLoading,
      };
    case TEACHER_COURSE_UPDATE_LESSONS_DATA:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload,
        },
      };
    case TEACHER_COURSE_UPDATE_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          ...action.payload,
        },
      };
    case TEACHER_COURSE_UPDATE_TOPIC_STANDARD_STATE:
      return {
        ...state,
        topicStandardState: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'teacherCourseDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateLessonCourses(courseData) {
    return this.dispatch({
      type: TEACHER_COURSE_LESSON_COURSES,
      payload: courseData,
    });
  }

  async updateLessons({
    lessons,
    included = [],
    hasPerformSearch,
    // filterCriteria,
    searchCriteria,
    selectedLessonIds,
    selectedDates,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCourses,
    assignedStudentCoursesLoading,
    standards,
    generatedLessons,
  }) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_LESSON_DATA,
      payload: {
        lessonData: {
          lessons,
          included,
        },
        hasPerformSearch,
        // filterCriteria,
        searchCriteria,
        selectedLessonIds,
        selectedDates,
        filterCriteriaAssignedStudentCourses,
        assignedStudentCourses,
        assignedStudentCoursesLoading,
        standards,
        generatedLessons,
      },
    });
  }

  async updateLessonListData(lessonData) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_LESSON_LIST_DATA,
      payload: lessonData,
    });
  }

  async updateSearchCriteria(searchCriteria) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_SEARCH_CRITERIA,
      payload: searchCriteria,
    });
  }

  async updateSelectedLessonIds(selectedLessonIds) {
    return this.dispatch({
      type: TEACHER_COURSE_SELECTED_LESSON_IDS,
      payload: selectedLessonIds,
    });
  }

  async updateSelectedDates(selectedDates) {
    return this.dispatch({
      type: TEACHER_COURSE_SELECTED_DATES,
      payload: selectedDates,
    });
  }

  async updateAssignedStudentsCoursesLoading(loading) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING,
      payload: loading,
    });
  }

  async updateFilterCriteriaAssignedStudentsCourses(filterCriteriaAssignedStudentCourses) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES,
      payload: filterCriteriaAssignedStudentCourses,
    });
  }

  async updateAssignedStudentsCourses(assignedStudentCourses, assignedStudentCoursesLoading = false) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_ASSIGNED_STUDENT_COURSES,
      payload: {
        assignedStudentCourses,
        assignedStudentCoursesLoading,
      },
    });
  }

  async updateLessonsData(lessons, included) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_LESSONS_DATA,
      payload: {
        lessons,
        included,
      },
    });
  }

  async updateTopicStandardState(topicStandardState) {
    return this.dispatch({
      type: TEACHER_COURSE_UPDATE_TOPIC_STANDARD_STATE,
      payload: topicStandardState,
    });
  }
}

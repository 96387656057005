import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Button, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetLessonCourseData } from '../../../TeacherCourseSearchBarHooks';
import { useHandleOnSearch } from '../../../search-bar-actions/SearchActionHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { LOADER_TYPE } from '../../../../../../components/loaders/SelectableLoader';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import Logger from '../../../../../../utils/Logger';
import { saveGenerateLessons } from '../../../../../../services/CourseService';
import ConfirmationDialog from '../../../../../../components/dialog/ConfirmationDialog';

const GenerateLessonByLevelAction = ({
  lessonGenerationLevel,
  onGenerateLessonSuccess,
  disabled,
}) => {
  const { subjectId } = useParams();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria: { courseId, unitId, topicId } } = teacherCourseSearchCriteriaDomain.domainData;

  const { getCourseData } = useGetLessonCourseData();
  const { handleOnSearch } = useHandleOnSearch();
  const { uiDomain } = useDomain();

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  if (lessonGenerationLevel <= 0) {
    return null;
  }

  const handleOnClick = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        uiDomain.showLoader(`Generating Level ${lessonGenerationLevel} Lessons`, LOADER_TYPE.BOUNCE_LOADER);
        const payload = {
          topicId,
          level: lessonGenerationLevel,
        };
        const generatedLessons = await saveGenerateLessons(payload);
        const courseDataPromise = getCourseData();
        const generateLessonSuccessPromise = onGenerateLessonSuccess();
        await Promise.all([courseDataPromise, generateLessonSuccessPromise]);

        await uiDomain.hideLoader();
        const total = generatedLessons.length;

        await uiDomain.showSnackbar(true, 'info', 6000, `${total} ${total > 1 ? 'activities are' : 'lesson is'} generated.`);
        await handleOnSearch({
          searchCriteria: {
            subjectId,
            courseId,
            unitId,
            topicId,
            lessonLevel: lessonGenerationLevel,
          },
        });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      await uiDomain.hideLoader();
    }
  };

  return (
    <>
      <Button
        variant='contained'
        className='btn-primary margin-right-medium'
        onClick={() => {
          setOpenConfirmDialog(true);
        }}
        startIcon={<EditIcon />}
        data-test='generate-lessons-by-levels'
        disabled={disabled}
      >
        GENERATE LEVEL {lessonGenerationLevel} ACTIVITIES
      </Button>
      {openConfirmDialog && (
        <ConfirmationDialog
          open={openConfirmDialog}
          title='Generate Activities'
          contentText={(
            <div>
              <Typography>
                Are you sure you want to generate level {lessonGenerationLevel} activites?
              </Typography>
              <Typography>
                {lessonGenerationLevel === 2 && (
                  'This cannot be undone and no more topic templates may be added.'
                )}
                {lessonGenerationLevel === 3 && (
                  'This cannot be undone.'
                )}
              </Typography>
            </div>
          )}
          confirmLabel='Continue'
          cancelLabel='Go Back'
          onConfirm={() => {
            setOpenConfirmDialog(false);
            handleOnClick();
          }}
          onCancel={() => { setOpenConfirmDialog(false); }}
          confirmClass='wide-button'
          cancelClass='wide-button'
        />
      )}
    </>
  );
};

GenerateLessonByLevelAction.defaultProps = {
  lessonGenerationLevel: 0,
  onGenerateLessonSuccess: () => null,
  disabled: true,
};

GenerateLessonByLevelAction.propTypes = {
  lessonGenerationLevel: PropTypes.number,
  onGenerateLessonSuccess: PropTypes.func,
  disabled: PropTypes.bool,
};

export default GenerateLessonByLevelAction;

import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
} from '@mui/material';
import './VizzleAppbar.scss';
import ObjectUtils from '../../utils/ObjectUtils';

/**
 * App bar of the application that automatically adjust the height
 * of the content
 */
export default function VizzleAppBar({
  children,
  appbarPosition,
  onAppbarChangeSize,
  ...props
}) {
  const [appBarState, setAppBarState] = useState({
    height: 100,
  });

  const appBarRefEl = useRef(null);

  useEffect(() => {
    if (appBarRefEl && appBarRefEl.current) {
      ObjectUtils.setTimeout(() => {
        setAppBarState({
          ...appBarState,
          height: appBarRefEl.current.clientHeight,
        });
        onAppbarChangeSize(appBarRefEl.current.clientHeight);
      }, 100);
    }
    // eslint-disable-next-line
  }, [appBarRefEl.current]);

  const appbarPositionClass = {
    top: 'vizzle-app-bar-position-top',
    bottom: 'vizzle-app-bar-position-bottom',
  };

  const appbarPositionContainerClass = {
    top: '',
    bottom: 'vizzle-app-bar-container-position-bottom',
  };

  return (
    <div
      style={{ height: appBarState.height, minHeight: appBarState.height }}
      className={`vizzle-app-bar-position-component ${appbarPositionContainerClass[appbarPosition]}`}
    >
      <AppBar
        ref={appBarRefEl}
        position='fixed'
        color='default'
        className={appbarPositionClass[appbarPosition]}
        {...props}
      >
        {children}
      </AppBar>
    </div>
  );
}

VizzleAppBar.defaultProps = {
  appbarPosition: 'top',
  onAppbarChangeSize: () => { },
};

VizzleAppBar.propTypes = {
  children: PropTypes.node.isRequired,
  appbarPosition: PropTypes.oneOf(['top', 'bottom']),
  onAppbarChangeSize: PropTypes.func,
};

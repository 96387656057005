import { Button } from '@mui/material';
import CustomTooltip from '../../../../../components/card/CustomTooltip';
import { useGetActivityReports, useGetTeacherList } from '../AdminDataHooks';
import '../../../components/buttons/Buttons.scss';
import ObjectUtils from '../../../../../utils/ObjectUtils';

const GenerateReportAction = () => {
  const {
    getTeacherListData,
  } = useGetTeacherList();

  const {
    getActivityReportsData,
  } = useGetActivityReports();

  return (
    <CustomTooltip title='Generate Report' aria-label='Generate Report'>
      <Button
        variant='contained'
        className='btn-primary admin-action-multiple-line-label'
        onClick={() => {
          // To ensure that the domain data is updated before making a service call
          ObjectUtils.setTimeout(() => {
            getTeacherListData();
            getActivityReportsData();
          });
        }}
      >
        Generate <br />Report
      </Button>
    </CustomTooltip>
  );
};

export default GenerateReportAction;

import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
} from '@mui/material';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
  SPINNER_BOARD_NUMBER,
} from '../../../../../components/vizzle/page/game/GameConstants';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import GameActivityUtils from '../../../../../components/vizzle/page/game/GameActivityUtils';
import './GamePlayActivityCellCard.scss';
import { useRegisterSwitchAccessScanEvent } from '../../../../switch-access/SwitchAccessHooks';

const GamePlayActivityCellCard = forwardRef(({
  id,
  cardIndex,
  text,
  image,
  borderColor,
  bgColor,
  spinner,
  gameSpaceType,
  onCardClick,
  textLineId,
}, ref) => {
  const imageToUse = gameSpaceType === TILE_TYPE_STANDARD ? image : GameActivityUtils.getSpaceTypeImage(spinner, gameSpaceType);
  const extraImage = gameSpaceType === TILE_TYPE_LOSE_A_TURN ? GameActivityUtils.getSpaceTypeExtraImage(gameSpaceType) : '';
  const cardId = `game-card-${id}-${cardIndex}`;

  const componentRef = useRef(null);
  useRegisterSwitchAccessScanEvent(componentRef, () => {
    componentRef.current.click();
  });

  useImperativeHandle(ref, () => ({
    getElement: () => componentRef.current,
  }));

  return (
    <Card
      className='game-card game-activity-card-cell'
      id={cardId}
      key={cardId}
      onClick={(e) => onCardClick(e, cardIndex)}
      style={{ borderColor, backgroundColor: bgColor }}
      ref={componentRef}
      data-switch-access-scan='true'
    >
      {
        gameSpaceType !== TILE_TYPE_STANDARD
          ? (
            <div className='card-text-container special-card-text' id={`${textLineId}-0`}>
              {GameActivityUtils.getSpecialCardText(gameSpaceType)}
            </div>
          ) : ''
      }

      {imageToUse ? (
        <div className={`card-image-container ${!imageToUse ? 'no-height' : ''}`}>
          <CardMedia
            className={`card-image ${text ? 'image-with-text' : ''}`}
            image={imageToUse || 'none'}
            title='Card Image'
            data-test='card-image-container'
          />
          {extraImage ? (
            <img
              className='extra-icon'
              src={extraImage}
              alt='Extra'
            />
          ) : ''}
        </div>
      ) : ''}
      {text ? (
        <div className='card-text-container' data-test='card-text-container'>
          <TextLines textLines={text} componentKey={textLineId} />
        </div>
      )
        : ''}
    </Card>
  );
});

GamePlayActivityCellCard.defaultProps = {
  text: null,
  image: null,
  borderColor: 'black',
  bgColor: null,
  gameSpaceType: TILE_TYPE_STANDARD,
  spinner: SPINNER_BOARD_NUMBER,
  onCardClick: () => { },
  textLineId: null,
};

GamePlayActivityCellCard.propTypes = {
  id: PropTypes.string.isRequired,
  cardIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object,
  image: PropTypes.string,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  gameSpaceType: PropTypes.number,
  spinner: PropTypes.number,
  onCardClick: PropTypes.func,
  textLineId: PropTypes.string,
};

export default GamePlayActivityCellCard;

import {
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import DateFromToForm from '../../../../components/form/DateFromToForm';
import MultiSelector from '../../../../components/selector/MultiSelector';
import ReportTable from '../../../../components/table/ReportTable';
import { useClassRoomReport, useTableStateManagement, createRenderer, ALL_SELECTOR } from './useClassRoomReport';
import { dateFormatMMddyyyy } from '../../../../AppConstants';
import { URL_MY_STUDENT } from '../../../../routes/RouteConstants';
import './ClassroomReport.scss';
import { useUserLink } from '../../MyClassRoomUtils';
// import { MY_CLASSROOM_ROUTES } from '../../MyClassRoomConstant';
import { useGetSubjectsByUserComponents } from '../../../hooks/SubjectsByUsersHook';

const fileName = 'VizZle NextGen';
const pdfTitle = 'Class Summary';

const ClassroomReport = () => {
  const {
    loading,
    hasLoaded,
    selectedCriteria,
    validDates,
    tableData,
    handleOnDateChange,
    handleOnSelectorChange,
    handleOnSubmitButtonClick,
  } = useClassRoomReport();

  const {
    tableState,
    handleOnTableStateChange,
  } = useTableStateManagement();

  const goToMyStudent = useUserLink({
    pathName: URL_MY_STUDENT,
    // goBackToPathName: `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.CLASS_REPORT}`,
    // label: 'Back To Classroom',
  });

  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '15%',
      skipDataRenderForReport: true,
      dataRenderer: (data) => {
        const { userName } = data;
        return (
          <Typography className='user-name-link' data-private onClick={() => { goToMyStudent(data); }}>{userName}</Typography>
        );
      },
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '15%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '15%',
    },
    {
      title: 'Total Activities',
      align: 'center',
      dataField: 'totalLessons',
      width: '13%',
    },
    {
      title: 'Questions Answered',
      align: 'center',
      dataField: 'totalQuestionsAnswered',
      width: '15%',
    },
    {
      title: 'Percentage Correct',
      align: 'center',
      dataField: 'correctPercentage',
      width: '15%',
      dataRenderer: (data) => {
        if (data.totalQuestionsAnswered > 0) {
          return data.correctPercentage > 0 ? data.correctPercentage.toString() : '0';
        } else {
          return data.correctPercentage > 0 ? data.correctPercentage.toString() : 'N/A';
        }
      },
    },
    {
      title: 'Total Time (Min)',
      align: 'center',
      dataField: 'totalTimeInMinutes',
      width: '16%',
    },
  ];

  const subjects = useGetSubjectsByUserComponents();
  subjects.unshift(ALL_SELECTOR);

  const displayTable = () => (
    hasLoaded ? (
      <ReportTable
        config={tableConfig}
        tableData={tableData}
        fileName={fileName}
        title={pdfTitle}
        loading={loading}
        onTableStateChange={handleOnTableStateChange}
        initTableState={tableState}
      />
    ) : <div className='search-text'>Please perform a search to retrieve data for your class</div>
  );

  return (
    <div className='classroom-report'>
      <DateFromToForm
        startDate={selectedCriteria.startDate}
        endDate={selectedCriteria.endDate}
        dateFormat={dateFormatMMddyyyy}
        onChange={handleOnDateChange}
      >
        <div className='additional-criteria'>
          <FormControl className='criteria-container'>
            <InputLabel htmlFor='detailed-report-subject'>Subject</InputLabel>
            <MultiSelector
              inputProps={{
                name: 'subject-title',
                id: 'detailed-report-subject',
              }}
              className='criterial-selector'
              selectorItems={subjects}
              onChange={(event) => { handleOnSelectorChange(event.target.value, 'subjects', subjects[0].value); }}
              values={selectedCriteria.subjects}
              renderValue={(selected) => createRenderer(selected, subjects)}
              variant='filled'
            />
          </FormControl>
        </div>
        <ButtonWithSpinner
          className='submit-button'
          label='Submit'
          loading={loading}
          onClick={handleOnSubmitButtonClick}
          disabled={!validDates}
          data-test='submit-button'
        />
      </DateFromToForm>

      <div className='data-table'>
        {displayTable()}
      </div>
    </div>
  );
};

export default ClassroomReport;

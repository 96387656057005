import { createContext, useContext } from 'react';
import UserDomain from './domains/UserDomain';
import StudentsDomain from './domains/StudentsDomain';
import AuthoredLessonsDomain from './domains/AuthoredLessonsDomain';
import UiDomain from './domains/UiDomain';
import LessonDataMaintenanceDomain from './domains/LessonDataMaintenanceDomain';
import LessonStandardDomain from './domains/LessonStandardDomain';
import AuthoringProfileDomain from './domains/AuthoringProfileDomain';
import { removeVizzleAuthoringWebSession } from '../utils/VizzleSessionStorage';

/**
 * Application domain (react hook for reducer) is a layer that
 * responsible for state management of the application.
 * Instead of using useReducer directly from react hook library,
 * Domain layer encapsulate dispatching event and data of the reducer.
 * The consumer (container layer) can manage the state of the application via the
 * provided methods rather than create a payload and call dispatch function directly.
 *
 * Domain can be separated based upon the data domain e.g. user, lessons and so on.
 */

/*-----------------------------------------------------------------------*/
// Register domains here
const domains = [
  new UserDomain(true), // When the user refresh the screen the user data should not be removed
  new UiDomain(true),
  new StudentsDomain(true),
  new AuthoredLessonsDomain(),
  new LessonDataMaintenanceDomain(),
  new LessonStandardDomain(),
  new AuthoringProfileDomain(),
];

/*-----------------------------------------------------------------------*/

/**
 * Initialize application domains and add them to the context so that
 * they can be used accross the application
 */
let AppDomainProviderContext;
const result = {};
export const initAppDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  AppDomainProviderContext = createContext(result);
};

/** Get application domains fromt the context */
export const useDomain = () => useContext(AppDomainProviderContext);

export const clearAllDomainData = () => {
  domains.forEach((element) => {
    element.resetDomainData();
  });
  removeVizzleAuthoringWebSession();
};

import { useEffect, useState } from 'react';
import { useGroupPlay } from '../context/GroupPlayContextProvider';
import {
  createExternalLink,
  updateExternalLink,
  deleteExternalLink,
} from '../../../../../services/ExternalLinkService';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';

export const useDeleteExternalLink = () => {
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { callRemoteServiceWrapper, loading: deleting } = useGetRemoteServiceWrapper();
  const { groupPlayDomain } = useGroupPlay();
  const { externalLinks } = groupPlayDomain.domainData;

  const deleteLink = async (data) => {
    await callRemoteServiceWrapper(async () => {
      try {
        await callRemoteServiceWrapper(async () => {
          await deleteExternalLink(user.id, data.id);
          const dataToUpdate = externalLinks.filter((e) => e.id !== data.id);
          await groupPlayDomain.updateGroupPlayExternalLinks(dataToUpdate);
        });
        await uiDomain.showSnackbar(true, 'success', 3000, `The link ${data.attributes.link} has been deleted.`);
      } catch (e) {
        Logger.logError({
          ERROR: e,
        });
      }
    });
  };

  return {
    deleting,
    deleteLink,
  };
};

export const useControlCreateUpdateDialog = () => {
  const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);

  const openDialog = () => {
    setOpenCreateUpdateDialog(true);
  };

  const closeDialog = () => {
    setOpenCreateUpdateDialog(false);
  };

  return {
    openCreateUpdateDialog,
    openDialog,
    closeDialog,
  };
};
function isValidHttpUrl(url) {
  // eslint-disable-next-line max-len
  return url.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/);
}

export const useAddExternalLink = (openCreateUpdateDialog, closeDialog) => {
  const [externalLink, setExternalLink] = useState();
  const { groupPlayDomain } = useGroupPlay();
  const { externalLinks } = groupPlayDomain.domainData;
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { loading: saving, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  useEffect(() => {
    setExternalLink({
      url: '',
      label: '',
      error: {
        isError: false,
        helperText: '',
      },
    });
  }, [openCreateUpdateDialog]);

  const onExternalLinkChange = (event) => {
    const { value } = event.target;
    const isValid = isValidHttpUrl(value);
    setExternalLink({
      ...externalLink,
      url: value,
      error: {
        isError: !isValid && value !== '',
        helperText: !isValid && value !== '' && 'Please enter a valid url',
      },
    });
  };

  const onExternalLinkLabelChange = (event) => {
    const { value } = event.target;
    setExternalLink({
      ...externalLink,
      label: value,
    });
  };

  const addExternalLink = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await createExternalLink(user.id, externalLink.url, externalLink.label);
        externalLinks.push(result.data);
        await groupPlayDomain.updateGroupPlayExternalLinks(externalLinks);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `The link ${externalLink.url} has been created.`);
      closeDialog();
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    externalLink,
    onExternalLinkChange,
    onExternalLinkLabelChange,
    addExternalLink,
    saving,
  };
};

export const useUpdateExternalLink = (openCreateUpdateDialog, data, closeDialog) => {
  const [externalLinkValue, setExternalLinkValue] = useState({
    url: '',
    label: '',
    error: {},
  });
  const { groupPlayDomain } = useGroupPlay();
  const { externalLinks } = groupPlayDomain.domainData;
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { loading: saving, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  useEffect(() => {
    if (openCreateUpdateDialog) {
      setExternalLinkValue({
        url: data.attributes.link,
        label: data.attributes.label,
        error: {
          isError: false,
          helperText: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCreateUpdateDialog]);

  const onExternalLinkChange = (event) => {
    const { value } = event.target;
    const isValid = isValidHttpUrl(value);
    setExternalLinkValue({
      ...externalLinkValue,
      url: value,
      error: {
        isError: !isValid && value !== '',
        helperText: !isValid && value !== '' && 'Please enter a valid url',
      },
    });
  };

  const onExternalLinkLabelChange = (event) => {
    const { value } = event.target;
    setExternalLinkValue({
      ...externalLinkValue,
      label: value,
    });
  };

  const updateLink = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await updateExternalLink(user.id, data.id, externalLinkValue.url, externalLinkValue.label);
        const valueToUpdate = externalLinks.find((e) => e.id === data.id);
        valueToUpdate.attributes.link = externalLinkValue.url;
        valueToUpdate.attributes.label = externalLinkValue.label;
        await groupPlayDomain.updateGroupPlayExternalLinks(externalLinks);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `The link has been update to ${externalLinkValue.url}.`);
      closeDialog();
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    externalLinkValue,
    onExternalLinkChange,
    onExternalLinkLabelChange,
    updateLink,
    saving,
  };
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  MenuItem,
  TextField,
} from '@mui/material';
import LandingInteractionEditorDialog from './editor/LandingInteractionEditorDialog';
import ConfirmationDialog from '../../../../../../../components/dialog/ConfirmationDialog';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import { landingInteractionTypes, isLandingInteractionEmpty } from './editor/LandingInteractionUtils';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
  TILE_TYPE_SPIN_AGAIN,
  TILE_TYPE_FORWARD,
  TILE_TYPE_BACKWARD_2_SPACES,
} from '../../../../../../../components/vizzle/page/game/GameConstants';
import ObjectUtils from '../../../../../../../utils/ObjectUtils';

const tileTypes = [
  {
    value: TILE_TYPE_STANDARD,
    label: 'Standard',
  },
  {
    value: TILE_TYPE_SPIN_AGAIN,
    label: 'Spin again',
  },
  {
    value: TILE_TYPE_LOSE_A_TURN,
    label: 'Lose a Turn',
  },
  {
    value: TILE_TYPE_FORWARD,
    label: 'Forward 1',
  },
  {
    value: TILE_TYPE_BACKWARD_2_SPACES,
    label: 'Backward 2',
  },
];

const GameCardSetup = ({ selectedCardIndex }) => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];
  const currentGameCard = selectedPage.gameCards[selectedCardIndex];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [landingInteractionType, setLandingInteractionType] = useState(landingInteractionTypes[0].value);
  const [state, setState] = useState({
    changeLayoutDialog: {
      open: false,
      selectedLayoutType: {},
    },
  });
  const [tileTypeDialogState, setTileTypeDialogState] = useState({
    open: false,
    selectedTileType: {},
  });

  useEffect(() => {
    setLandingInteractionType(currentGameCard.landingInteraction ? currentGameCard.landingInteraction.type : landingInteractionTypes[0].value);
    // eslint-disable-next-line
  }, [selectedCardIndex]);

  const handleOnTileTypeChange = (e) => {
    e.stopPropagation();
    const { value } = e.target;
    const standardValue = TILE_TYPE_STANDARD;
    if (value === currentGameCard.gameSpaceType) {
      return;
    }
    // Change from Standard to other types
    const { type, ...landingInteractionProps } = currentGameCard.landingInteraction || {};
    if (selectedPage.gameCards[selectedCardIndex].gameSpaceType === standardValue
      && value !== standardValue
      && (
        !ObjectUtils.isEmpty(landingInteractionProps)
        || currentGameCard.text
        || currentGameCard.image
        || currentGameCard.video
        || currentGameCard.audio)
    ) {
      setTileTypeDialogState({
        ...tileTypeDialogState,
        open: true,
        selectedTileType: tileTypes.find((tile) => tile.value === value),
      });
    } else {
      currentGameCard.gameSpaceType = value;
      selectedPage.gameCards[selectedCardIndex] = currentGameCard;
      lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    }
  };

  const changeLandingInteractionType = async (value) => {
    setLandingInteractionType(value);
    if (value === landingInteractionTypes[0].value) {
      selectedPage.gameCards[selectedCardIndex].landingInteraction = null;
      lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
      return;
    }

    const landingInteraction = {};
    landingInteraction.type = value;
    selectedPage.gameCards[selectedCardIndex].landingInteraction = landingInteraction;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const handleOnLandingInteractionTypeChange = (e) => {
    e.stopPropagation();
    const { value } = e.target;

    // Switch layout
    const selectedData = landingInteractionTypes.find((p) => (
      value === p.value
    ));

    // no change
    if (selectedPage.gameCards[selectedCardIndex].landingInteraction && value === selectedPage.gameCards[selectedCardIndex].landingInteraction.type) {
      return;
    }

    // Check current value here, if it is empty then switch it
    if (isLandingInteractionEmpty(selectedPage.gameCards[selectedCardIndex].landingInteraction)) {
      changeLandingInteractionType(value);
    } else {
      // Not an empty card
      setState({
        ...state,
        changeLayoutDialog: {
          open: true,
          selectedLayoutType: selectedData,
        },
      });
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleOnDialogClose = () => {
    setDialogOpen(false);
  };

  const handleOnDataSave = async (e, data) => {
    if (e) {
      e.stopPropagation();
    }
    selectedPage.gameCards[selectedCardIndex].landingInteraction = data;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    handleOnDialogClose();
  };

  const dialogProps = {
    open: state.changeLayoutDialog.open,
    title: 'Change Page Layout',
    contentText: (
      <span>
        This will delete my existing quiz/pop-up,
        <br />
        do I want to continue?
      </span>
    ),
    confirmLabel: 'Yes',
    cancelLabel: 'No',
    onConfirm: async () => {
      const { selectedLayoutType: { value } } = state.changeLayoutDialog;
      await changeLandingInteractionType(value);
      setState({
        ...state,
        selectedLayout: value,
        changeLayoutDialog: {
          ...state.changeLayoutDialog,
          open: false,
        },
      });
    },
    onCancel: () => {
      setState({
        ...state,
        changeLayoutDialog: {
          ...state.changeLayoutDialog,
          open: false,
        },
      });
    },
  };

  const dialogTileTypeProps = {
    open: tileTypeDialogState.open,
    title: 'Change Tile Type',
    contentText: (
      <span>
        This will delete my existing quiz/pop-ups,
        <br />
        do I want to continue?
      </span>
    ),
    confirmLabel: 'Yes',
    cancelLabel: 'No',
    onConfirm: async () => {
      currentGameCard.gameSpaceType = tileTypeDialogState.selectedTileType.value;
      currentGameCard.landingInteraction = null;
      currentGameCard.text = null;
      currentGameCard.image = null;
      currentGameCard.video = null;
      currentGameCard.video = null;
      selectedPage.gameCards[selectedCardIndex] = currentGameCard;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
      setTileTypeDialogState({
        ...tileTypeDialogState,
        open: false,
        selectedTileType: {},
      });
      setLandingInteractionType(landingInteractionTypes[0].value);
    },
    onCancel: () => {
      setTileTypeDialogState({
        ...tileTypeDialogState,
        open: false,
        selectedTileType: {},
      });
    },
  };

  const tileTypesToUse = selectedCardIndex < 2 ? tileTypes.filter((t) => t.value !== TILE_TYPE_BACKWARD_2_SPACES) : tileTypes;
  return (
    <>
      <Box display='flex' flexDirection='row'>
        <Box width='33%' mr='5px'>
          <TextField
            select
            label='Tile Type'
            value={currentGameCard.gameSpaceType}
            variant='outlined'
            onChange={handleOnTileTypeChange}
            fullWidth
            disabled={selectedCardIndex === 0 || selectedCardIndex === selectedPage.gameCards.length - 1}
          >
            {tileTypesToUse.map((element, index) => (
              <MenuItem key={`grid-card-editor-tile-type-${index}`} value={element.value}>
                {element.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box width='65%' mr='5px' display='flex' flexDirection='row'>
          <TextField
            select
            label='Include a quiz or pop-up'
            value={landingInteractionType}
            variant='outlined'
            onChange={handleOnLandingInteractionTypeChange}
            fullWidth
            disabled={
              selectedCardIndex === 0
              || selectedCardIndex === selectedPage.gameCards.length - 1
              || currentGameCard.gameSpaceType !== 1
            }
          >
            {landingInteractionTypes.map((element, index) => (
              <MenuItem key={`grid-card-editor-pop-up-type-${index}`} value={element.value}>
                {element.label}
              </MenuItem>
            ))}
          </TextField>

          <Box width='23%' display='flex' height='100%' ml='5px'>
            <Button
              variant='contained'
              fullWidth
              className='btn-primary vizzle-edit-pop-up-button'
              disabled={landingInteractionType === landingInteractionTypes[0].value}
              onClick={openDialog}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
      {dialogOpen && (
        <LandingInteractionEditorDialog
          open={dialogOpen}
          onClose={handleOnDialogClose}
          className='vizle-landing-interaction-editor-popup'
          landingInteractionType={landingInteractionType}
          selectedCardIndex={selectedCardIndex}
          onDataSave={handleOnDataSave}
        />
      )}

      <ConfirmationDialog {...dialogProps} className='vizzle-game-activity-change-layout-dialog' />
      <ConfirmationDialog {...dialogTileTypeProps} className='vizzle-game-activity-change-tile-type-dialog' />
    </>
  );
};

GameCardSetup.defaultProps = {
  selectedCardIndex: 0,
};

GameCardSetup.propTypes = {
  selectedCardIndex: PropTypes.number,
};

export default GameCardSetup;

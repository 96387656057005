import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';
import StudentSearchForm from './StudentSearchForm';
import { useDomain } from '../../../states/AppDomainProvider';
import { useGoToAddStudent } from '../../hooks/StudentHooks';
import './StudentSelector.scss';

const StudentSelector = ({
  selectedStudentIds,
  currentStudent,
  onStudentSelectionChange,
  createGap,
  className,
}) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;
  const [selectAll, setSelectAll] = useState(false);
  const [displayedStudents, setDisplayedStudents] = useState(students);
  const goToAddStudentMenu = useGoToAddStudent();

  if (!students || students.length === 0) {
    const handleOnLinkClick = () => {
      goToAddStudentMenu();
    };

    return (
      <div className={`student-list-student-selector-no-students ${className}`}>
        {createGap && (
          <>
            <div className='gap' />
            <Divider />
            <div className='gap' />
          </>
        )}
        <div className='no-students'>
          <Typography className='title' variant='subtitle1'>
            NO STUDENTS ADDED TO YOUR ACCOUNT.
          </Typography>
          <Button className='add-student-link' onClick={handleOnLinkClick}>Please add students</Button>
        </div>
      </div>
    );
  }

  const isAllSelected = (selectedIds, matchedStudent = displayedStudents) => {
    if (matchedStudent.length === 0) {
      return false;
    }
    const ids = matchedStudent.map((s) => s.id.toString());
    ids.sort((a, b) => a.toString().localeCompare(b));
    const uIds = [...new Set(ids)];

    const uSelectedIds = [...new Set(selectedIds)];
    uSelectedIds.sort((a, b) => a.toString().localeCompare(b));

    return uIds.every((val) => uSelectedIds.includes(val));
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    const selectedIds = displayedStudents.map((s) => s.id.toString());
    let updatedList = [];
    if (e.target.checked) {
      updatedList = [
        ...selectedStudentIds,
        ...selectedIds,
      ];
    } else {
      updatedList = selectedStudentIds.filter((s) => !selectedIds.includes(s));
      if (currentStudent) {
        updatedList.push(currentStudent.id.toString());
      }
    }
    const result = [...new Set(updatedList)];
    onStudentSelectionChange(result);
  };

  const handleOnKeywordChange = (keyword) => {
    const matchedStudent = students.filter((s) => {
      const name = `${s.firstName} ${s.lastName}`.toLowerCase();
      const userName = s.userName.toLowerCase();
      const isNameMatch = name.indexOf(keyword.toLowerCase()) > -1;
      const isUsernameMatch = userName.indexOf(keyword.toLowerCase()) > -1;
      return isNameMatch || isUsernameMatch;
    });
    setSelectAll(isAllSelected(selectedStudentIds, matchedStudent));
    setDisplayedStudents(matchedStudent);
  };

  const handleStudentSelectChange = (studentId) => {
    let updatedStudentIds = [...selectedStudentIds];
    if (selectedStudentIds.includes(studentId)) {
      updatedStudentIds = selectedStudentIds.filter((s) => s !== studentId);
    } else {
      updatedStudentIds.push(studentId);
    }
    setSelectAll(isAllSelected(updatedStudentIds));
    onStudentSelectionChange(updatedStudentIds);
  };

  return (
    <div className={`student-list-student-selector ${className}`}>
      <StudentSearchForm
        onKeywordChange={handleOnKeywordChange}
        title='SEARCH YOUR STUDENTS'
        placeholder='Student Name'
      />
      <div className='students' data-test='student-list'>
        <Typography variant='subtitle2'>
          STUDENT LIST
        </Typography>
        <FormControlLabel
          control={(
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
              name='Select All'
            />
          )}
          data-test='select-all-students'
          label={<span className='select-all-label'>SELECT ALL</span>}
        />

        <div className='student-list'>
          {
            displayedStudents.map((s) => (
              <FormControlLabel
                key={`student-selector-student-${s.id}`}
                control={(
                  <Checkbox
                    checked={selectedStudentIds && selectedStudentIds.includes(s.id.toString())}
                    onChange={() => handleStudentSelectChange(s.id.toString())}
                    name={`Student ${s.firstName} ${s.lastName} ${s.id}`}
                  />
                )}
                data-test={`student-${s.id}`}
                label={`${s.firstName} ${s.lastName}`}
                className={currentStudent && currentStudent.id.toString() === s.id.toString() ? 'selected-student' : ''}
                data-private
              />
            ))
          }
        </div>

      </div>
    </div>
  );
};

StudentSelector.defaultProps = {
  selectedStudentIds: [],
  currentStudent: null,
  onStudentSelectionChange: () => { },
  createGap: false,
  className: '',
};

StudentSelector.propTypes = {
  currentStudent: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  selectedStudentIds: PropTypes.arrayOf(PropTypes.string),
  onStudentSelectionChange: PropTypes.func,
  createGap: PropTypes.bool,
  className: PropTypes.string,
};

export default StudentSelector;

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import { getPageStyle } from '../common/LessonPageUtil';
import './MatchingThumbnail.scss';

export default function MatchingThumbnail({ promptCards, responseCards }) {
  const getCard = (card, thumbailClass) => (
    <Card className={thumbailClass} style={getPageStyle(card, true)}>
      <Box className='matching-thumbnail-image-container'>
        {
          card.image ? (
            <CardMedia
              className='matching-thumbnail-image'
              image={card.image}
              title='Matching'
            />

          )
            : (
              <Box
                component='div'
                className='matching-thumbnail-placeholder'
              >
                Image
              </Box>
            )
        }
      </Box>
      <Box className='matching-thumbnail-text-container'>
        {card.text
          ? (
            <Box className='matching-thumbnail-with-value'>
              <TextLines textLines={card.text} isThumbnail />
            </Box>
          ) : (
            <Box className='matching-thumbnail-placeholder'>Text</Box>
          )}
      </Box>
    </Card>
  );

  return (
    <Box className='matching-thumbnail-component vizzle-matching-thumbnail'>
      <Box className='matching-thumbnail-prompts-area'>
        {promptCards.map((card) => (
          <Box key={uuidv1()} style={{ width: `${(100 / promptCards.length)}%`, height: '100%' }}>
            {getCard(card, 'matching-thumbnail-prompts-card')}
          </Box>
        ))}
      </Box>
      <Box className='matching-thumbnail-responses-area'>
        {responseCards.map((card) => (
          <Box key={uuidv1()} style={{ width: `${(100 / responseCards.length)}%`, height: '100%' }}>
            {getCard(card, 'matching-thumbnail-responses-card')}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

MatchingThumbnail.defaultProps = {
  promptCards: [{}],
  responseCards: [{}, {}, {}, {}, {}],
};

MatchingThumbnail.propTypes = {
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),

  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
};

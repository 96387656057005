export default function Timer(fn, t) {
  const time = t;
  let timerObj = null;

  this.stop = () => {
    if (timerObj) {
      clearInterval(timerObj);
      timerObj = null;
    }
    return this;
  };

  // start timer using current settings (if it's not already running)
  this.start = () => {
    if (!timerObj) {
      this.stop();
      timerObj = setInterval(fn, time);
    }
    return this;
  };

  // start with new interval, stop current interval
  this.reset = () => (
    this.stop().start()
  );
}

import { useDomain } from '../../../../../states/AppDomainProvider';
import './ModeratorKeywordsEditor.scss';
import KeywordsEditor from '../../../../../components/form/KeywordsEditor';

const KEYWORD_LIMIT = 100;
export default function ModeratorKeyworksEditor() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const lessonKeywords = lessonData.attributes.lessonKeywords || [];

  return (
    <div className='moderator-keywords-editor'>
      <KeywordsEditor
        keywords={lessonKeywords}
        onKeywordsUpdate={(updatedKeywords) => {
          lessonDataMaintenanceDomain.updateLessonData({
            ...lessonData,
            attributes: {
              ...lessonData.attributes,
              lessonKeywords: updatedKeywords,
            },
          });
        }}
        totalKeywordLimit={KEYWORD_LIMIT}
      />
    </div>
  );
}

import vizzleAxios from './service';

export const TRACKING_STATUS = {
  PREVIEW: 'PREVIEW',
  LESSON_PLAN: 'LESSON_PLAN',
  UNIT_GUIDE: 'UNIT_GUIDE',
};

export const trackTeacherActivity = async (payload) => {
  const url = '/tracking';
  const result = await vizzleAxios.put(url, payload);
  return result;
};

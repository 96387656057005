// import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import CourseActiveAction from '../topic-lessons/actions/CourseActiveAction';
import EditTopicAction from '../topic-lessons/actions/EditTopicAction';
import EditUnitAction from '../topic-lessons/actions/EditUnitAction';
import { useGetGenteratedLessonData, useGetLessonGenerationLevel } from './GeneratedLessonsHooks';
import './GeneratedLessons.scss';
import FlatLessonCardListDetail from '../../../../../components/vizzle/cardlistdetail/lesson/FlatLessonCardListDetail';
import { calculateCardSize } from '../../../../../utils/CardSizeCalculator';
import AddTemplatesAction from './actions/AddTemplatesAction';
import GenerateLessonByLevelAction from './actions/GenerateLessonByLevelAction';
import EditLessonAction from '../../../../lessons/lessonactions/EditLessonAction';
// import { URL_COURSES } from '../../../../../routes/RouteConstants';
import PreviewLessonAction from '../../../../lessons/lessonactions/PreviewLessonAction';
// import { TEACHER_COURSES_ROUTES } from '../../TeacherCourseConstants';

const standardCardSize = calculateCardSize();
const cardSize = {
  width: standardCardSize.width * 0.84,
  slidesToShow: standardCardSize.slidesToShow,
  slidesToScroll: standardCardSize.slidesToScroll,
};

const GeneratedLessons = () => {
  // const { subjectId: currentSelectedSubjectId } = useParams();
  const {
    hasPerformSearch,
    generatedLessons,
  } = useGetGenteratedLessonData();

  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const {
    lessonLevel,
    lessonGeneration,
    getLessonLevel,
    loading,
  } = useGetLessonGenerationLevel();

  // const showGenerateLesson = [2, 3].includes(lessonGeneration.lessonGenerationLevel);

  const filteredLessons = {
    ...generatedLessons,
    lessons: generatedLessons && generatedLessons.lessons ? generatedLessons.lessons.filter((l) => l.attributes.approvalStatus === null || l.attributes.approvalStatus === undefined) : [],
  };

  return (
    <div className='teacher-course-generated-lessons'>

      {hasPerformSearch ? (
        <div className='lessons'>
          {user.userModerator && !loading && [1, 2].includes(lessonLevel) && (
            <fieldset className='actions-container-fieldset'>
              <legend>Actions</legend>
              <div className='actions-container' data-test='actions-container'>
                {[1, 2].includes(lessonLevel) && (
                  <GenerateLessonByLevelAction
                    lessonGenerationLevel={lessonLevel + 1}
                    onGenerateLessonSuccess={getLessonLevel}
                    disabled={lessonGeneration.lessonIds.length === 0}
                  />
                )}

                {lessonLevel === 1 && (
                  <AddTemplatesAction
                    lessonGenerationLevel={lessonLevel}
                  />
                )}
              </div>
            </fieldset>
          )}
          {user.userModerator && (
            <>
              <Box mt='15px'>
                <CourseActiveAction />
              </Box>
              <Box mt='15px' display='flex' alignItems='center'>
                <EditUnitAction />
              </Box>
              <Box mt='5px'>
                <EditTopicAction />
              </Box>
            </>
          )}
          <br />
          <FlatLessonCardListDetail
            lessonData={filteredLessons}
            noLessonMessage='No Activities found'
            currentUser={user}
            currentUserProfle={userProfile}
            cardSize={cardSize}
            createCardDetailAction={(lesson) => ([
              <PreviewLessonAction
                label='Preview'
                lesson={lesson}
                key={`teacher-course-generated-lesson-preview-${lesson.id}`}
                user={user}
                userProfile={userProfile}
                terminologies={terminologies}
                className='top-left'
              />,
            ])}
            createCardAction={(lesson) => (
              [
                <PreviewLessonAction
                  lesson={lesson}
                  key={`teacher-course-generated-lesson-preview-${lesson.id}`}
                  user={user}
                  userProfile={userProfile}
                  terminologies={terminologies}
                  className='top-left'
                />,
                <EditLessonAction
                  lesson={lesson}
                  key={`generated-template-lesson-edit-${lesson.id}`}
                  className='bottom-right'
                  goBackTo={-1}
                />,
              ]
            )}
          />
          <br />
        </div>
      ) : (
        <TeacherCourseInstruction />
      )}
    </div>
  );
};

export default GeneratedLessons;

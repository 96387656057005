import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InstructionDialog from '../common/instruction/InstructionDialog';
import { useOpenInstructionDialog } from '../common/instruction/InstructionHooks';
import SequencingPrompt from './subcomponents/SequencingPrompt';
import SequencingResponse from './subcomponents/SequencingResponse';
import RejectedAnswer from '../common/RejectedAnswer';
import {
  useSequencingPlay,
} from './SequencingHooks';
import './Sequencing.scss';

export default function Sequencing({
  pageIndex,
  currentIndex,
  instructions,
  sequenceCards,
  responseCards,
}) {
  const {
    openInstructions,
    handleOnInstructionClosed,
  } = useOpenInstructionDialog(instructions, currentIndex === pageIndex);

  const {
    handleOnDragEnd,
    handleOnDragStart,
    selectedResponseCards,
  } = useSequencingPlay(pageIndex, sequenceCards, responseCards);

  return (
    <>
      <InstructionDialog
        open={openInstructions.open}
        instructions={instructions}
        onClose={handleOnInstructionClosed}
      />
      <DragDropContext
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      // sensors={[dragAndDropSensor]}
      >
        <div className='sequencing-activity' id={`sequencing-component-${pageIndex}`}>
          <Droppable
            droppableId={`sequencing-component-main-droppable-container-${pageIndex}`}
            direction='horizontal'
            isDropDisabled
          >
            {(sequencingProvided) => (
              <div
                ref={sequencingProvided.innerRef}
                {...sequencingProvided.droppableProps}
                className='sequencing-prompt-draggable'
              >
                <div className='prompt-cards-area panel-with-shadow'>
                  <div className='prompt-cards'>
                    {
                      sequenceCards.map((card, index) => {
                        const drappableKeyId = `sequencing-component-droppable-container-${pageIndex}-${index}`;
                        const responseCard = selectedResponseCards.find((r) => responseCards[r].answerKey === card.key);
                        const cardId = `${pageIndex}-${index}`;
                        const responseId = `sequencing-response-answered-${cardId}`;
                        const isAnswered = responseCard !== null && responseCard !== undefined;

                        return (
                          isAnswered ? (
                            <div
                              key={responseId}
                              className={`propmpt-container cards-${sequenceCards.length}-margin`}
                            >
                              <SequencingResponse
                                responseCard={responseCards[responseCard]}
                                responseCardId={responseId}
                                onClick={() => { }}
                                disablePlayMedia
                              />
                            </div>
                          ) : (
                            <Droppable
                              key={drappableKeyId}
                              droppableId={drappableKeyId}
                              direction='horizontal'
                              isDropDisabled={isAnswered}
                            >
                              {
                                (provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`
                                    propmpt-container
                                    ${snapshot.isDraggingOver ? 'sequencing-selected-component' : 'sequencing-component-response-card-non-drag-items'}
                                    cards-${sequenceCards.length}-margin
                                  `}
                                  >
                                    <SequencingPrompt
                                      sequenceCard={card}
                                      sequenceCardId={`sequence-propmpt-${pageIndex}-key-${card.key}`}
                                    />
                                    {provided.placeholder}

                                    <div className='sequencing-component-prompt-reject' id={drappableKeyId}>
                                      <RejectedAnswer noBorder />
                                    </div>
                                  </div>
                                )
                              }
                            </Droppable>
                          )
                        );
                      })
                    }
                  </div>

                </div>
                <div className='response-cards-area'>
                  <div className='response-cards'>
                    {responseCards.map((responseCard, index) => {
                      const cardId = `${pageIndex}-${index}`;
                      const responseKeyId = `sequencing-response-key-${cardId}`;
                      const responseId = `sequencing-response-${cardId}`;
                      const draggableId = `sequencing-drag-item-${cardId}`;
                      return (
                        <div
                          key={responseKeyId}
                          className={`response-card-place-holder cards-${sequenceCards.length}-margin`}
                        >
                          {selectedResponseCards.includes(index) ? '' : (
                            <Draggable
                              key={draggableId}
                              draggableId={draggableId}
                              index={index}
                            >
                              {(providedItem, snapshotItem) => (
                                <div
                                  ref={providedItem.innerRef}
                                  {...providedItem.draggableProps}
                                  {...providedItem.dragHandleProps}
                                  id={`sequencing-response-item-${cardId}`}
                                  key={`sequencing-response-item-${cardId}`}
                                  className={`sequencing-response-draggable 
                                    ${snapshotItem.isDragging ? 'sequencing-selected-component' : 'sequencing-component-response-card-non-drag-items'}
                                  `}
                                >
                                  <SequencingResponse
                                    responseCard={responseCard}
                                    responseCardId={responseId}
                                    onClick={() => { }}
                                  />
                                </div>

                              )}
                            </Draggable>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {sequencingProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
}

Sequencing.defaultProps = {
  instructions: {},
  sequenceCards: [],
  responseCards: [],
};

Sequencing.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  sequenceCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    answerKey: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
};

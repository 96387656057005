import PropTypes from 'prop-types';
import {
  TableHead,
  TableCell,
  TableSortLabel,
  TableRow,
} from '@mui/material';
import './SortableTableHead.scss';

const SortableTableHead = ({ config, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className='sortable-table-head'>
      <TableRow>
        {config.map((headCell) => (
          <TableCell
            className='header'
            key={`header-${headCell.dataField}`}
            sortDirection={orderBy === headCell.dataField ? order : false}
            style={{ width: headCell.width }}
          >
            { headCell.noSort ? (
              <div className='nosort-label'>{headCell.title}</div>
            ) : (
              <TableSortLabel
                className='sort-label'
                active={orderBy === headCell.dataField}
                direction={orderBy === headCell.dataField ? order : 'asc'}
                onClick={createSortHandler(headCell.dataField)}
              >
                {headCell.title ? headCell.title : <div style={{ width: '20px', height: '20px' }} />}
              </TableSortLabel>
            )}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SortableTableHead.defaultProps = {
  config: [],
  order: '',
  orderBy: '',
  onRequestSort: () => { },
};

SortableTableHead.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    dataRenderer: PropTypes.func,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
};

export default SortableTableHead;

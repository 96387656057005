import { Stack, Typography } from '@mui/material';
import Accessibility from './accessibility/Accessibility';
import InteractionSettings from './interaction/Interaction';
import ProfileSetting from './profile/ProfileSetting';
import CelebrationSetting from './celebrations/CelebrationSetting';
// import SeasonalFilter from './seasonal/SeasonalFilter';
import ReinforcersSetting from './reinforcers/ReinforcersSetting';
import TooltipWithIndicator from '../../../components/tooltip/TooltipWithIndicator';
import { updateCredentialTooltipText } from '../../../texts/TooltipText';
import { useDomain } from '../../../states/AppDomainProvider';

const Settings = () => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { b2cId } = userProfile;
  const isB2cId = Boolean(b2cId);

  const components = [
    { title: 'Password, name, etc.', component: <ProfileSetting />, tooltip: isB2cId ? updateCredentialTooltipText : null },
    { title: 'Interaction Options', component: <InteractionSettings /> },
    { title: 'Accessibility Options', component: <Accessibility /> },
    // { title: 'Seasonal Filter', component: <SeasonalFilter /> },
    { title: 'Celebrations', component: <CelebrationSetting /> },
    { title: 'Reinforcers', component: <ReinforcersSetting /> },
  ];

  return (
    <div className='my-setting-body'>
      <div className='my-setting-body-list'>
        {components.map((entry, index) => (
          <div
            key={`my-setting-entry-${index}`}
            className='my-setting-body-entry'
          >
            <Typography variant='h4' className='my-setting-body-entry-title-label'>
              <Stack direction='row' gap='10px' alignItems='center'>
                {entry.title} {entry.tooltip ? <TooltipWithIndicator {...updateCredentialTooltipText} /> : null}
              </Stack>
            </Typography>
            {entry.component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;

import { useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncLoader from 'react-spinners/SyncLoader';
import NotificationBlocker from '../../../../../../components/blocker/NotificationBlocker';
import { UNAUTHORIZED } from '../../../../../../AppConstants';
import ImageAndVideoUploader from '../../../../../../components/vizzle/uploader/ImageAndVideoUploader';
import { uploadMedia, updateUserAssets } from '../../../../../../services/MediaService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import BrowseImageDataDialog from './BrowseImageDataDialog';

const getUploadingMessage = (videoFile) => (
  `Please wait while the ${videoFile ? 'video' : 'image'} is being uploaded.`
);

/**
 * Container for uploading an image or a video from the user's computer
 *
 * @export
 */
export default function BrowseImageAndVideoUploader({
  onSelect,
  onRemove,
  disabledRemove,
  setBrowseImageRef,
  isVideoIncluded,
  isInlineRemoveButton,
  className,
  autoUploadWhenSelectImage,
  imageUrl,
  user,
}) {
  // let fileAcceptList = 'image/jpeg, image/jpg, image/gif, image/png, .png, .gif, .jpeg, .jpg';
  // fileAcceptList = isVideoIncluded ? `${fileAcceptList}, video/mp4` : fileAcceptList;
  const fileAcceptList = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/jpg': ['.jpeg', '.jpg'],
    'image/gif': ['.gif'],
  };
  if (isVideoIncluded) {
    fileAcceptList['video/mp4'] = ['.mp4'];
  }

  const [uploadState, setUploadState] = useState({
    isUploading: false,
    uploadingMessage: '',
  });
  const { uiDomain } = useDomain();

  const [showBrowseImageDialog, setShowBrowseImageDialog] = useState({
    open: false,
    data: null,
  });

  const uploadFile = async (data, additioalData) => {
    setUploadState({
      isUploading: true,
      uploadingMessage: getUploadingMessage(data.videoFile),
    });

    try {
      const imagePromise = uploadMedia(data.imageFile);
      const videoPromise = uploadMedia(data.videoFile);
      const [imagePromiseResolve, videoPromiseResolve] = await Promise.all([imagePromise, videoPromise]);

      if (data.videoFile) {
        const { attributes: { fileName } } = videoPromiseResolve;
        const { attributes: { s3Url } } = imagePromiseResolve;
        // Upload video and image. Only link video to the user assets
        updateUserAssets({ file: data.videoFile, fileName, description: fileName, thumbnailUrl: s3Url });
      } else {
        // Upload only image
        const { attributes: { fileName } } = imagePromiseResolve;
        updateUserAssets({ file: data.imageFile, fileName, description: fileName, ...additioalData });
      }
      // update image to the lesson
      const media = {
        selectedImageUrl: imagePromiseResolve.attributes.s3Url,
        selectedVideoUrl: null,
      };
      // update video to the lesson
      if (videoPromiseResolve) {
        media.selectedVideoUrl = videoPromiseResolve.attributes.s3Url;
      }
      await onSelect(media);
    } catch (e) {
      if (e.response && UNAUTHORIZED !== e.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
      }
      Logger.logError({
        ERROR: e,
      });
    }

    setUploadState({
      isUploading: false,
      uploadingMessage: '',
    });
  };

  const onApply = async (data) => {
    if (!autoUploadWhenSelectImage) {
      await onSelect({
        selectedImageUrl: data.imageUrl,
      });
      return;
    }
    if (data.videoFile) {
      uploadFile(data);
    } else if (user.userModerator || user.unitCreator) {
      setShowBrowseImageDialog({
        open: true,
        data,
      });
    } else {
      uploadFile(data);
    }
  };

  const handleRemoveImageVideo = async () => {
    await onRemove();
  };

  return (
    <>
      <ImageAndVideoUploader
        onSelect={onApply}
        loading={uploadState.isUploading}
        className={className}
        disabledRemove={disabledRemove}
        onRemove={handleRemoveImageVideo}
        setBrowseImageRef={setBrowseImageRef}
        fileAccept={fileAcceptList}
        isVideoIncluded={isVideoIncluded}
        isInlineRemoveButton={isInlineRemoveButton}
        imageUrl={imageUrl}
      />
      <NotificationBlocker
        show={uploadState.isUploading}
        loaderIcon={<SyncLoader color='#3b94d1' />}
        Icon={CloudUploadIcon}
        message={uploadState.uploadingMessage}
      />
      {showBrowseImageDialog.open && (
        <BrowseImageDataDialog
          openDialog={showBrowseImageDialog.open}
          onClose={() => {
            setShowBrowseImageDialog({
              open: false,
              data: null,
            });
          }}
          onApply={async (sourceAndKeywords) => {
            await uploadFile(showBrowseImageDialog.data, sourceAndKeywords);
            setShowBrowseImageDialog({
              open: false,
              data: null,
            });
          }}
        />
      )}

    </>
  );
}

BrowseImageAndVideoUploader.defaultProps = {
  disabledRemove: true,
  onSelect: () => { },
  onRemove: () => { },
  setBrowseImageRef: () => { },
  isVideoIncluded: true,
  isInlineRemoveButton: false,
  className: '',
  autoUploadWhenSelectImage: true,
  imageUrl: null,
};

BrowseImageAndVideoUploader.propTypes = {
  disabledRemove: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  setBrowseImageRef: PropTypes.func,
  isVideoIncluded: PropTypes.bool,
  isInlineRemoveButton: PropTypes.bool,
  className: PropTypes.string,
  autoUploadWhenSelectImage: PropTypes.bool,
  imageUrl: PropTypes.string,
  user: PropTypes.any.isRequired,
};

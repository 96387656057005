import { useEffect } from 'react';
import PropTypes from 'prop-types';
import FullScreenDialogbox from '../../../components/dialog/FullScreenDialogbox';
import { usePrepareLessonData } from './LessonPlayHook';
import LessonActivityPlay from '../components/lesson-activity-play/LessonActivityPlay';
import LessonLoader from '../components/LessonLoader';
import './ReactLessonPlay.scss';
import LessonPlayActivityUtils from '../activities/utils/LessonPlayActivityUtils';
import { useLessonPlay } from '../context/LessonPlayContextProvider';
/**
 * Play Lesson Action Container
 */
export default function ReactLessonPlay({
  open,
  handleClose,
  lesson,
  isPreviewMode,
  user,
  userProfile,
  terminologies,
  onLessonFinished,
  initialPageIndex,
  isPlayable,
  createAdditionalComponents,
  onChatWidgetOpen,
}) {
  const {
    loading,
  } = usePrepareLessonData({
    lessonUuid: lesson.attributes.uuid,
    user,
    userProfile,
    terminologies,
    isPreviewMode,
    initialPageIndex,
    isPlayable,
    onChatWidgetOpen,
  });

  const { lessonPlayDomain } = useLessonPlay();
  const { lessonPagesData } = lessonPlayDomain.domainData;

  useEffect(() => {
    // Remove the non selected lesson row doms out for the performance in the lesson play
    if (open) {
      document.getElementById('root').classList.add('react-lesson-play-disable');

      const selectedLessons = Array.prototype.slice.call(document.querySelectorAll(`[data-lessonuuid='${lesson.attributes.uuid}']`));
      const selectedRows = [];
      if (selectedLessons && selectedLessons.length > 0) {
        selectedLessons.forEach((lessonCard) => {
          const selectedRow = lessonCard.closest('.lesson-list-row');
          selectedRows.push(selectedRow);
        });
      }
      const allNonSelectedRows = Array.prototype.slice.call(document.querySelectorAll('.lesson-list-row')).filter((r) => !selectedRows.includes(r));

      if (allNonSelectedRows && allNonSelectedRows.length > 0) {
        allNonSelectedRows.forEach((row) => {
          row.classList.add('react-lesson-play-hide');
        });
      }

      const allCards = document.querySelectorAll('.lesson-card-container');
      if (allCards && allCards.length > 0) {
        allCards.forEach((c) => {
          c.classList.add('react-lesson-card-play-hide');
        });
      }
    }
    return () => {
      document.getElementById('root').classList.remove('react-lesson-play-disable');

      const hiddenElements = document.querySelectorAll('.react-lesson-play-hide');
      if (hiddenElements && hiddenElements.length > 0) {
        hiddenElements.forEach((e) => {
          e.classList.remove('react-lesson-play-hide');
        });
      }

      const hiddenCardElements = document.querySelectorAll('.react-lesson-card-play-hide');
      if (hiddenCardElements && hiddenCardElements.length > 0) {
        hiddenCardElements.forEach((e) => {
          e.classList.remove('react-lesson-card-play-hide');
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOnClose = () => {
    LessonPlayActivityUtils.stopMedias();
    handleClose();
  };

  const render = () => {
    if (loading) {
      return (
        <LessonLoader />
      );
    }

    if (!lessonPagesData || lessonPagesData.length === 0) {
      return null;
    }

    return (
      <LessonActivityPlay
        onLessonFinished={(isLessonComplete) => {
          handleOnClose();
          onLessonFinished(isLessonComplete);
        }}
        createAdditionalComponents={createAdditionalComponents}
        onChatWidgetOpen={onChatWidgetOpen}
      />
    );
  };

  return (
    open && (
      <FullScreenDialogbox
        className='lesson-play-dialog'
        open={open}
        handleClose={handleOnClose}
        noTitle
      >
        {render()}
      </FullScreenDialogbox>
    )
  );
}

ReactLessonPlay.defaultProps = {
  open: false,
  handleClose: () => { },
  isPreviewMode: false,
  user: null,
  userProfile: null,
  onLessonFinished: () => { },
  terminologies: {},
  initialPageIndex: 0,
  isPlayable: true,
  createAdditionalComponents: () => null,
  onChatWidgetOpen: () => { },
};

ReactLessonPlay.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  isPreviewMode: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxMatchingChoices: PropTypes.number,
    switchAccessibility: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
  onLessonFinished: PropTypes.func,
  initialPageIndex: PropTypes.number,
  isPlayable: PropTypes.bool,
  createAdditionalComponents: PropTypes.func,
  onChatWidgetOpen: PropTypes.func,
};

import { createContext, useContext } from 'react';
import LessonPlayDomain from './LessonPlayDomain';
import LessonPlayChatDomain from './LessonPlayChatDomain';

const domains = [
  new LessonPlayDomain(false),
  new LessonPlayChatDomain(false),
];

/*-----------------------------------------------------------------------*/

let LessonPlayContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  LessonPlayContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useLessonPlay = () => useContext(LessonPlayContextProvider);

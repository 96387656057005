import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField, Checkbox, FormControlLabel } from '@mui/material';
import './SchoolForm.scss';
import SearchDistrictAction from '../../../../components/actions/search-district/SearchDistrictAction';
import TransparentBlocker from '../../../../../../components/blocker/TransparentBlocker';

export const validateSchoolData = (data) => data && data.name !== '';
const SchoolForm = ({
  districtData,
  schoolData,
  onDataChange,
  isSuperAdmin,
  isDistrictEditable,
  disabled,
}) => {
  const [internalData, setInternalData] = useState({
    districtId: districtData.key,
    name: schoolData ? schoolData.name : '',
    shareStudents: schoolData && schoolData.shareStudents !== undefined ? schoolData.shareStudents : true,
    ncesId: schoolData ? schoolData.ncesId : '',
  });

  const updateDataChange = (updatedData) => {
    setInternalData(updatedData);
    const isDataValid = validateSchoolData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const updatedData = { ...internalData, [name]: name === 'shareStudents' ? e.target.checked : e.target.value };
    updateDataChange(updatedData);
  };

  const handleOnDistrictChange = (selectedDistrict) => {
    const updatedData = { ...internalData, districtId: selectedDistrict.key };
    updateDataChange(updatedData);
  };

  return (
    <div className='admin-school-form'>
      {disabled && (
        <TransparentBlocker show />
      )}
      <FormControl variant='outlined'>
        {isDistrictEditable ? (
          <SearchDistrictAction key='admin-school-search-district' name='districtId' filterValue={internalData.districtId} onFilterChange={handleOnDistrictChange} />
        ) : (
          <TextField aria-readonly label='District Name' value={districtData.name} name='district' className='form-school-district' />
        )}
        <TextField
          label='School Name'
          name='name'
          value={internalData.name || ''}
          onChange={handleOnChange}
          className='form-school-school-name'
          inputProps={{
            maxLength: 255,
          }}
          variant='standard'
        />
        {isSuperAdmin && (
          <FormControlLabel
            className='share-student-checkbox'
            control={(
              <Checkbox
                onChange={handleOnChange}
                name='shareStudents'
                color='primary'
                checked={internalData.shareStudents}
              />
            )}
            label='Share Students'
          />
        )}
        <TextField
          label='Nces ID'
          name='ncesId'
          value={internalData.ncesId || ''}
          onChange={handleOnChange}
          className='form-school-school-ncesId'
          inputProps={{
            maxLength: 255,
          }}
          variant='standard'
        />
      </FormControl>
    </div>
  );
};

SchoolForm.defaultProps = {
  districtData: {},
  onDataChange: () => null,
  isSuperAdmin: false,
  schoolData: {},
  isDistrictEditable: false,
  disabled: false,
};

SchoolForm.propTypes = {
  districtData: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  schoolData: PropTypes.shape({
    districtId: PropTypes.string,
    name: PropTypes.string,
    shareStudents: PropTypes.bool,
    ncesId: PropTypes.string,
  }),
  onDataChange: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  isDistrictEditable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SchoolForm;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import CustomDialog from '../../../components/dialog/CustomDialog';
import './UsernameToResetForm.scss';

const UsernameToResetForm = ({ onClose, openDialog, onSubmit, loading }) => {
  const [value, setValue] = useState('');

  const handleOnTextChange = (e) => {
    setValue(e.target.value);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(value);
    }
  };

  const handleOnNextClick = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <CustomDialog
      className='username-to-reset-form-dialog'
      openDialog={openDialog}
      onClose={onClose}
      title={(
        <div className='title'>
          Reset Password
        </div>
      )}
      content={(
        <div className='content'>
          <TextField
            className='text-form'
            value={value}
            label='Please enter your username or email address'
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            onKeyPress={handleOnKeyPress}
            onChange={handleOnTextChange}
          />
        </div>
      )}
      displayCloseIcon
      actions={[
        {
          title: 'Next',
          onClick: handleOnNextClick,
          className: 'btn-primary next-button',
          disabled: !value || value.length === 0,
          loading,
        },
      ]}
    />
  );
};

UsernameToResetForm.defaultProps = {
  onClose: () => { },
  openDialog: false,
  onSubmit: () => { },
  loading: false,
};

UsernameToResetForm.propTypes = {
  onClose: PropTypes.func,
  openDialog: PropTypes.bool,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default UsernameToResetForm;

import PropTypes from 'prop-types';
import './TransparentBlocker.scss';

/**
 * A component for blocking user interaction on the screen
 */
export default function TransparentBlocker({ show, isFullScreen }) {
  return show ? <div className={`transparent-blocker ${isFullScreen ? 'full-screen' : ''}`} /> : '';
}

TransparentBlocker.defaultProps = {
  show: false,
  isFullScreen: false,
};

TransparentBlocker.propTypes = {
  show: PropTypes.bool,
  isFullScreen: PropTypes.bool,
};

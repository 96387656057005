import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import VizzleSlider from '../../slider/VizzleSlider';
import { VOICE_TYPES, cancelTts, playTextToSpeech } from '../../../services/tts/TextToSpeechServiceV1';
import './TextToSpeechSettingsFormV1.scss';
import { HIGHLIGHT_COLORS } from '../../../services/tts/TextToSpeechService';

const TextToSpeechSettingsFormV1 = ({ ttsConfig, onChange }) => {
  const tts = useRef(null);
  const [ttsValue, setTtsValue] = useState(ttsConfig);
  useEffect(() => {
    setTtsValue(ttsConfig);

    return () => {
      cancelTts();
    };
  }, [ttsConfig]);
  const handlePlaySampleButtonClick = async () => {
    await playTextToSpeech([tts.current], ttsConfig);
  };
  return (
    <div className='text-to-speech-settings-form-v1'>
      <VizzleSlider
        label='Rate'
        // defaultValue={ttsConfig.rate || 10}
        value={ttsValue.rate}
        step={1}
        min={1}
        max={15}
        onChange={(e, value) => { setTtsValue({ ...ttsValue, rate: value }); }}
        onChangeCommitted={(e, value) => { onChange('rate', value); }}
        name='tts-slider-rate'
      />
      <br />
      <VizzleSlider
        label='Pitch'
        // defaultValue={ttsConfig.pitch || 10}
        value={ttsValue.pitch}
        step={1}
        min={1}
        max={20}
        onChange={(e, value) => { setTtsValue({ ...ttsValue, pitch: value }); }}
        onChangeCommitted={(e, value) => { onChange('pitch', value); }}
        name='tts-slider-pitch'
      />
      <br />
      <VizzleSlider
        label='Volume'
        // defaultValue={ttsConfig.volume || 10}
        value={ttsValue.volume}
        step={1}
        min={1}
        max={10}
        onChange={(e, value) => { setTtsValue({ ...ttsValue, volume: value }); }}
        onChangeCommitted={(e, value) => { onChange('volume', value); }}
        name='tts-slider-volume'
      />
      <br />
      <div className='selector-container' data-test='tts-voice-menu-container'>
        <Typography variant='subtitle1' fontWeight={500} data-test='tts-voice-menu-label'>
          Voices
        </Typography>
        <Select
          value={ttsConfig.voice === null || ttsConfig.voice === undefined ? '' : ttsConfig.voice}
          variant='outlined'
          onChange={(e) => { onChange('voice', e.target.value); }}
          data-test='tts-voice-menu-selector'
        >
          {VOICE_TYPES.map((option) => (
            <MenuItem key={option.key} value={option.value} data-test={`tts-voice-menu-option-${option.name}`}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <br />
      <div className='color-selector-container' data-test='tts-highlight-container'>
        <Typography variant='subtitle1' fontWeight={500} data-test='tts-highlight-label'>
          Highlight
        </Typography>
        <Select
          value={ttsConfig.highlightColor === null || ttsConfig.highlightColor === undefined ? '' : ttsConfig.highlightColor}
          variant='outlined'
          onChange={(e) => { onChange('highlightColor', e.target.value); }}
          data-test='tts-highlight-selector'
        >
          {HIGHLIGHT_COLORS.map((option) => (
            <MenuItem key={option.key} value={option.value} data-test={`tts-highlight-${option.name}`}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <div style={{ backgroundColor: ttsConfig.highlightColor }} className='tts-color' data-test='tts-color' />
      </div>
      <br />
      <div className='sample'>
        <Button variant='contained' className='btn-primary' onClick={handlePlaySampleButtonClick} data-test='tts-play-sample-action'>
          PLAY SAMPLE
        </Button>
        <div className='sample-text'>
          <div ref={tts}>
            Welcome to VizZle NextGen.
            <br />
            Content Created by Teachers for Teachers.
          </div>
        </div>
      </div>
    </div>
  );
};

TextToSpeechSettingsFormV1.defaultProps = {
  ttsConfig: {},
  onChange: () => { },
};

TextToSpeechSettingsFormV1.propTypes = {
  ttsConfig: PropTypes.shape({
    rate: PropTypes.number,
    pitch: PropTypes.number,
    volume: PropTypes.number,
    voice: PropTypes.number,
    highlightColor: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default TextToSpeechSettingsFormV1;

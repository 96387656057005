import { useRef } from 'react';
import LessonCardListDetail from '../../../../components/vizzle/cardlistdetail/lesson/LessonCardListDetail';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/LessonCardDetailActionBuilder';
import { createLessonCardAction, createLessonCardLabel } from './actions/LessonCardActionBuilder';
import LessonTag from '../../../tag/LessonTag';
import { studentGoalAndObjectiveTag } from '../../../../texts/TooltipText';
import { useGetCurrentStudent } from '../../../hooks/StudentHooks';
import {
  myStudentTags,
  usePrepareStudentLesson,
  useGetActiveGoals,
  useGetFilterOptions,
  useHandleLessonTag,
  useLessonSelect,
  useHandleTeacherCourseAssigment,
  useGetFilteredLessons,
  findMtssLesson,
  useGetAllLessonsFromProfile,
} from './StudentLessonsHooks';
import StudentLessonsRowActions from './actions/StudentLessonsRowActions';
import StudentLessonsMultiSelectionActions from './actions/StudentLessonsMultiSelectionActions';
import { calculateCardSize } from '../../../../utils/CardSizeCalculator';
import TeacherAssignedStudentCoursePanel from '../../../lessons/assigned-student-courses/TeacherAssignedStudentCoursePanel';
import StudentLessonFilter from './filter/StudentLessonFilter';
import './StudentLessons.scss';
import DeleteSelectedAssignmentsAction from './actions/DeleteSelectedAssignmentsAction';

const standardCardSize = calculateCardSize();
const cardSize = {
  width: standardCardSize.width * 0.85,
  slidesToShow: standardCardSize.slidesToShow,
  slidesToScroll: standardCardSize.slidesToScroll,
};

const StudentLessons = () => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { lessonData, selectedStudentProfile, terminologies, loadingStudent } = myStudentDomain.domainData;
  const { filteredLessonData } = useGetFilteredLessons();

  const allLessonsFromProfile = useGetAllLessonsFromProfile();

  const currentStudent = useGetCurrentStudent();
  const {
    loading,
    currentSelectedSubjectName,
    fetchStudentLesson,
  } = usePrepareStudentLesson(currentStudent);

  const activeGoals = useGetActiveGoals();
  const multiSelectFilterConfig = useGetFilterOptions({
    activeGoals,
  });

  const {
    getLessonTagIds,
    handleOnTagDelete,
    handleOnTagSelect,
    handleOnTagAdd,
  } = useHandleLessonTag();

  const {
    handleOnLessonSelect,
    handleOnLessonClear,
  } = useLessonSelect({
    allLessonsFromProfile,
  });

  const handleOnFilterChange = async (filterValue) => {
    await myStudentDomain.updateLessonFilterValue(filterValue);
  };

  const {
    selectedDates,
    handleOnSelectedDatesApply,
    handleOnStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
    handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  } = useHandleTeacherCourseAssigment();

  const lessonCardDetailRef = useRef(null);
  const teacherAssignedStudentCoursePanelRef = useRef(null);

  const refreshStudentLessonAndAssignedLessons = async () => {
    if (teacherAssignedStudentCoursePanelRef.current) {
      await teacherAssignedStudentCoursePanelRef.current.refreshData();
    }
    await fetchStudentLesson(true, []);
  };

  return (

    <div className='student-lessons'>
      {loading ? null : (
        <>
          <LessonCardListDetail
            key={currentSelectedStudentId}
            ref={lessonCardDetailRef}
            cardSize={cardSize}
            largeSingleRowMode
            currentSelectedSubjectName={currentSelectedSubjectName}
            lessonData={filteredLessonData}
            preFilterComponent={<StudentLessonFilter />}
            filterTitle='Additional Filters'
            withArchiveFilter={false}
            onFilterChange={handleOnFilterChange}
            noLessonMessage={
              !loading && lessonData.filter.isExpiredAssignment
                ? `No ${currentSelectedSubjectName} activities previously assigned for ${currentStudent && currentStudent.userName}`
                : `No ${currentSelectedSubjectName} activities assigned for ${currentStudent && currentStudent.userName}`
            }
            multiSelectFilterConfig={multiSelectFilterConfig}
            createCardAction={(lesson) => createLessonCardAction({ lesson, currentStudent, selectedStudentProfile, terminologies, allLessonsFromProfile })}
            createCardDetailAction={(
              lesson,
              currentUser,
            ) => createLessonCardDetailAction({ lesson, currentUser, selectedStudentProfile, terminologies, selectedDates, refreshStudentLessonAndAssignedLessons, subjectId })}
            createRowAction={() => (
              <div className='lesson-row-action'>
                <StudentLessonsRowActions
                  onClearClick={handleOnLessonClear}
                  selectedLessonIds={lessonData.selectedLessonIds}
                  lessonCardDetailRef={lessonCardDetailRef}
                />
                {!lessonData.filter.isExpiredAssignment && (
                  <DeleteSelectedAssignmentsAction
                    selectedLessonIds={lessonData.selectedLessonIds}
                    className='delete-selected-assignment'
                    student={currentStudent}
                    subjectId={subjectId}
                    onDeleteSuccess={refreshStudentLessonAndAssignedLessons}
                  />
                )}
              </div>
            )}
            currentUser={currentStudent}
            currentUserProfle={{
              ...selectedStudentProfile,
              standardScopes: userProfile.standardScopes,
            }}
            createLessonTagsComponent={(lesson) => (
              <LessonTag
                currentUser={currentStudent}
                lesson={lesson}
                title='Goals & Objectives'
                tooltipText={studentGoalAndObjectiveTag}
                tagIds={getLessonTagIds(lesson)}
                noTagMessage={`Activity not tagged to any Goals/Objectives for ${currentStudent.firstName}`}
                placeholder='Type to add/search Goals & Objectives'
                allLessonTags={activeGoals}
                tagUrl={myStudentTags}
                onTagDelete={handleOnTagDelete}
                onTagSelect={handleOnTagSelect}
                onTagAdd={handleOnTagAdd}
              />
            )}
            selectedLessonIds={lessonData.selectedLessonIds}
            onLessonSelect={handleOnLessonSelect}
            createLessonDetailAction={(lesson) => createLessonCardDetailonPlanelAction(lesson, currentStudent, selectedStudentProfile, terminologies)}
            showLessonBadges={false}
            showLessonDescription={false}
            createCardLabel={(lesson) => createLessonCardLabel(lesson)}
            shouldDisplayViewDetail={(lesson) => {
              const matchedLesson = findMtssLesson(lesson, allLessonsFromProfile);
              return !matchedLesson;
            }}
          />

          <StudentLessonsMultiSelectionActions
            selectedLessonIds={lessonData.selectedLessonIds}
          />
        </>
      )}
      {!loadingStudent && (
        <TeacherAssignedStudentCoursePanel
          ref={teacherAssignedStudentCoursePanelRef}
          selectedDates={selectedDates}
          onSelectedDatesApply={handleOnSelectedDatesApply}
          onStartLoading={handleOnStartLoading}
          subjectId={subjectId}
          onDataLoad={onDataLoad}
          assignedStudentCourses={assignedStudentCourses}
          filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
          loading={assignedStudentCoursesLoading}
          onFilterCriteriaAssignedStudentsCoursesUpdate={handleOnFilterCriteriaAssignedStudentsCoursesUpdate}
          canArchive={false}
          currentStudent={currentStudent}
          onTableActionSuccess={() => {
            fetchStudentLesson(true, lessonData.selectedLessonIds);
          }}
          canLessonDeleteFunction={(lesson) => {
            const matchedLesson = findMtssLesson(lesson, allLessonsFromProfile);
            return !matchedLesson;
          }}
        />
      )}

    </div>

  );
};

export default StudentLessons;

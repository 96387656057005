import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useGetSelectedStudentTable,
  useHandleSearchDistrictSchoolSelector,
  validateMoveStudentFormData as validator,
} from './MoveStudentsActionHooks';
import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import DistrictSchoolSelector from '../../../../components/actions/district-school-selector/DistrictSchoolSelector';
import './MoveStudentsForm.scss';

export const validateMoveStudentFormData = validator;

const MoveStudentsForm = ({
  initData,
  studentTableData,
  onDataChange,
}) => {
  const {
    tableConfig,
    tableData,
  } = useGetSelectedStudentTable({
    studentTableData,
  });

  const [data, setData] = useState({ ...initData });

  useEffect(() => {
    setData({ ...initData });
  }, [initData]);

  const {
    handleOnDistrictSchoolChange,
  } = useHandleSearchDistrictSchoolSelector({
    data,
    setData,
    onDataChange,
  });

  return (
    <div className='move-student-form'>
      <SearchAndSortableTable
        containerClassName='move-student-table'
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'userName',
        }}
        autoResetTableState
        filterable={false}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />

      <DistrictSchoolSelector
        onChange={handleOnDistrictSchoolChange}
        selectedDistrictId={data.district && data.district.key}
        selectedSchoolId={data.school && data.school.key}
      />
    </div>
  );
};

MoveStudentsForm.defaultProps = {
  initData: {
    schoolId: null,
  },
  studentTableData: [],
  onDataChange: () => null,
};

MoveStudentsForm.propTypes = {
  initData: PropTypes.shape({
    schoolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  studentTableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
  onDataChange: PropTypes.func,
};

export default MoveStudentsForm;

import BaseDomain from '../../../states/domains/BaseDomain';

const SCHOOLS_UPDATE_SCHOOLS = 'SCHOOLS_UPDATE_SCHOOLS';
const SCHOOLS_UPDATE_SELECTED_SCHOOL_IDS = 'SCHOOLS_UPDATE_SELECTED_SCHOOL_IDS';
const SCHOOLS_UPDATE_SELECTED_DISTRICT_ID = 'SCHOOLS_UPDATE_SELECTED_DISTRICT_ID';

const initialState = {
  schools: [],
  selectedSchoolIds: null,
  selectedDistrictId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SCHOOLS_UPDATE_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
      };
    case SCHOOLS_UPDATE_SELECTED_SCHOOL_IDS:
      return {
        ...state,
        selectedSchoolIds: action.payload,
      };
    case SCHOOLS_UPDATE_SELECTED_DISTRICT_ID:
      return {
        ...state,
        selectedDistrictId: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'schoolsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSchools(schools) {
    return this.dispatch({
      type: SCHOOLS_UPDATE_SCHOOLS,
      payload: schools,
    });
  }

  async updateSelectedSchools(selectedSchoolIds) {
    return this.dispatch({
      type: SCHOOLS_UPDATE_SELECTED_SCHOOL_IDS,
      payload: selectedSchoolIds,
    });
  }

  async updateSelectedDistrictId(selectedDistrictId) {
    return this.dispatch({
      type: SCHOOLS_UPDATE_SELECTED_DISTRICT_ID,
      payload: selectedDistrictId,
    });
  }
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useApplyFontStyle } from '../../ApplyTextStyleActionHooks';
import EntryContainer from '../EntryContainer';
import { FONT_FORMAT_LIST } from '../../../../../../../../../../constants/TextEditorConstants';

export default function FontFormatForm({ applyToActivities }) {
  const [formats, setFormats] = useState(() => []);
  const [clearFormat, setClearFormat] = useState([]);

  const handleFormat = (_event, newFormats) => {
    setClearFormat([]);
    setFormats(newFormats);
  };

  const handleClearFormat = (_event, newFormats) => {
    setFormats([]);
    setClearFormat(newFormats);
  };

  const {
    // eslint-disable-next-line no-unused-vars
    applyFontStyle,
  } = useApplyFontStyle({
    applyToActivities,
  });

  return (
    <EntryContainer
      data-test='font-format-form'
      label='Font Format'
      onApply={async () => {
        const formatToUpdate = [];
        if (formats.length > 0) {
          if (formats.includes(FONT_FORMAT_LIST[0])) {
            formatToUpdate.push({
              fieldName: 'fontWeight',
              value: FONT_FORMAT_LIST[0],
            });
          }
          if (formats.includes(FONT_FORMAT_LIST[1])) {
            formatToUpdate.push({
              fieldName: 'fontStyle',
              value: FONT_FORMAT_LIST[1],
            });
          }
          if (formats.includes(FONT_FORMAT_LIST[2])) {
            formatToUpdate.push({
              fieldName: 'textDecoration',
              value: FONT_FORMAT_LIST[2],
            });
          }
          await applyFontStyle(formatToUpdate);
          setFormats([]);
        } else if (clearFormat.length > 0) {
          formatToUpdate.push({
            fieldName: 'fontWeight',
            value: null,
          });
          formatToUpdate.push({
            fieldName: 'fontStyle',
            value: null,
          });
          formatToUpdate.push({
            fieldName: 'textDecoration',
            value: null,
          });
          await applyFontStyle(formatToUpdate);
          setClearFormat([]);
        }
      }}
      disabled={formats.length === 0 && clearFormat.length === 0}
    >
      <ToggleButtonGroup
        value={formats}
        onChange={handleFormat}
        aria-label='text formatting'
      >
        <ToggleButton value={FONT_FORMAT_LIST[0]} aria-label='bold'>
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton value={FONT_FORMAT_LIST[1]} aria-label='italic'>
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton value={FONT_FORMAT_LIST[2]} aria-label='underlined'>
          <FormatUnderlinedIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        value={clearFormat}
        onChange={handleClearFormat}
        aria-label='text formatting'
        className='margin-left-medium'
      >
        <ToggleButton value='clear' aria-label='Clear format'>
          <ClearIcon /> Clear Format
        </ToggleButton>
      </ToggleButtonGroup>
    </EntryContainer>
  );
}

FontFormatForm.propTypes = {
  applyToActivities: PropTypes.string.isRequired,
};

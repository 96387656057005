import { Paper, Typography } from '@mui/material';
import SelectedStudent from './SelectedStudent';
import ConfirmationDialog from '../../../../../components/dialog/ConfirmationDialog';

import './SelectedStudentList.scss';
import { useConfirmDialog, useSelectStudentList } from './SelectedStudentListHook';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';

const SelectedStudentList = () => {
  const {
    selectedStudent,
    students,
    handleOnRemovedStudent,
    onConfirmDialogClosed,
  } = useSelectStudentList();

  const {
    displayConfirmDialog,
    setDisplayConfirmDialog,
  } = useConfirmDialog();
  const confirmationDialogProps = {
    open: displayConfirmDialog,
    title: 'Remove Student',
    contentText: `Are you sure you want to remove ${selectedStudent.current ? `${selectedStudent.current.firstName} ${selectedStudent.current.lastName}` : ''}?`,
    confirmLabel: 'YES',
    cancelLabel: 'NO',
    onConfirm: () => {
      setDisplayConfirmDialog(false);
      handleOnRemovedStudent();
    },
    onCancel: () => {
      setDisplayConfirmDialog(false);
      onConfirmDialogClosed();
    },
  };

  const onClickedRemoveStudent = (student) => {
    setDisplayConfirmDialog(true);
    selectedStudent.current = student;
  };

  return (
    <div className='selected-student-list'>
      {
        !students || students.length === 0 ? (
          <NoLessonPaper className='no-student-paper' message='No students on your roster yet.' />
        ) : (
          <>
            <Typography variant='h6'>
              My Roster
            </Typography>
            <Paper className='selected-student-list-paper'>
              {
                students.map((student, index) => (
                  <SelectedStudent key={`selected-student-${index}`} data={student} onClickedRemoveStudent={onClickedRemoveStudent} />
                ))
              }
              <ConfirmationDialog {...confirmationDialogProps} />
            </Paper>
          </>
        )
      }
    </div>
  );
};

export default SelectedStudentList;

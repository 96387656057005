import { useEffect, useMemo, useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { KEY_WRONG } from '../../../../utils/activitytype/ActivityTypeInitialData';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

const getAngle = (allResponsesText, numberOfCards) => {
  if (!allResponsesText) {
    return [4, 2];
  }

  switch (numberOfCards) {
    case 2:
      return [2, 2];
    case 3:
      return [2, 2];
    case 4:
      return [1, 1.5];
    case 5:
      return [1.8, 2];
    case 6:
      return [1.8, 2];
    case 7:
      return [1, 2];
    case 8:
      return [1, 2];
    default:
      return [2, 2];
  }
};

export const useCardRotationCache = ({ numberOfCards, allResponsesText }) => {
  const [angle, angleDegree] = getAngle(allResponsesText, numberOfCards);
  const cardRotationCache = useMemo(() => {
    const result = [];
    for (let i = 0; i < numberOfCards; i++) {
      const rndAngle = Math.floor(Math.random() * angle) + angleDegree;
      const rndClock = Math.floor(Math.random() * 10) % 2;
      result[i] = {
        rndAngle,
        rndClock,
      };
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    cardRotationCache,
  };
};

export const useInitializeData = () => ({
  categorizingActivityRef: useRef(null),
  dragAndDropApiRef: useRef(null),
});

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
      componentId: `categorizing-activity-component-${id}`,
      droppableId: `categorizing-component-main-droppable-container-${id}`,
    };
  }, []);

  return componentIds;
};

export const useGenerateRejectedAnswerRefs = () => {
  const rejectedAnswerPromptRefs = [];
  const setRejectedAnswerPromptRefs = (ref) => {
    if (ref) {
      rejectedAnswerPromptRefs.push(ref);
    }
  };

  const rejectedAnswerResponseRefs = [];
  const setRejectedAnswerResponseRefs = (ref, index) => {
    if (ref) {
      rejectedAnswerResponseRefs[index] = ref;
    }
  };

  return {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  };
};

export const useGetAvailableResponseKeys = (promptCards, responseCards) => {
  const prompCardKeys = promptCards.map((p) => p.key);
  const availableResponseKeys = responseCards
    .filter((r) => prompCardKeys.includes(r.key))
    .map((r) => r.key);
  return availableResponseKeys;
};

const removeHighZIndexClass = () => {
  const elList = document.getElementsByClassName('high-z-index');
  if (elList.length > 0) {
    [].forEach.call(elList, (el) => {
      el.classList.remove('high-z-index');
    });
  }
};

export const useDragAndDropSubmitAnswer = ({
  categorizingActivityRef,
  promptCards,
  rejectedAnswerPromptRefs,
  availableResponseKeys,
  responseCards,
  rejectedAnswerResponseRefs,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
}) => {
  const [selectedResponseCards, setSelectedResponseCards] = useState([]);
  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = (initial) => {
    const draggingItem = document.getElementById(`${initial.draggableId}-container`);
    if (draggingItem) {
      draggingItem.classList.add('high-z-index');
    }
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(categorizingActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      removeHighZIndexClass();
      LessonPlayActivityUtils.disableComponent(categorizingActivityRef, false);
      return;
    }

    try {
      const sourceIndex = source.index;
      const destinationDroppingId = destination.droppableId;
      const destinationIndexStr = destinationDroppingId.substring(destinationDroppingId.lastIndexOf('-') + 1);
      // 10 -> decimal number
      const destinationIndex = parseInt(destinationIndexStr, 10);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(destinationIndex)) {
        return;
      }
      if (promptCards[destinationIndex].key === responseCards[sourceIndex].key) {
        const updatedSelectdResponseCards = [
          ...selectedResponseCards,
          sourceIndex,
        ];
        setSelectedResponseCards(updatedSelectdResponseCards);
        const isFinished = updatedSelectdResponseCards.length >= availableResponseKeys.length;
        await onCorrectAnswerSelected({ insertResponse: true, responseIndex: sourceIndex, once: false, isFinished, showReinforcer: isFinished });
        if (isFinished) {
          LessonPlayActivityUtils.removeSelectedElementClass();
          onActivityFinished();
        }
      } else {
        if (responseCards[sourceIndex].key === KEY_WRONG) {
          onIncorrectAnswerSelected(sourceIndex, true);
        } else {
          onIncorrectAnswerSelected(sourceIndex);
        }
        showDropRejection(rejectedAnswerPromptRefs[destinationIndex]);
        showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      removeHighZIndexClass();
      LessonPlayActivityUtils.disableComponent(categorizingActivityRef, false);
    }
  };
  return {
    handleOnDragStart,
    handleOnDragEnd,
    selectedResponseCards,
    setSelectedResponseCards,
  };
};

export const useClickSubmitAnswer = ({
  categorizingActivityRef,
  dragAndDropApiRef,
}) => {
  const currentSelectedIndex = useRef(null);

  const handleOnPromptCardClick = (_e, prompCardDropableId) => {
    if (currentSelectedIndex.current) {
      LessonPlayActivityUtils.stopMedias();

      const dragAndDropApi = dragAndDropApiRef.current;
      const triggerDragAndDrop = async () => {
        await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedIndex.current.responseContainerId, currentSelectedIndex.current.draggableId, prompCardDropableId);
        // clear selected value
        if (currentSelectedIndex.current) {
          categorizingActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
          const el = document.getElementById(currentSelectedIndex.current.responseId);
          if (el) {
            el.classList.remove('vizzle-selected-component-medium');
          }
          currentSelectedIndex.current = null;
        }
      };

      ObjectUtils.setTimeout(() => {
        triggerDragAndDrop();
      });
    }
  };

  const handleOnResponseCardClick = (_e, isTtsAbleToPlay, responseCard, responseId, responseContainerId, draggableId) => {
    if (!responseCard.audio && !responseCard.video && !isTtsAbleToPlay) {
      LessonPlayActivityUtils.stopMedias();
    }

    if (!currentSelectedIndex.current || currentSelectedIndex.current.cardId !== responseId) {
      if (currentSelectedIndex.current && document.getElementById(currentSelectedIndex.current.responseId)) {
        document.getElementById(currentSelectedIndex.current.responseId).classList.remove('vizzle-selected-component-medium');
      }
      currentSelectedIndex.current = {
        responseId,
        responseContainerId,
        draggableId,
      };
      categorizingActivityRef.current.classList.add('prompt-selected-response-cards-hover');
      document.getElementById(responseId).classList.add('vizzle-selected-component-medium');
    }
  };

  return {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  };
};

export const useSwitchAccessSubmitAnswer = ({
  categorizingPromptRefs,
  onTriggerSwitchScan,
  dragAndDropApiRef,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility } = userProfile;

  const selectedPromptIndex = useRef(null);
  const handlePromptSwitchAccessSelect = switchAccessibility ? (index, drappableKeyId) => {
    const el = categorizingPromptRefs.map((s) => s.getEnableElements());
    LessonPlayActivityUtils.addClassToTheSelectedElement(el[index]);
    selectedPromptIndex.current = {
      index,
      drappableKeyId,
    };
    onTriggerSwitchScan();
  } : () => { };

  const handleResponseSwitchAccessSelect = switchAccessibility ? (responseId, draggableId) => {
    LessonPlayActivityUtils.stopMedias();
    const dragAndDropApi = dragAndDropApiRef.current;
    const triggerDragAndDrop = async () => {
      if (!selectedPromptIndex.current) {
        return;
      }
      await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, responseId, draggableId, selectedPromptIndex.current.drappableKeyId);
    };

    ObjectUtils.setTimeout(() => {
      triggerDragAndDrop();
    });
  } : () => { };
  return {
    selectedPromptIndex,
    handlePromptSwitchAccessSelect,
    handleResponseSwitchAccessSelect,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  setSelectedResponseCards,
  responseCards,
  promptCards,
  onActivityFinished,
  availableResponseKeys,
  pageIndex,
}) => {
  useEffect(() => {
    const result = [];
    const promptCardKeys = promptCards.map((p) => p.key);
    responseCards.forEach((r, index) => {
      if (promptCardKeys.includes(r.key) && r.correctSubmission !== null && r.correctSubmission !== undefined) {
        result.push(index);
      }
    });

    const isFinished = result.length >= availableResponseKeys.length;
    if (isFinished) {
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    setSelectedResponseCards(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

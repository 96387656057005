/* eslint-disable no-restricted-globals */
import { useState } from 'react';
import Grow from '@mui/material/Grow';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import './DateFromToForm.scss';
import { TextField } from '@mui/material';

export default function DateFromToForm({
  startDate,
  endDate,
  dateFormat,
  onChange,
  children,
  displayTime,
  startTime,
  endTime,
  className,
  startDateLabel,
  endDateLabel,
  disableToolbar,
}) {
  const [showDateErrorMessage, setShowDateErrorMessage] = useState({
    isValid: true,
    message: '',
  });

  const handleOnDateChange = (date, field, validation) => {
    setShowDateErrorMessage({ ...validation });
    onChange(date, field, validation.isValid);
  };

  const [showTimeErrorMessage, setShowTimeErrorMessage] = useState({
    isValid: true,
    message: '',
  });

  const handleOnTimeChange = (time, field, validation) => {
    setShowTimeErrorMessage({ ...validation });
    onChange(time, field, validation.isValid);
  };

  return (
    <div className={`date-from-to-form ${className}`}>
      <Grow in={!showDateErrorMessage.isValid && Boolean(showDateErrorMessage.message)}>
        {!showDateErrorMessage.isValid && showDateErrorMessage.message ? (
          <Alert className='error-bar' severity='error'>{showDateErrorMessage.message}</Alert>
        ) : <div />}
      </Grow>
      <Grow in={!showTimeErrorMessage.isValid && Boolean(showTimeErrorMessage.message)}>
        {!showTimeErrorMessage.isValid && showTimeErrorMessage.message ? (
          <Alert className='error-bar' severity='error'>{showTimeErrorMessage.message}</Alert>
        ) : <div />}
      </Grow>
      <div className='date-from-to-container'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className='date-input' data-test='start-date'>
            <DatePicker
              disableToolbar={disableToolbar}
              format={dateFormat}
              label={startDateLabel}
              value={startDate}
              onChange={(date) => handleOnDateChange(date, 'startDate', {
                isValid: date <= endDate,
                message: isNaN(date) ? null : 'Start Date cannot be after End Date',
              })}
              InputAdornmentProps={{ position: 'start' }}
              maxDate={endDate}
              autoOk
              maxDateMessage={null}
              minDateMessage={null}
              invalidDateMessage={null}
              renderInput={(params) => <TextField {...params} variant='standard' className='date-picker required-field' />}
            />
          </div>

          <div className='date-input' data-test='end-date'>
            <DatePicker
              disableToolbar={disableToolbar}
              format={dateFormat}
              label={endDateLabel}
              value={endDate}
              onChange={(date) => handleOnDateChange(date, 'endDate', {
                isValid: date >= startDate,
                message: isNaN(date) ? null : 'End Date cannot be before Start Date',
              })}
              InputAdornmentProps={{ position: 'start' }}
              minDate={startDate}
              autoOk
              maxDateMessage={null}
              minDateMessage={null}
              invalidDateMessage={null}
              renderInput={(params) => <TextField {...params} variant='standard' className='date-picker required-field' />}
            />
          </div>
          {displayTime ? (
            <div className='time-container'>
              <div className='time-input' data-test='start-time'>
                <TimePicker
                  ampm
                  variant='inline'
                  label='Start Time'
                  className='time-picker'
                  value={startTime}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleOnTimeChange(date, 'startTime', {
                    isValid: date <= endTime,
                    message: 'Start Time cannot be after End Time',
                  })}
                  keyboardIcon={<AccessTimeIcon />}
                  minutesStep={5}
                  maxDateMessage={null}
                  minDateMessage={null}
                  invalidDateMessage={null}
                  renderInput={(params) => <TextField {...params} variant='standard' className='time-picker' />}
                />
              </div>
              <div className='time-input' data-test='end-time'>
                <TimePicker
                  ampm
                  variant='inline'
                  label='End Time'
                  className='time-picker'
                  value={endTime}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleOnTimeChange(date, 'endTime', {
                    isValid: date >= startTime,
                    message: 'End Time cannot be before Start Time',
                  })}
                  keyboardIcon={<AccessTimeIcon />}
                  minutesStep={5}
                  maxDateMessage={null}
                  minDateMessage={null}
                  invalidDateMessage={null}
                  renderInput={(params) => <TextField {...params} variant='standard' className='time-picker' />}
                />
              </div>
            </div>
          ) : null}
        </LocalizationProvider>

        {children}
      </div>
    </div>
  );
}

DateFromToForm.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
  dateFormat: '',
  onChange: () => { },
  displayTime: false,
  children: null,
  startTime: new Date(),
  endTime: new Date(),
  className: '',
  startDateLabel: 'Start Date',
  endDateLabel: 'End Date',
  disableToolbar: false,
};

DateFromToForm.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  displayTime: PropTypes.bool,
  children: PropTypes.node,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  disableToolbar: PropTypes.bool,
};

import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import {
  useGetLicenseTable,
  useHandleLicenseTable,
} from './LicensesHooks';
import './Licenses.scss';
import AddLicenseAction from './actions/AddLicenseAction';
import UpdateLicenseAction from './actions/UpdateLicenseAction';
import UpdateMultipleLicensesAction from './actions/UpdateMultipleLicensesAction';
import '../../AdminCommon.scss';
import AdminDistrictSchoolSelector from '../../admin-district-school-selector/AdminDistrictSchoolSelector';
import { useGetUsersAndLicensesData } from '../../admin-district-school-selector/AdminDistrictSchoolSelectorHook';

const Licenses = () => {
  const {
    tableConfig,
    licensesTableData,
    tableRef,
  } = useGetLicenseTable();

  const {
    getUsersAndLicensesData,
    isLoading,
  } = useGetUsersAndLicensesData();

  const { selectedLicenseIds, handleOnSelectedRowIds, updateLicenseRef } = useHandleLicenseTable();
  return (
    <div className='admin-tab-licenses full-height'>
      <SearchAndSortableTable
        ref={tableRef}
        className='license-table'
        preToolbarAction={[
          <AdminDistrictSchoolSelector
            key='license-district-selector'
            onSchoolChange={() => {
              if (tableRef.current) {
                tableRef.current.resetTableState();
              }
            }}
          />,
        ]}
        config={tableConfig}
        tableData={licensesTableData}
        initTableState={{
          order: 'desc',
          orderBy: 'userName',
        }}
        searchPlaceholder='Filter by License Name'
        filterable={false}
        dataId='id'
        loading={isLoading}
        selectedRowIds={selectedLicenseIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        toolbarActions={[
          <AddLicenseAction key='admin-add-license-action' onLicenseAddSuccess={() => getUsersAndLicensesData()} />,
          !selectedLicenseIds || selectedLicenseIds.length <= 1
            ? <UpdateLicenseAction key='admin-update-license-action' ref={updateLicenseRef} onLicenseUpdateSuccess={() => getUsersAndLicensesData()} />
            : <UpdateMultipleLicensesAction key='admin-update-licenses-action' onLicensesUpdateSuccess={() => getUsersAndLicensesData()} />,
        ]}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Licenses;

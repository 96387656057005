import { Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { updateGenerateUnitGuideFromTopics } from '../../../../../../services/CourseService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetUnit } from '../../topic-lessons/TeacherCourseLessonsHooks';
import './GenerateUnitGuideFromTopicsActiveAction.scss';

const GenerateUnitGuideFromTopicsActiveAction = () => {
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { courseId, unitId } = searchCriteria;

  const selectedUnit = useGetUnit(courseId, unitId);

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  if (!user.userModerator) {
    return null;
  }

  const handleSaveData = async (_e, newValue) => {
    try {
      await callRemoteServiceWrapper(async () => {
        selectedUnit.generateUnitGuideFromTopics = newValue;
        await teacherCourseDomain.updateLessonCourses([...lessonCourses]);
        updateGenerateUnitGuideFromTopics({
          courseId,
          unitId,
          generateUnitGuideFromTopics: newValue,
        });
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `Generate Unit Guide has been updated to ${newValue ? 'Active' : 'Inactive'}.`);
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return (
    <div className='generate-unit-guide-from-topics-active-action'>
      <Typography variant='h6'>Generate Unit Guide </Typography>
      <ToggleButtonGroup size='small' onChange={handleSaveData} value={selectedUnit.generateUnitGuideFromTopics || false} exclusive>
        <ToggleButton value>
          Active
        </ToggleButton>
        <ToggleButton value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default GenerateUnitGuideFromTopicsActiveAction;

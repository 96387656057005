import { addSchool, updateSchool } from '../../../../../services/AdminService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { transformDistrictData } from '../SchoolsHooks';
import { useAdmin } from '../../../context/AdminContextProvider';

export const useDataSchoolObject = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { userSuperAdmin } = user;
  const { schoolsDomain, districtsDomain } = useAdmin();
  const { selectedDistrictId, selectedSchoolIds, schools } = schoolsDomain.domainData;
  const { districts } = districtsDomain.domainData;
  let schoolDataByDistrict = {};

  const district = districts.length === 1 ? districts[0] : districts.find((d) => d.id === selectedDistrictId);
  const districtData = district ? transformDistrictData(district) : null;

  if (selectedDistrictId && selectedSchoolIds && selectedSchoolIds.length === 1) {
    schoolDataByDistrict = {
      districtId: selectedDistrictId,
      name: '',
      shareStudents: false,
      ncesId: '',
    };

    const selectedId = selectedSchoolIds[0];
    const selectedSchool = schools.find((school) => school.id === selectedId);
    if (selectedSchool) {
      schoolDataByDistrict = {
        ...schoolDataByDistrict,
        name: selectedSchool.name,
        shareStudents: selectedSchool.shareStudents,
        ncesId: selectedSchool.ncesId,
      };
    }
  }

  return {
    userSuperAdmin,
    districtData,
    schoolDataByDistrict,
    selectedSchoolIds,
  };
};

export const useAddSchool = ({ setOpen, onSchoolAddSuccess }) => {
  const { uiDomain } = useDomain();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const addNewSchool = async (newSchool, showLoader = true) => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (showLoader) {
          uiDomain.showLoader('Loading Schools Data', LOADER_TYPE.RISE_LOADER);
        }
        await addSchool(newSchool);
        await onSchoolAddSuccess(newSchool.districtId);
        setOpen(false);
        await uiDomain.showSnackbar(true, 'info', 3000, `School ${newSchool.name} is added.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    } finally {
      if (showLoader) {
        uiDomain.hideLoader();
      }
    }
  };
  return {
    loading,
    addNewSchool,
  };
};

export const useUpdateSchool = ({ setOpen, onSchoolUpdateSuccess }) => {
  const { uiDomain } = useDomain();
  const { schoolsDomain } = useAdmin();
  const { selectedDistrictId } = schoolsDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const updateExistingSchool = async (schoolId, updatedSchoolObj) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await updateSchool(schoolId, updatedSchoolObj);
        await onSchoolUpdateSuccess(selectedDistrictId);
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, `School ${updatedSchoolObj.name} is updated.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };
  return {
    loading,
    updateExistingSchool,
  };
};

import { Divider } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import ContainerWithSideBar from '../../components/container/ContainerWithSideBar';
import './AdminData.scss';
import TeacherMenubar from './components/TeacherMenubar';
import TeacherSelector from './components/TeacherSelector';
import TeacherActivityReport from './components/TeacherActivityReport';
import { URL_ACTIVITY_REPORT, URL_ACTIVITY_REPORT_STUDENTS, URL_ACTIVITY_REPORT_TEACHERS } from './AdminDataConstants';
import TeacherActivityReportStudents from './components/TeacherActivityReportStudents';
import { URL_ADMIN } from '../../../../routes/RouteConstants';
import TeacherActivityReportTeachers from './components/TeacherActivityReportTeachers';

const AdminData = () => (
  <div className='admin-data' data-private>
    <ContainerWithSideBar
      sideBar={<TeacherSelector />}
      content={(
        <div className='admin-data-content'>
          <TeacherMenubar />
          <Divider />
          <Routes>
            <Route
              path={URL_ACTIVITY_REPORT}
              element={<TeacherActivityReport />}
            />
            <Route
              path={`${URL_ACTIVITY_REPORT}/${URL_ACTIVITY_REPORT_STUDENTS}`}
              element={<TeacherActivityReportStudents />}
            />

            <Route
              path={`${URL_ACTIVITY_REPORT}/${URL_ACTIVITY_REPORT_TEACHERS}`}
              element={<TeacherActivityReportTeachers />}
            />

            <Route
              exact
              path='*'
              element={<Navigate to={`${URL_ADMIN}/data/${URL_ACTIVITY_REPORT}`} />}
            />
          </Routes>
        </div>
      )}
    />
  </div>
);

export default AdminData;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotificationDialog from '../../components/dialog/NotificationDialog';
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog';
import ToggleForm from '../../components/toggle/ToggleForm';

const content = (
  <>
    <Box>
      Matching Activity can only be played in one-click mode when Switch/Keyboard Access is turned on.
    </Box>
    <Box mt='10px'>
      Celebrations are currently not available when Switch/Keyboard Access is turned on.
    </Box>
  </>
);
const warningToSwitchOffMessage = 'Switch/Keyboard Accessibility cannot be changed because JAWS® or other Screen Reader is currently ON.';

const SwitchKeyboard = ({ profile, onConfirmCallback, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.switchAccessibility === 1);
  const isSwitchAccessibility = profile.switchAccessibility === 1;
  // When mounting and changing a student.
  useEffect(() => {
    setToggle(isSwitchAccessibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id, profile.switchAccessibility]);

  const [openDialog, setOpenDialog] = useState(false);
  const onConfirm = () => {
    onConfirmCallback();
    setOpenDialog(false);
  };

  const onCancel = () => {
    setToggle(false);
    setOpenDialog(false);
  };

  const dialogProps = {
    open: openDialog,
    title: 'Switch',
    contentText: content,
    confirmLabel: 'OK',
    cancelLabel: 'Cancel',
    onConfirm: async () => {
      await onConfirm();
    },
    onCancel: () => {
      onCancel();
    },
  };

  const [warningShow, setWarningShow] = useState(false);
  const onCloseWarningShow = () => {
    setWarningShow(false);
  };

  const warningDialog = {
    open: warningShow,
    onClose: onCloseWarningShow,
    confirmLabel: 'OK',
    message: warningToSwitchOffMessage,
    title: 'Switch',
  };

  const handleSwitchKeyboardOnchange = (field, newValue) => {
    if (newValue === null) return;
    if (profile.screenReaderOn) {
      setWarningShow(true);
      return;
    }
    if (newValue) {
      setToggle(newValue);
      setOpenDialog(newValue);
    } else {
      setToggle(newValue);
      setOpenDialog(newValue);
      const value = newValue === true ? 1 : 0;
      onChangeCallback(value);
    }
  };

  return (
    <>
      <ToggleForm
        value={toggle}
        handleOnChange={handleSwitchKeyboardOnchange}
        data-test='switch-keyboard-toggle'
      />
      <ConfirmationDialog
        open={dialogProps.open}
        title={dialogProps.title}
        contentText={dialogProps.contentText}
        confirmLabel={dialogProps.confirmLabel}
        cancelLabel={dialogProps.cancelLabel}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
        data-test='switch-keyboard-confirmation-dialog'
      />
      <NotificationDialog
        open={warningDialog.open}
        onClose={warningDialog.onClose}
        title={warningDialog.title}
        confirmLabel={warningDialog.confirmLabel}
        message={warningDialog.message}
        Icon={HighlightOffIcon}
        data-test='switch-keyboard-notification-dialog'
      />
    </>
  );
};

SwitchKeyboard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onConfirmCallback: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default SwitchKeyboard;

import {
  RECENTLY_MASTERED_TITLE,
  MASTERED_TITLE,
  IN_PROGRESS_TITLE,
  ARCHIVED_TITLE,
  MASTERED_STATUS_CRITERIA_MET,
  MASTERED_STATUS_IN_PROGRESS,
  MASTERED_STATUS_NOT_STARTED,
} from '../constants/MasterObjectiveConstant';
import DateUtils from './DateUtils';

export const sortObjectiveList = (objectiveList = []) => {
  const list = objectiveList.sort((a, b) => {
    const dateA = a.attributes.updatedDate < a.attributes.latestLessonPlayTime ? a.attributes.latestLessonPlayTime : a.attributes.updatedDate;
    const dateB = b.attributes.updatedDate < b.attributes.latestLessonPlayTime ? b.attributes.latestLessonPlayTime : b.attributes.updatedDate;
    return dateA.localeCompare(dateB);
  });
  return list.reverse();
};

export const mapMasteryCriteriaIntoGroup = (masteryCriteria) => {
  const recentBenchMarksMet = [];
  const benchmarksReach = [];
  const inProgress = [];
  const archived = [];
  masteryCriteria.forEach((objective) => {
    if (objective.meta.archived) {
      archived.push(objective);
    } else if (objective.meta.masteredRecently) {
      recentBenchMarksMet.push(objective);
    } else if (objective.attributes.masteredStatus === MASTERED_STATUS_CRITERIA_MET) {
      benchmarksReach.push(objective);
    } else if (objective.attributes.masteredStatus === MASTERED_STATUS_IN_PROGRESS
      || objective.attributes.masteredStatus === MASTERED_STATUS_NOT_STARTED
    ) {
      inProgress.push(objective);
    }
  });

  const result = [
    {
      objectiveRowTitle: RECENTLY_MASTERED_TITLE,
      objectives: sortObjectiveList(recentBenchMarksMet),
    },
    {
      objectiveRowTitle: MASTERED_TITLE,
      objectives: sortObjectiveList(benchmarksReach),
    },
    {
      objectiveRowTitle: IN_PROGRESS_TITLE,
      objectives: sortObjectiveList(inProgress),
    },
    {
      objectiveRowTitle: ARCHIVED_TITLE,
      objectives: sortObjectiveList(archived),
    },
  ];
  return result;
};

export const getBenchmarkText = (objective) => {
  let benchmarkText = '';
  let benchmarkDate = '';

  if (objective.attributes.masteredStatus === MASTERED_STATUS_CRITERIA_MET) {
    benchmarkText = 'Mastered on';
    benchmarkDate = objective.attributes.updatedDate;
  } else if (objective.meta.archived) {
    benchmarkText = 'Last Played on';
    benchmarkDate = objective.attributes.updatedDate;
  } else if (objective.meta.masteredRecently) {
    benchmarkText = 'Mastered on';
    benchmarkDate = objective.attributes.updatedDate;
  } else if (objective.attributes.masteredStatus === MASTERED_STATUS_IN_PROGRESS
    || objective.attributes.masteredStatus === MASTERED_STATUS_NOT_STARTED) {
    if (objective.attributes.latestLessonPlayTime) {
      benchmarkText = 'Last Played on';
      benchmarkDate = objective.attributes.latestLessonPlayTime;
    } else {
      benchmarkText = 'No Activities Played';
      benchmarkDate = null;
    }
  }

  return {
    benchmarkText,
    benchmarkDate: benchmarkDate ? DateUtils.convertDateToMMddYYYY(new Date(benchmarkDate)) : null,
  };
};

export function getCorrectPercentages(objective, included, max) {
  const correctPercentages = [];

  let maxI = max;
  if (maxI === null || maxI === undefined) {
    maxI = objective && objective.relationships && objective.relationships.lessonPlays && objective.relationships.lessonPlays.data
      ? objective.relationships.lessonPlays.data.length : 5;
  }

  for (let i = 0; i < maxI; i++) {
    const lessonPlay = objective.relationships.lessonPlays.data[i];
    if (lessonPlay) {
      const lessonPlayData = included.find((include) => include.id === lessonPlay.id && include.type === lessonPlay.type);
      if (lessonPlayData) {
        correctPercentages.push({
          lessonName: lessonPlayData.attributes.lessonName,
          playDate: DateUtils.convertYYYYMMddToMMddYYYY(lessonPlayData.attributes.playDate),
          y: lessonPlayData.attributes.correctPercentage,
        });
      }
    }
  }
  correctPercentages.reverse();
  // shift the first point
  correctPercentages.unshift({ y: null });

  return correctPercentages;
}

export const createMasteryCriteriaDummyData = () => {
  const masterObjectives = [
    {
      objectiveRowTitle: IN_PROGRESS_TITLE,
      objectives: [{
        attributes: {
          name: 'Customized goals and objective',
          masteredStatus: 'in-progress',
          numberOfAssignedLessons: 2,
          percentCorrect: 80,
          consecutivePlays: 3,
          latestLessonPlayTime: new Date(),
          updatedDate: new Date(),
        },
        meta: {
          archived: false,
          masteredRecently: false,
        },
        relationships: {
          lessonPlays: {
            data: [],
          },
        },
      }],
    },

  ];
  return {
    masterObjectives,
    included: [],
  };
};

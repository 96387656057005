import PropTypes from 'prop-types';
import AddRemoveFromGroupPlay from '../../../../lessons/lessonactions/add-remove-from-group-play/AddRemoveFromGroupPlay';
import { useMyLessonsContext } from '../context/MyLessonsContextProvider';
import Logger from '../../../../../utils/Logger';

export default function MyLessonAddRemoveFromGroupPlay({
  lesson,
}) {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData: { lessons, included } } = myLessonsDomain.domainData;

  return (
    <AddRemoveFromGroupPlay
      lesson={lesson}
      onSaveSuccess={async (updatedLesson) => {
        Logger.isDebugMode({
          UPDATED_LESSON: updatedLesson,
        });
        await myLessonsDomain.updateLessons([...lessons], included);
      }}
    />
  );
}

MyLessonAddRemoveFromGroupPlay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }).isRequired,
};

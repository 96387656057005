import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import { useOpenInstructionDialog } from '../common/instructions-dialog/ActivityInstructionHooks';
import ActivityInstructionDialog from '../common/instructions-dialog/ActivityInstructionDialog';
import {
  useInitializeData,
  useGenerateComponentIds,
  useGenerateRejectedAnswerRefs,
  useSequencingDragAndDropSubmission,
  useClickSubmitAnswer,
  usePrepareViewCorrectSubmissions,
} from './SequencingActivityHooks';
import '../activities-common.scss';
import './SequencingActivity.scss';
import SequencingActivityResponse from './components/SequencingActivityResponse';
import SequencingActivityPrompt from './components/SequencingActivityPrompt';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

const SequencingActivity = forwardRef(({
  instructions,
  sequenceCards,
  responseCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  isCurrentPage,
  onInstructionFinished,
  pageIndex,
}, ref) => {
  const { id, componentId, droppableId, textLineId } = useGenerateComponentIds();

  const {
    sequencingActivityRef,
    dragAndDropApiRef,
    currentSelectedIndex,
  } = useInitializeData();

  const {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  } = useGenerateRejectedAnswerRefs();

  const {
    handleOnDragEnd,
    handleOnDragStart,
    selectedResponseCards,
    isFinished,
    setSelectedResponseCards,
  } = useSequencingDragAndDropSubmission({
    sequencingActivityRef,
    sequenceCards,
    responseCards,
    rejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
    currentSelectedIndex,
  });

  const {
    openInstructions,
    handleOnInstructionClosed,
    audioPlaying,
    hasOpen,
  } = useOpenInstructionDialog({ instructions, isCurrentPage, textLineId, onInstructionFinished, alwaysShowInstruction: true, isFinished: isFinished.current });

  const {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  } = useClickSubmitAnswer({
    sequencingActivityRef,
    dragAndDropApiRef,
    currentSelectedIndex,
  });

  usePrepareViewCorrectSubmissions({
    setSelectedResponseCards,
    responseCards,
    isFinished,
    onActivityFinished,
    pageIndex,
  });

  const sequencingPromptRefs = [];
  const setSequencingPromptRefs = (r, index) => {
    if (r) {
      sequencingPromptRefs[index] = r;
    }
  };

  const sequencingResponseRefs = [];
  const setSequencingResponseRefs = (r, index) => {
    if (r) {
      sequencingResponseRefs[index] = r;
    }
  };

  useImperativeHandle(ref, () => ({
    skipAppBar: () => !hasOpen.current,
    getEnableElements: async () => {
      if (!hasOpen.current || isFinished.current) {
        return [];
      } else {
        const remainingPrompts = sequencingPromptRefs.map((s) => s.getEnableElements()).filter((s) => s !== undefined);
        const remainingResponse = sequencingResponseRefs.map((s) => s.getEnableElements()).filter((s) => s !== undefined);

        return [
          ...remainingPrompts,
          ...remainingResponse,
        ];
      }
    },
  }));

  return (
    <>
      <ActivityInstructionDialog
        id={textLineId}
        open={openInstructions.open}
        instructions={instructions}
        onClose={handleOnInstructionClosed}
        audioPlaying={audioPlaying}
      />
      <DragDropContext
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
        sensors={[(sensorApi) => {
          dragAndDropApiRef.current = sensorApi;
        }]}
      >
        <div
          className='sequencing-play-activity'
          ref={sequencingActivityRef}
          id={componentId}
          data-test='sequencing-play-activity'
        >
          <Droppable
            droppableId={droppableId}
            direction='horizontal'
            isDropDisabled
          >
            {(sequencingProvided) => (
              <div
                ref={sequencingProvided.innerRef}
                {...sequencingProvided.droppableProps}
                className='sequencing-prompt-draggable'
              >
                <div className='prompt-cards-area panel-with-shadow' data-test='prompt-cards-area'>
                  <div className='prompt-cards'>
                    {sequenceCards.map((card, index) => {
                      const drappableKeyId = `sequencing-component-droppable-container-${id}-${index}`;
                      const promptCardId = `sequence-prompt-${id}-key-${card.key}`;
                      const responseCard = selectedResponseCards.find((r) => responseCards[r].answerKey === card.key);
                      const isAnswered = responseCard !== null && responseCard !== undefined;
                      const cardId = `${id}-${index}`;
                      const responseId = `sequencing-response-answered-${cardId}`;

                      const currentCard = responseCards[responseCard];
                      return (
                        isAnswered ? (
                          <div
                            key={responseId}
                            className={`prompt-container cards-${sequenceCards.length}-margin`}
                            data-test={`sequencing-prompt-answer-${index}`}
                          >
                            <SequencingActivityResponse
                              responseCard={currentCard}
                              responseCardId={responseId}
                              disablePlayMedia
                            />
                            {/** Alway display the indicator when the correct answer is submitted */}
                            <SubmittedAnswerIndicator
                              correctSubmission={currentCard.correctSubmission
                                || ((currentCard.correctSubmission === undefined || currentCard.correctSubmission === null) && card.key === currentCard.answerKey)}
                            />
                          </div>
                        ) : (
                          <Droppable
                            key={drappableKeyId}
                            droppableId={drappableKeyId}
                            direction='horizontal'
                          >
                            {(provided, snapshot) => (
                              <div
                                data-test={`sequencing-prompt-${index}`}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={`
                                prompt-container
                                ${snapshot.isDraggingOver
                                  ? 'sequencing-selected-component'
                                  : 'non-drag-items'
                                }
                          cards-${sequenceCards.length}-margin
                        `}
                              >
                                <SequencingActivityPrompt
                                  sequenceCard={card}
                                  sequenceCardId={promptCardId}
                                  onClick={(e) => {
                                    handleOnPromptCardClick(e, promptCardId);
                                  }}
                                  ref={(r) => { setSequencingPromptRefs(r, index); }}
                                />
                                <RejectedAnswerResponse ref={(r) => { setRejectedAnswerPromptRefs(r, index); }} showIcon={false} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )
                      );
                    })}
                  </div>
                </div>

                <div className='response-cards-area' data-test='response-cards-area'>
                  <div className='response-cards'>
                    {responseCards.map((responseCard, index) => {
                      const cardId = `${id}-${index}`;
                      const responseKeyId = `sequencing-response-key-${cardId}`;
                      const responseId = `sequencing-response-${cardId}`;
                      const draggableId = `sequencing-drag-item-${cardId}`;
                      const isResponseSelected = selectedResponseCards.includes(index);
                      return (
                        <div
                          key={responseKeyId}
                          className={`response-card-place-holder item-placeholder cards-${sequenceCards.length}-margin`}
                        >
                          {/* {!selectedResponseCards.includes(index) && ( */}
                          <Draggable
                            key={draggableId}
                            draggableId={draggableId}
                            index={index}
                            shouldRespectForcePress={false}
                            timeForLongPress={0}
                          >
                            {(providedItem, snapshotItem) => (
                              <div
                                data-test={`sequencing-response-${index}`}
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                id={`sequencing-response-item-${cardId}`}
                                key={`sequencing-response-item-${cardId}`}
                                className={`
                                  ${isResponseSelected ? '' : 'sequencing-response-draggable'} 
                                  ${snapshotItem.isDragging
                                  ? 'sequencing-selected-component'
                                  : 'non-drag-items'
                                }
                                `}
                                style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                              >
                                {!isResponseSelected && (
                                  <>
                                    <SequencingActivityResponse
                                      responseCard={responseCard}
                                      responseCardId={responseId}
                                      onClick={(e, isTtsAbleToPlay) => {
                                        handleOnResponseCardClick(e, isTtsAbleToPlay, responseCard, responseId, draggableId);
                                      }}
                                      ref={(r) => { setSequencingResponseRefs(r, index); }}
                                    />
                                    <RejectedAnswerResponse ref={(r) => { setRejectedAnswerResponseRefs(r, index); }} />
                                  </>
                                )}

                              </div>
                            )}
                          </Draggable>
                          {/* )} */}
                        </div>
                      );
                    })}
                  </div>
                </div>

                {sequencingProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
});

export default SequencingActivity;

SequencingActivity.defaultProps = {
  instructions: {},
  sequenceCards: [],
  responseCards: [],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  isCurrentPage: false,
  onInstructionFinished: () => { },
};

SequencingActivity.propTypes = {
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  sequenceCards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    }),
  ),
  responseCards: PropTypes.arrayOf(
    PropTypes.shape({
      answerKey: PropTypes.string,
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
      correctSubmission: PropTypes.bool,
    }),
  ),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  onInstructionFinished: PropTypes.func,
  pageIndex: PropTypes.number.isRequired,
};

import { getFileAsBlob } from '../../services/MediaService';
import Logger from '../../utils/Logger';
import { useGetRemoteServiceWrapper } from './RemoteServiceHooks';

export const useDownloadFileAsBlob = () => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const downloadFileToDataBlob = async (fullUrl) => {
    let result = null;
    try {
      await callRemoteServiceWrapper(async () => {
        if (!fullUrl) {
          return;
        }
        const url = new URL(fullUrl);
        const pathname = url.pathname.replace('/', '');
        result = await getFileAsBlob(pathname);
      });
    } catch (e) {
      Logger.logError('File is not found');
      Logger.logError(e);
      throw e;
    }
    return result;
  };

  return { loading, downloadFileToDataBlob };
};

import PropTypes from 'prop-types';
import './TextEditorWarningLabel.scss';

function TextEditorWarningLabel({
  show,
  message,
}) {
  if (!show) {
    return null;
  }
  return (
    <div className='text-editor-warning-label'>
      {message}
    </div>
  );
}

export default TextEditorWarningLabel;

TextEditorWarningLabel.defaultProps = {
  show: false,
  message: '',
};

TextEditorWarningLabel.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

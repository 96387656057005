import SimpleTable from '../../../../../components/table/SimpleTable';
import DeleteExternalLink from './table-actions/DeleteExternalLink';
import UpdateExternalLink from './table-actions/UpdateExternalLink';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useGroupPlay } from '../context/GroupPlayContextProvider';
import './ExternalLinkTable.scss';

export const summaryTableConfig = [
  {
    title: '',
    align: 'left',
    width: '90%',
    dataField: 'externalLink',
    dataRenderer: (data) => {
      const { attributes } = data;
      if (data && attributes) {
        const link = data.attributes.link.startsWith('http') ? data.attributes.link : `//${data.attributes.link}`;
        return (
          <a href={link} rel='noopener noreferrer' target='_blank'>
            {data.attributes.label || data.attributes.link}
          </a>
        );
      }
      return (null);
    },
  },
  {
    title: '',
    align: 'center',
    width: '10%',
    dataField: 'action',
    dataRenderer: (data) => (
      <div className='table-actions'>
        <UpdateExternalLink externalLink={data} />
        <DeleteExternalLink externalLink={data} />
      </div>
    ),
  },
];
const ExternalLinkTable = () => {
  const { groupPlayDomain } = useGroupPlay();
  const { externalLinks } = groupPlayDomain.domainData;

  if (!externalLinks || externalLinks.length === 0) {
    return (<NoLessonPaper message='No external links' className='no-external-links' />);
  }

  return (
    <div className='external-links-table-container'>
      <SimpleTable
        noTableHeader
        config={summaryTableConfig}
        tableData={externalLinks}
        tableClassName='external-links-table'
      />
    </div>
  );
};

export default ExternalLinkTable;

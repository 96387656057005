import vizzleAxios from './service';

export default {
  createExtensionActivities: async (topicId, actitivies) => {
    const url = `/topics/${topicId}/extensionactivity`;
    const result = await vizzleAxios.post(url, actitivies);
    return result.data;
  },

  getExtensionActivities: async (topicId) => {
    const url = `/topics/${topicId}/extensionactivity`;
    const result = await vizzleAxios.get(url);
    return result.data;
  },

  updateName: async (topicId, extensionActivityId, newFileName) => {
    const url = `/topics/${topicId}/extensionactivity/${extensionActivityId}`;
    const result = await vizzleAxios.put(url, {
      name: newFileName,
    });
    return result.data;
  },
  deleteExtensionActivities: async (topicId, actitivies) => {
    const url = `/topics/${topicId}/extensionactivity`;
    const result = await vizzleAxios.delete(url, { data: actitivies });
    return result.data;
  },
};

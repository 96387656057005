import { useState } from 'react';
import { getUserDataById } from '../../../services/AdminService';
import { useDomain } from '../../../states/AppDomainProvider';
import Logger from '../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useAdmin } from '../context/AdminContextProvider';
import PasswordUtils, { invalidConfirmPassword, invalidPassword, passwordMismatch } from '../../../utils/PasswordUtils';

export const useGetUserDataById = () => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const [userData, setUserData] = useState({ data: {}, included: [] });
  const getUserData = async (id) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getUserDataById(id);
        setUserData(result);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };
  return {
    getUserData,
    userData,
    loading,
  };
};

export const getDataFromIncluded = (dataList, included) => dataList.map((s) => included.find((t) => t.type === s.type && t.id === s.id));

export const MIN_PASSWORD = 4;
export const usePasswordValidator = () => {
  const [passwordError, setPasswordError] = useState({
    password: null,
    confirmPassword: null,
  });

  const validatePassword = (password = '', confirmPassword = '') => {
    const updatedData = {
      password: null,
      confirmPassword: null,
    };

    if (!password && !confirmPassword) {
      setPasswordError(updatedData);
      return;
    }

    if (password && password.length < MIN_PASSWORD) {
      updatedData.password = `Please enter at least ${MIN_PASSWORD} characters`;
      setPasswordError(updatedData);
      return;
    }
    if (confirmPassword && confirmPassword.length < MIN_PASSWORD) {
      updatedData.confirmPassword = `Please enter at least ${MIN_PASSWORD} characters`;
      setPasswordError(updatedData);
      return;
    }

    if (password !== confirmPassword) {
      updatedData.password = null;
      updatedData.confirmPassword = 'Password does not match';
      setPasswordError(updatedData);
      return;
    }
    setPasswordError(updatedData);
  };

  const validatePasswordTeacher = (password = '', confirmPassword = '') => {
    const updatedData = {
      password: null,
      confirmPassword: null,
    };

    if (!password && !confirmPassword) {
      setPasswordError(updatedData);
      return;
    }

    if (password && PasswordUtils.validatePassword(password)) {
      updatedData.password = invalidPassword;
      setPasswordError(updatedData);
      return;
    }
    if (confirmPassword && PasswordUtils.validatePassword(confirmPassword)) {
      updatedData.confirmPassword = invalidConfirmPassword;
      setPasswordError(updatedData);
      return;
    }

    if (password !== confirmPassword) {
      updatedData.password = null;
      updatedData.confirmPassword = passwordMismatch;
      setPasswordError(updatedData);
      return;
    }
    setPasswordError(updatedData);
  };

  return {
    validatePassword,
    validatePasswordTeacher,
    passwordError,
  };
};

export const useGetDistrictAndSchool = () => {
  const { adminDomain, districtsDomain } = useAdmin();
  const { selectedDistrictId, selectedSchoolId, schoolsBySelectedDistrictId } = adminDomain.domainData;
  if (!selectedDistrictId || !selectedSchoolId) {
    return {
      selectedDistrict: null,
      selectedSchool: null,
    };
  }
  const { districts } = districtsDomain.domainData;
  const district = districts.find((d) => d.id === selectedDistrictId);
  const school = schoolsBySelectedDistrictId.find((s) => s.id === selectedSchoolId);

  const selectedDistrict = {
    key: district.id,
    name: district.name,
  };

  const selectedSchool = {
    key: school.id,
    name: school.attributes.name,
  };

  return {
    selectedDistrict,
    selectedSchool,
  };
};

export const useCheckAddAccess = () => {
  const { userDomain } = useDomain();
  const { user: { addUsersAccess, userSuperAdmin, userLeadTeacher } } = userDomain.domainData;
  return addUsersAccess || userSuperAdmin || userLeadTeacher;
};

import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import './ContainerWithSideBar.scss';

const ContainerWithSideBar = ({
  sideBar,
  content,
}) => (
  <div className='container-with-side-bar'>
    <div className='container-sidebar'>
      {sideBar}
    </div>
    <Divider orientation='vertical' />
    <div className='container-content'>
      {content}
    </div>
  </div>
);

ContainerWithSideBar.defaultProps = {
  sideBar: null,
  content: null,
};

ContainerWithSideBar.propTypes = {
  sideBar: PropTypes.node,
  content: PropTypes.node,
};

export default ContainerWithSideBar;

/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CardAction from '../../../components/card/CardAction';
import { assignLesson } from '../../../services/LessonService';
import { useDomain } from '../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import Logger from '../../../utils/Logger';
import './BookmarkLessonAction.scss';

/**
 * Share Link Lesson Action Container
 */
export default function BookmarkLessonAction({
  lesson,
  showLabel,
  className,
  onSaveSuccess,
}) {
  const { meta: { assigned, archived } } = lesson;
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  if (assigned && !archived) {
    return (
      <CardAction
        icon={<BookmarkIcon />}
        label={showLabel ? 'ACTIVITY ADDED' : ''}
        className={`${className} btn-green-background bookmark-lesson-action-assigned`}
        actionHandler={() => { }}
        data-test='add-to-my-lessons-action'
      />
    );
  }

  const handleOnAddToMyLessons = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await assignLesson([lesson.id], user.id);
        await uiDomain.showSnackbar(true, 'success', 3000, 'The Activity has been added to My Resources');
      });
      await onSaveSuccess();
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return (
    <CardAction
      icon={<BookmarkIcon />}
      label={showLabel ? 'ADD TO MY RESOURCES' : ''}
      className={`${className} btn-gray-background bookmark-lesson-action-click-to-assign`}
      actionHandler={handleOnAddToMyLessons}
      data-test='add-to-my-lessons-action'
    />
  );
}

BookmarkLessonAction.defaultProps = {
  showLabel: false,
  onSaveSuccess: () => { },
  className: '',
};

BookmarkLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  onSaveSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import ThumbnailCard from '../common/ThumbnailCard';
import { getPageStyle } from '../common/LessonPageUtil';
import './SortingCardThumbnail.scss';

export default function SortingCardThumbnail({ promptCardsConfig, responseCardsConfig, isSmallThumbnail }) {
  const createCard = (className, cards) => (
    cards.map((c) => {
      const caredId = uuidv1();
      return (
        <ThumbnailCard
          id={caredId}
          key={caredId}
          image={c.image}
          text={c.text}
          isSmallThumbnail={isSmallThumbnail}
          className={className}
          style={getPageStyle(c, true)}
        />
      );
    })
  );

  return (
    <div className='sorting-card-thumbnail'>
      <div className='sorting-card-prompts'>
        {createCard('sorting-card-prompt', promptCardsConfig)}
      </div>
      <div className='sorting-card-responses'>
        {createCard('sorting-card-response', responseCardsConfig)}
      </div>
    </div>
  );
}

SortingCardThumbnail.defaultProps = {
  promptCardsConfig: [],
  responseCardsConfig: [],
  isSmallThumbnail: false,
};

SortingCardThumbnail.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  promptCardsConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  responseCardsConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
};

import MaxChoicesSettings from './MaxChoices';
import MatchingTypeSettings from './MatchingType';
import RedXPromptingSettings from './RedXPrompting';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';
import { redXPromptingTooltipText, matchingTooltipText, maxMatchingChoicesTooltipText } from '../../../../texts/TooltipText';
import InteractionContainer from '../../../settings/InteractionContainer';

const InteractionSettings = () => {
  const components = [
    {
      title: 'Red X Prompting',
      component: <RedXPromptingSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...redXPromptingTooltipText} />,
      className: 'limit-width-20',
    },
    {
      title: 'Matching Type',
      component: <MatchingTypeSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...matchingTooltipText} />,
      className: 'limit-width-30',
    },
    {
      title: 'Max Choices',
      component: <MaxChoicesSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...maxMatchingChoicesTooltipText} />,
      className: 'limit-width-30',
    },
  ];

  return (
    <InteractionContainer
      components={components}
      className='mysettings-interaction'
    />
  );
};
export default InteractionSettings;

import PropTypes from 'prop-types';
import { sort } from '../../utils/ArrayUtils';
import { mapLessonStandards } from '../../utils/LessonsMapping';
import LessonStandardSection from './LessonStandardSection';

export default function LessonStandardList({ lesson, included, currentUser, currentUserProfle }) {
  const sortedUniqueStandards = mapLessonStandards([lesson], included);
  const hasStandard = sortedUniqueStandards && sortedUniqueStandards.length > 0;
  let { lessonLevel } = lesson.attributes;

  if (!hasStandard) {
    return (
      <LessonStandardSection title='Activity Standards' lessonLevel={lesson.attributes.lessonLevel} standardList={[]} />
    );
  }

  const groupedStandard = {};
  sortedUniqueStandards.forEach((s) => {
    let group = groupedStandard[s.attributes.scope];
    if (!group) {
      group = [];
    }
    group.push(s);
    groupedStandard[s.attributes.scope] = group;
  });

  const { standardScopes } = currentUserProfle;
  const displayedGroupedStandard = Object.keys(groupedStandard).map((g) => {
    const scope = standardScopes.find((s) => s.scopeCode === g);
    if (!scope) {
      return null;
    }
    return {
      title: scope.scopeDisplayName,
      standardList: groupedStandard[g],
    };
  }).filter((d) => d !== null);

  if (currentUser.userModerator || currentUser.unitCreator) {
    lessonLevel = lessonLevel || sortedUniqueStandards[0].attributes.lessonLevel;
    sort(displayedGroupedStandard, 'title').map((d) => (
      <LessonStandardSection
        key={d.title}
        title={d.title}
        lessonLevel={lessonLevel}
        standardList={d.standardList}
      />
    ));
  }

  if (!displayedGroupedStandard || displayedGroupedStandard.length === 0) {
    return (
      <LessonStandardSection title='Activity Standards' lessonLevel={lesson.attributes.lessonLevel} standardList={[]} />
    );
  }

  return (
    sort(displayedGroupedStandard, 'title').map((d) => (
      <LessonStandardSection
        key={d.title}
        title={d.title}
        lessonLevel={lesson.attributes.lessonLevel}
        standardList={d.standardList}
      />
    ))
  );
}

LessonStandardList.defaultProps = {
  lesson: {},
  included: [],
  currentUser: {},
  currentUserProfle: {},
};

LessonStandardList.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    specialtyContent: PropTypes.object,
  }),
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  currentUser: PropTypes.shape({
    userName: PropTypes.string,
    userModerator: PropTypes.bool,
    unitCreator: PropTypes.bool,
  }),
  currentUserProfle: PropTypes.shape({
    id: PropTypes.number,
    standardScopes: PropTypes.array,
  }),
};

import { useState } from 'react';

export const useLogout = () => {
  const [logoutDialog, setLogoutDialog] = useState(false);

  const openLogoutDialog = () => {
    setLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialog(false);
  };

  return {
    logoutDialog,
    openLogoutDialog,
    closeLogoutDialog,
  };
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stepper,
  Step,
  StepButton,
  StepLabel,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Brightness1';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import './VerticalStepper.scss';

/**
 * Progress through numbered steps with vertical orientation
 *
 * @param {*} props
 * steps - steps {
 *  stepLabel: Step label,
 *  component: Component to display on the step,
 * }
 * activeStep - the current active step (step index)
 */
export default function VerticalStepper(
  {
    steps,
    activeStep,
    handleStep,
    nonLinear,
    className,
    stepperClassName,
    ...others
  },
) {
  const [open, setOpen] = useState(true);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const displayTextOrIcon = (index, step) => {
    if (open) {
      return (
        <StepButton
          icon={<CircleIcon className={`vertical-step-icon ${index === activeStep ? 'vertical-step-selected-icon' : ''}`} />}
          onClick={handleStep(index)}
          className={`vizzle-stepper-${index}`}
        >
          <div className='vertical-step-stepper-label'>{step.stepLabel}</div>
        </StepButton>
      );
    }
    return (
      <StepButton
        onClick={handleStep(index)}
        icon=''
        className={`vertical-stepper-icon ${index === activeStep ? 'vertical-icon-step-selected' : ''} vizzle-collapsed-icon-stepper-${index}`}
      >
        {step.icon}
      </StepButton>
    );
  };

  return (
    <div className={`vertical-step-container ${className} ${open ? '' : 'vertical-step-container-collapsed'}`} {...others}>
      <div className={`vertical-step-stepper-container ${stepperClassName}`}>
        {open ? (
          <IconButton className='vertical-step-collapse-action' onClick={handleOnClick}>
            <NavigateBeforeIcon className='vertical-step-collapse-icon' />
          </IconButton>
        ) : (
          <IconButton className='vertical-step-collapse-action' onClick={handleOnClick}>
            <NavigateNextIcon className='vertical-step-collapse-icon' />
          </IconButton>
        )}

        <div className='stepper-inner'>
          <Stepper
            activeStep={activeStep}
            nonLinear={nonLinear}
            orientation='vertical'
            className='vertical-step-stepper'
          >
            {steps.map((step, index) => (
              <Step key={step.stepLabel} data-test={`vertical-step-stepper-${index}`}>

                {handleStep && activeStep != null
                  ? displayTextOrIcon(index, step)
                  : (
                    <StepLabel
                      icon={<CircleIcon className={`vertical-step-icon ${index === activeStep ? 'vertical-step-selected-icon' : ''}`} />}
                      className={`vizzle-stepper-${index}`}
                    >
                      <div className='vertical-step-stepper-label'>{step.stepLabel}</div>
                    </StepLabel>
                  )}

              </Step>
            ))}
          </Stepper>
        </div>

      </div>
      <div className='vertical-step-content'>
        {activeStep === null
          ? ''
          : steps[activeStep].component}
      </div>
    </div>
  );
}

VerticalStepper.defaultProps = {
  activeStep: null,
  handleStep: null,
  nonLinear: false,
  className: '',
  stepperClassName: '',
};

VerticalStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    stepLabel: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
    icon: PropTypes.object,
  })).isRequired,
  activeStep: PropTypes.number,
  handleStep: PropTypes.func,
  nonLinear: PropTypes.bool,
  className: PropTypes.string,
  stepperClassName: PropTypes.string,
};

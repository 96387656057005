import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FullScreenDialogbox from '../../../../components/dialog/FullScreenDialogbox';
import LessonNavigator from '../../../../components/navigator/LessonNavigator';
import ReviewerNotes from '../actions/reviewer/ReviewerNotes';
import { stopAudio } from '../../../../components/vizzle/media/AudioPlayer';
import { useDomain } from '../../../../states/AppDomainProvider';
import { getActivityCardPreviewDataByActivityType, getActivityCardPreviewByActivityType } from '../../../../utils/activitytype/ActivityTypesUtil';
import { cancelTts } from '../../../../services/tts/TextToSpeechServiceV2';
import { useLessonPlay } from '../../../lessonplay/context/LessonPlayContextProvider';
// import { hideChatIcon } from '../../../../services/IntercomChatService';
import { hideAccessibeChatIcon } from '../../../../services/AccessibeService';
import LiveChatService from '../../../../services/LiveChatService';
import './LessonPreview.scss';
// import CopyLessonPageAction from '../actions/copy-lesson-page/CopyLessonPageAction';
import CopyLessonMultiplePagesAction from '../actions/copy-lesson-page/copy-lesson-multiple-page/CopyLessonMultiplePagesAction';
import ReinforcerV2Service from '../../../terminologies/reinforcer-v2/ReinforcerV2Service';
import { REINFORCER_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';
import ObjectUtils from '../../../../utils/ObjectUtils';
import CorrectSubmissionMessage from '../../../lessonplay/components/ui/CorrectSubmissionMessage';
import { showMicrosoftCopilot } from '../../../../components/copilot/MicrosoftCopilot';

const autoCloseCorrectSubmissionMessage = 4000;

const stopMedias = () => {
  stopAudio();
  cancelTts();
  const audioList = (document.getElementsByTagName('audio'));
  [].forEach.call(audioList, (a) => {
    a.pause();
    // eslint-disable-next-line no-param-reassign
    a.currentTime = 0;
  });

  // Stop video and audio when changing the card.
  const elList = document.getElementsByClassName('vizzle-lesson-preview-nativator');
  if (elList.length > 0) {
    [].forEach.call(elList, (el) => {
      const videoList = (el.getElementsByTagName('video'));
      [].forEach.call(videoList, (vl) => {
        vl.pause();
        // eslint-disable-next-line no-param-reassign
        vl.currentTime = 0;
      });
    });
  }
};

/**
 * Container for review the lesson
 * @param {*}
 */
export default function LessonPreview({ open, onClose, initialSlide }) {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { lessonData, pagesData } = lessonDataMaintenanceDomain.domainData;
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [activitiyCardData, setActivitiyCardData] = useState([]);
  const { user, userProfile } = userDomain.domainData;
  const [showCorrectSubmissionMessage, setShowCorrectSubmissionMessage] = useState(false);

  const {
    lessonPlayDomain,
  } = useLessonPlay();

  const handleClose = () => {
    onClose();
    lessonPlayDomain.updateCurrentPageIndex(0);
  };

  const afterSlideChange = (index) => {
    lessonPlayDomain.updateCurrentPageIndex(index);
  };

  /**
   * As during the data and props preparation, some activities needs to shuffle the response cards.
   * We do not need the shuffle process happens everytime when the state of this component is changed
   * e.g. setCurrentIndex is called.
   *
   * Solution
   * Separate the data and props preparation tand selecting activity components into 2 parts.
   * - Prepare the props and data in useEffect method, when the LessonPreview is open.
   * - Use the prepared data to render the activity compnents
   */
  useEffect(() => {
    stopMedias();
    if (open) {
      setActivitiyCardData(getActivityCardPreviewDataByActivityType(pagesData));
      hideAccessibeChatIcon(true);
      LiveChatService.hideLiveChatIcon();
      showMicrosoftCopilot(false);
    } else {
      hideAccessibeChatIcon(false);
      LiveChatService.showLiveChatIcon();
    }
    return (() => {
      showMicrosoftCopilot(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    lessonPlayDomain.updateCurrentPageIndex(initialSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSlide]);

  const correctSubmissionMessage = showCorrectSubmissionMessage ? (
    <Stack marginLeft='auto'>
      <CorrectSubmissionMessage />
    </Stack>
  ) : null;

  return (
    <FullScreenDialogbox
      open={open}
      handleClose={handleClose}
      title={`${lessonData.attributes.name} (Page ${lessonPlayDomain.domainData.currentPageIndex + 1} of ${activitiyCardData.length})`}
      className='vizzle-lesson-preview-lessons lesson-preview-dialog'
      titleAction={(
        <>
          {
            (user.userModerator || user.unitCreator)
            && (
              <>
                <CopyLessonMultiplePagesAction currentIndex={lessonPlayDomain.domainData.currentPageIndex} />
                {/* <CopyLessonPageAction currentIndex={lessonPlayDomain.domainData.currentPageIndex} /> */}
              </>
            )
          }
          <ReviewerNotes />
        </>
      )}
      data-test='lesson-preview-dialog'
    >
      <LessonNavigator
        afterSlideChange={afterSlideChange}
        beforeSlideChange={stopMedias}
        initialSlide={initialSlide}
        className={`vizzle-lesson-preview-nativator ${showCorrectSubmissionMessage ? 'success-message-toolbar' : ''}`}
        toolbarChildren={correctSubmissionMessage}
      >
        {
          getActivityCardPreviewByActivityType({
            activitiyCardData,
            currentIndex: lessonPlayDomain.domainData.currentPageIndex,
            onCorrectAnswerSelected: async ({ showReinforcer }) => {
              if (!showReinforcer) {
                return;
              }

              if ([REINFORCER_STYLE_KEY.animated].includes(userProfile.reinforcerStyle)) {
                const reinfocers = userProfile.reinforcers
                  .map((userProfileReinforcerCode) => lessonPlayDomain.domainData.terminologies.reinforcers.find((t) => t.code === userProfileReinforcerCode)).filter((r) => Boolean(r));

                await ReinforcerV2Service.playRandomReinforcer(reinfocers);
              } else if ([REINFORCER_STYLE_KEY.simple].includes(userProfile.reinforcerStyle)) {
                setShowCorrectSubmissionMessage(true);
                if (autoCloseCorrectSubmissionMessage) {
                  await ObjectUtils.setTimeout(() => {
                    setShowCorrectSubmissionMessage(false);
                  }, autoCloseCorrectSubmissionMessage);
                }
              }
            },
          })
        }

      </LessonNavigator>
    </FullScreenDialogbox>
  );
}

LessonPreview.defaultProps = {
  onClose: () => { },
  open: false,
  initialSlide: 0,
};

LessonPreview.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  initialSlide: PropTypes.number,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import './BrowseImageDataDialog.scss';
import DropdownInput from '../../../../../../components/selector/DropdownInput';
import KeywordsEditor from '../../../../../../components/form/KeywordsEditor';

const sourceOptions = [
  { id: '1', name: 'Getty' },
  { id: '2', name: 'Shutter Stock' },
  { id: '3', name: 'Other' },
];

export default function BrowseImageDataDialog({ openDialog, onApply, onClose }) {
  const [source, setSource] = useState('');
  const [keywords, setKeywords] = useState([]);

  return (
    <CustomDialog
      title='Upload Image'
      openDialog={openDialog}
      onClose={onClose}
      displayCloseIcon
      className='browse-image-data-dialog'
      content={(
        <div className='browse-image-data-container'>
          <DropdownInput
            dropdownValues={sourceOptions}
            label='Source'
            name='source'
            value={source}
            onChange={async (e) => {
              setSource(e.target.value);
            }}
          />

          <KeywordsEditor
            className='browse-image-keywords-container'
            keywords={keywords}
            onKeywordsUpdate={async (updatedKeywords) => {
              setKeywords([...updatedKeywords]);
            }}
          />
        </div>
      )}
      actions={[
        {
          title: 'Cancel',
          color: 'primary',
          className: 'btn-danger',
          onClick: onClose,
        },
        {
          title: 'Apply',
          color: 'primary',
          className: 'btn-primary',
          onClick: () => {
            const data = {
              source,
              keywords,
              makePublic: true, // always pass this to true
            };
            onApply(data);
          },
          disabled: (!source || keywords.length === 0),
        },
      ]}
    />
  );
}

BrowseImageDataDialog.defaultProps = {
  openDialog: false,
  onApply: () => { },
  onClose: () => { },
};

BrowseImageDataDialog.propTypes = {
  openDialog: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
};

import { useState } from 'react';

export const useHandleSnackbar = () => {
  const [snackbar, setSnackbar] = useState({});

  const openSnackbar = ({
    variant,
    autoCloseIn,
    open,
    message,
  }) => {
    setSnackbar({
      variant,
      autoCloseIn,
      open,
      message,
    });
  };

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
    });
  };

  return {
    snackbar,
    openSnackbar,
    onSnackbarClose,
  };
};

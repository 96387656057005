import { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import MathView from 'react-math-view';
import { adjustMathEquationText } from '../../../../../../components/vizzle/text/TextLinesUtils';
import './MathEquationTextToSpeechEditorElement.scss';

export default function MathEquationTextToSpeechEditorElement({ equationText, ttsText, onChange }) {
  const [text, setText] = useState('');
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      if (ttsText) {
        setText(ttsText);
        return;
      }
      const t = ref.current.getValue('spoken')
        .replace(/'/gi, ' ');

      const tempText = adjustMathEquationText(t, text);
      setText(tempText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div className='math-equation-text-to-speech-editor-element'>
      <MathView
        className='math-live-container'
        value={equationText}
        readOnly
        contentEditable='false'
        suppressContentEditableWarning
        suppressHydrationWarning
        ref={ref}
      />
      <TextField
        fullWidth
        value={text}
        onChange={(e) => { setText(e.target.value); onChange(e.target.value); }}
      />
    </div>
  );
}

MathEquationTextToSpeechEditorElement.defaultProps = {
  equationText: '',
  ttsText: '',
  onChange: () => { },
};

MathEquationTextToSpeechEditorElement.propTypes = {
  equationText: PropTypes.string,
  ttsText: PropTypes.string,
  onChange: PropTypes.func,
};

import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import './SearchLessonsFilter.scss';
import SearchTextField from '../../../../../../../components/form/SearchTextField';
import SearchLessonPendingFilter from './SearchLessonPendingFilter';
import TeacherCourseFilter from './components/TeacherCourseFilter';
import { FILTER_STATUS_FOR_COPY } from '../../../../../../myclassroom/components/authored-lessons/AuthoredLessonHooks';

const SearchLessonsFilter = ({
  filterValue,
  onFilterUpdate,
  onSearchFormSubmit,
}) => {
  const handleOnFilterTextUpdate = (value) => {
    onFilterUpdate({
      ...filterValue,
      filteredText: value,
    });
  };

  const handleOnPendingUpdate = (value) => {
    onFilterUpdate({
      ...filterValue,
      pendingFilterValue: value,
    });
  };

  return (
    <div className='search-lessons-filter' data-test='search-lessons-filter'>
      <Accordion defaultExpanded className='accordion' data-test='search-lessons-filter-accordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='lesson-filter-panel'
          className='summary-panel'
          data-test='summary-panel'
        >
          <div className='accordion-summary'>
            <Typography>Filters</Typography>
          </div>

        </AccordionSummary>
        <AccordionDetails>
          <div className='accordion-detail'>
            <div className='row'>
              <SearchTextField
                filteredText={filterValue.filteredText}
                onFilterUpdate={handleOnFilterTextUpdate}
              />
              <SearchLessonPendingFilter
                pendingFilterValue={filterValue.pendingFilterValue}
                onFilterUpdate={handleOnPendingUpdate}
              />
            </div>

            <div className='row'>
              {FILTER_STATUS_FOR_COPY[2].value === filterValue.pendingFilterValue && (
                <TeacherCourseFilter onSearchSubmit={onSearchFormSubmit} />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

SearchLessonsFilter.defaultProps = {
  onFilterUpdate: () => null,
  onSearchFormSubmit: () => null,
  filterValue: {
    filteredText: '',
    pendingFilterValue: '',
  },
};

SearchLessonsFilter.propTypes = {
  filterValue: PropTypes.shape({
    filteredText: PropTypes.string,
    pendingFilterValue: PropTypes.string,
  }),
  onFilterUpdate: PropTypes.func,
  onSearchFormSubmit: PropTypes.func,
};

export default SearchLessonsFilter;

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { SHOW_FINISH_TIME, useSetupTimeout } from '../CelebrationUtil';

const isLargeHeight = window.innerHeight >= 800;
const SCALE_X = 1.25;
const SCALE_Y = isLargeHeight ? 1.1 : 1.05;
const extraHeight = isLargeHeight ? 40 : 10;

const CANVAS_WIDTH = 800;
const SLOPE = (20 * 3.14159) / 180;
const TABLE_LENGTH = 28;
const TABLE_WIDTH = 15;
const PADDLE_RADIUS = 0.62;
const CAMERA_HEIGHT = 14;
const CAMERA_DISTANCE = 40;
const CAMERA_FOCAL = 1250 * SCALE_X; // <45 degree viewing angle
const START_FRAMES = 30;

const MAX_SPEED = 0.5;
const PADDLE2_CHASE_SPEED = 0.10;
const PADDLE2_HIT_SPEED = 0.4;
const PHYSICS_TICKS = 20;

const POINT_LIMIT = 3;
const GOAL_ATTRACTION = 0.0003;
const FRICTION = 0.9992;

const getTimeRemaining = (time) => SHOW_FINISH_TIME - (new Date().getTime() - time);

const AirHockeyCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    startTime: new Date().getTime(),
    canvas: null,
    tweens: [],
    timeouts: [],
    startingSide: 1,
    hitSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'hit')].sound,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
  };

  const playHit = () => {
    if (app.hitSound) {
      app.hitSound.play({ start: 0, volume: 1 });
    }
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const loadBitmaps = () => {
    app.sheet = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'spritesheetData')].spritesheet;

    app.table = new PIXI.AnimatedSprite(app.sheet.animations.table);

    app.tabletop = new PIXI.AnimatedSprite(app.sheet.animations.tabletop);

    app.paddle1 = new PIXI.AnimatedSprite(app.sheet.animations.paddle1);
    app.paddle1.scale.x = 0.66;
    app.paddle1.scale.y = 0.66;
    app.paddle1Bounds = app.paddle1.getBounds();
    app.paddle1.anchor.set(0.5);

    app.puck = new PIXI.AnimatedSprite(app.sheet.animations.puck);
    app.puck.anchor.set(0.5);
    app.puck.scale.x = 0.65;
    app.puck.scale.y = 0.65;
    app.puckBounds = app.puck.getBounds();

    app.paddle2 = new PIXI.AnimatedSprite(app.sheet.animations.paddle2);
    app.paddle2.scale.x = 0.47;
    app.paddle2.scale.y = 0.47;
    app.paddle2Bounds = app.paddle2.getBounds();
    app.paddle2.anchor.set(0.5);

    app.goodJob = new PIXI.AnimatedSprite(app.sheet.animations.greatwork);
    app.goodJobBounds = app.goodJob.getBounds();
  };

  const project = (argX, argY, argZ) => {
    const result = {};

    const transX = argX;
    const transY = argY + CAMERA_DISTANCE;
    const transZ = argZ - CAMERA_HEIGHT;

    const rotX = transX;
    const rotY = transY * Math.cos(SLOPE) - transZ * Math.sin(SLOPE);
    const rotZ = transY * Math.sin(SLOPE) + transZ * Math.cos(SLOPE);

    result.x = ((rotX * CAMERA_FOCAL) / rotY) + app.canvas.view.width / 2;
    result.y = app.canvas.view.height / 2 - ((rotZ * CAMERA_FOCAL) / rotY);

    return result;
  };

  // eslint-disable-next-line no-restricted-properties
  const distance = (argDx, argDy) => Math.sqrt(Math.pow(argDx, 2) + Math.pow(argDy, 2));

  const start = () => {
    app.startingSide = -app.startingSide;
    app.puckPos.x = -2.5;
    app.puckPos.y = (app.startingSide * TABLE_LENGTH) / 4;
    app.puckPos.dx = 0;
    app.puckPos.dy = 0;
    app.paddle2Pos.speed = PADDLE2_CHASE_SPEED;
    app.paddle2Pos.x = 0;
    app.paddle2Pos.y = TABLE_LENGTH / 3 - PADDLE_RADIUS;
    app.paddle2Pos.destX = app.paddle2Pos.x;
    app.paddle2Pos.destY = app.paddle2Pos.y;
    app.puckPos.framesSinceHit = 100;
    app.startCountdown = START_FRAMES;
  };

  const handleMove = (e) => {
    const pt = app.stage.toLocal(e.data.global);

    if (pt.x < 0 || pt.x > app.canvas.view.width / SCALE_X || pt.y < 0 || pt.y > app.canvas.view.height / SCALE_Y) {
      return;
    }
    app.stageX = pt.x;
    app.stageY = pt.y;
  };

  const createStage = () => {
    app.stage = new PIXI.Container();
    app.stage.scale.x = SCALE_X;
    app.stage.scale.y = SCALE_Y;
    app.canvas.stage.addChild(app.stage);

    const corners = [];
    corners.push(project(-TABLE_WIDTH / 2, -TABLE_LENGTH / 2, 0));
    corners.push(project(TABLE_WIDTH / 2, -TABLE_LENGTH / 2, 0));
    corners.push(project(TABLE_WIDTH / 2, TABLE_LENGTH / 2, 0));
    corners.push(project(-TABLE_WIDTH / 2, TABLE_LENGTH / 2, 0));

    app.yBottom = corners[0].y;
    app.yTop = corners[2].y;

    app.distanceBottom = distance(CAMERA_HEIGHT, CAMERA_DISTANCE - TABLE_LENGTH / 2);
    app.distanceTop = distance(CAMERA_HEIGHT, CAMERA_DISTANCE + TABLE_LENGTH / 2);

    app.table.x = 0;
    app.table.y = 0;
    app.stage.addChild(app.table);

    app.objectLayer = new PIXI.Container();
    app.objectLayer.x = 0;
    app.objectLayer.y = 0;
    app.objectLayer.width = app.canvas.view.width;
    app.objectLayer.height = app.canvas.view.height;

    app.stage.addChild(app.objectLayer);

    app.tabletop.x = app.table.x;
    app.tabletop.y = app.table.y;
    app.objectLayer.addChild(app.tabletop);

    app.paddle1.x = app.table.x + app.table.getBounds().width / 2 - app.paddle1Bounds.width;
    app.paddle1.y = app.canvas.view.height - app.paddle1Bounds.height - 10;
    app.objectLayer.addChild(app.paddle1);
    app.paddle1Pos = {};
    app.paddle1Pos.x = -TABLE_WIDTH / 4;
    app.paddle1Pos.y = -TABLE_LENGTH / 2.5 + PADDLE_RADIUS;
    app.paddle1Pos.dx = 0;
    app.paddle1Pos.dy = 0;

    app.puck.x = app.table.x + app.table.getBounds().width / 2 - app.puckBounds.width;
    app.puck.y = app.tabletop.y + app.tabletop.getBounds().height / 2 - app.puckBounds.height / 2;
    app.objectLayer.addChild(app.puck);
    app.puckPos = {};

    app.objectLayer.addChild(app.paddle2);
    app.paddle2.x = app.table.x + app.table.getBounds().width / 2 - app.paddle1Bounds.width;
    app.paddle2.y = app.tabletop.y + app.paddle2Bounds.height + 80;

    app.paddle2Pos = {};
    app.paddle2Pos.x = 0;
    app.paddle2Pos.y = TABLE_LENGTH - PADDLE_RADIUS;
    app.paddle2Pos.dx = 0;
    app.paddle2Pos.dy = 0;
    app.paddle2Pos.destX = app.paddle2Pos.x;
    app.paddle2Pos.destY = app.paddle2Pos.y;

    start();

    app.player1Score = 0;
    app.player1ScoreText = new PIXI.Text(app.player1Score, { fontFamily: 'sans-serif', fontSize: 130, fill: 0x99ddbb, textAlign: 'center' });
    app.player1ScoreText.x = 25;
    app.player1ScoreText.y = -10;
    app.stage.addChild(app.player1ScoreText);

    app.player2Score = 0;
    app.player2ScoreText = new PIXI.Text(app.player2Score, { fontFamily: 'sans-serif', fontSize: 130, fill: 0xbb99dd, textAlign: 'center' });
    app.player2ScoreText.x = app.canvas.view.width - 260;
    app.player2ScoreText.y = app.player1ScoreText.y;
    app.stage.addChild(app.player2ScoreText);

    app.timeText = new PIXI.Text('00:00', { fontFamily: 'sans-serif', fontSize: 90, fill: 0x99bbdd, textAlign: 'center' });
    app.timeText.anchor.set(0.5, 0);
    app.timeText.x = (app.canvas.view.width / 2) - (app.timeText.getBounds().width / 3);
    app.timeText.y = 0;
    app.stage.addChild(app.timeText);

    app.stage.interactive = true;
    app.stage.on('mousemove', handleMove);
    app.stage.on('touchmove', handleMove);
    app.running = true;
  };

  const unproject = (argX, argY) => {
    const result = {};

    const lerpY = (argY - app.yBottom) / (app.yTop - app.yBottom);
    const dist = 1 / (1 / app.distanceBottom + lerpY * (1 / app.distanceTop - 1 / app.distanceBottom));
    // eslint-disable-next-line no-restricted-properties
    const offset = Math.sqrt(Math.pow(dist, 2) - Math.pow(CAMERA_HEIGHT, 2));
    // eslint-disable-next-line no-mixed-operators
    result.x = (argX - app.canvas.view.width / 2) * dist / CAMERA_FOCAL;
    result.y = offset - CAMERA_DISTANCE;
    return result;
  };

  const updatePaddle1 = () => {
    const newPaddle1Pos = unproject(app.stageX, app.stageY);

    newPaddle1Pos.y = Math.max(newPaddle1Pos.y, -TABLE_LENGTH / 2.5 + PADDLE_RADIUS);
    newPaddle1Pos.y = Math.min(newPaddle1Pos.y, -0.15);

    const left = -TABLE_WIDTH / 2 + PADDLE_RADIUS;
    newPaddle1Pos.x = Math.max(newPaddle1Pos.x, -TABLE_WIDTH / 2 + PADDLE_RADIUS);

    const distY = distance(newPaddle1Pos.y, TABLE_LENGTH / 2 + PADDLE_RADIUS);
    newPaddle1Pos.x = Math.min(newPaddle1Pos.x, (-left / 2.6) + (distY - TABLE_WIDTH) / 4);

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(newPaddle1Pos.y)) {
      let dx = newPaddle1Pos.x - app.paddle1Pos.x;
      let dy = newPaddle1Pos.y - app.paddle1Pos.y;

      const dist = distance(dx, dy);
      if (dist > MAX_SPEED) {
        dx = (dx * MAX_SPEED) / dist;
        dy = (dy * MAX_SPEED) / dist;
      }

      dx /= PHYSICS_TICKS;
      dy /= PHYSICS_TICKS;

      app.paddle1Pos.dx = dx;
      app.paddle1Pos.dy = dy;
    }
  };

  const updatePaddle2 = () => {
    let dist = distance(app.paddle2Pos.destX - app.paddle2Pos.x, app.paddle2Pos.destY - app.paddle2Pos.y);

    if (dist < app.paddle2Pos.speed) {
      if (app.puckPos.y < -1 || app.puckPos.dy < -0.1 || app.puckPos.framesSinceHit < 30) {
        // chase
        app.paddle2Pos.destX = app.puckPos.x + (app.puckPos.x > 2 ? -1 : 0);
        app.paddle2Pos.destY = TABLE_LENGTH / 3 - PADDLE_RADIUS;
        app.paddle2Pos.speed = PADDLE2_CHASE_SPEED;
      } else if (app.startCountdown <= 0) {
        // hit
        app.paddle2Pos.destX = (app.puckPos.x + app.paddle2Pos.x) / 2 + Math.random() - 0.5;
        app.paddle2Pos.destY = Math.min(app.puckPos.y + PADDLE_RADIUS, TABLE_LENGTH / 2 - PADDLE_RADIUS);
        app.paddle2Pos.speed = PADDLE2_HIT_SPEED;
      } else {
        // wait
        app.startCountdown--;
        app.paddle2Pos.destX = app.paddle2Pos.x;
        app.paddle2Pos.destY = app.paddle2Pos.y;
        app.paddle2Pos.speed = PADDLE2_HIT_SPEED;
      }
    }

    dist = distance(app.paddle2Pos.destX - app.paddle2Pos.x, app.paddle2Pos.destY - app.paddle2Pos.y);

    if (dist < app.paddle2Pos.speed) {
      app.paddle2Pos.dx = app.paddle2Pos.destX - app.paddle2Pos.x;
      app.paddle2Pos.dy = app.paddle2Pos.destY - app.paddle2Pos.y;
    } else {
      app.paddle2Pos.dx = ((app.paddle2Pos.destX - app.paddle2Pos.x) * app.paddle2Pos.speed) / dist;
      app.paddle2Pos.dy = ((app.paddle2Pos.destY - app.paddle2Pos.y) * app.paddle2Pos.speed) / dist;
    }

    app.paddle2Pos.dx /= PHYSICS_TICKS;
    app.paddle2Pos.dy /= PHYSICS_TICKS;
  };

  const placeObject = (argBitmap, argTablePos) => {
    const dist = distance(CAMERA_HEIGHT, argTablePos.y + CAMERA_DISTANCE);

    // eslint-disable-next-line no-param-reassign
    argBitmap.scale.x = (PADDLE_RADIUS * 2 * CAMERA_FOCAL) / dist / app.paddle1Bounds.width;
    // eslint-disable-next-line no-param-reassign
    argBitmap.scale.y = argBitmap.scale.x;

    const tableProject = project(argTablePos.x, argTablePos.y, 0);

    // eslint-disable-next-line no-param-reassign
    argBitmap.x = tableProject.x;
    // eslint-disable-next-line no-param-reassign
    argBitmap.y = tableProject.y;
  };

  const checkCollision = (argHitter, argHit) => {
    let dx = argHit.x - argHitter.x;
    let dy = argHit.y - argHitter.y;
    const dist = distance(dx, dy) / 1.5;
    dx /= dist;
    dy /= dist;

    if (dist <= PADDLE_RADIUS * 2) {
      const hitterDx = argHitter.dx - argHit.dx;
      const hitterDy = argHitter.dy - argHit.dy;

      const dot = ((hitterDx * dx) + (hitterDy * dy)) / 2.2;

      // eslint-disable-next-line no-param-reassign
      argHit.dx += dx * dot;
      // eslint-disable-next-line no-param-reassign
      argHit.dy += dy * dot;

      // eslint-disable-next-line no-param-reassign
      argHit.x = argHitter.x + dx * PADDLE_RADIUS * 2;
      // eslint-disable-next-line no-param-reassign
      argHit.y = argHitter.y + dy * PADDLE_RADIUS * 2;

      const intensity = Math.max(dx * dot, dy * dot);

      if (intensity > 0.01) {
        // eslint-disable-next-line no-param-reassign
        argHit.framesSinceHit = 0;
      }

      playHit();
    }
  };

  const showGoodJob = () => {
    app.running = false;
    app.goodJob.x = app.canvas.view.width / 2 - (app.goodJobBounds.width * SCALE_X) / 2;
    app.goodJob.y = app.canvas.view.height / 2 - ((app.goodJobBounds.height + extraHeight) * SCALE_Y);
    app.goodJob.alpha = 0;

    app.stage.addChild(app.goodJob);

    const goodJobTextTween = new TWEEN.Tween(app.goodJob)
      .to({ alpha: 1 }, 500)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          onFinish();
        }, 5000);
        app.timeouts.push(timeout);
      });
    app.tweens.push(goodJobTextTween);
    goodJobTextTween.start();
    playClappingSound();
  };

  const runPhysics = () => {
    for (let i = 0; i < PHYSICS_TICKS; i++) {
      app.paddle1Pos.x += app.paddle1Pos.dx;
      app.paddle1Pos.y += app.paddle1Pos.dy;

      checkCollision(app.paddle1Pos, app.puckPos);

      app.paddle2Pos.x += app.paddle2Pos.dx;
      app.paddle2Pos.y += app.paddle2Pos.dy;

      checkCollision(app.paddle2Pos, app.puckPos);

      app.puckPos.x += app.puckPos.dx;
      app.puckPos.y += app.puckPos.dy;
      if (app.puckPos.x <= -TABLE_WIDTH / 2 + PADDLE_RADIUS) {
        app.puckPos.x = -TABLE_WIDTH / 2 + PADDLE_RADIUS;
        app.puckPos.dx = Math.abs(app.puckPos.dx);
        playHit();
      }

      const magicNum = app.puckPos.y > 0 ? 5 : 4.2;
      if (app.puckPos.x >= TABLE_WIDTH / magicNum - PADDLE_RADIUS) {
        app.puckPos.x = TABLE_WIDTH / magicNum - PADDLE_RADIUS;
        app.puckPos.dx = -Math.abs(app.puckPos.dx);

        playHit();
      }
      if (app.puckPos.y <= -TABLE_LENGTH / 2 - PADDLE_RADIUS * 2) {
        app.player2Score++;
        app.player2ScoreText.text = app.player2Score;
        if (app.player2Score >= POINT_LIMIT) {
          showGoodJob();
          break;
        } else {
          start();
        }
      }
      if (app.puckPos.y >= TABLE_LENGTH / 2.5 + PADDLE_RADIUS * 2) {
        app.player1Score++;
        app.player1ScoreText.text = app.player1Score;
        if (app.player1Score >= POINT_LIMIT) {
          showGoodJob();
          break;
        } else {
          start();
        }
      }
      if (app.puckPos.y > TABLE_LENGTH / 2.5 - PADDLE_RADIUS) {
        app.puckPos.dy += GOAL_ATTRACTION;
      }

      if (app.puckPos.y < -TABLE_LENGTH / 2 + PADDLE_RADIUS) {
        app.puckPos.dy -= GOAL_ATTRACTION;
      }

      app.puckPos.dx *= FRICTION;
      app.puckPos.dy *= FRICTION;
    }

    app.puckPos.framesSinceHit++;
  };

  const drawObjects = () => {
    if (app.puckPos.y < app.paddle1Pos.y && app.puckPos.y < app.paddle2Pos.y) {
      // puck -> front -> back
      app.objectLayer.setChildIndex(app.paddle2, 3);
      app.objectLayer.setChildIndex(app.tabletop, 2);
      app.objectLayer.setChildIndex(app.puck, 1);
      app.objectLayer.setChildIndex(app.paddle1, 0);
    } else if (app.puckPos.y < app.paddle2Pos.y) {
      // front -> puck -> back
      app.objectLayer.setChildIndex(app.puck, 3);
      app.objectLayer.setChildIndex(app.paddle2, 2);
      app.objectLayer.setChildIndex(app.tabletop, 1);
      app.objectLayer.setChildIndex(app.paddle1, 0);
    } else {
      // front -> back -> puck
      app.objectLayer.setChildIndex(app.paddle2, 3);
      app.objectLayer.setChildIndex(app.tabletop, 2);
      app.objectLayer.setChildIndex(app.paddle1, 1);
      app.objectLayer.setChildIndex(app.puck, 0);
    }

    placeObject(app.paddle1, app.paddle1Pos);
    placeObject(app.paddle2, app.paddle2Pos);
    placeObject(app.puck, app.puckPos);
  };

  const tick = () => {
    if (app.running) {
      updatePaddle1();
      updatePaddle2();

      runPhysics();

      drawObjects();

      const second = Math.round(getTimeRemaining(app.startTime) / 1000);
      if (second === 0) {
        app.timeText.text = '00:00';
      } else if (second < 10) {
        app.timeText.text = `00:0${second}`;
      } else {
        app.timeText.text = `00:${second}`;
      }
    }
  };

  useSetupTimeout(canvas, showGoodJob);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight + extraHeight,
        backgroundColor: 0xFFFFFF,
      });
      loadBitmaps();
      createStage();
      app.canvas.ticker.add(tick);
    }

    return () => {
      if (app.hitSound) {
        app.hitSound.stop();
      }
      if (app.clappingSound) {
        app.clappingSound.stop();
      }
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

AirHockeyCelebration.defaultProps = {
  onFinish: () => { },
};

AirHockeyCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default AirHockeyCelebration;

import { NON_ACADEMIC_SUBJECTS, SUBJECT_CONSTANTS } from '../../constants/LessonConstant';
import { useDomain } from '../../states/AppDomainProvider';
import { unique } from '../../utils/ArrayUtils';
import { USER_COMPONENT_ACADEMIC, USER_COMPONENT_SKILL_SUITE } from '../../utils/User';

export const useGetSubjectsByUserComponents = (includeMisc) => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { components } = userProfile;
  const componentIds = (components || []).map((c) => String(c.identifier));

  let subjects = [];

  if (componentIds.includes(USER_COMPONENT_ACADEMIC)) {
    subjects = [
      { id: SUBJECT_CONSTANTS.english.subjectId, value: SUBJECT_CONSTANTS.english.subjectId, name: SUBJECT_CONSTANTS.english.name },
      { id: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
      { id: SUBJECT_CONSTANTS.science.subjectId, value: SUBJECT_CONSTANTS.science.subjectId, name: SUBJECT_CONSTANTS.science.name },
      { id: SUBJECT_CONSTANTS.social.subjectId, value: SUBJECT_CONSTANTS.social.subjectId, name: SUBJECT_CONSTANTS.social.name },
    ];
  }
  if (componentIds.includes(USER_COMPONENT_SKILL_SUITE)) {
    subjects = [
      ...subjects,
      { id: SUBJECT_CONSTANTS.speech.subjectId, value: SUBJECT_CONSTANTS.speech.subjectId, name: SUBJECT_CONSTANTS.speech.name },
      { id: SUBJECT_CONSTANTS.arts.subjectId, value: SUBJECT_CONSTANTS.arts.subjectId, name: SUBJECT_CONSTANTS.arts.name },
      { id: SUBJECT_CONSTANTS.life.subjectId, value: SUBJECT_CONSTANTS.life.subjectId, name: SUBJECT_CONSTANTS.life.name },
      { id: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.socialSkills.name },
      { id: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.transitionalSkills.name },
    ];
  }

  if (includeMisc) {
    subjects.push({ id: SUBJECT_CONSTANTS.misc.subjectId, value: SUBJECT_CONSTANTS.misc.subjectId, name: SUBJECT_CONSTANTS.misc.name });
  }
  return unique(subjects, 'id');
};

import LessonLabel from '../../../../../../components/paper/LessonLabel';
import {
  LESSON_TYPE_INSTRUCTIONAL,
  TOPIC_POST_ASSESSMENT,
  TOPIC_PRE_ASSESSMENT,
  UNIT_SUMMATIVE_ASSESSMENT,
} from '../../../../../../constants/LessonConstant';
import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';
import TeacherCourseLessonBookmarkAction from './TeacherCourseLessonBookmarkAction';

export const createLessonCardAction = (lesson, user, userProfile, terminologies) => {
  const actions = [(
    <PreviewLessonAction
      lesson={lesson}
      className='top-left'
      key={`teacher-course-lesson-play-${lesson.id}`}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />
  ),
  (<TeacherCourseLessonBookmarkAction
    lesson={lesson}
    className='top-left'
    key={`teacher-course-lesson-play-bookmark-lesson-${lesson.id}`}
  />
  )];

  return actions;
};

export const createLessonCardLabel = (lesson) => {
  const labels = [];
  const { attributes: { lessonType } } = lesson;

  if (lessonType) {
    let label = '';
    let className = '';
    if (lessonType === LESSON_TYPE_INSTRUCTIONAL) {
      label = 'Instructional';
    } else if (lessonType === TOPIC_PRE_ASSESSMENT) {
      label = 'Topic Pre Assessment';
      className = 'small-label label-lesson-detail-container';
    } else if (lessonType === TOPIC_POST_ASSESSMENT) {
      label = 'Topic Post Assessment';
      className = 'small-label label-lesson-detail-container';
    } else if (lessonType === UNIT_SUMMATIVE_ASSESSMENT) {
      label = 'Unit Summative Assessment';
      className = 'small-label label-lesson-detail-container';
    }

    labels.push(
      <LessonLabel
        key={`teacher-course-lesson-label-${lesson.id}`}
        label={label}
        className={className}
      />,
    );
  }
  return labels;
};

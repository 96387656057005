import { useState, useEffect } from 'react';
import Timer from '../../../../utils/Timer';

const SECOND = 1;

/**
 * A pure component for displaying time elapsed
 */
export default function TimeElapsed() {
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  let counter = 0;
  const tick = () => {
    counter += SECOND;
    setSecondsElapsed(counter);
  };

  useEffect(() => {
    const timer = new Timer(tick, SECOND * 1000);
    timer.start();

    return () => {
      timer.stop();
    };

    // eslint-disable-next-line
  }, []);

  const convertSecondToMMSS = (s) => {
    const secNum = parseInt(s, 10); // don't forget the second param
    const hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - (hours * 3600)) / 60);
    let seconds = secNum - (hours * 3600) - (minutes * 60);

    if (minutes < 10) { minutes = `0${minutes}`; }
    if (seconds < 10) { seconds = `0${seconds}`; }
    return `${minutes}:${seconds}`;
  };

  return (
    <>
      {convertSecondToMMSS(secondsElapsed)}
    </>
  );
}

import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { getTeacherCourseStandards, searchTeacherCourseLessons } from '../../../../services/CourseService';
import { useDomain } from '../../../../states/AppDomainProvider';
import { createLessonObject, sortLessonAttributes } from '../../../../utils/LessonsMapping';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { defaultFilterCriteriaAssignedStudentCourses } from '../../context/TeacherCourseDomain';
import { useTeacherCourses } from '../../context/TeacherCourseContextProvider';
import Logger from '../../../../utils/Logger';
import ChurnZeroService from '../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../constants/ChurnZeroConstant';
import { getLessonLevels } from '../../../../services/LessonStandardService';

export const transformTeacherCourseLessons = ({ result, user }) => {
  let lessonsToUpdate = [];
  let lessonsIncluded = [];
  if (result.data) {
    const lessonsInGroup = [];
    const lessonsWithoutLessonType = [];
    let lessons = [];

    result.data.forEach((lesson) => {
      const lessonData = createLessonObject(lesson, user);
      const { attributes: { topicLessonSequenceNum } } = lessonData;
      if (topicLessonSequenceNum === null || topicLessonSequenceNum === undefined) {
        lessonsWithoutLessonType.push(lessonData);
      } else {
        lessons.push(lessonData);
      }
    });

    lessons = sortLessonAttributes(lessons, 'topicLessonSequenceNum');
    lessonsInGroup.push({
      subject: 'Activities',
      lessons: [
        ...lessons,
        ...lessonsWithoutLessonType,
      ],
    });
    lessonsToUpdate = lessonsInGroup;
    lessonsIncluded = result.included;
  }
  return {
    lessons: lessonsToUpdate,
    included: lessonsIncluded,
  };
};

export const useHandleOnSearch = () => {
  const { subjectId } = useParams();
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnSearch = async ({ searchCriteria }) => {
    uiDomain.showLoader('Fetching courses', LOADER_TYPE.RING_LOADER);
    try {
      await callRemoteServiceWrapper(async () => {
        const resultPromise = searchTeacherCourseLessons(searchCriteria);
        const standardResultPromise = getTeacherCourseStandards(searchCriteria);
        const autoGeneratedOnlyPromise = searchTeacherCourseLessons({
          ...searchCriteria,
          autoGeneratedOnly: true,
        });
        const [
          result,
          standardResult,
          autoGeneratedOnlyResult,
        ] = await Promise.all([resultPromise, standardResultPromise, autoGeneratedOnlyPromise]);
        const lessonsToUpdate = transformTeacherCourseLessons({ result, user });

        await teacherCourseSearchCriteriaDomain.updateSearchCriteriaLessons({
          hasPerformSearch: true,
          searchCriteria: { ...searchCriteria, subjectId: Number(subjectId) },
        });
        await teacherCourseDomain.updateLessons({
          lessons: lessonsToUpdate.lessons,
          included: lessonsToUpdate.included,
          selectedLessonIds: [],
          selectedDates: {},
          assignedStudentCoursesLoading: false,
          assignedStudentCourses: [],
          filterCriteriaAssignedStudentCourses: JSON.parse(JSON.stringify(defaultFilterCriteriaAssignedStudentCourses)),
          standards: standardResult ? standardResult.data : [],
          generatedLessons: {
            lessons: autoGeneratedOnlyResult.data,
            included: autoGeneratedOnlyResult.included,
          },
        });
        ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'View unit guide', user);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    uiDomain.hideLoader();
  };

  return {
    handleOnSearch,
  };
};

export const useRefreshDataWhenEnter = ({
  handleOnSearch,
}) => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const location = useLocation();
  const { subjectId: currentSelectedSubjectId } = useParams();

  const resetData = async () => {
    await teacherCourseSearchCriteriaDomain.updateSearchCriteriaLessons({
      hasPerformSearch: false,
      searchCriteria: { lessonLevel: getLessonLevels()[0].id },
    });
    teacherCourseDomain.updateLessons({
      lessons: [],
      included: [],
      hasPerformSearch: false,
      searchCriteria: { lessonLevel: getLessonLevels()[0].id },
      selectedLessonIds: [],
      selectedDates: {},
      assignedStudentCoursesLoading: false,
      assignedStudentCourses: [],
      filterCriteriaAssignedStudentCourses: JSON.parse(JSON.stringify(defaultFilterCriteriaAssignedStudentCourses)),
      standards: [],
      generatedLessons: {
        lessons: [],
        included: [],
      },
    });
  };

  useEffect(() => {
    resetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedSubjectId]);

  useEffect(() => {
    let searchCriteriaToUse = null;
    const { search } = location.state || location;
    if (search) {
      searchCriteriaToUse = search;
    } else {
      const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
      if (Number(currentSelectedSubjectId) !== searchCriteria.subjectId) {
        resetData();
      } else {
        searchCriteriaToUse = searchCriteria;
      }
    }

    if (searchCriteriaToUse && searchCriteriaToUse.courseId && searchCriteriaToUse.unitId && searchCriteriaToUse.topicId && searchCriteriaToUse.lessonLevel) {
      handleOnSearch({ searchCriteria: searchCriteriaToUse });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

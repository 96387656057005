/* eslint-disable object-shorthand */
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { getBenchmarkText, getCorrectPercentages } from '../../../../utils/MasterObjectiveMapping';
import './ObjectiveCard.scss';

const cardRatio = 1.24;

const ObjectiveCard = ({
  width,
  masterObjectiveData,
  included,
  onClick,
}) => {
  const adjustedWidth = width + 40;
  const { attributes } = masterObjectiveData;
  const cardHeight = adjustedWidth * cardRatio;

  const bentmark = getBenchmarkText(masterObjectiveData);

  let plotColor = '#000000';
  if (attributes.masteredStatus === 'criteria-met') {
    plotColor = '#5DB572';
  }

  return (
    <Card className='objective-card-container' style={{ width: adjustedWidth, height: cardHeight }} data-test={`goal-card-${masterObjectiveData.id}`}>
      <CardActionArea className='action-area' onClick={onClick} id={`goal-card-action-${masterObjectiveData.id}`}>
        <CardHeader
          title={attributes.name}
          subheader={`${attributes.numberOfAssignedLessons} ${attributes.numberOfAssignedLessons > 1 ? 'Activities' : 'Activity'}`}
          className='title'
          avatar={
            attributes.masteredStatus === 'criteria-met' ? <VerifiedUserIcon data-test='mastery-badge' /> : null
          }
          data-test='title'
        />
        <CardContent className='content'>
          <div className='graph-area' data-test='graph'>
            <HighchartsReact
              highcharts={Highcharts}
              containerProps={{ style: { height: '100%', width: '100%' } }}
              options={{
                title: {
                  text: '',
                },
                legend: { enabled: false },
                credits: {
                  enabled: false,
                },
                xAxis: {
                  visible: false,
                  max: 6,
                  min: 0,
                },
                yAxis: {
                  max: 100,
                  min: 0,
                  plotLines: [{ // mark the weekend
                    color: '#BFBEBF',
                    width: 2,
                    value: attributes.percentCorrect,
                    dashStyle: 'dash',
                  }],
                  title: '',
                },
                plotOptions: {
                  series: {
                    color: plotColor,
                    tooltip: false,
                  },
                },
                tooltip: {
                  // eslint-disable-next-line func-names
                  formatter: function () {
                    // eslint-disable-next-line react/no-this-in-sfc
                    const { options: { lessonName, playDate, y } } = this.point;
                    const lesson = lessonName ? `Activity : <b>${lessonName}</b><br/>` : '';
                    const playDateString = playDate ? `Played On : <b>${playDate}</b><br/>` : '';
                    const scoreString = y ? `Score : <b> ${y} </b>` : '';

                    return `${lesson}${playDateString}${scoreString}`;
                  },
                },
                series: [{
                  data: getCorrectPercentages(masterObjectiveData, included, 5),
                }],
              }}
            />
          </div>
          <div className='summary' data-test='summary-container'>
            <div
              data-test='mastery-percent'
              className={`percent-correct ${attributes.percentCorrect >= 100 ? 'percent-small' : ''}`}
            >
              {attributes.percentCorrect}
              %
            </div>
            <div
              data-test='mastery-description'
              className='description'
            >
              <div>
                Target score for&nbsp;
                <span className='score' data-test='consecutive-lessons'>
                  {attributes.consecutivePlays}
                </span>
              </div>
              <div>Consecutive activities</div>
            </div>
          </div>
          <div className='benchmark-reach' data-test='benchmark-reach'>
            {bentmark.benchmarkText} {bentmark.benchmarkDate}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ObjectiveCard.defaultProps = {
  width: 205,
  included: [],
  onClick: () => { },
};

ObjectiveCard.propTypes = {
  masterObjectiveData: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
  }).isRequired,
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  width: PropTypes.number,
  onClick: PropTypes.func,
};

export default ObjectiveCard;

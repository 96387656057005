import {
  Box,
  Typography,
} from '@mui/material';
import { RADIO_LIST, useGetLessonCourseData, useGetTopicDropdownList, useGetUnitDropdownList, useHandleFormChange } from './ApprovedForCourseHooks';
import './ApprovedForCourseForm.scss';
import MultiSelectorWithSearch from '../../../../../../components/selector/MultiSelectorWithSearch';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import DropdownInput from '../../../../../../components/selector/DropdownInput';
import { DAYS } from '../../../../../../constants/CourseConstant';
import {
  TOPIC_PRE_ASSESSMENT,
  TOPIC_POST_ASSESSMENT,
  UNIT_SUMMATIVE_ASSESSMENT,
} from '../../../../../../constants/LessonConstant';

export default function ApprovedForCourseForm() {
  const {
    lessonCourseLoading,
    lessonCourses,
  } = useGetLessonCourseData();

  const {
    handleOnApprovedCourseFormData,
    handleAssessmentStatusChange,
    handleDaysChange,
  } = useHandleFormChange();

  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const {
    attributes: {
      courseId,
      unitId,
      topicId,
      days,
      lessonType,
    },
  } = lessonData;

  const unitDropdown = useGetUnitDropdownList(courseId);

  const topicDropdown = useGetTopicDropdownList(courseId, unitId);

  return (
    <div className='approved-for-courses-form'>
      <DropdownInput
        label='Curriculum'
        placeholder='Course'
        disabled={
          !lessonCourses
          || lessonCourseLoading
        }
        dropdownValues={lessonCourses}
        name='courseId'
        value={courseId || ''}
        onChange={(e) => handleOnApprovedCourseFormData(e, { unitId: '', topicId: '' })}
        loading={lessonCourseLoading}
        className='dropdown-small-padding'
      />
      <br />
      <DropdownInput
        label='Unit'
        placeholder='Unit'
        disabled={
          !lessonCourses
          || lessonCourseLoading
          || courseId === null
          || courseId === undefined
          || courseId === ''
        }
        dropdownValues={unitDropdown}
        name='unitId'
        value={unitId || ''}
        onChange={(e) => handleOnApprovedCourseFormData(e, { topicId: '' })}
        loading={lessonCourseLoading}
        className='dropdown-small-padding'
      />
      <br />
      <DropdownInput
        label='Topic'
        placeholder='Topic'
        disabled={
          !lessonCourses
          || lessonCourseLoading
          || unitId === null
          || unitId === undefined
          || unitId === ''
        }
        dropdownValues={topicDropdown}
        name='topicId'
        value={topicId || ''}
        onChange={(e) => handleOnApprovedCourseFormData(e)}
        loading={lessonCourseLoading}
        className='dropdown-small-padding'
      />
      <br />
      <DropdownInput
        label='Assignment'
        placeholder='Assignment'
        dropdownValues={RADIO_LIST}
        name='lessonType'
        value={lessonType || ''}
        onChange={(e) => handleAssessmentStatusChange(e)}
        className='dropdown-small-padding'
      />
      <br />

      <Box width='100%'>
        {(![
          TOPIC_PRE_ASSESSMENT,
          TOPIC_POST_ASSESSMENT,
          UNIT_SUMMATIVE_ASSESSMENT,
        ].includes(lessonType)) && (
          <>
            <Typography variant='h5'>
              Plans
            </Typography>
            <MultiSelectorWithSearch
              className='vizzle-lesson-library-days-dropdown dropdown-small-padding'
              values={days}
              onChange={handleDaysChange}
              selectorItems={DAYS}
              placeholderWhenEmpty=''
              limitTags={2}
            />
          </>
        )}
      </Box>
    </div>
  );
}

import { useState, useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import PIXI_SOUND from 'pixi-sound';
import CelebrationUtil from './CelebrationUtil';

export const celebrationObject = {};

export const useInitCelebration = () => {
  const [celebrationState, setCelebrationState] = useState({
    open: false,
    celebrationId: '',
    ready: false,
  });

  celebrationObject.celebrationState = celebrationState;
  celebrationObject.setCelebrationState = setCelebrationState;

  return {
    celebrationState,
    setCelebrationState,
  };
};

export const usePreloadCelebration = (celebrationList, enableAudio) => {
  const [pixiLoader, setPixiLoader] = useState();
  const loaderRef = useRef(null);
  useEffect(() => {
    setPixiLoader(null);
    if (celebrationList && celebrationList.length > 0) {
      const loader = new PIXI.Loader();
      loaderRef.current = loader;
      PIXI.utils.clearTextureCache();
      celebrationList.forEach((celebration) => {
        if (celebration.id) {
          const { manifest } = celebration;
          manifest.forEach((m) => {
            const key = CelebrationUtil.getResourceKey(celebration.id, m.id);
            if ((!loader.resources[key])) {
              loader.add(key, m.src, (resource) => {
                if (['mp3'].includes(resource.extension) || (resource && resource.data && resource.data.nodeName && resource.data.nodeName.toLowerCase() === 'audio')) {
                  if (enableAudio) {
                    // eslint-disable-next-line no-param-reassign
                    resource.sound = PIXI_SOUND.Sound.from({
                      url: resource.url,
                      preload: true,
                    });
                  }
                }
              });
            }
          });
        }
      });

      loader.load();
      setPixiLoader(loader);
    }

    return () => {
      if (loaderRef.current) {
        loaderRef.current.reset();
      }
      PIXI.utils.clearTextureCache();
      PIXI.utils.destroyTextureCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [celebrationList]);

  return pixiLoader;
};

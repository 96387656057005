import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField, Typography } from '@mui/material';
import './AddTeachersForm.scss';
import { EMAIL_REGEXP } from '../../../../../../constants/Regex';
import { useDomain } from '../../../../../../states/AppDomainProvider';

export const validateTeacherData = (data) => data && data.firstName && data.lastName && data.username && EMAIL_REGEXP.test(data.username);

const AddTeachersForm = ({ teacherData, onDataChange }) => {
  const [internalData, setInternalData] = useState({ ...teacherData });

  const [emailHelperText, setEmailHelperText] = useState(false);

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userAdmin,
    userSuperAdmin,
    schoologyFlag,
  } = user;

  const handleOnChange = (e) => {
    const { name } = e.target;
    const updatedData = { ...internalData, [name]: name === 'shareStudents' ? e.target.checked : e.target.value };
    setInternalData(updatedData);
    const isDataValid = validateTeacherData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  const handleOnEmailChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      const result = EMAIL_REGEXP.test(value);
      if (result) {
        setEmailHelperText(false);
      } else {
        setEmailHelperText(true);
      }
    }

    const updatedData = { ...internalData, [name]: value };
    setInternalData({
      ...internalData,
      [e.target.name]: value,
    });
    const isDataValid = validateTeacherData(updatedData);
    onDataChange(updatedData, isDataValid);
  };

  return (
    <div className='admin-school-form'>
      <FormControl variant='outlined'>
        <TextField
          label='First Name'
          name='firstName'
          value={internalData.firstName || ''}
          onChange={handleOnChange}
          className='form-teacher-first-name'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          variant='standard'
        />
        <TextField
          label='Last Name'
          name='lastName'
          value={internalData.lastName || ''}
          onChange={handleOnChange}
          className='form-teacher-last-name'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          variant='standard'
        />
        <TextField
          label='Username (Email)'
          name='username'
          value={internalData.username || ''}
          onChange={handleOnEmailChange}
          className='form-teacher-user-name'
          inputProps={{
            maxLength: 255,
            'data-private': 'redact',
          }}
          error={emailHelperText}
          helperText={emailHelperText && 'Invalid email format'}
          variant='standard'
        />
        <Typography variant='caption'>
          Teacher will be emailed a link to set password
        </Typography>
        {(schoologyFlag || userSuperAdmin || userAdmin) && (
          <TextField
            label='Schoology ID (Optional) '
            name='schoologyId'
            value={internalData.schoologyId || ''}
            onChange={handleOnChange}
            className='form-teacher-schoology-name'
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
          />
        )}
      </FormControl>
    </div>
  );
};

AddTeachersForm.defaultProps = {
  onDataChange: () => null,
  teacherData: {},
};

AddTeachersForm.propTypes = {
  teacherData: PropTypes.shape({
    districtId: PropTypes.string,
    schoolId: PropTypes.string,
    name: PropTypes.string,
    shareStudents: PropTypes.bool,
  }),
  onDataChange: PropTypes.func,
};

export default AddTeachersForm;

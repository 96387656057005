import PropTypes from 'prop-types';
import AddRemoveFromGroupPlay from '../../../../../lessons/lessonactions/add-remove-from-group-play/AddRemoveFromGroupPlay';
import { useTeacherCurriculum } from '../../../context/TeacherCurriculumContextProvider';
import Logger from '../../../../../../utils/Logger';

export default function CurriculumAddRemoveFromGroupPlay({
  lesson,
}) {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonData } = teacherCurriculumDomain.domainData;

  return (
    <AddRemoveFromGroupPlay
      lesson={lesson}
      onSaveSuccess={async (updatedLesson) => {
        Logger.isDebugMode({
          UPDATED_LESSON: updatedLesson,
        });
        await teacherCurriculumDomain.updateLessonData([...lessonData.lessons], [...lessonData.included]);
      }}
    />
  );
}

CurriculumAddRemoveFromGroupPlay.defaultProps = {
};

CurriculumAddRemoveFromGroupPlay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }).isRequired,
};

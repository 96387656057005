import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import {
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_TEXT,
} from '../../common/CardEditorConstants';
import './CategorizingCardsEditor.scss';
import CardEditor from '../../common/card-editor/CardEditor';

export default function CategorizingCardsEditor({
  cards,
  isSelected,
  selectedCardIndex,
  selectedSection,
  onSectionSelected,
  onNumberOfCardsChange,
  title,
  options,
  createCardChildrenFuncton,
  className,
  isImageIncluded,
  isAudioIncluded,
  isVideoIncluded,
  ...rest
}) {
  return (
    <div {...rest} className={`categorizing-cards-editor ${className}`}>
      <TextField
        select
        label={title}
        value={cards.length}
        variant='outlined'
        onChange={onNumberOfCardsChange}
        fullWidth
        className='categorizing-cards-selector'
        data-test='number-of-cards-selector'
      >
        {options.map((element, index) => (
          <MenuItem key={`grid-card-editor-selectable-value-${index}`} value={element.value}>
            {element.label}
          </MenuItem>
        ))}
      </TextField>
      <div className='cards-container'>
        {cards.map((card, index) => (
          <CardEditor
            key={`card-${index}`}
            config={{
              text: card.text,
              image: card.image,
              video: card.video,
              audio: card.audio,
            }}
            isSelected={isSelected && index === selectedCardIndex}
            selectedSection={selectedSection}
            onImageSelected={() => {
              onSectionSelected(SECTION_IMAGE, index);
            }}
            onTextSelected={() => {
              onSectionSelected(SECTION_TEXT, index);
            }}
            onAudioSelected={() => {
              onSectionSelected(SECTION_AUDIO, index);
            }}
            isVideoIncluded={isVideoIncluded}
            isImageIncluded={isImageIncluded}
            isAudioIncluded={isAudioIncluded}
          >
            {createCardChildrenFuncton && createCardChildrenFuncton(card, index)}
          </CardEditor>
        ))}
      </div>
    </div>
  );
}

CategorizingCardsEditor.defaultProps = {
  className: '',
  isSelected: false,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
  onSectionSelected: () => null,
  instructions: {},
  cards: [],
  onNumberOfCardsChange: () => null,
  title: 'Number of Cards',
  options: [],
  createCardChildrenFuncton: () => null,
  isImageIncluded: true,
  isAudioIncluded: true,
  isVideoIncluded: true,
};

CategorizingCardsEditor.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  selectedCardIndex: PropTypes.number,
  onSectionSelected: PropTypes.func,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  cards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
  })),
  onNumberOfCardsChange: PropTypes.func,
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  createCardChildrenFuncton: PropTypes.func,
  isImageIncluded: PropTypes.bool,
  isAudioIncluded: PropTypes.bool,
  isVideoIncluded: PropTypes.bool,
};

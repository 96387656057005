import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const CANVAS_WIDTH = 800;
const CHANCE_LEVEL_CANDY = 2;
const CHANCE_LEVEL_COLLISION = 5;
const BOX_STACK_MAX = 6;
const SCALE = 0.75;
const JUMP_INDICATOR_WIDTH = 200;
const JUMP_INDICATOR_HEIGHT = 50;
const JUMP_INDICATOR_BAR_COLORS = [0x90E545, 0xDAE24A, 0xE9BE41, 0xEC7A3E, 0xEF3F3F];
const JUMP_BAR_REST_ALPHA = 0.15;

const CART_TRACK_VELOCITY = 0.1;
const CART_TRACK_MAX_SPEED = 6;
const FOREGROUND_VELOCITY = 0.020;
const FOREGROUND_SPEED = 5;
const BACKGROUND_VELOCITY = 0.015;
const BACKGROUND_SPEED = 4;

const COLLISION_IMPACT_LEVEL = 2;

const VERTICAL_SPEED = 1.15;

const MAX_PROPULSION_ITEMS_ALLOWED = 40;
const JUMP_THRUST_MAX = 1000;
const CART_JUMP_MAX = 500;
const SHOW_FINISH_MESSAGE_TIME = 6000;

const themes = ['castle', 'football', 'space', 'others'];

const chooseComponentsBasedOnTheme = () => {
  const sceneTheme = {
    randomCandyFunction: () => (Math.floor((Math.random() * 5) + 2)),
  };

  const themeId = themes[Math.floor(Math.random() * themes.length)];

  if (themeId === 'castle') {
    sceneTheme.trackShort = 'football_track_short';
    sceneTheme.trackLong = 'football_track_long';
    sceneTheme.cart = 'pony_running';
    sceneTheme.jumpCart = 'ponyjump';
    sceneTheme.cartAvatar = 'blank_track_short';
    sceneTheme.randomCandyFunction = () => {
      let randomCandy = Math.floor((Math.random() * 15) + 2);
      while (randomCandy === 11 || randomCandy === 10 || randomCandy === 9 || randomCandy === 8 || randomCandy === 7) {
        randomCandy = Math.floor((Math.random() * 15) + 2);
      }
      return randomCandy;
    };
    const randomScene = Math.floor((Math.random() * 3) + 1);
    if (randomScene === 1) {
      sceneTheme.trackShort = 'mountain_track_short';
      sceneTheme.trackLong = 'mountain_track_long';
      sceneTheme.scene = 'mountain';
      sceneTheme.backgroundColor = 0xFFFFFF;
    } else if (randomScene === 2) {
      sceneTheme.trackShort = 'castle_track_short';
      sceneTheme.trackLong = 'castle_track_long';
      sceneTheme.scene = 'castle';
      sceneTheme.backgroundColor = 0xC8E8E6;
    } else {
      sceneTheme.scene = 'forest';
      sceneTheme.trackShort = 'forest_track_short';
      sceneTheme.trackLong = 'forest_track_long';
      const randomBackgroundColor = Math.floor((Math.random() * 3) + 1);
      switch (randomBackgroundColor) {
        case 1:
          sceneTheme.backgroundColor = 0x304049;
          break;
        case 2:
          sceneTheme.backgroundColor = 0xF9F3CA;
          break;
        default:
          sceneTheme.backgroundColor = 0xF3FDFF;
          break;
      }
    }
  } else if (themeId === 'football') {
    sceneTheme.trackShort = 'football_track_short';
    sceneTheme.trackLong = 'football_track_long';
    sceneTheme.cart = 'runner';
    sceneTheme.jumpCart = 'runner3';
    sceneTheme.cartAvatar = 'blank_track_short';

    sceneTheme.scene = 'football';
    sceneTheme.backgroundColor = 0xFC9250;
    sceneTheme.randomCandyFunction = () => (Math.floor((Math.random() * 10) + 2));
  } else if (themeId === 'space') {
    sceneTheme.trackShort = 'blank_track_short';
    sceneTheme.trackLong = 'blank_track_long';
    sceneTheme.cart = 'rocket';
    sceneTheme.cartAvatar = 'cart_alien';

    sceneTheme.scene = 'space';
    sceneTheme.backgroundColor = 0x571A7A;
    sceneTheme.hasPropulsion = true;
  } else {
    sceneTheme.trackShort = 'track_short';
    sceneTheme.trackLong = 'track_long';
    sceneTheme.cart = 'cart';
    sceneTheme.cartAvatar = 'cart_avatar';
    sceneTheme.scene = 'city';
    sceneTheme.backgroundColor = 0x9EA9B2;
  }
  return sceneTheme;
};

const CandyExplodeCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    backgroundSpeed: 0,
    foregroundSpeed: 0,
    cartTrackSpeed: 0,
    cartYSpeed: 0,
    onTracksBounceUp: 0,
    points: 0,
    propulsionParticlesAllowed: 0,
    spritesPlay: [],
    background2Collection: [],
    background3Collection: [],
    sameTrackCount: 1,
    trackCollection: [],
    candyCollection: [],
    collisionBoxes: [],
    jumpIndicatorBars: [],
    tweens: [],
    jumpSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'jump')].sound,
    boxHitSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bangShort')].sound,
    bonusSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bonus')].sound,
    bangSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bang')].sound,
    trackSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'track')].sound,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
  };

  /* ------------------------------------- Sounds  ------------------------------------- */
  const playJumpSound = () => {
    if (!app.messageShown && app.jumpSound) {
      app.jumpSound.play({ start: 0, volume: 1 });
    }
  };

  const playBoxHitSound = () => {
    if (!app.messageShown && app.boxHitSound) {
      app.boxHitSound.play({ start: 0, volume: 0.6 });
    }
  };

  const playCandyHitSound = () => {
    if (!app.messageShown && app.bonusSound) {
      const { candyObjectType } = app;
      if ([6, 7, 8, 9, 10].includes(candyObjectType)) {
        app.bonusSound.play({ start: 0, volume: 0.4 });
      } else {
        app.bangSound.currentTime = 0;
        app.bangSound.play({ start: 0, volume: 1 });
      }
    }
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  /* ------------------------------------- End Sounds  ------------------------------------- */

  /* ------------------------------------- Create components ------------------------------------- */
  const chooseScene = () => {
    const sceneTheme = chooseComponentsBasedOnTheme();
    app.sceneTheme = sceneTheme;
  };

  const loadBitmaps = () => {
    app.sheet = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'spritesheetData')].spritesheet;

    const candy1 = new PIXI.AnimatedSprite(app.sheet.animations.candy1);
    app.candyBounds = candy1.getBounds();

    const box = new PIXI.AnimatedSprite(app.sheet.animations.box);
    app.collisionBoxBounds = box.getBounds();

    app.cart = new PIXI.AnimatedSprite(app.sheet.animations[app.sceneTheme.cart]);
    app.cartBounds = app.cart.getBounds();

    if (app.sceneTheme.jumpCart) {
      app.jumpingCart = new PIXI.AnimatedSprite(app.sheet.animations[app.sceneTheme.jumpCart]);
    }

    app.cartAvatar = new PIXI.AnimatedSprite(app.sheet.animations[app.sceneTheme.cartAvatar]);
    app.cartAvatarBounds = app.cartAvatar.getBounds();

    const jump = new PIXI.AnimatedSprite(app.sheet.animations.track_jump);
    app.jumpBounds = jump.getBounds();

    const background2 = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.sceneTheme.scene}-background2`)].texture);
    app.background2Bounds = background2.getBounds();

    const background3 = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.sceneTheme.scene}-background3`)].texture);
    app.background3Bounds = background3.getBounds();

    app.backgroundWidth = app.canvas.view.width / SCALE;
    app.backgroundHeight = app.canvas.view.height / SCALE;
  };

  const addScrollableBackground = (num = 3) => {
    let previousBackground = null;
    for (let i = 0; i < num; i++) {
      const background2 = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.sceneTheme.scene}-background2`)].texture);
      background2.x = 0;
      if (app.background2Collection.length > 0) {
        previousBackground = app.background2Collection[app.background2Collection.length - 1];
        background2.x = previousBackground.x + app.background2Bounds.width - 2;
      }
      if (previousBackground != null) {
        background2.x = previousBackground.x + app.background2Bounds.width - 2;
      }
      background2.y = (app.backgroundHeight) - app.background2Bounds.height;
      previousBackground = background2;
      app.backgroundContainer.addChildAt(background2, 0);
      app.background2Collection.push(background2);
    }
  };

  const addScrollableForeground = (num = 3) => {
    let previousBackground = null;
    for (let i = 0; i < num; i++) {
      const background3 = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.sceneTheme.scene}-background3`)].texture);
      background3.x = 0;
      if (app.background3Collection.length > 0) {
        previousBackground = app.background3Collection[app.background3Collection.length - 1];
        background3.x = previousBackground.x + app.background3Bounds.width - 2;
      }
      if (previousBackground != null) {
        background3.x = previousBackground.x + app.background3Bounds.width - 2;
      }
      background3.y = (app.backgroundHeight) - app.background3Bounds.height / 1.1;
      previousBackground = background3;
      app.backgroundContainer.addChild(background3);
      app.background3Collection.push(background3);
    }
  };

  const addCandy = (xPos, yPos) => {
    const randomAddCandy = Math.floor((Math.random() * CHANCE_LEVEL_CANDY) + 1);
    if (randomAddCandy === 1) {
      const randomCandy = app.sceneTheme.randomCandyFunction();
      const candyRotationContainer = new PIXI.Container();

      const candy = new PIXI.AnimatedSprite(app.sheet.animations[`candy${randomCandy - 1}`]);

      if (candy && app.candyBounds) {
        const randomYAdd = Math.floor((Math.random() * 270) + 1);

        candyRotationContainer.x = xPos;
        candyRotationContainer.y = yPos - randomYAdd;
        app.wrapper.addChild(candyRotationContainer);
        candy.x = -app.candyBounds.width / 2;
        candy.y = -app.candyBounds.height / 2;
        candyRotationContainer.addChild(candy);

        const candyObj = {};
        candyObj.elem = candyRotationContainer;
        candyObj.type = randomCandy - 1;
        candyObj.hit = false;
        app.candyCollection.push(candyObj);
      }
    }
  };

  const addCollisionObject = (xPos, yPos) => {
    const randomAddCollisionObject = Math.floor((Math.random() * CHANCE_LEVEL_COLLISION) + 1);
    if (randomAddCollisionObject === 1) {
      const randomAmountToStack = Math.floor((Math.random() * BOX_STACK_MAX) + 1);
      for (let i = 0; i < randomAmountToStack; i++) {
        const collisionElemRotationContainer = new PIXI.Container();
        const collisionElem = new PIXI.AnimatedSprite(app.sheet.animations.box);
        if (collisionElem) {
          collisionElemRotationContainer.x = xPos;
          collisionElemRotationContainer.y = yPos - (i * (app.collisionBoxBounds.height - 5));
          app.wrapper.addChild(collisionElemRotationContainer);

          collisionElem.x = -app.collisionBoxBounds.width / 2;
          collisionElem.y = -app.collisionBoxBounds.height + 10;
          collisionElemRotationContainer.addChild(collisionElem);

          const collisionObject = {};
          collisionObject.elem = collisionElemRotationContainer;
          collisionObject.hit = false;
          app.collisionBoxes.push(collisionObject);
        }
      }
    }
  };

  const addScrollableTracks = (num = 1) => {
    let previousObj;
    let previous;
    let previousBounds;

    for (let i = 0; i < num; i++) {
      const randomTrackType = 2;
      const type = randomTrackType === 1 ? 'trackLong' : 'trackShort';
      const track = new PIXI.AnimatedSprite(app.sheet.animations[app.sceneTheme[type]]);
      const bounds = track.getBounds();

      if (app.trackCollection.length > 0) {
        previousObj = app.trackCollection[app.trackCollection.length - 1];
        previous = previousObj.elem;
        previousBounds = previous ? previous.getBounds() : null;
      }

      const random = (Math.floor((Math.random() * 100)));
      if (previous && previousBounds) {
        track.x = previous.x + previousBounds.width - 2;
        if (random < 5) {
          const jump = new PIXI.AnimatedSprite(app.sheet.animations.track_jump);
          jump.y = previous.y - app.jumpBounds.height + 10;
          jump.x = previous.x + previousBounds.width - app.jumpBounds.width;
          previousObj.jump = jump;
          app.wrapper.addChild(jump);
        }
      }

      const trackObj = {};
      trackObj.elem = track;
      trackObj.jump = false;
      trackObj.jumpInitiated = false;

      track.y = (app.backgroundHeight) - 10;
      app.trackContainer.addChild(track);
      app.trackCollection.push(trackObj);

      addCollisionObject(track.x + bounds.width / 2, track.y);
      addCandy(track.x + bounds.width / 2, track.y - app.cartBounds.height / 2);
    }
  };

  const createCartAndCartAvatar = () => {
    const cartPositionX = 100;
    const cartPositionY = app.backgroundHeight - app.cartBounds.height - 20;

    app.cartAvatarContainer = new PIXI.Container();
    app.cartAvatar.x = cartPositionX + app.cartAvatarBounds.width - 15;
    app.cartAvatar.y = cartPositionY - app.cartAvatarBounds.height / 1.6 + (app.sceneTheme.hasPropulsion ? 10 : 0);

    app.cartAvatarContainer.addChild(app.cartAvatar);
    app.wrapper.addChild(app.cartAvatarContainer);

    app.cartContainer = new PIXI.Container();
    app.cartContainer.x = cartPositionX;
    app.cartContainer.y = cartPositionY;
    app.cart.animationSpeed = 0.2;
    app.cart.play();

    if (app.jumpingCart) {
      app.jumpingCart.alpha = 0;
      app.jumpingCart.animationSpeed = 0.2;
      app.jumpingCart.play();
      app.spritesPlay.push(app.jumpingCart);
      app.cartContainer.addChild(app.jumpingCart);
    }
    app.spritesPlay.push(app.cart);
    app.cartContainer.addChild(app.cart);
    app.wrapper.addChild(app.cartContainer);
  };

  const createScoreText = () => {
    const padding = 20;
    app.scoreText = new PIXI.Text('0 points', { fontFamily: 'Arial', fontSize: 40, fill: 0xff7700, align: 'center' });
    app.scoreText.y = padding;
    app.scoreText.x = app.backgroundWidth - app.scoreText.getBounds().width - padding;
    app.scoreText.alpha = 0.5;
    app.wrapper.addChild(app.scoreText);
  };

  const createJumpIndicator = () => {
    const padding = 20;
    app.jumpIndicatorContainer = new PIXI.Container();
    app.jumpIndicatorContainer.x = padding;
    app.jumpIndicatorContainer.y = padding;

    for (let i = 0; i < JUMP_INDICATOR_BAR_COLORS.length; i++) {
      const bar = new PIXI.Graphics();
      const barWidth = JUMP_INDICATOR_WIDTH / JUMP_INDICATOR_BAR_COLORS.length;
      const barXPos = barWidth * i;
      bar.alpha = JUMP_BAR_REST_ALPHA;
      bar.beginFill(JUMP_INDICATOR_BAR_COLORS[i]);
      bar.drawRect(barXPos, 0, barWidth, JUMP_INDICATOR_HEIGHT);
      app.jumpIndicatorContainer.addChildAt(bar, 0);
      app.jumpIndicatorBars.push(bar);
    }

    app.jumpMessageOutline = new PIXI.Text('JUMP', { fontFamily: 'Arial', fontSize: 30, fill: 0xFFFFFF, fontWeight: 'bold', strokeThickness: 2 });
    app.jumpMessageOutline.alpha = JUMP_BAR_REST_ALPHA;
    const messageTextBounds = app.jumpMessageOutline.getBounds();

    const jumpMessageX = JUMP_INDICATOR_WIDTH / 2 - messageTextBounds.width / 2;
    app.jumpMessageOutline.x = jumpMessageX;

    const jumpMessageY = JUMP_INDICATOR_HEIGHT / 2 - messageTextBounds.height / 2;
    app.jumpMessageOutline.y = jumpMessageY;
    app.jumpIndicatorContainer.addChild(app.jumpMessageOutline);
    app.wrapper.addChild(app.jumpIndicatorContainer);
  };

  const createMessageText = () => {
    app.messageContainer = new PIXI.Container();
    app.messageContainer.alpha = 0;
    app.messageContainer.visible = false;
    app.wrapper.addChild(app.messageContainer);

    const shape = new PIXI.Graphics();
    shape.beginFill(0x000000);
    shape.drawRect(0, 0, app.backgroundWidth, app.backgroundHeight);
    shape.alpha = 0.5;
    app.messageContainer.addChild(shape);

    const messageText = new PIXI.Text('Great Job!', { fontFamily: 'Arial', fontSize: 170, fill: 0xFF7700 });
    messageText.y = app.backgroundHeight / 2 - messageText.getBounds().height / 2;
    messageText.x = app.backgroundWidth / 2 - messageText.getBounds().width / 2;
    app.messageContainer.addChild(messageText);
  };

  const prepareCartForBounce = () => {
    if (app.cartContainer.y !== app.cartHeadToYPos && app.propulsionParticlesAllowed > 0) {
      app.propulsionParticlesAllowed--;
    } else if (app.propulsionParticlesAllowed < MAX_PROPULSION_ITEMS_ALLOWED) {
      app.propulsionParticlesAllowed++;
    }

    const timeOnMouseUp = (new Date()).getTime();
    let diff = timeOnMouseUp - app.timeOnMouseDown;
    diff = diff > JUMP_THRUST_MAX ? JUMP_THRUST_MAX : diff;
    app.jumpPower = diff / JUMP_THRUST_MAX;
    app.jumpMessageOutline.alpha = app.jumpPower;

    for (let i = 0; i < app.jumpIndicatorBars.length; i++) {
      const bar = app.jumpIndicatorBars[i];
      const iPercentage = ((i + 1) / JUMP_INDICATOR_BAR_COLORS.length);
      bar.alpha = i === 0 || iPercentage <= app.jumpPower ? 1 : JUMP_BAR_REST_ALPHA;
    }
  };

  const createCartJumpTween = (cartJumpToY, cartAvatarJumpToY, delay, onTweenFinish) => {
    const cartTweenMove = new TWEEN.Tween(app.cartContainer)
      .to({ y: app.cartContainer.y, rotation: 0 }, delay)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        if (onTweenFinish) {
          onTweenFinish();
        }
      });

    const cartTween = new TWEEN.Tween(app.cartContainer);
    cartTween.to({ y: cartJumpToY, rotation: -0.3 }, delay);
    cartTween.easing(TWEEN.Easing.Linear.None);
    cartTween.chain(cartTweenMove);

    const cartAvatarContainerY = app.cartAvatarContainer.y;
    const cartAvatarContainerX = app.cartAvatarContainer.x;

    const cartAvartarTween2 = new TWEEN.Tween(app.cartAvatarContainer)
      .to({ x: cartAvatarContainerX, y: cartAvatarContainerY, rotation: 0 }, delay)
      .easing(TWEEN.Easing.Linear.None);

    const cartAvartarTween = new TWEEN.Tween(app.cartAvatarContainer);
    cartAvartarTween.to({ y: cartAvatarJumpToY, x: cartAvatarContainerX - 160, rotation: -0.3 }, delay);
    cartAvartarTween.easing(TWEEN.Easing.Linear.None);
    cartAvartarTween.chain(cartAvartarTween2);

    app.tweens.push(cartAvartarTween);
    cartAvartarTween.start();

    app.tweens.push(cartTween);
    cartTween.start();
  };

  const cartJump = () => {
    if (app.cartContainer.y === app.cartHeadToYPos) {
      playJumpSound();
      app.cartJumping = true;

      if (app.sceneTheme.jumpCart) {
        app.cart.alpha = 0;
        app.jumpingCart.alpha = 1;
      }
      const cartJumpToY = app.cartContainer.y - (app.jumpPower * CART_JUMP_MAX);
      const cartAvatarJumpToY = app.cartAvatarContainer.y - (app.jumpPower * CART_JUMP_MAX) + 60;
      createCartJumpTween(cartJumpToY, cartAvatarJumpToY, 500, () => {
        if (app.sceneTheme.jumpCart) {
          app.cart.alpha = 1;
          app.jumpingCart.alpha = 0;
        }
      });
    }
  };

  const handleOnMouseDown = () => {
    if (app.mouseDownInterval) {
      clearInterval(app.mouseDownInterval);
    }

    app.mouseDownInterval = setInterval(prepareCartForBounce, 100);
    app.timeOnMouseDown = (new Date()).getTime();
  };

  const handleOnMouseUp = () => {
    app.propulsionParticlesAllowed = 0;

    if (app.mouseDownInterval) {
      clearInterval(app.mouseDownInterval);
    }

    app.jumpMessageOutline.alpha = JUMP_BAR_REST_ALPHA;

    for (let i = 0; i < app.jumpIndicatorBars.length; i++) {
      const bar = app.jumpIndicatorBars[i];
      bar.alpha = JUMP_BAR_REST_ALPHA;
    }

    const timeOnMouseUp = (new Date()).getTime();
    let diff = timeOnMouseUp - app.timeOnMouseDown;
    diff = diff > JUMP_THRUST_MAX ? JUMP_THRUST_MAX : diff;
    app.jumpPower = diff / JUMP_THRUST_MAX;
    cartJump();
  };

  const registerMouseEvents = () => {
    app.wrapper.interactive = true;

    app.wrapper.on('mousedown', handleOnMouseDown);
    app.wrapper.on('mouseup', handleOnMouseUp);

    app.wrapper.on('touchstart', handleOnMouseDown);
    app.wrapper.on('touchend', handleOnMouseUp);
  };

  const createStage = () => {
    app.wrapper = new PIXI.Container();
    app.wrapper.scale.x = SCALE;
    app.wrapper.scale.y = SCALE;
    app.canvas.stage.addChild(app.wrapper);

    /* Create background and foreground container */
    app.backgroundContainer = new PIXI.Container();
    app.wrapper.addChild(app.backgroundContainer);
    app.foregroundContainer = new PIXI.Container();
    app.wrapper.addChild(app.foregroundContainer);

    /* Create scrollable background and foreground */
    addScrollableBackground(3);
    addScrollableForeground(3);

    /* Create cart */
    app.cartPropulsionContainer = new PIXI.Container();
    app.wrapper.addChild(app.cartPropulsionContainer);
    createCartAndCartAvatar();
    createScoreText();

    /* Create track container */
    app.trackContainer = new PIXI.Container();
    app.wrapper.addChild(app.trackContainer);

    addScrollableTracks(5);
    createJumpIndicator();

    createMessageText();
    registerMouseEvents();
  };

  /* ------------------------------------- End Create components ------------------------------------- */

  /* ------------------------------------- Update components ------------------------------------- */

  const removeScrollableBackground = (elem) => {
    app.backgroundContainer.removeChild(elem);
    app.background2Collection.splice(0, 1);
  };

  const removeScrollableForeground = (elem) => {
    app.foregroundContainer.removeChild(elem);
    app.background3Collection.splice(0, 1);
  };

  const checkBackgroundPositions = () => {
    let i;
    let elem;
    for (i = 0; i < app.background2Collection.length; i++) {
      elem = app.background2Collection[i];
      if (elem.x + app.backgroundWidth <= 0) {
        addScrollableBackground(1);
      }
      if (elem.x + app.backgroundWidth <= -200) {
        removeScrollableBackground(elem);
      }
    }

    for (i = 0; i < app.background3Collection.length; i++) {
      elem = app.background3Collection[i];
      if (elem.x + app.backgroundWidth + 200 <= 0) {
        removeScrollableForeground(elem);
        addScrollableForeground(1);
      }
    }
  };

  const checkTrackJump = (startOfCart, trackObj, nextTrackObj) => {
    let hittingJump = false;

    if (trackObj.jump && nextTrackObj) {
      const { jump } = trackObj;
      const padding = 5;
      if ((startOfCart > jump.x + padding) && (startOfCart <= (jump.x + app.jumpBounds.width))) {
        hittingJump = true;
        if (!trackObj.jumpInitiated) {
          playJumpSound();

          // eslint-disable-next-line no-param-reassign
          trackObj.jumpInitiated = true;
          app.cartJumping = true;

          const cartJumpToY = nextTrackObj.elem.y - app.cartBounds.height - 200;
          const cartAvatarJumpToY = app.cartAvatarContainer.y - 150;
          createCartJumpTween(cartJumpToY, cartAvatarJumpToY, 800);
        }
      }
    }

    return hittingJump;
  };

  const checkTrackPositions = () => {
    if (app.trackCollection.length > 0) {
      const firstTrack = app.trackCollection[0].elem;
      const firstTrackBounds = firstTrack ? firstTrack.getBounds() : null;
      if (firstTrack && firstTrackBounds && (firstTrack.x + firstTrackBounds.width) <= 0) {
        app.trackCollection.splice(0, 1);
        app.trackContainer.removeChild(firstTrack);
      }

      const lastTrack = app.trackCollection[app.trackCollection.length - 1].elem;
      const lastTrackBounds = lastTrack ? lastTrack.getBounds() : null;
      if (lastTrack && lastTrackBounds && (lastTrack.x + lastTrackBounds.width) <= (app.backgroundWidth + CART_TRACK_MAX_SPEED)) {
        addScrollableTracks();
      }
    }

    for (let i = 0; i < app.trackCollection.length; i++) {
      const trackObj = app.trackCollection[i];
      const nextTrackObj = i < app.trackCollection.length - 2 ? app.trackCollection[i + 1] : null;
      const track = trackObj.elem;
      const trackBounds = track ? track.getBounds() : null;
      const startOfCart = app.cartContainer.x + app.cartBounds.width;
      if (trackBounds && (startOfCart > track.x) && (startOfCart <= (track.x + trackBounds.width))) {
        let hittingJump = false;
        if (trackObj.jump && app.cartContainer.y === app.cartHeadToYPos) {
          hittingJump = checkTrackJump(startOfCart, trackObj, nextTrackObj);
        }

        if (!hittingJump) {
          app.cartHeadToYPos = track.y - app.cartBounds.height + 3;
        }
      }
    }
  };

  const showExplosion = (obj, name) => {
    if (!app.messageShown) {
      const randomAmount = Math.floor((Math.random() * 30) + 1);
      for (let i = 0; i < (30 + randomAmount); i++) {
        const elem = new PIXI.AnimatedSprite(app.sheet.animations[name]);
        elem.x = obj.elem.x;
        elem.y = obj.elem.y;
        app.wrapper.addChild(elem);

        const randomXDirection = Math.floor((Math.random() * 2) + 1);
        const randomYDirection = Math.floor((Math.random() * 2) + 1);
        let randomX = Math.floor((Math.random() * 600) + 25);
        let randomY = Math.floor((Math.random() * 600) + 25);
        randomX *= randomXDirection === 1 ? 1 : -1;
        randomY *= randomYDirection === 1 ? 1 : -1;
        const randomScale = Math.floor((Math.random() * 3) + 1);

        const explosionScaleTween = new TWEEN.Tween(elem.scale);
        explosionScaleTween.to({ x: randomScale, y: randomScale }, 600);
        explosionScaleTween.easing(TWEEN.Easing.Linear.None);

        const explosionTween = new TWEEN.Tween(elem);
        explosionTween.to({ x: elem.x + randomX, y: elem.y + randomY, alpha: 1 }, 600);
        explosionTween.easing(TWEEN.Easing.Sinusoidal.Out);
        explosionTween.onComplete(() => {
          app.wrapper.removeChild(elem);
        });

        explosionScaleTween.start();
        explosionTween.start();

        app.tweens.push(explosionScaleTween);
        app.tweens.push(explosionTween);
      }
    }
  };

  const updateScoreText = () => {
    if (!app.messageShown) {
      if (app.candyObjectType === 1 || app.candyObjectType === 2 || app.candyObjectType === 3 || app.candyObjectType === 4 || app.candyObjectType === 5) {
        app.points++;
      } else {
        app.points++;
        app.points++;
      }

      app.scoreText.text = `${app.points} points`;
      const padding = 20;
      app.scoreText.x = app.backgroundWidth - Math.max(app.scoreText.getBounds().width, 141) - padding - (app.points >= 10 ? 20 : 0);

      const scrollTextTween = new TWEEN.Tween(app.scoreText);
      scrollTextTween.to({ alpha: 1 }, 500);
      scrollTextTween.easing(TWEEN.Easing.Linear.None);
      scrollTextTween.chain(new TWEEN.Tween(app.scoreText)
        .to({ alpha: 0.5 }, 500)
        .easing(TWEEN.Easing.Linear.None));

      scrollTextTween.start();
      app.tweens.push(scrollTextTween);
    }
  };

  const showPoints = (obj, name) => {
    if (!app.messageShown) {
      const point = new PIXI.AnimatedSprite(app.sheet.animations[name]);
      point.x = obj.elem.x;
      point.y = obj.elem.y;
      app.wrapper.addChild(point);

      const pointsTween = new TWEEN.Tween(point);
      pointsTween.to({ x: app.scoreText.x, y: app.scoreText.y, alpha: 1 }, 500);
      pointsTween.easing(TWEEN.Easing.Linear.None);
      pointsTween.onComplete(() => {
        app.wrapper.removeChild(point);
      });

      pointsTween.start();
      app.tweens.push(pointsTween);
    }
  };

  const checkCandyPositions = () => {
    for (let i = 0; i < app.candyCollection.length; i++) {
      const candyObj = app.candyCollection[i];
      const candy = candyObj.elem;

      if (candy) {
        const rect1 = {};
        const padding = 20;
        rect1.x = app.cartContainer.x + padding;
        rect1.y = app.cartContainer.y + padding;
        rect1.width = app.cartBounds.width - padding;
        rect1.height = app.cartBounds.height - padding;

        const rect2 = {};
        rect2.x = candy.x;
        rect2.y = candy.y;
        rect2.width = app.candyBounds.width;
        rect2.height = app.candyBounds.height;

        if (!candyObj.hit && !(rect1.x >= rect2.x + rect2.width || rect1.x + rect1.width <= rect2.x || rect1.y >= rect2.y + rect2.height || rect1.y + rect1.height <= rect2.y)) {
          candyObj.hit = true;
          app.candyObjectType = candyObj.type;

          updateScoreText();
          showExplosion(candyObj, `candy${candyObj.type}_explode`);
          playCandyHitSound();
          showPoints(candyObj, `candy${candyObj.type}_point`);
          app.wrapper.removeChild(candy);
          app.candyObjectType = -1;
        } else if ((candy.x + app.candyBounds.width) <= 0) {
          app.candyCollection.splice(0, 1);
          app.wrapper.removeChild(candy);
        }
      }
    }
  };

  const checkBoxPositions = () => {
    for (let i = 0; i < app.collisionBoxes.length; i++) {
      const boxObject = app.collisionBoxes[i];
      const box = boxObject.elem;

      if (box) {
        const rect1 = {};
        const padding = 0;
        rect1.x = app.cartContainer.x + padding;
        rect1.y = app.cartContainer.y + padding;
        rect1.width = app.cartBounds.width - padding;
        rect1.height = app.cartBounds.height - padding;

        const rect2 = {};
        rect2.x = box.x;
        rect2.y = box.y;
        rect2.width = app.collisionBoxBounds.width;
        rect2.height = app.collisionBoxBounds.height;

        if (!boxObject.hit && !(rect1.x >= rect2.x + rect2.width || rect1.x + rect1.width <= rect2.x || rect1.y >= rect2.y + rect2.height || rect1.y + rect1.height <= rect2.y)) {
          boxObject.hit = true;

          app.cartTrackSpeed -= COLLISION_IMPACT_LEVEL;
          app.cartTrackSpeed = app.cartTrackSpeed < 0 ? 0 : app.cartTrackSpeed;

          app.backgroundSpeed -= (COLLISION_IMPACT_LEVEL / 2);
          app.backgroundSpeed = app.backgroundSpeed < 0 ? 0 : app.backgroundSpeed;

          app.foregroundSpeed -= (COLLISION_IMPACT_LEVEL / 2);
          app.foregroundSpeed = app.foregroundSpeed < 0 ? 0 : app.foregroundSpeed;

          showExplosion(boxObject, 'box_explode');
          playBoxHitSound();
          app.wrapper.removeChild(box);
        } else if ((box.x + app.collisionBoxBounds.width) <= 0) {
          app.collisionBoxes.splice(0, 1);
          app.wrapper.removeChild(box);
        }
      }
    }
  };

  const checkPositions = () => {
    checkBackgroundPositions();
    checkTrackPositions();

    if (!app.messageShown) {
      checkCandyPositions();
      checkBoxPositions();
    }
  };

  const updateBackgroundPositions = () => {
    app.backgroundSpeed += BACKGROUND_VELOCITY;
    app.backgroundSpeed = app.backgroundSpeed > BACKGROUND_SPEED ? BACKGROUND_SPEED : app.backgroundSpeed;
    let i;
    for (i = 0; i < app.background2Collection.length; i++) {
      const background = app.background2Collection[i];
      background.x -= app.backgroundSpeed;
    }

    app.foregroundSpeed += FOREGROUND_VELOCITY;
    app.foregroundSpeed = app.foregroundSpeed > FOREGROUND_SPEED ? FOREGROUND_SPEED : app.foregroundSpeed;
    for (i = 0; i < app.background3Collection.length; i++) {
      const foreground = app.background3Collection[i];
      foreground.x -= app.foregroundSpeed;
    }
  };

  const updateTrackPositions = () => {
    app.cartTrackSpeed += CART_TRACK_VELOCITY;
    app.cartTrackSpeed = app.cartTrackSpeed > CART_TRACK_MAX_SPEED ? CART_TRACK_MAX_SPEED : app.cartTrackSpeed;

    for (let i = 0; i < app.trackCollection.length; i++) {
      const trackObj = app.trackCollection[i];
      if (trackObj.elem) {
        trackObj.elem.x -= app.cartTrackSpeed;

        if (trackObj.jump) {
          trackObj.jump.x -= app.cartTrackSpeed;
        }
      }
    }
  };

  const updateCartPosition = () => {
    if (!app.cartJumping) {
      if (app.cartContainer.y !== app.cartHeadToYPos) {
        app.cartYSpeed += VERTICAL_SPEED;

        if (app.cartContainer.y < app.cartHeadToYPos) {
          if ((app.cartContainer.y + app.cartYSpeed) > app.cartHeadToYPos) {
            app.cartContainer.y = app.cartHeadToYPos;
          } else {
            app.cartContainer.y += app.cartYSpeed;
          }
        } else if ((app.cartContainer.y - app.cartYSpeed) < app.cartHeadToYPos) {
          app.cartContainer.y = app.cartHeadToYPos;
        } else {
          app.cartContainer.y -= app.cartYSpeed;
        }
      } else {
        app.cartYSpeed = 0;
      }
    }

    if (app.cartContainer.y === app.cartHeadToYPos) {
      const bounceAmount = (app.cartTrackSpeed / CART_TRACK_MAX_SPEED) * 2;
      app.cartAvatar.y += (app.onTracksBounceUp === 0 ? bounceAmount : -bounceAmount);
      app.onTracksBounceUp = app.onTracksBounceUp === 0 ? 1 : 0;
    }
  };

  const updateCandyPositions = () => {
    for (let i = 0; i < app.candyCollection.length; i++) {
      const candy = app.candyCollection[i].elem;
      candy.x -= app.cartTrackSpeed;
      candy.rotation += 0.03;
    }
  };

  const updateCollisionObjectPositions = () => {
    for (let i = 0; i < app.collisionBoxes.length; i++) {
      const collisionBox = app.collisionBoxes[i].elem;
      collisionBox.x -= app.cartTrackSpeed;
    }
  };

  const updatePropulsion = () => {
    if (app.sceneTheme.hasPropulsion) {
      app.cartPropulsionContainer.x = app.cartContainer.x - 10;
      app.cartPropulsionContainer.y = app.cartContainer.y + app.cartBounds.height - 40;
      app.cartPropulsionContainer.rotation = app.cartContainer.rotation * 2;

      for (let i = 0; i < app.propulsionParticlesAllowed; i++) {
        const elem = new PIXI.AnimatedSprite(app.sheet.animations.propulsion);
        elem.y = Math.floor((Math.random() * 5) + 1);
        app.cartPropulsionContainer.addChild(elem);

        const randomYDirection = Math.floor((Math.random() * 2) + 1);
        const randomX = -(Math.floor((Math.random() * 200) + 25));
        let randomY = Math.floor((Math.random() * (Math.max(25, app.propulsionParticlesAllowed + 20))) + 1);
        randomY *= randomYDirection === 1 ? 1 : -1;

        const cartPropulsionTween = new TWEEN.Tween(elem)
          .to({ x: elem.x + randomX, y: elem.y + randomY, alpha: 0 }, 500)
          .onComplete(() => {
            app.cartPropulsionContainer.removeChild(elem);
          })
          .easing(TWEEN.Easing.Linear.None);
        cartPropulsionTween.start();
        app.tweens.push(cartPropulsionTween);
      }
    }
  };

  const updatePositions = () => {
    updateBackgroundPositions();
    updateTrackPositions();
    updateCartPosition();
    updateCandyPositions();
    updateCollisionObjectPositions();
    updatePropulsion();
  };

  const tick = () => {
    checkPositions();
    updatePositions();
  };

  const playCartMoveSound = () => {
    if (!app.messageShown && app.trackSound) {
      app.trackSound.volume = (app.cartContainer.y === app.cartHeadToYPos) ? 0.1 : 0;
      app.trackSound.play({ start: 0, volume: 1 });
    }
  };

  const updateCartMoveSound = (time) => {
    if (app.playCartMoveSoundInterval) {
      clearInterval(app.playCartMoveSoundInterval);
    }

    const cartMoveSoundTime = time || ((CART_TRACK_MAX_SPEED * 3.7) - app.cartTrackSpeed) * 5;
    app.playCartMoveSoundInterval = setInterval(() => {
      playCartMoveSound();

      const tempCartMoveSoundTime = ((CART_TRACK_MAX_SPEED * 3.7) - app.cartTrackSpeed) * 5;
      if (tempCartMoveSoundTime !== app.cartMoveSoundTime) {
        updateCartMoveSound(tempCartMoveSoundTime);
      }
    }, cartMoveSoundTime);
  };

  const showFinish = () => {
    if (!app.showFinishCalled) {
      app.messageShown = true;

      app.showFinishCalled = true;
      app.messageContainer.visible = true;

      const messageTween = new TWEEN.Tween(app.messageContainer)
        .to({ alpha: 1 }, 1000)
        .easing(TWEEN.Easing.Linear.None)
        .onComplete(() => {
          app.timeout = setTimeout(() => {
            clearTimeout(app.timeout);
            onFinish();
          }, SHOW_FINISH_MESSAGE_TIME);
        });
      messageTween.start();
      app.tweens.push(messageTween);
      playClappingSound();
    }
  };

  useSetupTimeout(canvas, showFinish);

  useEffect(() => {
    if (canvas.current) {
      chooseScene();
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundColor: app.sceneTheme.backgroundColor,
      });
      loadBitmaps();
      createStage();
      updateCartMoveSound();
      app.canvas.ticker.add(tick);
    }

    return () => {
      if (app.jumpSound) {
        app.jumpSound.stop();
      }
      if (app.boxHitSound) {
        app.boxHitSound.stop();
      }
      if (app.bonusSound) {
        app.bonusSound.stop();
      }
      if (app.bangSound) {
        app.bangSound.stop();
      }
      if (app.trackSound) {
        app.trackSound.stop();
      }
      if (app.clappingSound) {
        app.clappingSound.stop();
      }

      clearInterval(app.playCartMoveSoundInterval);
      clearTimeout(app.timeout);

      if (app.mouseDownInterval) {
        clearInterval(app.mouseDownInterval);
      }

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

CandyExplodeCelebration.defaultProps = {
  onFinish: () => { },
};

CandyExplodeCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default CandyExplodeCelebration;

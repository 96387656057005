import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import ObjectiveDetailPrint from '../../../../../components/vizzle/print/ObjectiveDetailPrint';
import './ObjectiveDetailAction.scss';
import './PrintObjectiveAction.scss';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';

const loadImage = (src) => new Promise((resolve) => {
  const image = new Image();
  image.onload = () => {
    resolve();
  };
  image.onerror = () => {
    resolve();
  };
  image.src = src;
});

const PrintObjectiveAction = ({
  objective,
  included,
  currentUser,
}) => {
  const { uiDomain } = useDomain();
  const printContainerRef = useRef();
  const imagesList = useRef([]);
  const currentRef = useRef();
  const waitImage = async () => {
    await Promise.all(imagesList.current);
    uiDomain.hideLoader();
    return Promise.resolve();
  };

  const handlePrint = useReactToPrint({
    content: () => currentRef.current,
    onBeforePrint: waitImage,
    removeAfterPrint: true,
    onAfterPrint: () => {
      printContainerRef.current.innerHTML = '';
    },
  });

  const onPrint = () => {
    uiDomain.showLoader(`Preparing activity ${objective.attributes.name} for printing`, LOADER_TYPE.HASH_LOADER);
    if (currentRef.current !== null) {
      const imageEls = currentRef.current.querySelectorAll('div[class$="-image"]');
      if (imageEls && imageEls.length > 0) {
        imagesList.current = [];
        imageEls.forEach((child) => {
          const src = child.style.backgroundImage.slice(4, -1).replace(/"/g, '');
          imagesList.current.push(loadImage(src));
        });
      }
    }
    const lessonExist = currentRef.current.getElementsByClassName('objective-lesson-play');
    if (lessonExist && lessonExist.length === 0) {
      const objectiveLesson = currentRef.current.getElementsByClassName('objective-lesson');
      objectiveLesson[0].style.display = 'none';
    }

    handlePrint();
  };

  const [loadingGraph, setLoadingGraph] = useState(true);
  useEffect(() => {
    ObjectUtils.setTimeout(() => {
      setLoadingGraph(false);
    }, 1000);
  }, []);

  return (
    <>
      <ButtonWithSpinner
        className='objective-detail-action btn-secondary margin-right'
        variant='contained'
        color='primary'
        onClick={onPrint}
        disabled={loadingGraph}
        loading={loadingGraph}
        label={(
          <>Print
            <br />
            Goal/Obj
          </>
        )}
      />
      <div ref={printContainerRef} style={{ position: 'fixed', left: '-100000px', top: '-100000px' }}>
        <ObjectiveDetailPrint ref={currentRef} objective={objective} included={included} currentUser={currentUser} />
      </div>
    </>
  );
};

PrintObjectiveAction.defaultProps = {
  componentRef: { current: null },
  included: [],
};

PrintObjectiveAction.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  componentRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default PrintObjectiveAction;

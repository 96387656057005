import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ObjectiveLineChart from './components/ObjectiveLineChart';
import ObjectiveLessons from './components/ObjectiveLessons';
import FlattenLessonStandardList from '../../../card/FlattenLessonStandardList';
import { mapLessonStandards } from '../../../../utils/LessonsMapping';
import './ObjectiveDetail.scss';

const ObjectiveDetail = ({
  objective,
  currentUser,
  included,
  editGoalNameAction,
  editMasterySetAndConsecutiveLessonAction,
  actions,
  onLessonSelect,
}) => {
  const { attributes } = objective;
  const { relationships: { lessons } } = objective;
  const lessonsData = lessons.data || [];

  const lessonsWidthDetail = lessonsData.map((lesson) => (
    included.find((i) => i.type === lesson.type && i.id === lesson.id)
  ));

  const standards = mapLessonStandards(lessonsWidthDetail, included);
  const hasStandard = standards && standards.length > 0;

  return (
    <div className='objective-detail-container' data-test={`objective-detail-container-${objective.id}`}>
      <Typography variant='h6' className='title-name' data-test='student-name' data-private>
        {currentUser.firstName} {currentUser.lastName}
      </Typography>

      <div className='lesson-result-container'>
        <div className='lesson-result'>
          {attributes.masteredStatus === 'criteria-met' ? <VerifiedUserIcon data-test='mastery-badge' className='criteria-met' /> : null}
          <div className='master-detail'>
            <div className='result' data-test='title-container'>
              <Typography variant='h5' fontWeight={500} className='title' data-test='title'>
                Activity Results for:
              </Typography>
              <Typography variant='h5' fontWeight={500} className='objective-goal-name' data-test='goal-name'>
                {attributes.name}
              </Typography>
              {editGoalNameAction}
            </div>
            <div className='master-set' data-test='mastery-set-container'>
              <Typography variant='h6' className='master-set-text' data-test='mastery-set-for'>
                Mastery set for
                &nbsp;
                <span data-test='mastery-set-for-consecutive-plays' className='highlight'>{attributes.consecutivePlays}</span>
                &nbsp;
                consecutive activities at
                &nbsp;
                <span className='highlight' data-test='percent-correct'>
                  {attributes.percentCorrect}
                  %
                </span>
              </Typography>
              {editMasterySetAndConsecutiveLessonAction}
            </div>

            <div className='master-achievement'>
              <Typography variant='subtitle1'>
                Mastery achievement is recalculated after every activity play
              </Typography>
            </div>
          </div>
        </div>

        <div className='action-container'>
          {actions.map((action) => (
            (action)
          ))}
        </div>
      </div>

      <div className='graph-area' data-test='graph-container'>
        <ObjectiveLineChart
          key={`objective-detail-line-chart-${objective.id}`}
          objective={objective}
          included={included}
        />
      </div>
      <div>
        <Typography variant='subtitle1'>
          Only activities played to completion are displayed and counted toward Mastery progress
        </Typography>
      </div>
      <div className='objective-lesson' data-test='lessons-container'>
        <ObjectiveLessons
          objective={objective}
          included={included}
          onLessonSelect={onLessonSelect}
        />
      </div>
      {
        hasStandard ? (
          <div className='lesson-standards' data-test='lessons-standards-container'>
            <Typography variant='h4'>
              Activity Standards
            </Typography>
            <FlattenLessonStandardList standards={standards} />
          </div>
        ) : (
          null
        )
      }
    </div>
  );
};

ObjectiveDetail.defaultProps = {
  objective: {},
  included: [],
  currentUser: {},
  actions: [],
  editGoalNameAction: null,
  editMasterySetAndConsecutiveLessonAction: null,
  onLessonSelect: null,
};

ObjectiveDetail.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
  }),
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
  }),
  editMasterySetAndConsecutiveLessonAction: PropTypes.node,
  editGoalNameAction: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
  onLessonSelect: PropTypes.func,
};

export default ObjectiveDetail;

import PropTypes from 'prop-types';
import ThumbnailCard from '../common/ThumbnailCard';
import './SequencingThumbnail.scss';

const SequencingThumbnail = ({
  sequenceCards,
  responseCards,
  isSmallThumbnail,
}) => (
  <div className={`sequencing-thumbnail ${isSmallThumbnail ? 'small-thumbnail' : ''}`}>
    <div className='question-area'>
      {sequenceCards.map((sequenceCard) => (
        <ThumbnailCard
          key={`sequence-card-${sequenceCard.key}`}
          image={sequenceCard.image}
          text={sequenceCard.text}
          isSmallThumbnail={isSmallThumbnail}
          cardKey={isSmallThumbnail ? null : sequenceCard.key}
          className={`${isSmallThumbnail ? 'thumbnail-card-small' : ''}`}
        />
      ))}
    </div>
    <div className='answer-area'>
      {responseCards.map((responseCard, index) => (
        <ThumbnailCard
          key={`sequence-card-${responseCard.key}-${index}`}
          image={responseCard.image}
          text={responseCard.text}
          isSmallThumbnail={isSmallThumbnail}
          className={`${isSmallThumbnail ? 'thumbnail-card-small' : ''}`}
        />
      ))}
    </div>
  </div>
);

SequencingThumbnail.defaultProps = {
  sequenceCards: [],
  responseCards: [],
  isSmallThumbnail: false,
};

SequencingThumbnail.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  sequenceCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
};

export default SequencingThumbnail;

import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import './CategorizingPrintPrompt.scss';
import PrintCard from '../../../common/print/PrintCard';

const CategorizingPrintPrompt = ({
  promptCard,
  children,
  fontScale,
}) => (
  <Box
    boxShadow={2}
    className='categorizing-activity-prompt-card'
  >
    <div className='prompt-detail'>
      <PrintCard
        {...promptCard}
        fontScale={fontScale}
      />
    </div>
    <div
      className='prompt-selected-response-cards'
    >
      {children}
    </div>
  </Box>
);

export default CategorizingPrintPrompt;

CategorizingPrintPrompt.defaultProps = {
  promptCard: {},
  children: null,
  fontScale: null,
};

CategorizingPrintPrompt.propTypes = {
  promptCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  }),
  children: PropTypes.node,
  fontScale: PropTypes.number,
};

import { useRef } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { calculateCardSize } from '../../../../utils/CardSizeCalculator';
import { initDomains } from './context/MyLessonsContextProvider';
import LessonCardListDetail from '../../../../components/vizzle/cardlistdetail/lesson/LessonCardListDetail';
import LessonTag from '../../../tag/LessonTag';
import { createLessonCardAction } from './actions/LessonCardActionBuilder';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/LessonCardDetailActionBuilder';
import MyLessonsMultiSelectionActions from './actions/MyLessonsMultiSelectionActions';
import {
  myListTags,
  usePrepareMyLessons,
  useGetActiveGoals,
  useGetFilterOptions,
  useHandleLessonTag,
  useLessonSelect,
  useHandleTeacherCourseAssigment,
} from './MyLessonsHook';
import MyLessonsFilter from './MyLessonsFilter';
import MyLessonsRowActions from './actions/MyLessonsRowActions';
import TeacherAssignedStudentCoursePanel from '../../../lessons/assigned-student-courses/TeacherAssignedStudentCoursePanel';
import MyLessonsAssignmentSelectionActions from './actions/MyLessonsAssignmentSelectionActions';
import './MyLessons.scss';

const standardCardSize = calculateCardSize();
const cardSize = {
  width: standardCardSize.width * 0.84,
  slidesToShow: standardCardSize.slidesToShow,
  slidesToScroll: Math.floor(standardCardSize.slidesToScroll / 0.84),
};

const MyLessons = () => {
  initDomains();

  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;
  const { loading, filteredLessonData, lessonData, currentSelectedSubjectName } = usePrepareMyLessons();

  const activeGoals = useGetActiveGoals();
  const {
    handleOnFilterChange,
    multiSelectFilterConfig,
  } = useGetFilterOptions(activeGoals);

  const {
    getLessonTagIds,
    handleOnTagDelete,
    handleOnTagSelect,
    handleOnTagAdd,
  } = useHandleLessonTag();

  const {
    handleOnLessonSelect,
    handleOnLessonClear,
  } = useLessonSelect();

  const lessonCardDetailRef = useRef(null);

  const {
    selectedDates,
    handleOnSelectedDatesApply,
    handleOnStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
    handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  } = useHandleTeacherCourseAssigment();

  return (
    <div className='my-lessons'>
      <div className='container'>
        {!loading && (
          <div className='my-lessons-container'>
            <LessonCardListDetail
              key={user.id}
              cardSize={cardSize}
              largeSingleRowMode
              currentSelectedSubjectName={currentSelectedSubjectName}
              ref={lessonCardDetailRef}
              filterTitle='Additional Filters'
              lessonData={filteredLessonData}
              preFilterComponent={<MyLessonsFilter />}
              onFilterChange={handleOnFilterChange}
              noLessonMessage='No Activities Found.'
              multiSelectFilterConfig={multiSelectFilterConfig}
              createCardAction={(lesson) => createLessonCardAction(lesson, user, userProfile, terminologies)}
              createCardDetailAction={(
                lesson,
                currentUser,
                rowIndex,
                closeDetailPanelFunc,
                moveCardIndexFunc,
                cardIndex,
              ) => (
                createLessonCardDetailAction(lesson, currentUser, rowIndex, closeDetailPanelFunc, moveCardIndexFunc, cardIndex, userProfile, terminologies, selectedDates)
              )}
              createRowAction={() => (
                <div className='lesson-row-action'>
                  <MyLessonsRowActions
                    onClearClick={handleOnLessonClear}
                    selectedLessonIds={lessonData.selectedLessonIds}
                    lessonCardDetailRef={lessonCardDetailRef}
                  />
                  <MyLessonsMultiSelectionActions
                    selectedLessonIds={lessonData.selectedLessonIds}
                  />
                </div>
              )}
              currentUser={user}
              currentUserProfle={userProfile}
              selectedLessonIds={lessonData.selectedLessonIds}
              onLessonSelect={handleOnLessonSelect}
              createLessonTagsComponent={(lesson) => (
                <LessonTag
                  currentUser={user}
                  lesson={lesson}
                  title='Tags'
                  tagIds={getLessonTagIds(lesson)}
                  noTagMessage='Activity not associated with any tags.'
                  placeholder='Type to add/search tags'
                  allLessonTags={activeGoals}
                  tagUrl={myListTags}
                  onTagDelete={handleOnTagDelete}
                  onTagSelect={handleOnTagSelect}
                  onTagAdd={handleOnTagAdd}
                />
              )}
              createLessonDetailAction={(lesson) => createLessonCardDetailonPlanelAction(lesson, user, userProfile, terminologies)}
              showLessonBadges={false}
            />
            <MyLessonsAssignmentSelectionActions
              selectedLessonIds={lessonData.selectedLessonIds}
            />
            <TeacherAssignedStudentCoursePanel
              selectedDates={selectedDates}
              onSelectedDatesApply={handleOnSelectedDatesApply}
              onStartLoading={handleOnStartLoading}
              subjectId={subjectId}
              onDataLoad={onDataLoad}
              assignedStudentCourses={assignedStudentCourses}
              filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
              loading={assignedStudentCoursesLoading}
              onFilterCriteriaAssignedStudentsCoursesUpdate={handleOnFilterCriteriaAssignedStudentsCoursesUpdate}
              canArchive={false}
              canLessonDeleteFunction={(lesson) => {
                try {
                  const canDelete = !lesson.meta.mtss;
                  return canDelete;
                } catch (e) {
                  return true;
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLessons;

import { useState } from 'react';
import {
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import { useDomain } from '../../../../states/AppDomainProvider';
import { LESSON_STATUS, useHandleSubmit, useOnChangeHandler } from './library/LessonVizzleLibraryHook';
import './library/LessonVizzleLibrary.scss';
import ApprovedForCourseForm from './library/components/ApprovedForCourseForm';
import { APPROVED_FOR_COURSES, LESSON_STATUS_PENDING_NEXTGEN } from '../../../../AppConstants';

/**
 * Lesson stepper for Vizzle Library step
 * @export
 */
export default function LessonVizzleLibrary() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const [disableSubmit, setDisableSubmit] = useState(![LESSON_STATUS_PENDING_NEXTGEN, APPROVED_FOR_COURSES].includes(lessonData.attributes.approvalStatus));
  const [submitterEmail] = useState(lessonData.attributes.userName);

  const {
    approvalStatus,
    moderatorComments,
    handleModeratorCommentsChanged,
    handleModeratorCommentsBlur,
    handleStatusChange,
  } = useOnChangeHandler();

  const {
    handleOnSubmitClick,
    loading,
  } = useHandleSubmit({
    setDisableSubmit,
    approvalStatus,
    moderatorComments,
  });

  const {
    attributes: {
      courseId,
      unitId,
      topicId,
    },
  } = lessonData;

  return (
    <Box className='lesson-vizzle-library'>
      <br />
      {window.innerWidth <= 1024 ? (
        <div>
          <br />
        </div>
      ) : ''}

      <Grid container spacing={3} wrap='nowrap'>
        <Grid item xs={5}>
          <Box>
            <FormControl component='fieldset' className='vizzle-vizzle-moderator-statuses'>
              <Typography variant='h5'>
                Status
              </Typography>
              <RadioGroup
                aria-label='Activity status'
                name='lessonStatus'
                onChange={handleStatusChange}
                value={approvalStatus}
                className='radio-container'
              >
                {LESSON_STATUS.map((l, index) => (
                  <FormControlLabel
                    key={`lesson-vizzle-library-status-${index}`}
                    value={l.value}
                    control={<Radio />}
                    label={l.label}
                    className='vizzle-vizzle-moderator-approve-status'
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <br />
            <Box width='100%' mt='10px'>
              <Typography variant='h5'>
                Submitter Email
              </Typography>
              <TextField
                width='10px'
                variant='outlined'
                fullWidth
                value={submitterEmail}
                className='vizzle-vizzle-submitter-email'
              />
            </Box>
            <Box width='100%' mt='20px'>
              <Typography variant='h5'>
                Moderator Comments
              </Typography>
              <TextField
                width='10px'
                variant='outlined'
                onChange={handleModeratorCommentsChanged}
                onBlur={handleModeratorCommentsBlur}
                multiline
                minRows={7}
                maxRows={7}
                fullWidth
                value={moderatorComments || ''}
                className='vizzle-vizzle-moderator-comments'
              />
            </Box>
            <br />
            <Box width='180px' mr='20px'>
              <ButtonWithSpinner
                disabled={
                  disableSubmit || !approvalStatus || !moderatorComments
                  || approvalStatus === LESSON_STATUS_PENDING_NEXTGEN
                  || (approvalStatus === APPROVED_FOR_COURSES && (
                    !courseId || !unitId || !topicId
                  ))
                }
                label='Submit'
                onClick={handleOnSubmitClick}
                loading={loading}
                fullWidth
                className='vizzle-vizzle-moderator-submit-btn'
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          {approvalStatus === APPROVED_FOR_COURSES && (
            <ApprovedForCourseForm />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import './GenerateStandards.scss';
import { useGenerateStandardPdf } from './GenerateStandardsHooks';

export default function GenerateStandards() {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { standards } = teacherCourseDomain.domainData;
  const { hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;
  const {
    dataUri,
  } = useGenerateStandardPdf();

  if (!hasPerformSearch) {
    return (<TeacherCourseInstruction />);
  }

  if (!standards || standards.length === 0) {
    return (<NoLessonPaper message='No Standards Available' />);
  }

  if (!dataUri) {
    return null;
  }
  return (
    <object className='generate-standard-container' data={dataUri} type='application/pdf'>
      <iframe
        title='Standard PDF'
        src={dataUri}
        frameBorder='0'
        allowFullScreen
      />
    </object>
  );
}

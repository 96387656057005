import { useState } from 'react';
import ExtensionActivitiesService from '../../../../../../../services/ExtensionActivitiesService';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../../../context/TeacherCourseContextProvider';

export const useHandleRenameFileForm = () => {
  const [name, setName] = useState('');
  // const [error, setError] = useState('');

  const handleOnFileNameFieldChange = (e) => {
    const newValue = e.target.value;

    // if (!newValue.match(/[<>?:*"|\\/]/)) {
    //   setError('');
    // } else {
    //   setError('Invalid Character');
    // }
    setName(newValue);
  };

  const resetFileNameField = () => {
    setName('');
  };

  return {
    name,
    resetFileNameField,
    // error,
    handleOnFileNameFieldChange,
  };
};

const getUniqueName = (fileName, existingFileList, index = 0) => {
  let checkName = fileName; let
    ext = '';
  if (index) {
    if (checkName.indexOf('.') > -1) {
      const tokens = checkName.split('.'); ext = `.${tokens.pop()}`;
      checkName = tokens.join('.');
    }

    // make any pattern here
    checkName = `${checkName}(${index})${ext}`;
  }

  const nameExists = existingFileList.filter((name) => name === checkName).length > 0;
  return nameExists ? getUniqueName(fileName, existingFileList, index + 1) : checkName;
};

const getFileName = (activity, extensionActivities, fileName) => {
  if (!fileName) {
    return '';
  }
  const currentFileName = activity.name.split('.');
  const extension = currentFileName[currentFileName.length - 1];

  let fileNameToUse = fileName;
  const newFileName = fileName.split('.');
  if (newFileName.length === 1 || !newFileName[newFileName.length - 1]) {
    // no extension
    fileNameToUse = `${fileNameToUse}.${extension}`;
  }
  // const fileNameToUse = newValue.endsWith(extension) ? newValue : `${newValue}.${extension}`;
  const existingFilenames = extensionActivities.activities.filter((a) => a.id !== activity.id).map((a) => a.name);
  fileNameToUse = getUniqueName(fileNameToUse, [...existingFilenames]);
  return fileNameToUse;
};

export const useHandleSubmitData = ({
  resetFileNameField,
}) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const { teacherCourseSearchCriteriaDomain, extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities, selectedActivityIds } = extensionActivitiesDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { topicId } = searchCriteria;

  const handleSubmitData = async (activity, fileName) => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (!fileName || !fileName.trim()) {
          return;
        }
        const fileNameToUse = getFileName(activity, extensionActivities, fileName);
        await ExtensionActivitiesService.updateName(topicId, activity.id, fileNameToUse);
        const selectedActivity = extensionActivities.activities.find((a) => a.id === activity.id);
        selectedActivity.name = fileNameToUse;
        await extensionActivitiesDomain.updateExtensionActivitiesDataAndSelectedId(extensionActivities.activities, selectedActivityIds);
        const message = `Successfully rename the file to ${fileNameToUse}`;
        await uiDomain.showSnackbar(true, 'success', 3000, message);
        resetFileNameField();
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    handleSubmitData,
    isSaving: loading,
  };
};

import vizzleAxios from './service';

export const getFreeFormAnswer = async (studentId) => {
  const url = `/users/${studentId}/answers/freeform`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const saveFreeFormAnswer = async (studentId, answer) => {
  const url = `/users/${studentId}/answers/freeform`;
  const result = await vizzleAxios.post(url, answer);
  return result;
};

export const addFreeFormComment = async ({
  studentId,
  lessonId,
  comments,
  pageNumber,
  cardNumber,
  studentHistoryId,
}) => {
  const url = `/users/${studentId}/answers/freeform`;
  const result = await vizzleAxios.post(url, { comments, lessonId, pageNumber, cardNumber, studentHistoryId });
  return result;
};

export const updateFreeFormComment = async ({
  studentId,
  freeFormAnswerId,
  comments,
  studentHistoryId,
}) => {
  const url = `/users/${studentId}/answers/freeform/${freeFormAnswerId}/comments`;
  const result = await vizzleAxios.put(url, { comments, studentHistoryId });
  return result;
};

export const updateLessonReviewStatus = async (studentId, {
  lessonId,
  reviewStatus,
  studentHistoryId,
  originalStudentHistoryId,
}) => {
  const url = `/users/${studentId}/status/review`;
  const result = await vizzleAxios.put(url, {
    lessonId,
    reviewStatus,
    studentHistoryId,
    originalStudentHistoryId,
  });
  return result;
};

export const updateFreeFormNotification = async ({
  studentId,
  freeFormAnswerId,
}) => {
  const url = `/users/${studentId}/answers/freeform/${freeFormAnswerId}/notified`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const reviewNotificationViewed = async ({ studentId }) => {
  const url = `/users/${studentId}/reviewNotificationViewed`;
  const result = await vizzleAxios.put(url);
  return result;
};

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { Card, CardContent, CardMedia } from '@mui/material';
import TextLines from '../../../text/TextLines';
import './PrintCard.scss';

const PrintCard = ({
  text,
  innerImage,
  image,
  className,
  style,
  fontScale,
}) => {
  const componentId = useMemo(() => uuidv1(), []);

  return (
    <Card
      id={componentId}
      key={componentId}
      className={`print-card-activity ${className} vizzle-card-print-card-activity-page-${componentId}`}
      data-private
      style={style}
    >
      {
        (image || innerImage) && (
          <CardMedia
            className='card-activity-image'
            data-private
            image={image}
            title='Image'
            style={{ zIndex: 1 }}
            data-test='card-image'
          >
            {
              innerImage && (
                <CardMedia
                  className='card-activity-image inner-image'
                  data-private
                  image={innerImage}
                  title='Image'
                  data-test='card-background-image'
                />
              )
            }
          </CardMedia>
        )
      }

      {text && (
        <CardContent className={`card-activity-text ${image ? 'text-with-image' : 'only-text'}`}>
          <TextLines
            componentKey={`text-line-${componentId}`}
            textLines={text}
            fontScale={fontScale}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default PrintCard;

PrintCard.defaultProps = {
  text: null,
  innerImage: null,
  image: null,
  className: '',
  style: null,
  fontScale: null,
};

PrintCard.propTypes = {
  text: PropTypes.object,
  innerImage: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fontScale: PropTypes.number,
};

import ObjectUtils from '../../../../utils/ObjectUtils';

/**
 * Disable / Enable component without changing the state of the component
 * Note: changing the state causes the screen to re-render
 * @param componentId the component id
 * @param disable disable or enable the component
 */
export const disableComponent = (componentId, disable) => {
  if (disable) {
    document.getElementById(componentId).classList.add('matching-component-disabled');
  } else {
    document.getElementById(componentId).classList.remove('matching-component-disabled');
  }
};

/**
 * Get the dropping anicate class
 * This is to avoid the unselected cards moving
 *
 * @param {*} isDropAnimating whether or not the element is dropping
 * @param {*} isDragging whether or not the element is dragging
 * @param {*} isCorrect whether or not the selected card is the correct answer
 */
export const getDroppingAnimateClass = (isDropAnimating, isDragging, isCorrect) => {
  if (isDropAnimating) {
    if (isCorrect) {
      return '';
    } else {
      if (isDragging) {
        return '';
      }
      return 'matching-component-response-card-non-drag-items';
    }
  } else {
    return '';
  }
};

/**
 * Display reject icon when the user selects a wrong answer
 * @param {*} rejectDroppedCardId the area to display the icon
 */
export const showDropRejection = (rejectDroppedCardId) => {
  document.getElementById(rejectDroppedCardId).classList.remove('matching-component-prompts-reject-hide');
  document.getElementById(rejectDroppedCardId).classList.add('matching-component-prompts-reject-show');
  ObjectUtils.setTimeout(() => {
    document.getElementById(rejectDroppedCardId).classList.remove('matching-component-prompts-reject-show');
    document.getElementById(rejectDroppedCardId).classList.add('matching-component-prompts-reject-hide');
  }, 200);
};

import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
} from '@mui/material';
import SortableTableHead from './SortableTableHead';
import './SortableTable.scss';

const descendingComparator = (a, b, orderBy) => {
  if (!orderBy) {
    return 0;
  }

  if (a[orderBy] === undefined) {
    return 1;
  }

  if (b[orderBy] === undefined) {
    return -1;
  }

  if (typeof a[orderBy] === 'string') {
    return a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase(), undefined, { numeric: true, sensitivity: 'base' });
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
};

const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const defaultRowsPerPageOptions = [10, 25, 50, 100];

/**
 * Table component that can be exported to csv and pdf file.
 */
const SortableTable = forwardRef(({
  className,
  config,
  tableData,
  onTableSort,
  onTableStateChange,
  initTableState,
  onRowClick,
  onRowDoubleClicks,
  dataId,
  selectedRowIds,
  loading,
  labelRowsPerPage,
  placeHolderData,
  rowsPerPageOptions,
  autoResetTableState,
}, ref) => {
  const { page: initPage, rowsPerPage: initRowsPerPage, order: initOrder, orderBy: initOrderBy } = initTableState;
  const [tableState, setTableState] = useState({
    page: initPage || 0,
    rowsPerPage: initRowsPerPage || rowsPerPageOptions[0],
    order: initOrder || 'asc',
    orderBy: initOrderBy || '',
  });

  useImperativeHandle(ref, () => ({
    resetTableState: () => {
      setTableState({
        page: initPage || 0,
        rowsPerPage: initRowsPerPage || rowsPerPageOptions[0],
        order: initOrder || 'asc',
        orderBy: initOrderBy || '',
      });
    },
  }));

  useEffect(() => {
    if (autoResetTableState) {
      setTableState({
        page: initPage || 0,
        rowsPerPage: initRowsPerPage || rowsPerPageOptions[0],
        order: initOrder || 'asc',
        orderBy: initOrderBy || '',
      });
    } else {
      setTableState({
        ...tableState,
        page: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const handleChangePage = (event, newPage) => {
    const newState = {
      ...tableState,
      page: newPage,
    };
    setTableState(newState);
    onTableStateChange(newState);
  };

  const handleChangeRowsPerPage = (event) => {
    const newState = {
      ...tableState,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableState(newState);
    onTableStateChange(newState);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = tableState.orderBy === property && tableState.order === 'asc';
    const newState = {
      ...tableState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    };
    setTableState(newState);
    onTableSort(stableSort(tableData, getComparator(isAsc ? 'desc' : 'asc', property)));
    onTableStateChange(newState);
  };

  const rowsPerPage = tableState.rowsPerPage || rowsPerPageOptions[0];
  const currentData = stableSort(tableData, getComparator(tableState.order, tableState.orderBy))
    .slice(tableState.page * rowsPerPage, tableState.page * rowsPerPage + rowsPerPage);

  const createTableRow = (row, rowIndex) => (
    <TableRow
      key={`table-data-row-${rowIndex}`}
      className={`${onRowClick || onRowDoubleClicks ? 'clickable' : ''}`}
      onClick={(e) => {
        if (onRowClick) {
          onRowClick(e, rowIndex, row[dataId], currentData);
        }
      }}
      onDoubleClick={(e) => {
        if (onRowDoubleClicks) {
          onRowDoubleClicks(e, row[dataId], rowIndex);
        }
      }}
      selected={selectedRowIds.includes(row[dataId])}
    >
      {
        config.map((c, cellIndex) => (
          <TableCell
            key={`table-data-${c.dataField}-row-${rowIndex}-cell-${cellIndex}`}
            align={c.align}
            className='table-cell'
            data-private
          >
            {c.dataRenderer ? c.dataRenderer(row) : row[c.dataField]}
          </TableCell>
        ))
      }
    </TableRow>
  );

  return (
    <div className={`sortable-table-component ${className}`}>
      <div className='sortable-table-container'>
        <TableContainer component={Paper} className='sortable-table'>
          <Table size='small'>
            <SortableTableHead
              config={config}
              order={tableState.order}
              orderBy={tableState.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            {!loading && (
              <TableBody>
                {placeHolderData && (
                  createTableRow(placeHolderData, 'place-holder')
                )}
                {currentData.map((row, rowIndex) => (
                  createTableRow(row, rowIndex)
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {loading
        ? (
          <div className='loading-sortable'>
            <CircularProgress className='loading-icon' />
          </div>
        )
        : null}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={tableData.length}
        rowsPerPage={tableState.rowsPerPage}
        page={tableState.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={labelRowsPerPage}
        data-test='rows-per-page-menu'
      />
    </div>
  );
});

export default SortableTable;

SortableTable.defaultProps = {
  className: '',
  tableData: [],
  config: [],
  onTableSort: () => { },
  onTableStateChange: () => { },
  initTableState: {
    page: 0,
    // rowsPerPage: rowsPerPageOptions[0],
    order: 'asc',
    orderBy: '',
  },
  onRowClick: () => null,
  onRowDoubleClicks: () => null,
  dataId: 'id',
  selectedRowIds: [],
  loading: false,
  labelRowsPerPage: 'Rows per page:',
  placeHolderData: undefined,
  rowsPerPageOptions: defaultRowsPerPageOptions,
  autoResetTableState: true,
};

SortableTable.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    dataRenderer: PropTypes.func,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    noSort: PropTypes.bool,
  })),
  onTableSort: PropTypes.func,
  onTableStateChange: PropTypes.func,
  initTableState: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  onRowClick: PropTypes.func,
  onRowDoubleClicks: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  dataId: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  selectedRowIds: PropTypes.array,
  loading: PropTypes.bool,
  labelRowsPerPage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  placeHolderData: PropTypes.any,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  autoResetTableState: PropTypes.bool,
};

import vizzleAxios from './service';
import {
  SUBJECT_ID_ELA,
  SUBJECT_ID_MATH,
  SUBJECT_ID_SCIENCE,
  SUBJECT_ID_SOCIAL_STUDY,
  SUBJECT_ID_SPEECH_THERAPY,
  SUBJECT_ID_LIFE_SKILL,
  SUBJECT_ID_LIFE_ART_MUSIC,
  NON_ACADEMIC_SUBJECTS,
} from '../constants/LessonConstant';

/**
 * Assign a subject to a lesson
 *
 * @param lessonUuid lesson id
 * @param subjectId subject id to assign
 */
export const assignLessonSubject = async (lessonUuid, subjectId) => {
  const url = `/lessons/${lessonUuid}/classification`;
  const result = await vizzleAxios.put(url, {
    subjectId,
  });
  return result;
};

/**
 * Change a subject in a lesson
 *
 * @param lessonUuid lesson id
 * @param subjectId subject id to assign
 */
export const changeLessonSubject = async (lessonUuid, subjectId) => {
  const url = `/lessons/${lessonUuid}/classification?type=update`;
  const result = await vizzleAxios.put(url, {
    subjectId,
  });
  return result;
};

/**
 * Update lesson subject
 *
 * @param userId student id
 * @param lessonId lesson id
 * @param oldSubjectId old subject id
 * @param newSubjectId new subject id
 */
export const updateLessonSubject = async (userId, lessonId, oldSubjectId, newSubjectId) => {
  const url = `/users/${userId}/lessons/${lessonId}/personalize`;
  const result = await vizzleAxios.put(url, { oldSubjectId, newSubjectId });
  return result;
};

/**
 * Lesson mapping class to map lesson data from remote service
 * to objects for container
 */
const SubjectListSelectionOrder = [
  { name: 'English Language Arts', subjectId: SUBJECT_ID_ELA },
  { name: 'Math', subjectId: SUBJECT_ID_MATH },
  { name: 'Science', subjectId: SUBJECT_ID_SCIENCE },
  { name: 'Social Studies', subjectId: SUBJECT_ID_SOCIAL_STUDY },
  { name: 'Speech Therapy', subjectId: SUBJECT_ID_SPEECH_THERAPY },
  { name: 'Arts & Music', subjectId: SUBJECT_ID_LIFE_ART_MUSIC },
  { name: 'Life Skills', subjectId: SUBJECT_ID_LIFE_SKILL },
];

/**
 * Get list by user
 *
 * @return subject objects
 */
export const getSubjectsByUser = () => [
  SubjectListSelectionOrder[0],
  SubjectListSelectionOrder[1],
  SubjectListSelectionOrder[2],
  SubjectListSelectionOrder[3],
];

export const getAllSubjectes = (includeNonAcademicSubject = false) => {
  if (includeNonAcademicSubject) {
    return [
      ...SubjectListSelectionOrder,
      NON_ACADEMIC_SUBJECTS.socialSkills,
      NON_ACADEMIC_SUBJECTS.transitionalSkills,
    ];
  }
  return [
    ...SubjectListSelectionOrder,
  ];
};

/**
 * Get subject object by subject name
 *
 * @param subjectName subject name
 * @return subject object
 */
export const getSubjectObjectBySubjectName = (subjectName) => (
  // Get all subjects
  getAllSubjectes(true).find((s) => (
    s.name === subjectName
  )) || {}
);

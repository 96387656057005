import vizzleAxios from './service';

export const getMasteryObjective = async (studentId) => {
  const url = `/users/${studentId}/masteryCriteria?_include=lessonPlays,curriculumTags,lessons&filter[maxLessonPlaysPerObjective]=20`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const masteryNotificationViewed = async (studentId) => {
  const url = `/users/${studentId}/masteryNotificationViewed`;
  const result = await vizzleAxios.put(url, {});
  return result;
};

export const updateMasteryCriteria = async (studentId, objectiveId, consecutivePlays, percentCorrect) => {
  const url = `/users/${studentId}/masteryCriteria/${objectiveId}`;
  const result = await vizzleAxios.put(url, { data: { type: 'masteryCriteria', attributes: { consecutivePlays, percentCorrect } } });
  return result;
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactLessonPlay from './lesson-play-by-environment/ReactLessonPlay';
// import { hideChatIcon } from '../../services/IntercomChatService';
import { hideAccessibeChatIcon, showAccessibeChatIcon } from '../../services/AccessibeService';
import LiveChatService from '../../services/LiveChatService';
import { showMicrosoftCopilot } from '../../components/copilot/MicrosoftCopilot';

/**
 * Play Lesson Action Container
 */
export default function LessonPlay({
  open,
  handleClose,
  lesson,
  isPreviewMode,
  user,
  userProfile,
  terminologies,
  onLessonFinished,
  initialPageIndex,
  isPlayable,
  createAdditionalComponents,
  onChatWidgetOpen,
}) {
  useEffect(() => {
    hideAccessibeChatIcon();
    LiveChatService.hideLiveChatIcon();
    showMicrosoftCopilot(false);
    return () => {
      showAccessibeChatIcon();
      LiveChatService.showLiveChatIcon();
      showMicrosoftCopilot(true);
    };
  }, []);

  return (
    <ReactLessonPlay
      open={open}
      handleClose={handleClose}
      lesson={lesson}
      isPreviewMode={isPreviewMode}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
      onLessonFinished={onLessonFinished}
      initialPageIndex={initialPageIndex}
      isPlayable={isPlayable}
      createAdditionalComponents={createAdditionalComponents}
      onChatWidgetOpen={onChatWidgetOpen}
    />
  );
}

LessonPlay.defaultProps = {
  open: false,
  handleClose: () => { },
  isPreviewMode: false,
  user: null,
  userProfile: null,
  onLessonFinished: () => { },
  terminologies: {},
  initialPageIndex: 0,
  isPlayable: true,
  createAdditionalComponents: () => null,
  onChatWidgetOpen: () => { },
};

LessonPlay.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  isPreviewMode: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxMatchingChoices: PropTypes.number,
    switchAccessibility: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
  onLessonFinished: PropTypes.func,
  initialPageIndex: PropTypes.number,
  isPlayable: PropTypes.bool,
  createAdditionalComponents: PropTypes.func,
  onChatWidgetOpen: PropTypes.func,
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearAllDomainData } from '../../../states/AppDomainProvider';

export default function LoginRethinkOpenId() {
  const location = useLocation();
  const { state } = location;
  const { redirecTo } = state || {};
  useEffect(() => {
    const init = async () => {
      await clearAllDomainData();
      const cisBaseUrl = process.env.REACT_APP_CIS_BASE_URL;
      const cisPolicy = process.env.REACT_APP_CIS_APP_POLICY;
      const cisClientId = process.env.REACT_APP_CIS_CLIENT_ID;
      const cisRedirectUri = process.env.REACT_APP_CIS_REDIRECT_URI;
      const stateParam = `${redirecTo ? `redirectTo=${redirecTo}` : ''}`;
      const baseUrl = window.location.origin;
      // eslint-disable-next-line max-len
      const url = `https://${cisBaseUrl}/${cisPolicy}/oauth2/v2.0/authorize?client_id=${cisClientId}&response_type=code+id_token&redirect_uri=${baseUrl}/${cisRedirectUri}&response_mode=fragment&scope=openid${stateParam ? `&state=${stateParam}` : ''}`;
      window.location.replace(url);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    null
  );
}

import { useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import NoLessonPaper from '../../../paper/NoLessonPaper';
import GridListComponent from '../../../grid/GridList';
import LessonCard from '../../../card/LessonCard';
import LessonFilter from './LessonFilter';
import LessonDetail from './LessonDetail';
import ViewLessonDetailAction from './actions/ViewLessonDetailAction';
import { useLessonCardFilter } from './LessonCardListDetailHooks';
import CardDetailContainer, { useCardDetail } from '../CardDetailContainer';
import './LessonCardListDetail.scss';
import { calculateCardSize } from '../../../../utils/CardSizeCalculator';

const LessonCardListDetail = forwardRef(({
  cardSize,
  lessonData,
  preFilterComponent,
  noLessonMessage,
  filterTitle,
  withArchiveFilter,
  noFilter,
  onFilterChange,
  multiSelectFilterConfig,
  createCardAction,
  createCardLabel,
  createCardDetailAction,
  createCardDetailLabel,
  currentUser,
  currentUserProfle,
  createLessonTagsComponent,
  createLessonMultiSelectionCompnent,
  onLessonSelect,
  selectedLessonIds,
  editSubjectAction,
  createRowAction,
  createLessonDetailAction,
  displayFastNavigator,
  showLessonDescription,
  showLessonBadges,
  largeSingleRowMode,
  currentSelectedSubjectName,
  onTitleClick,
  createAddtionalDetailPanel,
  shouldDisplayViewDetail,
}, ref) => {
  const {
    filteredLessons,
    hasLesson,
    filterValue,
    handleOnFilterChange,
  } = useLessonCardFilter({
    lessonData,
    multiSelectFilterConfig,
    onFilterChange,
    noFilter,
    withArchiveFilter,
  });

  const {
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openCardDetail,
    setOpenCardDetail,
  } = useCardDetail();

  useImperativeHandle(ref, () => ({
    getCurrentDisplayData: () => filteredLessons,
  }));

  const lessonList = useMemo(() => (
    <div className='lesson-card-list-detail-container'>
      <div className='lesson-card-list-detail-header'>
        {preFilterComponent}
        {!noFilter && (
          <LessonFilter
            filterValue={filterValue}
            onFilterChange={handleOnFilterChange}
            multiSelectFilterConfig={multiSelectFilterConfig}
            withArchiveFilter={withArchiveFilter}
            filterTitle={filterTitle}
          />
        )}
        {createLessonMultiSelectionCompnent && createLessonMultiSelectionCompnent()}
      </div>
      {
        hasLesson && (
          filteredLessons.map((subjectWithLessons, rowIndex) => (
            subjectWithLessons.lessons.length > 0
              ? (
                <div
                  className='lesson-list-row'
                  data-private
                  key={`lesson-list-row-${subjectWithLessons.subject}-${JSON.stringify(filterValue || Math.random())}`}
                  data-test={`lesson-list-row-${subjectWithLessons.subject}`}
                >
                  {createRowAction && (
                    <div className='lesson-list-row-component'>
                      {createRowAction(rowIndex)}
                    </div>
                  )}

                  <GridListComponent
                    key={`lesson-list-${subjectWithLessons.subject}-${JSON.stringify(filterValue)}`}
                    titleName={subjectWithLessons.subject}
                    subTitle={subjectWithLessons.subTitle}
                    // slidesToShow={cardSize.slidesToShow}
                    slidesToScroll={cardSize.slidesToScroll}
                    className='lesson-card-list-detail-lessons'
                    canSwipe={false}
                    displayFastNavigator={displayFastNavigator}
                    onTitleClick={onTitleClick ? () => {
                      onTitleClick(subjectWithLessons);
                    } : null}
                  >
                    {
                      subjectWithLessons.lessons.map((lesson, cardIndex) => {
                        const actions = createCardAction(lesson, cardIndex) || [];
                        const cardLabels = createCardLabel(lesson, cardIndex) || [];
                        const key = `vizzle-lesson-detail-${subjectWithLessons.subject}-lesson-index-${cardIndex}`;
                        if (shouldDisplayViewDetail(lesson)) {
                          actions.push(
                            <ViewLessonDetailAction
                              key={key}
                              className={key}
                              onClick={() => { handleOnViewDetailClick(cardIndex, rowIndex); }}
                            />,
                          );
                        }

                        return (
                          <Box
                            key={`lessons-action-id-${lesson.attributes.uuid}`}
                            className='lesson-card-action'
                            onClick={() => onLessonSelect(lesson)}
                          >
                            <LessonCard
                              key={`lessons-id-${lesson.attributes.uuid}`}
                              lessonData={lesson}
                              width={cardSize.width}
                              actions={actions}
                              isHighligthed={selectedLessonIds.includes(lesson.id)}
                              cardLabels={cardLabels}
                              showLessonDescription={showLessonDescription}
                              showLessonBadges={showLessonBadges}
                            />
                          </Box>
                        );
                      })
                    }
                  </GridListComponent>
                </div>
              ) : ''
          ))
        )
      }
      {
        !hasLesson && largeSingleRowMode && (
          <GridListComponent
            key='lesson-list-no-lesson-placeholder'
            titleName={currentSelectedSubjectName}
            // slidesToShow={cardSize.slidesToShow}
            slidesToScroll={cardSize.slidesToScroll}
            className='lesson-card-list-detail-lessons'
            canSwipe={false}
            displayFastNavigator={displayFastNavigator}
            placeHolderChildren={(
              <div className='no-lesson-placeholder'>
                <div style={{ height: `${cardSize.width * 1.24}px`, display: 'flex', alignItems: 'center' }}>
                  <NoLessonPaper message={noLessonMessage} />
                </div>
              </div>
            )}
          />
        )
      }
      {
        !hasLesson && !largeSingleRowMode && (
          <NoLessonPaper message={noLessonMessage} />
        )
      }
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [filteredLessons, selectedLessonIds]);

  const cardSlide = useRef(null);

  const lessonDetail = () => (
    openCardDetail.open ? (
      <CardDetailContainer
        ref={cardSlide}
        open={openCardDetail.open}
        selectedIndex={openCardDetail.selectedIndex}
        onClose={handleOnCloseDetail}
        dataList={filteredLessons[openCardDetail.rowIndex].lessons}
        createCardFunction={(lesson, index) => (
          <LessonDetail
            key={`lesson-detail-${lesson.id}`}
            lesson={lesson}
            currentUser={currentUser}
            currentUserProfle={currentUserProfle}
            included={lessonData.included}
            actions={createCardDetailAction(
              lesson,
              currentUser,
              openCardDetail.rowIndex,
              () => {
                handleOnCloseDetail();
              },
              (cardIndex) => {
                // Move to the selected index
                cardSlide.current.goToSlide(cardIndex, true);
              },
              index,
            )}
            lessonTagsComponent={createLessonTagsComponent(lesson)}
            editSubjectAction={editSubjectAction(lesson, openCardDetail.rowIndex, (newRowIndex) => {
              setOpenCardDetail({
                open: false,
              });
              if (newRowIndex >= 0) {
                setOpenCardDetail({
                  ...openCardDetail,
                  selectedIndex: 0,
                  rowIndex: newRowIndex,
                });
              }
            })}
            lessonDetailActions={createLessonDetailAction(lesson)}
            cardLabels={createCardDetailLabel(lesson)}
            addtionalDetailPanel={createAddtionalDetailPanel({ lesson })}
          />
        )}
      />
    ) : null
  );

  return (
    <Fade in>
      <div>
        {lessonList}
        {lessonDetail()}
      </div>
    </Fade>
  );
});

LessonCardListDetail.defaultProps = {
  cardSize: calculateCardSize(),
  lessonData: {},
  filterTitle: undefined,
  withArchiveFilter: true,
  preFilterComponent: null,
  noFilter: false,
  onFilterChange: () => { },
  noLessonMessage: 'No Activities Available',
  multiSelectFilterConfig: [],
  createCardAction: () => ([]),
  createCardLabel: () => ([]),
  createCardDetailAction: () => ([]),
  createCardDetailLabel: () => ([]),
  currentUser: {},
  currentUserProfle: {},
  createLessonTagsComponent: () => null,
  createLessonMultiSelectionCompnent: null,
  onLessonSelect: () => { },
  selectedLessonIds: [],
  editSubjectAction: () => { },
  createRowAction: null,
  createLessonDetailAction: () => ([]),
  displayFastNavigator: false,
  showLessonDescription: undefined,
  showLessonBadges: undefined,
  largeSingleRowMode: false,
  currentSelectedSubjectName: '',
  onTitleClick: null,
  createAddtionalDetailPanel: () => null,
  shouldDisplayViewDetail: () => true,
};

LessonCardListDetail.propTypes = {
  cardSize: PropTypes.shape({
    width: PropTypes.number,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
  }),
  lessonData: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    lessons: PropTypes.array,
    included: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.object,
    })),
    lessonFilterValue: PropTypes.object,
  }),
  preFilterComponent: PropTypes.node,
  filterTitle: PropTypes.string,
  withArchiveFilter: PropTypes.bool,
  noFilter: PropTypes.bool,
  onFilterChange: PropTypes.func,
  noLessonMessage: PropTypes.string,
  multiSelectFilterConfig: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    subTitle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.any,
      name: PropTypes.any,
      value: PropTypes.any,
    })),
    className: PropTypes.string,
    keyName: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    filterable: PropTypes.bool,
  })),
  createCardAction: PropTypes.func,
  createCardLabel: PropTypes.func,
  createCardDetailAction: PropTypes.func,
  createCardDetailLabel: PropTypes.func,
  currentUser: PropTypes.shape({
    userName: PropTypes.string,
  }),
  currentUserProfle: PropTypes.shape({
    id: PropTypes.number,
  }),
  createLessonTagsComponent: PropTypes.func,
  createLessonMultiSelectionCompnent: PropTypes.func,
  onLessonSelect: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  editSubjectAction: PropTypes.func,
  createRowAction: PropTypes.func,
  createLessonDetailAction: PropTypes.func,
  displayFastNavigator: PropTypes.bool,
  showLessonDescription: PropTypes.bool,
  showLessonBadges: PropTypes.bool,
  largeSingleRowMode: PropTypes.bool,
  currentSelectedSubjectName: PropTypes.string,
  onTitleClick: PropTypes.func,
  createAddtionalDetailPanel: PropTypes.func,
  shouldDisplayViewDetail: PropTypes.func,
};

export default LessonCardListDetail;

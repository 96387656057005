import { useId } from 'react';
import PropTypes from 'prop-types';
import { Paper, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import PrintCard from '../common/print/PrintCard';
import './MultipleChoiceV2Layout.scss';
import './MultipleChoiceActivityV2Print.scss';
import { getMultipleChoicePrefix } from '../multiple-choice/MultipleChoiceUtils';

const MultipleChoiceActivityV2Print = ({
  questionCard,
  answerCards,
  promptText,
  subText,
  singlePage,
}) => {
  const hasCardWithMedia = answerCards.find((a) => a.image || a.video || a.audio);
  const id = useId();

  return (
    <div
      className={`
        multiple-choice-v2-print-component 
        multiple-choice-v2-layout 
        ${singlePage ? 'single-page' : 'side-by-side'}
        ${hasCardWithMedia ? '' : 'stack-vertically'}
        `}
    >
      <div className='prompt-area'>
        {promptText && (
          <PrintCard data-test='multiple-choice-prompt' className='multiple-choice-prompt-text' text={promptText} fontScale={0.95} />
        )}
        <PrintCard
          className='multiple-choice-component-question-card'
          text={questionCard.text}
          image={questionCard.image}
          data-test='multiple-choice-question'
        />
        {subText && (
          <PrintCard data-test='multiple-choice-sub' className='multiple-choice-sub-text' text={subText} fontScale={0.95} />
        )}
      </div>
      <FormControl
        className='response-area vizzle-radio'
      >
        <RadioGroup
          className={`response-cards-container multiple-choice-${answerCards.length} vizzle-radio`}
          value=''
        >
          {answerCards.map((card, index) => {
            const key = `multiple-choice-answer-key-${id}-${index}-${card.answerKey}`;
            return (
              <Paper
                key={key}
                className='multiple-choice-component-response-container multiple-choice-component-response-main-container'
                elevation={3}
                style={{ overflow: 'auto' }}
              >
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={(
                    <div className='prefix'>
                      {getMultipleChoicePrefix(index)}.
                    </div>
                  )}
                  labelPlacement='start'
                />
                <div
                  className='multiple-choice-component-response-container'
                >
                  <PrintCard
                    className='multiple-choice-component-response'
                    image={card.image}
                    text={card.text}
                    data-test={key}
                  />
                </div>
              </Paper>
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default MultipleChoiceActivityV2Print;

MultipleChoiceActivityV2Print.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}],
  promptText: null,
  subText: null,
  singlePage: true,
};

MultipleChoiceActivityV2Print.propTypes = {
  questionCard: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  promptText: PropTypes.object,
  subText: PropTypes.object,
  singlePage: PropTypes.bool,
};

import { createContext, useContext } from 'react';
import MyStudentDomain from './MyStudentDomain';

const domains = [
  new MyStudentDomain(),
];

/*-----------------------------------------------------------------------*/

let MyStudentContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  MyStudentContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useStudentContext = () => useContext(MyStudentContextProvider);

import { useDomain } from '../../../states/AppDomainProvider';
import { flatten, sort, unique, uniqueObject, isArrayEqual } from '../../../utils/ArrayUtils';

export const useHandleStudentsFilter = ({
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  onFilterCriteriaAssignedStudentsCoursesUpdate,
  currentStudent,
}) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  let studentOptions = [];
  const studentKeys = [];
  if (currentStudent) {
    studentOptions.push({
      key: currentStudent.id,
      name: `${currentStudent.firstName} ${currentStudent.lastName}`,
      value: currentStudent.id,
    });
    studentKeys.push(currentStudent.id);
  }
  if (assignedStudentCourses && assignedStudentCourses.length > 0) {
    const allStudentIds = uniqueObject(assignedStudentCourses.map((a) => a.userId));
    if (currentStudent) {
      allStudentIds.push(currentStudent.id);
    }
    const studentObjects = allStudentIds.map((studentId) => {
      const studentObject = students.find((s) => s.id === studentId);
      studentKeys.push(studentId);
      return {
        key: studentObject.id,
        name: `${studentObject.firstName} ${studentObject.lastName}`,
        value: studentObject.id,
      };
    });
    studentOptions = unique(sort(studentObjects, 'name'), 'key');
  }

  const { studentIds } = filterCriteriaAssignedStudentCourses;
  if (studentIds && studentIds.length > 0) {
    const updatedStudentIds = studentIds.filter((sid) => studentKeys.includes(sid));
    const updatedFilter = {
      ...filterCriteriaAssignedStudentCourses,
      studentIds: updatedStudentIds && updatedStudentIds.length > 0 ? updatedStudentIds : null,
    };

    const isEqual = isArrayEqual(updatedStudentIds, studentIds);
    if (!isEqual) {
      onFilterCriteriaAssignedStudentsCoursesUpdate(updatedFilter);
    }
  }

  const handleOnStudentsSelected = (values) => {
    const sortedValues = sort(values, 'name');
    const selectedIds = sortedValues.map((v) => v.key);
    const updatedFilter = {
      ...filterCriteriaAssignedStudentCourses,
      studentIds: selectedIds,
    };

    onFilterCriteriaAssignedStudentsCoursesUpdate(updatedFilter);
  };

  return {
    studentOptions,
    handleOnStudentsSelected,
  };
};

export const useHandleLessonsFilter = ({
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  onFilterCriteriaAssignedStudentsCoursesUpdate,
}) => {
  const { studentIds } = filterCriteriaAssignedStudentCourses;
  const filteredAssignedCourses = studentIds && studentIds.length > 0
    ? assignedStudentCourses.filter((a) => studentIds.includes(a.userId))
    : assignedStudentCourses;

  const allLessons = flatten(filteredAssignedCourses.map((a) => a.lessons));
  const uniqueLessons = unique(allLessons, 'id');

  const lessonOptions = [];
  const lessonKeys = [];
  uniqueLessons.forEach((u) => {
    lessonOptions.push({
      key: u.id,
      name: u.attributes.name,
      value: u.id,
    });
    lessonKeys.push(u.id);
  });

  const { lessonIds } = filterCriteriaAssignedStudentCourses;
  if (lessonIds && lessonIds.length > 0) {
    const updatedLessonIds = lessonIds.filter((lid) => lessonKeys.includes(lid));
    const updatedFilter = {
      ...filterCriteriaAssignedStudentCourses,
      lessonIds: updatedLessonIds && updatedLessonIds.length > 0 ? updatedLessonIds : null,
    };

    const isEqual = isArrayEqual(updatedLessonIds, lessonIds);
    if (!isEqual) {
      onFilterCriteriaAssignedStudentsCoursesUpdate(updatedFilter);
    }
  }

  const handleOnLessonsSelected = (values) => {
    const sortedValues = sort(values, 'name');
    const selectedIds = sortedValues.map((v) => v.key);
    const updatedFilter = {
      ...filterCriteriaAssignedStudentCourses,
      lessonIds: selectedIds,
    };

    onFilterCriteriaAssignedStudentsCoursesUpdate(updatedFilter);
  };

  return {
    lessonOptions: sort(lessonOptions, 'key'),
    handleOnLessonsSelected,
  };
};

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { BLANK_SPACE } from '../../../../components/vizzle/page/fitb/FillInTheBlankUtils';
import { useTtsPlayForElement } from '../../utils/UseTtsPlay';
import './FillInTheBlankActivityWithTextStyle.scss';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import TextLines from '../../../../components/vizzle/text/TextLines';

const FillInTheBlankActivityWithTextStyle = ({
  question,
  answers,
  onFinish,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  initialCorrectAnswer,
  className,
  autoFocus,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;

  const textFieldRef = useRef(null);
  const focusToTextField = () => {
    if (textFieldRef.current && autoFocus) {
      textFieldRef.current.focus();
    }
  };

  const preBlankRef = useRef(null);
  const postBlankRef = useRef(null);

  const {
    playTts,
    tts,
    cancelTts,
  } = useTtsPlayForElement();

  const playQuestionTts = async () => {
    if (preBlankRef.current) {
      await playTts(preBlankRef.current);
    }

    if (postBlankRef.current) {
      await ObjectUtils.delay(500);
      await playTts(postBlankRef.current);
    }

    focusToTextField();
  };

  const [answerText, setAnswerText] = useState(initialCorrectAnswer);
  const [correctAnswer, setCorrectAnswer] = useState(
    initialCorrectAnswer !== null
    && initialCorrectAnswer !== undefined
    && initialCorrectAnswer !== '',
  );
  const [wrongAnswer, setWrongAnswer] = useState({
    count: 0,
    showNotification: false,
  });

  const isCorrect = (attempt) => {
    if (answers.length === 0) {
      return true;
    }
    const transformedAnswers = answers.map((answer) => (answer.toLowerCase()));
    const transformedAttemp = attempt.toLowerCase();
    return transformedAnswers.indexOf(transformedAttemp) >= 0;
  };

  // Form submit somehow does now work
  const handleOnAnswerSubmit = async (e) => {
    e.preventDefault();
    cancelTts();
    if (isCorrect(answerText)) {
      setWrongAnswer({
        ...wrongAnswer,
        count: 0,
        showNotification: false,
      });
      setCorrectAnswer(true);
      await onCorrectAnswerSelected(answerText);
      onFinish();
    } else if (wrongAnswer.count < 2) {
      setWrongAnswer({
        ...wrongAnswer,
        count: wrongAnswer.count + 1,
        showNotification: true,
      });
      setAnswerText('');
      onIncorrectAnswerSelected(answerText);
    } else {
      setWrongAnswer({
        ...wrongAnswer,
        count: 0,
        showNotification: false,
      });
      setAnswerText('');
      onIncorrectAnswerSelected(answerText);
      onFinish();
    }
  };

  const handleOnAnswerTextChange = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleOnAnswerSubmit(e);
    } else {
      setAnswerText(e.target.value.substring(0, 255));
      if (wrongAnswer.showNotification) {
        setWrongAnswer({
          ...wrongAnswer,
          showNotification: false,
        });
      }
    }
  };

  return (
    <div className={`fill-in-the-blank-activity-with-text-style ${className}`}>
      <div className={`question-area ${tts.enabled ? 'clickable' : ''}`}>
        <Box
          component='span'
          ref={preBlankRef}
          onClick={playQuestionTts}
        >
          <TextLines textLines={question.preBlank} />
        </Box>
        {answerText ? (
          <div className='answer-text'>
            {answerText}
          </div>
        ) : (
          <Box
            onClick={focusToTextField}
          >
            {BLANK_SPACE}
          </Box>
        )}
        {question.postBlank && (
          <Box
            component='span'
            ref={postBlankRef}
            onClick={playQuestionTts}
          >
            <TextLines textLines={question.postBlank} />
          </Box>
        )}
      </div>
      <form className='submit-answer-form'>
        <div className='text-field'>
          {wrongAnswer.showNotification ? (
            <Typography variant='subtitle1' className='try-again'>
              Try again!
            </Typography>
          ) : ''}
          <TextField
            autoFocus={autoFocus}
            fullWidth
            value={answerText}
            onChange={handleOnAnswerTextChange}
            variant='outlined'
            disabled={correctAnswer}
            className={`${wrongAnswer.showNotification && userProfile.prompt ? 'wrong-answer' : ''}`}
            inputRef={textFieldRef}
          />
        </div>
        <Button
          color='primary'
          type='submit'
          className='btn-primary'
          disabled={!answerText || correctAnswer}
          onClick={handleOnAnswerSubmit}
        >
          Answer
        </Button>
      </form>
    </div>
  );
};

FillInTheBlankActivityWithTextStyle.defaultProps = {
  question: {
    preBlank: {
      lines: [],
    },
    postBlank: {
      lines: [],
    },
  },
  answers: [],
  onFinish: () => { },
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  initialCorrectAnswer: '',
  className: '',
  autoFocus: true,
};

FillInTheBlankActivityWithTextStyle.propTypes = {
  question: PropTypes.shape({
    preBlank: PropTypes.any,
    postBlank: PropTypes.any,
  }),
  answers: PropTypes.arrayOf(PropTypes.string),
  onFinish: PropTypes.func,
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  initialCorrectAnswer: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default FillInTheBlankActivityWithTextStyle;

import AssignedStudentCoursesList from './components/assignment-overview-result/AssignedStudentCoursesList';
import AssignmentsOverviewDateSelector from './components/AssignmentsOverviewDateSelector';
import AssignmentsOverviewFilter from './components/AssignmentsOverviewFilter';
import './AssignmentOverview.scss';
import { useDomain } from '../../../../states/AppDomainProvider';
import NoLessonPaper from '../../../../components/paper/NoLessonPaper';

const MyAssignments = () => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  return (
    <div className='assignment-overview-container'>
      <AssignmentsOverviewFilter />
      {students.length === 0 && (
        <NoLessonPaper className='no-students-message' message='You currently have no students' />
      )}
      {students.length > 0 && (
        <>
          <AssignmentsOverviewDateSelector />
          <AssignedStudentCoursesList />
        </>
      )}
    </div>
  );
};

export default MyAssignments;

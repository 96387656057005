import PropTypes from 'prop-types';
import {
  MenuItem,
} from '@mui/material';
import { useArchiveLessonForTeacher } from '../../../../../lessons/lessonactions/hooks/ArchiveUnarchiveHooks';

const ArchiveUnarchiveMenuItem = ({
  lesson,
  onActionComplete,
  onClick,
}) => {
  const { archiveLesson } = useArchiveLessonForTeacher();
  const handleArchiveLesson = async (archiveFlag) => {
    await archiveLesson([lesson], archiveFlag);
    if (onActionComplete) {
      onActionComplete();
    }
  };

  return (
    lesson.meta.archived ? (
      <MenuItem onClick={() => { onClick(); handleArchiveLesson(false); }}>Unarchive</MenuItem>
    ) : (
      <MenuItem onClick={() => { onClick(); handleArchiveLesson(true); }}>Archive</MenuItem>
    )
  );
};

ArchiveUnarchiveMenuItem.defaultProps = {
  lesson: {},
  onActionComplete: () => { },
  onClick: () => { },
};

ArchiveUnarchiveMenuItem.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }),
  onActionComplete: PropTypes.func,
  onClick: PropTypes.func,
};

export default ArchiveUnarchiveMenuItem;

import { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DatePicker from 'react-multi-date-picker';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ProfileBox from '../../../../../components/box/ProfileBox';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import './StudentInfo.scss';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import { playExpiredLessonTooltipText, schoologyTooltipText, stateIdentifierTooltipText } from '../../../../../texts/TooltipText';
import ToggleForm from '../../../../../components/toggle/ToggleForm';
import { dateFormatMMddyyyy } from '../../../../../AppConstants';
import DropdownInput from '../../../../../components/selector/DropdownInput';
import { GENDER_CONSTANTS_OPTIONS, ETHNIC_CONSTANTS_OPTIONS } from '../../../../admin/tabs/students/actions/forms/additional-student-fields/AdditionalFieldsConstant';
import { gradeListOptions } from '../../../../../constants/GradeConstants';
import { EMAIL_REGEXP } from '../../../../../constants/Regex';

const passwordMismatch = 'Password and Confirm Password do not match.';
const invalidPassword = 'Password must be at least 4 characters';
const invalidConfirmPassword = 'Confirm Password must be at least 4 characters';
const invalidProfileFieldMessages = {
  userName: 'Username must be at least 4 characters',
  firstName: 'First name is required',
  lastName: 'Last name is required',
  stateIdentifier: 'State identifier is required',
  emailAddress: 'Invalid email format',
};

const StudentInfo = () => {
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const { updateProfileSettingsOnChange, loading } = useSettingForm('Student Profile Settings');
  const { studentsDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedStudentId, students } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;
  // const { b2cId } = selectedStudentProfile;
  // const isB2cId = Boolean(b2cId);
  const { isClasslinkDistrict, isCleverDistrict } = user;
  const disableSettingData = isClasslinkDistrict || isCleverDistrict;

  const [userProfile, setUserProfile] = useState({
    userName: selectedStudentProfile.userName,
    firstName: selectedStudentProfile.firstName,
    lastName: selectedStudentProfile.lastName,
    externalId: selectedStudentProfile.externalId,
    password: '',
    confirmPassword: '',
    schoologyId: selectedStudentProfile.schoologyId,
    playExpiredLessons: selectedStudentProfile.playExpiredLessons,
    esy: selectedStudentProfile.esy,
    feedbackChat: selectedStudentProfile.feedbackChat,
    grade: selectedStudentProfile.grade || '',
    ethnicity: selectedStudentProfile.ethnicity || '',
    gender: selectedStudentProfile.gender || '',
    dob: selectedStudentProfile.dob || '',
    stateIdentifier: selectedStudentProfile.stateIdentifier || '',
    emailAddress: selectedStudentProfile.emailAddress || '',
    specialEducation: selectedStudentProfile.specialEducation,
  });
  const [booleanLogic, setBooleanLogic] = useState({
    validPassword: false,
    validConfirmPassword: false,
    onEdit: false,
    showAlert: false,
    disableButton: true,
  });
  const [helperText, setHelperText] = useState({
    password: '',
    confirmPassword: '',
    userName: '',
    firstName: '',
    lastName: '',
    stateIdentifier: '',
    emailAddress: '',
  });
  const [saveObject, setSaveObject] = useState({});
  // When password or confirm password is changing
  useEffect(() => {
    if (booleanLogic.validPassword && booleanLogic.validConfirmPassword) {
      const emptyHelperText = (!helperText.firstName && !helperText.lastName && !helperText.userName && !helperText.emailAddress && !helperText.stateIdentifier);
      if (userProfile.password === userProfile.confirmPassword) {
        if (emptyHelperText) {
          setBooleanLogic({ ...booleanLogic, disableButton: false, showAlert: false });
        } else {
          setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: false });
        }
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile.password, userProfile.confirmPassword]);

  // When mounting and changing a student.
  useEffect(() => {
    setUserProfile({
      userName: selectedStudentProfile.userName,
      firstName: selectedStudentProfile.firstName,
      lastName: selectedStudentProfile.lastName,
      externalId: selectedStudentProfile.externalId,
      password: '',
      confirmPassword: '',
      schoologyId: selectedStudentProfile.schoologyId,
      playExpiredLessons: selectedStudentProfile.playExpiredLessons,
      esy: selectedStudentProfile.esy,
      feedbackChat: selectedStudentProfile.feedbackChat,
      grade: selectedStudentProfile.grade,
      ethnicity: selectedStudentProfile.ethnicity,
      gender: selectedStudentProfile.gender,
      dob: selectedStudentProfile.dob,
      stateIdentifier: selectedStudentProfile.stateIdentifier,
      emailAddress: selectedStudentProfile.emailAddress,
      specialEducation: selectedStudentProfile.specialEducation,
    });
    setBooleanLogic({ ...booleanLogic, onEdit: false });
    setSaveObject({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudentProfile.id, selectedStudentProfile.switchAccessibility]);

  const validateHelperText = (errorMessage) => {
    const emptyProfileHelperText = (!errorMessage.firstName && !errorMessage.lastName && !errorMessage.userName && !errorMessage.emailAddress && !errorMessage.stateIdentifier);
    if (emptyProfileHelperText) {
      const emptyPasswordError = (!errorMessage.password && !errorMessage.confirmPassword);
      if (emptyPasswordError && userProfile.password === userProfile.confirmPassword) {
        setBooleanLogic({ ...booleanLogic, disableButton: false });
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
  };

  const validateFieldInput = (e) => {
    const errorText = helperText;
    if (e.target.value) {
      switch (e.target.name) {
        case 'userName':
          if (e.target.value.length >= 4) {
            errorText.userName = '';
            setHelperText({ ...errorText });
          } else {
            errorText.userName = invalidProfileFieldMessages.userName;
            setHelperText({ ...errorText });
          }
          break;
        case 'firstName':
          errorText.firstName = '';
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = '';
          setHelperText({ ...errorText });
          break;
        case 'stateIdentifier':
          errorText.stateIdentifier = '';
          setHelperText({ ...errorText });
          break;
        case 'emailAddress':
          if (EMAIL_REGEXP.test(e.target.value)) {
            errorText.emailAddress = '';
          } else {
            errorText.emailAddress = invalidProfileFieldMessages.emailAddress;
          }
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    } else {
      switch (e.target.name) {
        case 'userName':
          errorText.userName = invalidProfileFieldMessages.userName;
          setHelperText({ ...errorText });
          break;
        case 'firstName':
          errorText.firstName = invalidProfileFieldMessages.firstName;
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = invalidProfileFieldMessages.lastName;
          setHelperText({ ...errorText });
          break;
        case 'stateIdentifier':
          errorText.stateIdentifier = invalidProfileFieldMessages.stateIdentifier;
          setHelperText({ ...errorText });
          break;
        case 'emailAddress':
          errorText.emailAddress = '';
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    }
    validateHelperText(errorText);
  };

  const onUserProfileChange = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    setSaveObject({ ...saveObject, [e.target.name]: e.target.value });
    validateFieldInput(e);
  };

  const onUserProfileFieldChange = (fieldName, value) => {
    setUserProfile({ ...userProfile, [fieldName]: value });
    setSaveObject({ ...saveObject, [fieldName]: value });
    validateFieldInput({
      target: {
        name: fieldName,
        value,
      },
    });
  };

  const onPasswordChange = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && e.target.value.length >= 4) {
      setBooleanLogic({ ...booleanLogic, validPassword: true });
      setHelperText({ ...helperText, password: '' });
      setSaveObject({ ...saveObject, password: e.target.value });
    } else {
      setBooleanLogic({ ...booleanLogic, validPassword: false });
      setHelperText({ ...helperText, password: invalidPassword });
      delete saveObject.password;
      setSaveObject({ ...saveObject });
    }
  };

  const onConfirmPasswordChange = (e) => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && e.target.value.length >= 4) {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: true });
      setHelperText({ ...helperText, confirmPassword: '' });
    } else {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: false });
      setHelperText({ ...helperText, confirmPassword: invalidConfirmPassword });
    }
  };

  const editOnClick = () => {
    setBooleanLogic({ ...booleanLogic, onEdit: true });
  };

  const onClose = () => {
    setBooleanLogic({ ...booleanLogic, showAlert: false });
  };

  const clearState = () => {
    setBooleanLogic({
      validPassword: false,
      validConfirmPassword: false,
      onEdit: false,
      showAlert: false,
      disableButton: true,
    });
    setHelperText({ password: '', confirmPassword: '' });
    setSaveObject({});
  };

  const onCancel = () => {
    setUserProfile({
      userName: selectedStudentProfile.userName,
      firstName: selectedStudentProfile.firstName,
      lastName: selectedStudentProfile.lastName,
      externalId: selectedStudentProfile.externalId,
      password: '',
      confirmPassword: '',
      schoologyId: selectedStudentProfile.schoologyId,
      playExpiredLessons: selectedStudentProfile.playExpiredLessons,
      esy: selectedStudentProfile.esy,
      feedbackChat: selectedStudentProfile.feedbackChat,
      grade: selectedStudentProfile.grade,
      ethnicity: selectedStudentProfile.ethnicity,
      gender: selectedStudentProfile.gender,
      dob: selectedStudentProfile.dob,
      stateIdentifier: selectedStudentProfile.stateIdentifier,
      emailAddress: selectedStudentProfile.emailAddress,
      specialEducation: selectedStudentProfile.specialEducation,
    });
    clearState();
  };

  const onSave = async () => {
    await updateProfileSettingsOnChange(currentSelectedStudentId, { ...saveObject });
    setUserProfile({
      ...userProfile,
      password: '',
      confirmPassword: '',
    });

    const student = students.find((st) => st.id === currentSelectedStudentId);
    student.username = userProfile.userName;
    student.firstName = userProfile.firstName;
    student.lastName = userProfile.lastName;
    student.schoologyId = userProfile.schoologyId;
    student.playExpiredLessons = userProfile.playExpiredLessons;
    student.esy = userProfile.esy;
    student.feedbackChat = userProfile.feedbackChat;
    student.grade = userProfile.grade;
    student.ethnicity = userProfile.ethnicity;
    student.gender = userProfile.gender;
    student.dob = userProfile.dob;
    student.stateIdentifier = userProfile.stateIdentifier;
    student.emailAddress = userProfile.emailAddress;
    student.specialEducation = userProfile.specialEducation;
    studentsDomain.updateStudentList([...students]);

    clearState();
  };

  return (
    <Box display='flex' flexDirection='column' className='student-info-flexbox' data-test='student-info-container'>
      {booleanLogic.showAlert ? <Alert severity='error' onClose={onClose}>{passwordMismatch}</Alert> : null}
      <Grid container spacing={4} rowSpacing={2} data-test='student-form-info-container'>

        <Grid item xs={4}>
          <ProfileBox
            label='First Name'
            name='firstName'
            isRequired
            value={userProfile.firstName}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.firstName}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>
        <Grid item xs={4}>
          <ProfileBox
            label='State Identifier'
            name='stateIdentifier'
            value={userProfile.stateIdentifier}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            className='state-identifier-field'
            isRequired
            helperText={helperText.stateIdentifier}
            fullWidth
            endDecorator={(
              <TooltipWithIndicator {...stateIdentifierTooltipText} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <ProfileBox
            label='Username'
            name='userName'
            isRequired
            value={userProfile.userName}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.userName}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>

        <Grid item xs={4}>
          <ProfileBox
            label='Last Name'
            name='lastName'
            isRequired
            value={userProfile.lastName}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.lastName}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>
        <Grid item xs={4}>
          <Box className='selector-dropdown-container'>
            <Typography fontWeight={500}>
              Gender
            </Typography>
            <DropdownInput
              name='gender'
              placeholder='Select Gender'
              dropdownValues={GENDER_CONSTANTS_OPTIONS}
              className='selector-dropdown'
              value={userProfile.gender || ''}
              onChange={onUserProfileChange}
              disabled={!booleanLogic.onEdit || disableSettingData}
              data-test='student-gender-selector'
              inputProps={{
                'data-private': 'redact',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <ProfileBox
            label='Password'
            name='password'
            type='password'
            isRequired
            value={userProfile.password || (booleanLogic.onEdit && (isPasswordFocus || helperText.password) ? '' : '****')}
            onChange={onPasswordChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.password}
            onFocus={() => {
              setIsPasswordFocus(true);
            }}
            onBlur={() => {
              setIsPasswordFocus(false);
            }}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>

        {/* {!isB2cId && (
          <Grid item xs={4}>
            <ProfileBox
              label='Email Address'
              name='emailAddress'
              value={userProfile.emailAddress}
              onChange={onUserProfileChange}
              isEdit={booleanLogic.onEdit}
              helperText={helperText.emailAddress}
              fullWidth
            />
          </Grid>
        )} */}

        <Grid item xs={4}>
          <Box className='selector-dropdown-container'>
            <Typography fontWeight={500}>
              Ethnicity
            </Typography>
            <DropdownInput
              name='ethnicity'
              placeholder='Select Ethnicity'
              dropdownValues={ETHNIC_CONSTANTS_OPTIONS}
              className='selector-dropdown'
              value={userProfile.ethnicity || ''}
              onChange={onUserProfileChange}
              disabled={!booleanLogic.onEdit || disableSettingData}
              data-test='ethnicity-gender-selector'
              inputProps={{
                'data-private': 'redact',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          {
            booleanLogic.onEdit && !disableSettingData
              ? (
                <ProfileBox
                  label='Confirm Password'
                  name='confirmPassword'
                  value={userProfile.confirmPassword}
                  onChange={onConfirmPasswordChange}
                  isEdit={booleanLogic.onEdit}
                  type='password'
                  helperText={helperText.confirmPassword}
                  fullWidth
                />
              )
              : null
          }
        </Grid>
        <Grid item xs={4}>
          <Box className='dob-form-row' width='100%' data-test='date-of-birth-selector' data-private>
            <Typography fontWeight={500}>
              Date of Birth
            </Typography>
            <DatePicker
              format={dateFormatMMddyyyy.toLocaleUpperCase()}
              className='dob-picker'
              placeholder='Choose Date of Birth'
              onOpenPickNewDate={false}
              name='dob'
              value={userProfile.dob}
              onChange={(dateValue) => {
                const value = dateValue.format(dateFormatMMddyyyy.toLocaleUpperCase());
                onUserProfileFieldChange('dob', value);
              }}
              disabled={!booleanLogic.onEdit || disableSettingData}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box className='selector-dropdown-container'>
            <Typography className='form-label' fontWeight={500}>
              Grade
            </Typography>
            <DropdownInput
              name='grade'
              placeholder='Select Grade'
              dropdownValues={gradeListOptions}
              className='selector-dropdown'
              value={userProfile.grade || ''}
              onChange={onUserProfileChange}
              disabled={!booleanLogic.onEdit || disableSettingData}
              data-test='student-grade-selector'
              inputProps={{
                'data-private': 'redact',
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          {user.schoologyFlag && (
            <ProfileBox
              label='Schoology ID'
              name='schoologyId'
              value={userProfile.schoologyId}
              onChange={onUserProfileChange}
              isEdit={booleanLogic.onEdit}
              endDecorator={(
                <TooltipWithIndicator {...schoologyTooltipText} />
              )}
              fullWidth
            />
          )}
        </Grid>

        <Grid item xs={4}>
          <Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='subtitle1' className='my-student-title'>
                Play Expired Activities
              </Typography>
              <TooltipWithIndicator className='tooltip margin-left-small' {...playExpiredLessonTooltipText} />
            </Box>
            <Box>
              <ToggleForm
                cssClassName='student-info-toggle'
                value={userProfile.playExpiredLessons}
                handleOnChange={(_e, value) => {
                  if (value === null) {
                    return;
                  }
                  setUserProfile({
                    ...userProfile,
                    playExpiredLessons: value,
                  });
                  updateProfileSettingsOnChange(currentSelectedStudentId, { playExpiredLessons: value });
                }}
                data-test='play-expired-lessons-toggle'
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='subtitle1' className='my-student-title nested'>
                Attending ESY
              </Typography>
            </Box>
            <Box>
              <ToggleForm
                cssClassName='student-info-toggle'
                value={userProfile.esy}
                handleOnChange={(_e, value) => {
                  if (value === null) {
                    return;
                  }
                  setUserProfile({
                    ...userProfile,
                    esy: value,
                  });
                  updateProfileSettingsOnChange(currentSelectedStudentId, { esy: value });
                }}
                data-test='esy-toggle'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='subtitle1' className='my-student-title'>
                Teacher/Student Chat
              </Typography>
            </Box>
            <Box>
              <ToggleForm
                cssClassName='student-info-toggle'
                value={userProfile.feedbackChat}
                handleOnChange={(_e, value) => {
                  if (value === null) {
                    return;
                  }
                  setUserProfile({
                    ...userProfile,
                    feedbackChat: value,
                  });
                  updateProfileSettingsOnChange(currentSelectedStudentId, { feedbackChat: value });
                }}
                data-test='feedbackChat-toggle'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='subtitle1' className='my-student-title nested'>
                Special Education
              </Typography>
            </Box>
            <Box>
              <ToggleForm
                cssClassName='student-info-toggle'
                value={userProfile.specialEducation}
                handleOnChange={(_e, value) => {
                  if (value === null) {
                    return;
                  }
                  setUserProfile({
                    ...userProfile,
                    specialEducation: value,
                  });
                  updateProfileSettingsOnChange(currentSelectedStudentId, { specialEducation: value });
                }}
                onLabel='Yes'
                offLabel='No'
                data-test='special-education-toggle'
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {(!disableSettingData || user.schoologyFlag) && (
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          {
            booleanLogic.onEdit
              ? (
                <Box display='flex' flexDirection='row' className='flex-student-setting-on-edit'>
                  <Button variant='contained' onClick={onCancel} className='btn-primary-rounded-border margin-right ' disabled={loading} data-test='cancel-student-info-action'>
                    Cancel
                  </Button>
                  <ButtonWithSpinner
                    label='Save'
                    variant='contained'
                    onClick={onSave}
                    className='btn-primary action-button'
                    disabled={booleanLogic.disableButton}
                    loading={loading}
                    data-test='save-student-info-action'
                  />
                </Box>
              )
              : (
                <Box display='flex' flexDirection='row' className='flex-student-setting-on-edit' data-test='edit-student-info-action'>
                  <ButtonWithSpinner buttonClass='' startIcon={<EditIcon />} label='Edit' variant='text' onClick={editOnClick} className='action-button' disabled={loading} />
                </Box>
              )
          }
        </Box>
      )}
    </Box>
  );
};

export default StudentInfo;

import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import DistrictsAction from './actions/DistrictsAction';
import PermissionsAction from './actions/PermissionsAction';
import { useAdminsTable, useHandleAdminTable, useInitialAdminsData } from './AdminsHooks';
import '../../AdminCommon.scss';

const Admins = () => {
  const {
    tableConfig,
    tableData,
    loading,
    searchTableFunction,
  } = useAdminsTable();

  const {
    selectedAdminIds,
    handleOnSelectedRowIds,
    updateDistrictRef,
  } = useHandleAdminTable();

  useInitialAdminsData();
  return (
    <div className='admin-tab-admins full-height'>
      <SearchAndSortableTable
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'username',
        }}
        searchPlaceholder='Search Admin'
        searchFunction={searchTableFunction}
        toolbarActions={[
          <PermissionsAction key='admin-permission-action' />,
          <DistrictsAction key='admin-districts-action' ref={updateDistrictRef} />,
        ]}
        dataId='id'
        selectedRowIds={selectedAdminIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        loading={loading}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Admins;

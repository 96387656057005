import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import './Buttons.scss';
import CustomTooltip from '../../../../components/card/CustomTooltip';

const UpdateButton = ({
  ariaLabel,
  onClick,
  ...rest
}) => (
  <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
    <span>
      <IconButton
        {...rest}
        className='admin-action-icon-button'
        aria-label={ariaLabel}
        onClick={onClick}
      >
        <EditIcon />
      </IconButton>
    </span>
  </CustomTooltip>
);

UpdateButton.defaultProps = {
  ariaLabel: '',
  onClick: () => null,
  disabled: false,
};

UpdateButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default UpdateButton;

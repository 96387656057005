import BaseDomain from '../../../../../states/domains/BaseDomain';

const GROUP_PLAY_UPDATE_EXTERNAL_LINK = 'GROUP_PLAY_UPDATE_EXTERNAL_LINK';
const GROUP_PLAY_UPDATE_LESSON_PLAY = 'GROUP_PLAY_UPDATE_LESSON_PLAY';
const GROUP_PLAY_UPDATE_EXTERNAL_LINK_AND_LESSON_PLAY = 'GROUP_PLAY_UPDATE_EXTERNAL_LINK_AND_LESSON_PLAY';
const GROUP_PLAY_UPDATE_LESSON_FILTER = 'GROUP_PLAY_UPDATE_LESSON_FILTER';

const initialState = {
  externalLinks: [],
  lessonData: {
    lessons: [],
    included: [],
    filter: {
      subjects: [],
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case GROUP_PLAY_UPDATE_EXTERNAL_LINK:
      return {
        ...state,
        externalLinks: action.payload,
      };
    case GROUP_PLAY_UPDATE_LESSON_PLAY:
      return {
        ...state,
        lessonData: action.payload,
      };
    case GROUP_PLAY_UPDATE_EXTERNAL_LINK_AND_LESSON_PLAY:
      return {
        ...state,
        externalLinks: action.payload.externalLinks,
        lessonData: {
          ...state.lessonData,
          lessons: action.payload.lessonData.lessons,
          included: action.payload.lessonData.included,
        },
      };
    case GROUP_PLAY_UPDATE_LESSON_FILTER:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          filter: action.payload,
        },
      };
    default:
      return state;
  }
};

const domainName = 'groupPlayDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update list of external links
   *
   * @returns Promise
   */
  async updateGroupPlayExternalLinks(externalLinks) {
    return this.dispatch({
      type: GROUP_PLAY_UPDATE_EXTERNAL_LINK,
      payload: externalLinks,
    });
  }

  /**
   * Update list of lesson group play
   *
   * @returns Promise
   */
  async updateLessonGroupPlay(lessonData) {
    return this.dispatch({
      type: GROUP_PLAY_UPDATE_LESSON_PLAY,
      payload: lessonData,
    });
  }

  /**
   * Update list of external links and lessons group play
   *
   * @returns Promise
   */
  async updateGroupPlayExternalLinksAndLessonGroupPlay(
    externalLinks,
    lessonData,
  ) {
    return this.dispatch({
      type: GROUP_PLAY_UPDATE_EXTERNAL_LINK_AND_LESSON_PLAY,
      payload: {
        externalLinks,
        lessonData,
      },
    });
  }

  async updateGroupPlayLessonFilter(filter) {
    return this.dispatch({
      type: GROUP_PLAY_UPDATE_LESSON_FILTER,
      payload: filter,
    });
  }
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Typography,
  TextField,
} from '@mui/material';
import {
  AddCircle as AddCircleIcon,
  NearMe as NearMeIcon,
} from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TooltipWithIndicator from '../../components/tooltip/TooltipWithIndicator';
import {
  removeTagFromLesson,
  addExistingTagToLesson,
  createAndAssignTag,
} from '../../services/LessonTagService';
import './LessonTag.scss';
import {
  useGetRemoteServiceWrapper,
} from '../hooks/RemoteServiceHooks';
import {
  usePrepareData,
} from './LessonTagHooks';
import Logger from '../../utils/Logger';

const filter = createFilterOptions();

const LessonTag = ({
  currentUser,
  tagUrl,
  title,
  tooltipText,
  lesson,
  tagIds,
  noTagMessage,
  placeholder,
  allLessonTags,
  onTagDelete,
  onTagSelect,
  onTagAdd,
}) => {
  const {
    selectedTagIds,
    setSelectedTagIds,
  } = usePrepareData(tagIds, allLessonTags);

  const selectedTags = selectedTagIds
    .map((tagId) => (allLessonTags.find((tag) => tag.id === tagId)))
    .filter((t) => t !== null && t !== undefined);
  const unusedTags = allLessonTags.filter((tag) => !selectedTagIds.includes(tag.id));

  const [value, setValue] = useState(null);
  const resetValue = () => {
    // Reset value
    setValue({
      attributes: {
        name: '',
      },
    });
  };

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const handleDelete = async (e, selectedTag) => {
    callRemoteServiceWrapper(async () => {
      await removeTagFromLesson(currentUser.id, lesson.id, selectedTag.id, tagUrl);
      const updatedSelectedTagIds = selectedTagIds.filter((tagId) => tagId !== selectedTag.id);
      setSelectedTagIds(updatedSelectedTagIds);
      onTagDelete(selectedTag, lesson);
    });
  };

  const handleSelect = (selectedTag) => {
    callRemoteServiceWrapper(async () => {
      await addExistingTagToLesson(currentUser.id, lesson.id, selectedTag.id, tagUrl);
      resetValue();
      setSelectedTagIds([
        selectedTag.id,
        ...selectedTagIds,
      ]);
      onTagSelect(selectedTag, lesson);
    });
  };

  const handleAdd = (tag) => {
    callRemoteServiceWrapper(async () => {
      const newTag = await createAndAssignTag(currentUser.id, lesson.id, tag, tagUrl);
      await onTagAdd(newTag, lesson);
      setSelectedTagIds([
        newTag.id,
        ...selectedTagIds,
      ]);
      resetValue();
    });
  };

  return (
    <div className='lesson-tag' data-test={`lesson-tag-${lesson.id}`}>
      <div className='title'>
        <Typography variant='h6' gutterBottom>
          {title}
        </Typography>
        {tooltipText ? (
          <TooltipWithIndicator className='lesson-tag-tooltip' {...tooltipText} />
        ) : null}
      </div>
      {!selectedTagIds || selectedTagIds.length === 0 ? (
        <div className='no-tag'>
          {noTagMessage}
        </div>
      ) : (
        <div className='lesson-tag-container'>
          {selectedTags.map((selectedTag) => (
            <Chip
              key={`lesson-${lesson.id}-tag-${selectedTag.id}`}
              icon={null}
              label={selectedTag.attributes.name}
              onDelete={(e) => handleDelete(e, selectedTag)}
              className='lesson-tag-chip'
              data-test={`lesson-tag-chip-${selectedTag.id}`}
            />
          ))}
        </div>
      )}
      <div>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              const matched = allLessonTags.find((t) => t.attributes.name.toLowerCase() === newValue.toLowerCase());
              if (matched) {
                Logger.logWhenDebugModeIsOn('call add existing tag (press Enter)', matched);
                handleSelect(matched);
              } else {
                Logger.logWhenDebugModeIsOn('call add new tag (press Enter)', newValue);
                handleAdd(newValue);
              }
            } else if (newValue && newValue.inputValue) {
              Logger.logWhenDebugModeIsOn('call add new tag (press add)', newValue.inputValue);
              handleAdd(newValue.inputValue);
            } else if (newValue) {
              Logger.logWhenDebugModeIsOn('call add existing tag (from list)', newValue);
              handleSelect(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              const matched = filtered.findIndex((f) => f.attributes.name.toLowerCase() === params.inputValue.toLowerCase());
              if (matched < 0) {
                filtered.unshift({
                  inputValue: params.inputValue,
                  attributes: {
                    name: (
                      <div className='lesson-tag-add-new-tag-container'>
                        <AddCircleIcon className='new-tag-icon' />
                        Add &quot;<b>{params.inputValue}</b>&quot;
                      </div>
                    ),
                  },
                });
              }
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          blurOnSelect
          handleHomeEndKeys
          options={unusedTags}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.attributes ? option.attributes.name : '';
          }}
          renderOption={(props, option) => {
            if (!option.attributes) {
              return '';
            }
            const { name } = option.attributes;
            if (typeof name === 'string') {
              return (
                <li
                  {...props}
                  key={`option-${option.id}`}
                >
                  <div className='lesson-tag-tag-render'>
                    <NearMeIcon className='tag-icon' />
                    {name}
                  </div>
                </li>
              );
            }
            return (
              <li
                {...props}
                key={`option-${option.id}`}
              >
                {name}
              </li>
            );
          }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} className='auto-complete-textfield' placeholder={placeholder} variant='outlined' data-test='lesson-tag-input' />
          )}
        />
      </div>
    </div>
  );
};

LessonTag.defaultProps = {
  lesson: {},
  title: '',
  tooltipText: null,
  tagIds: [],
  allLessonTags: [],
  noTagMessage: null,
  currentUser: {},
  placeholder: '',
  onTagDelete: () => { },
  onTagSelect: () => { },
  onTagAdd: () => { },
};

LessonTag.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
  }),
  title: PropTypes.string,
  tooltipText: PropTypes.shape({
    title: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
  tagIds: PropTypes.arrayOf(PropTypes.string),
  allLessonTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  noTagMessage: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    userName: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  tagUrl: PropTypes.string.isRequired,
  onTagDelete: PropTypes.func,
  onTagSelect: PropTypes.func,
  onTagAdd: PropTypes.func,
};

export default LessonTag;

/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import { useDistrictSelectorTable, useSchoolSelectorTable } from './DistrictsFormHooks';
import './DistrictsForm.scss';
import TransparentBlocker from '../../../../../../components/blocker/TransparentBlocker';

const DistrictsForm = ({
  formData,
  loading,
  onDataChange,
}) => {
  const {
    districtTableConfig,
    districtsTableData,
    lastSelectedDistrictIdRef,
  } = useDistrictSelectorTable({
    formData,
    onDataChange,
  });

  const {
    schoolTableConfig,
    schoolTableData,
    schoolTableDataLoading,
  } = useSchoolSelectorTable({
    formData,
    onDataChange,
    lastSelectedDistrictIdRef,
  });

  return (
    <div className='admin-districts-selector-form'>
      <TransparentBlocker show={schoolTableDataLoading} />
      <SearchAndSortableTable
        config={districtTableConfig}
        tableData={loading ? [] : districtsTableData}
        initTableState={{
          order: 'desc',
          orderBy: 'districtName',
        }}
        searchPlaceholder='District Name'
        searchField='districtName'
        dataId='id'
        autoResetTableState={false}
        loading={loading}
      />
      <SearchAndSortableTable
        config={schoolTableConfig}
        tableData={loading ? [] : schoolTableData}
        initTableState={{
          order: 'desc',
          orderBy: 'schoolName',
        }}
        searchPlaceholder='School Name'
        searchField='schoolName'
        dataId='id'
        autoResetTableState={false}
        loading={loading || schoolTableDataLoading}
      />
    </div>
  );
};

DistrictsForm.defaultProps = {
  formData: {
    selectedSchoolIds: [],
  },
  onDataChange: () => null,
  loading: false,
};

DistrictsForm.propTypes = {
  formData: PropTypes.shape({
    selectedSchoolIds: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  }),
  onDataChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default DistrictsForm;

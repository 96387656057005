import PropTypes from 'prop-types';
import ListIcon from '@mui/icons-material/List';
import CardAction from '../../../../card/CardAction';
import './ViewLessonDetailAction.scss';

/**
 * Unlock Lesson Action Container
 */
export default function ViewLessonDetailAction({ onClick, className }) {
  return (
    <CardAction
      icon={<ListIcon />}
      actionHandler={onClick}
      className={`view-lesson-detail-action ${className}`}
      data-test='lesson-detail-action'
    />
  );
}

ViewLessonDetailAction.defaultProps = {
  onClick: () => {},
  className: '',
};

ViewLessonDetailAction.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

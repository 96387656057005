import { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CardAction from '../../../../components/card/CardAction';
import { useDeleteAssingedLesson } from './TeacherCoursesActionHooks';
import ConfirmationDialog from '../../../../components/dialog/ConfirmationDialog';
import './DeleteAssignedLessonAction.scss';

const DELETE_ALL_VALUE = {
  oneAssignment: 'oneAssignment',
  allAssignment: 'allAssignment',
};
export default function DeleteAssignedLessonAction({
  courseAssignmentId,
  student,
  date,
  lesson,
  onDeleteSuccess,
}) {
  const [isDeleteAllSelected, setIsDeleteAllSelected] = useState(DELETE_ALL_VALUE.oneAssignment);
  const {
    deleteAssingedLesson,
  } = useDeleteAssingedLesson({
    courseAssignmentId,
    student,
    date,
    onDeleteSuccess,
  });
  const [openDialog, setOpenDialog] = useState();

  useEffect(() => {
    if (openDialog) {
      setIsDeleteAllSelected(DELETE_ALL_VALUE.oneAssignment);
    }
  }, [openDialog]);

  const content = (
    <div className='delete-assigned-action-content'>
      <FormControl>
        <RadioGroup
          aria-label='Delete'
          name='isDeleteAllSelected'
          onChange={(e) => {
            setIsDeleteAllSelected(e.target.value);
          }}
          value={isDeleteAllSelected}
        >
          <FormControlLabel
            value={DELETE_ALL_VALUE.oneAssignment}
            control={<Radio />}
            label={`Delete single assignment on ${date} for ${student.firstName} ${student.lastName}`}
            data-test='delete-single-assignment'
            data-private
          />
          <FormControlLabel
            value={DELETE_ALL_VALUE.allAssignment}
            control={<Radio />}
            label={`Delete all assignments for this activity for ${student.firstName} ${student.lastName}`}
            data-test='delete-all-assignments'
            data-private
          />
        </RadioGroup>
      </FormControl>
    </div>
  );

  return (
    <>
      <CardAction
        icon={<DeleteIcon />}
        actionHandler={() => { setOpenDialog(true); }}
        className='delete-assigned-action'
        title='Delete Assignment'
        ariaLabel='Delete'
        data-test={`delete-assigned-action-${student.id}-${courseAssignmentId}-${lesson.id}`}
      />
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title={`Delete Assignment for Activity: ${lesson.attributes.name}`}
          contentText={content}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={() => { setOpenDialog(false); deleteAssingedLesson(lesson, isDeleteAllSelected === DELETE_ALL_VALUE.allAssignment); }}
          onCancel={() => { setOpenDialog(false); }}
        />
      ) : null}
    </>
  );
}

DeleteAssignedLessonAction.defaultProps = {
  onDeleteSuccess: () => null,
};

DeleteAssignedLessonAction.propTypes = {
  courseAssignmentId: PropTypes.string.isRequired,
  student: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    meta: PropTypes.object,
    attributes: PropTypes.object,
    relationships: PropTypes.object,
  }).isRequired,
  onDeleteSuccess: PropTypes.func,
};

import { getAssignmentsStudentCourses } from '../../../services/CourseService';
import Logger from '../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';

export const useGetAssignedStudentCourses = ({
  onStartLoading,
  subjectId,
  onDataLoad,
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
}) => {
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getAssignedStudentData = (innerSelectedDates, showLoader = true) => {
    if (!innerSelectedDates || !innerSelectedDates.dates || innerSelectedDates.dates.length === 0) {
      return;
    }
    try {
      callRemoteServiceWrapper(async () => {
        if (showLoader && onStartLoading) {
          onStartLoading(true);
        }
        const result = await getAssignmentsStudentCourses(innerSelectedDates, subjectId);
        const { data } = result;
        if (!data) {
          onDataLoad([]);
        }

        // cannot use optional chaining (ES2020 feature) as we still need to support old browser
        const filteredData = data.filter((d) => d && d.relationships && d.relationships.lessons && d.relationships.lessons.data && d.relationships.lessons.data.length > 0);

        const flattedData = filteredData.map((d) => {
          const { id, relationships, type, attributes } = d;
          const lessons = relationships.lessons.data.map((l) => {
            const lesson = result.included.find((i) => i.type === l.type && i.id === l.id);
            const copiedLesson = JSON.parse(JSON.stringify(lesson));

            const { lessonAssignmentsMetadata } = d.attributes;
            const metaData = lessonAssignmentsMetadata.find((lm) => lm.lId.toString() === l.id);
            const { lId, ...rest } = metaData;
            copiedLesson.meta = {
              ...rest,
            };
            return copiedLesson;
          });

          const newData = {
            id,
            lessons,
            type,
            ...attributes,
          };
          return newData;
        });
        onDataLoad(flattedData);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  // To handle the large data
  const filteredAssignedStudentCourses = filterCriteriaAssignedStudentCourses ? assignedStudentCourses.map((a) => JSON.parse(JSON.stringify(a))).filter((asc) => {
    const {
      studentIds,
      lessonIds,
    } = filterCriteriaAssignedStudentCourses;

    const isStudentMatched = studentIds === null || studentIds.length === 0 ? true : studentIds.includes(asc.userId);

    let isLessonIdMatched = true;
    const filteredLessons = !lessonIds || lessonIds.length === 0 ? [...asc.lessons] : asc.lessons.filter((l) => lessonIds.includes(l.id));
    // eslint-disable-next-line no-param-reassign
    asc.lessons = filteredLessons;
    isLessonIdMatched = filteredLessons.length > 0;
    return isStudentMatched && isLessonIdMatched;
  }) : assignedStudentCourses;

  return {
    filteredAssignedStudentCourses,
    getAssignedStudentData,
  };
};

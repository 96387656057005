import PropTypes from 'prop-types';
import ConfirmationDialog from '../../components/dialog/ConfirmationDialog';
import './LogoutConfirmation.scss';
import { logout } from '../../services/LoginService';

const LogoutConfirmation = ({
  title,
  message,
  open,
  onConfirm,
  onClose,
}) => {
  if (!open) {
    return null;
  }
  return (
    <ConfirmationDialog
      open={open}
      title={title}
      contentText={(
        <div>
          {message}
        </div>
      )}
      confirmLabel='Ok'
      cancelLabel='Cancel'
      onConfirm={async () => {
        await logout();
        onConfirm();
        onClose();
      }}
      onCancel={() => { onClose(); }}
      confirmClass='wide-button'
      cancelClass='wide-button'
    />
  );
};

LogoutConfirmation.defaultProps = {
  title: 'Exit Vizzle',
  message: 'Are you sure you want to exit Vizzle?',
  open: false,
  onConfirm: () => null,
  onClose: () => null,
};

LogoutConfirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default LogoutConfirmation;

import { useEffect, useState } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import DateUtils from '../../../../../utils/DateUtils';
import { useAdmin } from '../../../context/AdminContextProvider';

export const useAutoLoadData = ({
  getTeacherListData,
  getActivityReportsData,
}) => {
  const { districtsDomain, adminDataDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  const { searchCriteria, schoolsBySelectedDistrictId } = adminDataDomain.domainData;

  useEffect(() => {
    if (districts && districts.length === 1) {
      const district = districts[0];
      const updatedSearchCriteria = {
        ...searchCriteria,
        districtIds: district ? [district.id] : [],
        schoolIds: searchCriteria.schoolIds && searchCriteria.schoolIds.length > 0 ? searchCriteria.schoolIds : [],
      };
      adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts]);

  useEffect(() => {
    const loadData = async (schoolId) => {
      const updatedSearchCriteria = {
        ...searchCriteria,
        schoolIds: [schoolId],
      };
      await adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
      getTeacherListData();
      getActivityReportsData();
    };

    if (schoolsBySelectedDistrictId && schoolsBySelectedDistrictId.length === 1) {
      const school = schoolsBySelectedDistrictId[0];
      loadData(school.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolsBySelectedDistrictId]);
};

export const useHandleSearchDistrict = ({
  getTeacherListData,
  getActivityReportsData,
}) => {
  const { adminDataDomain } = useAdmin();
  const { searchCriteria, selectedTeacherId } = adminDataDomain.domainData;
  const { districtIds } = searchCriteria;

  const handleOnDistrictChange = async (district) => {
    const updatedSearchCriteria = {
      ...searchCriteria,
      districtIds: district ? [district.key] : [],
      schoolIds: [],
    };
    await adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
    getTeacherListData();
    getActivityReportsData();
  };

  const shouldSearchDistrictDisabled = selectedTeacherId !== null;
  return {
    districtIds,
    handleOnDistrictChange,
    shouldSearchDistrictDisabled,
  };
};

export const useHandleSchoolFilter = ({
  getTeacherListData,
  getActivityReportsData,
}) => {
  const { adminDataDomain } = useAdmin();
  const { schoolsBySelectedDistrictId, searchCriteria, selectedTeacherId } = adminDataDomain.domainData;

  const handleOnSchoolsLoaded = (schools) => {
    adminDataDomain.updateSchoolsBySelectedDistrictId(schools);
  };

  const onFilterSchoolChange = async (schools) => {
    const updatedSearchCriteria = {
      ...searchCriteria,
      schoolIds: schools && schools.length > 0 ? schools.map((s) => s.key) : [],
    };
    await adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
    getTeacherListData();
    getActivityReportsData();
  };

  const shouldSearchSchoolDisabled = selectedTeacherId !== null;

  return {
    schoolsData: schoolsBySelectedDistrictId,
    selectedSchoolIds: searchCriteria.schoolIds,
    handleOnSchoolsLoaded,
    onFilterSchoolChange,
    shouldSearchSchoolDisabled,
  };
};

export const useHandleLicenseType = ({
  getTeacherListData,
  getActivityReportsData,
}) => {
  const { adminDataDomain } = useAdmin();
  const { searchCriteria, selectedTeacherId } = adminDataDomain.domainData;
  const { licenseTypeIds } = searchCriteria;
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { licenseTypes } = userProfile;

  const handleOnLicensesChange = async (licenses) => {
    const selectedLicenseIds = licenses.map((d) => d.key);
    const updatedSearchCriteria = {
      ...searchCriteria,
      licenseTypeIds: selectedLicenseIds,
    };
    await adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
    getTeacherListData();
    getActivityReportsData();
  };

  const licensesTypeOptions = licenseTypes.map((l) => ({
    key: l,
    name: l,
    value: l,
  }));

  const shouldLicenseTypeDisabled = selectedTeacherId !== null;

  return {
    licenseTypeIds,
    handleOnLicensesChange,
    licensesTypeOptions,
    shouldLicenseTypeDisabled,
  };
};

export const yesterdayDate = DateUtils.getYesterdayDate();

export const useHandleDateSelector = () => {
  const { adminDataDomain } = useAdmin();
  const { selectedDates } = adminDataDomain.domainData;
  const [internalSelectedDates, setInternalSelectedDates] = useState({
    dates: [selectedDates.startDate, selectedDates.endDate],
  });

  const handleOnSelectedDatesChange = (selectedDatesData) => {
    setInternalSelectedDates(selectedDatesData);
  };

  const handleOnSelectedDateApply = async () => {
    const updatedSelectedDates = {
      ...selectedDates,
    };

    if (!internalSelectedDates || !internalSelectedDates.dates || internalSelectedDates.length === 0) {
      updatedSelectedDates.startDate = null;
      updatedSelectedDates.endDate = null;
    }

    const { dates } = internalSelectedDates;
    if (dates.length === 1) {
      const [startDate] = dates;
      updatedSelectedDates.startDate = startDate;
      updatedSelectedDates.endDate = startDate;
    }

    if (dates.length === 2) {
      const [startDate, endDate] = dates;
      updatedSelectedDates.startDate = startDate;
      updatedSelectedDates.endDate = endDate;
    }

    await adminDataDomain.updatSelectedDates(updatedSelectedDates);
  };

  return {
    internalSelectedDates,
    handleOnSelectedDatesChange,
    handleOnSelectedDateApply,
  };
};

import { Checkbox, FormControlLabel } from '@mui/material';
import { SELECTED_ALL_ID, useHandleActivitySelector, useHandleTeacherSelectorTable, usePrepareTeacherList, useTeacherListTable } from './TeacherSelectorHooks';
import './TeacherSelector.scss';
import '../../../components/TableSelector.scss';
import SearchAndSortableTable from '../../../../../components/table/SearchAndSortableTable';
import { useGetActivityReports, useGetTeacherLabel, useGetTeacherList } from '../AdminDataHooks';

const TeacherSelector = () => {
  const {
    loading,
    getTeacherListData,
  } = useGetTeacherList();

  const {
    getActivityReportsData,
  } = useGetActivityReports();

  usePrepareTeacherList({ getTeacherListData, getActivityReportsData });

  const {
    searchCriteria,
    handleOnChange,
    shouldAcivitySelectorDisabled,
  } = useHandleActivitySelector({ getTeacherListData, getActivityReportsData });

  const {
    tableConfig,
    tableData,
  } = useTeacherListTable();

  const {
    selectedRowIds,
    handleOnSelectedRowIds,
  } = useHandleTeacherSelectorTable({ getActivityReportsData });

  const {
    getTeacherLabel,
  } = useGetTeacherLabel();

  return (
    <div className='teacher-selector'>
      <FormControlLabel
        disabled={shouldAcivitySelectorDisabled}
        control={
          (
            <Checkbox
              checked={searchCriteria && searchCriteria.teachersLoggingIn ? searchCriteria.teachersLoggingIn : false}
              onChange={handleOnChange}
              name='teachersLoggingIn'
            />
          )
        }
        label='Teachers Logging in'
      />

      <FormControlLabel
        disabled={shouldAcivitySelectorDisabled}
        control={
          (
            <Checkbox
              checked={searchCriteria && searchCriteria.teachersWithoutLogins ? searchCriteria.teachersWithoutLogins : false}
              onChange={handleOnChange}
              name='teachersWithoutLogins'
            />
          )
        }
        label='Teachers without Logins'
      />

      <SearchAndSortableTable
        containerClassName='table-selector'
        className='selector-table'
        loading={loading}
        config={tableConfig}
        tableData={tableData}
        searchPlaceholder='SEARCH YOUR TEACHERS'
        labelRowsPerPage='Rows'
        searchFunction={(keyword, data) => {
          const isNameMatched = data.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          const isEmailMatched = data.attributes.userName.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          return isNameMatched || isEmailMatched;
        }}
        placeHolderData={{
          name: <strong>{getTeacherLabel().toUpperCase()}</strong>,
          id: SELECTED_ALL_ID,
          isAllTeacher: true,
        }}
        rowsPerPageOptions={[25, 50, 100, 150]}
        noResultMessage='No result founds.'
        dataId='id'
        selectedRowIds={selectedRowIds}
        onSelectedRowIds={handleOnSelectedRowIds}
      />
    </div>
  );
};

export default TeacherSelector;

import BaseDomain from '../../../states/domains/BaseDomain';

const LESSON_PLAY_UPDATE_LESSON_PAGE = 'LESSON_PLAY_UPDATE_LESSON_PAGE';
const LESSON_PLAY_UPDATE_CURRENT_PAGE_INDEX = 'LESSON_PLAY_UPDATE_CURRENT_PAGE_INDEX';
const LESSON_PLAY_UPDATE_IS_MEDIA_PLAYING_FLAG = 'LESSON_PLAY_UPDATE_IS_MEDIA_PLAYING_FLAG';
const LESSON_PLAY_UPDATE_USER_PROFILE = 'LESSON_PLAY_UPDATE_USER_PROFILE';
const LESSON_PLAY_UPDATE_LESSON_PAGE_DATA = 'LESSON_PLAY_UPDATE_LESSON_PAGE_DATA';
const LESSON_PLAY_UPDATE_LESSON = 'LESSON_PLAY_UPDATE_LESSON';

const initialState = {
  lesson: {},
  currentPageIndex: 0,
  lessonPagesData: [],
  user: {},
  userProfile: {},
  terminologies: {},
  isPreviewMode: false,
  isMediaPlaying: false,
  registeredLesson: {},
  isPlayable: true,
  lessonPagesDataSnapshot: [],
  freeformAnswerData: [],
  initialLessonPlayId: null,
  initialPlayHistoryId: null,
  singleAnswer: false,
  isError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case LESSON_PLAY_UPDATE_LESSON_PAGE:
      return {
        ...state,
        lesson: action.payload.lesson,
        lessonPagesData: action.payload.lessonPagesData,
        user: action.payload.user,
        userProfile: action.payload.userProfile,
        terminologies: action.payload.terminologies,
        isPreviewMode: action.payload.isPreviewMode,
        currentPageIndex: action.payload.currentPageIndex,
        isPlayable: action.payload.isPlayable,
        lessonPagesDataSnapshot: action.payload.lessonPagesDataSnapshot,
        freeformAnswerData: action.payload.freeformAnswerData,
        initialLessonPlayId: action.payload.initialLessonPlayId,
        initialPlayHistoryId: action.payload.initialPlayHistoryId,
        singleAnswer: action.payload.singleAnswer,
        isError: action.payload.isError,
      };
    case LESSON_PLAY_UPDATE_LESSON:
      return {
        ...state,
        lesson: action.payload,
      };
    case LESSON_PLAY_UPDATE_CURRENT_PAGE_INDEX:
      return {
        ...state,
        currentPageIndex: action.payload,
      };
    case LESSON_PLAY_UPDATE_IS_MEDIA_PLAYING_FLAG:
      return {
        ...state,
        isMediaPlaying: action.payload,
      };
    case LESSON_PLAY_UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case LESSON_PLAY_UPDATE_LESSON_PAGE_DATA:
      return {
        ...state,
        lessonPagesData: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'lessonPlayDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateUserProfile(userProfile) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_USER_PROFILE,
      payload: userProfile,
    });
  }

  /**
   * Update lesson page data
   *
   * @returns Promise
   */
  async updateLessonPagesData({
    lesson,
    lessonPagesData,
    user,
    userProfile,
    terminologies,
    isPreviewMode,
    lessonPagesDataSnapshot,
    currentPageIndex,
    isPlayable,
    freeformAnswerData,
    initialLessonPlayId,
    initialPlayHistoryId,
    singleAnswer,
    isError,
  }) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_LESSON_PAGE,
      payload: {
        lesson,
        lessonPagesData,
        user,
        userProfile,
        terminologies,
        isPreviewMode,
        currentPageIndex,
        isPlayable,
        lessonPagesDataSnapshot,
        freeformAnswerData,
        initialLessonPlayId,
        initialPlayHistoryId,
        singleAnswer,
        isError,
      },
    });
  }

  async updateLesson(lesson) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_LESSON,
      payload: lesson,
    });
  }

  async updateLessonPageData({ lessonPagesData }) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_LESSON_PAGE_DATA,
      payload: lessonPagesData,
    });
  }

  async updateCurrentPageIndex(currentPageIndex) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_CURRENT_PAGE_INDEX,
      payload: currentPageIndex,
    });
  }

  async updateIsMediaPlaying(isMediaPlaying) {
    return this.dispatch({
      type: LESSON_PLAY_UPDATE_IS_MEDIA_PLAYING_FLAG,
      payload: isMediaPlaying,
    });
  }
}

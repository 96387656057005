import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import './FillInTheBlankEditorWithTextStyle.scss';
import TextAndMediaFormatting from '../TextAndMediaFormatting';

const FillInTheBlankEditorWithTextStyle = ({
  preBlank,
  postBlank,
  answers,
  onPreBlankChange,
  onPostBlankChange,
  onAnswerAdd,
  onAnswerDelete,
}) => {
  const [answerText, setAnswerText] = useState('');

  const handleOnAnswerTextChange = (e) => {
    e.stopPropagation();
    setAnswerText(e.target.value.substring(0, 255));
  };

  const handleOnAnswerAdd = (e) => {
    e.preventDefault();
    onAnswerAdd(answerText);
    setAnswerText('');
  };

  const handleOnAnswerDelete = (chipToDelete) => () => {
    onAnswerDelete(chipToDelete);
  };

  return (
    <div className='fill-in-the-blank-editor-with-text-tyle'>
      <TextAndMediaFormatting
        text={preBlank}
        onTextApply={onPreBlankChange}
        onTextRemoved={() => { onPreBlankChange(null); }}
        showActionButtons={false}
      />
      <Paper className='answer-area'>
        <Typography variant='subtitle1'>
          Answers
        </Typography>
        <form onSubmit={handleOnAnswerAdd} className='add-answer'>
          <div className='text-field'>
            <TextField
              fullWidth
              value={answerText}
              onChange={handleOnAnswerTextChange}
            />
          </div>
          <Button
            color='primary'
            type='submit'
            className='btn-primary'
            disabled={!answerText}
          >
            ADD
          </Button>
        </form>

        {!answers || answers.length === 0 ? (
          <div className=''>
            (If no answers are added then all answers are correct)
          </div>
        ) : ''}

        <div className='answers'>
          {answers.map((data, index) => {
            let icon;
            return (
              <Chip
                className='chip'
                key={index}
                icon={icon}
                label={data}
                onDelete={handleOnAnswerDelete(data)}
              />
            );
          })}
        </div>
      </Paper>
      <TextAndMediaFormatting
        text={postBlank}
        onTextApply={onPostBlankChange}
        onTextRemoved={() => { onPostBlankChange(null); }}
        showActionButtons={false}
      />
    </div>
  );
};

FillInTheBlankEditorWithTextStyle.defaultProps = {
  preBlank: undefined,
  postBlank: undefined,
  answers: [],
  onPreBlankChange: () => { },
  onPostBlankChange: () => { },
  onAnswerAdd: () => { },
  onAnswerDelete: () => { },
};

FillInTheBlankEditorWithTextStyle.propTypes = {
  preBlank: PropTypes.any,
  postBlank: PropTypes.any,
  answers: PropTypes.arrayOf(PropTypes.string),
  onPreBlankChange: PropTypes.func,
  onPostBlankChange: PropTypes.func,
  onAnswerAdd: PropTypes.func,
  onAnswerDelete: PropTypes.func,
};

export default FillInTheBlankEditorWithTextStyle;

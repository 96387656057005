import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Fade,
  Typography,
} from '@mui/material';
import PacmanLoader from 'react-spinners/PacmanLoader';
import VizzleLogo from '../../vizzle_NoTag.svg';
import { loginWithOauth, loginWithVizzleToken } from '../../services/LoginService';
import Logger from '../../utils/Logger';

/**
 * Login with vizzle token container
 *
 * @param {*} props passed in from parent component
 */
const loadingMessage = 'Loading, please wait';
const errorMessage = `An error occurs, cannot login to ${process.env.REACT_APP_RETHINK_REDIRECT_URI}`;

export default function LoginForRethink() {
  const [showError, setShowError] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const handleLoginFailed = () => {
      Logger.logError('login with token failed: redirect to login');
      setShowError(true);
    };

    const login = async () => {
      const vizzleToken = query.get('vizzle-token');
      Logger.logWhenDebugModeIsOn({
        VIZZLE_TOKEN: vizzleToken,
      });
      try {
        const result = await loginWithVizzleToken(vizzleToken);
        if (result.success) {
          const url = await loginWithOauth(process.env.REACT_APP_RETHINK_CLIENT_ID, process.env.REACT_APP_RETHINK_REDIRECT_URI);
          window.location = url;
        } else {
          handleLoginFailed();
        }
      } catch (error) {
        Logger.logError(error);
        handleLoginFailed();
      }
    };

    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' flexDirection='column' width='100vw' height='100vh'>
      <Box mx='auto' mt='120px'>
        <img src={VizzleLogo} alt='Vizzle Logo' width='250px' />
      </Box>
      {showError ? (
        <Fade timeout={1500} in>
          <Box display='flex' flexDirection='column' mt='80px' mx='auto'>
            <Typography variant='h5' gutterBottom>
              {errorMessage}
            </Typography>
          </Box>
        </Fade>
      ) : (
        <Box mt='50px' mx='auto'>
          <Box width='100px' height='100px' mx='auto'>
            <PacmanLoader size={50} color='#3b94d1' css={{ margin: 'auto' }} />
          </Box>
          <Box mt='20px'>
            <Typography variant='h5' gutterBottom>
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

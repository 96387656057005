import { useState, useEffect } from 'react';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { getListByTypeStudent } from '../../../../services/StudentService';
import { useDomain } from '../../../../states/AppDomainProvider';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';

const sortFullname = (a, b) => {
  const fullNameA = `${a.attributes.firstName}${a.attributes.lastName}`;
  const fullNameB = `${b.attributes.firstName}${b.attributes.lastName}`;
  return fullNameA.localeCompare(fullNameB);
};

export const usePrepareData = () => {
  const { userDomain, uiDomain } = useDomain();
  const [listStudents, setListStudents] = useState(null);
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { user } = userDomain.domainData;

  const getAllByTypeStudent = async () => {
    if (user.shareStudents) {
      uiDomain.showLoader('Loading Students', LOADER_TYPE.HASH_LOADER);
      await callRemoteServiceWrapper(async () => {
        const response = await getListByTypeStudent();
        const responseSorted = response.data.sort((a, b) => sortFullname(a, b));
        setListStudents(responseSorted);
        uiDomain.hideLoader();
      });
    } else {
      setListStudents([]);
    }
  };

  useEffect(() => {
    getAllByTypeStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    listStudents,
    getAllByTypeStudent,
  };
};

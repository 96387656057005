import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import CauseAndEffectActivityCard from './components/CauseAndEffectActivityCard';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import CauseAndEffectActivityPrompt from './components/CauseAndEffectActivityPrompt';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import {
  useInitializeData,
  useGenerateComponentIds,
  usePrepareData,
  useDragAndDropSubmitAnswer,
  useGenerateRejectedAnswerRefs,
  getSelectedIndexCards,
  useClickSubmitAnswer,
  useSwitchAccessSubmitAnswer,
} from './CauseAndEffectActivityHooks';

import '../activities-common.scss';
import './CauseAndEffectActivity.scss';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

const CauseAndEffectActivity = forwardRef(({
  causeAndEffectCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onTriggerSwitchScan,
}, ref) => {
  const {
    id,
    droppableId,
  } = useGenerateComponentIds();

  const {
    causeAndEffectActivityRef,
    dragAndDropApiRef,
    currentSelectedEffectIndex,
  } = useInitializeData();

  const {
    numberOfSets,
  } = usePrepareData({
    causeAndEffectCards,
  });

  const {
    rejectedCausePromptRefs,
    setRejectedCausePromptRefs,
    rejectedEffectPromptRefs,
    setRejectedEffectPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  } = useGenerateRejectedAnswerRefs();

  const {
    selectedCauseCards,
    selectedEffectCards,
    handleOnDragStart,
    handleOnDragEnd,
  } = useDragAndDropSubmitAnswer({
    causeAndEffectActivityRef,
    causeAndEffectCards,
    rejectedCausePromptRefs,
    rejectedEffectPromptRefs,
    rejectedAnswerResponseRefs,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
    currentSelectedEffectIndex,
  });

  const {
    handleOnCauseCardClick,
    handleOnEffectCardClick,
  } = useClickSubmitAnswer({
    causeAndEffectActivityRef,
    dragAndDropApiRef,
    currentSelectedEffectIndex,
  });

  const {
    handleResponseSwitchAccessSelect,
  } = useSwitchAccessSubmitAnswer({
    onTriggerSwitchScan,
    handleOnCauseCardClick,
    handleOnEffectCardClick,
  });

  const causeAndEffectResponseCardRefs = [];
  const setCauseAndEffectResponseCardRefs = (r, index) => {
    if (r) {
      causeAndEffectResponseCardRefs[index] = r;
    }
  };

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      const remainingResponse = causeAndEffectResponseCardRefs.map((s) => s.getEnableElements()).filter((s) => s !== undefined);
      if (!currentSelectedEffectIndex.current) {
        return [
          ...remainingResponse,
        ];
      } else {
        const remainingPromptList = Array.prototype.slice.call(causeAndEffectActivityRef.current.querySelectorAll('[data-cause-effect-set-index]'));
        return [
          ...remainingPromptList,
          ...remainingResponse,
        ];
      }
    },
  }));

  const getCauseAndActivitySet = () => {
    const prompts = [];
    for (let i = 0; i < numberOfSets; i++) {
      const causeDrappableKeyId = `cause-component-droppable-container-${id}-${i}`;
      const effectDrappableKeyId = `effect-component-droppable-container-${id}-${i}`;

      const isCauseCardSelected = selectedCauseCards[i] >= 0;
      const isEffectCardSelected = selectedEffectCards[i] >= 0;

      prompts.push(
        <CauseAndEffectActivityPrompt
          key={`cause-and-effect-prompt-set-${id}-${i}`}
          causeComponent={
            (
              isCauseCardSelected ? (
                <CauseAndEffectActivityCard
                  card={causeAndEffectCards[selectedCauseCards[i]]}
                  disablePlayMedia
                >
                  <SubmittedAnswerIndicator correctSubmission />
                </CauseAndEffectActivityCard>
              ) : (
                <Droppable
                  key={causeDrappableKeyId}
                  droppableId={causeDrappableKeyId}
                  direction='horizontal'
                  isDropDisabled={isCauseCardSelected}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`prompt-container
                        ${snapshot.isDraggingOver ? 'cause-and-effect-selected-component' : 'non-drag-items'}
                    `}
                      id={causeDrappableKeyId}
                      data-is-cause-card='true'
                      data-cause-effect-set-index={i}
                      onClick={(e) => {
                        handleOnCauseCardClick(e, causeDrappableKeyId);
                      }}
                    >
                      <RejectedAnswerResponse ref={(r) => { setRejectedCausePromptRefs(r, i); }} showIcon={false} />
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              )
            )
          }
          effectComponent={
            (
              isEffectCardSelected ? (
                <CauseAndEffectActivityCard
                  card={causeAndEffectCards[selectedEffectCards[i]]}
                  disablePlayMedia
                >
                  <SubmittedAnswerIndicator correctSubmission />
                </CauseAndEffectActivityCard>
              ) : (
                <Droppable
                  key={effectDrappableKeyId}
                  droppableId={effectDrappableKeyId}
                  direction='horizontal'
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`prompt-container
                          ${snapshot.isDraggingOver ? 'cause-and-effect-selected-component' : 'non-drag-items'}
                `}
                      id={effectDrappableKeyId}
                      data-is-cause-card='false'
                      data-cause-effect-set-index={i}
                      onClick={(e) => {
                        handleOnCauseCardClick(e, effectDrappableKeyId);
                      }}
                    >
                      <RejectedAnswerResponse ref={(r) => { setRejectedEffectPromptRefs(r, i); }} showIcon={false} />
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              ))

          }
        />,
      );
    }
    return prompts;
  };

  const selectedCardIndexes = getSelectedIndexCards(selectedCauseCards, selectedEffectCards);

  return (
    <DragDropContext
      onDragStart={handleOnDragStart}
      onDragEnd={handleOnDragEnd}
      sensors={[(sensorApi) => {
        dragAndDropApiRef.current = sensorApi;
      }]}
    >
      <div className='cause-and-effect-activity' ref={causeAndEffectActivityRef}>
        <Droppable
          droppableId={droppableId}
          direction='horizontal'
          isDropDisabled
          isCombineEnabled
        >
          {(causeAndEffectProvided) => (
            <div
              ref={causeAndEffectProvided.innerRef}
              {...causeAndEffectProvided.droppableProps}
              className='cause-and-effect-prompt-draggable'
            >
              <div className='cause-and-effect-area-container'>
                <div className={`cause-and-effect-area cause-length-${numberOfSets}`}>
                  {
                    getCauseAndActivitySet()
                  }
                </div>
              </div>
              <div className='cards-area-container'>
                <div className='cards'>
                  {
                    causeAndEffectCards.map((card, index) => {
                      const cardId = `${id}-${index}`;
                      const keyId = `effect-card-key-${cardId}`;
                      const effectCardId = `effect-card-${cardId}`;
                      const draggableId = `effect-card-drag-item-${cardId}`;
                      const isCardSelected = selectedCardIndexes.includes(index);
                      return (
                        <div key={keyId} className={`cause-and-effect-card-place-holder item-placeholder card-margin-${causeAndEffectCards.length}-cards`}>
                          {!isCardSelected && (
                            <Draggable
                              key={draggableId}
                              draggableId={draggableId}
                              index={index}
                              shouldRespectForcePress={false}
                              timeForLongPress={0}
                            >
                              {(providedItem, snapshotItem) => (
                                <div
                                  ref={providedItem.innerRef}
                                  {...providedItem.draggableProps}
                                  {...providedItem.dragHandleProps}
                                  id={`cause-and-effect-item-${cardId}`}
                                  key={`cause-and-effect-item-${cardId}`}
                                  className={`
                                  cause-and-effect-response-card-container
                                  ${snapshotItem.isDragging ? 'cause-and-effect-selected-component' : 'non-drag-items'}
                                  `}
                                  style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                                >
                                  <CauseAndEffectActivityCard
                                    card={card}
                                    cardId={effectCardId}
                                    onClick={(e, isTtsAbleToPlay) => {
                                      handleOnEffectCardClick(e, isTtsAbleToPlay, card, effectCardId, draggableId);
                                    }}
                                    oSwitchAccessSelect={(e, isTtsAbleToPlay) => {
                                      handleResponseSwitchAccessSelect(e, isTtsAbleToPlay, card, effectCardId, draggableId);
                                    }}
                                    ref={(r) => { setCauseAndEffectResponseCardRefs(r, index); }}
                                  />
                                  <RejectedAnswerResponse ref={(r) => { setRejectedAnswerResponseRefs(r, index); }} />
                                </div>
                              )}

                            </Draggable>
                          )}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              {causeAndEffectProvided.placeholder}
            </div>
          )}

        </Droppable>
      </div>
    </DragDropContext>
  );
});

export default CauseAndEffectActivity;

CauseAndEffectActivity.defaultProps = {
  causeAndEffectCards: [],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  onTriggerSwitchScan: () => { },
};

CauseAndEffectActivity.propTypes = {
  causeAndEffectCards: PropTypes.arrayOf(PropTypes.any),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
};

/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import './FillInTheBlankPageActivity.scss';
import FillInTheBlankPageComponent from './FillInTheBlankPageComponent';

/**
 * Lesson activity for Book
 */
const FillInTheBlankPageActivity = ({
  cardConfig,
  className,
}) => {
  const componentId = useMemo(() => uuidv1(), []);
  return (
    <div
      key={componentId}
      className={
        `fitb-page-activity
      vizzle-fitb-page-activity
      ${className}
      ${cardConfig.length === 1 ? 'fitb-card-activity-small' : 'fitb-card-activity-big'}`
      }
    >
      {
        cardConfig.map((c, index) => (
          <FillInTheBlankPageComponent
            key={`fitbcard-activity${componentId}-${index}`}
            cardConfig={c}
          />
        ))
      }
    </div>
  );
};

export default FillInTheBlankPageActivity;

FillInTheBlankPageActivity.defaultProps = {
  cardConfig: [{
    card: {
      audio: null,
      image: null,
      video: null,
    },
    fitbList: [],
  }],
  className: '',
};

FillInTheBlankPageActivity.propTypes = {
  cardConfig: PropTypes.arrayOf(
    PropTypes.shape({
      card: PropTypes.shape({
        audio: PropTypes.string,
        image: PropTypes.string,
        video: PropTypes.string,
      }),
      fitbList: PropTypes.arrayOf(PropTypes.shape({
        answers: PropTypes.array,
        question: PropTypes.shape({
          question: PropTypes.string,
        }),
      })),
    }),
  ),
  className: PropTypes.string,
};

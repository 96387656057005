import PropTypes from 'prop-types';
import GridOnIcon from '@mui/icons-material/GridOn';
import {
  Button,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import './CsvTableExport.scss';

/**
 * A component for blocking user interaction on the screen
 */
export default function CsvTableExport({
  config,
  tableData,
  linkComponent,
  className,
  ...props
}) {
  const headers = config.map((c) => ({
    label: c.title,
    key: c.dataField,
  }));

  const data = tableData.map((t) => {
    const object = {};
    config.forEach((c) => {
      const { dataRenderer, skipDataRenderForReport } = c;
      object[c.dataField] = dataRenderer && !skipDataRenderForReport ? dataRenderer(t) : String(t[c.dataField]);
    });

    return object;
  });

  return (
    <CSVLink
      {...props}
      headers={headers}
      data={data}
      className={`csv-table-export ${className}`}
      data-test='csv-export'
    >
      {linkComponent}
    </CSVLink>
  );
}

CsvTableExport.defaultProps = {
  config: [],
  tableData: [],
  className: '',
  linkComponent: <Button className='btn-primary' startIcon={<GridOnIcon />} variant='contained'>CSV</Button>,
};

CsvTableExport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    dataField: PropTypes.string,
  })),
  className: PropTypes.string,
  linkComponent: PropTypes.node,
};

import { forwardRef, useImperativeHandle, useState } from 'react';
import SchoolIcon from '@mui/icons-material/School';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import GenericButton from '../../../components/buttons/GenericButton';
import { useGetAdminDistrictData, usePrepareData, useUpdateAdminSchoolAccess } from './AdminActionHooks';
import DistrictsForm from './forms/DistrictsForm';
import './DistrictsAction.scss';
import { isArrayEqual } from '../../../../../utils/ArrayUtils';

const DistrictsAction = forwardRef((_props, ref) => {
  const [open, setOpen] = useState(false);

  const handleOnCancel = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openUpdateDistrict: () => {
      setOpen(true);
    },
  }));

  const {
    adminDistrictSelectedDistrictIdsSchoolIds,
    setAdminDistrictSelectedDistrictIdsSchoolIds,
    adminDistrictSelectedSchoolLoading,
    originalAdminDistrictSelectedSchoolIds,
  } = useGetAdminDistrictData({
    open,
  });

  const {
    loading,
    updateSchoolAccess,
  } = useUpdateAdminSchoolAccess({
    setOpen,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: () => {
        updateSchoolAccess(adminDistrictSelectedDistrictIdsSchoolIds.schoolIds, originalAdminDistrictSelectedSchoolIds.current);
      },
      className: 'btn-primary action-button',
      title: 'Save',
      loading,
      disabled: isArrayEqual(originalAdminDistrictSelectedSchoolIds.current, adminDistrictSelectedDistrictIdsSchoolIds.schoolIds),
    },
    ];
    return buttons;
  };

  const {
    selectedAdminIds,
    selectedAdminsData,
  } = usePrepareData();

  let labelTitle = 'Update Districts';

  if (selectedAdminsData.length === 1) {
    const selectedAdmin = selectedAdminsData[0];
    const { attributes: {
      firstName,
      lastName,
    } } = selectedAdmin;
    labelTitle = `Update Districts for ${firstName} ${lastName}`;
  } else if (selectedAdminsData.length > 1) {
    labelTitle = `Update Districts for ${selectedAdminsData.length} users`;
  }

  return (
    <>
      <GenericButton
        ariaLabel={labelTitle}
        onClick={() => { setOpen(true); }}
        disabled={!selectedAdminIds || selectedAdminIds.length <= 0}
        icon={SchoolIcon}
        label='Districts'
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='admin-districts-select-school-action-dialog'
          onClose={handleOnCancel}
          title={labelTitle}
          content={(
            <DistrictsForm
              loading={adminDistrictSelectedSchoolLoading}
              formData={{
                selectedDistrictIds: adminDistrictSelectedDistrictIdsSchoolIds.districtIds,
                selectedSchoolIds: adminDistrictSelectedDistrictIdsSchoolIds.schoolIds,
              }}
              onDataChange={(updatedData) => {
                setAdminDistrictSelectedDistrictIdsSchoolIds({
                  districtIds: updatedData.selectedDistrictIds,
                  schoolIds: updatedData.selectedSchoolIds,
                });
              }}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
});

export default DistrictsAction;

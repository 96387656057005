import BaseDomain from '../../../../states/domains/BaseDomain';

const TEACHER_CURRICULUM_UPDATE_DATA = 'TEACHER_CURRICULUM_UPDATE_DATA';
const TEACHER_CURRICULUM_UPDATE_FILTERED_LESSONS = 'TEACHER_CURRICULUM_UPDATE_FILTERED_LESSONS';
const TEACHER_CURRICULUM_UPDATE_LESSONS = 'TEACHER_CURRICULUM_UPDATE_LESSONS';
const TEACHER_CURRICULUM_SELECTED_LESSON_IDS = 'TEACHER_CURRICULUM_SELECTED_LESSON_IDS';
const TEACHER_CURRICULUM_COURSE_LESSON_COURSES = 'TEACHER_CURRICULUM_COURSE_LESSON_COURSES';
const TEACHER_CURRICULUM_UPDATE_GUIDE_LESSONS = 'TEACHER_CURRICULUM_UPDATE_GUIDE_LESSONS';

const initialState = {
  hasPerformSearched: false,
  searchCriteria: {},
  guidePdfLink: null,
  scopeAndSequenceLink: null,
  lessonPlans: null,
  filterCriteria: {},
  lessonData: {
    lessons: [],
    included: [],
    meta: {},
  },
  selectedLessonIds: [],
  lessonCourses: null,
  guideLessonData: {
    lessons: [],
    included: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case TEACHER_CURRICULUM_UPDATE_DATA:
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
        hasPerformSearched: action.payload.hasPerformSearched,
        ...action.payload.searchResult,
      };

    case TEACHER_CURRICULUM_UPDATE_FILTERED_LESSONS:
      return {
        ...state,
        filterCriteria: action.payload.filterCriteria,
        lessonData: action.payload.lessonData,
        selectedLessonIds: action.payload.selectedLessonIds,
      };
    case TEACHER_CURRICULUM_UPDATE_LESSONS:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          lessons: action.payload.lessons,
          included: action.payload.included,
        },
      };
    case TEACHER_CURRICULUM_SELECTED_LESSON_IDS:
      return {
        ...state,
        selectedLessonIds: action.payload,
      };
    case TEACHER_CURRICULUM_COURSE_LESSON_COURSES:
      return {
        ...state,
        lessonCourses: action.payload,
      };
    case TEACHER_CURRICULUM_UPDATE_GUIDE_LESSONS:
      return {
        ...state,
        guideLessonData: {
          ...state.guideLessonData,
          lessons: action.payload.lessons,
          included: action.payload.included,
        },
      };
    default:
      return state;
  }
};

const domainName = 'teacherCurriculumDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update teacher curriculum data
   *
   * @returns Promise
   */
  async updateCurriculumData(
    searchCriteria,
    hasPerformSearched,
    {
      guidePdfLink,
      scopeAndSequenceLink,
      lessonPlans,
      lessonData,
      filterCriteria,
      selectedLessonIds,
      guideLessonData,
    },
  ) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_UPDATE_DATA,
      payload: {
        hasPerformSearched,
        searchCriteria,
        searchResult: {
          guidePdfLink,
          scopeAndSequenceLink,
          lessonPlans,
          lessonData,
          filterCriteria,
          selectedLessonIds,
          guideLessonData,
        },
      },
    });
  }

  async updateFilteredLessonData(filterCriteria, lessonData, selectedLessonIds) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_UPDATE_FILTERED_LESSONS,
      payload: {
        filterCriteria,
        lessonData,
        selectedLessonIds,
      },
    });
  }

  async updateLessonData(lessons, included) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_UPDATE_LESSONS,
      payload: {
        lessons,
        included,
      },
    });
  }

  async updateSelectedLessonIds(selectedLessonIds) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_SELECTED_LESSON_IDS,
      payload: selectedLessonIds,
    });
  }

  async updateLessonCourses(courseData) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_COURSE_LESSON_COURSES,
      payload: courseData,
    });
  }

  async updateGuideLessonData(lessons, included) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_UPDATE_GUIDE_LESSONS,
      payload: {
        lessons,
        included,
      },
    });
  }
}

import { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { getUserIdByVerificationToken, verifyUser } from '../../../services/LoginService';
import { useDomain } from '../../../states/AppDomainProvider';
import Logger from '../../../utils/Logger';
import ObjectUtils from '../../../utils/ObjectUtils';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';

export const STATE = {
  VERIFY_FORM: 0,
  VERIFY_SUCCESS: 1,
};

const ERROR_MESSAGE = {};
ERROR_MESSAGE.RESET_EXPIRE = 'Reset code has expired';
ERROR_MESSAGE.INVALID_PASSWORD = 'Password cannot be less than 4 characters';
ERROR_MESSAGE.INVALID_TOKEN = 'Token is invalid';

export const useControlVerifyUser = ({
  user,
}) => {
  const [state, setState] = useState({
    slide: {
      in: true,
      direction: 'left',
    },
    form: STATE.VERIFY_FORM,
  });
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnVerifyUser = async (value) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await verifyUser(value, user.data.id);
        setState({
          ...state,
          slide: {
            in: false,
            direction: 'right',
          },
        });
        ObjectUtils.setTimeout(() => {
          setState({
            ...state,
            slide: {
              in: true,
              direction: 'left',
            },
            form: STATE.VERIFY_SUCCESS,
          });
        }, 1000);
      });
    } catch (e) {
      Logger.logError(e);
    }
  };
  return {
    state,
    loading,
    handleOnVerifyUser,
  };
};

export const useValidateVerificationCode = () => {
  const { verfyCode } = useParams();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const verifyToken = async () => {
    await callRemoteServiceWrapper(async () => {
      try {
        const result = await getUserIdByVerificationToken(verfyCode);
        setUser(result);
      } catch (e) {
        setError(e.response.data);
        Logger.logError(e);
      }
    });
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    user,
    error,
  };
};

export const useHandleSnackbar = () => {
  const { uiDomain } = useDomain();
  const {
    snackbar,
  } = uiDomain.domainData;

  const onSnackbarClose = () => {
    uiDomain.closeSnackbar();
  };

  return {
    snackbar,
    onSnackbarClose,
  };
};

import { stopAudio } from '../../../../components/vizzle/media/AudioPlayer';
import { cancelTts } from '../../../../services/tts/TextToSpeechServiceV2';
import TweenFunctions from '../../../../utils/TweenFunctions';

const CLASS_SWITCH_SELECTED_COMPONENT = 'switch-select';

const moveStepByStep = (drag, values, resolve) => {
  requestAnimationFrame(() => {
    const newPosition = values.shift();
    drag.move(newPosition);
    if (values.length) {
      moveStepByStep(drag, values, resolve);
    } else {
      drag.drop();
      resolve();
    }
  });
};

const stopVideos = () => {
  // Stop video and audio when changing the card.
  const videoList = document.getElementsByTagName('video');
  [].forEach.call(videoList, (vl) => {
    vl.pause();
    // eslint-disable-next-line no-param-reassign
    vl.currentTime = 0;
  });
};

const removeSelectedElementClass = () => {
  const currentElement = document.querySelectorAll(`.${CLASS_SWITCH_SELECTED_COMPONENT}`);
  if (currentElement && currentElement.length > 0) {
    currentElement.forEach((e) => {
      e.classList.remove(CLASS_SWITCH_SELECTED_COMPONENT);
    });
  }
};

export default {

  drapAndDropAnimation: async (dragAndDropApi, elementIdToMove, draggableId, elementIdDestination) => new Promise((resolve) => {
    const preDrag = dragAndDropApi.tryGetLock(draggableId);
    if (!preDrag) {
      return;
    }

    const positionPrompt = document.getElementById(elementIdDestination).getBoundingClientRect();
    const positionResponse = document.getElementById(elementIdToMove).getBoundingClientRect();

    const start = { x: 0, y: 0 };
    const end = { x: positionPrompt.left - positionResponse.left, y: positionPrompt.top - positionResponse.top };
    const drag = preDrag.fluidLift(start);

    const points = [];
    for (let i = 0; i < 23; i++) {
      points.push({
        x: TweenFunctions.linear(i, start.x, end.x, 21),
        y: TweenFunctions.linear(i, start.y, end.y, 21),
      });
    }
    moveStepByStep(drag, points, resolve);
  }),

  disableComponent: (componentRef, disable) => {
    if (!componentRef || !componentRef.current) {
      return;
    }
    if (disable) {
      componentRef.current.classList.add('component-disabled');
    } else {
      componentRef.current.classList.remove('component-disabled');
    }
  },

  stopVideos: () => {
    stopVideos();
  },

  stopMedias: () => {
    cancelTts();

    try {
      stopAudio();
      // eslint-disable-next-line no-empty
    } catch (_e) { }

    const audioList = (document.getElementsByTagName('audio'));
    [].forEach.call(audioList, (a) => {
      a.pause();
      // eslint-disable-next-line no-param-reassign
      a.currentTime = 0;
    });

    const videoDialog = document.querySelector('.video-player-dialog');
    if (videoDialog) {
      const backdrop = videoDialog.querySelector('.MuiBackdrop-root');
      if (backdrop) {
        backdrop.click();
      }
    }

    // Stop video and audio when changing the card.
    stopVideos();

    const elList = document.getElementsByClassName('media-player-playing');
    if (elList.length > 0) {
      [].forEach.call(elList, (el) => {
        el.classList.remove('media-player-playing');
      });
    }

    const elIconList = document.getElementsByClassName('media-player-playing-icon');
    if (elIconList.length > 0) {
      [].forEach.call(elIconList, (el) => {
        el.classList.remove('media-player-playing-icon');
      });
    }
  },

  getDropStyle: (style, snapshot) => {
    if (snapshot.isDropAnimating && snapshot.dropAnimation.moveTo.y === 0) {
      // eslint-disable-next-line no-param-reassign
      style.transform = 'translate(0.01px, 0px)';
    }
    return style;
  },

  removeSelectedElementClass: () => {
    removeSelectedElementClass();
  },

  addClassToTheSelectedElement: (element) => {
    removeSelectedElementClass();
    element.classList.add(CLASS_SWITCH_SELECTED_COMPONENT);
  },

  resizeDragCardWhenDragging: (before, promptElementId, resizedWidth) => {
    const dragElement = document.getElementById(before.draggableId);
    if (dragElement) {
      const promptSample = document.getElementById(promptElementId);
      if (promptSample) {
        const promptHeight = promptSample.offsetHeight;
        const dragElementHeight = dragElement.offsetHeight;
        if (dragElementHeight > promptHeight) {
          dragElement.style.height = `${promptHeight}px`;
        }
        if (resizedWidth) {
          dragElement.style.width = resizedWidth;
        }
      }
    }
  },
};

import { useState } from 'react';
import VerticalStepper from '../../../../components/steppers/VerticalStepper';
import {
  useGetTabs,
} from '../../common/services/TeacherCurriculumComponentService';
import './TeacherCurriculumMainTab.scss';

const TeacherCurriculumMainTab = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const {
    steps,
  } = useGetTabs();

  const stepperProps = {
    steps,
    activeStep,
    handleStep,
    nonLinear: true,
    className: 'teacher-curriculum-stepper',
    stepperClassName: 'side-menu',
  };

  return (
    <div className='teacher-curriculum-stepper-container'>
      <VerticalStepper {...stepperProps} />
    </div>
  );
};

export default TeacherCurriculumMainTab;

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import CauseAndEffectCard from './preview/CauseAndEffectActivityCard';
import CauseAndEffectPrompt from './preview/CauseAndEffectPrompt';
import './CauseAndEffect.scss';

const CauseAndEffect = ({
  causeAndEffectCards,
}) => {
  const numberOfSets = causeAndEffectCards.filter((card) => card.isCauseCard).length;
  const id = uuidv1();

  const getCauseAndActivitySet = () => {
    const prompts = [];
    for (let i = 0; i < numberOfSets; i++) {
      prompts.push(
        <CauseAndEffectPrompt
          key={`cause-and-effect-prompt-set-${id}-${i}`}
        />,
      );
    }
    return prompts;
  };

  return (
    <div className='cause-and-effect'>
      <div className='cause-and-effect-area-container'>
        <div className={`cause-and-effect-area cause-length-${numberOfSets}`}>
          {
            getCauseAndActivitySet()
          }
        </div>
      </div>
      <div className='cards-area-container'>
        <div className='cards'>
          {
            causeAndEffectCards.map((card, index) => {
              const cardId = `${id}-${index}`;
              const keyId = `cause-and-effect-key-${cardId}`;
              const causeAndEffectCardId = `cause-and-effect-card-${cardId}`;
              return (
                <div key={keyId} className={`cause-and-effect-card-place-holder card-margin-${causeAndEffectCards.length}-cards`}>
                  <CauseAndEffectCard
                    card={card}
                    cardId={causeAndEffectCardId}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default CauseAndEffect;

CauseAndEffect.defaultProps = {
  causeAndEffectCards: [],
};

CauseAndEffect.propTypes = {
  causeAndEffectCards: PropTypes.arrayOf(PropTypes.any),
};

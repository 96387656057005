import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useDebounce } from '../../../utils/ReactUtil';
import './StudentSearchForm.scss';

const StudentSearchForm = ({
  onKeywordChange,
  title,
  placeholder,
}) => {
  const [keyword, setKeyword] = useState('');
  const debouncedKeywordValue = useDebounce(keyword, 600);

  const handleTextChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleClearKeywordClick = () => {
    setKeyword('');
  };

  useEffect(() => {
    onKeywordChange(debouncedKeywordValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywordValue]);

  return (
    <div className='student-search-form'>
      <Typography variant='subtitle2'>
        {title}
      </Typography>
      <Input
        type='text'
        data-test='student-search-input'
        placeholder={placeholder}
        onChange={handleTextChange}
        variant='outlined'
        value={keyword || ''}
        className='input-field'
        inputProps={{
          'data-test': 'search-student-input',
          'data-private': 'redact',
        }}
        endAdornment={
          keyword ? (
            <InputAdornment position='end' className='clear-keyword-button'>
              <IconButton
                aria-label='Clear keyword'
                onClick={handleClearKeywordClick}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position='end'>
              <SearchIcon className='search-icon' />
            </InputAdornment>
          )
        }
      />
    </div>
  );
};

StudentSearchForm.defaultProps = {
  onKeywordChange: () => { },
  title: 'SEARCH YOUR STUDENTS',
  placeholder: 'Student Name',
};

StudentSearchForm.propTypes = {
  onKeywordChange: PropTypes.func,
  title: PropTypes.string,
  placeholder: PropTypes.string,

};

export default StudentSearchForm;

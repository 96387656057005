import Logger from '../utils/Logger';

export default {
  initializeLiveChatService: (user) => {
    // eslint-disable-next-line no-underscore-dangle
    window.__lc = window.__lc || {};
    // eslint-disable-next-line no-underscore-dangle
    window.__lc.license = 1031508;
    // eslint-disable-next-line
    ; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))
    // eslint-disable-next-line no-undef
    window.LiveChatWidget = LiveChatWidget;

    Logger.logWhenDebugModeIsOn('Live chat starts');
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('set_customer_name', `${user.firstName} ${user.lastName}`);
      window.LiveChatWidget.call('set_customer_email', user.userName);
      window.LiveChatWidget.call('set_session_variables', {
        firstName: user.firstName,
        name: user.userName,
        email: user.userName,
        licenseType: user.licenseType,
        licenseExpirationDate: user.licenseExpirationDate,
        schoolId: user.schoolId,
        isUserAdmin: user.userAdmin,
        state: user.state,
      });
    }
  },

  resetLiveChatService: () => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('set_customer_name', ' ');
      window.LiveChatWidget.call('set_customer_email', ' ');
      window.LiveChatWidget.call('set_session_variables', {});
    }
  },

  showLiveChatIcon: () => {
    const liveChatElement = document.querySelector('#chat-widget-container');
    if (liveChatElement) {
      liveChatElement.classList.remove('hide');
    }
  },

  hideLiveChatIcon: () => {
    const liveChatElement = document.querySelector('#chat-widget-container');
    if (liveChatElement) {
      liveChatElement.classList.add('hide');
    }
  },
};

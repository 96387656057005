/* eslint-disable react/jsx-no-target-blank */
// import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import './TeacherCourseInstruction.scss';
// import classroomLogo from '../../../../static/images/classroom.png';
// import vizzleLogo from '../../../../static/images/vizzle-rethink-logo.png';

// const videoLink = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/in-app-help-videos/VizzlePromo.mp4#t=0.1`;
// const pdfLink = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/in-app-help-videos/Vizzle K-12 Scope & Sequence.pdf`;

const TeacherCourseInstruction = () => (
  <div className='teacher-course-instruction'>
    <p className='no-top-margin'>
      To get started, choose an option for all filters on the left and click Search
    </p>
    {/* <div className='information-container'>
      <video src={videoLink} type='video/mp4' controls className='curriculum-intruction-video'>
        <track kind='captions' src={videoLink} />
      </video>

      <Card sx={{ maxWidth: 300 }}>
        <CardActionArea onClick={() => {
          window.open(pdfLink, '_blank');
        }}
        >
          <CardMedia
            component='img'
            height='140'
            image={classroomLogo}
            alt='Classroom logo'
            className='classroom-logo'
          />
          <CardMedia
            component='img'
            className='vizzle-logo'
            image={vizzleLogo}
            alt='Vizzle logo'
          />
          <CardContent className='text-content'>
            <Typography gutterBottom={false} variant='subtitle1' className='subtitle1'>
              Scope and Sequence Curriculum
            </Typography>
            <Typography variant='subtitle2' className='subtitle2' gutterBottom={false}>
              Grades K-12
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div> */}
  </div>
);

export default TeacherCourseInstruction;

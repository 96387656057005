import { useState } from 'react';
import { useLessonPlay } from '../context/LessonPlayContextProvider';
import TextToSpeechService from '../../../services/tts/TextToSpeechService';

export const useTtsPlay = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;

  const [textLineComponent, setTextLineComponent] = useState(null);

  const setTextLineRef = (r) => {
    if (!textLineComponent && r) {
      setTextLineComponent(r);
    }
  };

  const elementToPlayTts = userProfile.tts && userProfile.tts.enabled && textLineComponent && textLineComponent.getParagraphRefs();
  return {
    ttsConfig: userProfile.tts,
    setTextLineRef,
    elementToPlayTts: elementToPlayTts && elementToPlayTts.length > 0 ? elementToPlayTts.filter((el) => el.textContent.trim().length > 0) : [],
  };
};

export const useTtsPlayForElement = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { tts } = userProfile;

  const textToSpeechService = new TextToSpeechService(userProfile.tts.ttsMode);

  const { playTextToSpeech, cancelTts, isTtsPlaying } = textToSpeechService.getTtsService();

  const playTts = async (elementToPlay) => {
    if (tts.enabled) {
      if (isTtsPlaying()) {
        cancelTts();
        return;
      }
      await playTextToSpeech([elementToPlay], tts);
    }
  };

  return {
    playTts,
    tts,
    cancelTts,
  };
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import {
  Button,
} from '@mui/material';

import CustomDialog from '../dialog/CustomDialog';
import './ColorPickerModal.scss';

/**
 * Custom color picker for WYSIWYG text editor
 *
 * @export
 * @param {*} props
 */
export default function ColorPickerModal({
  color,
  onApply,
}) {
  const [internalValue, setInterValue] = useState(color);
  const [open, setOpen] = useState(false);

  const onChangeComplete = (colorValue) => {
    setInterValue(colorValue.hex);
  };

  useEffect(() => {
    if (open) {
      setInterValue(color || 'black');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        variant='contained'
        className='color-picker-button'
        style={{ backgroundColor: color }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {color || 'Select color'}
      </Button>
      {open && (
        <CustomDialog
          className='color-pick-modal-container'
          openDialog={open}
          onClose={() => { setOpen(false); }}
          content={(
            <SketchPicker
              expanded
              color={internalValue}
              onChangeComplete={onChangeComplete}
            />
          )}
          displayCloseIcon
          actions={[{
            onClick: () => {
              setOpen(false);
            },
            className: 'btn-danger margin-right',
            title: 'Cancel',
          },
          {
            onClick: () => {
              setOpen(false);
              onApply(internalValue);
            },
            className: 'btn-primary action-button',
            title: 'Apply',
          },
          ]}
        />
      )}
    </>
  );
}

ColorPickerModal.defaultProps = {
  color: '',
  onApply: () => null,
};

ColorPickerModal.propTypes = {
  color: PropTypes.string,
  onApply: PropTypes.func,
};

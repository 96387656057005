import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import BrowseImageAndVideoUploader from './BrowseImageAndVideoUploader';
import RecentlyUsedImageAndVideo from './RecentlyUsedImageAndVideo';
import SearchVizzleLibraryfrom from './SearchVizzleLibrary';
import ButtonList from '../../../../../../components/buttons/ButtonList';
import { useDomain } from '../../../../../../states/AppDomainProvider';

const BROWSE_COMPUTER = 0;
const RECENTLY_USED = 1;
const VIZZLE_MEDIA = 2;

export default function AddPhotoOrVideo({
  image,
  onSelect,
  onRemove,
  isVideoIncluded,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const [browseImageRef, setBrowseImageRef] = useState();

  const buttonListProps = {
    config: [
      {
        label: 'Browse Computer',
        onClick: () => {
          setSelectedOption(BROWSE_COMPUTER);
          if (browseImageRef && browseImageRef.current) {
            browseImageRef.current.open();
          }
        },
      },
      {
        label: 'Recently Used',
        onClick: () => {
          setSelectedOption(RECENTLY_USED);
        },
      },
      {
        label: 'Vizzle Media',
        onClick: () => {
          setSelectedOption(VIZZLE_MEDIA);
        },
      },
    ],
    align: 'horizental',
    componentKey: 'vizzle-image-video-upload-selector',
  };

  const onFileDialogClose = () => {
    setSelectedOption(null);
  };

  const setBrowseImageInputRef = (ref) => {
    setBrowseImageRef(ref);
    setSelectedOption(BROWSE_COMPUTER);
    if (selectedOption === BROWSE_COMPUTER) {
      ref.current.open();
    }
  };

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  return (
    <Box height='98%' data-test='image-and-video-selector-container'>
      <ButtonList
        {...buttonListProps}
        className='vizzle-image-video-upload-selector center margin-x-auto'
      />

      <Box height='80%' mt='10px'>
        <BrowseImageAndVideoUploader
          onSelect={onSelect}
          onRemove={onRemove}
          disabledRemove={image === null || image === undefined}
          setBrowseImageRef={setBrowseImageInputRef}
          isVideoIncluded={isVideoIncluded}
          user={user}
        />
        <RecentlyUsedImageAndVideo
          onSelect={onSelect}
          openFileDialog={selectedOption === RECENTLY_USED}
          onFileDialogClose={onFileDialogClose}
          isVideoIncluded={isVideoIncluded}
        />
        <SearchVizzleLibraryfrom
          onSelect={onSelect}
          openFileDialog={selectedOption === VIZZLE_MEDIA}
          onFileDialogClose={onFileDialogClose}
        />
      </Box>
    </Box>
  );
}

AddPhotoOrVideo.defaultProps = {
  image: null,
  onSelect: () => { },
  onRemove: () => { },
  isVideoIncluded: true,
};

AddPhotoOrVideo.propTypes = {
  image: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  isVideoIncluded: PropTypes.bool,
};

/* eslint-disable react/react-in-jsx-scope */
import { useRef, useMemo } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useAdmin } from '../../context/AdminContextProvider';

export const useGetTeacherTable = () => {
  const { adminDomain, adminTeachersDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const { filterActive } = adminTeachersDomain.domainData;
  const tableRef = useRef(null);
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '30%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '30%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '30%',
    },
  ];

  if (userSuperAdmin) {
    tableConfig.unshift(
      {
        title: 'Id',
        align: 'left',
        dataField: 'id',
        width: '10%',
      },
    );
  }
  const teacherTableData = useMemo(() => {
    if (!usersAndLicensesData.data || !usersAndLicensesData.data.relationships || !usersAndLicensesData.data.relationships.teachers) {
      return [];
    }

    const { included } = usersAndLicensesData;
    return usersAndLicensesData.data.relationships.teachers.data.map((t) => {
      const teacherObject = included.find((i) => i.id === t.id && i.type === t.type);
      const { attributes } = teacherObject;
      const result = {
        id: teacherObject.id,
        userName: attributes.userName,
        firstName: attributes.firstName,
        lastName: attributes.lastName,
        active: attributes.isActive,
      };
      return result;
    }).filter((t) => t.active === filterActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAndLicensesData, filterActive]);

  return {
    tableConfig,
    teacherTableData,
    tableRef,
  };
};

export const useHandleTeacherTable = () => {
  const { adminDomain, adminTeachersDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const updateTeacherRef = useRef(null);

  const handleOnSelectedRowIds = (selectedRowIds, clickParam) => {
    adminTeachersDomain.updateSelectedTeacherIds(selectedRowIds);
    if (clickParam && clickParam.doubleClicks && updateTeacherRef.current) {
      updateTeacherRef.current.openUpdateTeacher();
    }
  };

  const selectedTeachers = selectedTeacherIds && selectedTeacherIds.length > 0 && usersAndLicensesData.included
    ? usersAndLicensesData.included.filter((i) => i.type === 'users' && selectedTeacherIds.includes(i.id))
    : [];
  return {
    selectedTeacherIds,
    handleOnSelectedRowIds,
    updateTeacherRef,
    selectedTeachers,
  };
};

import { useEffect, useRef, useState } from 'react';
import { getUnitGuide, saveUnitGuide } from '../../../../services/CourseService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';

export const useGetUnitGuideData = ({ courseId, unitId, topicId }) => {
  const [unitGuideData, setUnitGuideData] = useState();
  const unitGuideDataSnapShot = useRef();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getUnitGuideData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        let result = await getUnitGuide({ courseId, unitId });
        if (!result) {
          result = { unitGuideInfo: [] };
        }
        setUnitGuideData(result);
        unitGuideDataSnapShot.current = JSON.parse(JSON.stringify(result));
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (courseId && unitId) {
      getUnitGuideData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, unitId, topicId]);

  return {
    unitGuideData,
    loading,
    setUnitGuideData,
    unitGuideDataSnapShot,
  };
};

export const useSaveUnitGuideData = () => {
  const { loading: saving, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const saveUnitGuideData = async ({ topicId, unitGuideData }) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await saveUnitGuide(topicId, unitGuideData);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, 'Unit guide data is succesfully saved.');
    } catch (e) {
      Logger.logError(e);
    }
  };

  return {
    saving,
    saveUnitGuideData,
  };
};

import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@mui/material';
import FillInTheBlankEditorWithTextStyle from './FillInTheBlankEditorWithTextStyle';
import FillInTheBlankPreviewWithTextStyle from '../../../../../../../components/vizzle/page/fitb/FillInTheBlankPreviewWithTextStyle';

const FillInTheBlankDialogEditorWithTextStyle = ({ data, onDataChange }) => {
  const { question: { preBlank, postBlank } } = data;

  const handleOnPreBlankChange = (value) => {
    onDataChange({
      ...data,
      question: {
        ...data.question,
        preBlank: {
          lines: value || [],
        },
      },
    });
  };

  const handleOnPostBlankChange = (value) => {
    onDataChange({
      ...data,
      question: {
        ...data.question,
        postBlank: {
          lines: value || [],
        },
      },
    });
  };

  const handleOnAnswerAdded = (newAnswer) => {
    const { answers } = data;
    const updatedAnswers = answers || [];

    // Ignore the new answer when it's already in the list
    const index = updatedAnswers.findIndex((a) => a === newAnswer);
    if (index >= 0) {
      return;
    }
    onDataChange({
      ...data,
      answers: [
        ...updatedAnswers,
        newAnswer,
      ],
    });
  };

  const handleOnAnswerDelete = (answerToDelete) => {
    const { answers } = data;
    const updatedAnswers = answers.filter((answer) => (
      answer !== answerToDelete
    ));
    onDataChange({
      ...data,
      answers: [
        ...updatedAnswers,
      ],
    });
  };

  return (
    <Grid container spacing={3} wrap='nowrap' style={{ height: 'inherit', padding: '8px' }}>
      <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '0px' }}>
        <Box width='100%' height='100%' my='auto'>
          <FillInTheBlankEditorWithTextStyle
            preBlank={preBlank}
            postBlank={postBlank}
            answers={data.answers}
            onPreBlankChange={handleOnPreBlankChange}
            onPostBlankChange={handleOnPostBlankChange}
            onAnswerAdd={handleOnAnswerAdded}
            onAnswerDelete={handleOnAnswerDelete}
          />
        </Box>
      </Grid>
      <Grid item xs={5} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit' }}>
        <Box width='100%' height='100%' my='auto'>
          <FillInTheBlankPreviewWithTextStyle
            data={data}
          />

        </Box>
      </Grid>
    </Grid>

  );
};

FillInTheBlankDialogEditorWithTextStyle.defaultProps = {
  data: {
    question: {
      preBlank: {
        lines: [],
      },
      postBlank: {
        lines: [],
      },
    },
    answers: [],
  },
  onDataChange: () => { },
};

FillInTheBlankDialogEditorWithTextStyle.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.shape({
      preBlank: PropTypes.any,
      postBlank: PropTypes.any,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
  }),
  onDataChange: PropTypes.func,
};

export default FillInTheBlankDialogEditorWithTextStyle;

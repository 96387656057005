import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DOMPurify from 'dompurify';
import { useRef } from 'react';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import LoadingSpinner from '../../../../../components/loaders/LoadingSpinner';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import { useGetUnitGuideData, useSaveUnitGuideData } from '../../unit-guide/UnitGuidePdfGeneratorHooks';
import TopicUnitGuideEditor from './components/TopicUnitGuideEditor';
import './TopicInfo.scss';

const getUnitGuideByTopic = (unitGuideData, topicId) => {
  const unitGuideByTopic = unitGuideData && unitGuideData.unitGuideInfo ? unitGuideData.unitGuideInfo.find((u) => u.topicId === topicId) || {} : {};
  return unitGuideByTopic;
};

const TopicInfo = () => {
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria, hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;

  const { courseId, unitId, topicId } = searchCriteria;

  const keyVocabularyRef = useRef(null);
  const essentialRef = useRef(null);
  const activityRef = useRef(null);

  const {
    unitGuideData,
    loading,
    setUnitGuideData,
    unitGuideDataSnapShot,
  } = useGetUnitGuideData({ courseId, unitId, topicId });

  const {
    saving,
    saveUnitGuideData,
  } = useSaveUnitGuideData();
  if (!hasPerformSearch) {
    return null;
  }

  if (loading) {
    return (
      <div className='topic-info loading-container' data-test='loading-container'>
        <LoadingSpinner message='Loading Unit Guide' />
      </div>
    );
  }

  const unitGuideByTopic = getUnitGuideByTopic(unitGuideData, topicId);

  return (
    <div className='topic-info' data-test='topic-info'>
      <TopicUnitGuideEditor
        title='ADD KEY VOCABULARY'
        headerClassName='key-vocabulary-editor-header'
        data-test='key-vocabulary-editor-header'
        unitGuideSectionData={unitGuideByTopic ? unitGuideByTopic.keyVocabulary : ''}
        onChange={(newData) => {
          if (!unitGuideByTopic.topicId) {
            unitGuideByTopic.keyVocabulary = newData;
            unitGuideData.unitGuideInfo.push({
              topicId,
              ...unitGuideByTopic,
            });
          } else {
            unitGuideByTopic.keyVocabulary = newData;
          }
          setUnitGuideData({ ...unitGuideData });
        }}
        ref={keyVocabularyRef}
      />
      <TopicUnitGuideEditor
        title='ADD ESSENTIAL QUESTION/BIG IDEAS'
        headerClassName='essential-question-editor-header'
        data-test='essential-question-editor-header'
        unitGuideSectionData={unitGuideByTopic ? unitGuideByTopic.essentialQuestions : ''}
        onChange={(newData) => {
          if (!unitGuideByTopic.topicId) {
            unitGuideByTopic.essentialQuestions = newData;
            unitGuideData.unitGuideInfo.push({
              topicId,
              ...unitGuideByTopic,
            });
          } else {
            unitGuideByTopic.essentialQuestions = newData;
          }
          setUnitGuideData({ ...unitGuideData });
        }}
        ref={essentialRef}
      />
      <TopicUnitGuideEditor
        title='ADD ACTIVITIES'
        headerClassName='activities-editor-header'
        data-test='activities-editor-header'
        unitGuideSectionData={unitGuideByTopic ? unitGuideByTopic.activities : ''}
        onChange={(newData) => {
          if (!unitGuideByTopic.topicId) {
            unitGuideData.unitGuideInfo.push({
              topicId,
              ...unitGuideByTopic,
            });
          } else {
            unitGuideByTopic.activities = newData;
          }
          setUnitGuideData({ ...unitGuideData });
        }}
        ref={activityRef}
      />

      <div className='actions-container'>
        <ButtonWithSpinner
          variant='contained'
          className='btn-primary-rounded-border cancel-button'
          data-test='topic-cancel-action'
          label='CANCEL'
          onClick={() => {
            setUnitGuideData(unitGuideByTopic ? unitGuideDataSnapShot.current : '');
            const originalUnitGuideByTopic = getUnitGuideByTopic(unitGuideDataSnapShot.current, topicId);
            keyVocabularyRef.current.setContent(originalUnitGuideByTopic.keyVocabulary);
            essentialRef.current.setContent(originalUnitGuideByTopic.essentialQuestions);
            activityRef.current.setContent(originalUnitGuideByTopic.activities);
          }}
          loading={saving}
          startIcon={saving ? null : <CancelIcon />}
        />
        <ButtonWithSpinner
          variant='contained'
          className='btn-primary topic-action'
          data-test='topic-save-action'
          label="FINISHED AND APPLY TO TOPIC'S UNIT GUIDE"
          loading={saving}
          startIcon={saving ? null : <CheckCircleIcon />}
          onClick={() => {
            const keyVocabulary = DOMPurify.sanitize(unitGuideByTopic.keyVocabulary);
            const essentialQuestions = DOMPurify.sanitize(unitGuideByTopic.essentialQuestions);
            const activities = DOMPurify.sanitize(unitGuideByTopic.activities);
            const payload = {
              keyVocabulary,
              essentialQuestions,
              activities,
            };
            saveUnitGuideData({ topicId, unitGuideData: payload });
          }}
        />
      </div>
    </div>
  );
};

export default TopicInfo;

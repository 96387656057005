import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AssignmentLesson from '../../../../../cards/AssignmentLesson';
import './LessonAssignmentOrdering.scss';

export default function LessonAssignmentOrdering({
  lessons,
  onDragEnd,
  className,
  withSash,
  disabled,
}) {
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (source === null || destination === null) {
      return;
    }
    const { source: { index: sourceIndex }, destination: { index: destinationIndex } } = result;
    if (sourceIndex === null || sourceIndex === undefined || destinationIndex === null || destinationIndex === undefined || sourceIndex === destinationIndex) {
      return;
    }
    const lessonsToUpdate = Array.from(lessons);
    const [removed] = lessonsToUpdate.splice(sourceIndex, 1);
    lessonsToUpdate.splice(destinationIndex, 0, removed);
    onDragEnd([
      ...lessonsToUpdate,
    ]);
  };

  return (
    <div className={`lesson-assignment-ordering ${className}`} data-test='lesson-ordering-container'>
      <div className='lesson-assigment-container-container'>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
        >
          <Droppable
            droppableId='order-assignment-lessons-droppable-container'
            direction='vertical'
            isDropDisabled={disabled}
          >
            {
              (provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className='order-lessons'
                >
                  {lessons.length === 1 ? (
                    <AssignmentLesson
                      lesson={lessons[0]}
                      key={`order-lesson-drag-item-${0}`}
                      withSash={withSash}
                    />
                  ) : (
                    lessons.map((lesson, index) => {
                      const cardId = `assignment-lesson-${index}`;
                      const draggableId = `order-lesson-drag-item-${cardId}`;
                      return (
                        <Draggable
                          key={draggableId}
                          draggableId={draggableId}
                          index={index}
                          isDragDisabled={disabled}
                        >
                          {(providedItem, snapshotItem) => (
                            <div
                              ref={providedItem.innerRef}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                              id={`order-lesson-item-${cardId}`}
                              key={`order-lesson-item-${cardId}`}
                            >
                              <AssignmentLesson
                                className={`${snapshotItem.isDragging ? 'selected-lesson-component' : ''}`}
                                lesson={lesson}
                                withSash={withSash}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  )}
                  {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

LessonAssignmentOrdering.defaultProps = {
  lessons: [],
  onDragEnd: () => { },
  className: '',
  withSash: true,
  disabled: false,
};

LessonAssignmentOrdering.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      // eslint-disable-next-line react/forbid-prop-types
      attributes: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      relationships: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      meta: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      specialtyContent: PropTypes.object,
    }),
  ),
  onDragEnd: PropTypes.func,
  className: PropTypes.string,
  withSash: PropTypes.bool,
  disabled: PropTypes.bool,
};

import BaseDomain from '../../../states/domains/BaseDomain';

const ADMIN_TEACHERS_UPDATE_SELECTED_TEACHER_IDS = 'ADMIN_TEACHERS_UPDATE_SELECTED_TEACHER_IDS';
const ADMIN_TEACHERS_UPDATE_FILTER_ACTIVE = 'ADMIN_TEACHERS_UPDATE_FILTER_ACTIVE';

const initialState = {
  selectedTeacherIds: [],
  filterActive: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADMIN_TEACHERS_UPDATE_SELECTED_TEACHER_IDS:
      return {
        ...state,
        selectedTeacherIds: action.payload,
      };
    case ADMIN_TEACHERS_UPDATE_FILTER_ACTIVE:
      return {
        ...state,
        filterActive: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'adminTeachersDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSelectedTeacherIds(selectedTeacherIds) {
    return this.dispatch({
      type: ADMIN_TEACHERS_UPDATE_SELECTED_TEACHER_IDS,
      payload: selectedTeacherIds,
    });
  }

  async updateFilterActive(filterActive) {
    return this.dispatch({
      type: ADMIN_TEACHERS_UPDATE_FILTER_ACTIVE,
      payload: filterActive,
    });
  }
}

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import UpdateButton from '../../buttons/UpdateButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useMoveTeachers } from './MoveTeacherActionHooks';
import MoveTeacherForm, { validateMoveTeacherFormData } from './MoveTeacherForm';
import './MoveTeacherAction.scss';

const initData = {
  shouldMoveStudent: false,
};

const MoveTeacherAction = ({
  selectedTeachers,
  initSelectedDistrict,
  initSelectedSchool,
  onMoveTeachersSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [isDataValid, setIsDataValid] = useState(false);

  const dataRef = useRef(null);

  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    moveTeachersToNewSchool,
    loading,
  } = useMoveTeachers({
    onMoveTeachersSuccess,
    setOpen,
    selectedTeachers,
  });

  useEffect(() => {
    if (open) {
      dataRef.current = {
        ...initData,
        district: initSelectedDistrict,
        school: initSelectedSchool,
      };
      setIsDataValid(validateMoveTeacherFormData(initData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: () => { moveTeachersToNewSchool(dataRef.current); },
      className: 'btn-primary action-button',
      title: 'Save',
      disabled: !isDataValid,
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <UpdateButton
        ariaLabel={`Move Teacher${selectedTeachers.length > 1 ? 's' : ''}`}
        onClick={() => { setOpen(true); }}
        disabled={!selectedTeachers || selectedTeachers.length < 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='search-teacher-update-teacher-dialog'
          onClose={handleOnCancel}
          title='Move Teacher'
          content={(
            <MoveTeacherForm
              initData={dataRef.current}
              teacherTableData={selectedTeachers}
              onDataChange={(updatedData, valid) => {
                dataRef.current = updatedData;
                setIsDataValid(valid);
              }}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};
MoveTeacherAction.defaultProps = {
  onMoveTeachersSuccess: () => null,
  selectedTeachers: [],
  initSelectedDistrict: null,
  initSelectedSchool: null,
};

MoveTeacherAction.propTypes = {
  onMoveTeachersSuccess: PropTypes.func,
  selectedTeachers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
  initSelectedDistrict: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  initSelectedSchool: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default MoveTeacherAction;

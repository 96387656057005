import ArchiveObjectiveAction from './ArchiveObjectiveAction';
import UnarchiveObjectiveAction from './UnarchiveObjectiveAction';
import PrintObjectiveAction from './PrintObjectiveAction';
import { isMobile } from '../../../../../utils/DeviceUtils';

export const createObjectiveDetailActions = (objective, rowIndex, closeDetailFunc, included, currentStudent) => {
  const actions = [];
  if (!isMobile()) {
    actions.push(
      <PrintObjectiveAction
        key={`objective-detail-action-print-${objective.id}`}
        objective={objective}
        included={included}
        currentUser={currentStudent}
      />,
    );
  }

  if (objective.meta.archived) {
    actions.push(
      <UnarchiveObjectiveAction
        key={`objective-detail-action-unarchive-${objective.id}`}
        objective={objective}
        onSaveSuccess={closeDetailFunc}
        rowIndex={rowIndex}
      />,
    );
  } else {
    actions.push(
      <ArchiveObjectiveAction
        key={`objective-detail-action-archive-${objective.id}`}
        objective={objective}
        onSaveSuccess={closeDetailFunc}
        rowIndex={rowIndex}
      />,
    );
  }

  return actions;
};

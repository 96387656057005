import { useEffect, useMemo, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
} from '@mui/material';
import BlankActivityCard from '../../../../components/vizzle/page/BlankActivityCard';
import {
  CARD_MODEL,
  MATCHING_QUIZ,
  FILL_IN_THE_BLANK,
} from '../../../../components/vizzle/page/game/GameConstants';
import { shuffle } from '../../../../utils/ArrayUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
// eslint-disable-next-line import/no-cycle
import BookCardActivity from '../book/BookCardActivity';
import MatchingActivity from '../matching/MatchingActivity';
import FillInTheBlankActivity from '../fitb/FillInTheBlankActivity';
import './PlayActivityInteractionPopup.scss';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import { useScreenReader } from '../../../screen-reader/ScreenReaderHooks';
import { useRegisterEvent } from '../../../switch-access/SwitchAccessHooks';
import { hideAccessbeChatIconInTimeInterval } from '../../../../services/AccessibeService';

const PlayActivityInteractionPopup = forwardRef(({
  open,
  onClose,
  landingInteraction,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onEntered,
}, ref) => {
  const eventClenupFuncRef = useRef(null);

  useEffect(() => {
    LessonPlayActivityUtils.stopMedias();
    hideAccessbeChatIconInTimeInterval();
    return () => {
      if (eventClenupFuncRef.current) {
        eventClenupFuncRef.current();
      }
    };
  }, [open]);

  const handleOnCorrectAnswerSelected = () => {
    ObjectUtils.setTimeout(() => {
      onClose();
    }, 500);
  };

  const closeButtonRef = useRef(null);
  const contentRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      if (FILL_IN_THE_BLANK === landingInteraction.type) {
        return [];
      }
      let result = [
        closeButtonRef.current,
      ];

      if (contentRef.current) {
        const content = await contentRef.current.getEnableElements();
        if (content) {
          result = [
            ...content,
            ...result,
          ];
        }
      }
      return result;
    },
    skipAppBar: () => open,
  }));

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  const handleOnEnter = () => {
    onEntered();

    eventClenupFuncRef.current = registerSwitchEvent('switchAccessScan', closeButtonRef, () => {
      readWhenScreenReaderIsOn(closeButtonRef.current);
    });
  };

  const shuffledMatchingResponseCards = useMemo(() => {
    if (MATCHING_QUIZ === landingInteraction.type) {
      const responseCards = landingInteraction.answerCards.map((card) => ({
        ...card,
        correctAnswer: card.correctAnswer === null || card.correctAnswer === undefined ? landingInteraction.questionCard.key === card.answerKey : card.correctAnswer,
      }));
      const shuffledResponseCards = shuffle(responseCards);
      return shuffledResponseCards;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [correctAnswer, setCorrectAnswer] = useState();
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);

  const onHotspotCorrectAnswerSelected = async (_insertResponse, answer, once, isFinished) => {
    setCorrectAnswer(answer);
    await onCorrectAnswerSelected({ insertResponse: true, responseIndex: answer, once, isFinished, showReinforcer: true, autoCloseCorrectSubmissionMessage: 4000 });
  };

  const onHotspotIncorrectAnswerSelected = (incorrectAnswer) => {
    setIncorrectAnswers([
      ...incorrectAnswers,
      incorrectAnswer,
    ]);
    onIncorrectAnswerSelected(incorrectAnswer);
  };

  const getComponentByInteractionType = () => {
    if (CARD_MODEL === landingInteraction.type) {
      return (
        <BookCardActivity
          className='book-card-popup'
          layoutConfig={[{
            text: landingInteraction.questionCard && landingInteraction.questionCard.text,
            image: landingInteraction.questionCard && landingInteraction.questionCard.image,
            video: landingInteraction.questionCard && landingInteraction.questionCard.video,
            audio: landingInteraction.questionCard && landingInteraction.questionCard.audio,
          }]}
          ref={contentRef}
          isCurrentPage
        />
      );
    } else if (MATCHING_QUIZ === landingInteraction.type) {
      return (
        <MatchingActivity
          autoPlayDelay={1000}
          className='matching-popup'
          promptCards={[landingInteraction.questionCard]}
          responseCards={shuffledMatchingResponseCards}
          onCorrectAnswerSelected={onHotspotCorrectAnswerSelected}
          onIncorrectAnswerSelected={onHotspotIncorrectAnswerSelected}
          onActivityFinished={handleOnCorrectAnswerSelected}
          isCurrentPage
          initialCorrectAnswer={correctAnswer}
          initialIncorrectAnswers={incorrectAnswers}
          ref={contentRef}
        />
      );
    } else if (FILL_IN_THE_BLANK === landingInteraction.type) {
      return (
        <FillInTheBlankActivity
          question={landingInteraction.question}
          answers={landingInteraction.answers}
          onCorrectAnswerSelected={onHotspotCorrectAnswerSelected}
          onIncorrectAnswerSelected={onHotspotIncorrectAnswerSelected}
          onFinish={handleOnCorrectAnswerSelected}
          initialCorrectAnswer={correctAnswer}
          initialIncorrectAnswers={incorrectAnswers}
          ttsText={landingInteraction.ttsText}
        />
      );
    }

    return <BlankActivityCard />;
  };

  if (!landingInteraction.type) {
    onClose();
    return null;
  }

  return (open ? (
    <Dialog
      fullWidth
      fullScreen={MATCHING_QUIZ === landingInteraction.type}
      maxWidth={MATCHING_QUIZ === landingInteraction.type ? false : 'md'}
      open={open}
      PaperProps={{ sx: { height: '100%' } }}
      onClose={onClose}
      className='play-interaction-popup'
      disableEscapeKeyDown
      TransitionProps={{
        onEntered: handleOnEnter,
      }}
      data-test='play-interaction-popup'
    >
      <DialogContent style={{ height: 'inherit' }} className={`content ${MATCHING_QUIZ === landingInteraction.type ? 'blue-background' : ''}`}>
        <Box data-test='play-interaction-popup-close-action' className='close-icon' alt='Close' onClick={onClose} ref={closeButtonRef} data-switch-access-scan={screenReaderOn ? 'true' : 'false'}>
          <CloseIcon />
        </Box>
        <Box className='content-component'>
          {getComponentByInteractionType()}
        </Box>
      </DialogContent>
    </Dialog>
  ) : '');
});

PlayActivityInteractionPopup.defaultProps = {
  open: false,
  onClose: () => { },
  landingInteraction: null,
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onEntered: () => null,
};

PlayActivityInteractionPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  landingInteraction: PropTypes.shape({
    type: PropTypes.string,
    questionCard: PropTypes.object,
    answerCards: PropTypes.array,
    question: PropTypes.shape({
      question: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
    ttsText: PropTypes.object,
  }),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onEntered: PropTypes.func,
};

export default PlayActivityInteractionPopup;

import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './CauseAndEffectActivityPrompt.scss';

const CauseAndEffectActivityPrompt = forwardRef(({
  causeComponent,
  effectComponent,
}, ref) => {
  useImperativeHandle(ref, () => ({
    elementRef: () => { },
  }));

  const createPlaceholder = (title, component) => (
    <div className='card-container'>
      <div className='card-container-placeholder item-placeholder'>
        <div className='title'>
          {title}
        </div>
        <div className='selected-card-container'>
          <div className='selected-card-container-inner'>
            {component}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='cause-and-effect-activity-prompt'>
      {createPlaceholder('Cause', causeComponent)}
      <div>
        <DoubleArrowIcon className='arrow' />
      </div>
      {createPlaceholder('Effect', effectComponent)}
    </div>
  );
});

export default CauseAndEffectActivityPrompt;

CauseAndEffectActivityPrompt.defaultProps = {
  causeComponent: null,
  effectComponent: null,
};

CauseAndEffectActivityPrompt.propTypes = {
  causeComponent: PropTypes.node,
  effectComponent: PropTypes.node,
};

import PropTypes from 'prop-types';
import { getUserToken } from '../utils/VizzleSessionStorage';
import { URL_LOGIN } from './RouteConstants';

/**
 * Private route that check the token of the user.
 * Redirect the user to login screen when the token is unavailable.
 * @param {*} props of the Route component
 */
export const TwoFactorPrivateRoute = ({ user, ...props }) => {
  const userToken = getUserToken();

  if (userToken && user.mfaLoggedIn) {
    return props.element;
  }
  // eslint-disable-next-line no-unused-expressions
  // force the url to change to login screen
  window.location = URL_LOGIN;
  return '';
};

TwoFactorPrivateRoute.defaultProps = {
  user: {},
};

TwoFactorPrivateRoute.propTypes = {
  user: PropTypes.object,
};

export default TwoFactorPrivateRoute;

import { useRef, useState } from 'react';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { stopAudio } from '../../media/AudioPlayer';

const showDropRejection = (destinationDroppingId) => {
  ObjectUtils.setTimeout(() => {
    document.getElementById(destinationDroppingId).classList.remove('sequencing-component-reject-hide');
    document.getElementById(destinationDroppingId).classList.add('sequencing-component-reject-show');
  }, 100);

  ObjectUtils.setTimeout(() => {
    document.getElementById(destinationDroppingId).classList.remove('sequencing-component-reject-show');
    document.getElementById(destinationDroppingId).classList.add('sequencing-component-reject-hide');
  }, 500);
};

export const useSequencingPlay = (pageIndex, sequenceCards, responseCards) => {
  const currentDroppableId = useRef(null);
  const [selectedResponseCards, setSelectedResponseCards] = useState([]);

  const handleOnDragStart = () => {
    stopAudio();
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      return;
    }
    const sourceIndex = source.index;
    const destinationDroppingId = currentDroppableId.current || destination.droppableId;
    const destinationIndexStr = destinationDroppingId.substring(destinationDroppingId.lastIndexOf('-') + 1);
    const destinationIndex = parseInt(destinationIndexStr, 10);
    if (sequenceCards[destinationIndex].key === responseCards[sourceIndex].answerKey) {
      setSelectedResponseCards([
        ...selectedResponseCards,
        sourceIndex,
      ]);
    } else {
      showDropRejection(destinationDroppingId);
    }
    document.getElementById(`sequencing-component-${pageIndex}`).focus();
  };

  return {
    handleOnDragEnd,
    handleOnDragStart,
    selectedResponseCards,
  };
};

import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import './MatchingEditor.scss';
import CardEditor from '../common/card-editor/CardEditor';
import { AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../common/CardEditorConstants';
import MatchingQuestionKey from '../common/question-key/MatchingQuestionKey';
import MatchingAnswerKey from '../common/question-key/MatchingAnswerKey';

export default function MatchingEditor({
  promptCards,
  responseCards,
  selectedSection,
  onCardSelected,
  onQuestionKeyChange,
  onAnswerKeyChange,
}) {
  const onCardSlected = (area, cardIndex, section) => {
    const params = {
      selectedArea: area,
      selectedCardIndex: cardIndex,
      selectedSection: section,
    };
    onCardSelected(params);
  };

  const handleQuestionKeyChange = (index, newKey) => {
    const promptCardKeys = promptCards.map(((c) => c.questionKey));
    promptCardKeys[index] = newKey;
    onQuestionKeyChange(promptCardKeys);
  };

  const handleonAnswerKeyChange = (index, newKey) => {
    const responseCardKeys = responseCards.map(((c) => c.answerKey));
    responseCardKeys[index] = newKey;
    onAnswerKeyChange(responseCardKeys);
  };

  const getCardEditor = (card, area, cardIndex) => (
    <CardEditor
      key={`matching-editor-action-${cardIndex}`}
      isSelected={selectedSection.selectedArea === area
        && selectedSection.selectedCardIndex === cardIndex}
      config={{
        text: card.text,
        image: card.image,
        video: card.video,
        audio: card.audio,
      }}
      selectedSection={selectedSection.selectedSection}
      onImageSelected={() => {
        onCardSlected(area, cardIndex, SECTION_IMAGE);
      }}
      onTextSelected={() => {
        onCardSlected(area, cardIndex, SECTION_TEXT);
      }}
      onAudioSelected={() => {
        onCardSlected(area, cardIndex, SECTION_AUDIO);
      }}
    />
  );

  return (
    <Box data-test='matching-editor' className='matching-editor-component vizzle-matching-editor'>
      <Box className='matching-editor-prompts-area'>
        {promptCards.map((card, index) => {
          const key = `matching-editor-question-key-${index}-${card.questionKey}`;
          return (
            <Box key={key} position='relative' style={{ width: `${(100 / promptCards.length)}%`, height: '100%' }}>
              <MatchingQuestionKey questionKey={card.questionKey} onKeyChange={(value) => { handleQuestionKeyChange(index, value); }} />
              {getCardEditor(
                card,
                AREA_PROMPT_CARDS,
                index,
              )}
            </Box>
          );
        })}
      </Box>
      <Box className='matching-editor-responses-area'>
        {responseCards.map((card, index) => {
          const key = `matching-editor-answer-key-${index}-${card.answerKey}`;
          return (
            <Box key={key} position='relative' style={{ width: `${(100 / responseCards.length)}%`, height: '100%' }}>
              <MatchingAnswerKey
                value={card.answerKey}
                onKeyChange={(value) => { handleonAnswerKeyChange(index, value); }}
                disabled={index === 0}
                isCorrect={promptCards.findIndex((p) => (card.answerKey && p.questionKey === card.answerKey)) >= 0}
              />
              {getCardEditor(
                card,
                AREA_RESPONSE_CARDS,
                index,
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

MatchingEditor.defaultProps = {
  promptCards: [{}],
  responseCards: [{}, {}, {}, {}, {}],
  selectedSection: {
    selectedArea: AREA_PROMPT_CARDS,
    selectedCardIndex: 0,
    selectedSection: SECTION_AUDIO,
  },
  onCardSelected: () => null,
  onQuestionKeyChange: () => null,
  onAnswerKeyChange: () => null,
};

MatchingEditor.propTypes = {
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
  })),

  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),

  selectedSection: PropTypes.shape({
    selectedArea: PropTypes.oneOf([AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS]),
    selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
    selectedCardIndex: PropTypes.number,
  }),

  onCardSelected: PropTypes.func,
  onQuestionKeyChange: PropTypes.func,
  onAnswerKeyChange: PropTypes.func,
};

import { useEffect, useState } from 'react';
import { getLessonCourses } from '../../../../../../../../services/CourseService';
import { getLessonLevels } from '../../../../../../../../services/LessonStandardService';
import { sort } from '../../../../../../../../utils/ArrayUtils';
import Logger from '../../../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../../../hooks/RemoteServiceHooks';

export const useGetLessonCourseData = () => {
  const [lessonCourses, setLessonCourses] = useState();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getCourseData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getLessonCourses();
        const sortedResult = sort(result.data, 'name');
        setLessonCourses(sortedResult);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (!lessonCourses) {
      getCourseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lessonCourseLoading: loading,
    lessonCourses,
    getCourseData,
  };
};

export const useHandleFormChange = () => {
  const [localSearchCriteria, setLocalSearchCriteria] = useState({
    subjectId: '',
    courseId: '',
    unitId: '',
    topicId: '',
    lessonLevel: getLessonLevels()[0].id,
  });

  const handleSubjectChange = (e) => {
    setLocalSearchCriteria({
      ...localSearchCriteria,
      [e.target.name]: e.target.value,
      courseId: '',
      unitId: '',
      topicId: '',
    });
  };

  const handleOnCourseDataChange = (e, additionalData = {}) => {
    setLocalSearchCriteria({
      ...localSearchCriteria,
      [e.target.name]: e.target.value,
      ...additionalData,
    });
  };

  const handleOnLessonLevelChange = (e) => {
    setLocalSearchCriteria({
      ...localSearchCriteria,
      [e.target.name]: e.target.value,
    });
  };

  return {
    localSearchCriteria,
    handleSubjectChange,
    handleOnCourseDataChange,
    handleOnLessonLevelChange,
  };
};

export const useHandleSubjectSelector = () => {
  const [subjectId, setSubjectId] = useState('');
  const handleSubjectChange = (e) => {
    setSubjectId(e.target.value);
  };

  return {
    subjectId,
    handleSubjectChange,
  };
};

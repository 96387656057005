import {
  Box,
} from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';
import EditableSingleFieldForm from '../../../../components/form/EditableSingleFieldForm';
import ChurnZeroService from '../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../constants/ChurnZeroConstant';

/**
 * Editable lesson title container
 */
export default function EditableLessonTitleContainer() {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const { user } = userDomain.domainData;

  const handleSubmitForLessonTitile = async (lessonTitle) => {
    // Save lesson icon to the state
    ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'Author Lesson', user);
    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        name: lessonTitle,
      },
    };
    await lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  return (
    <Box width='40vw' height='35px'>
      <EditableSingleFieldForm
        label='Lesson Title'
        placeholderLabel='Please specify a title'
        placeholderColor='error'
        formData={lessonData.attributes ? lessonData.attributes.name : ''}
        handleSaveData={handleSubmitForLessonTitile}
        data-test='lesson-title-editor'
      />
    </Box>
  );
}

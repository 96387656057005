import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import vizzleImageData from '../../vizzle-logo-data-image';
import './StudentUsernamesPrint.scss';

const StudentUsernamesPrint = forwardRef(({
  currentUser,
  students,
}, ref) => (
  <div className='student-usernames-print' ref={ref}>
    <div className='vizzle-logo'>
      <img src={vizzleImageData} alt='Vizzle Logo' />
    </div>
    <div className='header'>
      List of Student Usernames for
      <br />
      <div className='username' data-private>{currentUser.userName}</div>
    </div>
    <div className='student-list'>
      {students.map((student) => (
        <div className='student' key={`print-student-${student.id}`}>
          <div className='username' data-private>
            {student.userName}
          </div>
          <div className='name' data-private>
            {student.firstName} {student.lastName}
          </div>
        </div>
      ))}
    </div>
  </div>
));

StudentUsernamesPrint.defaultProps = {
  currentUser: {},
  students: [],
};

StudentUsernamesPrint.propTypes = {
  currentUser: PropTypes.PropTypes.shape({
    userName: PropTypes.string,
  }),
  students: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })),
};

export default StudentUsernamesPrint;

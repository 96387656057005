import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import './FullScreenDialogbox.scss';
import LiveChatService from '../../services/LiveChatService';

const Transition = forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

/**
 * Full screen confirmation dialog component.
 * Dialogs inform users about a task and can contain critical information, require decisions
 */
export default function FullScreenDialogbox({
  open,
  handleClose,
  title,
  titleAction,
  children,
  className,
  noTitle,
  ...rest
}) {
  useEffect(() => {
    if (open) {
      LiveChatService.hideLiveChatIcon();
    } else {
      LiveChatService.showLiveChatIcon();
    }
    return () => {
      LiveChatService.showLiveChatIcon();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog
      {...rest}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={`full-screen-dialog-component ${className}`}
      // to prevent firing actions of the background components
      onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
    >
      {!noTitle && (
        <Toolbar className='full-screen-dialog-toolbar'>
          <Typography variant='h6' data-test='title'>
            {title}
          </Typography>
          <div className='full-screen-dialog-close-button'>
            {titleAction}
            <IconButton
              color='inherit'
              onClick={handleClose}
              aria-label='close'
              className='close-button vizzle-full-screen-dialog-close-btn'
              data-test='close-button'
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
      )}
      {children}
    </Dialog>
  );
}

FullScreenDialogbox.defaultProps = {
  open: false,
  handleClose: () => { },
  title: '',
  children: null,
  titleAction: null,
  className: '',
  noTitle: false,
};

FullScreenDialogbox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  titleAction: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  noTitle: PropTypes.bool,
};

import { Box, Typography } from '@mui/material';
import ToggleForm from '../../../../../components/toggle/ToggleForm';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { feedbackLoopTooltipText } from '../../../../../texts/TooltipText';
import { useFindFfrPage, useShouldFeedbackLoopDisable } from '../../hooks/LessonMaintenanceHooks';
import './FeedbackLoopSaveAction.scss';

const FeedbackLoopSaveAction = () => {
  const {
    lessonDataMaintenanceDomain,
  } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const {
    getShouldFeedbackLoopDisable,
  } = useShouldFeedbackLoopDisable();

  const isDisabled = getShouldFeedbackLoopDisable();

  const {
    hasFfr,
  } = useFindFfrPage();

  return (
    <Box className='feedback-loop-save-action' display='flex' flexDirection='column' mt='-23px' mr='15px'>
      <Typography variant='h6' className='header'>
        Feedback Loop <TooltipWithIndicator
          className='feedback-loop-tooltip'
          dialogClassName='feedback-loop-tooltip-dialog'
          {...feedbackLoopTooltipText}
        />
      </Typography>
      <Box display='flex' flexDirection='row' gap={3} alignItems='flex-start'>
        <ToggleForm
          disabled={isDisabled || !hasFfr}
          value={lessonData.attributes.feedbackLoopValue}
          handleOnChange={async (_e, value) => {
            if (value === null || value === undefined) {
              return;
            }
            lessonData.attributes.feedbackLoopValue = value;
            lessonDataMaintenanceDomain.updateLessonData({ ...lessonData }, true);
          }}
          data-test='feedback-loop-toggle'
        />

        {!isDisabled && !hasFfr && (
          <Typography className='toggle-disable-message'>
            You will need to add at least one custom response page to enable the Feedback Loop.
          </Typography>
        )}
        {isDisabled && (
          <Typography className='toggle-disable-message'>
            This activity has already been assigned to one or more students. As a result, the toggle is now disabled.
          </Typography>
        )}
      </Box>

    </Box>
  );
};

export default FeedbackLoopSaveAction;

import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './SubmittedAnswerIndicator.scss';
// import { useLessonPlay } from '../../../context/LessonPlayContextProvider';

/**
 * Sub-component for displaying Reject answer animation
 */
const SubmittedAnswerIndicator = ({ correctSubmission }) => {
  // const { lessonPlayDomain } = useLessonPlay();
  // const { isPlayable, isPreviewMode } = lessonPlayDomain.domainData;

  if (correctSubmission === null || correctSubmission === undefined) {
    return null;
  }
  return (
    <div className='submitted-answer-indicator' data-test='submitted-answer-indicator'>
      {correctSubmission && (
        <CheckIcon className='icon correct' />
      )}
      {!correctSubmission && (
        <CloseIcon className='icon incorrect' />
      )}
    </div>
  );
};

export default SubmittedAnswerIndicator;

SubmittedAnswerIndicator.defaultProps = {
  correctSubmission: undefined,
};

SubmittedAnswerIndicator.propTypes = {
  correctSubmission: PropTypes.bool,
};

import { createContext, useContext } from 'react';
import AuthoredLessonsSearchCriteriaDomain from './AuthoredLessonsSearchCriteriaDomain';
import { removeVizzleAuthoringWebSession } from '../../../../../utils/VizzleSessionStorage';

/**
 * Application domain (react hook for reducer) is a layer that
 * responsible for state management of the application.
 * Instead of using useReducer directly from react hook library,
 * Domain layer encapsulate dispatching event and data of the reducer.
 * The consumer (container layer) can manage the state of the application via the
 * provided methods rather than create a payload and call dispatch function directly.
 *
 * Domain can be separated based upon the data domain e.g. user, lessons and so on.
 */

/*-----------------------------------------------------------------------*/
// Register domains here
const domains = [
  new AuthoredLessonsSearchCriteriaDomain(true),
];

/*-----------------------------------------------------------------------*/

/**
 * Initialize application domains and add them to the context so that
 * they can be used accross the application
 */
let AuthoredLessonsDomainProviderContext;
const result = {};
export const initAuthoredLessonsDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  AuthoredLessonsDomainProviderContext = createContext(result);
};

/** Get application domains fromt the context */
export const useAuthoredLessonsDomain = () => useContext(AuthoredLessonsDomainProviderContext);

export const clearAllDomainData = () => {
  domains.forEach((element) => {
    element.resetDomainData();
  });
  removeVizzleAuthoringWebSession();
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CardAction from '../../../../../components/card/CardAction';
import ConfirmationDialog from '../../../../../components/dialog/ConfirmationDialog';
import './StudentGroupAction.scss';
import { useHandleDeleteStudentGroup } from './StudentGroupActionHooks';

export default function DeleteStudentGroupAction({
  group,
  onStudentGroupDeleted,
}) {
  const [openDialog, setOpenDialog] = useState();

  const {
    deleting,
    deleteNewStudentGroup,
  } = useHandleDeleteStudentGroup({
    onStudentGroupDeleted: () => {
      setOpenDialog(false);
      onStudentGroupDeleted();
    },
  });

  return (
    <>
      <CardAction
        icon={<DeleteIcon fontSize='small' className='delete-icon' />}
        actionHandler={() => { setOpenDialog(true); }}
        className='delete-student-group-action'
        title='Delete Student Group'
        ariaLabel='Delete'
        data-test={`delete-student-group-action-${group.id}`}
      />
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title='Delete Student Group'
          contentText={`Are you sure you want to delete student group ${group.name}?`}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={() => { deleteNewStudentGroup(group); }}
          onCancel={() => { setOpenDialog(false); }}
          loading={deleting}
          confirmClass='student-group-confirm-action'
          cancelClass='student-group-confirm-action'
        />
      ) : null}
    </>
  );
}

DeleteStudentGroupAction.defaultProps = {
  onStudentGroupDeleted: () => null,
};

DeleteStudentGroupAction.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  }).isRequired,
  onStudentGroupDeleted: PropTypes.func,
};

import { Button, FormControl, TextField } from '@mui/material';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import './ContactAccountManagerAction.scss';
import { usePrepareData, useCustomDialog, useHandleMessageTextField, useHandleOnSubmit } from './ContactAccountManagerActionHooks';
import CustomTooltip from '../../../../../components/card/CustomTooltip';
import '../../../components/buttons/Buttons.scss';

const ContactAccountManagerAction = () => {
  const {
    message,
    handleOnMessageChange,
    clearMessage,
  } = useHandleMessageTextField();

  const {
    open,
    handleOnCancel,
    openDialog,
  } = useCustomDialog({ clearMessage });

  const {
    accountManagerEmail,
  } = usePrepareData();

  const {
    loading,
    submitMessage,
  } = useHandleOnSubmit({
    accountManagerEmail,
    message,
    onSubmitSuccess: handleOnCancel,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: submitMessage,
      className: 'btn-primary action-button',
      title: 'Submit',
      disabled: !message || message.length === 0,
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <div className='contact-account-manager-action'>
        <CustomTooltip title='Contact Account Manager' aria-label='Contact Account Manager'>
          <span className='contact-account-manager-action-span'>
            <Button
              variant='contained'
              className='btn-primary admin-action-multiple-line-label action-button'
              onClick={openDialog}
              disabled={accountManagerEmail === null}
            >
              Contact <br />Account Manager
            </Button>
          </span>
        </CustomTooltip>
      </div>
      {open && (
        <CustomDialog
          openDialog={open}
          className='contact-account-dialog'
          onClose={handleOnCancel}
          title='Contact Account Manager'
          content={
            (
              <FormControl variant='outlined' className='form-container'>
                <TextField aria-readonly label='Email' value={accountManagerEmail} name='email' />
                <TextField
                  multiline
                  minRows={4}
                  maxRows={4}
                  label='Message'
                  value={message}
                  name='message'
                  onChange={handleOnMessageChange}
                  autoFocus
                  className='message-field'
                />
              </FormControl>
            )
          }
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

export default ContactAccountManagerAction;

import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';
import CurriculumBookmarkAction from './CurriculumBookmarkAction';

/**
 * Lesson card action
 */
// eslint-disable-next-line import/prefer-default-export
export const createLessonCardAction = (lesson, user, userProfile, terminologies) => {
  const actions = [(
    <PreviewLessonAction
      lesson={lesson}
      className='top-left'
      key={`teacher-curriculum-lesson-preview-${lesson.id}`}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />
  ),
  (
    <CurriculumBookmarkAction
      lesson={lesson}
      className='top-left'
      key={`teacher-curriculum-bookmark-lesson-${lesson.id}`}
    />
  ),
  ];
  return actions;
};

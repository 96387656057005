import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useFetchAuthoredFromService, FILTER_STATUS } from '../AuthoredLessonHooks';
import { useAuthoredLessonsDomain } from '../stores/AuthoredLessonsDomainProvider';
import './AuthoredLessonPendingFilter.scss';

const AuthoredLessonPendingFilter = () => {
  const { userDomain } = useDomain();
  const { authoredLessonsSearchCriteriaDomain } = useAuthoredLessonsDomain();
  const { selectedPendingStatus } = authoredLessonsSearchCriteriaDomain.domainData;
  const { user } = userDomain.domainData;

  const {
    fetchAuthoredFromService,
  } = useFetchAuthoredFromService();

  const handleStatusChange = async (e) => {
    await fetchAuthoredFromService(e.target.value);
  };

  if (!user.userModerator) {
    return null;
  }

  return (
    <div className='authored-lesson-pending-switch'>
      <FormControl component='fieldset' className='vizzle-vizzle-moderator-statuses'>
        <RadioGroup
          aria-label='Activity status'
          name='lessonStatus'
          onChange={handleStatusChange}
          value={selectedPendingStatus}
          className='radio-container'
        >
          {FILTER_STATUS.map((l, index) => (
            <FormControlLabel
              key={`lesson-authoring-lessons-status-${index}`}
              value={l.value}
              control={<Radio />}
              label={l.label}
              // className='vizzle-vizzle-moderator-approve-status'
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default AuthoredLessonPendingFilter;

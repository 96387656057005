import { useEffect } from 'react';
import ObjectUtils from '../../utils/ObjectUtils';

export const useDisableRightClick = (isOpen) => {
  const disableFunction = (event) => event.preventDefault();

  const getCanvasElement = async () => {
    await ObjectUtils.delay(100);
    const canvasList = (document.getElementsByTagName('canvas')) || [];
    [].forEach.call(canvasList, (canvas) => {
      canvas.addEventListener('contextmenu', disableFunction, false);
    });
  };

  useEffect(() => {
    getCanvasElement();
    return () => {
      const canvasList = (document.getElementsByTagName('canvas')) || [];
      [].forEach.call(canvasList, (canvas) => {
        canvas.removeEventListener('contextmenu', disableFunction, false);
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
};

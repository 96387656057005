import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateFormatMMddyyyy, dateFormatyyyyMMdd, fullDateTimeFormatHHmm, timeFormatHHmm, timeFormatHHmmAmPm } from '../AppConstants';

const dateFunc = new AdapterDateFns();

const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 1);
yesterdayDate.setHours(0);
yesterdayDate.setMinutes(0);
yesterdayDate.setSeconds(0);
yesterdayDate.setMilliseconds(0);

const currentDate = new Date();
export default {
  convertMMddYYYYToYYYYMMdd: (dateMMddYYYY) => {
    if (!dateMMddYYYY) {
      return null;
    }
    return dateFunc.formatByString(dateFunc.parse(dateMMddYYYY, dateFormatMMddyyyy), dateFormatyyyyMMdd);
  },

  convertYYYYMMddToMMddYYYY: (dateYYYYMMdd) => {
    if (!dateYYYYMMdd) {
      return null;
    }
    return dateFunc.formatByString(dateFunc.parse(dateYYYYMMdd, dateFormatyyyyMMdd), dateFormatMMddyyyy);
  },

  convertDateToYYYYMMdd: (date) => dateFunc.formatByString(date, dateFormatyyyyMMdd),

  convertYYYYMMddToDate: (yyyyMMdd) => dateFunc.parse(yyyyMMdd, dateFormatyyyyMMdd),

  convertMMddYYYYToDate: (yyyyMMdd) => dateFunc.parse(yyyyMMdd, dateFormatMMddyyyy),

  convertDateToMMddYYYY: (date) => dateFunc.formatByString(date, dateFormatMMddyyyy),

  convertDateToFullDateTimeFormatHHmm: (date) => dateFunc.formatByString(date, fullDateTimeFormatHHmm),

  convertDateToTimeFormatHHmm: (date) => dateFunc.formatByString(date, timeFormatHHmm),

  convertDateToTimeFormatHHmmAmPm: (date) => dateFunc.formatByString(date, timeFormatHHmmAmPm),

  parseDate: (dateString, format) => dateFunc.parse(dateString, format),

  parseDateInMMddYYYY: (dateMMddYYYY) => {
    if (!dateMMddYYYY) {
      return null;
    }
    return dateFunc.parse(dateMMddYYYY, dateFormatMMddyyyy);
  },

  getYesterdayDate: () => yesterdayDate,

  getYesterdayDateInMMddYYYY: () => dateFunc.formatByString(yesterdayDate, dateFormatMMddyyyy),

  getLastYearDateMinusOneInMMddYYYY: () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setFullYear(date.getFullYear() - 1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return dateFunc.formatByString(date, dateFormatMMddyyyy);
  },
  getCurrentDate: () => currentDate,

  get30DaysFromCurrentDate: () => {
    const maxDate = new Date(currentDate.getTime());
    maxDate.setDate(maxDate.getDate() + 30);
    return maxDate;
  },

  convertDateString: (dateString, dateStringFormat, targetFormat) => dateFunc.formatByString(dateFunc.parse(dateString, dateStringFormat), targetFormat),
};

import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  // ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../../AppConstants';
import {
  getInitialDataByAcivityType,
} from '../../../../utils/activitytype/ActivityTypeInitialData';
import { checkEmptyActivity } from '../../../../utils/activitytype/ActivityTypeEmptyChecker';
import ButtonSelector from '../../../../components/selector/ButtonSelector';
import ConfirmationDialog from '../../../../components/dialog/ConfirmationDialog';
import BookLayout from './layout/BookLayout';
import MatchingLayout from './layout/MatchingLayout';
import SortingLayout from './layout/SortingLayout';
import GameLayout from './layout/GameLayout';
import SequencingLayout from './layout/SequencingLayout';
import EditableLessonTitleContainer from './EditableLessonTitleContainer';
import { useDomain } from '../../../../states/AppDomainProvider';
import CauseAndEffectLayout from './layout/CauseAndEffectLayout';
import FillInTheBlankLayout from './layout/FillInTheBlankLayout';
import CategorizingLayout from './layout/CategorizingLayout';
import FlexiMatchingLayout from './layout/FlexiMatchingLayout';
// import MultipleChoiceLayout from './layout/MultipleChoiceLayout';
import MultipleChoiceV2Layout from './layout/MultipleChoiceV2Layout';

/**
 * Container for manager Lesson Layout screen
 */
export default function LessonLayoutContainer() {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];
  const { user } = userDomain.domainData;

  const pageTypeSelector = ([
    {
      label: 'Build A Book',
      dataValue: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
    },
    {
      label: 'Matching',
      dataValue: ACTIVITY_TYPE_MATCHING_ACTIVITY,
    },
    {
      label: 'Sorting',
      dataValue: ACTIVITY_TYPE_SORTING_ACTIVITY,
    },
    {
      label: 'Game',
      dataValue: ACTIVITY_TYPE_GAME_ACTIVITY,
    },
    {
      label: 'Sequencing',
      dataValue: ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
    },
    // {
    //   label: 'Fill in the Blank',
    //   dataValue: ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
    // },
    {
      label: 'Categorizing',
      dataValue: ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
    },
    {
      label: 'Advanced Matching',
      dataValue: ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
    },
    // {
    //   label: 'Multiple Choice',
    //   dataValue: ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
    // },
    {
      label: 'Multiple Choice',
      dataValue: ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
    },
  ]);

  if (user.features && user.features.includes('CauseAndEffect')) {
    pageTypeSelector.push({
      label: 'Cause and Effect',
      dataValue: ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
    });
  }

  const [state, setState] = useState({
    selectedLayout: null,
    changeLayoutDialog: {
      open: false,
      selectedLayoutType: {},
    },
  });

  /**
   * Change the lesson layout by the activityType
   * @param {*} activityType activit type
   */
  const changeLessonLayout = async (activityType) => {
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, getInitialDataByAcivityType(activityType));
  };

  /**
   * Handle page type (layout) selection
   * @param {*} dataValue new value (activity type)
   */
  const handleSelectedPageType = async (dataValue) => {
    // Switch layout
    const selectedData = pageTypeSelector.find((p) => (
      dataValue === p.dataValue
    ));

    // Display popup when changing layout
    if (selectedData) {
      // check if the card id empty
      if (checkEmptyActivity(selectedPage)) {
        setState({
          ...state,
          selectedLayout: dataValue,
        });
        await changeLessonLayout(dataValue);
      } else {
        // Not and empty card, display a confirmation dialog box
        setState({
          ...state,
          changeLayoutDialog: {
            open: true,
            selectedLayoutType: selectedData,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!selectedPage) {
      setState({
        ...state,
        selectedLayout: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
      });
      return;
    }

    setState({
      ...state,
      selectedLayout: selectedPage.activityType,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedPagesData]);

  const dialogProps = {
    open: state.changeLayoutDialog.open,
    title: 'Change Page Layout',
    contentText: (
      <span>
        Are you sure you want to change to &nbsp;
        <strong>{`${state.changeLayoutDialog.selectedLayoutType.label}`}</strong>
        &nbsp;layout ?
      </span>
    ),
    confirmLabel: 'Yes',
    cancelLabel: 'No',
    onConfirm: async () => {
      const { selectedLayoutType: { dataValue } } = state.changeLayoutDialog;
      await changeLessonLayout(dataValue);
      setState({
        ...state,
        selectedLayout: dataValue,
        changeLayoutDialog: {
          ...state.changeLayoutDialog,
          open: false,
        },
      });
    },
    onCancel: () => {
      setState({
        ...state,
        changeLayoutDialog: {
          ...state.changeLayoutDialog,
          open: false,
        },
      });
    },
  };

  return (
    <div>

      <Box display='flex' pt='10px'>
        <EditableLessonTitleContainer />
      </Box>
      <br />
      <Typography variant='h5'>Page Type</Typography>
      <br />
      <ButtonSelector
        config={pageTypeSelector}
        onSelect={handleSelectedPageType}
        selectedValue={state.selectedLayout}
        className='vizzle-page-type-selector'
        data-test='page-type-selector'
      />
      {selectedPage && (
        selectedPage.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA
        || selectedPage.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA
      )
        && <BookLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY && <MatchingLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY && <SortingLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_GAME_ACTIVITY && <GameLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY && <SequencingLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY && <CauseAndEffectLayout />}
      {selectedPage && [ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE, ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING].includes(selectedPage.activityType) && <FillInTheBlankLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY && <CategorizingLayout />}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY && <FlexiMatchingLayout />}
      {/* {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY && <MultipleChoiceLayout />} */}
      {selectedPage && selectedPage.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2 && <MultipleChoiceV2Layout />}
      <ConfirmationDialog {...dialogProps} className='vizzle-change-layout-dialog' />
    </div>
  );
}

import SettingSelectorForm from './form/SettingSelectorForm';
import {
  SEASONAL_FILTER_OPTIONS,
  // THEME_OPTIONS,
  REINFORCERS_OPTIONS,
  STUDENT_SETTINGS_OPTIONS,
  CELEBRATIONS_OPTIONS,
} from '../../../../constants/PlayPreferenceConstant';
import SettingOffButtonForm from './button/SettingOffButtonForm';
import { studentSettingsAllStudentsTooltipText } from '../../../../texts/TooltipText';
import { disableAllReinforcers, disableAllCelebrations } from '../../../../services/UserProfileService';

const GeneralApperance = () => (
  <>
    <SettingSelectorForm label='Seasonal Filter' options={SEASONAL_FILTER_OPTIONS} attributeNameToSave='filter' />
    {/* <SettingSelectorForm label='Theme' options={THEME_OPTIONS} attributeNameToSave='playerWorld' /> */}
    <SettingOffButtonForm
      label='Reinforcers'
      options={REINFORCERS_OPTIONS}
      attributeNameToSave='studentIds'
      confirmTitle='Set All Reinforcers to Off'
      confirmContent='Are you sure you want to turn off all reinforcers?'
      serviceFunction={disableAllReinforcers}
      data-test='set-all-reinforcer-to-off'
    />
    <SettingOffButtonForm
      label='Celebrations'
      options={CELEBRATIONS_OPTIONS}
      attributeNameToSave='studentIds'
      confirmTitle='Set All Celebrations to Off'
      confirmContent='Are you sure you want to turn off all celebrations?'
      serviceFunction={disableAllCelebrations}
      data-test='set-all-celebration-to-off'
    />
    <SettingSelectorForm label='Student Settings' options={STUDENT_SETTINGS_OPTIONS} attributeNameToSave='studentSettings' tooltip={studentSettingsAllStudentsTooltipText} />
  </>
);

export default GeneralApperance;

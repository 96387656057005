/* eslint-disable no-unused-vars */
import Logger from '../../../../utils/Logger';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
// import { useTeacherCourses } from '../../../courses/context/TeacherCourseContextProvider';
import {
  archiveUnarchiveAssignCoursesLesson,
  removeLessonAssignCoursesLesson,
} from '../../../../services/CourseService';
import { archiveLessons } from '../../../../services/LessonService';
import { getSubjectId } from '../../../../utils/LessonsMapping';
// import { useGetAssignedStudentCourses } from '../AssignedStudentCoursesHooks';

export const useArchiveUnarchiveLesson = ({
  courseAssignmentId,
  student,
  date,
  onArchiveSuccess,
}) => {
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const updateLesson = async (lesson) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const archiveFlag = true;
        const currentSubjectId = getSubjectId(lesson);
        const archiveCourseLesson = archiveUnarchiveAssignCoursesLesson(student.id, courseAssignmentId, lesson.id, currentSubjectId, archiveFlag);
        const archiveStudentLesson = archiveLessons([lesson.id], archiveFlag, student.id);
        await Promise.all([archiveCourseLesson, archiveStudentLesson]);
        await onArchiveSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Activity archived for ${student.userName} on ${date}`);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    updateLesson,
  };
};

export const useDeleteAssingedLesson = ({
  courseAssignmentId,
  student,
  date,
  onDeleteSuccess,
}) => {
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const deleteAssingedLesson = async (lesson, isDeleteAllSelected) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const currentSubjectId = getSubjectId(lesson);
        if (isDeleteAllSelected) {
          const archiveFlag = true;
          await archiveUnarchiveAssignCoursesLesson(student.id, courseAssignmentId, lesson.id, currentSubjectId, archiveFlag);
        } else {
          await removeLessonAssignCoursesLesson(student.id, courseAssignmentId, lesson.id, currentSubjectId);
        }
        await onDeleteSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Activity deleted for ${student.userName} on ${date}`);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    deleteAssingedLesson,
  };
};

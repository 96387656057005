import { useEffect, useState } from 'react';
import {
  NON_ACADEMIC_SUBJECTS,
  SUBJECT_CONSTANTS,
} from '../../../../constants/LessonConstant';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { getTeachersForStudent } from '../../../../services/StudentService';
import { getStudentReport } from '../../../../services/ReportService';
import { updateLessonPlayInfo } from '../../../../services/LessonPlayService';
import { addWrappedText, buildDataTable } from '../../../../components/export/PdfTableExport';
import { sort } from '../../../../utils/ArrayUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
import Logger from '../../../../utils/Logger';
import DateUtils from '../../../../utils/DateUtils';
import { getReportDataToDisplay, renderStudentViewAnswerAction } from '../../common/actions/view-student-answers/ViewStudentAnswersUtils';

export const ALL_SELECTOR = {
  id: 0,
  value: 0,
  name: 'All',
};

const endDate = new Date();
endDate.setHours(0);
endDate.setMinutes(0);
endDate.setSeconds(0);
endDate.setMilliseconds(0);
const startDate = new Date();
startDate.setDate(endDate.getDate() - 90);
startDate.setHours(0);
startDate.setMinutes(0);
startDate.setSeconds(0);
startDate.setMilliseconds(0);

const startTime = new Date();
startTime.setHours(0);
startTime.setMinutes(0);
startTime.setSeconds(0);
startTime.setMilliseconds(0);

const endTime = new Date();
endTime.setHours(23);
endTime.setMinutes(59);
endTime.setSeconds(0);
endTime.setMilliseconds(0);

export const createRenderer = (selected, list) => {
  const selectedValues = selected.map((s) => (
    list.find((subject) => subject.value === s)
  ));
  return selectedValues.map((s) => s && s.name).join(', ');
};

const subjects = [
  { ...ALL_SELECTOR },
  { id: SUBJECT_CONSTANTS.english.subjectId, value: SUBJECT_CONSTANTS.english.subjectId, name: SUBJECT_CONSTANTS.english.name },
  { id: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
  { id: SUBJECT_CONSTANTS.science.subjectId, value: SUBJECT_CONSTANTS.science.subjectId, name: SUBJECT_CONSTANTS.science.name },
  { id: SUBJECT_CONSTANTS.social.subjectId, value: SUBJECT_CONSTANTS.social.subjectId, name: SUBJECT_CONSTANTS.social.name },
  { id: SUBJECT_CONSTANTS.speech.subjectId, value: SUBJECT_CONSTANTS.speech.subjectId, name: SUBJECT_CONSTANTS.speech.name },
  { id: SUBJECT_CONSTANTS.arts.subjectId, value: SUBJECT_CONSTANTS.arts.subjectId, name: SUBJECT_CONSTANTS.arts.name },
  { id: SUBJECT_CONSTANTS.life.subjectId, value: SUBJECT_CONSTANTS.life.subjectId, name: SUBJECT_CONSTANTS.life.name },
  { id: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.socialSkills.name },
  { id: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.transitionalSkills.name },
];

export const useGetReportTableConfig = () => {
  const { myStudentDomain } = useStudentContext();
  const {
    trackingDetailedReport,
    selectedStudentProfile,
  } = myStudentDomain.domainData;

  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;

  /** Table configuration */
  const tableConfig = [
    {
      title: 'Activity Name',
      align: 'left',
      dataField: 'lessonName',
      width: '22%',
      dataRenderer: (data) => renderStudentViewAnswerAction({
        data,
        reviewFeedbackData: trackingDetailedReport.reportData,
        currentSelectedStudentId,
        onUpdateReviewStatusSuccess: () => { },
        feedbackChat: selectedStudentProfile.feedbackChat,
        onChatWidgetOpen: () => {
          // eslint-disable-next-line no-param-reassign
          data.chatNotification = false;
          if (currentSelectedStudentId) {
            const currentStudent = students.find((s) => s.id === currentSelectedStudentId);
            currentStudent.chatNotificationUnread = false;
          }
        },
        showIcons: true,
      }),
      skipDataRenderForReport: true,
    },
    {
      title: 'Complete',
      align: 'center',
      dataField: 'completed',
      width: '7%',
      dataRenderer: (data) => {
        if (data.completed === undefined || data.completed === null) {
          return 'N/A';
        }
        return data.completed ? 'Y' : 'N';
      },
    },
    {
      title: '%',
      align: 'center',
      dataField: 'correctPercentage',
      width: '4%',
      dataRenderer: (data) => {
        if (data.totalQuestions > 0) {
          return data.correctPercentage > 0 ? data.correctPercentage.toString() : '0';
        } else {
          return data.correctPercentage > 0 ? data.correctPercentage.toString() : 'N/A';
        }
      },
    },
    {
      title: 'Questions',
      align: 'center',
      dataField: 'totalQuestions',
      width: '8%',
    },
    {
      title: 'Correct',
      align: 'center',
      dataField: 'correctAnswers',
      width: '7%',
      dataRenderer: (data) => {
        if (data.totalQuestions > 0) {
          return data.correctAnswers > 0 ? data.correctAnswers.toString() : '0';
        } else {
          return data.correctAnswers > 0 ? data.correctAnswers.toString() : 'N/A';
        }
      },
    },
    {
      title: 'Incorrect',
      align: 'center',
      dataField: 'incorrectAnswers',
      width: '7%',
      dataRenderer: (data) => {
        if (data.totalQuestions > 0) {
          return data.incorrectAnswers > 0 ? data.incorrectAnswers.toString() : '0';
        } else {
          return data.incorrectAnswers > 0 ? data.incorrectAnswers.toString() : 'N/A';
        }
      },
    },
    {
      title: 'Start Time',
      align: 'center',
      dataField: 'startTime',
      width: '16%',
      dataRenderer: (data) => (
        data.startTime ? DateUtils.convertDateToFullDateTimeFormatHHmm(new Date(data.startTime)) : 'N/A'
      ),
    },
    {
      title: 'Minutes',
      align: 'center',
      dataField: 'totalTimeInMinutes',
      width: '5%',
    },
    {
      title: 'Logged-in User',
      align: 'center',
      dataField: 'userName',
      width: '13%',
    },
    {
      title: 'Notes',
      align: 'center',
      dataField: 'note',
      width: '9%',
      skipDataRenderForReport: true,
      dataRenderer: (data) => {
        const { id, note, isDirty } = data;
        return (
          <>
            <textarea
              key={`note-text-area-${id}`}
              rows='3'
              maxLength='255'
              style={{
                resize: 'vertical',
                borderRadius: '3px',
                minHeight: '50px',
                maxHeight: '200px',
              }}
              onChange={async (e) => {
                e.target.nextSibling.style.display = 'block';
              }}
              onBlur={async (e) => {
                // eslint-disable-next-line no-param-reassign
                data.note = e.target.value;
                // eslint-disable-next-line no-param-reassign
                data.isDirty = true;
                const { criteria, reportData, summaryData } = trackingDetailedReport;
                await myStudentDomain.updateDetailedReport(criteria, [...reportData], summaryData);
              }}
              defaultValue={note}
            />
            <button
              type='button'
              key={`note-submit-button-${id}`}
              style={{ display: isDirty ? 'block' : 'none' }}
              onClick={async (e) => {
                const element = e.target;
                try {
                  await updateLessonPlayInfo('patch', data.id, { note: element.previousSibling.value });
                  element.style.display = 'none';
                } catch (error) {
                  Logger.logError(error);
                }
              }}
            >
              Submit
            </button>
          </>
        );
      },
    },
  ];

  return tableConfig;
};

export const summaryTableConfig = [
  {
    title: 'Avg Percent',
    align: 'left',
    dataField: 'avgCorrectPercentage',
    width: '13%',
  },
  {
    title: 'Avg Questions',
    align: 'left',
    dataField: 'avgQuestions',
    width: '15%',
  },
  {
    title: 'Avg Right',
    align: 'left',
    dataField: 'avgCorrectAnswers',
    width: '10%',
  },
  {
    title: 'Avg Wrong',
    align: 'left',
    dataField: 'avgIncorrectAnswers',
    width: '10%',
  },
  {
    title: 'Avg Activity Time',
    align: 'left',
    dataField: 'avgTimeInMinutes',
    width: '16%',
  },
  {
    title: 'Total Activity Plays',
    align: 'left',
    dataField: 'totalNumberOfPlaysWithQuestions',
    width: '16%',
  },
];

/** Hook functions */
export const useGetTeachersForStudent = () => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;
  const [teacherSelectorList, setTeacherSelectorList] = useState([{ ...ALL_SELECTOR }]);
  const currentStudent = students && students.find((s) => s.id === currentSelectedStudentId);

  useEffect(() => {
    const getTeachersForStudentFromService = async (studentId) => {
      const teachers = await getTeachersForStudent(studentId);
      const teachersForStudent = teachers.data.map((teacher) => {
        const { attributes: { userName } } = teacher;
        return {
          id: userName,
          value: userName,
          name: userName,
        };
      });
      teachersForStudent.unshift({
        id: currentStudent.userName,
        value: currentStudent.userName,
        name: currentStudent.userName,
      });
      teachersForStudent.unshift({ ...ALL_SELECTOR });
      setTeacherSelectorList(teachersForStudent);
    };
    if (currentSelectedStudentId) {
      getTeachersForStudentFromService(currentSelectedStudentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    teacherSelectorList,
  };
};

export const useGetLessonTags = () => {
  const { myStudentDomain } = useStudentContext();
  const { lessonTags } = myStudentDomain.domainData;
  const lessonTagsList = lessonTags.map((tag) => {
    const { attributes: { name } } = tag;
    return {
      id: tag.id,
      value: tag.id,
      name,
    };
  });
  lessonTagsList.unshift({ ...ALL_SELECTOR });
  return {
    lessonTagsList,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const useDetailedReportFilter = () => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const [validDates, setValidDates] = useState(true);

  const [selectedCriteria, setSelectedCriteria] = useState({
    startDate,
    endDate,
    startTime,
    endTime,
    showCompletedLessonsOnly: false,
    // feedbackLoopOnly: false,
  });

  const { myStudentDomain } = useStudentContext();
  const {
    trackingDetailedReport: { criteria },
  } = myStudentDomain.domainData;

  useEffect(() => {
    setSelectedCriteria({
      startDate: criteria.startDate || startDate,
      endDate: criteria.endDate || endDate,
      startTime: criteria.startTime || startTime,
      endTime: criteria.endTime || endTime,
      subjects: criteria.subjects || [ALL_SELECTOR.value],
      teachers: criteria.teachers || [ALL_SELECTOR.value],
      objectives: criteria.objectives || [ALL_SELECTOR.value],
      showCompletedLessonsOnly: criteria.showCompletedLessonsOnly || false,
      // feedbackLoopOnly: criteria.feedbackLoopOnly || false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStudentId]);

  const handleOnDataChange = (value, field, isValidDate) => {
    setSelectedCriteria({
      ...selectedCriteria,
      [field]: value,
    });
    if (isValidDate !== undefined) {
      setValidDates(isValidDate);
    }
  };

  const handleOnSelectorChange = (value, field, allValue) => {
    const oldValue = selectedCriteria[field];
    const difference = value.filter((x) => !oldValue.includes(x));

    if (difference.includes(allValue) || value.length === 0) {
      setSelectedCriteria({
        ...selectedCriteria,
        [field]: [allValue],
      });
    } else {
      setSelectedCriteria({
        ...selectedCriteria,
        [field]: value.filter((v) => v !== allValue),
      });
    }
  };

  return {
    selectedCriteria,
    validDates,
    handleOnDataChange,
    handleOnSelectorChange,
  };
};

export const useSubmitCriteria = () => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;

  const { myStudentDomain } = useStudentContext();
  const {
    trackingDetailedReport,
  } = myStudentDomain.domainData;

  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(trackingDetailedReport.reportData.length > 0);

  const handleOnSubmitButtonClick = async (selectedCriteria) => {
    setLoading(true);
    const searchCriteria = {
      studentId: currentSelectedStudentId,
      startDate: DateUtils.convertDateToYYYYMMdd(selectedCriteria.startDate),
      endDate: DateUtils.convertDateToYYYYMMdd(selectedCriteria.endDate),
    };

    const formattedStartTime = DateUtils.convertDateToTimeFormatHHmm(startTime);
    const formattedSelectedStartTime = DateUtils.convertDateToTimeFormatHHmm(selectedCriteria.startTime);

    if (formattedStartTime !== formattedSelectedStartTime) {
      searchCriteria.startTime = `${formattedSelectedStartTime}:00`;
    }

    const formattedEndTime = DateUtils.convertDateToTimeFormatHHmm(endTime);
    const formattedSelectedEndTime = DateUtils.convertDateToTimeFormatHHmm(selectedCriteria.endTime);

    if (formattedEndTime !== formattedSelectedEndTime) {
      searchCriteria.endTime = `${formattedSelectedEndTime}:99`;
    }

    // did not select ALL
    if (selectedCriteria.subjects && !selectedCriteria.subjects.includes(ALL_SELECTOR.value)) {
      searchCriteria.subjects = selectedCriteria.subjects.join(',');
    }

    // did not select ALL
    if (selectedCriteria.teachers && !selectedCriteria.teachers.includes(ALL_SELECTOR.value)) {
      searchCriteria.userName = selectedCriteria.teachers.join(',');
    }

    // did not select ALL
    if (selectedCriteria.objectives && !selectedCriteria.objectives.includes(ALL_SELECTOR.value)) {
      searchCriteria.objectives = selectedCriteria.objectives.join(',');
    }

    if (selectedCriteria.showCompletedLessonsOnly) {
      searchCriteria.showCompletedLessonsOnly = selectedCriteria.showCompletedLessonsOnly;
    }

    // if (selectedCriteria.feedbackLoopOnly) {
    //   searchCriteria.feedbackLoopOnly = selectedCriteria.feedbackLoopOnly;
    // }

    const queryCriteria = {
      studentId: searchCriteria.studentId,
      startDate: searchCriteria.startDate,
      endDate: searchCriteria.endDate,
      startTime: searchCriteria.startTime,
      endTime: searchCriteria.endTime,
      subjectId: searchCriteria.subjects,
      objectiveId: searchCriteria.objectives,
      userName: searchCriteria.userName,
      showCompletedLessonsOnly: searchCriteria.showCompletedLessonsOnly,
      // feedbackLoopOnly: searchCriteria.feedbackLoopOnly,
    };
    const result = await getStudentReport(queryCriteria);
    const { data, meta } = result;
    const tableData = data.map((d) => {
      const { attributes } = d;
      const transformedAttributed = {
        ...attributes,
        totalTimeInMinutes: Number(attributes.totalTimeInMinutes),
        note: attributes.note || '',
      };
      return ({
        id: d.id,
        type: d.type,
        ...transformedAttributed,
      });
    });
    const sortedTableData = sort(tableData, 'startTime');
    setHasLoaded(true);
    await myStudentDomain.updateDetailedReport(selectedCriteria, sortedTableData, meta || {});
    setLoading(false);
  };

  const { criteria } = trackingDetailedReport;
  useEffect(() => {
    if (!ObjectUtils.isEmpty(criteria)) {
      handleOnSubmitButtonClick(criteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    reportData,
    summaryData,
  } = trackingDetailedReport;

  const reportDataToDisplay = getReportDataToDisplay(reportData);
  return {
    loading,
    handleOnSubmitButtonClick,
    reportDataToDisplay,
    summaryData,
    hasLoaded,
  };
};

export const useCustomExportPdf = (lessonTagsList) => {
  const { myStudentDomain } = useStudentContext();
  const {
    trackingDetailedReport,
  } = myStudentDomain.domainData;

  const customPrintPdf = (defaultComponents, doc, currentStudent) => {
    const {
      logo,
      titleRow,
      reportCreated,
      table,
      filename,
    } = defaultComponents;

    const { criteria, summaryData } = trackingDetailedReport;
    doc.addImage(logo.image, logo.imageType, logo.x, logo.y, logo.width, logo.height);
    doc.text(titleRow.titleText, titleRow.x, titleRow.y, titleRow.options, titleRow.transform, titleRow.align);

    doc.setFontSize(10);
    const { x } = reportCreated;
    let { y } = reportCreated;
    doc.text(`Student Username: ${currentStudent.userName}`, x, y);

    y += 5;
    doc.text(`Date Start: ${DateUtils.convertDateToMMddYYYY(criteria.startDate)}     End: ${DateUtils.convertDateToMMddYYYY(criteria.endDate)}`, x, y);

    y += 5;
    doc.text(`Time Start: ${DateUtils.convertDateToTimeFormatHHmmAmPm(criteria.startTime)}     End: ${DateUtils.convertDateToTimeFormatHHmmAmPm(criteria.endTime)}`, x, y);

    y += 7;
    y = addWrappedText({
      text: `Goals/Objectives: ${createRenderer(criteria.objectives, lessonTagsList)}`,
      textWidth: 170,
      doc,
      lineSpacing: 5,
      xPosition: x,
      initialYPosition: y,
      pageWrapInitialYPosition: 10,
    });

    y = criteria.objectives.length > 1 ? y += 3 : y += 1;
    y = addWrappedText({
      text: `Subjects: ${createRenderer(criteria.subjects, subjects)}`,
      textWidth: 170,
      doc,
      lineSpacing: 5,
      xPosition: x,
      initialYPosition: y,
      pageWrapInitialYPosition: 10,
    });

    y = criteria.subjects.length > 1 ? y += 3 : y += 1;
    doc.text(reportCreated.title, x, y);

    y += 7;
    doc.text('Summary', x, y);

    y += 3;
    const summaryTable = buildDataTable(summaryTableConfig, [summaryData]);
    doc.table(x, y, summaryTable.data, summaryTable.headers, { fontSize: 8, padding: 1, margins: 0 });

    y += 20;
    doc.setFontSize(10);
    doc.text('Details', x, y);

    y += 3;
    // doc.setLineHeightFactor(1);
    doc.table(x, y, table.data.map((t) => {
      const { lessonName } = t;
      const reg = lessonName.match(/.{1, 23}/g);
      const l = reg ? reg.join('\n\n') : lessonName;
      return {
        ...t,
        lessonName: l,
      };
    }), table.headers, { ...table.options, fontSize: 6 });

    doc.save(filename);
  };

  return customPrintPdf;
};

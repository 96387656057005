import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CardAction from '../../../components/card/CardAction';

/**
 * Copy Lesson Action Container
 */
export default function EditLessonAction({ lesson, className, goBackTo }) {
  const navigate = useNavigate();
  return (
    <CardAction
      icon={<BorderColorIcon />}
      actionHandler={() => {
        navigate(`/lessons/${lesson.attributes.uuid}/update`,
          {
            state: {
              goBackTo,
            },
          });
      }}
      className={className}
    />
  );
}

EditLessonAction.defaultProps = {
  className: '',
  goBackTo: null,
};

EditLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
  goBackTo: PropTypes.string,
};

import { useEffect, useState } from 'react';
import { dataURLtoFile } from '../../../../../components/vizzle/uploader/VideoToImage';
import { saveFreeFormAnswer } from '../../../../../services/AnswerFreeFormService';
import { uploadMedia } from '../../../../../services/MediaService';
import Logger from '../../../../../utils/Logger';
import { isTeacher } from '../../../../../utils/User';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useLessonPlay } from '../../../context/LessonPlayContextProvider';

export const useHandleDialog = ({
  responseCard,
}) => {
  const [open, setOpen] = useState(false);

  const handleOnCardClick = () => {
    if (!responseCard.text) {
      setOpen(true);
    }
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return {
    open,
    handleOnCardClick,
    openDialog,
    closeDialog,
  };
};

export const useHandleSubmitFreeTextAnswer = ({
  responseCard,
  onCorrectAnswerSelected,
  onActivityFinished,
  cardNumber,
  registeredLessonRef,
  studentHistoryId,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { lessonPlayDomain } = useLessonPlay();
  const { lesson, currentPageIndex, user, isPreviewMode, lessonPagesData } = lessonPlayDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const isTeacherUser = isTeacher(user);

  const handleOnSubmit = async (event) => {
    event.stopPropagation();
    if (isTeacherUser) {
      return;
    }

    try {
      await callRemoteServiceWrapper(async () => {
        let savedImageUrl = null;
        if (responseCard.drawingImage) {
          const fileNameToSave = `student-${user.id}-image-${String(new Date().getTime())}`;
          const imageFile = dataURLtoFile(responseCard.drawingImage, fileNameToSave);
          if (imageFile) {
            const { attributes: { s3Url } } = await uploadMedia(imageFile);
            savedImageUrl = s3Url;
            // await updateUserAssets({ file: imageFile, fileName, description: fileName });
          }
        }
        const dataToSave = {
          lessonId: lesson.lessonId,
          lessonPlayId: registeredLessonRef.current.lessonPlayId,
          pageNumber: currentPageIndex + 1,
          totalPages: lessonPagesData.length,
          cardNumber: cardNumber >= 0 ? cardNumber + 1 : 1,
          answer: {
            text: { ...responseCard.text },
            image: savedImageUrl,
            ffImages: responseCard.ffImages,
          },
          studentHistoryId,
        };
        await saveFreeFormAnswer(user.id, dataToSave);
        setIsDirty(false);
        await onCorrectAnswerSelected({ insertResponse: false, showReinforcer: true });
        onActivityFinished({ toNextPage: false });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  const shouldShowSubmitButton = !isPreviewMode && !isTeacherUser && isDirty && (responseCard.text || responseCard.drawingImage || (responseCard.ffImages && responseCard.ffImages.length > 0));

  return {
    handleOnSubmit,
    isDirty,
    setIsDirty,
    shouldShowSubmitButton,
    saving: loading,
    isPreviewMode,
    user,
  };
};

export const useHandleFreeTextData = ({
  open,
  responseCard,
  onChange,
  closeDialog,
  setIsDirty,
}) => {
  const initState = {
    isDirty: false,
    image: responseCard.image,
    text: responseCard.text || { lines: [] },
  };
  const [freeTextData, setFreeTextData] = useState(initState);

  useEffect(() => {
    setFreeTextData(initState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOnImageChange = (image) => {
    if (image && image.image && image.operationsDone > 0) {
      setFreeTextData((previousState) => (
        {
          ...previousState,
          isDirty: true,
          image: image.image.asDataURL(),
        }
      ));
    }
  };

  const handleOnTextChange = (textLines) => {
    if (!textLines) {
      return;
    }

    setFreeTextData((previousState) => (
      {
        ...previousState,
        isDirty: true,
        text: {
          lines: textLines,
        },
      }
    ));
  };

  const handleOnFfImageChange = (ffImages) => {
    setFreeTextData((previousState) => (
      {
        ...previousState,
        isDirty: true,
        ffImages,
      }
    ));
  };

  const handleOnApplyFreeTextData = () => {
    const { text } = responseCard;
    const currentTextLines = text ? text.lines : [];
    const newTextLines = freeTextData.text.lines.map((e, index) => ({
      ...currentTextLines[index],
      ...e,
    }));

    if (!responseCard.text) {
      // eslint-disable-next-line no-param-reassign
      responseCard.text = {};
    }

    if (newTextLines.length === 0) {
      // eslint-disable-next-line no-param-reassign
      responseCard.text = undefined;
    } else {
      // eslint-disable-next-line no-param-reassign
      responseCard.text.lines = newTextLines;
    }

    // eslint-disable-next-line no-param-reassign
    responseCard.drawingImage = freeTextData.image;

    // eslint-disable-next-line no-param-reassign
    responseCard.ffImages = freeTextData.ffImages;

    // responseCard.image = freeTextData.image;
    onChange();
    closeDialog();
    setIsDirty(true);
  };

  return {
    freeTextData,
    handleOnImageChange,
    handleOnTextChange,
    handleOnApplyFreeTextData,
    handleOnFfImageChange,
  };
};

import { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useGetAssignedStudentCourses } from './AssignedStudentCoursesHooks';
import './TeacherAssignedStudentCoursePanelTab.scss';
import TeacherCourseAssignmentForDaysAction from './actions/TeacherCourseAssignmentForDaysAction';
import TeacherAssignedStudentNoSelectedDateMessage from './TeacherAssignedStudentNoSelectedDateMessage';
import DateUtils from '../../../utils/DateUtils';

const ASSIGNMENT_CALENDAR_VALUE = {
  thisWeek: 'thisWeek',
  nextWeek: 'nextWeek',
  custom: 'custom',
};

// const dateFunc = new DateFnsUtils();

const TeacherAssignedStudentCoursePanelTab = forwardRef(({
  selectedDates,
  onSelectedDatesApply,
  onStartLoading,
  subjectId,
  onDataLoad,
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  isCustomAllowed,
  disabled,
}, ref) => {
  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
  });

  const [value, setValue] = useState(() => {
    if (!selectedDates || (!selectedDates.option && (!selectedDates.dates || selectedDates.dates.length === 0))) {
      return '';
    } else if (!selectedDates.option && selectedDates.dates && selectedDates.dates.length > 0) {
      return ASSIGNMENT_CALENDAR_VALUE.custom;
    } else {
      return selectedDates.option;
    }
  });

  useEffect(() => {
    if (selectedDates && selectedDates.dates && selectedDates.dates.length > 0) {
      getAssignedStudentData(selectedDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  const thisWeekToggle = useRef(null);
  const nextWeekToggle = useRef(null);

  useImperativeHandle(ref, () => ({
    toggleToThisWeek: () => {
      if (thisWeekToggle && thisWeekToggle.current) {
        thisWeekToggle.current.click();
      }
    },
    toggleToNexWeekk: () => {
      if (nextWeekToggle && nextWeekToggle.current) {
        nextWeekToggle.current.click();
      }
    },
  }));

  const handleChange = async (_field, newValue) => {
    if (!newValue) {
      return;
    }
    setValue(newValue);
    if (newValue === ASSIGNMENT_CALENDAR_VALUE.thisWeek) {
      const currentDate = new Date();
      const startWeekdate = startOfWeek(currentDate, { weekStartsOn: 1 });
      const endWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });

      const dates = {
        isDateRange: true,
        dates: [
          DateUtils.convertDateToMMddYYYY(startWeekdate),
          DateUtils.convertDateToMMddYYYY(endWeekDate),
        ],
        option: ASSIGNMENT_CALENDAR_VALUE.thisWeek,
      };
      await onSelectedDatesApply(dates);
      getAssignedStudentData(dates);
    } else if (newValue === ASSIGNMENT_CALENDAR_VALUE.nextWeek) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() + 7);

      const startWeekdate = startOfWeek(startDate, { weekStartsOn: 1 });
      const endWeekDate = endOfWeek(startDate, { weekStartsOn: 1 });

      const dates = {
        isDateRange: true,
        dates: [
          DateUtils.convertDateToMMddYYYY(startWeekdate),
          DateUtils.convertDateToMMddYYYY(endWeekDate),
        ],
        option: ASSIGNMENT_CALENDAR_VALUE.nextWeek,
      };
      await onSelectedDatesApply(dates);
      getAssignedStudentData(dates);
    } else {
      await onSelectedDatesApply({
        isDateRange: null,
        date: [],
        option: ASSIGNMENT_CALENDAR_VALUE.custom,
      });
      onDataLoad([]);
    }
  };

  return (
    <div className='teacher-assignment-student-course-tab'>
      {!disabled && value !== ASSIGNMENT_CALENDAR_VALUE.custom ? (
        <TeacherAssignedStudentNoSelectedDateMessage
          selectedDates={selectedDates}
        />
      ) : <div className='teacher-assigned-student-no-selected-date-message' />}

      <div className='teacher-assignment-student-course-tab-toggle-container'>
        <ToggleButtonGroup
          size='medium'
          value={value}
          onChange={handleChange}
          exclusive
          className='teacher-assignment-student-course-tab-toggle'
        >
          <ToggleButton ref={thisWeekToggle} disabled={disabled} value={ASSIGNMENT_CALENDAR_VALUE.thisWeek} data-test='this-week-button'>
            This Week
          </ToggleButton>
          <ToggleButton ref={nextWeekToggle} disabled={disabled} value={ASSIGNMENT_CALENDAR_VALUE.nextWeek} data-test='next-week-button'>
            Next Week
          </ToggleButton>
          {isCustomAllowed && (
            <ToggleButton disabled={disabled} value={ASSIGNMENT_CALENDAR_VALUE.custom} data-test='custom-date-button'>
              Custom
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        {value === ASSIGNMENT_CALENDAR_VALUE.custom && (
          <div className='assigned-student-courses-actions'>
            <TeacherCourseAssignmentForDaysAction
              selectedDates={selectedDates}
              onSelectedDatesApply={(d) => {
                onSelectedDatesApply({
                  option: ASSIGNMENT_CALENDAR_VALUE.custom,
                  ...d,
                });
              }}
              onStartLoading={onStartLoading}
              subjectId={subjectId}
              onDataLoad={onDataLoad}
              assignedStudentCourses={assignedStudentCourses}
              filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default TeacherAssignedStudentCoursePanelTab;

TeacherAssignedStudentCoursePanelTab.defaultProps = {
  selectedDates: {},
  onSelectedDatesApply: () => null,
  onStartLoading: () => null,
  subjectId: null,
  onDataLoad: () => null,
  assignedStudentCourses: [],
  filterCriteriaAssignedStudentCourses: {},
  isCustomAllowed: true,
  disabled: false,
};

TeacherAssignedStudentCoursePanelTab.propTypes = {
  selectedDates: PropTypes.shape({
    isDateRange: PropTypes.bool,
    dates: PropTypes.array,
    option: PropTypes.string,
  }),
  onSelectedDatesApply: PropTypes.func,
  onStartLoading: PropTypes.func,
  subjectId: PropTypes.number,
  onDataLoad: PropTypes.func,
  assignedStudentCourses: PropTypes.arrayOf(PropTypes.any),
  filterCriteriaAssignedStudentCourses: PropTypes.any,
  isCustomAllowed: PropTypes.bool,
  disabled: PropTypes.bool,
};

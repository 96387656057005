import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDomain } from '../../states/AppDomainProvider';
import { useStudentContext } from './context/MyStudentContextProvider';
import { getUserProfile, getTerminologies } from '../../services/UserProfileService';
import StudentTracking from './components/tracking/StudentTracking';
import StudentLessons from './components/lessons/StudentLessons';
import MyStudentsSettings from './components/settings/MyStudentsSettings';
import ReviewAndFeedback from './components/review-and-feedback/ReviewAndFeedback';
import ObjectUtils from '../../utils/ObjectUtils';
import { URL_MY_STUDENT } from '../../routes/RouteConstants';
import { MYSTUDENT_ROUTES } from './MyStudentConstants';

/**
 * Function to handle the vertical step state
 * @param {*} fetchStudentLesson the function to call when the Lesson step is selected
 */
export const useStep = () => {
  const myStudentSteps = [
    {
      label: 'Tracking',
      element: <StudentTracking />,
      link: MYSTUDENT_ROUTES.TRACKING,
    },
    {
      label: 'Activities',
      element: <StudentLessons />,
      link: MYSTUDENT_ROUTES.LESSONS,
    },
    {
      label: 'Settings',
      element: <MyStudentsSettings />,
      link: MYSTUDENT_ROUTES.SETTINGS,
    },
    {
      label: 'Review and Feedback',
      element: <ReviewAndFeedback />,
      link: MYSTUDENT_ROUTES.REVIEW_AND_FEEDBACK,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  // const { student } = location.state || location;
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const path = location.pathname && location.pathname.split('/');
    if (path && path.length >= 3) {
      const subPath = path[2];
      const index = myStudentSteps.findIndex((am) => am.link === subPath);
      setTabValue(index >= 0 ? index : 0);
    } else {
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleTabChange = (_event, newValue) => {
    navigate(`${URL_MY_STUDENT}/${myStudentSteps[newValue].link}`);
  };

  return {
    myStudentSteps,
    handleTabChange,
    tabValue,
  };
};

export const useUpdateCurrentSelectedStudent = () => {
  const location = useLocation();
  const { student } = location.state || location;
  const { studentsDomain } = useDomain();
  useEffect(() => {
    if (student) {
      studentsDomain.updateCurrentSelectedStudentId(student.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => () => {
    // clean up current selected student
    studentsDomain.updateCurrentSelectedStudentId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const getSelectedStudentProfiles = async (studentId) => getUserProfile(studentId);
const getTerminologiesUser = async (userId) => getTerminologies(userId);

export const useWhenStudentChange = () => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  useEffect(() => {
    if (currentSelectedStudentId) {
      const { criteria } = myStudentDomain.domainData.trackingDetailedReport;
      let detailReportCriteria = {};
      if (!ObjectUtils.isEmpty(criteria)) {
        const { endDate, endTime, startDate, startTime } = criteria;
        detailReportCriteria = {
          endDate, endTime, startDate, startTime,
        };
      }

      myStudentDomain.resetDomainData();
      myStudentDomain.isFetchingSelectedStudentProfile(true);
      const terminologiesUser = getTerminologiesUser(currentSelectedStudentId);
      const selectedStudentProfile = getSelectedStudentProfiles(currentSelectedStudentId);
      const fetchingStudentProfile = async () => {
        const [terminologiesUserData, selectedStudentProfileData] = await Promise.all([terminologiesUser, selectedStudentProfile]);

        const newTrackingDetailedReport = {
          ...myStudentDomain.domainData.trackingDetailedReport,
          criteria: {
            ...myStudentDomain.domainData.trackingDetailedReport.criteria,
            ...detailReportCriteria,
          },
        };
        const data = {
          terminologies: terminologiesUserData,
          selectedStudentProfile: selectedStudentProfileData,
          loadingStudent: false,
          trackingDetailedReport: newTrackingDetailedReport,
        };

        myStudentDomain.customUpdate(data);
      };
      fetchingStudentProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStudentId]);
};

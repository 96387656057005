const minPassword = 8;
export const invalidPassword = `Password needs to be minimum ${minPassword} characters with 1 number and 1 special character.`;
export const invalidConfirmPassword = `Confirm ${invalidPassword}`;
export const passwordMismatch = 'Password and Confirm Password do not match.';

const passwordReg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

export default {
  validatePassword: (password) => password.match(passwordReg),
};

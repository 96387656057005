import { useState, useEffect, useMemo } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import NoteIcon from '@mui/icons-material/Note';
import SaveIcon from '@mui/icons-material/Save';
import SlideShowIcon from '@mui/icons-material/Slideshow';
import VizzleAppBar from '../../../components/appbar/VizzleAppbar';
import VizzleMainContainer from '../../vizzle/VizzleMainContainer';
import VerticalStepper from '../../../components/steppers/VerticalStepper';
import LessonStepperSave from './actions/LessonStepperSave';
import LessonLayout from './steps/LessonLayout';
import LessonTextAndMedia from './steps/LessonTextAndMedia';
import LessonPreview from './steps/LessonPreview';
import LessonSave from './steps/LessonSave';
import LessonVizzleLibrary from './steps/LessonVizzleLibrary';
import LessonDataPageListSelector from './LessonDataPageListSelector';
import { useDomain } from '../../../states/AppDomainProvider';
import { unique } from '../../../utils/ArrayUtils';
import { APPROVED_FOR_COURSES, LESSON_STATUS_PENDING_NEXTGEN } from '../../../AppConstants';
import { useGetLessonStandardsByUser, usePrepareLessonPlayDomain } from './useInitilizeDataForAuthoring';
import { initDomains as initLessonPlayDomain, useLessonPlay } from '../../lessonplay/context/LessonPlayContextProvider';
import {
  STEP_VIZZLE_LIB,
  STEP_TEXT_MEDIA,
  useHandleSteps,
} from './LessonSteppersHooks';
import ReinforcerV2 from '../../terminologies/reinforcer-v2/ReinforcerV2';

/**
 * Lesson steppers convey progress through numbered steps
 * to create and update a lesson.
 */
export default function LessonSteppers(props) {
  initLessonPlayDomain();
  usePrepareLessonPlayDomain();

  const {
    lessonPlayDomain,
  } = useLessonPlay();

  const {
    lessonDataMaintenanceDomain,
    lessonStandardDomain,
    userDomain,
    authoredLessonDomain,
  } = useDomain();

  const { user } = userDomain.domainData;
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const isLessonStandardLoading = lessonStandardDomain.domainData.isLoading;
  const lessonStandards = useGetLessonStandardsByUser();

  const {
    activeStep,
    setActiveStep,
    handleStep,
    previewState,
    onPreviewClose,
  } = useHandleSteps();

  const [originalApprovalStatus] = useState(lessonData.attributes.approvalStatus);

  const steps = [
    {
      stepLabel: 'Page Type',
      component: <LessonLayout />,
      icon: <ListAltIcon />,
    },
    {
      stepLabel: 'Text & Media',
      component: <LessonTextAndMedia />,
      icon: <NoteIcon />,
    },
    {
      // Only need the step to appear on the lesson step
      stepLabel: 'Preview',
      component: '',
      icon: <SlideShowIcon />,
    },
    {
      stepLabel: 'Activity Details',
      component: <LessonSave originalApprovalStatus={originalApprovalStatus} />,
      icon: <SaveIcon />,
    },
  ];

  if (user.userModerator && (originalApprovalStatus === LESSON_STATUS_PENDING_NEXTGEN || originalApprovalStatus === APPROVED_FOR_COURSES)) {
    steps.push({
      stepLabel: 'Vizzle Library',
      component: <LessonVizzleLibrary />,
      icon: <LibraryAddIcon />,
    });
  }

  /** Handle the height of the bottom bar */
  const [appBarState, setAppBarState] = useState({
    height: 70,
  });

  const onAppbarChangeSize = (height) => {
    setAppBarState({
      ...appBarState,
      height,
    });
  };

  const stepperProps = {
    steps,
    activeStep,
    handleStep,
    nonLinear: true,
    style: { height: `calc(100% - ${appBarState.height}px)` },
    className: 'vizzle-lesson-create-stepper',
  };

  useEffect(() => {
    if (!isLessonStandardLoading && lessonStandards) {
      if (lessonData.relationships && lessonData.relationships.standards && lessonData.relationships.standards.data) {
        const relationShipStandard = lessonData.relationships.standards.data.map((data) => (
          data.id
        ));

        const { includedData } = authoredLessonDomain.domainData || [];
        const standardIncludedData = [];
        if (relationShipStandard) {
          relationShipStandard.forEach((rs) => {
            const result = includedData.find((i) => (
              i.type === 'standards' && i.id === rs
            ));
            if (result) {
              standardIncludedData.push(result);
            }
          });
        }

        const grades = [];
        const standards = [];
        standardIncludedData.forEach((s) => {
          grades.push(s.attributes.standardGrade);
          standards.push(s.attributes.standardId.toString());
        });

        // Update grades and standars to the lesson data object
        lessonData.attributes.grades = unique(grades, 0);
        lessonData.attributes.standards = standards;
        lessonDataMaintenanceDomain.updateLessonDataNoDirtyFlag(lessonData);
      }
    }
    // eslint-disable-next-line
  }, [isLessonStandardLoading]);

  const reinforcer = useMemo(() => {
    if (!lessonPlayDomain.domainData.terminologies.reinforcers || lessonPlayDomain.domainData.terminologies.reinforcers.length === 0) {
      return null;
    }
    return (
      <ReinforcerV2
        reinforcerList={lessonPlayDomain.domainData.terminologies.reinforcers}
        enableAudio={lessonPlayDomain.domainData.userProfile.reinforcerSound}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonPlayDomain.domainData.terminologies.reinforcers]);

  return (
    <>
      <VizzleMainContainer style={{ height: '90vh' }}>
        <div>
          <LessonStepperSave
            {...props}
            showSaveButton={activeStep !== STEP_VIZZLE_LIB}
            pauseAutoSave={previewState.open}
            smallButtons={
              window.innerWidth <= 1024 && activeStep === STEP_TEXT_MEDIA && user.userReviewer
            }
          />
          <VerticalStepper {...stepperProps} />
          <VizzleAppBar appbarPosition='bottom' onAppbarChangeSize={onAppbarChangeSize} data-test='lesson-maintenance-bottom-navigator'>
            <LessonDataPageListSelector setActiveStep={setActiveStep} />
          </VizzleAppBar>
          {previewState.open && (
            <LessonPreview open={previewState.open} onClose={onPreviewClose} initialSlide={previewState.initialSlide} />
          )}
        </div>
      </VizzleMainContainer>
      {reinforcer}
    </>
  );
}

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import FilterableMultiSelector from '../../../components/selector/FilterableMultiSelector';
import { useHandleLessonsFilter, useHandleStudentsFilter } from './TeacherAssignedStudentCourseFilterHooks';
import './TeacherAssignedStudentCourseFilter.scss';

const TeacherAssignedStudentCourseFilter = ({
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  onFilterCriteriaAssignedStudentsCoursesUpdate,
  currentStudent,
  subjectId,
}) => {
  const { studentOptions, handleOnStudentsSelected } = useHandleStudentsFilter({
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    onFilterCriteriaAssignedStudentsCoursesUpdate,
    currentStudent,
  });

  const { lessonOptions, handleOnLessonsSelected } = useHandleLessonsFilter({
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    onFilterCriteriaAssignedStudentsCoursesUpdate,
  });

  useEffect(() => {
    const updatedFilter = {
      ...filterCriteriaAssignedStudentCourses,
      lessonIds: null,
    };

    onFilterCriteriaAssignedStudentsCoursesUpdate(updatedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  return (
    <div className='teacher-assigned-student-course-filter'>
      {currentStudent ? (
        <Typography variant='h2' className='current-student-name' data-private>
          {currentStudent.firstName} {currentStudent.lastName}
        </Typography>
      ) : (
        <FilterableMultiSelector
          label='All Students'
          options={studentOptions}
          filterable
          onApply={handleOnStudentsSelected}
          initFilterKeyValues={filterCriteriaAssignedStudentCourses.studentIds || []}
          disabled={!studentOptions || studentOptions.length === 0}
          className='filter-student-combobox'
        />
      )}

      <FilterableMultiSelector
        label='All Activities'
        options={lessonOptions}
        filterable
        onApply={handleOnLessonsSelected}
        initFilterKeyValues={filterCriteriaAssignedStudentCourses.lessonIds || []}
        disabled={!lessonOptions || lessonOptions.length === 0}
        className='filter-lesson-combobox'
      />
    </div>
  );
};

export default TeacherAssignedStudentCourseFilter;

TeacherAssignedStudentCourseFilter.defaultProps = {
  assignedStudentCourses: [],
  filterCriteriaAssignedStudentCourses: {},
  onFilterCriteriaAssignedStudentsCoursesUpdate: () => null,
  currentStudent: null,
  subjectId: null,
};

TeacherAssignedStudentCourseFilter.propTypes = {
  assignedStudentCourses: PropTypes.arrayOf(PropTypes.any),
  filterCriteriaAssignedStudentCourses: PropTypes.any,
  onFilterCriteriaAssignedStudentsCoursesUpdate: PropTypes.func,
  currentStudent: PropTypes.any,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import { useRef, useMemo, useState, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

export const useInitializeData = () => ({
  sequencingActivityRef: useRef(null),
  dragAndDropApiRef: useRef(null),
  currentSelectedIndex: useRef(null),
});

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
      componentId: `sequencing-activity-component-${id}`,
      textLineId: `sequencing-activity-component-${id}-textlines`,
      droppableId: `sequencing-activity-component-${id}-droppableId`,
    };
  }, []);

  return componentIds;
};

export const useGenerateRejectedAnswerRefs = () => {
  const rejectedAnswerPromptRefs = [];
  const setRejectedAnswerPromptRefs = (ref, index) => {
    if (ref) {
      rejectedAnswerPromptRefs[index] = ref;
    }
  };

  const rejectedAnswerResponseRefs = [];
  const setRejectedAnswerResponseRefs = (ref, index) => {
    if (ref) {
      rejectedAnswerResponseRefs[index] = ref;
    }
  };

  return {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  };
};

export const useSequencingDragAndDropSubmission = ({
  sequencingActivityRef,
  sequenceCards,
  responseCards,
  rejectedAnswerPromptRefs,
  rejectedAnswerResponseRefs,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  currentSelectedIndex,
}) => {
  const currentDroppableId = useRef(null);
  const [selectedResponseCards, setSelectedResponseCards] = useState([]);
  const isFinished = useRef(false);

  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = () => {
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(sequencingActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      LessonPlayActivityUtils.disableComponent(sequencingActivityRef, false);
      return;
    }

    try {
      const sourceIndex = source.index;
      const destinationDroppingId = currentDroppableId.current || destination.droppableId;
      const destinationIndexStr = destinationDroppingId.substring(destinationDroppingId.lastIndexOf('-') + 1);
      const destinationIndex = parseInt(destinationIndexStr, 10);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(destinationIndex)) {
        return;
      }
      if (sequenceCards[destinationIndex].key === responseCards[sourceIndex].answerKey) {
        if (currentSelectedIndex && currentSelectedIndex.current) {
          // eslint-disable-next-line no-param-reassign
          currentSelectedIndex.current = null;
        }
        const updatedSelectdResponseCards = [
          ...selectedResponseCards,
          sourceIndex,
        ];
        sequencingActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
        setSelectedResponseCards(updatedSelectdResponseCards);

        isFinished.current = updatedSelectdResponseCards.length >= responseCards.length;
        await onCorrectAnswerSelected({
          insertResponse: true,
          responseIndex: sourceIndex,
          once: true,
          isFinished: isFinished.current,
          showReinforcer: isFinished.current,
        });
        if (isFinished.current) {
          onActivityFinished();
        }
      } else {
        onIncorrectAnswerSelected(sourceIndex, true);
        showDropRejection(rejectedAnswerPromptRefs[destinationIndex]);
        showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      LessonPlayActivityUtils.disableComponent(sequencingActivityRef, false);
    }
  };

  return {
    handleOnDragEnd,
    handleOnDragStart,
    selectedResponseCards,
    isFinished,
    setSelectedResponseCards,
  };
};

const deselectedResponse = (currentSelectedIndex) => {
  // clear selected value
  if (currentSelectedIndex.current) {
    const el = document.getElementById(currentSelectedIndex.current.responseId);
    if (el) {
      el.classList.remove('vizzle-selected-component-medium');
    }
    // eslint-disable-next-line no-param-reassign
    currentSelectedIndex.current = null;
  }
};

export const useClickSubmitAnswer = ({
  sequencingActivityRef,
  dragAndDropApiRef,
  currentSelectedIndex,
}) => {
  const handleOnPromptCardClick = (_e, prompCardDropableId) => {
    if (currentSelectedIndex.current) {
      LessonPlayActivityUtils.stopMedias();

      const dragAndDropApi = dragAndDropApiRef.current;
      const triggerDragAndDrop = async () => {
        await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedIndex.current.responseId, currentSelectedIndex.current.draggableId, prompCardDropableId);
        // clear selected value
        // if (currentSelectedIndex.current) {
        // sequencingActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
        // const el = document.getElementById(currentSelectedIndex.current.responseId);
        // if (el) {
        //   el.classList.remove('vizzle-selected-component-medium');
        // }
        // currentSelectedIndex.current = null;
        // }
      };

      ObjectUtils.setTimeout(() => {
        triggerDragAndDrop();
      });
    }
  };

  const handleOnResponseCardClick = (_e, isTtsAbleToPlay, responseCard, responseId, draggableId) => {
    if (!responseCard.audio && !responseCard.video && !isTtsAbleToPlay) {
      LessonPlayActivityUtils.stopMedias();
    }

    const videoPlaying = document.querySelector('.video-player-dialog');
    if (videoPlaying) {
      return;
    }

    if (!currentSelectedIndex.current || currentSelectedIndex.current.responseId !== responseId) {
      if (currentSelectedIndex.current && document.getElementById(currentSelectedIndex.current.responseId)) {
        document.getElementById(currentSelectedIndex.current.responseId).classList.remove('vizzle-selected-component-medium');
      }
      // eslint-disable-next-line no-param-reassign
      currentSelectedIndex.current = {
        responseId,
        draggableId,
      };
      sequencingActivityRef.current.classList.add('prompt-selected-response-cards-hover');
      document.getElementById(responseId).classList.add('vizzle-selected-component-medium');
    } else if (currentSelectedIndex.current && currentSelectedIndex.current.responseId === responseId) {
      LessonPlayActivityUtils.stopMedias();
      deselectedResponse(currentSelectedIndex);
      sequencingActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
    }
  };

  return {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  setSelectedResponseCards,
  responseCards,
  isFinished,
  onActivityFinished,
  pageIndex,
}) => {
  useEffect(() => {
    const result = [];
    responseCards.forEach((r, index) => {
      if (r.correctSubmission !== null && r.correctSubmission !== undefined) {
        result.push(index);
      }
    });
    // eslint-disable-next-line no-param-reassign
    isFinished.current = result.length >= responseCards.length;
    if (isFinished.current) {
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    setSelectedResponseCards(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

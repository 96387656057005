import BaseDomain, { updateObjectInArray, insert, reorder } from './BaseDomain';
import { getInitialDataForBook } from '../../utils/activitytype/ActivityTypeInitialData';

const LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA = 'LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA';
const LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA_NO_DIRTY_FLAG = 'LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA_NO_DIRTY_FLAG';
const LESSON_DATA_MAINTAIN_PREPARE_DATA = 'LESSON_DATA_MAINTAIN_PREPARE_DATA';
const LESSON_DATA_MAINTAIN_UPDATE_CURRENT_SELECTED_PAGE_DATA = 'LESSON_DATA_MAINTAIN_UPDATE_CURRENT_SELECTED_PAGE_DATA';
const LESSON_DATA_MAINTAIN_ADD_PAGE = 'LESSON_DATA_MAINTAIN_ADD_PAGE';
const LESSON_DATA_MAINTAIN_UPDATE_PAGE = 'LESSON_DATA_MAINTAIN_UPDATE_PAGE';
const LESSON_DATA_MAINTAIN_DELETE_PAGE = 'LESSON_DATA_MAINTAIN_DELETE_PAGE';
const LESSON_DATA_MAINTAIN_REORDER_PAGE_DATA = 'LESSON_DATA_MAINTAIN_REORDER_PAGE_DATA';
const LESSON_DATA_MAINTAIN_UPDATE_IS_DIRTY_FLAG = 'LESSON_DATA_MAINTAIN_UPDATE_IS_DIRTY_FLAG';
const LESSON_DATA_MAINTAIN_UPDATE_LESSON_COURSE = 'LESSON_DATA_MAINTAIN_UPDATE_LESSON_COURSE';
const LESSON_DATA_MAINTAIN_UPDATE_PAGES_DATA = 'LESSON_DATA_MAINTAIN_UPDATE_PAGES_DATA';

const initialState = {
  lessonData: { attributes: { appId: 17, subjects: [], feedbackLoopValue: false } },
  pagesData: { pages: [getInitialDataForBook()] },
  currentSelectedPagesData: 0,
  isDirty: false,
  lessonCourses: null,
  updateMode: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA:
      return {
        ...state,
        isDirty: action.payload.dirty,
        lessonData: action.payload.lessonData,
      };
    case LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA_NO_DIRTY_FLAG:
      return {
        ...state,
        lessonData: action.payload,
      };
    case LESSON_DATA_MAINTAIN_PREPARE_DATA:
      return {
        ...state,
        isDirty: false,
        lessonData: action.payload.lessonData,
        pagesData: action.payload.pagesData,
        currentSelectedPagesData: action.payload.currentSelectedPagesData,
        updateMode: action.payload.updateMode,
      };
    case LESSON_DATA_MAINTAIN_UPDATE_CURRENT_SELECTED_PAGE_DATA:
      return {
        ...state,
        currentSelectedPagesData: action.payload,
      };
    case LESSON_DATA_MAINTAIN_ADD_PAGE:
      return {
        ...state,
        isDirty: true,
        pagesData: {
          ...state.pagesData,
          pages: insert(
            state.pagesData.pages,
            action.payload.indexToInsert,
            action.payload.dataPage,
          ),
        },
        currentSelectedPagesData: action.payload.indexToInsert,
      };
    case LESSON_DATA_MAINTAIN_UPDATE_PAGE:
      return {
        ...state,
        isDirty: true,
        pagesData: {
          ...state.pagesData,
          pages: updateObjectInArray(
            state.pagesData.pages,
            action.payload.index,
            action.payload.newPageData,
          ),
        },
      };
    case LESSON_DATA_MAINTAIN_DELETE_PAGE: {
      let newIndexToSelect = action.payload;
      if (newIndexToSelect >= state.pagesData.pages.length - 1) {
        newIndexToSelect = action.payload - 1;
      }
      if (newIndexToSelect === -1 && state.pagesData.pages.length > 0) {
        newIndexToSelect = 0;
      }
      return {
        ...state,
        isDirty: true,
        pagesData: {
          ...state.pagesData,
          pages: [
            ...state.pagesData.pages.slice(0, action.payload),
            ...state.pagesData.pages.slice(action.payload + 1),
          ],
        },
        currentSelectedPagesData: newIndexToSelect,
      };
    }
    case LESSON_DATA_MAINTAIN_REORDER_PAGE_DATA: {
      return {
        ...state,
        isDirty: true,
        pagesData: {
          ...state.pagesData,
          pages: reorder(
            state.pagesData.pages,
            action.payload.startIndex,
            action.payload.endIndex,
          ),
        },
        currentSelectedPagesData: action.payload.selectedIndex,
      };
    }
    case LESSON_DATA_MAINTAIN_UPDATE_IS_DIRTY_FLAG: {
      return {
        ...state,
        isDirty: action.payload,
      };
    }
    case LESSON_DATA_MAINTAIN_UPDATE_LESSON_COURSE: {
      return {
        ...state,
        lessonCourses: action.payload,
      };
    }
    case LESSON_DATA_MAINTAIN_UPDATE_PAGES_DATA: {
      return {
        ...state,
        pagesData: action.payload.pagesData,
        isDirty: action.payload.isDirty,
      };
    }
    default:
      return state;
  }
};

const domainName = 'lessonDataMaintenanceDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor() {
    super(domainName, initialState, reducer);
  }

  /**
   * Update lesson data with the dirty flag
   * @param {*} lessonData
   * @param {*} dirty
   */
  async updateLessonData(lessonData, dirty = true) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA,
      payload: {
        lessonData,
        dirty,
      },
    });
  }

  /**
   * Update lesson data without the dirty flag
   *
   * @param {*} lessonData
   */
  async updateLessonDataNoDirtyFlag(lessonData) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_LESSON_DATA_NO_DIRTY_FLAG,
      payload: lessonData,
    });
  }

  /**
   * update Lesson data
   *
   * @param {*} lessonData lesson data to update
   * @param {*} pagesData pages data to update
   */
  async prepareDataForUpdate(lessonData, pagesData, currentSelectedPagesData = 0, updateMode = false) {
    let pagesDataToUse = pagesData;

    if (!pagesData || Object.keys(pagesData).length === 0) {
      pagesDataToUse = initialState.pagesData;
    }
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_PREPARE_DATA,
      payload: {
        lessonData,
        pagesData: pagesDataToUse,
        currentSelectedPagesData,
        updateMode,
      },
    });
  }

  /**
   * update current selected Lesson pages data
   *
   * @param {*} currentSelectedPagesData current selected pages data
   */
  async updateCurrentSelectedPagesData(currentSelectedPagesData) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_CURRENT_SELECTED_PAGE_DATA,
      payload: currentSelectedPagesData,
    });
  }

  /**
   * Add a new data page
   *
   * @param {*} dataPage data page to add
   * @param {*} indexToSelect new index to select
   */
  async addLessonPageData(dataPage, indexToInsert) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_ADD_PAGE,
      payload: {
        dataPage,
        indexToInsert,
      },
    });
  }

  /**
   * Update a data page
   *
   * @param index index to update
   * @param newPageData data page to add
   */
  async updateLessonPageData(index, newPageData) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_PAGE,
      payload: {
        index,
        newPageData,
      },
    });
  }

  /**
   * Update a data page
   *
   * @param index index to delete
   */
  async deleteLessonPageData(index) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_DELETE_PAGE,
      payload: index,
    });
  }

  /**
   * Reorder list
   *
   * @param {*} startIndex index of item to move
   * @param {*} endIndex target index of the item to move to
   */
  async reOrderLessonPageData(startIndex, endIndex, selectedIndex) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_REORDER_PAGE_DATA,
      payload: {
        startIndex,
        endIndex,
        selectedIndex,
      },
    });
  }

  async updateIsDirtyFlag(isDirty) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_IS_DIRTY_FLAG,
      payload: isDirty,
    });
  }

  async updateLessonCourse(lessonCourses) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_LESSON_COURSE,
      payload: lessonCourses,
    });
  }

  async updatePagesData(pagesData, isDirty = true) {
    return this.dispatch({
      type: LESSON_DATA_MAINTAIN_UPDATE_PAGES_DATA,
      payload: { pagesData, isDirty },
    });
  }
}

const createUserPayload = (user, role) => ({
  accountId: user.eduIdentifier || `Vizzle${user.schoolId}`,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  id: user.id,
  role,
  district: user.externalAccountId,
  division: 'Education',
  product: 'Vizzle',
  school: user.eduIdentifier,
  availablecomponents: ['Vizzle'],
  accountintegration: user.cleverId,
});
const createAccountPayload = (user) => ({
  id: user.eduIdentifier || `Vizzle${user.schoolId}`,
  name: user.schoolName,
  state: user.state,
});
export default {
  trackTeacher: (user) => {
    if (!process.env.REACT_APP_GAIN_SIGHT_SERVICE_KEY) {
      return;
    }
    if (!user.isDistrictActive || !user.isDistrictPaidCustomer) {
      return;
    }
    const userPayload = createUserPayload(user, user.userAdmin ? 'Admin' : 'Teacher');
    const accountPayload = createAccountPayload(user);
    // eslint-disable-next-line no-undef
    aptrinsic('identify', userPayload, accountPayload);
  },

  trackStudent: (user) => {
    if (!process.env.REACT_APP_GAIN_SIGHT_SERVICE_KEY) {
      return;
    }
    if (!user.isDistrictActive || !user.isDistrictPaidCustomer) {
      return;
    }
    const userPayload = createUserPayload(user, 'Student');
    userPayload.firstName = undefined;
    userPayload.lastName = undefined;
    const accountPayload = createAccountPayload(user);
    // eslint-disable-next-line no-undef
    aptrinsic('identify', userPayload, accountPayload);
  },
};

import PropTypes from 'prop-types';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './CauseAndEffectThumbnailComponent.scss';
import CauseAndEffectCard from './CauseAndEffectCard';

export default function CauseAndEffectThumbnailComponent({ causeCard, effectCard, isSmallThumbnail }) {
  return (
    <div className='cause-and-effect-thumbnail-component vizzle-cause-and-effect-card-thumbnail'>
      <div className='card-container'>
        <CauseAndEffectCard card={causeCard} isSmallThumbnail={isSmallThumbnail} />
      </div>
      <div>
        <DoubleArrowIcon className='arrow' />
      </div>
      <div className='card-container'>
        <CauseAndEffectCard card={effectCard} isSmallThumbnail={isSmallThumbnail} />
      </div>
    </div>
  );
}

CauseAndEffectThumbnailComponent.defaultProps = {
  causeCard: { key: 0 },
  effectCard: { key: 0 },
  isSmallThumbnail: false,
};

CauseAndEffectThumbnailComponent.propTypes = {
  causeCard: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    text: PropTypes.object,
  }),
  effectCard: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    text: PropTypes.object,
  }),
  isSmallThumbnail: PropTypes.bool,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import AddButton from '../../../components/buttons/AddButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import AddStudentForm from './forms/AddStudentForm';
import { useAddStudent } from './StudentActionHooks';
import { useAdmin } from '../../../context/AdminContextProvider';
import './StudentDialog.scss';

const initializeData = () => ({
  firstName: '',
  lastName: '',
  // externalId: '',
  schoologyId: '',
  userName: '',
  password: '',
  type: 'Student',
  stateIdentifier: '',
});

const AddStudentAction = ({
  onStudentAddSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const [isDataValid, setIsDataValid] = useState(false);

  // const data = useRef(null);
  const [data, setData] = useState({ ...initializeData });

  const handleOnCancel = () => {
    setData({ ...initializeData });
    setOpen(false);
  };

  const handleOnDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    // data.current = updatedData;
    setData(updatedData);
  };

  const { adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;

  const {
    loading,
    addNewStudent,
  } = useAddStudent({
    closeDialog: handleOnCancel,
    onStudentAddSuccess,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: () => { addNewStudent(data); },
      className: 'btn-primary action-button',
      title: 'Save',
      disabled: !isDataValid,
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <AddButton
        ariaLabel='Add Student'
        onClick={() => { setOpen(true); }}
        disabled={!selectedSchoolId}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='admin-add-student-dialog'
          onClose={handleOnCancel}
          title='Add Student'
          content={<AddStudentForm studentData={data} onDataChange={handleOnDataChange} />}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

AddStudentAction.defaultProps = {
  onStudentAddSuccess: () => null,
};

AddStudentAction.propTypes = {
  onStudentAddSuccess: PropTypes.func,
};

export default AddStudentAction;

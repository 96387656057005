import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './DistrictSchoolSelector.scss';
import SearchDistrictAction from '../search-district/SearchDistrictAction';
import SearchSchoolAction from '../search-school/SearchSchoolAction';

const DistrictSchoolSelector = ({
  onChange,
  selectedDistrictId,
  selectedSchoolId,
  filterSchoolFunction,
}) => {
  const [selectedDistrict, setSelectedDistrict] = useState({
    key: selectedDistrictId,
  });

  useEffect(() => {
    if (selectedDistrictId) {
      setSelectedDistrict({
        key: selectedDistrictId,
      });
    }
  }, [selectedDistrictId]);

  const handleOnDistrictChange = async (district) => {
    setSelectedDistrict(district);
    onChange({
      district,
      school: null,
    });
  };

  const [schoolsData, setSchoolsData] = useState([]);
  const handleOnFilterSchoolChange = (school) => {
    onChange({
      district: selectedDistrict,
      school,
    });
  };

  useEffect(() => {
    if (schoolsData.length === 1) {
      const school = schoolsData[0];
      const schoolObj = {
        key: school.id,
        name: school.attributes.name,
      };
      onChange({
        district: selectedDistrict,
        school: schoolObj,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolsData]);

  const handleOnSchoolsLoaded = (schools) => {
    if (filterSchoolFunction) {
      const filteredSchools = filterSchoolFunction(schools);
      setSchoolsData(filteredSchools);
    } else {
      setSchoolsData(schools);
    }
  };

  return (
    <div className='district-school-selector'>
      <div className='form-row'>
        <Typography className='form-label'>
          Change District
        </Typography>
        <SearchDistrictAction
          className='district-school-selector-dropdown'
          onFilterChange={handleOnDistrictChange}
          filterValue={selectedDistrict ? selectedDistrict.key : null}
          isMultiple={false}
          ableToUnselectedInSingleMode
        />
      </div>
      <div className='form-row'>
        <Typography className='form-label'>
          Change School
        </Typography>
        <SearchSchoolAction
          className='district-school-selector-dropdown margin-top'
          districtId={selectedDistrict ? selectedDistrict.key : null}
          schoolsData={schoolsData}
          onSchoolsLoaded={handleOnSchoolsLoaded}
          isMultiple={false}
          filterValue={schoolsData && schoolsData.find((s) => s.id === selectedSchoolId) ? selectedSchoolId : null}
          onFilterChange={handleOnFilterSchoolChange}
        />
      </div>
    </div>
  );
};

DistrictSchoolSelector.defaultProps = {
  onChange: () => null,
  selectedDistrictId: null,
  selectedSchoolId: null,
  filterSchoolFunction: null,
};

DistrictSchoolSelector.propTypes = {
  onChange: PropTypes.func,
  selectedDistrictId: PropTypes.string,
  selectedSchoolId: PropTypes.string,
  filterSchoolFunction: PropTypes.func,
};

export default DistrictSchoolSelector;

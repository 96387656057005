import { Box } from '@mui/material';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import { useDomain } from '../../../../../states/AppDomainProvider';
import FileUploader from '../../file-uploader/FileUploader';
import './TeacherCourseLessonPlan.scss';
import { updateLessonPlan, uploadLessonPlans } from '../../../../../services/CourseService';
import { useCheckPdf } from '../../../../hooks/CheckPdfLinkHooks';
import ChurnZeroService from '../../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../../constants/ChurnZeroConstant';
import { useTrackTeacherCourseActivity } from '../../TeacherCourseMainTabHooks';
import { TRACKING_STATUS } from '../../../../../services/ActivityTrackingService';

const TeacherCourseLessonPlan = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria, hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const {
    courseId,
    unitId,
    topicId,
  } = searchCriteria;

  const selectedCourse = (lessonCourses || []).find((l) => l.id === courseId) || {};

  const { units } = selectedCourse;
  const selectedUnit = (units || []).find((u) => u.id === unitId) || '';

  const { topics } = selectedUnit;
  const selectedTopic = (topics || []).find((t) => t.id === topicId) || '';

  const {
    loading,
    signedPdfUrl,
  } = useCheckPdf(selectedTopic.guideLink);

  useTrackTeacherCourseActivity(TRACKING_STATUS.LESSON_PLAN);

  if (!hasPerformSearch) {
    return (<TeacherCourseInstruction />);
  }

  if (!lessonCourses || !searchCriteria || !searchCriteria.unitId || !selectedCourse || !selectedUnit || !selectedTopic) {
    return null;
  }

  ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'View Activity Plan', user);
  return (
    <div className='teacher-course-lesson-plan'>
      {user.userModerator && (
        <Box display='flex' justifyContent='center'>
          <FileUploader
            id='pdf-uploader-teacher-course-lesson-plans'
            buttonLabel='UPLOAD ACTIVITY PLAN'
            uploadServiceFunction={async (file) => {
              const url = await uploadLessonPlans({
                file,
                courseId,
                unitId,
                topicId,
                topicName: selectedTopic.name,
              });
              await updateLessonPlan({
                courseId,
                unitId,
                topicId,
                guideLink: url,
              });
              selectedTopic.guideLink = url;
              teacherCourseDomain.updateLessonCourses(lessonCourses);
            }}
          />
        </Box>
      )}
      {signedPdfUrl && !loading && (
        <object className='pdf-container' data={signedPdfUrl} type='application/pdf'>
          <iframe title='Activity Plan PDF' src={signedPdfUrl} />
        </object>
      )}
    </div>
  );
};

export default TeacherCourseLessonPlan;

import {
  Box,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AREA_PROMPT_CARDS, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../../../../../components/vizzle/page/common/CardEditorConstants';
import MultipleChoiceEditor from '../../../../../components/vizzle/page/multiple-choice/MultipleChoiceEditor';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useHandleMatchingQuestionKey } from '../../hooks/LessonMaintenanceHooks';
import { checkHasNon18FontSize, checkHasNonJosefinSlab, getExceedCharLimitMessage, getTotalCharsLength, useHandleTextEditorWarning } from '../../hooks/TextEditorWarningHooks';
import AddAudio from './editor/AddAudio';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';

const initState = {
  selectedArea: AREA_PROMPT_CARDS,
  selectedCardIndex: 0,
  selectedSection: SECTION_IMAGE,
};

export default function MultipleChoiceTextAndMedia() {
  const { lessonDataMaintenanceDomain, userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];
  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const handleMatchingQuestionKey = useHandleMatchingQuestionKey();

  const multipleChoicesEditorProps = {
    selectedSection: {
      ...currentSelectedData,
    },
    questionCard: {
      text: selectedPage.questionCard.text,
      image: selectedPage.questionCard.image,
      audio: selectedPage.questionCard.audio,
      video: selectedPage.questionCard.video,
      questionKey: selectedPage.questionCard.questionKey,
    },

    answerCards: selectedPage.answerCards.filter((card) => (
      !card.hide
    )).map((card, index) => ({
      text: card.text,
      image: card.image,
      audio: card.audio,
      video: card.video,
      answerKey: selectedPage.answerCards[index].answerKey,
    })),

    onCardSelected: (selectedSection) => {
      setCurrentSelectedData(selectedSection);
    },

    onQuestionKeyChange: async (questionCard) => {
      let hasChange = false;

      if (questionCard.questionKey !== (selectedPage.questionKey || '')) {
        hasChange = true;
      }

      if (!hasChange) {
        return;
      }

      selectedPage.questionCard.questionKey = questionCard.questionKey;

      handleMatchingQuestionKey(selectedPage.questionCard.questionKey, 'answerKey', 'answerCards');
    },

    onAnswerKeyChange: async (answerKeys) => {
      answerKeys.forEach((key, index) => {
        selectedPage.answerCards[index].answerKey = key;
      });
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    },
    singlePage: selectedPage.singlePage,
  };

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }
    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }
    const hasNon18FontSize = checkHasNon18FontSize(textLines);
    if (hasNon18FontSize) {
      closeTextEditorWarning();
      return;
    }

    const charLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 52 : 36;
    const totalCharsLength = getTotalCharsLength(textLines);
    if (totalCharsLength > charLimit) {
      showTextEditorWarning(getExceedCharLimitMessage(charLimit));
      return;
    }
    closeTextEditorWarning();
  };

  useEffect(() => {
    const text = currentSelectedData.selectedArea === AREA_PROMPT_CARDS
      ? selectedPage.questionCard && selectedPage.questionCard.text
      : selectedPage.answerCards[currentSelectedData.selectedCardIndex] && selectedPage.answerCards[currentSelectedData.selectedCardIndex].text;
    onTextChange(text && text.lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  const onTextApply = async (textLines) => {
    const { text } = currentSelectedData.selectedArea === AREA_PROMPT_CARDS
      ? selectedPage.questionCard && selectedPage.questionCard : selectedPage.answerCards[currentSelectedData.selectedCardIndex];

    const currentTextLines = text ? text.lines : [];
    const newTextLines = textLines.map((e, index) => ({
      ...currentTextLines[index],
      ...e,
    }));

    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    if (!cardObject.text) {
      cardObject.text = {};
    }
    cardObject.text.lines = newTextLines;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemove = async () => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    const card = {
      ...cardObject,
      text: null,
    };
    cardObject = card;

    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      selectedPage.questionCard = cardObject;
    } else {
      selectedPage.answerCards[currentSelectedData.selectedCardIndex] = cardObject;
    }

    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const onImageApply = async (media) => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    cardObject.image = media.selectedImageUrl;
    cardObject.video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    cardObject.image = null;
    cardObject.video = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioApply = async (audioData) => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    cardObject.audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    cardObject.audio = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  useEffect(() => {
    // When the user select a new page, reset the current selected matching to the first one
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  useEffect(() => {
    if (!selectedPage.questionCard.questionKey) {
      uiDomain.blockScreen(true);
    } else {
      uiDomain.blockScreen(false);
    }
    return () => {
      uiDomain.blockScreen(false);
    };
    // eslint-disable-next-line
  }, [selectedPage.questionCard.questionKey]);

  return (
    <div>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <MultipleChoiceEditor {...multipleChoicesEditorProps} />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', position: 'relative', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={
                    currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                      ? selectedPage.questionCard && selectedPage.questionCard.text
                      : selectedPage.answerCards[currentSelectedData.selectedCardIndex] && selectedPage.answerCards[currentSelectedData.selectedCardIndex].text
                  }
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemove}
                  onTextChange={onTextChange}
                  enableMathEditor
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {currentSelectedData.selectedSection === SECTION_IMAGE && (
              <AddPhotoOrVideo
                image={currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                  ? selectedPage.questionCard && selectedPage.questionCard.image
                  : selectedPage.answerCards[currentSelectedData.selectedCardIndex] && selectedPage.answerCards[currentSelectedData.selectedCardIndex].image}
                onSelect={onImageApply}
                onRemove={onImageRemove}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={
                  currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                    ? selectedPage.questionCard && selectedPage.questionCard.audio
                    : selectedPage.answerCards[currentSelectedData.selectedCardIndex] && selectedPage.answerCards[currentSelectedData.selectedCardIndex].audio
                }
                onAudioApply={onAudioApply}
                onAudioRemoved={onAudioRemoved}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

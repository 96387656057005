import { useEffect, useState } from 'react';
import { getTopicTemplate, saveGenerateLessons } from '../../../../../../services/CourseService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import ObjectUtils from '../../../../../../utils/ObjectUtils';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';

export const useGetTopicTemplate = ({
  subjectId,
}) => {
  const [topicTemplate, setTopicTemplate] = useState([]);
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  useEffect(() => {
    try {
      callRemoteServiceWrapper(async () => {
        const result = await getTopicTemplate();
        const filteredResult = result.filter((r) => Number(r.subjectId) === Number(subjectId));
        setTopicTemplate(filteredResult);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    topicTemplate,
    setTopicTemplate,
  };
};

export const useGenerateLessons = ({
  onSaveSuccess,
}) => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const generateLessons = async ({
    topicId,
    lessonLevel,
    topicTemplateValues,
  }) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const keys = Object.keys(topicTemplateValues);
        const templates = keys.map((key) => ({
          lessonId: key,
          ...topicTemplateValues[key],
        }));

        const nonZeroTemplates = templates.filter((t) => t.numberOfCopies > 0);

        const payload = {
          topicId,
          level: lessonLevel,
          templates: nonZeroTemplates,
        };

        const generatedLessons = await saveGenerateLessons(payload);
        const total = generatedLessons.length;

        await uiDomain.showSnackbar(true, 'info', 6000, `${total} ${total > 1 ? 'activities are' : 'activity is'} generated.`);
        ObjectUtils.setTimeout(async () => {
          await onSaveSuccess();
        });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saving: loading,
    generateLessons,
  };
};

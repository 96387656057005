import { useState } from 'react';
import { useDomain } from '../../states/AppDomainProvider';
import Logger from '../../utils/Logger';
import { UNAUTHORIZED } from '../../AppConstants';

export const useGetRemoteServiceWrapper = (initLoadingState = false) => {
  const { uiDomain } = useDomain();
  const [loading, setLoading] = useState(initLoadingState);

  const callRemoteServiceWrapper = async (func) => {
    try {
      setLoading(true);
      await func();
      setLoading(false);
      return Promise.resolve();
    } catch (error) {
      Logger.logError({
        ERROR: error,
      });
      if (error.response && UNAUTHORIZED !== error.response.status) {
        const message = typeof error.response.data === 'string' ? error.response.data : 'An error occurs while processing. Please check the log.';
        await uiDomain.showSnackbar(true, 'error', 300000, message);
      }
      setLoading(false);
      return Promise.reject();
    }
  };

  return {
    loading,
    callRemoteServiceWrapper,
  };
};

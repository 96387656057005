import { useEffect, useState } from 'react';
import { checkPdfLink } from '../../services/PdfService';
import Logger from '../../utils/Logger';

export const useCheckPdf = (pdfKey) => {
  const [state, setState] = useState({
    loading: false,
    signedPdfUrl: '',
  });

  const checkPdfExistings = async () => {
    try {
      setState({
        loading: true,
        signedPdfUrl: null,
      });

      const result = await checkPdfLink(pdfKey);
      if (result.data) {
        setState({
          loading: false,
          signedPdfUrl: result.data,
        });
      }
    } catch (e) {
      Logger.logError('Pdf is not found');
      Logger.logError(e);
      setState({
        loading: false,
        signedPdfUrl: null,
      });
    }
  };

  useEffect(() => {
    if (pdfKey) {
      checkPdfExistings();
    } else {
      setState({
        loading: false,
        signedPdfUrl: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfKey]);

  return state;
};

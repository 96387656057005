import { useRef } from 'react';
import TeacherAssignedStudentCoursePanelTab from '../../../../lessons/assigned-student-courses/TeacherAssignedStudentCoursePanelTab';
import { useIntitialData, useMyAssignmentDataSelector, useMyAssignmentGetData, useMyAssignmentLoadData } from './AssignmentsOverviewDateSelectorHooks';
import './AssignmentsOverviewDateSelector.scss';
import { useDomain } from '../../../../../states/AppDomainProvider';

const AssignmentsOverviewDateSelector = () => {
  const tabRef = useRef(null);

  useIntitialData(tabRef);
  const { selectedSubject } = useMyAssignmentGetData();

  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const {
    handleOnSelectedDatesApply,
    selectedDates,
  } = useMyAssignmentDataSelector();

  const {
    handleOnStartLoading,
    handleOnDataLoad,
  } = useMyAssignmentLoadData();

  return (
    <div className='assignment-overview-date-selector'>
      <TeacherAssignedStudentCoursePanelTab
        selectedDates={selectedDates}
        onSelectedDatesApply={handleOnSelectedDatesApply}
        onStartLoading={handleOnStartLoading}
        subjectId={selectedSubject}
        onDataLoad={handleOnDataLoad}
        isCustomAllowed={false}
        disabled={students.length === 0}
        ref={tabRef}
      />
    </div>
  );
};

export default AssignmentsOverviewDateSelector;

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  IconButton,
} from '@mui/material';
import Draggable from 'react-draggable';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import StopIcon from '@mui/icons-material/Stop';
import TimeElapsed from './subcomponents/TimeElapsed';
import ButtonWithSpinner from '../../buttons/ButtonWithSpinner';
import './AudioRecorder.scss';

function PaperComponent(props) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * A component for Audio recorder ui
 */
export default function AudioRecorder({
  open,
  onClose,
  isRecording,
  startRecording,
  stopRecording,
  recordedAudioUrl,
  onApply,
  isSaving,
  limitInMinute,
}) {
  const getStartRecordingComponent = () => (
    <>
      <IconButton
        className='audio-recorder-start-recording-action'
        onClick={() => { startRecording(); }}
        disabled={isSaving}
      >
        <FiberManualRecordRoundedIcon className='icon-red-with-gradient-boder audio-recorder-icon-no-margin' />
      </IconButton>
      <div className='audio-recorder-time-elapsed-label' />
    </>
  );

  const getStopRecordingComponent = () => (
    <>
      <IconButton
        className='audio-recorder-stop-recording-action'
        onClick={() => { stopRecording(); }}
      >
        <StopIcon className='audio-recorder-icon' />
      </IconButton>
      <div className='audio-recorder-time-elapsed-label'>
        <TimeElapsed />
      </div>
    </>
  );

  return (
    <Dialog
      open={open}
      className='audio-recorder'
      aria-labelledby='form-dialog-title'
      PaperComponent={PaperComponent}
      data-test='audio-recorder-dialog'
    >
      <DialogTitle className='audio-recorder-title' id='draggable-dialog-title'>Audio Recorder</DialogTitle>
      {limitInMinute !== null ? (
        <div className='audio-recorder-title'>
          Audio length is limited to&nbsp;
          {limitInMinute}
          &nbsp;minutes.
        </div>
      ) : ''}
      <DialogContent className='audio-recorder-content'>
        {
          isRecording ? getStopRecordingComponent() : getStartRecordingComponent()
        }
        <div className='audio-recorder-audio-player'>
          <audio controls src={recordedAudioUrl}>
            <track kind='captions' />
          </audio>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className='btn-gray-background audio-recorder-button vizzle-audio-recorder-cancel-btn'
          disabled={isRecording || isSaving}
        >
          Close
        </Button>
        <ButtonWithSpinner
          onClick={onApply}
          loading={isSaving}
          className='btn-primary audio-recorder-button vizzle-audio-recorder-apply-btn'
          disabled={isRecording || !recordedAudioUrl}
          label='Apply'
        />
      </DialogActions>
    </Dialog>
  );
}

AudioRecorder.defaultProps = {
  recordedAudioUrl: null,
  open: false,
  onClose: () => { },
  isRecording: false,
  startRecording: () => { },
  stopRecording: () => { },
  onApply: () => { },
  isSaving: false,
  limitInMinute: null,
};

AudioRecorder.propTypes = {
  recordedAudioUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isRecording: PropTypes.bool,
  startRecording: PropTypes.func,
  stopRecording: PropTypes.func,
  onApply: PropTypes.func,
  isSaving: PropTypes.bool,
  limitInMinute: PropTypes.number,
};

import { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import VideoPlayer, { preloadVideo } from './VideoPlayer';
import { playAudio, stopAudio, playRegisteredAudio, isAudioPlaying } from './AudioPlayer';
import './MediaPlayer.scss';
import Logger from '../../../utils/Logger';
import ObjectUtils from '../../../utils/ObjectUtils';
import TextToSpeechService from '../../../services/tts/TextToSpeechService';
import { useRegisterSwitchAccessScanEvent } from '../../../containers/switch-access/SwitchAccessHooks';
import { useDetermineClickOrTouchEventForDnd } from '../../../containers/hooks/DndClickOrTouchHooks';

const removeAllMediaPlayingClass = () => {
  // Ensure the class has been removed from eveywhere
  const elList = document.getElementsByClassName('media-player-playing');
  if (elList.length > 0) {
    [].forEach.call(elList, (el) => {
      el.classList.remove('media-player-playing');
    });
  }
  const elIconList = document.getElementsByClassName('media-player-playing-icon');
  if (elIconList.length > 0) {
    [].forEach.call(elIconList, (el) => {
      el.classList.remove('media-player-playing-icon');
    });
  }
};

/**
 * Remove the css class of media-player-playing
 */
export const removeMediaPlayingClass = (parentElementId) => {
  if (parentElementId) {
    const parentElement = document.getElementById(parentElementId);
    if (!parentElement) {
      return;
    }
    parentElement.classList.remove('media-player-playing');
    parentElement.classList.remove('media-player-playing-icon');
  } else {
    // Ensure the class has been removed from eveywhere
    removeAllMediaPlayingClass();
  }
};

/** Update the style by adding css class directly to the element to avoid state */
const onMediaStarted = (parentElementId, highlightIcon) => {
  removeMediaPlayingClass();

  if (!parentElementId) {
    return;
  }
  const parentElement = document.getElementById(parentElementId);
  if (!parentElement) {
    return;
  }

  if (highlightIcon) {
    parentElement.classList.add('media-player-playing-icon');
  } else {
    parentElement.classList.add('media-player-playing-icon');
    parentElement.classList.add('media-player-playing');
  }
};

const onMediaEnded = (parentElementId) => {
  removeMediaPlayingClass(parentElementId);
};

/**
 * Media player
 */
const MediaPlayer = forwardRef(({
  id,
  audioUrl,
  videoUrl,
  className,
  parentElementId,
  omitPlayIcon,
  playsVideoInline,
  videoPoster,
  highlightIcon,
  disablePlay,
  textToRead,
  tts,
  onMediaPlayFinish,
  onMediaPlayFinishForClick,
  forDndElement,
  forPrinting,
  ...props
}, ref) => {
  const [openVideo, setOpenVideo] = useState({
    open: false,
    onDialogVideoEnd: () => { },
  });
  const elementRef = useRef(null);

  const playsVideoInlineRef = useRef(null);

  const promiseRef = useRef({});

  const playerActionRef = useRef(null);

  const isTtsAbleToPlay = tts.enabled && textToRead && textToRead.length > 0;

  const textToSpeechService = new TextToSpeechService(tts.ttsMode);

  const { playTextToSpeech, cancelTts, isTtsPlaying } = textToSpeechService.getTtsService();

  useEffect(() => (
    () => {
      stopAudio();
    }
    // eslint-disable-next-line
  ), [audioUrl]);

  let audioUrlToPlay = audioUrl;
  if (isTtsAbleToPlay && audioUrl) {
    if (audioUrl.indexOf('tts') >= 0) {
      audioUrlToPlay = '';
    }
  }

  // eslint-disable-next-line no-async-promise-executor
  const playMedia = ({ onlyTts } = { onlyTts: false }) => new Promise(async (resolve, reject) => {
    promiseRef.current = {
      resolve,
      reject,
    };

    let shouldWait = false;
    if (isAudioPlaying() || isTtsPlaying()) {
      const parentElement = document.getElementById(parentElementId);
      let isElementPlaying = false;
      if (parentElement) {
        const mediaPlaying = parentElement.classList.contains('media-player-playing')
          || parentElement.classList.contains('media-player-playing-icon')
          || parentElement.getElementsByClassName('media-player-playing').lenth > 0
          || parentElement.getElementsByClassName('media-player-playing-icon').lenth > 0;

        const ttsPlaying = parentElement.getElementsByClassName('tts-playing');
        isElementPlaying = mediaPlaying || ttsPlaying.length > 0;
      }

      cancelTts();
      stopAudio();
      if (isElementPlaying) {
        resolve({
          success: true,
          message: 'Cancel tts',
        });
        return;
      }
      shouldWait = true;
    }

    removeAllMediaPlayingClass();
    if (shouldWait) {
      await ObjectUtils.delay(700);
    } else {
      await ObjectUtils.delay(100);
    }

    const videoList = (document.getElementsByTagName('video'));
    [].forEach.call(videoList, (vl) => {
      vl.pause();
    });

    if (playsVideoInline) {
      // to unlock the video inline on IOS
      await playRegisteredAudio();
    }

    try {
      if (isTtsAbleToPlay && !(audioUrl && (audioUrl.indexOf('recorded-audio') >= 0 || audioUrl.indexOf('recording') >= 0))) {
        // for ios, preload the video after the user tab the screen
        if (videoUrl && !audioUrl) {
          preloadVideo(videoUrl);
        }
        await ObjectUtils.delay(50);
        await playTextToSpeech(textToRead, tts);
      }

      if (onlyTts) {
        resolve({
          success: true,
          message: 'Request to play only tts',
        });
        onMediaPlayFinish();
        return;
      }

      if (!videoUrl && !audioUrlToPlay) {
        resolve({
          success: true,
          message: 'No audio and video to play',
        });
        onMediaPlayFinish();
        return;
      }

      if (audioUrlToPlay && videoUrl) {
        // play audio then video here
        try {
          onMediaStarted(parentElementId, highlightIcon);
          // for ios, preload the video after the user tab the screen
          if (videoUrl) {
            preloadVideo(videoUrl);
          }
          await playAudio(audioUrlToPlay);
          setTimeout(() => {
            setOpenVideo({
              open: true,
              onDialogVideoEnd: () => {
                onMediaPlayFinish();
                resolve({
                  success: true,
                  message: 'Finish playing audio and video.',
                });
              },
            });
          }, 1000);
        } catch (e) {
          Logger.logError(e);
          resolve({
            success: false,
          });
          onMediaEnded();
        }
      } else if (audioUrlToPlay) {
        // play audio here
        try {
          onMediaStarted(parentElementId, highlightIcon);
          await playAudio(audioUrlToPlay);
          resolve({
            success: true,
            message: 'Finish playing audio. (No video)',
          });
        } catch (e) {
          Logger.logError(e);
          resolve({
            success: false,
          });
        } finally {
          onMediaPlayFinish();
          onMediaEnded(parentElementId);
        }
      } else if (playsVideoInline) {
        playsVideoInlineRef.current.currentTime = 0;
        playsVideoInlineRef.current.play();
      } else {
        onMediaStarted(parentElementId);
        setOpenVideo({
          open: true,
          onDialogVideoEnd: () => {
            onMediaPlayFinish();
            resolve({
              success: true,
              message: 'Finish playing video. (No audio)',
            });
          },
        });
      }
    } catch (e) {
      if (e && e.isCancel) {
        resolve({
          success: true,
          message: 'Media is cancelled',
          isCancel: true,
        });
        onMediaPlayFinish();
      }
    }
  });

  useRegisterSwitchAccessScanEvent(playerActionRef, () => {
    playMedia();
  });

  const handleOnClick = async (e) => {
    if (disablePlay) {
      return;
    }

    await playMedia();
    onMediaPlayFinishForClick(e);
  };

  const handleClose = () => {
    setOpenVideo({
      open: false,
      onDialogVideoEnd: () => { },
    });
    onMediaEnded();
    ObjectUtils.setTimeout(() => {
      if (promiseRef.current && promiseRef.current.resolve) {
        promiseRef.current.resolve();
      }
    }, 200);
  };

  // Fix for IOS. After the video plays, it needs to set the property muted to true,
  // otherwise the all sound components will stop working.
  const onInlineVideoPlay = async () => {
    cancelTts();
    stopAudio();
    await ObjectUtils.delay(100);
    ObjectUtils.setTimeout(() => {
      playsVideoInlineRef.current.muted = false;
    }, 0.01);

    ObjectUtils.setTimeout(() => {
      onMediaStarted(playsVideoInlineRef.current.id);
    }, 200);
  };

  const onInlineVideoStop = () => {
    onMediaEnded(playsVideoInlineRef.current.id);
    playsVideoInlineRef.current.muted = true;
    if (promiseRef.current && promiseRef.current.resolve) {
      onMediaPlayFinish();
      promiseRef.current.resolve();
    }
  };

  const onInlineVideoError = (error) => {
    onMediaEnded();
    Logger.logError(error);
    playsVideoInlineRef.current.muted = true;
    if (promiseRef.current && promiseRef.current.reject) {
      onMediaPlayFinish();
      promiseRef.current.reject(error);
    }
  };

  useImperativeHandle(ref, () => ({
    playMedia: (options) => playMedia(options),
    elementRef: elementRef.current,
  }));

  const handleEvent = useDetermineClickOrTouchEventForDnd(handleOnClick, forDndElement);

  return (
    <div id={id} className={`${className} media-player-component`} {...props} ref={elementRef} data-test='media-player-component'>
      {
        (audioUrlToPlay || (videoUrl && !playsVideoInline) || isTtsAbleToPlay)
          ? (
            <Box className={`media-player-action-area ${disablePlay ? '' : 'enable-play'}`} data-switch-access-scan='true' {...handleEvent} ref={playerActionRef}>
              {omitPlayIcon ? '' : <PlayCircleFilledIcon className='media-player-icon' data-test='media-player-icon' />}
            </Box>
          ) : ''
      }
      <VideoPlayer
        key={`video-player-${id}`}
        videoUrl={videoUrl}
        open={openVideo.open}
        onDialogVideoEnd={openVideo.onDialogVideoEnd}
        handleClose={handleClose}
        playsVideoInline={playsVideoInline}
        ref={playsVideoInlineRef}
        onInlineVideoPlay={onInlineVideoPlay}
        onInlineVideoStop={onInlineVideoStop}
        onInlineVideoError={onInlineVideoError}
        videoPoster={videoPoster}
        forPrinting={forPrinting}
      />
    </div>
  );
});

MediaPlayer.defaultProps = {
  id: null,
  audioUrl: null,
  videoUrl: null,
  className: null,
  parentElementId: null,
  omitPlayIcon: false,
  playsVideoInline: false,
  videoPoster: null,
  highlightIcon: false,
  disablePlay: false,
  textToRead: [],
  tts: {
    enabled: false,
    ttsMode: 'rv',
  },
  onMediaPlayFinish: () => { },
  forDndElement: false,
  forPrinting: false,
  onMediaPlayFinishForClick: () => null,
};

MediaPlayer.propTypes = {
  id: PropTypes.string,
  audioUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  className: PropTypes.string,
  parentElementId: PropTypes.string,
  omitPlayIcon: PropTypes.bool,
  playsVideoInline: PropTypes.bool,
  videoPoster: PropTypes.string,
  highlightIcon: PropTypes.bool,
  disablePlay: PropTypes.bool,
  tts: PropTypes.shape({
    enabled: PropTypes.bool,
    ttsMode: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  textToRead: PropTypes.array,
  onMediaPlayFinish: PropTypes.func,
  forDndElement: PropTypes.bool,
  forPrinting: PropTypes.bool,
  onMediaPlayFinishForClick: PropTypes.func,
};

export default MediaPlayer;

import PropTypes from 'prop-types';
import DefaultImage from '../../../static/images/default_thumbnail.png';
import './AssignmentLesson.scss';
import {
  TOPIC_PRE_ASSESSMENT,
  TOPIC_POST_ASSESSMENT,
  UNIT_SUMMATIVE_ASSESSMENT,
  LESSON_TYPE_MAPPING,
} from '../../../constants/LessonConstant';

export default function AssignmentLesson({
  lesson,
  className,
  withSash,
}) {
  return (
    <div className={`${className} assignment-lesson-for-ordering`} data-test='assignment-lesson-for-ordering'>
      {
        (withSash && [
          TOPIC_PRE_ASSESSMENT,
          TOPIC_POST_ASSESSMENT,
          UNIT_SUMMATIVE_ASSESSMENT,
        ].includes(lesson.attributes.lessonType)) && (
          <div className='assign-leson-label'>
            {LESSON_TYPE_MAPPING[lesson.attributes.lessonType]}
          </div>
        )
      }
      <div
        className='lesson-image'
        style={{ backgroundImage: `url(${lesson.attributes.thumbnailUrl || DefaultImage})` }}
      />
      <div className='lesson-name'>
        {lesson.attributes.name}
      </div>
    </div>
  );
}

AssignmentLesson.defaultProps = {
  className: '',
  withSash: true,
};

AssignmentLesson.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    meta: PropTypes.object,
    attributes: PropTypes.object,
    relationships: PropTypes.object,
  }).isRequired,
  className: PropTypes.string,
  withSash: PropTypes.bool,
};

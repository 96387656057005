export const GRADES_FOR_FILTER = [
  { key: 'Pre-K', name: 'Pre-K', value: 'pre-k' },
  { key: 'K,1,2', name: 'K-2', value: 'k-2nd' },
  { key: '3,4,5', name: '3-5', value: '3-5' },
  { key: '6,7,8', name: '6-8', value: '6-8' },
  { key: '9,10,11,12', name: '9-12', value: '9-12' },
  { key: 'All', name: 'Everyone', value: ['all', 'all ages', 'none', 'n/a', 'no classification', 'undefined'] },
];

export const ACTIVITIES_FILTER = [
  { key: 'Sorting', name: 'Sorting', value: 'Sorting' },
  { key: 'Game', name: 'Game', value: 'Game' },
  { key: 'Book', name: 'Book', value: 'Book' },
  { key: 'Matching', name: 'Matching', value: 'Matching' },
  { key: 'Sequencing', name: 'Sequencing', value: 'Sequencing' },
  { key: 'Categorizing', name: 'Categorizing', value: 'Categorizing' },
  { key: 'FlexiMatching', name: 'Advanced Matching', value: 'FlexiMatching' },
  // { key: 'MultipleChoice', name: 'Multiple Choice', value: 'MultipleChoice' },
  { key: 'MultipleChoice', name: 'Multiple Choice', value: 'MultipleChoice' },
];

export const STANDARD_LEVELS = [
  { key: 1, name: '1', value: '1' },
  { key: 2, name: '2', value: '2' },
  { key: 3, name: '3', value: '3' },
];

const userAgent = navigator.userAgent || navigator.vendor || window.opera;

export const getMobileOperatingSystem = () => {
  // Windows Phone must come first because its UA also contains 'Android'
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const isMobile = () => {
  const currentDevice = getMobileOperatingSystem();

  // ios 14 there is no word iPad and iPhone to test against anymore
  // the temp workaround for now is to detect the ontouchstart event
  return currentDevice !== 'unknown' || 'ontouchstart' in document.documentElement;
};

export const isSafari = () => userAgent.indexOf('Safari') > -1;

import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import BrowseAudioWithSmallUploaders from './editor/BrowseAudioWithSmallUploaders';
import GameCardGrids from '../../../../../components/vizzle/page/game/GameCardGrids';
import GameCardSetup from './editor/game/GameCardSetup';
import GameTextAndMediaEditor from './editor/game/GameTextAndMediaEditor';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getInitialGameCard, getBorderColorByCardIndex } from '../../../../../utils/activitytype/ActivityTypeInitialData';

const NUMBER_OF_CARDS = 20;

const getNumberOfTilesValues = () => {
  const result = [];
  for (let i = 8; i <= 20; i++) {
    result.push({
      value: i,
      label: i,
    });
  }
  return result;
};

const GameTextAndMedia = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const getNumberOfTiles = () => ({
    selectedCardIndex: 0,
    numberOfTiles: selectedPage.gameCards.filter((card) => !card.disabled).length,
  });

  const [state, setState] = useState({ ...getNumberOfTiles() });

  useEffect(() => {
    setState({ ...getNumberOfTiles() });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  const handleOnCardSelected = (e, cardIndex) => {
    e.stopPropagation();
    setState({
      ...state,
      selectedCardIndex: cardIndex,
    });
  };

  const handleOnNumberOfTilesChanged = (e) => {
    e.stopPropagation();
    const { value } = e.target;
    setState({
      ...state,
      numberOfTiles: value,
      selectedCardIndex: 0,
    });

    selectedPage.gameCards.forEach((card) => {
      // eslint-disable-next-line no-param-reassign
      delete card.disabled;
    });

    for (let i = value - 1; i < selectedPage.gameCards.length - 1; i++) {
      selectedPage.gameCards[i].disabled = true;
    }

    // Set the border color of the last cell
    selectedPage.gameCards[selectedPage.gameCards.length - 1].borderColor = getBorderColorByCardIndex(value - 1);

    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const fillTheGap = (gameCards) => {
    if (gameCards.length <= NUMBER_OF_CARDS) {
      for (let i = gameCards.length; i <= NUMBER_OF_CARDS - 1; i++) {
        gameCards.splice(gameCards.length - 1, 0, {
          ...getInitialGameCard(i - 1),
          disabled: true,
        });
      }
    }
    return gameCards;
  };

  const handleOnLandingSoundSelect = (audioData) => {
    const updatedSelectedPage = {
      ...selectedPage,
      landingAudio: audioData,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  const handleOnLandingSoundRemove = () => {
    const updatedSelectedPage = {
      ...selectedPage,
      landingAudio: null,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  const props = {
    gameCards: fillTheGap(selectedPage.gameCards),
    selectedCardIndex: state.selectedCardIndex,
    onCardSelected: handleOnCardSelected,
    numberOfTiles: state.numberOfTiles,
    onNumberOfTilesChanged: handleOnNumberOfTilesChanged,
    spinner: selectedPage.spinner,
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid data-test='game-editor' item xs={6} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <Box height={window.innerWidth <= 1024 ? '10.5%' : '13%'} display='flex' flexDirection='row'>
            <Box width='30%'>
              <TextField
                select
                label='Number of tiles'
                value={state.numberOfTiles}
                variant='outlined'
                onChange={handleOnNumberOfTilesChanged}
                fullWidth
              >
                {getNumberOfTilesValues().map((element, index) => (
                  <MenuItem key={`grid-card-editor-selectable-value-${index}`} value={element.value}>
                    {element.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box width='70%'>
              <BrowseAudioWithSmallUploaders
                title='Landing sound (optional)'
                placeHolder='Click to select a Landing Sound'
                onSelect={handleOnLandingSoundSelect}
                onRemove={handleOnLandingSoundRemove}
                audio={selectedPage.landingAudio}
              />
            </Box>
          </Box>
          <Box height='82%' mt='15px'>
            <GameCardGrids {...props} />
          </Box>
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <GameCardSetup selectedCardIndex={state.selectedCardIndex} />
          <Box height='75%'>
            <GameTextAndMediaEditor selectedCardIndex={state.selectedCardIndex} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameTextAndMedia;

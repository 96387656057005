import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import VideoCamIcon from '@mui/icons-material/OndemandVideo';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TextLines from '../../../text/TextLines';
import './CauseAndEffectEditorCard.scss';

const SECTION_IMAGE = 'image';
const SECTION_TEXT = 'text';
const SECTION_AUDIO = 'audio';

export default function CauseAndEffectEditorCard({
  card,
  isSelected,
  selectedSection,
  onImageSelected,
  onTextSelected,
  onAudioSelected,
}) {
  const caredId = uuidv1();
  return (
    <div className='cause-and-effect-editor-card vizzle-cause-and-effect-card-editor'>
      <Card
        id={caredId}
        key={caredId}
        className={`
                card-editor
                ${isSelected ? 'card-editor-selected' : ''}
              `}
      >
        <CardActionArea
          className={`
                  card-editor-image-action 
                  ${selectedSection === SECTION_IMAGE ? 'card-editor-section-selected' : ''} 
                  `}
          onClick={() => { onImageSelected(SECTION_IMAGE); }}
        >
          {
            card.image ? (
              <CardMedia
                className='card-editor-image'
                image={card.image}
                title='Cause and Effect'
              >
                {
                  card.video ? <VideoCamIcon className='card-editor-with-video-icon' /> : ''
                }

              </CardMedia>
            )
              : (
                <Box
                  component='div'
                  className='card-editor-placeholder'
                >
                  Image/Video
                </Box>
              )
          }
        </CardActionArea>
        <CardActionArea
          className={`
                  card-editor-text-action 
                  ${selectedSection === SECTION_TEXT ? 'card-editor-section-selected' : ''} 
                  `}
          onClick={() => { onTextSelected(SECTION_TEXT); }}
        >
          {card.text
            ? (
              <Box className='card-editor-with-value'>
                <TextLines textLines={card.text} isThumbnail />
              </Box>
            ) : (
              <Box className='card-editor-placeholder'>Text</Box>
            )}
        </CardActionArea>
        <CardActionArea
          className={`
                  card-editor-audio-action 
                  ${selectedSection === SECTION_AUDIO ? 'card-editor-section-selected' : ''} 
                  `}
          onClick={() => { onAudioSelected(SECTION_AUDIO); }}
        >
          {card.audio
            ? (
              <Box className='card-editor-with-value card-editor-with-audio'>
                <VolumeUpIcon className='card-editor-with-audio-icon' />
              </Box>
            ) : (
              <Box
                component='div'
                className='card-editor-placeholder'
              >
                Audio
              </Box>
            )}
        </CardActionArea>
      </Card>
    </div>
  );
}

CauseAndEffectEditorCard.defaultProps = {
  selectedSection: SECTION_IMAGE,
  onImageSelected: () => { },
  onTextSelected: () => { },
  onAudioSelected: () => { },
  card: { key: 0 },
  isSelected: false,
};

CauseAndEffectEditorCard.propTypes = {
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  onImageSelected: PropTypes.func,
  onTextSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
  card: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    text: PropTypes.object,
  }),
  isSelected: PropTypes.bool,
};

import { useState } from 'react';
import { getFeedbackChatData, saveFeedbackChatData, saveFeedbackViewed } from '../../../../services/FeedbackChatService';
import { useDomain } from '../../../../states/AppDomainProvider';
import DateUtils from '../../../../utils/DateUtils';
import Logger from '../../../../utils/Logger';
import { isStudent, isTeacher } from '../../../../utils/User';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';

export const useGetFeedbackChat = ({ studentHistoryId, lessonId }) => {
  const { lessonPlayChatDomain, lessonPlayDomain } = useLessonPlay();
  const { lesson } = lessonPlayDomain.domainData;
  const getFeedbackChat = async ({ fetchData, showChatWidget, studentId, hideWidgetWhenNoFeedbackChat, teacherId, defaultOpen }) => {
    let feedbackChatData = [];
    let shouldShowWidget = showChatWidget;
    if (fetchData || showChatWidget) {
      feedbackChatData = await getFeedbackChatData({ studentHistoryId, teacherId, studentId, lessonId: lesson.lessonId || lessonId }) || [];
      if (hideWidgetWhenNoFeedbackChat && feedbackChatData.length === 0) {
        shouldShowWidget = false;
      }
    }
    await lessonPlayChatDomain.updateChatData({
      showChatWidget: shouldShowWidget,
      studentId,
      feedbackChat: feedbackChatData,
      defaultOpen,
    });
  };

  return {
    getFeedbackChat,
  };
};

export const useHandleChatWidget = ({ onChatWidgetOpen, studentHistoryId }) => {
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const { userDomain } = useDomain();
  const { user: userLogin } = userDomain.domainData;

  const { lessonPlayDomain, lessonPlayChatDomain } = useLessonPlay();
  const { lesson, user, currentPageIndex, isPlayable } = lessonPlayDomain.domainData;
  const { studentId, feedbackChat } = lessonPlayChatDomain.domainData;
  const userToDetermine = userLogin.id === user.id ? user : userLogin;

  const [chatOpen, setChatOpen] = useState(false);
  const handleOnChatOpen = (open, showBadge) => {
    setChatOpen(open);
    if (open) {
      onChatWidgetOpen();
    }
    if (!open || !showBadge) {
      return;
    }

    try {
      callRemoteServiceWrapper(async () => {
        lessonPlayChatDomain.updateFeedbackChat(
          feedbackChat.map((f) => ({
            ...f,
            viewed: true,
          })),
        );
        await saveFeedbackViewed({ studentHistoryId, studentId, lessonId: lesson.lessonId });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  const issuerToDetect = isTeacher(userToDetermine) ? 'Student' : 'Teacher';
  const showBadge = Boolean(feedbackChat.find((f) => f.issuer === issuerToDetect && f.viewed === false));

  const dataRecords = feedbackChat.map((f) => {
    let position = 'left';
    if (userLogin.userType === user.userType) {
      position = f.issuer === user.userType ? 'right' : 'left';
    } else if (isPlayable) {
      position = f.issuer === 'Student' ? 'right' : 'left';
    } else {
      position = f.issuer === 'Teacher' ? 'right' : 'left';
    }
    return {
      position,
      text: f.msg,
      date: f.createdDate,
      sender: isStudent(userToDetermine) && f.issuer.toLowerCase() === 'student' ? f.teacherName : null,
    };
  });

  const handleOnChatSubmit = async (text) => {
    try {
      callRemoteServiceWrapper(async () => {
        const isTeacherUserPlaying = isTeacher(userToDetermine);
        const payload = {
          lessonId: lesson.lessonId,
          studentId,
          teacherId: isTeacherUserPlaying ? userToDetermine.id : null,
          issuer: isPlayable ? user.userType : userLogin.userType,
          page: currentPageIndex,
          msg: text,
          studentHistoryId,
        };

        const currentDate = DateUtils.getCurrentDate();
        lessonPlayChatDomain.updateFeedbackChat([
          ...feedbackChat,
          {
            ...payload,
            viewed: true,
            createdDate: DateUtils.convertDateToMMddYYYY(currentDate),
          },
        ]);
        await saveFeedbackChatData(payload);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    chatOpen,
    handleOnChatOpen,
    dataRecords,
    handleOnChatSubmit,
    showBadge,
  };
};

import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import SwitchKeyboard from '../../../../settings/SwitchKeyboard';

const SwitchKeyboardSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Switch/Keyboard Accessibility');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onConfirm = () => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      switchAccessibility: 1,
      interactionType: 1,
    });
  };

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      switchAccessibility: newValue,
    });
  };

  return (
    <SwitchKeyboard
      profile={selectedStudentProfile}
      onConfirmCallback={onConfirm}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default SwitchKeyboardSettings;

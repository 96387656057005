import PropTypes from 'prop-types';
import SortingActivityResponse from './SortingActivityResponse';
import './SelectedActivityResponseCards.scss';
import SubmittedAnswerIndicator from '../../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

export default function SelectedActivityResponseCards({
  id,
  promptKey,
  responseCards,
  selectedResponseCards,
}) {
  const matchedResponseCards = [];
  selectedResponseCards.forEach((cardIndex) => {
    if (responseCards[cardIndex].key === promptKey) {
      matchedResponseCards.push(responseCards[cardIndex]);
    }
  });

  return (
    <div className='selected-activity-response-cards-component'>
      {matchedResponseCards.map((card, cardIndex) => (
        <div
          key={`selected-response-display-${id}-${cardIndex}`}
          className={`selected-response-display-container ${matchedResponseCards.length < 7 ? 'selected-response-display' : 'selected-response-display-small'} `}
        >
          <SortingActivityResponse responseCard={card} disablePlayMedia />
          {/** Alway display the indicator for the correct answer */}
          <SubmittedAnswerIndicator correctSubmission={card.correctSubmission || ((card.correctSubmission === undefined || card.correctSubmission === null) && card.key === promptKey)} />
        </div>
      ))}
    </div>
  );
}

SelectedActivityResponseCards.defaultProps = {
  id: 0,
  promptKey: null,
  responseCards: [],
  selectedResponseCards: [],
};

SelectedActivityResponseCards.propTypes = {
  id: PropTypes.string,
  promptKey: PropTypes.string,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  selectedResponseCards: PropTypes.arrayOf(PropTypes.number),
};

import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LessonStandardList from '../../../card/LessonStandardList';
import TooltipWithIndicator from '../tooltip/TooltipWithIndicator';
import { guideToLevelingTooltipText } from '../../texts/TooltipText';
import './LessonStandardSection.scss';

export default function LessonStandardSection({ title, lessonLevel, standardList }) {
  const hasStandard = standardList && standardList.length > 0;
  return (
    <Accordion
      className='lesson-standard-section'
      data-test='lesson-standard-section'
      expanded={hasStandard ? undefined : true}
    >
      <AccordionSummary
        className='standard-title'
        expandIcon={<ExpandMoreIcon className='expand-icon' />}
      >
        <Typography variant='h4' className='title-container'>
          <div className='title'>{title}</div> {hasStandard && (<div className='click-to-expand-label'>(Click to Expand)</div>)}
        </Typography>

        {lessonLevel ? (
          <div className='level-title'>
            <Typography variant='h4'>
              Level {lessonLevel}
            </Typography>
            <TooltipWithIndicator className='tooltip' {...guideToLevelingTooltipText} />
          </div>
        ) : null}
      </AccordionSummary>
      <div>
        {hasStandard ? (
          <ul className='standard-list' data-test='standard-list'>
            {
              standardList.map((standard) => (
                <li key={`lesson-standard-${standard.id}`} data-test={`lesson-standard-${standard.id}`}>
                  <div className='standard-code' data-test='standard-code'>
                    {standard.attributes.standardCode}
                  </div>
                  <div className='standard-description' data-test='standard-description'>
                    {standard.attributes.standardDescription}
                  </div>
                </li>

              ))
            }
          </ul>
        ) : (
          <div>
            This activity is not associated with any state standards.
          </div>
        )}

      </div>
    </Accordion>
  );
}

LessonStandardSection.defaultProps = {
  title: null,
  lessonLevel: null,
  standardList: [],
};

LessonStandardSection.propTypes = {
  lessonLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  standardList: PropTypes.array,
};

import { useState, useEffect, useRef } from 'react';
import { getDistrictAdminAccess, updateAdminsAccess, updateDistrictAdminSchoolAccess, removeDistrictAdminSchoolAccess } from '../../../../../services/AdminService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../../context/AdminContextProvider';

export const usePrepareData = () => {
  const { adminAdminsDomain } = useAdmin();
  const { selectedAdminIds, adminsData } = adminAdminsDomain.domainData;

  const selectedAdminsData = adminsData.data.filter((ad) => selectedAdminIds.includes(ad.id));

  return {
    selectedAdminIds,
    selectedAdminsData,
  };
};

export const useUpdatePermissions = ({
  setOpen,
}) => {
  const { uiDomain } = useDomain();
  const {
    selectedAdminIds,
    selectedAdminsData,
  } = usePrepareData();

  const { adminAdminsDomain } = useAdmin();
  const { adminsData } = adminAdminsDomain.domainData;

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const updatePermissions = async (permissionsData) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await updateAdminsAccess(selectedAdminIds, permissionsData);
        selectedAdminsData.forEach((sd) => {
          // eslint-disable-next-line no-param-reassign
          sd.attributes = {
            ...sd.attributes,
            ...permissionsData,
          };
        });
        await adminAdminsDomain.updateAdminsData({ ...adminsData });

        setOpen(false);
        let message = `Permissions for ${selectedAdminsData.length} users are updated.`;
        if (selectedAdminsData && selectedAdminsData.length === 1) {
          const selectedAdmin = selectedAdminsData[0];
          const { attributes: {
            firstName,
            lastName,
          } } = selectedAdmin;
          message = `Permissions for ${firstName} ${lastName} has been updated.`;
        }
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    updatePermissions,
  };
};

export const useGetAdminDistrictData = ({
  open,
}) => {
  const {
    selectedAdminIds,
  } = usePrepareData();

  const [adminDistrictSelectedDistrictIdsSchoolIds, setAdminDistrictSelectedDistrictIdsSchoolIds] = useState({
    districtIds: [],
    schoolIds: [],
  });
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const originalAdminDistrictSelectedSchoolIds = useRef([]);

  useEffect(() => {
    if (open && selectedAdminIds && selectedAdminIds.length === 1) {
      const getData = async () => {
        try {
          await callRemoteServiceWrapper(async () => {
            const result = await getDistrictAdminAccess(selectedAdminIds[0]);
            const schoolIds = [];
            const districtIds = [];
            result.data.forEach((d) => {
              schoolIds.push(d.id);
              districtIds.push(d.attributes.vizzleDistrictId.toString());
            });

            originalAdminDistrictSelectedSchoolIds.current = schoolIds;
            setAdminDistrictSelectedDistrictIdsSchoolIds({
              schoolIds,
              districtIds: [...new Set(districtIds)],
            });
          });
        } catch (e) {
          Logger.logWhenDebugModeIsOn(e);
        }
      };
      getData();
    }

    return () => {
      setAdminDistrictSelectedDistrictIdsSchoolIds({
        districtIds: [],
        schoolIds: [],
      });
      originalAdminDistrictSelectedSchoolIds.current = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    adminDistrictSelectedDistrictIdsSchoolIds,
    setAdminDistrictSelectedDistrictIdsSchoolIds,
    adminDistrictSelectedSchoolLoading: loading,
    originalAdminDistrictSelectedSchoolIds,
  };
};

export const useUpdateAdminSchoolAccess = ({
  setOpen,
}) => {
  const { uiDomain } = useDomain();
  const {
    selectedAdminIds,
    selectedAdminsData,
  } = usePrepareData();

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const updateSchoolAccess = async (schoolIds, originalSchoolIds) => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (selectedAdminIds.length === 1) {
          const toRemove = originalSchoolIds.filter((o) => !schoolIds.includes(o));
          const toAdd = schoolIds.filter((c) => !originalSchoolIds.includes(c));

          const promistList = [];
          if (toRemove.length > 0) {
            promistList.push(removeDistrictAdminSchoolAccess(selectedAdminIds[0], toRemove));
          }
          if (toAdd.length > 0) {
            promistList.push(updateDistrictAdminSchoolAccess(selectedAdminIds, toAdd));
          }
          await Promise.all(promistList);
        } else {
          await updateDistrictAdminSchoolAccess(selectedAdminIds, schoolIds);
        }

        setOpen(false);
        let message = `School Access for ${selectedAdminsData.length} users are updated.`;
        if (selectedAdminsData && selectedAdminsData.length === 1) {
          const selectedAdmin = selectedAdminsData[0];
          const { attributes: {
            firstName,
            lastName,
          } } = selectedAdmin;
          message = `School Access for ${firstName} ${lastName} has been updated.`;
        }
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    updateSchoolAccess,
  };
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField } from '@mui/material';
import './SearchForm.scss';

const SearchForm = ({
  initCriteria,
  onSearchClick,
  onSearchClear,
}) => {
  const [state, setState] = useState({ ...initCriteria });

  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    const newState = {
      username: '',
      firstName: '',
      lastName: '',
    };
    setState(newState);
    onSearchClear(newState);
  };
  return (
    <form
      className='admin-search-form'
      onSubmit={(e) => {
        e.preventDefault();
        onSearchClick(state);
      }}
    >
      <TextField
        label='Username'
        name='username'
        value={state.username || ''}
        onChange={handleOnChange}
        className='admin-search-form-input-field'
        variant='standard'
        data-private
      />
      <TextField
        label='First Name'
        name='firstName'
        value={state.firstName || ''}
        onChange={handleOnChange}
        className='admin-search-form-input-field'
        variant='standard'
        data-private
      />
      <TextField
        label='Last Name'
        name='lastName'
        value={state.lastName || ''}
        onChange={handleOnChange}
        className='admin-search-form-input-field'
        variant='standard'
        data-private
      />

      <Button
        type='submit'
        className='btn-secondary search-button'
        variant='contained'
        disabled={(!state.firstName && !state.lastName && !state.username)}
      >
        SEARCH
      </Button>
      <Box className='clear-button' onClick={clearValues}>
        Clear
      </Box>
    </form>
  );
};

SearchForm.defaultProps = {
  initCriteria: {
    username: '',
    firstName: '',
    lastName: '',
  },
  onSearchClick: () => null,
  onSearchClear: () => null,
};

SearchForm.propTypes = {
  initCriteria: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  onSearchClick: PropTypes.func,
  onSearchClear: PropTypes.func,
};

export default SearchForm;

import { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import StudentGroupForm, { studentGroupFormValidator } from '../forms/StudentGroupForm';
import { useHandleAddStudentGroup, useHandleStudentGroupActionForm } from './StudentGroupActionHooks';
import './StudentGroupAction.scss';

const AddStudentGroupAction = ({
  onStudentGroupAdded,
}) => {
  const [open, setOpen] = useState(false);
  const {
    studentGroupData,
    handleStudentGroupFormChange,
    resetForm,
  } = useHandleStudentGroupActionForm();

  const handleOnCancel = () => {
    resetForm();
    setOpen(false);
  };

  const {
    saving,
    addNewStudentGroup,
  } = useHandleAddStudentGroup({
    onSaveSuccess: () => {
      handleOnCancel();
      onStudentGroupAdded();
    },
  });
  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right action-button',
      title: 'Cancel',
      loading: saving,
    }, {
      onClick: () => { addNewStudentGroup(studentGroupData); },
      className: 'btn-primary action-button',
      title: 'Save',
      disabled: !studentGroupFormValidator(studentGroupData),
      loading: saving,
    },
    ];
    return buttons;
  };

  return (
    <>
      <ButtonWithSpinner
        label='Add Student Group'
        className='margin-left-medium'
        onClick={() => { setOpen(true); }}
        data-test='add-student-group-action'
      />
      {open && (
        <CustomDialog
          className='add-student-group-dialog'
          openDialog={open}
          onClose={handleOnCancel}
          title='Add Student Group'
          content={(
            <StudentGroupForm
              studentGroupName={studentGroupData.name}
              selectedStudentIds={studentGroupData.students}
              onDataChange={handleStudentGroupFormChange}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

AddStudentGroupAction.defaultProps = {
  onStudentGroupAdded: () => null,
};

AddStudentGroupAction.propTypes = {
  onStudentGroupAdded: PropTypes.func,
};

export default AddStudentGroupAction;

import { useEffect, useState } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { updateClassProfile } from '../../../../../services/UserProfileService';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

const useSettingForm = (label) => {
  const { uiDomain } = useDomain();
  const [value, setValue] = useState(null);

  const { loading, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  const handleOnClick = async (dataToSave) => {
    await callRemoteServiceWrapper(async () => {
      try {
        await updateClassProfile(dataToSave);
        setValue(null);
        await uiDomain.showSnackbar(true, 'info', 3000, `${label} has been updated.`);
      } catch (e) {
        Logger.logError({
          ERROR: e,
        });
      }
    });
  };

  useEffect(
    () => {
      // Cleanup the state
      const { snackbar } = uiDomain.domainData;
      if (snackbar.open) {
        uiDomain.closeSnackbar();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    loading,
    value,
    handleOnChange,
    handleOnClick,
  };
};

export default useSettingForm;

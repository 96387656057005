import PropTypes from 'prop-types';
import { Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import './ProfileBox.scss';

const ProfileBox = ({
  label,
  name,
  value,
  onChange,
  type,
  className,
  isEdit,
  helperText,
  endDecorator,
  fullWidth,
  isRequired,
  onFocus,
  onBlur,
  disabled,
}) => (
  <Box data-test={`${name}-profile-box`} className={`profile-box ${className}`}>
    <Stack direction='row' gap={2} alignItems='center' position='relative'>
      <Typography className={`profile-box-label ${isRequired ? 'required' : ''}`}>
        {label}
      </Typography>
      <Box className='end-decorator-container'>
        {endDecorator}
      </Box>
    </Stack>
    <TextField
      data-test={`${name}-profile-text-field`}
      className='profile-box-textfield'
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      helperText={helperText}
      variant='outlined'
      data-private
      InputProps={{
        readOnly: !isEdit || disabled,
        autoComplete: type === 'password' ? 'new-password' : null,
        'data-private': 'redact',
      }}
      fullWidth={fullWidth}
      required={isRequired}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  </Box>
);

ProfileBox.defaultProps = {
  className: '',
  name: '',
  label: '',
  value: '',
  onChange: () => { },
  isEdit: false,
  helperText: '',
  type: '',
  endDecorator: null,
  fullWidth: false,
  isRequired: false,
  onFocus: () => null,
  onBlur: () => null,
  disabled: false,
};

ProfileBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.string,
  endDecorator: PropTypes.node,
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ProfileBox;

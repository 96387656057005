import { AppBar, Tab, Tabs } from '@mui/material';
import { Navigate, Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import {
//   Class as ClassIcon,
//   CollectionsBookmark as TopicIcon,
//   ListAlt as ListAltIcon,
//   ViewList as ViewListIcon,
//   DriveFileRenameOutline as DriveFileRenameOutlineIcon,
//   AdUnits as AdUnitsIcon,
//   Assessment as TopicAssessmentIcon,
//   Analytics as UnitAssessmentIcon,
//   Subject as SubjectIcon,
//   UploadFile as UploadFileIcon,
// } from '@mui/icons-material';
import TeacherCourseLessons from './tabs/topic-lessons/TeacherCourseLessons';
import TeacherCourseUnitGuide from './tabs/topic/TeacherCourseTopicGuide';
import TeacherCourseLessonPlan from './tabs/lesson-plans/TeacherCourseLessonPlan';
import './TeacherCourseMainTab.scss';
import GenerateStandards from './tabs/standards/GenerateStandards';
import GeneratedLessons from './tabs/generated-lessons/GeneratedLessons';
import { useGetIsLessonTemplateFeatureAvailable, useGetIsStandardTemplateFeatureAvilable } from './TeacherCourseSearchBarHooks';
import TopicInfo from './tabs/topic-info/TopicInfo';
import { useDomain } from '../../../states/AppDomainProvider';
import { TEACHER_COURSES_ROUTES } from './TeacherCourseConstants';
import '../../../components/steppers/TabStyle.scss';
import { TOPIC_POST_ASSESSMENT, TOPIC_PRE_ASSESSMENT, UNIT_SUMMATIVE_ASSESSMENT } from '../../../constants/LessonConstant';
import TopicStandards from './tabs/topic-standards/TopicStandards';
import ExtensionActivities from './tabs/extension-activities/ExtensionActivities';

const TeacherCourseMainTab = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { subjectId: currentSelectedSubjectId } = useParams();

  const steps = [
    {
      label: 'Activities',
      element: <TeacherCourseLessons
        isCourseDetailEditable
        excludeLessonTypes={[
          TOPIC_PRE_ASSESSMENT,
          TOPIC_POST_ASSESSMENT,
          UNIT_SUMMATIVE_ASSESSMENT,
        ]}
      />,
      // icon: <ClassIcon />,
      link: TEACHER_COURSES_ROUTES.LESSONS,
    },
    {
      label: 'Topic Assessment',
      element: <TeacherCourseLessons
        includeLessonTypes={[
          TOPIC_PRE_ASSESSMENT,
          TOPIC_POST_ASSESSMENT,
        ]}
        noLessonMessage='Assessments coming soon'
        subjectTitle='Assessments'
      />,
      // icon: <TopicAssessmentIcon />,
      link: TEACHER_COURSES_ROUTES.TOPIC_ASSESSMENT,
    },
    {
      label: 'Lesson Plans',
      element: <TeacherCourseLessonPlan />,
      // icon: <ViewListIcon />,
      link: TEACHER_COURSES_ROUTES.LESSON_PLANS,
    },
    {
      label: 'Unit Guide',
      element: <TeacherCourseUnitGuide />,
      // icon: <TopicIcon />,
      link: TEACHER_COURSES_ROUTES.UNIT_GUIDE,
    },
    {
      label: 'Unit Assessment',
      element: <TeacherCourseLessons
        includeLessonTypes={[
          UNIT_SUMMATIVE_ASSESSMENT,
        ]}
        noLessonMessage='Assessments coming soon'
        subjectTitle='Assessments'
      />,
      // icon: <UnitAssessmentIcon />,
      link: TEACHER_COURSES_ROUTES.UNIT_ASSESSMENT,
    },
  ];

  const isStandardTemplateFeatureAvilable = useGetIsStandardTemplateFeatureAvilable();
  if (isStandardTemplateFeatureAvilable) {
    steps.splice(3, 0, {
      label: 'Standards',
      element: <GenerateStandards />,
      // icon: <ListAltIcon />,
      link: TEACHER_COURSES_ROUTES.STANDARDS,
    });
  }

  if (user.userModerator) {
    steps.splice(isStandardTemplateFeatureAvilable ? 4 : 3, 0, {
      label: 'Topic Standards',
      element: <TopicStandards />,
      // icon: <SubjectIcon />,
      link: TEACHER_COURSES_ROUTES.TOPIC_STANDARDS,
    });
  }

  const isLessonTemplateFeatureAvailable = useGetIsLessonTemplateFeatureAvailable();

  if (isLessonTemplateFeatureAvailable) {
    steps.push(
      {
        label: 'Activity Template Generator',
        element: <GeneratedLessons />,
        // icon: <DriveFileRenameOutlineIcon />,
        link: TEACHER_COURSES_ROUTES.LESSON_TEMPLATE_GENERATOR,
      },
    );
  }

  if (user.userModerator) {
    steps.push(
      {
        label: 'Add / Edit Topic Info',
        element: <TopicInfo />,
        // icon: <AdUnitsIcon />,
        link: TEACHER_COURSES_ROUTES.TOPIC_INFO,
      },
    );
  }

  steps.push(
    {
      label: 'Extension Activities',
      element: <ExtensionActivities />,
      // icon: <UploadFileIcon />,
      link: TEACHER_COURSES_ROUTES.EXTENSION_ACTIVITIES,
    },
  );

  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const path = location.pathname && location.pathname.split('/');
    if (path) {
      const subPath = path[path.length - 1];
      const index = steps.findIndex((am) => {
        const link = am.link.replace('/*', '');
        return link === subPath || path.includes(link);
      });
      setTabValue(index >= 0 ? index : 0);
    } else {
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedSubjectId, location, steps.length]);

  const navigate = useNavigate();

  const handleTabChange = (_event, newValue) => {
    navigate(`${steps[newValue].link.replace('/*', '')}`);
  };

  return (
    <div className='teacher-course-stepper-container'>
      <AppBar position='static' color='default' className='vizzle-tab-menu-style'>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          indicatorColor='primary'
          textColor='primary'
          aria-label='Teacher Course Tabs'
        >
          {steps.map((menu, index) => (
            <Tab
              key={`teacher-course-menu-${index}`}
              label={menu.label}
              icon={menu.icon}
              data-test={`teacher-course-tab-${menu.link}`}
            />
          ))}
        </Tabs>
      </AppBar>
      <div className='content-panel'>
        <div className='inner-content-panel'>
          <Routes>
            {steps.map((menu, index) => (
              <Route
                key={`teacher-course-route-${index}`}
                path={`${menu.link}`}
                element={menu.element}
              />
            ))}
            <Route
              path='*'
              element={<Navigate to={`${steps[0].link}`} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default TeacherCourseMainTab;

import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
  SPINNER_BOARD_NUMBER,
} from './GameConstants';
import GameActivityUtils from './GameActivityUtils';
import './GameCellCard.scss';

const GameCellCard = ({
  pageIndex,
  cardIndex,
  text,
  image,
  borderColor,
  bgColor,
  spinner,
  gameSpaceType,
  onCardClick,
}) => {
  const imageToUse = gameSpaceType === TILE_TYPE_STANDARD ? image : GameActivityUtils.getSpaceTypeImage(spinner, gameSpaceType);
  const extraImage = gameSpaceType === TILE_TYPE_LOSE_A_TURN ? GameActivityUtils.getSpaceTypeExtraImage(gameSpaceType) : '';
  const cardId = `game-card-${pageIndex}-${cardIndex}`;
  return (
    <Card
      className='game-card game-card-cell'
      id={cardId}
      onClick={(e) => onCardClick(e, cardIndex)}
      style={{ borderColor, backgroundColor: bgColor }}
      data-test={`game-tile-${cardIndex}`}
    >
      {
        gameSpaceType !== TILE_TYPE_STANDARD
          ? (
            <div className='card-text-container special-card-text'>
              {GameActivityUtils.getSpecialCardText(gameSpaceType)}
            </div>
          ) : ''
      }

      {imageToUse ? (
        <div className={`card-image-container ${!imageToUse ? 'no-height' : ''}`} data-test='card-image-container'>
          <CardMedia
            className={`card-image ${text ? 'image-with-text' : ''}`}
            image={imageToUse || 'none'}
            title='Card Image'
          />
          {extraImage ? (
            <img
              className='extra-icon'
              src={extraImage}
              alt='Extra'
            />
          ) : ''}
        </div>
      ) : ''}
      {text ? (
        <div className='card-text-container' data-test='card-text-container'>
          <TextLines textLines={text} />
        </div>
      )
        : ''}
    </Card>
  );
};

GameCellCard.defaultProps = {
  text: null,
  image: null,
  borderColor: 'black',
  bgColor: null,
  gameSpaceType: TILE_TYPE_STANDARD,
  spinner: SPINNER_BOARD_NUMBER,
  onCardClick: () => { },
};

GameCellCard.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  cardIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object,
  image: PropTypes.string,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  gameSpaceType: PropTypes.number,
  spinner: PropTypes.number,
  onCardClick: PropTypes.func,
};

export default GameCellCard;

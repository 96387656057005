import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import AddTeacherAction from './actions/AddTeacherAction';
// import EmailPasswordResetAction from './actions/EmailPasswordResetAction';
import TeacherSchoolRosterAccessAction from './actions/TeacherSchoolRosterAccessAction';
import TeachersSchoolRosterAccessAction from './actions/TeachersSchoolRosterAccessAction';
import {
  useGetTeacherTable,
  useHandleTeacherTable,
} from './TeachersHooks';
import './Teachers.scss';
import UpdateTeacherAction from './actions/UpdateTeacherAction';
import MoveTeacherAction from '../../components/actions/move-teacher/MoveTeacherAction';
import FilterTeacherAction from './actions/FilterTeacherAction';
import DeactivateTeacherAction from './actions/DeactivateTeacherAction';
import { useAdmin } from '../../context/AdminContextProvider';
import ReactivateTeacherAction from './actions/ReactivateTeacherAction';
import ImportUsersAction from '../../components/actions/import/import-users/ImportUsersAction';
import { teachersBatchUpload } from '../../../../services/AdminService';
import '../../AdminCommon.scss';
import { useCheckAddAccess, useGetDistrictAndSchool } from '../../hooks/AdminUtilityHooks';
import AdminDistrictSchoolSelector from '../../admin-district-school-selector/AdminDistrictSchoolSelector';
import { useGetUsersAndLicensesData } from '../../admin-district-school-selector/AdminDistrictSchoolSelectorHook';
// import { useDomain } from '../../../../states/AppDomainProvider';

const Teachers = () => {
  const {
    tableConfig,
    teacherTableData,
    tableRef,
  } = useGetTeacherTable();

  const {
    getUsersAndLicensesData,
    isLoading,
  } = useGetUsersAndLicensesData();

  const {
    selectedTeacherIds,
    handleOnSelectedRowIds,
    updateTeacherRef,
    selectedTeachers,
  } = useHandleTeacherTable();

  const { adminTeachersDomain, adminDomain } = useAdmin();
  const { filterActive } = adminTeachersDomain.domainData;
  const { selectedSchoolId } = adminDomain.domainData;

  const reloadTable = () => {
    getUsersAndLicensesData();
  };

  const {
    selectedDistrict,
    selectedSchool,
  } = useGetDistrictAndSchool();

  const hasAddAccess = useCheckAddAccess();

  // const { userDomain } = useDomain();
  // const { userProfile } = userDomain.domainData;
  // const { b2cId } = userProfile;
  // const isB2cId = Boolean(b2cId);

  return (
    <div className='admin-tab-teachers full-height'>
      <SearchAndSortableTable
        ref={tableRef}
        containerClassName='teachers-table'
        preToolbarAction={[
          <AdminDistrictSchoolSelector
            key='admin-teachers-school-selector'
            onDataLoadSuccess={() => {
              if (tableRef.current) {
                tableRef.current.resetTableState();
              }
            }}
          />,
        ]}
        config={tableConfig}
        tableData={teacherTableData}
        initTableState={{
          order: 'desc',
          orderBy: tableConfig[0].dataField,
        }}
        searchPlaceholder='Filter by Teacher Name'
        searchFunction={(keyword, data) => {
          const name = `${data.firstName} ${data.lastName}`;
          const isNameMatched = name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          const isEmailMatched = data.userName.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          return isNameMatched || isEmailMatched;
        }}
        dataId='id'
        loading={isLoading}
        selectedRowIds={selectedTeacherIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        toolbarActions={[
          hasAddAccess ? (
            <AddTeacherAction key='admin-add-teacher-action' onTeacherAddSuccess={reloadTable} />
          ) : null,
          (!selectedTeacherIds || selectedTeacherIds.length <= 1
            ? <UpdateTeacherAction key='admin-update-teacher-action' ref={updateTeacherRef} onTeacherUpdateSuccess={reloadTable} />
            : (
              <MoveTeacherAction
                key='admin-edit-teachers-action'
                selectedTeachers={selectedTeachers}
                onMoveTeachersSuccess={reloadTable}
                initSelectedDistrict={selectedDistrict}
                initSelectedSchool={selectedSchool}
              />
            )
          ),
          (filterActive ? (
            <DeactivateTeacherAction
              key='admin-deactivate-action'
              onTeacherDeactivateSuccess={reloadTable}
            />
          ) : (
            <ReactivateTeacherAction
              key='admin-reactivate-action'
              onTeacherReactivateSuccess={reloadTable}
            />
          )),
          // isB2cId ? null : (
          //   <EmailPasswordResetAction
          //     key='admin-password-reset-action'
          //     onEmailPasswordResetSent={() => {
          //       adminTeachersDomain.updateSelectedTeacherIds([]);
          //     }}
          //   />
          // ),
          (!selectedTeacherIds || selectedTeacherIds.length <= 1
            ? (
              <TeacherSchoolRosterAccessAction
                key='admin-teacher-school-roster-action'
                onRosterAccessUpdateSuccess={reloadTable}
              />
            )
            : (
              <TeachersSchoolRosterAccessAction
                key='admin-teacher-school-roster-action'
                sonRosterAccessUpdateSuccess={reloadTable}
              />
            )
          ),
          <FilterTeacherAction key='admin-share-filter-action' />,
          hasAddAccess ? (
            <ImportUsersAction
              key='admin-import-teachers-action'
              buttonLabel='Import'
              id='admin-import-teachers-action'
              uploadServiceFunction={async (file) => teachersBatchUpload(selectedSchoolId, file)}
              ariaLabel='Import Teachers'
              uploadingMessage='Uploading Teachers'
              onUploadSuccess={reloadTable}
              disabled={!selectedSchoolId}
              additionalMessage='Teachers successfully added will be emailed a link to set password'
            />
          ) : null,
        ]}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Teachers;

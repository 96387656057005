import PropTypes from 'prop-types';
import { FormControl, TextField, Typography } from '@mui/material';
import DatePicker from 'react-multi-date-picker';
import { usePasswordValidator } from '../../../../../hooks/AdminUtilityHooks';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import DistrictSchoolSelector from '../../../../../components/actions/district-school-selector/DistrictSchoolSelector';
import {
  useStudentFormControl,
} from '../StudentFormHook';
import './UpdateStudentForm.scss';
import { dateFormatMMddyyyy } from '../../../../../../../AppConstants';
import DropdownInput from '../../../../../../../components/selector/DropdownInput';
import { gradeListOptions } from '../../../../../../../constants/GradeConstants';
import { ETHNIC_CONSTANTS_OPTIONS, GENDER_CONSTANTS_OPTIONS } from '../additional-student-fields/AdditionalFieldsConstant';
import TooltipWithIndicator from '../../../../../../../components/tooltip/TooltipWithIndicator';
import { stateIdentifierTooltipText } from '../../../../../../../texts/TooltipText';

export const specialEducationOptions = [
  {
    id: true,
    name: 'Yes',
  },
  {
    id: false,
    name: 'No',
  },
];

const UpdateStudentForm = ({ studentFormData, onDataChange }) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userAdmin,
    userSuperAdmin,
    schoologyFlag,
  } = user;

  const {
    internalData,
    confirmPassword,
    usernameError,
    emailError,
    handleOnChange,
    validateUsername,
    handleConfirmPassword,
    handleOnDistrictSchoolChange,
    handleOnEmailChange,
  } = useStudentFormControl({ initData: studentFormData, onDataChange });

  const {
    validatePassword,
    passwordError,
  } = usePasswordValidator();

  const { isClasslinkDistrict, isCleverDistrict } = internalData;
  const disableSettingData = isClasslinkDistrict || isCleverDistrict;

  return (
    <div className='admin-update-student-form'>
      <FormControl variant='outlined' className='form-container' autoComplete='off'>
        <div className='form-row flex-end'>
          <Typography className='form-label required'>
            First Name
          </Typography>
          <TextField
            name='firstName'
            value={internalData.firstName || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
            disabled={disableSettingData}
          />
        </div>
        <div className='form-row flex-end'>
          <Typography className='form-label required'>
            Last Name
          </Typography>
          <TextField
            name='lastName'
            value={internalData.lastName || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
            disabled={disableSettingData}
          />
        </div>
        <div className='form-row flex-end'>
          <div className='row'>
            <Typography className='form-label required'>
              State Identifier
            </Typography>
            <TooltipWithIndicator className='tooltip' {...stateIdentifierTooltipText} />
          </div>
          <TextField
            name='stateIdentifier'
            value={internalData.stateIdentifier || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
          />
        </div>
        {(schoologyFlag || userSuperAdmin || userAdmin) && (
          <div className='form-row flex-end'>
            <Typography className='form-label'>
              Schoology ID (optional)
            </Typography>
            <TextField
              name='schoologyId'
              value={internalData.schoologyId || ''}
              onChange={handleOnChange}
              inputProps={{
                maxLength: 255,
                'data-private': 'redact',
              }}
              variant='standard'
            />
          </div>
        )}
        <div className='form-row flex-end'>
          <Typography className='form-label required'>
            Username
          </Typography>
          <TextField
            name='userName'
            value={internalData.userName || ''}
            onChange={handleOnChange}
            onBlur={validateUsername}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            error={Boolean(usernameError)}
            helperText={usernameError}
            variant='standard'
            disabled={disableSettingData}
          />
        </div>
        {!disableSettingData && (
          <>
            <div className='form-row flex-end'>
              <Typography className='form-label'>
                Student Password
              </Typography>
              <TextField
                name='password'
                type='password'
                value={internalData.password || ''}
                onChange={handleOnChange}
                onBlur={() => {
                  validatePassword(internalData.password, confirmPassword);
                }}
                autoComplete='new-password'
                inputProps={{
                  maxLength: 255,
                  'data-private': 'redact',
                }}
                error={passwordError.password !== null}
                helperText={passwordError.password}
                variant='standard'
              />
            </div>
            <div className='form-row flex-end'>
              <Typography className='form-label'>
                Confirm Password
              </Typography>
              <TextField
                name='confirmPassword'
                type='password'
                value={confirmPassword || ''}
                onChange={handleConfirmPassword}
                onBlur={() => {
                  validatePassword(internalData.password, confirmPassword);
                }}
                inputProps={{
                  maxLength: 255,
                  'data-private': 'redact',
                }}
                error={passwordError.confirmPassword !== null}
                helperText={passwordError.confirmPassword}
                variant='standard'
              />
            </div>
          </>
        )}

        <div className='form-row flex-end'>
          <Typography className='form-label'>
            Add to Teacher (Username)
          </Typography>
          <TextField
            name='addToTeacher'
            value={internalData.addToTeacher || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
          />
        </div>

        <div className='form-row flex-end'>
          <Typography className='form-label'>
            Email Address
          </Typography>
          <TextField
            name='email'
            value={internalData.email || ''}
            onChange={handleOnEmailChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            error={emailError}
            helperText={emailError && 'Invalid email format'}
            variant='standard'
            disabled
          />
        </div>

        <div className='form-row flex-end date-field' data-test='date-of-birth-selector' data-private>
          <Typography className='form-label'>
            Date of Birth
          </Typography>
          <DatePicker
            format={dateFormatMMddyyyy.toLocaleUpperCase()}
            className='dob-picker'
            placeholder='Choose Date of Birth'
            onOpenPickNewDate={false}
            value={internalData.dob || ''}
            onChange={(dateValue) => {
              const value = dateValue.format(dateFormatMMddyyyy.toLocaleUpperCase());
              handleOnChange({
                target: {
                  name: 'dob',
                  value,
                },
              });
            }}
            disabled={disableSettingData}
          />
        </div>

        <div className='form-row dropdown-field'>
          <Typography className='form-label drodown-field'>
            Special Education
          </Typography>
          <DropdownInput
            name='specialEducation'
            placeholder='Special Education'
            dropdownValues={specialEducationOptions}
            containerClass='selector-dropdown-container'
            className='selector-dropdown'
            value={internalData.specialEducation || ''}
            onChange={handleOnChange}
            data-test='student-special-education-selector'
            inputProps={{
              'data-private': 'redact',
            }}
          />
        </div>
        <div className='form-row dropdown-field'>
          <Typography className='form-label drodown-field'>
            Grade
          </Typography>
          <DropdownInput
            name='grade'
            placeholder='Select Grade'
            dropdownValues={gradeListOptions}
            containerClass='selector-dropdown-container'
            className='selector-dropdown'
            value={internalData.grade || ''}
            onChange={handleOnChange}
            data-test='student-grade-selector'
            inputProps={{
              'data-private': 'redact',
            }}
            disabled={disableSettingData}
          />
        </div>

        <div className='form-row dropdown-field'>
          <Typography className='form-label drodown-field'>
            Gender
          </Typography>
          <DropdownInput
            name='gender'
            placeholder='Select Gender'
            dropdownValues={GENDER_CONSTANTS_OPTIONS}
            containerClass='selector-dropdown-container'
            className='selector-dropdown'
            value={internalData.gender || ''}
            onChange={handleOnChange}
            data-test='student-gender-selector'
            inputProps={{
              'data-private': 'redact',
            }}
            disabled={disableSettingData}
          />
        </div>

        <div className='form-row dropdown-field'>
          <Typography className='form-label'>
            Ethnicity
          </Typography>
          <DropdownInput
            name='ethnicity'
            placeholder='Select Ethnicity'
            dropdownValues={ETHNIC_CONSTANTS_OPTIONS}
            containerClass='selector-dropdown-container'
            className='selector-dropdown'
            value={internalData.ethnicity || ''}
            onChange={handleOnChange}
            data-test='ethnicity-gender-selector'
            inputProps={{
              'data-private': 'redact',
            }}
            disabled={disableSettingData}
          />
        </div>
        <br />
        <DistrictSchoolSelector
          onChange={handleOnDistrictSchoolChange}
          selectedDistrictId={internalData.district && internalData.district.key}
          selectedSchoolId={internalData.school && internalData.school.key}
        />
      </FormControl>
    </div>
  );
};

UpdateStudentForm.defaultProps = {
  onDataChange: () => null,
  studentFormData: {},
};

UpdateStudentForm.propTypes = {
  onDataChange: PropTypes.func,
  studentFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    externalId: PropTypes.string,
    schoologyId: PropTypes.string,
    userName: PropTypes.string,
    schoolId: PropTypes.number,
    isClasslinkDistrict: PropTypes.bool,
    isCleverDistrict: PropTypes.bool,
  }),
};
export default UpdateStudentForm;

import BaseDomain from './BaseDomain';

const STUDENTS_UPDATE_STUDENTS_LIST_ACTION = 'STUDENTS_UPDATE_STUDENTS_LIST_ACTION';
const STUDENTS_UPDATE_CURRENT_SELECTED_STUDENT_ACTION = 'STUDENTS_UPDATE_CURRENT_SELECTED_STUDENT_ACTION';

const reducer = (state, action) => {
  switch (action.type) {
    case STUDENTS_UPDATE_STUDENTS_LIST_ACTION:
      return {
        ...state,
        students: action.payload,
      };
    case STUDENTS_UPDATE_CURRENT_SELECTED_STUDENT_ACTION:
      return {
        ...state,
        currentSelectedStudentId: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  students: [],
  currentSelectedStudentId: null,
};

const domainName = 'studentsDomain';

/**
 * Domain class for students of the logged in teacher management domain
 *
 * @export
 * @class StudentsDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update student list information
   *
   * @param {*} student list
   * @returns
   */
  async updateStudentList(studentList = []) {
    return this.dispatch({
      type: STUDENTS_UPDATE_STUDENTS_LIST_ACTION,
      payload: studentList,
    });
  }

  /**
   * Update current selected student
   *
   * @param {*} student id
   * @returns
   */
  async updateCurrentSelectedStudentId(studentId) {
    return this.dispatch({
      type: STUDENTS_UPDATE_CURRENT_SELECTED_STUDENT_ACTION,
      payload: studentId,
    });
  }
}

import Box from '@mui/material/Box';
import './GettingStarted.scss';
import { isTexasTeacher } from '../../utils/User';
import { useDomain } from '../../states/AppDomainProvider';
import { useGoToAddStudent } from '../hooks/StudentHooks';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';

const gettingStartedVideoLinkNonTexas = 'https://assets.monarchteachtech.com/in-app-help-videos/Non-Texas/New%20Vizzle%20Excel%20Tour.mp4';
const gettingStartedVideoLinkTexas = 'https://assets.monarchteachtech.com/in-app-help-videos/Texas/New%20Vizzle%20Excel%20Tour.mp4';

const GettingStarted = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const videoLink = isTexasTeacher(user) ? gettingStartedVideoLinkTexas : gettingStartedVideoLinkNonTexas;
  const goToAddStudentMenu = useGoToAddStudent();

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div>
        <TeacherTopAppbar />
        <div className='getting-started'>
          <p>Need help getting started? Watch the quick video below</p>
          <video controls src={videoLink}>
            <track kind='captions' src={videoLink} />
          </video>
          <Box className='add-student-link' onClick={goToAddStudentMenu}>
            Click here to add students
          </Box>
        </div>
      </div>
    </VizzleMainContainer>
  );
};

export default GettingStarted;

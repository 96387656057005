/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Notifications as NotificationsActiveIcon,
  ExpandMore as ExpandMoreIcon,
  PriorityHigh as PriorityHighIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import UrlLink from '../../../components/urllink/UrlLink';
import { useGetStudents, useGoToAddStudent } from '../../hooks/StudentHooks';
import { useDomain } from '../../../states/AppDomainProvider';
import { sort } from '../../../utils/ArrayUtils';
import { URL_MY_STUDENT } from '../../../routes/RouteConstants';
import './StudentSelectorMenu.scss';
import { MYSTUDENT_ROUTES } from '../../mystudent/MyStudentConstants';
import LoopIcon from '../../../static/images/revision_review_icon.svg';

const getTitle = (students, currentSelectedStudentId) => {
  const currentStudent = students.find((s) => s.id === currentSelectedStudentId);
  const notificationUnread = students.findIndex((s) => s.masteryNotificationUnread) >= 0;
  const hasReviewNotificationUnread = students.findIndex((s) => s.reviewNotificationUnread) >= 0;
  const hasChatNotificationUnread = students.findIndex((s) => s.chatNotificationUnread) >= 0;

  return (
    <div className='student-selector-menu-title'>
      <div className={`title ${notificationUnread ? 'title-padding' : ''}`}>
        <div className='my-student-title'>
          {(hasReviewNotificationUnread || notificationUnread || hasChatNotificationUnread) && (
            <PriorityHighIcon className='review-notification' />
          )}
          Students
        </div>
        <ExpandMoreIcon className='expand-more' />
      </div>
      {window.location.pathname.startsWith(URL_MY_STUDENT) ? (
        <div className='selected-student' data-private>{currentStudent && `${currentStudent.firstName} ${currentStudent.lastName}`}</div>
      ) : null}
    </div>
  );
};

const StudentSelectorMenu = () => {
  useGetStudents();
  const goToAddStudentMenu = useGoToAddStudent();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;

  const handleOnStudentClick = (student) => {
    const paths = location.pathname && location.pathname.split('/');
    const isOnMyStudentPage = paths.findIndex((p) => p === URL_MY_STUDENT.replace('/', '')) >= 0;
    const url = isOnMyStudentPage ? `${URL_MY_STUDENT}/${paths[paths.length - 1]}` : URL_MY_STUDENT;
    navigate(
      url,
      {
        state: {
          student,
        },
      },
    );
    handleClose();
  };

  const handleOnAddStudentClick = () => {
    handleClose();
    goToAddStudentMenu();
  };

  return (
    <div className='student-selector-menu'>
      <UrlLink
        onClick={handleClickListItem}
        title={getTitle(students, currentSelectedStudentId)}
        url={URL_MY_STUDENT}
        data-test='my-students-dropdown'
        dataTest='My Students'
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {students.length > 0 ? sort(students, 'firstName').map((student) => (
          <MenuItem
            key={`student-selector-menu${student.id}`}
            onClick={() => handleOnStudentClick(student)}
            selected={student.id === currentSelectedStudentId}
            className='student-menu-item'
            data-test={`student-${student.id}`}
            data-private
          >
            {student.firstName}&nbsp;{student.lastName}

            <div className='notification-container'>
              {student.reviewNotificationUnread ? (
                <img
                  src={LoopIcon}
                  className='notification review-notification'
                  alt='Loop Icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `${URL_MY_STUDENT}/${MYSTUDENT_ROUTES.REVIEW_AND_FEEDBACK}`,
                      {
                        state: {
                          student,
                        },
                      },
                    );
                    handleClose();
                  }}
                />
              ) : null}
              {student.chatNotificationUnread ? (
                <ChatIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `${URL_MY_STUDENT}/${MYSTUDENT_ROUTES.REVIEW_AND_FEEDBACK}`,
                      {
                        state: {
                          student,
                        },
                      },
                    );
                    handleClose();
                  }}
                  className='notification chat-notification'
                />
              ) : null}
              {student.masteryNotificationUnread ? <NotificationsActiveIcon className='notification mastery-notification' /> : null}
            </div>
          </MenuItem>
        )) : (
          <MenuItem onClick={handleOnAddStudentClick}>
            <div className='add-remove-students'>
              <PersonAddIcon className='add-remove-students-icon' />
              <div className='add-remove-students-text'>Add/Remove Students</div>
            </div>
          </MenuItem>
        )}
      </Menu>

    </div>
  );
};

export default StudentSelectorMenu;

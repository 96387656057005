import { useState } from 'react';
import PropTypes from 'prop-types';
import OffButtonForm from './OffButtonForm';
import useOffButtonForm from './useOffButtonForm';
import ConfirmationDialog from '../../../../../components/dialog/ConfirmationDialog';

const SettingOffButtonForm = ({
  label,
  options,
  attributeNameToSave,
  confirmTitle,
  confirmContent,
  serviceFunction,
  ...rest
}) => {
  const { loading, value, handleOnClick } = useOffButtonForm(label, serviceFunction);
  const [open, setOpen] = useState(false);

  return (
    <>
      <OffButtonForm
        {...rest}
        label={label}
        options={options}
        loading={loading}
        value={value}
        handleOnClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <ConfirmationDialog
          open={open}
          title={confirmTitle}
          contentText={confirmContent}
          confirmLabel='Yes'
          cancelLabel='No'
          onConfirm={() => {
            setOpen(false);
            handleOnClick({
              [attributeNameToSave]: value,
            });
          }}
          onCancel={() => { setOpen(false); }}
        />
      )}
    </>
  );
};

SettingOffButtonForm.defaultProps = {
  options: [],
  confirmTitle: '',
  confirmContent: '',
  serviceFunction: () => {},
};

SettingOffButtonForm.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      name: PropTypes.string,
    }),
  ),
  attributeNameToSave: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  serviceFunction: PropTypes.func,
};

export default SettingOffButtonForm;

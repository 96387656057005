import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import {
  CARD_MODEL,
  MATCHING_QUIZ,
  FILL_IN_THE_BLANK,
} from '../../../../../../../../components/vizzle/page/game/GameConstants';
import CardPopupEditor from './CardPopupEditor';
import MatchingPopupEditor from './MatchingPopupEditor';
import ConfirmationDialog from '../../../../../../../../components/dialog/ConfirmationDialog';
import { useDomain } from '../../../../../../../../states/AppDomainProvider';
import FillInTheBlankDialogEditor from '../../fitb/FillInTheBlankDialogEditor';
import { getTextArray } from '../../../../../../../../components/vizzle/text/TextLinesUtils';

const LandingInteractionEditorDialog = ({
  open,
  onClose,
  title,
  landingInteractionType,
  selectedCardIndex,
  onDataSave,
}) => {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];
  const currentGameCard = selectedPage.gameCards[selectedCardIndex];
  const [data, setData] = useState({ ...currentGameCard.landingInteraction });
  const [isDirty, setIsDirty] = useState(false);
  const [closeConfirmDialog, setCloseConfirmDialog] = useState(false);
  const { user } = userDomain.domainData;
  const isTtsTextAvailable = user.userModerator || user.unitCreator;

  useEffect(() => {
    if (open) {
      setData(JSON.parse(JSON.stringify(currentGameCard.landingInteraction)));
      setIsDirty(false);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleOnDataChange = (newData) => {
    setIsDirty(true);
    setData(newData);
  };

  const getInteractionTypeEditorByType = () => {
    switch (landingInteractionType) {
      case CARD_MODEL:
        return <CardPopupEditor data={data} onDataChange={handleOnDataChange} />;
      case MATCHING_QUIZ:
        return <MatchingPopupEditor data={data} onDataChange={handleOnDataChange} />;
      case FILL_IN_THE_BLANK:
        return <FillInTheBlankDialogEditor data={data} onDataChange={handleOnDataChange} isTtsTextAvailable={isTtsTextAvailable} />;
      default:
        return <div>Unsupported</div>;
    }
  };

  const handleOnSaveClick = (e) => {
    if (landingInteractionType === MATCHING_QUIZ) {
      const answerCards = data.answerCards
        .filter((card) => !card.hide)
        .map((card) => {
          const { hide, ...rest } = card;
          return rest;
        });
      const newData = {
        ...data,
        answerCards,
      };
      onDataSave(e, newData);
    } else if (landingInteractionType === FILL_IN_THE_BLANK) {
      if (!data.question || !data.question.question) {
        return;
      }
      const newText = data.question.question;
      const textArray = getTextArray(newText);
      const equations = textArray.filter((t) => !t.isText).map((t) => t.text);
      // eslint-disable-next-line no-param-reassign
      if (data.ttsText) {
        Object.keys(data.ttsText).forEach((t) => {
          if (!t.isText && !equations.includes(t)) {
            // eslint-disable-next-line no-param-reassign
            delete data.ttsText[t];
          }
        });
      }
      onDataSave(e, data);
    } else {
      onDataSave(e, data);
    }
  };

  const handleOnCloseClicked = (e) => {
    e.stopPropagation();
    if (isDirty) {
      setCloseConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const closeConfirmationDialogProps = {
    open: closeConfirmDialog,
    title: 'Close editor',
    contentText: 'Do you want to save your changes?',
    confirmLabel: 'YES',
    cancelLabel: 'NO',
    onConfirm: () => {
      setCloseConfirmDialog(false);
      handleOnSaveClick();
    },
    onCancel: () => {
      setCloseConfirmDialog(false);
      onClose();
    },
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={`${landingInteractionType === FILL_IN_THE_BLANK ? 'md' : 'xl'}`}
        open={open}
        PaperProps={{ sx: { height: '100%', overflowX: 'hidden' } }}
        disableEscapeKeyDown
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ height: 'inherit' }}>
          {getInteractionTypeEditorByType()}
        </DialogContent>
        <DialogActions>
          <Button className='btn-primary' onClick={handleOnSaveClick}>
            Save
          </Button>
          <Button className='btn-danger' onClick={handleOnCloseClicked}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        {...closeConfirmationDialogProps}
      />
    </>
  );
};

LandingInteractionEditorDialog.defaultProps = {
  open: false,
  onClose: () => { },
  title: '',
  landingInteractionType: null,
  selectedCardIndex: null,
  onDataSave: () => { },
};

LandingInteractionEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  landingInteractionType: PropTypes.string,
  selectedCardIndex: PropTypes.number,
  onDataSave: PropTypes.func,
};

export default LandingInteractionEditorDialog;

import { useEffect, useRef, useState } from 'react';
import { TOOLBAR_ACTIONS_ID, TOOLBAR_NEXT_ACTION_ID } from '../../../../components/navigator/LessonNavigator';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

export const useInitializeData = () => {
  const questionCardRef = useRef(null);
  const promptTextRef = useRef(null);
  const subTextRef = useRef(null);
  const multipleChoicesResponseRefs = [];
  const setMultipleChoiceResponseRefs = (r, index) => {
    if (r) {
      multipleChoicesResponseRefs[index] = r;
    }
  };

  const submitAnswerActionRef = useRef(null);
  return {
    questionCardRef,
    promptTextRef,
    subTextRef,
    multipleChoicesResponseRefs,
    setMultipleChoiceResponseRefs,
    submitAnswerActionRef,
  };
};

export const useClickSubmitAnswer = ({
  onTriggerSwitchScan,
}) => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleOnResponseCardClick = (value) => {
    LessonPlayActivityUtils.stopMedias();
    setSelectedValue(value);
    if (selectedValue === '' && value >= 0) {
      onTriggerSwitchScan({ continueIndex: true });
    }
  };

  return {
    selectedValue,
    handleOnResponseCardClick,
  };
};

export const useHandleSubmitButton = ({
  questionCard,
  answerCards,
  selectedValue,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  rejectedAnswerPromptRefs,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [incorrectedAnswers, setIncorrectedAnswers] = useState([]);
  const showDropRejection = useShowDropRejection();

  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;

  const handleOnSubmitButtonClick = async () => {
    LessonPlayActivityUtils.stopMedias();
    const answerCard = answerCards[selectedValue];
    const selectedValueNum = Number(selectedValue);

    if (singleAnswer) {
      setIsSubmitted(true);
      await ObjectUtils.delay(500);
      if (answerCard.answerKey === questionCard.questionKey) {
        await onCorrectAnswerSelected({ insertResponse: true, responseIndex: selectedValueNum, once: true, isFinished: true, showReinforcer: true });
      } else {
        await showDropRejection(rejectedAnswerPromptRefs[selectedValueNum]);
        setIncorrectedAnswers((previousState) => [...previousState, selectedValueNum]);
        onIncorrectAnswerSelected(selectedValueNum, true, true);
      }
      onActivityFinished({ toNextPage: true });
      return;
    }
    if (answerCard.answerKey === questionCard.questionKey) {
      setIsSubmitted(true);
      await ObjectUtils.delay(500);
      await onCorrectAnswerSelected({ insertResponse: true, responseIndex: selectedValueNum, once: true, isFinished: true, showReinforcer: true });
      onActivityFinished({ toNextPage: true });
    } else {
      await showDropRejection(rejectedAnswerPromptRefs[selectedValueNum]);
      setIncorrectedAnswers((previousState) => [...previousState, selectedValueNum]);
      onIncorrectAnswerSelected(selectedValueNum, true, true);
    }
  };

  return {
    isSubmitted,
    incorrectedAnswers,
    handleOnSubmitButtonClick,
    setIsSubmitted,
    setIncorrectedAnswers,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  answerCards,
  onActivityFinished,
  pageIndex,
  setShowSubmitValueButton,
  setIsSubmitted,
  setIncorrectedAnswers,
  handleOnResponseCardClick,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;

  const getIncorrectAnswers = () => {
    const incorrectAnswers = [];
    answerCards.forEach((r, index) => {
      const result = r.correctSubmission !== null && r.correctSubmission === false;
      if (result) {
        incorrectAnswers.push(index);
      }
    });
    return incorrectAnswers;
  };

  useEffect(() => {
    if (pageIndex < 0) {
      return;
    }

    if (singleAnswer) {
      const incorrectAnswers = getIncorrectAnswers();
      setIncorrectedAnswers(incorrectAnswers);

      const result = answerCards.findIndex((r) => (r.correctSubmission !== null && r.correctSubmission === true));
      if (result >= 0) {
        handleOnResponseCardClick(result);
      }
      if (incorrectAnswers.length > 0 || result >= 0) {
        setShowSubmitValueButton(false);
        setIsSubmitted(true);
        onActivityFinished({ toNextPage: false, pageIndex });
      }
      return;
    }

    const incorrectAnswers = getIncorrectAnswers();
    setIncorrectedAnswers(incorrectAnswers);

    const result = answerCards.findIndex((r) => (r.correctSubmission !== null && r.correctSubmission === true));
    if (result >= 0) {
      handleOnResponseCardClick(result);
      setShowSubmitValueButton(false);
      setIsSubmitted(true);
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useShowSubmitValueButton = ({
  isCurrentPage,
  isSubmitted,
}) => {
  const [showSubmitValueButton, setShowSubmitValueButton] = useState(false);

  const { lessonPlayDomain } = useLessonPlay();
  const { currentPageIndex } = lessonPlayDomain.domainData;

  useEffect(() => {
    const nextAction = document.getElementById(TOOLBAR_NEXT_ACTION_ID);
    const toolbarAction = document.getElementById(TOOLBAR_ACTIONS_ID);
    if (isCurrentPage && toolbarAction) {
      if (isSubmitted) {
        ObjectUtils.setTimeout(() => {
          if (nextAction) {
            nextAction.classList.remove('hide');
            nextAction.classList.add('show');
          }
          if (toolbarAction) {
            toolbarAction.classList.add('hide');
          }
          setShowSubmitValueButton(false);
        });
      } else {
        ObjectUtils.setTimeout(() => {
          if (nextAction) {
            nextAction.classList.add('hide');
            nextAction.classList.remove('show');
          }
          if (toolbarAction) {
            toolbarAction.classList.remove('hide');
          }
          setShowSubmitValueButton(true);
        });
      }
    } else {
      if (nextAction) {
        nextAction.classList.remove('hide');
        nextAction.classList.add('show');
      }
      if (toolbarAction) {
        toolbarAction.classList.add('hide');
      }
      setShowSubmitValueButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, isSubmitted]);

  return {
    showSubmitValueButton,
    setShowSubmitValueButton,
  };
};

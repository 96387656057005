import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  DEFAULT_NUMBER_OF_ANSWER_CARDS,
  MAX_ANSWER_CARDS,
  DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS,
  MAX_SETS_OF_CAUSES_AND_EFFECTS,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../AppConstants';
import { layoutDropdownValues } from '../../components/vizzle/page/flexible-matching/FlexibleMatchingConstants';

import {
  SPINNER_BOARD_PRIMARY_COLORS,
} from '../../components/vizzle/page/game/GameConstants';

/**
 * Get initial data for book
 */
export const getInitialDataForBook = () => (
  { activityType: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA, cards: [{}], images: [''] }
);

const getInitialDataForBookFacing = () => (
  { activityType: ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA, cards: [{}, {}], images: ['', ''] }
);

/**
 * Get initial data for matching
 */
export const getInitialDataForMatching = () => {
  const responseCards = [];
  for (let i = 0; i < MAX_ANSWER_CARDS; i++) {
    responseCards.push({
      hide: i >= DEFAULT_NUMBER_OF_ANSWER_CARDS,
    });
  }
  return {
    activityType: ACTIVITY_TYPE_MATCHING_ACTIVITY,
    promptCards: [{}],
    responseCards,
    cards: null,
    thumbnailImages: [],
    images: [],

  };
};

export const KEY_NA = 'N/A';

export const availableSortingKeys = [
  KEY_NA,
  'A',
  'B',
];

const createSortingResponse = (startNum, endNum) => {
  const result = [];
  for (let i = startNum; i <= endNum; i++) {
    result.push({ sequenceNum: i, key: availableSortingKeys[0] });
  }
  return result;
};

export const getInitialDataForSorting = () => (
  {
    activityType: ACTIVITY_TYPE_SORTING_ACTIVITY,
    categories: [
      {
        promptCards: [{ key: availableSortingKeys[1] }],
        responseCards: createSortingResponse(1, 4),
      },
      {
        promptCards: [{ key: availableSortingKeys[2] }],
        responseCards: createSortingResponse(5, 8),
      },
    ],
    instructions: {
      text: {
        defaultText: true,
        lines: [
          {
            color: '#717171',
            fontFamily: 'Quattrocento',
            fontSize: null,
            textAlign: 'center',
            elements: [
              {
                color: '#717171',
                fontFamily: 'Quattrocento',
                fontStyle: 'normal',
                fontWeight: 'normal',
                text: 'Sort into the correct categories',
              },
            ],
          },
        ],
      },
    },
    images: [],
    cards: null,
    thumbnailImages: [],
  }
);

export const getDefaultText = (initText) => ({
  lines: [
    {
      color: '#000000',
      fontFamily: 'Quattrocento',
      fontSize: null,
      textAlign: 'center',
      elements: [
        {
          color: '#000000',
          fontFamily: 'Quattrocento',
          fontStyle: 'normal',
          fontWeight: 'normal',
          text: initText,
        },
      ],
    },
  ],
});

export const getBorderColorByCardIndex = (cardIndex) => {
  const mod = cardIndex % 4;
  return SPINNER_BOARD_PRIMARY_COLORS[mod];
};

export const getInitialGameCard = (index) => ({
  text: null,
  image: null,
  video: null,
  audio: null,
  gameSpaceType: 1,
  landingInteraction: null,
  borderColor: getBorderColorByCardIndex(index),
});

export const getInitialDataForGame = (numberOfCards = 20) => {
  const gameCards = [];

  gameCards.push({
    text: getDefaultText('Start'),
    image: null,
    gameSpaceType: 1,
    borderColor: getBorderColorByCardIndex(0),
  });
  for (let i = 0; i < numberOfCards - 2; i++) {
    gameCards.push(getInitialGameCard(i + 1));
  }

  gameCards.push({
    text: getDefaultText('Finish'),
    image: null,
    gameSpaceType: 1,
    borderColor: getBorderColorByCardIndex(numberOfCards - 1),
  });

  return {
    activityType: ACTIVITY_TYPE_GAME_ACTIVITY,
    gameCards,
    layout: 1,
    spinner: 1,
    moveToCardIndicator: true,
    landingAudio: null,
  };
};

export const SEQUENCING_ACTIVITY_KEYS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export const getInitialDataForSequencing = () => (
  {
    activityType: ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
    sequenceCards: SEQUENCING_ACTIVITY_KEYS.map((key, index) => ({
      key,
      sequenceNum: index,
      text: null,
      image: null,
      video: null,
      audio: null,
    })),
    responseCards: SEQUENCING_ACTIVITY_KEYS.map((key, index) => ({
      answerKey: KEY_NA,
      sequenceNum: index,
      text: null,
      image: null,
      video: null,
      audio: null,
    })),
    instructions: {
      text: {
        defaultText: true,
        lines: [
          {
            color: '#717171',
            fontFamily: 'Quattrocento',
            fontSize: null,
            textAlign: 'center',
            elements: [
              {
                color: '#717171',
                fontFamily: 'Quattrocento',
                fontStyle: 'normal',
                fontWeight: 'normal',
                text: 'Put the cards in the correct order',
              },
            ],
          },
        ],
      },
    },
    images: [],
    cards: null,
    thumbnailImages: [],
  }
);

const getCuaseEffectCardData = (numberOfSets = DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS) => {
  const data = {
    causeCards: [],
    effectCards: [],
  };
  for (let i = 0; i < MAX_SETS_OF_CAUSES_AND_EFFECTS; i++) {
    const causeCard = {
      key: i,
      hide: i >= numberOfSets,
    };
    data.causeCards.push(causeCard);
    const effectCard = {
      key: i,
      hide: i >= numberOfSets,
    };
    data.effectCards.push(effectCard);
  }
  return data;
};

export const getInitialDataForCauseAndEffect = () => {
  const causeAndEffectData = getCuaseEffectCardData(DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS);
  return {
    activityType: ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
    causeCards: causeAndEffectData.causeCards,
    effectCards: causeAndEffectData.effectCards,
    cards: null,
    images: [],
  };
};

export const getInitialDataForFillInTheBlankData = () => ({
  card: {
    audio: null,
    image: null,
    video: null,
  },
  fitbList: [],
});

export const getInitialDataForFillInTheBlankSingle = () => (
  {
    activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
    cards: [
      {
        card: {
          audio: null,
          image: null,
          video: null,
        },
        fitbList: [],
      },
    ],
  }
);

export const getInitialDataForFillInTheBlankFacing = () => (
  {
    activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
    cards: [
      {
        card: {
          audio: null,
          image: null,
          video: null,
        },
        fitbList: [],
      },
      {
        card: {
          audio: null,
          image: null,
          video: null,
        },
        fitbList: [],
      },
    ],
  }
);
export const KEY_WRONG = 'Wrong';
export const availableCategorizingKeys = [
  KEY_WRONG,
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
];

export const createCategorizingResponse = (startNum, endNum) => {
  const result = [];
  for (let i = startNum; i <= endNum; i++) {
    result.push({ sequenceNum: i, key: availableCategorizingKeys[0] });
  }
  return result;
};

export const getCategorizing = (numberOfCategories = 1) => {
  const promptCards = [];
  let responseCards = [];

  for (let i = 0; i < numberOfCategories; i++) {
    promptCards.push({ key: availableCategorizingKeys[i + 1] });
    responseCards = createCategorizingResponse(i + 1, i + 2);
  }
  return {
    promptCards,
    responseCards,
  };
};

export const getInitialDataForCategorizing = (numberOfCategories = 1) => {
  const { promptCards, responseCards } = getCategorizing(numberOfCategories);
  return (
    {
      activityType: ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
      promptCards,
      responseCards,
      instructions: {
        image: '',
        text: null,
      },
    }
  );
};

export const defaultSubmitText = {
  lines: [
    {
      color: '#717171',
      fontFamily: 'Quattrocento',
      fontSize: null,
      textAlign: 'center',
      elements: [
        {
          color: '#717171',
          fontFamily: 'Quattrocento',
          fontStyle: 'normal',
          fontWeight: 'normal',
          text: 'Submit your answer here',
        },
      ],
    },
  ],
};

export const defaultFfrSubmitText = {
  lines: [
    {
      color: '#000000',
      fontFamily: 'Quattrocento',
      fontSize: null,
      textAlign: 'center',
      elements: [
        {
          color: '#000000',
          fontFamily: 'Quattrocento',
          fontStyle: 'normal',
          fontWeight: 'normal',
          text: 'Submit your answer here',
        },
      ],
    },
  ],
};

const getInitialDataForFlexiMatching = (layout = layoutDropdownValues[layoutDropdownValues.length - 1].id, numberOfPrompts = 4, numberOfResponses = 4) => {
  const promptCards = [];
  for (let i = 1; i <= numberOfPrompts; i++) {
    promptCards.push({ sequenceNum: i });
  }

  const responseCards = [];
  for (let i = 1; i <= numberOfResponses; i++) {
    responseCards.push({ sequenceNum: i });
  }

  return {
    activityType: ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
    promptCards,
    responseCards,
    layout,
    promptText: null,
    subText: null,
    submitAnswerText: {
      ...defaultSubmitText,
    },
  };
};

/**
 * Get initial data for multiple choices
 */
const getInitialDataForMultipleChoice = () => {
  const responseCards = [];
  for (let i = 0; i < MAX_ANSWER_CARDS; i++) {
    responseCards.push({
      hide: i >= DEFAULT_NUMBER_OF_ANSWER_CARDS,
    });
  }
  return {
    activityType: ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
    questionCard: {},
    answerCards: responseCards,
    singlePage: false,
  };
};

const getInitialDataForMultipleChoiceV2 = (numberOfResponses = 4) => {
  const answerCards = [];
  for (let i = 1; i <= numberOfResponses; i++) {
    answerCards.push({ sequenceNum: i, cardIndex: i });
  }

  return {
    activityType: ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
    questionCard: {},
    answerCards,
    promptText: null,
    subText: null,
    randomize: true,
    singlePage: true,
  };
};

/**
 * Get initial data by activity type
 * @param {*} activityType activity type
 * @return initial data
 */
export const getInitialDataByAcivityType = (activityType) => {
  switch (activityType) {
    case ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA:
      return getInitialDataForBook();
    case ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA:
      return getInitialDataForBookFacing();
    case ACTIVITY_TYPE_MATCHING_ACTIVITY:
      return getInitialDataForMatching();
    case ACTIVITY_TYPE_SORTING_ACTIVITY:
      return getInitialDataForSorting();
    case ACTIVITY_TYPE_GAME_ACTIVITY:
      return getInitialDataForGame();
    case ACTIVITY_TYPE_SEQUENCING_ACTIVITY:
      return getInitialDataForSequencing();
    case ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY:
      return getInitialDataForCauseAndEffect();
    case ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE:
      return getInitialDataForFillInTheBlankSingle();
    case ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING:
      return getInitialDataForFillInTheBlankFacing();
    case ACTIVITY_TYPE_CATEGORIZING_ACTIVITY:
      return getInitialDataForCategorizing();
    case ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY:
      return getInitialDataForFlexiMatching();
    case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY:
      return getInitialDataForMultipleChoice();
    case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2:
      return getInitialDataForMultipleChoiceV2();
    default:
      return getInitialDataForBook();
  }
};

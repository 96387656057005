import PropTypes from 'prop-types';
import './LessonLabel.scss';

/**
 * Play Lesson Action Container
 */
export default function LessonLabel({ label, className }) {
  return (
    <div className={`leson-label ${className}`}>
      {label}
    </div>
  );
}

LessonLabel.defaultProps = {
  className: '',
};

LessonLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

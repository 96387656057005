import { useRef } from 'react';
import { addDistrict, districtsExpirationLicense, getSaleForceInfo, updateDistrict } from '../../../../../services/AdminService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../../context/AdminContextProvider';
import { useGetDistrictData } from '../DistrictsHooks';

export const useGetAddDistrictHandler = ({
  setOpen,
}) => {
  const { uiDomain } = useDomain();

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const {
    getDistrictsData,
  } = useGetDistrictData();

  const addNewDistrict = async (districtData) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await addDistrict(districtData);
        await getDistrictsData(false);
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, `District ${districtData.name} is added.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    addNewDistrict,
  };
};

export const useGetUpdateDistrictHandler = ({
  setOpen,
  saveAdditionalDataFunction,
}) => {
  const { districtsDomain } = useAdmin();
  const { districts, selectedDistrictIds } = districtsDomain.domainData;

  const { uiDomain } = useDomain();

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const updateExistingDistrict = async (districtData) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const {
          id,
          name,
          state,
          accountManager,
          schoologyFlag,
          accountType,
          churnzeroId,
          paidAccount,
          active,
          createStudent,
          leadTeacher,
        } = districtData;
        await updateDistrict({
          id,
          name,
          state,
          accountManager,
          schoologyFlag,
          accountType,
          churnzeroId,
          paidAccount,
          active,
          createStudent,
          leadTeacher,
        });
        if (saveAdditionalDataFunction) {
          await saveAdditionalDataFunction();
        }
        const oldDataIndex = districts.findIndex((d) => d.id === districtData.id);
        const updatedDistricts = [
          ...districts,
        ];
        updatedDistricts[oldDataIndex] = { ...districtData };
        await districtsDomain.updateDistricts(updatedDistricts);
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, `District ${districtData.name} is updated.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  let selectedDistrictObject = {};
  if (selectedDistrictIds && selectedDistrictIds.length === 1) {
    const id = selectedDistrictIds[0];
    selectedDistrictObject = districts.find((d) => d.id === id);
  }
  return {
    loading,
    updateExistingDistrict,
    selectedDistrictIds,
    selectedDistrictObject,
  };
};

export const useSetLicenseExpirationHandler = ({
  // eslint-disable-next-line no-unused-vars
  setOpen,
}) => {
  const { districtsDomain } = useAdmin();
  const { districts, selectedDistrictIds } = districtsDomain.domainData;

  const { uiDomain } = useDomain();

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const setDistrictsExpiration = async (expirationDate) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await districtsExpirationLicense(selectedDistrictIds, expirationDate);
        setOpen(false);

        const updateSuccessMessage = selectedDistrictIds.length === 1
          ? `License expiration date for ${districts.find((d) => d.id === selectedDistrictIds[0]).name} is updated to ${expirationDate}.`
          : `License expiration date for ${selectedDistrictIds.length} Districts are update to ${expirationDate}.`;

        await uiDomain.showSnackbar(
          true,
          'info',
          3000,
          updateSuccessMessage,
        );
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    selectedDistrictIds,
    loading,
    setDistrictsExpiration,
  };
};

export const useGetSaleForceInfo = () => {
  const saleForceData = useRef(null);
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const getSaleForceInfoData = async (selectedDistrictId) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getSaleForceInfo(selectedDistrictId);
        saleForceData.current = result;
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saleForceInfoLoading: loading,
    saleForceData,
    getSaleForceInfoData,
  };
};

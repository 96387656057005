import { useState, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import TextLines from '../../../../components/vizzle/text/TextLines';
import './Hotspot.scss';
// eslint-disable-next-line import/no-cycle
import PlayActivityInteractionPopup from '../play-interaction/PlayActivityInteractionPopup';
import { useTtsPlay } from '../../utils/UseTtsPlay';
import TextToSpeechService from '../../../../services/tts/TextToSpeechService';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useRegisterSwitchAccessScanEvent, useRegisterSwitchAccessSelectEvent } from '../../../switch-access/SwitchAccessHooks';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

const SHAPE_CIRCLE = 'circle';
const SHAPE_TRIANGLE = 'triangle';
const SHAPE_SQUARE = 'square';

const getSizeByType = {
  [SHAPE_CIRCLE]: (_width, _height) => {
    let factor = 1.36;

    if (_width >= 0 && _width < 30) {
      factor = 3.5;
    } else if (_width > 30 && _width < 50) {
      factor = 2.9;
    } else if ((_width > 53 && _width < 70) || _width === 74) {
      factor = 2.9;
    }
    return {
      width: _width * factor,
      height: _height * factor,
    };
  },
  [SHAPE_TRIANGLE]: (_width, _height) => {
    const width = Math.min(_width, _height) * 1.20;
    const height = Math.min(_width, _height) * 1.55;
    return {
      width,
      height,
    };
  },
  [SHAPE_SQUARE]: (_width, _height) => {
    const width = _width * 1.3;
    const height = _height * 1.3;
    return {
      width,
      height,
    };
  },
};

const toRGBA = (_hexColor, _alpha) => {
  let alpha = typeof _alpha !== 'undefined' ? _alpha : 1.0;
  alpha = _alpha < 1 ? _alpha : 1.0;

  const hex = _hexColor.replace('0x', '').replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${alpha})`;
};

const Hotspot = forwardRef(({
  hotspot,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onInteractionOpen,
  hotspotCardIndex,
  isPlayable,
  userProfile,
}, ref) => {
  const {
    shape,
    width,
    height,
    xCoordinate,
    yCoordinate,
    borderWeight,
    borderColor,
    bgColor,
    bgAlpha,
    text,
    centered,
    // image,
    // type,
  } = hotspot;

  const size = getSizeByType[shape || SHAPE_CIRCLE](width, height);

  let position = {
    top: `${yCoordinate}%`,
    left: `${xCoordinate}%`,
  };
  if (centered) {
    position = {
      top: `calc(${yCoordinate}% - ${size.height / 2}px)`,
      left: `calc(${xCoordinate}% - ${size.width / 2}px)`,
    };
  }

  const border = {
    border: `${borderWeight}px solid ${borderColor}`,
  };

  const background = {
    backgroundColor: toRGBA(bgColor, bgAlpha),
  };

  const [open, setOpen] = useState(false);

  const componentId = useMemo(() => uuidv1(), []);

  const componentRef = useRef(null);

  const playActivityInteractionPopupRef = useRef(null);

  const textToSpeechService = new TextToSpeechService(userProfile.tts.ttsMode);

  const { playTextToSpeech, cancelTts, isTtsPlaying } = textToSpeechService.getTtsService();

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      if (open) {
        return {
          interaction: true,
          interactionRef: playActivityInteractionPopupRef.current,
        };
      }
      return componentRef.current;
    },
  }));

  const {
    setTextLineRef,
    elementToPlayTts,
    ttsConfig,
  } = useTtsPlay();

  const playTts = async () => {
    try {
      await playTextToSpeech(elementToPlayTts, ttsConfig);
    } catch (e) {
      // DO nothing
    }
  };

  useRegisterSwitchAccessSelectEvent(componentRef, () => {
    LessonPlayActivityUtils.stopMedias();
    setOpen(true);
  });

  useRegisterSwitchAccessScanEvent(componentRef, ({ detail: { triggeredElement } }) => {
    if (triggeredElement === componentRef.current) {
      playTts();
    }
  });

  return (
    <>
      <Box
        data-skip-switch-access-click='true'
        data-switch-access-scan='true'
        data-test={`hotspot-container-${hotspotCardIndex}`}
        className={`hotspot ${shape} ${isPlayable ? '' : 'not-playable'}`}
        style={{
          ...size,
          ...position,
          ...border,
          ...background,
        }}
        onClick={async (event) => {
          event.target.classList.add('no-opacity');
          if (isTtsPlaying()) {
            const isElementPlaying = event.target.parentElement.classList.contains('tts-playing');
            cancelTts();
            if (isElementPlaying) {
              return;
            }
          }
          await playTts();
          setOpen(true);
        }}
        ref={componentRef}
      >
        {shape === SHAPE_TRIANGLE && (
          <svg height={`${size.height + borderWeight}px`}>
            <path
              d={`M 0,${size.width - borderWeight} L ${(size.width - borderWeight) / 2},10 L ${size.width - borderWeight},${size.width - borderWeight} z`}
              fill={background.backgroundColor}
              stroke={borderColor}
              strokeWidth={borderWeight}
            />
          </svg>
        )}
        {text && (
          <TextLines textLines={text} componentKey={`hotspot-text-line-${componentId}`} ref={setTextLineRef} />
        )}
        <Box
          onClick={async (event) => {
            event.stopPropagation();
            if (isTtsPlaying()) {
              cancelTts();
              await ObjectUtils.delay(700);
            }

            try {
              await playTextToSpeech(elementToPlayTts, ttsConfig);
            } catch (_error) {
              // ignore
            }
          }}
        />
      </Box>
      <PlayActivityInteractionPopup
        open={open}
        onClose={() => {
          const elements = document.querySelector('no-opacity');
          if (elements && elements.length > 0) {
            elements.forEach((element) => {
              element.classList.remove('no-opacity');
            });
          }
          setOpen(false);
          onInteractionOpen(false);
        }}
        landingInteraction={hotspot}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        ref={playActivityInteractionPopupRef}
        onEntered={() => { onInteractionOpen(true); }}
      />
    </>
  );
});

Hotspot.defaultProps = {
  hotspot: null,
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onInteractionOpen: () => { },
  hotspotCardIndex: 0,
  isPlayable: true,
  userProfile: { tts: {} },
};

Hotspot.propTypes = {
  hotspot: PropTypes.shape({
    type: PropTypes.string,
    shape: PropTypes.string,
    bgColor: PropTypes.string,
    bgAlpha: PropTypes.number,
    borderWeight: PropTypes.number,
    borderColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    xCoordinate: PropTypes.number,
    yCoordinate: PropTypes.number,
    text: PropTypes.object,
    image: PropTypes.number,
    centered: PropTypes.bool,
    questionCard: PropTypes.object,
    answerCards: PropTypes.array,
    question: PropTypes.shape({
      question: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
  }),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onInteractionOpen: PropTypes.func,
  hotspotCardIndex: PropTypes.number,
  isPlayable: PropTypes.bool,
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tts: PropTypes.object,
  }),
};

export default Hotspot;

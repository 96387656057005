import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../components/loaders/LoadingSpinner';
import NoLessonPaper from '../../../../components/paper/NoLessonPaper';
import { getAdminReportUrl } from '../../../../services/AdminService';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import './AdminReport.scss';

const AdminReport = () => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  // const [url, setUrl] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      callRemoteServiceWrapper(async () => {
        try {
          const result = await getAdminReportUrl();
          // setUrl(result);
          window.location.replace(result);
        } catch (e) {
          Logger.logError(e);
          setError(true);
        }
      });
    } catch (e) {
      Logger.logError(e);
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className='admin-report loading-container' data-test='loading-container'>
        <LoadingSpinner message='Loading...' />
      </div>
    );
  }

  if (error) {
    return (
      <NoLessonPaper
        className='admin-report error-message'
        message={(
          <>
            <div>
              Sorry, we are unable to process your request at the moment.
            </div>
            <div>
              Please try again later.
            </div>
          </>
        )}
      />
    );
  }

  // return (
  //   <div className='admin-report'>
  //     <iframe title='Report' src={url} />
  //   </div>
  // );
  return null;
};

export default AdminReport;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MicRecorder from 'mic-recorder-to-mp3';
import MicOffIcon from '@mui/icons-material/MicOff';
import NotificationDialog from '../../../../../../components/dialog/NotificationDialog';
import AudioRecorder from '../../../../../../components/vizzle/media/AudioRecorder';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { UNAUTHORIZED } from '../../../../../../AppConstants';
import { uploadMedia, updateUserAssets } from '../../../../../../services/MediaService';
import Logger from '../../../../../../utils/Logger';
import ObjectUtils from '../../../../../../utils/ObjectUtils';

/**
 * Audio length limit in minutes
 */
const AUDIO_LIMIT_MINUTE = 5;

const initialState = {
  isRecording: false,
  recordedAudioUrl: null,
  audioFile: null,
  isSaving: false,
  timer: null,
};

// Fix for Safari that need to get the Audio context from webkitAudioContext
window.AudioContext = window.AudioContext || window.webkitAudioContext;

/**
 * Audio recorder container that containt the business logic
 */
export default function AudioRecorderContainer({ open, onClose, onApply }) {
  const [state, setState] = useState(initialState);
  const [recorder, setRecorder] = useState(initialState);
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  const [notification, setNotification] = useState({
    open: false,
    message: '',
  });

  const createRecorder = () => {
    const recorderInstance = new MicRecorder({
      bitRate: 128,
    });
    setRecorder(recorderInstance);
  };

  const checkMicrophonePermission = async () => {
    if (navigator.permissions) {
      const permissionStatus = await navigator.permissions.query(
        { name: 'microphone' },
      );
      if (permissionStatus.state === 'denied') {
        setNotification({
          open: true,
          message: 'Please allow your browser to record audio. You can do this from the browser settings',
        });
      } else {
        createRecorder();
      }
    } else {
      createRecorder();
    }
  };

  useEffect(() => {
    // New instance
    if (open) {
      if (!navigator.mediaDevices) {
        setNotification({
          open: true,
          message: 'Sorry, your browser does not support this feature.',
        });
        return;
      }
      checkMicrophonePermission();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => (
    () => {
      if (state.timer) {
        clearTimeout(state.timer);
      }
    }
    // eslint-disable-next-line
  ), []);

  const stopRecording = (async () => {
    const [buffer, blob] = await recorder.stop().getMp3();
    const currentDate = new Date();
    const formatDate = `${currentDate.getUTCFullYear()}-${currentDate.getUTCMonth()}-${currentDate.getUTCDate()}`;
    const formatTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    const file = new File(buffer, `${user.userName}-${formatDate}-${formatTime}.mp3`, {
      type: blob.type,
      lastModified: Date.now(),
    });

    if (state.timer) {
      clearTimeout(state.timer);
    }
    const recordedAudioUrl = URL.createObjectURL(file);
    setState({
      ...state,
      isRecording: false,
      recordedAudioUrl,
      audioFile: file,
    });
  });

  const startRecording = (async () => {
    try {
      await recorder.start();

      /** Limit audio record to AUDIO_LIMIT_MINUTE minutes */
      const timer = setTimeout(async () => {
        Logger.logWhenDebugModeIsOn(`Limit audio record to ${AUDIO_LIMIT_MINUTE} minutes.`);
        await stopRecording();
        clearTimeout(timer);
      }, (AUDIO_LIMIT_MINUTE * 60 * 1000) + 1000);

      setState({
        ...state,
        isRecording: true,
        timer,
      });
    } catch (e) {
      Logger.logError(e);
      if (e.name === 'NotAllowedError') {
        setNotification({
          open: true,
          message: 'Please allow your browser to record audio. You can do this from the browser settings',
        });
      }
    }
  });

  const handleOnClose = (() => {
    setState(initialState);
    onClose();
  });

  const handleOnApply = async () => {
    // 1. Set the state of the dialog box to saving state to block the user interactions
    // and display spinning icon on Apply button
    setState({
      ...state,
      isSaving: true,
    });
    try {
      // 2. Perform save function
      const audioPromise = await uploadMedia(state.audioFile, true);

      const { attributes: { fileName, s3Url } } = audioPromise;
      updateUserAssets({ file: state.audioFile, fileName, description: fileName });
      await onApply(s3Url);
      setState({
        ...state,
        isSaving: false,
      });
      // 3. Wait a bit before closing the dialog box after the process has finished without any errors.
      ObjectUtils.setTimeout(() => {
        handleOnClose();
      }, 1500);
    } catch (e) {
      if (e.response && UNAUTHORIZED !== e.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
      }
      Logger.logError({
        ERROR: e,
      });
      setState({
        ...state,
        isSaving: false,
      });
    }
  };

  const onNotificationClose = () => {
    setNotification({
      open: false,
    });
    handleOnClose();
  };

  if (open) {
    if (notification.open) {
      return (
        <NotificationDialog
          open={notification.open}
          onClose={onNotificationClose}
          Icon={MicOffIcon}
          title='Audio Recorder'
          confirmLabel='Close'
          message={notification.message}
        />
      );
    } else {
      return (
        <AudioRecorder
          open={open}
          onClose={handleOnClose}
          isRecording={state.isRecording}
          startRecording={startRecording}
          stopRecording={stopRecording}
          recordedAudioUrl={state.recordedAudioUrl}
          isSaving={state.isSaving}
          onApply={handleOnApply}
          limitInMinute={AUDIO_LIMIT_MINUTE}
        />
      );
    }
  } else {
    return '';
  }
}
AudioRecorderContainer.defaultProps = {
  open: false,
  onClose: () => { },
  onApply: () => { },
};

AudioRecorderContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
};

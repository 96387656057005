import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import StudentSettingOption from '../../../../settings/StudentSettingOption';

const StudentSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Student Settings');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, { studentSettings: newValue });
  };

  return (
    <StudentSettingOption
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default StudentSettings;

import PropTypes from 'prop-types';
import {
  Typography,
  Box,
} from '@mui/material';
import ObjectiveLessonplay from './ObjectiveLessonplay';
import './ObjectiveLessons.scss';

const NUMBER_OF_LESSONS = 5;

const ObjectiveLessons = ({
  objective,
  included,
  onLessonSelect,
}) => {
  const activeLessons = [];
  const averagePercentageCorrectForLesson = {};
  const { relationships } = objective;
  const lessonPlays = relationships.lessonPlays.data || [];
  const lessons = relationships.lessons.data || [];
  const lessonPlayIds = lessonPlays.map((lessonPlay) => lessonPlay.id);

  lessons.forEach((lesson) => {
    const lessonPlayDataForLesson = included.filter((i) => (i.type === 'studentLessonPlays' && i.attributes.lessonId.toString() === lesson.id));
    const lessonPlayData = [];
    lessonPlayIds.forEach((lessonPlayId) => {
      const matchedLessonPlay = lessonPlayDataForLesson.find((l) => l.id === lessonPlayId);
      if (matchedLessonPlay) {
        lessonPlayData.push(matchedLessonPlay);
      }
    });
    const correctPercentages = lessonPlayData.map((l) => l.attributes.correctPercentage).slice(0, NUMBER_OF_LESSONS);
    const sum = correctPercentages.reduce((a, b) => a + b, 0);
    const average = Math.round((sum / correctPercentages.length) || 0);
    averagePercentageCorrectForLesson[lesson.id] = {
      numberofPlays: correctPercentages.length,
      average,
    };

    const lessonDetail = included.find((i) => (i.type === lesson.type && i.id === lesson.id));
    if (!lessonDetail.meta.archived) {
      activeLessons.push(lessonDetail);
    }
  });

  const createLessonPlaysList = (title, lessonList) => {
    if (!lessonList || lessonList.length === 0) {
      return null;
    }
    return (
      <div>
        <Typography variant='h5' fontWeight={500}>
          {title}
        </Typography>
        {lessonList.map((lesson, index) => (
          <Box
            onClick={() => {
              if (onLessonSelect) {
                onLessonSelect(lesson, index, lessonList, included);
              }
            }}
            className={`${onLessonSelect && 'objective-lesson-play-container'}`}
            key={`objective-lessons-${lesson.id}`}
            data-test={`objective-lessons-${lesson.id}`}
          >
            <ObjectiveLessonplay
              lesson={lesson}
              averagePercentageCorrect={averagePercentageCorrectForLesson[lesson.id]}
            />
          </Box>
        ))}
      </div>
    );
  };

  return (
    <div className='objective-lessons'>
      { createLessonPlaysList('Activities', activeLessons) }
    </div>
  );
};

ObjectiveLessons.defaultProps = {
  included: [],
  onLessonSelect: null,
};

ObjectiveLessons.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  onLessonSelect: PropTypes.func,
};

export default ObjectiveLessons;

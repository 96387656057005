import PropTypes from 'prop-types';
import { useTeacherCurriculum } from '../../../common/context/TeacherCurriculumContextProvider';
import PdfContainer from './PdfContainer';

const GuideScopeAndSequence = ({
  loadingMessage,
}) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { scopeAndSequenceLink } = teacherCurriculumDomain.domainData;

  return (
    <PdfContainer
      pdfKey={scopeAndSequenceLink}
      loadingMessage={loadingMessage}
      noPdfFoundMessage='No Sequence Guide available'
    />
  );
};

GuideScopeAndSequence.defaultProps = {
  loadingMessage: null,
};

GuideScopeAndSequence.propTypes = {
  loadingMessage: PropTypes.string,
};

export default GuideScopeAndSequence;

import { useRef } from 'react';

export const useDetermineClickOrTouchEventForDnd = (func = () => { }, isDraggable = false) => {
  /* This is to resolve the issues of
  1. the dnd card must be responsie without long press
  2. the media should play when tapping the media card
*/
  const moved = useRef(false);
  const handleEvent = ('ontouchstart' in document.documentElement) && isDraggable ? {
    onTouchEnd: (e) => {
      if (!moved.current) {
        func(e);
      }
      moved.current = false;
    },
    onTouchMove: () => {
      moved.current = true;
    },
  } : {
    onClick: func,
  };

  return handleEvent;
};

import { useState } from 'react';

export const getExceedLineLimit = (lineLimit) => `Cannot be more than ${lineLimit} lines`;
export const getExceedCharLimitMessage = (charLimit) => `Cannot be more than ${charLimit} characters`;

export const checkHasNonJosefinSlab = (textLines) => textLines && textLines.findIndex((line) => line.elements && line.elements.findIndex((element) => element.fontFamily !== 'Josefin Slab') >= 0) >= 0;

export const checkHasNon18FontSize = (textLines) => textLines && textLines.findIndex((line) => line.elements && line.elements.findIndex((element) => element.fontSize !== '18px') >= 0) >= 0;

// eslint-disable-next-line max-len
export const checkHasNonSpecificFontSize = (textLines, fontSize) => textLines && textLines.findIndex((line) => line.elements && line.elements.findIndex((element) => element.fontSize !== fontSize) >= 0) >= 0;

export const checkHasNonBoldFontWeight = (textLines) => textLines && textLines.findIndex((line) => line.elements && line.elements.findIndex((element) => element.fontWeight !== 'bold') >= 0) >= 0;

// eslint-disable-next-line max-len
export const checkCharsLimit = (textLines, charLimit) => textLines && textLines.findIndex((line) => line.elements && line.elements.findIndex((element) => element.text && element.text.trim().length > charLimit) >= 0) >= 0;

export const getTotalCharsLength = (textLines) => {
  if (!textLines || textLines.length === 0) {
    return 0;
  }

  let result = 0;
  textLines.forEach((line) => {
    if (line.elements) {
      line.elements.forEach((element) => {
        if (element && element.text) {
          result += element.text.trim().length;
        }
      });
    }
  });
  return result;
};

export const useHandleTextEditorWarning = () => {
  const [textEditorWarning, setTextEditorWarning] = useState({
    show: false,
    message: '',
  });

  const showTextEditorWarning = (message) => {
    setTextEditorWarning({
      show: true,
      message,
    });
  };

  const closeTextEditorWarning = () => {
    setTextEditorWarning({
      show: false,
      message: '',
    });
  };

  return {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  };
};

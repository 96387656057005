import { SUBJECT_ID_ELA } from '../../../../../constants/LessonConstant';
import BaseDomain from '../../../../../states/domains/BaseDomain';

const MY_LESSONS_UPDATE_LESSONS_LIST = 'MY_LESSONS_UPDATE_LESSONS_LIST';
const MY_LESSONS_UPDATE_LESSONS_AND_TAGS = 'MY_LESSONS_UPDATE_LESSONS_AND_TAGS';
const MY_LESSONS_UPDATE_LESSONS_LIST_AND_SELECTION = 'MY_LESSONS_UPDATE_LESSONS_LIST_AND_SELECTION';
const MY_LESSONS_UPDATE_SELECTED_LESSON_IDS = 'MY_LESSONS_UPDATE_SELECTED_LESSON_IDS';
const MY_LESSONS_UPDATE_LESSON_FILTER_VALUE = 'MY_LESSONS_UPDATE_LESSON_FILTER_VALUE';
const MY_LESSONS_UPDATE_LESSON_TAGS = 'MY_LESSONS_UPDATE_LESSON_TAGS';
const MY_LESSONS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER = 'MY_LESSONS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER';
const MY_LESSONS_SELECTED_DATES = 'MY_LESSONS_SELECTED_DATES';
const MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING = 'MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING';
const MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES = 'MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES';
const MY_LESSONS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES = 'MY_LESSONS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES';
const MY_LESSONS_UPDATE_LESSON_SORT_OPTION = 'MY_LESSONS_UPDATE_LESSON_SORT_OPTION';

export const defaultFilterCriteriaAssignedStudentCourses = {
  studentIds: null,
  lessonIds: null,
};

const initialState = {
  lessonData: {
    lessons: [],
    included: [],
    lessonFilterValue: {
      archived: false,
      keyword: '',
    },
    selectedLessonIds: [],
    filter: {
      selectedSubject: SUBJECT_ID_ELA,
    },
  },
  lessonSortOption: null,
  lessonTags: [],
  selectedDates: {},
  assignedStudentCoursesLoading: false,
  filterCriteriaAssignedStudentCourses: JSON.parse(JSON.stringify(defaultFilterCriteriaAssignedStudentCourses)),
  assignedStudentCourses: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case MY_LESSONS_UPDATE_LESSONS_LIST:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload,
        },
      };
    case MY_LESSONS_UPDATE_LESSONS_LIST_AND_SELECTION:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload,
        },
      };
    case MY_LESSONS_UPDATE_LESSONS_AND_TAGS:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload.lessonData,
        },
        lessonTags: action.payload.lessonTags,
      };
    case MY_LESSONS_UPDATE_LESSON_FILTER_VALUE:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          lessonFilterValue: action.payload,
        },
      };
    case MY_LESSONS_UPDATE_SELECTED_LESSON_IDS:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          selectedLessonIds: action.payload,
        },
      };
    case MY_LESSONS_UPDATE_LESSON_TAGS:
      return {
        ...state,
        lessonTags: action.payload,
      };
    case MY_LESSONS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          filter: action.payload.filter,
          selectedLessonIds: action.payload.selectedLessonIds,
        },
      };
    case MY_LESSONS_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload,
      };
    case MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING:
      return {
        ...state,
        assignedStudentCoursesLoading: action.payload,
      };
    case MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        assignedStudentCourses: action.payload.assignedStudentCourses,
        assignedStudentCoursesLoading: action.payload.assignedStudentCoursesLoading,
      };
    case MY_LESSONS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        filterCriteriaAssignedStudentCourses: action.payload,
      };
    case MY_LESSONS_UPDATE_LESSON_SORT_OPTION:
      return {
        ...state,
        lessonSortOption: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'myLessonsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update my resources
   *
   * @returns Promise
   */
  async updateLessons(lessons, included) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSONS_LIST,
      payload: {
        lessons,
        included,
      },
    });
  }

  /**
   * Update my resources and selected lessons
   *
   * @returns Promise
   */
  async updateLessonsAndSelectedLessonIds(lessons, included, selectedLessonIds) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSONS_LIST_AND_SELECTION,
      payload: {
        lessons,
        included,
        selectedLessonIds,
      },
    });
  }

  /**
   * Update lessons and lesson tags
   * @param {*} lessons lesson to update
   * @param {*} included included data
   * @param {*} lessonTags lesson tags
   */
  async updateLessonAndTags(lessons, included, lessonTags, selectedLessonIds = []) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSONS_AND_TAGS,
      payload: {
        lessonData: {
          lessons,
          included,
          selectedLessonIds,
        },
        lessonTags,
      },
    });
  }

  /**
  * Update lesson filter value
  *
  * @returns Promise
  */
  async updateLessonFilterValue(lessonFilterValue) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSON_FILTER_VALUE,
      payload: lessonFilterValue,
    });
  }

  /**
* Update lesson filter value
*
* @returns Promise
*/
  async updateSelectedLessonIds(selectedLessonIds) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_SELECTED_LESSON_IDS,
      payload: selectedLessonIds,
    });
  }

  async updateLessonTag(lessonTags) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSON_TAGS,
      payload: lessonTags,
    });
  }

  async updateSelectedLessonIdsAndFilter(selectedLessonIds, filter) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER,
      payload: {
        filter,
        selectedLessonIds,
      },
    });
  }

  async updateSelectedDates(selectedDates) {
    return this.dispatch({
      type: MY_LESSONS_SELECTED_DATES,
      payload: selectedDates,
    });
  }

  async updateAssignedStudentsCoursesLoading(loading) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING,
      payload: loading,
    });
  }

  async updateAssignedStudentsCourses(assignedStudentCourses, assignedStudentCoursesLoading) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_ASSIGNED_STUDENT_COURSES,
      payload: {
        assignedStudentCourses,
        assignedStudentCoursesLoading,
      },
    });
  }

  async updateFilterCriteriaAssignedStudentsCourses(filterCriteriaAssignedStudentCourses) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES,
      payload: filterCriteriaAssignedStudentCourses,
    });
  }

  async updateLessonSortOption(lessonSortOption) {
    return this.dispatch({
      type: MY_LESSONS_UPDATE_LESSON_SORT_OPTION,
      payload: lessonSortOption,
    });
  }
}

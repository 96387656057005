import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './TeacherAssignedStudentNoSelectedDateMessage.scss';

const TeacherAssignedStudentNoLessonMessage = ({
  selectedDates,
}) => (
  <div className='teacher-assigned-student-no-selected-date-message'>
    {(!selectedDates || !selectedDates.dates || selectedDates.dates.length === 0) && (
      <Typography className='no-seleted-lessons-title' variant='h5'>
        Choose a date option to display assignments
      </Typography>
    )}
  </div>
);

TeacherAssignedStudentNoLessonMessage.defaultProps = {
  selectedDates: {},
};

TeacherAssignedStudentNoLessonMessage.propTypes = {
  selectedDates: PropTypes.shape({
    dates: PropTypes.array,
  }),
};

export default TeacherAssignedStudentNoLessonMessage;

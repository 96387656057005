import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './MultiSelectorWithSearch.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function MultiSelectorWithSearch({
  selectorItems,
  values,
  className,
  onChange,
  disabled,
  placeholderWhenEmpty,
  limitTags,
  showSelectedValueAtTheTop,
}) {
  const v = selectorItems.filter((s) => values.includes(s.value));

  const filterText = useRef('');
  const [open, setOpen] = useState(false);

  const [initValues, setInitValues] = useState(values);

  let optionToUse = selectorItems;
  if (showSelectedValueAtTheTop) {
    const selectedOptions = selectorItems.filter((s) => initValues.includes(s.value));
    const notSelectedOptions = selectorItems.filter((s) => !initValues.includes(s.value));
    optionToUse = [
      ...selectedOptions,
      ...notSelectedOptions,
    ];
  }
  return (
    <Autocomplete
      multiple
      options={optionToUse}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      limitTags={limitTags}
      value={v}
      className={`multi-selector-with-search ${className}`}
      openOnFocus
      disableClearable
      open={open}
      onOpen={() => {
        if (showSelectedValueAtTheTop) {
          setInitValues(values);
        }
      }}
      onClose={(event, reason) => {
        if (reason === 'toggleInput') {
          return;
        }
        filterText.current = '';
        setOpen(false);
      }}
      onChange={(event, value) => {
        if (event.type === 'keydown') {
          return;
        }
        onChange(event, value);
      }}
      disabled={disabled}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={`option-${option.name}`}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      filterOptions={(optionsFilter) => {
        const filtered = optionsFilter.filter((option) => option.name.toLowerCase().indexOf(filterText.current.toLowerCase()) >= 0);
        return filtered;
      }}
      renderInput={(params) => {
        // eslint-disable-next-line no-param-reassign
        params.inputProps.value = filterText.current;

        return (
          <TextField
            {...params}
            onClick={() => { setOpen(true); }}
            variant='outlined'
            label={placeholderWhenEmpty}
            placeholder={placeholderWhenEmpty}
            onChange={(e) => {
              filterText.current = e.target.value;
            }}
          />
        );
      }}
    />
  );
}

MultiSelectorWithSearch.defaultProps = {
  values: [],
  className: '',
  onChange: () => { },
  disabled: false,
  placeholderWhenEmpty: '',
  limitTags: 1,
  showSelectedValueAtTheTop: false,
};

MultiSelectorWithSearch.propTypes = {
  selectorItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholderWhenEmpty: PropTypes.string,
  limitTags: PropTypes.number,
  showSelectedValueAtTheTop: PropTypes.bool,
};

import PropTypes from 'prop-types';
import './FfImageList.scss';

const FfImageList = ({ imageList }) => (
  <div className={`image-list ${imageList.length === 1 ? 'one-image' : 'multiple-images'}`}>
    {imageList.map((image, index) => (
      <div
        key={`ffImage-${index}`}
        data-test={`ffImage-${index}`}
        className='image-container'
        style={{ backgroundImage: `url(${image})` }}
      />
    ))}
  </div>
);

FfImageList.defaultProps = {
  imageList: [],
};

FfImageList.propTypes = {
  imageList: PropTypes.array,
};

export default FfImageList;

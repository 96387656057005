import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../../../components/buttons/ButtonWithSpinner';
import './EntryContainer.scss';

export default function EntryContainer({
  label,
  onApply,
  children,
  disabled,
  ...rest
}) {
  return (
    <div {...rest} className='apply-text-entry-container'>
      <div className='row'>
        <Typography variant='subtitle1' className='label'>
          {label}
        </Typography>
        {children}
      </div>

      <ButtonWithSpinner
        label='Apply'
        disabled={disabled}
        onClick={onApply}
      />
    </div>
  );
}

EntryContainer.defaultProps = {
  onApply: () => null,
  children: null,
  disabled: true,
  label: '',
};

EntryContainer.propTypes = {
  label: PropTypes.string,
  onApply: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

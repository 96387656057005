import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './MoveStudentsAction.scss';
import UpdateButton from '../../../../components/buttons/UpdateButton';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import MoveStudentsForm from './MoveStudentsForm';
import { useMoveStudents, validateMoveStudentFormData } from './MoveStudentsActionHooks';

const initData = {};

const MoveStudentsAction = ({
  selectedStudents,
  initSelectedDistrict,
  initSelectedSchool,
  onMoveStudentsSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [isDataValid, setIsDataValid] = useState(false);

  const dataRef = useRef(null);

  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    moveStudentsToNewSchool,
    loading,
  } = useMoveStudents({
    setOpen,
    onMoveStudentsSuccess,
    selectedStudents,
  });

  useEffect(() => {
    if (open) {
      dataRef.current = {
        ...initData,
        district: initSelectedDistrict,
        school: initSelectedSchool,
      };
      setIsDataValid(validateMoveStudentFormData(initData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: () => { moveStudentsToNewSchool(dataRef.current); },
      className: 'btn-primary action-button',
      title: 'Save',
      disabled: !isDataValid,
      loading,
    },
    ];
    return buttons;
  };

  return (
    <>
      <UpdateButton
        ariaLabel={`Move Student${selectedStudents.length > 1 ? 's' : ''}`}
        onClick={() => { setOpen(true); }}
        disabled={!selectedStudents || selectedStudents.length < 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='admin-students-move-students-dialog'
          onClose={handleOnCancel}
          title={`Move Student${selectedStudents.length > 1 ? 's' : ''}`}
          content={(
            <MoveStudentsForm
              initData={dataRef.current}
              studentTableData={selectedStudents}
              onDataChange={(updatedData, valid) => {
                dataRef.current = updatedData;
                setIsDataValid(valid);
              }}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};
MoveStudentsAction.defaultProps = {
  onMoveStudentsSuccess: () => null,
  selectedStudents: [],
  initSelectedDistrict: null,
  initSelectedSchool: null,
};

MoveStudentsAction.propTypes = {
  onMoveStudentsSuccess: PropTypes.func,
  selectedStudents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
  initSelectedDistrict: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  initSelectedSchool: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default MoveStudentsAction;

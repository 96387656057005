import { useMemo, useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../components/vizzle/media/MediaPlayer';
// eslint-disable-next-line import/no-cycle
import Hotspot from '../hotspot/Hotspot';
import { useTtsPlay } from '../../utils/UseTtsPlay';
import './BookCardComponent.scss';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';

/**
 * Lesson activity for Book
 */
const BookCardComponent = forwardRef(({
  config,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onInteractionOpen,
  className,
  cardIndex,
  isPlayable,
  userProfile,
}, ref) => {
  const componentRef = useRef(null);
  const hotspotRefs = useRef([]);

  const setHotspotRefs = (r, index) => {
    if (r) {
      hotspotRefs.current[index] = r.getEnableElements();
    }
  };

  const componentId = useMemo(() => uuidv1(), []);

  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef.current]);

  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const mediaPlayerRef = useRef(null);

  const cardStyle = {
    backgroundColor: config.bgColor,
    borderStyle: !config.borderColor || config.borderColor === '#000000' ? 'none' : 'solid',
    borderWidth: config.borderColor === '#000000' ? '0px' : '5px',
    borderColor: config.borderColor,
  };

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      const result = [componentRef.current];
      if (hotspotRefs.current && hotspotRefs.current.length > 0) {
        result.push(...hotspotRefs.current);
      }
      return result;
    },

    playMedia: () => mediaPlayerRef && mediaPlayerRef.current && mediaPlayerRef.current.playMedia(),
  }));

  return (
    <Card
      id={componentId}
      key={componentId}
      className={`book-card-component book-card-padding vizzle-book-card-page-${componentId} ${className}`}
      style={cardStyle}
      ref={componentRef}
      data-test={`book-card-activity-${cardIndex}`}
    >
      <TransparentBlocker show={!isPlayable} />
      {
        config.image && (
          <CardMedia
            className='book-card-image'
            image={config.image}
            title='Book'
            ref={imageRef}
            data-test='book-card-image'
          />
        )
      }

      {config.text && (
        <CardContent className='book-card-text' data-test='book-card-text'>
          <TextLines
            componentKey={`text-line-${componentId}`}
            textLines={config.text}
            ref={setTextLineRef}
          />
        </CardContent>
      )}

      {
        (config.audio || config.video || (elementToPlayTts && elementToPlayTts.length > 0))
        && (
          <MediaPlayer
            audioUrl={config.audio}
            videoUrl={config.video}
            textToRead={elementToPlayTts}
            tts={ttsConfig}
            parentElementId={componentId}
            className={`media-component vizzle-book-card-page-${componentId}-media-btn`}
            ref={mediaPlayerRef}
          />
        )
      }

      <div className='hospot-container' style={{ height: config.image ? `${imageHeight}px` : '100%' }}>
        {config.hotspots && config.hotspots.length > 0 && (
          config.hotspots.map((hotspot, hotspotIndex) => (
            <Hotspot
              key={`${componentId}-hotspot-${hotspotIndex}`}
              hotspot={hotspot}
              onCorrectAnswerSelected={onCorrectAnswerSelected}
              onIncorrectAnswerSelected={onIncorrectAnswerSelected}
              onInteractionOpen={onInteractionOpen}
              ref={(r) => { setHotspotRefs(r, hotspotIndex); }}
              hotspotCardIndex={hotspotIndex}
              isPlayable={isPlayable}
              userProfile={userProfile}
            />
          ))
        )}
      </div>
    </Card>
  );
});

export default BookCardComponent;

BookCardComponent.defaultProps = {
  config: {},
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onInteractionOpen: () => { },
  className: null,
  cardIndex: 0,
  isPlayable: true,
  userProfile: { tts: {} },
};

BookCardComponent.propTypes = {
  config: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    hotspots: PropTypes.array,
  }),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onInteractionOpen: PropTypes.func,
  className: PropTypes.string,
  cardIndex: PropTypes.number,
  isPlayable: PropTypes.bool,
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tts: PropTypes.object,
  }),
};

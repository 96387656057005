import { useRef, useMemo } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useAdmin } from '../../context/AdminContextProvider';

const buildTableData = (backendData) => {
  let tableData = [];
  if (backendData && backendData.data && backendData.data && backendData.data.relationships && backendData.data.relationships.students) {
    tableData = backendData.data.relationships.students.data.map((d) => {
      const studentObject = backendData.included.find((li) => li.id === d.id && li.type === d.type);
      const { attributes } = studentObject;
      const result = {
        userName: attributes.userName,
        firstName: attributes.firstName,
        lastName: attributes.lastName,
        active: attributes.isActive,
        email: attributes.email,
        id: studentObject.id,
      };

      return result;
    });
  }
  return tableData;
};

export const useGetStudentTable = () => {
  const { adminDomain, adminStudentsDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const { filterActive } = adminStudentsDomain.domainData;
  const tableRef = useRef(null);
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '30%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '30%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '30%',
    },
  ];

  if (userSuperAdmin) {
    tableConfig.unshift(
      {
        title: 'Id',
        align: 'left',
        dataField: 'id',
        width: '10%',
      },
    );
  }

  const studentTableData = useMemo(() => {
    if (!usersAndLicensesData || !usersAndLicensesData.data || !usersAndLicensesData.data.relationships || !usersAndLicensesData.data.relationships.students) {
      return [];
    }
    return buildTableData(usersAndLicensesData).filter((t) => t.active === filterActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAndLicensesData, filterActive]);
  return {
    tableConfig,
    studentTableData,
    tableRef,
  };
};

export const useHandleStudentTable = () => {
  const { adminDomain, adminStudentsDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const { selectedStudentIds } = adminStudentsDomain.domainData;
  const updateStudentRef = useRef(null);

  const handleOnSelectedRowIds = (selectedRowIds, clickParam) => {
    adminStudentsDomain.updateSelectedStudentIds(selectedRowIds);
    if (clickParam && clickParam.doubleClicks && updateStudentRef.current) {
      updateStudentRef.current.openUpdateStudent();
    }
  };

  const selectedStudents = selectedStudentIds && selectedStudentIds.length > 0 && usersAndLicensesData.included
    ? usersAndLicensesData.included.filter((i) => i.type === 'users' && selectedStudentIds.includes(i.id))
    : [];

  return {
    selectedStudentIds,
    handleOnSelectedRowIds,
    updateStudentRef,
    selectedStudents,
  };
};

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  useHandleFileUpload,
} from './FileUploaderHooks';
import './FileUploader.scss';

const FileUploader = ({
  uploadServiceFunction,
  buttonLabel,
  id,
  onUploadSuccess,
  disabled,
  buttonClassName,
  isMultiple,
  fileAccept,
}) => {
  const {
    handleOnFileSelect,
  } = useHandleFileUpload({
    uploadServiceFunction,
    onUploadSuccess,
    isMultiple,
  });

  return (
    <>
      <input
        accept={fileAccept}
        className='files-upload-action-input'
        id={id}
        type='file'
        onChange={handleOnFileSelect}
        value=''
        disabled={disabled}
        multiple={`${isMultiple ? 'multiple' : undefined}`}
      />
      <label htmlFor={id}>
        <Button
          variant='contained'
          className={`${buttonClassName || 'btn-green-background'}`}
          startIcon={<PictureAsPdfIcon />}
          component='span'
          disabled={disabled}
          data-test='file-upload'
        >
          {buttonLabel}
        </Button>
      </label>
    </>
  );
};

FileUploader.defaultProps = {
  buttonLabel: 'Upload',
  uploadServiceFunction: () => null,
  onUploadSuccess: () => null,
  disabled: false,
  buttonClassName: '',
  isMultiple: false,
  fileAccept: '.pdf',
};

FileUploader.propTypes = {
  buttonLabel: PropTypes.string,
  uploadServiceFunction: PropTypes.func,
  id: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  buttonClassName: PropTypes.string,
  isMultiple: PropTypes.bool,
  fileAccept: PropTypes.string,
};

export default FileUploader;

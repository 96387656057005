import {
  celebrationObject,
} from './CelebrationHooks';

export default {
  playCelebration: (celebrationId) => {
    celebrationObject.setCelebrationState({
      open: true,
      celebrationId,
    });
  },
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Typography,
} from '@mui/material';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import CancelDialog from '../CancelDialog';
import './AddTopicDialog.scss';
import '../../SearchBarAction.scss';
import { useGetTopicDropdownList } from '../../../TeacherCourseSearchBarHooks';
import { useHandleTopic, useSaveTopics } from './AddTopicDialogHooks';
import { useGetUnit } from '../../../tabs/topic-lessons/TeacherCourseLessonsHooks';

const AddTopicDialog = ({
  open,
  onClose,
  onSaveSuccess,
  courseId,
  unitId,
}) => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const {
    topicName,
    // addedTopicNames,
    handleOnTopicChange,
    // hadleOnAddTopicSubmit,
  } = useHandleTopic();

  const {
    saving,
    handleOnSave,
  } = useSaveTopics({
    onSaveSuccess,
    courseId,
    unitId,
  });

  const topicList = useGetTopicDropdownList(courseId, unitId);

  const unit = useGetUnit(courseId, unitId);

  return (
    <>
      <CustomDialog
        className='teacher-course-add-topic-dialog'
        openDialog={open}
        onClose={() => onClose()}
        displayCloseIcon={false}
        title={`Add topic to ${unit.name}`}
        content={(
          <div className='dialog-content'>
            <form
              className='enter-topic-container'
              onSubmit={(e) => {
                e.preventDefault();
                handleOnSave(topicName);
              }}
            >
              <TextField
                onChange={handleOnTopicChange}
                label='Enter name of new Topic'
                fullWidth
                value={topicName}
                inputProps={{
                  maxLength: 255,
                }}
                autoFocus
              />
            </form>
            <div className='topic-list'>
              {topicList.map((t) => (
                <Typography className='topic' key={`add-topic-existing-topic-${t.id}`}>{t.name}</Typography>
              ))}
            </div>
          </div>
        )}
        actions={[
          {
            title: 'Cancel',
            color: 'primary',
            className: 'btn-danger action-button wide-button left-button',
            onClick: () => { if (topicName) { setOpenCancelDialog(true); } else { onClose(); } },
            disabled: saving,
            loading: saving,
          },
          {
            title: 'Continue',
            color: 'primary',
            className: 'btn-primary wide-button action-button',
            disabled: !topicName || topicName.length === 0 || saving,
            onClick: () => { handleOnSave(topicName); },
            loading: saving,
          },
        ]}
      />

      <CancelDialog
        open={openCancelDialog}
        title='Cancel adding a Topic'
        onClose={() => { setOpenCancelDialog(false); }}
        onCancel={() => { onClose(); }}
      />
    </>
  );
};

AddTopicDialog.defaultProps = {
  open: false,
  onClose: () => null,
  onSaveSuccess: () => null,
};

AddTopicDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveSuccess: PropTypes.func,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AddTopicDialog;

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import ThemeUtil from '../ThemeUtil';
import './NeutralTheme.scss';

const NeutralTheme = ({
  // themeData,
  onClick,
  lessonsCompleted,
  lessonsLength,
}) => {
  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  return (
    <div className='natural-theme-container' id='neutral-theme'>
      <Card className='card' onClick={onClick} data-switch-access-element='true'>
        <h1 className='title'>
          Your Lesson
        </h1>
        <div className='body'>
          You are on activity {innerLessonsCompleted} of {innerLessonsLength}
        </div>
      </Card>
    </div>
  );
};

NeutralTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

NeutralTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default NeutralTheme;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import { usePrepareStudentGroups } from './AssignLessonActionWithDateSelectionHooks';
import CustomDialog from '../../../../components/dialog/CustomDialog';
import './AssignLessonActionWithDateSelectionV2.scss';
import NewVizzleIframe from '../../../../components/vizzle/container/NewVizzleIframe';
import { useDomain } from '../../../../states/AppDomainProvider';

export default function AssignLessonActionWithDateSelectionV2({
  lessons,
  selectedLessonIds,
  buttonLabel,
  currentStudent,
  showAddToMyList,
  reloadDataFunc,
  tagNameForAutoSaving,
  objectiveType,
}) {
  const { uiDomain } = useDomain();
  const [openDialog, setOpenDialog] = useState(false);

  const selectedLessons = [];
  lessons.forEach((l) => {
    if (selectedLessonIds.includes(l.id)) {
      selectedLessons.push(l);
    }
  });

  const handleOnClick = () => {
    setOpenDialog(true);
  };

  const handleOnDialogClose = () => {
    setOpenDialog(false);
  };

  const {
    loading,
    studentGroupData,
  } = usePrepareStudentGroups();

  useEffect(() => {
    const register = (e) => {
      const { data } = e;
      if (data.event === 'close-dialog') {
        handleOnDialogClose();
      } else if (data.event === 'save-activities-assigment-success') {
        reloadDataFunc();
      } else if (data.event === 'save-tags-assigment-success') {
        uiDomain.showSnackbar(true, 'success', 3000, 'Lessons tagged successfully');
        reloadDataFunc();
      }
    };
    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ButtonWithSpinner
        variant='contained'
        className='btn-primary'
        disabled={!selectedLessonIds || selectedLessonIds.length === 0}
        onClick={handleOnClick}
        startIcon={<PersonAddIcon />}
        data-test='assign-lessons-action'
        label={buttonLabel}
        loading={loading}
      />
      {(!selectedLessonIds || selectedLessonIds.length === 0) && (
        <Typography variant='h6' className='orange-title'>
          Select activities
        </Typography>
      )}

      {openDialog && (
        <CustomDialog
          className='lessons-assignment-v2-sortable-dialog'
          openDialog={openDialog}
          onClose={handleOnDialogClose}
          fullScreen
          // title={<strong>Assign</strong>}
          content={(
            <Box width='100%' height='100vh' overflow='hidden'>
              <NewVizzleIframe
                path='/interim-assign-lesson'
                payload={{
                  assignmentItems: selectedLessons,
                  buttonLabel,
                  currentStudent,
                  showAddToMyList,
                  // reloadDataFunc,
                  tagNameForAutoSaving,
                  objectiveType,
                  studentGroupData,
                }}
              />
            </Box>
          )}
          displayCloseIcon={false}
        // actions={actions}
        />
      )}
    </>
  );
}

AssignLessonActionWithDateSelectionV2.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  buttonLabel: 'Assign',
  currentStudent: null,
  showAddToMyList: false,
  reloadDataFunc: () => { },
  tagNameForAutoSaving: null,
  objectiveType: undefined,
};

AssignLessonActionWithDateSelectionV2.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  currentStudent: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  showAddToMyList: PropTypes.bool,
  reloadDataFunc: PropTypes.func,
  tagNameForAutoSaving: PropTypes.arrayOf(PropTypes.string),
  objectiveType: PropTypes.string,
};

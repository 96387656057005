import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, Portal, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import CardActivity from '../common/card-activity/CardActivity';
import '../../../../components/vizzle/page/multiple-choice-v2/MultipleChoiceV2Layout.scss';
import './MultipleChoiceActivityV2.scss';
import '../activities-common.scss';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useInitializeData, useClickSubmitAnswer, useHandleSubmitButton, usePrepareViewCorrectSubmissions } from './MultipleChoiceActivityV2Hooks';
import { useShowSubmitValueButton } from '../multiple-choice/MultipleChoiceActivityHooks';
import { TOOLBAR_ACTIONS_ID } from '../../../../components/navigator/LessonNavigator';
import { getMultipleChoicePrefix } from '../../../../components/vizzle/page/multiple-choice/MultipleChoiceUtils';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

const MultipleChoiceActivityV2 = forwardRef(({
  questionCard,
  answerCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onTriggerSwitchScan,
  isCurrentPage,
  pageIndex,
  promptText,
  subText,
  singlePage,
}, ref) => {
  const {
    questionCardRef,
    promptTextRef,
    subTextRef,
    multipleChoicesResponseRefs,
    setMultipleChoiceResponseRefs,
    submitAnswerActionRef,
  } = useInitializeData();

  const rejectedAnswerPromptRefs = [];

  const setRejectedAnswerPromptRef = (r, index) => {
    if (r) {
      rejectedAnswerPromptRefs[index] = r;
    }
  };

  const {
    selectedValue,
    handleOnResponseCardClick,
  } = useClickSubmitAnswer({
    onTriggerSwitchScan,
  });

  const {
    isSubmitted,
    setIsSubmitted,
    incorrectedAnswers,
    handleOnSubmitButtonClick,
    setIncorrectedAnswers,
  } = useHandleSubmitButton({
    questionCard,
    answerCards,
    selectedValue,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
    rejectedAnswerPromptRefs,
  });

  const {
    showSubmitValueButton,
    setShowSubmitValueButton,
  } = useShowSubmitValueButton({ isCurrentPage, isSubmitted, pageIndex });

  const hasCardWithMedia = answerCards.find((a) => a.image || a.video || a.audio);

  usePrepareViewCorrectSubmissions({
    answerCards,
    onActivityFinished,
    pageIndex,
    setShowSubmitValueButton,
    setIsSubmitted,
    setIncorrectedAnswers,
    handleOnResponseCardClick,
    isCurrentPage,
  });

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(100);
      if (isSubmitted) {
        return [];
      } else {
        const responseRefs = multipleChoicesResponseRefs;

        let result = [];
        if (promptTextRef && promptTextRef.current) {
          result.push(promptTextRef.current.getEnableElements());
        }

        result.push(questionCardRef.current.getEnableElements());

        if (subTextRef && subTextRef.current) {
          result.push(subTextRef.current.getEnableElements());
        }
        result = [
          ...result,
          ...responseRefs,
        ];

        if (submitAnswerActionRef.current && !submitAnswerActionRef.current.disabled) {
          result.push(submitAnswerActionRef.current);
        }
        return result;
      }
    },
  }));

  return (
    <>
      <div
        className={`
        multiple-choice-v2-activity-component 
        multiple-choice-v2-layout 
        ${singlePage ? 'single-page' : 'side-by-side'}
        ${hasCardWithMedia ? '' : 'stack-vertically'}
        `}
      >
        <TransparentBlocker show={isSubmitted} />
        <div className='prompt-area'>
          {promptText && (
            <CardActivity data-test='multiple-choice-prompt' className='multiple-choice-prompt-text' text={promptText} ref={promptTextRef} fontScale={0.95} />
          )}
          <CardActivity
            className='multiple-choice-component-question-card'
            text={questionCard.text}
            image={questionCard.image}
            video={questionCard.video}
            audio={questionCard.audio}
            ref={questionCardRef}
            data-test='multiple-choice-question'
          />
          {subText && (
            <CardActivity data-test='multiple-choice-sub' className='multiple-choice-sub-text' text={subText} ref={subTextRef} fontScale={0.95} />
          )}
        </div>
        <FormControl
          className='response-area vizzle-radio'
        >
          <RadioGroup
            name={`multiple-choice-${pageIndex}`}
            className={`response-cards-container multiple-choice-${answerCards.length} vizzle-radio`}
            value={selectedValue}
          >
            {answerCards.map((card, index) => {
              const key = `multiple-choice-answer-key-${pageIndex}-${index}-${card.answerKey}`;
              return (
                <Paper
                  key={key}
                  className={`multiple-choice-component-response-container multiple-choice-component-response-main-container ${incorrectedAnswers.includes(index) ? 'disable' : ''}`}
                  onClick={() => handleOnResponseCardClick && handleOnResponseCardClick(index)}
                  elevation={3}
                  style={{ overflow: 'auto' }}
                  ref={(r) => { setMultipleChoiceResponseRefs(r, index); }}
                >
                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={(
                      <div className='prefix'>
                        {getMultipleChoicePrefix(index)}.
                      </div>
                    )}
                    labelPlacement='start'
                  />
                  <div
                    className='multiple-choice-component-response-container'
                  >
                    <CardActivity
                      className='multiple-choice-component-response'
                      image={card.image}
                      audio={card.audio}
                      video={card.video}
                      text={card.text}
                      data-test={key}
                    />
                  </div>
                  <SubmittedAnswerIndicator correctSubmission={answerCards[index].correctSubmission} />
                  <RejectedAnswerResponse ref={(r) => setRejectedAnswerPromptRef(r, index)} />
                </Paper>
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>

      {showSubmitValueButton && (
        <Portal container={document.getElementById(TOOLBAR_ACTIONS_ID)}>
          <Button
            onClick={handleOnSubmitButtonClick}
            data-test={`multiple-choice-page-${pageIndex}-submit-action`}
            className='multiple-choice-v2-submit-answer-action btn-green-background'
            disabled={selectedValue === null || selectedValue === undefined || selectedValue === ''}
            ref={submitAnswerActionRef}
          >
            SUBMIT ANSWER
          </Button>
        </Portal>
      )}

    </>
  );
});

export default MultipleChoiceActivityV2;

MultipleChoiceActivityV2.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  onTriggerSwitchScan: () => { },
  isCurrentPage: false,
  promptText: null,
  subText: null,
  singlePage: true,
};

MultipleChoiceActivityV2.propTypes = {
  questionCard: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  pageIndex: PropTypes.number.isRequired,
  promptText: PropTypes.object,
  subText: PropTypes.object,
  singlePage: PropTypes.bool,
};

import { useState, useEffect } from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
// import {
//   DataUsage as DataUsageIcon,
//   LocationCity as LocationCityIcon,
//   PersonPin as PersonPinIcon,
//   Face as FaceIcon,
//   VerifiedUser as VerifiedUserIcon,
//   Search as SearchIcon,
//   SupervisedUserCircle as SupervisedUserCircleIcon,
//   Domain as DomainIcon,
//   // Person as PersonIcon,
// } from '@mui/icons-material';
import { useDomain } from '../../states/AppDomainProvider';
import AdminData from './tabs/data/AdminData';
import Districts from './tabs/districts/Districts';
import Schools from './tabs/schools/Schools';
import Licenses from './tabs/licenses/Licenses';
import Teachers from './tabs/teachers/Teachers';
import Students from './tabs/students/Students';
import Admins from './tabs/admins/Admins';
import AdminSearch from './tabs/search/AdminSearch';
// import ExternalUsers from './tabs/external-users/ExternalUsers';
import { useGetDistrictData } from './tabs/districts/DistrictsHooks';
import { URL_ADMIN } from '../../routes/RouteConstants';
import AdminReport from './tabs/report/AdminReport';

export const useGetAccessibleMenu = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    adminDataAccess,
    adminSchoolsAccess,
    adminTeachersAccess,
    adminStudentsAccess,
    userSuperAdmin,
    adminSearchAccess,
    legacyAdminDataAccess,
  } = user;

  const accessibleMenu = [];
  if (userSuperAdmin) {
    accessibleMenu.push({
      label: 'Districts',
      // icon: <DomainIcon />,
      link: 'districts',
      element: <Districts />,
    });

    accessibleMenu.push({
      label: 'Schools',
      // icon: <LocationCityIcon />,
      link: 'schools',
      element: <Schools />,
    });
    accessibleMenu.push({
      label: 'Teachers',
      // icon: <PersonPinIcon />,
      link: 'teachers',
      element: <Teachers />,
    });

    accessibleMenu.push({
      label: 'Students',
      // icon: <FaceIcon />,
      link: 'students',
      element: <Students />,
    });

    accessibleMenu.push({
      label: 'Licenses',
      // icon: <VerifiedUserIcon />,
      link: 'licenses',
      element: <Licenses />,
    });

    if (legacyAdminDataAccess) {
      accessibleMenu.push({
        label: 'Data',
        // icon: <DataUsageIcon />,
        link: 'data/*',
        element: <AdminData />,
      });
    } else {
      accessibleMenu.push({
        label: 'Data',
        // icon: <DataUsageIcon />,
        link: 'data',
        element: <AdminReport />,
      });
    }

    accessibleMenu.push({
      label: 'Search',
      // icon: <SearchIcon />,
      link: 'search/*',
      element: <AdminSearch />,
    });

    accessibleMenu.push({
      label: 'Admins',
      // icon: <SupervisedUserCircleIcon />,
      link: 'admins',
      element: <Admins />,
    });
  } else {
    if (adminSchoolsAccess) {
      accessibleMenu.push({
        label: 'Schools',
        // icon: <LocationCityIcon />,
        link: 'schools',
        element: <Schools />,
      });
    }

    if (adminTeachersAccess) {
      accessibleMenu.push({
        label: 'Teachers',
        // icon: <PersonPinIcon />,
        link: 'teachers',
        element: <Teachers />,
      });
    }

    if (adminStudentsAccess) {
      accessibleMenu.push({
        label: 'Students',
        // icon: <FaceIcon />,
        link: 'students',
        element: <Students />,
      });
    }

    if (legacyAdminDataAccess) {
      accessibleMenu.push({
        label: 'Data',
        // icon: <DataUsageIcon />,
        link: 'data/*',
        element: <AdminData />,
      });
    } else if (!legacyAdminDataAccess && adminDataAccess) {
      accessibleMenu.push({
        label: 'Data',
        // icon: <DataUsageIcon />,
        link: 'data',
        element: <AdminReport />,
      });
    }

    if (adminSearchAccess) {
      accessibleMenu.push({
        label: 'Search',
        // icon: <SearchIcon />,
        link: 'search/*',
        element: <AdminSearch />,
      });
    }
  }

  // if (userSuperAdmin) {
  //   accessibleMenu.push({
  //     label: 'External Users',
  //     icon: <PersonIcon />,
  //     link: 'external-users',
  //     element: <ExternalUsers />,
  //   });
  // }

  return {
    accessibleMenu,
  };
};

export const useHandleTab = (accessibleMenu) => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(() => {
    const path = location.pathname && location.pathname.split('/');
    if (path && path.length >= 3) {
      const subPath = path[2];
      const index = accessibleMenu.findIndex((am) => {
        if (am.link.endsWith('/*')) {
          return am.link.substring(0, am.link.length - 2);
        }
        return am.link === subPath;
      });
      return index >= 0 ? index : 0;
    } else {
      return 0;
    }
  });

  const navigate = useNavigate();

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
    navigate(`${URL_ADMIN}/${accessibleMenu[newValue].link}`);
  };

  return {
    tabValue,
    handleTabChange,
    location: location.pathname,
  };
};

export const useInitialDistrictData = (showLoader) => {
  const {
    getDistrictsData,
  } = useGetDistrictData();
  useEffect(() => {
    getDistrictsData(showLoader);
    // eslint-disable-next-line
  }, []);
};

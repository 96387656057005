/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CardAction from '../../../../components/card/CardAction';
import { useAddRemoveFromGroupPlay } from './AddRemoveFromGroupPlayHooks';
import './RemoveFromGroupPlay.scss';

/**
 * Share Link Lesson Action Container
 */
export default function RemoveFromGroupPlay({
  lesson,
  onSaveSuccess,
}) {
  const { removeLessonFromGroupPlay } = useAddRemoveFromGroupPlay(lesson, onSaveSuccess);
  const handleOnRemoveFromGroupPlay = () => {
    removeLessonFromGroupPlay();
  };

  return (
    <CardAction
      icon={<RemoveCircleIcon />}
      label='GROUP INSTRUCTION'
      ariaLabel='Remove from Group Instruction'
      title='Remove from Group Instruction'
      className='remove-from-group-play-action'
      actionHandler={handleOnRemoveFromGroupPlay}
      data-test='remove-from-group-play-action'
    />
  );
}

RemoveFromGroupPlay.defaultProps = {
  onSaveSuccess: () => { },
};

RemoveFromGroupPlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  onSaveSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Card,
  CardActionArea,
} from '@mui/material';
import TextLines from '../../../text/TextLines';
import {
  AREA_INSTRUCTIONS,
  SECTION_TEXT,
  SECTION_AUDIO,
} from './InstructionEditorConstants';
import './InstructionEditor.scss';

export default function InstructionEditor({ instructionsConfig, className, selectedSection }) {
  const handleOnTextSelected = (e) => {
    instructionsConfig.onSelected(e, AREA_INSTRUCTIONS, SECTION_TEXT);
  };

  const handleOnAudioSelected = (e) => {
    instructionsConfig.onSelected(e, AREA_INSTRUCTIONS, SECTION_AUDIO);
  };

  return (
    <div className={`instructions-editor ${className}`} data-test='instruction-editor'>
      <legend>Instruction Prompt</legend>
      <Card className='instructions-editor-card'>
        <CardActionArea
          className={`instructions-editor-text ${
            selectedSection.selectedArea === AREA_INSTRUCTIONS
              && selectedSection.selectedSection === SECTION_TEXT
              ? 'instructions-editor-selected' : ''
          }`}
          onClick={handleOnTextSelected}
          data-test='instruction-editor-text'
        >
          {instructionsConfig.text
            ? (
              <div className='instructions-editor-with-value'>
                <TextLines textLines={instructionsConfig.text} isThumbnail />
              </div>
            ) : (
              <div className='instructions-editor-placeholder'>Text</div>
            )}
        </CardActionArea>
        <CardActionArea
          className={`instructions-editor-audio ${
            selectedSection.selectedArea === AREA_INSTRUCTIONS
              && selectedSection.selectedSection === SECTION_AUDIO
              ? 'instructions-editor-selected' : ''
          }`}
          onClick={handleOnAudioSelected}
          data-test='instruction-editor-audio'
        >
          {instructionsConfig.audio
            ? (
              <div className='instructions-editor-with-value instructions-editor-with-audio'>
                <VolumeUpIcon className='instructions-editor-with-audio-icon' />
              </div>
            ) : (
              <div className='instructions-editor-placeholder'>
                Audio
              </div>
            )}
        </CardActionArea>
      </Card>
    </div>
  );
}

InstructionEditor.defaultProps = {
  instructionsConfig: {
    onSelected: () => {},
  },
  selectedSection: {
    selectedArea: AREA_INSTRUCTIONS,
    selectedSection: SECTION_AUDIO,
  },
  className: '',
};

InstructionEditor.propTypes = {
  instructionsConfig: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  selectedSection: PropTypes.shape({
    selectedArea: PropTypes.string,
    selectedSection: PropTypes.string,
  }),
  className: PropTypes.string,
};

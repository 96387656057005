import { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import useSettingForm from '../form/useSettingForm';
import { PLAY_MODE } from '../../../../../constants/PlayPreferenceConstant';
import './PlayModeSetting.scss';

const playModeOptions = [
  {
    value: PLAY_MODE.selfSelected,
    name: 'Self-select',
    description: 'Students are able to view and play all activities assigned by an educator. Recommended for students who can navigate independently.',
  },
  {
    value: PLAY_MODE.progressBasedPlay,
    name: 'Auto-play',
    description: 'Students automatically move through assignments in the order set by an educator. Recommended for students needing additional support for independence.',
  },
];

export default function PlayModeSetting() {
  const { updateProfileSettingsOnChange } = useSettingForm('Play Mode');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;
  const [internalPlayMode, setInternalPlayMode] = useState(selectedStudentProfile.playMode);

  // When mounting and changing a student.
  useEffect(() => {
    setInternalPlayMode(selectedStudentProfile.playMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudentProfile.id]);

  const handleOnSelect = (e) => {
    setInternalPlayMode(e.target.value);
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      playMode: e.target.value,
    });
  };

  return (
    <FormControl variant='outlined' data-test='play-mode-setting' className='play-mode-setting'>
      <RadioGroup
        aria-labelledby='play-mode-radio-group'
        value={internalPlayMode}
        onChange={handleOnSelect}
        className='play-mode-radio-group'
      >
        {playModeOptions.map((option) => (
          <FormControlLabel
            key={`play-mode-option-${option.value}`}
            value={option.value}
            control={<Radio />}
            className='play-mode-radio'
            label={
              (
                <div className='label-container'>
                  <div className='label-name'>
                    {option.name}
                  </div>
                  <div className='label-description'>
                    {option.description}
                  </div>
                </div>
              )
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

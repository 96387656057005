import MultipleDateSelector, { DATE_SELECTOR_MODE } from '../../../../../components/date/MultipleDateSelector';
import FilterableMultiSelector from '../../../../../components/selector/FilterableMultiSelector';
import SearchDistrictAction from '../../../components/actions/search-district/SearchDistrictAction';
import SearchSchoolAction from '../../../components/actions/search-school/SearchSchoolAction';
import AdminExportData from '../actions/AdminExportData';
import ContactAccountManagerAction from '../actions/ContactAccountManagerAction';
import GenerateReportAction from '../actions/GenerateReportAction';
import { useGetActivityReports, useGetTeacherList } from '../AdminDataHooks';
import './TeacherMenubar.scss';
import { useHandleSearchDistrict, useHandleLicenseType, useHandleDateSelector, yesterdayDate, useHandleSchoolFilter, useAutoLoadData } from './TeacherMenubarHooks';

const TeacherMenubar = () => {
  const {
    getTeacherListData,
  } = useGetTeacherList();

  const {
    getActivityReportsData,
  } = useGetActivityReports();

  const {
    districtIds,
    handleOnDistrictChange,
    shouldSearchDistrictDisabled,
  } = useHandleSearchDistrict({
    getTeacherListData,
    getActivityReportsData,
  });

  const {
    schoolsData,
    selectedSchoolIds,
    handleOnSchoolsLoaded,
    onFilterSchoolChange,
    shouldSearchSchoolDisabled,
  } = useHandleSchoolFilter({
    getTeacherListData,
    getActivityReportsData,
  });

  useAutoLoadData({
    getTeacherListData,
    getActivityReportsData,
  });

  const {
    licenseTypeIds,
    handleOnLicensesChange,
    licensesTypeOptions,
    shouldLicenseTypeDisabled,
  } = useHandleLicenseType({
    getTeacherListData,
    getActivityReportsData,
  });

  const {
    internalSelectedDates,
    handleOnSelectedDatesChange,
    handleOnSelectedDateApply,
  } = useHandleDateSelector();
  return (
    <div className='teacher-menu-bar'>
      <div className='no-wrap-container'>
        <SearchDistrictAction
          className='search-dropdown'
          onFilterChange={handleOnDistrictChange}
          filterValue={districtIds}
          isMultiple={false}
          disabled={shouldSearchDistrictDisabled}
          ableToUnselectedInSingleMode
        />
        <SearchSchoolAction
          className='search-dropdown'
          districtId={districtIds && districtIds.length > 0 ? districtIds[0] : null}
          schoolsData={schoolsData}
          onSchoolsLoaded={handleOnSchoolsLoaded}
          isMultiple
          filterValue={selectedSchoolIds}
          onFilterChange={onFilterSchoolChange}
          disabled={shouldSearchSchoolDisabled}
        />
        <FilterableMultiSelector
          className='license-type'
          onApply={handleOnLicensesChange}
          label='License Type'
          initFilterKeyValues={licenseTypeIds}
          options={licensesTypeOptions}
          isMultiple
          filterable={false}
          disabled={shouldLicenseTypeDisabled}
        />
      </div>
      <div className='no-wrap-container'>
        <MultipleDateSelector
          selectorMode={DATE_SELECTOR_MODE.DATE_RANGE}
          selectedDates={internalSelectedDates}
          onSelectedDatesChange={handleOnSelectedDatesChange}
          onClose={handleOnSelectedDateApply}
          maxDate={yesterdayDate}
          noApplyButton
          noClearButton
          className='admin-data-date-selector'
          showDatePanel
          formMode
        />

        <GenerateReportAction />
        <ContactAccountManagerAction />
        <AdminExportData />
      </div>
    </div>
  );
};

export default TeacherMenubar;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import PrintCard from '../../common/print/PrintCard';
import CategorizingPrintPrompt from './components/CategorizingPrintPrompt';
import { useCardRotationCache } from '../../../../../containers/lessonplay/activities/categorizing/CategorizingActivityHooks';
import './CategorizingPrint.scss';

const CategorizingPrint = ({
  instructions,
  promptCards,
  responseCards,
}) => {
  const id = useMemo(() => uuidv1(), []);

  const allResponsesText = responseCards.findIndex((c) => Boolean(c.image)) < 0;

  const {
    cardRotationCache,
  } = useCardRotationCache({
    numberOfCards: responseCards.length,
    allResponsesText,
  });

  const hasInstruction = instructions.text || instructions.image;

  return (
    <div className='categorizing-print-component'>
      <div
        className='categorizing-print-prompt'
      >
        <div className='flexible-column-container'>
          {hasInstruction && (
            <div className={`panel instruction-container ${instructions.image ? 'full-height' : ''}`}>
              <PrintCard {...instructions} fontScale={0.75} />
            </div>
          )}
          <div
            className={`
            panel 
            responses-container 
            categorizing-response-card-${responseCards.length} 
            ${hasInstruction ? '' : 'large-gap'}
            ${allResponsesText ? 'flatten-cards' : ''}
            `}
          >
            {
              responseCards.map((responseCard, index) => {
                const cardId = `${id}-${index}`;
                const responseKeyId = `categorizing-response-key-${cardId}`;
                const responseContainerId = `categorizing-response-container-${cardId}`;
                const draggableId = `categorizing-drag-item-${cardId}`;
                const { rndAngle, rndClock } = cardRotationCache[index];
                return (
                  <div id={responseContainerId} key={responseContainerId} className='response-card-container'>
                    <div
                      style={{
                        transform: `rotate(${rndClock === 1 ? '-' : ''}${rndAngle}deg)`,
                      }}
                      key={responseKeyId}
                      className='response-card-place-holder'
                      id={`${draggableId}-container`}
                    >

                      <div
                        key={`categorizing-response-print-item-${cardId}`}
                        className='categorizing-response-print-container'
                      >
                        <PrintCard
                          {...responseCard}
                          fontScale={allResponsesText ? 0.85 : undefined}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>

        <div className='prompt-panels-container'>
          <div className={`flexible-column-container panel prompt-container  ${promptCards.length >= 7 ? 'two-columns' : ''}`}>
            {promptCards.map((promptCard, index) => {
              const promptContainer = `categorizing-prompt-print-container-${id}-${index}`;
              return (
                <div className={`prompt prompt-${promptCards.length}`} key={promptContainer}>
                  <CategorizingPrintPrompt
                    promptCard={promptCard}
                    fontScale={promptCards.length >= 7 ? 0.5 : 0.9}
                  >
                    <div
                      className='selected-response-cards'
                    />
                  </CategorizingPrintPrompt>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorizingPrint;

CategorizingPrint.defaultProps = {
  instructions: {},
  promptCards: [],
  responseCards: [],
};

CategorizingPrint.propTypes = {
  instructions: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  }),
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
  })),
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
} from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';
import ConfirmationDialog from '../../../../components/dialog/ConfirmationDialog';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { URL_MY_CLASSROOM } from '../../../../routes/RouteConstants';
import { MY_CLASSROOM_ROUTES } from '../../../myclassroom/MyClassRoomConstant';

/**
 * Lesson stepper Exit
 * @param {*} props passed in from parent component
 * @export
 */
export default function LessonStepperExit({ onExitConfirm }) {
  const { lessonDataMaintenanceDomain } = useDomain();

  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => {
    const { goBackTo } = location.state || location;
    if (goBackTo) {
      navigate(goBackTo);
    } else {
      navigate(`${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.AUTHORED_LESSONS}`);
    }
  };

  const handleOnClick = () => {
    const { isDirty } = lessonDataMaintenanceDomain.domainData;
    if (isDirty) {
      setOpenDialog(true);
    } else {
      redirect();
    }
  };

  const dialogProps = {
    open: openDialog,
    title: 'Exit',
    contentText: 'You have unsaved changes, would you like to save them?',
    confirmLabel: 'Yes',
    cancelLabel: 'No',
    onConfirm: async () => {
      await onExitConfirm();
      ObjectUtils.setTimeout(() => {
        redirect();
      }, 2000);
    },
    onCancel: () => {
      redirect();
    },
  };

  return (
    <div>
      <ConfirmationDialog {...dialogProps} className='vizzle-exit-dialog' />
      <Button
        variant='contained'
        fullWidth
        className='btn-primary vizzle-exit-button'
        onClick={handleOnClick}
        data-test='exit-to-home-action'
      >
        Exit to Home
      </Button>
    </div>
  );
}

LessonStepperExit.defaultProps = {
  onExitConfirm: () => { },
};

LessonStepperExit.propTypes = {
  onExitConfirm: PropTypes.func,
};

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './ChartContainer.scss';

const ChartContainer = ({
  title,
  children,
  isDataAvailable,
  className,
  onClick,
}) => (
  // eslint-disable-next-line
  <div className={`chart-container ${className} ${onClick ? 'clickable' : ''}`} onClick={onClick}>
    {title && (<Typography variant='h6'>{title}</Typography>)}
    {isDataAvailable ? (
      children
    ) : (
      <div className='no-data-container'>
        <Typography variant='h5'>No Data Available</Typography>
      </div>
    )}
  </div>
);

ChartContainer.defaultProps = {
  title: '',
  isDataAvailable: true,
  children: '',
  className: '',
  onClick: null,
};

ChartContainer.propTypes = {
  title: PropTypes.string,
  isDataAvailable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChartContainer;

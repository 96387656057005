import { SUBJECT_ID_ELA } from '../../../constants/LessonConstant';
import BaseDomain from '../../../states/domains/BaseDomain';

const MY_ASSIGNMENT_UPDATE_ASSIGNMENT_FILTER_VALUE = 'MY_ASSIGNMENT_UPDATE_ASSIGNMENT_FILTER_VALUE';
const MY_ASSIGNMENT_SELECTED_DATES = 'MY_ASSIGNMENT_SELECTED_DATES';
const MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING = 'MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING';
const MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES = 'MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES';

const initialState = {
  selectedDates: {},
  assignedStudentCoursesLoading: false,
  assignedStudentCourses: [],
  filter: {
    selectedSubject: SUBJECT_ID_ELA,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case MY_ASSIGNMENT_UPDATE_ASSIGNMENT_FILTER_VALUE:
      return {
        ...state,
        filter: {
          ...action.payload,
        },
      };
    case MY_ASSIGNMENT_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload,
      };
    case MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING:
      return {
        ...state,
        assignedStudentCoursesLoading: action.payload,
      };
    case MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        assignedStudentCourses: action.payload.assignedStudentCourses,
        assignedStudentCoursesLoading: action.payload.assignedStudentCoursesLoading,
      };
    default:
      return state;
  }
};

const domainName = 'myAssignmentsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
  * Update assignment filter value
  *
  * @returns Promise
  */
  async updateAssignmentFilterValue(assignmentFilterValue) {
    return this.dispatch({
      type: MY_ASSIGNMENT_UPDATE_ASSIGNMENT_FILTER_VALUE,
      payload: assignmentFilterValue,
    });
  }

  async updateSelectedDates(selectedDates) {
    return this.dispatch({
      type: MY_ASSIGNMENT_SELECTED_DATES,
      payload: selectedDates,
    });
  }

  async updateAssignedStudentsCoursesLoading(loading) {
    return this.dispatch({
      type: MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING,
      payload: loading,
    });
  }

  async updateAssignedStudentsCourses(assignedStudentCourses, assignedStudentCoursesLoading) {
    return this.dispatch({
      type: MY_ASSIGNMENT_UPDATE_ASSIGNED_STUDENT_COURSES,
      payload: {
        assignedStudentCourses,
        assignedStudentCoursesLoading,
      },
    });
  }
}

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ObjectUtils from '../../../../utils/ObjectUtils';
import './ClearLessonSelectionAction.scss';

const selectedNumberClass = 'selected-number-highlighted';

const ClearLessonAction = ({
  selectedLessonIds,
  onClearClick,
  className,
  clearSelectedLessonTitle,
}) => {
  const selectedNumberRef = useRef(null);
  useEffect(() => {
    if (selectedLessonIds.length > 0 && selectedNumberRef.current) {
      selectedNumberRef.current.classList.add(selectedNumberClass);
      ObjectUtils.setTimeout(() => {
        if (selectedNumberRef.current) {
          selectedNumberRef.current.classList.remove(selectedNumberClass);
        }
      }, 1000);
    }
  }, [selectedLessonIds.length]);

  return (
    selectedLessonIds.length > 0 && (
      <div className={`clear-lesson-selection ${className}`}>
        <div className='number-of-selected-lessons'>
          <div className='selected-number' ref={selectedNumberRef}>
            {selectedLessonIds.length}
          </div>
          {selectedLessonIds.length > 1 ? 'Activities' : 'Activity'}&nbsp;
          selected
        </div>
        <Box onClick={onClearClick} className='clear-selected-lessons' data-test='clear-selected-lessons'>
          {clearSelectedLessonTitle}
        </Box>
      </div>
    )
  );
};

ClearLessonAction.defaultProps = {
  selectedLessonIds: [],
  onClearClick: () => {},
  className: '',
  clearSelectedLessonTitle: 'Deselect activities',
};

ClearLessonAction.propTypes = {
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  onClearClick: PropTypes.func,
  className: PropTypes.string,
  clearSelectedLessonTitle: PropTypes.string,
};

export default ClearLessonAction;

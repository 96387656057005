import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import './VizzleSnackbar.scss';
import { Portal } from '@mui/material';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningAmberIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const variantClass = {
  success: 'variant-success',
  warning: 'variant-warning',
  error: 'variant-error',
  info: 'variant-info',
};

/**
 * Vizzle snackbar component
 * @param props
 * message message to display in the snackbar
 * onClose onClose function handler
 * variant type of Snack bar
 *  - success
 *  - error
 * open open or close the snackbar
 * autoCloseIn time to automatically close the snack bar in seconds
 */
export default function VizzleSnackbar(props) {
  const {
    message,
    onClose,
    variant,
    open,
    autoCloseIn,
    ...other
  } = props;

  const Icon = variantIcon[variant];
  const cssClass = variantClass[variant];

  return (
    <Portal container={document.body}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={autoCloseIn}
        onClose={(_e, reason) => {
          if (reason === 'timeout') {
            onClose();
          }
        }}
        className='vizzle-snack-bar-component'
      >
        {open ? (
          <SnackbarContent
            aria-describedby='client-snackbar'
            className={`vizzle-snack-bar-content ${cssClass}`}
            message={
              (
                <span id='client-snackbar' className='vizzle-snack-bar-message'>
                  <Icon />
                  <div className='vizzle-snack-bar-message-text' data-private>{message}</div>
                </span>
              )
            }
            action={[
              <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose} className='vizzle-snackbar-close-button'>
                <CloseIcon />
              </IconButton>,
            ]}
            {...other}
          />
        ) : <span />}

      </Snackbar>
    </Portal>
  );
}

VizzleSnackbar.defaultProps = {
  open: false,
  message: '',
  onClose: () => { },
  variant: null,
  autoCloseIn: 3000,
};

VizzleSnackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  autoCloseIn: PropTypes.number,
};

import {
  Paper,
  Zoom,
} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import './PaperSheetError.scss';

/**
 * Paper sheet to display error message
 *
 * Props:
 * message - message to display
 */
export default function PaperSheetError(props) {
  const {
    message,
    className,
    ...rest
  } = props;

  return (
    <Zoom timeout={500} in>
      <div className={`PaperSheetError ${className}`}>
        <Paper {...rest} className='paper-sheet paper-sheet-error'>
          <Typography component='p'>
            {message}
          </Typography>
        </Paper>
      </div>
    </Zoom>
  );
}

PaperSheetError.defaultProps = {
  className: '',
};

PaperSheetError.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/* eslint-disable no-param-reassign */
import { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import {
  TextField,
  Typography,
} from '@mui/material';
import CardAction from '../../../../../components/card/CardAction';
import { useDomain } from '../../../../../states/AppDomainProvider';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { updateMasteryCriteria } from '../../../../../services/MasteryService';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { UNAUTHORIZED } from '../../../../../AppConstants';
import Logger from '../../../../../utils/Logger';
import {
  IN_PROGRESS_TITLE,
  MASTERED_STATUS_CRITERIA_MET,
  MASTERED_STATUS_IN_PROGRESS,
} from '../../../../../constants/MasterObjectiveConstant';
import { sortObjectiveList } from '../../../../../utils/MasterObjectiveMapping';
import './EditMasterySetAndConsecutiveLessonAction.scss';

/**
 * Edit mastery set and consecutive lesson action container
 */
export default function EditMasterySetAndConsecutiveLessonAction({ objective, rowIndex, moveCardIndexFunc, moveToProgress }) {
  const { attributes, meta } = objective;
  const { myStudentDomain } = useStudentContext();
  const { masterObjectiveData } = myStudentDomain.domainData;
  const { studentsDomain, uiDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const [open, setOpen] = useState(false);
  const [consecutivePlays, setConsecutivePlays] = useState(attributes.consecutivePlays);
  const [percentCorrect, setPercentCorrect] = useState(attributes.percentCorrect);
  const [saving, setSaving] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSaving(false);
    setOpen(false);
  };

  const handleOnConsecutivePlaysChange = (event) => {
    if (!event.target.value
      || (Number(event.target.value) && Number(event.target.value) >= 1 && Number(event.target.value) <= 10)) {
      setConsecutivePlays(event.target.value.replace(/\D/, ''));
    }
  };

  const hanleOnPercentCorrectChange = (event) => {
    if (!event.target.value
      || (Number(event.target.value) && Number(event.target.value) >= 1 && Number(event.target.value) <= 100)) {
      setPercentCorrect(event.target.value.replace(/\D/, ''));
    }
  };

  const saveObjectiveName = async () => {
    if (attributes.consecutivePlays === consecutivePlays
      && attributes.percentCorrect === percentCorrect) {
      // No change, do not have to save.
      handleClose();
      return;
    }
    try {
      setSaving(true);
      await updateMasteryCriteria(currentSelectedStudentId, objective.id, consecutivePlays, percentCorrect);
      attributes.consecutivePlays = consecutivePlays;
      attributes.percentCorrect = percentCorrect;
      attributes.updatedDate = new Date().toISOString();

      if (!meta.archived
        && (meta.masteredRecently || attributes.masteredStatus === MASTERED_STATUS_CRITERIA_MET)) {
        meta.masteredRecently = false;
        attributes.masteredStatus = MASTERED_STATUS_IN_PROGRESS;
        const currentRow = masterObjectiveData.masterObjectives[rowIndex].objectives;
        const updatedCurrentRow = currentRow.filter((c) => c.id !== objective.id);
        masterObjectiveData.masterObjectives[rowIndex].objectives = updatedCurrentRow;

        const progressRowIndex = masterObjectiveData.masterObjectives.findIndex((m) => m.objectiveRowTitle === IN_PROGRESS_TITLE);
        masterObjectiveData.masterObjectives[progressRowIndex].objectives.unshift(objective);
        await uiDomain.showSnackbar(true, 'success', 3000, 'This goal has been moved to In Progress, and mastery calculation will occur after the next activity play');
        moveToProgress(progressRowIndex);
      } else {
        sortObjectiveList(masterObjectiveData.masterObjectives[rowIndex].objectives);
        if (moveCardIndexFunc) {
          moveCardIndexFunc(0);
        }
        await uiDomain.showSnackbar(true, 'success', 3000, 'Mastery criteria has been updated');
      }
      handleClose();

      myStudentDomain.updateMasteryObjectives([...masterObjectiveData.masterObjectives], masterObjectiveData.included);
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
        setSaving(false);
      }
      Logger.logError({
        ERROR: error,
      });
    }
  };

  const content = (
    <div className='content-container'>
      <div className='content'>
        <Typography variant='h6'>Mastery set for</Typography>
        <TextField
          className='input'
          onChange={(event) => { handleOnConsecutivePlaysChange(event); }}
          value={consecutivePlays}
          size='small'
          inputProps={{
            size: 2,
          }}
          variant='outlined'
        />
      </div>
      <div className='content'>
        <Typography variant='h6'>consecutive activities at</Typography>
        <TextField
          className='input'
          onChange={(event) => { hanleOnPercentCorrectChange(event); }}
          value={percentCorrect}
          size='small'
          inputProps={{
            size: 3,
          }}
          variant='outlined'
        />
        <Typography variant='h6'>%</Typography>
      </div>
    </div>
  );

  return (
    <>
      <CardAction
        key='edit-mastery-sets'
        className='edit-mastery-sets-button'
        data-test='edit-mastery-sets-action'
        icon={<EditIcon />}
        actionHandler={() => {
          handleOpen();
        }}
      />
      {open ? (
        <CustomDialog
          key='edit-mastery-sets-custom-dialog'
          className='edit-mastery-sets-custom-dialog'
          content={content}
          openDialog={open}
          onClose={handleClose}
          actions={[
            {
              title: 'OK',
              onClick: saveObjectiveName,
              color: 'primary',
              className: 'edit-mastery-sets-custom-ok-button btn-primary',
              disabled: (!consecutivePlays || !percentCorrect) || saving,
              loading: saving,
            },
          ]}
        />
      ) : null}
    </>
  );
}
EditMasterySetAndConsecutiveLessonAction.defaultProps = {
  objective: {
    attributes: {
      consecutivePlays: '',
      percentCorrect: '',
    },
  },
  moveCardIndexFunc: null,
  moveToProgress: () => {},
};

EditMasterySetAndConsecutiveLessonAction.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    meta: PropTypes.object,
  }),
  rowIndex: PropTypes.number.isRequired,
  moveCardIndexFunc: PropTypes.func,
  moveToProgress: PropTypes.func,
};

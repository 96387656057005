export const DELEMETER = '$$';

export const textAlignToFlex = (textAlign) => {
  if (textAlign === 'left') {
    return 'flex-start';
  }
  if (textAlign === 'right') {
    return 'flex-end';
  }
  return textAlign;
};

export const getTextArray = (text) => {
  const splittedText = text.split(DELEMETER).filter((t) => t && t !== '');
  let previousText = true;
  const result = splittedText.map((t) => {
    const start = text.indexOf(t) - DELEMETER.length;
    if (start < 0) {
      previousText = true;
      return {
        isText: true,
        text: t,
      };
    }
    const isStartWithDelemeter = text.substring(start, start + DELEMETER.length) === DELEMETER;
    const end = text.indexOf(t) + t.length;
    const isEndtWithDelemeter = text.substring(end, end + DELEMETER.length) === DELEMETER;
    if (isStartWithDelemeter && isEndtWithDelemeter && previousText) {
      previousText = false;
      return {
        isText: false,
        text: t,
      };
    }
    previousText = true;
    return {
      isText: true,
      text: t,
    };
  });

  return result;
};

export const adjustMathEquationText = (equationText, text) => {
  let t = equationText
    .replace(/'/gi, ' ')
    .replace(/End square root/gi, ' ')
    .replace(/End cube root/gi, ' ')
    .replace(/End fraction/gi, ' ');
  // .replace(/comma/gi, ' ');

  if (text.includes('^')) {
    t = t
      .replace(/power/gi, '')
      .replace(/to the/gi, ' to the power of ');
  }

  const tempText = t.split(' ').filter((temp) => temp !== '');
  const notEqualIndex = [];
  let notEqualCounter = 0;
  text
    .replace(/=/gi, ' = ')
    .replace(/\\ne/gi, ' \\ne ')
    .split(' ').forEach((textElement) => {
      if (textElement === '\\ne') {
        notEqualIndex.push(notEqualCounter);
        notEqualCounter++;
      } else if (textElement === '=') {
        notEqualCounter++;
      }
    });

  let equalIndex = 0;
  tempText.forEach((tt, index) => {
    const ttTrim = tt.trim();
    if (ttTrim === 'minus') {
      if (index < 1) {
        tempText[index] = 'negative';
      }

      const preceedingText = tempText[index - 1];
      if (['plus', 'minus', 'times', 'over', 'equals', 'of', 'comma', 'to', 'plus or minus', 'by'].includes(preceedingText)) {
        tempText[index] = 'negative';
      } else if (['paren.', 'parenthesis'].includes(preceedingText)) {
        const preceedingText2 = tempText[index - 2];
        const nextText = tempText[index - 1];
        if (!(preceedingText2.toLowerCase() === 'close' && nextText.toLowerCase() === 'paren.')) {
          tempText[index] = 'negative';
        }
      }
    } else if (ttTrim === 'paren.') {
      tempText[index] = 'parenthesis';
    } else if (ttTrim === 'pm') {
      tempText[index] = 'plus or minus';
    } else if (ttTrim === 'equals') {
      if (notEqualIndex.includes(equalIndex)) {
        tempText[index] = 'not equal to';
      }
      equalIndex++;
    }
  });
  const string = tempText.join(' ')
    .replace(/comma/gi, ' ');
  return string;
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const COLORS = ['#1c177a', '#c8e5fc'];
const BACKGROUND_GRADIENT_INTENSITY = 20;

const CampTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    spritesPlay: [],
    tweens: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  const addDemon = () => {
    const demon = new PIXI.AnimatedSprite(app.sheet.animations.demonCloud);
    demon.anchor.set(0.5);
    demon.x = app.floatingIsland.x;
    demon.y = 250;
    demon.animationSpeed = 0.08;
    app.spritesPlay.push(demon);
    demon.play();

    app.floatingIslandContainer.addChild(demon);
    if (innerLessonsCompleted >= 2) {
      const demonLaugh = new PIXI.AnimatedSprite(app.sheet.animations.demonLaugh);
      demonLaugh.x = app.floatingIsland.x - 15;
      demonLaugh.y = 250;
      demonLaugh.animationSpeed = 0.08;
      app.spritesPlay.push(demonLaugh);
      demonLaugh.play();
      app.floatingIslandContainer.addChild(demonLaugh);
    }
  };

  const createBatTween = (bat, rotation) => {
    const batRotation = new TWEEN.Tween(bat)
      .to(
        { rotation },
        1800,
      )
      .repeat(Infinity)
      .easing(TWEEN.Easing.Linear.None);

    batRotation.start();
    app.tweens.push(batRotation);
  };

  const addBats = () => {
    for (let i = 0; i <= 5; i++) {
      const bat = new PIXI.AnimatedSprite(app.sheet.animations.bat);
      bat.y = 170 + 5 * i;
      bat.anchor.set(-1);
      bat.animationSpeed = 0.1;
      bat.play();
      app.spritesPlay.push(bat);

      bat.scale.x = 0.5;
      bat.scale.y = 0.5;
      if (i % 2 === 0) {
        bat.x = app.floatingIsland.x - 10 - 5 * i;
        createBatTween(bat, -6.2);
      } else {
        bat.x = app.floatingIsland.x + 10 + 5 * i;
        createBatTween(bat, 6.2);
      }
      app.floatingIslandContainer.addChild(bat);
    }
  };

  const addWeatherEffects = () => {
    const weather1 = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    const weather2 = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    weather1.anchor.set(0.5);
    weather2.anchor.set(0.5);

    weather1.x = app.floatingIsland.x + 1250;
    weather1.y = app.floatingIsland.y - 420;
    weather2.x = app.floatingIsland.x - 800;
    weather2.y = app.floatingIsland.y + 420;
    weather1.scale.x = 2.5;
    weather1.scale.y = 2.5;
    weather2.scale.x = 2.5;
    weather2.scale.y = 2.5;
    app.floatingIslandContainer.addChild(weather1, weather2);

    const weather1Tween = new TWEEN.Tween(weather1)
      .to({ rotation: -3 }, 20000)
      .easing(TWEEN.Easing.Linear.None)
      .repeat(Infinity);

    const weather2Tween = new TWEEN.Tween(weather2)
      .to({ rotation: 3 }, 20000)
      .easing(TWEEN.Easing.Linear.None)
      .repeat(Infinity);

    app.tweens.push(weather1Tween);
    app.tweens.push(weather2Tween);
    weather1Tween.start();
    weather2Tween.start();
  };

  const addSprites = () => {
    addWeatherEffects();
    addDemon();

    const mountains = new PIXI.AnimatedSprite(app.sheet.animations.mountains);
    const mountainBounds = mountains.getBounds();
    mountains.anchor.set(0.5);
    mountains.x = app.floatingIsland.x + 10;
    mountains.y = app.floatingIsland.y - mountainBounds.height - 70;
    app.floatingIslandContainer.addChild(mountains);

    addBats();

    app.bear = new PIXI.AnimatedSprite(app.sheet.animations.walkingbear);
    app.bear.anchor.set(0.5);
    app.bear.animationSpeed = 0.1;
    app.bear.play();
    app.spritesPlay.push(app.bear);
    app.floatingIslandContainer.addChild(app.bear);

    app.waterfallmountain = new PIXI.AnimatedSprite(
      app.sheet.animations.waterfallmountains,
    );
    app.waterfallmountain.x = mountains.x - mountainBounds.width / 2;
    app.waterfallmountain.y = mountains.y;
    app.floatingIslandContainer.addChild(app.waterfallmountain);

    const waterfall = new PIXI.AnimatedSprite(app.sheet.animations.waterfall);
    const waterfallBounds = waterfall.getBounds();
    waterfall.anchor.set(0.5);
    waterfall.x = mountainBounds.width / 2 + 30;
    waterfall.y = mountainBounds.height / 2 + 210;
    waterfall.animationSpeed = 0.1;
    waterfall.play();
    app.spritesPlay.push(waterfall);
    app.floatingIslandContainer.addChild(waterfall);

    app.lake = new PIXI.AnimatedSprite(app.sheet.animations.lake);
    const lakeBounds = app.lake.getBounds();
    app.lake.x = waterfall.x - Math.min(37, waterfallBounds.width) - 70;
    app.lake.y = waterfall.y + Math.min(132, waterfallBounds.height) / 2 - 5;
    app.floatingIslandContainer.addChild(app.lake);

    const water = new PIXI.AnimatedSprite(app.sheet.animations.water);
    water.x = app.lake.x + lakeBounds.width - 25;
    water.y = app.lake.y + lakeBounds.height - 9;
    water.animationSpeed = 0.1;
    water.play();
    app.spritesPlay.push(water);
    app.floatingIslandContainer.addChild(water);

    const forest = new PIXI.AnimatedSprite(app.sheet.animations.forestry2);
    forest.x = waterfall.x + 30;
    forest.y = waterfall.y - 20;
    app.floatingIslandContainer.addChild(forest);

    let shelterChoice = 'tent';
    if (innerLessonsCompleted === 2) {
      shelterChoice = 'small';
    } else if (innerLessonsCompleted >= 3) {
      shelterChoice = 'large';
    }
    const shelter = new PIXI.AnimatedSprite(
      app.sheet.animations[`cabin_${shelterChoice}`],
    );
    const shelterBounds = shelter.getBounds();
    shelter.x = forest.x + 30;
    shelter.y = forest.y + 25;
    app.floatingIslandContainer.addChild(shelter);

    if (shelterChoice === 'tent') {
      const fire = new PIXI.AnimatedSprite(app.sheet.animations.fire);
      const fireBounds = fire.getBounds();
      fire.x = shelter.x + shelterBounds.width / 4;
      fire.y = shelter.y + shelterBounds.height / 2 + 10;
      fire.animationSpeed = 0.1;
      fire.play();
      app.spritesPlay.push(fire);
      app.floatingIslandContainer.addChild(fire);

      const smoke = new PIXI.AnimatedSprite(app.sheet.animations.smoke);
      smoke.anchor.set(0.5);
      smoke.x = fire.x + fireBounds.width + 20;
      smoke.y = fire.y - fireBounds.height - 30;
      smoke.animationSpeed = 0.1;
      smoke.play();
      app.spritesPlay.push(smoke);
      app.floatingIslandContainer.addChild(smoke);
    } else if (shelterChoice === 'large' || shelterChoice === 'small') {
      const smoke = new PIXI.AnimatedSprite(app.sheet.animations.smoke);
      smoke.anchor.set(0.5, 1);
      smoke.x = shelter.x + shelterBounds.width / 2 + 55;
      smoke.y = shelter.y + 10;
      smoke.animationSpeed = 0.1;
      smoke.play();
      app.spritesPlay.push(smoke);
      app.floatingIslandContainer.addChild(smoke);
    }

    app.splash = new PIXI.AnimatedSprite(app.sheet.animations.splash);
    app.splash.x = app.lake.x + 40;
    app.splash.y = app.lake.y - 65;
    app.splash.alpha = 0;
    app.floatingIslandContainer.addChild(app.splash);

    const tree = new PIXI.AnimatedSprite(app.sheet.animations.forestry);
    tree.anchor.set(1, 0);
    tree.x = waterfall.x - 60;
    tree.y = waterfall.y - 25;
    app.floatingIslandContainer.addChild(tree);
  };

  const animateBear = () => {
    app.bear.scale.x = 0.5;
    app.bear.scale.y = 0.5;
    app.bear.x = 500;
    app.bear.y = app.waterfallmountain.y + 35;

    const bearWalkBackTween = new TWEEN.Tween(app.bear)
      .to({ x: app.bear.x, y: app.bear.y }, 8000)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        app.bear.scale.x = 0.5;
        app.bearTween.start();
      });

    const bearWalkDownTween = new TWEEN.Tween(app.bear)
      .to(
        { rotation: 0, x: app.bear.x + 100, y: app.waterfallmountain.y + 35 },
        12000,
      )
      .easing(TWEEN.Easing.Linear.None)
      .chain(bearWalkBackTween);

    const bearWalkUpTween = new TWEEN.Tween(app.bear)
      .to({ rotation: -0.1, x: app.bear.x + 270, y: app.bear.y - 25 }, 12000)
      .easing(TWEEN.Easing.Linear.None)
      .chain(bearWalkDownTween)
      .onComplete(() => {
        app.bear.scale.x = -0.5;
      });

    app.bearTween = new TWEEN.Tween(app.bear);
    app.tweens.push(app.bearTween);
    app.bearTween.to({ x: app.bear.x + 100 }, 8000);
    app.bearTween.easing(TWEEN.Easing.Linear.None);
    app.bearTween.chain(bearWalkUpTween);
    app.bearTween.start();
  };

  const bearMountain = () => {
    app.bear.scale.y = 1;
    app.bear.scale.x = -1;
    app.bear.x = 500;
    app.bear.y = app.waterfallmountain.y + 35;

    const bearWalkDownTween = new TWEEN.Tween(app.bear)
      .to({ rotation: 0.2, x: app.bear.x - 15, y: app.bear.y }, 8000)
      .easing(TWEEN.Easing.Linear.None)
      .onStart(() => {
        app.bear.scale.x = 1;
      })
      .onComplete(() => {
        app.bear.scale.x = -1;
        app.timer = setTimeout(() => {
          clearTimeout(app.timer);
          app.bearTween.start();
        }, 2000);
      });

    app.bearTween = new TWEEN.Tween(app.bear);
    app.tweens.push(app.bearTween);
    app.bearTween.to({ x: app.bear.x - 110, y: app.bear.y - 40 }, 8000);
    app.bearTween.easing(TWEEN.Easing.Linear.None);
    app.bearTween.chain(bearWalkDownTween);
    app.bearTween.start();
  };

  const bearWaterFall = () => {
    app.bear.scale.y = 1;
    app.bear.scale.x = -1;
    app.bear.x = 500;
    app.bear.y = app.waterfallmountain.y + 35;

    const topWaterfallX = app.bear.x - 110;
    const topWaterfallY = app.bear.y - 40;

    const fallingbear = new PIXI.AnimatedSprite(
      app.sheet.animations.fallingbear,
    );
    fallingbear.anchor.set(0.5, 0);
    fallingbear.scale.x = -1;
    fallingbear.x = topWaterfallX;
    fallingbear.y = topWaterfallY - 20;
    fallingbear.animationSpeed = 0.1;
    app.spritesPlay.push(fallingbear);

    const waterbear = new PIXI.AnimatedSprite(app.sheet.animations.waterbear);
    waterbear.anchor.set(0.5, 1);
    waterbear.x = topWaterfallX;
    waterbear.y = app.lake.y + waterbear.getBounds().height / 2;
    waterbear.alpha = 0;
    waterbear.animationSpeed = 0.05;
    app.spritesPlay.push(waterbear);

    const waterbearMoveTween = new TWEEN.Tween(waterbear)
      .to({ x: topWaterfallX - 130 }, 5000)
      .delay(500)
      .onComplete(() => {
        app.bear.x = 500;
        app.bear.y = app.waterfallmountain.y + 35;
        app.floatingIslandContainer.removeChild(waterbear);
        waterbear.x = topWaterfallX;
        waterbear.y = app.lake.y + waterbear.getBounds().height / 2;
        app.timer = setTimeout(() => {
          clearTimeout(app.timer);
          app.bear.alpha = 1;
          app.bearTween.start();
        }, 2000);
      });
    app.tweens.push(waterbearMoveTween);

    const waterbearTween = new TWEEN.Tween(waterbear)
      .to({ alpha: 1 }, 1000)
      .onStart(() => {
        app.floatingIslandContainer.addChildAt(
          waterbear,
          app.floatingIslandContainer.children.length - 2,
        );
        waterbear.play();
        waterbearMoveTween.start();
      });
    app.tweens.push(waterbearTween);

    const splashCompleteTween = new TWEEN.Tween(app.splash).to(
      { alpha: 0 },
      1500,
    );

    const splashTween = new TWEEN.Tween(app.splash)
      .to({ alpha: 1 }, 1500)
      .onStart(() => {
        app.floatingIslandContainer.removeChild(fallingbear);
      })
      .onComplete(() => {
        waterbearTween.start();
      })
      .chain(splashCompleteTween);

    const bearFallingTween = new TWEEN.Tween(fallingbear);
    bearFallingTween.to({ y: topWaterfallY + 110 }, 1000);
    bearFallingTween.easing(TWEEN.Easing.Linear.None);
    bearFallingTween.onStart(() => {
      app.bear.alpha = 0;
      app.floatingIslandContainer.addChild(fallingbear);
      fallingbear.play();
    });
    bearFallingTween.chain(splashTween);

    app.bearTween = new TWEEN.Tween(app.bear);
    app.tweens.push(app.bearTween);
    app.bearTween.to({ x: topWaterfallX, y: topWaterfallY }, 8000);
    app.bearTween.easing(TWEEN.Easing.Linear.None);
    app.bearTween.chain(bearFallingTween);
    app.bearTween.start();
  };

  const chooseRandomAnimation = () => {
    if (innerLessonsCompleted >= 4) {
      if (innerLessonsCompleted % 4 === 0) {
        bearMountain();
      } else if (innerLessonsCompleted % 2 === 0) {
        animateBear();
      } else if (innerLessonsCompleted % 3 === 0) {
        bearWaterFall();
      } else {
        animateBear();
      }
    } else if (innerLessonsCompleted === 3) {
      bearWaterFall();
    } else if (innerLessonsCompleted === 2) {
      bearMountain();
    } else if (innerLessonsCompleted <= 1) {
      animateBear();
    }
  };

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas } = ThemeUtil.initCanvasWithBackground(
        canvas,
        COLORS,
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick);
      const progressBar = {
        barBackground: 'progressbar_background_forest',
        progressBar: 'progressbar_honey',
        progressAvatar: 'bear',
        dotType: 'circle',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );
      ThemeUtil.addFloatableObjects(app, 5000, false);
      addSprites();
      chooseRandomAnimation();
    }

    return () => {
      ThemeUtil.stopFloatableObjects(app);
      if (app.timer) {
        clearTimeout(app.timer);
      }
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

CampTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

CampTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default CampTheme;

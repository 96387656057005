import { uploadMediaFiles } from '../../../../../../../services/MediaService';
import ExtensionActivitiesService from '../../../../../../../services/ExtensionActivitiesService';
import Logger from '../../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../../../context/TeacherCourseContextProvider';
import { useDomain } from '../../../../../../../states/AppDomainProvider';

export const useHandleFilesUpload = () => {
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const { teacherCourseSearchCriteriaDomain, extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities, selectedActivityIds } = extensionActivitiesDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { topicId } = searchCriteria;

  const handleFilesUpload = async (files) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const fileList = Array.from(files).map((file) => file);
        const uploadedResult = await uploadMediaFiles(fileList);

        const fileNameList = uploadedResult.map((uploadFile) => ({
          name: uploadFile.attributes.fileName,
          url: uploadFile.attributes.s3Url,
        }));

        const activities = {
          activities: fileNameList,
        };
        const createdActivitiesResult = await ExtensionActivitiesService.createExtensionActivities(topicId, activities);
        const { activities: currentActivities } = extensionActivities;
        const ids = currentActivities.map((a) => a.id);
        const filteredCreatedActivitiesResult = createdActivitiesResult.filter((cr) => !ids.includes(cr.id));
        await extensionActivitiesDomain.updateExtensionActivitiesDataAndSelectedId(
          [
            ...filteredCreatedActivitiesResult,
            ...currentActivities,
          ],
          selectedActivityIds,
        );
        const message = `Successfully upload ${fileNameList.length} file${fileNameList.length > 1 ? 's' : ''}`;
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return { handleFilesUpload };
};

export const useHandleFileSelection = () => {
  const { extensionActivitiesDomain } = useTeacherCourses();
  const { selectedActivityIds } = extensionActivitiesDomain.domainData;

  const handleFileSelection = (activity) => {
    const selectedId = activity.id;
    let updatedSelectedIds = [...selectedActivityIds];
    if (selectedActivityIds.includes(selectedId)) {
      updatedSelectedIds = updatedSelectedIds.filter((s) => s !== selectedId);
    } else {
      updatedSelectedIds.push(selectedId);
    }

    extensionActivitiesDomain.updateSelectedExtensionActivityIds(updatedSelectedIds);
  };

  return { handleFileSelection };
};

export const useDeleteFiles = () => {
  const { uiDomain } = useDomain();
  const { teacherCourseSearchCriteriaDomain, extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities, selectedActivityIds } = extensionActivitiesDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { topicId } = searchCriteria;

  const disabled = !selectedActivityIds || selectedActivityIds.length === 0 || loading;
  const areaLabel = `Delete ${selectedActivityIds.length} file${selectedActivityIds.length > 1 ? 's' : ''}`;

  const onConfirm = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const activityList = selectedActivityIds.map((s) => ({ id: s }));
        const activitiesObject = {
          activities: activityList,
        };
        await ExtensionActivitiesService.deleteExtensionActivities(topicId, activitiesObject);
        const message = `Successfully delete ${selectedActivityIds.length} file${selectedActivityIds.length > 1 ? 's' : ''}`;

        const newSelectedActivityIds = selectedActivityIds.filter((s) => !selectedActivityIds.includes(s));
        const newActivities = extensionActivities.activities.filter((a) => !selectedActivityIds.includes(a.id));

        extensionActivitiesDomain.updateExtensionActivitiesDataAndSelectedId(newActivities, newSelectedActivityIds);
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    disabled,
    areaLabel,
    onConfirm,
    isDeleting: loading,
  };
};

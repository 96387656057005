import { GradeSort } from '../constants/GradeConstants';
import { sort, unique } from '../utils/ArrayUtils';

/**
 * Get grades by subject id
 *
 * @param {*} subjectId subject id
 * @param {*} lessonStandards list of standards
 */
export const getGradesBySubjectId = (subjectId, lessonStandards) => {
  const filteredStandards = lessonStandards.filter((standard) => (
    standard.type === 'standards' && standard.attributes.subject === subjectId
  ));
  const grades = filteredStandards.map((standard) => ({
    id: standard.attributes.standardGrade.trim(),
    name: standard.attributes.standardGrade.trim(),
    value: standard.attributes.standardGrade.trim(),
  }));

  const uniqueGrades = unique(grades, 'id') || [];
  const primaryList = [];
  const secondaryList = [];

  uniqueGrades.forEach((option) => {
    if (GradeSort.includes(option.id)) {
      primaryList.push(option);
    } else {
      secondaryList.push(option);
    }
  });

  const sortedPrimaryList = [];
  GradeSort.forEach((g) => {
    const matchedElement = primaryList.find((p) => p.id === g);
    if (matchedElement) {
      sortedPrimaryList.push(matchedElement);
    }
  });

  return [
    ...sortedPrimaryList,
    ...sort(secondaryList, 'id'),
  ];

  // return uniqueGrades;
};

export default getGradesBySubjectId;

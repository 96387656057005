import vizzleAxios from './service';
import { WRITING_SUBJECT } from '../constants/LessonConstant';
import ObjectUtils from '../utils/ObjectUtils';

const PDF_GUIDE_TEXAS = 'curriculum_field_guides_9_weeks/';
const PDF_GUIDE_FLORIDA = 'FL_Curriculum_Field_Guides/';
const PDF_SCOPE_AND_SEQUENCE = 'Scope and Sequence 9 Weeks/';

const FIELD_GUIDE_MAP = {};
// eslint-disable-next-line dot-notation
FIELD_GUIDE_MAP['K'] = 'Kindergarten';
FIELD_GUIDE_MAP['1'] = '1st Grade';
FIELD_GUIDE_MAP['2'] = '2nd Grade';
FIELD_GUIDE_MAP['3'] = '3rd Grade';
FIELD_GUIDE_MAP['4'] = '4th Grade';
FIELD_GUIDE_MAP['5'] = '5th Grade';
FIELD_GUIDE_MAP['6'] = '6th Grade';
FIELD_GUIDE_MAP['7'] = '7th Grade';
FIELD_GUIDE_MAP['8'] = '8th Grade';
FIELD_GUIDE_MAP['6-8'] = '6-8th Grade';
FIELD_GUIDE_MAP['High School'] = 'High School';

const GRADE_BUCKET = {};
// eslint-disable-next-line dot-notation
GRADE_BUCKET['K'] = 'K-2';
GRADE_BUCKET['1'] = 'K-2';
GRADE_BUCKET['2'] = 'K-2';
GRADE_BUCKET['3'] = '3-5';
GRADE_BUCKET['4'] = '3-5';
GRADE_BUCKET['5'] = '3-5';
GRADE_BUCKET['6'] = '6-8';
GRADE_BUCKET['7'] = '6-8';
GRADE_BUCKET['8'] = '6-8';
GRADE_BUCKET['6-8'] = '6-8';
GRADE_BUCKET['9'] = 'High School';
GRADE_BUCKET['10'] = 'High School';
GRADE_BUCKET['11'] = 'High School';

const getNth = (num) => {
  switch (num) {
    case '1':
    case 1:
      return 'st';
    case '2':
    case 2:
      return 'nd';
    case '3':
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getGuidePdfLinkForTexasUser = (subject, grade, unit) => {
  let finalUrl = `${PDF_GUIDE_TEXAS}Grade_${grade}/`;
  if (subject === WRITING_SUBJECT.name) {
    const nth = getNth(grade);
    finalUrl = `${finalUrl}${grade}${nth} Grade Writing Instructional Guide.pdf`;
  } else {
    const nth = getNth(unit);
    finalUrl = `${finalUrl}${unit}${nth} NW/${FIELD_GUIDE_MAP[grade]} ${subject} Instructional Guide.pdf`;
  }
  finalUrl = finalUrl.replace(/\s/g, '+');
  return finalUrl;
};

export const getGuidePdfLinkForFloridaUser = (subject, grade, unit) => {
  let finalUrl = `${PDF_GUIDE_FLORIDA}Grade_${grade}/`;
  if (grade === '6-8') {
    const quater = `${unit}${getNth(unit)} Quarter`;
    finalUrl = `${finalUrl}${quater}/`;
  }
  finalUrl = `${finalUrl}${FIELD_GUIDE_MAP[grade]} ${subject} Instructional Guide.pdf`;
  return finalUrl;
};

export const getScopeAndSequenceLink = (subject, grade, unit) => {
  let finalUrl = PDF_SCOPE_AND_SEQUENCE;
  let gradeBand = '';

  if (grade === 'K' || grade === '1' || grade === '2') {
    finalUrl += 'A_ K-2/';
    gradeBand = 'K-2';
  } else if (grade === '3' || grade === '4' || grade === '5') {
    finalUrl += 'B_ 3-5/';
    gradeBand = '3-5';
  } else if (grade === '6' || grade === '7' || grade === '8') {
    finalUrl += 'C_ Middle School/';
    gradeBand = '6-8';
  } else {
    finalUrl += 'D_ High School/';
    gradeBand = 'High School';
  }

  const nth = getNth(unit);
  finalUrl = `${finalUrl}${unit}${nth} NW/${gradeBand} ${subject} Scope and Sequence ${unit}${nth} NW.pdf`;
  finalUrl = finalUrl.replace(/\s/g, '+');
  return finalUrl;
};

export const getLessonPlans = async (subjectName, gradeId) => {
  const prefix = `FL_Lesson_Plans/${subjectName}/${GRADE_BUCKET[gradeId]}`;
  const url = `${process.env.REACT_APP_MEDIA_API_URL}/media/list?prefix=${prefix}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const searchCurriculumLessons = async (searchNode, { curriculumProvider, subject, grade, unit, standards }, filterCriteria) => {
  let url = `/curriculums/lessons?filter[curriculumProvider]=${curriculumProvider}&filter[subjectId]=${subject.key}&filter[gradeLevel]=${grade.key}`;
  if (!subject.noUnits && unit) {
    // Lubock Writing is a special case where the Unit is for the whole year
    url = `${url}&filter[unit]=${unit.key}`;
  }
  if (standards) {
    url = ObjectUtils.appendQueryString(standards.map((s) => encodeURIComponent(s.value)).join(','), 'filter[standards]', url);
  }

  if (filterCriteria) {
    const { standardCode, essenceStatement, standardLessonLevel } = filterCriteria;
    url = ObjectUtils.appendQueryString(standardLessonLevel, 'filter[standardLessonLevel]', url);
    url = ObjectUtils.appendQueryString(essenceStatement, 'filter[essenceStatement]', url);
    url = ObjectUtils.appendQueryString(standardCode, 'filter[standardCode]', url);
  }
  url = `${url}&searchNode=${searchNode}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import './PermissionForm.scss';

const PermissionForm = ({
  permissionData,
  onDataChange,
}) => {
  const [internalData, setInternalData] = useState({
    ...permissionData,
  });

  const handleOnChange = (event) => {
    const updatedData = {
      ...internalData,
      [event.target.name]: event.target.checked,
    };
    setInternalData(updatedData);
    onDataChange(updatedData);
  };

  return (
    <div className='admin-permission-form'>
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminDataAccess}
            onChange={handleOnChange}
            name='adminDataAccess'
            className='permission-checkbox'
          />
        )}
        label='Data Tab Access'
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminSchoolsAccess}
            onChange={handleOnChange}
            name='adminSchoolsAccess'
            className='permission-checkbox'
          />
        )}
        label='Schools Tab Access'
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminTeachersAccess}
            onChange={handleOnChange}
            name='adminTeachersAccess'
            className='permission-checkbox'
          />
        )}
        label='Teachers Tab Access'
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminStudentsAccess}
            onChange={handleOnChange}
            name='adminStudentsAccess'
            className='permission-checkbox'
          />
        )}
        label='Students Tab Access'
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminSearchAccess}
            onChange={handleOnChange}
            name='adminSearchAccess'
            className='permission-checkbox'
          />
        )}
        label='Search Tab Access'
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={internalData.adminAddUsersAccess}
            onChange={handleOnChange}
            name='adminAddUsersAccess'
            className='permission-checkbox'
          />
        )}
        label='Add Users Access'
      />
    </div>
  );
};

PermissionForm.defaultProps = {
  permissionData: {
    adminDataAccess: false,
    adminSchoolsAccess: false,
    adminTeachersAccess: false,
    adminStudentsAccess: false,
    adminSearchAccess: false,
    adminAddUsersAccess: false,
  },
  onDataChange: () => null,
};

PermissionForm.propTypes = {
  permissionData: PropTypes.shape({
    adminDataAccess: PropTypes.bool,
    adminSchoolsAccess: PropTypes.bool,
    adminTeachersAccess: PropTypes.bool,
    adminStudentsAccess: PropTypes.bool,
    adminSearchAccess: PropTypes.bool,
    adminAddUsersAccess: PropTypes.bool,
  }),
  onDataChange: PropTypes.func,
};

export default PermissionForm;

import { useEffect, useState } from 'react';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { filterByKeyword } from '../../../../../../utils/LessonUtils';
import { FILTER_STATUS_FOR_COPY } from '../../../../../myclassroom/components/authored-lessons/AuthoredLessonHooks';
import { defaultSelectedFilterStatus } from '../copy-lesson-multiple-page/CopyLessonMultiplePagesActionActionHooks';

export const useHandleFilter = ({
  loadSearchLessonsData,
}) => {
  const { authoredLessonDomain } = useDomain();
  const [filterValue, setFilterValue] = useState({
    filteredText: '',
    pendingFilterValue: defaultSelectedFilterStatus,
  });

  const handleOnFilterUpdate = (value) => {
    setFilterValue(value);
    if ([FILTER_STATUS_FOR_COPY[2].value].includes(value.pendingFilterValue)) {
      authoredLessonDomain.updateSearchLessonsData({});
    }
  };

  useEffect(() => {
    loadSearchLessonsData(filterValue.pendingFilterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.pendingFilterValue]);

  const handleOnSearchFormSubmit = (searchCriteira) => {
    loadSearchLessonsData(filterValue.pendingFilterValue, searchCriteira);
  };

  return {
    filterValue,
    handleOnFilterUpdate,
    handleOnSearchFormSubmit,
  };
};

export const getFilterdLessons = ({
  lessons,
  filterValue,
}) => {
  if (!lessons) {
    return [];
  }
  const result = [];
  lessons.forEach((al) => {
    const subjectRow = { ...al };
    if (filterValue.filteredText && filterValue.filteredText.trim().length > 0) {
      subjectRow.lessons = subjectRow.lessons.filter((l) => (
        filterByKeyword(filterValue.filteredText, l)
      )) || [];
    }

    result.push(subjectRow);
  });
  return result;
};

import { useState } from 'react';
import { Slide } from '@mui/material';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import Logger from '../../../utils/Logger';
import { generateMfa } from '../../../services/MfaService';
import './SetupTwoFactorAuthentication.scss';
import { logout } from '../../../services/LoginService';

const SetupTwoFactorAuthentication = () => {
  const [step, setStep] = useState({
    stepNum: 1,
  });
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnGenerateClick = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const imageData = await generateMfa();
        setStep({
          stepNum: 2,
          img: window.URL.createObjectURL(imageData),
        });
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return (
    <Slide
      direction='left'
      timeout={600}
      in
      mountOnEnter
      unmountOnExit
    >
      <div className='setup-two-factor-authentication'>
        <div className='vizzle-logo-container'>
          <img src={VizzleLogo} alt='Vizzle Logo' />
        </div>
        <div className='title'>
          Setup Two Factor Authentication
        </div>
        <div className='content'>
          {
            step.stepNum === 1 && (
              <div className='step-1'>
                <div>
                  <p>You currently do not have two factor authentication setup.</p>
                  <p>Please click the “Generate” button below to start the setup</p>
                </div>
                <div className='actions'>
                  <ButtonWithSpinner
                    className='btn-primary'
                    label='Generate'
                    fullWidth
                    loading={loading}
                    onClick={handleOnGenerateClick}
                  />
                </div>
              </div>
            )
          }
          {
            step.stepNum === 2 && (
              <div className='step-2'>
                <div>
                  <p>Please use the google authenticator app on your phone to</p>
                  <p>scan the QR code below to finish the two factor authentication setup</p>
                </div>
                <div className='qr-code'>
                  <img
                    src={step.img}
                    alt=''
                  />
                </div>
                <div className='actions'>
                  <ButtonWithSpinner
                    className='btn-primary'
                    label='GO TO LOGIN PAGE'
                    fullWidth
                    loading={loading}
                    onClick={() => {
                      logout(false);
                    }}
                  />
                </div>
              </div>
            )
          }

        </div>

      </div>
    </Slide>
  );
};

export default SetupTwoFactorAuthentication;

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Button,
  Card,
  CardActionArea,
} from '@mui/material';
import FileUploadPlaceholder from './FileUploadPlaceholder';
import './AudioUploader.scss';

const oneMB = 1048576;

const placeHolderInitState = (maxFileSize) => ({
  title: 'Drag and drop an audio file or use the above buttons',
  subTitle: `File size limit is ${maxFileSize} MB.`,
  error: '',
});

export default function AudioUploader({
  onSelect,
  onRemove,
  loading,
  fileAccept,
  maxFileSize,
  className,
  disabledRemove,
  setBrowseAudioRef,
  audioUrl,
}) {
  const fileSize = maxFileSize * oneMB;

  const uploaderRef = useRef(null);
  const audioRef = useRef(null);
  const [placeholderMessages, setPlaceholderMessages] = useState(placeHolderInitState(maxFileSize));

  const displayError = (errorMessage) => {
    setPlaceholderMessages({
      ...placeHolderInitState(maxFileSize),
      error: errorMessage,
    });
  };

  const handleSelectedFiles = async (files) => {
    if (!files || files.length === 0) {
      displayError('The selected file type is not supported.');
      return;
    }

    const file = files[0];
    if (file.size > fileSize) {
      displayError(`File size cannot exceed ${maxFileSize} MB`);
      return;
    }
    setPlaceholderMessages(placeHolderInitState(maxFileSize));
    await onSelect(file);
  };

  useEffect(() => {
    if (uploaderRef.current) {
      setBrowseAudioRef(uploaderRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaderRef]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  return (
    <div className={`audio-uploader-component ${className}`}>
      <div className='audio-uploader-drop-area'>
        <Dropzone
          disabled={loading}
          onDrop={handleSelectedFiles}
          ref={uploaderRef}
          multiple={false}
          accept={fileAccept}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <Card
              className='audio-uploader-drop-area-card'
              {...getRootProps()}
              data-test='audio-uploader-drop-area'
            >
              <input {...getInputProps()} />
              <CardActionArea
                disabled={loading}
                className='audio-uploader-drop-area-card-action'
              >
                <div className={isDragActive ? 'audio-uploader-drag-active' : ''} />
                <FileUploadPlaceholder {...placeholderMessages} />
              </CardActionArea>
            </Card>
          )}

        </Dropzone>
        <div className='audio-uploader-audio-player'>
          <audio controls src={audioUrl} ref={audioRef} data-test='audio-player'>
            <track kind='captions' />
          </audio>
        </div>
      </div>
      <div className='audio-uploader-actions'>
        <Button
          variant='contained'
          className='btn-primary action-button vizzle-audio-uploader-remove'
          onClick={onRemove}
          label='Remove'
          disabled={disabledRemove}
          data-test='remove-audio-action'
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

AudioUploader.defaultProps = {
  onSelect: () => { },
  onRemove: () => { },
  setBrowseAudioRef: () => { },
  loading: false,
  fileAccept: '.mp3',
  maxFileSize: 50,
  className: '',
  disabledRemove: false,
  audioUrl: null,
};

AudioUploader.propTypes = {
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  loading: PropTypes.bool,
  fileAccept: PropTypes.string,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  disabledRemove: PropTypes.bool,
  setBrowseAudioRef: PropTypes.func,
  audioUrl: PropTypes.string,
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Slide,
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import PaperSheetError from '../../../components/paper/PaperSheetError';
import LoginWithCleverButton from './clever/LoginWithCleverButton';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import './LoginForm.scss';
import Logger from '../../../utils/Logger';

/**
 * Login form component for user login.
 *
 * Props
 *
 * onSubmit: function handler when the form is submitted
 * onValidate: function handler. This will be called when an even onChange is trigged.
 * loginSuccess: flag indicating the login is successful
 * loading: Whether the button is showing the spinning icon
 * errorMessage: error message
 */
export default function LoginForm({
  onSubmit,
  onValidate,
  loginSuccess,
  loading,
  errorMessage,
  userLoginData,
  forgotPasswordComponent,
  loginClever,
  children,
}) {
  const [state, setState] = useState({
    data: {
      username: '',
      password: '',
      rememberMe: false,
    },
    ui: {
      isSubmitButtonDisabled: true,
      show: true,
      direction: 'left',
    },
  });

  if (loginSuccess) {
    setState({
      ...state,
      ui: {
        ...state.ui,
        direction: 'right',
        show: false,
      },
    });
  }

  useEffect(() => {
    if (userLoginData) {
      const shouldDisable = !userLoginData.username || !userLoginData.password;
      setState({
        ...state,
        data: {
          ...userLoginData,
        },
        ui: {
          ...state.ui,
          isSubmitButtonDisabled: shouldDisable,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginData]);

  const handleChange = (prop) => (event) => {
    const isValid = onValidate(prop, event.target.value, state.data);
    const isSubmitButtonDisabled = !isValid;

    setState({
      ...state,
      data: {
        ...state.data,
        [prop]: event.target.value,
      },
      ui: {
        ...state.ui,
        isSubmitButtonDisabled,
      },
    });
  };

  const handleCheckboxChange = (name) => (event) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: event.target.checked,
      },
    });
  };

  const handleSubmit = () => {
    try {
      onSubmit(state.data);
    } catch (error) {
      Logger.logError(error);
    }
  };

  const handleError = (errors) => {
    Logger.logWhenDebugModeIsOn(errors);
  };

  return (
    <Slide
      direction={state.ui.direction}
      timeout={600}
      in={state.ui.show}
      mountOnEnter
      unmountOnExit
    >
      <div className='LoginForm'>
        <img src={VizzleLogo} alt='Vizzle Logo' />

        <ValidatorForm
          onSubmit={handleSubmit}
          onError={handleError}
          className='login-form'
        >
          <div className='login-form-wrapper'>
            <div className='row'>
              <PersonIcon className='icon-primary form-icon' />
              <TextValidator
                label='Username'
                className='form-input'
                onChange={handleChange('username')}
                onBlur={handleChange('username')}
                autoFocus
                name='username'
                value={state.data.username || ''}
                validators={['required']}
                errorMessages={['Username is required.']}
                variant='standard'
                data-test='username'
                inputProps={{
                  'data-private': 'redact',
                }}
              />
            </div>
            <div className='row'>
              <LockIcon className='icon-primary form-icon' />
              <TextValidator
                label='Password'
                className='form-input'
                type='password'
                onChange={handleChange('password')}
                onBlur={handleChange('password')}
                name='password'
                value={state.data.password || ''}
                validators={['required']}
                errorMessages={['Username is required.']}
                inputProps={{ autoComplete: 'off' }}
                variant='standard'
                data-test='password'
              />
            </div>
            <ButtonWithSpinner
              label='Login'
              loading={loading}
              disabled={state.ui.isSubmitButtonDisabled}
              variant='contained'
              type='submit'
              className='login-button'
              data-test='login-button'
            />
            <div className='remember-me-container'>
              <FormControlLabel
                className='login-form-remember-me'
                control={(
                  <Checkbox
                    checked={state.data.rememberMe}
                    onChange={handleCheckboxChange('rememberMe')}
                    value='rememberMe'
                    color='primary'
                  />
                )}
                label='Remember Me?'
                data-test='remember-me-checkbox'
              />
              <span className='forgot-password'>
                {forgotPasswordComponent}
              </span>
            </div>
            {loginClever && (
              <LoginWithCleverButton />
            )}
            <div className='children-container'>
              {children}
            </div>
          </div>
        </ValidatorForm>
        <br />
        {/* <PaperSheetError message='Error message ...' className='login-form-error-area' /> */}
        {errorMessage ? <PaperSheetError message={errorMessage} className='login-form-error-area' /> : ''}
      </div>
    </Slide>
  );
}

LoginForm.defaultProps = {
  loading: false,
  errorMessage: '',
  userLoginData: {
    username: '',
    password: '',
    rememberMe: false,
  },
  loginClever: true,
  forgotPasswordComponent: null,
  children: null,
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  loginSuccess: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  userLoginData: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    rememberMe: PropTypes.bool,
  }),
  forgotPasswordComponent: PropTypes.node,
  loginClever: PropTypes.bool,
  children: PropTypes.node,
};

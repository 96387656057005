import { useState, useEffect } from 'react';

const objectiveNameComparator = (a, b) => a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase());

export const usePrepareData = (tagIds, allLessonTags) => {
  const [selectedTagIds, setSelectedTagIds] = useState([...tagIds]);
  useEffect(() => {
    const selectedTags = tagIds
      .map((tagId) => (allLessonTags.find((tag) => tag.id === tagId)))
      .filter((t) => t !== null && t !== undefined);
    selectedTags.sort((a, b) => objectiveNameComparator(a, b));
    const sortedTags = selectedTags.map((s) => s.id);
    setSelectedTagIds(sortedTags);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedTagIds,
    setSelectedTagIds,
  };
};

import SchoolIcon from '@mui/icons-material/School';
import './LessonFinished.scss';
import { Fade } from '@mui/material';

export default function LessonFinished() {
  return (
    <Fade timeout={1000} in>
      <div className='lesson-finished'>
        <div className='icon'>
          <SchoolIcon className='icon' />
        </div>
        <div className='text'>
          Activity Complete!
        </div>
      </div>
    </Fade>
  );
}

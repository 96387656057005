import PropTypes from 'prop-types';
import './StudentAssignment.scss';
import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import { useAssignedStudentCoursesTable, useGetTableData } from './StudentAssignmentHooks';

const StudentAssignment = ({
  data,
  onTableActionSuccess,
  loading,
}) => {
  const {
    tableConfig,
  } = useAssignedStudentCoursesTable({
    onTableActionSuccess,
  });

  const {
    tableData,
  } = useGetTableData({
    assignedStudentCourses: data.assignedStudentCourses,
  });

  return (
    <div className='student-assignment' data-test='student-assignment-table'>
      <SearchAndSortableTable
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc', // needs to figure out whey it is 'desc' to order the date
          orderBy: 'studentName',
        }}
        filterable={false}
        loading={loading}
        autoResetTableState={false}
      />
    </div>
  );
};

StudentAssignment.defaultProps = {
  onTableActionSuccess: () => null,
  loading: false,
};

StudentAssignment.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    assignmentStatus: PropTypes.number,
    assignedStudentCourses: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onTableActionSuccess: PropTypes.func,
  loading: PropTypes.bool,
};

export default StudentAssignment;

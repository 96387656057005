import PropTypes from 'prop-types';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import StudentAssignLessonAction from './StudentAssignLessonAction';
import { useGetCurrentStudent } from '../../../../hooks/StudentHooks';
import { flatten, unique } from '../../../../../utils/ArrayUtils';
import './StudentLessonsMultiSelectionActions.scss';
import EmailLessonPlanAction from './EmailLessonPlanAction';

const StudentLessonsMultiSelectionActions = ({
  selectedLessonIds,
}) => {
  const { myStudentDomain } = useStudentContext();
  const { lessonData, selectedDates } = myStudentDomain.domainData;
  const { lessons } = lessonData;

  const currentStudent = useGetCurrentStudent();

  const allLessons = unique(flatten(lessons.map((subjectWithLessons) => subjectWithLessons.lessons)), 'id');

  return (
    <div className='student-lessons-multi-selection-actions'>
      <EmailLessonPlanAction />
      <StudentAssignLessonAction
        lessons={allLessons}
        selectedLessonIds={selectedLessonIds}
        currentStudent={currentStudent}
        selectedDates={selectedDates}
        key='student-lesson-assign-to-others'
      />
    </div>
  );
};

StudentLessonsMultiSelectionActions.defaultProps = {
  selectedLessonIds: [],
};

StudentLessonsMultiSelectionActions.propTypes = {
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
};

export default StudentLessonsMultiSelectionActions;

/* eslint-disable object-shorthand */
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { createMasteryCriteriaDummyData } from '../../../../utils/MasterObjectiveMapping';
import ObjectiveCard from './ObjectiveCard';
import './ObjectiveCardGettingStarted.scss';
import videoPoster from '../../../../static/images/track_progress_default.png';

const masterObjectiveData = createMasteryCriteriaDummyData();
const ObjectiveCardGettingStarted = ({
  videoLink,
  student,
  filterChildren,
}) => {
  const { firstName } = student;

  return (
    <div className='objective-card-getting-started'>
      {filterChildren ? (
        <Paper className='top-bar'>
          {filterChildren}
        </Paper>
      ) : null}

      <div className='objective-card-container'>
        <div className='objective-card'>
          <h2>
            <PlayCircleFilledIcon
              className='margin-right-medium grid-list-navigate-previous'
            />

            {masterObjectiveData.masterObjectives[0].objectiveRowTitle}

            <PlayCircleFilledIcon
              className='margin-left-medium grid-list-navigate-next'
            />
          </h2>
          <ObjectiveCard
            masterObjectiveData={masterObjectiveData.masterObjectives[0].objectives[0]}
            included={masterObjectiveData.included}
            width={220}
          />
          <div className='overlay' />
        </div>
        <div className='objective-video'>
          <div>
            <div className='getting-started-text' data-private>
              <Typography variant='h6' data-private>
                {firstName} has no customized goal/objective tracking.
              </Typography>
              <Typography variant='h6' gutterBottom data-private>
                Start customizing {firstName}&apos;s data tracking with this video
              </Typography>
            </div>
            <video poster={videoPoster} src={videoLink} controls type='video/mp4'>
              <track kind='captions' src={videoLink} />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

ObjectiveCardGettingStarted.defaultProps = {
  student: {},
  videoLink: '',
  filterChildren: null,
};

ObjectiveCardGettingStarted.propTypes = {
  videoLink: PropTypes.string,
  student: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  filterChildren: PropTypes.node,
};

export default ObjectiveCardGettingStarted;

import { createContext, useContext } from 'react';
import TeacherCurriculumDomain from './TeacherCurriculumDomain';
import TeacherCurriculumLessonsAndGuidesDomain from './TeacherCurriculumLessonsAndGuidesDomain';

const domains = [
  new TeacherCurriculumDomain(),
  new TeacherCurriculumLessonsAndGuidesDomain(),
];

/*-----------------------------------------------------------------------*/

let TeacherCurriculumContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  TeacherCurriculumContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useTeacherCurriculum = () => useContext(TeacherCurriculumContextProvider);

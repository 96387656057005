import BaseDomain from '../../../states/domains/BaseDomain';
import DateUtils from '../../../utils/DateUtils';

const ADMIN_DATA_UPDATE_TEACHER_LIST = 'ADMIN_DATA_UPDATE_TEACHER_LIST';
const ADMIN_DATA_UPDATE_TEACHER_LIST_IS_LOADING = 'ADMIN_DATA_UPDATE_TEACHER_LIST_IS_LOADING';
const ADMIN_DATA_UPDATE_ACTIVITY_REPORTS = 'ADMIN_DATA_UPDATE_ACTIVITY_REPORTS';
const ADMIN_DATA_UPDATE_ACTIVITY_REPORTS_IS_LOADING = 'ADMIN_DATA_UPDATE_ACTIVITY_REPORTS_IS_LOADING';
const ADMIN_DATA_UPDATE_SEARCH_CRITERIA = 'ADMIN_DATA_UPDATE_SEARCH_CRITERIA';
const ADMIN_DATA_UPDATE_SELECTED_DATES = 'ADMIN_DATA_UPDATE_SELECTED_DATES';
const ADMIN_DATA_UPDATE_SELECTED_TEACHER_ID = 'ADMIN_DATA_UPDATE_SELECTED_TEACHER_ID';
const ADMIN_DATA_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID = 'ADMIN_DATA_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID';

const currentDateMMddYYYY = DateUtils.getYesterdayDateInMMddYYYY();
const lastYearDateMMddYYYY = DateUtils.getLastYearDateMinusOneInMMddYYYY();

const initialState = {
  isTeacherListLoading: false,
  teacherList: [],
  searchCriteria: {
    teachersLoggingIn: true,
    teachersWithoutLogins: true,
    districtIds: [],
    licenseTypeIds: [],
    selectedSchoolId: null,
    schoolIds: [],
  },
  isActivityReportLoading: false,
  activityReports: {},
  selectedDates: {
    startDate: lastYearDateMMddYYYY,
    endDate: currentDateMMddYYYY,
  },
  selectedTeacherId: null,
  schoolsBySelectedDistrictId: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADMIN_DATA_UPDATE_TEACHER_LIST:
      return {
        ...state,
        teacherList: action.payload.teacherList,
        isTeacherListLoading: action.payload.isTeacherListLoading,
      };
    case ADMIN_DATA_UPDATE_TEACHER_LIST_IS_LOADING:
      return {
        ...state,
        isTeacherListLoading: action.payload,
      };
    case ADMIN_DATA_UPDATE_ACTIVITY_REPORTS:
      return {
        ...state,
        activityReports: action.payload.activityReports,
        isActivityReportLoading: action.payload.isActivityReportLoading,
      };
    case ADMIN_DATA_UPDATE_ACTIVITY_REPORTS_IS_LOADING:
      return {
        ...state,
        isActivityReportLoading: action.payload,
      };
    case ADMIN_DATA_UPDATE_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.payload,
      };
    case ADMIN_DATA_UPDATE_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload,
      };
    case ADMIN_DATA_UPDATE_SELECTED_TEACHER_ID:
      return {
        ...state,
        selectedTeacherId: action.payload,
      };
    case ADMIN_DATA_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID:
      return {
        ...state,
        schoolsBySelectedDistrictId: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'adminDataDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateTeacherList(teacherList, isTeacherListLoading = false) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_TEACHER_LIST,
      payload: {
        teacherList,
        isTeacherListLoading,
      },
    });
  }

  async updateTeacherListLoading(isTeacherListLoading) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_TEACHER_LIST_IS_LOADING,
      payload: isTeacherListLoading,
    });
  }

  async updateActivityReports(activityReports, isActivityReportLoading = false) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_ACTIVITY_REPORTS,
      payload: {
        activityReports,
        isActivityReportLoading,
      },
    });
  }

  async updateActivityReportsLoading(isActivityReportLoading) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_ACTIVITY_REPORTS_IS_LOADING,
      payload: isActivityReportLoading,
    });
  }

  async updateSearchCriteria(searchCriteria) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_SEARCH_CRITERIA,
      payload: searchCriteria,
    });
  }

  async updatSelectedDates(dates) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_SELECTED_DATES,
      payload: dates,
    });
  }

  async updatSelectedTeacherId(teacherId) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_SELECTED_TEACHER_ID,
      payload: teacherId,
    });
  }

  async updateSchoolsBySelectedDistrictId(schools) {
    return this.dispatch({
      type: ADMIN_DATA_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID,
      payload: schools,
    });
  }
}

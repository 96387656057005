import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getLocalStorageLessonResponseEvents, getUserToken, saveTokenToMemory, setLocalStorageLessonResponseEvents } from '../utils/VizzleSessionStorage';
import { dispatchSessionTimeoutEvent } from './VizzleEvents';

export const apiUrl = process.env.REACT_APP_API_URL;
const vizzleAxios = axios.create({
  baseURL: apiUrl,
  /* other custom settings */
});

const requestInterceptors = (config) => {
  // user token cannot be retrieved outside react hook
  // so it needs to be retrived from the sesson storage
  const token = getUserToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const responseInterceptorsOnFullfill = (response) => {
  const token = response.headers.vizzle_auth_token;
  if (token) {
    saveTokenToMemory(token);
  }
  return response;
};

const responseInterceptorsOnReject = (error) => {
  if (error && error.response) {
    const { status } = error.response;
    if (status === 401) {
      dispatchSessionTimeoutEvent('Your session expired please log in again.');
    }
  }
  return Promise.reject(error);
};

const responseInterceptorsSaveToCache = (error) => {
  if (error && error.response) {
    const { status } = error.response;
    if (status === 401) {
      const lessonEvents = getLocalStorageLessonResponseEvents() || [];
      lessonEvents.push(JSON.parse(error.config.data));
      setLocalStorageLessonResponseEvents(lessonEvents);
    }
  }
  return Promise.reject(error);
};

vizzleAxios.interceptors.request.use(requestInterceptors);
vizzleAxios.interceptors.response.use(responseInterceptorsOnFullfill, responseInterceptorsOnReject);

export default vizzleAxios;

/**
 *
 * Set up retryable axios service
 *
 */
export const vizzleAxiosWithRetry = axios.create({
  baseURL: apiUrl,
  /* other custom settings */
});
vizzleAxiosWithRetry.interceptors.request.use(requestInterceptors);
vizzleAxiosWithRetry.interceptors.response.use(responseInterceptorsOnFullfill, responseInterceptorsSaveToCache);
axiosRetry(vizzleAxiosWithRetry, {
  retries: 120,
  retryCondition: (response) => {
    const { isAxiosError, message } = response;
    return isAxiosError && message === 'Network Error';
  },
  retryDelay: () => (2000),
});

export const vizzleLessonAzureTrackingAxios = axios.create({
  baseURL: process.env.REACT_APP_AZURE_ANSWER_TRACKING_API_URL,
  timeout: 15000, // 15 seconds
  /* other custom settings */
});

vizzleLessonAzureTrackingAxios.interceptors.request.use((config) => {
  const token = getUserToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers['x-functions-key'] = process.env.REACT_APP_AZURE_ANSWER_TRACKING_API_KEY;
  }
  return config;
});

export const vizzleAxiosAccessTokenLogin = axios.create({
  baseURL: apiUrl,
  /* other custom settings */
});

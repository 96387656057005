import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { getPageStyle } from '../../../../../components/vizzle/page/common/LessonPageUtil';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './MatchingResponseActivity.scss';
import { useDetermineClickOrTouchEventForDnd } from '../../../../hooks/DndClickOrTouchHooks';

/**
 * Matching responses
 */
const MatchingResponseActivity = forwardRef(({
  responseCard,
  index,
  cardId,
  onClick,
  disablePlay,
  withTts,
  isDraggable,
}, ref) => {
  const componentRef = useRef(null);
  const mediaPlayerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    playMedia: () => mediaPlayerRef && mediaPlayerRef.current && mediaPlayerRef.current.playMedia(),
    getEnableElements: () => componentRef.current,
  }));

  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const handleEvent = useDetermineClickOrTouchEventForDnd(
    (e) => {
      if (onClick) {
        onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
      }
    },
    isDraggable,
  );

  return (
    <Card
      className={`matching-response-activity-card ${onClick ? 'clickable' : ''}`}
      style={getPageStyle(responseCard, true)}
      onClick={(e) => onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0)}
      key={`matching-response-activity-card-${cardId}`}
      ref={componentRef}
      {...handleEvent}
      data-test='matching-response-card'
    >
      {
        responseCard.image ? (
          <CardMedia
            className='matching-response-activity-card-image'
            image={responseCard.image}
            title='Matching'
            data-test='matching-response-activity-card-image'
          />
        ) : ''
      }
      {responseCard.text
        ? (
          <CardContent
            key={`matching-response-activity-card-2${cardId}`}
            className='matching-response-activity-card-text'
            data-test='matching-response-activity-card-text'
          >

            <TextLines
              textLines={responseCard.text}
              componentKey={`text-line-${cardId}`}
              ref={setTextLineRef}
            />

          </CardContent>
        ) : ''}
      {
        (responseCard.audio || responseCard.video || (withTts && elementToPlayTts))
        && (
          <MediaPlayer
            audioUrl={responseCard.audio}
            videoUrl={responseCard.video}
            parentElementId={cardId}
            className={`media-component vizzle-matching-response-activity-card-${index}-media-btn`}
            ref={mediaPlayerRef}
            disablePlay={disablePlay}
            textToRead={elementToPlayTts}
            tts={ttsConfig}
            forDndElement={isDraggable}
          />
        )
      }
    </Card>
  );
});

MatchingResponseActivity.defaultProps = {
  responseCard: {},
  onClick: null,
  disablePlay: false,
  withTts: true,
  isDraggable: false,
};

MatchingResponseActivity.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  cardId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disablePlay: PropTypes.bool,
  withTts: PropTypes.bool,
  isDraggable: PropTypes.bool,
};

export default MatchingResponseActivity;

import SearchAndSortableTable from '../../../../../../components/table/SearchAndSortableTable';
import SearchForm from '../form/SearchForm';
import { useGetDefaultDistrict, useHandleSearch, useHandleTeacherTable, useSearchTeacher, useSearchTeacherTable } from './SearchTeacherHooks';
import './SearchTeacher.scss';
import CsvTableExport from '../../../../../../components/export/CsvTableExport';
import ExportData from '../buttons/ExportTableData';
import MoveTeacherAction from '../../../../components/actions/move-teacher/MoveTeacherAction';
import '../../../../AdminCommon.scss';

const SearchTeacher = () => {
  const {
    tableData,
    tableConfigForTable,
    tableConfigForCsv,
    hasLoaded,
  } = useSearchTeacherTable();

  const {
    searchTeacherByCriteria,
    loading,
  } = useSearchTeacher();

  const {
    criteria,
    handleOnSearchClick,
    handleOnSearchClear,
  } = useHandleSearch({
    searchTeacherByCriteria,
  });

  const {
    selectedTeacherIds,
    selectedTeachers,
    handleOnSelectedRowIds,
  } = useHandleTeacherTable();

  const defaultDistrict = useGetDefaultDistrict();
  return (
    <div className='search-teacher relative-container full-height'>
      <SearchAndSortableTable
        config={tableConfigForTable}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'firstName',
        }}
        toolbarActions={[
          <SearchForm
            key='search-teacher-search-form'
            initCriteria={criteria}
            onSearchClick={handleOnSearchClick}
            onSearchClear={handleOnSearchClear}
          />,
          <MoveTeacherAction
            key='search-teacher-move-teacher'
            selectedTeachers={selectedTeachers}
            onMoveTeachersSuccess={() => {
              searchTeacherByCriteria(criteria);
            }}
            initSelectedDistrict={defaultDistrict}
          />,
          <CsvTableExport
            key='search-teacher-search-export'
            config={tableConfigForCsv}
            tableData={tableData}
            filename='Vizzle NextGen Teachers.csv'
            linkComponent={<ExportData ariaLabel='Export Teachers' />}
          />,
        ]}
        dataId='id'
        selectedRowIds={selectedTeacherIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        loading={loading}
        autoResetTableState
        filterable={false}
        noDataMessage={hasLoaded ? 'No results found' : ''}
      />
    </div>
  );
};

export default SearchTeacher;

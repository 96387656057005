import {
  Fab, Fade, Tooltip,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  useNavigate,
} from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import './TeacherActivityReportStudents.scss';
import SearchAndSortableTable from '../../../../../components/table/SearchAndSortableTable';
import { useAdmin } from '../../../context/AdminContextProvider';
import { URL_ACTIVITY_REPORT } from '../AdminDataConstants';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';

const tableConfig = [
  {
    title: 'Student Name',
    align: 'left',
    dataField: 'name',
    width: '40%',
  },
  {
    title: 'Plays Per Subject',
    align: 'left',
    dataField: 'subjectName',
    width: '60%',
    noSort: true,
    dataRenderer: (data) => {
      const { id, lessonPlays } = data;
      if (lessonPlays) {
        return (
          <div key={`student-id-${id}`} className='student-subject-report-container'>
            {
              lessonPlays.map((s) => {
                const uuid = uuidv1();
                return (
                  <div key={`student-id-${id}-subject-${uuid}`} className='student-subject-report'>
                    <div>{s.subjectName}</div>
                    <div>{s.count > 0 ? s.count : 'None'}</div>
                  </div>
                );
              })
            }

          </div>
        );
      }
      return 'None';
    },
  },
];

// eslint-disable-next-line arrow-body-style
const TeacherActivityReportStudents = () => {
  const navigate = useNavigate();
  const { adminDataDomain } = useAdmin();
  const {
    isActivityReportLoading,
    activityReports,
  } = adminDataDomain.domainData;

  let tableData = [];
  if (activityReports && activityReports.attributes && activityReports.attributes.studentsByActivity && activityReports.attributes.studentsByActivity.students) {
    tableData = activityReports.attributes.studentsByActivity.students.map((s) => {
      const { lessonPlays } = s;
      return (
        {
          id: s.id,
          name: `${s.firstName} ${s.lastName}`,
          lessonPlays,
        }
      );
    });
  }

  return (
    <Fade timeout={1000} in>
      <div className='teacher-activity-report-students' data-test='teacher-activity-report-students'>
        <Tooltip title='Back'>
          <Fab
            className='back-fab'
            aria-label='Back'
            onClick={() => {
              navigate(URL_ACTIVITY_REPORT);
            }}
          >
            <KeyboardBackspaceIcon />
          </Fab>
        </Tooltip>
        {!isActivityReportLoading && tableData.length === 0 ? (
          <NoLessonPaper message='No Students' className='no-data' />
        ) : (
          <SearchAndSortableTable
            config={tableConfig}
            tableData={tableData}
            initTableState={{
              order: 'desc',
              orderBy: 'name',
            }}
            searchPlaceholder='Filter by Student Name'
            searchField='name'
            dataId='id'
            loading={isActivityReportLoading}
            autoResetTableState={false}
            containerClassName='teacher-activity-report-students-table'
          />
        )}
      </div>
    </Fade>
  );
};

export default TeacherActivityReportStudents;

import { useEffect, useState } from 'react';
import { getNumberOfLessonsCanBeGenerated } from '../../../../../services/CourseService';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';

export const useGetGenteratedLessonData = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const {
    generatedLessons,
  } = teacherCourseDomain.domainData;

  const { hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;
  return {
    hasPerformSearch,
    generatedLessons,
  };
};

export const useGetLessonGenerationLevel = () => {
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const [lessonGeneration, setLessonGeneration] = useState({ lessonIds: [] });
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria: { courseId, unitId, topicId, lessonLevel } } = teacherCourseSearchCriteriaDomain.domainData;
  const lessonLevelAsNumber = Number(lessonLevel);
  const getLessonLevel = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getNumberOfLessonsCanBeGenerated(topicId, lessonLevelAsNumber);
        setLessonGeneration(result);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (courseId && unitId && topicId) {
      getLessonLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, unitId, topicId, lessonLevel]);

  return {
    lessonLevel: lessonLevelAsNumber,
    lessonGeneration,
    getLessonLevel,
    loading,
  };
};

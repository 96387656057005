import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../../../components/dialog/ConfirmationDialog';
import '../SearchBarAction.scss';

const CancelDialog = ({
  title,
  open,
  onClose,
  onCancel,
}) => {
  if (!open) {
    return null;
  }
  return (
    <ConfirmationDialog
      open={open}
      title={title}
      contentText={(
        <div>
          Are you sure?
          <br />
          Cancelling will delete any unsaved changes.
        </div>
      )}
      confirmLabel='Go Back'
      cancelLabel='Cancel'
      onConfirm={() => { onClose(); }}
      onCancel={() => { onClose(); onCancel(); }}
      confirmClass='wide-button'
      cancelClass='wide-button'
    />
  );
};

CancelDialog.defaultProps = {
  title: '',
  open: false,
  onClose: () => null,
  onCancel: () => null,
};

CancelDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CancelDialog;

/* eslint-disable no-param-reassign */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const CANVAS_WIDTH = 800;

const SPARK_RADIUS = 1;
const SPARK_COUNT = 240;

const SPARK_FLOAT = 0.1;
const SPARK_FLOAT_VARIATION = 0.02;
const SPARK_FLOAT_PROBABILITY = 0.2;

const SPARK_PULSE_RATE = 0.15; // radians per frame
const SPARK_PULSE_MIN = 0.25;

const SPARK_MAX_DISTANCE = 140;

const CAMERA_STARTING_SPEED = 0.3;
const CAMERA_ACCELERATION = 0.0002;
const CAMERA_RECOVER_ACCELERATION = 0.03;
const CAMERA_SPEED_LATERAL = 0.1;
const CAMERA_BOUNCE_SPEED = -0.6;
const MAX_CAMERA_SPEED = 0.5;

const CAMERA_HEIGHT = 14;
const CAMERA_FOCAL = 1200; // <45 degree viewing angle

const ARCH_COUNT = 6;
const ARCH_SPACING = 40;
const ARCH_CUTOFF = 20;
const ARCH_SIZE = 55;

const TRACK_WIDTH = 12;
const TRACK_HEIGHT = 14;

const BOOK_SPEED = 0.042;
const BOOK_START = 14;
const BOOK_WOBBLE_RATE = 0.15; // radians per frame
const BOOK_WOBBLE_INTENSITY = 0.15; // degrees
const BOOKS_PER_ARCH = 2;
const BOOK_SPACING = 2;

const STACK_WOBBLE_RATE = 0.05; // radians per frame
const STACK_WOBBLE_INTENSITY = 0.012; // degrees
const STACK_VISIBILITY_PROBABILITY = 0.6;
const STACK_BOUNDS_X = 2;
const STACK_BOUNDS_Y = 2;

const MOUSE_SPEED = 0.5;

const MagicWandCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    score: 0,
    tweens: [],
    timeouts: [],
    bookImpactSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bookImpact')].sound,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
    hitSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'hit')].sound,
  };

  const playBookImpactSound = () => {
    if (app.bookImpactSound) {
      app.bookImpactSound.play({ start: 0, volume: 1 });
    }
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const playHitSound = () => {
    if (app.hitSound) {
      app.hitSound.play({ start: 0, volume: 1 });
    }
  };

  const loadBitmaps = () => {
    const spark = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'spark')].texture);
    app.sparkBounds = spark.getBounds();

    const book = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'book')].texture);
    app.bookBounds = book.getBounds();

    const stack = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'stack')].texture);
    app.stackBounds = stack.getBounds();

    const libraryArch = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'libraryArch')].texture);
    app.libraryArchBounds = libraryArch.getBounds();

    app.wand = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'wand')].texture);
    app.wandBounds = app.wand.getBounds();
    app.wand.anchor.set(0.5, 0);

    app.goodJob = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'goodJob')].texture);
    app.goodJob.anchor.set(0.5);
  };

  const addRenderObject = (argId, argParent, argScale) => {
    const result = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, argId)].texture);
    result.renderScale = argScale;
    argParent.addChild(result);
    app.renderObjects.push(result);
    return result;
  };

  const setWorldPos = (argBitmap, argX, argY, argZ) => {
    argBitmap.worldX = argX;
    argBitmap.worldY = argY;
    argBitmap.worldZ = argZ;
  };

  const setDeltas = (argBitmap, argDx, argDy, argDz) => {
    argBitmap.dx = argDx;
    argBitmap.dy = argDy;
    argBitmap.dz = argDz;
  };

  const handleMouseMove = (e) => {
    const pt = app.canvas.stage.toLocal(e.data.global);

    if (pt.x < 0 || pt.x > app.canvas.view.width || pt.y < 0 || pt.y > app.canvas.view.height) {
      return;
    }
    app.stageX = pt.x;
    app.stageY = pt.y;

    app.desiredCameraX = (app.stageX / app.canvas.view.width - 0.5) * TRACK_WIDTH;
    app.desiredCameraZ = (0.5 - app.stageY / app.canvas.view.height) * TRACK_HEIGHT;

    const deltaX = (app.stageX - app.wand.x) * MOUSE_SPEED;
    app.wand.x += deltaX;

    const deltaY = (app.stageY - app.wand.y) * MOUSE_SPEED;
    app.wand.y += deltaY;
  };

  const handleOnBookClick = (book) => {
    book.visible = false;
    app.score++;
    if (app.score === 1) {
      app.scoreText.text = '1 book collected';
    } else {
      app.scoreText.text = `${app.score} books collected`;
    }

    playHitSound();
  };

  const createStage = () => {
    let i;
    app.renderObjects = [];
    app.archContainers = [];

    const objectScale = ARCH_SIZE / app.libraryArchBounds.width;

    for (i = 0; i < ARCH_COUNT; i++) {
      const container = new PIXI.Container();
      app.archContainers.push(container);

      app.canvas.stage.addChild(container);

      container.arch = addRenderObject('libraryArch', container, objectScale);
      container.arch.anchor.set(0.5, 2 / 3);

      setWorldPos(
        container.arch,
        0, (ARCH_COUNT - i - 1) * ARCH_SPACING + ARCH_CUTOFF, 0,
      );

      container.books = [];

      for (let b = 0; b < BOOKS_PER_ARCH; b++) {
        const book = addRenderObject('book', container, objectScale);
        book.interactive = true;
        book.on('click', () => {
          handleOnBookClick(book);
        });
        book.on('touchend', () => {
          handleOnBookClick(book);
        });
        book.anchor.set(0.5);
        setWorldPos(book, container.arch.worldY, 0);
        setDeltas(book, 0, 0, 0);
        book.pulse = Math.random() * 3.14159 * 2;
        book.visible = false;
        container.books.push(book);
      }

      container.stack = addRenderObject('stack', container, objectScale);
      container.stack.anchor.set(0.5, 1);

      setWorldPos(container.stack, (0, 0, 0));
      container.stack.pulse = Math.random() * 3.14159 * 2;
      container.stack.visible = false;
    }

    app.sparks = [];
    for (i = 0; i < SPARK_COUNT; i++) {
      app.sparks.push(addRenderObject('spark', app.canvas.stage, (SPARK_RADIUS * 2) / app.sparkBounds.width));
      app.sparks[i].anchor.set(0.5);

      setWorldPos(app.sparks[i], 0, 0, 0);
      setDeltas(app.sparks[i], 0, 0, 0);
      app.sparks[i].pulse = Math.random() * 3.14159 * 2;
    }

    app.canvas.stage.addChild(app.wand);

    app.scoreText = new PIXI.Text('0 books collected', { fontFamily: 'sans-serif', fontSize: 40, fill: 0xFFFFFF, textAlign: 'center' });
    app.scoreText.x = app.canvas.view.width / 2 - app.scoreText.getBounds().width / 2;
    app.scoreText.y = 10;
    app.canvas.stage.addChild(app.scoreText);
    app.score = 0;

    app.startIndex = 0;
    app.cameraSpeed = CAMERA_STARTING_SPEED;
    app.desiredCameraX = 0;
    app.desiredCameraZ = 0;
    app.cameraX = 0;
    app.cameraY = 0;
    app.cameraZ = 0;

    app.canvas.stage.cursor = 'none';
    app.canvas.stage.interactive = true;
    app.canvas.stage.on('mousemove', handleMouseMove);
    app.canvas.stage.on('touchmove', handleMouseMove);
    app.running = true;
  };

  const updateDeltas = (argBitmap) => {
    argBitmap.worldX += argBitmap.dx;
    argBitmap.worldY += argBitmap.dy;
    argBitmap.worldZ += argBitmap.dz;
  };

  const updateSpark = (argSpark) => {
    updateDeltas(argSpark);

    argSpark.rotation = Math.random() * 1;

    argSpark.pulse += SPARK_PULSE_RATE;
    argSpark.alpha = (Math.sin(argSpark.pulse) * 0.5 + 0.5) * (1 - SPARK_PULSE_MIN) + SPARK_PULSE_MIN;

    if (argSpark.worldY < app.cameraY + 1 && argSpark.parent !== null) {
      argSpark.parent.removeChild(argSpark);
    }
  };

  const updateArch = (argArch) => {
    for (let b = 0; b < BOOKS_PER_ARCH; b++) {
      updateDeltas(argArch.books[b]);

      argArch.books[b].pulse += BOOK_WOBBLE_RATE;
      argArch.books[b].rotation = Math.sin(argArch.books[b].pulse) * BOOK_WOBBLE_INTENSITY;
    }

    argArch.stack.pulse += STACK_WOBBLE_RATE;
    argArch.stack.skew.x = Math.sin(argArch.stack.pulse) * STACK_WOBBLE_INTENSITY;
  };

  const advanceArches = () => {
    app.archContainers.unshift(app.archContainers.pop());

    for (let i = 0; i < ARCH_COUNT; i++) {
      app.archContainers[i].parent.setChildIndex(app.archContainers[i], i);
    }

    app.archContainers[0].arch.worldY = app.archContainers[1].arch.worldY + ARCH_SPACING;

    for (let b = 0; b < BOOKS_PER_ARCH; b++) {
      app.archContainers[0].books[b].visible = true;
      app.archContainers[0].books[b].worldZ = (Math.random() - 0.5) * TRACK_HEIGHT;
      app.archContainers[0].books[b].worldY = app.archContainers[0].arch.worldY;

      if (Math.random() < 0.5) {
        app.archContainers[0].books[b].worldX = -BOOK_START - BOOK_SPACING * b;
        app.archContainers[0].books[b].dx = BOOK_SPEED;
      } else {
        app.archContainers[0].books[b].worldX = BOOK_START + BOOK_SPACING * b;
        app.archContainers[0].books[b].dx = -BOOK_SPEED;
      }
    }

    if (Math.random() <= STACK_VISIBILITY_PROBABILITY) {
      app.archContainers[0].stack.visible = true;
      app.archContainers[0].stack.worldX = (Math.random() - 0.5) * TRACK_WIDTH;
      app.archContainers[0].stack.worldY = app.archContainers[0].arch.worldY;
      app.archContainers[0].stack.worldZ = -CAMERA_HEIGHT;
    } else {
      app.archContainers[0].stack.visible = false;
    }
  };

  const unproject = (argX, argY) => {
    const result = {};

    const top = argY < app.canvas.view.height / 2;

    if (top) {
      result.z = CAMERA_HEIGHT - app.cameraZ;
    } else {
      result.z = -CAMERA_HEIGHT - app.cameraZ;
    }

    result.y = (CAMERA_FOCAL * result.z) / (app.canvas.view.height / 2 - argY);
    result.x = ((argX - app.canvas.view.width / 2) * result.y) / CAMERA_FOCAL;

    result.x += app.cameraX;
    result.y += app.cameraY;
    result.z += app.cameraZ;

    const dx = result.x - app.cameraX;
    const dy = result.y - app.cameraY;
    const dz = result.z - app.cameraZ;

    if (dy > SPARK_MAX_DISTANCE) {
      result.x = (app.cameraX + SPARK_MAX_DISTANCE * dx) / dy;
      result.y = app.cameraY + SPARK_MAX_DISTANCE;
      result.z = (app.cameraZ + SPARK_MAX_DISTANCE * dz) / dy;
    }

    return result;
  };

  const createSpark = (argX, argY, argZ) => {
    setWorldPos(app.sparks[app.startIndex], argX, argY, argZ);
    app.sparks[app.startIndex].dx = (Math.random() - 0.5) * SPARK_FLOAT_VARIATION;
    app.sparks[app.startIndex].dy = (Math.random() - 0.5) * SPARK_FLOAT_VARIATION;
    app.sparks[app.startIndex].dz = (Math.random() - 0.5) * SPARK_FLOAT_VARIATION;

    if (Math.random() <= SPARK_FLOAT_PROBABILITY) {
      app.sparks[app.startIndex].dz += SPARK_FLOAT;
    }

    app.sparks[app.startIndex].pulse = Math.random() * 3.14159 * 2;

    for (let i = ARCH_COUNT - 1; i >= 0; i--) {
      if (i === 0 || app.sparks[app.startIndex].worldY < app.archContainers[i].arch.worldY) {
        if (app.sparks[app.startIndex].parent !== null) {
          app.sparks[app.startIndex].parent.removeChild(app.sparks[app.startIndex]);
        }
        app.archContainers[i].addChild(app.sparks[app.startIndex]);
        break;
      }
    }

    app.startIndex++;
    app.startIndex %= SPARK_COUNT;
  };

  const project = (argX, argY, argZ) => {
    const result = {};

    const transX = argX - app.cameraX;
    const transY = argY - app.cameraY;
    const transZ = argZ - app.cameraZ;

    result.x = ((transX * CAMERA_FOCAL) / transY) + app.canvas.view.width / 2;
    result.y = app.canvas.view.height / 2 - ((transZ * CAMERA_FOCAL) / transY);

    return result;
  };

  const placeObject = (argBitmap) => {
    argBitmap.scale.x = (argBitmap.renderScale * CAMERA_FOCAL) / (argBitmap.worldY - app.cameraY);
    argBitmap.scale.y = argBitmap.scale.x;

    const worldProject = project(argBitmap.worldX, argBitmap.worldY, argBitmap.worldZ);

    argBitmap.x = worldProject.x;
    argBitmap.y = worldProject.y;

    argBitmap.alpha = Math.min(-((argBitmap.worldY - ARCH_CUTOFF - app.cameraY) / ARCH_SPACING - ARCH_COUNT + 0.5),
      (argBitmap.worldY - ARCH_CUTOFF - app.cameraY) * 0.25);
  };

  const drawObjects = () => {
    for (let i = 0; i < app.renderObjects.length; i++) {
      placeObject(app.renderObjects[i]);
    }
  };

  // eslint-disable-next-line no-restricted-properties
  const distance = (argDx, argDy) => Math.sqrt(Math.pow(argDx, 2) + Math.pow(argDy, 2));

  const updateCamera = () => {
    if (app.cameraSpeed >= CAMERA_STARTING_SPEED) {
      const speed = app.cameraSpeed + CAMERA_ACCELERATION;
      app.cameraSpeed = Math.min(speed, MAX_CAMERA_SPEED);
    } else {
      app.cameraSpeed += CAMERA_RECOVER_ACCELERATION;
    }

    const dx = app.desiredCameraX - app.cameraX;
    const dz = app.desiredCameraZ - app.cameraZ;

    const d = distance(dx, dz);

    if (d <= CAMERA_SPEED_LATERAL) {
      app.cameraX = app.desiredCameraX;
      app.cameraZ = app.desiredCameraZ;
    } else {
      app.cameraX += (dx * CAMERA_SPEED_LATERAL) / d;
      app.cameraZ += (dz * CAMERA_SPEED_LATERAL) / d;
    }

    app.cameraY += app.cameraSpeed;

    if (
      app.archContainers[ARCH_COUNT - 1].stack.visible
      && app.archContainers[ARCH_COUNT - 1].stack.worldY <= app.cameraY + ARCH_CUTOFF + STACK_BOUNDS_Y
      && Math.abs(app.cameraX - app.archContainers[ARCH_COUNT - 1].stack.worldX) <= STACK_BOUNDS_X) {
      app.cameraSpeed = CAMERA_BOUNCE_SPEED;
      playBookImpactSound();
    }

    app.wand.rotation = -0.5 + dx * 0.5;
  };

  const tick = () => {
    let i;

    if (app.running) {
      for (i = 0; i < SPARK_COUNT; i++) {
        updateSpark(app.sparks[i]);
      }

      for (i = 0; i < ARCH_COUNT; i++) {
        updateArch(app.archContainers[i]);
      }

      if (app.archContainers[ARCH_COUNT - 1].arch.worldY < app.cameraY + ARCH_CUTOFF) {
        advanceArches();
      }

      const pos = unproject(app.stageX, app.stageY);

      createSpark(pos.x, pos.y, pos.z);

      drawObjects();

      updateCamera();
    }
  };

  const showGoodJob = () => {
    app.running = false;
    app.goodJob.x = app.canvas.view.width / 2;
    app.goodJob.y = app.canvas.view.height / 2;
    app.goodJob.alpha = 0;

    app.canvas.stage.addChild(app.goodJob);

    const showGoodJobTween = new TWEEN.Tween(app.goodJob);
    showGoodJobTween.to({ alpha: 1 }, 500);
    showGoodJobTween.easing(TWEEN.Easing.Linear.None);
    showGoodJobTween.onComplete(() => {
      const showGoodJobTimeout = setTimeout(() => {
        clearTimeout(showGoodJobTimeout);
        onFinish();
      }, 3500);
    });

    app.tweens.push(showGoodJobTween);
    showGoodJobTween.start();

    playClappingSound();
  };

  useSetupTimeout(canvas, showGoodJob);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundColor: 0x332244,
      });
    }

    loadBitmaps();
    createStage();
    app.canvas.ticker.add(tick);

    return () => {
      if (app.bookImpactSound) {
        app.bookImpactSound.stop();
      }
      if (app.clappingSound) {
        app.clappingSound.stop();
      }
      if (app.hitSound) {
        app.hitSound.stop();
      }

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

MagicWandCelebration.defaultProps = {
  onFinish: () => { },
};

MagicWandCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default MagicWandCelebration;

import vizzleAxios from './service';

export const uploadFile = async (url, file) => {
  const data = new FormData();
  data.append('file', file);
  const result = await vizzleAxios({
    method: 'POST',
    url,
    data,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return result.data;
};

export const exportDataAsFile = async (url) => {
  const result = await vizzleAxios({
    method: 'get',
    url,
    responseType: 'blob',
  });
  const { data } = result;
  return data;
};

import { useEffect, useRef, useState } from 'react';
import { Checkbox } from '@mui/material';
import { useAdmin } from '../../../../context/AdminContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import Logger from '../../../../../../utils/Logger';
import { getSchoolsByDistrictIds } from '../../../../../../services/AdminService';

export const useDistrictSelectorTable = ({
  onDataChange,
  formData,
}) => {
  const lastSelectedDistrictIdRef = useRef(null);
  const tableConfig = [
    {
      dataRenderer: (data) => {
        const { isSelected } = data;
        return (
          <Checkbox
            color='primary'
            className='school-selector-checkbox'
            checked={isSelected}
            onChange={(event) => {
              let updatedSelectedDistrictIds = [...formData.selectedDistrictIds];
              if (event.target.checked) {
                updatedSelectedDistrictIds.push(data.id);
                lastSelectedDistrictIdRef.current = data.id;
              } else {
                updatedSelectedDistrictIds = updatedSelectedDistrictIds.filter((u) => u !== data.id);
                lastSelectedDistrictIdRef.current = null;
              }
              onDataChange({
                ...formData,
                selectedDistrictIds: updatedSelectedDistrictIds,
              });
            }}
          />
        );
      },
      dataField: 'isSelected',
      align: 'center',
      width: '20px',
    },
    {
      title: 'Select District(s)',
      align: 'left',
      dataField: 'districtName',
      width: '90%',
    },
  ];

  const { districtsDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  const tableData = districts.map((d) => ({
    id: d.id,
    districtName: d.name,
    isSelected: formData.selectedDistrictIds.includes(d.id),
  }));
  return {
    districtTableConfig: tableConfig,
    districtsTableData: tableData,
    lastSelectedDistrictIdRef,
  };
};

export const useSchoolSelectorTable = ({
  formData,
  onDataChange,
  lastSelectedDistrictIdRef,
}) => {
  const { selectedSchoolIds } = formData;
  const [schoolTableData, setSchoolTableData] = useState([]);

  const tableConfig = [
    {
      dataRenderer: (data) => {
        const { isSelected } = data;
        return (
          <Checkbox
            color='primary'
            className='school-selector-checkbox'
            checked={isSelected}
            onChange={(event) => {
              let updatedSelectedSchoolIds = [...selectedSchoolIds];
              if (event.target.checked) {
                updatedSelectedSchoolIds.push(data.id);
              } else {
                updatedSelectedSchoolIds = updatedSelectedSchoolIds.filter((u) => u !== data.id);
              }

              const tableData = schoolTableData.map((sd) => ({
                ...sd,
                isSelected: updatedSelectedSchoolIds.includes(sd.id),
              }));
              setSchoolTableData(tableData);
              onDataChange({
                ...formData,
                selectedSchoolIds: updatedSelectedSchoolIds,
              });
            }}
          />
        );
      },
      dataField: 'isSelected',
      align: 'center',
      width: '20px',
    },
    {
      title: 'Select School(s)',
      align: 'left',
      dataField: 'schoolName',
      width: '90%',
    },
  ];

  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const getSchoolsBySelectedDistrictIds = async (districtIds) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getSchoolsByDistrictIds(districtIds);
        if (result && result.data) {
          const allSchoolIds = result.data.map((rd) => rd.id);

          const updatedData = {
            ...formData,
            selectedDistrictIds: districtIds,
            selectedSchoolIds: formData.selectedSchoolIds.filter((f) => allSchoolIds.includes(f)),
          };

          if (lastSelectedDistrictIdRef.current) {
            const schoolsOfLastSelectedDistrict = result.data
              .filter((rd) => rd.relationships.district.data.id === lastSelectedDistrictIdRef.current.toString())
              .map((s) => s.id);
            updatedData.selectedSchoolIds = [
              ...updatedData.selectedSchoolIds,
              ...schoolsOfLastSelectedDistrict,
            ];
          }

          const tableData = result.data.map((rd) => ({
            id: rd.id,
            schoolName: rd.attributes.name,
            isSelected: updatedData.selectedSchoolIds.includes(rd.id),
          }));

          setSchoolTableData(tableData);
          onDataChange(updatedData);
        } else {
          setSchoolTableData([]);
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (formData.selectedDistrictIds && formData.selectedDistrictIds.length > 0) {
      getSchoolsBySelectedDistrictIds(formData.selectedDistrictIds);
    } else {
      setSchoolTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.selectedDistrictIds]);

  return {
    schoolTableConfig: tableConfig,
    schoolTableData,
    schoolTableDataLoading: loading,
  };
};

import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './SequencingActivityPrompt.scss';

const SequencingActivityPrompt = forwardRef(({
  sequenceCard,
  sequenceCardId,
  onClick,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const prompRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => prompRef.current,
  }));

  return (
    <Card
      className='sequencing-activity-prompt'
      id={sequenceCardId}
      key={sequenceCardId}
      onClick={onClick}
      ref={prompRef}
    >
      <Card className='prompt-content'>
        {
          sequenceCard.image ? (
            <CardMedia
              className='sequencing-component-image'
              image={sequenceCard.image}
              title='Sequencing'
              data-test='sequencing-component-image'
            />
          ) : null
        }
        {sequenceCard.text
          ? (
            <CardContent data-test='sequencing-component-text' className={`sequencing-component-text ${sequenceCard.image ? 'text-with-image' : ''}`}>
              <TextLines
                textLines={sequenceCard.text}
                ref={setTextLineRef}
                componentKey={`text-line-${sequenceCardId}`}
              />
            </CardContent>
          ) : null}
      </Card>
      <MediaPlayer
        audioUrl={sequenceCard.audio}
        videoUrl={sequenceCard.video}
        parentElementId={sequenceCardId}
        className='sequence-media'
        textToRead={elementToPlayTts}
        tts={ttsConfig}
      />
    </Card>
  );
});

export default SequencingActivityPrompt;

SequencingActivityPrompt.defaultProps = {
  sequenceCard: {},
  onClick: () => { },
};

SequencingActivityPrompt.propTypes = {
  sequenceCardId: PropTypes.string.isRequired,
  sequenceCard: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

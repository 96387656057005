import BaseDomain from './BaseDomain';

const UI_DOMAIN_SHOW_LOADER = 'UI_DOMAIN_UPDATE_SHOW_LOADER';
const UI_DOMAIN_UPDATE_SHOW_SNACK_BAR = 'UI_DOMAIN_UPDATE_SHOW_SNACK_BAR';
const UI_DOMAIN_SHOW_NOTIFICATION_DIALOG = 'UI_DOMAIN_SHOW_DIALOG_NOTIFICATION';
const UI_DOMAIN_BLOCK_SCREEN = 'UI_DOMAIN_BLOCK_SCREEN';

const initialState = {
  spinningLoader: {},
  snackbar: {},
  notificationDialog: {},
  blockScreen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case UI_DOMAIN_SHOW_LOADER:
      return {
        ...state,
        spinningLoader: action.payload,
      };
    case UI_DOMAIN_UPDATE_SHOW_SNACK_BAR:
      return {
        ...state,
        snackbar: action.payload,
      };
    case UI_DOMAIN_SHOW_NOTIFICATION_DIALOG:
      return {
        ...state,
        notificationDialog: action.payload,
      };
    case UI_DOMAIN_BLOCK_SCREEN:
      return {
        ...state,
        blockScreen: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'uiDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Display the spinner loader
   *
   * @param bolean open true to display
   * @param string message message to display in the spinner loader
   * @param string loaderType loader type
   * @returns Promise
   */
  async showLoader(message, loaderType) {
    return this.dispatch({
      type: UI_DOMAIN_SHOW_LOADER,
      payload: { open: true, message, loaderType },
    });
  }

  /**
   * Hide the spinner loader
   *
   * @returns Promise
   */
  async hideLoader() {
    return this.dispatch({
      type: UI_DOMAIN_SHOW_LOADER,
      payload: { open: false },
    });
  }

  /**
   * Hide the spinner loader
   * @param open open flag - true/false
   * @param variant type of snackbar:
   *        success
   *        error
   * @param autoCloseIn automatically close the snackbar in seconds
   * @param message message to display
   * @returns Promise
   */
  async showSnackbar(open, variant, autoCloseIn, message) {
    return this.dispatch({
      type: UI_DOMAIN_UPDATE_SHOW_SNACK_BAR,
      payload: {
        variant,
        autoCloseIn,
        open,
        message,
      },
    });
  }

  /**
   * Hide the spinner loader
   *
   * @returns Promise
   */
  async closeSnackbar() {
    return this.dispatch({
      type: UI_DOMAIN_UPDATE_SHOW_SNACK_BAR,
      payload: {
        open: false,
      },
    });
  }

  /**
   * Display notification dialog
   *
   * @returns Promise
   */
  async showNotificationDialog(open, title, icon, message, confirmLabel, toLogin = true) {
    return this.dispatch({
      type: UI_DOMAIN_SHOW_NOTIFICATION_DIALOG,
      payload: {
        open,
        title,
        Icon: icon,
        message,
        confirmLabel,
        toLogin,
      },
    });
  }

  /**
   * To block / unblock the action on the screen
   *
   * @returns Promise
   */
  async blockScreen(isBlock) {
    return this.dispatch({
      type: UI_DOMAIN_BLOCK_SCREEN,
      payload: isBlock,
    });
  }
}

import { useState, useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import PIXI_SOUND from 'pixi-sound';
import ThemeUtil from './ThemeUtil';

export const themeObject = {};

export const useInitTheme = () => {
  const [themeState, setThemeState] = useState({
    open: false,
    themeId: '',
    ready: false,
  });

  themeObject.themeState = themeState;
  themeObject.setThemeState = setThemeState;

  return {
    themeState,
    setThemeState,
  };
};

export const usePreloadTheme = (themeList) => {
  const [pixiLoader, setPixiLoader] = useState();
  const loaderRef = useRef(null);

  useEffect(() => {
    setPixiLoader(null);
    if (themeList && themeList.length > 0) {
      const loader = new PIXI.Loader();
      loaderRef.current = loader;
      PIXI.utils.clearTextureCache();
      themeList.forEach((theme) => {
        if (theme.id) {
          const { manifest } = theme;
          manifest.forEach((m) => {
            const key = ThemeUtil.getResourceKey(theme.id, m.id);
            if ((!loader.resources[key])) {
              loader.add(key, m.src, (resource) => {
                if (['mp3'].includes(resource.extension) || (resource && resource.data && resource.data.nodeName && resource.data.nodeName.toLowerCase() === 'audio')) {
                  // eslint-disable-next-line no-param-reassign
                  resource.sound = PIXI_SOUND.Sound.from({
                    url: resource.url,
                    preload: true,
                  });
                }
              });
            }
          });
        }
      });

      loader.load();
      setPixiLoader(loader);
    }
    return () => {
      if (loaderRef.current) {
        loaderRef.current.reset();
      }
      PIXI.utils.clearTextureCache();
      PIXI.utils.destroyTextureCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeList]);

  return pixiLoader;
};

import { Paper, TextField, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './NumberWithChevronInput.scss';

// eslint-disable-next-line arrow-body-style
const NumberWithChevronInput = ({
  value,
  onChange,
  minValue,
  maxValue,
}) => {
  const handleOnChange = (event) => {
    if (!event.target.value
      || (Number(event.target.value) && Number(event.target.value) >= 1 && Number(event.target.value) <= 10)) {
      onChange(Number(event.target.value.replace(/\D/, '')));
    }
  };

  const handleOnDecrease = () => {
    if (value <= minValue) {
      return;
    }
    onChange(value - 1);
  };

  const handleOnIncrease = () => {
    if (value >= maxValue) {
      return;
    }
    onChange(value + 1);
  };

  return (
    <Paper className='number-with-chevron-input' elevation={3}>
      <IconButton aria-label='decrease' size='medium' onClick={handleOnDecrease} disabled={value <= minValue}>
        <ChevronLeftIcon fontSize='inherit' />
      </IconButton>
      <TextField
        className='input'
        onChange={handleOnChange}
        value={value}
        size='small'
        inputProps={{
          size: 2,
          maxLength: 2,
        }}
        variant='outlined'
      />
      <IconButton aria-label='increase' size='medium' onClick={handleOnIncrease} disabled={value >= maxValue}>
        <ChevronRightIcon fontSize='inherit' />
      </IconButton>
    </Paper>
  );
};

export default NumberWithChevronInput;

NumberWithChevronInput.defaultProps = {
  value: 0,
  onChange: () => null,
  minValue: 0,
  maxValue: 99,
};

NumberWithChevronInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

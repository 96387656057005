import ObjectUtils from '../../../../utils/ObjectUtils';
import TweenFunctions from '../../../../utils/TweenFunctions';
import { stopAudio } from '../../media/AudioPlayer';

export const showDropRejection = (rejectDroppedCardId, destinationDroppingId) => {
  ObjectUtils.setTimeout(() => {
    document.getElementById(destinationDroppingId).classList.remove('sorting-component-reject-hide');
    document.getElementById(destinationDroppingId).classList.add('sorting-component-reject-show');
  }, 100);

  ObjectUtils.setTimeout(() => {
    document.getElementById(destinationDroppingId).classList.remove('sorting-component-reject-show');
    document.getElementById(destinationDroppingId).classList.add('sorting-component-reject-hide');
  }, 500);

  document.getElementById(rejectDroppedCardId).classList.remove('sorting-component-reject-hide');
  document.getElementById(rejectDroppedCardId).classList.add('sorting-component-reject-show');
  ObjectUtils.setTimeout(() => {
    document.getElementById(rejectDroppedCardId).classList.remove('sorting-component-reject-show');
    document.getElementById(rejectDroppedCardId).classList.add('sorting-component-reject-hide');
  }, 500);
};

export const addReponseSelectedClass = (responseCardId) => {
  document.getElementById(responseCardId).classList.add('sorting-component-response-selected');
};

export const removeReponseSelectedClass = (responseCardId) => {
  document.getElementById(responseCardId).classList.remove('sorting-component-response-selected');
};

let api;
let currentSelectedResponseCardId = null;
let currentDraggableId = null;
let currentDroppableId = null;

export const clearSelectorValue = () => {
  if (currentSelectedResponseCardId) {
    removeReponseSelectedClass(currentSelectedResponseCardId);
    currentSelectedResponseCardId = null;
    currentDraggableId = null;
    currentDroppableId = null;
  }
};

export const getCurrentDroppableId = () => (currentDroppableId);

export const dragAndDropSensor = (value) => {
  api = value;
};

export const handleOnResponseCardClick = (e, responseCardId, draggableId) => {
  e.stopPropagation();
  if (currentSelectedResponseCardId) {
    removeReponseSelectedClass(currentSelectedResponseCardId);
  }
  if (currentSelectedResponseCardId === responseCardId) {
    currentSelectedResponseCardId = null;
    currentDraggableId = null;
    removeReponseSelectedClass(responseCardId);
  } else {
    currentSelectedResponseCardId = responseCardId;
    currentDraggableId = draggableId;
    addReponseSelectedClass(responseCardId);
  }
};

const moveStepByStep = (drag, values) => {
  requestAnimationFrame(() => {
    const newPosition = values.shift();
    drag.move(newPosition);
    if (values.length) {
      moveStepByStep(drag, values);
    } else {
      drag.drop();
    }
  });
};

export const handleOnPromptResponseCardAreaSelected = (e, drappableKeyId) => {
  e.stopPropagation();
  const preDrag = api.tryGetLock(currentDraggableId);
  if (!preDrag) {
    return;
  }
  stopAudio();
  currentDroppableId = drappableKeyId;
  const positionPrompt = document.getElementById(drappableKeyId).getBoundingClientRect();
  const positionResponse = document.getElementById(currentSelectedResponseCardId).getBoundingClientRect();
  const start = { x: 0, y: 0 };
  const end = { x: positionPrompt.left - positionResponse.left, y: positionPrompt.top - positionResponse.top };
  const drag = preDrag.fluidLift(start);

  const points = [];
  for (let i = 0; i < 20; i++) {
    points.push({
      x: TweenFunctions.linear(i, start.x, end.x, 20),
      y: TweenFunctions.linear(i, start.y, end.y, 20),
    });
  }
  moveStepByStep(drag, points);
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleForm from '../../components/toggle/ToggleForm';

const Accessibe = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.accessibeOn);

  // When mounting and changing a student.
  useEffect(() => {
    setToggle(profile.accessibeOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleOnchange = (_field, newValue) => {
    if (newValue === null) return;
    setToggle(newValue);
    onChangeCallback(newValue);
  };

  return (
    <ToggleForm value={toggle} handleOnChange={handleOnchange} data-test='accessibe-toggle' />
  );
};

Accessibe.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default Accessibe;

import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useScreenReader } from '../../../screen-reader/ScreenReaderHooks';
import { useRegisterSwitchAccessScanEvent } from '../../../switch-access/SwitchAccessHooks';
import './ReplayBookActivityAction.scss';

const ReplayBookActivityAction = forwardRef(({
  onClick,
  disabled,
}, ref) => {
  const replayButtonRef = useRef(null);

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  useImperativeHandle(ref, () => ({
    getEnableElements: () => (replayButtonRef.current),
  }));

  useRegisterSwitchAccessScanEvent(replayButtonRef, () => {
    readWhenScreenReaderIsOn(replayButtonRef.current);
  });

  return (
    <Button
      color='primary'
      className='btn-blue-primary book-replay-button'
      endIcon={<ReplayIcon />}
      onClick={onClick}
      disabled={disabled}
      ref={replayButtonRef}
      alt='Replay Activity Cards'
      data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
      data-test='book-activity-replay-action'
    >
      Replay
    </Button>
  );
});

ReplayBookActivityAction.defaultProps = {
  onClick: () => { },
  disabled: false,
};

ReplayBookActivityAction.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ReplayBookActivityAction;

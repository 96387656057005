import { useEffect } from 'react';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import DropdownInput from '../../../../../components/selector/DropdownInput';
import { useFetchStudentLesson } from '../StudentLessonsHooks';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useGetCurrentStudent } from '../../../../hooks/StudentHooks';
import './StudentLessonFilter.scss';
import { useGetSubjectsByUserComponents } from '../../../../hooks/SubjectsByUsersHook';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { USER_COMPONENT_SKILL_SUITE } from '../../../../../utils/User';

const StudentLessonFilter = () => {
  const { myStudentDomain } = useStudentContext();
  const { userDomain } = useDomain();
  const { lessonData } = myStudentDomain.domainData;

  const currentStudent = useGetCurrentStudent();
  const {
    fetchStudentLesson,
  } = useFetchStudentLesson(currentStudent);

  const subjectListOptions = useGetSubjectsByUserComponents(true);

  useEffect(() => {
    const { userProfile } = userDomain.domainData;
    const { components } = userProfile;
    if (components.length === 1 && String(components[0].identifier) === USER_COMPONENT_SKILL_SUITE) {
      myStudentDomain.updateSelectedLessonIdsAndFilter([], {
        ...lessonData.filter,
        selectedSubject: subjectListOptions[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className='student-lessons-filter'>
      <div className='subject-selector'>
        <DropdownInput
          dropdownValues={subjectListOptions}
          name='selectedSubject'
          value={lessonData.filter.selectedSubject ? lessonData.filter.selectedSubject : ''}
          className='subject-selector-dropdown'
          onChange={async (e) => {
            await myStudentDomain.updateSelectedLessonIdsAndFilter([], {
              ...lessonData.filter,
              selectedSubject: e.target.value,
            });
            fetchStudentLesson(true);
          }}
          data-test='subject-filter'
        />
      </div>

      <FormControlLabel
        className='expired-assignment-checkbox'
        control={
          (
            <Checkbox
              checked={lessonData && lessonData.filter.isExpiredAssignment}
              onChange={async (e) => {
                await myStudentDomain.updateSelectedLessonIdsAndFilter([], {
                  ...lessonData.filter,
                  isExpiredAssignment: e.target.checked,
                });
              }}
              name='expiredAssignment'
              color='primary'
              className='expired-assignment-checkbox'
              data-test='previously-assigned-checkbox'
            />
          )
        }
        label='Previously Assigned'
      />
    </Paper>
  );
};

export default StudentLessonFilter;

import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import {
  AREA_PROMPT_TEXT,
  AREA_SUB_TEXT,
  SECTION_IMAGE,
  SECTION_TEXT,
  SECTION_AUDIO,
  AREA_SUBMIT_ANSWER_TEXT,
  AREA_RESPONSE_CARDS,
  AREA_PROMPT_CARDS,
} from '../../../../../components/vizzle/page/common/CardEditorConstants';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import FlexibleMatchingEditor from '../../../../../components/vizzle/page/flexible-matching/FlexibleMatchingEditor';
import { useHandleMatchingQuestionKey } from '../../hooks/LessonMaintenanceHooks';
import { defaultSubmitText } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import {
  checkCharsLimit,
  checkHasNon18FontSize,
  checkHasNonBoldFontWeight,
  checkHasNonJosefinSlab,
  checkHasNonSpecificFontSize,
  getExceedCharLimitMessage,
  getTotalCharsLength,
  useHandleTextEditorWarning,
} from '../../hooks/TextEditorWarningHooks';
import { layoutDropdownValues } from '../../../../../components/vizzle/page/flexible-matching/FlexibleMatchingConstants';

const initState = {
  selectedArea: AREA_PROMPT_TEXT,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
};

/**
 * Container for adding text, image, video and audio for Categorizing page
 */
export default function FlexibleMatchingTextAndMedia() {
  const { lessonDataMaintenanceDomain, uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  // eslint-disable-next-line no-unused-vars
  const selectedPage = pages[currentSelectedPagesData];

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  useEffect(() => {
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedPagesData]);

  useEffect(() => {
    if (!selectedPage.key) {
      uiDomain.blockScreen(true);
    } else {
      uiDomain.blockScreen(false);
    }
    return () => {
      uiDomain.blockScreen(false);
    };
    // eslint-disable-next-line
  }, [selectedPage.key]);

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onAreaAndSectionSelected = (selectedArea, selectedSection, selectedCardIndex = 0) => {
    setCurrentSelectedData({
      selectedArea,
      selectedSection,
      selectedCardIndex,
    });
  };

  const handleMatchingQuestionKey = useHandleMatchingQuestionKey();
  const promptCards = selectedPage.promptCards.filter((card) => !card.hide);
  const responseCards = selectedPage.responseCards.filter((card) => !card.hide);

  const handleOnQuestionKeyChange = async (value) => {
    if (selectedPage.key === value) {
      return;
    }
    selectedPage.key = value;
    await handleMatchingQuestionKey(value, 'key');
  };

  const onAnswerKeyChange = async (answerKeys) => {
    answerKeys.forEach((key, index) => {
      selectedPage.responseCards[index].key = key;
    });
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const getText = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_PROMPT_TEXT:
        return selectedPage.promptText;
      case AREA_SUB_TEXT:
        return selectedPage.subText;
      case AREA_SUBMIT_ANSWER_TEXT:
        return selectedPage.submitAnswerText;
      default:
        // eslint-disable-next-line max-len
        return currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].text : null;
    }
  };

  const onTextApply = async (textLines) => {
    const newTextLines = textLines.map((e) => ({
      ...e,
    }));

    if ([AREA_PROMPT_TEXT, AREA_SUB_TEXT, AREA_SUBMIT_ANSWER_TEXT].includes(currentSelectedData.selectedArea)) {
      if (!selectedPage[currentSelectedData.selectedArea]) {
        selectedPage[currentSelectedData.selectedArea] = {};
      }
      selectedPage[currentSelectedData.selectedArea].lines = newTextLines;
    } else {
      const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
      if (!selectedCard.text) {
        selectedCard.text = {};
      }
      selectedCard.text.lines = newTextLines;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemoved = async () => {
    if ([AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(currentSelectedData.selectedArea)) {
      selectedPage[currentSelectedData.selectedArea] = null;
    } else if (AREA_SUBMIT_ANSWER_TEXT === currentSelectedData.selectedArea) {
      selectedPage[currentSelectedData.selectedArea] = {
        ...defaultSubmitText,
      };
    } else {
      selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].text = null;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }

    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }

    if (currentSelectedData.selectedArea === AREA_PROMPT_TEXT) {
      const hasNon18FontSize = checkHasNon18FontSize(textLines);
      if (hasNon18FontSize) {
        closeTextEditorWarning();
        return;
      }
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 44;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_SUB_TEXT) {
      const hasNon22FontSize = checkHasNonSpecificFontSize(textLines, '22px');
      if (hasNon22FontSize) {
        closeTextEditorWarning();
        return;
      }
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 78;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      if (responseCards.length === 4 || responseCards.length === 6) {
        const hasNon18FontSize = checkHasNon18FontSize(textLines);
        if (hasNon18FontSize) {
          closeTextEditorWarning();
          return;
        }

        const charLimit = responseCards.length === 4 ? 40 : 22;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      if (promptCards.length === 1) {
        const hasNon18FontSize = checkHasNon18FontSize(textLines);
        if (hasNon18FontSize) {
          closeTextEditorWarning();
          return;
        }
        const charLimit = 882;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      } else if (promptCards.length === 3) {
        const hasNon18FontSize = checkHasNon18FontSize(textLines);
        if (hasNon18FontSize) {
          closeTextEditorWarning();
          return;
        }

        const isLargeSelected = (selectedPage.layout === layoutDropdownValues[1].id && currentSelectedData.selectedCardIndex === 0)
          || (selectedPage.layout === layoutDropdownValues[2].id && currentSelectedData.selectedCardIndex === 2);

        const charLimit = isLargeSelected ? 196 : 88;
        const exceedCharLimit = checkCharsLimit(textLines, charLimit);
        if (exceedCharLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      } else if (promptCards.length === 4) {
        const hasNon18FontSize = checkHasNon18FontSize(textLines);
        if (hasNon18FontSize) {
          closeTextEditorWarning();
          return;
        }
        const charLimit = 88;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    }
    closeTextEditorWarning();

    // if (currentSelectedData.selectedArea === AREA_PROMPT_TEXT) {
    //   const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //   if (hasNon18FontSize) {
    //     closeTextEditorWarning();
    //     return;
    //   }
    //   const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
    //   if (!hasNonBoldFontWeight) {
    //     const lineLimit = 2;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 22;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // } else if (currentSelectedData.selectedArea === AREA_SUB_TEXT) {
    //   const hasNon22FontSize = checkHasNonSpecificFontSize(textLines, '22px');
    //   if (hasNon22FontSize) {
    //     closeTextEditorWarning();
    //     return;
    //   }
    //   const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
    //   if (!hasNonBoldFontWeight) {
    //     const lineLimit = 2;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 39;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
    //   if (responseCards.length === 4 || responseCards.length === 6) {
    //     const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //     if (hasNon18FontSize) {
    //       closeTextEditorWarning();
    //       return;
    //     }

    //     const lineLimit = responseCards.length === 4 ? 2 : 1;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = responseCards.length === 4 ? 20 : 22;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // } else if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
    //   if (promptCards.length === 1) {
    //     const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //     if (hasNon18FontSize) {
    //       closeTextEditorWarning();
    //       return;
    //     }
    //     const lineLimit = 18;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 49;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   } else if (promptCards.length === 3) {
    //     const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //     if (hasNon18FontSize) {
    //       closeTextEditorWarning();
    //       return;
    //     }

    //     const lineLimit = 4;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const isLargeSelected = (selectedPage.layout === layoutDropdownValues[1].id && currentSelectedData.selectedCardIndex === 0)
    //       || (selectedPage.layout === layoutDropdownValues[2].id && currentSelectedData.selectedCardIndex === 2);

    //     const charLimit = isLargeSelected ? 49 : 22;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   } else if (promptCards.length === 4) {
    //     const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //     if (hasNon18FontSize) {
    //       closeTextEditorWarning();
    //       return;
    //     }
    //     const lineLimit = 4;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 22;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // }
    // closeTextEditorWarning();
  };

  useEffect(() => {
    if ([AREA_PROMPT_TEXT, AREA_SUB_TEXT, AREA_SUBMIT_ANSWER_TEXT].includes(currentSelectedData.selectedArea)) {
      const text = selectedPage[currentSelectedData.selectedArea];
      onTextChange(text && text.lines);
    } else {
      const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
      const { text } = selectedCard;
      onTextChange(text && text.lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  // eslint-disable-next-line max-len
  const getImage = () => (currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].image : null);

  const onImageAndVideoSelected = async (media) => {
    const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
    selectedCard.image = media.selectedImageUrl;
    selectedCard.thumbNailImage = media.selectedImageUrl;
    selectedCard.video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  // eslint-disable-next-line max-len
  const getAudio = () => (currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].audio : null);

  const handleOnAudioApply = async (audioData) => {
    selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <FlexibleMatchingEditor
            selectedArea={currentSelectedData.selectedArea}
            selectedSection={currentSelectedData.selectedSection}
            selectedCardIndex={currentSelectedData.selectedCardIndex}
            onAreaAndSectionSelected={onAreaAndSectionSelected}
            questionKey={selectedPage.key}
            onQuestionKeyChange={handleOnQuestionKeyChange}
            promptText={selectedPage.promptText}
            layout={selectedPage.layout}
            promptCards={promptCards}
            subText={selectedPage.subText}
            responseCards={responseCards}
            onAnswerKeyChange={onAnswerKeyChange}
            submitAnswerText={selectedPage.submitAnswerText}
          />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={getText()}
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemoved}
                  onTextChange={onTextChange}
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {(currentSelectedData.selectedSection === SECTION_IMAGE) && (
              <AddPhotoOrVideo
                image={getImage()}
                onSelect={onImageAndVideoSelected}
                onRemove={() => { onImageAndVideoSelected({}); }}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={getAudio()}
                onAudioApply={(audioData) => { handleOnAudioApply(audioData); }}
                onAudioRemoved={() => { handleOnAudioApply(null); }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import './BookCardEditor.scss';
import CardEditor from '../common/card-editor/CardEditor';
import {
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_NONE,
  SECTION_TEXT,
} from '../common/CardEditorConstants';
import CustomResponseEditor from './components/CustomResponseEditor';

/**
 * Lesson activity for Book
 */
export default function BookCardEditor({
  layoutConfig,
  selectedPageIndex,
  selectedSection,
  onImageSelected,
  onTextSelected,
  onAudioSelected,
  onCustomResponseSelect,
  onCustomResponseOptionSelect,
  onFfImageCountChange,
}) {
  return (
    <div data-test='book-card-editor' className='book-card-editor-component vizzle-book-card-editor'>
      {
        layoutConfig.map((c, index) => {
          const caredId = uuidv1();
          return (
            <CardEditor
              data-test={`book-card-editor-${index}`}
              key={caredId}
              className={`vizzle-book-card-editor-page-${index}`}
              config={{
                text: c.allowCustomResponse ? c.freetextInstructions : c.text,
                image: c.image,
                video: c.video,
                audio: c.audio,
              }}
              isSelected={selectedPageIndex === index}
              selectedSection={selectedSection}
              onImageSelected={() => {
                onImageSelected(index, SECTION_IMAGE);
              }}
              onTextSelected={() => {
                onTextSelected(index, SECTION_TEXT);
              }}
              onAudioSelected={() => {
                if (c.allowCustomResponse) {
                  return;
                }
                onAudioSelected(index, SECTION_AUDIO);
              }}
              textButtonLabel={c.allowCustomResponse ? 'Free Text Instruction' : 'Text'}
              disableAudio={c.allowCustomResponse}
            >
              {onCustomResponseSelect && layoutConfig.length > 1 && index === layoutConfig.length - 1 && (
                <CustomResponseEditor
                  index={index}
                  allowCustomResponse={c.allowCustomResponse || false}
                  customResponseOption={c.customResponseOption}
                  ffImageCount={c.ffImageCount}
                  onCustomResponseSelect={(e) => { onCustomResponseSelect(index, e.target.checked); }}
                  onCustomResponseOptionSelect={(e) => { onCustomResponseOptionSelect(index, e.target.value); }}
                  onFfImageCountChange={(_e, value) => { onFfImageCountChange(index, value); }}
                />
              )}

            </CardEditor>
          );
        })
      }
    </div>
  );
}

BookCardEditor.defaultProps = {
  layoutConfig: [],
  selectedPageIndex: 0,
  selectedSection: SECTION_IMAGE,
  onImageSelected: () => { },
  onTextSelected: () => { },
  onAudioSelected: () => { },
  onCustomResponseSelect: undefined,
  onCustomResponseOptionSelect: undefined,
  onFfImageCountChange: undefined,
};

BookCardEditor.propTypes = {
  layoutConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    freetextInstructions: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  selectedPageIndex: PropTypes.number,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO, SECTION_NONE]),
  onImageSelected: PropTypes.func,
  onTextSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
  onCustomResponseSelect: PropTypes.func,
  onCustomResponseOptionSelect: PropTypes.func,
  onFfImageCountChange: PropTypes.func,
};

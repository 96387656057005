import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NewVizzleIframe from '../../components/vizzle/container/NewVizzleIframe';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import './SearchLessonsV2.scss';
import { useDomain } from '../../states/AppDomainProvider';
import { useTrackTeacherActivityPreview } from '../lessons/lessonactions/hooks/TeacherTrackingActivityHooks';
import LessonPlay from '../lessonplay/LessonPlay';
import PrintLessonMenuItem from '../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import ObjectUtils from '../../utils/ObjectUtils';

export default function SearchLessonsV2() {
  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const [open, setOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [openPrint, setOpenPrint] = useState(false);
  const [lesson, setLesson] = useState();
  useTrackTeacherActivityPreview(open, user, lesson);
  const printMenuItemRef = useRef(null);
  const vizzleIFrameContainerRef = useRef(null);

  const location = useLocation();
  useEffect(() => {
    vizzleIFrameContainerRef.current.postMessageToIFrame('search-lessons');
  }, [location]);

  useEffect(() => {
    const register = (e) => {
      const { data } = e;
      if (data.event === 'preview-lesson') {
        setIsPreviewMode(true);
        setLesson(data.data);
        setOpen(true);
      } else if (data.event === 'play-lesson') {
        setIsPreviewMode(false);
        setLesson(data.data);
        setOpen(true);
      } else if (data.event === 'print-lesson') {
        setLesson(data.data);
        setOpenPrint(true);
        ObjectUtils.setTimeout(() => {
          printMenuItemRef.current.click();
        }, 500);
      }
    };
    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <VizzleMainContainer className='main-with-background-container flex-box'>
        <div className='search-lessons-container-v2'>
          <TeacherTopAppbar />
          <div className='content'>
            <NewVizzleIframe
              path='/search-lessons'
              payload={{}}
              ref={vizzleIFrameContainerRef}
            />
          </div>
        </div>
      </VizzleMainContainer>
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          isPreviewMode={isPreviewMode}
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
        />
      )}
      {openPrint && (
        <Box sx={{ display: 'none' }}>
          <PrintLessonMenuItem
            ref={printMenuItemRef}
            lesson={lesson}
            currentUserProfile={userProfile}
            onAfterPrint={() => {
              setOpenPrint(false);
            }}
          />
        </Box>
      )}
    </>

  );
}

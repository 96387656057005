import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import MatchingEditor from '../../../../../components/vizzle/page/matching/MatchingEditor';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { AREA_PROMPT_CARDS, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../../../../../components/vizzle/page/common/CardEditorConstants';
import { useHandleMatchingQuestionKey } from '../../hooks/LessonMaintenanceHooks';
import {
  checkHasNon18FontSize,
  checkHasNonJosefinSlab,
  getExceedCharLimitMessage,
  getTotalCharsLength,
  useHandleTextEditorWarning,
} from '../../hooks/TextEditorWarningHooks';

/**
 * Container for adding text, image, video and audio for Matching page
 */
export default function MatchingTextAndMedia() {
  const { lessonDataMaintenanceDomain, uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];
  const initState = {
    selectedArea: AREA_PROMPT_CARDS,
    selectedCardIndex: 0,
    selectedSection: SECTION_IMAGE,
  };

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const handleMatchingQuestionKey = useHandleMatchingQuestionKey();

  const matchingEditorProps = {
    selectedSection: {
      ...currentSelectedData,
    },
    promptCards: [{
      text: selectedPage.promptCards[0].text,
      image: selectedPage.promptCards[0].image,
      audio: selectedPage.promptCards[0].audio,
      video: selectedPage.promptCards[0].video,
      questionKey: selectedPage.promptCards[0].questionKey,
    }],

    responseCards: selectedPage.responseCards.filter((card) => (
      !card.hide
    )).map((card, index) => ({
      text: card.text,
      image: card.image,
      audio: card.audio,
      video: card.video,
      answerKey: selectedPage.responseCards[index].answerKey,
    })),

    onCardSelected: (selectedSection) => {
      setCurrentSelectedData(selectedSection);
    },

    onQuestionKeyChange: async (questionKeys) => {
      let hasChange = false;

      questionKeys.forEach((key, index) => {
        const questionKey = selectedPage.promptCards[index].questionKey || '';
        hasChange = hasChange || questionKey !== key;
      });
      if (!hasChange) {
        return;
      }

      questionKeys.forEach((key, index) => {
        selectedPage.promptCards[index].questionKey = key;
      });

      handleMatchingQuestionKey(questionKeys[0]);
    },

    onAnswerKeyChange: async (answerKeys) => {
      answerKeys.forEach((key, index) => {
        selectedPage.responseCards[index].answerKey = key;
      });
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    },
  };

  useEffect(() => {
    // When the user select a new page, reset the current selected matching to the first one
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  useEffect(() => {
    if (!selectedPage.promptCards[0].questionKey) {
      uiDomain.blockScreen(true);
    } else {
      uiDomain.blockScreen(false);
    }
    return () => {
      uiDomain.blockScreen(false);
    };
    // eslint-disable-next-line
  }, [selectedPage.promptCards[0].questionKey]);

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }
    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }
    const hasNon18FontSize = checkHasNon18FontSize(textLines);
    if (hasNon18FontSize) {
      closeTextEditorWarning();
      return;
    }

    const charLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 52 : 36;
    const totalCharsLength = getTotalCharsLength(textLines);
    if (totalCharsLength > charLimit) {
      showTextEditorWarning(getExceedCharLimitMessage(charLimit));
      return;
    }
    closeTextEditorWarning();
    // if (!hasNonJosefinSlab) {
    //   const hasNon18FontSize = checkHasNon18FontSize(textLines);
    //   if (!hasNon18FontSize) {
    //     const lineLimit = 2;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = currentSelectedData.selectedArea === AREA_PROMPT_CARDS ? 26 : 18;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // }
    // closeTextEditorWarning();
  };

  useEffect(() => {
    const { text } = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
    onTextChange(text && text.lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  const onTextApply = async (textLines) => {
    let field = 'responseCards';
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
    }

    const { text } = selectedPage[field][currentSelectedData.selectedCardIndex];
    const currentTextLines = text ? text.lines : [];
    const newTextLines = textLines.map((e, index) => ({
      ...currentTextLines[index],
      ...e,
    }));
    if (!selectedPage[field][currentSelectedData.selectedCardIndex].text) {
      selectedPage[field][currentSelectedData.selectedCardIndex].text = {};
    }
    selectedPage[field][currentSelectedData.selectedCardIndex].text.lines = newTextLines;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemove = async () => {
    let field = 'responseCards';
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
    }

    const card = {
      ...selectedPage[field][currentSelectedData.selectedCardIndex],
      text: null,
    };
    selectedPage[field][currentSelectedData.selectedCardIndex] = card;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const onAudioApply = async (audioData) => {
    let field = 'responseCards';
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
    }

    selectedPage[field][currentSelectedData.selectedCardIndex].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    let field = 'responseCards';
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
    }

    selectedPage[field][currentSelectedData.selectedCardIndex].audio = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageApply = async (media) => {
    let field = 'responseCards';
    let thumbnailIndex = 0;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
      thumbnailIndex = currentSelectedData.selectedCardIndex + 1;
    }

    selectedPage[field][currentSelectedData.selectedCardIndex].image = media.selectedImageUrl;
    selectedPage[field][currentSelectedData.selectedCardIndex].thumbNailImage = media.selectedImageUrl;
    selectedPage[field][currentSelectedData.selectedCardIndex].video = media.selectedVideoUrl;
    selectedPage.thumbnailImages[thumbnailIndex] = media.selectedImageUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    let field = 'responseCards';
    let thumbnailIndex = 0;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      field = 'promptCards';
      thumbnailIndex = currentSelectedData.selectedCardIndex + 1;
    }
    selectedPage[field][currentSelectedData.selectedCardIndex].image = null;
    selectedPage[field][currentSelectedData.selectedCardIndex].thumbNailImage = null;
    selectedPage[field][currentSelectedData.selectedCardIndex].video = null;
    selectedPage.thumbnailImages[thumbnailIndex] = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <MatchingEditor {...matchingEditorProps} />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={
                    currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                      ? selectedPage.promptCards[currentSelectedData.selectedCardIndex] && selectedPage.promptCards[currentSelectedData.selectedCardIndex].text
                      : selectedPage.responseCards[currentSelectedData.selectedCardIndex] && selectedPage.responseCards[currentSelectedData.selectedCardIndex].text
                  }
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemove}
                  onTextChange={onTextChange}
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {currentSelectedData.selectedSection === SECTION_IMAGE && (
              <AddPhotoOrVideo
                image={
                  currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                    ? selectedPage.promptCards[currentSelectedData.selectedCardIndex] && selectedPage.promptCards[currentSelectedData.selectedCardIndex].image
                    : selectedPage.responseCards[currentSelectedData.selectedCardIndex] && selectedPage.responseCards[currentSelectedData.selectedCardIndex].image
                }
                onSelect={onImageApply}
                onRemove={onImageRemove}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={
                  currentSelectedData.selectedArea === AREA_PROMPT_CARDS
                    ? selectedPage.promptCards[currentSelectedData.selectedCardIndex] && selectedPage.promptCards[currentSelectedData.selectedCardIndex].audio
                    : selectedPage.responseCards[currentSelectedData.selectedCardIndex] && selectedPage.responseCards[currentSelectedData.selectedCardIndex].audio
                }
                onAudioApply={onAudioApply}
                onAudioRemoved={onAudioRemoved}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

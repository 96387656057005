import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@mui/material';
import FillInTheBlankEditor from '../../../../../../../components/vizzle/page/fitb/FillInTheBlankEditor';
import FillInTheBlankPreview from '../../../../../../../components/vizzle/page/fitb/FillInTheBlankPreview';
import { splitQuestionText, BLANK_SPACE } from '../../../../../../../components/vizzle/page/fitb/FillInTheBlankUtils';

const MAX_TEXT_LENGTH = 255;
const FillInTheBlankDialogEditor = ({ data, onDataChange, isTtsTextAvailable }) => {
  const { question } = data;
  const questionText = question ? question.question : BLANK_SPACE;
  const parts = splitQuestionText(questionText);

  const handleOnPreBlankChange = (event) => {
    const value = event.target.value.substring(0, MAX_TEXT_LENGTH);
    onDataChange({
      ...data,
      question: {
        question: `${value}${BLANK_SPACE}${parts.postBlank}`,
      },
    });
  };

  const handleOnPostBlankChange = (event) => {
    const value = event.target.value.substring(0, MAX_TEXT_LENGTH);
    onDataChange({
      ...data,
      question: {
        question: `${parts.preBlank}${BLANK_SPACE}${value}`,
      },
    });
  };

  const handleOnAnswerAdded = (newAnswer) => {
    const { answers } = data;
    const updatedAnswers = answers || [];

    // Ignore the new answer when it's already in the list
    const index = updatedAnswers.findIndex((a) => a === newAnswer);
    if (index >= 0) {
      return;
    }
    onDataChange({
      ...data,
      answers: [
        ...updatedAnswers,
        newAnswer,
      ],
    });
  };

  const handleOnAnswerDelete = (answerToDelete) => {
    const { answers } = data;
    const updatedAnswers = answers.filter((answer) => (
      answer !== answerToDelete
    ));
    onDataChange({
      ...data,
      answers: [
        ...updatedAnswers,
      ],
    });
  };

  return (
    <Grid container spacing={3} wrap='nowrap' style={{ height: 'inherit', padding: '8px' }}>
      <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit' }}>
        <Box width='100%' height='100%' my='auto'>
          <FillInTheBlankEditor
            preBlank={parts.preBlank}
            postBlank={parts.postBlank}
            answers={data.answers}
            onPreBlankChange={handleOnPreBlankChange}
            onPostBlankChange={handleOnPostBlankChange}
            onAnswerAdd={handleOnAnswerAdded}
            onAnswerDelete={handleOnAnswerDelete}
            fitbData={data}
            isTtsTextAvailable={isTtsTextAvailable}
          />
        </Box>
      </Grid>
      <Grid item xs={5} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit' }}>
        <Box width='100%' height='100%' my='auto'>
          <FillInTheBlankPreview
            question={questionText}
          />

        </Box>
      </Grid>
    </Grid>

  );
};

FillInTheBlankDialogEditor.defaultProps = {
  data: {
    question: {
      question: BLANK_SPACE,
    },
    answers: [],
  },
  onDataChange: () => { },
  isTtsTextAvailable: false,
};

FillInTheBlankDialogEditor.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.shape({
      question: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
  }),
  onDataChange: PropTypes.func,
  isTtsTextAvailable: PropTypes.bool,
};

export default FillInTheBlankDialogEditor;

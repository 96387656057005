import BaseDomain from '../../../states/domains/BaseDomain';

const SWITCH_ACCESS_ELEMENTS_UPDATE_ELEMENT = 'SWITCH_ACCESS_ELEMENTS_UPDATE_ELEMENT';

const initialState = {
  elementsForSwitch: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case SWITCH_ACCESS_ELEMENTS_UPDATE_ELEMENT:
      return {
        ...state,
        elementsForSwitch: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'switchAccessElementDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class SwitchAccessDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateElementToScan(elementsForSwitch) {
    return this.dispatch({
      type: SWITCH_ACCESS_ELEMENTS_UPDATE_ELEMENT,
      payload: elementsForSwitch,
    });
  }
}

import { useEffect } from 'react';
import ExtensionActivitiesService from '../../../../../../services/ExtensionActivitiesService';
import Logger from '../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';

export const useGetExtensionActivities = () => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { extensionActivitiesDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { topicId } = searchCriteria;

  const handleGetExtensionActivities = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (topicId) {
          const extensitionActivities = await ExtensionActivitiesService.getExtensionActivities(topicId);
          const activities = (extensitionActivities && extensitionActivities.activities) || { activities: [] };
          const { selectedActivityIds } = extensionActivitiesDomain.domainData;

          const ids = activities.map((a) => a.id);
          const newSelectedIds = ids.filter((i) => selectedActivityIds.includes(i));
          extensionActivitiesDomain.updateExtensionActivitiesDataAndSelectedId(activities, newSelectedIds);
        }
      });
    } catch (e) {
      extensionActivitiesDomain.updateExtensionActivitiesDataAndSelectedId([], []);
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    handleGetExtensionActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  return { loading };
};

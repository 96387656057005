import { useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { useAdmin } from '../../../../context/AdminContextProvider';

export const useGetSchoolSelectorTable = ({
  initSelectedSchoolIds,
  disabledSchoolIds,
  onDataChange,
  sortBySelectedIds,
  selectedDistrictIds,
}) => {
  const [internalData, setInternalData] = useState([...initSelectedSchoolIds]);
  const { userDomain } = useDomain();
  const { userProfile: { buildings } } = userDomain.domainData;
  const [tableDataState, setTableDataState] = useState(() => (
    buildings.map((b) => ({
      id: b.schoolId,
      schoolName: b.schoolName,
      isSelected: initSelectedSchoolIds.includes(b.schoolId) || disabledSchoolIds.includes(b.schoolId),
    }))
  ));

  useEffect(() => {
    setInternalData([...initSelectedSchoolIds]);
    let filteredBuildings = buildings;
    if (selectedDistrictIds && selectedDistrictIds.length > 0) {
      filteredBuildings = buildings.filter((b) => selectedDistrictIds.includes(b.districtId));
    }
    const schoolTableData = filteredBuildings.map((b) => ({
      id: b.schoolId,
      schoolName: b.schoolName,
      isSelected: initSelectedSchoolIds.includes(b.schoolId) || disabledSchoolIds.includes(b.schoolId),
    }));

    if (sortBySelectedIds) {
      schoolTableData.sort((a, b) => (b.isSelected) - (a.isSelected) || a.schoolName.localeCompare(b.schoolName), undefined, { numeric: true, sensitivity: 'base' });
    }
    setTableDataState(schoolTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSelectedSchoolIds, selectedDistrictIds]);

  useEffect(() => {
    setTableDataState(tableDataState.map((t) => ({
      ...t,
      isSelected: internalData.includes(t.id) || disabledSchoolIds.includes(t.id),
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalData]);

  const tableConfig = [
    {
      title: '',
      align: 'center',
      width: '10%',
      dataField: 'isSelected',
      dataRenderer: (data) => {
        if (data.isSelected) {
          const isDisabled = disabledSchoolIds.includes(data.id);
          return (
            <CheckBoxIcon
              className={`vizzle-checkbox ${isDisabled ? 'disabled' : 'selectable'}`}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                const updatedData = internalData.filter((p) => p !== data.id);
                setInternalData(updatedData);
                onDataChange(updatedData);
              }}
            />
          );
        } else {
          return (
            <CheckBoxOutlineBlankIcon
              className='vizzle-checkbox selectable'
              onClick={() => {
                const updatedData = [
                  ...internalData,
                  data.id,
                ];
                setInternalData(updatedData);
                onDataChange(updatedData);
              }}
            />
          );
        }
      },
    },
    {
      title: 'Select School(s)',
      align: 'left',
      dataField: 'schoolName',
      width: '90%',
    },
  ];

  return {
    tableConfig,
    schoolTableData: tableDataState,
  };
};

export const useHandleSelectedDistrict = () => {
  const { districtsDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  const [selectedDistrictIds, setSelectedDistrictIds] = useState(() => (districts && districts.length === 1 ? [districts[0].id] : []));
  const onFilterDistrictChange = (values) => {
    if (!values || values.length === 0) {
      setSelectedDistrictIds([]);
    }
    setSelectedDistrictIds(values.map((d) => d.key));
  };

  return {
    selectedDistrictIds,
    onFilterDistrictChange,
  };
};

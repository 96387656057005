import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getActivityCardPlay } from '../../utils/LessonActivityPlayUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import './LessonPagesContainer.scss';
import LessonPlayActivityUtils from '../../activities/utils/LessonPlayActivityUtils';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';

/**
 * Play Lesson Action Container
 */
// eslint-disable-next-line no-empty-pattern
const LessonPagesContainer = forwardRef(({
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onInteractionOpen,
  onInstructionFinished,
  onTriggerSwitchScan,
  registeredLessonRef,
  studentHistoryId,
}, ref) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { lessonPagesData, currentPageIndex, isPlayable, freeformAnswerData } = lessonPlayDomain.domainData;
  const currentSlide = useRef(currentPageIndex);
  const sliderRef = useRef(null);
  const lessonPlaySlickSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'lesson-pages-container-slider',
    swipe: false,
    initialSlide: currentPageIndex,
    // lazyLoad: 'ondemand',
    beforeChange: () => { LessonPlayActivityUtils.stopMedias(); },
    afterChange: (current) => {
      currentSlide.current = current;
      lessonPlayDomain.updateCurrentPageIndex(current);
    },
    ref: sliderRef,
    accessibility: false,
  };

  const lessonPageElementsRef = useRef([]);
  const setLessonPageElementRef = (r, index) => {
    if (r) {
      lessonPageElementsRef.current[index] = r;
    }
  };

  useImperativeHandle(ref, () => ({
    getSliderRef: () => sliderRef.current,
    next: () => {
      sliderRef.current.slickGoTo(currentSlide.current + 1);
    },
    previous: () => {
      sliderRef.current.slickGoTo(currentSlide.current - 1);
    },
    getEnableElements: (index) => (lessonPageElementsRef.current[index].getEnableElements()),
    skipAppBar: (index) => {
      if (lessonPageElementsRef.current[index].skipAppBar) {
        return lessonPageElementsRef.current[index].skipAppBar();
      }
      return false;
    },
  }));

  return (
    <div className='lesson-pages-container'>
      <Slider {...lessonPlaySlickSettings}>
        {lessonPagesData.map((page, index) => (
          <div className='activity-container' key={`lesson-play-activity-container-${index}`}>
            <TransparentBlocker show={!isPlayable} />
            <div className='center' data-test={`activity-container-${index}-${page.activityType}`}>
              {getActivityCardPlay({
                page,
                isCurrentPage: isPlayable && index === currentPageIndex,
                onCorrectAnswerSelected,
                onIncorrectAnswerSelected,
                onActivityFinished,
                onInteractionOpen,
                onInstructionFinished,
                setLessonPageElementRef,
                index,
                onTriggerSwitchScan,
                isPreview: false,
                registeredLessonRef,
                isPlayable,
                studentHistoryId,
                freeformAnswerData,
              })}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});

LessonPagesContainer.defaultProps = {
  isPreviewMode: false,
  onLessonFinished: () => { },
  onExit: () => { },
  onActivityFinished: () => { },
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onInteractionOpen: () => { },
  onInstructionFinished: () => { },
  onTriggerSwitchScan: () => { },
  registeredLessonRef: null,
  studentHistoryId: null,
};

LessonPagesContainer.propTypes = {
  isPreviewMode: PropTypes.bool,
  onLessonFinished: PropTypes.func,
  onExit: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onInteractionOpen: PropTypes.func,
  onInstructionFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
  registeredLessonRef: PropTypes.any,
  studentHistoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LessonPagesContainer;

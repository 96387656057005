import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import SelectableCard from '../../../../../components/card/SelectableCard';
import './ThemeSetting.scss';

const ThemeSettingV2 = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Theme');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile, terminologies } = myStudentDomain.domainData;
  const [selectedTheme, setSelectedTheme] = useState(selectedStudentProfile.playerWorld);

  // When mounting and changing a student.
  useEffect(() => {
    setSelectedTheme(selectedStudentProfile.playerWorld);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudentProfile.id]);

  const onCardSelected = (itemCode) => {
    setSelectedTheme(itemCode);
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      playerWorld: itemCode,
    });
  };

  const styleChecked = (itemCode) => ((itemCode === selectedTheme) ? 'theme-setting-is-selected' : '');

  return (
    <Box display='flex' flexDirection='row' flexWrap='wrap' className='theme-mystudent-setting-container' data-test='theme-mystudent-setting-container'>
      {terminologies && terminologies.themes
        ? terminologies.themes.map((item) => (item.name ? (
          <Box key={item.code} className={`theme-setting-el-box ${styleChecked(item.code)}`} data-test={`theme-thumbnail-${item.name}`}>
            <SelectableCard
              name={item.name}
              thumbnail={item.thumbnail}
              onClick={() => onCardSelected(item.code)}
              checked={(item.code === selectedTheme)}
              thumbnailAsColor={item && item.manifest[0] && item.manifest[0].id === 'color'}
            />
          </Box>
        ) : null))
        : null}
    </Box>
  );
};

export default ThemeSettingV2;

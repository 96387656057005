import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SelectableCard from '../../../components/card/SelectableCard';
import CelebrationDialog from '../../terminologies/celebration/CelebrationDialog';
import CelebrationService from '../../terminologies/celebration/CelebrationService';
import './CelebrationList.scss';

const CelebrationList = ({ profile, terminologies, onChangeCallback }) => {
  const [selectedCelebrations, setSelectedCelebrations] = useState(profile.celebrations);
  const [currentSelectedCelebration, setCurrentSelectedCelebration] = useState(null);

  // When mounting and changing a student.
  useEffect(() => {
    setSelectedCelebrations(profile.celebrations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  /**
   *  Current student profile have a celebration array value starting from 1 to celebration’s length
   *  whereas indexing of for-loop begins with 0. so it needs to add +1 to be the same format as the celebration’s profile
   *  in order to check whether the selected index is in the celebration’s profile.
   */
  const onCardSelected = (index) => {
    const value = index + 1;
    let celebration = [];
    if (selectedCelebrations.includes(value)) {
      celebration = selectedCelebrations.filter((item) => item !== value);
      setSelectedCelebrations(celebration);
    } else {
      selectedCelebrations.push(value);
      celebration = selectedCelebrations;
      setSelectedCelebrations(selectedCelebrations);
    }
    onChangeCallback(celebration);
  };

  const styleChecked = (index) => (selectedCelebrations.includes(index + 1) ? 'celebration-is-selected' : '');

  const onMediaClick = (celebrationItem, index) => {
    setCurrentSelectedCelebration(index);
    CelebrationService.playCelebration(celebrationItem.id);
  };

  return (
    <>
      <Box display='flex' flexDirection='row' flexWrap='wrap' className='celebration-setting-container' data-test='celebration-setting-container'>
        {terminologies && terminologies.celebrations
          ? terminologies.celebrations.map((item, index) => (
            <Box key={index} className={`celebration-el-box ${styleChecked(index)}`} data-test={`celebration-thumbnail-${item.name}`}>
              <SelectableCard
                name={item.name}
                thumbnail={item.thumbnail}
                onClick={() => onCardSelected(index)}
                checked={selectedCelebrations.includes(index + 1)}
                onMediaClick={() => onMediaClick(item, index)}
              />
            </Box>
          ))
          : null}
      </Box>
      <CelebrationDialog
        celebrationList={currentSelectedCelebration != null ? [terminologies.celebrations[currentSelectedCelebration]] : []}
        enableAudio={profile.celebrationSound}
      />
    </>
  );
};

CelebrationList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default CelebrationList;

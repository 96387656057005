import { useEffect, useRef } from 'react';
import { useSwitchAccess } from './context/SwitchAccessContextProvider';
import Logger from '../../utils/Logger';
import ObjectUtils from '../../utils/ObjectUtils';
import LessonPlayActivityUtils from '../lessonplay/activities/utils/LessonPlayActivityUtils';
import { useLessonPlay } from '../lessonplay/context/LessonPlayContextProvider';

const KEY_SELECT = 13; // Enter key
const KEY_SCAN = 32; // Space bar key
const CLASS_SWITCH_SELECTED_COMPONENT = 'switch-selected-component';

const createElementList = (elementsObject) => {
  const elements = [];
  if (elementsObject.topBar && elementsObject.topBar.length > 0) {
    elements.push(...elementsObject.topBar);
  }

  if (elementsObject.body && elementsObject.body.length > 0) {
    elements.push(...elementsObject.body);
  }
  if (elementsObject.bottomBar && elementsObject.bottomBar.length > 0) {
    elements.push(...elementsObject.bottomBar);
  }
  return elements;
};

const removeSelectedElementClass = () => {
  const currentElement = document.querySelectorAll(`.${CLASS_SWITCH_SELECTED_COMPONENT}`);
  if (currentElement && currentElement.length > 0) {
    currentElement.forEach((e) => {
      e.classList.remove(CLASS_SWITCH_SELECTED_COMPONENT);
    });
  }
};

const addClassToTheSelectedElement = (element) => {
  removeSelectedElementClass();
  if (element) {
    element.classList.add('switch-selected-component');
  }
};

export const useRegisterSwitchKeys = (switchAccessibility) => {
  const { switchAccessDomain, switchAccessElementDomain } = useSwitchAccess();
  const { hasDialogShown } = switchAccessDomain.domainData;
  const { elementsForSwitch } = switchAccessElementDomain.domainData;

  const currentSelectedIndex = useRef(-1);
  const continueFromIndex = useRef(-1);
  useEffect(() => {
    if (!switchAccessibility) {
      return undefined;
    }

    let keyDownEventListener = null;

    if (hasDialogShown) {
      const elements = createElementList(elementsForSwitch);
      if (!elements || elements.length === 0) {
        Logger.logWhenDebugModeIsOn('No elements to register the event');
        return undefined;
      }

      Logger.logWhenDebugModeIsOn('Register switch access');
      currentSelectedIndex.current = -1;
      removeSelectedElementClass();

      keyDownEventListener = (e) => {
        e.preventDefault();
        if (KEY_SCAN === e.which) {
          LessonPlayActivityUtils.stopMedias();
          let newIndex = currentSelectedIndex.current;

          if (currentSelectedIndex.current < 0) {
            if (continueFromIndex.current >= 0) {
              newIndex = continueFromIndex.current;
              continueFromIndex.current = -1;
            } else {
              newIndex = elementsForSwitch.topBar ? elementsForSwitch.topBar.length : 0;
            }
          } else {
            const currentElement = elements[currentSelectedIndex.current];
            if (currentElement) {
              currentElement.dispatchEvent(new CustomEvent('switchAccessLeaveElement', {
                isSwitchAccess: true,
              }));
            }

            newIndex = currentSelectedIndex.current + 1;
          }
          if (newIndex >= elements.length) {
            newIndex = 0;
          }
          currentSelectedIndex.current = newIndex;

          const element = elements[currentSelectedIndex.current];
          addClassToTheSelectedElement(element);

          let el = element.querySelector('[data-switch-access-scan=\'true\']');
          if (element.dataset.switchAccessScan === 'true') {
            el = element;
          }
          if (el) {
            el.dispatchEvent(new CustomEvent('switchAccessScan', {
              detail: {
                isSwitchAccess: true,
                triggeredElement: element,
              },
            }));
          }
        } else if (KEY_SELECT === e.which) {
          const element = elements[currentSelectedIndex.current];
          if (element) {
            if (element.dataset && element.dataset.skipSwitchAccessClick === 'true') {
              element.dispatchEvent(new CustomEvent('switchAccessSelect', {
                isSwitchAccess: true,
              }));
            } else {
              element.click();
            }
          }
        }
      };
    } else {
      Logger.logWhenDebugModeIsOn('register spacebar to close the dialog');
      keyDownEventListener = (e) => {
        if (KEY_SCAN === e.which) {
          switchAccessDomain.updateShowDialog(false, true);

          ObjectUtils.setTimeout(() => {
            window.dispatchEvent(new KeyboardEvent('keydown', { keyCode: KEY_SCAN }));
          }, 100);
        } else {
          e.preventDefault();
        }
      };
    }

    if (keyDownEventListener) {
      window.addEventListener('keydown', keyDownEventListener);
    }

    return () => {
      Logger.logWhenDebugModeIsOn('unregister enter and spacebar key');
      if (keyDownEventListener) {
        window.removeEventListener('keydown', keyDownEventListener);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDialogShown, elementsForSwitch]);

  const selectElementByIndex = (index) => {
    if (index < 0) {
      return;
    }
    const { elementsForSwitch: elementsForSwitch2 } = switchAccessElementDomain.domainData;
    const elements = createElementList(elementsForSwitch2);
    const element = elements[index];
    currentSelectedIndex.current = index;
    addClassToTheSelectedElement(element);
  };

  const setContinueFromIndex = (index) => {
    if (index < 0) {
      return;
    }
    continueFromIndex.current = index;
  };

  return {
    currentSelectedIndex: switchAccessibility ? currentSelectedIndex : null,
    selectElementByIndex: switchAccessibility ? selectElementByIndex : () => { },
    setContinueFromIndex: switchAccessibility ? setContinueFromIndex : () => { },
  };
};

export const useShowSwitchAccessDialog = (switchAccessibility, hasInstruction, showDialogDelay = 4000) => {
  const { switchAccessDomain } = useSwitchAccess();

  const showSwitchAccessDialog = (delay = 500) => {
    if (!switchAccessibility) {
      return false;
    }

    ObjectUtils.setTimeout(() => {
      const { hasDialogShown } = switchAccessDomain.domainData;
      if (!hasDialogShown) {
        switchAccessDomain.updateShowDialog(true);
      }
    }, delay);
    return switchAccessDomain.domainData.hasDialogShown;
  };

  useEffect(() => {
    if (switchAccessDomain.domainData.isDialogShowing) {
      // Ensure it starts without showing the dialog
      switchAccessDomain.updateShowDialog(false, switchAccessDomain.domainData.hasDialogShown);
    }

    if (!hasInstruction && switchAccessibility) {
      showSwitchAccessDialog(showDialogDelay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showSwitchAccessDialog,
  };
};

export const useUpdateElementsToScan = () => {
  const { switchAccessElementDomain } = useSwitchAccess();
  const updateElementsToScan = async (elements) => {
    await switchAccessElementDomain.updateElementToScan(elements);
  };

  return {
    updateElementsToScan,
  };
};

export const useRegisterEvent = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility } = userProfile;

  const registerSwitchEvent = (eventName, elementRef, func) => {
    if (!switchAccessibility || switchAccessibility === 0) {
      return () => { };
    }

    const handleSwitchAccessScan = (params) => {
      func(params);
    };

    if (elementRef.current) {
      elementRef.current.addEventListener(eventName, handleSwitchAccessScan);
    }

    return () => {
      if (elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        elementRef.current.removeEventListener(eventName, handleSwitchAccessScan);
      }
    };
  };

  return {
    registerSwitchEvent,
  };
};

export const useRegisterSwitchAccessScanEvent = (elementRef, func = () => { }) => {
  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  const cleanupFunction = useRef(null);
  useEffect(() => {
    cleanupFunction.current = registerSwitchEvent('switchAccessScan', elementRef, func);

    return () => {
      cleanupFunction.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  return cleanupFunction.current;
};

export const useRegisterSwitchAccessSelectEvent = (elementRef, func = () => { }) => {
  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  useEffect(() => {
    const cleanupFunction = registerSwitchEvent('switchAccessSelect', elementRef, func);

    return () => {
      cleanupFunction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import OverlayMaskLoader from '../../../../components/loaders/OverlayMaskLoader';
import EditableLessonTitleContainer from './EditableLessonTitleContainer';
import SubjectSelector from './save/SubjectSelector';
import GradeSelector from './save/GradeSelector';
import LessonLevelSelector from './save/LessonLevelSelector';
import LessonStandardSelector from './save/LessonStandardSelector';
import LessonDescriptionEditor from './save/LessonDescriptionEditor';
import LessonStepperSubmitVizzleLibrary from '../actions/LessonStepperSubmitVizzleLibrary';
// import LessonStepperVizzleReviewer from '../actions/LessonStepperVizzleReviewer';
import LessonStepperCompleteReview from '../actions/LessonStepperCompleteReview';
import { getSubjectObjectBySubjectName } from '../../../../services/SubjectService';
import { sort, unique } from '../../../../utils/ArrayUtils';
import { useDomain } from '../../../../states/AppDomainProvider';
import { APPROVED_FOR_COURSES, LESSON_STATUS_PENDING_NEXTGEN, LESSON_STATUS_UNDER_REVIEW } from '../../../../AppConstants';
import { useGetLessonStandardsByUser } from '../useInitilizeDataForAuthoring';
import ModeratorKeywordsEditor from './save/ModeratorKeywordsEditor';
import LessonGradeLevelSelector from './save/LessonGradeLevelSelector';
import FeedbackLoopSaveAction from '../actions/feedback-loop/FeedbackLoopSaveAction';
import StateSelector from './save/StateSelector';

/**
 * Container for Lesson Save screen
 *
 * @export
 * @returns
 */
export default function LessonSave({ originalApprovalStatus }) {
  const { lessonDataMaintenanceDomain, lessonStandardDomain, userDomain } = useDomain();
  const { lessonData, isDirty } = lessonDataMaintenanceDomain.domainData;
  const isLessonStandardLoading = lessonStandardDomain.domainData.isLoading;
  const lessonStandards = useGetLessonStandardsByUser();
  const { user } = userDomain.domainData;

  const shouldSubmitVizzleBeDisabled = () => {
    const { attributes: { subjects } } = lessonData;
    return lessonDataMaintenanceDomain.domainData.isDirty
      || !subjects || subjects.length <= 0;
  };

  const [lessonStandardsState, setLessonStandardsState] = useState({
    lessonStandardsList: [],
    disabled: !lessonData.attributes.standards || lessonData.attributes.standards.length === 0,
    placeholderWhenEmpty: '',
    submitVizzleDisabled: shouldSubmitVizzleBeDisabled(),
  });

  const getLessonStandardList = () => {
    const { attributes: { subjects, grades } } = lessonData;
    if (subjects && subjects.length > 0 && grades && grades.length > 0) {
      const currentSubject = getSubjectObjectBySubjectName(subjects[0]);
      const uniqueLessonStandards = unique(lessonStandards, 'id')
        .filter((standard) => (
          standard.attributes.subject === currentSubject.subjectId
          && grades.indexOf(standard.attributes.standardGrade) > -1
        ))
        .map((standard) => (
          {
            id: standard.id,
            name: `(${standard.attributes.scope.toLowerCase() === 'other' ? 'CCSS' : standard.attributes.scope}) ${standard.attributes.standardCode} : ${standard.attributes.standardDescription}`,
            value: standard.id,
          }
        ));

      return sort(uniqueLessonStandards, 'name');
    }
    return [];
  };

  const onStandardSelected = () => {
    setLessonStandardsState({
      ...lessonStandardsState,
    });
  };

  useEffect(() => {
    if (!isLessonStandardLoading) {
      const lessonStandardsList = getLessonStandardList();
      const disabled = !lessonData.attributes.subjects || lessonData.attributes.subjects.length === 0
        || !lessonData.attributes.grades || lessonData.attributes.grades.length === 0
        || !lessonStandardsList || lessonStandardsList.length === 0;
      const { attributes: { subjects, grades } } = lessonData;
      setLessonStandardsState({
        ...lessonStandardsState,
        lessonStandardsList,
        disabled,
        submitVizzleDisabled: shouldSubmitVizzleBeDisabled(),
        placeholderWhenEmpty: subjects && subjects.length > 0 && grades && grades.length > 0 && (!lessonStandardsList || lessonStandardsList.length === 0)
          ? 'No standards available' : '',
      });
    }
    // eslint-disable-next-line
  }, [isDirty, lessonData.attributes.subjects, lessonData.attributes.grades, isLessonStandardLoading]);

  const isUserModeratorEditingPendingLesson = user.userModerator && [LESSON_STATUS_PENDING_NEXTGEN, APPROVED_FOR_COURSES].includes(originalApprovalStatus);

  const isSmallDescription = user.unitCreator || user.userReviewer || (
    (user.unitCreator || user.userModerator)
    && (originalApprovalStatus === null || originalApprovalStatus === undefined)
  ) || isUserModeratorEditingPendingLesson;

  return (
    <Box pt='10px' data-test='lesson-detail-container'>
      <Box display='flex'>
        <EditableLessonTitleContainer />
      </Box>
      <br />
      <br />
      {window.innerWidth <= 1024 ? (
        <div>
          <br />
        </div>
      ) : ''}

      <Grid container spacing={3} wrap='nowrap'>
        <Grid item xs={6}>
          {(user.userModerator || user.unitCreator) && (
            <Box width='33vw' mb='10px'>
              <StateSelector />
            </Box>
          )}
          <Box width='33vw'>
            <SubjectSelector />
          </Box>
          {isUserModeratorEditingPendingLesson && (
            <>
              <br />
              <Box width='33vw'>
                <LessonGradeLevelSelector />
              </Box>
            </>
          )}
          <Box width='33vw' height={`${isSmallDescription ? '110px' : '180px'}`}>
            <LessonDescriptionEditor rows={isSmallDescription ? 3 : 6} rowsMax={isSmallDescription ? 3 : 6} />
          </Box>
          <br />
          <Box width='33vw' mt='5px'>
            <LessonLevelSelector />
          </Box>
        </Grid>

        <Grid item xs={10}>
          <div>
            <Box width='32vw'>
              <GradeSelector />
            </Box>
            <br />
            <Box width='32vw'>
              <LessonStandardSelector
                lessonStandards={lessonStandardsState.lessonStandardsList}
                disabled={lessonStandardsState.disabled}
                onStandardSelected={onStandardSelected}
                placeholderWhenEmpty={lessonStandardsState.placeholderWhenEmpty}
              />
            </Box>
            <br /><br />

            {(
              (
                (user.userModerator || user.userModeratorWithDirectAccess) && [LESSON_STATUS_PENDING_NEXTGEN, APPROVED_FOR_COURSES].includes(originalApprovalStatus))
            )
              && (
                <Box width='32vw'>
                  <ModeratorKeywordsEditor />
                </Box>
              )}
            <FeedbackLoopSaveAction />
          </div>
        </Grid>
      </Grid>
      <br />
      {/* {user.unitCreator
        ? (
          <Box width='100%'>
            <Box width='30vw' mb='10px'>
              <LessonStepperVizzleReviewer
                disabled={lessonStandardsState.submitVizzleDisabled
                  && originalApprovalStatus !== LESSON_STATUS_UNDER_REVIEW}
              />
            </Box>
            {
              isDirty ? (
                <Box mt='auto' mb='auto'>
                  <Typography color='error' variant='h6'>
                    Please save all changes before submitting
                  </Typography>
                </Box>
              ) : ''
            }
          </Box>
        ) : ''} */}
      {user.userReviewer
        ? (
          <Box width='100%'>
            <Box width='30vw' mb='10px'>
              <LessonStepperCompleteReview
                disabled={
                  originalApprovalStatus !== LESSON_STATUS_UNDER_REVIEW
                }
              />
            </Box>
            {
              isDirty ? (
                <Box mt='auto' mb='auto'>
                  <Typography color='error' variant='h6'>
                    Please save all changes before submitting
                  </Typography>
                </Box>
              ) : ''
            }
          </Box>
        ) : ''}
      {
        (user.unitCreator || user.userModerator)
          && (lessonData.attributes.approvalStatus === null || lessonData.attributes.approvalStatus === undefined)
          ? (
            <Box width='100%'>
              <Box width='30vw' mb='5px'>
                <LessonStepperSubmitVizzleLibrary
                  disabled={lessonStandardsState.submitVizzleDisabled}
                />
              </Box>
              {
                isDirty ? (
                  <Box mt='auto' mb='auto'>
                    <Typography color='error' variant='h6'>
                      Please save all changes before submitting
                    </Typography>
                  </Box>
                ) : ''
              }
            </Box>
          ) : ''
      }

      <OverlayMaskLoader show={isLessonStandardLoading} loadingMessage='Please wait while activity standards are fetching.' />
    </Box>
  );
}

LessonSave.defaultProps = {
  originalApprovalStatus: undefined,
};

LessonSave.propTypes = {
  originalApprovalStatus: PropTypes.string,
};

import { useState } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import useInitilizeDataForAuthoring from '../../useInitilizeDataForAuthoring';
import { US_STATES } from '../../../../../constants/StateConstant';
import './StateSelector.scss';

export default function StateSelector() {
  const [standardState, setStandardState] = useState();
  const {
    getLessonStandardList: getLessonStandardListByState,
  } = useInitilizeDataForAuthoring();

  const handleOnStateChange = (e) => {
    setStandardState(e.target.value);
    getLessonStandardListByState(e.target.value);
  };

  return (
    <Box width='100%'>
      <Box display='flex'>
        <Typography variant='h6'>
          State
        </Typography>
      </Box>
      <FormControl variant='outlined' fullWidth>
        <Select
          name='state'
          value={standardState || 'none'}
          onChange={handleOnStateChange}
          className='lesson-save-state-selector'
        >
          <MenuItem key='none' value='none' disabled>&nbsp;</MenuItem>
          {
            US_STATES.map((state) => (
              <MenuItem key={state.value} value={state.value}>[{state.value}] {state.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
}

import BaseDomain from '../../../states/domains/BaseDomain';

const ADMIN_ADMINS_UPDATE_ADMINS = 'ADMIN_ADMINS_UPDATE_ADMINS';
const ADMIN_ADMINS_UPDATE_LOADING_FLAG = 'ADMIN_ADMINS_UPDATE_LOADING_FLAG';
const ADMIN_ADMINS_UPDATE_SELECTED_ADMIN_IDS = 'ADMIN_ADMINS_UPDATE_SELECTED_ADMIN_IDS';

const initialState = {
  adminsData: {
    data: [],
    included: [],
  },
  loading: false,
  selectedAdminIds: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADMIN_ADMINS_UPDATE_ADMINS:
      return {
        ...state,
        adminsData: action.payload.adminsData,
        loading: action.payload.loading,
      };
    case ADMIN_ADMINS_UPDATE_LOADING_FLAG:
      return {
        ...state,
        loading: action.payload,
      };
    case ADMIN_ADMINS_UPDATE_SELECTED_ADMIN_IDS:
      return {
        ...state,
        selectedAdminIds: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'adminAdminsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateAdminsData(adminsData, loading = false) {
    return this.dispatch({
      type: ADMIN_ADMINS_UPDATE_ADMINS,
      payload: {
        adminsData,
        loading,
      },
    });
  }

  async updateAdminsLoadingFlag(loading) {
    return this.dispatch({
      type: ADMIN_ADMINS_UPDATE_LOADING_FLAG,
      payload: loading,
    });
  }

  async updateSelectedAdminIds(adminIds) {
    return this.dispatch({
      type: ADMIN_ADMINS_UPDATE_SELECTED_ADMIN_IDS,
      payload: adminIds,
    });
  }
}

import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import TextToSpeech from '../../../../settings/TextToSpeech';

const TextToSpeechSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Text to speech');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, { tts: newValue });
  };

  return (
    <TextToSpeech
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default TextToSpeechSettings;

import BaseDomain from './BaseDomain';

const LESSON_STANDARD_UPDATE_IS_LOADING_ACTION = 'LESSON_STANDARD_UPDATE_IS_LOADING_ACTION';
const LESSON_STANDARD_UPDATE_LESSON_STANDARD_ACTION = 'LESSON_STANDARD_UPDATE_LESSON_STANDARD_ACTION';
const LESSON_STANDARD_UPDATE_LESSON_STANDARD_AND_ACCESS_POINTS_ACTION = 'LESSON_STANDARD_UPDATE_LESSON_STANDARD_AND_ACCESS_POINTS_ACTION';
const LESSON_STANDARD_UPDATE_LESSON_STANDARD_FOR_SEARCH_ACTION = 'LESSON_STANDARD_UPDATE_LESSON_STANDARD_FOR_SEARCH_ACTION';

const reducer = (state, action) => {
  switch (action.type) {
    case LESSON_STANDARD_UPDATE_IS_LOADING_ACTION:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LESSON_STANDARD_UPDATE_LESSON_STANDARD_ACTION:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        lessonStandards: action.payload.lessonStandards,
      };
    case LESSON_STANDARD_UPDATE_LESSON_STANDARD_FOR_SEARCH_ACTION:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        lessonStandardsForSearch: action.payload.lessonStandards,
      };
    case LESSON_STANDARD_UPDATE_LESSON_STANDARD_AND_ACCESS_POINTS_ACTION:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        lessonStandardsForSearch: action.payload.lessonStandards,
        accessPoints: action.payload.accessPoints,
      };
    default:
      return state;
  }
};

const initialState = {
  lessonStandards: [],
  isLoading: false,
  lessonStandardsForSearch: [],
};

const domainName = 'lessonStandardDomain';

/**
 * Domain class for user management domain
 *
 * @export
 * @class LessonStandardDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor() {
    super(domainName, initialState, reducer);
  }

  /**
   * Update is loading flag
   *
   * @param {*} isLoading is loading flag
   */
  async updateIsLoading(isLoading) {
    return this.dispatch({
      type: LESSON_STANDARD_UPDATE_IS_LOADING_ACTION,
      payload: isLoading,
    });
  }

  /**
   * Update is loading flag
   *
   * @param {*} isLoading is loading flag
   */
  async updateLessonStandards(lessonStandards, isLoading = false) {
    return this.dispatch({
      type: LESSON_STANDARD_UPDATE_LESSON_STANDARD_ACTION,
      payload: {
        lessonStandards,
        isLoading,
      },
    });
  }

  async updateLessonStandardsForSearch(lessonStandards, isLoading = false) {
    return this.dispatch({
      type: LESSON_STANDARD_UPDATE_LESSON_STANDARD_FOR_SEARCH_ACTION,
      payload: {
        lessonStandards,
        isLoading,
      },
    });
  }
}

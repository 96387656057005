import { useEffect } from 'react';
import { getLessonCourses } from '../../../../../../services/CourseService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { sort } from '../../../../../../utils/ArrayUtils';
import { DAYS } from '../../../../../../constants/CourseConstant';
import {
  LESSON_TYPE_INSTRUCTIONAL,
  LESSON_TYPE_NONE_ASSESSMENT,
  TOPIC_PRE_ASSESSMENT,
  TOPIC_POST_ASSESSMENT,
  UNIT_SUMMATIVE_ASSESSMENT,
  LESSON_TYPE_MAPPING,
} from '../../../../../../constants/LessonConstant';

export const RADIO_LIST = [
  {
    id: LESSON_TYPE_NONE_ASSESSMENT,
    value: LESSON_TYPE_NONE_ASSESSMENT,
    name: 'None',
  },
  {
    id: TOPIC_PRE_ASSESSMENT,
    value: TOPIC_PRE_ASSESSMENT,
    name: LESSON_TYPE_MAPPING[TOPIC_PRE_ASSESSMENT],
  },
  {
    id: TOPIC_POST_ASSESSMENT,
    value: TOPIC_POST_ASSESSMENT,
    name: LESSON_TYPE_MAPPING[TOPIC_POST_ASSESSMENT],
  },
  {
    id: UNIT_SUMMATIVE_ASSESSMENT,
    value: UNIT_SUMMATIVE_ASSESSMENT,
    name: LESSON_TYPE_MAPPING[UNIT_SUMMATIVE_ASSESSMENT],
  },
  {
    id: LESSON_TYPE_INSTRUCTIONAL,
    value: LESSON_TYPE_INSTRUCTIONAL,
    name: 'Instructional',
  },
];

export const useHandleFormChange = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const handleOnApprovedCourseFormData = (e, additionalData = {}) => {
    lessonDataMaintenanceDomain.updateLessonDataNoDirtyFlag({
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        [e.target.name]: e.target.value,
        ...additionalData,
      },
    });
  };

  const handleAssessmentStatusChange = (e) => {
    const selectedValue = e.target.value;

    let { days } = lessonData.attributes;
    if ([TOPIC_PRE_ASSESSMENT].includes(selectedValue)) {
      days = [DAYS[0].value];
    } else if ([TOPIC_POST_ASSESSMENT].includes(selectedValue)) {
      days = [DAYS[DAYS.length - 1].value];
    } else if ([UNIT_SUMMATIVE_ASSESSMENT].includes(selectedValue)) {
      days = [DAYS[0].value];
    } else {
      days = [];
    }

    lessonDataMaintenanceDomain.updateLessonDataNoDirtyFlag({
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        lessonType: selectedValue,
        days,
      },
    });
  };

  const handleDaysChange = (_event, newValues) => {
    lessonDataMaintenanceDomain.updateLessonDataNoDirtyFlag({
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        days: newValues.map((v) => v.id),
      },
    });
  };

  return {
    handleOnApprovedCourseFormData,
    handleAssessmentStatusChange,
    handleDaysChange,
  };
};

export const useGetLessonCourseData = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonCourses } = lessonDataMaintenanceDomain.domainData;
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  useEffect(() => {
    if (!lessonCourses) {
      try {
        callRemoteServiceWrapper(async () => {
          const result = await getLessonCourses();
          const sortedResult = sort(result.data, 'name');
          await lessonDataMaintenanceDomain.updateLessonCourse(sortedResult);
        });
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lessonCourseLoading: loading,
    lessonCourses,
  };
};

export const useGetUnitDropdownList = (courseId) => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonCourses } = lessonDataMaintenanceDomain.domainData;

  if (!lessonCourses || !courseId) {
    return [];
  }

  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  return course ? course.units : [];
};

export const useGetTopicDropdownList = (courseId, unitId) => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonCourses } = lessonDataMaintenanceDomain.domainData;

  if (!lessonCourses || !courseId || !unitId) {
    return [];
  }
  const course = lessonCourses && lessonCourses.find((l) => l.id === courseId);
  const { units } = course;

  const unit = units.find((u) => u.id === unitId);
  return unit ? unit.topics : [];
};

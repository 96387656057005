import {
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  useGetDropdownOptions,
} from '../../common/services/TeacherCurriculumComponentService';
import {
  useHandleDropdownValues,
  useHandleSearchButton,
} from './TeacherCurriculumSearchBarHooks';
import './TeacherCurriculumSearchBar.scss';

const TeacherCurriculumSearchBar = () => {
  const {
    values,
    handleOnSubjectChange,
    handleOnGradeChange,
    handleOnChange,
  } = useHandleDropdownValues();

  const {
    subjects,
    grades,
    units,
  } = useGetDropdownOptions(values);

  const {
    allMandatoryFieldsSelected,
    handleOnSearch,
  } = useHandleSearchButton(values);

  return (
    <div className='teacher-curriculum-search-bar'>
      <TextField
        variant='outlined'
        className='select-field subject-search'
        name='subject'
        value={values.subject || ''}
        onChange={handleOnSubjectChange}
        select
        SelectProps={{ renderValue: (value) => (value && value.name ? value.name : 'Subjects'), displayEmpty: true }}
      >
        {subjects.map((c) => (
          <MenuItem key={c.key} value={c}>{c.name}</MenuItem>
        ))}
      </TextField>
      <TextField
        variant='outlined'
        className='select-field grade-search'
        name='grade'
        value={values.grade || ''}
        onChange={handleOnGradeChange}
        disabled={!values.subject}
        select
        SelectProps={{ renderValue: (value) => (value && value.name ? value.name : 'Grade'), displayEmpty: true }}
      >
        {grades.map((c) => (
          <MenuItem key={c.key} value={c}>{c.name}</MenuItem>
        ))}
      </TextField>
      <TextField
        variant='outlined'
        className='select-field unit-search'
        name='unit'
        value={values.unit || ''}
        onChange={handleOnChange}
        disabled={!values.grade}
        select
        SelectProps={{ renderValue: (value) => (value && value.name ? value.name : 'Section'), displayEmpty: true }}
      >
        {units.map((c) => (
          <MenuItem key={c.key} value={c}>{c.name}</MenuItem>
        ))}
      </TextField>
      <Button
        variant='contained'
        className='btn-primary search-button'
        onClick={handleOnSearch}
        disabled={!allMandatoryFieldsSelected}
        startIcon={<SearchIcon />}
      >
        Search
      </Button>
    </div>
  );
};

export default TeacherCurriculumSearchBar;

import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
} from './GameConstants';
import GameActivityUtils from './GameActivityUtils';
import { getPageStyle } from '../common/LessonPageUtil';
import './GameCards.scss';
import './GameThumbnail.scss';

const GameThumbnail = ({ gameCards, isSmallThumbnail }) => (
  <div className={`game-thumbnail game-card-layout game-card-layout-${gameCards.length} ${isSmallThumbnail ? 'game-card-small' : ''}`}>
    {gameCards.map((cell, index) => {
      const imageToUse = cell.gameSpaceType === TILE_TYPE_STANDARD ? cell.image : GameActivityUtils.getSpaceTypeImage(cell.spinner, cell.gameSpaceType);
      const extraImage = cell.gameSpaceType === TILE_TYPE_LOSE_A_TURN ? GameActivityUtils.getSpaceTypeExtraImage(cell.gameSpaceType) : '';
      return (
        <div
          key={`game-thumbnail-card-${index}`}
          className='card-container'
          style={{ gridArea: `card${index}` }}
        >
          <Card
            className={`
                game-card 
                ${cell.text || imageToUse ? '' : 'place-holder'}
                `}
            style={getPageStyle(cell)}

          >
            {imageToUse ? (
              <div className='card-image-container'>
                <CardMedia
                  className='card-image'
                  image={imageToUse}
                  title='Card Image'
                />
                {extraImage ? (
                  <img
                    className='extra-icon'
                    src={extraImage}
                    alt='Extra'
                  />
                ) : ''}
              </div>
            ) : ''}
            {!imageToUse && cell.text ? (
              <TextLines textLines={cell.text} isThumbnail />
            ) : ''}
            {!cell.text && !imageToUse ? <div className='card-order'>{index + 1}</div> : ''}
          </Card>
        </div>
      );
    })}
  </div>
);

GameThumbnail.defaultProps = {
  gameCards: [],
  isSmallThumbnail: false,
};

GameThumbnail.propTypes = {
  gameCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  isSmallThumbnail: PropTypes.bool,
};

export default GameThumbnail;

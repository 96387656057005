/* eslint-disable react/react-in-jsx-scope */
import MathView from 'react-math-view';
import { adjustMathEquationText, getTextArray } from '../../text/TextLinesUtils';

export const BLANK_SPACE = '__________';

export const splitQuestionText = (questionText) => {
  const parts = questionText.split(/__________/);
  const preBlank = parts[0];
  const postBlank = parts[1];
  return {
    preBlank,
    postBlank,
  };
};

const setMathLiveRefs = (r, textContainerId, ttsText) => {
  if (r) {
    const preferedTtsText = ttsText[r.getValue()];
    if (preferedTtsText) {
      document.getElementById(textContainerId).innerHTML = preferedTtsText;
      return;
    }

    const t = r.getValue('spoken')
      .replace(/'/gi, ' ');

    const tempText = adjustMathEquationText(t, ttsText);
    document.getElementById(textContainerId).innerHTML = tempText;
  }
};

export const getFitbTextLine = (text, keyToUse, ttsText = {}) => {
  const textArray = getTextArray(text);
  return textArray.map((t, i) => {
    const mathLiveKey = `text-line-element-${keyToUse}-${i}`;
    if (t.isText) {
      return (
        t.text
      );
    } else {
      return (
        <span key={`text-line-element-${keyToUse}-${i}`}>
          <MathView
            key={mathLiveKey}
            value={t.text}
            readOnly
            contentEditable='false'
            suppressContentEditableWarning
            suppressHydrationWarning
            ref={(r) => { setMathLiveRefs(r, `${mathLiveKey}-text-container`, ttsText); }}
          />
          <div className='match-live-text-container' id={`${mathLiveKey}-text-container`} />
        </span>
      );
    }
  });
};

import { getUserToken } from '../utils/VizzleSessionStorage';
import { URL_LOGIN } from './RouteConstants';

/**
 * Private route that check the token of the user.
 * Redirect the user to login screen when the token is unavailable.
 * @param {*} props of the Route component
 */
export const PrivateRoute = (props) => {
  const userToken = getUserToken();

  if (userToken) {
    return props.element;
  }
  // eslint-disable-next-line no-unused-expressions
  // force the url to change to login screen
  window.location = URL_LOGIN;
  return '';
};

export default PrivateRoute;

import { CHURNZERO_CONSTANT } from '../../../../../constants/ChurnZeroConstant';
import { addStudent, updateStudent, makeUsersActive } from '../../../../../services/AdminService';
import ChurnZeroService from '../../../../../services/ChurnZeroService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../../context/AdminContextProvider';

export const transformStudentDataToStudentFormObject = (studentDataObject) => (studentDataObject && studentDataObject.data && studentDataObject.data.attributes ? {
  firstName: studentDataObject.data.attributes.firstName,
  lastName: studentDataObject.data.attributes.lastName,
  externalId: studentDataObject.data.attributes.externalId,
  schoologyId: studentDataObject.data.attributes.schoologyId,
  userName: studentDataObject.data.attributes.userName,
  stateIdentifier: studentDataObject.data.attributes.stateIdentifier,
  schoolId: Number(studentDataObject.data.relationships.school.data.id),
  grade: studentDataObject.data.attributes.grade,
  ethnicity: studentDataObject.data.attributes.ethnicity,
  gender: studentDataObject.data.attributes.gender,
  dob: studentDataObject.data.attributes.dob,
  email: studentDataObject.data.attributes.email,
  specialEducation: studentDataObject.data.attributes.specialEducation,
  isClasslinkDistrict: studentDataObject.data.attributes.isClasslinkDistrict,
  isCleverDistrict: studentDataObject.data.attributes.isCleverDistrict,
} : {});

export const useAddStudent = ({ closeDialog, onStudentAddSuccess }) => {
  const { uiDomain } = useDomain();
  const { adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const addNewStudent = async (newStudent) => {
    // eslint-disable-next-line no-param-reassign
    try {
      await callRemoteServiceWrapper(async () => {
        const { firstName, lastName, schoologyId, stateIdentifier, userName, password } = newStudent;
        const payload = {
          data: {
            attributes: { firstName, lastName, schoologyId, stateIdentifier, userName, password, type: 'Student' },
            relationships: {
              school: {
                data: {
                  id: Number(selectedSchoolId),
                  type: 'districts',
                },
              },
            },
            type: 'users',
          },
        };
        await addStudent(payload);
        await onStudentAddSuccess();
        closeDialog();
        await uiDomain.showSnackbar(true, 'info', 3000, `Student ${newStudent.firstName} ${newStudent.lastName} is added.`);
        ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.SETUP, 'Add student', user);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    addNewStudent,
  };
};

export const useUpdateStudent = ({ closeDialog, onStudentUpdateSuccess }) => {
  const { uiDomain } = useDomain();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const updateExistingStudent = async (studentDataObject, studentFormDataState) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const coppiedStudentObject = JSON.parse(JSON.stringify(studentDataObject));
        coppiedStudentObject.data.attributes.firstName = studentFormDataState.firstName;
        coppiedStudentObject.data.attributes.lastName = studentFormDataState.lastName;
        coppiedStudentObject.data.attributes.userName = studentFormDataState.userName;
        coppiedStudentObject.data.attributes.externalId = studentFormDataState.externalId;
        coppiedStudentObject.data.attributes.schoologyId = studentFormDataState.schoologyId;
        coppiedStudentObject.data.attributes.stateIdentifier = studentFormDataState.stateIdentifier;
        coppiedStudentObject.data.attributes.password = studentFormDataState.password;
        coppiedStudentObject.data.relationships.school.data.id = studentFormDataState.school.key;
        coppiedStudentObject.data.attributes.grade = studentFormDataState.grade;
        coppiedStudentObject.data.attributes.ethnicity = studentFormDataState.ethnicity;
        coppiedStudentObject.data.attributes.gender = studentFormDataState.gender;
        coppiedStudentObject.data.attributes.dob = studentFormDataState.dob;
        coppiedStudentObject.data.attributes.email = studentFormDataState.email;
        coppiedStudentObject.data.attributes.specialEducation = studentFormDataState.specialEducation;

        const { addToTeacher } = studentFormDataState;
        delete coppiedStudentObject.included;
        await updateStudent(coppiedStudentObject, addToTeacher);
        await onStudentUpdateSuccess();
        closeDialog();
        await uiDomain.showSnackbar(true, 'success', 3000, `Student ${studentFormDataState.firstName} ${studentFormDataState.lastName} is updated.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };
  return {
    loading,
    updateExistingStudent,
  };
};

export const useGetStudentName = () => {
  const { adminDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;

  const getStudentName = (studentId) => {
    const { included } = usersAndLicensesData;
    const studentObject = included.find((i) => i.id === studentId && i.type === 'users');
    return `${studentObject.attributes.firstName} ${studentObject.attributes.lastName}`;
  };

  const getStudentUsername = (studentId) => {
    const { included } = usersAndLicensesData;
    const studentObject = included.find((i) => i.id === studentId && i.type === 'users');
    return studentObject.attributes.userName;
  };

  return {
    getStudentName,
    getStudentUsername,
  };
};

export const useDeactivateStudents = ({ setOpen, onStudentDeactivateSuccess }) => {
  const { adminDomain, adminStudentsDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedStudentIds } = adminStudentsDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const {
    getStudentName,
  } = useGetStudentName();

  const deactivateStudents = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await makeUsersActive(selectedStudentIds, selectedSchoolId, false);
        await onStudentDeactivateSuccess();
        setOpen(false);

        const message = selectedStudentIds.length === 1
          ? `Student ${getStudentName(selectedStudentIds[0])} is deactivated.`
          : 'The selected student are deactivated.';

        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    deactivateStudents,
    loading,
  };
};

export const useReactivateStudents = ({ setOpen, onStudentReactivateSuccess }) => {
  const { adminStudentsDomain, adminDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedStudentIds } = adminStudentsDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const {
    getStudentName,
  } = useGetStudentName();

  const reactivateStudents = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await makeUsersActive(selectedStudentIds, selectedSchoolId, true);
        await onStudentReactivateSuccess();
        setOpen(false);

        const message = selectedStudentIds.length === 1
          ? `Student ${getStudentName(selectedStudentIds[0])} is reactivated.`
          : 'The selected student are reactivated.';

        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    reactivateStudents,
    loading,
  };
};

import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Fade,
  Typography,
} from '@mui/material';
import PacmanLoader from 'react-spinners/PacmanLoader';
import VizzleLogo from '../../vizzle_NoTag.svg';
import { loginWithRethinkAccessToken, logout } from '../../services/LoginService';
import ObjectUtils from '../../utils/ObjectUtils';
import Logger from '../../utils/Logger';
import { usePrepareData } from './LoginHooks';
import { isTeacher } from '../../utils/User';
import { MY_CLASSROOM_ROUTES } from '../myclassroom/MyClassRoomConstant';
import { STUDENT_LANDING, URL_ADMIN, URL_ENTER_TWO_FACTOR_AUTH, URL_MY_CLASSROOM, URL_TERMS_OF_USE } from '../../routes/RouteConstants';

/**
 * Login with vizzle token container
 *
 * @param {*} props passed in from parent component
 */
const loadingMessage = 'Loading, please wait';
const errorMessage = 'An error occurs, please login with you username and password.';

export default function LoginRethinkSsoWithAccessToken() {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const hideToolbar = query.get('hideToolbar');

  const {
    prepareDataForTeacher,
    prepareDataForStudent,
  } = usePrepareData();
  useEffect(() => {
    const handleLoginFailed = () => {
      Logger.logError('login with token failed: redirect to login');
      setShowError(true);
      ObjectUtils.setTimeout(() => {
        logout(false);
      }, 3500);
    };

    const login = async () => {
      const { search } = location.state || location;
      const params = new URLSearchParams(search);
      const accessToken = params.get('access_token');
      const rurl = params.get('rurl');
      Logger.logWhenDebugModeIsOn({
        ACCESS_TOKEN: accessToken,
        RURL: rurl,
      });

      try {
        const result = await loginWithRethinkAccessToken(accessToken);
        if (result.success) {
          Logger.logWhenDebugModeIsOn('success: redirect to authoring screen');

          if (result.mfasetup) {
            navigate(URL_ENTER_TWO_FACTOR_AUTH);
          } else if (isTeacher(result)) {
            const { students } = await prepareDataForTeacher({ ...result, mfaLoggedIn: true, hideToolbar: hideToolbar === 'true' });
            if (!result.tosFlag) {
              navigate(URL_TERMS_OF_USE);
              return;
            }

            if (rurl) {
              ObjectUtils.setTimeout(() => {
                navigate(
                  `${rurl}`,
                );
              });
            } else if (result.userAdmin) {
              navigate(URL_ADMIN);
            } else if (students.length === 0) {
              navigate(`${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.STUDENT_MANAGEMENT}`);
            } else {
              navigate(`${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.CLASS_REPORT}`);
            }
          } else {
            await prepareDataForStudent({ ...result, hideToolbar: hideToolbar === 'true' });
            navigate(STUDENT_LANDING);
          }
        } else {
          handleLoginFailed();
        }
      } catch (error) {
        Logger.logError(error);
        handleLoginFailed();
      }
    };

    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display='flex' flexDirection='column' width='100vw' height='100vh'>
      <Box mx='auto' mt='120px'>
        <img src={VizzleLogo} alt='Vizzle Logo' width='250px' />
      </Box>
      {showError ? (
        <Fade timeout={1500} in>
          <Box display='flex' flexDirection='column' mt='80px' mx='auto'>
            <Typography variant='h5' gutterBottom>
              {errorMessage}
            </Typography>
          </Box>
        </Fade>
      ) : (
        <Box mt='50px' mx='auto'>
          <Box width='100px' height='100px' mx='auto'>
            <PacmanLoader size={50} color='#3b94d1' css={{ margin: 'auto' }} />
          </Box>
          <Box mt='20px'>
            <Typography variant='h5' gutterBottom>
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Accessibe from '../../../../settings/Accessibe';

const AccessibeSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Accessibility');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, { accessibeOn: newValue });
  };

  return (
    <Accessibe
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default AccessibeSettings;

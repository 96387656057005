import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import './LoadingSpinner.scss';

const LoadingSpinner = ({
  message,
}) => (
  <div className='loading-spinner-loading'>
    <CircularProgress size={40} />
    <div className='loading-message' data-private>
      {message}
    </div>
  </div>
);

LoadingSpinner.defaultProps = {
  message: '',
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

export default LoadingSpinner;

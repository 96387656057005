/* eslint-disable no-param-reassign */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const CANVAS_WIDTH = 800;

const LAUNCH_SPEED_MIN = 4;
const LAUNCH_SPEED_MAX = 15;
const GRAVITY = 0.15;
const PARTICLE_SCALE = 0.8;

const LAUNCH_EVERY_MIN = 80;
const LAUNCH_EVERY_MAX = 90;
const LAUNCH_X_VARIATION = 0.1;

const PARTICLE_LAUNCH_TAIL_SCALE = 0.0044;

const BURST_SPEED_MIN = 0.5;
const BURST_SPEED_MAX = 4;

const BURST_COUNT_MIN = 60;
const BURST_COUNT_MAX = 140;
const BURST_FRAMES_MIN = 160;
const BURST_FRAMES_MAX = 210;
const BURST_FLICKER_RATE = 5;
const BURST_FLICKER_FRAMES = 10;

const CLICK_BURSTS = 12;
const CLICK_BURST_SPACING = 500;

const MAX_PARTICLES = 200;

const DISPLAY_FRAMES = 600;

const GREAT_WORK_SCALE_START = 0.3;
const GREAT_WORK_SCALE_END = 0.5;
const GREAT_WORK_SCALE_TIME = 500;
const GREAT_WORK_WAIT = 4000;

const FireworksCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    selectedColor: 'Pink',
    tweens: [],
    timeouts: [],
    mouseY: 0.5,
    mouseX: 0.4,
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
    bangSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bang')].sound,
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const playBangSound = () => {
    if (app.bangSound) {
      app.bangSound.play({ start: 0, volume: 1 });
    }
  };

  const loadBitmaps = () => {
    app.skylineNumber = Math.floor(Math.random() * 4 + 1);

    app.fireworksDot = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `fireworksDot${app.selectedColor}`)].texture);
    app.fireworksDotBounds = app.fireworksDot.getBounds();

    app.fireworksTail = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `fireworksTail${app.selectedColor}`)].texture);
    app.fireworksTailBounds = app.fireworksTail.getBounds();

    app.skyline = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `${app.skylineNumber}-skyline`)].texture);
    app.skylineBounds = app.skyline.getBounds();

    app.picker = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'picker')].texture);
    app.pickerBounds = app.picker.getBounds();

    app.pickerColor = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'pickerColor')].texture);
    app.greatWork = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'greatWork')].texture);
    app.greatWorkBounds = app.greatWork.getBounds();

    app.colors = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'colors')].texture);
  };

  const hueToTint = (argHue) => {
    let tint = [];

    const sixth = Math.floor(argHue * 6);
    let frac = argHue * 6 - sixth;
    frac *= 0.5;

    switch (sixth) {
      case 0:
        tint = [1, frac + 0.5, 0.5];
        break;
      case 1:
        tint = [1 - frac, 1, 0.5];
        break;
      case 2:
        tint = [0.5, 1, frac + 0.5];
        break;
      case 3:
        tint = [0.5, 1 - frac, 1];
        break;
      case 4:
        tint = [frac + 0.5, 0.5, 1];
        break;
      default:
        tint = [1, 0.5, 1 - frac];
        break;
    }

    return tint;
  };

  const setMattrix = (filter, tint) => {
    const { matrix } = filter;
    // eslint-disable-next-line prefer-destructuring
    matrix[0] = tint[0];
    // eslint-disable-next-line prefer-destructuring
    matrix[6] = tint[1];
    // eslint-disable-next-line prefer-destructuring
    matrix[12] = tint[2];
  };

  const createParticle = (argX, argY, argDx, argDy, argBurst, argTint, argBurstAt) => {
    if (app.particlesUsed > MAX_PARTICLES) {
      return;
    }

    app.particles[app.particlesUsed] = {};
    app.particles[app.particlesUsed].dot = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `fireworksDot${app.selectedColor}`)].texture);
    app.particles[app.particlesUsed].dot.x = argX;
    app.particles[app.particlesUsed].dot.y = argY;
    app.particles[app.particlesUsed].dot.anchor.set(0.5);
    app.particles[app.particlesUsed].dot.scale.x = PARTICLE_SCALE;
    app.particles[app.particlesUsed].dot.scale.y = PARTICLE_SCALE;

    app.fireworksLayer.addChild(app.particles[app.particlesUsed].dot);

    app.particles[app.particlesUsed].dx = argDx;
    app.particles[app.particlesUsed].dy = argDy;
    app.particles[app.particlesUsed].burst = argBurst;
    app.particles[app.particlesUsed].countdown = BURST_FRAMES_MIN + Math.random() * (BURST_FRAMES_MAX - BURST_FRAMES_MIN);
    app.particles[app.particlesUsed].offset = Math.random() * 3.14159 * 2;

    if (!argBurst) {
      app.particles[app.particlesUsed].tail = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, `fireworksTail${app.selectedColor}`)].texture);
      app.particles[app.particlesUsed].tail.x = argX;
      app.particles[app.particlesUsed].tail.y = argY;
      app.particles[app.particlesUsed].tail.anchor.set(0.5);
      app.particles[app.particlesUsed].tail.scale.x = PARTICLE_SCALE;
      app.particles[app.particlesUsed].tail.scale.y = PARTICLE_SCALE;

      app.fireworksLayer.addChild(app.particles[app.particlesUsed].tail);
    }
    app.particles[app.particlesUsed].burstAt = argBurstAt;

    app.particlesUsed++;
  };

  const createBurst = (argX, argY, argTint) => {
    playBangSound();

    let r = 1 - argY / app.canvas.view.height;
    r *= r;
    app.baseSpeed = (BURST_SPEED_MIN + r * (BURST_SPEED_MAX - BURST_SPEED_MIN));

    for (let i = 0; i < BURST_COUNT_MIN + r * (BURST_COUNT_MAX - BURST_COUNT_MIN); i++) {
      app.speed = Math.random();
      app.speed = app.baseSpeed * (1 - (1 - app.speed) * (1 - app.speed));
      app.angle = Math.random() * 2 * 3.14159;

      createParticle(
        argX,
        argY,
        Math.cos(app.angle) * app.speed,
        Math.sin(app.angle) * app.speed,
        true,
        argTint,
      );
    }
  };

  const handleStageMouseOver = (e) => {
    const pt = app.canvas.stage.toLocal(e.data.global);

    if (pt.x < 0 || pt.x > app.canvas.view.width || pt.y < 0 || pt.y > app.canvas.view.height - app.pickerBounds.height / 2) {
      return;
    }

    app.mouseX = pt.x / app.canvas.view.width;
    app.mouseY = (pt.y / app.canvas.view.height);

    const tint = hueToTint(1 - app.mouseY);

    if (app.mouseY <= 0.1) {
      app.selectedColor = 'Pink';
    } else if (app.mouseY > 0.1 && app.mouseY <= 0.2) {
      app.selectedColor = 'PinkPurple';
    } else if (app.mouseY > 0.2 && app.mouseY <= 0.3) {
      app.selectedColor = 'Purple';
    } else if (app.mouseY > 0.3 && app.mouseY <= 0.4) {
      app.selectedColor = 'Blue';
    } else if (app.mouseY > 0.4 && app.mouseY <= 0.6) {
      app.selectedColor = 'Green';
    } else if (app.mouseY > 0.6 && app.mouseY <= 0.7) {
      app.selectedColor = 'Yellow';
    } else if (app.mouseY > 0.7) {
      app.selectedColor = 'Orange';
    }

    const pickerColorFilter = new PIXI.filters.ColorMatrixFilter();
    setMattrix(pickerColorFilter, tint);
    app.pickerColor.filters = [pickerColorFilter];
    app.picker.y = pt.y;
    app.pickerColor.y = pt.y;
  };

  function createClickBurst(argTint) {
    createBurst(
      Math.random() * app.canvas.view.width,
      Math.random() * app.canvas.view.height,
      argTint,
    );
  }
  const handleStageMouseDown = () => {
    if (app.canvas && app.canvas.view && !app.showFinish) {
      for (let i = 0; i < CLICK_BURSTS; i++) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          createClickBurst(hueToTint(1 - app.mouseY));
        }, i * CLICK_BURST_SPACING);
        app.timeouts.push(timeout);
      }
    }
  };

  const createStage = () => {
    app.framesToLive = DISPLAY_FRAMES;

    app.stage = new PIXI.Container();
    app.canvas.stage.addChild(app.stage);
    app.particlesUsed = 0;
    app.particles = [];
    app.countdown = 1;

    const hue = Math.random();
    const tint = hueToTint(hue);

    app.fireworksLayer = new PIXI.Container();
    app.fireworksLayer.x = 0;
    app.fireworksLayer.y = 0;
    app.fireworksLayer.width = app.canvas.view.width;
    app.fireworksLayer.height = app.canvas.view.height;
    app.stage.addChild(app.fireworksLayer);

    const scaleX = app.canvas.view.width / 640;
    app.skyline.x = 0;
    app.skyline.y = -20;
    const skylineFilter = new PIXI.filters.ColorMatrixFilter();
    setMattrix(skylineFilter, tint);
    app.skyline.filters = [skylineFilter];
    // scaled by the image's size
    app.skyline.scale.x = scaleX;
    app.skyline.scale.y = 1.15;
    app.stage.addChild(app.skyline);

    app.colors.x = 0;
    app.colors.y = 0;
    app.colors.scale.x = scaleX;
    app.stage.addChild(app.colors);

    app.picker.x = 0;
    app.picker.anchor.set(0, 0.25);
    app.picker.y = app.canvas.view.height / 2;
    app.picker.scale.x = scaleX;
    app.picker.scale.y = 1.2;
    app.stage.addChild(app.picker);

    app.pickerColor.x = 0;
    app.pickerColor.anchor.set(0, 0.25);
    app.pickerColor.y = app.canvas.view.height / 2;
    app.pickerColor.scale.x = scaleX;
    app.pickerColor.scale.y = 1.2;
    const pickerColorFilter = new PIXI.filters.ColorMatrixFilter();
    setMattrix(pickerColorFilter, tint);
    app.pickerColor.filters = [pickerColorFilter];
    app.stage.addChild(app.pickerColor);

    app.stage.interactive = true;
    app.stage.on('mousemove', handleStageMouseOver);
    app.stage.on('click', handleStageMouseDown);

    app.stage.on('touchmove', handleStageMouseOver);
    app.stage.on('tap', handleStageMouseDown);
  };

  const showFinish = () => {
    app.showFinish = true;
    playClappingSound();
    app.greatWork.anchor.set(0.5);
    app.greatWork.x = app.canvas.view.width / 2;
    app.greatWork.y = app.canvas.view.height / 2;
    app.greatWork.scale.x = GREAT_WORK_SCALE_START;
    app.greatWork.scale.y = GREAT_WORK_SCALE_START;

    app.stage.addChild(app.greatWork);

    const greatWorkTween = new TWEEN.Tween(app.greatWork.scale);
    greatWorkTween.to({
      x: GREAT_WORK_SCALE_END,
      y: GREAT_WORK_SCALE_END,
    }, GREAT_WORK_SCALE_TIME);
    greatWorkTween.easing(TWEEN.Easing.Linear.None);
    greatWorkTween.onComplete(() => {
      const onFinishTimeout = setTimeout(() => {
        clearTimeout(onFinishTimeout);
        onFinish();
      }, GREAT_WORK_WAIT);
      app.timeouts.push(onFinishTimeout);
    });
    app.tweens.push(greatWorkTween);
    greatWorkTween.start();
  };

  const launchFirework = (argTint) => {
    app.angle = Math.atan2(app.mouseY - 1, app.mouseX - 0.5);
    app.angle += (Math.random() - 0.5) * LAUNCH_X_VARIATION;

    const speed = LAUNCH_SPEED_MIN + (1 - app.mouseY) * (LAUNCH_SPEED_MAX - LAUNCH_SPEED_MIN);
    createParticle(
      app.canvas.view.width / 2,
      app.canvas.view.height,
      speed * Math.cos(app.angle),
      speed * Math.sin(app.angle),
      false,
      argTint,
      (app.mouseY * app.canvas.view.height) - 0.12,
    );
  };

  const updateParticle = (argParticle) => {
    argParticle.dy += GRAVITY;
    argParticle.dot.x += argParticle.dx;
    argParticle.dot.y += argParticle.dy;

    if (argParticle.dot && argParticle.tail) {
      argParticle.tail.x = argParticle.dot.x;
      argParticle.tail.y = argParticle.dot.y;
      app.angle = Math.atan2(argParticle.dy, argParticle.dx);
      argParticle.tail.rotation = ((app.angle * 180) / 3.14159) + 180;
    }

    if (!argParticle.burst) {
      argParticle.tail.scale.x = (argParticle.dx * argParticle.dx + argParticle.dy * argParticle.dy)
        * PARTICLE_SCALE * PARTICLE_LAUNCH_TAIL_SCALE;

      if (argParticle.dot.y <= argParticle.burstAt) {
        createBurst(argParticle.dot.x, argParticle.dot.y, argParticle.tint);
        return false;
      }
    } else if (argParticle.dot) {
      argParticle.dot.alpha = argParticle.countdown / BURST_FRAMES_MIN
        - (
          (Math.sin(app.framesToLive * BURST_FLICKER_RATE + argParticle.offset) + 1)
          * 0.5
          * (1 - Math.min(argParticle.countdown / BURST_FLICKER_FRAMES, 1))
        );
      argParticle.dot.alpha = Math.max(Math.min(argParticle.dot.alpha, 1), 0);
      argParticle.countdown--;
      if (argParticle.countdown <= 0) {
        return false;
      }
    }

    return true;
  };

  const tick = () => {
    app.framesToLive--;
    if (app.showFinish) {
      return;
    }

    app.countdown--;
    if (app.countdown <= 0) {
      app.countdown = LAUNCH_EVERY_MIN + Math.random() * (LAUNCH_EVERY_MAX - LAUNCH_EVERY_MIN);

      launchFirework(hueToTint(Math.random()));
    }

    let i = 0;

    while (i < app.particlesUsed) {
      if (!updateParticle(app.particles[i])) {
        app.fireworksLayer.removeChild(app.particles[i].dot);
        app.fireworksLayer.removeChild(app.particles[i].tail);
        app.particlesUsed--;
        app.particles[i] = app.particles[app.particlesUsed];
      } else {
        i++;
      }
    }
  };

  useSetupTimeout(canvas, showFinish);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundColor: 0x282828,
      });

      loadBitmaps();
      createStage();
      app.canvas.ticker.add(tick);
      app.canvas.ticker.maxFPS = 40;
    }

    return () => {
      if (app.clappingSound) {
        app.clappingSound.stop();
      }
      if (app.bangSound) {
        app.bangSound.stop();
      }

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

FireworksCelebration.defaultProps = {
  onFinish: () => { },
};

FireworksCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default FireworksCelebration;

import { useEffect, useState } from 'react';
import { EMAIL_REGEXP } from '../../../../../../constants/Regex';

export const MIN_USERNAME = 4;
export const validateStudentData = (data) => data
  && data.firstName && data.firstName !== ''
  && data.lastName && data.lastName !== ''
  && data.userName && data.userName !== ''
  && data.stateIdentifier && data.stateIdentifier !== ''
  && (!data.email || EMAIL_REGEXP.test(data.email));

export const validatePasswordNotEmpty = (data) => data.password !== '' && data.confirmPassword !== '';
export const validateUserNameLength = (data) => data && data.length >= MIN_USERNAME;
export const validatePasswordMatching = (password, confirmPassword) => password === confirmPassword;

export const useStudentFormControl = ({
  initData,
  onDataChange,
}) => {
  const [internalData, setInternalData] = useState({ ...initData });
  useEffect(() => {
    if (initData) {
      setInternalData({ ...initData });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [emailError, setemailError] = useState(false);

  const updateDataChange = (updatedData, confirmPass = confirmPassword) => {
    setInternalData(updatedData);
    const isDataValid = validateStudentData(updatedData)
      && validateUserNameLength(updatedData.userName)
      && validatePasswordNotEmpty(updatedData)
      && validatePasswordMatching(updatedData.password || '', confirmPass);
    onDataChange(updatedData, isDataValid);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...internalData, [name]: value };
    updateDataChange(updatedData);
  };

  const validateUsername = (e) => {
    const { value } = e.target;
    setUsernameError(validateUserNameLength(value) ? '' : `Needs to be at least ${MIN_USERNAME} characters`);
  };

  const handleConfirmPassword = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    updateDataChange(internalData, value);
  };

  const handleOnDistrictSchoolChange = async (selectedDistrictSchool) => {
    const updatedData = {
      ...internalData,
      school: selectedDistrictSchool.school,
    };
    setInternalData(updatedData);
    const isDataValid = validateStudentData(updatedData)
      && validateUserNameLength(updatedData.userName)
      && validatePasswordNotEmpty(updatedData)
      && validatePasswordMatching(updatedData.password || '', updatedData.confirmPassword || '');
    onDataChange(updatedData, isDataValid);
  };

  const handleOnEmailChange = (e) => {
    const { value } = e.target;
    const result = EMAIL_REGEXP.test(value);
    if (!value || result) {
      setemailError(false);
    } else {
      setemailError(true);
    }
    handleOnChange(e);
  };

  return {
    internalData,
    confirmPassword,
    usernameError,
    emailError,
    handleOnChange,
    validateUsername,
    handleConfirmPassword,
    handleOnDistrictSchoolChange,
    handleOnEmailChange,
  };
};

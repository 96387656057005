import PropTypes from 'prop-types';
import './CauseAndEffectEditor.scss';
import CauseAndEffectEditorComponent from './editor/CauseAndEffectEditorComponent';

/**
 * Lesson activity for Cause and Effect
 */
export default function CauseAndEffectEditor({
  causeCards,
  effectCards,
  selectedIndex,
  isCauseSelected,
  selectedSection,
  onImageSelected,
  onTextSelected,
  onAudioSelected,
}) {
  return (
    <div data-test='cause-and-effect-editor' className={`cause-and-effect-editor cause-length-${causeCards.length} vizzle-cause-and-effect-card-editor`}>
      {causeCards.map((cc, index) => (
        <CauseAndEffectEditorComponent
          key={`cause-and-effect-thumbnail-${index}`}
          causeCard={cc}
          effectCard={effectCards[index]}
          isSelected={index === selectedIndex}
          isCauseSelected={isCauseSelected}
          selectedSection={selectedSection}
          onImageSelected={(param) => onImageSelected({ ...param, selectedIndex: index })}
          onTextSelected={(param) => onTextSelected({ ...param, selectedIndex: index })}
          onAudioSelected={(param) => onAudioSelected({ ...param, selectedIndex: index })}
        />
      ))}
    </div>
  );
}

CauseAndEffectEditor.defaultProps = {
  causeCards: [],
  effectCards: [],
  selectedIndex: 0,
  isCauseSelected: true,
  selectedSection: undefined,
  onImageSelected: () => { },
  onTextSelected: () => { },
  onAudioSelected: () => { },
};

CauseAndEffectEditor.propTypes = {
  causeCards: PropTypes.arrayOf(PropTypes.any),
  effectCards: PropTypes.arrayOf(PropTypes.any),
  selectedIndex: PropTypes.number,
  isCauseSelected: PropTypes.bool,
  selectedSection: PropTypes.string,
  onImageSelected: PropTypes.func,
  onTextSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
};

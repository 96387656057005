import {
  CardMedia,
} from '@mui/material';
import VideoIcon from '@mui/icons-material/VideoLibrary';

export const MEDIA_TYPE_IMAGE = 'IMAGE';
export const MEDIA_TYPE_VIDEO = 'VIDEO';
export const MEDIA_LIMIT = 20;

export const getImageUrlForImage = (imagesData = []) => (
  imagesData.map((data) => {
    const imageUrl = data.attributes.s3Url;
    return {
      ...data,
      imageUrl,
      component: (
        <CardMedia
          image={imageUrl}
          title={data.attributes.name}
        />
      ),
      description: data.attributes.name,
    };
  })
);

export const getImageUrlForVideo = (videosData = []) => (
  videosData.map((data) => {
    const imageUrl = data.attributes.thumbnailUrlForVideo;
    const component = imageUrl ? (
      <CardMedia
        image={imageUrl}
        title={data.attributes.name}
      />
    ) : (
      <VideoIcon />
    );
    return {
      ...data,
      imageUrl,
      component,
      description: data.attributes.name,
    };
  })
);

export const hasMoreRecord = (meta) => (
  meta.offset + MEDIA_LIMIT < meta.totalCount
);

import PropTypes from 'prop-types';
import SearchAndSortableTable from '../../../../../../../components/table/SearchAndSortableTable';
import './TeacherTableSelector.scss';

const tableConfig = [
  {
    title: 'First Name',
    align: 'left',
    dataField: 'firstName',
    width: '28%',
  },
  {
    title: 'Last Name',
    align: 'left',
    dataField: 'lastName',
    width: '29%',
  },
  {
    title: 'Username',
    align: 'left',
    dataField: 'username',
    width: '43%',
  },
];

const TeacherTableSelector = ({
  studentTeacherSelectorTableData,
}) => (
  <div className='teacher-table-selector'>
    <SearchAndSortableTable
      containerClassName='teacher-selector-table'
      config={tableConfig}
      tableData={studentTeacherSelectorTableData}
      searchPlaceholder='Search your Teachers'
      initTableState={{
        order: 'desc',
        orderBy: 'firstName',
      }}
      searchFunction={(keyword, data) => {
        const name = `${data.firstName} ${data.lastName}`;
        const isNameMatched = name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
        const isEmailMatched = data.username.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
        return isNameMatched || isEmailMatched;
      }}
      autoResetTableState={false}
    />
  </div>
);

TeacherTableSelector.defaultProps = {
  studentTeacherSelectorTableData: {},
};

TeacherTableSelector.propTypes = {
  studentTeacherSelectorTableData: PropTypes.array,
};

export default TeacherTableSelector;

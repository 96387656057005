/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ProfileBox from '../../../../components/box/ProfileBox';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import ToggleForm from '../../../../components/toggle/ToggleForm';
import './ProfileSetting.scss';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';
import { schoologyTooltipText } from '../../../../texts/TooltipText';
import ResetPasswordAction from '../actions/reset-password/ResetPasswordAction';
import PasswordUtils, { invalidConfirmPassword, invalidPassword, passwordMismatch } from '../../../../utils/PasswordUtils';

const invalidProfileFieldMessages = {
  firstName: 'First name is required',
  lastName: 'Last name is required',
};
const ProfileSetting = () => {
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const { updateProfileSettingsOnChange, loading } = useSettingForm('Teacher Profile');
  const { userDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;
  const { b2cId } = userProfile;
  const isB2cId = Boolean(b2cId);
  const { isClasslinkDistrict, isCleverDistrict } = user;
  const disableSettingData = isClasslinkDistrict || isCleverDistrict;

  const [userProfileData, setUserProfileData] = useState({
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    password: '',
    confirmPassword: '',
    schoologyId: userProfile.schoologyId,
  });
  const [booleanLogic, setBooleanLogic] = useState({
    validPassword: false,
    validConfirmPassword: false,
    onEdit: false,
    showAlert: false,
    disableButton: !disableSettingData,
  });
  const [helperText, setHelperText] = useState({
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });
  const [toggleValues, setToggleValues] = useState({
    emailNotificationOn: userProfile.emailNotificationOn || false,
    emailWeeklyAssignmentReport: userProfile.emailWeeklyAssignmentReport || false,
  });

  const [saveObject, setSaveObject] = useState({});
  // When password or confirm password is changing
  useEffect(() => {
    if (booleanLogic.validPassword && booleanLogic.validConfirmPassword) {
      const emptyHelperText = (!helperText.firstName && !helperText.lastName);
      if (userProfileData.password === userProfileData.confirmPassword) {
        if (emptyHelperText) {
          setBooleanLogic({ ...booleanLogic, disableButton: false, showAlert: false });
        } else {
          setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: false });
        }
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true, showAlert: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData.password, userProfileData.confirmPassword]);

  // When mounting and changing a student.
  useEffect(() => {
    setUserProfileData({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      password: '',
      confirmPassword: '',
      schoologyId: userProfile.schoologyId,
    });
    setBooleanLogic({ ...booleanLogic, onEdit: false });
    setSaveObject({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, userProfile.switchAccessibility]);

  const validateHelperText = (errorMessage) => {
    const emptyProfileHelperText = (!errorMessage.firstName && !errorMessage.lastName);
    if (emptyProfileHelperText) {
      const emptyPasswordError = (!errorMessage.password && !errorMessage.confirmPassword);
      if (emptyPasswordError && userProfileData.password === userProfileData.confirmPassword) {
        setBooleanLogic({ ...booleanLogic, disableButton: false });
      } else {
        setBooleanLogic({ ...booleanLogic, disableButton: true });
      }
    } else {
      setBooleanLogic({ ...booleanLogic, disableButton: true });
    }
  };

  const validateFieldInput = (e) => {
    const errorText = helperText;
    if (e.target.value) {
      switch (e.target.name) {
        case 'firstName':
          errorText.firstName = '';
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = '';
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    } else {
      switch (e.target.name) {
        case 'firstName':
          errorText.firstName = invalidProfileFieldMessages.firstName;
          setHelperText({ ...errorText });
          break;
        case 'lastName':
          errorText.lastName = invalidProfileFieldMessages.lastName;
          setHelperText({ ...errorText });
          break;
        default:
          break;
      }
    }
    validateHelperText(errorText);
  };

  const onUserProfileChange = (e) => {
    setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value });
    setSaveObject({ ...saveObject, [e.target.name]: e.target.value });
    validateFieldInput(e);
  };

  const onPasswordChange = (e) => {
    setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && PasswordUtils.validatePassword(e.target.value)) {
      setBooleanLogic({ ...booleanLogic, validPassword: true });
      setHelperText({ ...helperText, password: '' });
      setSaveObject({ ...saveObject, password: e.target.value });
    } else {
      setBooleanLogic({ ...booleanLogic, validPassword: false });
      setHelperText({ ...helperText, password: invalidPassword });
      delete saveObject.password;
      setSaveObject({ ...saveObject });
    }
  };

  const onConfirmPasswordChange = (e) => {
    setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value });
    setBooleanLogic({ ...booleanLogic, showAlert: false });
    if (e.target.value && PasswordUtils.validatePassword(e.target.value)) {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: true });
      setHelperText({ ...helperText, confirmPassword: '' });
    } else {
      setBooleanLogic({ ...booleanLogic, validConfirmPassword: false });
      setHelperText({ ...helperText, confirmPassword: invalidConfirmPassword });
    }
  };

  const editOnClick = () => {
    setBooleanLogic({ ...booleanLogic, onEdit: true });
  };

  const onClose = () => {
    setBooleanLogic({ ...booleanLogic, showAlert: false });
  };

  const clearState = () => {
    setBooleanLogic({
      validPassword: false,
      validConfirmPassword: false,
      onEdit: false,
      showAlert: false,
      disableButton: true,
    });
    setHelperText({ password: '', confirmPassword: '' });
    setSaveObject({});
  };

  const onCancel = () => {
    setUserProfileData({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      password: '',
      confirmPassword: '',
      schoologyId: userProfile.schoologyId,
    });
    clearState();
  };

  const onSave = async () => {
    await updateProfileSettingsOnChange({ ...saveObject });
    setUserProfileData({
      ...userProfileData,
      password: '',
      confirmPassword: '',
    });
    clearState();
  };

  const handleToggle = (fieldName, value) => {
    if (value === null) {
      return;
    }
    setToggleValues({
      ...toggleValues,
      [fieldName]: value,
    });
    updateProfileSettingsOnChange({
      [fieldName]: value,
    });
  };

  return (
    <Box display='flex' flexDirection='column' className='mysetting-profile-info-flexbox'>
      {booleanLogic.showAlert ? <Alert severity='error' onClose={onClose}>{passwordMismatch}</Alert> : null}
      <Grid container spacing={4} rowSpacing={2} data-test='mysetting-profile-form-info-container'>
        <Grid item xs={4}>
          <ProfileBox
            label='First Name'
            name='firstName'
            isRequired
            value={userProfileData.firstName}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.firstName}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>
        <Grid item xs={4}>
          <ProfileBox
            label='Last Name'
            name='lastName'
            isRequired
            value={userProfileData.lastName}
            onChange={onUserProfileChange}
            isEdit={booleanLogic.onEdit}
            helperText={helperText.lastName}
            fullWidth
            disabled={disableSettingData}
          />
        </Grid>

        {!isB2cId && (
          <Grid item xs={4} alignSelf='end'>
            <ProfileBox
              label='Password'
              name='password'
              type='password'
              value={userProfileData.password || (booleanLogic.onEdit && (isPasswordFocus || helperText.password) ? '' : '****')}
              // value={userProfileData.password}
              onChange={onPasswordChange}
              isEdit={booleanLogic.onEdit}
              helperText={helperText.password}
              isRequired
              onFocus={() => {
                setIsPasswordFocus(true);
              }}
              onBlur={() => {
                setIsPasswordFocus(false);
              }}
              fullWidth
              disabled={disableSettingData}
            />
          </Grid>
        )}
        {
          isB2cId && disableSettingData && (
            <Grid item xs={4} />
          )
        }
        {
          isB2cId && !disableSettingData && (
            <Grid item xs={4} alignSelf='end'>
              <ResetPasswordAction
                onSaveNewPassword={async (newPassword) => {
                  await updateProfileSettingsOnChange({ password: newPassword });
                }}
              />
            </Grid>
          )
        }

        <Grid item xs={4}>
          {user.schoologyFlag && (
            <ProfileBox
              label='Schoology ID'
              name='schoologyId'
              value={userProfileData.schoologyId}
              onChange={onUserProfileChange}
              isEdit={booleanLogic.onEdit}
              endDecorator={(
                <TooltipWithIndicator {...schoologyTooltipText} />
              )}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={4}>
          {
            booleanLogic.onEdit && !isB2cId && !disableSettingData
              ? (
                <ProfileBox
                  label='Confirm Password'
                  name='confirmPassword'
                  value={userProfileData.confirmPassword}
                  onChange={onConfirmPasswordChange}
                  isEdit={booleanLogic.onEdit}
                  type='password'
                  helperText={helperText.confirmPassword}
                  onFocus={() => {
                    setIsPasswordFocus(true);
                  }}
                  onBlur={() => {
                    setIsPasswordFocus(false);
                  }}
                  fullWidth
                />
              )
              : null
          }
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Box>
              <Typography variant='subtitle1' className='my-setting-title'>
                Student Progress Email Notifications
              </Typography>
            </Box>
            <Box>
              <ToggleForm value={toggleValues.emailNotificationOn} handleOnChange={(_e, value) => handleToggle('emailNotificationOn', value)} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Box>
              <Typography variant='subtitle1' className='my-setting-title'>
                Email weekly assignment report
              </Typography>
            </Box>
            <Box>
              <ToggleForm value={toggleValues.emailWeeklyAssignmentReport} handleOnChange={(_e, value) => handleToggle('emailWeeklyAssignmentReport', value)} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {(!disableSettingData || user.schoologyFlag) && (
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          {
            booleanLogic.onEdit
              ? (
                <Box display='flex' flexDirection='row' className='flex-my-setting-on-edit'>
                  <Button variant='contained' onClick={onCancel} className='btn-primary-rounded-border margin-right ' disabled={loading} data-test='teacher-cancel-profile-action'>
                    Cancel
                  </Button>
                  <ButtonWithSpinner
                    label='Save'
                    variant='contained'
                    onClick={onSave}
                    className='btn-primary-rounded action-button'
                    disabled={booleanLogic.disableButton}
                    loading={loading}
                    data-test='teacher-save-profile-action'
                  />
                </Box>
              )
              : (
                <Box display='flex' flexDirection='row' className='flex-teacher-setting-on-edit'>
                  <ButtonWithSpinner
                    startIcon={<EditIcon />}
                    label='Edit'
                    variant='text'
                    onClick={editOnClick}
                    className='action-button'
                    buttonClass=''
                    disabled={loading}
                    data-test='teacher-edit-profile-action'
                  />
                </Box>
              )
          }
        </Box>
      )}

    </Box>
  );
};

export default ProfileSetting;

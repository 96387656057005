import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import BookCardEditor from '../../../../../components/vizzle/page/book/BookCardEditor';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { SECTION_AUDIO, SECTION_IMAGE, SECTION_NONE, SECTION_TEXT } from '../../../../../components/vizzle/page/common/CardEditorConstants';
import { getImageDimension } from '../../../../../utils/ImageUtil';
import Logger from '../../../../../utils/Logger';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import AllowCustomResponseConfirmation from './editor/allow-custom-response/AllowCustomResponseConfirmation';
import { isCardEmpty } from '../../../../../utils/activitytype/ActivityTypeEmptyChecker';
import { CUSTOM_RESPONSE_OPTIONS, DEFAULT_FF_IMAGE_COUNT } from '../../../../../components/vizzle/page/book/components/CustomResponseConstants';
import { findFfrPage, useShouldFeedbackLoopDisable } from '../../hooks/LessonMaintenanceHooks';
import NotificationDialog from '../../../../../components/dialog/NotificationDialog';
import { defaultFfrSubmitText } from '../../../../../utils/activitytype/ActivityTypeInitialData';

/**
 * Container for adding text, image, video and audio for Matching page
 */
export default function BookTextAndMedia() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData, lessonData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];
  const initState = {
    currentSelectedBook: 0,
    selectedOption: SECTION_IMAGE,
    selectedSection: SECTION_IMAGE,
  };

  const [openAllowCustomResponse, setOpenAllowCustomResponse] = useState({
    open: false,
    selectedIndex: null,
  });

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const [customResponseNotification, setCustomResponseNotification] = useState(false);

  const {
    getShouldFeedbackLoopDisable,
  } = useShouldFeedbackLoopDisable();

  const onCustomResponseSelect = (selectedIndex) => {
    // selectedPage.cards[selectedIndex].image = null;
    selectedPage.cards[selectedIndex].video = null;
    selectedPage.cards[selectedIndex].text = null;
    selectedPage.cards[selectedIndex].audio = null;
    setCurrentSelectedData({
      ...currentSelectedData,
      selectedOption: SECTION_IMAGE,
      selectedSection: SECTION_IMAGE,
    });
  };

  const createBookCardPreview = () => {
    const layoutConfig = selectedPage.cards.filter((card) => (
      !card.hide
    )).map((card) => (
      {
        text: card.text,
        image: card.image,
        audio: card.audio,
        video: card.video,
        freetextInstructions: card.freetextInstructions,
        allowCustomResponse: card.allowCustomResponse,
        customResponseOption: card.customResponseOption,
        ffImageCount: card.ffImageCount,
      }
    ));

    const props = {
      layoutConfig,
      selectedPageIndex: currentSelectedData.currentSelectedBook,
      selectedSection: currentSelectedData.selectedSection,
      onImageSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
          selectedOption: SECTION_IMAGE,
        });
      },
      onTextSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
          selectedOption: SECTION_TEXT,
        });
      },
      onAudioSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
          selectedOption: SECTION_AUDIO,
        });
      },
      onCustomResponseSelect: async (selectedIndex, value) => {
        const isDisabled = getShouldFeedbackLoopDisable();
        if (isDisabled && lessonData.attributes.feedbackLoopValue) {
          setCustomResponseNotification(true);
          return;
        }
        const isEmpty = isCardEmpty(selectedPage.cards[selectedIndex]);
        if (value) {
          if (isEmpty) {
            selectedPage.cards[selectedIndex].allowCustomResponse = value;
            selectedPage.cards[selectedIndex].customResponseOption = CUSTOM_RESPONSE_OPTIONS[0].id;
            selectedPage.cards[selectedIndex].freetextInstructions = JSON.parse(JSON.stringify(defaultFfrSubmitText));
            lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, {
              ...selectedPage,
            });
            onCustomResponseSelect(selectedIndex);
          } else {
            setOpenAllowCustomResponse({
              selectedIndex,
              open: true,
            });
          }
        } else {
          selectedPage.cards[selectedIndex].allowCustomResponse = value;
          selectedPage.cards[selectedIndex].freetextInstructions = null;
          selectedPage.cards[selectedIndex].customResponseOption = '';

          const hasFfr = findFfrPage(pagesData);
          if (!hasFfr) {
            lessonData.attributes.feedbackLoopValue = false;
            await lessonDataMaintenanceDomain.updateLessonData({ ...lessonData });
          }

          lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, {
            ...selectedPage,
          });
          setCurrentSelectedData({
            ...currentSelectedData,
            selectedOption: SECTION_IMAGE,
            selectedSection: SECTION_IMAGE,
          });
        }
      },
      onCustomResponseOptionSelect: (selectedIndex, value) => {
        selectedPage.cards[selectedIndex].customResponseOption = value;
        if (value === CUSTOM_RESPONSE_OPTIONS[2].id) {
          selectedPage.cards[selectedIndex].ffImageCount = DEFAULT_FF_IMAGE_COUNT;
        } else {
          selectedPage.cards[selectedIndex].ffImageCount = undefined;
        }
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, {
          ...selectedPage,
        });
      },
      onFfImageCountChange: (selectedIndex, value) => {
        selectedPage.cards[selectedIndex].ffImageCount = value;
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, {
          ...selectedPage,
        });
      },
    };

    return (
      <BookCardEditor {...props} />
    );
  };

  useEffect(() => {
    // When the user select a new page, reset the current selected book to the first one
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  const onTextApply = async (textLines) => {
    const { text } = selectedPage.cards[currentSelectedData.currentSelectedBook];
    const currentTextLines = text ? text.lines : [];
    const newTextLines = textLines.map((e, index) => ({
      ...currentTextLines[index],
      ...e,
    }));

    if (selectedPage.cards[currentSelectedData.currentSelectedBook].allowCustomResponse) {
      if (!selectedPage.cards[currentSelectedData.currentSelectedBook].freetextInstructions) {
        selectedPage.cards[currentSelectedData.currentSelectedBook].freetextInstructions = {};
      }
      selectedPage.cards[currentSelectedData.currentSelectedBook].freetextInstructions.lines = newTextLines;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    } else {
      if (!selectedPage.cards[currentSelectedData.currentSelectedBook].text) {
        selectedPage.cards[currentSelectedData.currentSelectedBook].text = {};
      }
      selectedPage.cards[currentSelectedData.currentSelectedBook].text.lines = newTextLines;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    }
  };

  const onTextRemoved = async () => {
    if (currentSelectedData.currentSelectedBook === 1 && selectedPage.cards[currentSelectedData.currentSelectedBook].allowCustomResponse) {
      selectedPage.cards[currentSelectedData.currentSelectedBook].freetextInstructions.lines = JSON.parse(JSON.stringify(defaultFfrSubmitText)).lines;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    } else {
      const card = {
        ...selectedPage.cards[currentSelectedData.currentSelectedBook],
        text: null,
      };
      selectedPage.cards[currentSelectedData.currentSelectedBook] = card;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    }
  };

  const onAudioApply = async (audioData) => {
    selectedPage.cards[currentSelectedData.currentSelectedBook].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    selectedPage.cards[currentSelectedData.currentSelectedBook].audio = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageAndVideoSelected = async (media) => {
    // update image to the lesson
    selectedPage.cards[currentSelectedData.currentSelectedBook].image = media.selectedImageUrl;
    selectedPage.images[currentSelectedData.currentSelectedBook] = media.selectedImageUrl;
    try {
      const imageDimension = await getImageDimension(media.selectedImageUrl);
      selectedPage.cards[currentSelectedData.currentSelectedBook].authoringHeight = imageDimension.height;
      selectedPage.cards[currentSelectedData.currentSelectedBook].authoringWidth = imageDimension.width;
    } catch (e) {
      Logger.logWhenDebugModeIsOn('Cannot get the image from the server');
      Logger.logError(e);
      selectedPage.cards[currentSelectedData.currentSelectedBook].authoringHeight = 800;
      selectedPage.cards[currentSelectedData.currentSelectedBook].authoringWidth = 800;
    }

    // update video to the lesson
    selectedPage.cards[currentSelectedData.currentSelectedBook].video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    const card = {
      ...selectedPage.cards[currentSelectedData.currentSelectedBook],
      image: null,
      video: null,
    };
    selectedPage.cards[currentSelectedData.currentSelectedBook] = card;
    selectedPage.images[currentSelectedData.currentSelectedBook] = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <>
      <div>
        <br />
        <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
          <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
            {createBookCardPreview()}
          </Grid>
          <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', position: 'relative', padding: '12px' }}>
            <Box height='100%' className='center'>
              {currentSelectedData.selectedOption === SECTION_TEXT && (
                <Box height='100%'>
                  <TextAndMediaFormatting
                    text={
                      selectedPage.cards[currentSelectedData.currentSelectedBook] && selectedPage.cards[currentSelectedData.currentSelectedBook].allowCustomResponse
                        ? selectedPage.cards[currentSelectedData.currentSelectedBook] && selectedPage.cards[currentSelectedData.currentSelectedBook].freetextInstructions
                        : selectedPage.cards[currentSelectedData.currentSelectedBook] && selectedPage.cards[currentSelectedData.currentSelectedBook].text
                    }
                    onTextApply={onTextApply}
                    onTextRemoved={onTextRemoved}
                    enableMathEditor
                  />
                  <br />
                </Box>
              )}
              {currentSelectedData.selectedOption === SECTION_IMAGE && (
                <AddPhotoOrVideo
                  image={selectedPage.cards[currentSelectedData.currentSelectedBook] && selectedPage.cards[currentSelectedData.currentSelectedBook].image}
                  onSelect={onImageAndVideoSelected}
                  onRemove={onImageRemove}
                />
              )}
              {currentSelectedData.selectedOption === SECTION_AUDIO && (
                <AddAudio
                  audio={selectedPage.cards[currentSelectedData.currentSelectedBook] && selectedPage.cards[currentSelectedData.currentSelectedBook].audio}
                  onAudioApply={onAudioApply}
                  onAudioRemoved={onAudioRemoved}
                />
              )}
              {currentSelectedData.selectedOption === SECTION_NONE && (
                <NoLessonPaper message='Editing content is not applicable.' />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
      {openAllowCustomResponse.open && (
        <AllowCustomResponseConfirmation
          open={openAllowCustomResponse.open}
          onClose={() => {
            setOpenAllowCustomResponse({
              open: false,
              selectedIndex: null,
            });
          }}
          onConfirm={async () => {
            selectedPage.cards[openAllowCustomResponse.selectedIndex].allowCustomResponse = true;
            selectedPage.cards[openAllowCustomResponse.selectedIndex].customResponseOption = CUSTOM_RESPONSE_OPTIONS[0].id;
            selectedPage.cards[openAllowCustomResponse.selectedIndex].freetextInstructions = JSON.parse(JSON.stringify(defaultFfrSubmitText));
            onCustomResponseSelect(openAllowCustomResponse.selectedIndex);
            setOpenAllowCustomResponse({
              open: false,
              selectedIndex: null,
            });
          }}
        />
      )}

      <NotificationDialog
        open={customResponseNotification}
        onClose={() => { setCustomResponseNotification(false); }}
        Icon={LoopIcon}
        title='Custom response'
        confirmLabel='Close'
        message="Custom response page can't be changed or added to assigned activities"
      />
    </>
  );
}

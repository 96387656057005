import { isTexasTeacher } from './User';
import TEXAS_STAR_ICON from '../static/images/specialty-logos/TEXAS_STAR.png';
import ULS_LOGO_ICON from '../static/images/specialty-logos/ULS_LOGO.png';
import { SUBJECT_CONSTANTS, FULL_SORTED_SUBJECT_CONSTANTS, NON_ACADEMIC_SUBJECTS } from '../constants/LessonConstant';
import { flatten, shuffle, sortByAttributes } from './ArrayUtils';

/**
 * Lesson mapping class to map lesson data from remote service
 * to objects for container
 */
export const SubjectList = [
  SUBJECT_CONSTANTS.english.name,
  SUBJECT_CONSTANTS.math.name,
  SUBJECT_CONSTANTS.science.name,
  SUBJECT_CONSTANTS.social.name,
  SUBJECT_CONSTANTS.speech.name,
  SUBJECT_CONSTANTS.arts.name,
  SUBJECT_CONSTANTS.life.name,
  NON_ACADEMIC_SUBJECTS.socialSkills.name,
  NON_ACADEMIC_SUBJECTS.transitionalSkills.name,
  SUBJECT_CONSTANTS.misc.name,
];

/**
 * Add a lesson to a subject
 * @param {*} subjectMapping subject mapping
 * @param {*} subjectName subject name
 * @param {*} lesson lesson to add
 */
const addLessonToSubject = (subjectMapping, subjectName, lesson) => {
  subjectMapping.forEach((subjectListMap) => {
    if (subjectListMap.subject === subjectName) {
      subjectListMap.lessons.push(lesson);
    }
  });
};

/**
 * Create subject mapping lessons
 */
const createSubjectMappingLessons = () => {
  const result = SubjectList.map((subject) => (
    {
      subject,
      lessons: [],
    }
  ));
  return result;
};

/**
 * create lesson object for displaying in lesson grid
 * @param {*} lesson lesson object
 * @param {*} user user
 */
export const createLessonObject = (lesson, user) => {
  const isTexasUser = isTexasTeacher(user);
  let specialtyContent = {};
  if (isTexasUser && lesson.attributes.specialtyContentCode === 'STAAR_ALTERNATE') {
    specialtyContent = {
      cardImage: TEXAS_STAR_ICON,
      description: 'This activities aligns specifically to Texas Assessments of Academic Readiness. For More Info, please visit',
      url: 'https://tea.texas.gov/student.assessment/special-ed/staaralt/frameworks',
    };
  } else if (lesson.attributes.specialtyContentCode === 'UNIQUE_LEARNING_SYSTEM') {
    specialtyContent = {
      cardImage: ULS_LOGO_ICON,
      description: 'Unique Learning System is an online, dynamic, standards-based curriculum specifically designed for special learners. For More Info, please visit',
      url: 'https://www.n2y.com',
    };
  }
  const lessonToAdd = { ...lesson, specialtyContent };
  return lessonToAdd;
};

export const sortLessonByName = (lessons) => (
  lessons.sort((a, b) => a.attributes.name.trim().toLowerCase().localeCompare(b.attributes.name))
);

/**
 * Util function to map lessons to subject. The returned structure is
 *
 * {
 *  subject: 'subject name',
 *  lessons: []
 * }
 */
export const mapLessonsIntoSubject = (lessons, user, sorting = true) => {
  const subjectMapping = createSubjectMappingLessons();
  lessons.data.forEach((lesson) => {
    const lessonToAdd = createLessonObject(lesson, user);
    const subjectsFromLesson = lesson.attributes.subjects;
    if (subjectsFromLesson && subjectsFromLesson.length > 0) {
      subjectsFromLesson.forEach((subjectName) => {
        addLessonToSubject(subjectMapping, subjectName, lessonToAdd);
      });
    } else {
      addLessonToSubject(subjectMapping, SUBJECT_CONSTANTS.misc.name, lessonToAdd);
    }
  });

  const result = [];
  // sort object
  subjectMapping.forEach((record) => {
    result.push({
      subject: record.subject,
      lessons: sorting ? sortLessonByName(record.lessons) : record.lessons,
    });
  });
  return result;
};

export const mapRecommendedLessonObjects = (recommendedLessons, user) => {
  const result = recommendedLessons.data.map((lessonRow) => ({
    subject: lessonRow.attributes.title,
    subTitle: 'Recently Added',
    lessons: shuffle(lessonRow.relationships.lessons.data
      .map(
        (lesson) => createLessonObject(
          recommendedLessons.included.find((r) => r.type === lesson.type && r.id === lesson.id),
          user,
        ),
      )),
  }));

  return result;
};

export const mapLessonsIntoCourseUnitTopic = ({ lessons, topicList, user, sorting = true }) => {
  const courseUnitTopicMapping = {};
  lessons.data.forEach((lesson) => {
    const lessonToAdd = createLessonObject(lesson, user);
    const topicIdTitle = lesson.attributes.topicId;
    const courseUnitTopicObject = courseUnitTopicMapping[topicIdTitle] || [];
    courseUnitTopicMapping[topicIdTitle] = [...courseUnitTopicObject, lessonToAdd];
  });

  const keys = Object.keys(courseUnitTopicMapping).sort((a, b) => a.toString().localeCompare(b));
  const result = keys.map((key) => {
    const topic = topicList.find((t) => Number(t.id) === Number(key));
    return {
      subject: topic.displayName,
      subTitle: 'Go To',
      topic,
      lessons: sorting ? sortLessonByName(courseUnitTopicMapping[key]) : courseUnitTopicMapping[key],
    };
  });

  return result;
};

export const mapLessonStandards = (lessons, included) => {
  // eslint-disable-next-line max-len
  const allStandards = lessons.map((lesson) => (lesson && lesson.relationships && lesson.relationships.standards && lesson.relationships.standards.data.length ? lesson.relationships.standards.data : []));
  const standards = flatten(allStandards);
  // Get standard details from included array
  const standardListWithDetail = standards.map((s) => (
    included.find((i) => i.type === s.type && i.id === s.id)
  ));

  const uniqueStandards = [...new Map(standardListWithDetail.map((item) => [item.attributes.standardCode, item])).values()];
  const sortedUniqueStandards = sortByAttributes(uniqueStandards, 'standardCode');
  return sortedUniqueStandards;
};

export const getSubjectId = (lesson) => {
  let currentSubjectId = SUBJECT_CONSTANTS.misc.subjectId;
  if (lesson.attributes && lesson.attributes.subjects.length > 0 && lesson.attributes.subjects[0]) {
    currentSubjectId = (FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.name === lesson.attributes.subjects[0]) || SUBJECT_CONSTANTS.misc).subjectId;
  }
  return currentSubjectId;
};

export const sortLessonAttributes = (arr, attr, parentAttr = 'attributes') => (
  arr.sort((a, b) => {
    if (!a[parentAttr][attr] || !b[parentAttr][attr]) {
      return 1;
    }
    return a[parentAttr][attr].toString().localeCompare(b[parentAttr][attr], undefined, { numeric: true, sensitivity: 'base' });
  })
);

import { useState } from 'react';
import PropTypes from 'prop-types';
import ReinforcerListV2 from './ReinforcerListV2';
import './ReinforcerSettingForm.scss';
import ReinforcerStyleSetting from '../../mysettings/settings/reinforcers/ReinforcerStyleSetting';
import { REINFORCER_STYLE_KEY } from '../../../constants/PlayPreferenceConstant';
import SimpleReinforcer from './simple-reinforcer/SimpleReinforcer';
import SoundEffectSetting from '../sound-effect-setting/SoundEffectSetting';
import SelectAllSettings from '../select-all/SelectAllSettings';

const defaultReinforcers = [61, 62, 67];
export default function ReinforcerSettingForm({ profile, terminologies, onChangeCallback }) {
  const [internalReinforcerStyle, setInternalReinforcerStyle] = useState(profile.reinforcerStyle);
  const showReinforcerDetail = internalReinforcerStyle === REINFORCER_STYLE_KEY.animated;

  return (
    <div className='reinforcer-setting-form'>
      <div className='reinforcer-style-container'>
        <ReinforcerStyleSetting
          profile={profile}
          onChangeCallback={(newValue) => {
            setInternalReinforcerStyle(newValue);
            const value = { reinforcerStyle: newValue };
            if (newValue === REINFORCER_STYLE_KEY.animated && profile.reinforcers.length === 0) {
              value.reinforcers = [...defaultReinforcers];
              // eslint-disable-next-line no-param-reassign
              profile.reinforcers = [...defaultReinforcers];
            }
            onChangeCallback(value);
          }}
        />
        {showReinforcerDetail && (
          <SoundEffectSetting
            profile={profile}
            onChangeCallback={(newValue) => onChangeCallback({ reinforcerSound: newValue })}
            attributeName='reinforcerSound'
          />
        )}
      </div>
      {!showReinforcerDetail && internalReinforcerStyle === REINFORCER_STYLE_KEY.simple && (
        <SimpleReinforcer />
      )}
      {showReinforcerDetail && (
        <>
          <SelectAllSettings
            attributeName='reinforcers'
            defaultValue={[...defaultReinforcers]}
            profile={profile}
            terminologies={terminologies}
            onChangeCallback={(newValue) => onChangeCallback({ reinforcers: newValue })}
          />
          <ReinforcerListV2
            profile={profile}
            terminologies={terminologies}
            onChangeCallback={(newValue) => onChangeCallback({ reinforcers: newValue })}
          />
        </>
      )}
    </div>
  );
}

ReinforcerSettingForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

import { AppBar, Tab, Tabs } from '@mui/material';
import {
  Route,
  Navigate,
  Routes,
} from 'react-router-dom';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import './AdminHome.scss';
import { useGetAccessibleMenu, useHandleTab, useInitialDistrictData } from './AdminHomeHooks';
import { URL_ADMIN } from '../../routes/RouteConstants';
import { initDomains } from './context/AdminContextProvider';
import '../../components/steppers/TabStyle.scss';

const AdminHome = () => {
  initDomains();
  useInitialDistrictData(false);

  const {
    accessibleMenu,
  } = useGetAccessibleMenu();

  const {
    tabValue,
    handleTabChange,
    location,
  } = useHandleTab(accessibleMenu);

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div className='admin-home'>
        <TeacherTopAppbar />
        <AppBar position='static' color='default' className='vizzle-tab-menu-style'>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='fullWidth'
            scrollButtons='auto'
            indicatorColor='primary'
            textColor='primary'
            aria-label='Admin tabs'
          >
            {accessibleMenu.map((menu, index) => (
              <Tab key={`admin-menu-${index}`} label={menu.label} icon={menu.icon} />
            ))}
          </Tabs>
        </AppBar>
        <div className={`content ${location.replace(/\//g, '-').substring(1)}`}>
          <Routes>
            {accessibleMenu.map((menu, index) => (
              <Route
                key={`admin-menu-route-${index}`}
                path={`${menu.link}`}
                element={menu.element}
              />
            ))}
            <Route
              path='*'
              element={<Navigate to={`${URL_ADMIN}/${accessibleMenu[0].link}`} />}
            />
          </Routes>
        </div>
      </div>
    </VizzleMainContainer>
  );
};

export default AdminHome;

/* eslint-disable no-useless-escape */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import MathView from 'react-math-view';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import './MatchEquationEditorDialog.scss';
import MathEquationTextToSpeechEditor from './MathEquationTextToSpeechEditor';
import { useDomain } from '../../../../../../states/AppDomainProvider';

export default function MatchEquationEditorDialog({ openDialog, onApply, onClose, textToRead, ttsText }) {
  const ref = useRef(null);
  // const [state, setState] = useState('');
  const ttsTextRef = useRef({ ...ttsText });
  const { uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const isTtsTextAvailable = user.userModerator || user.unitCreator;

  return (
    <CustomDialog
      title='Math Equation Editor'
      openDialog={openDialog}
      onClose={onClose}
      displayCloseIcon
      className='math-editor-container-dialog'
      TransitionProps={{
        onEntered: () => {
          ref.current.focus();
        },
      }}
      content={(
        <div className='math-editor-container' data-test='math-editor-container'>
          <div className='math-container'>
            <MathView
              className='math-view-component'
              // value={state}
              // onChange={(e) => {
              //   setState(e.target.value);
              // }}
              virtualKeyboardMode='manual'
              ref={ref}
            />
          </div>
          {isTtsTextAvailable && (
            <MathEquationTextToSpeechEditor
              text={textToRead}
              ttsText={ttsText}
              onChange={(equationText, value) => {
                ttsTextRef.current[equationText] = value;
              }}
            />
          )}
        </div>
      )}
      actions={[
        {
          title: isTtsTextAvailable ? 'Close' : 'Cancel',
          color: 'primary',
          className: 'btn-danger',
          onClick: onClose,
        },
        {
          title: isTtsTextAvailable ? 'Apply' : 'Add',
          color: 'primary',
          className: 'btn-primary',
          onClick: async () => {
            onApply(ref.current.value, ttsTextRef.current, !isTtsTextAvailable);
            if (isTtsTextAvailable) {
              ref.current.setValue('');
              await uiDomain.showSnackbar(true, 'success', 3000, 'Math equation is updated.');
            }
          },
        },
      ]}
    />
  );
}

MatchEquationEditorDialog.defaultProps = {
  openDialog: false,
  onApply: () => { },
  onClose: () => { },
  textToRead: null,
  ttsText: {},
};

MatchEquationEditorDialog.propTypes = {
  openDialog: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  textToRead: PropTypes.string,
  ttsText: PropTypes.object,
};

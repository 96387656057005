import unicorn from '../../../../../static/images/activities/game/pieces/unicorn.png';
import balloon from '../../../../../static/images/activities/game/pieces/balloon.png';
import boat from '../../../../../static/images/activities/game/pieces/boat.png';
import car from '../../../../../static/images/activities/game/pieces/car.png';
import helicopter from '../../../../../static/images/activities/game/pieces/helicopter.png';
import princess from '../../../../../static/images/activities/game/pieces/princess.png';
import sailboat from '../../../../../static/images/activities/game/pieces/sailboat.png';
import teddy from '../../../../../static/images/activities/game/pieces/teddy.png';
import train from '../../../../../static/images/activities/game/pieces/train.png';
import chessBlue from '../../../../../static/images/activities/game/pieces/chess_blue.png';
import chessGreen from '../../../../../static/images/activities/game/pieces/chess_green.png';
import chessOrange from '../../../../../static/images/activities/game/pieces/chess_orange.png';
import chessPurple from '../../../../../static/images/activities/game/pieces/chess_purple.png';
import chessRed from '../../../../../static/images/activities/game/pieces/chess_red.png';
import chessYellow from '../../../../../static/images/activities/game/pieces/chess_yellow.png';

export const playerPieceItems = [
  {
    id: 0, value: 0, text: 'Unicorn', image: unicorn,
  },
  {
    id: 1, value: 1, text: 'Balloon', image: balloon,
  },
  {
    id: 2, value: 2, text: 'Boat', image: boat,
  },
  {
    id: 3, value: 3, text: 'Car', image: car,
  },
  {
    id: 4, value: 4, text: 'Helicopter', image: helicopter,
  },
  {
    id: 5, value: 5, text: 'Princess', image: princess,
  },
  {
    id: 6, value: 6, text: 'Sailboat', image: sailboat,
  },
  {
    id: 7, value: 7, text: 'Teddy', image: teddy,
  },
  {
    id: 8, value: 8, text: 'Train', image: train,
  },
  {
    id: 9, value: 9, text: 'Blue', image: chessBlue,
  },
  {
    id: 10, value: 10, text: 'Green', image: chessGreen,
  },
  {
    id: 11, value: 11, text: 'Orange', image: chessOrange,
  },
  {
    id: 12, value: 12, text: 'Purple', image: chessPurple,
  },
  {
    id: 13, value: 13, text: 'Red', image: chessRed,
  },
  {
    id: 14, value: 14, text: 'Yellow', image: chessYellow,
  },
];

export default playerPieceItems;

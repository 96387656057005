import BaseDomain from '../../../states/domains/BaseDomain';

const MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT = 'MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT';
const MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT_TABLE_STATE = 'MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT_TABLE_STATE';

const initialState = {
  classRoomReport: {
    selectedCriteria: {},
    tableData: [],
    tableState: undefined,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT:
      return {
        ...state,
        classRoomReport: action.payload,
      };
    case MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT_TABLE_STATE:
      return {
        ...state,
        classRoomReport: {
          ...state.classRoomReport,
          tableState: action.payload,
        },
      };
    default:
      return state;
  }
};

const domainName = 'myClassroomDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update classroom report
   *
   * @returns Promise
   */
  async updateClassRoomReport(classRoomReport) {
    return this.dispatch({
      type: MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT,
      payload: classRoomReport,
    });
  }

  /**
 * Update classroom report
 *
 * @returns Promise
 */
  async updateClassRoomTableState(classRoomReportTableState) {
    return this.dispatch({
      type: MY_CLASS_ROOM_UPDATE_CLASSROOM_REPORT_TABLE_STATE,
      payload: classRoomReportTableState,
    });
  }
}

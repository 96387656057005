import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleForm from '../../../components/toggle/ToggleForm';

const RedXPrompting = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.prompt);
  const isRedXPrompting = profile.prompt;
  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(isRedXPrompting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleRedXPromptingOnchange = (field, newValue) => {
    if (newValue === null) return;
    setToggle(newValue);
    onChangeCallback(newValue);
  };

  return (
    <ToggleForm value={toggle} handleOnChange={handleRedXPromptingOnchange} />
  );
};

RedXPrompting.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default RedXPrompting;

import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartContainer from './ChartContainer';

const PieChartRing = ({
  title,
  dataNumber,
  innerLabel,
  colors,
  onClick,
}) => {
  const formattedDataNumber = dataNumber ? dataNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : dataNumber;

  return (
    <ChartContainer title={title} isDataAvailable={dataNumber > 0} onClick={onClick}>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%', height: '100%' } }}
        options={{
          chart: {
            type: 'pie',
            polar: false,
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              innerSize: '80%',
              dataLabels: { enabled: false },
            },
          },
          colors,
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },
          subtitle: {
            text: `<div class='chart-subtitle'>${formattedDataNumber}</div><br/>${innerLabel}`,
            floating: true,
            style: {
              fontSize: '1rem',
              color: '#5a5759',
              lineHeight: '25px',
            },
            y: 90,
          },
          tooltip: { enabled: false },
          series: [{
            data: [dataNumber],
          }],
        }}
      />
    </ChartContainer>
  );
};

PieChartRing.defaultProps = {
  title: '',
  dataNumber: 0,
  innerLabel: '',
  colors: ['#66B767', '#ED1C24'],
  onClick: null,
};

PieChartRing.propTypes = {
  title: PropTypes.string,
  dataNumber: PropTypes.number,
  innerLabel: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

export default PieChartRing;

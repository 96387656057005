import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
} from '@mui/material';
import InstructionEditor from '../common/instruction/InstructionEditor';
import './SortingEditor.scss';
import CardEditor from '../common/card-editor/CardEditor';
import { AREA_INSTRUCTIONS, AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS, SECTION_ANSWER_KEY, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../common/CardEditorConstants';

export default function SortingEditor({
  instructionsConfig,
  promptCardsConfig,
  responseCardsConfig,
  selectedSection,
}) {
  // eslint-disable-next-line no-unused-vars
  const createCard = (className, field, config, decoratorFunc) => (
    config[field].map((c, cardIndex) => (
      <CardEditor
        key={`${className}-${cardIndex}`}
        className={className}
        isSelected={
          selectedSection.selectedArea === field
          && selectedSection.selectedCardIndex === cardIndex
        }
        config={{
          text: c.text,
          image: c.image,
          video: c.video,
          audio: c.audio,
        }}
        selectedSection={selectedSection.selectedSection}
        onImageSelected={(e) => {
          config.onSelected(e, field, SECTION_IMAGE, cardIndex);
        }}
        onTextSelected={(e) => {
          config.onSelected(e, field, SECTION_TEXT, cardIndex);
        }}
        onAudioSelected={(e) => {
          config.onSelected(e, field, SECTION_AUDIO, cardIndex);
        }}
      >
        {decoratorFunc ? decoratorFunc(c, cardIndex, field, config) : ''}
      </CardEditor>
    ))
  );

  const promptCardKey = (card) => (
    <div className='prompt-card-key'>{card.key}</div>
  );

  const handleOnResponseCardKey = (e, field, cardIndex, config) => {
    if (selectedSection.selectedCardIndex === cardIndex) {
      return;
    }
    config.onSelected(e, field, SECTION_ANSWER_KEY, cardIndex);
  };

  const responseCardKey = (card, cardIndex, field, config) => (
    responseCardsConfig.availableSortingKeys
      ? (
        <>
          <Select
            value={card.key || ''}
            onChange={(e) => { responseCardsConfig.onKeyChanged(e, cardIndex); }}
            onOpen={(e) => handleOnResponseCardKey(e, field, cardIndex, config)}
            variant='outlined'
            placeholder='Key'
            fullWidth
            className='response-card-key'
          >
            {responseCardsConfig.availableSortingKeys.map((key, keyIndex) => (
              <MenuItem
                className='sorting-response-key'
                key={`sorting-editor-response-key-selector-${cardIndex}-${keyIndex}`}
                value={key}
              >
                {key}
              </MenuItem>
            ))}
          </Select>
          {
            responseCardsConfig.availableSortingKeys[0] === card.key ? <div className='response-key-overlay' /> : ''
          }
        </>
      ) : ''
  );

  return (
    <div className='sorting-card-editor' data-test='sorting-card-editor'>
      <div className='sorting-card-editor-left-column'>
        <InstructionEditor
          instructionsConfig={instructionsConfig}
          selectedSection={selectedSection}
          className='sorting-card-editor-instruction'
        />
        <div className='sorting-card-editor-prompts'>
          {createCard(
            'sorting-card-editor-prompt',
            AREA_PROMPT_CARDS,
            promptCardsConfig,
            promptCardKey,
          )}
        </div>
      </div>
      <div className='sorting-card-editor-right-column sorting-card-editor-responses'>
        {createCard(
          'sorting-card-editor-response',
          AREA_RESPONSE_CARDS,
          responseCardsConfig,
          responseCardKey,
        )}
      </div>
    </div>
  );
}

SortingEditor.defaultProps = {
  instructionsConfig: {
    onSelected: () => { },
  },
  promptCardsConfig: {
    promptCards: [],
    onSelected: () => { },
  },
  responseCardsConfig: {
    reponseCards: [],
    availableSortingKeys: null,
    onKeyChanged: () => { },
    onSelected: () => { },
  },
  selectedSection: {
    selectedArea: AREA_INSTRUCTIONS,
    selectedCardIndex: 0,
    selectedSection: SECTION_AUDIO,
  },
};

SortingEditor.propTypes = {
  instructionsConfig: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  promptCardsConfig: PropTypes.shape({
    promptCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    })),
    onSelected: PropTypes.func,
  }),
  responseCardsConfig: PropTypes.shape({
    responseCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    })),
    onKeyChanged: PropTypes.func,
    availableSortingKeys: PropTypes.arrayOf(PropTypes.string),
    onSelected: PropTypes.func,
  }),
  selectedSection: PropTypes.shape({
    selectedArea: PropTypes.oneOf([AREA_INSTRUCTIONS, AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS]),
    selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO, SECTION_ANSWER_KEY]),
    selectedCardIndex: PropTypes.number,
  }),
};

import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import NoLessonPaper from '../../../paper/NoLessonPaper';
import LessonCard from '../../../card/LessonCard';
import LessonDetail from './LessonDetail';
import ViewLessonDetailAction from './actions/ViewLessonDetailAction';
import CardDetailContainer, { useCardDetail } from '../CardDetailContainer';
import './FlatLessonCardListDetail.scss';
import { calculateCardSize } from '../../../../utils/CardSizeCalculator';

const FlatLessonCardListDetail = ({
  cardSize,
  lessonData,
  noLessonMessage,
  createCardAction,
  createCardDetailAction,
  currentUser,
  currentUserProfle,
  onLessonSelect,
  selectedLessonIds,
  createLessonDetailAction,
}) => {
  const {
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openCardDetail,
  } = useCardDetail();

  const lessonList = useMemo(() => (
    <div className='list-detail-container'>
      {
        lessonData.lessons.length > 0 ? (
          lessonData.lessons.map((lesson, cardIndex) => {
            const actions = createCardAction(lesson, cardIndex) || [];
            const key = `vizzle-lesson-detail-lesson-index-${cardIndex}`;
            actions.push(
              <ViewLessonDetailAction
                key={key}
                className={key}
                onClick={() => { handleOnViewDetailClick(cardIndex, 0); }}
              />,
            );
            return (
              <Box
                key={`lessons-action-id-${lesson.attributes.uuid}`}
                className='lesson-card-action'
                onClick={() => onLessonSelect(lesson)}
              >
                <LessonCard
                  key={`lessons-id-${lesson.attributes.uuid}`}
                  lessonData={lesson}
                  width={cardSize.width}
                  actions={actions}
                  isHighligthed={selectedLessonIds.includes(lesson.id)}
                />
              </Box>
            );
          })
        ) : <NoLessonPaper message={noLessonMessage} />
      }
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [lessonData.lessons, selectedLessonIds]);

  const cardSlide = useRef(null);

  const lessonDetail = () => (
    openCardDetail.open ? (
      <CardDetailContainer
        ref={cardSlide}
        open={openCardDetail.open}
        selectedIndex={openCardDetail.selectedIndex}
        onClose={handleOnCloseDetail}
        dataList={lessonData.lessons}
        createCardFunction={(lesson) => (
          <LessonDetail
            key={`lesson-detail-${lesson.id}`}
            lesson={lesson}
            currentUser={currentUser}
            currentUserProfle={currentUserProfle}
            included={lessonData.included}
            actions={createCardDetailAction(
              lesson,
              currentUser,
              0,
              () => {
                handleOnCloseDetail();
              },
            )}
            lessonDetailActions={createLessonDetailAction(lesson)}
          />
        )}
      />
    ) : null
  );

  return (
    <Fade in>
      <div className='flat-lesson-card-list-detail'>
        {lessonList}
        {lessonDetail()}
      </div>
    </Fade>
  );
};

FlatLessonCardListDetail.defaultProps = {
  cardSize: calculateCardSize(),
  lessonData: {},
  noLessonMessage: 'No Activities Available',
  createCardAction: () => ([]),
  createCardDetailAction: () => ([]),
  currentUser: {},
  currentUserProfle: {},
  onLessonSelect: () => { },
  selectedLessonIds: [],
  createLessonDetailAction: () => ([]),
};

FlatLessonCardListDetail.propTypes = {
  cardSize: PropTypes.shape({
    width: PropTypes.number,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
  }),
  lessonData: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    lessons: PropTypes.array,
    included: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.object,
    })),
  }),
  noLessonMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  createCardAction: PropTypes.func,
  createCardDetailAction: PropTypes.func,
  currentUser: PropTypes.shape({
    userName: PropTypes.string,
  }),
  currentUserProfle: PropTypes.shape({
    id: PropTypes.number,
  }),
  onLessonSelect: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  createLessonDetailAction: PropTypes.func,
};

export default FlatLessonCardListDetail;

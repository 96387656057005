import useSettingForm from '../form/useSettingForm';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../states/AppDomainProvider';
import { getTerminologies } from '../../../../services/UserProfileService';
import Seasonal from '../../../settings/seasonal/Seasonal';

const SeasonalFilter = () => {
  const { updateProfileSettingsOnChange, loading } = useSettingForm('Seasonal Filter');
  const { uiDomain, userDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;

  const refreshTerminologies = async () => {
    uiDomain.showLoader('Refreshing terminologies', LOADER_TYPE.HASH_LOADER);
    const result = await getTerminologies(user.id);
    uiDomain.hideLoader();
    return result;
  };

  const onChange = async (newValue) => {
    await updateProfileSettingsOnChange({
      filter: newValue,
    }, () => refreshTerminologies());
    uiDomain.hideLoader();
  };

  return (
    <Seasonal
      profile={userProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
      loading={loading}
    />
  );
};

export default SeasonalFilter;

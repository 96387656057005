import {
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';
import GeneralApperance from './GeneralApperance';
import InteractionOptions from './InteractionOptions';
import ChangePassword from './ChangePassword';
import TextToSpeechSettings from './TextToSpeechSettings';
import './StudentsSettings.scss';
import { updateCredentialAllStudentsTooltipText } from '../../../../texts/TooltipText';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';

const StudentsSettings = () => {
  const { studentsDomain, userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { b2cId } = userProfile;
  const isB2cId = Boolean(b2cId);

  const { students } = studentsDomain.domainData;
  const hasStudents = students && students.length > 0;

  const components = [
    { title: 'General Appearance', component: <GeneralApperance /> },
    { title: 'Interaction Options', component: <InteractionOptions /> },
    { title: 'Text to Speech', component: <TextToSpeechSettings /> },
    { title: 'Change Passwords for All Students', component: <ChangePassword />, tooltip: isB2cId ? updateCredentialAllStudentsTooltipText : null },
  ];

  return (
    <div className='student-settings'>
      <div className='text-area'>
        <p>This will apply Settings to all of your students</p>
        <p>For a complete list of options, see an individual student&apos;s Settings tab</p>
        {!hasStudents ? (
          <p className='no-student-message'>
            You do not have any students on your roster currently.
            This panel will be enabled when you have 1 or more students
          </p>
        ) : null}
      </div>

      {hasStudents ? (
        <div className='setting-list'>
          {components.map((entry, index) => (
            <Paper elevation={3} key={`setting-entry-${index}`} className='setting-entry'>
              <Typography variant='h6' className='setting-title'>
                <Stack direction='row' gap='10px' alignItems='center'>
                  {entry.title} {entry.tooltip ? <TooltipWithIndicator {...entry.tooltip} /> : null}
                </Stack>
              </Typography>
              {entry.component}
            </Paper>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default StudentsSettings;

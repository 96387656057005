import { useState } from 'react';
import { contactAccountManager } from '../../../../../services/AdminService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../../context/AdminContextProvider';

export const usePrepareData = () => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { districtsDomain, adminDataDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  const { searchCriteria } = adminDataDomain.domainData;
  const { districtIds } = searchCriteria;

  let accountManagerEmail = null;
  if (districts && districts.length > 0 && districtIds && districtIds.length > 0) {
    const selectedDistrictId = districtIds[0];
    const districtObject = districts.find((d) => d.id === selectedDistrictId);
    if (districtObject) {
      accountManagerEmail = districtObject.accountManager;
    }
  }
  const {
    licenseExpiryDays,
  } = userProfile;

  return {
    accountManagerEmail,
    licenseExpiryDays,
  };
};

export const useCustomDialog = ({
  clearMessage,
}) => {
  const [open, setOpen] = useState(false);

  const handleOnCancel = () => {
    clearMessage();
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return {
    open,
    handleOnCancel,
    openDialog,
  };
};

export const useHandleMessageTextField = () => {
  const [message, setMessage] = useState('');

  const handleOnMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const clearMessage = () => {
    setMessage('');
  };

  return {
    message,
    handleOnMessageChange,
    clearMessage,
  };
};

export const useHandleOnSubmit = ({
  accountManagerEmail,
  message,
  onSubmitSuccess,
}) => {
  const { uiDomain } = useDomain();
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  const submitMessage = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await contactAccountManager(accountManagerEmail, message);
        onSubmitSuccess();
        await uiDomain.showSnackbar(true, 'info', 3000, 'Message sent successfully');
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    submitMessage,
  };
};

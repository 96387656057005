import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import ReinforcerSettingForm from '../../../settings/reinforcers/ReinforcerSettingForm';

const ReinforcersSetting = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Reinforcers');
  const { userDomain } = useDomain();
  const { userProfile, terminologies } = userDomain.domainData;

  const onChange = (reinforcerValue) => {
    updateProfileSettingsOnChange({
      ...reinforcerValue,
    });
  };

  return (
    <ReinforcerSettingForm
      profile={userProfile}
      terminologies={terminologies}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default ReinforcersSetting;

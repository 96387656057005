import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import ScreenReader from '../../../settings/ScreenReader';

const ScreenReaderSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('JAWS® or other Screen Reader');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const onConfirm = () => {
    updateProfileSettingsOnChange({
      screenReaderOn: true,
      switchAccessibility: 1,
      interactionType: 1,
      tts: { ...userProfile.tts, enabled: true },
    });
  };

  const onChange = (newValue) => {
    updateProfileSettingsOnChange({
      screenReaderOn: newValue,
    });
  };

  return (
    <ScreenReader
      profile={userProfile}
      onConfirmCallback={onConfirm}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default ScreenReaderSettings;

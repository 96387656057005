import BounceLoader from 'react-spinners/BounceLoader';
import './LessonLoader.scss';

/**
 * Play Lesson Action Container
 */
export default function LessonLoader() {
  return (
    <div className='lesson-loader'>
      <BounceLoader size={70} color='#3b94d1' />
      <br />
      <div className='loading-message'>
        Please wait while we grab your activity!
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { RouterConfiguration } from '../../routes/RouterConfiguration';
import { registerMedia } from '../../services/MediaRegistrationService';
import { initAppDomains } from '../../states/AppDomainProvider';
import VizzleEventListenerContainer from '../vizzle/VizzleEventListenerContainer';
import './App.scss';
import { useInitializationWhenLogin } from './AppHooks';
import MicrosoftCopilot from '../../components/copilot/MicrosoftCopilot';

const preventEvent = (e) => {
  e.preventDefault();
};

const registerMediaAndTts = () => {
  registerMedia();
};

function App() {
  initAppDomains();
  const { user } = useInitializationWhenLogin();

  useEffect(() => {
    // Prevent the user drag and drop a file to the app and causes the browser open the file
    window.addEventListener('dragover', preventEvent, false);
    window.addEventListener('drop', preventEvent, false);

    // Register media elements for IOS
    window.addEventListener('mousedown', registerMediaAndTts, { once: true });

    // Cleanup the state
    return () => {
      window.removeEventListener('drop', preventEvent);
      window.removeEventListener('dragover', preventEvent);

      window.removeEventListener('mousedown', registerMediaAndTts, { once: true });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div className='App'>
        <RouterConfiguration />
      </div>
      <VizzleEventListenerContainer />
      {user.copilot && <MicrosoftCopilot />}
    </Router>
  );
}

export default App;

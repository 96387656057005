/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useRef } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useAdmin } from '../../context/AdminContextProvider';

export const useGetLicenseTable = () => {
  const { adminDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const tableRef = useRef(null);

  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '15%',
    },
    {
      title: 'First name',
      align: 'left',
      dataField: 'firstName',
      width: '15%',
    },
    {
      title: 'Last name',
      align: 'left',
      dataField: 'lastName',
      width: '15%',
    },
    {
      title: 'Type',
      align: 'left',
      dataField: 'type',
      width: '15%',
    },
    {
      title: 'Active',
      align: 'center',
      dataField: 'active',
      width: '10%',
      dataRenderer: (data) => {
        if (data.active) {
          return <CheckBoxIcon className='checkbox-icon' />;
        } else {
          return <CheckBoxOutlineBlankIcon className='checkbox-icon' />;
        }
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      dataField: 'quantity',
      width: '10%',
    },
    {
      title: 'Start Date',
      align: 'center',
      dataField: 'startDate',
      width: '10%',
    },
    {
      title: 'expiry Date',
      align: 'center',
      dataField: 'expirationDate',
      width: '10%',
    },
  ];

  const licensesTableData = useMemo(() => {
    if (usersAndLicensesData && usersAndLicensesData.data && usersAndLicensesData.data.relationships && usersAndLicensesData.data.relationships.licenses) {
      const tableData = usersAndLicensesData.data.relationships.licenses.data.map((d) => {
        const licenseObject = usersAndLicensesData.included.find((li) => li.id === d.id && li.type === d.type);
        const { attributes, relationships } = licenseObject;
        const result = {};

        if (relationships) {
          const { assignedTeacher } = relationships;
          const userObject = usersAndLicensesData.included.find((li) => li.id === assignedTeacher.data.id && li.type === assignedTeacher.data.type);
          result.userName = userObject.attributes.userName;
          result.firstName = userObject.attributes.firstName;
          result.lastName = userObject.attributes.lastName;
        }

        result.id = d.id;
        result.type = attributes.type;
        result.quantity = attributes.quantity;
        result.active = attributes.active;
        result.startDate = attributes.startDate;
        result.expirationDate = attributes.expirationDate;

        return result;
      });
      return tableData;
    }
    return [];
  }, [usersAndLicensesData]);

  return {
    tableConfig,
    licensesTableData,
    tableRef,
  };
};

export const useHandleLicenseTable = () => {
  const { licensesDomain } = useAdmin();
  const { selectedLicenseIds } = licensesDomain.domainData;
  const updateLicenseRef = useRef(null);

  const handleOnSelectedRowIds = (selectedRowIds, clickParam) => {
    licensesDomain.updateSelectedLicenseIds(selectedRowIds);
    if (clickParam && clickParam.doubleClicks && updateLicenseRef.current) {
      updateLicenseRef.current.openUpdateLicense();
    }
  };

  return {
    selectedLicenseIds,
    handleOnSelectedRowIds,
    updateLicenseRef,
  };
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useAdmin } from '../../../context/AdminContextProvider';
import GenericButton from '../../../components/buttons/GenericButton';
import { useGetStudentName, useReactivateStudents } from './StudentActionHooks';
import CustomDialog from '../../../../../components/dialog/CustomDialog';

const ReactivateStudentAction = ({ onStudentReactivateSuccess }) => {
  const [open, setOpen] = useState(false);
  const { adminStudentsDomain } = useAdmin();
  const { selectedStudentIds } = adminStudentsDomain.domainData;
  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    getStudentName,
  } = useGetStudentName();

  const {
    reactivateStudents,
    loading,
  } = useReactivateStudents({
    setOpen,
    onStudentReactivateSuccess,
  });

  const confirmActions = [{
    onClick: handleOnCancel,
    className: 'btn-danger margin-right',
    title: 'No',
    loading,
  }, {
    onClick: () => {
      reactivateStudents();
    },
    className: 'btn-primary action-button',
    title: 'Yes',
    loading,
  },
  ];

  return (
    <>
      <GenericButton
        ariaLabel={`Reactivate Student${selectedStudentIds && selectedStudentIds.length > 1 ? 's' : ''}`}
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedStudentIds || selectedStudentIds.length === 0}
        icon={PostAddIcon}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          onClose={handleOnCancel}
          title={`Reactivate Student${selectedStudentIds.length > 1 ? 's' : ''}`}
          content={(
            <div data-private>
              Are you sure you want to reactivate {selectedStudentIds.length === 1 ? getStudentName(selectedStudentIds[0]) : 'the selected students'}?
            </div>
          )}
          displayCloseIcon
          actions={confirmActions}
        />
      )}
    </>
  );
};

ReactivateStudentAction.defaultProps = {
  onStudentReactivateSuccess: () => null,
};

ReactivateStudentAction.propTypes = {
  onStudentReactivateSuccess: PropTypes.func,
};

export default ReactivateStudentAction;

import { useNavigate, useLocation } from 'react-router-dom';
import {
  URL_MY_CLASSROOM,
  STUDENT_LANDING,
  URL_TERMS_OF_USE,
  URL_ADMIN,
  URL_SETUP_TWO_FACTOR_AUTH,
  URL_ENTER_TWO_FACTOR_AUTH,
} from '../../routes/RouteConstants';
import { MY_CLASSROOM_ROUTES } from '../myclassroom/MyClassRoomConstant';
import { isTeacher, isMfaUser } from '../../utils/User';
import { getListOfStudents } from '../../services/StudentService';
import { getUserProfile, getTerminologies } from '../../services/UserProfileService';
import { useDomain } from '../../states/AppDomainProvider';
import { getLocalStorageLessonResponseEvents, setLocalStorageLessonResponseEvents } from '../../utils/VizzleSessionStorage';
import { submitLessonEvent, submitLessonResponseEvent } from '../../services/LessonPlayService';
import ChurnZeroService from '../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../constants/ChurnZeroConstant';
import GainsightService from '../../services/GainsightService';
import Logger from '../../utils/Logger';
import LogRocket from '../../services/LogRocket';

export const usePrepareData = () => {
  const { userDomain, uiDomain, studentsDomain } = useDomain();
  const navigate = useNavigate();

  const updateUserData = async (teacherData) => {
    await userDomain.updateCurrentUser(teacherData, {}, {});
  };

  const location = useLocation();
  const { state } = location;
  const { redirecTo, extraParams } = state || {};

  const checkUserActive = (userData) => {
    if (userData.isLicenseInvalid) {
      uiDomain.showNotificationDialog(true, null, null, 'We\'re sorry but your account is no longer active. Please contact your admin for assistance.', 'Ok', true);
    }
  };

  const prepareDataForTeacher = async (teacherData) => {
    // sendTrackingEventToIntercom('React Login');
    const userProfileResultPromise = getUserProfile(teacherData.id);
    const userTerminologiesPromise = getTerminologies(teacherData.id);
    const studentListPromise = getListOfStudents(teacherData.id);
    const [userProfileResult, studentList, userTerminologies] = await Promise.all([userProfileResultPromise, studentListPromise, userTerminologiesPromise]);
    await userDomain.updateCurrentUser(teacherData, userProfileResult, userTerminologies);
    await studentsDomain.updateStudentList(studentList.students);

    checkUserActive(teacherData);
    try {
      // GainsightService.initGainSight(teacherData);
      GainsightService.trackTeacher(teacherData);
      ChurnZeroService.initChurnZero(teacherData, userProfileResult);
      ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.LOGIN, 'Educator Login', teacherData);
      LogRocket.identifyUser(teacherData);
    } catch (e) {
      Logger.logError(e);
    }

    return {
      students: studentList ? studentList.students : [],
    };
  };

  const prepareDataForStudent = async (studentData) => {
    // sendTrackingEventToIntercom('React Login');
    const userProfileResultPromise = getUserProfile(studentData.id);
    const userTerminologiesPromise = getTerminologies(studentData.id);
    const [userProfileResult, userTerminologies] = await Promise.all([userProfileResultPromise, userTerminologiesPromise]);
    try {
      // GainsightService.initGainSight(studentData);
      GainsightService.trackStudent(studentData);
      LogRocket.identifyUser(studentData);
    } catch (e) {
      Logger.logError(e);
    }
    await userDomain.updateCurrentUser(studentData, userProfileResult, userTerminologies);
    checkUserActive(studentData);
  };

  const redirectUserToLandingPage = (students, result) => {
    if (result.userAdmin) {
      navigate(URL_ADMIN);
    } else if (students.length === 0) {
      navigate(
        `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.STUDENT_MANAGEMENT}`,
      );
    } else {
      navigate(
        `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.CLASS_REPORT}`,
      );
    }
  };

  const prepareData = async (result, targetUrl) => {
    if (isTeacher(result)) {
      if (result.tosFlag) {
        if (isMfaUser(result) && process.env.REACT_APP_SKIP_MFA !== 'true') {
          await userDomain.updateCurrentUser(result);
          if (result.mfasetup) {
            navigate(
              URL_ENTER_TWO_FACTOR_AUTH,
              {
                state: {
                  redirecTo,
                },
              },
            );
          } else {
            navigate(URL_SETUP_TWO_FACTOR_AUTH);
          }
        } else {
          const { students } = await prepareDataForTeacher(result);
          const targetRedirect = targetUrl || redirecTo;
          if (targetRedirect) {
            navigate(
              targetRedirect.startsWith('/') ? targetRedirect : `/${targetRedirect}`,
              {
                state: {
                  exitPath: `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.MY_LESSONS}`,
                  ...extraParams,
                },
              },
            );
          } else {
            redirectUserToLandingPage(students, result);
          }
        }
      } else {
        // sendTrackingEventToIntercom('React Login');
        await updateUserData(result);
        navigate(URL_TERMS_OF_USE);
      }
    } else {
      await prepareDataForStudent(result);
      const targetRedirect = targetUrl || redirecTo;
      if (targetRedirect) {
        navigate(
          targetRedirect.startsWith('/') ? targetRedirect : `/${targetRedirect}`,
          {
            state: {
              exitPath: STUDENT_LANDING,
            },
          },
        );
      } else {
        navigate(STUDENT_LANDING);
      }
    }
  };
  return {
    prepareData,
    prepareDataForTeacher,
    prepareDataForStudent,
    redirectUserToLandingPage,
  };
};

export const saveLessonResponseEventsInCache = () => {
  const responseEvents = getLocalStorageLessonResponseEvents() || [];
  setLocalStorageLessonResponseEvents([]);
  if (responseEvents && responseEvents.length > 0) {
    responseEvents.forEach((r) => {
      if (r.eventType === 'RESPONSE') {
        submitLessonResponseEvent(r);
      } else {
        submitLessonEvent(r);
      }
    });
  }
};

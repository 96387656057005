import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import CustomDialog from '../../../../../../../components/dialog/CustomDialog';
import './UpdateReviewStatusAction.scss';
import UpdateReviewStatusForm from './form/UpdateReviewStatusForm';
import { useUpdateReviewStatus } from './UpdateReviewStatusActionHooks';
import VizzleSnackbar from '../../../../../../../components/snackbar/VizzleSnackbar';
import { useHandleSnackbar } from './form/FreeFormAnswerHooks';
import { REVIEW_FINISHED_ID, REVIEW_STATUSES, UNDER_REVISION_ID } from '../../../../../components/tracking/actions/LessonTrackingConstants';

const UpdateReviewStatusAction = ({
  answerLesson,
  onStatusUpdateSucccess,
  answerFreeFormData,
  allFfrPages,
}) => {
  const [answerLessonData, setAnswerLessonData] = useState();
  const [open, setOpen] = useState(false);

  const reviewStatus = REVIEW_STATUSES.find((r) => r.id === answerLesson.reviewStatus) || REVIEW_STATUSES[0];

  const {
    snackbar,
    onSnackbarClose,
    openSnackbar,
  } = useHandleSnackbar();

  const {
    isSaving,
    updateReviewStatus,
  } = useUpdateReviewStatus({
    setOpen,
    onStatusUpdateSucccess,
    onSnackbarClose,
    openSnackbar,
  });

  const answerPageNumbers = [...new Set(answerFreeFormData.filter((a) => Boolean(a.attributes.comments)).map((a) => a.attributes.pageNumber - 1))];

  return (
    <>
      <Button
        className='btn-primary update-review-status-action'
        data-test='free-form-answer-update-review-status-action'
        onClick={() => {
          setAnswerLessonData(JSON.parse(JSON.stringify(answerLesson)));
          setOpen(true);
        }}
      >
        Status: {reviewStatus.name}
      </Button>

      {open && (
        <CustomDialog
          openDialog={open}
          className='update-review-status-action-dialog'
          onClose={() => {
            setOpen(false);
          }}
          title={<Typography className='title'>Review Status</Typography>}
          content={(
            <div>
              <UpdateReviewStatusForm
                answerLesson={answerLessonData}
                onDataChange={(updatedData) => {
                  setAnswerLessonData(updatedData);
                }}
                answerFreeFormData={answerFreeFormData}
                allFfrPages={allFfrPages}
              />
              {answerLessonData.reviewStatus === UNDER_REVISION_ID && (
                <div className='review-warning'>
                  {allFfrPages.length > 0 && allFfrPages.length > answerPageNumbers.length
                    ? (
                      <>
                        <div>
                          You can no longer make any edits to comments, are you sure?
                        </div>
                        <div>
                          You have only reviewed {answerPageNumbers.length} out of the {allFfrPages.length} freeform responses
                        </div>
                      </>
                    )
                    : 'You can no longer make any edits to comments, are you sure?'}
                </div>
              )}
              {answerLessonData.reviewStatus === REVIEW_FINISHED_ID && (
                <div className='review-warning'>
                  You are about to complete the review process of this lesson. Are you sure?
                </div>
              )}
            </div>
          )}
          displayCloseIcon
          actions={[{
            onClick: () => { setOpen(false); },
            className: 'btn-danger margin-right',
            title: 'Cancel',
            loading: isSaving,
          }, {
            onClick: () => { updateReviewStatus({ answerLessonData }); },
            className: 'btn-primary action-button',
            title: 'Save',
            loading: isSaving,
          },
          ]}
        />
      )}
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </>
  );
};

UpdateReviewStatusAction.defaultProps = {
  answerLesson: {},
  onStatusUpdateSucccess: () => null,
  answerFreeFormData: [],
  allFfrPages: [],
};

UpdateReviewStatusAction.propTypes = {
  answerLesson: PropTypes.any,
  onStatusUpdateSucccess: PropTypes.func,
  answerFreeFormData: PropTypes.any,
  allFfrPages: PropTypes.array,
};

export default UpdateReviewStatusAction;

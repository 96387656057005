import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker,
} from '@mui/x-date-pickers';
import DropdownInput from '../../../../../../components/selector/DropdownInput';
import { dateFormatMMddyyyy } from '../../../../../../AppConstants';
import { licenseTypeOptions, quantityOptions } from './LicenseConstant';
import './LicenseFormForMultiple.scss';

// eslint-disable-next-line max-len
export const validateLicenseData = (data) => data && data.quantity !== null && data.type !== '' && data.active !== null && data.expirationDate !== null;

const LicenseFormForMultiple = ({
  licenseData,
  onDataChange,
}) => {
  const [internalData, setInternalData] = useState({ ...licenseData });

  const updateDataChange = (updatedData) => {
    setInternalData(updatedData);
    const request = { ...updatedData, type: licenseTypeOptions.filter((item) => item.id === updatedData.type)[0].name };
    const isDataValid = validateLicenseData(request);
    onDataChange(request, isDataValid);
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const updatedData = { ...internalData, [name]: name === 'active' ? e.target.checked : e.target.value };
    updateDataChange(updatedData);
  };

  const handleOnDateChange = (date) => {
    const updatedData = { ...internalData };
    updatedData.expirationDate = date;
    updateDataChange(updatedData);
  };

  return (
    <div className='admin-license-form-multiple'>
      <FormControl variant='outlined'>
        <FormControlLabel control={<Checkbox onChange={handleOnChange} name='active' color='primary' checked={internalData.active} />} label='Active' />
        <DropdownInput
          dropdownValues={quantityOptions}
          name='quantity'
          value={internalData.quantity}
          onChange={handleOnChange}
          inlineLabel='Quantity'
        />
        <DropdownInput
          dropdownValues={licenseTypeOptions}
          name='type'
          value={internalData.type}
          onChange={handleOnChange}
          inlineLabel='License Type'
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableToolbar={false}
            format={dateFormatMMddyyyy}
            label='Expiration Date'
            value={internalData.expirationDate}
            onChange={handleOnDateChange}
            InputAdornmentProps={{ position: 'start' }}
            maxDateMessage={null}
            minDateMessage={null}
            invalidDateMessage={null}
            renderInput={(params) => <TextField {...params} variant='standard' className='date-picker required' />}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

LicenseFormForMultiple.defaultProps = {
  licenseData: {},
  onDataChange: () => null,
};

LicenseFormForMultiple.propTypes = {
  licenseData: PropTypes.shape({
    active: PropTypes.bool,
    type: PropTypes.string,
    quantity: PropTypes.number,
    expirationDate: PropTypes.instanceOf(Date),
  }),
  onDataChange: PropTypes.func,
};

export default LicenseFormForMultiple;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { layoutDropdownValues } from '../FlexibleMatchingConstants';
import { isCardEmpty } from '../../../../../utils/activitytype/ActivityTypeEmptyChecker';
import './FlexibleMatchingPrint.scss';
import '../FlexibleMatchingLayout.scss';
import PrintCard from '../../common/print/PrintCard';
import FlexibleMatchingUtils from '../FlexibleMatchingUtils';
import { defaultSubmitText } from '../../../../../utils/activitytype/ActivityTypeInitialData';

const FlexibleMatchingPrint = ({
  promptText,
  layout,
  promptCards,
  subText,
  responseCards,
  submitAnswerText,
}) => {
  const componentId = useMemo(() => uuidv1(), []);
  const promptWithValueIndex = promptCards.findIndex((p) => !isCardEmpty(p));
  const isPromptAreaEmpty = promptWithValueIndex < 0 && !promptText && !subText;
  const notEmptyPromptCards = promptCards.filter((p) => !isCardEmpty(p));

  return (
    <div className={`flexible-matching-print-component flexi-matching-layout ${isPromptAreaEmpty ? 'no-prompt-area' : ''}`}>
      {!isPromptAreaEmpty && (
        <div className='prompt-area'>
          {promptText && (
            <PrintCard className='flexi-matching-prompt-text' text={promptText} fontScale={0.9} />
          )}

          <div className={`prompt-cards-container ${FlexibleMatchingUtils.getFlexibleMatchingLayoutClass(layout, promptCards)}`}>
            {
              notEmptyPromptCards.map((p, index) => {
                const isEmpty = isCardEmpty(p);
                if (isEmpty) {
                  return (
                    <div key={`${componentId}-prompt-${index}`} style={{ gridArea: `card-${index + 1}` }} />
                  );
                }
                return (
                  <PrintCard
                    key={`${componentId}-prompt-${index}`}
                    className='flexi-matching-prompt-card'
                    style={{ gridArea: `card-${index + 1}` }}
                    text={p.text}
                    image={p.image}
                    video={p.video}
                    audio={p.audio}
                    fontScale={0.9}
                  />
                );
              })
            }
          </div>

          {subText && (
            <PrintCard className='flexi-matching-sub-text' text={subText} fontScale={0.9} />
          )}
        </div>
      )}

      <div className='response-area'>
        <div className='submit-response-area'>
          <PrintCard
            text={submitAnswerText || defaultSubmitText}
            fontScale={0.9}
          />
        </div>

        <div className='response-cards-container'>
          {responseCards.map((card, index) => {
            const cardId = `${componentId}-${index}`;
            const responseId = `flexi-matching-response-${cardId}`;
            const responseContainerId = `flexi-matching-response-container-${cardId}`;
            const draggableId = `flexi-matching-drag-item-${cardId}`;

            return (
              <div
                className='response-card-container flexi-matching-response-card'
                id={responseContainerId}
                key={responseContainerId}
              >
                <div
                  id={draggableId}
                  key={`flexi-matching-response-item-${cardId}`}
                  className='flexi-matching-component-response-card-container'
                >
                  <PrintCard
                    id={responseId}
                    text={card.text}
                    image={card.image}
                    video={card.video}
                    audio={card.audio}
                    fontScale={0.9}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FlexibleMatchingPrint;

FlexibleMatchingPrint.defaultProps = {
  promptText: null,
  layout: layoutDropdownValues[0].className,
  promptCards: [{}],
  subText: null,
  responseCards: [{}, {}],
  submitAnswerText: null,
};

FlexibleMatchingPrint.propTypes = {
  promptText: PropTypes.object,
  layout: PropTypes.string,
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  subText: PropTypes.object,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  submitAnswerText: PropTypes.object,
};

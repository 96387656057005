import { useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { getPageStyle } from '../../../../../components/vizzle/page/common/LessonPageUtil';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './MatchingPromptActivity.scss';

/**
 * Matching prompts
 */
const MatchingPromptActivity = forwardRef(({
  promptCard,
  onClick,
  renderQuestionPlaceholder,
}, ref) => {
  const cardRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const videoPlayerRef = useRef(null);

  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const cardId = useMemo(() => uuidv1(), []);

  useImperativeHandle(ref, () => ({
    playAudio: () => audioPlayerRef && audioPlayerRef.current && audioPlayerRef.current.playMedia(),
    playVideo: () => videoPlayerRef && videoPlayerRef.current && videoPlayerRef.current.playMedia(),
    elementRef: cardRef.current,
  }));

  const getCard = (card) => (
    <Card
      className='matching-prompt-activity'
      style={getPageStyle(card)}
      data-test='matching-prompt-activity'
    >
      {
        card.image ? (
          <CardMedia
            className='matching-prompt-activity-image'
            image={card.image}
            title='Matching'
            data-test='matching-prompt-activity-image'
          />
        ) : ''
      }
      {card.text
        ? (
          <CardContent className='matching-prompt-activity-text' data-test='matching-prompt-activity-text'>

            <TextLines
              textLines={card.text}
              ref={setTextLineRef}
              componentKey={`text-line-${cardId}`}
            />

          </CardContent>
        ) : ''}
      {card.audio || (elementToPlayTts && elementToPlayTts.length > 0) ? <PlayCircleFilledIcon className='matching-prompt-activity-media-player-icon media-player-icon-play' /> : ''}
    </Card>
  );

  const getPromptCardPlaceHolder = () => (
    <Card className='matching-prompt-activity-area-question-placeholder' data-test='matching-prompt-activity-area-question-placeholder'>
      {renderQuestionPlaceholder}
    </Card>
  );

  const getVideo = (video) => {
    if (!video) {
      return '';
    }
    return (
      <Card className='matching-prompt-activity-area-question-video' data-test='matching-prompt-activity-area-question-video'>
        <MediaPlayer
          videoUrl={video}
          // parentElementId={videoCardId}
          className='media-component vizzle-matching-prompt-activity-video-pagemedia-btn'
          omitPlayIcon
          playsVideoInline
          ref={videoPlayerRef}
        />
      </Card>
    );
  };

  return (
    <div className='matching-prompt-activity-container' ref={cardRef}>
      <div className='matching-prompt-activity-prompt-area'>
        <Card
          className={`matching-prompt-activity-prompt-area-question-container ${onClick ? 'clickable' : ''}`}
          id={cardId}
          key={cardId}
          style={getPageStyle(promptCard, true)}
          onClick={onClick}
        >
          <div
            className='matching-prompt-activity-area-question'
          >
            {getCard(promptCard)}
          </div>
          {getPromptCardPlaceHolder()}
          {
            (promptCard.audio || (elementToPlayTts && elementToPlayTts.length > 0))
            && (
              <MediaPlayer
                audioUrl={promptCard.audio}
                parentElementId={cardId}
                className='media-component vizzle-matching-activity-prompt-audio-page-media-btn'
                omitPlayIcon
                ref={audioPlayerRef}
                textToRead={elementToPlayTts}
                tts={ttsConfig}
              />
            )
          }
        </Card>
        {getVideo(promptCard.video)}
      </div>
    </div>
  );
});

MatchingPromptActivity.defaultProps = {
  promptCard: {},
  renderQuestionPlaceholder: null,
  onClick: null,
};

MatchingPromptActivity.propTypes = {
  promptCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  renderQuestionPlaceholder: PropTypes.node,
  onClick: PropTypes.func,
};

export default MatchingPromptActivity;

import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import Logger from '../../../../../utils/Logger';
import CategorizingCardThumbnail from '../../../../../components/vizzle/page/categorizing/CategorizingCardThumbnail';
import { getInitialDataForCategorizing } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import WatchQuickVideo from '../../../../../components/vizzle/media/WatchQuickVideo';

const initDataForCategorizing = getInitialDataForCategorizing(2);

const videoUrl = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/in-app-help-videos/Categorizing.mp4`;
/**
* Container for Categorizing layout
*/
export default function CategorizingLayout() {
  const promptCardsConfig = initDataForCategorizing.promptCards;
  const responseCardsConfig = initDataForCategorizing.responseCards;
  const props = {
    config: [
      {
        component: (
          <CategorizingCardThumbnail promptCardsConfig={promptCardsConfig} responseCardsConfig={responseCardsConfig} />
        ),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '42vw' : '40vw',
    height: '53vh',
  };

  return (
    <Box>
      <Box display='flex' mt='20px'>
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-sorting-layout'
        />

        <Box alignSelf='center' ml='20px'>
          <WatchQuickVideo
            videoUrl={videoUrl}
          />
        </Box>
      </Box>
    </Box>
  );
}

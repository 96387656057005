import './LoginWithCleverButton.scss';
import { ButtonBase } from '@mui/material';
// import { URL_LOGIN_WITH_CLEVER_CODE } from '../../../../routes/RouteConstants';

const LoginWithCleverButton = () => {
  // const vizzleCleverLogin = encodeURIComponent(`${window.location.origin}${URL_LOGIN_WITH_CLEVER_CODE}`);
  // const url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${vizzleCleverLogin}&client_id=${process.env.REACT_APP_CLEVER_CLIENT_ID}`;

  // eslint-disable-next-line max-len
  const url = `https://clever.com/oauth/authorize?response_type=code&state=dGFyZ2V0U3lzdGVtSWQ9NTAwJmN1c3RvbVByb3BlcnR5PWFiY2Rl&redirect_uri=${process.env.REACT_APP_SSO_URL}&client_id=${process.env.REACT_APP_CLEVER_CLIENT_ID}`;

  return (
    <div className='login-with-clever-button'>
      <ButtonBase
        className='login-with-clever-link'
        href={url}
        data-test='login-with-clever-button'
      />
    </div>
  );
};

export default LoginWithCleverButton;

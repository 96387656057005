import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GenerateLessonsDialog from '../../../search-bar-actions/dialog/generate-lessons/GenerateLessonsDialog';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';

const AddTemplatesAction = ({
  lessonGenerationLevel,
}) => {
  const { subjectId } = useParams();
  const [openGenerateLessonDialog, setOpenGenerateLessonDialog] = useState(false);
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria: { courseId, unitId, topicId } } = teacherCourseSearchCriteriaDomain.domainData;
  if (lessonGenerationLevel !== 1) {
    return null;
  }

  return (
    <>
      <Button
        variant='contained'
        className='btn-green-background'
        onClick={() => { setOpenGenerateLessonDialog(true); }}
        startIcon={<EditIcon />}
        data-test='add-templates'
      >
        ADD TEMPLATES
      </Button>
      {openGenerateLessonDialog && (
        <GenerateLessonsDialog
          open={openGenerateLessonDialog}
          onClose={() => {
            setOpenGenerateLessonDialog(false);
          }}
          courseId={courseId}
          unitId={unitId}
          topicId={topicId}
          lessonLevel={lessonGenerationLevel}
          subjectId={Number(subjectId)}
        />
      )}
    </>
  );
};

AddTemplatesAction.defaultProps = {
  lessonGenerationLevel: 0,
};

AddTemplatesAction.propTypes = {
  lessonGenerationLevel: PropTypes.number,
};

export default AddTemplatesAction;

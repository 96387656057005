import { v1 as uuidv1 } from 'uuid';
import Logger from '../../../utils/Logger';

let video;

const playAndPauseVideo = () => {
  video.play().catch(() => {
    // Do nothing
  });
  const timerPlay = setTimeout(() => {
    video.pause();
    clearTimeout(timerPlay);
  }, 250);
};

if (!video) {
  video = document.createElement('video');
  video.muted = true;
  video.autoPlay = true;
  video.playsInline = true;
}

export const preloadVideo = (filePath) => {
  /**
   * Fix for ios that the video will not be automatically starts
   * without a user gesture.
   */
  video.src = filePath;
  playAndPauseVideo();
  /** End fix for ios */
};

/**
 * Get video image
 * @param path of a video to get an image
 * @param secs the seonds of the vidoe to get the image
 * @return Promise
 * img: image object
 */
const getVideoImage = (path, secs) => {
  const promise = new Promise((resolve, reject) => {
    playAndPauseVideo();

    video.onloadedmetadata = function onloadedmetadata() {
      const me = this;
      // Add delay for safari
      const timer = setTimeout(() => {
        me.currentTime = secs;
        clearTimeout(timer);
      }, 250);
    };

    video.onseeked = function onseeked(e) {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.src = canvas.toDataURL();
      resolve({
        img,
        e,
      });
    };

    video.onerror = function onerror(e) {
      reject(e);
    };
  });
  return promise;
};

/**
 * Convert data url to a file
 * @param dataUrl data url to convert
 * @param fileName the name of the file
 */
export const dataURLtoFile = (dataUrl, fileName) => {
  try {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  } catch (e) {
    Logger.logError(e);
    return null;
  }
};

/** Extract an image out of a local video file from the local machine
 * @param secs the second to get the image from the the video
 * @param file video file to extract the image
 * @return Promise that contains
 * imgUrl: image url to be display as an image
 * imgFile: image file for saving purpose
 */
export const showImageAt = (secs, filePath) => {
  // eslint-disable-next-line
  const promise = new Promise(async (resolve, reject) => {
    try {
      const getVideoImagePromise = await getVideoImage(filePath, secs);
      const tempFile = dataURLtoFile(getVideoImagePromise.img.src, `${uuidv1()}.png`);
      resolve({
        imgUrl: getVideoImagePromise.img.src,
        imgFile: tempFile,
      });
    } catch (e) {
      reject(e);
    }
  });
  return promise;
};

export default showImageAt;

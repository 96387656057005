import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const COLORS_NAME = {
  '#000407': 'blue',
  '#0D2B20': 'green',
  '#280D0D': 'red',
  '#0F0601': 'orange',
  '#140A21': 'purple',
};

const BACKGROUND_GRADIENT_INTENSITY = 20;

const SpaceTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    flags: [],
    spritesPlay: [],
    tweens: [],
    timeouts: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  // eslint-disable-next-line no-unused-vars
  const stuff = (moveHorizontal, moveVertical, scaleX, scaleY) => {
    // eslint-disable-next-line no-param-reassign
    moveHorizontal = moveHorizontal || 0;
    // eslint-disable-next-line no-param-reassign
    moveVertical = moveVertical || 0;
    // eslint-disable-next-line no-param-reassign
    scaleX = scaleX || 1;
    // eslint-disable-next-line
    scaleY = scaleY || 1;
  };

  const addRock = (type, moveHorizontal, moveVertical, scaleX, scaleY, darken = 0) => {
    stuff();
    // short_rock_blue, tall_rock_angled_blue, tall_rock_blocked_blue
    const rock = new PIXI.AnimatedSprite(app.sheet.animations[`${type}${app.colorName}`]);
    rock.scale.y = scaleY;
    rock.scale.x = scaleX;
    const rockBounds = rock.getBounds();
    rock.x = app.floatingIsland.x - app.floatingIslandBounds.width / 2 + moveHorizontal;
    rock.y = app.floatingIsland.y - app.floatingIslandBounds.height / 2 + 60 - rockBounds.height + moveVertical;

    app.floatingIslandContainer.addChild(rock);

    const rockTint = new PIXI.AnimatedSprite(app.sheet.animations[`${type}tint`]);
    rockTint.scale.x = scaleX;
    rockTint.scale.y = scaleY;
    rockTint.x = rock.x;
    rockTint.y = rock.y;
    rockTint.alpha = darken;
    app.floatingIslandContainer.addChild(rockTint);

    return rock;
  };

  const addSupportingObject = (name, moveHorizontal, moveVertical, scaleX, scaleY) => {
    stuff();
    const elem = new PIXI.AnimatedSprite(app.sheet.animations[name]);
    elem.scale.y = scaleY;
    elem.scale.x = scaleX;
    const elemBounds = elem.getBounds();
    elem.x = app.floatingIsland.x - app.floatingIslandBounds.width / 2 + moveHorizontal;
    elem.y = app.floatingIsland.y - app.floatingIslandBounds.height / 2 + moveVertical - elemBounds.height / 2;
    app.floatingIslandContainer.addChild(elem);
  };

  const addFlag = (moveHorizontal, moveVertical, alpha) => {
    stuff();
    const container = new PIXI.Container();
    container.scale.x = 0.7;
    container.scale.y = 0.7;

    const flag = new PIXI.AnimatedSprite(app.sheet.animations.flag);
    flag.alpha = alpha;
    flag.animationSpeed = 0.3;
    flag.play();
    app.spritesPlay.push(flag);
    container.addChild(flag);

    const flagBounds = flag.getBounds();
    container.x = app.floatingIsland.x - (flagBounds.width / 2) + moveHorizontal;
    container.y = app.floatingIsland.y - app.floatingIslandBounds.height / 2 - flagBounds.height / 2 + moveVertical;
    app.floatingIslandContainer.addChild(container);
  };

  const addRover = (name, moveHorizontal, moveVertical, scaleX, scaleY) => {
    stuff();
    const rover = new PIXI.AnimatedSprite(app.sheet.animations[name]);
    rover.scale.y = scaleY;
    rover.scale.x = scaleX;
    const roverBounds = rover.getBounds();
    rover.x = app.floatingIsland.x - (roverBounds.width / 2) + moveHorizontal;
    rover.y = app.floatingIsland.y - app.floatingIslandBounds.height / 2 - roverBounds.height / 2 + moveVertical;

    rover.animationSpeed = 0.3;
    rover.play();
    app.spritesPlay.push(rover);
    app.floatingIslandContainer.addChild(rover);
    rover.anchor.set(0.5, 0);
    const negative = -(scaleX);
    rover.scale.x = negative;

    const roverX = rover.x;

    const animationRunBackTween = new TWEEN.Tween(rover)
      .to({ x: roverX }, 5000)
      .easing(TWEEN.Easing.Linear.None)
      .onStart(() => {
        rover.scale.x = scaleX;
      })
      .onComplete(() => {
        rover.scale.x = negative;
        app.animationTween.start();
      });

    app.animationTween = new TWEEN.Tween(rover)
      .to({ x: roverX + 230 }, 5000)
      .chain(animationRunBackTween)
      .easing(TWEEN.Easing.Linear.None);
    app.tweens.push(app.animationTween);
    app.animationTween.start();
  };

  const blastOff = () => {
    const blastOffTimeout = setTimeout(() => {
      clearTimeout(blastOffTimeout);
      app.blast.alpha = 1;
      app.floatingIslandContainer.removeChild(app.launch);

      const blastOffTween = new TWEEN.Tween(app.blast)
        .to({ y: -app.blast.getBounds().height - 100 }, 4000)
        .easing(TWEEN.Easing.Linear.None)
        .onComplete(() => {
          app.blast.stop();
          app.floatingIslandContainer.removeChild(app.blast);
        });
      app.tweens.push(blastOffTween);
      blastOffTween.start();
    }, 1500);
    app.timeouts.push(blastOffTimeout);
  };

  const addShip = (moveHorizontal, moveVertical, scaleX, scaleY, moveVertical2 = 0) => {
    stuff();
    if (innerLessonsCompleted >= 1) {
      app.launch = new PIXI.AnimatedSprite(app.sheet.animations.launch);
      const shipBounds = app.launch.getBounds();

      const launchX = app.floatingIsland.x - shipBounds.width / 3 + moveHorizontal;
      const launchY = app.floatingIsland.y - app.floatingIslandBounds.height / 2.5 - shipBounds.height / 2.5 + moveVertical + moveVertical2;
      app.launch.x = launchX;
      app.launch.y = launchY;
      app.launch.scale.y = scaleY;
      app.launch.scale.x = scaleX;
      app.launch.animationSpeed = 0.3;
      app.launch.play();
      app.spritesPlay.push(app.launch);
      app.floatingIslandContainer.addChild(app.launch);

      app.blast = new PIXI.AnimatedSprite(app.sheet.animations.blastOff);
      app.blast.x = launchX + 15;
      app.blast.y = launchY;
      app.blast.scale.y = scaleY;
      app.blast.scale.x = scaleX;
      app.blast.animationSpeed = 0.3;
      app.blast.alpha = 0;
      app.blast.play();
      app.spritesPlay.push(app.blast);
      app.floatingIslandContainer.addChild(app.blast);

      blastOff();
    } else {
      app.ship = new PIXI.AnimatedSprite(app.sheet.animations.ship);
      app.ship.scale.y = scaleY;
      app.ship.scale.x = scaleX;
      const shipBounds = app.ship.getBounds();
      app.ship.x = app.floatingIsland.x - shipBounds.width / 2 + moveHorizontal;
      app.ship.y = app.floatingIsland.y - app.floatingIslandBounds.height / 2 - shipBounds.height / 2 + moveVertical;
      app.floatingIslandContainer.addChild(app.ship);

      const shipShadow = new PIXI.AnimatedSprite(app.sheet.animations.ship_shadow);
      shipShadow.alpha = 0.2;
      shipShadow.scale.y = scaleY;
      shipShadow.scale.x = scaleX;
      shipShadow.x = app.ship.x + (13 * scaleX);
      shipShadow.y = app.ship.y + shipBounds.height - (15 * scaleY);
      app.floatingIslandContainer.addChild(shipShadow);
    }
  };

  const addAlienShip = (moveHorizontal, moveVertical, scaleX, scaleY, movementY1, movementY2, arrival) => {
    stuff();
    const alienShip = new PIXI.AnimatedSprite(app.sheet.animations.alienShip);
    alienShip.scale.y = scaleY;
    alienShip.scale.x = scaleX;
    const alienShipBounds = alienShip.getBounds();
    alienShip.x = app.floatingIsland.x - alienShipBounds.width / 2 + moveHorizontal;
    alienShip.y = moveVertical;
    alienShip.animationSpeed = 0.3;
    alienShip.play();
    app.spritesPlay.push(alienShip);
    app.floatingIslandContainer.addChild(alienShip);

    const shipAnimation8Tween = new TWEEN.Tween(alienShip)
      .to({ x: -1500, y: -600 }, 700)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        const shipAnimationTimeout = setTimeout(() => {
          app.shipAnimationTween.start();
        }, 5000);
        app.timeouts.push(shipAnimationTimeout);
      });

    const shipAnimation7Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY1 }, 400)
      .chain(shipAnimation8Tween)
      .easing(TWEEN.Easing.Linear.None);

    const shipAnimation6Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY2 }, 400)
      .chain(shipAnimation7Tween)
      .easing(TWEEN.Easing.Linear.None);

    const shipAnimation5Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY1 }, 400)
      .chain(shipAnimation6Tween)
      .easing(TWEEN.Easing.Linear.None);

    const shipAnimation4Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY2 }, 400)
      .chain(shipAnimation5Tween)
      .easing(TWEEN.Easing.Linear.None);

    const shipAnimation3Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY1 }, 400)
      .chain(shipAnimation4Tween)
      .easing(TWEEN.Easing.Linear.None);

    const shipAnimation2Tween = new TWEEN.Tween(alienShip)
      .to({ y: movementY2 }, 400)
      .chain(shipAnimation3Tween)
      .easing(TWEEN.Easing.Linear.None);

    app.shipAnimationTween = new TWEEN.Tween(alienShip)
      .to({ x: arrival, y: movementY1 }, 2000)
      .delay(1500)
      .chain(shipAnimation2Tween)
      .easing(TWEEN.Easing.Linear.None);
    app.tweens.push(app.shipAnimationTween);
    app.shipAnimationTween.start();
  };

  const showRandomScene = () => {
    const scenes = [1, 2, 3, 4, 5, 6];
    const rand = Math.floor(Math.random() * scenes.length) + 1;
    const scene = scenes[rand - 1];

    switch (scene) {
      case 1:
        addRock('tall_rock_angled_', 125, 5, 0.8, 0.6);
        addSupportingObject('ladder_short', 205, -40, 1, 1);
        addRock('short_rock_', 110, 0, 0.2, 0.2, 0.2);
        addRock('short_rock_', 140, 10, 0.4, 0.4);
        addFlag(-90, -195, 0.9);
        addRover('rover', 0, 25, 0.5, 0.6);
        if (innerLessonsCompleted <= 3) { addShip(200, 0, 0.6, 0.6, -40); }
        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.5, 0.5, 100, 115, 400); }
        break;

      case 2:
        addRover('rover', 0, -25, 0.45, 0.55);
        addRock('tall_rock_blocked_and_angled_', 125, -15, 0.8, 0.5);
        addRock('short_rock_', 110, -20, 0.2, 0.2, 0.2);
        addRock('short_rock_', 140, 0, 0.4, 0.4);
        addRock('tall_rock_angled_', 660, -10, -0.7, 0.7);
        addRock('short_rock_', 600, -20, 0.15, 0.15, 0.3);
        addRock('short_rock_', 470, 0, 0.5, 0.5);
        if (innerLessonsCompleted <= 3) { addShip(-70, 0, 0.5, 0.5); }
        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.5, 0.5, 100, 115, 400); }
        break;

      case 3:
        addRock('tall_rock_blocked_', 390, 10, 0.7, 0.65, 0.1);
        addRock('short_rock_', 365, -40, 0.2, 0.2, 0.2);
        addRover('rover', 0, 10, 0.5, 0.6);
        addSupportingObject('ladder_short', 295, -19, 0.7, 0.7);
        addRock('tall_rock_blocked_', 200, 35, 0.7, 0.4);
        addRock('tall_rock_blocked_', 620, 35, -0.7, 0.4);
        addSupportingObject('ladder_horizontal', 330, -70, 0.8, 0.8);
        addSupportingObject('ladder_short', 497, -130, 0.6, 0.6);
        addRock('short_rock_', 490, -128, 0.2, 0.2);
        addRock('short_rock_', 315, -118, 0.15, 0.15);
        if (innerLessonsCompleted <= 3) { addShip(-200, 30, 0.5, 0.5, -30); }
        addFlag(168, -235, 0.9);
        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.5, 0.5, 165, 150, 150); }
        break;

      case 4:
        addRock('tall_rock_angled_', 125, -5, 0.8, 0.8);
        addRock('tall_rock_angled_', 630, -5, -0.8, 0.8);
        addSupportingObject('ladder_horizontal', 275, -258, 1, 1);
        addSupportingObject('ladder_tall', 250, -115, 1, 1);
        addSupportingObject('satellite', 550, -280, -0.7, 0.7);
        addRock('short_rock_', 110, -20, 0.2, 0.2, 0.2);
        addRock('short_rock_', 140, 0, 0.4, 0.4);
        addRover('rover', 0, 25, 0.5, 0.6);
        addShip(200, 0, 0.6, 0.6, -20);
        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.5, 0.5, 0, 15, 400); }
        break;

      case 5:
        addRover('rover', 0, -18, 0.5, 0.6);
        addRock('tall_rock_blocked_', 205, 65, 0.8, 0.6);
        if (innerLessonsCompleted <= 3) { addShip(200, 0, 0.6, 0.6, -30); }
        addRock('short_rock_', 230, 25, 0.2, 0.2, 0.2);
        addRock('short_rock_', 240, 65, 0.4, 0.4, 0);
        addFlag(-25, -150, 0.9);
        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.5, 0.5, 100, 115, 200); }
        break;

      case 6:
      default:
        addRock('short_rock_', 270, -5, 0.1, 0.1, 0.1);
        addRock('short_rock_', 240, -10, 0.1, 0.1, 0.2);
        if (innerLessonsCompleted <= 3) { addShip(200, 0, 0.4, 0.4, 20); }
        addRock('short_rock_', 220, 20, 0.2, 0.2, 0.1);

        if (innerLessonsCompleted >= 3) { addAlienShip(1000, -1200, 0.8, 0.8, 100, 120, 400); }
        addRock('short_rock_', 100, 10, 0.5, 1, 0.1);
        break;
    }
  };

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas, selectedColor } = ThemeUtil.initCanvasWithBackground(
        canvas,
        Object.keys(COLORS_NAME),
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      app.colorName = COLORS_NAME[selectedColor];
      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick, {
        island: `island_${app.colorName}`,
      });
      const progressBar = {
        barBackground: 'progressbar_background_splatter',
        progressBar: 'progressbar_fire',
        progressAvatar: 'spaceship',
        dotType: 'circle',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );

      ThemeUtil.addFloatableObjects(app, 3000, false);
      showRandomScene();
    }

    return () => {
      clearInterval(app.interval);
      ThemeUtil.stopFloatableObjects(app);
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

SpaceTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

SpaceTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default SpaceTheme;

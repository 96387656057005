import PropTypes from 'prop-types';
import {
  Paper,
} from '@mui/material';
import './FreeTextResponseTabs.scss';
import TextAndMediaFormatting from '../../../../lessons/lessonmaintenance/steps/media/editor/TextAndMediaFormatting';
import { CUSTOM_RESPONSE_OPTIONS } from '../../../../../components/vizzle/page/book/components/CustomResponseConstants';
import FreeFormResponseImageSelector from './components/FreeFormResponseImageSelector';
import DrawingEditor from './components/DrawingEditor';

/**
 * Editable Matching responses
 */
const FreeTextResponseTabs = ({
  card,
  onTextChange,
  onImageChange,
  onFfImageChange,
  isPreviewMode,
  user,
}) => {
  const displayImageEditor = card.customResponseOption === undefined || card.customResponseOption === null || [Number(CUSTOM_RESPONSE_OPTIONS[0].id)].includes(Number(card.customResponseOption));

  const displayTextEditor = [Number(CUSTOM_RESPONSE_OPTIONS[1].id)].includes(Number(card.customResponseOption));

  const displayImagesSelector = [Number(CUSTOM_RESPONSE_OPTIONS[2].id)].includes(Number(card.customResponseOption));

  return (
    <Paper square className={`free-text-response-tabs ${displayImageEditor ? 'full-height' : ''}`}>
      {displayImageEditor && (
        <div className='container draw-canvas-container'>
          <DrawingEditor
            onImageChange={onImageChange}
            imageUrl={card.image}
            drawingImage={card.isAnswerImage && card.image ? null : card.drawingImage}
          />
        </div>
      )}

      {displayTextEditor && (
        <div className='container text-editor-container'>
          <TextAndMediaFormatting
            text={card.text}
            onTextRemoved={() => {
              onTextChange(null);
            }}
            onTextChange={(textLines) => {
              onTextChange(textLines);
            }}
            includeMath={false}
            includeInlineOption={false}
            includeTextAlign={false}
            showApplyButton={false}
            showRemoveTextButton={false}
            showApplyStyleLessonButton={false}
            useUserProfileAsDefault={false}
            selectAll={!card.text}
          />
        </div>
      )}

      {displayImagesSelector && (
        <div className='container images-selector-container'>
          <FreeFormResponseImageSelector
            ffImageCount={card.ffImageCount}
            ffImages={card.ffImages}
            onFfImageChange={onFfImageChange}
            isPreviewMode={isPreviewMode}
            user={user}
          />
        </div>
      )}
    </Paper>
  );
};

FreeTextResponseTabs.defaultProps = {
  onTextChange: () => null,
  onImageChange: () => null,
  onFfImageChange: () => null,
  text: null,
  card: {},
  isPreviewMode: true,
  user: {},
  drawingImage: null,
};

FreeTextResponseTabs.propTypes = {
  onTextChange: PropTypes.func,
  onImageChange: PropTypes.func,
  onFfImageChange: PropTypes.func,
  text: PropTypes.object,
  card: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    drawingImage: PropTypes.string,
    customResponseOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ffImageCount: PropTypes.number,
    ffImages: PropTypes.array,
    isAnswerImage: PropTypes.bool,
  }),
  isPreviewMode: PropTypes.bool,
  user: PropTypes.any,
  drawingImage: PropTypes.string,
};

export default FreeTextResponseTabs;

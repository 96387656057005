import PropTypes from 'prop-types';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './CauseAndEffectEditorComponent.scss';
import CauseAndEffectEditorCard from './CauseAndEffectEditorCard';

const SECTION_IMAGE = 'image';
const SECTION_TEXT = 'text';
const SECTION_AUDIO = 'audio';

/**
 * Lesson activity for Book
 */
export default function CauseAndEffectEditorComponent({
  causeCard,
  effectCard,
  isSelected,
  isCauseSelected,
  selectedSection,
  onImageSelected,
  onTextSelected,
  onAudioSelected,
}) {
  return (
    <div className='cause-and-effect-editor-component vizzle-cause-and-effect-editor'>
      <div className='card-container'>
        <CauseAndEffectEditorCard
          card={causeCard}
          onImageSelected={(section) => onImageSelected({ isCauseSelected: true, selectedSection: section })}
          onTextSelected={(section) => onTextSelected({ isCauseSelected: true, selectedSection: section })}
          onAudioSelected={(section) => onAudioSelected({ isCauseSelected: true, selectedSection: section })}
          isSelected={isSelected && isCauseSelected}
          selectedSection={selectedSection}
        />
      </div>
      <div>
        <DoubleArrowIcon className='arrow' />
      </div>
      <div className='card-container'>
        <CauseAndEffectEditorCard
          card={effectCard}
          onImageSelected={(section) => onImageSelected({ isCauseSelected: false, selectedSection: section })}
          onTextSelected={(section) => onTextSelected({ isCauseSelected: false, selectedSection: section })}
          onAudioSelected={(section) => onAudioSelected({ isCauseSelected: false, selectedSection: section })}
          isSelected={isSelected && !isCauseSelected}
          selectedSection={selectedSection}
        />
      </div>
    </div>
  );
}

CauseAndEffectEditorComponent.defaultProps = {
  causeCard: { key: 0 },
  effectCard: { key: 0 },
  isSelected: false,
  isCauseSelected: true,
  selectedSection: SECTION_IMAGE,
  onImageSelected: () => { },
  onTextSelected: () => { },
  onAudioSelected: () => { },
};

CauseAndEffectEditorComponent.propTypes = {
  causeCard: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    text: PropTypes.object,
  }),
  effectCard: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    text: PropTypes.object,
  }),
  isCauseSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  onImageSelected: PropTypes.func,
  onTextSelected: PropTypes.func,
  onAudioSelected: PropTypes.func,
};

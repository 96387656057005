import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './ViewStudentAnswersNotification.scss';

const ViewStudentAnswersNotification = ({
  message,
}) => (
  <div className='view-student-answers-notification'>
    <Typography variant='h4' className='view-student-answers-notification-message'>
      {message}
    </Typography>
  </div>
);

ViewStudentAnswersNotification.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ViewStudentAnswersNotification;

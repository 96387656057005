import BaseDomain from '../../../../../states/domains/BaseDomain';

const STUDENT_GROUPS_UPDATE_STUDENT_GROUP_LIST = 'STUDENT_GROUPS_UPDATE_STUDENT_GROUP_LIST';

const initialState = {
  studentGroups: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case STUDENT_GROUPS_UPDATE_STUDENT_GROUP_LIST:
      return {
        ...state,
        studentGroups: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'studentGroupsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateStudentGroups(studentGroups) {
    return this.dispatch({
      type: STUDENT_GROUPS_UPDATE_STUDENT_GROUP_LIST,
      payload: studentGroups,
    });
  }
}

import { useEffect, useRef, useState } from 'react';
import { MATCHING_ACTIVITY_ONE_CLICK } from '../../../../constants/SettingConstant';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

export const useInitializeData = () => {
  useEffect(() => {
    const timeout = ObjectUtils.setTimeout(() => {
      LessonPlayActivityUtils.stopVideos();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {
    bookActivityRef: useRef(null),
    replayButtonRef: useRef(null),
  };
};

const CARD_MOVE_DELAY = 1200;
export const useAutoPlay = ({
  isCurrentPage,
  bookActivityRef,
  bookCardRefs,
  layoutConfig,
}) => {
  const alreadyPlayed = useRef(false);
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const isAutoPlay = MATCHING_ACTIVITY_ONE_CLICK === userProfile.interactionType && userProfile.tts.enabled;

  const [internalIsMediaPlaying, setInternalIsMediaPlaying] = useState(isAutoPlay);
  const internalIsMediaPlayingRef = useRef(isAutoPlay);

  const [bookCardsAnimation, setBookCardsAnimation] = useState(() => {
    const animation = {
      timeout: 0,
      bookCardIn: layoutConfig.map(() => true),
    };

    if (isAutoPlay) {
      animation.timeout = CARD_MOVE_DELAY;
      animation.bookCardIn = [];
    }
    return animation;
  });

  const playBookCardMedia = (bookCardRef, timeoutList = []) => (
    new Promise((resolve) => {
      try {
        const timeout = ObjectUtils.setTimeout(async () => {
          if (bookCardRef.playMedia) {
            await bookCardRef.playMedia();
          }
          resolve();
        }, CARD_MOVE_DELAY + 500);
        timeoutList.push(timeout);
      } catch (e) {
        Logger.logError(e);
        resolve();
      }
    })
  );

  let timeoutList = [];

  const playMedia = async () => {
    timeoutList = [];
    LessonPlayActivityUtils.disableComponent(bookActivityRef, true);
    await lessonPlayDomain.updateIsMediaPlaying(true);
    await ObjectUtils.delay(700);

    for (let i = 0; i < bookCardRefs.current.length; i++) {
      setBookCardsAnimation((previousState) => {
        // eslint-disable-next-line no-param-reassign
        previousState.bookCardIn[i] = true;
        return { ...previousState };
      });

      const bookCardRef = bookCardRefs.current[i];
      if (bookCardRef) {
        // eslint-disable-next-line no-await-in-loop
        await playBookCardMedia(bookCardRef, timeoutList);
      }
    }
    alreadyPlayed.current = true;
    await lessonPlayDomain.updateIsMediaPlaying(false);
    internalIsMediaPlayingRef.current = false;
    setInternalIsMediaPlaying(false);
    LessonPlayActivityUtils.disableComponent(bookActivityRef, false);
  };

  useEffect(() => {
    if (isAutoPlay && isCurrentPage && !alreadyPlayed.current) {
      // Wait a bit before start playing
      internalIsMediaPlayingRef.current = true;
      setInternalIsMediaPlaying(true);
      ObjectUtils.setTimeout(() => {
        playMedia();
      }, 100);
    }

    return (async () => {
      await lessonPlayDomain.updateIsMediaPlaying(false);
      timeoutList.forEach((t) => clearTimeout(t));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPage]);

  const replayCards = () => {
    alreadyPlayed.current = false;
    internalIsMediaPlayingRef.current = true;
    setInternalIsMediaPlaying(true);
    setBookCardsAnimation({
      ...bookCardsAnimation,
      bookCardIn: [],
    });
    ObjectUtils.setTimeout(() => {
      playMedia();
    }, CARD_MOVE_DELAY + 200);
  };

  return {
    internalIsMediaPlaying,
    internalIsMediaPlayingRef,
    isAutoPlay,
    bookCardsAnimation,
    replayCards,
  };
};

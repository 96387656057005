import BusinessIcon from '@mui/icons-material/Business';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ExportData from '../../../components/actions/export/ExportData';
import { useAdmin } from '../../../context/AdminContextProvider';
import { getExportDataForAdmin } from '../../../../../services/AdminService';
import '../../../components/buttons/Buttons.scss';
import ObjectUtils from '../../../../../utils/ObjectUtils';

const AdminExportData = () => {
  const { adminDataDomain } = useAdmin();
  const { searchCriteria, selectedTeacherId } = adminDataDomain.domainData;
  const { districtIds, schoolIds, licenseTypeIds } = searchCriteria;

  const { uiDomain } = useDomain();

  return (
    <ExportData
      checkBeforeLoad={() => {
        if (!selectedTeacherId && (!districtIds || districtIds.length === 0)) {
          uiDomain.showNotificationDialog(true, 'Export Data', BusinessIcon, 'Please select at least one district.', 'Ok', false);
          return false;
        }
        return true;
      }}
      getDataFunction={async () => new Promise((resolve) => {
        // To ensure that the domain data is updated before making a service call
        ObjectUtils.setTimeout(async () => {
          const result = await getExportDataForAdmin({
            selectedTeacherId,
            districtIds,
            schoolIds,
            licenseTypeIds,
            selectedDates: adminDataDomain.domainData.selectedDates,
          });
          resolve(result);
        });
      })}
      className='admin-action-multiple-line-label btn-primary'
      ariaLabel='Export Data'
      buttonLabel={(
        <div>
          Export<br />Data
        </div>
      )}
      exportFileName='district-data-report.xlsx'
      exportingMessage='Exporting Data'
      noIcon
    />
  );
};

export default AdminExportData;

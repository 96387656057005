import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
} from '@mui/material';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import BrowseAudioUploader from './BrowseAudioUploader';
import AudioRecorder from './AudioRecorderContainer';
import ButtonList from '../../../../../../components/buttons/ButtonList';

const BROWSE_COMPUTER = 0;
const MY_MEDIA = 1;
const VIZZLE_MEDIA = 2;
const RECORD_SOUND = 3;

export default function AddAudio({ audio, onAudioApply, onAudioRemoved }) {
  const [state, setState] = useState({
    selectedOption: BROWSE_COMPUTER,
    openFileBrowser: false,
    openAudioRecorder: false,
  });
  const [browseAudioRef, setBrowseAudioRef] = useState();

  const pageTypeSelector = [
    {
      label: 'Browse',
      onClick: () => {
        setState({
          selectedOption: BROWSE_COMPUTER,
          openFileBrowser: true,
        });

        if (browseAudioRef && browseAudioRef.current) {
          browseAudioRef.current.open();
        }
      },
    },
    {
      label: 'Record Sound',
      onClick: () => {
        setState({
          selectedOption: RECORD_SOUND,
          openAudioRecorder: true,
        });
      },
      renderIcon: () => (
        <FiberManualRecordRoundedIcon className='icon-red-with-gradient-boder' />
      ),
    },
  ];

  const setBrowseAudioInputRef = (ref) => {
    setBrowseAudioRef(ref);
    setState({
      ...state,
      openFileBrowser: true,
    });
    if (state.openFileBrowser) {
      ref.current.open();
    }
  };

  const onAudioRecorderClose = () => {
    setState({
      ...state,
      openAudioRecorder: false,
    });
  };

  return (
    <Box height='98%' width='100%' data-test='add-autio-container'>
      <ButtonList
        config={pageTypeSelector}
        align='horizental'
        componentKey='audio-upload-selector'
      />
      <br />
      {(state.selectedOption === BROWSE_COMPUTER
        || state.selectedOption === RECORD_SOUND)
        && (
          <Box height='80%' width='100%'>
            <BrowseAudioUploader
              audio={audio}
              onSelect={onAudioApply}
              onRemove={onAudioRemoved}
              setBrowseAudioRef={setBrowseAudioInputRef}
            />
          </Box>
        )}
      {state.selectedOption === MY_MEDIA && <Paper>MY_MEDIA</Paper>}
      {state.selectedOption === VIZZLE_MEDIA && <Paper>VIZZLE_MEDIA</Paper>}
      {
        window.innerWidth <= 1024 ? <br /> : ''
      }
      <AudioRecorder
        open={state.openAudioRecorder}
        onClose={onAudioRecorderClose}
        onApply={onAudioApply}
      />
    </Box>
  );
}

AddAudio.defaultProps = {
  audio: null,
  onAudioApply: () => { },
  onAudioRemoved: () => { },
};

AddAudio.propTypes = {
  audio: PropTypes.string,
  onAudioApply: PropTypes.func,
  onAudioRemoved: PropTypes.func,
};

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import './ThumbnailCard.scss';

const ThumbnailCard = ({
  cardKey,
  text,
  image,
  isSmallThumbnail,
  className,
  style,
  isImageIncluded,
  alwaysShowText,
}) => {
  const caredId = uuidv1();

  const showImage = () => {
    if (isImageIncluded && image) {
      return (
        <CardMedia
          className='thumbnail-image'
          image={image}
          title='Sorting'
        />
      );
    }

    if (isImageIncluded && !text) {
      return (
        <div
          className='thumbnail-image-placeholder'
        >
          Image/Video
        </div>
      );
    }

    return null;
  };

  const showText = () => {
    if (isSmallThumbnail) {
      if (!image && text) {
        return (
          <Box className={`thumbnail-text-with-value ${!isImageIncluded ? 'text-large' : ''}`}>
            <TextLines textLines={text} isThumbnail />
          </Box>
        );
      }

      if (alwaysShowText) {
        return text ? (
          <Box>
            <TextLines textLines={text} isThumbnail />
          </Box>
        ) : (
          <CardContent className={`thumbnail-text ${!isImageIncluded ? 'text-large' : ''}`}>
            Text
          </CardContent>
        );
      }
      if (isImageIncluded) {
        return null;
      }
    }

    return (
      <CardContent className={`thumbnail-text ${!isImageIncluded ? 'text-large' : ''}`}>
        Text
      </CardContent>
    );
  };

  return (
    <Card
      id={caredId}
      key={caredId}
      className={`thumbnail-card ${className} ${isSmallThumbnail ? 'small-thumbnail' : ''}`}
      style={style}
    >
      {cardKey ? (
        <div className='card-key'>
          {cardKey}
        </div>
      ) : null}
      {showImage()}
      {showText()}

    </Card>
  );
};

ThumbnailCard.defaultProps = {
  isSmallThumbnail: false,
  cardKey: null,
  className: '',
  style: {},
  text: null,
  image: null,
  isImageIncluded: true,
  alwaysShowText: false,
};

ThumbnailCard.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  cardKey: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  image: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object,
  isImageIncluded: PropTypes.bool,
  alwaysShowText: PropTypes.bool,
};

export default ThumbnailCard;

import BaseDomain from '../../../states/domains/BaseDomain';

const ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID = 'ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID';
const ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID = 'ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID';
const ADMIN_DOMAIN_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID = 'ADMIN_DOMAIN_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID';
const ADMIN_DOMAIN_UPDATE_SELECTED_SCHOOL_ID = 'ADMIN_DOMAIN_UPDATE_SELECTED_SCHOOL_ID';
const ADMIN_DOMAIN_IS_DATA_LOADING = 'ADMIN_DOMAIN_IS_DATA_LOADING';
const ADMIN_DOMAIN_UPDATE_USERS_AND_LICENSES_DATA = 'ADMIN_DOMAIN_UPDATE_USERS_AND_LICENSES_DATA';

const initialState = {
  selectedDistrictId: null,
  schoolsBySelectedDistrictId: [],
  selectedSchoolId: null,
  isLoading: false,
  usersAndLicensesData: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID:
      return {
        ...state,
        selectedDistrictId: action.payload,
      };
    case ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID:
      return {
        ...state,
        selectedDistrictId: action.payload.selectedDistrictId,
        selectedSchoolId: action.payload.selectedSchoolId,
      };
    case ADMIN_DOMAIN_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID:
      return {
        ...state,
        schoolsBySelectedDistrictId: action.payload,
      };
    case ADMIN_DOMAIN_UPDATE_SELECTED_SCHOOL_ID:
      return {
        ...state,
        selectedSchoolId: action.payload,
      };
    case ADMIN_DOMAIN_IS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ADMIN_DOMAIN_UPDATE_USERS_AND_LICENSES_DATA:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        usersAndLicensesData: action.payload.usersAndLicensesData,
      };
    default:
      return state;
  }
};

const domainName = 'adminDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSelectedDistrictId(selectedDistrictId) {
    return this.dispatch({
      type: ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID,
      payload: selectedDistrictId,
    });
  }

  async updateSelectedDistrictIdAndSchoolId(selectedDistrictId, selectedSchoolId) {
    return this.dispatch({
      type: ADMIN_DOMAIN_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID,
      payload: {
        selectedDistrictId,
        selectedSchoolId,
      },
    });
  }

  async updateSchoolsBySelectedDistrictId(schools) {
    return this.dispatch({
      type: ADMIN_DOMAIN_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID,
      payload: schools,
    });
  }

  async updateSelectedSchoolId(selectedSchoolId) {
    return this.dispatch({
      type: ADMIN_DOMAIN_UPDATE_SELECTED_SCHOOL_ID,
      payload: selectedSchoolId,
    });
  }

  async updateIsDataLoading(isLoading) {
    return this.dispatch({
      type: ADMIN_DOMAIN_IS_DATA_LOADING,
      payload: isLoading,
    });
  }

  async updateUsersAndLicensesData(usersAndLicensesData, isLoading = false) {
    return this.dispatch({
      type: ADMIN_DOMAIN_UPDATE_USERS_AND_LICENSES_DATA,
      payload: {
        usersAndLicensesData,
        isLoading,
      },
    });
  }
}

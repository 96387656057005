import { useEffect, useState } from 'react';

export const useObjectCardFilter = (masterObjectiveData, multiSelectFilterConfig, onFilterChange) => {
  const { masterObjectives, objectiveFilterValue } = masterObjectiveData;

  const [filteredObjectives, setFilteredObjectives] = useState(masterObjectives);
  const [filterValue, setFilterValue] = useState(objectiveFilterValue || {});

  const handleOnFilterChange = async (newFilterValue) => {
    await setFilterValue({ ...newFilterValue });
  };

  const performFilter = () => {
    const filterResult = masterObjectives.map((masterObjective) => {
      const { objectiveRowTitle } = masterObjective;

      const result = masterObjective.objectives.filter((objective) => {
        let multiSelected = true;
        if (multiSelectFilterConfig && multiSelectFilterConfig.length > 0) {
          const matchedResults = multiSelectFilterConfig.map((multiSelect) => {
            const valueToCompare = multiSelect.getValue(objective);
            const selectedValues = filterValue[multiSelect.keyName];
            if (!selectedValues || selectedValues.length === 0) {
              // no selected values, assumes it matched
              return true;
            }

            const values = selectedValues.map((s) => s.value);
            if (Array.isArray(valueToCompare)) {
              return valueToCompare.some((v) => values.includes(v));
            } else {
              return values.includes(valueToCompare);
            }
          });
          multiSelected = matchedResults.reduce((total, value) => total && value);
        }

        return multiSelected;
      });
      return {
        objectiveRowTitle,
        objectives: result,
      };
    });

    setFilteredObjectives(filterResult);
  };

  useEffect(() => {
    if (JSON.stringify(objectiveFilterValue) !== JSON.stringify(filterValue)) {
      performFilter();
      onFilterChange(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  useEffect(() => {
    performFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterObjectives]);

  return {
    filteredObjectives,
    filterValue,
    handleOnFilterChange,
  };
};

export default useObjectCardFilter;

import PropTypes from 'prop-types';
import {
  Box,
  Slider,
  Typography,
} from '@mui/material';
import { DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS, MAX_SETS_OF_CAUSES_AND_EFFECTS } from '../../../../../../../AppConstants';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

const ChooseNumberOfCauseAndEffect = ({
  onSliderChange,
  value,
  flexDirection,
  sliderWidth,
}) => (
  <Box display='flex' flexDirection={flexDirection} width='100%'>
    <Box mr='20px'>
      <Typography variant='h6' style={{ whiteSpace: 'nowrap' }}>Choose Number of Causes and Effects</Typography>
    </Box>
    <Box width={sliderWidth}>
      <Slider
        defaultValue={DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS}
        min={1}
        max={MAX_SETS_OF_CAUSES_AND_EFFECTS}
        valueLabelDisplay='off'
        marks={marks}
        onChange={onSliderChange}
        value={value}
      />
    </Box>
  </Box>
);

ChooseNumberOfCauseAndEffect.defaultProps = {
  value: () => { },
  onSliderChange: () => { },
  flexDirection: 'column',
  sliderWidth: '100%',
};

ChooseNumberOfCauseAndEffect.propTypes = {
  value: PropTypes.number,
  onSliderChange: PropTypes.func,
  flexDirection: PropTypes.string,
  sliderWidth: PropTypes.string,
};

export default ChooseNumberOfCauseAndEffect;

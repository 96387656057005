import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import VizzleSlider from '../../../components/slider/VizzleSlider';

const MaxChoices = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.maxMatchingChoices > 0);
  const [showSlider, setShowSlider] = useState(profile.maxMatchingChoices > 0);
  const [tmpSliderValue, setTmpSliderValue] = useState(profile.maxMatchingChoices);
  const { maxMatchingChoices } = profile;
  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(maxMatchingChoices > 0);
    setShowSlider(maxMatchingChoices > 0);
    setTmpSliderValue(profile.maxMatchingChoices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleToggleOnchange = (field, newValue) => {
    if (newValue === null) return;
    setShowSlider(newValue);
    setToggle(newValue);
    const updatedValue = newValue ? 5 : 0;
    setTmpSliderValue(updatedValue);
    onChangeCallback(updatedValue);
  };

  const handleSliderOnchange = (field, newValue) => {
    onChangeCallback(newValue);
  };

  return (
    <Box display='flex' flexDirection='row'>
      <ToggleButtonGroup size='small' value={toggle} onChange={handleToggleOnchange} exclusive className='setting-interaction-toggle'>
        <ToggleButton value={false}>DEFAULT</ToggleButton>
        <ToggleButton value>CUSTOM</ToggleButton>
      </ToggleButtonGroup>
      {showSlider ? (
        <div className='max-choice-slider-container' data-test='max-choice-slider-container'>
          <VizzleSlider
            className='max-choice-slider'
            value={tmpSliderValue}
            step={1}
            min={1}
            max={5}
            onChange={(e, value) => {
              setTmpSliderValue(value);
            }}
            onChangeCommitted={handleSliderOnchange}
          />
        </div>
      ) : null}
    </Box>
  );
};

MaxChoices.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default MaxChoices;

/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import './StudentLessonSummary.scss';
import { Fade } from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';

const StudentLessonSummary = ({
  data,
  subjectId,
}) => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const subjectFromProfile = userProfile.subjects;

  const subject = subjectFromProfile.find((s) => s.id === subjectId);

  if (userProfile.convenience && subject.lessons.length > 0) {
    return (
      <Fade timeout={1000} in>
        <div className='student-lesson-summary-expire-lessons'>
          No assignments today, but you can work on these previous lessons.
        </div>
      </Fade>
    );
  } else {
    if (!data || data.key <= 0) {
      return null;
    }
    const numberOfLessons = data.key.length;
    return (
      <Fade timeout={1000} in>
        <div className='student-lesson-summary'>
          {numberOfLessons} {numberOfLessons > 1 ? 'Activities' : 'Activity'} completed today
        </div>
      </Fade>
    );
  }
};

StudentLessonSummary.defaultProps = {
  data: null,
  subjectId: null,
};

StudentLessonSummary.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }),
  subjectId: PropTypes.string,
};

export default StudentLessonSummary;

import './ExtensionActivitesUploadFileMessage.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Typography } from '@mui/material';

export default function ExtensionActivitesUploadFileMessage() {
  return (
    <div className='extension-activities-upload-file-message' data-test='extension-activity-no-file'>
      <InsertDriveFileIcon className='icon' />
      <Typography>Upload your files here</Typography>
    </div>
  );
}

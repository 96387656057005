import FilterableMultiSelector from '../../../../../components/selector/FilterableMultiSelector';
import { useGetSubjectsByUserComponents } from '../../../../hooks/SubjectsByUsersHook';
import { useGroupPlay } from '../context/GroupPlayContextProvider';
import './LessonsGroupPlayFilter.scss';

const LessonsGroupPlayFilter = () => {
  const { groupPlayDomain } = useGroupPlay();
  const { lessonData } = groupPlayDomain.domainData;
  const { filter: { subjects } } = lessonData;
  const subjectList = useGetSubjectsByUserComponents(true);

  const subjectListDropdown = subjectList.map((s) => ({
    key: s.id,
    ...s,
  }));

  const handleOnApply = (value) => {
    groupPlayDomain.updateGroupPlayLessonFilter({
      ...lessonData.filter,
      subjects: value,
    });
  };

  return (
    <div className='lessons-group-play-filter' data-test='lesson-group-play-subject-filter'>
      <FilterableMultiSelector
        label='Subject'
        options={subjectListDropdown}
        filterable={false}
        className='lesson-group-play-subjct-dropdown'
        popperClassName='lesson-group-play-subjct-popper'
        onApply={handleOnApply}
        initFilterKeyValues={subjects.map((s) => s.key)}
      />
    </div>
  );
};

export default LessonsGroupPlayFilter;

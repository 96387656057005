import PropTypes from 'prop-types';
import { useMyLessonsContext } from '../context/MyLessonsContextProvider';
import MyLessonsAssignLessonsAction from './MyLessonsAssignLessonsAction';
import { flatten, unique } from '../../../../../utils/ArrayUtils';
import './MyLessonsAssignmentSelectionActions.scss';
import LessonsAddToGroupPlay from '../../../../lessons/lessonactions/lesson-multi-selection-actions/LessonsAddToGroupPlay';

const MyLessonsAssignmentSelectionActions = ({
  selectedLessonIds,
}) => {
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData, selectedDates } = myLessonsDomain.domainData;
  const { lessons } = lessonData;

  const allLessons = unique(flatten(lessons.map((subjectWithLessons) => subjectWithLessons.lessons)), 'id');

  return (
    <div className='my-lessons-assignment-selection-actions'>
      <LessonsAddToGroupPlay
        lessons={allLessons}
        selectedLessonIds={selectedLessonIds}
      />
      <MyLessonsAssignLessonsAction
        lessons={allLessons}
        selectedLessonIds={selectedLessonIds}
        key='my-lessons-assign-to-others'
        selectedDates={selectedDates}
      />
    </div>
  );
};

MyLessonsAssignmentSelectionActions.defaultProps = {
  selectedLessonIds: [],
};

MyLessonsAssignmentSelectionActions.propTypes = {
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
};

export default MyLessonsAssignmentSelectionActions;

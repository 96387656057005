import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { getActivityCardForPrinting } from '../../../utils/activitytype/ActivityTypesUtil';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../AppConstants';
import './LessonPageDataPrint.scss';
import { initDomains } from '../../../containers/lessonplay/context/LessonPlayContextProvider';

const LessonPageDataPrint = forwardRef(({ pageData }, ref) => {
  initDomains();
  const getContainerClass = (data) => {
    switch (data.activityType) {
      case ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA:
        return 'build-a-book-single-container';
      case ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA:
        return 'build-a-book-facing-container';
      case ACTIVITY_TYPE_MATCHING_ACTIVITY:
        return 'matching-container';
      case ACTIVITY_TYPE_SORTING_ACTIVITY:
        return 'sorting-container';
      case ACTIVITY_TYPE_GAME_ACTIVITY:
        return 'game-container';
      case ACTIVITY_TYPE_SEQUENCING_ACTIVITY:
        return 'sequencing-container';
      case ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY:
        return 'cause-and-effect-container';
      case ACTIVITY_TYPE_CATEGORIZING_ACTIVITY:
        return 'categorizing-container';
      case ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY:
        return 'flexible-matching-container';
      case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY:
        return 'multiple-choice-container';
      case ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2:
        return 'multiple-choice-v2-container';
      default:
        return '';
    }
  };

  return (
    <div ref={ref} className='lesson-page-print'>
      {pageData && pageData.map((item, index) => (
        <div className='page-print' key={index}>
          <div><br /></div>
          <div className={`page-print-inner ${getContainerClass(item)}`}>
            {getActivityCardForPrinting(item, index, 9999999, true)}
          </div>
        </div>
      ))}
    </div>
  );
});

LessonPageDataPrint.defaultProps = {
  pageData: [],
};

LessonPageDataPrint.propTypes = {
  pageData: PropTypes.arrayOf(PropTypes.shape({
    activityType: PropTypes.string,
  })),
};
export default LessonPageDataPrint;

import { SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS } from '../constants/LessonConstant';
import vizzleAxios from './service';

/**
 * Retrieve all lesson standards
 */
export const getLessonStandards = async (searchableOnly = true, state) => {
  let url = `/users/standards?searchableOnly=${searchableOnly}`;
  if (state) {
    url = `${url}&state=${state}`;
  }
  const result = await vizzleAxios.get(url);
  return result.data.data;
};

export const getLessonLevels = () => (
  [
    { id: '1', name: '1 - Higher level text and comprehension activities without visual cues' },
    { id: '2', name: '2 - Modified text, visual cues' },
    { id: '3', name: '3 - Minimal text, visual cues' },
  ]
);

export const getLessonGradeBand = (subjectId) => {
  if ([
    SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS].includes(Number(subjectId))) {
    return (
      [
        { id: '1', name: 'Pre-K', value: 'Pre-K' },
        { id: '2', name: 'K-2', value: 'K-2' },
        { id: '3', name: '3-5', value: '3-5' },
        { id: '4', name: '6-12', value: '6-12' },
        { id: '5', name: 'All', value: 'All' },
      ]
    );
  }
  return (
    [
      { id: '1', name: 'Pre-K', value: 'Pre-K' },
      { id: '2', name: 'K-2', value: 'K-2' },
      { id: '3', name: '3-5', value: '3-5' },
      { id: '4', name: '6-8', value: '6-8' },
      { id: '5', name: '9-12', value: '9-12' },
      { id: '6', name: 'All', value: 'All' },
    ]
  );
};

/**
 * @deprecated
 */
// export const getTopics = async () => {
//   const url = '/topics';
//   const result = await vizzleAxios.get(url);
//   return result.data;
// };

export const getStandardListByTopicIds = async ({ topicIds, state }) => {
  const url = '/topics/standards';
  const result = await vizzleAxios.get(url, {
    params: {
      ids: topicIds.join(','),
      state,
    },
  });
  return result.data.data;
};

export const getStandardListByCriteria = async (criteria) => {
  const url = '/standards';
  const result = await vizzleAxios.get(url, {
    params: {
      subject: criteria.subject,
      grades: criteria.grades.join(','),
    },
  });
  return result.data.data;
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContainerLessonPageDataList from '../../../components/vizzle/lessons/lessonmaintenance/ContainerLessonPageDataList';
import { getActivityCardThumbnailByActivityType } from '../../../utils/activitytype/ActivityTypesUtil';
import { useDomain } from '../../../states/AppDomainProvider';
import { getInitialDataByAcivityType } from '../../../utils/activitytype/ActivityTypeInitialData';
import { useShouldFeedbackLoopDisable } from './hooks/LessonMaintenanceHooks';
import { ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA } from '../../../AppConstants';

/**
 * Container class for Lesson PageDataListSelector
 *
 * @export
 * @returns
 */
export default function LessonDataPageListSelector({ setActiveStep }) {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData, lessonData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];
  const [pageDataList, setPageDataList] = useState();

  const pageTypeSelector = [
    {
      id: 'ADD_PAGE',
      label: 'ADD PAGE',
      onClick: async () => {
        // Add and Select to the last page
        const initData = getInitialDataByAcivityType(selectedPage.activityType);
        await lessonDataMaintenanceDomain.addLessonPageData(initData, currentSelectedPagesData + 1);
        setActiveStep(0);
      },
    },
    {
      id: 'COPY_PAGE',
      label: 'COPY PAGE',
      onClick: async () => {
        const dataToCopy = JSON.parse(JSON.stringify(pagesData.pages[currentSelectedPagesData]));
        // Add and Select to the last page
        await lessonDataMaintenanceDomain.addLessonPageData(dataToCopy, currentSelectedPagesData + 1);
        setActiveStep(0);
      },
      disabled: currentSelectedPagesData == null,
    },
  ];

  const onSelect = (index) => {
    lessonDataMaintenanceDomain.updateCurrentSelectedPagesData(index);
  };

  const onDelete = (index) => {
    lessonDataMaintenanceDomain.deleteLessonPageData(index);
  };

  const onDragEnd = (source, destination) => {
    lessonDataMaintenanceDomain.reOrderLessonPageData(source.index, destination.index, destination.index);
  };

  const {
    getShouldFeedbackLoopDisable,
  } = useShouldFeedbackLoopDisable();
  const isDisabled = getShouldFeedbackLoopDisable();
  const { feedbackLoopValue } = lessonData.attributes;
  const buildABookFacingPages = pagesData.pages.filter((page) => page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA);
  const disableDeleteIndex = [];
  if (buildABookFacingPages && buildABookFacingPages.length > 0) {
    buildABookFacingPages.forEach((bookPage, index) => {
      if (bookPage.cards.findIndex((card) => card.allowCustomResponse) >= 0 && isDisabled && feedbackLoopValue) {
        disableDeleteIndex.push(index);
      }
    });
  }

  useEffect(() => {
    const result = getActivityCardThumbnailByActivityType(pagesData);
    setPageDataList(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesData, pagesData.pages]);

  return (
    <ContainerLessonPageDataList
      actionConfigs={pageTypeSelector}
      actionAlign='vertical'
      selectedIndex={currentSelectedPagesData}
      onSelect={onSelect}
      onDelete={onDelete}
      onDragEnd={onDragEnd}
      className='vizzle-lesson-data-page-list-selector'
      disableDeleteIndex={disableDeleteIndex}
    >
      {pageDataList}
    </ContainerLessonPageDataList>
  );
}

LessonDataPageListSelector.defaultProps = {
  setActiveStep: () => { },
};

LessonDataPageListSelector.propTypes = {
  setActiveStep: PropTypes.func,
};

import PropTypes from 'prop-types';
import CurriculumLessonsFilterBar from './CurriculumLessonsFilterBar';
import FlatLessonCardListDetail from '../../../../../components/vizzle/cardlistdetail/lesson/FlatLessonCardListDetail';
import { useTeacherCurriculum } from '../../context/TeacherCurriculumContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { createLessonCardAction } from './actions/LessonCardActionBuilder';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/LessonCardDetailActionBuilder';
import LessonsMultiSelectionActions from './actions/LessonsMultiSelectionActions';
import { useLessonSelect, useGetNoLessonsMessage } from './CurriculumLessonsHooks';
import './CurriculumLessons.scss';

const CurriculumLessons = ({
  instructionMessage,
  noLessonMessage,
  isAssignAutoTag,
  searchInstructionComponent,
}) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonData, selectedLessonIds, hasPerformSearched } = teacherCurriculumDomain.domainData;

  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const {
    handleOnLessonSelect,
    handleOnLessonClear,
  } = useLessonSelect(teacherCurriculumDomain);

  const {
    isFiltered,
    noLessonsMessage,
  } = useGetNoLessonsMessage({
    noLessonMessage,
  });

  if (!hasPerformSearched) {
    return searchInstructionComponent;
  }

  return (
    <div className='curriculum-lessons'>
      {((lessonData && lessonData.lessons && lessonData.lessons.length > 0) || isFiltered) && (
        <LessonsMultiSelectionActions
          onClearClick={handleOnLessonClear}
          selectedLessonIds={selectedLessonIds}
          isAssignAutoTag={isAssignAutoTag}
        />
      )}
      <div className='curriculum-lessons-list'>
        {((lessonData && lessonData.lessons && lessonData.lessons.length > 0) || isFiltered) && (
          <CurriculumLessonsFilterBar instructionMessage={instructionMessage} />
        )}
        <FlatLessonCardListDetail
          lessonData={lessonData}
          noLessonMessage={noLessonsMessage}
          currentUser={user}
          currentUserProfle={userProfile}
          createCardAction={(lesson) => createLessonCardAction(lesson, user, userProfile, terminologies)}
          createCardDetailAction={(lesson, currentUser) => createLessonCardDetailAction(lesson, currentUser, userProfile, terminologies)}
          selectedLessonIds={selectedLessonIds}
          onLessonSelect={handleOnLessonSelect}
          createLessonDetailAction={(lesson) => createLessonCardDetailonPlanelAction(lesson, user, userProfile, terminologies)}
        />
      </div>
    </div>
  );
};

CurriculumLessons.defaultProps = {
  instructionMessage: '',
  noLessonMessage: 'No Activities are mapped to this Section.',
  isAssignAutoTag: true,
  searchInstructionComponent: null,
};

CurriculumLessons.propTypes = {
  instructionMessage: PropTypes.string,
  noLessonMessage: PropTypes.string,
  isAssignAutoTag: PropTypes.bool,
  searchInstructionComponent: PropTypes.node,
};

export default CurriculumLessons;

import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import AddStudentAction from './actions/AddStudentAction';
import DeactivateStudentAction from './actions/DeactivateStudentAction';
import ReactivateStudentAction from './actions/ReactivateStudentAction';
import FilterStudentAction from './actions/FilterStudentAction';
import { useGetStudentTable, useHandleStudentTable } from './StudentsHooks';
import { useAdmin } from '../../context/AdminContextProvider';
import './Students.scss';
import '../../AdminCommon.scss';
import ImportUsersAction from '../../components/actions/import/import-users/ImportUsersAction';
import { studentsBatchUpload } from '../../../../services/AdminService';
import MoveStudentsAction from './actions/move-students/MoveStudentsAction';
import AdminDistrictSchoolSelector from '../../admin-district-school-selector/AdminDistrictSchoolSelector';
import { useCheckAddAccess, useGetDistrictAndSchool } from '../../hooks/AdminUtilityHooks';
import { useGetUsersAndLicensesData } from '../../admin-district-school-selector/AdminDistrictSchoolSelectorHook';
import UpdateStudentAction from './actions/UpdateStudentAction';

const Students = () => {
  const {
    getUsersAndLicensesData,
    isLoading,
  } = useGetUsersAndLicensesData();
  const { studentTableData, tableConfig, tableRef } = useGetStudentTable();

  const reloadTable = () => {
    getUsersAndLicensesData();
  };

  const { adminStudentsDomain, adminDomain } = useAdmin();

  const { filterActive } = adminStudentsDomain.domainData;
  const { selectedSchoolId } = adminDomain.domainData;

  const {
    selectedStudentIds,
    handleOnSelectedRowIds,
    updateStudentRef,
    selectedStudents,
  } = useHandleStudentTable();

  const {
    selectedDistrict,
    selectedSchool,
  } = useGetDistrictAndSchool();

  const hasAddAccess = useCheckAddAccess();

  return (
    <div className='admin-tab-students full-height'>
      <SearchAndSortableTable
        containerClassName='students-table'
        preToolbarAction={[
          <AdminDistrictSchoolSelector
            key='admin-students-school-selector'
            onDataLoadSuccess={() => {
              if (tableRef.current) {
                tableRef.current.resetTableState();
              }
            }}
          />,
        ]}
        config={tableConfig}
        tableData={studentTableData}
        initTableState={{
          order: 'desc',
          orderBy: tableConfig[0].dataField,
        }}
        searchPlaceholder='Filter by Student Name'
        dataId='id'
        searchField='firstName'
        loading={isLoading}
        searchFunction={(keyword, data) => {
          const name = `${data.firstName} ${data.lastName}`;
          const isNameMatched = name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          const isEmailMatched = data.userName.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          return isNameMatched || isEmailMatched;
        }}
        selectedRowIds={selectedStudentIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        toolbarActions={[
          hasAddAccess ? (
            <AddStudentAction key='admin-add-student-action' onStudentAddSuccess={reloadTable} />
          ) : null,
          (!selectedStudentIds || selectedStudentIds.length <= 1
            ? (
              <UpdateStudentAction
                key='admin-update-student-action'
                ref={updateStudentRef}
                onStudentUpdateSuccess={reloadTable}
              />
            )
            : (
              <MoveStudentsAction
                key='admin-edit-students-action'
                selectedStudents={selectedStudents}
                onMoveStudentsSuccess={reloadTable}
                initSelectedDistrict={selectedDistrict}
                initSelectedSchool={selectedSchool}
              />
            )
          ),
          filterActive ? (
            <DeactivateStudentAction key='admin-deactivate-student-action' onStudentDeactivateSuccess={reloadTable} />
          ) : (
            <ReactivateStudentAction key='admin-reactivate-student-action' onStudentReactivateSuccess={reloadTable} />
          ),
          <FilterStudentAction key='admin-filter-student-action' />,
          hasAddAccess ? (
            <ImportUsersAction
              key='admin-import-students-action'
              buttonLabel='Import'
              id='admin-import-students-action'
              uploadServiceFunction={async (file) => studentsBatchUpload(selectedSchoolId, file)}
              ariaLabel='Import Students'
              uploadingMessage='Uploading Students'
              onUploadSuccess={reloadTable}
              disabled={!selectedSchoolId}
            />
          ) : null,
        ]}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Students;

/* eslint-disable no-param-reassign */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const CANVAS_WIDTH = 800;

const BUBBLE_COUNT = 40;
const MIN_BUBBLE_SIZE = 40;
const MAX_BUBBLE_SIZE = 120;

const BUBBLE_SPEED = 1.4;
const BUBBLE_BOB_RATE = 0.1;
const BUBBLE_BOB_AMOUNT = 0.05;

const POP_FRAMES = 4;
const POP_SCALE_START = 1.2;
const POP_SCALE_GROW = 1.2;
const POP_DY_START = -2;
const POP_DY_ACCELERATION = 1;

const GREAT_WORK_SCALE_START = 0.3;
const GREAT_WORK_SCALE_END = 0.5;
const GREAT_WORK_SCALE_TIME = 500;
const GREAT_WORK_WAIT = 4000;

const FORCE_POP_FRAMES = 600;

const BubbleCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    tweens: [],
    timeouts: [],
    clappingSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound,
    popSound: pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'pop')].sound,
  };

  const playClappingSound = () => {
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0 });
    }
  };

  const playPopSound = () => {
    if (app.popSound) {
      app.popSound.play({ start: 0 });
    }
  };

  const loadBitmaps = () => {
    app.bubble = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bubble')].texture);
    app.bubbleBounds = app.bubble.getBounds();
    app.bubblePopped = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bubblePopped')].texture);
    app.bubbleHit = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bubbleHit')].texture);
    app.greatWork = new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'greatWork')].texture);
    app.greatWorkBounds = app.greatWork.getBounds();
  };

  const popBubble = (argBubble) => {
    if (!argBubble.popped) {
      playPopSound();
      argBubble.image = app.bubblePopped.image;
      argBubble.scale.x *= POP_SCALE_START;
      argBubble.scale.y *= POP_SCALE_START;
      argBubble.framesToLive = POP_FRAMES;
      argBubble.popped = true;
      argBubble.dx = 0;
      argBubble.dy = POP_DY_START;
    }
  };

  const createBubbles = () => {
    app.stage = new PIXI.Container();
    app.canvas.stage.addChild(app.stage);
    app.bubblesLeft = BUBBLE_COUNT;
    app.bubbles = [];

    for (let i = 0; i < BUBBLE_COUNT; i++) {
      app.bubbles.push(new PIXI.Sprite(pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'bubble')].texture));
      app.bubbles[i].diameter = MIN_BUBBLE_SIZE + Math.random() * (MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE);
      app.bubbles[i].scale.x = app.bubbles[i].diameter / app.bubbleBounds.width;
      app.bubbles[i].scale.y = app.bubbles[i].scale.x;
      app.bubbles[i].anchor.set(0.5);
      app.bubbles[i].x = app.bubbles[i].diameter / 2 + Math.random() * (app.canvas.view.width - app.bubbles[i].diameter);
      app.bubbles[i].y = app.bubbles[i].diameter / 2 + Math.random() * (app.canvas.view.height - app.bubbles[i].diameter);
      app.bubbles[i].bob = Math.random() * 2 * 3.14159;
      app.bubbles[i].framesToLive = FORCE_POP_FRAMES + Math.random() * 10;
      app.bubbles[i].popped = false;

      const angle = Math.random() * 2 * 3.14159;

      app.bubbles[i].dx = Math.cos(angle) * BUBBLE_SPEED;
      app.bubbles[i].dy = Math.sin(angle) * BUBBLE_SPEED;

      app.bubbles[i].interactive = true;
      app.bubbles[i].on('click', () => {
        popBubble(app.bubbles[i]);
      });

      app.bubbles[i].on('tap', () => {
        popBubble(app.bubbles[i]);
      });

      app.stage.addChild(app.bubbles[i]);
    }
  };

  const showFinish = () => {
    if (!app.showFinishCalled) {
      app.showFinishCalled = true;

      playClappingSound();

      app.greatWork.anchor.set(0.5);
      app.greatWork.x = app.canvas.view.width / 2;
      app.greatWork.y = app.canvas.view.height / 2;
      app.greatWork.scale.x = GREAT_WORK_SCALE_START;
      app.greatWork.scale.y = GREAT_WORK_SCALE_START;

      app.stage.addChild(app.greatWork);

      const greatWorkTween = new TWEEN.Tween(app.greatWork.scale);
      greatWorkTween.delay(500);
      greatWorkTween.to({
        x: GREAT_WORK_SCALE_END,
        y: GREAT_WORK_SCALE_END,
      }, GREAT_WORK_SCALE_TIME);
      greatWorkTween.easing(TWEEN.Easing.Linear.None);
      greatWorkTween.onComplete(() => {
        const onFinishTimeout = setTimeout(() => {
          clearTimeout(onFinishTimeout);
          onFinish();
        }, GREAT_WORK_WAIT);
        app.timeouts.push(onFinishTimeout);
      });

      app.tweens.push(greatWorkTween);
      greatWorkTween.start();
    }
  };

  const updateBubble = (argBubble) => {
    if (argBubble.visible) {
      if (!argBubble.popped) {
        argBubble.bob += BUBBLE_BOB_RATE;

        argBubble.x += argBubble.dx;
        argBubble.y += argBubble.dy;

        argBubble.scale.x = (argBubble.diameter * (1 + Math.sin(argBubble.bob) * BUBBLE_BOB_AMOUNT)) / app.bubbleBounds.width;
        argBubble.scale.y = (argBubble.diameter * (1 + Math.cos(argBubble.bob) * BUBBLE_BOB_AMOUNT)) / app.bubbleBounds.height;

        if (argBubble.x <= argBubble.diameter / 2) {
          argBubble.dx = Math.abs(argBubble.dx);
        }

        if (argBubble.y <= argBubble.diameter / 2) {
          argBubble.dy = Math.abs(argBubble.dy);
        }

        if (argBubble.x >= app.canvas.view.width - argBubble.diameter / 2) {
          argBubble.dx = -Math.abs(argBubble.dx);
        }

        if (argBubble.y >= app.canvas.view.height - argBubble.diameter / 2) {
          argBubble.dy = -Math.abs(argBubble.dy);
        }

        argBubble.framesToLive--;

        if (argBubble.framesToLive <= 0) {
          const randomPop = Math.random() * 2000;
          const popBubbleTimeout = setTimeout(() => {
            clearTimeout(randomPop);
            popBubble(argBubble);
          }, randomPop);
          app.timeouts.push(popBubbleTimeout);
        }
      } else {
        argBubble.x += argBubble.dx;
        argBubble.y += argBubble.dy;

        argBubble.dy += POP_DY_ACCELERATION;

        argBubble.scale.x *= POP_SCALE_GROW;
        argBubble.scale.y *= POP_SCALE_GROW;

        argBubble.framesToLive--;

        if (argBubble.framesToLive <= 0) {
          argBubble.visible = false;
          app.bubblesLeft--;
        }
      }
    }
  };

  const tick = () => {
    if (app.bubblesLeft > 0) {
      for (let i = 0; i < BUBBLE_COUNT; i++) {
        updateBubble(app.bubbles[i]);
      }
    } else if (app.bubblesLeft === 0) {
      app.bubblesLeft = -1;
      showFinish();
    }
  };

  useSetupTimeout(canvas, showFinish);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundColor: 0xFFFFFF,
      });

      loadBitmaps();
      createBubbles();
      app.canvas.ticker.add(tick);
    }

    return () => {
      if (app.clappingSound) {
        app.clappingSound.stop();
      }
      if (app.popSound) {
        app.popSound.stop();
      }

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

BubbleCelebration.defaultProps = {
  onFinish: () => { },
};

BubbleCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default BubbleCelebration;

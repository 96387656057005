import BaseDomain from '../../../states/domains/BaseDomain';

const TEACHER_COURSE_SEARCH_CRITERIA_UPDATE_LESSON_DATA = 'TEACHER_COURSE_SEARCH_CRITERIA_UPDATE_LESSON_DATA';

export const defaultFilterCriteriaAssignedStudentCourses = {
  studentIds: null,
  lessonIds: null,
};

const initialState = {
  searchCriteria: {},
  hasPerformSearch: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case TEACHER_COURSE_SEARCH_CRITERIA_UPDATE_LESSON_DATA:
      return {
        ...state,
        hasPerformSearch: action.payload.hasPerformSearch,
        searchCriteria: action.payload.searchCriteria,
      };
    default:
      return state;
  }
};

const domainName = 'teacherCourseSearchCriteriaDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSearchCriteriaLessons({
    hasPerformSearch,
    searchCriteria,
  }) {
    return this.dispatch({
      type: TEACHER_COURSE_SEARCH_CRITERIA_UPDATE_LESSON_DATA,
      payload: {
        hasPerformSearch,
        // filterCriteria,
        searchCriteria,
      },
    });
  }
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { splitQuestionText, BLANK_SPACE } from './FillInTheBlankUtils';
import './FillInTheBlank.scss';

const FillInTheBlank = ({
  question,
  answers,
  onFinish,
}) => {
  const questionText = question.question;
  const parts = splitQuestionText(questionText);
  const [answerText, setAnswerText] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState({
    count: 0,
    showNotification: false,
  });

  const isCorrect = (attempt) => {
    if (answers.length === 0) {
      return true;
    }
    const transformedAnswers = answers.map((answer) => (answer.toLowerCase()));
    const transformedAttemp = attempt.toLowerCase();
    return transformedAnswers.indexOf(transformedAttemp) >= 0;
  };

  // Form submit somehow does now work
  const handleOnAnswerSubmit = (e) => {
    e.preventDefault();
    if (isCorrect(answerText)) {
      setWrongAnswer({
        ...wrongAnswer,
        count: 0,
        showNotification: false,
      });
      setCorrectAnswer(true);
      onFinish();
    } else if (wrongAnswer.count < 2) {
      setWrongAnswer({
        ...wrongAnswer,
        count: wrongAnswer.count + 1,
        showNotification: true,
      });
      setAnswerText('');
    } else {
      setWrongAnswer({
        ...wrongAnswer,
        count: 0,
        showNotification: false,
      });
      setAnswerText('');
      onFinish();
    }
  };

  const handleOnAnswerTextChange = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleOnAnswerSubmit(e);
    } else {
      setAnswerText(e.target.value.substring(0, 255));
      if (wrongAnswer.showNotification) {
        setWrongAnswer({
          ...wrongAnswer,
          showNotification: false,
        });
      }
    }
  };

  return (
    <div className='fill-in-the-blank'>
      <div className='question-area'>
        {parts.preBlank}
        {answerText ? (
          <div className='answer-text'>
            {answerText}
          </div>
        ) : BLANK_SPACE}

        {parts.postBlank}
      </div>
      <form className='submit-answer-form'>
        <div className='text-field'>
          {wrongAnswer.showNotification ? (
            <Typography variant='subtitle1' className='try-again'>
              Try again!
            </Typography>
          ) : ''}
          <TextField
            autoFocus
            fullWidth
            value={answerText}
            onChange={handleOnAnswerTextChange}
            variant='outlined'
            disabled={correctAnswer}
            className={`${wrongAnswer.showNotification ? 'wrong-answer' : ''}`}
          />
        </div>
        <Button
          color='primary'
          type='submit'
          className='btn-primary'
          disabled={!answerText || correctAnswer}
          onClick={handleOnAnswerSubmit}
        >
          Answer
        </Button>
      </form>
    </div>
  );
};

FillInTheBlank.defaultProps = {
  question: '',
  answers: [],
  onFinish: () => { },
};

FillInTheBlank.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string,
  }),
  answers: PropTypes.arrayOf(PropTypes.string),
  onFinish: PropTypes.func,
};

export default FillInTheBlank;

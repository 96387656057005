import PropTypes from 'prop-types';
import CustomDialog from '../../../components/dialog/CustomDialog';
import './StudentResetPassword.scss';

const StudentResetPassword = ({ onClose, openDialog, backFunction }) => (
  <CustomDialog
    className='student-reset-password-dialog'
    openDialog={openDialog}
    onClose={onClose}
    title={(
      <div className='title'>
        It looks like you are a Student
      </div>
    )}
    content={(
      <div className='content'>
        <div className='message'>
          Please contact your Teacher to reset your password.
        </div>
      </div>
    )}
    actions={[
      {
        title: 'Not a student?',
        onClick: backFunction,
        className: 'btn-primary back-button',
      },
    ]}
    displayCloseIcon
  />
);

StudentResetPassword.defaultProps = {
  onClose: () => { },
  openDialog: false,
  backFunction: () => { },
};

StudentResetPassword.propTypes = {
  onClose: PropTypes.func,
  openDialog: PropTypes.bool,
  backFunction: PropTypes.func,
};

export default StudentResetPassword;

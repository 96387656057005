import LoadingSpinner from '../../../../../../components/loaders/LoadingSpinner';
import NoLessonPaper from '../../../../../../components/paper/NoLessonPaper';
import { sort } from '../../../../../../utils/ArrayUtils';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import './ExtensionActivitiesFileList.scss';
import { useGetExtensionActivities } from './ExtensionActivitiesHooks';
import ExtensionActivitiesViewFile from './view/ExtensionActivitiesViewFile';

const ExtensionActivitiesFileList = () => {
  const { loading } = useGetExtensionActivities();
  const { extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities } = extensionActivitiesDomain.domainData;
  const { activities } = extensionActivities;

  if (loading) {
    return (
      <div className='extension-activities-file-list loading-container'>
        <LoadingSpinner message='Loading Extension Activities' />
      </div>
    );
  }

  if (!activities || activities.length === 0) {
    return (
      <NoLessonPaper message='No extension activities available' />
    );
  }

  return (
    <div className='blue-gradient extension-activities-file-list'>
      {sort(activities, 'name').map((activity) => (
        <ExtensionActivitiesViewFile key={`activity-${activity.id}`} activity={activity} />
      ))}
    </div>
  );
};

export default ExtensionActivitiesFileList;

import {
  SEARCH_BAR_DROPDOWN_VALUES_FLORIDA_TEACHER,
  SEARCH_BAR_DROPDOWN_VALUES,
  CUSTOM_UNITS,
} from './TeacherCurriculumDropdownValues';
import {
  getTexasTabs,
  getFloridaTabs,
} from '../../teacher-curriculum/service/TeacherCurriculumTabs';
import { useDomain } from '../../../../states/AppDomainProvider';
import { isFloridaTeacher, isTexasTeacher } from '../../../../utils/User';

export const useGetDropdownOptions = (values) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  if (isFloridaTeacher(user)) {
    if (values.grade.customUnit) {
      return {
        ...SEARCH_BAR_DROPDOWN_VALUES_FLORIDA_TEACHER,
        units: [...CUSTOM_UNITS[values.grade.customUnit]],
      };
    }
    return SEARCH_BAR_DROPDOWN_VALUES_FLORIDA_TEACHER;
  }
  return SEARCH_BAR_DROPDOWN_VALUES;
};

export const useGetTabs = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  let steps = [];

  if (isTexasTeacher(user)) {
    steps = getTexasTabs();
  } else if (isFloridaTeacher(user)) {
    steps = getFloridaTabs();
  }
  return {
    steps,
  };
};

import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { getActivityCardThumbnailByActivityType } from '../../../../../../utils/activitytype/ActivityTypesUtil';
import './LessonPagesList.scss';

function LessonPagesList({
  selectedPageIndexes,
  onSelectePage,
}) {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData } = lessonDataMaintenanceDomain.domainData;
  const cards = getActivityCardThumbnailByActivityType(pagesData);

  return (
    <div className='lesson-pages-list'>
      {cards.map((card, index) => {
        const isSelected = selectedPageIndexes.includes(index);
        return (
          <Paper
            className={`lesson-page-card ${isSelected ? 'vizzle-selected-component' : ''}`}
            key={`lesson-pages-selection-page-${index}`}
            onClick={() => {
              if (isSelected) {
                const updatedSelectedPage = selectedPageIndexes.filter((s) => s !== index);
                onSelectePage(updatedSelectedPage);
              } else {
                const updatedSelectedPage = [...selectedPageIndexes, index];
                onSelectePage(updatedSelectedPage);
              }
            }}
          >
            {card}
          </Paper>
        );
      })}
    </div>
  );
}

LessonPagesList.defaultProps = {
  selectedPageIndexes: [],
  onSelectePage: () => null,
};

LessonPagesList.propTypes = {
  selectedPageIndexes: PropTypes.arrayOf(PropTypes.number),
  onSelectePage: PropTypes.func,
};

export default LessonPagesList;

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import './MultipleDateSelector.scss';

export const DATE_SELECTOR_MODE = {
  DATE_RANGE: '0',
  MULTIPLE_DATES: '1',
};

const getMultipleDateSelectorPlaceHolder = (mode, showText, openCalendar) => (
  // eslint-disable-next-line
  <div className='dates-selector' onClick={openCalendar}>
    <DateRangeIcon className='icon' />
    {showText && mode === DATE_SELECTOR_MODE.DATE_RANGE && 'CLICK TO SELECT DATE RANGE'}
    {showText && mode === DATE_SELECTOR_MODE.MULTIPLE_DATES && 'CLICK TO SELECT DAYS'}
  </div>
);

export default function MultipleDateSelector({
  selectedDates,
  onSelectedDatesChange,
  onClose,
  onApply,
  selectorMode,
  minDate,
  maxDate,
  noApplyButton,
  noClearButton,
  className,
  showDatePanel,
  formMode,
  highlightToday,
  showText,
}) {
  const datePickerRef = useRef(null);
  const [mode, setMode] = useState(() => {
    if (selectorMode !== null) {
      return selectorMode;
    }
    if (!selectedDates || selectedDates.isDateRange === null || selectedDates.isDateRange === undefined) {
      return DATE_SELECTOR_MODE.DATE_RANGE;
    }
    return selectedDates.isDateRange ? DATE_SELECTOR_MODE.DATE_RANGE : DATE_SELECTOR_MODE.MULTIPLE_DATES;
  });

  const resetDaysSelection = () => {
    onSelectedDatesChange({
      isDateRange: mode === DATE_SELECTOR_MODE.DATE_RANGE,
      dates: [],
    });
  };

  const handleChange = (e) => {
    setMode(e.target.value);
    resetDaysSelection();
  };

  const plugins = [];
  if (showDatePanel) {
    plugins.push(<DatePanel />);
  }
  return (
    <div className={`multiple-date-selector ${formMode ? 'form-mode' : ''} ${className}`}>
      <div className='date-picker-component-container' data-test='multiple-date-selector'>
        <DatePicker
          multiple={mode === DATE_SELECTOR_MODE.MULTIPLE_DATES}
          range={mode === DATE_SELECTOR_MODE.DATE_RANGE}
          animation
          value={selectedDates.dates}
          format='MM/DD/YYYY'
          minDate={minDate}
          maxDate={maxDate}
          // type={`${showText ? 'button' : 'icon'}`}
          containerClassName={`date-picker-component ${highlightToday ? '' : 'no-highlight'} ${showText ? 'with-text' : ''}`}
          ref={datePickerRef}
          onClose={() => {
            onClose();
          }}
          onChange={(dates) => {
            const sortedDate = (mode === DATE_SELECTOR_MODE.MULTIPLE_DATES ? dates.sort() : dates);
            const formattedDates = sortedDate.map((d) => d.format('MM/DD/YYYY'));
            onSelectedDatesChange({
              isDateRange: mode === DATE_SELECTOR_MODE.DATE_RANGE,
              dates: formattedDates,
            });
          }}
          plugins={plugins}
          renderInput={(params) => <TextField {...params} variant='standard' />}
          render={formMode ? undefined : (_value, openCalendar) => getMultipleDateSelectorPlaceHolder(mode, showText, openCalendar)}
        >
          {selectorMode === null && (
            <RadioGroup
              className='multiple-date-selector-radio-group'
              value={mode}
              onChange={handleChange}
            >
              <FormControlLabel
                value={DATE_SELECTOR_MODE.DATE_RANGE}
                control={<Radio />}
                label='Select Date Range'
                data-test='select-date-range'
              />
              <FormControlLabel
                value={DATE_SELECTOR_MODE.MULTIPLE_DATES}
                control={<Radio />}
                label='Select Days'
                data-test='select-days'
              />
            </RadioGroup>
          )}
        </DatePicker>
        {(!noApplyButton || !noClearButton) && (
          <Divider className='divider' orientation='vertical' />
        )}
        {!noApplyButton && (
          <IconButton
            aria-label='Apply'
            size='small'
            disabled={!selectedDates || !selectedDates.dates || selectedDates.dates.length === 0}
            className='btn-green-background apply-date-button'
            onClick={onApply}
            data-test='apply-button'
          >
            <CheckIcon />
          </IconButton>
        )}

        {!noClearButton && (
          <IconButton
            aria-label='Clear Dates'
            size='small'
            disabled={!selectedDates || !selectedDates.dates || selectedDates.dates.length === 0}
            className='btn-danger'
            onClick={resetDaysSelection}
            data-test='clear-dates-button'
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}

MultipleDateSelector.defaultProps = {
  selectedDates: {},
  onSelectedDatesChange: () => null,
  onClose: () => null,
  onApply: () => null,
  selectorMode: null,
  minDate: null,
  maxDate: null,
  noApplyButton: false,
  noClearButton: false,
  className: '',
  showDatePanel: false,
  formMode: false,
  highlightToday: true,
  showText: true,
};

MultipleDateSelector.propTypes = {
  selectedDates: PropTypes.shape({
    isDateRange: PropTypes.bool,
    dates: PropTypes.arrayOf(PropTypes.string),
  }),
  onSelectedDatesChange: PropTypes.func,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  selectorMode: PropTypes.oneOf([DATE_SELECTOR_MODE.DATE_RANGE, DATE_SELECTOR_MODE.MULTIPLE_DATES]),
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  noApplyButton: PropTypes.bool,
  noClearButton: PropTypes.bool,
  className: PropTypes.string,
  showDatePanel: PropTypes.bool,
  formMode: PropTypes.bool,
  highlightToday: PropTypes.bool,
  showText: PropTypes.bool,
};

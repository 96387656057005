import { useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncLoader from 'react-spinners/SyncLoader';
import NotificationBlocker from '../../../../../../components/blocker/NotificationBlocker';
import AudioUploader from '../../../../../../components/vizzle/uploader/AudioUploader';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { UNAUTHORIZED } from '../../../../../../AppConstants';
import { uploadMedia, updateUserAssets } from '../../../../../../services/MediaService';
import Logger from '../../../../../../utils/Logger';

/**
 * Container for uploading an audio file from the user's computer
 *
 * @export
 */
export default function BrowseAudioUploader({
  audio,
  onSelect,
  onRemove,
  setBrowseAudioRef,
}) {
  const [uploadState, setUploadState] = useState({
    isUploading: false,
    uploadingMessage: '',
  });
  const { uiDomain } = useDomain();

  const handleOnAudioSelected = async (data) => {
    setUploadState({
      isUploading: true,
      uploadingMessage: 'Please wait while the audio is being uploaded.',
    });

    try {
      const audioPromise = await uploadMedia(data);
      const { attributes: { fileName, s3Url } } = audioPromise;
      updateUserAssets({ file: data, fileName, description: fileName });
      await onSelect(s3Url);
    } catch (e) {
      if (e.response && UNAUTHORIZED !== e.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
      }
      Logger.logError({
        ERROR: e,
      });
    }
    setUploadState({
      isUploading: false,
      uploadingMessage: '',
    });
  };

  const handleOnAudioRemoved = async () => {
    await onRemove();
  };

  const noAudio = audio === null || audio === undefined;

  return (
    <>
      <AudioUploader
        onSelect={handleOnAudioSelected}
        onRemove={handleOnAudioRemoved}
        loading={uploadState.isUploading}
        className='vizzle-audio-browse-computer'
        disabledRemove={noAudio}
        setBrowseAudioRef={setBrowseAudioRef}
        audioUrl={audio}
      />
      <NotificationBlocker
        show={uploadState.isUploading}
        loaderIcon={<SyncLoader color='#3b94d1' />}
        Icon={CloudUploadIcon}
        message={uploadState.uploadingMessage}
      />
    </>
  );
}

BrowseAudioUploader.defaultProps = {
  audio: null,
  onSelect: () => { },
  onRemove: () => { },
  setBrowseAudioRef: () => { },
};

BrowseAudioUploader.propTypes = {
  audio: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  setBrowseAudioRef: PropTypes.func,
};

/* eslint-disable react/no-unused-prop-types */
import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MathView from 'react-math-view';
import { v1 as uuidv1 } from 'uuid';
import './TextLines.scss';
import { adjustMathEquationText, getTextArray, textAlignToFlex } from './TextLinesUtils';

const TextLines = forwardRef(({
  textLines,
  isThumbnail,
  componentKey,
  fontScale,
}, ref) => {
  let currentFontSize = null;

  const paragraphRefs = useRef([]);
  const setParagraphRefs = (r, index) => {
    if (r) {
      paragraphRefs.current[index] = r;
    }
  };

  useImperativeHandle(ref, () => ({
    getParagraphRefs: () => paragraphRefs.current,
  }));

  const getContainer = (child, keyToUse, lineIndex, index, elementStyleToUse, fontSize) => (
    <span key={`text-line-element-${keyToUse}-${lineIndex}-${index}`} className={isThumbnail ? 'text-lines-hide-long-text' : ''} style={{ ...elementStyleToUse, minHeight: fontSize }}>
      {child}
    </span>
  );

  const setMathLiveRefs = (r, textContainerId, text) => {
    // let minusCounter = 0;
    if (r) {
      const ttsText = textLines.ttsText || {};
      const preferedTtsText = ttsText[r.getValue()];
      if (preferedTtsText) {
        document.getElementById(textContainerId).innerHTML = preferedTtsText;
        return;
      }
      // eslint-disable-next-line
      r._mathfield.element.querySelector('.ML__container').style.minHeight = 'unset';
      // eslint-disable-next-line
      r._mathfield.element.querySelector('.ML__container').style.padding = '2px 0 0px 1px';
      const t = r.getValue('spoken')
        .replace(/'/gi, ' ');

      const tempText = adjustMathEquationText(t, text);
      document.getElementById(textContainerId).innerHTML = tempText;
    }
  };

  return (
    textLines.lines.map((line, lineIndex) => {
      const { elements, ...style } = line;
      const { fontSize, ...styleWithoutFontSize } = style;
      const styleToUse = isThumbnail ? styleWithoutFontSize : style;
      const keyToUse = componentKey || uuidv1();
      return (
        <div
          key={`${keyToUse}-${lineIndex}`}
          id={`${keyToUse}-${lineIndex}`}
          className={`${isThumbnail ? 'text-lines-text-content-line' : ''} text-lines-text-content`}
          style={{ ...styleToUse, justifyContent: textAlignToFlex(styleToUse.textAlign) }}
          ref={(r) => setParagraphRefs(r, lineIndex)}
          data-test={`text-lines-${lineIndex}`}
        >
          {elements.length === 0 && !isThumbnail ? (
            <span style={{ fontSize: currentFontSize }}> &nbsp; </span>
          ) : ''}
          {elements.map((e, index) => {
            const { text, ...elementStyle } = e;
            // eslint-disable-next-line
            const { fontSize, ...elementStyleWithoutFontSize } = elementStyle;
            currentFontSize = fontSize;
            let elementStyleToUse = isThumbnail ? elementStyleWithoutFontSize : elementStyle;
            if (fontScale > 0) {
              elementStyleToUse = {
                ...elementStyleToUse,
                fontSize: `calc(${fontSize} * ${fontScale})`,
              };
            }
            if (!text) {
              return (
                getContainer(<span>&nbsp;</span>, keyToUse, lineIndex, index, elementStyleToUse, fontSize)
              );
            }
            const textArray = getTextArray(text) || [];
            return (
              textArray.map((t, i) => {
                const mathLiveKey = `text-line-element-${keyToUse}-${lineIndex}-${index}-${i}`;
                if (t.isText) {
                  return (
                    // eslint-disable-next-line max-len
                    <span key={`text-line-element-${keyToUse}-${lineIndex}-${index}-${i}`} className={`text-line-span ${isThumbnail ? 'text-lines-hide-long-text' : ''}`} style={{ ...elementStyleToUse, minHeight: fontSize }}>
                      {(elementStyleToUse.textDecoration && elementStyleToUse.textDecoration !== 'none') ? (
                        <div style={{ display: 'initial', textDecoration: elementStyleToUse.textDecoration }}>{t.text}</div>
                      ) : t.text}
                    </span>
                  );
                } else {
                  return (
                    // eslint-disable-next-line max-len
                    <span
                      key={`text-line-element-${keyToUse}-${lineIndex}-${index}-${i}`}
                      className={`text-line-span ${isThumbnail ? 'text-lines-hide-long-text' : ''}`}
                      style={{ ...elementStyleToUse, minHeight: fontSize }}
                    >
                      <MathView
                        key={mathLiveKey}
                        className='math-live-container'
                        value={t.text}
                        readOnly
                        contentEditable='false'
                        suppressContentEditableWarning
                        suppressHydrationWarning
                        ref={(r) => { setMathLiveRefs(r, `${mathLiveKey}-text-container`, t.text); }}
                      />
                      <div className='match-live-text-container' id={`${mathLiveKey}-text-container`} />
                    </span>
                  );
                }
              })
            );
          })}
        </div>
      );
    })
  );
});

TextLines.defaultProps = {
  textLines: {
    lines: [],
    ttsText: {},
  },
  isThumbnail: false,
  componentKey: '',
  fontScale: null,
};

TextLines.propTypes = {
  textLines: PropTypes.shape({
    lines: PropTypes.array,
    ttsText: PropTypes.object,
  }),
  isThumbnail: PropTypes.bool,
  componentKey: PropTypes.string,
  fontScale: PropTypes.number,
};

export default TextLines;

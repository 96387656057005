import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import './FillInTheBlankEditor.scss';
import MatchEquationEditorDialog from '../../../../containers/lessons/lessonmaintenance/steps/media/editor/MatchEquationEditorDialog';
import { DELEMETER } from '../../text/TextLinesUtils';

const FillInTheBlankEditor = ({
  preBlank,
  postBlank,
  answers,
  onPreBlankChange,
  onPostBlankChange,
  onAnswerAdd,
  onAnswerDelete,
  fitbData,
  isTtsTextAvailable,
}) => {
  const [openDialog, setOpenDialog] = useState({
    open: false,
    isPreBlank: false,
  });

  const [answerText, setAnswerText] = useState('');

  const handleOnAnswerTextChange = (e) => {
    e.stopPropagation();
    setAnswerText(e.target.value.substring(0, 255));
  };

  const handleOnAnswerAdd = (e) => {
    e.preventDefault();
    onAnswerAdd(answerText);
    setAnswerText('');
  };

  const handleOnAnswerDelete = (chipToDelete) => () => {
    onAnswerDelete(chipToDelete);
  };

  return (
    <>
      <div className='fill-in-the-blank-editor'>
        <TextField
          autoFocus
          value={preBlank}
          multiline
          minRows={3}
          maxRows={3}
          onChange={onPreBlankChange}
          fullWidth
          label='Text before answer (optional)'
        />
        <Button
          variant='contained'
          className='btn-primary math-editor-button vizzle-text-math-equation-editor'
          onClick={async () => {
            setOpenDialog({
              open: true,
              isPreBlank: true,
            });
          }}
        >
          Open Math Editor
        </Button>
        <Paper className='answer-area'>
          <Typography variant='subtitle1'>
            Answers
          </Typography>
          <form onSubmit={handleOnAnswerAdd} className='add-answer'>
            <div className='text-field'>
              <TextField
                fullWidth
                value={answerText}
                onChange={handleOnAnswerTextChange}
              />
            </div>
            <Button
              color='primary'
              type='submit'
              className='btn-primary'
              disabled={!answerText}
            >
              ADD
            </Button>
          </form>

          {!answers || answers.length === 0 ? (
            <div className=''>
              (If no answers are added then all answers are correct)
            </div>
          ) : ''}

          <div className='answers'>
            {answers.map((data, index) => {
              let icon;
              return (
                <Chip
                  className='chip'
                  key={index}
                  icon={icon}
                  label={data}
                  onDelete={handleOnAnswerDelete(data)}
                />
              );
            })}
          </div>
        </Paper>
        <TextField
          value={postBlank}
          multiline
          minRows={3}
          maxRows={3}
          onChange={onPostBlankChange}
          fullWidth
          label='Text after answer (optional)'
        />
        <Button
          variant='contained'
          className='btn-primary math-editor-button vizzle-text-math-equation-editor'
          onClick={async () => {
            setOpenDialog({
              open: true,
              isPreBlank: false,
            });
          }}
        >
          Open Math Editor
        </Button>
      </div>
      {openDialog.open && (
        <MatchEquationEditorDialog
          openDialog={openDialog.open}
          onClose={() => { setOpenDialog(false); }}
          onApply={async (value, updatedTtsText) => {
            // eslint-disable-next-line no-param-reassign
            fitbData.ttsText = updatedTtsText;
            if (openDialog.isPreBlank) {
              const data = {
                target: {
                  value: value ? `${preBlank} ${DELEMETER}${value}${DELEMETER} ` : preBlank,
                },
              };
              onPreBlankChange(data);
            } else if (openDialog.isPreBlank === false) {
              const data = {
                target: {
                  value: value ? `${postBlank} ${DELEMETER}${value}${DELEMETER} ` : postBlank,
                },
              };
              onPostBlankChange(data);
            }
            if (!isTtsTextAvailable) {
              setOpenDialog({
                open: false,
                isPreBlank: undefined,
              });
            }
          }}
          textToRead={`${preBlank} ${postBlank}`}
          ttsText={fitbData.ttsText || {}}
        />
      )}
    </>
  );
};

FillInTheBlankEditor.defaultProps = {
  preBlank: '',
  postBlank: '',
  answers: [],
  onPreBlankChange: () => { },
  onPostBlankChange: () => { },
  onAnswerAdd: () => { },
  onAnswerDelete: () => { },
  fitbData: {
    ttsText: {},
  },
  isTtsTextAvailable: false,
};

FillInTheBlankEditor.propTypes = {
  preBlank: PropTypes.string,
  postBlank: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.string),
  onPreBlankChange: PropTypes.func,
  onPostBlankChange: PropTypes.func,
  onAnswerAdd: PropTypes.func,
  onAnswerDelete: PropTypes.func,
  fitbData: PropTypes.object,
  isTtsTextAvailable: PropTypes.bool,
};

export default FillInTheBlankEditor;

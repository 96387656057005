import PropTypes from 'prop-types';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import './ArchiveUnarchiveObjectiveDialog.scss';
import LiveChatService from '../../../../../../services/LiveChatService';
import ObjectUtils from '../../../../../../utils/ObjectUtils';

const ArchiveUnarchiveObjectiveDialog = ({
  open,
  message,
  onYesClick,
  onNoClick,
  onClose,
}) => {
  const actions = [
    {
      title: 'Yes',
      onClick: onYesClick,
      color: 'primary',
      className: 'btn-primary',
    },
    {
      title: 'No',
      onClick: onNoClick,
      color: 'primary',
      className: 'btn-danger',
    },
  ];

  const content = (
    <div className='archive-unarchive-objective-content'>
      {message}
    </div>
  );
  return (
    <CustomDialog
      key='archive-unachive-all-lessons-in-objective'
      title={null}
      content={content}
      openDialog={open}
      onClose={onClose}
      displayCloseIcon
      actions={actions}
      onEntered={() => {
        LiveChatService.hideLiveChatIcon();
      }}
      onExisting={() => {
        ObjectUtils.setTimeout(() => {
          LiveChatService.hideLiveChatIcon();
        });
      }}
    />
  );
};

ArchiveUnarchiveObjectiveDialog.defaultProps = {
  open: false,
  message: '',
  onYesClick: () => {},
  onNoClick: () => {},
  onClose: () => {},
};

ArchiveUnarchiveObjectiveDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default ArchiveUnarchiveObjectiveDialog;

import PropTypes from 'prop-types';
import './FlattenLessonStandardList.scss';

export default function FlattenLessonStandardList({ standards }) {
  return (
    <div>
      <ul className='flatten-standard-list' data-test='standard-list'>
        {
          standards.map((standard) => (
            <li key={`lesson-standard-${standard.id}`} data-test={`lesson-standard-${standard.id}`}>
              <div className='standard-code' data-test='standard-code'>
                {standard.attributes.standardCode}
              </div>
              <div className='standard-description' data-test='standard-description'>
                {standard.attributes.standardDescription}
              </div>
            </li>

          ))
        }
      </ul>
    </div>
  );
}

FlattenLessonStandardList.defaultProps = {
  standards: [],
};

FlattenLessonStandardList.propTypes = {
  standards: PropTypes.arrayOf(PropTypes.shape({
    attributes: PropTypes.object,
  })),
};

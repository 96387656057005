import PropTypes from 'prop-types';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useMyLessonsContext } from '../context/MyLessonsContextProvider';
import ArchiveSelectedLessons from '../../../../lessons/lessonactions/lesson-multi-selection-actions/ArchiveSelectedLessons';
import UnarchiveSelectedLessons from '../../../../lessons/lessonactions/lesson-multi-selection-actions/UnarchiveSelectedLessons';
import { useArchiveLessonForTeacher } from '../../../../lessons/lessonactions/hooks/ArchiveUnarchiveHooks';
import './MyLessonsMultiSelectionActions.scss';

const MyLessonsMultiSelectionActions = ({
  selectedLessonIds,
}) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { myLessonsDomain } = useMyLessonsContext();
  const { lessonData } = myLessonsDomain.domainData;
  const { lessons } = lessonData;
  const { archiveLesson, isArchiving } = useArchiveLessonForTeacher();

  const archiveLessonsAndClearSelection = async (lessonsToArchiveUnarchive, archiveFlag, successMessage) => {
    await archiveLesson(lessonsToArchiveUnarchive, archiveFlag, successMessage);
  };

  return (
    <div className='my-lessons-multi-selection-actions'>
      {!lessonData.lessonFilterValue.archived && (
        <ArchiveSelectedLessons
          lessons={lessons}
          archiveLessonFunction={archiveLessonsAndClearSelection}
          isArchiving={isArchiving}
          selectedLessonIds={selectedLessonIds}
        />
      )}

      {lessonData.lessonFilterValue.archived && (
        <UnarchiveSelectedLessons
          currentUser={user}
          lessons={lessons}
          archiveLessonFunction={archiveLessonsAndClearSelection}
          isArchiving={isArchiving}
          selectedLessonIds={selectedLessonIds}
        />
      )}
    </div>
  );
};

MyLessonsMultiSelectionActions.defaultProps = {
  selectedLessonIds: [],
};

MyLessonsMultiSelectionActions.propTypes = {
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
};

export default MyLessonsMultiSelectionActions;

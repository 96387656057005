import { useAdmin } from '../../../context/AdminContextProvider';

const transformDistrictData = (item) => ({
  key: item.id,
  name: item.name,
  value: item.name,
});

export const usePrepareData = () => {
  const { districtsDomain } = useAdmin();
  const { districts, loading } = districtsDomain.domainData;
  const transformDistricts = districts.map((district) => transformDistrictData(district));
  return {
    transformDistricts,
    loading,
  };
};

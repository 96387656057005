/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { Typography } from '@mui/material';
import './UnitGuideSection.scss';

const UnitGuideSection = ({ title, className, content, children }) => (
  <div className={`unit-guite-section ${className}`}>
    {title && (
      <Typography variant='h6' className='unit-guide-section-title'>{title}</Typography>
    )}
    <div className='unit-guide-section-body'>
      {children}
      {content && (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      )}
    </div>
  </div>
);

UnitGuideSection.defaultProps = {
  title: '',
  children: null,
  content: null,
  className: '',
};

UnitGuideSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  className: PropTypes.string,
};

export default UnitGuideSection;

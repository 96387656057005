import { useDomain } from '../../../states/AppDomainProvider';
import Logger from '../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import LessonAssignmentOrdering from '../lessonactions/lesson-multi-selection-actions/educationplan/forms/lessonsassignments/LessonAssignmentOrdering';
import { assignAllStudentsForCourses } from '../../../services/LessonTagService';
import ArchiveOrUnArchiveLessonAction from './actions/ArchiveOrUnArchiveLessonAction';
import DeleteAssignedLessonAction from './actions/DeleteAssignedLessonAction';
import { getStudent, getStudentName } from '../../hooks/StudentHooks';
import DateUtils from '../../../utils/DateUtils';

// const dateFunc = new DateFnsUtils();

export const useAssignedStudentCoursesTable = ({
  onDataLoad,
  assignedStudentCourses,
  selectedDates,
  getAssignedStudentData,
  canArchive,
  onTableActionSuccess,
  canLessonDeleteFunction,
}) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const tableConfig = [
    {
      title: 'Date',
      align: 'center',
      dataField: 'date',
      width: '12%',
    },
    {
      title: 'Student',
      align: 'center',
      dataField: 'userId',
      dataRenderer: (data) => (getStudentName(students, data.userId)),
      width: '25%',
    },
    {
      title: 'Activities',
      align: 'center',
      dataField: 'lessons',
      dataRenderer: (data) => {
        const { lessons, userId, date } = data;
        const student = getStudent(students, userId);
        const savedFormattedDate = DateUtils.convertYYYYMMddToMMddYYYY(date);
        const handleOnDragEnd = async (result) => {
          const assignedStudentCourse = assignedStudentCourses.find((asc) => asc.id === data.id);
          const { lessons: originalLessons } = assignedStudentCourse;
          try {
            callRemoteServiceWrapper(async () => {
              try {
                assignedStudentCourse.lessons = result;
                await onDataLoad(assignedStudentCourses);
                await assignAllStudentsForCourses([data.userId.toString()], false, [savedFormattedDate], result, student.id);
                await uiDomain.showSnackbar(true, 'info', 3000, `Lessons for ${student.userName} on ${data.date} have been updated`);
              } catch (e) {
                // rollback the data
                assignedStudentCourse.lessons = originalLessons;
                await onDataLoad(assignedStudentCourses);
                throw e;
              }
            });
          } catch (e) {
            Logger.logWhenDebugModeIsOn(e);
          }
        };
        // disable the re-ordering
        return <LessonAssignmentOrdering withSash={false} lessons={lessons} onDragEnd={handleOnDragEnd} disabled />;
      },
      width: '45%',
      noSort: true,
    },
    {
      title: canArchive ? 'Archive/Delete' : 'Delete',
      align: 'center',
      dataRenderer: (data) => {
        const student = getStudent(students, data.userId);
        const date = DateUtils.convertYYYYMMddToMMddYYYY(data.date);
        const lessons = (
          <div className='actions-container' style={{ gridTemplateRows: `repeat(${data.lessons.length}, 82px)` }}>
            {
              data.lessons.map((dataLesson, index) => {
                const shouldDisplayDeleteButton = canLessonDeleteFunction(dataLesson);
                if (!shouldDisplayDeleteButton) {
                  return <div />;
                }
                return (
                  <div className='actions' key={`assigned-student-action-${index}-lesson-${dataLesson.id}`}>
                    {canArchive && (
                      <ArchiveOrUnArchiveLessonAction
                        lesson={dataLesson}
                        courseAssignmentId={data.id}
                        student={student}
                        date={date}
                        onArchiveSuccess={() => getAssignedStudentData(selectedDates, false)}
                      />
                    )}
                    <DeleteAssignedLessonAction
                      lesson={dataLesson}
                      courseAssignmentId={data.id}
                      student={student}
                      date={date}
                      onDeleteSuccess={async () => {
                        await getAssignedStudentData(selectedDates, false);
                        onTableActionSuccess();
                      }}
                    />
                  </div>
                );
              })
            }
          </div>
        );
        return lessons;
      },
      width: '20%',
      noSort: true,
    },
  ];

  return {
    tableConfig,
  };
};

/* eslint-disable react/react-in-jsx-scope */
import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URL_MY_STUDENT } from '../../../../../../routes/RouteConstants';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import DateUtils from '../../../../../../utils/DateUtils';
import { getStudent, getStudentName } from '../../../../../hooks/StudentHooks';
import DeleteAssignedLessonAction from '../../../../../lessons/assigned-student-courses/actions/DeleteAssignedLessonAction';
import LessonAssignmentOrdering from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/educationplan/forms/lessonsassignments/LessonAssignmentOrdering';

export const useAssignedStudentCoursesTable = ({
  onTableActionSuccess,
}) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const navigate = useNavigate();

  const tableConfig = [
    {
      title: 'Student',
      align: 'center',
      dataField: 'studentName',
      dataRenderer: (data) => {
        const { userId, studentName } = data;
        const student = getStudent(students, userId);
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            component='button'
            variant='body2'
            onClick={() => {
              navigate(
                URL_MY_STUDENT,
                {
                  state: {
                    student,
                  },
                },
              );
            }}
            className='user-name-link'
            data-private
          >
            {studentName}
          </Link>
        );
      },
      width: '25%',
    },
    {
      title: 'Activities',
      align: 'center',
      dataField: 'lessons',
      dataRenderer: (data) => {
        const { lessons } = data;
        if (!lessons) {
          return <div className='no-assignment'><Typography variant='h6'>No assignments</Typography></div>;
        }
        return <LessonAssignmentOrdering disabled withSash={false} lessons={lessons} />;
      },
      width: '45%',
      noSort: true,
    },
    {
      title: 'Delete',
      align: 'center',
      dataRenderer: (data) => {
        if (!data.lessons) {
          return null;
        }
        const student = getStudent(students, data.userId);
        const date = DateUtils.convertYYYYMMddToMMddYYYY(data.date);
        const lessons = (
          <div className='actions-container' style={{ gridTemplateRows: `repeat(${data.lessons.length}, 82px)` }}>
            {
              data.lessons.map((dataLesson, index) => (
                <div className='actions' key={`assigned-student-action-${index}-lesson-${dataLesson.id}`}>
                  <DeleteAssignedLessonAction
                    lesson={dataLesson}
                    courseAssignmentId={data.id}
                    student={student}
                    date={date}
                    onDeleteSuccess={async () => {
                      onTableActionSuccess();
                    }}
                  />
                </div>
              ))
            }
          </div>
        );
        return lessons;
      },
      width: '20%',
      noSort: true,
    },
  ];

  return {
    tableConfig,
  };
};

export const useGetTableData = ({
  assignedStudentCourses,
}) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const assignedStudentIds = assignedStudentCourses.map((a) => a.userId);
  const assignedStudentIdsUnique = [...new Set(assignedStudentIds)];
  const notAssignedStudents = students.filter((s) => !assignedStudentIdsUnique.includes(s.id));

  const emptyAssignedStudentCourses = notAssignedStudents.map((s) => ({
    userId: s.id,
  }));
  const tableData = [
    ...assignedStudentCourses,
    ...emptyAssignedStudentCourses,
  ];

  const talbeDataWithStudentName = tableData.map((t) => {
    const { userId } = t;
    return ({
      ...t,
      studentName: getStudentName(students, userId),
    });
  });
  return {
    tableData: talbeDataWithStudentName,
  };
};

import { useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { useDomain } from '../../../states/AppDomainProvider';
import { usePrepareLessonData } from './LessonPlayHook';
import LessonActivityPlay from '../components/lesson-activity-play/LessonActivityPlay';
import LessonLoader from '../components/LessonLoader';
import './LessonPlayMain.scss';
import { logout } from '../../../services/LoginService';
import { URL_LOGIN } from '../../../routes/RouteConstants';
import { useLogout } from '../../logout/LogoutHooks';
import LogoutConfirmation from '../../logout/LogoutConfirmation';
import { hideAccessbeChatIconInTimeInterval, showAccessibeChatIcon } from '../../../services/AccessibeService';
import { useUpdateLessonStartIndex } from '../activities/utils/LessonPlayUtils';

/**
 * Play Lesson Action Container
 */
export default function LessonPlayMain() {
  const navigate = useNavigate();

  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const { lessonUuid } = useParams();

  useEffect(() => {
    hideAccessbeChatIconInTimeInterval();
    return () => {
      showAccessibeChatIcon();
    };
  }, []);

  const query = new URLSearchParams(useLocation().search);
  const auth = query.get('auth');
  const isAuth = auth === 'Y';

  const location = useLocation();
  const { state } = location;
  const exitPath = state ? state.exitPath : '';

  const {
    loading,
  } = usePrepareLessonData({
    lessonUuid,
    user,
    userProfile,
    terminologies,
    isPreviewMode: false,
    isAuth,
  });

  const updateLessonStartIndex = useUpdateLessonStartIndex();

  const {
    logoutDialog,
    openLogoutDialog,
    closeLogoutDialog,
  } = useLogout();

  if (auth === 'Y') {
    return (
      <Navigate
        to={URL_LOGIN}
        state={{
          redirecTo: `/lesson/${lessonUuid}`,
        }}
      />
    );
  }

  return (
    <>
      <div className='lesson-play-main'>
        {loading ? (
          <LessonLoader />
        ) : (
          <LessonActivityPlay
            onLessonFinished={async (isLessonComplete) => {
              if (exitPath) {
                navigate(exitPath.startsWith('/') ? exitPath : `/${exitPath}`);
              } else if (user.id) {
                await updateLessonStartIndex(isLessonComplete, lessonUuid, userProfile);
                navigate(-1);
              } else if (isLessonComplete) {
                openLogoutDialog();
              } else {
                logout(!isLessonComplete);
              }
            }}
          />
        )}

      </div>
      <LogoutConfirmation
        open={logoutDialog}
        onClose={closeLogoutDialog}
      />
    </>
  );
}

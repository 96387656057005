import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';
import ShareLinkLessonAction from '../../../../../lessons/lessonactions/ShareLinkLessonAction';
import DropdownAction from '../../../../../lessons/lessonactions/dropdown/DropdownAction';
import CopyToEditAction from '../../../../../lessons/lessonactions/CopyToEditAction';
import PrintLessonMenuItem from '../../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import LessonPlayMenuItem from '../../../../../lessons/lessonactions/dropdown/LessonPlayMenuItem';
import CurriculumBookmarkAction from './CurriculumBookmarkAction';
import TeacherCurriculumAssignLessonsAction from './TeacherCurriculumAssignLessonsAction';
import { isMobile } from '../../../../../../utils/DeviceUtils';
import PlayLessonDetailAction from '../../../../../lessons/lessonactions/PlayLessonDetailAction';
import CurriculumAddRemoveFromGroupPlay from './CurriculumAddRemoveFromGroupPlay';

/**
 * Lesson card detail action
 */
export const createLessonCardDetailAction = (lesson, currentUser, currentUserProfile, terminologies) => {
  const actions = [];

  actions.push(
    (<PreviewLessonAction
      label='Preview'
      lesson={lesson}
      key={`teacher-curriculum-lesson-preview-${lesson.id}`}
      user={currentUser}
      userProfile={currentUserProfile}
      terminologies={terminologies}
    />),
  );

  actions.push(
    (<CurriculumBookmarkAction
      lesson={lesson}
      showLabel
      key={`teacher-curriculum-lesson-bookmark-${lesson.id}`}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`teacher-curriculum-lesson-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<CurriculumAddRemoveFromGroupPlay
      lesson={lesson}
      key={`teacher-curriculum-add-remove-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    <LessonPlayMenuItem
      key={`teacher-curriculum-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      recordDataTo={currentUser.userName}
      user={currentUser}
      userProfile={currentUserProfile}
      terminologies={terminologies}
    />,
  ];
  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`teacher-curriculum-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={currentUserProfile}
      />
    ));
  }

  actions.push(
    (
      <DropdownAction
        key={`teacher-curriculum-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  actions.push(
    (
      <CopyToEditAction
        lesson={lesson}
        currentUser={currentUser}
        key={`teacher-curriculum-copy-to-edit-${lesson.id}`}
        fromStudentId={currentUser.id}
      />
    ),
  );

  actions.push(
    (
      <TeacherCurriculumAssignLessonsAction
        lessons={[lesson]}
        selectedLessonIds={[lesson.id]}
        key={`teacher-curriculum-assign-to-others-${lesson.id}`}
        isAssignAutoTag={false}
      />
    ),
  );

  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, user, userProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`teacher-curriculum-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import SortableTable from './SortableTable';
import './SearchAndSortableTable.scss';
import { useHandleFilter, useHandleTableAction, usePerformFilter } from './SearchAndSortableTableHooks';

const SearchAndSortableTable = forwardRef(({
  containerClassName,
  className,
  config,
  tableData,
  onTableSort,
  onTableStateChange,
  initTableState,
  searchPlaceholder,
  searchField,
  searchFunction,
  toolbarActions,
  dataId,
  selectedRowIds,
  onSelectedRowIds,
  preToolbarAction,
  loading,
  labelRowsPerPage,
  placeHolderData,
  rowsPerPageOptions,
  noResultMessage,
  noDataMessage,
  filterable,
  autoResetTableState,
}, ref) => {
  const {
    keyword,
    handleTextChange,
    handleClearKeywordClick,
  } = useHandleFilter();

  const {
    internalTableData,
  } = usePerformFilter({
    keyword,
    searchField,
    searchFunction,
    tableData,
  });

  const {
    handleSingleClick,
    handleDoubleClicks,
    resetLastShiftSelectedRowId,
  } = useHandleTableAction({
    internalTableData,
    dataId,
    selectedRowIds,
    onSelectedRowIds,
  });

  return (
    <div className={`search-and-sort-table ${containerClassName}`} data-test={`table-${containerClassName}`}>
      <div className='table-toolbar'>
        {preToolbarAction && preToolbarAction.length > 0
          ? (preToolbarAction.map((item) => item))
          : null}

        {filterable && (
          <div className='keyword-input'>
            <FilterListIcon />
            <Input
              type='text'
              placeholder={searchPlaceholder}
              onChange={handleTextChange}
              fullWidth
              name='keyword'
              value={keyword || ''}
              className='input-field'
              data-test={`${containerClassName}-filter`}
              endAdornment={
                keyword ? (
                  <InputAdornment position='end' className='clear-keyword-button'>
                    <IconButton
                      aria-label='Clear keyword'
                      onClick={handleClearKeywordClick}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              inputProps={{
                readOnly: loading || tableData.length === 0,
                'data-private': 'redact',
              }}
            />
          </div>
        )}
        {toolbarActions.map((action) => action)}
      </div>

      {noResultMessage && internalTableData.length === 0 && keyword && (
        <div className='no-result-found'>
          {noResultMessage}
        </div>
      )}
      {noDataMessage && tableData.length === 0 && (
        <div className='no-result-found'>
          {noDataMessage}
        </div>
      )}

      {!filterable && (<div className='gap' />)}
      <div className='sortable-table-container'>
        <SortableTable
          className={`search-and-sortable-table-container ${className}`}
          config={config}
          tableData={internalTableData}
          onTableSort={onTableSort}
          onTableStateChange={(newState) => {
            resetLastShiftSelectedRowId();
            onTableStateChange(newState);
          }}
          initTableState={initTableState}
          onRowClick={handleSingleClick}
          onRowDoubleClicks={handleDoubleClicks}
          dataId={dataId}
          selectedRowIds={selectedRowIds}
          loading={loading}
          labelRowsPerPage={labelRowsPerPage}
          placeHolderData={placeHolderData}
          rowsPerPageOptions={rowsPerPageOptions}
          ref={ref}
          autoResetTableState={autoResetTableState}
        />
      </div>
    </div>
  );
});

SearchAndSortableTable.defaultProps = {
  containerClassName: '',
  className: '',
  tableData: [],
  config: [],
  onTableSort: () => { },
  onTableStateChange: () => { },
  initTableState: {
    page: 0,
    order: 'asc',
    orderBy: '',
  },
  searchPlaceholder: 'Filter by keyword',
  searchField: null,
  searchFunction: null,
  toolbarActions: [],
  dataId: 'id',
  selectedRowIds: [],
  onSelectedRowIds: null,
  preToolbarAction: [],
  loading: false,
  labelRowsPerPage: undefined,
  placeHolderData: undefined,
  rowsPerPageOptions: undefined,
  // No result when search
  noResultMessage: null,
  // No data supplied to the table
  noDataMessage: null,
  filterable: true,
  autoResetTableState: true,
};

SearchAndSortableTable.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    dataRenderer: PropTypes.func,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    noSort: PropTypes.bool,
  })),
  onTableSort: PropTypes.func,
  onTableStateChange: PropTypes.func,
  initTableState: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  searchPlaceholder: PropTypes.string,
  searchField: PropTypes.string,
  searchFunction: PropTypes.func,
  toolbarActions: PropTypes.arrayOf(PropTypes.node),
  // eslint-disable-next-line react/require-default-props
  dataId: PropTypes.any,
  selectedRowIds: PropTypes.array,
  onSelectedRowIds: PropTypes.func,
  preToolbarAction: PropTypes.arrayOf(PropTypes.node),
  loading: PropTypes.bool,
  labelRowsPerPage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  placeHolderData: PropTypes.any,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  noResultMessage: PropTypes.string,
  noDataMessage: PropTypes.string,
  filterable: PropTypes.bool,
  autoResetTableState: PropTypes.bool,
};

export default SearchAndSortableTable;

/* eslint-disable react/jsx-boolean-value */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonWithSpinner from '../buttons/ButtonWithSpinner';
import './CustomDialog.scss';
import LiveChatService from '../../services/LiveChatService';

/**
 * Dialog component.
 */
export default function CustomDialog({
  openDialog,
  className,
  onClose,
  title,
  content,
  displayCloseIcon,
  actions,
  onEntered,
  onExisting,
  fullScreen,
}) {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (openDialog) {
      LiveChatService.hideLiveChatIcon();
    } else {
      LiveChatService.showLiveChatIcon();
    }
    return () => {
      LiveChatService.showLiveChatIcon();
      onExisting();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={openDialog}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose(e, reason);
      }}
      maxWidth='md'
      fullWidth={false}
      fullScreen={fullScreen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={`custom-dialog-component ${className}`}
      ref={dialogRef}
      data-test='custom-dialog-modal'
      TransitionProps={{
        onEntered: (e) => {
          /* Fix the issues when there are multiple popups on the screen, the input fields
           * on the subsequenc dialogs does not work
          */
          dialogRef.current.querySelector('.MuiDialog-container').removeAttribute('tabindex');
          onEntered(e);
        },
        onExisting,
      }}
    >
      {title || displayCloseIcon ? (
        <DialogTitle data-test='custom-dialog-title' onClose={onClose} className='custom-dialog-title'>
          {title}
          {displayCloseIcon ? <IconButton data-test='custom-dialog-close-icon' aria-label='close' className='custom-dialog-icon-button' onClick={onClose}><CloseIcon /></IconButton> : ''}
        </DialogTitle>
      ) : null}

      <DialogContent className='custom-dialog-content' data-test='custom-dialog-content'>
        {content}
      </DialogContent>
      {
        actions && actions.length > 0 ? (
          <DialogActions className='vizzle-custom-dialog-actions'>
            {actions.map((action, index) => (
              action.isCustomComponent ? action.component : (
                <ButtonWithSpinner
                  key={`custom-dialog-action-${index}`}
                  onClick={action.onClick}
                  color={action.color}
                  className={action.className}
                  label={action.title}
                  disabled={action.disabled}
                  loading={action.loading}
                  ref={action.ref}
                  data-test={`custom-dialog-actions-${index}`}
                />
              )
            ))}
          </DialogActions>
        ) : null
      }

    </Dialog>
  );
}

CustomDialog.defaultProps = {
  openDialog: false,
  onClose: () => { },
  title: '',
  displayCloseIcon: true,
  actions: null,
  className: '',
  onEntered: () => null,
  onExisting: () => null,
  fullScreen: false,
};

CustomDialog.propTypes = {
  openDialog: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  displayCloseIcon: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    ref: PropTypes.object,
    isCustomComponent: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })),
  className: PropTypes.string,
  onEntered: PropTypes.func,
  onExisting: PropTypes.func,
  fullScreen: PropTypes.bool,
};

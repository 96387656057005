/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Popper,
  Button,
  InputBase,
  Divider,
  CircularProgress,
  ClickAwayListener,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { v1 as uuidv1 } from 'uuid';
import './FilterableMultiSelector.scss';
import ObjectUtils from '../../utils/ObjectUtils';

const FilterableMultiSelector = ({
  label,
  subTitle,
  initFilterKeyValues,
  options,
  className,
  onApply,
  filterable,
  disabled,
  noOptionsText,
  popperClassName,
  isMultiple,
  loading,
  ableToUnselectedInSingleMode,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(isMultiple ? [] : null);
  const [pendingValue, setPendingValue] = useState(isMultiple ? [] : null);
  const filterText = useRef('');
  useEffect(() => {
    if (initFilterKeyValues && initFilterKeyValues.length > 0) {
      const selectedOptions = initFilterKeyValues.map((key) => (
        options.find((option) => option.key === key)
      ));
      setPendingValue(selectedOptions);
      setValue(selectedOptions);
    } else {
      setPendingValue(null);
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFilterKeyValues]);

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }
    filterText.current = '';
    setAnchorEl(null);
  };

  const handleOnApply = () => {
    setValue(pendingValue);
    handleClose();
    onApply(!isMultiple && Array.isArray(pendingValue) && pendingValue.length > 0 ? pendingValue[0] : pendingValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? uuidv1() : undefined;

  return (
    <>
      <div className={`${className} filterable-multi-selector`} data-test={`filterable-multi-selector-${className}`}>
        <Button
          disableRipple
          className='filter-button'
          aria-describedby={id}
          onClick={handleClick}
          variant='outlined'
          disabled={disabled}
        >
          {value && value.length > 0 ? (
            <span className='button-label' data-private>
              {
                value.map((v) => v.name).join(', ')
              }
            </span>
          ) : (
            <span className='button-label'>{loading ? (<div className='loading-filter'><CircularProgress className='loading-icon' /></div>) : label}</span>
          )}

          <Divider orientation='vertical' flexItem className='divider' />
          <KeyboardArrowDownIcon className='filter-button-icon' />
        </Button>
      </div>

      <ClickAwayListener
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
        onClickAway={handleClose}
      >
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement='bottom-start'
          className={`filterable-multi-select-popper ${popperClassName}`}
        >
          {subTitle ? (
            <div className='header'>{subTitle}</div>
          ) : null}
          <Autocomplete
            className='autocomplete-container'
            open
            clearOnBlur={false}
            onClose={handleClose}
            multiple={isMultiple}
            value={pendingValue || []}
            onChange={(event, newValue) => {
              setPendingValue(newValue);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText={noOptionsText}
            filterOptions={(optionsFilter) => {
              const filtered = optionsFilter.filter((option) => !option.hidden && option.name.toLowerCase().indexOf(filterText.current.toLowerCase()) >= 0);
              return filtered;
            }}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                key={`option-${option.key}`}
              >
                <div
                  className='row-entry'
                  onClick={() => {
                    if (ableToUnselectedInSingleMode && !isMultiple && selected) {
                      // Need to be in timeout
                      ObjectUtils.setTimeout(() => {
                        setPendingValue(null);
                      });
                    }
                  }}
                  key={`option-${option.key}`}
                >
                  {
                    selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                  }
                  <div
                    className='text'
                    data-private
                  >
                    {option.name}
                  </div>
                </div>
              </li>
            )}
            options={options}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => (option.length > 0 ? option[0].name : '')}
            isOptionEqualToValue={(option, v) => (v.length > 0 ? option.key === v[0].key : option.key === v.key)}
            renderInput={(params) => {
              // Fix error from the library "Uncaught TypeError: Cannot read properties of null (reading 'length')"
              if (params.inputProps.value) {
                // eslint-disable-next-line no-param-reassign
                params.inputProps.value = filterText.current;
              } else {
                // eslint-disable-next-line no-param-reassign
                params.inputProps.value = null;
              }

              return (
                <>
                  <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    className={`input-base ${!filterable ? 'hidden' : ''}`}
                    data-private
                    onChange={(e) => {
                      filterText.current = e.target.value;
                    }}
                    name='filter-text-input'
                  />
                  <div className='actions'>
                    <Button className='cancel' onClick={handleClose} data-test='filterable-multi-selector-cancel-button'>
                      Cancel
                    </Button>
                    <Button className='apply' onClick={handleOnApply} data-test='filterable-multi-selector-apply-button'>
                      Apply
                    </Button>
                  </div>
                </>
              );
            }}
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

FilterableMultiSelector.defaultProps = {
  label: '',
  subTitle: '',
  initFilterKeyValues: [],
  options: [],
  className: '',
  onApply: () => { },
  filterable: true,
  disabled: false,
  noOptionsText: 'Search not matched',
  popperClassName: '',
  isMultiple: true,
  loading: false,
  ableToUnselectedInSingleMode: false,
};

FilterableMultiSelector.propTypes = {
  label: PropTypes.string,
  subTitle: PropTypes.string,
  initFilterKeyValues: PropTypes.arrayOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    hidden: PropTypes.any,
    group: PropTypes.any,
  })),
  className: PropTypes.string,
  onApply: PropTypes.func,
  filterable: PropTypes.bool,
  disabled: PropTypes.bool,
  noOptionsText: PropTypes.string,
  popperClassName: PropTypes.string,
  isMultiple: PropTypes.bool,
  loading: PropTypes.bool,
  ableToUnselectedInSingleMode: PropTypes.bool,
};

export default FilterableMultiSelector;

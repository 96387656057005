import { useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';

import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import './ExtensionActivitiesAction.scss';
import { useDownloadFileAsBlob } from '../../../../../../../hooks/DownloadFileAsBlob';
import ObjectUtils from '../../../../../../../../utils/ObjectUtils';

export default function DownloadFileAction({ activity }) {
  const blobRef = useRef(null);
  const { loading, downloadFileToDataBlob } = useDownloadFileAsBlob();
  return (
    <Button
      className='extension-activities-view-action'
      spinningClass='button-spinning'
      startIcon={loading ? <CircularProgress size={25} /> : <DownloadIcon />}
      variant='text'
      disabled={loading}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (blobRef.current) {
          ObjectUtils.downloadFileFromData(blobRef.current, activity.name);
        } else {
          const blob = await downloadFileToDataBlob(activity.url);
          blobRef.current = blob;
          ObjectUtils.downloadFileFromData(blob, activity.name);
        }
      }}
      data-test={`extension-activity-download-${activity.id}`}
    >
      Download
    </Button>
  );
}

DownloadFileAction.propTypes = {
  activity: PropTypes.object.isRequired,
};

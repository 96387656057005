export const isTexasTeacher = (user) => (
  user && user.state === 'TX'
);

export const isFloridaTeacher = (user) => (
  user && user.state === 'FL'
);

export const isTeacher = (user) => (
  user && user.userType === 'Teacher'
);

export const isStudent = (user) => (
  user && user.userType === 'Student'
);

export const isMfaUser = (user) => (
  user && (user.userSuperAdmin || user.userModerator || user.userModeratorWithDirectAccess)
);

export const isPaidAccount = (user) => (
  user && user.isDistrictPaidCustomer
);

export const USER_COMPONENT_ACADEMIC = '35';
export const USER_COMPONENT_SKILL_SUITE = '36';

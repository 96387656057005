/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { Fade, Typography } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { useGetLastLoggedIn, useGetTeacherLabel } from '../AdminDataHooks';
import './TeacherActivityReport.scss';
import { useAdmin } from '../../../context/AdminContextProvider';
import PieChartRing from './charts/PieChartRing';
import PieChart from './charts/PieChart';
import LineChart from './charts/LineChart';
import { URL_ACTIVITY_REPORT_STUDENTS, URL_ACTIVITY_REPORT_TEACHERS } from '../AdminDataConstants';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useDomain } from '../../../../../states/AppDomainProvider';
import DateUtils from '../../../../../utils/DateUtils';

const createSubjectObjects = (data, attributeName) => {
  if (!data || !data[attributeName]) {
    return [];
  }
  const keys = Object.keys(data[attributeName]);
  const result = [];
  keys.forEach((k) => {
    const value = data[attributeName][k];
    if (value > 0) {
      result.push({
        name: k,
        y: data[attributeName][k],
      });
    }
  });
  return result;
};

const TeacherActivityReport = () => {
  const {
    getTeacher,
    getTeacherLabelOrName,
  } = useGetTeacherLabel();

  const { adminDataDomain } = useAdmin();
  const {
    isActivityReportLoading,
    activityReports,
    selectedTeacherId,
    searchCriteria,
  } = adminDataDomain.domainData;

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  const data = activityReports && activityReports.attributes;
  const navigate = useNavigate();

  const charts = useMemo(() => {
    const result = {};
    result.studentsByActivityChart = (
      <PieChartRing
        title='Students Playing Lessons'
        dataNumber={data && data.studentsByActivity && data.studentsByActivity.numberOfStudentsWithActivity}
        innerLabel='STUDENTS'
        colors={['#66B767']}
        onClick={data && data.studentsByActivity && data.studentsByActivity.students && data.studentsByActivity.students.length > 0 ? () => {
          navigate(URL_ACTIVITY_REPORT_STUDENTS);
        } : null}
      />
    );

    // Show this graph when the user select All Teachers
    if (!selectedTeacherId) {
      const { teachersLoggingIn, teachersWithoutLogins } = searchCriteria;
      let title = 'Teachers Logging in';
      let colors = ['#66B767'];
      let dataNumber = data && data.teachersByActivity && data.teachersByActivity.numberOfTeachersWithActivity;

      if (!teachersLoggingIn && teachersWithoutLogins) {
        title = 'Teachers Without Logins';
        colors = ['#ED1C24'];
        dataNumber = data && data.teachersByActivity && data.teachersByActivity.numberOfTeachersWithoutActivity;
      }

      result.teachersByActivityChart = (
        <PieChartRing
          title={title}
          dataNumber={dataNumber}
          innerLabel='TEACHERS'
          colors={colors}
          // eslint-disable-next-line max-len
          onClick={(teachersLoggingIn || (!teachersLoggingIn && !teachersWithoutLogins)) && data && data.teachersByActivity && data.teachersByActivity.teacherSummary && data.teachersByActivity.teacherSummary.length > 0 ? () => {
            navigate(URL_ACTIVITY_REPORT_TEACHERS);
          } : null}
        />
      );
    }

    const activitiesByCoreSubjectsData = createSubjectObjects(data, 'activitiesByCoreSubjects');
    result.activityByCoreSubjectsChart = (
      <PieChart
        title='Plays by Core Subjects'
        data={activitiesByCoreSubjectsData}
        colors={['#EF8C26', '#63ACDF', '#7AC17D', '#F36575']}
      />
    );

    const activitiesByOtherSubjectsData = createSubjectObjects(data, 'activitiesByOtherSubjects');
    result.activitiesByOtherSubjectsChart = (
      <PieChart
        title='Plays by Other Subjects'
        data={activitiesByOtherSubjectsData}
        colors={['#EF8C26', '#63ACDF', '#7AC17D', '#F36575']}
      />
    );

    const categories = [];
    const categoriesData = [];
    const cummulativeActivities = (data && data.cumulativeActivities) || [];
    cummulativeActivities.forEach((c) => {
      categories.push(c.month);
      categoriesData.push(c.count);
    });

    let lessonPlayTitle = 'All Activity Plays';
    if (data && data.cumulativeActivities && data.cumulativeActivities.length > 0) {
      const firstMonth = data.cumulativeActivities[0].month;
      const lastMonth = data.cumulativeActivities[data.cumulativeActivities.length - 1].month;

      const lastDate = DateUtils.parseDate(lastMonth, 'MMM');
      const lastDay = lastDayOfMonth(lastDate);
      lessonPlayTitle = `${lessonPlayTitle} ${firstMonth} 1 - ${lastMonth} ${lastDay.getDate()}`;
    }

    result.cummulativeActivitiesChart = (
      <LineChart
        title={lessonPlayTitle}
        data={categoriesData}
        categories={categories}
        plotColor='#C87B40'
      />
    );

    let values = [];
    if (data) {
      if (data.activitiesByCoreSubjects) {
        values = Object.values(data.activitiesByCoreSubjects);
      }
      if (data.activitiesByOtherSubjects) {
        values = [...values, ...Object.values(data.activitiesByOtherSubjects)];
      }
    }

    const total = values.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    result.totalPlayChart = (
      <PieChartRing
        title='Total Plays'
        dataNumber={total}
        // innerLabel=''
        colors={['#ee7b22']}
      />
    );

    return result;
  }, [activityReports]);

  const lastLoggedInMessage = useGetLastLoggedIn();
  const teacher = getTeacher();
  const hasActiveLicense = !selectedTeacherId ? true : selectedTeacherId && teacher && teacher.meta.hasActiveLicense;

  if (userSuperAdmin && (!searchCriteria || !searchCriteria.districtIds || searchCriteria.districtIds.length === 0)) {
    return <NoLessonPaper message='Please select a district' />;
  }
  return (
    <Fade timeout={1000} in>
      <div className='teacher-activity-report'>
        <div className='teacher-activity-report-inner'>
          <div className='teacher-activity-report-inner-container'>
            <Typography variant='h4' className='teacher-name-label'>{getTeacherLabelOrName()}</Typography>
            {lastLoggedInMessage && !isActivityReportLoading && (
              <Typography variant='subtitle1' className='teacher-last-logged-in'>{lastLoggedInMessage}</Typography>
            )}
          </div>
          {!hasActiveLicense && teacher && (
            <div className='no-active-license'>
              No Active License in effect
            </div>
          )}

          {isActivityReportLoading ? (
            <div className='teacher-activity-report-loading'>
              <ScaleLoader
                css={`
                margin-bottom: 10px;
    `}
                color='#ee7b22'
                height={50}
                width={6}
                radius={5}
                margin={3}
              />
              <Typography variant='h6'>Loading Activity Report</Typography>
            </div>
          ) : (
            <div className='graphs-container'>
              <div className='graphs-container-row'>
                {charts.teachersByActivityChart}
                {charts.studentsByActivityChart}
                {charts.cummulativeActivitiesChart}
              </div>
              <br /><br />
              <div className='graphs-container-row left'>
                {charts.activityByCoreSubjectsChart}
                {charts.activitiesByOtherSubjectsChart}
                {charts.totalPlayChart}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default TeacherActivityReport;

import { useImperativeHandle, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card, CardContent,
} from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import './FreeTextResponseActivity.scss';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import FreeTextResponseTabs from './FreeTextResponseTabs';
import { isCardEmpty } from '../../../../../utils/activitytype/ActivityTypeEmptyChecker';
import CardActivity from '../card-activity/CardActivity';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import { useHandleDialog, useHandleFreeTextData, useHandleSubmitFreeTextAnswer } from './FreeTextResponseActivityHooks';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { sort } from '../../../../../utils/ArrayUtils';
import FfImageList from './components/FfImageList';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import { CUSTOM_RESPONSE_OPTIONS } from '../../../../../components/vizzle/page/book/components/CustomResponseConstants';

/**
 * Editable Matching responses
 */
const FreeTextResponseActivity = forwardRef(({
  responseCardId,
  responseCard,
  onChange,
  onClearAll,
  className,
  onCorrectAnswerSelected,
  cardNumber,
  registeredLessonRef,
  studentHistoryId,
  onActivityFinished,
}, ref) => {
  const {
    open,
    handleOnCardClick,
    openDialog,
    closeDialog,
  } = useHandleDialog({
    responseCard,
  });

  const {
    handleOnSubmit,
    setIsDirty,
    shouldShowSubmitButton,
    saving,
    isPreviewMode,
    user,
  } = useHandleSubmitFreeTextAnswer({
    responseCard,
    cardNumber,
    onCorrectAnswerSelected,
    onActivityFinished,
    registeredLessonRef,
    studentHistoryId,
  });

  const {
    freeTextData,
    handleOnImageChange,
    handleOnTextChange,
    handleOnApplyFreeTextData,
    handleOnFfImageChange,
  } = useHandleFreeTextData({
    open,
    responseCard,
    onChange,
    closeDialog,
    setIsDirty,
  });

  const componentRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEnableElements: () => [componentRef.current],
  }));

  const getCardContent = () => {
    if (isCardEmpty(responseCard) && (!responseCard.ffImages || responseCard.ffImages.length === 0) && !responseCard.drawingImage && !responseCard.image) {
      return null;
    }

    if (responseCard.ffImages && responseCard.ffImages.length > 0) {
      const sortFfImages = sort(responseCard.ffImages, 'index').map((i) => i && i.url);
      return (
        <Box
          width='100%'
          height='100%'
          onClick={() => {
            openDialog();
          }}
        >
          <FfImageList imageList={sortFfImages} />
        </Box>
      );
    }
    return (
      <CardActivity
        id={responseCardId}
        text={responseCard.text}
        image={responseCard.image}
        onClick={() => {
          openDialog();
        }}
        enableTts={false}
        drawingImage={responseCard.drawingImage}
      />
    );
  };

  const displayImagesSelector = [Number(CUSTOM_RESPONSE_OPTIONS[0].id)].includes(Number(responseCard.customResponseOption));
  return (
    <>
      <Card
        className={`free-text-response-activity-card ${className}`}
        onClick={handleOnCardClick}
        key={`free-text-response-activity-card-${uuidv1()}`}
        ref={componentRef}
        data-skip-switch-access-click='true'
      >
        <CardContent className='content'>
          <NoLessonPaper className='' message='Click/Tap to add response' />
          {getCardContent()}
          {shouldShowSubmitButton && (
            <div className='free-text-response-actions-container'>
              <ButtonWithSpinner
                className='btn-green-background submit-button'
                label='Submit'
                onClick={handleOnSubmit}
                loading={saving}
                data-test='custom-response-submit-action'
              />
              <ButtonWithSpinner
                className='btn-danger clear-all-button'
                label='Clear All'
                onClick={onClearAll}
                data-test='custom-response-clear-all-action'
              />
            </div>
          )}
        </CardContent>
      </Card>
      {open && (
        <CustomDialog
          className={`free-text-response-popup-dialog ${displayImagesSelector ? 'dialog-full-height' : ''}`}
          title={(
            responseCard.freetextInstructions
              ? <div data-test='free-text-response-popup-dialog-title' className='free-text-response-popup-dialog-title'><TextLines textLines={responseCard.freetextInstructions} /></div>
              : 'Submit your own answer.'
          )}
          openDialog={open}
          onClose={closeDialog}
          content={(
            <FreeTextResponseTabs
              card={responseCard}
              onChange={async () => {
                setIsDirty(true);
                await onChange();
                closeDialog();
              }}
              onImageChange={handleOnImageChange}
              onTextChange={handleOnTextChange}
              onFfImageChange={handleOnFfImageChange}
              isPreviewMode={isPreviewMode}
              user={user}
            />
          )}
          displayCloseIcon
          actions={[{
            onClick: handleOnApplyFreeTextData,
            className: 'btn-green-background action-button submit-button',
            title: 'Apply',
            disabled: !freeTextData.isDirty,
          },
          {
            onClick: closeDialog,
            className: 'btn-danger action-button',
            title: 'Cancel',
          },
          ]}
        />
      )}
    </>
  );
});

FreeTextResponseActivity.defaultProps = {
  responseCardId: '',
  responseCard: {},
  onChange: () => null,
  onClearAll: () => null,
  className: null,
  onCorrectAnswerSelected: () => { },
  cardNumber: null,
  registeredLessonRef: null,
  studentHistoryId: null,
  onActivityFinished: () => null,
};

FreeTextResponseActivity.propTypes = {
  responseCardId: PropTypes.string,
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    ffImages: PropTypes.array,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    drawingImage: PropTypes.string,
    freetextInstructions: PropTypes.object,
    customResponseOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func,
  onClearAll: PropTypes.func,
  className: PropTypes.string,
  onCorrectAnswerSelected: PropTypes.func,
  cardNumber: PropTypes.number,
  registeredLessonRef: PropTypes.any,
  studentHistoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onActivityFinished: PropTypes.func,
};

export default FreeTextResponseActivity;

import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import FillInTheBlankPageEditor from '../../../../../components/vizzle/page/fitb/page/FillInTheBlankPageEditor';
import AddFillInTheBlank from './editor/AddFillInTheBlank';
import { ADD_FITB, SECTION_AUDIO, SECTION_IMAGE } from '../../../../../components/vizzle/page/common/CardEditorConstants';

/**
 * Container for adding text, image, video and audio for Matching page
 */
export default function FillInTheBlankPageTextAndMedia() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const initState = {
    currentSelectedCard: 0,
    selectedOption: SECTION_IMAGE,
    selectedSection: 'image',
  };

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const createCardPreview = () => {
    const cardConfig = selectedPage.cards.filter((card) => (
      !card.hide
    )).map((card) => (
      {
        card: {
          image: card.image,
          audio: card.audio,
          video: card.video,
        },
        fitbList: card.fitbList,
      }
    ));

    const props = {
      cardConfig,
      selectedPageIndex: currentSelectedData.currentSelectedCard,
      selectedSection: currentSelectedData.selectedSection,
      onImageSelected: (cardIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedCard: cardIndex,
          selectedSection: section,
        });
      },
      onAudioSelected: (cardIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedCard: cardIndex,
          selectedSection: section,
        });
      },
      onFitbSelected: (cardIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedCard: cardIndex,
          selectedSection: section,
        });
      },
    };

    return (
      <FillInTheBlankPageEditor {...props} />
    );
  };

  useEffect(() => {
    // When the user select a new page, reset the current selected book to the first one
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  const onAudioApply = async (audioData) => {
    selectedPage.cards[currentSelectedData.currentSelectedCard].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    selectedPage.cards[currentSelectedData.currentSelectedCard].audio = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageAndVideoSelected = async (media) => {
    // update image to the lesson
    selectedPage.cards[currentSelectedData.currentSelectedCard].image = media.selectedImageUrl;

    // update video to the lesson
    selectedPage.cards[currentSelectedData.currentSelectedCard].video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    const card = {
      ...selectedPage.card,
      image: null,
      video: null,
    };
    selectedPage.cards[currentSelectedData.currentSelectedCard] = card;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onFitbAdd = (newData) => {
    selectedPage.cards[currentSelectedData.currentSelectedCard].fitbList.push(newData);
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onFitbUpdate = (newData, rowIndex) => {
    selectedPage.cards[currentSelectedData.currentSelectedCard].fitbList[rowIndex] = newData;
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onFitbRemoved = (removedIndex) => {
    selectedPage.fitbList = selectedPage.fitbList.filter((_d, index) => index !== removedIndex);
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <div>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '69vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          {createCardPreview()}
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', overflow: 'hidden', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_IMAGE && (
              <AddPhotoOrVideo
                image={selectedPage.cards[currentSelectedData.currentSelectedCard].image}
                onSelect={onImageAndVideoSelected}
                onRemove={onImageRemove}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={selectedPage.cards[currentSelectedData.currentSelectedCard].audio}
                onAudioApply={onAudioApply}
                onAudioRemoved={onAudioRemoved}
              />
            )}
            {currentSelectedData.selectedSection === ADD_FITB && (
              <AddFillInTheBlank
                fitbList={selectedPage.cards[currentSelectedData.currentSelectedCard].fitbList}
                onFitbAdd={onFitbAdd}
                onFitbUpdate={onFitbUpdate}
                onFitbRemoved={onFitbRemoved}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

import { useReducer } from 'react';
import {
  setVizzleAuthoringWebItem,
  getVizzleAuthoringWebItem,
} from '../../utils/VizzleSessionStorage';
import Logger from '../../utils/Logger';

const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';

/** Middle of the reducer
 * @param domainName the domain name of the reducer
 * @param oldState stae before an action is applied
 * @param action an action to change the old state
 * @param newState the state after the action is applied
 */
const middleWare = (domainName, oldState, action, newState, _saveToLocalStorage) => {
  if (_saveToLocalStorage) {
    setVizzleAuthoringWebItem(domainName, newState);
  }
  Logger.logWhenDebugModeIsOn({
    DOMAIN: domainName,
    OLD_STATE: oldState,
    ACTION: action,
    NEW_STATE: newState,
  });
};

/**
 * Create a proxy reduer to inject common action and middlewares
 *
 * @param domainName the domain name of the reducer
 * @param _reducer reducer to create
 *
 * @returns proxy reducer
 */
const createProxyReducer = (domainName, _reducer, _initialState, _saveToLocalStorage) => {
  const proxyReducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case RESET_TO_INITIAL_STATE:
        middleWare(domainName, state, action, _initialState);
        return JSON.parse(JSON.stringify(_initialState));
    }

    const newState = _reducer(state, action);
    middleWare(domainName, state, action, newState, _saveToLocalStorage);

    return (newState);
  };
  return proxyReducer;
};

/**
 * Abstract class for creating domain object of the application.
 * DO NOT USE THIS CLASS DIRECTLY
 *
 * Extend this class and provide
 * 1. domain name
 * 2. initial state
 * 3. reducer
 *
 * @export
 * @class BaseDomain
 */
export default class {
  constructor(_domainName, _initialState, _reducer, _saveToLocalStorage = false) {
    this.domainName = _domainName;
    this.initialState = _initialState;
    const proxyReducer = createProxyReducer(this.domainName, _reducer, _initialState, _saveToLocalStorage);
    this.reducer = proxyReducer;
  }

  /**
   * To initial react hook reducer, it needs to run inside a compnent
   */
  init() {
    const initialStateFromMemory = getVizzleAuthoringWebItem(this.domainName);
    const initialStateToUse = initialStateFromMemory || this.initialState;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const reducerInstance = useReducer(this.reducer, initialStateToUse);
    const [reducerData, dispatch] = reducerInstance;
    this.dispatch = dispatch;
    this.reducerData = reducerData;
  }

  async dispatch(dispatchAction) {
    return this.dispatch(dispatchAction);
  }

  get domainData() {
    return this.reducerData;
  }

  set domainData(_reducerData) {
    this.reducerData = _reducerData;
  }

  resetDomainData() {
    this.dispatch({
      type: RESET_TO_INITIAL_STATE,
    });
  }
}

/**
 * Updating one item in an array
 *
 * @export
 * @param {*} array array to update
 * @param {*} indexToUpdate index to update
 * @param {*} newItem new item
 * @returns updated array
 */
export function updateObjectInArray(array, indexToUpdate, newItem) {
  return array.map((item, index) => {
    if (index !== indexToUpdate) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...newItem,
    };
  });
}

/**
 * Insert a new object into the middle of an array
 *
 * @export
 * @param {*} array array to update
 * @param {*} index index to insert the new item
 * @param {*} item item to insert
 */
export function insert(array, index, item) {
  array.splice(index, 0, item);
  return array;
}

/**
 * Move an item in the list
 *
 * @export
 * @param {*} list list ot reorder
 * @param {*} startIndex index of item to move
 * @param {*} endIndex target index of the item to move to
 * @returns
 */
export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

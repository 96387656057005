import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ObjectiveDetail from '../cardlistdetail/objective/ObjectiveDetail';
import './ObjectiveDetailPrint.scss';

const ObjectiveDetailPrint = forwardRef(({
  objective,
  included,
  currentUser,
}, ref) => (
  <div className='print-objective-detail-container' ref={ref}>
    <div className='name'>
      {currentUser.firstName} {currentUser.lastName}
    </div>
    <ObjectiveDetail objective={objective} included={included} />
  </div>
));

ObjectiveDetailPrint.defaultProps = {
  included: [],
};

ObjectiveDetailPrint.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  included: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    attributes: PropTypes.object,
  })),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default ObjectiveDetailPrint;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Logger from '../../utils/Logger';
import './SingleFieldFormComponent.scss';
/**
 * Lesson title form input
 *
 * @returns
 */
export default function SingleFieldForm({
  handleSubmit,
  formData,
  label,
  maxLength,
  ...rest
}) {
  const [data, setData] = useState({ formData: '' });

  useEffect(() => {
    setData({
      formData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleError = (errors) => {
    Logger.logWhenDebugModeIsOn(errors);
  };

  const handleChange = (prop) => (event) => {
    setData({
      [prop]: event.target.value.substring(0, maxLength),
    });
  };

  const handleSave = () => {
    handleSubmit(data.formData);
  };

  return (
    <ValidatorForm
      {...rest}
      onSubmit={handleSave}
      onError={handleError}
      className='single-field-form'
    >
      <TextValidator
        label={label}
        onChange={handleChange('formData')}
        autoFocus
        name='title'
        value={data.formData}
        validators={['required']}
        className='single-field-form-textfield'
        errorMessages={[`${label} is required.`]}
        variant='standard'
      />
    </ValidatorForm>
  );
}

SingleFieldForm.defaultProps = {
  maxLength: 255,
};

SingleFieldForm.propTypes = {
  label: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import './EditableSingleFieldForm.scss';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SingleFieldFormComponent from './SingleFieldForm';

/**
 * Ediable single form component
 * When the component is in the display mode, it display as a text with an icon
 * indicating the form can be editted.
 */
export default function EditableSingleFieldForm({
  formData,
  handleSaveData,
  label,
  placeholderLabel,
  placeholderColor,
  className,
  title,
  children,
  ...props
}) {
  const [editMode, setEditMode] = useState(false);

  const editFormMode = () => {
    setEditMode(true);
  };

  const handleSave = (data) => {
    handleSaveData(data);
    setEditMode(false);
  };

  const handleOnBlur = (event) => {
    handleSave(event.target.value);
  };

  return (
    <div {...props} className={`editable-form ${className}`}>
      {title && (
        <Typography variant='h5' className='form-title editable-field-title' data-test='editable-field-title'>{title}: </Typography>
      )}
      {children}
      {
        editMode
          ? (
            <SingleFieldFormComponent
              label={label}
              formData={formData}
              handleSubmit={handleSave}
              onBlur={handleOnBlur}
            />
          ) : (
            <div className='editable-form-display'>
              <Typography color={formData ? 'initial' : `${placeholderColor}`} variant='h5' className='form-title'>{formData || placeholderLabel}</Typography>
              <BorderColorIcon className='edit-icon' onClick={editFormMode} data-test='edit-action' />
            </div>
          )
      }

    </div>
  );
}

EditableSingleFieldForm.defaultProps = {
  formData: '',
  placeholderLabel: '',
  placeholderColor: 'initial',
  className: '',
  title: '',
  children: null,
};

EditableSingleFieldForm.propTypes = {
  label: PropTypes.string.isRequired,
  formData: PropTypes.string,
  handleSaveData: PropTypes.func.isRequired,
  placeholderLabel: PropTypes.string,
  placeholderColor: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

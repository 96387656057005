import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ExitLesson from '../../../../settings/interaction/ExitLesson';

const ExitLessonSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Exit Lesson');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      exitLesson: newValue,
    });
  };

  return (
    <ExitLesson
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default ExitLessonSettings;

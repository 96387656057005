import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js';
import TWEEN from '@tweenjs/tween.js';
import CelebrationUtil, { useSetupTimeout } from '../CelebrationUtil';

const FIREFLIES_ALLOWED_IN_BOTTLE = 40;
const TWEEN_DELAY_INITIAL_RELEASE_TIME = 3000;
const TWEEN_RELEASE_TIME = 5000;
const SHOW_FINISH_MESSAGE_TIME = 1500;
const CANVAS_WIDTH = 800;
const MOUSE_SPEED = 0.15;

const getRandomFireflyDirection = (switchDirection) => {
  const min = 0.008;
  const max = 0.020;
  const switchDirectionResult = switchDirection || ((Math.floor(Math.random() * (1))) > 0.5 ? -1 : 1);
  return (Math.random() * (max - min) + min).toFixed(4) * switchDirectionResult;
};

class Firefly {
  constructor(image) {
    this.image = image;
    this.image.anchor.set(0.5);
    this.xPos = 0;
    this.yPos = 0;
    this.xMove = getRandomFireflyDirection();
    this.yMove = getRandomFireflyDirection();
    this.xCurrDirection = this.xMove > 0 ? 'right' : 'left';
    this.yCurrDirection = this.yMove > 0 ? 'down' : 'up';
  }
}

const FireflyCelebration = ({ celebrationData, onFinish, pixiLoader }) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    fireflies: [],
    firefliesInCaptureBottle: [],
    inBottle: true,
    tweens: [],
    timeouts: [],
  };

  const loadBitmaps = () => {
    app.sheet = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'spritesheetData')].spritesheet;
    app.background = new PIXI.AnimatedSprite(app.sheet.animations.background);
    app.owlEyebrowLeft = new PIXI.AnimatedSprite(app.sheet.animations.owl_eyebrow);
    app.owlEyebrowRight = new PIXI.AnimatedSprite(app.sheet.animations.owl_eyebrow);
    app.bottle = new PIXI.AnimatedSprite(app.sheet.animations.bottle);
    app.bottleCapture = new PIXI.AnimatedSprite(app.sheet.animations.bottle_capture);
    app.bottleCap = new PIXI.AnimatedSprite(app.sheet.animations.bottle_cap);
    app.bottleShadow = new PIXI.AnimatedSprite(app.sheet.animations.bottle_shadow);
    app.message = new PIXI.AnimatedSprite(app.sheet.animations.message);

    app.backgroundBounds = app.background.getBounds();
    app.bottleBounds = app.bottle.getBounds();

    for (let i = 0; i < FIREFLIES_ALLOWED_IN_BOTTLE; i++) {
      const firefly = new Firefly(new PIXI.AnimatedSprite(app.sheet.animations.firefly));
      firefly.kill = false;
      firefly.allowShow = true;
      app.fireflies.push(firefly);
      if (!app.fireflyBounds) app.fireflyBounds = firefly.image.getBounds();
    }
  };

  const createBackground = () => {
    app.background.x = 0;
    app.background.y = 120;
    app.wrapper.addChild(app.background);
  };

  const createBottleShadow = () => {
    app.bottleShadowBounds = app.bottleShadow.getBounds();
    app.bottleShadow.x = app.backgroundBounds.width / 2 - app.bottleShadowBounds.width / 2;
    app.bottleShadow.y = app.backgroundBounds.height - app.bottleShadowBounds.height;
    app.wrapper.addChild(app.bottleShadow);
  };

  const createOwlEyebrow = () => {
    const owlEyebrowBounds = app.owlEyebrowLeft.getBounds();

    app.owlEyebrowLeft.x = 172;
    app.owlEyebrowLeft.y = app.backgroundBounds.height / 1.7 + app.canvas.view.height - owlEyebrowBounds.height - 60;
    app.owlEyebrowLeft.alpha = 0;
    app.wrapper.addChild(app.owlEyebrowLeft);

    app.owlEyebrowRight.x = 211;
    app.owlEyebrowRight.y = app.backgroundBounds.height / 1.7 + app.canvas.view.height - owlEyebrowBounds.height - 60;
    app.owlEyebrowRight.alpha = 0;
    app.wrapper.addChild(app.owlEyebrowRight);
  };

  const createBottle = () => {
    const spaceFromFloor = 70;
    app.bottle.x = app.backgroundBounds.width / 2 - app.bottleBounds.width / 2;
    app.bottle.y = app.backgroundBounds.height - app.bottleBounds.height - spaceFromFloor;
    app.wrapper.addChild(app.bottle);
  };

  const createBottleCap = () => {
    app.bottleCapBounds = app.bottleCap.getBounds();
    const bottleCapHeightMinusCord = 20;
    app.bottleCap.x = (app.bottle.x + app.bottleBounds.width / 2) - app.bottleCapBounds.width / 2;
    app.bottleCap.y = app.bottle.y - app.bottleCapBounds.height + bottleCapHeightMinusCord;
    app.wrapper.addChild(app.bottleCap);
  };

  const handleMove = (e) => {
    const pt = app.bottleCaptureContainer.toLocal(e.data.global);
    const newX = app.bottleCaptureContainer.x + pt.x - app.bottleCaptureBounds.width / 2;
    if ((e.data.global.x + 50) > app.bottleCaptureBounds.width / 4 && (e.data.global.x - 20) <= CANVAS_WIDTH - app.bottleCaptureBounds.width / 2) {
      const delta = (newX - app.bottleCaptureContainer.x) * MOUSE_SPEED;
      app.bottleCaptureContainer.x += delta;
    }

    const newY = app.bottleCaptureContainer.y + pt.y - app.bottleCaptureBounds.height / 2;
    if (e.data.global.y > app.bottleCaptureBounds.height / 2 && e.data.global.y <= 640 - app.bottleCaptureBounds.height / 4) {
      const delta = (newY - app.bottleCaptureContainer.y) * MOUSE_SPEED;
      app.bottleCaptureContainer.y += delta;
    }

    if (app.bottleCaptureContainer.x > app.bottleCapturePrevX) {
      if (app.bottleCaptureRotateContainer.rotation < 1) {
        app.bottleCaptureRotateContainer.rotation += 0.04;
      }
    } else if (app.bottleCaptureRotateContainer.rotation > -0.7) {
      app.bottleCaptureRotateContainer.rotation -= 0.04;
    }
    app.bottleCapturePrevX = app.bottleCaptureContainer.x;
    app.bottleCapturePrevY = app.bottleCaptureContainer.y;
  };

  const createBottleCapture = () => {
    app.bottleCaptureBounds = app.bottleCapture.getBounds();

    app.bottleCaptureContainer = new PIXI.Container();
    app.bottleCaptureContainer.x = app.backgroundBounds.width / 2 - app.bottleCaptureBounds.width / 2;
    app.bottleCaptureContainer.y = app.backgroundBounds.height / 2 + app.canvas.view.height - app.bottleCaptureBounds.height;
    app.wrapper.addChild(app.bottleCaptureContainer);

    app.bottleCaptureRotateContainer = new PIXI.Container();
    app.bottleCaptureContainer.addChild(app.bottleCaptureRotateContainer);

    app.bottleCapture.anchor.set(0.5, 0.5);
    app.bottleCapture.x = app.bottleCaptureBounds.width / 2;
    app.bottleCapture.y = app.bottleCaptureBounds.height / 2;
    app.bottleCapture.alpha = 0;
    app.bottleCaptureRotateContainer.addChild(app.bottleCapture);

    app.bottleCaptureLighting = new Firefly(new PIXI.AnimatedSprite(app.sheet.animations.bottle_capture_highlight));
    app.bottleCaptureLighting.image.alpha = 0;
    app.bottleCaptureLighting.image.y = 90;
    app.bottleCaptureLighting.image.x = 125;
    app.bottleCaptureRotateContainer.addChild(app.bottleCaptureLighting.image);

    app.canvas.stage.cursor = 'none';
    app.canvas.stage.interactive = true;
    app.bottleCaptureRotateContainer.interactive = true;
    app.bottleCaptureRotateContainer.on('mousemove', handleMove);
    app.bottleCaptureRotateContainer.on('touchmove', handleMove);
  };

  const createBottleCaptureFireflies = () => {
    const minX = 85;
    const maxX = 80;
    const minY = 30;
    const maxY = app.bottleCaptureBounds.height - 55;

    for (let i = 0; i < FIREFLIES_ALLOWED_IN_BOTTLE; i++) {
      const randomX = Math.floor((Math.random() * maxX) + minX);
      const randomY = Math.floor((Math.random() * maxY) + minY);

      const firefly = new Firefly(new PIXI.AnimatedSprite(app.sheet.animations.firefly));
      const fireflyObj = {};
      fireflyObj.image = firefly.image;
      fireflyObj.allowShow = false;
      firefly.image.x = randomX;
      firefly.image.y = randomY;
      firefly.image.alpha = 0;
      app.firefliesInCaptureBottle.push(fireflyObj);
      app.bottleCaptureRotateContainer.addChild(firefly.image);
    }
  };

  const createFireflies = () => {
    for (let i = 0; i < app.fireflies.length; i++) {
      const firefly = app.fireflies[i];

      const random = Math.random() * 1;
      const randomX = Math.random() * (app.bottleBounds.width / 2);
      const x = random >= 0.5 ? randomX : -randomX;

      const randomY = Math.random() * (app.bottleBounds.height / 2);
      const y = random >= 0.5 ? randomY : -randomY;

      firefly.image.x = (app.backgroundBounds.width / 2 - app.bottleBounds.width / 2) + app.bottleBounds.width / 2 + x;
      firefly.image.y = (app.backgroundBounds.height - app.bottleBounds.height - 50) + app.bottleBounds.height / 2 + y;
      app.wrapper.addChild(firefly.image);
    }
  };

  const createMessage = () => {
    app.messageBounds = app.message.getBounds();
    app.message.anchor.set(0.5);
    app.message.alpha = 0;
    app.message.x = app.backgroundBounds.width / 2;
    app.message.y = app.backgroundBounds.height / 2 + app.canvas.view.height + app.messageBounds.height + 200;
    app.message.scale.x = 0.1;
    app.message.scale.y = 0.1;
    app.wrapper.addChild(app.message);
  };

  const createStage = () => {
    app.wrapper = new PIXI.Container();
    app.wrapper.scale.x = app.canvas.view.width / app.backgroundBounds.width;
    app.wrapper.scale.y = app.wrapper.scale.x;
    app.wrapper.y = app.canvas.view.height - app.backgroundBounds.height * app.wrapper.scale.y;
    app.canvas.stage.addChild(app.wrapper);

    createBackground();
    createBottleShadow();
    createOwlEyebrow();
    createFireflies();
    createBottle();
    createBottleCap();
    createBottleCapture();
    createBottleCaptureFireflies();
    createMessage();
  };

  const playBackgroundSound = () => {
    app.cricketsSound = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'crickets')].sound;
    if (app.cricketsSound) {
      app.cricketsSound.play({ start: 0, volume: 1 });
    }
  };

  const playHitSound = async () => {
    app.hitSound = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'hit')].sound;
    if (app.hitSound) {
      app.hitSound.stop();
      try {
        app.hitSound.play({ start: 0, volume: 1 });
      } catch (e) {
        // ignore
      }
    }
  };

  const playClappingSound = () => {
    app.clappingSound = pixiLoader.resources[CelebrationUtil.getResourceKey(celebrationData.id, 'clapping')].sound;
    if (app.clappingSound) {
      app.clappingSound.play({ start: 0, volume: 1 });
    }
  };

  const blink = () => {
    app.owlEyebrowLeft.alpha = 1;
    app.owlEyebrowRight.alpha = 1;
    const blinkHeight = 15;

    const owlEyebrowLeftTween = new TWEEN.Tween(app.owlEyebrowLeft);
    owlEyebrowLeftTween.to({ y: app.owlEyebrowLeft.y + (app.blinkUp ? blinkHeight : -blinkHeight) }, 500);
    owlEyebrowLeftTween.easing(TWEEN.Easing.Linear.None);
    owlEyebrowLeftTween.repeatDelay(5000);
    owlEyebrowLeftTween.repeat(5000);

    const owlEyebrowRightTween = new TWEEN.Tween(app.owlEyebrowRight);
    owlEyebrowRightTween.to({ y: app.owlEyebrowRight.y + (app.blinkUp ? blinkHeight : -blinkHeight) }, 500);
    owlEyebrowRightTween.easing(TWEEN.Easing.Linear.None);
    owlEyebrowRightTween.repeatDelay(5000);
    owlEyebrowRightTween.repeat(5000);

    app.tweens.push(owlEyebrowLeftTween);
    app.tweens.push(owlEyebrowRightTween);

    owlEyebrowLeftTween.start();
    owlEyebrowRightTween.start();
  };

  const tweenReleaseComplete = () => {
    const bottleCaptureTween = new TWEEN.Tween(app.bottleCapture);
    bottleCaptureTween.to({ alpha: 1 }, 800);
    bottleCaptureTween.easing(TWEEN.Easing.Linear.None);
    bottleCaptureTween.onComplete(() => {
      app.startCapturing = true;
    });
    app.tweens.push(bottleCaptureTween);
    bottleCaptureTween.start();
    blink();
  };

  const releaseTheFireflies = () => {
    const bottleCaptween = new TWEEN.Tween(app.bottleCap);
    bottleCaptween.to({ y: -app.bottleCapBounds.height }, TWEEN_DELAY_INITIAL_RELEASE_TIME);
    bottleCaptween.easing(TWEEN.Easing.Exponential.In);
    bottleCaptween.delay(500);
    app.tweens.push(bottleCaptween);
    bottleCaptween.start();

    const releaseFirefliesTimeout = setTimeout(() => {
      clearTimeout(releaseFirefliesTimeout);
      app.inBottle = false;
      const backgroundTween = new TWEEN.Tween(app.background);
      backgroundTween.to({ y: app.backgroundBounds.height / 1.7 }, TWEEN_DELAY_INITIAL_RELEASE_TIME);
      backgroundTween.easing(TWEEN.Easing.Linear.None);

      const bottleShadowTween = new TWEEN.Tween(app.bottleShadow);
      bottleShadowTween.to({ y: app.backgroundBounds.height + 700 }, TWEEN_DELAY_INITIAL_RELEASE_TIME);
      bottleShadowTween.easing(TWEEN.Easing.Linear.None);

      const bottleTween = new TWEEN.Tween(app.bottle);
      bottleTween.to({ y: app.backgroundBounds.height + 700 }, TWEEN_DELAY_INITIAL_RELEASE_TIME);
      bottleTween.easing(TWEEN.Easing.Linear.None);
      bottleTween.onComplete(() => {
        tweenReleaseComplete();
      });

      app.tweens.push(backgroundTween);
      app.tweens.push(bottleShadowTween);
      app.tweens.push(bottleTween);

      backgroundTween.start();
      bottleShadowTween.start();
      bottleTween.start();

      const innerReleseFirefliesTimeout = setTimeout(() => {
        clearTimeout(innerReleseFirefliesTimeout);
        app.ceilingReached = true;
      }, TWEEN_RELEASE_TIME - 3800);
      app.timeouts.push(innerReleseFirefliesTimeout);
    }, 1500);
    app.timeouts.push(releaseFirefliesTimeout);
  };

  const updateFirefliesBlinking = () => {
    let firefly;
    let i;
    let random;
    let randomTime;
    for (i = 0; i < app.firefliesInCaptureBottle.length; i++) {
      firefly = app.firefliesInCaptureBottle[i];
      if (firefly.allowShow) {
        random = Math.floor((Math.random() * 2) + 1);
        randomTime = Math.floor((Math.random() * 750) + 250);

        if (random === 1) { // random == 1 means to allow animation to occur
          const fireflyTween = new TWEEN.Tween(firefly.image);
          fireflyTween.to({ alpha: (firefly.image.alpha === 1 ? 0 : 1) }, randomTime);
          fireflyTween.easing(TWEEN.Easing.Linear.None);
          app.tweens.push(fireflyTween);
          fireflyTween.start();
        }
      }
    }

    for (i = 0; i < app.fireflies.length; i++) {
      firefly = app.fireflies[i];
      if (firefly.allowShow) {
        random = Math.floor((Math.random() * 2) + 1);
        if (random === 1) { // random == 1 means to allow animation to occur
          const randomVisibility = (Math.random() * (1.0 - 0.3) + 0.3).toFixed(1);
          randomTime = Math.floor((Math.random() * 750) + 250);

          const fireflyTween = new TWEEN.Tween(firefly.image);
          fireflyTween.to({ alpha: randomVisibility }, 750);
          fireflyTween.easing(TWEEN.Easing.Linear.None);

          const randomScale = Math.floor((Math.random() * 3) + 2);
          // eslint-disable-next-line no-nested-ternary
          const scale = app.startCapturing ? (firefly.image.scale.x === 1 ? randomScale : 1) : (firefly.image.scale.x === 3.5 ? 0.8 : 3.5);

          const fireflyScaleTween = new TWEEN.Tween(firefly.image.scale);
          fireflyScaleTween.to({ x: scale, y: scale }, 750);
          fireflyScaleTween.easing(TWEEN.Easing.Linear.None);

          app.tweens.push(fireflyTween);
          app.tweens.push(fireflyScaleTween);
          fireflyTween.start();
          fireflyScaleTween.start();
        }
      }
    }

    const updateFirefliesBlinkingTimeout = setTimeout(() => {
      clearTimeout(updateFirefliesBlinkingTimeout);
      updateFirefliesBlinking();
    }, 750);
    app.timeouts.push(updateFirefliesBlinkingTimeout);
  };

  const showFinish = () => {
    if (!app.showFinishCalled) {
      app.showFinishCalled = true;

      const messageTween = new TWEEN.Tween(app.message);
      messageTween.to({
        alpha: 1,
        x: (app.backgroundBounds.width / 2),
        y: (app.backgroundBounds.height / 2 + app.canvas.view.height),
      }, SHOW_FINISH_MESSAGE_TIME);
      messageTween.easing(TWEEN.Easing.Exponential.In);

      const messageScaleTween = new TWEEN.Tween(app.message.scale);
      messageScaleTween.to({ x: 1.5, y: 1.5 }, SHOW_FINISH_MESSAGE_TIME);
      messageScaleTween.easing(TWEEN.Easing.Exponential.In);

      playClappingSound();

      app.tweens.push(messageTween);
      app.tweens.push(messageScaleTween);
      messageTween.start();
      messageScaleTween.start();

      const showFinishTimeout = setTimeout(() => {
        clearTimeout(showFinishTimeout);
        onFinish();
      }, SHOW_FINISH_MESSAGE_TIME * 4);
      app.timeouts.push(showFinishTimeout);
    }
  };

  const showNewFireflyInBottleCapture = () => {
    let invisibleFireflyFound = false;
    let visibleFirefliesCount = 0;

    for (let i = 0; i < app.firefliesInCaptureBottle.length; i++) {
      const firefly = app.firefliesInCaptureBottle[i];
      if (!firefly.allowShow) {
        if (!invisibleFireflyFound) {
          invisibleFireflyFound = true;
          firefly.allowShow = true;
          firefly.image.alpha = 1;

          if (i === app.firefliesInCaptureBottle.length - 1) {
            showFinish();
          }
        }
      } else {
        visibleFirefliesCount++;
      }
    }

    app.bottleCaptureLighting.image.alpha = (visibleFirefliesCount / app.firefliesInCaptureBottle.length);
  };

  function updateFireflies() {
    let padding = 20;
    for (let i = 0; i < app.fireflies.length; i++) {
      const firefly = app.fireflies[i];
      if (!firefly.kill) {
        firefly.xPos += firefly.xMove;
        firefly.yPos += firefly.yMove;

        firefly.image.x += firefly.xPos;
        firefly.image.y += firefly.yPos;

        const rect1 = {};
        padding = 20;
        rect1.x = app.bottleCaptureContainer.x + padding;
        rect1.y = app.bottleCaptureContainer.y + padding;
        rect1.width = app.bottleCaptureBounds.width - padding;
        rect1.height = app.bottleCaptureBounds.height - padding;

        const rect2 = {};
        rect2.x = firefly.image.x;
        rect2.y = firefly.image.y;
        rect2.width = app.fireflyBounds.width;
        rect2.height = app.fireflyBounds.height;

        if (app.startCapturing && !(rect1.x >= rect2.x + rect2.width || rect1.x + rect1.width <= rect2.x || rect1.y >= rect2.y + rect2.height || rect1.y + rect1.height <= rect2.y)) {
          firefly.kill = true;
          firefly.allowShow = false;
          firefly.image.visible = false;
          playHitSound();

          const fireflyScaleTween = new TWEEN.Tween(firefly.image.scale);
          fireflyScaleTween.to({ x: 5, y: 5 }, 250);
          fireflyScaleTween.easing(TWEEN.Easing.Linear.None);
          app.tweens.push(fireflyScaleTween);
          fireflyScaleTween.start();

          const fireflyPosTween = new TWEEN.Tween(firefly.image);
          fireflyPosTween.to({ x: firefly.image.x - 60, y: firefly.image.y - 60, alpha: 0 }, 250);
          fireflyPosTween.easing(TWEEN.Easing.Linear.None);
          app.tweens.push(fireflyPosTween);
          fireflyPosTween.start();

          showNewFireflyInBottleCapture();
        }

        // Get walls of container
        let restrictRight;
        let restrictLeft;
        let restrictTop;
        let restrictBottom;
        if (app.ceilingReached) {
          padding = 15;
          restrictRight = app.backgroundBounds.width + padding;
          restrictLeft = -padding;
          restrictTop = 950 - padding;
          restrictBottom = app.backgroundBounds.height + padding;
        } else {
          restrictRight = app.bottle.x + app.bottleBounds.width - app.fireflyBounds.width / 2 + 10;
          restrictLeft = app.bottle.x + app.fireflyBounds.width / 2 - 10;
          restrictTop = app.bottle.y + app.fireflyBounds.height / 2;
          restrictBottom = app.bottle.y + app.bottleBounds.height - app.fireflyBounds.height / 2;
        }

        if (firefly.image.x >= restrictRight && firefly.xCurrDirection === 'right') {
          // hits far right
          firefly.xCurrDirection = 'left';
          firefly.xMove = getRandomFireflyDirection(-1);
          firefly.xPos = 0;
        } else if (firefly.image.x <= restrictLeft && firefly.xCurrDirection === 'left') {
          // hits far left
          firefly.xCurrDirection = 'right';
          firefly.xMove = getRandomFireflyDirection(1);
          firefly.xPos = 0;
        }

        // hits bottom
        if (firefly.image.y >= restrictBottom && firefly.yCurrDirection === 'down') {
          firefly.yCurrDirection = 'up';
          firefly.yMove = getRandomFireflyDirection(-1);
          firefly.yPos = 0;
        } else if ((firefly.inBottle || app.inBottle || app.ceilingReached) && firefly.image.y <= restrictTop && firefly.yCurrDirection === 'up') {
          // hits top
          firefly.yCurrDirection = 'down';
          firefly.yMove = getRandomFireflyDirection(1);
          firefly.yPos = 0;
        }
      }
    }
  }

  const tick = () => {
    updateFireflies();
  };

  const start = () => {
    playBackgroundSound();
    releaseTheFireflies();

    const startTimeout = setTimeout(() => {
      clearTimeout(startTimeout);
      updateFirefliesBlinking();
    }, TWEEN_DELAY_INITIAL_RELEASE_TIME / 2);
    app.timeouts.push(startTimeout);
  };

  useSetupTimeout(canvas, showFinish);

  useEffect(() => {
    if (canvas.current) {
      app.canvas = new PIXI.Application({
        view: canvas.current,
        width: CANVAS_WIDTH,
        height: canvas.current.parentElement.clientHeight,
        backgroundAlpha: 0,
      });
      loadBitmaps();
      createStage();
      app.canvas.ticker.add(tick);
      start();
    }

    return () => {
      if (app.cricketsSound) {
        app.cricketsSound.stop();
      }
      if (app.hitSound) {
        app.hitSound.stop();
      }
      if (app.clappingSound) {
        app.clappingSound.stop();
      }

      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });

      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });

      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

FireflyCelebration.defaultProps = {
  onFinish: () => { },
};

FireflyCelebration.propTypes = {
  celebrationData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
};

export default FireflyCelebration;

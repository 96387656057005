/* eslint-disable no-param-reassign */
import * as PIXI from 'pixi.js';
import tinycolor from 'tinycolor2';
import TWEEN from '@tweenjs/tween.js';

const FLOAT_AMOUNT = 25;
const FLOAT_TIME = 3000;

const initBgColor = (colors, gradientIntensity) => {
  const rand = Math.floor(Math.random() * colors.length) + 1;
  const color = colors[rand - 1];
  const colorCombinations = [
    tinycolor(color).lighten(gradientIntensity),
    tinycolor(color).darken(gradientIntensity),
  ];
  colorCombinations.push([tinycolor(color).lighten(gradientIntensity), tinycolor(color).darken(gradientIntensity)]);
  return { selectedColor: color, colorCombinations };
};

const getResourceKey = (themeId, manifestId) => `${themeId}-${manifestId}`;

export default {
  getResourceKey,

  getLessonData: (lessonsCompleted, lessonsLength) => {
    const innerLessonsCompleted = lessonsCompleted === null || lessonsCompleted === undefined ? Math.floor(Math.random() * 6) : lessonsCompleted;
    const innerLessonsLength = lessonsLength === null || lessonsLength === undefined ? Math.max(7, innerLessonsCompleted * 3) : lessonsLength;

    return {
      innerLessonsCompleted,
      innerLessonsLength,
    };
  },

  initCanvasWithBackground: (canvasRef, colors, gradientIntensity) => {
    const { selectedColor, colorCombinations: backgroundColor } = initBgColor(colors, gradientIntensity);
    const canvas = new PIXI.Application({
      view: canvasRef.current,
      width: 1024,
      height: canvasRef.current.parentElement.clientHeight,
      backgroundAlpha: 0,
    });

    const bg = `radial-gradient(ellipse at center, ${backgroundColor[0].toString()} 0%, ${backgroundColor[1].toString()} 100%)`;
    // eslint-disable-next-line no-param-reassign
    canvasRef.current.parentElement.style.background = 'transparent';
    canvasRef.current.parentElement.parentElement.parentElement.style.background = bg;
    return { selectedColor, canvas };
  },

  initWrapper: (app, themeData, pixiLoader, onClick, config) => {
    /* Read sprite sheet */
    app.sheet = pixiLoader.resources[getResourceKey(themeData.id, 'spritesheetData')].spritesheet;

    /* Init wrapper container */
    app.wrapper = new PIXI.Container();
    app.canvas.stage.addChild(app.wrapper);

    /* Init floating container */
    app.floatingElementsContainer = new PIXI.Container();
    app.wrapper.addChild(app.floatingElementsContainer);

    /* Init scene scaler */
    app.sceneScaler = new PIXI.AnimatedSprite(app.sheet.animations.scene_scaler);
    app.sceneScalerBounds = app.sceneScaler.getBounds();
    app.sceneScaler.x = app.canvas.view.width / 2 - app.sceneScalerBounds.width / 2;
    app.sceneScaler.y = app.sceneScalerBounds.height / 2.5;
    app.wrapper.addChild(app.sceneScaler);

    /* Add floating island */
    app.floatingIslandContainer = new PIXI.Container();
    app.floatingIslandHighlight = new PIXI.AnimatedSprite(app.sheet.animations.island_highlight);
    app.floatingIslandHighlight.visible = false;
    app.floatingIslandHighlight.anchor.set(0.5);
    app.floatingIslandHighlightBounds = app.floatingIslandHighlight.getBounds();
    app.floatingIslandHighlight.x = app.canvas.view.width / 2;
    app.floatingIslandHighlight.y = (app.sceneScalerBounds.height - app.floatingIslandHighlightBounds.height / 1.60) + 60 + 15;
    app.floatingIslandContainer.addChild(app.floatingIslandHighlight);

    app.floatingIsland = new PIXI.AnimatedSprite(app.sheet.animations[config && config.island ? config.island : 'island']);
    app.floatingIsland.anchor.set(0.5);
    app.floatingIslandBounds = app.floatingIsland.getBounds();
    app.floatingIsland.x = app.canvas.view.width / 2;
    app.floatingIsland.y = (app.sceneScalerBounds.height - app.floatingIslandBounds.height / 1.60) + 60;
    app.wrapper.addChild(app.floatingIslandContainer);
    app.floatingIslandContainer.addChild(app.floatingIsland);

    app.floatingIslandContainer.interactive = true;
    app.floatingIslandContainer.cursor = 'pointer';
    app.floatingIslandContainer.on('mouseover', () => {
      app.floatingIslandHighlight.visible = true;
    });
    app.floatingIslandContainer.on('mouseout', () => {
      app.floatingIslandHighlight.visible = false;
    });
    app.floatingIslandContainer.on('click', () => {
      onClick();
    });
    app.floatingIslandContainer.on('tap', () => {
      app.floatingIslandHighlight.visible = true;
      onClick();
    });

    const floatingIslandTween = new TWEEN.Tween(app.floatingIslandContainer);
    floatingIslandTween.to({ y: app.floatingIslandContainer.y - FLOAT_AMOUNT }, FLOAT_TIME);
    floatingIslandTween.easing(TWEEN.Easing.Linear.None);
    floatingIslandTween.chain(
      new TWEEN.Tween(app.floatingIslandContainer).to({ y: app.floatingIslandContainer.y + FLOAT_AMOUNT }, FLOAT_TIME).easing(TWEEN.Easing.Linear.None)
        .chain(
          new TWEEN.Tween(app.floatingIslandContainer).to({ y: app.floatingIslandContainer.y }, FLOAT_TIME).easing(TWEEN.Easing.Linear.None)
            .onComplete(() => {
              floatingIslandTween.start();
            }),
        ),
    );
    floatingIslandTween.start();

    app.stopFloatingIslandTween = () => {
      floatingIslandTween.stopChainedTweens();
      floatingIslandTween.stop();
    };
    return app;
  },

  addProgressBar: (app, progressBarConfig, _lessonLength, lessonsCompleted) => {
    // When the feedback loop lesson is finished, it's removed from the list.
    // This is to avoid the error of _lessonLength
    const lessonLength = _lessonLength === 0 ? 1 : _lessonLength;

    const progressBarContainer = new PIXI.Container();
    app.canvas.stage.addChild(progressBarContainer);
    const progressBarContainerContainer = new PIXI.Container();
    progressBarContainer.addChild(progressBarContainerContainer);

    /* Create background components */
    app.barBackground = new PIXI.AnimatedSprite(app.sheet.animations[progressBarConfig.barBackground]);
    const progressBar = new PIXI.AnimatedSprite(app.sheet.animations[progressBarConfig.progressBar]);
    app.progressAvatar = new PIXI.AnimatedSprite(app.sheet.animations[progressBarConfig.progressAvatar]);

    const barBackgroundBounds = app.barBackground.getBounds();
    app.barBackground.x = app.canvas.view.width / 2 - barBackgroundBounds.width / 2;
    app.barBackground.y = app.canvas.view.height - app.barBackground.getBounds().height - 10;
    progressBarContainer.addChild(app.barBackground);

    progressBar.x = app.barBackground.x + 14;
    progressBar.y = app.barBackground.y + 14;
    progressBar.visible = false;

    app.progressAvatar.anchor.set(0.5);
    app.progressAvatar.y = progressBar.y + 10;

    const dots = [];
    for (let i = 0; i <= lessonLength; i++) {
      dots.push(new PIXI.AnimatedSprite(app.sheet.animations[`${progressBarConfig.dotType}_red`]));
      dots[i].anchor.set(0.5);
      dots[i].x = 47 + (i * (910 / (lessonLength)));
      dots[i].y = app.barBackground.y + barBackgroundBounds.height / 2;
      progressBarContainer.addChild(dots[i]);
    }

    const imgSheen = new PIXI.Graphics();
    imgSheen.beginFill(0xFF3300);
    imgSheen.drawRect(progressBar.x, progressBar.y, 0, 100);
    imgSheen.endFill();
    progressBar.mask = imgSheen;
    progressBarContainer.addChild(progressBar);

    progressBarContainer.addChild(app.progressAvatar);

    if (lessonsCompleted === 0) {
      app.progressAvatar.x = dots[0].x;
      app.progressAvatar.scale.x = 0.1;
      app.progressAvatar.scale.y = 0.1;
      progressBar.visible = false;

      const progressAvatarTween = new TWEEN.Tween(app.progressAvatar)
        .to({ alpha: 1, rotation: -25.1 }, 2000)
        .easing(TWEEN.Easing.Linear.None);
      app.tweens.push(progressAvatarTween);

      const progressAvatarScaleTween = new TWEEN.Tween(app.progressAvatar.scale)
        .to({ x: 1, y: 1 }, 2000)
        .easing(TWEEN.Easing.Linear.None);
      app.tweens.push(progressAvatarScaleTween);

      progressAvatarTween.start();
      progressAvatarScaleTween.start();
    } else {
      app.progressAvatar.x = dots[lessonsCompleted - 1].x;
      progressBar.visible = true;

      if (app.progressAvatar.x <= dots[lessonsCompleted].x) {
        const progressAvatarMove = new TWEEN.Tween(app.progressAvatar)
          .to({ x: dots[lessonsCompleted].x }, 2000)
          .easing(TWEEN.Easing.Linear.None)
          .onUpdate((object) => {
            imgSheen.clear();

            imgSheen.beginFill(0xFF3300);
            imgSheen.drawRect(progressBar.x, progressBar.y, object.x - 20, 100);
            imgSheen.endFill();
          });
        app.tweens.push(progressAvatarMove);
        progressAvatarMove.start();
      }
    }
  },

  addFloatableObjects: (app, floatFrequency, allowedFloatRotation) => {
    app.floatableInterval = setInterval(() => {
      const scale = (Math.random() * (0.25 - 0.4) + 0.4).toFixed(1);
      const floatableContainer = new PIXI.Container();
      const floatable = new PIXI.AnimatedSprite(app.sheet.animations.floatable);
      floatable.scale.x = scale;
      floatable.scale.y = scale;
      const floatableBounds = floatable.getBounds();
      floatable.x = -floatableBounds.width / 2;
      floatable.y = -floatableBounds.height / 2;

      const alphaBySize = 1.4 - (1 - scale);
      const tweenTimeBySize = floatFrequency + (floatFrequency * (1 - scale));
      floatableContainer.alpha = 0;
      floatableContainer.x = app.sceneScalerBounds.width + 230;
      floatableContainer.y = Math.floor(Math.random() * (app.sceneScalerBounds.height / 3)) + floatableBounds.height;

      floatableContainer.addChild(floatable);
      app.floatingElementsContainer.addChild(floatableContainer);

      const floatingBgHalfwayTween = new TWEEN.Tween(floatableContainer)
        .to({
          x: floatableBounds.width / 2 + 10,
          alpha: 0,
          rotation: (allowedFloatRotation ? 4 : 0),
        }, tweenTimeBySize)
        .easing(TWEEN.Easing.Linear.None)
        .onComplete(() => {
          app.floatingElementsContainer.removeChild(floatableContainer);
        });

      const floatableObjectsTween = new TWEEN.Tween(floatableContainer)
        .to({
          x: app.sceneScalerBounds.width / 2,
          alpha: (alphaBySize),
          rotation: (allowedFloatRotation ? 2 : 0),
        }, tweenTimeBySize)
        .easing(TWEEN.Easing.Linear.None)
        .chain(floatingBgHalfwayTween);

      floatableObjectsTween.start();
      app.tweens.push(floatableObjectsTween);
    }, floatFrequency);
  },
  stopFloatableObjects: (app) => {
    clearInterval(app.floatableInterval);
  },
};

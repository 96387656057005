import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import ChooseNumberOfCards from '../media/editor/matching/ChooseNumberOfCards';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import DropdownInput from '../../../../../components/selector/DropdownInput';
import { useHandleNumberOfCardChanges } from '../../hooks/LessonMaintenanceHooks';
import { AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS } from '../../../../../components/vizzle/page/common/CardEditorConstants';
import FlexibleMatchingThumbnail from '../../../../../components/vizzle/page/flexible-matching/FlexibleMatchingThumbnail';
import { layoutDropdownValues } from '../../../../../components/vizzle/page/flexible-matching/FlexibleMatchingConstants';
import WatchQuickVideo from '../../../../../components/vizzle/media/WatchQuickVideo';

const videoUrl = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/in-app-help-videos/Advanced Matching.mp4`;

const responseCardsConfig = {
  label: 'Choose Number of Response Cards',
  min: 1,
  max: 6,
  step: 1,
  marks:
    [
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
    ],
};

const createCards = (startNum, endNum) => {
  const result = [];
  for (let i = startNum; i <= endNum; i++) {
    result.push({ sequenceNum: i, key: null });
  }
  return result;
};

/**
 * Container for Matching layout
 */
export default function FlexiMatchingLayout() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const handleNumberOfCardChange = useHandleNumberOfCardChanges();

  const promptCards = selectedPage.promptCards.filter((card) => !card.hide);
  const responseCards = selectedPage.responseCards.filter((card) => !card.hide);

  const handleOnPrompsChange = async (e) => {
    const value = e.target;
    selectedPage[value.name] = value.value;
    await handleNumberOfCardChange({
      numberOfCard: layoutDropdownValues.find((l) => l.id === value.value).cardValue,
      currentNumberOfCards: promptCards.length,
      cardType: AREA_PROMPT_CARDS,
      templateData: createCards(1, layoutDropdownValues[layoutDropdownValues.length - 1].cardValue),
    });
  };

  const handleOnSliderChange = async (event, value) => {
    event.stopPropagation();
    await handleNumberOfCardChange({
      numberOfCard: value,
      currentNumberOfCards: responseCards.length,
      cardType: AREA_RESPONSE_CARDS,
      templateData: createCards(1, responseCardsConfig.max),
    });
  };

  const props = {
    config: [
      {
        component: (
          <FlexibleMatchingThumbnail
            layout={selectedPage.layout}
            promptCards={promptCards.map(() => ({}))}
            responseCards={responseCards.map(() => ({}))}
            promptText={null} // pass null, do not show the text in the layout example
            subText={null}
          />),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '42vw' : '32vw',
    height: '48vh',
  };

  return (
    <Box>
      <br />
      <Box display='flex' mt='10px'>
        <br />
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-matching-layout'
        />

        <Box width='30vw' ml='15px'>
          <Box display='flex' height='100%' flexDirection='column' justifyContent='space-around'>
            <Box justifySelf='flex-start'>
              <WatchQuickVideo
                videoUrl={videoUrl}
              />
            </Box>

            <DropdownInput
              data-test='prompt-layout-selector'
              label='Choose Prompt Layout'
              dropdownValues={layoutDropdownValues}
              name='layout'
              value={selectedPage.layout ? selectedPage.layout : ''}
              labelVariant='h6'
              onChange={handleOnPrompsChange}
            />

            <ChooseNumberOfCards
              onSliderChange={handleOnSliderChange}
              value={
                selectedPage && selectedPage.responseCards ? selectedPage.responseCards.filter((card) => (
                  !card.hide
                )).length : null
              }
              {...responseCardsConfig}
              data-test='response-cards-selector'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

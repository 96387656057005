import { styled } from '@mui/material/styles';
import {
  Slider,
} from '@mui/material';

const NumberOfPlayersSlider = styled(Slider)({
  color: '#FF4081',
  height: 4,
  '& .MuiSlider-thumb': {
    height: 17,
    width: 17,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: 0,
    '&:hover': {
      height: 21,
      width: 21,
      marginTop: -9,
      boxShadow: 'inherit',
    },
    '&:focus': {
      boxShadow: '0px 0px 0px 10px rgba(255,64,129,.2)',
      height: 27,
      width: 27,
      marginTop: -12,
      border: '3px solid currentColor',
    },
  },
  '& .MuiSlider-track': {
    height: 2,
    borderRadius: 4,
    backgroundColor: 'gray',
  },
  '& .MuiSlider-rail': {
    height: 2,
    borderRadius: 4,
    backgroundColor: 'gray',
  },
});

export default NumberOfPlayersSlider;

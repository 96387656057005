import { useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import './TeacherCourseTopicGuide.scss';
import { getUrlByState, options, useHandleStateSelector } from './TeacherCourseTopicGuideHooks';
import { useCheckPdf } from '../../../../hooks/CheckPdfLinkHooks';
import GenerateUnitGuideFromTopicsActiveAction from './actions/GenerateUnitGuideFromTopicsActiveAction';
import UnitGuidePdfGenerator from './UnitGuidePdfGenerator';
import { useTrackTeacherCourseActivity } from '../../TeacherCourseMainTabHooks';
import { TRACKING_STATUS } from '../../../../../services/ActivityTrackingService';

const TeacherCourseTopicGuide = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria, hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;
  const [showPdf] = useState(true);

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const {
    state,
    handleOnStateChange,
  } = useHandleStateSelector();

  const {
    courseId,
    unitId,
  } = searchCriteria;

  const selectedCourse = (lessonCourses || []).find((l) => l.id === courseId) || {};

  const { units } = selectedCourse;
  const selectedUnit = (units || []).find((u) => u.id === unitId) || '';
  const urlByState = getUrlByState(user, selectedUnit, state);

  const {
    loading,
    signedPdfUrl,
  } = useCheckPdf(urlByState);

  useTrackTeacherCourseActivity(TRACKING_STATUS.UNIT_GUIDE);

  if (!hasPerformSearch) {
    return (<TeacherCourseInstruction />);
  }

  if (!lessonCourses || !searchCriteria || !searchCriteria.unitId || !selectedCourse || !selectedUnit) {
    return null;
  }

  return (
    <div className='teacher-course-topic-guide'>
      {user.userModerator && (
        <Box display='flex' justifyContent='center' alignItems='flex-end'>
          {!selectedUnit.generateUnitGuideFromTopics && (
            <Select
              variant='outlined'
              value={state || 'none'}
              className='content-type-search unit-guide-state-selector'
              onChange={handleOnStateChange}
              name='contentType'
            >
              <MenuItem key='none' value='none' disabled>
                State
              </MenuItem>
              {options.map((c) => (
                <MenuItem key={c.key} value={c.value}>{c.name}</MenuItem>
              ))}
            </Select>
          )}

          <GenerateUnitGuideFromTopicsActiveAction />
        </Box>
      )}
      {selectedUnit.generateUnitGuideFromTopics && (
        <UnitGuidePdfGenerator courseId={courseId} unitId={unitId} />
      )}
      {!selectedUnit.generateUnitGuideFromTopics && showPdf && signedPdfUrl && !loading && (
        <object className='pdf-container' data={signedPdfUrl} type='application/pdf'>
          <iframe title='Unit Guide PDF' src={signedPdfUrl} />
        </object>
      )}
    </div>
  );
};

export default TeacherCourseTopicGuide;

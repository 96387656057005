import { useGroupPlay } from '../context/GroupPlayContextProvider';
import { SUBJECT_CONSTANTS } from '../../../../../constants/LessonConstant';

export const useGetLessonGroupPlay = () => {
  const { groupPlayDomain } = useGroupPlay();
  const { lessonData } = groupPlayDomain.domainData;

  const { lessons, filter } = lessonData;
  let filteredLessons = [...lessons];
  if (filter && filter.subjects && filter.subjects.length > 0) {
    const subjectNames = filter.subjects.map((s) => s.name);

    filteredLessons = lessons.filter((l) => {
      const { attributes: { subjects } } = l;
      const { meta: { groupPlay } } = l;
      if (!groupPlay) {
        return false;
      }
      return subjectNames.some((v) => {
        if (v === SUBJECT_CONSTANTS.misc.name && subjects.length === 0) {
          return true;
        }
        return subjects.includes(v);
      });
    });
  }

  const filteredLessonData = {
    ...lessonData,
    lessons: filteredLessons,
  };

  return filteredLessonData;
};

/** Vizzle Events
 * Vizzle Events consists of 2 parts
 * 1. event dispatcher - To dispatch an event from outside a react component e.g.service
 * 2. event listener - A react component in 'container' layer that listens to event and
 * update domain data.
 *
 * In some occasion we need to fire an event outside a react component but
 * recat library does not provide an emitting and listening event.
 *
 * Example usage will be
 * 1. When a user login, the user token needs to be updated in the
 * domain layer for the next service call.
 *
 * We cannot directly update the attribute token in the uiDomain from the service layer
 * as the service lives outside
 * a react component and it will make the service layer depends on the domain layer.
 * When an event is fired, the container EventListenerContainer
 * who listening this event will update the data in the uiDomain.
 * Then other containers can retrived the ui domain data and use them.
 *
 * 2. Second example - We want to check every respond that comes back from the
 * remote service is not 401 (Unauthorized). If so we need to display a dialog box to
 * inform the user and redirect to the login screen.
 * This can be done by triggering an event. Then the EventListenerContainer container update
 * uiDomain to display the dialog.
 */

const el = document.getElementById('root');

export const dispatchSessionTimeoutEvent = (message) => {
  const event = new CustomEvent('sessionTimeout', { detail: message });
  el.dispatchEvent(event);
};

export const createSessionTimeoutEventListener = (f) => {
  el.addEventListener('sessionTimeout', (e) => {
    if (f) {
      f(e);
    }
  });
};

import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './ExtensionActivitiesRenameFile.scss';
import ButtonWithSpinner from '../../../../../../../components/buttons/ButtonWithSpinner';
import { useTeacherCourses } from '../../../../../context/TeacherCourseContextProvider';
import { useHandleRenameFileForm, useHandleSubmitData } from './ExtensionActivitiesRenameFileHooks';

export default function ExtensionActivitiesRenameFile() {
  const {
    name,
    resetFileNameField,
    // error,
    handleOnFileNameFieldChange,
  } = useHandleRenameFileForm();

  const navigate = useNavigate();
  const { fileId } = useParams();

  const {
    handleSubmitData,
    isSaving,
  } = useHandleSubmitData({
    resetFileNameField,
  });

  const { extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities } = extensionActivitiesDomain.domainData;
  const { activities } = extensionActivities;
  const activity = activities.find((a) => String(a.id) === fileId);

  if (!activity) {
    navigate('..');
    return null;
  }

  return (
    <div className='extension-activities-rename-file' data-test='extension-activities-rename-file'>
      <Typography variant='h5' className='rename-file-bold' data-test='rename-file'>
        Rename
      </Typography>
      <PictureAsPdfIcon className='rename-file-icon font-fading-color' />

      <Typography variant='h6' className='rename-file-bold font-fading-color' data-test='current-file-name'>
        {activity.name}
      </Typography>

      <Typography variant='h5' className='rename-file-bold' data-test='to'>
        TO
      </Typography>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmitData(activity, name);
      }}
      >
        <div className='rename-file-input-area' data-test='rename-file-input-area'>

          <TextField
            className='rename-file-field'
            value={name}
            label='File Name'
            variant='standard'
            onChange={handleOnFileNameFieldChange}
            // helperText={error} // error message
            // error={!!error} // set to true to change the border/helperText color to red
            data-test='file-name-input'
          />
          <ButtonWithSpinner
            loading={isSaving}
            className='btn-primary rename-file-action'
            // disabled={!name || !name.trim() || error}
            disabled={!name || !name.trim()}
            label='Save'
            type='submit'
            data-test='rename-file-submit'
          />
          <ButtonWithSpinner
            onClick={() => {
              navigate(-1);
            }}
            loading={isSaving}
            className='btn-gray-background rename-file-action'
            disabled={isSaving}
            label='Close'
            data-test='rename-file-close'
          />
        </div>
      </form>
    </div>
  );
}

import differenceInDays from 'date-fns/differenceInDays';
import PlayLessonAction from '../../../../lessons/lessonactions/PlayLessonAction';
import EditLessonAction from '../../../../lessons/lessonactions/EditLessonAction';
import DateUtils from '../../../../../utils/DateUtils';
import ViewMtssPlanAction from './row/ViewMtssPlanAction';
import { findMtssLesson } from '../StudentLessonsHooks';

/**
 * Lesson card action
 */
// eslint-disable-next-line import/prefer-default-export
export const createLessonCardAction = ({ lesson, currentStudent, selectedStudentProfile, terminologies, allLessonsFromProfile }) => {
  const matchedLesson = findMtssLesson(lesson, allLessonsFromProfile);
  if ((matchedLesson && matchedLesson.mtss)) {
    return [
      <ViewMtssPlanAction
        key={`student-lesson-view-mtss-plan-${lesson.id}`}
        className='top-left'
        label='View MTSS Plan'
        userProfile={selectedStudentProfile}
        lesson={lesson}
      />,
    ];
  }
  const actions = [
    <PlayLessonAction
      key={`student-lesson-play-${lesson.id}`}
      lesson={lesson}
      className='play-lesson-card-action top-left'
      user={currentStudent}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
    />,
  ];
  if (lesson.meta.archived) {
    return actions;
  }

  if (lesson.attributes.systemId === 10000 && !lesson.attributes.libraryLessonFlag) {
    actions.push(<EditLessonAction lesson={lesson} key={`student-lesson-edit-${lesson.id}`} className='bottom-right' />);
  }
  return actions;
};

export const createLessonCardLabel = (lesson) => {
  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const elements = [];
  if (!lesson.meta.archived) {
    const diffDays = differenceInDays(DateUtils.convertYYYYMMddToDate(lesson.meta.lastAssignedDate), currentDate);

    let message = '';
    if (diffDays === 0) {
      message = 'Expires today';
    } else if (diffDays === 1) {
      message = 'Expires tomorrow';
    } else if (diffDays > 1) {
      message = [
        'Expires in ',
        // <br key={`${lesson.id}-new-line`} />,
        `${diffDays} days`,
      ];
    }
    elements.push(
      <div className='last-assignment-date-panel' key={`label-${lesson.id}`} data-test={`last-assignment-date-panel-${lesson.id}`}>
        {message}
      </div>,
    );
  }
  return elements;
};

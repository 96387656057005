import { useState } from 'react';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CardAction from '../../../components/card/CardAction';
import LessonPlay from '../../lessonplay/LessonPlay';
import './PlayLessonAction.scss';

/**
 * Play Lesson Action Container
 */
export default function PlayLessonAction({ lesson, label, className, user, userProfile, onLessonFinished, terminologies }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <CardAction
        icon={<PlayArrowIcon />}
        label={label}
        actionHandler={() => { setOpen(true); }}
        className={`btn-gray-background play-lesson-card-action ${className}`}
        data-test={`play-lesson-card-action-${lesson.id}`}
      />
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          user={user}
          userProfile={userProfile}
          onLessonFinished={onLessonFinished}
          terminologies={terminologies}
        />
      )}
    </>
  );
}

PlayLessonAction.defaultProps = {
  label: '',
  className: '',
  user: null,
  userProfile: null,
  onLessonFinished: () => { },
  terminologies: {},
};

PlayLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
  onLessonFinished: PropTypes.func,
};

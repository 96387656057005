import { useState, useImperativeHandle, useRef, useEffect, forwardRef } from 'react';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import SchoolForm from './forms/SchoolForm';
import { useDataSchoolObject } from './SchoolActionHooks';
import ViewButton from '../../../components/buttons/ViewButton';

const ViewSchoolAction = forwardRef((_props, ref) => {
  const [open, setOpen] = useState(false);

  const { districtData, schoolDataByDistrict, userSuperAdmin, selectedSchoolIds } = useDataSchoolObject();
  const data = useRef(null);

  useEffect(() => {
    if (open) {
      data.current = schoolDataByDistrict;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useImperativeHandle(ref, () => ({
    openUpdateSchool: () => {
      setOpen(true);
    },
  }));

  const handleOnCancel = () => {
    setOpen(false);
  };

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Close',
    },
    ];
    return buttons;
  };

  return (
    <>
      <ViewButton
        ariaLabel='View School'
        onClick={() => { setOpen(true); }}
        disabled={!districtData || !selectedSchoolIds || selectedSchoolIds.length !== 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='update-school-dialog'
          onClose={handleOnCancel}
          title='Update School'
          content={(
            <SchoolForm
              districtData={districtData}
              schoolData={schoolDataByDistrict}
              isSuperAdmin={userSuperAdmin}
              isDistrictEditable={userSuperAdmin}
              disabled
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
});

export default ViewSchoolAction;

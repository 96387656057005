import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { Card, CardContent, CardMedia } from '@mui/material';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import './CardActivity.scss';
import { useDetermineClickOrTouchEventForDnd } from '../../../../hooks/DndClickOrTouchHooks';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';

const CardActivity = forwardRef(({
  id,
  className,
  style,
  text,
  image,
  drawingImage,
  video,
  audio,
  disabled,
  onClick,
  fontScale,
  onSwitchAccessSelected,
  onMediaPlayFinishForClick,
  enableTts,
  children,
  ...rest
}, ref) => {
  const componentRef = useRef(null);
  const generatedId = useMemo(() => uuidv1(), []);
  const componentId = id || generatedId;

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      const result = componentRef.current;
      return result;
    },
  }));

  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const handleEvent = useDetermineClickOrTouchEventForDnd(
    (e) => {
      if (onClick) {
        onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
      }
    },
    true,
  );

  useRegisterSwitchAccessSelectEvent(componentRef, () => {
    onSwitchAccessSelected();
  });

  return (
    <Card
      id={componentId}
      key={componentId}
      className={`card-activity ${disabled ? 'disabled' : ''} vizzle-card-card-activity-page-${componentId} ${text ? '' : 'no-text'} ${className}`}
      ref={componentRef}
      data-skip-switch-access-click='true'
      style={style}
      {...handleEvent}
      data-test='card-activity'
      {...rest}
    >
      {
        (image || drawingImage) && (
          <CardMedia
            className={`card-activity-image relative ${text ? 'image-with-text' : 'only-image'}`}
            image={image}
            title='Image'
            data-test='card-activity-image'
          >
            {
              drawingImage && (
                <CardMedia
                  className={`card-activity-image ${text ? 'image-with-text' : 'only-image'} drawing-image`}
                  image={drawingImage}
                  title='Image'
                  data-test='card-activity-image'
                />
              )
            }
          </CardMedia>
        )
      }

      {text && (
        <CardContent data-test='card-activity-text' className={`card-activity-text ${image ? 'text-with-image' : 'only-text'}`}>
          <TextLines
            componentKey={`text-line-${componentId}`}
            textLines={text}
            fontScale={fontScale}
            ref={setTextLineRef}
          />
        </CardContent>
      )}

      {disabled || !enableTts ? '' : (audio || video || (elementToPlayTts && elementToPlayTts.length > 0))
        && (
          <MediaPlayer
            audioUrl={audio}
            videoUrl={video}
            textToRead={elementToPlayTts}
            tts={ttsConfig}
            parentElementId={componentId}
            className={`media-component vizzle-card-activity-page-${componentId}-media-btn`}
            disablePlay={disabled}
            forDndElement
            onMediaPlayFinishForClick={onMediaPlayFinishForClick}
          />
        )}
      {children}
    </Card>
  );
});

export default CardActivity;

CardActivity.defaultProps = {
  className: '',
  style: null,
  text: null,
  image: null,
  drawingImage: null,
  video: null,
  audio: null,
  disabled: false,
  id: null,
  fontScale: null,
  onClick: () => null,
  onSwitchAccessSelected: () => null,
  onMediaPlayFinishForClick: () => null,
  enableTts: true,
  children: null,
};

CardActivity.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.object,
  image: PropTypes.string,
  drawingImage: PropTypes.string,
  video: PropTypes.string,
  audio: PropTypes.string,
  disabled: PropTypes.bool,
  fontScale: PropTypes.number,
  onClick: PropTypes.func,
  onSwitchAccessSelected: PropTypes.func,
  onMediaPlayFinishForClick: PropTypes.func,
  enableTts: PropTypes.bool,
  children: PropTypes.node,
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import NotificationDialog from '../../components/dialog/NotificationDialog';
import SelectableLoader from '../../components/loaders/SelectableLoader';
import TransparentBlocker from '../../components/blocker/GrayTransparentBlocker';
import VizzleSnackbar from '../../components/snackbar/VizzleSnackbar';
import { useDomain } from '../../states/AppDomainProvider';
import { useHandleSessionExpired } from '../hooks/HandleSessionExpiredHook';

/**
 * Main container of the application.
 * Wrap the component of the application to ensure the consistency
 * of the common behaviour e.g. fading in, displaying sesstion timeout and etc.
 *
 * @param {*} props of
 * children - components to add inside the main container.
 * prop of the Fade component
 */
export default function VizzleMainContainer({ children, ...props }) {
  const { uiDomain } = useDomain();

  const {
    spinningLoader,
    snackbar,
    blockScreen,
  } = uiDomain.domainData;

  const onSnackbarClose = () => {
    uiDomain.closeSnackbar();
  };

  const {
    notificationDialog,
    onNotificationDialogClose,
  } = useHandleSessionExpired();

  useEffect(() => {
    uiDomain.blockScreen(false);
    // eslint-disable-next-line
  }, []);

  return (
    // default timeout is 1 second
    <div {...props}>
      <Fade timeout={1000} in>
        {children}
      </Fade>
      <SelectableLoader {...spinningLoader} />
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
      <NotificationDialog {...notificationDialog} onClose={onNotificationDialogClose} />
      <TransparentBlocker show={blockScreen} />
    </div>
  );
}

VizzleMainContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

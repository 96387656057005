import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ReinforcerSettingForm from '../../../../settings/reinforcers/ReinforcerSettingForm';

const ReinforcersSetting = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Reinforcers');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile, terminologies } = myStudentDomain.domainData;

  const onChange = (reinforcerValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      ...reinforcerValue,
    });
  };

  return (
    <ReinforcerSettingForm
      profile={selectedStudentProfile}
      terminologies={terminologies}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default ReinforcersSetting;

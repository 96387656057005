import { Typography } from '@mui/material';
import CopyLessonAction from './actions/CopyLessonAction';
import DeleteLessonAction from './actions/DeleteLessonAction';
import EditLessonAction from './actions/EditLessonAction';

export const createCardAction = (lesson, index) => (
  [
    <CopyLessonAction
      index={index}
      key={`${lesson.attributes.uuid}-copy-action-${index}`}
      lesson={lesson}
      className={`vizzle-copy-lesson-action-${lesson.attributes.uuid}`}
    />,
    <EditLessonAction
      key={`${lesson.attributes.uuid}-edit-action-${index}`}
      index={index}
      lesson={lesson}
      className={`vizzle-edit-lesson-action-${lesson.attributes.uuid}`}
    />,
    <DeleteLessonAction
      key={`${lesson.attributes.uuid}-delete-action-${index}`}
      index={index}
      lesson={lesson}
      className={`vizzle-delete-lesson-action-${lesson.attributes.uuid}`}
    />,
  ]
);

export const createAddtionalDetailPanel = ({ lesson }) => (
  <div className='authored-lesson-additional-detail-panel'>
    {lesson.lesson.attributes.gradeLevel && (
      <div className='row'>
        <Typography variant='h5' className='title'>Grade Level</Typography>
        <Typography className='grade-level'>{(lesson.lesson.attributes.gradeLevel)}</Typography>
      </div>
    )}
    {lesson.lesson.attributes.lessonKeywords && lesson.lesson.attributes.lessonKeywords.length > 0 && (
      <div>
        <Typography variant='h5' className='title'>Activity Keywords</Typography>
        <div className='keyword-container'>
          {lesson.lesson.attributes.lessonKeywords.map((k, index) => (
            <Typography key={`keyword-${lesson.id}-${index}`} className='keyword'>{k}</Typography>
          ))}
        </div>
      </div>
    )}
  </div>
);

import CampTheme from './themes/CampTheme';
import CastleTheme from './themes/CastleTheme';
import SpaceTheme from './themes/SpaceTheme';
import NeutralTheme from './themes/NeutralTheme';
import FootballTheme from './themes/FootballTheme';
import TreehouseTheme from './themes/TreehouseTheme';
import RetrofutureTheme from './themes/RetrofutureTheme';

export const themesMap = {
  camp: CampTheme,
  castle: CastleTheme,
  space: SpaceTheme,
  neutral: NeutralTheme,
  football: FootballTheme,
  treehouse: TreehouseTheme,
  retrofuture: RetrofutureTheme,
};

import PropTypes from 'prop-types';
import SelectorForm from './SelectorForm';
import useSettingForm from './useSettingForm';

const SettingSelectorForm = ({
  label,
  options,
  attributeNameToSave,
  tooltip,
}) => {
  const {
    loading,
    value,
    handleOnChange,
    handleOnClick,
  } = useSettingForm(label);

  return (
    <SelectorForm
      label={label}
      options={options}
      tooltip={tooltip}
      loading={loading}
      value={value}
      handleOnChange={handleOnChange}
      dataTest={attributeNameToSave}
      handleOnClick={() => {
        handleOnClick({
          [attributeNameToSave]: value,
        });
      }}
    />
  );
};

SettingSelectorForm.defaultProps = {
  options: [],
  tooltip: null,
};

SettingSelectorForm.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    name: PropTypes.string,
  })),
  attributeNameToSave: PropTypes.string.isRequired,
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default SettingSelectorForm;

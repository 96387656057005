import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VizzleAppbar from '../../components/appbar/VizzleAppbar';
import { useDomain } from '../../states/AppDomainProvider';
import './StudentLandingTopAppbar.scss';
import { useScreenReader } from '../screen-reader/ScreenReaderHooks';
import { useRegisterSwitchAccessScanEvent } from '../switch-access/SwitchAccessHooks';
import LogoutConfirmation from '../logout/LogoutConfirmation';
import { useLogout } from '../logout/LogoutHooks';
// import StudentLessonCommentAction from './lesson-comment/StudentLessonCommentAction';

/**
 * App bar of the application that contain infomation
 * and action that will be used across the app.
 */
const StudentLandingTopAppbar = forwardRef(({
  className,
  exitLabel,
  exitFunction,
  hideToolbar,
}, ref) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  // const { switchAccessibility } = userProfile;

  const exitButtonRef = useRef(null);
  const rethinkButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      const result = [exitButtonRef.current];
      if (rethinkButtonRef.current) {
        result.unshift(rethinkButtonRef.current);
      }
      return result;
    },
  }));

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  useRegisterSwitchAccessScanEvent(exitButtonRef, () => {
    readWhenScreenReaderIsOn(exitButtonRef.current);
  });

  useRegisterSwitchAccessScanEvent(rethinkButtonRef, () => {
    readWhenScreenReaderIsOn(rethinkButtonRef.current);
  });

  const {
    logoutDialog,
    openLogoutDialog,
    closeLogoutDialog,
  } = useLogout();

  if (hideToolbar) {
    return null;
  }
  return (
    <>
      <VizzleAppbar appbarPosition='top' className={`landing-top-app-bar ${className}`} data-test='student-landing-toolbar'>
        <Toolbar className='toolbar'>
          <Typography variant='h6' className='title' data-private data-test='welcome-text'>
            Welcome, {user.firstName} {user.lastName}
          </Typography>
          <Box className='actions'>
            <Box width='170px'>
              <Button
                ref={exitButtonRef}
                fullWidth
                className='btn-primary exit-button'
                onClick={() => {
                  if (exitFunction) {
                    exitFunction();
                  } else {
                    openLogoutDialog();
                  }
                }}
                alt={exitLabel}
                endIcon={<CloseIcon />}
                data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
                data-test='exit-button'
              >
                {exitLabel}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </VizzleAppbar>
      <LogoutConfirmation
        open={logoutDialog}
        onClose={closeLogoutDialog}
      />
    </>
  );
});

export default StudentLandingTopAppbar;

StudentLandingTopAppbar.defaultProps = {
  className: '',
  exitLabel: 'Exit Vizzle',
  exitFunction: null,
  hideToolbar: false,
};

StudentLandingTopAppbar.propTypes = {
  className: PropTypes.string,
  exitLabel: PropTypes.string,
  exitFunction: PropTypes.func,
  hideToolbar: PropTypes.bool,
};

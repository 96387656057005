import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import vizzleImageData from '../../../../../components/vizzle-logo-data-image';
import { addWrappedText } from '../../../../../components/export/PdfTableExport';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetUnit } from '../topic-lessons/TeacherCourseLessonsHooks';
import { uniqueByAttributes } from '../../../../../utils/ArrayUtils';

export const useGenerateStandardPdf = () => {
  const [dataUri, setDataUri] = useState(null);
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { standards } = teacherCourseDomain.domainData;
  const { hasPerformSearch, searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;

  const { userDomain } = useDomain();
  const { user: { state } } = userDomain.domainData;

  const { courseId, unitId } = searchCriteria;
  const unit = useGetUnit(courseId, unitId);

  useEffect(() => {
    if (!hasPerformSearch || !standards || standards.length === 0) {
      return;
    }

    const groupedStandards = {};
    const uniqueStandards = uniqueByAttributes(standards, 'standardCode');

    uniqueStandards.forEach((s) => {
      let group = groupedStandards[s.attributes.topicName];
      if (!group) {
        group = [];
      }
      group.push(s);
      groupedStandards[s.attributes.topicName] = group;
    });

    const title = `${state} Standards for Unit ${unit.name}`;
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    doc.setLineHeightFactor(1.3);

    let y = 20;
    const logo = {
      image: vizzleImageData,
      imageType: 'PNG',
      x: 90,
      y,
      width: 40,
      height: 11,
    };

    doc.addImage(logo.image, logo.imageType, logo.x, logo.y, logo.width, logo.height);

    y += 20;
    doc.setFontSize(16);
    doc.text(title, 15, y);

    const groupedStandardKeys = Object.keys(groupedStandards);
    groupedStandardKeys.sort((a, b) => {
      const aNum = a.substring(0, a.indexOf(' '));
      const bNum = b.substring(0, b.indexOf(' '));
      return aNum - bNum;
    });

    groupedStandardKeys.forEach((gsKey) => {
      y += 15;
      doc.setFontSize(15);
      doc.setFont(undefined, 'bold');
      doc.text(gsKey, 15, y);
      y += 10;

      const standardList = groupedStandards[gsKey];
      doc.setFontSize(12);
      doc.setFont(undefined, 'normal');
      standardList.forEach((s) => {
        const { attributes } = s;
        const standardText = `\u2022 ${attributes.standardCode}: ${attributes.standardDescription}`;
        y = addWrappedText({
          text: standardText,
          textWidth: 180,
          doc,
          lineSpacing: 5,
          xPosition: 15,
          initialYPosition: y,
          pageWrapInitialYPosition: 20,
        });
        y += 10;
      });
    });

    doc.setProperties({ title });
    const datauriString = doc.output('datauristring');
    setDataUri(datauriString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  return {
    dataUri,
  };
};

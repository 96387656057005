import { useEffect } from 'react';
import { getStudentGroups } from '../../../../services/StudentService';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import DeleteStudentGroupAction from './actions/DeleteStudentGroupAction';
import UpdateStudentGroupAction from './actions/UpdateStudentGroupAction';
import { useStudentGroupsContext } from './context/StudentGroupsContextProvider';

export const usePrepareStudentGroupTable = ({
  getStudentGroupsData,
}) => {
  const tableConfig = [
    {
      title: 'Group',
      align: 'left',
      dataField: 'name',
      width: '60%',
    },
    {
      title: 'Students',
      align: 'left',
      width: '20%',
      noSort: true,
      dataRenderer: (data) => {
        const numberOfStudents = data && data.students && data.students.length;
        return `${numberOfStudents} student${numberOfStudents > 1 ? 's' : ''}`;
      },
      dataField: 'numOfStudents',
    },
    {
      title: 'Actions',
      align: 'center',
      dataRenderer: (data) => {
        const actions = (
          <div className='table-row-actions-container' key={`student-group-table-action-${data.id}`}>
            <UpdateStudentGroupAction
              group={data}
              onStudentGroupUpdated={() => {
                getStudentGroupsData();
              }}
            />
            <DeleteStudentGroupAction
              group={data}
              onStudentGroupDeleted={() => {
                getStudentGroupsData();
              }}
            />
          </div>
        );
        return actions;
      },
      width: '20%',
      noSort: true,
    },
  ];
  const { studentGroupsDomain } = useStudentGroupsContext();
  const { studentGroups } = studentGroupsDomain.domainData;

  return {
    tableConfig,
    tableData: studentGroups,
  };
};

export const useGetStudentGroups = () => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const { studentGroupsDomain } = useStudentGroupsContext();

  const getStudentGroupsData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getStudentGroups();
        studentGroupsDomain.updateStudentGroups(result.data);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };
  return {
    loading,
    getStudentGroupsData,
  };
};

export const usePrepareStudentGroups = () => {
  const {
    loading,
    getStudentGroupsData,
  } = useGetStudentGroups();

  useEffect(() => {
    getStudentGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    getStudentGroupsData,
  };
};

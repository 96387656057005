import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CustomTooltip from '../../../../../components/card/CustomTooltip';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import '../../buttons/Buttons.scss';
import Logger from '../../../../../utils/Logger';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';

const ExportData = ({
  getDataFunction,
  ariaLabel,
  buttonLabel,
  disabled,
  exportFileName,
  exportingMessage,
  className,
  checkBeforeLoad,
  noIcon,
}) => {
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const { uiDomain } = useDomain();

  return (
    <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
      <span>
        <Button
          variant='contained'
          className={`btn-primary admin-action-button ${className}`}
          startIcon={!noIcon ? <GetAppIcon /> : null}
          onClick={async () => {
            try {
              await callRemoteServiceWrapper(async () => {
                if (!checkBeforeLoad()) {
                  return;
                }
                await uiDomain.showLoader(exportingMessage, LOADER_TYPE.DOT_LOADER);
                const data = await getDataFunction();
                await uiDomain.hideLoader();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(data);
                link.download = exportFileName;
                link.click();
              });
            } catch (e) {
              Logger.logWhenDebugModeIsOn(e);
              await uiDomain.hideLoader();
            }
          }}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </span>
    </CustomTooltip>
  );
};

ExportData.defaultProps = {
  getDataFunction: () => null,
  ariaLabel: '',
  buttonLabel: '',
  disabled: false,
  exportingMessage: 'Exporting Data.',
  className: '',
  checkBeforeLoad: () => true,
  noIcon: false,
};

ExportData.propTypes = {
  getDataFunction: PropTypes.func,
  ariaLabel: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  exportFileName: PropTypes.string.isRequired,
  exportingMessage: PropTypes.string,
  className: PropTypes.string,
  checkBeforeLoad: PropTypes.func,
  noIcon: PropTypes.bool,
};

export default ExportData;

import { useDistrictTable, /* useGetDistrictData, */ useHandleDistrictTable } from './DistrictsHooks';
import { useAdmin } from '../../context/AdminContextProvider';
import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
// import AddDistrictAction from './actions/AddDistrictAction';
// import UpdateDistrictAction from './actions/UpdateDistrictAction';
// import SetDistrictExpiration from './actions/SetDistrictExpiration';
// import ImportLicensesAction from '../../components/actions/import/import-license/ImportLicensesAction';
// import { districtsBatchUpload } from '../../../../services/AdminService';
import '../../AdminCommon.scss';
import ViewDistrictAction from './actions/ViewDistrictAction';
// import UpdateDistrictAdminAction from './actions/UpdateDistrictAdminAction';
// import { useDomain } from '../../../../states/AppDomainProvider';

const Districts = () => {
  const { districtsDomain } = useAdmin();
  const { districts, loading } = districtsDomain.domainData;

  // const { userDomain } = useDomain();
  // const { user } = userDomain.domainData;
  // const {
  //   userSuperAdmin,
  // } = user;

  const {
    tableConfig,
  } = useDistrictTable();

  const {
    selectedDistrictIds,
    handleOnSelectedRowIds,
    updateDistrictRef,
  } = useHandleDistrictTable();

  // const {
  //   getDistrictsData,
  // } = useGetDistrictData();

  return (
    <div className='admin-tab-districts full-height'>
      <SearchAndSortableTable
        config={tableConfig}
        tableData={districts}
        initTableState={{
          order: 'desc',
          orderBy: 'name',
        }}
        searchPlaceholder='Filter by District Name'
        searchField='name'
        toolbarActions={[
          // <AddDistrictAction key='admin-add-district-action' />,

          // userSuperAdmin
          //   ? <UpdateDistrictAdminAction key='admin-update-district-admin-action' ref={updateDistrictRef} />
          //   : <UpdateDistrictAction key='admin-update-district-action' ref={updateDistrictRef} />,

          // <SetDistrictExpiration key='admin-district-expiration-action' />,
          // <ImportLicensesAction
          //   key='admin-district-import-licenses'
          //   id='admin-district-import-licenses-id'
          //   uploadServiceFunction={districtsBatchUpload}
          //   ariaLabel='Import District'
          //   uploadingMessage='Uploading Districts.'
          //   onUploadSuccess={async () => { await getDistrictsData(); }}
          //   failedToUploadTitle='Failed districts'
          // />,
          <ViewDistrictAction key='admin-view-district-action' ref={updateDistrictRef} />,
        ]}
        dataId='id'
        selectedRowIds={selectedDistrictIds}
        onSelectedRowIds={handleOnSelectedRowIds}
        loading={loading}
        autoResetTableState={false}
      />
    </div>
  );
};

export default Districts;

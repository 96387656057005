import { useAdmin } from '../../../../context/AdminContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { sort } from '../../../../../../utils/ArrayUtils';

const transformUserData = (item) => ({
  key: item.id,
  name: `${item.attributes.firstName} ${item.attributes.lastName} (${item.attributes.userName})`,
  value: `${item.attributes.firstName}, ${item.attributes.userName}`,
});

export const usePrepareData = () => {
  const { adminDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;
  const { loading } = useGetRemoteServiceWrapper();

  let transformUser = [];

  if (usersAndLicensesData && usersAndLicensesData.data && usersAndLicensesData.data.relationships && usersAndLicensesData.data.relationships.teachers) {
    const users = usersAndLicensesData.data.relationships.teachers.data.map((d) => usersAndLicensesData.included.find((li) => li.id === d.id && li.type === d.type));
    transformUser = users.map((user) => transformUserData(user));
    sort(transformUser, 'name');
  }
  return {
    loading,
    transformUser,
  };
};

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useState } from 'react';
import CustomDialog from '../../../../../../../components/dialog/CustomDialog';
import ScoreAnswerForm from './form/ScoreAnswerForm';
import './ViewAnswerAction.scss';
import { findFfrPage } from '../../../../../../lessons/lessonmaintenance/hooks/LessonMaintenanceHooks';
import { ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA } from '../../../../../../../AppConstants';
import { UNDER_REVIEW_ID } from '../../../../../components/tracking/actions/LessonTrackingConstants';

const ViewAnswerAction = ({
  answerLesson,
  answerPageData,
  parentAnswerFreeFormData,
  pageNumber,
  lessonPageData,
  onSaveSuccess,
  readOnly,
}) => {
  const [open, setOpen] = useState(false);

  const isFfrPage = findFfrPage({ pages: [lessonPageData] });
  if ((answerLesson.reviewStatus === UNDER_REVIEW_ID && isFfrPage) || answerLesson.reviewStatus !== UNDER_REVIEW_ID) {
    let backgroundImage = null;
    if (lessonPageData.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
      backgroundImage = lessonPageData.cards[1].image;
    }

    return (
      <>
        <Button
          className='btn-primary view-answer-action'
          data-test='free-form-answer-view-answer-action'
          onClick={() => {
            setOpen(true);
          }}
        >
          VIEW ANSWER
        </Button>

        {open && (
          <CustomDialog
            openDialog={open}
            className='view-answer-action-dialog'
            onClose={() => {
              setOpen(false);
            }}
            content={(
              <ScoreAnswerForm
                answerLesson={answerLesson}
                answerPageData={answerPageData}
                parentAnswerFreeFormData={parentAnswerFreeFormData}
                pageNumber={pageNumber}
                backgroundImage={backgroundImage}
                onSaveSuccess={async () => {
                  onSaveSuccess();
                  setOpen(false);
                }}
                readOnly={readOnly}
                onClose={() => {
                  setOpen(false);
                }}
              />
            )}
            displayCloseIcon
          />
        )}
      </>
    );
  }
  return null;
};

ViewAnswerAction.defaultProps = {
  answerLesson: {},
  answerPageData: {},
  parentAnswerFreeFormData: {},
  pageNumber: 1,
  lessonPageData: {},
  onSaveSuccess: () => null,
  readOnly: false,
};

ViewAnswerAction.propTypes = {
  answerLesson: PropTypes.any,
  answerPageData: PropTypes.any,
  parentAnswerFreeFormData: PropTypes.any,
  pageNumber: PropTypes.number,
  lessonPageData: PropTypes.any,
  onSaveSuccess: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ViewAnswerAction;

import PropTypes from 'prop-types';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import './SearchLessonPendingFilter.scss';
import { FILTER_STATUS_FOR_COPY } from '../../../../../../myclassroom/components/authored-lessons/AuthoredLessonHooks';

const SearchLessonPendingFilter = ({
  pendingFilterValue,
  onFilterUpdate,
}) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const handleStatusChange = (e) => {
    onFilterUpdate(e.target.value);
  };

  const filterStatusForCopy = user.userModerator ? [...FILTER_STATUS_FOR_COPY] : [
    FILTER_STATUS_FOR_COPY[0],
    FILTER_STATUS_FOR_COPY[2],
  ];

  return (
    <div className='search-lesson-pending-switch'>
      <FormControl component='fieldset' onChange={handleStatusChange}>
        <RadioGroup
          aria-label='Activity status'
          name='lessonStatus'
          value={pendingFilterValue || FILTER_STATUS_FOR_COPY[0].value}
          className='radio-container'
        >
          {filterStatusForCopy.map((l, index) => (
            <FormControlLabel
              key={`lesson-search-lessons-status-${index}`}
              value={l.value}
              control={<Radio />}
              label={l.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

SearchLessonPendingFilter.defaultProps = {
  onFilterUpdate: () => null,
  pendingFilterValue: '',
};

SearchLessonPendingFilter.propTypes = {
  pendingFilterValue: PropTypes.string,
  onFilterUpdate: PropTypes.func,
};

export default SearchLessonPendingFilter;

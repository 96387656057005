export const getPageStyle = (page, includeBorder) => {
  const style = {};
  if (page.bgColor) {
    style.backgroundColor = page.bgColor;
  }

  if (includeBorder && page.borderColor && page.borderColor !== '#000000') {
    style.borderStyle = 'solid';
    style.borderWidth = '5px';
    style.borderColor = page.borderColor;
  } else {
    style.borderStyle = 'none';
    style.borderWidth = '0px';
  }

  return style;
};

import {
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getLessonLevels } from '../../../../../services/LessonStandardService';
import { lessonLevelTooltipText } from '../../../../../texts/TooltipText';
import Logger from '../../../../../utils/Logger';

/**
 * Container for Subject selection in Lesson Save screen
 *
 * @export
 */
export default function LessonLevelSelector() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const handleLevelSelected = (event) => {
    Logger.logWhenDebugModeIsOn({
      SELECTED_LEVEL: event.target.value,
    });

    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        lessonLevel: event.target.value,
      },
    };

    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  return (
    <Box width='100%' data-test='lesson-level-selector'>
      <Box display='flex'>
        <Typography variant='h6'>
          Level
        </Typography>
        <TooltipWithIndicator className='vizzle-level-lesson-tooltip margin-left-medium' {...lessonLevelTooltipText} />
      </Box>
      <TextField
        select
        value={lessonData.attributes.lessonLevel || ''}
        onChange={handleLevelSelected}
        variant='outlined'
        placeholder='Subject'
        fullWidth
        disabled={!lessonData.attributes.subjects || lessonData.attributes.subjects.length === 0}
        className='vizzle-lesson-save-level-selector dropdown-small-padding'
      >
        {getLessonLevels().map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

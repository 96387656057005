import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import './GridList.scss';
import { Box } from '@mui/material';

/**
 * Grid list component to display list of element e.g. lesson cards
 *
 * @param {*} props
 *
 * titleName: Title of the slidablelist
 * children: elements to display in the list
 */
export default function GridList({
  titleName,
  subTitle,
  slidesToScroll,
  // slidesToShow,
  children,
  canSwipe,
  gradient,
  displayFastNavigator,
  placeHolderChildren,
  onTitleClick,
  ...rest
}) {
  const [state, setState] = useState({
    previousButtonDisable: true,
    nextButtonDisable: true,
  });

  const childrenLength = children.length;

  useEffect(
    () => {
      if (children.length > 0) {
        setState({
          ...state,
          nextButtonDisable: (0 + slidesToScroll) >= childrenLength,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  );
  const sliderRef = useRef(null);

  const afterSliderChage = (newIndex) => {
    setState({
      ...state,
      previousButtonDisable: newIndex <= 0,
      nextButtonDisable: (newIndex + slidesToScroll) >= childrenLength,
    });
  };

  const settings = {
    infinite: false,
    speed: 500,
    variableWidth: true,
    slidesToScroll,
    // slidesToShow,
    // lazyLoad: 'ondemand',
    className: 'slider',
    ref: sliderRef,
    afterChange: afterSliderChage,
    swipe: canSwipe,
  };

  const next = () => {
    if (!state.nextButtonDisable) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (!state.previousButtonDisable) {
      sliderRef.current.slickPrev();
    }
  };

  const fastBackward = () => {
    if (!state.previousButtonDisable) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const fastForward = () => {
    if (!state.nextButtonDisable) {
      sliderRef.current.slickGoTo(children.length - 1);
    }
  };

  return (
    <div {...rest} className={`grid-list-container ${gradient ? 'blue-gradient' : ''}`}>
      <h2 className='grid-list-container-header-for-vizzle-test'>
        {displayFastNavigator && (
          <FastRewindRoundedIcon
            className={`grid-list-fast-navigate ${state.previousButtonDisable ? 'grid-list-navigate-disable' : ''}`}
            onClick={fastBackward}
          />
        )}

        <PlayCircleFilledIcon
          className={`margin-right-medium grid-list-navigate-previous ${state.previousButtonDisable ? 'grid-list-navigate-disable' : ''}`}
          onClick={previous}
        />
        {subTitle && (
          <span className='sub-title'>
            {subTitle}
          </span>
        )}
        {onTitleClick ? (
          <Box
            className='clickable-title'
            onClick={() => {
              onTitleClick();
            }}
          >
            {titleName}
          </Box>
        ) : (
          <span data-private>
            {titleName}
          </span>
        )}
        <PlayCircleFilledIcon
          className={`margin-left-medium grid-list-navigate-next ${state.nextButtonDisable ? 'grid-list-navigate-disable' : ''}`}
          onClick={next}
        />
        {displayFastNavigator && (
          <FastForwardRoundedIcon
            className={`grid-list-fast-navigate ${state.nextButtonDisable ? 'grid-list-navigate-disable' : ''}`}
            onClick={fastForward}
          />
        )}
      </h2>
      {children && (
        <Slider {...settings}>
          {children}
          <div />
        </Slider>
      )}

      {placeHolderChildren}
    </div>
  );
}

GridList.defaultProps = {
  gradient: true,
  children: <div />,
  canSwipe: true,
  subTitle: null,
  displayFastNavigator: false,
  placeHolderChildren: null,
  onTitleClick: null,
};

GridList.propTypes = {
  gradient: PropTypes.bool,
  canSwipe: PropTypes.bool,
  titleName: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  slidesToScroll: PropTypes.number.isRequired,
  // slidesToShow: PropTypes.number.isRequired,
  children: PropTypes.node,
  displayFastNavigator: PropTypes.bool,
  placeHolderChildren: PropTypes.node,
  onTitleClick: PropTypes.func,
};

import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CustomTooltip from '../../../../components/card/CustomTooltip';
import './Buttons.scss';

const GenericButton = ({
  ariaLabel,
  onClick,
  disabled,
  icon: Icon,
  label,
}) => (
  <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
    <span>
      <IconButton className='admin-action-icon-button' disabled={disabled} aria-label={ariaLabel} onClick={onClick}>
        <Icon />
      </IconButton>
      <span className='admin-action-icon-button-label'>{label}</span>
    </span>
  </CustomTooltip>
);

GenericButton.defaultProps = {
  ariaLabel: '',
  onClick: () => null,
  disabled: false,
  icon: null,
  label: null,
};

GenericButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  label: PropTypes.string,
};

export default GenericButton;

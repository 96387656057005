import PropTypes from 'prop-types';
import './GrayTransparentBlocker.scss';

/**
 * A component for blocking user interaction on the screen
 */
export default function GrayTransparentBlocker({ show }) {
  return show ? <div className='gray-transparent-blocker' /> : '';
}

GrayTransparentBlocker.defaultProps = {
  show: false,
};

GrayTransparentBlocker.propTypes = {
  show: PropTypes.bool,
};

import ShareLinkLessonAction from '../../../../../lessons/lessonactions/ShareLinkLessonAction';
import CopyToEditAction from '../../../../../lessons/lessonactions/CopyToEditAction';
import PlayLessonDetailAction from '../../../../../lessons/lessonactions/PlayLessonDetailAction';
import PlayLessonAction from '../../../../../lessons/lessonactions/PlayLessonAction';
import DropdownAction from '../../../../../lessons/lessonactions/dropdown/DropdownAction';
import PrintLessonMenuItem from '../../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import { isMobile } from '../../../../../../utils/DeviceUtils';
import LessonPreviewMenuItem from '../../../../../lessons/lessonactions/dropdown/LessonPreviewMenuItem';
import LessonTrackingAddRemoveFromGroupPlay from './LessonTrackingAddRemoveFromGroupPlay';
import DeleteAllAssignmentAction from '../../../lessons/actions/dropdown/DeleteAllAssignmentAction';
import {
  FULL_SORTED_SUBJECT_CONSTANTS,
  SUBJECT_CONSTANTS,
} from '../../../../../../constants/LessonConstant';

export const createLessonCardDetailAction = (lesson, currentUser, selectedStudentProfile, handleOnCloseDetail, reloadObjectiveData, terminologies) => {
  const actions = [];
  actions.push(
    (<PlayLessonAction
      label='PLAY'
      lesson={lesson}
      key={`objective-lesson-detail-play-${lesson.id}`}
      user={currentUser}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
      onLessonFinished={reloadObjectiveData}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`objective-lesson-detail-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<LessonTrackingAddRemoveFromGroupPlay
      lesson={lesson}
      key={`objective-lesson-detail-add-remove-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    <LessonPreviewMenuItem
      key={`objective-lesson-detail-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
      onLessonFinished={reloadObjectiveData}
    />,
  ];

  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`objective-lesson-detail-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={selectedStudentProfile}
      />
    ));
  }

  const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.name === lesson.attributes.subjects[0]) || SUBJECT_CONSTANTS.misc;
  dropdownActions.push((
    <DeleteAllAssignmentAction
      key={`student-lesson-dropdown-delete-all-${lesson.id}`}
      lesson={lesson}
      subjectId={subject.subjectId}
      student={currentUser}
    />
  ));

  actions.push(
    (
      <DropdownAction
        key={`objective-lesson-detail-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  actions.push(
    (
      <CopyToEditAction
        lesson={lesson}
        currentUser={currentUser}
        key={`objective-lesson-detail-copy-to-edit-${lesson.id}`}
        fromStudentId={currentUser.id}
      />
    ),
  );

  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, currentUser, reloadObjectiveData, selectedStudentProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`objective-lesson-detail-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      onLessonFinished={reloadObjectiveData}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

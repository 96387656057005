import { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../states/AppDomainProvider';
import ObjectUtil from '../../../utils/ObjectUtils';
import { submitNewPassword } from '../../../services/LoginService';
import Logger from '../../../utils/Logger';

export const STATE = {
  RESET_FORM: 0,
  RESET_SUCCESS: 1,
};

const ERROR_MESSAGE = {};
ERROR_MESSAGE.RESET_EXPIRE = 'Reset code has expired';
ERROR_MESSAGE.INVALID_PASSWORD = 'Password cannot be less than 4 characters';
ERROR_MESSAGE.INVALID_RESET_CODE = 'Reset code is invalid';

export const useControlResetPassword = () => {
  const [state, setState] = useState({
    slide: {
      in: true,
      direction: 'left',
    },
    form: STATE.RESET_FORM,
  });
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  // eslint-disable-next-line camelcase
  const { reset_code } = useParams();
  const { uiDomain } = useDomain();

  const handleOnResetPassword = async (value) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await submitNewPassword(value, reset_code);
        const { message } = result.data;
        if (message === 'SUCCESS') {
          setState({
            ...state,
            slide: {
              in: false,
              direction: 'right',
            },
          });
          ObjectUtil.setTimeout(() => {
            setState({
              ...state,
              slide: {
                in: true,
                direction: 'left',
              },
              form: STATE.RESET_SUCCESS,
            });
          }, 1000);
        } else {
          await uiDomain.showSnackbar(true, 'error', 300000, ERROR_MESSAGE[message]);
        }
      });
    } catch (e) {
      Logger.logError(e);
    }
  };
  return {
    state,
    loading,
    handleOnResetPassword,
  };
};

export const useHandleSnackbar = () => {
  const { uiDomain } = useDomain();
  const {
    snackbar,
  } = uiDomain.domainData;

  const onSnackbarClose = () => {
    uiDomain.closeSnackbar();
  };

  return {
    snackbar,
    onSnackbarClose,
  };
};

import PropTypes from 'prop-types';
import {
  MenuItem,
  TextField,
} from '@mui/material';
import './PlayerSetup.scss';
import { playerPieceItems } from '../../../../../../components/vizzle/page/game/setup/PlayerPieceData';

const PlayerSetup = ({
  name,
  selectedPiece,
  onDataChange,
  excludePieces,
}) => {
  const handleOnChange = (prop, value) => {
    onDataChange(prop, value);
  };

  return (
    <div className='game-player-setup'>
      <TextField
        label='Name'
        value={name}
        className='name-input'
        onChange={(e) => handleOnChange('name', e.target.value)}
        data-test='name-input'
      />
      &nbsp;
      <TextField
        select
        label='Select Piece'
        value={selectedPiece}
        placeholder='Select Piece'
        fullWidth
        className='piece-selector'
        data-test='piece-selector'
        onChange={(e) => handleOnChange('selectedPiece', e.target.value)}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              className: 'player-piece-selector-pop-over',
              style: {
                // maxHeight: '300px',
              },
            },
          },
        }}
      >
        {playerPieceItems.filter((option) => !excludePieces.includes(option.value)).map((option) => (
          <MenuItem key={option.id} value={option.value} data-test={`piece-${option.id}`}>
            {option.text}
            <img
              className='piece-icon'
              src={option.image}
              alt='Piece'
              style={{
                marginLeft: 'auto',
                marginRight: '10px',
                width: '2em',
              }}
            />
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

PlayerSetup.defaultProps = {
  name: '',
  selectedPiece: 0,
  onDataChange: () => { },
  excludePieces: [],
};

PlayerSetup.propTypes = {
  name: PropTypes.string,
  selectedPiece: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onDataChange: PropTypes.func,
  excludePieces: PropTypes.arrayOf(PropTypes.number),
};

export default PlayerSetup;

import { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CardAction from '../../../../components/card/CardAction';
import './DropdownAction.scss';

const DropdownAction = ({
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CardAction
        icon={<MoreHorizIcon />}
        label=''
        actionHandler={(e) => {
          handleClick(e);
        }}
        className='dropdown-action'
        data-test='dropdown-more-action'
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='lesson-detail-dropdown-menu'
      >
        {menuItems.map((item) => (
          cloneElement(
            item,
            {
              onClick: handleClose,
            },
          )
        ))}
      </Menu>
    </>
  );
};

DropdownAction.defaultProps = {
  menuItems: null,
};

DropdownAction.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.node),
};

export default DropdownAction;

import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import {
  Button,
} from '@mui/material';
import vizzleImageData from '../vizzle-logo-data-image';
import DateUtils from '../../utils/DateUtils';

const formattedCurrentDate = DateUtils.convertDateToMMddYYYY(DateUtils.getCurrentDate());
export const buildDataTable = (config, tableData) => {
  const headers = config.map((c) => {
    const { width } = c;

    let columnWidth = 35;

    if (!Number.isInteger(width)) {
      const widthString = String(width);
      columnWidth = widthString.endsWith('%') ? Number(width.substring(0, width.length - 1)) * 2.5 : columnWidth;
    }

    return {
      id: c.dataField,
      name: c.dataField,
      prompt: c.title,
      width: columnWidth,
      align: c.align,
      padding: 0,
    };
  });

  const data = tableData.map((t) => {
    const object = {};
    config.forEach((c) => {
      const { dataRenderer, skipDataRenderForReport } = c;
      if (skipDataRenderForReport) {
        object[c.dataField] = t[c.dataField] ? String(t[c.dataField]) : ' ';
      } else {
        object[c.dataField] = dataRenderer ? dataRenderer(t) : String(t[c.dataField]);
      }
    });

    return object;
  });

  return {
    headers,
    data,
  };
};

export const addWrappedText = ({
  text,
  textWidth,
  doc,
  lineSpacing = 7,
  xPosition = 10,
  initialYPosition = 10,
  pageWrapInitialYPosition = 10,
}) => {
  const textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
  const pageHeight = doc.internal.pageSize.height; // Get page height, well use this for auto-paging
  // doc.setFontType(fontType);
  // doc.setFontSize(fontSize);

  let cursorY = initialYPosition;

  textLines.forEach((lineText) => {
    if (cursorY > pageHeight) { // Auto-paging
      doc.addPage();
      cursorY = pageWrapInitialYPosition;
    }
    doc.text(xPosition, cursorY, lineText);
    cursorY += lineSpacing;
  });

  return cursorY;
};

/**
 * A component for blocking user interaction on the screen
 */
export default function PdfTableExport({
  config,
  tableData,
  filename,
  title,
  className,
  customPrint,
}) {
  const handleOnClick = () => {
    const logo = {
      image: vizzleImageData,
      imageType: 'PNG',
      x: 90,
      y: 10,
      width: 30,
      height: 8,
    };

    const titleRow = {
      titleText: title,
      x: 105,
      y: 25,
      options: null,
      transform: null,
      align: 'center',
    };

    const reportCreated = {
      title: `Report Created: ${formattedCurrentDate}`,
      x: 10,
      y: 33,
    };

    const { headers, data } = buildDataTable(config, tableData);
    const table = {
      headers,
      data,
      x: 10,
      y: 40,
      options: { fontSize: 8, padding: 2, margins: 0, overflow: 'linebreak', overflowColumns: false },
    };

    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.setLineHeightFactor(1.3);

    if (customPrint) {
      customPrint({
        logo,
        titleRow,
        reportCreated,
        table,
        filename,
      }, doc);
    } else {
      doc.addImage(logo.image, logo.imageType, logo.x, logo.y, logo.width, logo.height);
      doc.text(titleRow.titleText, titleRow.x, titleRow.y, titleRow.options, titleRow.transform, titleRow.align);

      doc.setFontSize(10);
      doc.text(reportCreated.title, reportCreated.x, reportCreated.y);

      doc.setFontSize(5);
      doc.table(table.x, table.y, table.data, table.headers, table.options);
      doc.save(filename);
    }
  };

  return (
    <div>
      <Button
        className={`${className} btn-primary`}
        startIcon={<PictureAsPdfIcon />}
        variant='contained'
        onClick={handleOnClick}
        data-test='pdf-export'
      >
        PDF
      </Button>
    </div>
  );
}

PdfTableExport.defaultProps = {
  config: [],
  tableData: [],
  className: '',
  filename: 'VizZle NextGen.pdf',
  title: null,
  customPrint: null,
};

PdfTableExport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    skipDataRenderForReport: PropTypes.bool,
  })),
  className: PropTypes.string,
  filename: PropTypes.string,
  title: PropTypes.string,
  customPrint: PropTypes.func,
};

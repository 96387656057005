import { Slide, TextField } from '@mui/material';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import VizzleSnackbar from '../../../components/snackbar/VizzleSnackbar';
import { useHandleSnackbar, useHandleCodeValue, useSubmitCode } from './EnterTwoFactorAuthenticationHooks';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import PaperSheetError from '../../../components/paper/PaperSheetError';
import './EnterTwoFactorAuthentication.scss';
import { logout } from '../../../services/LoginService';

const EnterTwoFactorAuthentication = () => {
  const {
    snackbar,
    onSnackbarClose,
  } = useHandleSnackbar();

  const {
    value,
    handleOnTextChange,
  } = useHandleCodeValue();

  const {
    showError,
    loading,
    login,
    slide,
  } = useSubmitCode();

  const handleOnKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await login(value);
    }
  };

  const handleOnLoginClick = async (e) => {
    e.preventDefault();
    await login(value);
  };

  return (
    <>
      <Slide
        timeout={600}
        in={slide.in}
        direction={slide.direction}
        mountOnEnter
        unmountOnExit
      >
        <div className='enter-two-factor-authentication'>
          <div className='vizzle-logo-container'>
            <img src={VizzleLogo} alt='Vizzle Logo' />
          </div>
          <div className='title'>
            Two Factor Authentication
          </div>
          <div className='content'>
            <TextField
              className='text-form'
              value={value}
              label='Enter two factor authentication code'
              inputProps={{
                maxLength: 6,
              }}
              type='text'
              onKeyPress={handleOnKeyPress}
              onChange={handleOnTextChange}
              autoFocus
            />
            <div className='actions'>
              <ButtonWithSpinner
                className='btn-primary'
                label='Login'
                fullWidth
                disabled={!value || value.length < 6}
                loading={loading}
                onClick={handleOnLoginClick}
              />
            </div>

            {showError && (
              <div className='error'>
                <PaperSheetError message='Invalid authentication code' className='form-error-area' />
                <div className='actions'>
                  <ButtonWithSpinner
                    className='btn-primary'
                    label='Return to Login Page'
                    fullWidth
                    onClick={() => {
                      logout(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Slide>
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </>
  );
};

export default EnterTwoFactorAuthentication;

import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import ChooseNumberOfCauseAndEffect from './editor/causeandsequence/ChooseNumberOfCauseAndEffect';
import CauseAndEffectEditor from '../../../../../components/vizzle/page/causeandeffect/CauseAndEffectEditor';
import { SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../../../../../components/vizzle/page/common/CardEditorConstants';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddAudio from './editor/AddAudio';

const initState = {
  selectedIndex: 0,
  selectedSection: SECTION_IMAGE,
  isCauseSelected: true,
};

export default function CauseAndEffectTextAndMedia() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  const getCard = (cardName, numberOfCards) => {
    const currentCards = [...selectedPage[cardName]];
    const currentNumberOfCards = selectedPage[cardName].filter((card) => (
      !card.hide
    )).length;

    if (currentNumberOfCards > numberOfCards) {
      for (let i = numberOfCards; i < currentNumberOfCards; i++) {
        currentCards[i].hide = true;
      }
    } else if (currentNumberOfCards < numberOfCards) {
      for (let i = 0; i < numberOfCards; i++) {
        if (!currentCards[i]) {
          currentCards[i] = { key: i };
        }
        currentCards[i].hide = false;
      }
    }
    return currentCards;
  };

  const handleOnSliderChange = (event, value) => {
    event.stopPropagation();
    const newPageData = {
      ...selectedPage,
      causeCards: getCard('causeCards', value),
      effectCards: getCard('effectCards', value),
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
    setCurrentSelectedData({ ...initState });
  };

  useEffect(() => {
    // When the user select a new page, reset the current selected matching to the first one
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line
  }, [currentSelectedPagesData]);

  const onTextApply = async (textLines) => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }

    const { text } = selectedPage[field][currentSelectedData.selectedIndex];
    const currentTextLines = text ? text.lines : [];
    const newTextLines = textLines.map((e, index) => ({
      ...currentTextLines[index],
      ...e,
    }));
    if (!selectedPage[field][currentSelectedData.selectedIndex].text) {
      selectedPage[field][currentSelectedData.selectedIndex].text = {};
    }
    selectedPage[field][currentSelectedData.selectedIndex].text.lines = newTextLines;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemove = async () => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }

    const card = {
      ...selectedPage[field][currentSelectedData.selectedIndex],
      text: null,
    };
    selectedPage[field][currentSelectedData.selectedIndex] = card;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageApply = async (media) => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }

    selectedPage[field][currentSelectedData.selectedIndex].image = media.selectedImageUrl;
    selectedPage[field][currentSelectedData.selectedIndex].thumbNailImage = media.selectedImageUrl;
    selectedPage[field][currentSelectedData.selectedIndex].video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onImageRemove = async () => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }
    selectedPage[field][currentSelectedData.selectedIndex].image = null;
    selectedPage[field][currentSelectedData.selectedIndex].thumbNailImage = null;
    selectedPage[field][currentSelectedData.selectedIndex].video = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioApply = async (audioData) => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }

    selectedPage[field][currentSelectedData.selectedIndex].audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onAudioRemoved = async () => {
    let field = 'effectCards';
    if (currentSelectedData.isCauseSelected) {
      field = 'causeCards';
    }

    selectedPage[field][currentSelectedData.selectedIndex].audio = null;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <Box display='flex' flexDirection='column' height='100%'>
            <Box width='50%'>
              <ChooseNumberOfCauseAndEffect
                onSliderChange={handleOnSliderChange}
                value={
                  selectedPage && selectedPage.causeCards ? selectedPage.causeCards.filter((card) => (
                    !card.hide
                  )).length : null
                }
              />
            </Box>
            <Box flexGrow={1}>
              <CauseAndEffectEditor
                causeCards={selectedPage.causeCards.filter((card) => (
                  !card.hide
                ))}
                effectCards={selectedPage.effectCards.filter((card) => (
                  !card.hide
                ))}
                {...currentSelectedData}
                onImageSelected={(params) => {
                  setCurrentSelectedData({ ...params });
                }}
                onTextSelected={(params) => {
                  setCurrentSelectedData({ ...params });
                }}
                onAudioSelected={(params) => {
                  setCurrentSelectedData({ ...params });
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={
                    currentSelectedData.isCauseSelected
                      ? selectedPage.causeCards[currentSelectedData.selectedIndex] && selectedPage.causeCards[currentSelectedData.selectedIndex].text
                      : selectedPage.effectCards[currentSelectedData.selectedIndex] && selectedPage.effectCards[currentSelectedData.selectedIndex].text
                  }
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemove}
                />
                <br />
              </Box>
            )}
            {currentSelectedData.selectedSection === SECTION_IMAGE && (
              <AddPhotoOrVideo
                image={
                  currentSelectedData.isCauseSelected
                    ? selectedPage.causeCards[currentSelectedData.selectedIndex] && selectedPage.causeCards[currentSelectedData.selectedIndex].image
                    : selectedPage.effectCards[currentSelectedData.selectedIndex] && selectedPage.effectCards[currentSelectedData.selectedIndex].image
                }
                onSelect={onImageApply}
                onRemove={onImageRemove}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={
                  currentSelectedData.isCauseSelected
                    ? selectedPage.causeCards[currentSelectedData.selectedIndex] && selectedPage.causeCards[currentSelectedData.selectedIndex].audio
                    : selectedPage.effectCards[currentSelectedData.selectedIndex] && selectedPage.effectCards[currentSelectedData.selectedIndex].audio
                }
                onAudioApply={onAudioApply}
                onAudioRemoved={onAudioRemoved}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import './UnitGuidePage.scss';
import VizzleLogo from '../../../../../../static/images/Vizzle_logo.svg';
import UnitGuideSection from '../../../unit-guide/UnitGuideSection';
import { sort, unique } from '../../../../../../utils/ArrayUtils';
import { useDomain } from '../../../../../../states/AppDomainProvider';

const UnitGuidePage = forwardRef(({ unitGuideData, course }, ref) => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  return (
    <div ref={ref} className='unit-guide-page'>
      {unitGuideData.unitGuideInfo && unitGuideData.unitGuideInfo.map((unitGuideInfo) => {
        let displayedGroupedStandard = [];
        if (unitGuideInfo.standards) {
          const groupedStandard = {};
          unitGuideInfo.standards.forEach((s) => {
            let group = groupedStandard[s.standard_applied_scope];
            if (!group) {
              group = [];
            }
            group.push(s);
            groupedStandard[s.standard_applied_scope] = group;
          });

          const { standardScopes } = userProfile;
          displayedGroupedStandard = Object.keys(groupedStandard).map((g) => {
            const scope = standardScopes.find((s) => s.scopeCode === g);
            if (!scope) {
              return null;
            }
            return {
              title: scope.scopeDisplayName,
              standardList: groupedStandard[g],
            };
          }).filter((d) => d !== null);
        }

        return (
          <Paper elevation={3} className='unit-guide-page-container' key={`unit-guide-of-topic-${unitGuideInfo.topicId}`}>
            <div className='unit-guide-header'>
              <div className='unit-guide-pdf-logo'>
                <img src={VizzleLogo} alt='Vizzle Logo' />
              </div>
              <div className='unit-info'>
                <Typography className='header-text' variant='h5'>{course.name}</Typography>
                <Typography className='header-text unit-header' variant='h5'>UNIT: {unitGuideData.name}</Typography>
              </div>
            </div>
            <div className='unit-guide-body'>
              <UnitGuideSection title={`Topic ${unitGuideInfo.topicNumber > 0 ? `${unitGuideInfo.topicNumber} ` : ''}- ${unitGuideInfo.topicName}`}>
                {displayedGroupedStandard && displayedGroupedStandard.length > 0 ? (
                  displayedGroupedStandard.map((gStandard, i) => (
                    <div className='standard-group' key={`group-stnadard-${i}`}>
                      <Typography variant='subtitle1' className='unit-guide-body-sub-title'>{gStandard.title}</Typography>
                      <div className='standard-list'>
                        {gStandard.standardList && gStandard.standardList.length > 0 && sort(unique(gStandard.standardList, 'standard_code'), 'standard_code').map((standard, standardIndex) => (
                          <div className='standard-entry' key={`standard-${standardIndex}`}>
                            <Typography><strong>{standard.standard_code}</strong> {standard.standard_description}</Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='standard-in-progress'>
                    Standards in Progress
                  </div>
                )}
              </UnitGuideSection>
              <UnitGuideSection className='section-margin-top' title='KEY VOCABULARY' content={unitGuideInfo.keyVocabulary} />
              <UnitGuideSection className='section-margin-top' title='ESSENTIAL QUESTIONS/BIG IDEAS' content={unitGuideInfo.essentialQuestions} />
              <UnitGuideSection className='section-margin-top' title='ACTIVITIES' content={unitGuideInfo.activities} />
            </div>
          </Paper>
        );
      })}
    </div>
  );
});

UnitGuidePage.defaultProps = {
  unitGuideData: {},
  course: {},
};

UnitGuidePage.propTypes = {
  unitGuideData: PropTypes.shape({
    name: PropTypes.string,
    unitGuideInfo: PropTypes.arrayOf(PropTypes.shape({
      topicId: PropTypes.number,
      topicName: PropTypes.string,
      standards: PropTypes.array,
    })),
  }),
  course: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default UnitGuidePage;

import PropTypes from 'prop-types';
import AddRemoveFromGroupPlay from '../../../../../lessons/lessonactions/add-remove-from-group-play/AddRemoveFromGroupPlay';
import Logger from '../../../../../../utils/Logger';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';

export default function TeacherCourseAddRemoveFromGroupPlay({
  lesson,
}) {
  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonData: { lessons, included } } = teacherCourseDomain.domainData;

  return (
    <AddRemoveFromGroupPlay
      lesson={lesson}
      onSaveSuccess={async (updatedLesson) => {
        Logger.isDebugMode({
          UPDATED_LESSON: updatedLesson,
        });
        await teacherCourseDomain.updateLessonsData([...lessons], included);
      }}
    />
  );
}

TeacherCourseAddRemoveFromGroupPlay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }).isRequired,
};

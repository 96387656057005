export const CUSTOM_RESPONSE_OPTIONS = [
  {
    id: '0',
    name: 'Draw Editor',
  },
  {
    id: '1',
    name: 'Text Editor',
  },
  {
    id: '2',
    name: 'Image Selectors',
  },
];

export const DEFAULT_FF_IMAGE_COUNT = 2;

import { useState } from 'react';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { addMyStudent, getListOfStudents } from '../../../../../services/StudentService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

export const useDialogControl = () => {
  const [showDialog, setShowDialog] = useState(false);
  const handleOnAssignButtonClick = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return {
    showDialog,
    handleOnAssignButtonClick,
    closeDialog,
  };
};

export const useAssignStudent = () => {
  const { studentsDomain, userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const assignStudent = async (student) => {
    const fullName = `${student.attributes.firstName} ${student.attributes.lastName}`;
    uiDomain.showLoader(`Adding ${fullName}`, LOADER_TYPE.HASH_LOADER);
    try {
      await callRemoteServiceWrapper(async () => {
        await addMyStudent(student.id);
        const studentList = await getListOfStudents(user.id);
        await studentsDomain.updateStudentList(studentList.students);
      });
      await uiDomain.hideLoader();
      await uiDomain.showSnackbar(true, 'info', 3000, `Student ${fullName} is added `);
    } catch (e) {
      await uiDomain.hideLoader();
    }
  };

  return {
    assignStudent,
  };
};

export const useGetStudents = ({ listStudents }) => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  if (!listStudents || listStudents.length === 0) {
    return [];
  }
  const currentSelectedStudentIds = students.map((s) => s.id.toString());
  const nonSelectedStudents = listStudents.filter((ls) => !currentSelectedStudentIds.includes(ls.id.toString()));
  return nonSelectedStudents;
};

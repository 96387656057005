import PropTypes from 'prop-types';
import './CauseAndEffectThumbnail.scss';
import CauseAndEffectThumbnailComponent from './thumbnail/CauseAndEffectThumbnailComponent';

/**
 * Lesson activity for Cause and Effect
 */
export default function CauseAndEffectThumbnail({ causeCards, effectCards, isSmallThumbnail }) {
  return (
    <div className={`cause-and-effect-thumbnail ${isSmallThumbnail ? 'small' : ''} cause-length-${causeCards.length} vizzle-cause-and-effect-card-thumbnail`}>
      {causeCards.map((cc, index) => (
        <CauseAndEffectThumbnailComponent
          key={`cause-and-effect-thumbnail-${index}`}
          causeCard={cc}
          effectCard={effectCards[index]}
          isSmallThumbnail={isSmallThumbnail}
        />
      ))}
    </div>
  );
}

CauseAndEffectThumbnail.defaultProps = {
  effectCards: [],
  causeCards: [],
  isSmallThumbnail: false,
};

CauseAndEffectThumbnail.propTypes = {
  effectCards: PropTypes.arrayOf(PropTypes.any),
  causeCards: PropTypes.arrayOf(PropTypes.any),
  isSmallThumbnail: PropTypes.bool,
};

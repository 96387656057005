import { useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import HoverTooltip from './HoverTooltip';
import './TooltipWithIndicator.scss';
import CustomTooltip from '../card/CustomTooltip';

/**
 * Tooltip dialog component.
 * Dialogs inform users about a task and can contain critical information, require decisions
 */
export default function TooltipWithIndicator({
  className,
  title,
  messages,
  videoUrl,
  dialogClassName,
  isHoverable,
}) {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  return (
    <div className={`tooltip-with-indicator ${className}`}>
      {isHoverable ? (
        <HoverTooltip title={title} messages={messages} onClick={handleOpenDialog} />
      ) : (
        <CustomTooltip className='action-button-tooltip' title={title}>
          <HelpIcon onClick={handleOpenDialog} className='tooltip-with-indicator-icon' />
        </CustomTooltip>
      )}
      <Dialog
        open={open}
        transitionDuration={300}
        className={`tooltip-with-indicator-dialog ${dialogClassName}`}
      >
        {
          title
            ? (
              <DialogTitle>
                <div className='tooltip-with-indicator-title'>
                  <ErrorIcon className='tooltip-with-indicator-title-icon' />
                  {title}
                </div>
              </DialogTitle>
            ) : ''
        }
        <DialogContent className='tooltip-with-indicator-content'>
          {
            messages.map((m) => (
              <DialogContentText key={uuidv1()} className='tooltip-with-indicator-content-text'>
                {m}
              </DialogContentText>
            ))
          }
          {
            videoUrl ? (
              <CardMedia
                className='tooltip-video-player-inline-video'
                component='video'
                src={videoUrl}
                playsInline
                controls
              />
            ) : ''
          }
        </DialogContent>
        <DialogActions className='tooltip-with-indicator-actions'>
          <Button onClick={handleCloseDialog} color='primary' className='btn-primary tooltip-with-indicator-button'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TooltipWithIndicator.defaultProps = {
  title: 'title',
  messages: ['text line 1', 'text line 2', 'text line 3'],
  className: '',
  videoUrl: '',
  dialogClassName: '',
  isHoverable: false,
};

TooltipWithIndicator.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  className: PropTypes.string,
  videoUrl: PropTypes.string,
  dialogClassName: PropTypes.string,
  isHoverable: PropTypes.bool,
};

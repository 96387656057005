import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import SelectableCard from '../../../components/card/SelectableCard';
import './ReinforcerListV2.scss';
import ReinforcerV2 from '../../terminologies/reinforcer-v2/ReinforcerV2';
import ReinforcerV2Service from '../../terminologies/reinforcer-v2/ReinforcerV2Service';
import { useDomain } from '../../../states/AppDomainProvider';

const ReinforcerListV2 = ({ profile, terminologies, onChangeCallback }) => {
  const [selectedReinforcers, setSelectedReinforcers] = useState(profile.reinforcers);
  const { uiDomain } = useDomain();

  useEffect(() => {
    setSelectedReinforcers(profile.reinforcers);
  }, [profile.reinforcers]);

  // When mounting and changing a profile.
  useEffect(() => {
    setSelectedReinforcers(profile.reinforcers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const onCardSelected = (index) => {
    const value = index;
    let reinforcers = [];
    if (selectedReinforcers.includes(value)) {
      reinforcers = selectedReinforcers.filter((item) => item !== value);

      if (reinforcers.length === 0) {
        uiDomain.showSnackbar(true, 'warning', 6000,
          (
            <Stack>
              <Typography fontWeight={500}>At least 1 reinforcer must be selected.</Typography>
              <Typography fontWeight={300}>Change the style to “None” to turn off reinforcers</Typography>
            </Stack>
          ),
        );
        return;
      }
      setSelectedReinforcers(reinforcers);
    } else {
      selectedReinforcers.push(value);
      reinforcers = selectedReinforcers;
      setSelectedReinforcers(selectedReinforcers);
    }
    onChangeCallback(reinforcers);
  };

  const onMediaClick = (reinforcerItem) => {
    ReinforcerV2Service.playReinforcer(reinforcerItem.id);
  };

  const styleChecked = (code) => (selectedReinforcers.includes(code) ? 'reinforcers-is-selected' : '');

  return (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='wrap'
      className='reinforcers-setting-container-v2'
      data-test='reinforcers-setting-container-v2'
    >
      {terminologies && terminologies.reinforcers
        ? terminologies.reinforcers.map((item, index) => (item.name ? (
          <Box key={index} className={`reinforcers-el-box ${styleChecked(item.code)}`} data-test={`reinforcers-thumbnail-${item.name}`}>
            <SelectableCard
              name={item.name}
              thumbnail={item.thumbnail}
              onClick={() => onCardSelected(item.code)}
              checked={selectedReinforcers.includes(item.code)}
              onMediaClick={() => onMediaClick(item, item.code)}
            />
          </Box>
        ) : null))
        : null}

      <ReinforcerV2
        reinforcerList={terminologies.reinforcers || []}
        enableAudio={profile.reinforcerSound}
      />
    </Box>
  );
};

ReinforcerListV2.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default ReinforcerListV2;

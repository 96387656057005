import { useEffect } from 'react';
import { Typography } from '@mui/material';
import DropdownInput from '../../../../../components/selector/DropdownInput';
import './AssignmentsOverviewFilter.scss';
import { useMyClassroom } from '../../../context/MyClassRoomContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetSubjectsByUserComponents } from '../../../../hooks/SubjectsByUsersHook';
import { USER_COMPONENT_SKILL_SUITE } from '../../../../../utils/User';

const AssignmentsOverviewFilter = () => {
  const { myAssignmentsDomain } = useMyClassroom();
  const { filter } = myAssignmentsDomain.domainData;

  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  const subjectListOptions = useGetSubjectsByUserComponents(true);
  const { userDomain } = useDomain();

  useEffect(() => {
    const { userProfile } = userDomain.domainData;
    const { components } = userProfile;
    if (components.length === 1 && String(components[0].identifier) === USER_COMPONENT_SKILL_SUITE) {
      myAssignmentsDomain.updateAssignmentFilterValue({
        ...filter,
        selectedSubject: subjectListOptions[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='assignment-overview-filter' data-test='assignment-overview-filter'>
      <Typography variant='h5' data-test='subject-selector-title'>
        Assignments
      </Typography>
      <div className='subject-selector'>
        <DropdownInput
          data-test='subject-selector-dropdown'
          dropdownValues={subjectListOptions}
          name='selectedSubject'
          value={filter.selectedSubject ? filter.selectedSubject : 'Please select a subject'}
          className='subject-selector-dropdown'
          onChange={async (e) => {
            await myAssignmentsDomain.updateAssignmentFilterValue({
              ...filter,
              selectedSubject: e.target.value,
            });
          }}
          disabled={students.length === 0}
        />
      </div>
    </div>
  );
};

export default AssignmentsOverviewFilter;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
} from '@mui/material';
import {
  PlayCircleFilled as PlayCircleFilledIcon,
} from '@mui/icons-material';
import LessonPlay from '../../lessonplay/LessonPlay';
import './PlayLessonDetailAction.scss';

/**
 * Play Lesson Action Container
 */
export default function PlayLessonDetailAction({ lesson, user, userProfile, onLessonFinished, terminologies }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        aria-label='Play Lesson'
        className='lesson-detail-panel-play-button'
        onClick={() => { setOpen(true); }}
        data-test='lesson-detail-panel-play-action'
      >
        <PlayCircleFilledIcon className='play-icon' />
      </IconButton>
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
          onLessonFinished={onLessonFinished}
        />
      )}
    </>
  );
}

PlayLessonDetailAction.defaultProps = {
  user: null,
  userProfile: null,
  onLessonFinished: () => { },
  terminologies: {},
};

PlayLessonDetailAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
  onLessonFinished: PropTypes.func,
};

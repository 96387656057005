import PropTypes from 'prop-types';
import {
  Box,
  Slider,
  Typography,
} from '@mui/material';
import { DEFAULT_NUMBER_OF_ANSWER_CARDS } from '../../../../../../../AppConstants';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

const ChooseNumberOfCards = ({
  onSliderChange,
  value,
  flexDirection,
  sliderWidth,
  label,
  ...rest
}) => (
  <Box display='flex' flexDirection={flexDirection} width='100%'>
    <Box mr='20px'>
      <Typography variant='h6'>{label}</Typography>
    </Box>
    <Box width={sliderWidth}>
      <Slider
        defaultValue={DEFAULT_NUMBER_OF_ANSWER_CARDS}
        min={1}
        max={5}
        valueLabelDisplay='off'
        marks={marks}
        onChange={onSliderChange}
        value={value}
        {...rest}
      />
    </Box>
  </Box>
);

ChooseNumberOfCards.defaultProps = {
  value: () => { },
  onSliderChange: () => { },
  flexDirection: 'column',
  sliderWidth: '100%',
  label: 'Choose Number of Cards',
};

ChooseNumberOfCards.propTypes = {
  value: PropTypes.number,
  onSliderChange: PropTypes.func,
  flexDirection: PropTypes.string,
  sliderWidth: PropTypes.string,
  label: PropTypes.string,
};

export default ChooseNumberOfCards;

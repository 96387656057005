import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl } from '@mui/material';
import DropdownInput from '../../../components/selector/DropdownInput';
import './SoundEffectSetting.scss';

const soundEffectOptions = [
  {
    id: true, name: 'On',
  },
  {
    id: false, name: 'Off',
  },
];

export default function SoundEffectSetting({ profile, onChangeCallback, attributeName }) {
  const [soundEffect, setSoundEffect] = useState(profile[attributeName]);
  return (
    <Box display='flex' className='sound-effect-settings' data-test={`sound-effect-settings-container-${attributeName}`}>
      <FormControl variant='outlined'>
        <DropdownInput
          dropdownValues={soundEffectOptions}
          label='Sound Effects'
          labelVariant='h6'
          value={soundEffect}
          onChange={(e) => {
            setSoundEffect(e.target.value);
            onChangeCallback(e.target.value);
          }}
          className='sound-effect-dropdown-select'
          data-test='sound-effect-dropdown-select'
        />
      </FormControl>
    </Box>
  );
}

SoundEffectSetting.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  attributeName: PropTypes.string.isRequired,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import './ResetPasswordForm.scss';

const ResetPasswordForm = ({ onSubmit, loading }) => {
  const [value, setValue] = useState('');

  const handleOnTextChange = (e) => {
    setValue(e.target.value);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(value);
    }
  };

  const handleOnNextClick = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <div className='reset-password-form'>
      <div className='content'>
        <TextField
          className='text-form'
          value={value}
          label='New password'
          inputProps={{
            maxLength: 255,
          }}
          type='password'
          onKeyPress={handleOnKeyPress}
          onChange={handleOnTextChange}
        />
      </div>
      <div className='actions'>
        <ButtonWithSpinner
          className='btn-primary'
          label='Reset'
          fullWidth
          disabled={!value || value.length < 4}
          loading={loading}
          onClick={handleOnNextClick}
        />
      </div>
    </div>
  );
};

ResetPasswordForm.defaultProps = {
  onSubmit: () => { },
  loading: false,
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default ResetPasswordForm;

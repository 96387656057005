/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import './SortingPrompt.scss';
import TextLines from '../../../text/TextLines';
import MediaPlayer from '../../../media/MediaPlayer';
import { getPageStyle } from '../../common/LessonPageUtil';

export default function SortingPrompt({
  promptCard,
  promptCardId,
  isRightAlignLayout,
  children,
  onResponseCardsAreaClick,
}) {
  return (
    <Box
      id={promptCardId}
      boxShadow={2}
      className={`sorting-prompt-card ${isRightAlignLayout ? 'reverse-layout' : ''}`}
      style={getPageStyle(promptCard, true)}
    >
      <MediaPlayer
        className='media-action'
        audioUrl={promptCard.audio}
        videoUrl={promptCard.video}
        parentElementId={promptCardId}
        omitPlayIcon
      />
      <div className='prompt-details'>
        {promptCard.audio || promptCard.video ? (
          <PlayCircleFilledIcon className='media-player-icon' data-test='media-player-icon' />
        ) : ''}

        {
          promptCard.image ? (
            <div className='prompt-image-container'>
              <CardMedia image={promptCard.image} className='prompt-image' />
            </div>
          ) : ''
        }

        <div className='prompt-text'>
          {promptCard.text ? <TextLines textLines={promptCard.text} /> : ''}
        </div>
      </div>
      <div
        className='prompt-selected-response-cards'
        onClick={onResponseCardsAreaClick}
      >
        {children}
      </div>
    </Box>
  );
}

SortingPrompt.defaultProps = {
  promptCard: {},
  isRightAlignLayout: false,
  children: null,
  onResponseCardsAreaClick: () => { },
};

SortingPrompt.propTypes = {
  promptCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  promptCardId: PropTypes.string.isRequired,
  isRightAlignLayout: PropTypes.bool,
  children: PropTypes.node,
  onResponseCardsAreaClick: PropTypes.func,
};

import PropTypes from 'prop-types';
import {
  Checkbox, FormControlLabel, Typography,
} from '@mui/material';
import './CustomResponseEditor.scss';
import DropdownInput from '../../../../selector/DropdownInput';
import { CUSTOM_RESPONSE_OPTIONS } from './CustomResponseConstants';
import VizzleSlider from '../../../../slider/VizzleSlider';

/**
 * Lesson activity for Book
 */
export default function CustomResponseEditor({
  allowCustomResponse,
  customResponseOption,
  ffImageCount,
  onCustomResponseSelect,
  onCustomResponseOptionSelect,
  onFfImageCountChange,
}) {
  return (
    <div className='allow-custom-response'>
      <FormControlLabel
        control={
          (
            <Checkbox
              checked={allowCustomResponse || false}
              onChange={onCustomResponseSelect}
              name='allowCustomResponse'
              color='primary'
            />
          )
        }
        label='Custom Student Response'
        data-test='custom-student-response'
      />
      <br />
      <DropdownInput
        dropdownValues={[CUSTOM_RESPONSE_OPTIONS[0], CUSTOM_RESPONSE_OPTIONS[1]]}
        name='customResponseOption'
        value={customResponseOption}
        onChange={onCustomResponseOptionSelect}
        inlineLabel='Custom Response Option'
        disabled={!allowCustomResponse}
        data-test='custom-response-options'
      />
      <br />

      {customResponseOption && Number(customResponseOption) === Number(CUSTOM_RESPONSE_OPTIONS[2].id) ? (
        <div className='ff-image-count-selector' data-test='ff-image-count-selector'>
          <Typography gutterBottom className='label' data-test='ff-image-count'>
            Number of Images
          </Typography>
          <VizzleSlider
            name='ff-image-count-slider'
            value={ffImageCount}
            min={2}
            max={8}
            className='ff-image-count-slider'
            onChangeCommitted={onFfImageCountChange}
          />
        </div>
      ) : null}

    </div>
  );
}

CustomResponseEditor.defaultProps = {
  onCustomResponseSelect: undefined,
  allowCustomResponse: false,
  customResponseOption: '',
  onCustomResponseOptionSelect: undefined,
  ffImageCount: 2,
  onFfImageCountChange: undefined,
};

CustomResponseEditor.propTypes = {
  allowCustomResponse: PropTypes.bool,
  customResponseOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCustomResponseSelect: PropTypes.func,
  onCustomResponseOptionSelect: PropTypes.func,
  ffImageCount: PropTypes.number,
  onFfImageCountChange: PropTypes.func,
};

import PropTypes from 'prop-types';
import { IconButton, TextField } from '@mui/material';
import {
  Edit as EditIcon,
} from '@mui/icons-material';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import {
  useControlCreateUpdateDialog,
  useUpdateExternalLink,
} from '../ExternalLinkHooks';
import './UpdateExternalLink.scss';

const UpdateExternalLink = ({ externalLink }) => {
  const {
    openCreateUpdateDialog,
    openDialog,
    closeDialog,
  } = useControlCreateUpdateDialog(externalLink);

  const {
    externalLinkValue,
    onExternalLinkChange,
    onExternalLinkLabelChange,
    updateLink,
    saving,
  } = useUpdateExternalLink(openCreateUpdateDialog, externalLink, closeDialog);

  return (
    <>
      <IconButton data-test={`update-external-link${externalLink.id}`} aria-label='edit' className='edit-external-link' onClick={() => { openDialog(); }}>
        <EditIcon className='edit-external-link-icon' />
      </IconButton>
      {openCreateUpdateDialog ? (
        <CustomDialog
          className='create-external-link-dialog'
          openDialog={openCreateUpdateDialog}
          onClose={closeDialog}
          title='Update External Link'
          content={(
            <div className='dialog-content'>
              <TextField
                onChange={onExternalLinkLabelChange}
                label='Label'
                fullWidth
                value={externalLinkValue.label}
                inputProps={{
                  maxLength: 255,
                }}
                data-test='update-external-link-label-text-field'
                variant='standard'
              />
              <br /><br />
              <TextField
                onChange={onExternalLinkChange}
                label='External Link'
                fullWidth
                value={externalLinkValue.url}
                error={externalLinkValue.error.isError}
                helperText={externalLinkValue.error.helperText}
                inputProps={{
                  maxLength: 255,
                }}
                variant='standard'
                data-test='update-external-link-text-field'
              />
            </div>
          )}
          displayCloseIcon
          actions={[
            {
              title: 'Save',
              onClick: updateLink,
              color: 'primary',
              className: 'btn-primary',
              disabled: externalLinkValue.url === '' || externalLinkValue.error.isError,
              loading: saving,
            },
            {
              title: 'Close',
              onClick: closeDialog,
              color: 'primary',
              className: 'btn-gray-background',
              disabled: saving,
            },
          ]}
        />
      ) : null}
    </>
  );
};

UpdateExternalLink.propTypes = {
  externalLink: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string,
  }).isRequired,
};

export default UpdateExternalLink;

import { useState } from 'react';
import {
  addTeacher,
  getTeacherSchoolAccess,
  makeUsersActive,
  removeTeacherSchoolAccess,
  resetTeachersPassword,
  teacherUpdateSchools,
  updateTeachers,
} from '../../../../../services/AdminService';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useAdmin } from '../../../context/AdminContextProvider';

const convertTeacherDataToRequest = (teacher, selectedSchoolId) => {
  const request = {
    data: {
      attributes: {
        email: teacher.username,
        firstName: teacher.firstName,
        lastName: teacher.lastName,
        schoologyId: teacher.schoologyId,
        type: 'Teacher',
        userName: teacher.username,
      },
      type: 'users',
      relationships: {
        school: {
          data: {
            type: 'districts',
            id: selectedSchoolId,
          },
        },
      },
    },
  };

  return request;
};

export const useAddTeacherData = ({ setOpen, onTeacherAddSuccess }) => {
  const { adminDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const addTeacherData = async (teacher) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const request = convertTeacherDataToRequest(teacher, selectedSchoolId);

        await addTeacher(request);
        await onTeacherAddSuccess();
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, 'Teacher is added.');
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    addTeacherData,
    loading,
  };
};

export const transformTeacherDataToTeacherFormObject = (teacherDataObject) => (teacherDataObject && teacherDataObject.data && teacherDataObject.data.attributes ? {
  firstName: teacherDataObject.data.attributes.firstName,
  lastName: teacherDataObject.data.attributes.lastName,
  username: teacherDataObject.data.attributes.userName,
  email: teacherDataObject.data.attributes.email,
  schoologyId: teacherDataObject.data.attributes.schoologyId,
  isUserDistrictAdmin: teacherDataObject.data.attributes.isUserDistrictAdmin,
  schoolId: Number(teacherDataObject.data.relationships.school.data.id),
  adminCommunication: teacherDataObject.data.attributes.adminCommunication,
  userGroupRoles: teacherDataObject.data.attributes.userGroupRoles,
  b2cId: teacherDataObject.data.attributes.b2cId,
  isClasslinkDistrict: teacherDataObject.data.attributes.isClasslinkDistrict,
  isCleverDistrict: teacherDataObject.data.attributes.isCleverDistrict,
} : {});

export const useSaveEditTeacher = ({ closeDialog, onTeacherUpdateSuccess }) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const saveEditTeacher = async (teacherDataObject, teacherFormDataState, teacherSelectedStudentIdsRef) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const coppiedTeacherObject = JSON.parse(JSON.stringify(teacherDataObject));
        coppiedTeacherObject.data.attributes.firstName = teacherFormDataState.firstName;
        coppiedTeacherObject.data.attributes.lastName = teacherFormDataState.lastName;
        coppiedTeacherObject.data.attributes.userName = teacherFormDataState.username;
        // Save the email from user name
        coppiedTeacherObject.data.attributes.email = teacherFormDataState.username;
        // coppiedTeacherObject.data.attributes.email = teacherFormDataState.email;
        coppiedTeacherObject.data.attributes.schoologyId = teacherFormDataState.schoologyId;
        coppiedTeacherObject.data.attributes.password = teacherFormDataState.password;
        coppiedTeacherObject.data.attributes.isUserDistrictAdmin = teacherFormDataState.isUserDistrictAdmin;
        coppiedTeacherObject.data.relationships.students.data = teacherSelectedStudentIdsRef.current.map((s) => ({ type: 'users', id: s }));
        coppiedTeacherObject.data.relationships.school.data.id = teacherFormDataState.school.key;
        coppiedTeacherObject.data.attributes.adminCommunication = teacherFormDataState.adminCommunication;
        coppiedTeacherObject.data.attributes.userGroupRoles = teacherFormDataState.userGroupRoles;
        delete coppiedTeacherObject.included;
        await updateTeachers(coppiedTeacherObject, teacherFormDataState.shouldMoveStudent);
        await onTeacherUpdateSuccess();
        closeDialog();
        await uiDomain.showSnackbar(true, 'success', 3000, `Teacher ${teacherFormDataState.firstName} ${teacherFormDataState.lastName} is updated.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saveEditTeacher,
    loading,
  };
};

export const useGetTeacherName = () => {
  const { adminDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;

  const getTeacherName = (teacherId) => {
    const { included } = usersAndLicensesData;
    const teacherObject = included.find((i) => i.id === teacherId && i.type === 'users');
    return `${teacherObject.attributes.firstName} ${teacherObject.attributes.lastName}`;
  };

  const getTeacherUsername = (teacherId) => {
    const { included } = usersAndLicensesData;
    const teacherObject = included.find((i) => i.id === teacherId && i.type === 'users');
    return teacherObject.attributes.userName;
  };

  return {
    getTeacherName,
    getTeacherUsername,
  };
};

export const useDeactivateTeachers = ({ setOpen, onTeacherDeactivateSuccess }) => {
  const { adminDomain, adminTeachersDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const {
    getTeacherName,
  } = useGetTeacherName();

  const deactivateTeachers = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await makeUsersActive(selectedTeacherIds, selectedSchoolId, false);
        await onTeacherDeactivateSuccess();
        setOpen(false);

        const message = selectedTeacherIds.length === 1
          ? `Teacher ${getTeacherName(selectedTeacherIds[0])} is deactivated.`
          : 'The selected teachers are deactivated.';

        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    deactivateTeachers,
    loading,
  };
};

export const useReactivateTeachers = ({ setOpen, onTeacherReactivateSuccess }) => {
  const { adminDomain, adminTeachersDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const {
    getTeacherName,
  } = useGetTeacherName();

  const reactivateTeachers = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await makeUsersActive(selectedTeacherIds, selectedSchoolId, true);
        await onTeacherReactivateSuccess();
        setOpen(false);

        const message = selectedTeacherIds.length === 1
          ? `Teacher ${getTeacherName(selectedTeacherIds[0])} is reactivated.`
          : 'The selected teachers are reactivated.';

        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    reactivateTeachers,
    loading,
  };
};

export const useEmailResetPassword = ({
  setOpen,
  onEmailPasswordResetSent,
}) => {
  const { adminTeachersDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const {
    getTeacherUsername,
  } = useGetTeacherName();

  const emailResetPassword = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        resetTeachersPassword(selectedTeacherIds);
        setOpen(false);
        const message = selectedTeacherIds.length === 1
          ? `An email with a reset password link has been sent out successfully to ${getTeacherUsername(selectedTeacherIds[0])}`
          : 'An email with a reset password link has been sent out successfully to the selected users.';
        await onEmailPasswordResetSent();
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      setOpen(false);
    }
  };

  return {
    emailResetPassword,
  };
};

export const useGetTeacherSchoolAccess = () => {
  const { adminTeachersDomain } = useAdmin();
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);

  const getSelectdTeacherSchoolAccessTeachers = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getTeacherSchoolAccess(selectedTeacherIds[0]);
        setSelectedSchoolIds((result.data || []).map((d) => d.id));
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    getSelectdTeacherSchoolAccessTeachers,
    loading,
    selectedSchoolIds,
  };
};

export const useUpdateTeacherSchoolsAccess = ({
  setOpen,
}) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const {
    getTeacherUsername,
  } = useGetTeacherName();
  const { adminTeachersDomain } = useAdmin();
  const { selectedTeacherIds } = adminTeachersDomain.domainData;

  const updateTeacherShoolsAccess = async (originalSelectedIds, currentSelectedIds) => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (selectedTeacherIds.length === 1) {
          const toRemove = originalSelectedIds.filter((o) => !currentSelectedIds.includes(o));
          const toAdd = currentSelectedIds.filter((c) => !originalSelectedIds.includes(c));
          const promistList = [];
          if (toRemove.length > 0) {
            promistList.push(removeTeacherSchoolAccess(selectedTeacherIds[0], toRemove));
          }
          if (toAdd.length > 0) {
            promistList.push(teacherUpdateSchools(selectedTeacherIds, toAdd));
          }
          await Promise.all(promistList);
        } else {
          await teacherUpdateSchools(selectedTeacherIds, currentSelectedIds);
        }

        setOpen(false);

        const message = selectedTeacherIds.length === 1
          ? `The School Roster Acess for ${getTeacherUsername(selectedTeacherIds[0])} has been updated`
          : 'The School Roster Acess for the seleced teachers have been updated';
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      setOpen(false);
    }
  };
  return {
    saving: loading,
    updateTeacherShoolsAccess,
  };
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FixedSizeGrid as Grid } from 'react-window';
import StudentCard from './StudentCard';
import StudentSearchBox from './StudentSearchBox';
import './StudentList.scss';

const calculateRow = (total, columnCount) => (total % columnCount === 0 ? total / columnCount : (total / columnCount) + 1);
const columnCount = 5;

const StudentList = ({ listStudents, onAdd }) => {
  const calculateCol = () => columnCount;

  const [searchResult, setSearchResult] = useState(listStudents);

  const updateSearchResult = (value) => {
    setSearchResult(value);
  };

  return (
    <Box display='flex' flexDirection='column' className='student-list-box'>
      <Box data-test='student-list-search-student' className='search-section'>
        <StudentSearchBox updateSearchResult={updateSearchResult} listStudents={listStudents} />
      </Box>
      <Box className='search-result-existing-student'>
        {searchResult ? (
          <Grid
            rowCount={calculateRow(searchResult.length, calculateCol())}
            rowHeight={80}
            itemData={searchResult}
            className='student-content-grid'
            overscanRowCount={5}
            columnCount={5}
            columnWidth={160}
            height={396}
            width={840}

          >
            {({ columnIndex, rowIndex, data, style }) => <StudentCard columnIndex={columnIndex} rowIndex={rowIndex} data={data} style={style} onAdd={onAdd} columnCount={calculateCol()} />}
          </Grid>
        ) : null}
      </Box>
    </Box>
  );
};

StudentList.propTypes = {
  listStudents: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func.isRequired,
};
export default StudentList;

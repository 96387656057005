export const LICENSE_TYPE = [
  'PILOT',
  'MONARCH',
  'MAPP',
  'PARENT',
  'PARTNERSHIP',
  'CONTENT',
  'MTT',
  'EXPIRED',
  'UNIVERSITY',
  'PAID',
  'CONFERENCE',
  'CONTEST',
  'TRIAL',
  'COMP',
  'EXTENDED',
  'TECH',
];

import { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CardAction from '../../../components/card/CardAction';
import LessonPlay from '../../lessonplay/LessonPlay';
import './PreviewLessonAction.scss';
import ChurnZeroService from '../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../constants/ChurnZeroConstant';
import { useTrackTeacherActivityPreview } from './hooks/TeacherTrackingActivityHooks';

/**
 * Play Lesson Action Container
 */
export default function PreviewLessonAction({ lesson, label, className, user, userProfile, terminologies }) {
  const [open, setOpen] = useState(false);

  useTrackTeacherActivityPreview(open, user, lesson);

  return (
    <>
      <CardAction
        icon={<VisibilityIcon />}
        label={label}
        actionHandler={() => {
          ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.SETUP, 'Explore platform', user);
          setOpen(true);
        }}
        className={`btn-gray-background preview-lesson-card-action ${className}`}
        data-test='preview-lesson-card-action'
      />
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          isPreviewMode
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
        />
      )}
    </>
  );
}

PreviewLessonAction.defaultProps = {
  className: '',
  label: '',
  user: null,
  userProfile: null,
  terminologies: {},
};

PreviewLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
};

import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './AssignedStudentCourses.scss';
import { useGetAssignedStudentCourses } from './AssignedStudentCoursesHooks';
import { useAssignedStudentCoursesTable } from './AssignedStudentCoursesTableHooks';
import SearchAndSortableTable from '../../../components/table/SearchAndSortableTable';

const AssignedStudentCourses = forwardRef(({
  loading,
  selectedDates,
  onStartLoading,
  subjectId,
  onDataLoad,
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  canArchive,
  onTableActionSuccess,
  canLessonDeleteFunction,
}, ref) => {
  const {
    filteredAssignedStudentCourses,
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
  });

  const {
    tableConfig,
  } = useAssignedStudentCoursesTable({
    onDataLoad,
    assignedStudentCourses,
    selectedDates,
    getAssignedStudentData,
    canArchive,
    onTableActionSuccess,
    canLessonDeleteFunction,
  });

  useImperativeHandle(ref, () => ({
    refreshData: () => {
      getAssignedStudentData(selectedDates);
    },
  }));

  return (
    <div className='assigned-students-courses'>
      <SearchAndSortableTable
        containerClassName='assigned-student-course-table'
        config={tableConfig}
        tableData={filteredAssignedStudentCourses}
        searchPlaceholder='FILTER STUDENTS'
        initTableState={{
          order: 'desc', // needs to figure out whey it is 'desc' to order the date
          orderBy: 'date',
        }}
        filterable={false}
        loading={loading}
        autoResetTableState={false}
      />
    </div>
  );
});

export default AssignedStudentCourses;

AssignedStudentCourses.defaultProps = {
  loading: false,
  selectedDates: {},
  onStartLoading: () => null,
  subjectId: null,
  onDataLoad: () => null,
  assignedStudentCourses: [],
  filterCriteriaAssignedStudentCourses: {},
  canArchive: true,
  onTableActionSuccess: () => null,
  canLessonDeleteFunction: () => true,
};

AssignedStudentCourses.propTypes = {
  loading: PropTypes.bool,
  selectedDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onStartLoading: PropTypes.func,
  subjectId: PropTypes.number,
  onDataLoad: PropTypes.func,
  assignedStudentCourses: PropTypes.arrayOf(PropTypes.any),
  filterCriteriaAssignedStudentCourses: PropTypes.any,
  canArchive: PropTypes.bool,
  onTableActionSuccess: PropTypes.func,
  canLessonDeleteFunction: PropTypes.func,
};

import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import ActivityInstructionDialog from '../common/instructions-dialog/ActivityInstructionDialog';
import { useOpenInstructionDialog } from '../common/instructions-dialog/ActivityInstructionHooks';
import {
  useInitializeData,
  useGenerateComponentIds,
  useDragAndDropSubmitAnswer,
  useClickSubmitAnswer,
  useGenerateRejectedAnswerRefs,
  useSwitchAccessSubmitAnswer,
  usePrepareViewCorrectSubmissions,
} from './SortingActivityHooks';
import SortingActivityPrompt from './components/SortingActivityPrompt';
import SortingActivityResponse from './components/SortingActivityResponse';
import SelectedActivityResponseCards from './components/SelectedActivityResponseCards';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import '../activities-common.scss';
import './SortingActivity.scss';
import ObjectUtils from '../../../../utils/ObjectUtils';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

const SortingActivity = forwardRef(({
  instructions,
  promptCards,
  responseCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  isCurrentPage,
  onInstructionFinished,
  onTriggerSwitchScan,
  pageIndex,
}, ref) => {
  const {
    sortingActivityRef,
    dragAndDropApiRef,
  } = useInitializeData();

  const {
    id,
    componentId,
    droppableId,
    textLineId,
  } = useGenerateComponentIds();

  const {
    openInstructions,
    handleOnInstructionClosed,
    audioPlaying,
    hasOpen,
  } = useOpenInstructionDialog({ instructions, isCurrentPage, textLineId, onInstructionFinished });

  const {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  } = useGenerateRejectedAnswerRefs();

  const {
    handleOnDragStart,
    handleOnDragEnd,
    selectedResponseCards,
    isFinished,
    setSelectedResponseCards,
  } = useDragAndDropSubmitAnswer({
    sortingActivityRef,
    promptCards,
    rejectedAnswerPromptRefs,
    responseCards,
    rejectedAnswerResponseRefs,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
  });

  const {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  } = useClickSubmitAnswer({
    sortingActivityRef,
    dragAndDropApiRef,
  });

  usePrepareViewCorrectSubmissions({
    setSelectedResponseCards,
    responseCards,
    isFinished,
    onActivityFinished,
    pageIndex,
  });

  const sortingPromptRefs = [];
  const setSortingPromptRefs = (r, index) => {
    if (r) {
      sortingPromptRefs[index] = r;
    }
  };

  const sortingResponseRefs = [];
  const setSortingResponseRefs = (r, index) => {
    if (r) {
      sortingResponseRefs[index] = r;
    }
  };

  const {
    selectedPromptIndex,
    handlePromptSwitchAccessSelect,
    handleResponseSwitchAccessSelect,
  } = useSwitchAccessSubmitAnswer({
    sortingPromptRefs,
    sortingResponseRefs,
    onTriggerSwitchScan,
    dragAndDropApiRef,
  });

  useImperativeHandle(ref, () => ({
    skipAppBar: () => selectedPromptIndex.current === null,
    getEnableElements: async () => {
      if (!hasOpen.current) {
        return [];
      } else if (isFinished.current) {
        return [];
      } else if (selectedPromptIndex.current === null) {
        return sortingPromptRefs.map((s) => s.getEnableElements());
      } else {
        await ObjectUtils.delay(50);
        const remainPrompts = sortingPromptRefs
          .filter((_s, index) => index !== selectedPromptIndex.current.index)
          .map((s) => s.getEnableElements());
        const remainingResponse = sortingResponseRefs
          .filter((_s, index) => !sortingResponseRefs.includes(index))
          .map((s) => s.getEnableElements());

        return [
          ...remainingResponse,
          ...remainPrompts,
        ];
      }
    },
  }));

  return (
    <>
      <ActivityInstructionDialog
        id={textLineId}
        open={openInstructions.open && !isFinished.current}
        instructions={instructions}
        onClose={handleOnInstructionClosed}
        audioPlaying={audioPlaying}
      />
      <DragDropContext
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
        sensors={[(sensorApi) => {
          dragAndDropApiRef.current = sensorApi;
        }]}
      >
        <div className='sorting-activity-component' data-test='sorting-activity-component' ref={sortingActivityRef} id={componentId}>
          <Droppable
            droppableId={droppableId}
            direction='horizontal'
            isDropDisabled
            isCombineEnabled
          >
            {(sortingProvided) => (
              <div
                ref={sortingProvided.innerRef}
                {...sortingProvided.droppableProps}
                className='sorting-prompt-draggable'
              >
                <div className='sorting-prompt-area' data-test='sorting-prompt-area'>
                  {promptCards.map((promptCard, index) => {
                    const promptId = `sorting-prompt-${id}-${index}`;
                    const drappableKeyId = `sorting-component-droppable-container-${id}-${index}`;
                    const promptPlaceholderId = `sorting-prompt-placeholder-${id}-${index}`;
                    return (
                      <Droppable
                        key={drappableKeyId}
                        droppableId={drappableKeyId}
                        direction='horizontal'
                        index={index + 1}
                        data-test={`sorting-prompt-area-${index}`}
                      >
                        {(provided, snapshot) => (
                          <SortingActivityPrompt
                            promptCardId={promptId}
                            promptCard={promptCard}
                            isRightAlignLayout={index % 2 === 1}
                            onResponseCardsAreaClick={(e) => {
                              handleOnPromptCardClick(e, promptPlaceholderId);
                            }}
                            ref={(r) => { setSortingPromptRefs(r, index); }}
                            onPromptSwitchAccessSelected={() => { handlePromptSwitchAccessSelect(index, promptPlaceholderId); }}
                          >
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              id={promptPlaceholderId}
                              className={`
                                selected-response-cards 
                                ${snapshot.isDraggingOver ? 'sorting-selected-component' : 'sorting-component-response-card-non-drag-items'}
                                `}
                              data-test={`sorting-drop-zone-${index}`}
                            >
                              <SelectedActivityResponseCards
                                id={id}
                                promptKey={promptCard.key}
                                selectedResponseCards={selectedResponseCards}
                                responseCards={responseCards}
                              />

                              <RejectedAnswerResponse ref={setRejectedAnswerPromptRefs} showIcon={false} />
                            </div>
                            {provided.placeholder}
                          </SortingActivityPrompt>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
                <div className='sorting-response-area-container'>
                  <div className='sorting-response-cards'>
                    {
                      responseCards.map((responseCard, index) => {
                        const cardId = `${id}-${index}`;
                        const responseKeyId = `sorting-response-key-${cardId}`;
                        const responseId = `sorting-response-${cardId}`;
                        const draggableId = `sorting-drag-item-${cardId}`;
                        const isResponseSelected = selectedResponseCards.includes(index);
                        return (
                          <div key={responseKeyId} id={draggableId} className={`response-card-place-holder item-placeholder response-margin-${responseCards.length}-responses`}>
                            <Draggable
                              key={draggableId}
                              draggableId={draggableId}
                              index={index}
                              isDragDisabled={selectedResponseCards.includes(index)}
                              shouldRespectForcePress={false}
                              timeForLongPress={0}
                            >
                              {(providedItem, snapshotItem) => (
                                <div
                                  ref={providedItem.innerRef}
                                  {...providedItem.draggableProps}
                                  {...providedItem.dragHandleProps}
                                  id={`sorting-response-item-${cardId}`}
                                  key={`sorting-response-item-${cardId}`}
                                  className={`${isResponseSelected ? '' : 'sorting-response-draggable'} 
                                    ${snapshotItem.isDragging ? 'sorting-selected-component' : 'non-drag-items'}
                                    `}
                                  style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                                  data-test={`sorting-answer-card-${index}`}
                                >
                                  {
                                    !isResponseSelected && (
                                      <>
                                        <SortingActivityResponse
                                          responseCard={responseCard}
                                          responseCardId={responseId}
                                          onClick={(e, isTtsAbleToPlay) => {
                                            handleOnResponseCardClick(e, isTtsAbleToPlay, responseCard, responseId, draggableId);
                                          }}
                                          ref={(r) => { setSortingResponseRefs(r, index); }}
                                          onResponseSwitchAccessSelected={() => handleResponseSwitchAccessSelect(responseId, draggableId)}
                                        />
                                        <RejectedAnswerResponse ref={(r) => { setRejectedAnswerResponseRefs(r, index); }} />
                                      </>
                                    )
                                  }
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {sortingProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
});

export default SortingActivity;

SortingActivity.defaultProps = {
  instructions: {},
  promptCards: [],
  responseCards: [],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  isCurrentPage: false,
  onInstructionFinished: () => { },
  onTriggerSwitchScan: () => { },
};

SortingActivity.propTypes = {
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  onInstructionFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
  pageIndex: PropTypes.number.isRequired,
};

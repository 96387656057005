import PropTypes from 'prop-types';
import DvrIcon from '@mui/icons-material/Dvr';
import CardAction from '../../../../../../../../components/card/CardAction';

export default function ViewFilesAction({
  className,
  onClick,
}) {
  return (
    <CardAction
      icon={<DvrIcon />}
      actionHandler={onClick}
      label='View'
      className={`btn-primary ${className}`}
      ariaLabel='View file'
      title='View file'
      data-test='view-file-action'
    />
  );
}

ViewFilesAction.defaultProps = {
  className: '',
  onClick: () => null,
};

ViewFilesAction.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

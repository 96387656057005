import { Navigate, Routes, Route } from 'react-router-dom';
import { AppBar, Tab, Tabs } from '@mui/material';
import { initDomains } from './context/MyStudentContextProvider';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import { useUpdateCurrentSelectedStudent, useStep, useWhenStudentChange } from './MyStudentHooks';
import '../../components/steppers/TabStyle.scss';
import './MyStudent.scss';

const MyStudent = () => {
  initDomains();
  useUpdateCurrentSelectedStudent();
  const {
    myStudentSteps,
    handleTabChange,
    tabValue,
  } = useStep();

  useWhenStudentChange();

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div className='my-student'>
        <TeacherTopAppbar />
        <AppBar position='static' color='default' className='vizzle-tab-menu-style'>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='fullWidth'
            scrollButtons='auto'
            indicatorColor='primary'
            textColor='primary'
            aria-label='My Student Tabs'
          >
            {myStudentSteps.map((menu, index) => (
              <Tab
                key={`my-student-menu-${index}`}
                label={menu.label}
                data-test={`mystudent-tab-${menu.link}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <div className='content-panel'>
          <Routes>
            {myStudentSteps.map((menu, index) => (
              <Route
                key={`my-student-route-${index}`}
                path={`${menu.link}`}
                element={menu.element}
              />
            ))}
            <Route
              path='*'
              element={<Navigate to={`${myStudentSteps[0].link}`} />}
            />
          </Routes>
        </div>
      </div>
    </VizzleMainContainer>
  );
};

export default MyStudent;

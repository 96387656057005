import {
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { getSubjectObjectBySubjectName } from '../../../../../services/SubjectService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetSubjectsByUserComponents } from '../../../../hooks/SubjectsByUsersHook';

const isSubjectSelectorEditable = (lessonData) => {
  const { attributes: { libraryLessonFlag, subjectEditable } } = lessonData;
  if ((libraryLessonFlag === null || libraryLessonFlag === undefined) && (subjectEditable === null || subjectEditable === undefined)) {
    return { editable: true, message: '' };
  }
  const isSubjectEditable = { editable: false, message: '' };

  if (libraryLessonFlag) {
    isSubjectEditable.editable = false;
    isSubjectEditable.message = 'This is a shared Library Activity and the subject cannot be edited';
  } else if (subjectEditable) {
    isSubjectEditable.editable = true;
  } else {
    isSubjectEditable.editable = false;
    isSubjectEditable.message = 'The subject cannot be edited as this activity is already assigned to students';
  }

  return isSubjectEditable;
};

/**
 * Container for Subject selection in Lesson Save screen
 *
 * @export
 */
export default function SubjectSelector() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const subjectList = useGetSubjectsByUserComponents(false);
  // const subjectList = getAllSubjectes(true);

  const handleSubjectSelected = (event) => {
    Logger.logWhenDebugModeIsOn({
      SELECTED_SUBJECT: event.target.value,
    });

    const currentSubject = getSubjectObjectBySubjectName(event.target.value);

    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        subjects: [currentSubject.name],
        grades: [],
        standards: [],
      },
    };

    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  const isSubjectEditable = isSubjectSelectorEditable(lessonData);

  return (
    <Box width='100%' data-test='subject-selector'>
      <Typography variant='h6'>
        Subject
      </Typography>
      <TextField
        select
        value={lessonData.attributes.subjects[0] || ''}
        onChange={handleSubjectSelected}
        variant='outlined'
        placeholder='Subject'
        fullWidth
        className='vizzle-lesson-save-subject-selector dropdown-small-padding selector-helper-text'
        disabled={!isSubjectEditable.editable}
        helperText={isSubjectEditable.message}
      >
        {subjectList.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

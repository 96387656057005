import { createContext, useContext } from 'react';
import AdminDomain from './AdminDomain';
import AdminDataDomain from './AdminDataDomain';
import DistrictsDomain from './DistrictsDomain';
import SchoolsDomain from './SchoolsDomain';
import LicensesDomain from './LicensesDomain';
import AdminTeachersDomain from './AdminTeachersDomain';
import AdminAdminsDomain from './AdminAdminsDomain';
import SearchTeacherDomain from './SearchTeacherDomain';
import SearchStudentDomain from './SearchStudentDomain';
import AdminStudentsDomain from './AdminStudentsDomain';
import ExternalUsersDomain from './ExternalUsersDomain';

const domains = [
  new AdminDomain(),
  new DistrictsDomain(),
  new SchoolsDomain(),
  new AdminDataDomain(),
  new LicensesDomain(),
  new AdminTeachersDomain(),
  new AdminAdminsDomain(),
  new SearchTeacherDomain(),
  new SearchStudentDomain(),
  new AdminStudentsDomain(),
  new ExternalUsersDomain(),
];

/*-----------------------------------------------------------------------*/

let AdminContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  AdminContextProvider = createContext(result);
};

/** Get application domains from the context */
export const useAdmin = () => useContext(AdminContextProvider);

import { useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useDomain } from '../../../../states/AppDomainProvider';
import AuthoredLessonsFilter from './filter/AuthoredLessonsFilter';
import { useFetchAuthoredFromService, usePrepareData, useGetFilteredLessons, FILTER_STATUS } from './AuthoredLessonHooks';
import './AuthoredLessons.scss';
import LessonCardListDetail from '../../../../components/vizzle/cardlistdetail/lesson/LessonCardListDetail';
import { createAddtionalDetailPanel, createCardAction } from './AuthoredLessonsHelper';
import { initAuthoredLessonsDomains, useAuthoredLessonsDomain } from './stores/AuthoredLessonsDomainProvider';

/**
 * Authored lessons container to display all lessons of the teacher
 */
export default function AuthoredLessons() {
  initAuthoredLessonsDomains();
  const { authoredLessonsSearchCriteriaDomain } = useAuthoredLessonsDomain();
  const { authoredLessonDomain, userDomain } = useDomain();
  const { includedData } = authoredLessonDomain.domainData;
  const { selectedPendingStatus, filteredText } = authoredLessonsSearchCriteriaDomain.domainData;
  const { user, userProfile } = userDomain.domainData;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gridListId = useMemo(() => uuidv1(), [selectedPendingStatus, filteredText]);

  const {
    fetchAuthoredFromService,
  } = useFetchAuthoredFromService();

  usePrepareData(fetchAuthoredFromService);

  const {
    filteredLessons,
    hasLessonFlag,
  } = useGetFilteredLessons();

  return (
    <div className='authored-lesson-container'>
      <div className='filter'>
        <AuthoredLessonsFilter />
      </div>
      <div className='lesson-list-container'>
        <LessonCardListDetail
          key={gridListId}
          lessonData={{
            lessons: filteredLessons,
            included: includedData,
          }}
          noFilter
          multiSelectFilterConfig={[]}
          noLessonMessage={hasLessonFlag.message}
          createCardAction={(lesson, index) => createCardAction(lesson, index)}
          currentUser={user}
          currentUserProfle={userProfile}
          displayFastNavigator
          createAddtionalDetailPanel={
            user.userModerator && selectedPendingStatus === FILTER_STATUS[2].value
              ? (lesson) => createAddtionalDetailPanel({ lesson, user }) : undefined
          }
        />
      </div>
    </div>
  );
}

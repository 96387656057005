import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, TextField, Typography } from '@mui/material';
import { EMAIL_REGEXP } from '../../../../../../../constants/Regex';
import { useDomain } from '../../../../../../../states/AppDomainProvider';
import './UpdateTeacherForm.scss';
import DistrictSchoolSelector from '../../../../../components/actions/district-school-selector/DistrictSchoolSelector';
import { MIN_PASSWORD, usePasswordValidator } from '../../../../../hooks/AdminUtilityHooks';

export const validateTeacherData = (data, userSuperAdmin) => data
  && data.firstName
  && data.lastName
  && data.username && EMAIL_REGEXP.test(data.username)
  && (!userSuperAdmin || (userSuperAdmin && data.email && EMAIL_REGEXP.test(data.email)))
  && (
    (!data.password && !data.confirmPassword)
    || (data.password && data.confirmPassword && data.password.length >= MIN_PASSWORD && data.confirmPassword.length >= MIN_PASSWORD && data.password === data.confirmPassword)
  );

const UpdateTeacherForm = ({ teacherFormData, onDataChange }) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userAdmin,
    userSuperAdmin,
    schoologyFlag,
  } = user;
  const [internalData, setInternalData] = useState({ ...teacherFormData });
  useEffect(() => {
    if (teacherFormData && teacherFormData.username) {
      setInternalData({ ...teacherFormData });
    }
  }, [teacherFormData]);

  const [emailHelperText, setEmailHelperText] = useState({
    username: false,
    email: false,
  });

  const handleOnChange = (e) => {
    const updatedData = { ...internalData, [e.target.name]: e.target.value };
    setInternalData(updatedData);
    const isDataValid = validateTeacherData(updatedData, userSuperAdmin, userAdmin);
    onDataChange(updatedData, isDataValid);
  };

  const handleCheckboxChange = (e) => {
    let updatedData = { ...internalData };
    const propertyName = e.target.name;
    const { checked } = e.target;
    if (propertyName.includes('.')) {
      const attributes = propertyName.split('.');
      updatedData[attributes[0]][attributes[1]] = checked;
    } else {
      updatedData = { ...internalData, [e.target.name]: e.target.checked };
    }
    setInternalData(updatedData);
    const isDataValid = validateTeacherData(updatedData, userSuperAdmin);
    onDataChange(updatedData, isDataValid);
  };

  const handleOnEmailChange = (e) => {
    const { value } = e.target;
    const result = EMAIL_REGEXP.test(value);
    if (!value || result) {
      setEmailHelperText({
        ...emailHelperText,
        [e.target.name]: false,
      });
    } else {
      setEmailHelperText({
        ...emailHelperText,
        [e.target.name]: true,
      });
    }
    handleOnChange(e);
  };

  const {
    validatePasswordTeacher,
    passwordError,
  } = usePasswordValidator();

  const handleOnDistrictSchoolChange = async (selectedDistrictSchool) => {
    const updatedData = {
      ...internalData,
      school: selectedDistrictSchool.school,
    };
    setInternalData(updatedData);
    onDataChange(updatedData, validateTeacherData(updatedData));
  };

  const { b2cId } = teacherFormData;
  const isB2cId = Boolean(b2cId);
  const { isClasslinkDistrict, isCleverDistrict } = teacherFormData;
  const disableSettingData = isClasslinkDistrict || isCleverDistrict;

  return (
    <div className='update-admin-teacher-form'>
      <FormControl variant='outlined' className='form-container'>
        <div className='form-row flex-end'>
          <Typography className='form-label'>
            First Name
          </Typography>
          <TextField
            name='firstName'
            value={internalData.firstName || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
            disabled={isB2cId || disableSettingData}
          />
        </div>
        <div className='form-row flex-end'>
          <Typography className='form-label'>
            Last Name
          </Typography>
          <TextField
            name='lastName'
            value={internalData.lastName || ''}
            onChange={handleOnChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            variant='standard'
            disabled={isB2cId || disableSettingData}
          />
        </div>
        <div className='form-row flex-end'>
          <Typography className='form-label'>
            Username (Email)
          </Typography>
          <TextField
            name='username'
            value={internalData.username || ''}
            onChange={handleOnEmailChange}
            inputProps={{
              maxLength: 255,
              'data-private': 'redact',
            }}
            error={emailHelperText.username}
            helperText={emailHelperText.username && 'Invalid email format'}
            variant='standard'
            disabled={disableSettingData}
          />
        </div>
        {/* {userSuperAdmin && (
          <div className='form-row flex-end'>
            <Typography className='form-label'>
              Email
            </Typography>
            <TextField
              name='email'
              value={internalData.email || ''}
              onChange={handleOnEmailChange}
              inputProps={{
                maxLength: 255,
                'data-private': 'redact',
              }}
              error={emailHelperText.email}
              helperText={emailHelperText.email && 'Invalid email format'}
              variant='standard'
            />
          </div>
        )} */}
        {(schoologyFlag || userSuperAdmin || userAdmin) && (
          <div className='form-row flex-end'>
            <Typography className='form-label'>
              Schoology ID (Optional)
            </Typography>
            <TextField
              name='schoologyId'
              value={internalData.schoologyId || ''}
              onChange={handleOnChange}
              inputProps={{
                maxLength: 255,
                'data-private': 'redact',
              }}
              variant='standard'
            />
          </div>
        )}

        {isB2cId || disableSettingData
          ? <br /> : (
            <>
              <div className='form-row flex-end'>
                <Typography className='form-label'>
                  Password
                </Typography>
                <TextField
                  className='text-form'
                  value={internalData.password || ''}
                  inputProps={{
                    maxLength: 255,
                    'data-private': 'redact',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  type='password'
                  name='password'
                  onChange={handleOnChange}
                  onBlur={() => {
                    validatePasswordTeacher(internalData.password, internalData.confirmPassword);
                  }}
                  error={passwordError.password !== null}
                  helperText={passwordError.password}
                  variant='standard'
                  disabled={disableSettingData}
                />
              </div>
              <div className='form-row flex-end'>
                <Typography className='form-label'>
                  Confirm Password
                </Typography>
                <TextField
                  className='text-form'
                  value={internalData.confirmPassword || ''}
                  inputProps={{
                    maxLength: 255,
                    'data-private': 'redact',
                  }}
                  type='password'
                  name='confirmPassword'
                  onChange={handleOnChange}
                  onBlur={() => {
                    validatePasswordTeacher(internalData.password, internalData.confirmPassword);
                  }}
                  error={passwordError.confirmPassword !== null}
                  helperText={passwordError.confirmPassword}
                  variant='standard'
                />
              </div><br />
            </>
          )}

        <DistrictSchoolSelector
          onChange={handleOnDistrictSchoolChange}
          selectedDistrictId={internalData.district && internalData.district.key}
          selectedSchoolId={internalData.school && internalData.school.key}
        />
        <div className='form-row'>
          <Typography className='form-label for-checkbox'>
            Move Students
          </Typography>
          <Checkbox
            checked={internalData.shouldMoveStudent || false}
            onChange={handleCheckboxChange}
            name='shouldMoveStudent'
            className='vizzle-checkbox'
          />
        </div>
        {userSuperAdmin && (
          <div className='form-row'>
            <div className='form-row'>
              <Typography className='form-label for-checkbox'>
                Supervisor or Director
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.supervisorOrDirector || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.supervisorOrDirector'
                className='vizzle-checkbox'
              />
            </div>
            <div className='form-row'>
              <Typography className='form-label for-checkbox admin-communication-label'>
                Special Ed Supervisor
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.specialEdSupervisor || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.specialEdSupervisor'
                className='vizzle-checkbox'
              />
            </div>
          </div>
        )}

        <div className='form-row'>
          {(userSuperAdmin || userAdmin) && (
            <div className='form-row'>
              <Typography className='form-label for-checkbox'>
                Lead Teacher
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.leadTeacher || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.leadTeacher'
                className='vizzle-checkbox'
              />
            </div>
          )}
          {userSuperAdmin && (
            <div className='form-row'>
              <Typography className='form-label for-checkbox admin-communication-label'>
                Create Student
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.createStudent || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.createStudent'
                className='vizzle-checkbox'
              />
            </div>
          )}
        </div>
        {userSuperAdmin && (
          <div className='form-row'>
            <div className='form-row'>
              <Typography className='form-label for-checkbox admin-communication-label'>
                Case Manager
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.caseManager || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.caseManager'
                className='vizzle-checkbox'
              />
            </div>
          </div>
        )}
        {userSuperAdmin && (
          <div className='form-row'>
            <div className='form-row'>
              <Typography className='form-label for-checkbox'>
                Paraprofessional or 1:1 Aide
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.paraprofessionalOrAide || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.paraprofessionalOrAide'
                className='vizzle-checkbox'
              />
            </div>
            <div className='form-row'>
              <Typography className='form-label for-checkbox admin-communication-label'>
                Parent or Home Caregiver
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.parentOrHomeCaregiver || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.parentOrHomeCaregiver'
                className='vizzle-checkbox'
              />
            </div>
          </div>
        )}
        {userSuperAdmin && (
          <div className='form-row'>
            <div className='form-row'>
              <Typography className='form-label for-checkbox'>
                Training Center Only
              </Typography>
              <Checkbox
                checked={internalData.userGroupRoles?.trainingCenterOnly || false}
                onChange={handleCheckboxChange}
                name='userGroupRoles.trainingCenterOnly'
                className='vizzle-checkbox'
              />
            </div>
          </div>
        )}
        {userSuperAdmin && (
          <div className='form-row'>
            <div className='form-row'>
              <Typography className='form-label for-checkbox'>
                Admin
              </Typography>
              <Checkbox
                checked={internalData.isUserDistrictAdmin || false}
                onChange={handleCheckboxChange}
                name='isUserDistrictAdmin'
                className='vizzle-checkbox'
              />
            </div>
            <div className='form-row'>
              <Typography className='form-label for-checkbox admin-communication-label'>
                Admin Communication
              </Typography>
              <Checkbox
                checked={internalData.adminCommunication || false}
                onChange={handleCheckboxChange}
                name='adminCommunication'
                className='vizzle-checkbox'
              />
            </div>
          </div>
        )}
      </FormControl>
    </div>
  );
};

UpdateTeacherForm.defaultProps = {
  onDataChange: () => null,
  teacherFormData: {},
};

UpdateTeacherForm.propTypes = {
  teacherFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    isUserDistrictAdmin: PropTypes.bool,
    schoolId: PropTypes.number,
    adminCommunication: PropTypes.bool,
    userGroupRoles: PropTypes.shape({
      supervisorOrDirector: PropTypes.bool,
      specialEdSupervisor: PropTypes.bool,
      leadTeacher: PropTypes.bool,
      caseManager: PropTypes.bool,
      paraprofessionalOrAide: PropTypes.bool,
      parentOrHomeCaregiver: PropTypes.bool,
      trainingCenterOnly: PropTypes.bool,
    }),
    b2cId: PropTypes.bool,
    isClasslinkDistrict: PropTypes.bool,
    isCleverDistrict: PropTypes.bool,
  }),
  onDataChange: PropTypes.func,
};

export default UpdateTeacherForm;

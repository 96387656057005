import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { completeReviewTooltipText } from '../../../../texts/TooltipText';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import TooltipWithIndicator from '../../../../components/tooltip/TooltipWithIndicator';
import { useDomain } from '../../../../states/AppDomainProvider';
import { completeReview } from '../../../../services/LessonService';
import { UNAUTHORIZED } from '../../../../AppConstants';
import Logger from '../../../../utils/Logger';

/**
 * Lesson stepper submit a lesson to vizzle library
 * @export
 */
export default function LessonStepperCompleteReview({ disabled }) {
  const { lessonDataMaintenanceDomain, uiDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const [loading, setLoading] = useState(false);

  const onClickHandler = async () => {
    try {
      setLoading(true);
      await completeReview(lessonData.attributes.uuid);
      const updatedLessonData = {
        ...lessonData,
        attributes: {
          ...lessonData.attributes,
          submitted: true,
        },
      };
      await lessonDataMaintenanceDomain.updateLessonData(updatedLessonData, false);
      await uiDomain.showSnackbar(true, 'info', 3000, 'Review Complete - activity returned to user.');
      setLoading(false);
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
      }
      Logger.logError({
        ERROR: error,
      });
      setLoading(false);
    }
  };

  return (
    <Box display='flex'>
      <ButtonWithSpinner
        disabled={!lessonData.attributes || lessonData.attributes.submitted || disabled}
        label='Complete Review - return to user'
        onClick={onClickHandler}
        loading={loading}
        fullWidth
        className='vizzle-under-review'
      />
      <Box display='flex' ml='10px' my='auto'>
        <TooltipWithIndicator className='vizzle-complete-review-tooltip' {...completeReviewTooltipText} />
      </Box>
    </Box>
  );
}

LessonStepperCompleteReview.defaultProps = {
  disabled: false,
};

LessonStepperCompleteReview.propTypes = {
  disabled: PropTypes.bool,
};

import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import { logout } from '../../../services/LoginService';
import './ResetPasswordSuccess.scss';

const ResetPasswordSuccess = () => (
  <div className='reset-password-success'>
    <div className='message'>
      Password reset successfully
    </div>
    <div className='actions'>
      <ButtonWithSpinner
        className='btn-primary'
        label='Return to Login Page'
        fullWidth
        onClick={() => {
          logout(false);
        }}
      />
    </div>
  </div>
);

export default ResetPasswordSuccess;

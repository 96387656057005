import { useState } from 'react';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import UrlLink from '../../../components/urllink/UrlLink';
import { URL_SKILLS_SUITE } from '../../../routes/RouteConstants';
import './TeacherCourseSkillsSuiteMenu.scss';
import { NON_ACADEMIC_SUBJECTS, SUBJECT_CONSTANTS } from '../../../constants/LessonConstant';
import { TEACHER_COURSES_ROUTES } from './TeacherCourseConstants';

export const nonAcademicSubjectList = [
  SUBJECT_CONSTANTS.life,
  NON_ACADEMIC_SUBJECTS.socialSkills,
  NON_ACADEMIC_SUBJECTS.transitionalSkills,
];

const TeacherCourseSkillsSuiteMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { subjectId: currentSelectedSubjectId } = useParams();

  const handleOnSubjectClick = (subjectId) => {
    navigate(`${URL_SKILLS_SUITE.replace(':subjectId', subjectId)}/${TEACHER_COURSES_ROUTES.LESSONS}`);
    handleClose();
  };

  return (
    <div className='teacher-course-skill-suite-menu'>
      <UrlLink
        onClick={handleClickListItem}
        title={(
          <div className='title'>
            <div className='course-subject-title'>
              Skills Suite
            </div>
            <ExpandMoreIcon className='expand-more' />
            <div className='beta-tag'>BETA</div>
          </div>
        )}
        url={URL_SKILLS_SUITE.replace('/:subjectId', '')}
        data-test='skills-suite'
        dataTest='skills-suite'
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {nonAcademicSubjectList.map((subject) => (
          <MenuItem
            key={`course-subject-menu${subject.subjectId}`}
            onClick={() => handleOnSubjectClick(subject.subjectId)}
            selected={subject.subjectId === currentSelectedSubjectId}
            className='course-subject-menu-item'
            data-test={`course-subject-${subject.subjectId}`}
          >
            {subject.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TeacherCourseSkillsSuiteMenu;

import { useEffect, useMemo, useRef } from 'react';
import { getAdmins } from '../../../../services/AdminService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../context/AdminContextProvider';

export const useGetAdminsData = () => {
  const { adminAdminsDomain } = useAdmin();
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const getAdminsData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        adminAdminsDomain.updateAdminsLoadingFlag(true);
        const result = await getAdmins();
        adminAdminsDomain.updateAdminsData(result, false);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      await adminAdminsDomain.updateAdminsLoadingFlag(false);
    }
  };

  return {
    getAdminsData,
  };
};

export const useAdminsTable = () => {
  const { adminAdminsDomain } = useAdmin();
  const {
    loading,
    adminsData,
  } = adminAdminsDomain.domainData;

  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '15%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '15%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '15%',
    },
    {
      title: 'District',
      align: 'left',
      dataField: 'vizzleDistrictName',
      width: '22.5%',
    },
    {
      title: 'School',
      align: 'left',
      dataField: 'school',
      width: '22.5%',
    },
    {
      title: 'Data Access',
      align: 'center',
      dataField: 'adminDataAccess',
      dataRenderer: (data) => (data.adminDataAccess ? 'Y' : 'N'),
      width: '10%',
    },
  ];

  const tableData = useMemo(() => {
    const { data, included } = adminsData;
    const result = data.map((d) => {
      const mappedData = {
        id: d.id,
        ...d.attributes,
      };

      const { relationships: { school } } = d;
      const schoolRelationShipData = school.data;

      const schoolObjectData = included.find((b) => b.id.toString() === schoolRelationShipData.id);
      if (schoolObjectData) {
        mappedData.school = schoolObjectData.attributes.name;
        mappedData.vizzleDistrictName = schoolObjectData.attributes.vizzleDistrictName;
      }
      return mappedData;
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminsData]);

  const searchTableFunction = (keyword, data) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    const name = `${data.firstName} ${data.lastName}`.toLowerCase();
    const userName = data.userName.toLowerCase();
    const isNameMatch = name.indexOf(lowerCaseKeyword) > -1;
    const isUsernameMatch = userName.indexOf(lowerCaseKeyword) > -1;
    return isNameMatch || isUsernameMatch;
  };

  return {
    tableConfig,
    loading,
    tableData,
    searchTableFunction,
  };
};

export const useHandleAdminTable = () => {
  const updateDistrictRef = useRef(null);
  const { adminAdminsDomain } = useAdmin();
  const { selectedAdminIds } = adminAdminsDomain.domainData;

  const handleOnSelectedRowIds = (rowIds, clickParam) => {
    adminAdminsDomain.updateSelectedAdminIds(rowIds);
    if (clickParam && clickParam.doubleClicks && updateDistrictRef.current) {
      updateDistrictRef.current.openUpdateDistrict();
    }
  };

  return {
    updateDistrictRef,
    selectedAdminIds,
    handleOnSelectedRowIds,
  };
};

export const useInitialAdminsData = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;
  const {
    getAdminsData,
  } = useGetAdminsData();

  useEffect(() => {
    if (userSuperAdmin) {
      getAdminsData();
    }
    // eslint-disable-next-line
  }, []);
};

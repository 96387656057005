import { moveStudents } from '../../../../../../services/AdminService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';

export const useGetSelectedStudentTable = ({
  studentTableData,
}) => {
  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '36%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '32%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '32%',
    },
  ];

  const tableData = studentTableData.map((t) => {
    const { attributes: { userName, firstName, lastName } } = t;
    return {
      userName,
      firstName,
      lastName,
    };
  });

  return {
    tableConfig,
    tableData,
  };
};

export const useMoveStudents = ({
  setOpen,
  onMoveStudentsSuccess,
  selectedStudents,
}) => {
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const moveStudentsToNewSchool = async (data) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await moveStudents(data.school.key, selectedStudents.map((t) => t.id));
        await onMoveStudentsSuccess();
        setOpen(false);
        let message = '';
        if (selectedStudents.length > 1) {
          message = `${selectedStudents.length} students are moved to ${data.school.name}.`;
        } else {
          const studentObject = selectedStudents[0];
          const { attributes } = studentObject;
          message = `Student ${attributes.firstName} ${attributes.lastName} is moved to ${data.school.name}.`;
        }
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    moveStudentsToNewSchool,
    loading,
  };
};

export const validateMoveStudentFormData = (data) => data && data.school && data.school.key !== null && data.school.key !== undefined && data.school.key !== '';

export const useHandleSearchDistrictSchoolSelector = ({
  onDataChange,
  data,
  setData,
}) => {
  const handleOnDistrictSchoolChange = async (selectedDistrictSchool) => {
    const updatedData = {
      ...data,
      school: selectedDistrictSchool.school,
    };
    setData(updatedData);
    onDataChange(updatedData, validateMoveStudentFormData(updatedData));
  };

  return {
    handleOnDistrictSchoolChange,
  };
};

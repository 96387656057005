/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { URL_LOGIN } from '../../routes/RouteConstants';

const DESTINATION_PARAM = 'destination';

export default function LandingLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);

  const destination = query.get('destination');
  if (!destination) {
    navigate(URL_LOGIN);
  }

  const keys = (Array.from(query.keys())).filter((q) => q !== destination);

  const extraParams = {};
  keys.forEach((key) => {
    extraParams[key] = query.get(key);
  });

  navigate(
    URL_LOGIN,
    {
      state: {
        pathname: URL_LOGIN,
        redirecTo: destination,
        extraParams,
      },
    },
  );
  return (
    <>
    </>
  );
}

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDomain } from '../../states/AppDomainProvider';
import { getListOfStudents } from '../../services/StudentService';
import { URL_MY_CLASSROOM } from '../../routes/RouteConstants';
import { MY_CLASSROOM_ROUTES } from '../myclassroom/MyClassRoomConstant';

let fetching = false;
export const useGetStudents = () => {
  const { userDomain, studentsDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { students } = studentsDomain.domainData;
  useEffect(() => {
    const getStudents = async () => {
      const studentList = await getListOfStudents(user.id);
      studentsDomain.updateStudentList(studentList.students);
      fetching = false;
    };

    if ((students === null || students.length === 0) && !fetching) {
      fetching = true;
      getStudents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useGetCurrentStudent = () => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;
  if (!currentSelectedStudentId) {
    return null;
  }
  const currentStudent = students.find((s) => s.id === currentSelectedStudentId);
  return {
    ...currentStudent,
    userType: 'Student',
  };
};

export const useGoToAddStudent = () => {
  const navigate = useNavigate();
  const goToAddStudentMenu = () => {
    navigate(
      `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.STUDENT_MANAGEMENT}`,
    );
  };

  return goToAddStudentMenu;
};

export const useGoToAddStudentGroup = () => {
  const navigate = useNavigate();
  const goToAddStudentGroupMenu = () => {
    navigate(
      `${URL_MY_CLASSROOM}/${MY_CLASSROOM_ROUTES.STUDENT_GROUPS}`,
    );
  };

  return goToAddStudentGroupMenu;
};

export const getStudent = (students, id) => students.find((student) => student.id.toString() === id.toString());

export const getStudentName = (students, id) => {
  const s = getStudent(students, id);
  return `${s.firstName} ${s.lastName}`;
};

import { useRef } from 'react';
import { getAllDistricts } from '../../../../services/AdminService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../context/AdminContextProvider';
import { sort } from '../../../../utils/ArrayUtils';

export const useGetDistrictData = () => {
  const { uiDomain } = useDomain();
  const { districtsDomain } = useAdmin();
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();

  // eslint-disable-next-line no-unused-vars
  const getDistrictsData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        districtsDomain.updateLoadDistrictsIsLoading(true);

        const districtsData = await getAllDistricts();
        const flattenData = districtsData.data.map((d) => ({
          id: d.id,
          type: d.type,
          ...d.attributes,
        }));
        sort(flattenData, 'name');
        districtsDomain.updateDistricts(flattenData, false);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      await districtsDomain.updateLoadDistrictsIsLoading(false);
      await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
    }
  };

  return {
    loading,
    getDistrictsData,
  };
};

export const useDistrictTable = () => {
  const tableConfig = [
    {
      title: 'District',
      align: 'left',
      dataField: 'name',
      width: '100%',
    },
  ];

  return {
    tableConfig,
  };
};

export const useHandleDistrictTable = () => {
  const { districtsDomain } = useAdmin();
  const { selectedDistrictIds } = districtsDomain.domainData;

  const updateDistrictRef = useRef(null);

  const handleOnSelectedRowIds = (rowIds, clickParam) => {
    if (rowIds.length > 1) {
      return;
    }
    districtsDomain.updateSelectedDistricts(rowIds);
    if (clickParam && clickParam.doubleClicks && updateDistrictRef.current) {
      updateDistrictRef.current.openUpdateDistrict();
    }
  };

  return {
    selectedDistrictIds,
    handleOnSelectedRowIds,
    updateDistrictRef,
  };
};

import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../../AppConstants';
import { assignLessonSubject, changeLessonSubject, getSubjectObjectBySubjectName } from '../../../../services/SubjectService';
import { getInitialDataForSorting, getInitialDataForSequencing, KEY_WRONG } from '../../../../utils/activitytype/ActivityTypeInitialData';

/**
 * Filter out the hidden card before saving.
 *
 * @param pagesDataInput array of pages data
 * @return list of page data and cards that removed the hiddend cards.
 */
export const filterSavablePagesData = (pagesDataInput) => {
  const pagesArray = pagesDataInput.pages
    .filter((page) => (page !== null && page !== undefined))
    .map((page) => {
      if (page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA
        || page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
        const cardsOnPage = page.cards || [];
        const showedCards = cardsOnPage.filter((card) => (
          !card.hide
        ));
        return {
          ...page,
          cards: [...showedCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
        const responseCardsOnPage = page.responseCards || [];
        const showedCards = responseCardsOnPage.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });
        return {
          ...page,
          responseCards: [...showedCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
        let { instructions } = getInitialDataForSorting();
        if (page.instructions.text) {
          instructions = page.instructions;
        }
        const { text } = instructions;
        if (text) {
          const { defaultText, ...rest } = text;
          const { lines } = rest;
          lines.forEach((line) => {
            if (!line.color) {
              // eslint-disable-next-line no-param-reassign
              line.color = '#000000';
            }
            line.elements.forEach((e) => {
              if (!e.color) {
                e.color = '#000000';
              }
            });
          });
          return {
            ...page,
            instructions: {
              ...instructions,
              text: { ...rest, lines: [...lines] },
            },
          };
        }
      } else if (page.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
        const gameCards = page.gameCards || [];
        const showedCards = gameCards.filter((card) => (
          !card.disabled
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });
        return {
          ...page,
          gameCards: [...showedCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
        let { instructions } = getInitialDataForSequencing();
        if (page.instructions.text) {
          instructions = page.instructions;
        }
        const { text } = instructions;
        if (text) {
          const { defaultText, ...rest } = text;
          const { lines } = rest;
          lines.forEach((line) => {
            if (!line.color) {
              // eslint-disable-next-line no-param-reassign
              line.color = '#000000';
            }
            line.elements.forEach((e) => {
              if (!e.color) {
                e.color = '#000000';
              }
            });
          });
          return {
            ...page,
            instructions: {
              ...instructions,
              text: { ...rest, lines: [...lines] },
            },
          };
        }
      } else if (page.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY) {
        const causeCardsOnPage = page.causeCards || [];
        const causeCards = causeCardsOnPage.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });

        const effectCardsOnPage = page.effectCards || [];
        const effectCards = effectCardsOnPage.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });

        return {
          ...page,
          causeCards: [...causeCards],
          effectCards: [...effectCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY || page.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
        const promptCards = page.promptCards || [];
        const activePromptCards = promptCards.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });

        const responseCards = page.responseCards || [];
        const activeResponseCards = responseCards.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });
        return {
          ...page,
          promptCards: [...activePromptCards],
          responseCards: [...activeResponseCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
        const answerCards = page.answerCards || [];
        const activeAnswerCards = answerCards.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });
        return {
          ...page,
          answerCards: [...activeAnswerCards],
        };
      } else if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
        const answerCards = page.answerCards || [];
        const activeResponseCards = answerCards.filter((card) => (
          !card.hide
        )).map((card) => {
          // Remove hide attribute
          const { hide, ...rest } = card;
          return rest;
        });
        return {
          ...page,
          answerCards: [...activeResponseCards],
        };
      }
      return page;
    });
  return {
    ...pagesDataInput,
    pages: pagesArray,
  };
};

/**
 * construct standard object for saving process
 *
 * @param lessonData lesson data to create the lesson standard
 * @param lessonStandards list of lesson standard
 * @return standard object
 */
export const getLessonStandardObject = (lessonData, lessonStandards) => {
  const filteredStandard = [];
  if (lessonData.attributes.standards) {
    lessonData.attributes.standards.forEach((standard) => {
      const standardObject = lessonStandards.find((lessonStandard) => (
        lessonStandard.id === standard
      ));
      if (standardObject) {
        filteredStandard.push(standardObject);
      }
    });
  }

  const standardObject = filteredStandard.map((standard) => {
    const { attributes } = standard;
    return {
      standard_id: standard.id,
      standard_code: attributes.standardCode,
      standard_grade: attributes.standardGrade,
      standard_subject: attributes.subject,
      standard_description: attributes.standardDescription,
      standard_scope: attributes.scope,
    };
  });
  return standardObject;
};

/**
 * A helper method to decide which service to call to update lesson subject
 *
 * @param subjectToSave subject to save
 * @param lessonUuid lesson id
 * @param changeSubjectFrom old subject value
 * @return result of the service call
 */
export const getSaveSubjectFunction = async (subjectToSave, lessonUuid, changeSubjectFrom) => {
  if (!subjectToSave) {
    return null;
  }
  const subject = getSubjectObjectBySubjectName(subjectToSave);
  if (changeSubjectFrom) {
    return changeLessonSubject(lessonUuid, subject.subjectId);
  }
  return assignLessonSubject(lessonUuid, subject.subjectId);
};

/**
 * Validate data before saving
 * @param pagesData pages data to validate
 * @return true when the pages data is valid and good for saving, otherwise false.
 */
export const validateDataBeforeSaving = (pagesData) => {
  const { pages } = pagesData;

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    if (page.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
      const { promptCards } = page;
      const emptyQuestionKeyIndex = promptCards.findIndex((propmtCardsOnPage) => {
        const { questionKey } = propmtCardsOnPage;
        return questionKey === null || questionKey === undefined || questionKey.trim().length === 0;
      });

      if (emptyQuestionKeyIndex >= 0) {
        return {
          success: false,
          message: `Matching activity on page ${i + 1} is missing the Question Key.`,
        };
      }
    } else if (page.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY) {
      const { responseCards } = page;
      const correctAnswerIndex = responseCards.findIndex((r) => r.key !== KEY_WRONG);
      if (correctAnswerIndex < 0) {
        return {
          success: false,
          message: `Categorizing activity on page ${i + 1} has no correct answer.`,
        };
      }
    } else if (page.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
      const { key } = page;
      if (!key) {
        return {
          success: false,
          message: `Advanced Matching activity on page ${i + 1} is missing the Question Key.`,
        };
      }
    } else if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
      const { questionCard } = page;
      if (!questionCard.questionKey) {
        return {
          success: false,
          message: `Multiple choices activity on page ${i + 1} is missing the Question Key.`,
        };
      }
    } else if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
      const { questionCard } = page;
      if (!questionCard.questionKey) {
        return {
          success: false,
          message: `Multiple choices activity on page ${i + 1} is missing the Question Key.`,
        };
      }
    }
  }

  // pass all the validations
  return {
    success: true,
  };
};

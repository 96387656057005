import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';

/**
 * Lesson card action
 */
export const createLessonCardAction = (lesson, user, userProfile, terminologies) => {
  const actions = [(
    <PreviewLessonAction
      lesson={lesson}
      className='top-left'
      key={`lessons-group-play-lesson-preview-${lesson.id}`}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />
  ),
  ];
  return actions;
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
} from '@mui/material';
import BookCardEditor from '../../../../../../../../components/vizzle/page/book/BookCardEditor';
import TextAndMediaFormatting from '../../TextAndMediaFormatting';
import AddPhotoOrVideo from '../../AddPhotoOrVideo';
import AddAudio from '../../AddAudio';
import { SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../../../../../../../../components/vizzle/page/common/CardEditorConstants';

const initStateCardPopupEditor = {
  currentSelectedBook: 0,
  selectedSection: SECTION_IMAGE,
};

const CardPopupEditor = ({ data, onDataChange }) => {
  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initStateCardPopupEditor });

  let { questionCard } = data;
  if (!questionCard) {
    questionCard = {};
  }
  const createBookCardPreview = () => {
    const layoutConfig = [{
      text: questionCard.text,
      image: questionCard.image,
      audio: questionCard.audio,
      video: questionCard.video,
    }];

    const props = {
      layoutConfig,
      selectedPageIndex: currentSelectedData.currentSelectedBook,
      selectedSection: currentSelectedData.selectedSection,
      onImageSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
        });
      },
      onTextSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
        });
      },
      onAudioSelected: (currentSelectedIndex, section) => {
        setCurrentSelectedData({
          ...currentSelectedData,
          currentSelectedBook: currentSelectedIndex,
          selectedSection: section,
        });
      },
    };

    return <BookCardEditor {...props} />;
  };

  const onTextApply = async (textLines) => {
    const { text } = questionCard;
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        text: {
          ...text,
          lines: [
            ...textLines,
          ],
        },
      },
    };
    onDataChange(newData);
  };

  const onTextRemoved = () => {
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        text: null,
      },
    };
    onDataChange(newData);
  };

  const onImageAndVideoSelected = (media) => {
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        image: media.selectedImageUrl,
        video: media.selectedVideoUrl,
      },
    };
    onDataChange(newData);
  };

  const onImageRemove = () => {
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        image: null,
        video: null,
      },
    };
    onDataChange(newData);
  };

  const onAudioApply = (audioData) => {
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        audio: audioData,
      },
    };
    onDataChange(newData);
  };

  const onAudioRemoved = () => {
    const newData = {
      ...data,
      questionCard: {
        ...questionCard,
        audio: null,
      },
    };
    onDataChange(newData);
  };

  return (
    <Grid container spacing={3} wrap='nowrap' style={{ height: 'inherit', padding: '8px' }}>
      <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit' }}>
        {createBookCardPreview()}
      </Grid>
      <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit' }}>
        <Box height='100%' className='center'>
          {currentSelectedData.selectedSection === SECTION_TEXT && (
            <Box height='100%'>
              <TextAndMediaFormatting
                text={questionCard.text}
                onTextApply={onTextApply}
                onTextRemoved={onTextRemoved}
              />
              <br />
            </Box>
          )}
          {currentSelectedData.selectedSection === SECTION_IMAGE && (
            <AddPhotoOrVideo
              image={questionCard.image}
              onSelect={onImageAndVideoSelected}
              onRemove={onImageRemove}
            />
          )}
          {currentSelectedData.selectedSection === SECTION_AUDIO && (
            <AddAudio
              audio={questionCard.audio}
              onAudioApply={onAudioApply}
              onAudioRemoved={onAudioRemoved}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

CardPopupEditor.defaultProps = {
  data: { questionCard: {} },
  onDataChange: () => { },
};

CardPopupEditor.propTypes = {
  data: PropTypes.shape({
    questionCard: PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    }),
  }),
  onDataChange: PropTypes.func,
};

export default CardPopupEditor;

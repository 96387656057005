export const GENDER_CONSTANTS_OPTIONS = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'non-binary', name: 'Non-Binary' },
];

export const ETHNIC_CONSTANTS_OPTIONS = [
  { id: 'native', name: 'American Indian or Alaska Native' },
  { id: 'asian', name: 'Asian' },
  { id: 'black', name: 'Black or African American' },
  { id: 'latino', name: 'Hispanic or Latino' },
  { id: 'islander', name: 'Native Hawaiian or Other Pacific Islander' },
  { id: 'multiple-races', name: 'Two or More Races' },
  { id: 'white', name: 'White' },
];

import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import './FileUploadPlaceholder.scss';

export default function FileUploadPlaceholder({
  Icon,
  title,
  subTitle,
  error,
}) {
  return (
    <Paper className='file-upload-placeholder'>
      <Icon className='file-upload-icon' />
      <Typography variant='h6' className='file-upload-sub-text'>
        { title }
      </Typography>

      {subTitle ? (
        <Typography variant='subtitle2' className='file-upload-sub-title'>
          {subTitle}
        </Typography>
      ) : ''}

      {error ? (
        <Typography variant='subtitle1' className='file-upload-error'>
          {error}
        </Typography>
      ) : ''}
    </Paper>
  );
}

FileUploadPlaceholder.defaultProps = {
  Icon: UploadIcon,
  title: 'Click this area to upload',
  subTitle: null,
  error: null,
};

FileUploadPlaceholder.propTypes = {
  // eslint-disable-next-line
  Icon: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  error: PropTypes.string,
};

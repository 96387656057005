import { useState } from 'react';
import { useDomain } from '../../../states/AppDomainProvider';

export const STEP_TEXT_MEDIA = 1;
export const STEP_PREVIEW = 2;
export const STEP_VIZZLE_LIB = 4;

export const useHandleSteps = () => {
  const {
    lessonDataMaintenanceDomain,
  } = useDomain();
  const { currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;

  const [activeStep, setActiveStep] = useState(0);
  const [previewState, setPreviewState] = useState({
    open: false,
    initialSlide: 0,
  });

  const handleStep = (step) => () => {
    if (activeStep != null && step !== STEP_PREVIEW) {
      setPreviewState({
        ...previewState,
        open: false,
        initialSlide: 0,
      });
      setActiveStep(step);
    } else if (step === STEP_PREVIEW) {
      let initialSlide = 0;
      if (STEP_TEXT_MEDIA === activeStep) {
        initialSlide = currentSelectedPagesData;
      }
      setPreviewState({
        ...previewState,
        open: true,
        initialSlide,
      });
    }
  };

  const onPreviewClose = () => {
    setPreviewState({
      ...previewState,
      open: false,
      initialSlide: 0,
    });
  };

  return {
    activeStep,
    setActiveStep,
    handleStep,
    previewState,
    onPreviewClose,
  };
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import TWEEN from '@tweenjs/tween.js';
import FullScreenDialogbox from '../../../components/dialog/FullScreenDialogbox';
import './CelebrationDialog.scss';
import { useInitCelebration, usePreloadCelebration } from './CelebrationHooks';
import { celebrationsMap } from './CelebrationMapping';
import { useDisableRightClick } from '../TerminologiesHooks';
import Logger from '../../../utils/Logger';
import LoadingPlaceHolder from '../LoadingPlaceHolder';

const CelebrationDialog = ({
  celebrationList,
  enableAudio,
}) => {
  const pixiLoader = usePreloadCelebration(celebrationList, enableAudio);

  const {
    celebrationState,
    setCelebrationState,
  } = useInitCelebration();

  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
  }, []);

  useDisableRightClick(celebrationState.open);

  if (!celebrationState.open || !pixiLoader || !celebrationList || celebrationList.length === 0) {
    return null;
  }

  if (!celebrationState.ready) {
    clearInterval(intervalRef.current);
    let isReady = false;
    intervalRef.current = setInterval(() => {
      let resources = Object.keys(pixiLoader.resources).filter((key) => key.startsWith(celebrationState.celebrationId));
      if (!enableAudio) {
        resources = resources.filter((r) => {
          const resource = pixiLoader.resources[r];
          return !(['mp3'].includes(resource.extension) || (resource && resource.data && resource.data.nodeName && resource.data.nodeName.toLowerCase() === 'audio'));
        });
      }
      // eslint-disable-next-line no-underscore-dangle
      const notCompleted = resources.findIndex((r) => (pixiLoader.resources[r].isLoading));
      if (!pixiLoader.loading && notCompleted < 0) {
        isReady = true;
        clearInterval(intervalRef.current);
        clearTimeout(timeoutRef.current);
        setCelebrationState((state) => ({
          ...state,
          ready: true,
        }));
      }

      // if the resurces cannot be loaded within 30000 seconds, then skip the reinforcer
      if (!timeoutRef.current && !isReady) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          clearInterval(intervalRef.current);
          clearTimeout(timeoutRef.current);
          if (!celebrationState.ready) {
            Logger.logError(`Unable to load celebration ${celebrationState.celebrationId}`);
          }
        }, 30000);
      }
    }, 500);
  }

  const CelebrationComponent = celebrationsMap[celebrationState.celebrationId];

  const celebrationData = celebrationList.find((r) => r.id === celebrationState.celebrationId);

  if (!CelebrationComponent) {
    return null;
  }

  // Setup the animation loop.
  function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
  }
  requestAnimationFrame(animate);

  return (
    <FullScreenDialogbox open handleClose={() => { setCelebrationState({ open: false }); }} data-test='celebration-prreview-dialog'>
      <div
        className='celebration-player'
      >
        <Paper elevation={3} className='celebration-dialog-container' data-test='celebration-dialog-container'>
          {celebrationState.ready ? (
            <CelebrationComponent
              pixiLoader={pixiLoader}
              celebrationData={celebrationData}
              onFinish={() => {
                setCelebrationState({
                  open: false,
                  celebrationId: '',
                });
              }}
            />
          ) : (
            <div className='loading'>
              <LoadingPlaceHolder message='Celebration is loading.' />
            </div>
          )}

        </Paper>
      </div>
    </FullScreenDialogbox>
  );
};

CelebrationDialog.defaultProps = {
  enableAudio: false,
};

CelebrationDialog.propTypes = {
  celebrationList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    manifest: PropTypes.array,
  })).isRequired,
  enableAudio: PropTypes.bool,
};

export default CelebrationDialog;

import { useMemo, useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    return {
      id,
      droppableId: `cause-and-effect-component-main-droppable-container-${id}`,
    };
  }, []);

  return componentIds;
};

export const useInitializeData = () => ({
  causeAndEffectActivityRef: useRef(null),
  dragAndDropApiRef: useRef(null),
  currentSelectedEffectIndex: useRef(null),
});

export const usePrepareData = ({
  causeAndEffectCards,
}) => {
  const numberOfSets = causeAndEffectCards.filter((card) => card.isCauseCard).length;

  return {
    numberOfSets,
  };
};

export const useGenerateRejectedAnswerRefs = () => {
  const rejectedCausePromptRefs = [];
  const setRejectedCausePromptRefs = (ref, index) => {
    if (ref) {
      rejectedCausePromptRefs[index] = ref;
    }
  };

  const rejectedEffectPromptRefs = [];
  const setRejectedEffectPromptRefs = (ref, index) => {
    if (ref) {
      rejectedEffectPromptRefs[index] = ref;
    }
  };

  const rejectedAnswerResponseRefs = [];
  const setRejectedAnswerResponseRefs = (ref, index) => {
    if (ref) {
      rejectedAnswerResponseRefs[index] = ref;
    }
  };

  return {
    rejectedCausePromptRefs,
    setRejectedCausePromptRefs,
    rejectedEffectPromptRefs,
    setRejectedEffectPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  };
};

export const getSelectedIndexCards = (selectedCauseCards, selectedEffectCards) => {
  const selectedCauseCardIndexes = Object.values(selectedCauseCards);
  const selectedEffectCardIndexes = Object.values(selectedEffectCards);
  const selectedCardIndexes = [
    ...selectedCauseCardIndexes,
    ...selectedEffectCardIndexes,
  ];
  return selectedCardIndexes;
};

export const useDragAndDropSubmitAnswer = ({
  causeAndEffectActivityRef,
  causeAndEffectCards,
  rejectedCausePromptRefs,
  rejectedEffectPromptRefs,
  rejectedAnswerResponseRefs,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  currentSelectedEffectIndex,
}) => {
  const [selectedCauseCards, setSelectedCauseCards] = useState({});
  const [selectedEffectCards, setSelectedEffectCards] = useState({});

  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = () => {
    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(causeAndEffectActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;
    if (!source || !destination) {
      LessonPlayActivityUtils.disableComponent(causeAndEffectActivityRef, false);
      return;
    }

    try {
      const { droppableId } = destination;
      const dropableElement = causeAndEffectActivityRef.current.querySelector(`#${droppableId}`);
      const isDroppedElementCauseCard = dropableElement.dataset.isCauseCard;
      const causeEffectSetIndex = Number(dropableElement.dataset.causeEffectSetIndex);
      const { index: sourceIndex } = source;
      const selectedCard = causeAndEffectCards[sourceIndex];

      if (selectedCard.isCauseCard.toString() === isDroppedElementCauseCard) {
        if (isDroppedElementCauseCard === 'true') {
          setSelectedCauseCards((previousState) => ({
            ...previousState,
            [causeEffectSetIndex]: sourceIndex,
          }));

          // eslint-disable-next-line no-param-reassign
          currentSelectedEffectIndex.current = null;
          await onCorrectAnswerSelected({ insertResponse: true, responseIndex: sourceIndex, once: true, isFinished: false, showReinforcer: false });
        } else {
          // check if the key of effect card matches the key of cause card
          const causeCard = causeAndEffectCards[selectedCauseCards[causeEffectSetIndex]];
          // cause card is not selected yet
          if (!causeCard) {
            onIncorrectAnswerSelected(sourceIndex, true);
            showDropRejection(rejectedEffectPromptRefs[causeEffectSetIndex]);
            showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
          }
          const effectCard = causeAndEffectCards[sourceIndex];

          if (causeCard.key === effectCard.key) {
            const updatedSelectedEffectCards = {
              ...selectedEffectCards,
              [causeEffectSetIndex]: sourceIndex,
            };
            setSelectedEffectCards(updatedSelectedEffectCards);
            // eslint-disable-next-line no-param-reassign
            currentSelectedEffectIndex.current = null;

            const selectedCardIndexes = getSelectedIndexCards(selectedCauseCards, updatedSelectedEffectCards);
            const isFinished = selectedCardIndexes.length === causeAndEffectCards.length;
            await onCorrectAnswerSelected({ insertResponse: true, responseIndex: sourceIndex, once: true, isFinished, showReinforcer: isFinished });

            if (isFinished) {
              onActivityFinished();
            }
          } else {
            onIncorrectAnswerSelected(sourceIndex, true);
            showDropRejection(rejectedEffectPromptRefs[causeEffectSetIndex]);
            showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
          }
        }
      } else {
        onIncorrectAnswerSelected(sourceIndex, true);
        if (isDroppedElementCauseCard === 'true') {
          showDropRejection(rejectedCausePromptRefs[causeEffectSetIndex]);
        } else {
          showDropRejection(rejectedEffectPromptRefs[causeEffectSetIndex]);
        }
        showDropRejection(rejectedAnswerResponseRefs[sourceIndex]);
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      LessonPlayActivityUtils.disableComponent(causeAndEffectActivityRef, false);
    }
  };

  return {
    selectedCauseCards,
    selectedEffectCards,
    handleOnDragStart,
    handleOnDragEnd,
  };
};

const deselectedResponse = (currentSelectedIndex) => {
  // clear selected value
  if (currentSelectedIndex.current) {
    const el = document.getElementById(currentSelectedIndex.current.effectCardId);
    if (el) {
      el.classList.remove('vizzle-selected-component-medium');
    }
    // eslint-disable-next-line no-param-reassign
    currentSelectedIndex.current = null;
  }
};

export const useClickSubmitAnswer = ({
  causeAndEffectActivityRef,
  dragAndDropApiRef,
  currentSelectedEffectIndex,
}) => {
  const handleOnCauseCardClick = (_e, cardDropableId) => {
    if (currentSelectedEffectIndex.current) {
      LessonPlayActivityUtils.stopMedias();

      const dragAndDropApi = dragAndDropApiRef.current;
      const triggerDragAndDrop = async () => {
        await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedEffectIndex.current.effectCardId, currentSelectedEffectIndex.current.draggableId, cardDropableId);
      };

      ObjectUtils.setTimeout(() => {
        triggerDragAndDrop();
      });
    }
  };

  const handleOnEffectCardClick = (_e, isTtsAbleToPlay, card, effectCardId, draggableId) => {
    if (!card.audio && !card.video && !isTtsAbleToPlay) {
      LessonPlayActivityUtils.stopMedias();
    }

    const videoPlaying = document.querySelector('.video-player-dialog');
    if (videoPlaying) {
      return;
    }

    if (!currentSelectedEffectIndex.current || currentSelectedEffectIndex.current.effectCardId !== effectCardId) {
      if (currentSelectedEffectIndex.current && document.getElementById(currentSelectedEffectIndex.current.effectCardId)) {
        document.getElementById(currentSelectedEffectIndex.current.effectCardId).classList.remove('vizzle-selected-component-medium');
      }
      // eslint-disable-next-line no-param-reassign
      currentSelectedEffectIndex.current = {
        effectCardId,
        draggableId,
      };

      causeAndEffectActivityRef.current.classList.add('prompt-selected-response-cards-hover');
      document.getElementById(effectCardId).classList.add('vizzle-selected-component-medium');
    } else if (currentSelectedEffectIndex.current && currentSelectedEffectIndex.current.effectCardId === effectCardId) {
      LessonPlayActivityUtils.stopMedias();
      deselectedResponse(currentSelectedEffectIndex);
      causeAndEffectActivityRef.current.classList.remove('prompt-selected-response-cards-hover');
    }
  };

  return {
    handleOnCauseCardClick,
    handleOnEffectCardClick,
  };
};

export const useSwitchAccessSubmitAnswer = ({
  onTriggerSwitchScan,
  handleOnEffectCardClick,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility } = userProfile;

  const handleResponseSwitchAccessSelect = switchAccessibility ? (_e, isTtsAbleToPlay, card, effectCardId, draggableId) => {
    LessonPlayActivityUtils.stopMedias();
    handleOnEffectCardClick(_e, isTtsAbleToPlay, card, effectCardId, draggableId);
    onTriggerSwitchScan();
  } : () => { };

  return {
    handleResponseSwitchAccessSelect,
  };
};

import { useEffect, useState } from 'react';
import { getStudentGroups } from '../../../../services/StudentService';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';

export const usePrepareStudentGroups = () => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const [studentGroupData, setStudentGroupData] = useState([]);
  const getStudentGroupsData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const result = await getStudentGroups();
        setStudentGroupData(result.data);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    getStudentGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    getStudentGroupsData,
    studentGroupData,
  };
};

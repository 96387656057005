import {
  CARD_MODEL,
  MATCHING_QUIZ,
  FILL_IN_THE_BLANK,
} from '../../../../../../../../components/vizzle/page/game/GameConstants';

import { BLANK_SPACE } from '../../../../../../../../components/vizzle/page/fitb/FillInTheBlankUtils';

export const landingInteractionTypes = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: CARD_MODEL,
    label: 'Pop-up',
  },
  {
    value: MATCHING_QUIZ,
    label: 'Matching',
  },
  {
    value: FILL_IN_THE_BLANK,
    label: 'Fill-in-the-Blank',
  },
];

export const isLandingInteractionEmpty = (landingInteraction) => {
  if (!landingInteraction) {
    return true;
  }

  if (landingInteraction.type === FILL_IN_THE_BLANK) {
    return (
      !landingInteraction.question
      || !landingInteraction.question.question
      || !landingInteraction.question.question.replace(BLANK_SPACE, '')
    ) && (!landingInteraction.answers || landingInteraction.answers.length === 0);
  } else {
    const { questionCard, answerCards } = landingInteraction;
    if (!questionCard && (!answerCards || answerCards.length === 0)) {
      return true;
    }

    const isQuestionCardEmpty = !questionCard.text && !questionCard.image && !questionCard.audio;
    let isAnswerCardEmpty = false;
    if (answerCards && answerCards.length > 0) {
      const notEmptyCard = answerCards.findIndex((card) => (
        card.text || card.image || card.audio
      ));
      isAnswerCardEmpty = notEmptyCard < 0;
    }

    return isQuestionCardEmpty && isAnswerCardEmpty;
  }
};

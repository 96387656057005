import { useRef, useEffect } from 'react';
import { getSchoolsByDistrictId, schoolBatchUpload } from '../../../../services/AdminService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useAdmin } from '../../context/AdminContextProvider';

export const transformDistrictData = (item) => ({
  key: item.id,
  name: item.name,
  value: item.name,
});

export const useGetSchoolData = ({
  tableRef,
}) => {
  const { uiDomain, userDomain } = useDomain();
  const { schoolsDomain, districtsDomain } = useAdmin();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const getSchoolsDataByDistrictIds = async (districtId, resetTableState = true, updateBuilding = false) => {
    const schoolBeforeUpdate = schoolsDomain.domainData.schools;
    const schoolIdsBeforeUpdate = schoolBeforeUpdate.map((s) => s.id);
    await schoolsDomain.updateSelectedDistrictId(districtId);
    const { districts } = districtsDomain.domainData;
    try {
      await callRemoteServiceWrapper(async () => {
        const schoolsData = await getSchoolsByDistrictId(districtId);
        if (schoolsData) {
          const flattenData = schoolsData.data.map((d) => ({
            id: d.id,
            type: d.type,
            ...d.attributes,
          }));

          if (updateBuilding) {
            const newSchools = flattenData.filter((ns) => !schoolIdsBeforeUpdate.includes(ns.id));
            if (newSchools && newSchools.length > 0) {
              const selectedDistrict = districts.find((d) => d.id === String(districtId));
              const { userProfile } = userDomain.domainData;
              const { buildings } = userProfile;

              const newSchoolsObject = newSchools.map((n) => (
                {
                  districtId,
                  districtName: selectedDistrict.name,
                  schoolId: n.id,
                  schoolName: n.name,
                }
              ));

              const updatedBuildings = [
                ...buildings,
                ...newSchoolsObject,
              ];
              userProfile.buildings = updatedBuildings;
              userDomain.updateUserProfile({ ...userProfile });
            }
          }

          await schoolsDomain.updateSchools(flattenData);
        } else {
          await schoolsDomain.updateSchools([]);
        }
        if (resetTableState && tableRef.current) {
          tableRef.current.resetTableState();
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
    }
  };

  return {
    loading,
    getSchoolsDataByDistrictIds,
  };
};

export const useSchoolTable = () => {
  const tableRef = useRef(null);
  const tableConfig = [
    {
      title: 'School',
      align: 'left',
      dataField: 'name',
      width: '100%',
    },
  ];

  return {
    tableConfig,
    tableRef,
  };
};

export const useGetSelectedDistrict = () => {
  const { districtsDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;
  const getSelectedDistrict = (districtId) => districts.filter((district) => district.id === districtId).map((item) => transformDistrictData(item))[0];
  return {
    getSelectedDistrict,
  };
};

export const useHandleSelectedDistrict = ({
  getSchoolsDataByDistrictIds,
}) => {
  const { schoolsDomain } = useAdmin();
  const { selectedDistrictId } = schoolsDomain.domainData;

  const onFilterDistrictChange = (district) => {
    if (!district || !district.key) {
      return;
    }
    getSchoolsDataByDistrictIds(district.key);
  };
  return {
    onFilterDistrictChange,
    selectedDistrictId,
  };
};

export const usePrepareData = ({
  getSchoolsDataByDistrictIds,
}) => {
  const { schoolsDomain } = useAdmin();
  const { schools } = schoolsDomain.domainData;

  const { districtsDomain } = useAdmin();
  const { districts } = districtsDomain.domainData;

  useEffect(() => {
    if (districts && districts.length === 1) {
      getSchoolsDataByDistrictIds(districts[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts]);

  return {
    schools,
  };
};

export const useHandleSchoolTable = () => {
  const { schoolsDomain } = useAdmin();
  const { selectedSchoolIds } = schoolsDomain.domainData;
  const updateSchoolRef = useRef(null);

  const handleOnSelectedRowIds = (selectedRowIds, clickParam) => {
    // if (selectedRowIds.length > 1) {
    //   return;
    // }
    schoolsDomain.updateSelectedSchools(selectedRowIds);
    if (clickParam && clickParam.doubleClicks && updateSchoolRef.current) {
      updateSchoolRef.current.openUpdateSchool();
    }
  };

  return {
    selectedSchoolIds,
    handleOnSelectedRowIds,
    updateSchoolRef,
  };
};

export const useSchoolBatchUpload = () => {
  const { schoolsDomain } = useAdmin();
  const { selectedDistrictId } = schoolsDomain.domainData;

  const importSchoolBatch = async (file) => {
    const result = await schoolBatchUpload(selectedDistrictId, file);
    return result;
  };
  return {
    importSchoolBatch,
  };
};

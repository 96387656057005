import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import { getPageStyle } from '../../common/LessonPageUtil';
import TextLines from '../../../text/TextLines';
import './CauseAndEffectCard.scss';

/**
 * Lesson activity for Cause and Effect
 */
export default function CauseAndEffectCard({ card, isSmallThumbnail }) {
  const caredId = uuidv1();
  return (
    <div className='cause-and-effect-card'>
      <Card
        id={caredId}
        key={caredId}
        className='card'
        style={getPageStyle(card)}
      >
        {
          card.image ? (
            <CardMedia
              className='card-thumbnail-image'
              image={card.image}
              title='Cause'
            />
          )
            : (
              <Box
                component='div'
                className={`card-thumbnail-placeholder ${isSmallThumbnail ? 'small-text' : ''}`}
              >
                {isSmallThumbnail ? 'Image/ Video' : 'Image/Video'}
              </Box>
            )
        }
        <CardContent className={`card-thumbnail-text ${!card.text ? 'card-thumbnail-text-background' : ''} ${isSmallThumbnail ? 'small-text' : ''}`}>
          {card.text
            ? (
              <Box className='card-thumbnail-text-with-value'>
                <TextLines textLines={card.text} isThumbnail />
              </Box>
            ) : (
              <Box className='card-thumbnail-placeholder-text'>Text</Box>
            )}
        </CardContent>
      </Card>
    </div>
  );
}

CauseAndEffectCard.defaultProps = {
  card: { key: 0 },
  isSmallThumbnail: false,
};

CauseAndEffectCard.propTypes = {
  card: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    text: PropTypes.object,
  }),
  isSmallThumbnail: PropTypes.bool,
};

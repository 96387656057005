import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import './CardSelector.scss';

/**
 * Card selection that
 * allows the user to select one option from a set
 *
 * @param props of array of config object. An example is
 * config: [
 *  {
 *    component: bookLayoutOnePageComponent,
 *    dataValue: 'one-page',
 *    onSelect: (dataValue) => {console.log(dataValue)}
 *  },
 *  {
 *    component: bookLayouTwoPageComponent,
 *    dataValue: 'two-page',
 *    onSelect: (dataValue) => {console.log(dataValue)}
 *  },
 * ]
 *
 */
export default function CardSelector({
  config,
  width,
  height,
  align,
  selectedIndex,
  disabled,
  className,
  ...props
}) {
  const handleOnClick = (index) => {
    if (index === selectedIndex) {
      return;
    }
    if (config[index].onClick) {
      config[index].onClick(config[index].dataValue, index);
    }
  };

  const style = {
    width,
    height,
  };
  // by default it is left
  if (align === 'center') {
    style.margin = 'auto';
  }
  return (
    <div
      {...props}
      className={`card-selector-component ${disabled ? 'disable' : ''} ${className}`}
      style={{ ...style }}
    >
      {config.map((c, index) => (
        <Card
          data-test={`card-selector-option-${c.dataValue}`}
          key={uuidv1()}
          style={{ width: `calc( 100% / ${config.length})` }}
          onMouseUp={() => handleOnClick(index)}
          className={`card-selector ${selectedIndex === index ? 'card-selected' : ''} vizzle-card-selector-${index}`}
        >
          <CardActionArea className='card-selector-action-area'>
            <CardContent className='card-selector-action-area-content'>
              {c.component}
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
  );
}

CardSelector.defaultProps = {
  config: [],
  width: '100%',
  height: '50vh',
  align: 'left',
  selectedIndex: null,
  disabled: false,
  className: '',
};

CardSelector.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.node,
    dataValue: PropTypes.any,
    onClick: PropTypes.func,
  })),
  width: PropTypes.string,
  height: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center']),
  selectedIndex: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import MatchingType from '../../../settings/interaction/MatchingType';

const MatchingTypeSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Matching Type');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange({
      interactionType: newValue,
    });
  };

  return (
    <MatchingType
      profile={userProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default MatchingTypeSettings;

import vizzleAxios, { vizzleAxiosAccessTokenLogin } from './service';
import { saveTokenToMemory } from '../utils/VizzleSessionStorage';
import { URL_RESET_PASSWORD } from '../routes/RouteConstants';
import Logger from '../utils/Logger';

/**
 * User login service
 * @param {*} username username to login
 * @param {*} password password to login
 */
export const login = async (username, password) => {
  const url = '/user/login';
  const result = await vizzleAxios.post(url, {
    userName: window.btoa(String(username).toLowerCase()),
    password: window.btoa(password),
  });
  // stripe out token infomation
  const { token, ...rest } = result.data;

  saveTokenToMemory(token);
  return rest;
};

/**
 * User login with vizzle token service
 * @param {*} token vizzle token
 */
export const loginWithVizzleToken = async (vizzleToken) => {
  const url = '/user/vizzle/oauth';

  const result = await vizzleAxios({
    method: 'GET',
    url,
    headers: {
      'Vizzle-Jti': vizzleToken,
    },
  });

  // stripe out token infomation
  const { token, ...rest } = result.data;

  saveTokenToMemory(token);
  return rest;
};

export const loginWithOauth = async (clientId, redirectUri) => {
  const url = `/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const loginWithOidc = async (authorizationCode) => {
  const url = `/user/cis/oauth?authorizationCode=${authorizationCode}&provider=cis`;
  const result = await vizzleAxios.get(url);
  // stripe out token infomation
  const { token, ...rest } = result.data;

  saveTokenToMemory(token);
  return rest;
};

export const resetPassword = async (email, location) => {
  const url = '/user/forgotpassword/email';
  const clientUrl = `${location}${URL_RESET_PASSWORD}`.replace(':reset_code', '{reset_code}');
  const data = new URLSearchParams();
  data.set('email', email);
  data.set('clientUrl', clientUrl);

  const result = await vizzleAxios({
    method: 'POST',
    url,
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return result;
};

export const submitNewPassword = async (newPassword, resetCode) => {
  const url = '/user/forgotpassword/reset';
  const data = new URLSearchParams();
  data.set('new_password', newPassword);
  data.set('reset_code', resetCode);
  const result = await vizzleAxios({
    method: 'POST',
    url,
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return result;
};

export const verifyUser = async (password, userId) => {
  const url = `/users/${userId}/pwd`;
  const result = await vizzleAxios.put(url, {
    password: window.btoa(password),
  });
  return result.data;
};

export const getUserIdByVerificationToken = async (token) => {
  const url = `/users/verify/${token}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getVizzleJti = async () => {
  const url = '/users/jti';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export default login;

const callLogoutFn = async () => {
  try {
    const url = '/user/logout';
    await vizzleAxios.get(url);
  } catch (e) {
    Logger.logError(e);
  }
};

export const logout = async () => {
  await callLogoutFn();

  const cisBaseUrl = process.env.REACT_APP_CIS_BASE_URL;
  const cisPolicy = process.env.REACT_APP_CIS_APP_POLICY;
  const baseUrl = window.location.origin;
  const url = `https://${cisBaseUrl}/${cisPolicy}/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/`;
  window.location = url;
};

export const loginWithRethinkAccessToken = async (accessToken) => {
  const url = '/user/rethink-sso/inbound/authenticate';
  const result = await vizzleAxiosAccessTokenLogin.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  // stripe out token infomation
  const { token, ...rest } = result.data;

  saveTokenToMemory(token);
  return rest;
};

export const loginWithRethink = async (userName, password, accessToken) => {
  const url = '/user/rethink-sso/inbound/map';
  const result = await vizzleAxios.post(url, {
    userName,
    password,
    accessToken,
  });
  // stripe out token infomation
  const { token, ...rest } = result.data;

  saveTokenToMemory(token);
  return rest;
};

/**
 * User login with clever code
 * @param {*} cleverCode clever code
 */
export const loginWithProvider = async (provider, authorizationCode) => {
  const url = `/user/oauth?provider=${provider}&authorizationCode=${authorizationCode}`;
  const result = await vizzleAxios.get(url);
  const { token, ...rest } = result.data;
  saveTokenToMemory(token);
  return rest;
};

export const DAYS = [
  {
    id: 1,
    name: 'Plan 1',
    value: 1,
  },
  {
    id: 2,
    name: 'Plan 2',
    value: 2,
  },
  {
    id: 3,
    name: 'Plan 3',
    value: 3,
  },
  {
    id: 4,
    name: 'Plan 4',
    value: 4,
  },
  {
    id: 5,
    name: 'Plan 5',
    value: 5,
  },
];

import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import Logger from '../../../../../utils/Logger';
import { moveTeachers } from '../../../../../services/AdminService';

export const useGetSelectedTeacherTable = ({
  teacherTableData,
}) => {
  const tableConfig = [
    {
      title: 'Username',
      align: 'left',
      dataField: 'userName',
      width: '36%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '32%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '32%',
    },
  ];

  const tableData = teacherTableData.map((t) => {
    const { attributes: { userName, firstName, lastName } } = t;
    return {
      userName,
      firstName,
      lastName,
    };
  });

  return {
    tableConfig,
    tableData,
  };
};

export const useMoveTeachers = ({
  setOpen,
  onMoveTeachersSuccess,
  selectedTeachers,
}) => {
  const {
    callRemoteServiceWrapper,
    loading,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const moveTeachersToNewSchool = async (data) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await moveTeachers(data.shouldMoveStudent, data.school.key, selectedTeachers.map((t) => t.id));
        await onMoveTeachersSuccess();
        setOpen(false);
        let message = '';
        if (selectedTeachers.length > 1) {
          message = `${selectedTeachers.length} teachers are moved to ${data.school.name}.`;
        } else {
          const teacherObject = selectedTeachers[0];
          const { attributes } = teacherObject;
          message = `Teacher ${attributes.firstName} ${attributes.lastName} is moved to ${data.school.name}.`;
        }
        await uiDomain.showSnackbar(true, 'success', 3000, message);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    moveTeachersToNewSchool,
    loading,
  };
};

export const validateMoveTeacherFormData = (data) => data && data.school && data.school.key !== null && data.school.key !== undefined && data.school.key !== '';

export const useHandleSearchDistrictSchoolSelector = ({
  onDataChange,
  data,
  setData,
}) => {
  const handleOnDistrictSchoolChange = async (selectedDistrictSchool) => {
    const updatedData = {
      ...data,
      school: selectedDistrictSchool.school,
    };
    setData(updatedData);
    onDataChange(updatedData, validateMoveTeacherFormData(updatedData));
  };

  return {
    handleOnDistrictSchoolChange,
  };
};

import { isCardEmpty } from '../../../../utils/activitytype/ActivityTypeEmptyChecker';
import { layoutDropdownValues } from './FlexibleMatchingConstants';

export default {
  getFlexibleMatchingLayoutClass: (layout, promptCards) => {
    const promptIndexes = [];
    const emptyPromptIndexes = [];
    const nonEmptyPromptCards = promptCards.filter((p, index) => {
      const isNotEmpty = !isCardEmpty(p);
      if (isNotEmpty) {
        promptIndexes.push(index);
      } else {
        emptyPromptIndexes.push(index);
      }
      return isNotEmpty;
    });
    if (nonEmptyPromptCards.length === 1) {
      return 'flexi-matching-one-card';
    } else if (nonEmptyPromptCards.length === 2 && layout === layoutDropdownValues[1].id) {
      const [first] = promptIndexes;
      if (first % 2 === 0) {
        return 'flexi-matching-two-cards-vertical';
      } else {
        return 'flexi-matching-two-cards-horizental';
      }
    } else if (nonEmptyPromptCards.length === 2 && layout === layoutDropdownValues[2].id) {
      const [first, second] = promptIndexes;
      if (first % 2 === 0 && second % 2 === 1) {
        return 'flexi-matching-two-cards-horizental';
      } else {
        return 'flexi-matching-two-cards-vertical';
      }
    } else if (nonEmptyPromptCards.length === 2 && layout === layoutDropdownValues[3].id) {
      const [first, second] = promptIndexes;
      if (first % 2 === 0) {
        if (second % 2 === 0) {
          return 'flexi-matching-two-cards-vertical';
        } else {
          return 'flexi-matching-two-cards-horizental';
        }
      } else if (second % 2 === 0) {
        return 'flexi-matching-two-cards-horizental-flipped';
      } else {
        return 'flexi-matching-two-cards-vertical';
      }
    } else if (nonEmptyPromptCards.length === 3 && layout === layoutDropdownValues[3].id) {
      const [first] = emptyPromptIndexes;
      if ([0, 1].includes(first)) {
        return 'flexi-matching-three-cards-1';
      } else {
        return 'flexi-matching-three-cards-2';
      }
    }

    return layoutDropdownValues.find((l) => l.id === layout).className;
  },
};

import PropTypes from 'prop-types';
import './PlayerPieces.scss';

const PlayerPieces = ({
  containerIndex,
  players,
  currentPlayer,
  iconHeight,
  pageIndex,
}) => (
  <div className='player-pieces' data-test='player-pieces'>
    {players.map((player, playerIndex) => (
      player.currentCardIndex === containerIndex ? (
        <img
          key={`player-pieces-${pageIndex}-${containerIndex}-${playerIndex}`}
          id={player.elementId}
          className={`piece-icon ${playerIndex !== currentPlayer ? 'inactive-player' : ''}`}
          src={player.playerPieceImage}
          alt='Piece'
          style={{ height: iconHeight }}
        />
      ) : ''

    ))}
  </div>
);

PlayerPieces.defaultProps = {
  currentPlayer: null,
};

PlayerPieces.propTypes = {
  containerIndex: PropTypes.number.isRequired,
  players: PropTypes.arrayOf(PropTypes.shape({
    playerPieceImage: PropTypes.string,
  })).isRequired,
  currentPlayer: PropTypes.number,
  iconHeight: PropTypes.string.isRequired,
  pageIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PlayerPieces;

import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from '@mui/material';
import Draggable from 'react-draggable';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useDomain } from '../../../../../states/AppDomainProvider';
import './ReviewerNotes.scss';

function PaperComponent(props) {
  return (
    <Draggable handle='#draggable-reviewer-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ReviewerNotes = () => {
  const { userDomain, lessonDataMaintenanceDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [text, setText] = useState();
  const handleOnOpen = () => {
    setDialogOpen(true);
  };

  const handleOnOk = async () => {
    lessonData.attributes.reviewerNotes = text;
    await lessonDataMaintenanceDomain.updateLessonData(lessonData, false);
    setDialogOpen(false);
  };

  const handleOnTextChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    if (dialogOpen) {
      setText(lessonData.attributes.reviewerNotes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  const width = window.innerWidth <= 1024 ? '100px' : '130px';
  return (
    user.userReviewer && (
      <>
        <Button
          variant='contained'
          startIcon={<RateReviewIcon />}
          className='btn-secondary'
          onClick={handleOnOpen}
          style={{ width }}
        >
          Notes
        </Button>
        <Dialog
          open={dialogOpen}
          className='reviewer-notes-dialog'
          aria-labelledby='form-dialog-title'
          PaperComponent={PaperComponent}
          maxWidth='xl'
        >
          <DialogTitle className='title' id='draggable-reviewer-title'>Notes</DialogTitle>
          <DialogContent className='content'>
            <TextField
              variant='outlined'
              multiline
              minRows={10}
              maxRows={10}
              fullWidth
              value={text}
              onChange={handleOnTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleOnOk}
              className='btn-primary'
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default ReviewerNotes;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Typography,
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useDebounce } from '../../../../utils/ReactUtil';
import FilterableMultiSelector from '../../../selector/FilterableMultiSelector';
import './LessonFilter.scss';

const LessonFilter = ({
  onFilterChange,
  filterValue,
  multiSelectFilterConfig,
  filterTitle,
  withArchiveFilter,
}) => {
  const [state, setState] = useState(filterValue);
  const [expanded, setExpanded] = useState(false);
  const [keyword, setKeyword] = useState(filterValue.keyword);

  useEffect(() => {
    const hasValueIndex = Object.values(filterValue).findIndex((el) => {
      if (typeof el === 'string') {
        return el.trim().length > 0;
      } else if (typeof el === 'boolean') {
        return el;
      } else if (Array.isArray(el)) {
        return el && el.length > 0;
      }
      return el;
    });

    setExpanded(hasValueIndex >= 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateAndCallOnchange = (newState) => {
    setState(newState);
    onFilterChange(newState);
  };

  const handleCheckboxChange = (event) => {
    setStateAndCallOnchange({ ...state, [event.target.name]: event.target.checked });
  };

  const handleTextChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleMultiSelectorApply = (keyName, value) => {
    let valueToSet = value;
    if (value && !Array.isArray(value)) {
      valueToSet = [value];
    }
    setStateAndCallOnchange({ ...state, [keyName]: valueToSet });
  };

  const handleClearKeywordClick = () => {
    setKeyword('');
    setStateAndCallOnchange({ ...state, keyword: '' });
  };

  const handleOnClearFilterClick = () => {
    setKeyword('');
    setStateAndCallOnchange({
      archived: false,
      keyword: '',
    });
  };

  const handleOnExpandChange = (event, value) => {
    setExpanded(value);
  };

  const debouncedKeywordValue = useDebounce(keyword, 600);
  useEffect(() => {
    setStateAndCallOnchange({ ...state, keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywordValue]);

  return (
    <div className='lesson-filter' data-test='lesson-filter'>
      <Accordion className='filter-panel' onChange={handleOnExpandChange} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='lesson-filter-panel'
          id='lesson-filter-panel'
          className='summary-panel'
          data-test='lesson-filter-panel'
        >
          <Typography>{filterTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails className='content'>
          {multiSelectFilterConfig ? (
            multiSelectFilterConfig.map((config, index) => {
              const { keyName, getValue, ...rest } = config;
              const initFilterValue = state[keyName];
              const initFilterKeyValues = initFilterValue ? initFilterValue.map((v) => v.key) : [];

              return (
                <FilterableMultiSelector
                  key={`filter-selector-${config.label}-${index}`}
                  onApply={(value) => { handleMultiSelectorApply(keyName, value); }}
                  initFilterKeyValues={initFilterKeyValues}
                  {...rest}
                />
              );
            })
          ) : null}

          {withArchiveFilter && (
            <FormControlLabel
              control={
                (
                  <Checkbox
                    checked={state && state.archived ? state.archived : false}
                    onChange={handleCheckboxChange}
                    name='archived'
                    color='primary'
                  />
                )
              }
              label='Archived'
              data-test='archive-checkbox'
            />
          )}
          <div className='keyword-input'>
            <FilterListIcon />
            <Input
              type='text'
              placeholder='Filter by keyword'
              id='lesson-filter-by-keyword'
              onChange={handleTextChange}
              name='keyword'
              value={keyword || ''}
              className='input-field'
              endAdornment={
                state && state.keyword ? (
                  <InputAdornment position='end' className='clear-keyword-button'>
                    <IconButton
                      aria-label='Clear keyword'
                      onClick={handleClearKeywordClick}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              data-test='keyword-filter'
            />

          </div>

          <Button
            className='clear-filter-button'
            onClick={handleOnClearFilterClick}
            data-test='clear-filters-action'
          >
            Clear Filters
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

LessonFilter.defaultProps = {
  onFilterChange: () => { },
  filterValue: {
    archived: false,
    keyword: '',
  },
  multiSelectFilterConfig: [],
  filterTitle: 'Filters',
  withArchiveFilter: true,
};

LessonFilter.propTypes = {
  onFilterChange: PropTypes.func,
  filterValue: PropTypes.shape({
    archived: PropTypes.bool,
    keyword: PropTypes.string,
  }),
  multiSelectFilterConfig: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    subTitle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.any,
      name: PropTypes.any,
      value: PropTypes.any,
    })),
    className: PropTypes.string,
    keyName: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    filterable: PropTypes.bool,
  })),
  filterTitle: PropTypes.string,
  withArchiveFilter: PropTypes.bool,
};

export default LessonFilter;

/* Subject ID */
export const SUBJECT_ID_ELA = 4;
export const SUBJECT_ID_MATH = 1;
export const SUBJECT_ID_SCIENCE = 3;
export const SUBJECT_ID_SOCIAL_STUDY = 2;
export const SUBJECT_ID_SPEECH_THERAPY = 5;
export const SUBJECT_ID_LIFE_SKILL = 13;
export const SUBJECT_ID_LIFE_ART_MUSIC = 7;
export const SUBJECT_ID_MISC = -1;
export const SUBJECT_ID_READING = 41;
export const SUBJECT_ID_WRITING = 42;

export const SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS = 8;
export const SUBJECT_ID_SOCIAL_SKILLS = 9;

/** Subject constants */
export const SUBJECT_CONSTANTS = {
  english: { tag: 'english', name: 'English Language Arts', shortName: 'ELA', subjectId: SUBJECT_ID_ELA },
  arts: { tag: 'arts', name: 'Arts & Music', shortName: 'Arts & Music', subjectId: SUBJECT_ID_LIFE_ART_MUSIC },
  social: { tag: 'social', name: 'Social Studies', shortName: 'Social Studies', subjectId: SUBJECT_ID_SOCIAL_STUDY },
  speech: { tag: 'speech', name: 'Speech Therapy', shortName: 'Speech Therapy', subjectId: SUBJECT_ID_SPEECH_THERAPY },
  science: { tag: 'science', name: 'Science', shortName: 'Science', subjectId: SUBJECT_ID_SCIENCE },
  life: { tag: 'life', name: 'Life Skills', shortName: 'Life Skills', subjectId: SUBJECT_ID_LIFE_SKILL },
  math: { tag: 'math', name: 'Math', shortName: 'Math', subjectId: SUBJECT_ID_MATH },
  misc: { tag: 'misc', name: 'Misc.', shortName: 'Misc.', subjectId: SUBJECT_ID_MISC },
};

export const NON_ACADEMIC_SUBJECTS = {
  transitionalSkills: { tag: 'transition', name: 'Transition/Vocational Skills', shortName: 'Transition/Vocational Skills', subjectId: SUBJECT_ID_TRANSITIONAL_VOCATIONAL_SKILLS },
  socialSkills: { tag: 'social-skills', name: 'Social Skills', shortName: 'Social Skills', subjectId: SUBJECT_ID_SOCIAL_SKILLS },
};

export const READING_SUBJECT = { tag: 'reading', name: 'Reading', subjectId: SUBJECT_ID_READING };
export const WRITING_SUBJECT = { tag: 'writing', name: 'Writing', subjectId: SUBJECT_ID_WRITING };

export const SORTED_SUBJECT_CONSTANTS = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
  SUBJECT_CONSTANTS.life,
  SUBJECT_CONSTANTS.misc,
];

export const FULL_SORTED_SUBJECT_CONSTANTS = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
  SUBJECT_CONSTANTS.life,
  NON_ACADEMIC_SUBJECTS.socialSkills,
  NON_ACADEMIC_SUBJECTS.transitionalSkills,
  SUBJECT_CONSTANTS.misc,
];

export const FULL_SORTED_SUBJECT_CONSTANTS_WITHOUT_MISC = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
  SUBJECT_CONSTANTS.life,
  NON_ACADEMIC_SUBJECTS.socialSkills,
  NON_ACADEMIC_SUBJECTS.transitionalSkills,
];

/** Lesson content type */
export const FULL_LIBRARY = { key: '999', name: 'Full Library', value: '999' };
export const STAAR_ALTERNATE = { key: 'STAAR_ALTERNATE', name: 'STAAR Alternate 2', value: 'STAAR_ALTERNATE' };
export const CCSS = { key: 'CCSS', name: 'Common Core', value: 'CCSS', scope: 'ccss' };
export const LACO = { key: 'LACO', name: 'Louisiana Connectors', value: 'LACO', scope: 'LACO' };
export const FL_AP = { key: 'FL_AP', name: 'Florida Access Points', value: 'FL_AP', scope: 'FL_AP' };

export const SUBJECT_CONTENT_TYPE = [
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.speech,
  SUBJECT_CONSTANTS.arts,
  SUBJECT_CONSTANTS.life,
];

// Lesson Types
export const LESSON_TYPE_NONE_ASSESSMENT = 'NONE';
export const LESSON_TYPE_INSTRUCTIONAL = 'INSTRUCTIONAL';
export const TOPIC_PRE_ASSESSMENT = 'TOPIC_PRE_ASSESSMENT';
export const TOPIC_POST_ASSESSMENT = 'TOPIC_POST_ASSESSMENT';
export const UNIT_SUMMATIVE_ASSESSMENT = 'UNIT_SUMMATIVE_ASSESSMENT';

export const LESSON_TYPE_MAPPING = {
  [TOPIC_PRE_ASSESSMENT]: 'Topic Pre Assessment',
  [TOPIC_POST_ASSESSMENT]: 'Topic Post Assessment',
  [UNIT_SUMMATIVE_ASSESSMENT]: 'Unit Summative Assessment',
};

import PropTypes from 'prop-types';
import {
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import './SelectorForm.scss';

const SelectorForm = ({
  label,
  options,
  tooltip,
  loading,
  value,
  handleOnChange,
  handleOnClick,
  dataTest,
}) => (
  <div className='selector-form' data-test={`selector-form-${dataTest}`}>
    <div className='label-container' data-test={`label-container-${dataTest}`}>
      <Typography variant='subtitle1' className='label' data-test={`label-${dataTest}`}>
        {label}
      </Typography>
      {tooltip ? (
        <TooltipWithIndicator className='tooltip' {...tooltip} />
      ) : null}
    </div>
    <Select
      value={value === null || value === undefined ? '' : value}
      variant='outlined'
      fullWidth
      onChange={handleOnChange}
      data-test={`selector-${dataTest}`}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value} data-test={`selector-value-${dataTest}-${option.key}`}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
    <ButtonWithSpinner
      loading={loading}
      className='apply-button'
      label='Apply'
      disabled={value === null || value === undefined}
      onClick={handleOnClick}
      data-test={`apply-action-${dataTest}`}
    />
  </div>
);

SelectorForm.defaultProps = {
  options: [],
  tooltip: null,
  loading: false,
  value: null,
  handleOnChange: () => { },
  handleOnClick: () => { },
  dataTest: '',
};

SelectorForm.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    name: PropTypes.string,
  })),
  tooltip: PropTypes.shape({
    title: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  handleOnChange: PropTypes.func,
  handleOnClick: PropTypes.func,
  dataTest: PropTypes.string,
};

export default SelectorForm;

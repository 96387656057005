import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import {
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  Assignment as AssignmentIcon,
} from '@mui/icons-material';
import DateUtils from '../../../../../../utils/DateUtils';
import { dateFormatyyyyMMdd } from '../../../../../../AppConstants';
import './AssignedStudentCoursesCard.scss';
import { ASSIGNMNET_STATUS } from '../AssignmentOverviewConstant';

const getImageFromAssignmentStatus = (assignmentStatus) => {
  if (assignmentStatus === ASSIGNMNET_STATUS.noAssignment) {
    return (
      <AssignmentIcon className='icon no-assignment' />
    );
  } else if (assignmentStatus === ASSIGNMNET_STATUS.someStudentsAssigned) {
    return (
      <SentimentSatisfiedIcon className='icon some-assigned' />
    );
  }
  return (
    <SentimentVerySatisfiedIcon className='icon all-assigned' />
  );
};

const getDescriptionFromAssignmentStatus = (assignmentStatus) => {
  if (assignmentStatus === ASSIGNMNET_STATUS.noAssignment) {
    return 'No students have assignments.';
  } else if (assignmentStatus === ASSIGNMNET_STATUS.someStudentsAssigned) {
    return 'Some students have assignments.';
  }
  return 'All students have assignments.';
};

const AssignedStudentCoursesCard = ({
  data,
  onViewClick,
}) => {
  const {
    date,
    assignmentStatus,
  } = data;
  return (
    <Card className='assigned-student-courses-card' data-test={`assigned-student-courses-date-card-${date}`}>
      <CardContent>
        <div className='title'>
          <Typography className='day' gutterBottom>
            {DateUtils.convertDateString(date, dateFormatyyyyMMdd, 'EEE')}
          </Typography>
          <Typography gutterBottom>
            - {DateUtils.convertDateString(date, dateFormatyyyyMMdd, 'LLL d')}
          </Typography>
        </div>
        <div className='image-container'>
          {getImageFromAssignmentStatus(assignmentStatus)}
        </div>
        <div className='description'>
          {getDescriptionFromAssignmentStatus(assignmentStatus)}
        </div>
      </CardContent>
      <CardActions className='card-actions'>
        {assignmentStatus !== ASSIGNMNET_STATUS.noAssignment && (
          <Button size='small' onClick={onViewClick} variant='contained' className='btn-primary' data-test='view-card-action'>View</Button>
        )}
      </CardActions>
    </Card>
  );
};

AssignedStudentCoursesCard.defaultProps = {
  onViewClick: () => null,
};

AssignedStudentCoursesCard.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    assignmentStatus: PropTypes.number,
    assignedStudentCourses: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onViewClick: PropTypes.func,
};

export default AssignedStudentCoursesCard;

import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../states/AppDomainProvider';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import Logger from '../../../../utils/Logger';
import { addToGroupPlay } from '../../../../services/GroupPlayService';

export default function LessonsAddToGroupPlay({
  lessons,
  selectedLessonIds,
  onSaveSuccess,
}) {
  const { loading: saving, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  const selectedLessons = [];
  lessons.forEach((l) => {
    if (selectedLessonIds.includes(l.id)) {
      selectedLessons.push(l);
    }
  });

  const handleOnClick = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await addToGroupPlay(user.id, selectedLessonIds);
        selectedLessons.forEach((s) => {
          let { meta } = s;
          if (!meta) {
            meta = {};
          }
          meta.groupPlay = true;
          // eslint-disable-next-line no-param-reassign
          s.meta = meta;
        });
        onSaveSuccess(lessons);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `The ${selectedLessonIds.length === 1 ? 'activity is' : 'activities are'} added to the Group Instruction succesfully.`);
    } catch (e) {
      Logger.logError(e);
    }
  };

  return (
    <ButtonWithSpinner
      variant='contained'
      className='btn-primary'
      disabled={!selectedLessons || selectedLessons.length === 0}
      onClick={handleOnClick}
      startIcon={<AddCircleIcon />}
      data-test='lessons-add-to-group-play-action'
      label='Add to Group Instruction'
      loading={saving}
    />
  );
}

LessonsAddToGroupPlay.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  onSaveSuccess: () => { },
};

LessonsAddToGroupPlay.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      meta: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  onSaveSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  Button,
} from '@mui/material';

export default function SelectAllSettings({ attributeName, defaultValue, profile, terminologies, onChangeCallback }) {
  const selectedData = profile[attributeName];
  const terminologiesData = terminologies[attributeName];

  const isSelectedAll = selectedData.length === terminologiesData.length;

  const handleSelectAll = () => {
    const allData = terminologiesData.map((t) => t.code);
    // eslint-disable-next-line no-param-reassign
    profile[attributeName] = allData;
    onChangeCallback(allData);
  };

  const handleSelectDefault = () => {
    // eslint-disable-next-line no-param-reassign
    profile[attributeName] = defaultValue;
    onChangeCallback(defaultValue);
  };

  return (
    <Stack direction='row' gap={3} alignItems='center'>
      <Typography>{selectedData.length} {attributeName} selected</Typography>
      {isSelectedAll ? (
        <Button variant='text' className='btn-text-primary' onClick={handleSelectDefault}>Reset To Default</Button>
      ) : (
        <Button variant='text' className='btn-text-primary' onClick={handleSelectAll}>
          Select All
        </Button>
      )}

    </Stack>
  );
}

SelectAllSettings.propTypes = {
  attributeName: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

import { Typography } from '@mui/material';
import { useState } from 'react';
import FontFamilyForm from './forms/FontFamilyForm';
import FontSizeForm from './forms/FontSizeForm';
import FontFormatForm from './forms/FontFormatForm';
import FontColorForm from './forms/FontColorForm';
import TextAlignmentForm from './forms/TextAlignmentForm';
import DropdownInput from '../../../../../../../../../components/selector/DropdownInput';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
} from '../../../../../../../../../AppConstants';
import './FormContainer.scss';
import { useDomain } from '../../../../../../../../../states/AppDomainProvider';
import { SELECT_ALL_ACTIVITIES_OPTIONS } from '../ApplyTextStyleActionHooks';

export default function FormContainer() {
  const [applyToActivities, setApplyToActivities] = useState('0');

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const activitiesOptions = [
    {
      id: SELECT_ALL_ACTIVITIES_OPTIONS,
      name: 'All',
    },
    {
      id: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
      name: 'Build A Book (Single)',
    },
    {
      id: ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
      name: 'Build A Book (Facing)',
    },
    {
      id: ACTIVITY_TYPE_MATCHING_ACTIVITY,
      name: 'Matching',
    },
    {
      id: ACTIVITY_TYPE_SORTING_ACTIVITY,
      name: 'Sorting',
    },
    {
      id: ACTIVITY_TYPE_GAME_ACTIVITY,
      name: 'Game',
    },
    {
      id: ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
      name: 'Sequencing',
    },
    {
      id: ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
      name: 'Categorizing',
    },
    {
      id: ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
      name: 'Advanced Matching',
    },
  ];

  if (user.features && user.features.includes('CauseAndEffect')) {
    activitiesOptions.push({
      label: 'Cause and Effect',
      dataValue: ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
    });
  }

  return (
    <div className='apply-styles-form-container'>
      <div className='apply-to-container'>
        <Typography variant='subtitle1' className='label'>
          Apply to
        </Typography>
        <DropdownInput
          dropdownValues={activitiesOptions}
          name='apply-to-activity'
          value={applyToActivities}
          className='text-selector-dropdown'
          onChange={async (e) => {
            setApplyToActivities(e.target.value);
          }}
          data-test='apply-to-activity'
        />
      </div>

      <FontFamilyForm applyToActivities={applyToActivities} />
      <FontSizeForm applyToActivities={applyToActivities} />
      <FontFormatForm applyToActivities={applyToActivities} />
      <FontColorForm applyToActivities={applyToActivities} />
      <TextAlignmentForm applyToActivities={applyToActivities} />
    </div>
  );
}

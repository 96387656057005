import { useEffect, useState, useRef } from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDomain } from '../../states/AppDomainProvider';
import { NON_ACADEMIC_SUBJECTS, SUBJECT_CONSTANTS } from '../../constants/LessonConstant';
import ObjectUtils from '../../utils/ObjectUtils';
import { URL_LANDING_STUDENT } from '../../routes/RouteConstants';
import { useUpdateElementsToScan, useRegisterEvent } from '../switch-access/SwitchAccessHooks';
import { useScreenReader } from '../screen-reader/ScreenReaderHooks';
import { useLessonPlay } from '../lessonplay/context/LessonPlayContextProvider';
import { hideAccessibeChatIcon } from '../../services/AccessibeService';

const subjectList = Object.values({ ...SUBJECT_CONSTANTS, ...NON_ACADEMIC_SUBJECTS });

export const usePrepareUserProfile = () => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { lessonPlayDomain } = useLessonPlay();
  useEffect(() => {
    lessonPlayDomain.updateUserProfile(userProfile);
    hideAccessibeChatIcon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useCheckLessonComplete = () => {
  const [showCompleteMessage, setShowCompleteMessage] = useState(false);
  const navigate = useNavigate();
  const { lessonTag } = useParams();
  const { userDomain } = useDomain();

  const { userProfile } = userDomain.domainData;
  const { subjects } = userProfile;
  const selectedSubject = subjectList.find((s) => s.tag === lessonTag);
  const { name } = selectedSubject;
  const subject = subjects.find((s) => s.id === name) || subjects.find((s) => s.id.toLowerCase() === SUBJECT_CONSTANTS.misc.tag);

  useEffect(() => {
    const { completed } = subject;
    if (completed) {
      setShowCompleteMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteMessageDialogClose = async () => {
    subject.startIndex = '0';
    subject.completed = false;
    await userDomain.updateUserProfile(userProfile);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('exit-player-to-landing');
    }
    navigate(URL_LANDING_STUDENT);
  };

  return {
    showCompleteMessage,
    handleCompleteMessageDialogClose,
  };
};

export const useGetLessonSelectionData = () => {
  const { lessonTag } = useParams();

  const { userDomain } = useDomain();
  const { userProfile, terminologies } = userDomain.domainData;
  const { playerWorld, subjects: subjectFromProfile } = userProfile;
  const themeData = terminologies.themes[playerWorld];

  const selectedSubject = subjectList.find((s) => s.tag === lessonTag);

  const { subjectId, name } = selectedSubject;

  const subject = subjectFromProfile.find((s) => s.id === name) || subjectFromProfile.find((s) => s.id.toLowerCase() === SUBJECT_CONSTANTS.misc.tag);
  const { lessons, startIndex, id } = subject;

  return {
    themeData,
    subjectId,
    lessons,
    startIndex,
    subjectNameId: id,
  };
};

export const useHandleTheme = (lesson) => {
  const navigate = useNavigate();

  const [themeTransition, setThemeTransition] = useState({
    in: true,
    direction: 'left',
  });

  const onThemeClick = async () => {
    setThemeTransition({
      in: false,
      direction: 'right',
    });
    await ObjectUtils.delay(700);
    navigate(`/lesson/${lesson.uuid}`);
  };

  const gobackFunction = () => {
    navigate(-1);
  };

  return {
    themeTransition,
    onThemeClick,
    gobackFunction,
  };
};

export const useGetElementsForSwitchAccess = ({
  onThemeClick,
  showCompleteMessage,
}) => {
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const { switchAccessibility } = userProfile;

  const {
    screenReaderOn,
    readTextWhenScreenReaderIsOn,
  } = useScreenReader();

  const {
    updateElementsToScan,
  } = useUpdateElementsToScan();

  const topAppbarRef = useRef(null);
  const lessonCompleteButtonRef = useRef(null);
  const unregisterSelectListerner = useRef(null);
  const unregisterScanListerner = useRef(null);

  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  useEffect(() => () => {
    if (unregisterSelectListerner.current) {
      unregisterSelectListerner.current();
    }

    if (unregisterScanListerner.current) {
      unregisterScanListerner.current();
    }
  }, []);

  const getElementsForSwitchAccess = async () => {
    if (showCompleteMessage) {
      ObjectUtils.delay(100);
      updateElementsToScan({
        topBar: null,
        body: [lessonCompleteButtonRef.current],
        bottomBar: null,
      });
      return;
    }
    let body = [];
    if (document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas')[0]) {
      const themeCanvas = document.getElementsByTagName('canvas')[0];
      themeCanvas.dataset.skipSwitchAccessClick = true;
      themeCanvas.dataset.switchAccessScan = true;
      unregisterSelectListerner.current = registerSwitchEvent(
        'switchAccessSelect',
        { current: themeCanvas },
        () => {
          onThemeClick();
        },
      );

      if (screenReaderOn) {
        unregisterScanListerner.current = registerSwitchEvent(
          'switchAccessScan',
          { current: themeCanvas },
          () => {
            readTextWhenScreenReaderIsOn('Launch Lesson');
          },
        );
      }
      body = [themeCanvas];
    } else {
      const el = document.querySelector('[data-switch-access-element=\'true\']');
      el.dataset.switchAccessScan = true;
      unregisterScanListerner.current = registerSwitchEvent(
        'switchAccessScan',
        { current: el },
        () => {
          readTextWhenScreenReaderIsOn('Launch Lesson');
        },
      );
      body = [el] || [];
    }

    updateElementsToScan({
      topBar: topAppbarRef.current.getEnableElements(),
      body,
      bottomBar: null,
    });
  };

  return {
    topAppbarRef,
    lessonCompleteButtonRef,
    getElementsForSwitchAccess: switchAccessibility ? getElementsForSwitchAccess : () => { },
  };
};

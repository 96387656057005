import PropTypes from 'prop-types';
import { useHandleDistrictFilter, useHandleSchoolFilter } from './AdminDistrictSchoolSelectorHook';
import SearchDistrictAction from '../components/actions/search-district/SearchDistrictAction';
import SearchSchoolAction from '../components/actions/search-school/SearchSchoolAction';
import './AdminDistrictSchoolSelector.scss';

const AdminDistrictSchoolSelector = ({
  onDataLoadSuccess,
}) => {
  const {
    selectedDistrictId,
    handleOnDistrictChange,
  } = useHandleDistrictFilter();

  const {
    schoolsData,
    handleOnSchoolsLoaded,
    selectedSchoolId,
    handleOnFilterSchoolChange,
  } = useHandleSchoolFilter({
    onDataLoadSuccess,
  });

  return (
    <div className='admin-district-school-selector'>
      <SearchDistrictAction
        className='district-school-selector-dropdown'
        onFilterChange={handleOnDistrictChange}
        filterValue={selectedDistrictId}
        isMultiple={false}
        ableToUnselectedInSingleMode
      />
      <SearchSchoolAction
        className='district-school-selector-dropdown margin-top'
        districtId={selectedDistrictId}
        schoolsData={schoolsData}
        onSchoolsLoaded={handleOnSchoolsLoaded}
        isMultiple={false}
        filterValue={schoolsData && schoolsData.find((s) => s.id === selectedSchoolId) ? selectedSchoolId : null}
        onFilterChange={handleOnFilterSchoolChange}
      />
    </div>
  );
};

AdminDistrictSchoolSelector.defaultProps = {
  onDataLoadSuccess: () => null,
};

AdminDistrictSchoolSelector.propTypes = {
  onDataLoadSuccess: PropTypes.func,
};

export default AdminDistrictSchoolSelector;

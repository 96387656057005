import { useEffect } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { sortLessonAttributes } from '../../../../../utils/LessonsMapping';
import { useAdmin } from '../../../context/AdminContextProvider';

export const usePrepareTeacherList = ({ getTeacherListData, getActivityReportsData }) => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const {
    userSuperAdmin,
  } = user;

  useEffect(() => {
    if (!userSuperAdmin) {
      getTeacherListData();
      getActivityReportsData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useHandleActivitySelector = ({ getTeacherListData, getActivityReportsData }) => {
  const { adminDataDomain } = useAdmin();
  const { searchCriteria, selectedTeacherId } = adminDataDomain.domainData;

  const handleOnChange = async (e) => {
    const updatedSearchCriteria = {
      ...searchCriteria,
      [e.target.name]: e.target.checked,
    };
    await adminDataDomain.updateSearchCriteria(updatedSearchCriteria);
    getTeacherListData();
    getActivityReportsData();
  };

  const shouldAcivitySelectorDisabled = selectedTeacherId !== null;

  return {
    searchCriteria,
    handleOnChange,
    shouldAcivitySelectorDisabled,
  };
};

export const useTeacherListTable = () => {
  const tableConfig = [
    {
      align: 'left',
      dataField: 'name',
      width: '100%',
      dataRenderer: (data) => {
        if (data && data.meta && !data.meta.hasActiveLicense) {
          return (<div className='not-active-license'>{data.name}</div>);
        }
        return data.name;
      },
    },
  ];

  const { adminDataDomain } = useAdmin();
  const { teacherList } = adminDataDomain.domainData;

  const tableData = sortLessonAttributes(teacherList || [], 'name').map((t) => ({
    ...t,
    name: `${t.attributes.firstName} ${t.attributes.lastName}`,
  }));

  return {
    tableConfig,
    tableData,
  };
};

export const SELECTED_ALL_ID = -1;

export const useHandleTeacherSelectorTable = ({ getActivityReportsData }) => {
  const { adminDataDomain } = useAdmin();
  const { selectedTeacherId } = adminDataDomain.domainData;

  const handleOnSelectedRowIds = async (selectedRowIds) => {
    if (!selectedRowIds || selectedRowIds.length === 0) {
      return;
    }

    const selectedTeacherIdFromTable = selectedRowIds[selectedRowIds.length - 1];

    if (selectedTeacherIdFromTable === selectedTeacherId) {
      return;
    }

    await adminDataDomain.updatSelectedTeacherId(selectedTeacherIdFromTable === SELECTED_ALL_ID ? null : selectedTeacherIdFromTable);
    getActivityReportsData();
  };

  return {
    selectedRowIds: [selectedTeacherId === null ? SELECTED_ALL_ID : selectedTeacherId],
    handleOnSelectedRowIds,
  };
};

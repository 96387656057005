import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import './InteractionContainer.scss';

const InteractionContainer = ({ components, className }) => (
  <Box display='flex' flexDirection='row' flexWrap='wrap' className={`setting-section-container ${className}`} data-test='interaction-section'>
    {components.map((entry, index) => (
      <Box
        className={`${entry.className}`}
        p={1}
        key={`setting-section-container-entry-${index}`}
        data-test={`interaction-${entry.title.toLowerCase().replace(/ /g, '-')}`}
      >
        <Box display='flex' mr='15px' alignItems='center'>
          <Typography variant='subtitle1' className='setting-section-container-title nested'>
            {entry.title}
          </Typography>
          {entry.tooltip}
        </Box>
        {entry.component}
      </Box>
    ))}
  </Box>
);

InteractionContainer.defaultProps = {
  className: '',
};

InteractionContainer.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default InteractionContainer;

import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import './UpdateStudentPanel.scss';
import UpdateStudentForm from './UpdateStudentForm';
import { getDataFromIncluded } from '../../../../../hooks/AdminUtilityHooks';
import TeacherTableSelector from './TeacherTableSelector';
import { unique } from '../../../../../../../utils/ArrayUtils';

const UpdateStudentPanel = ({
  loading,
  studentFormData,
  onStudentFormDataChange,
  studentDataObject,
}) => {
  const teacherListFromStudentDataObject = getDataFromIncluded(
    studentDataObject && studentDataObject.data.relationships
      ? studentDataObject.data.relationships.teachers.data
      : [], studentDataObject.included || [],
  );

  const studentTeacherSelectorTableData = teacherListFromStudentDataObject.map((st) => ({
    id: st.id,
    username: st.attributes.userName,
    firstName: st.attributes.firstName,
    lastName: st.attributes.lastName,
  }));
  return (
    <div className='update-admin-student-panel'>
      {
        loading ? (
          <div className='loading-student-data'>
            <CircularProgress className='loading-icon' />
            Loading
          </div>
        ) : (
          <>
            <UpdateStudentForm
              studentFormData={studentFormData}
              onDataChange={onStudentFormDataChange}
            />
            <TeacherTableSelector
              studentTeacherSelectorTableData={unique(studentTeacherSelectorTableData, 'id')}
            />
          </>
        )
      }
    </div>
  );
};

UpdateStudentPanel.defaultProps = {
  loading: false,
  studentFormData: {},
  onStudentFormDataChange: () => null,
  studentDataObject: {
    data: {
      relationships: {
        teachers: [],
      },
    },
  },
};

UpdateStudentPanel.propTypes = {
  loading: PropTypes.bool,
  studentFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    externalId: PropTypes.string,
    schoologyId: PropTypes.string,
    username: PropTypes.string,
    schoolId: PropTypes.number,
  }),
  onStudentFormDataChange: PropTypes.func,
  studentDataObject: PropTypes.shape({
    data: PropTypes.shape({
      relationships: PropTypes.shape({
        teachers: PropTypes.shape({
          data: PropTypes.array,
        }),
      }),
    }),
    included: PropTypes.array,
  }),
};

export default UpdateStudentPanel;

import {
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import DataNotFoundIcon from '@mui/icons-material/SentimentDissatisfied';
import './IconWithMessagePaper.scss';

/**
 * Paper card for display No lesson available.
 */
export default function IconWithMessagePaper({ Icon, message }) {
  return (
    <div className='no-data-found-paper'>
      <Icon className='no-data-found-icon' />
      <Typography component='p' className='no-data-found-message'>
        { message }
      </Typography>
    </div>
  );
}

IconWithMessagePaper.defaultProps = {
  Icon: DataNotFoundIcon,
};

IconWithMessagePaper.propTypes = {
  // eslint-disable-next-line
  Icon: PropTypes.object,
  message: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import FilterableMultiSelector from '../../../../../../components/selector/FilterableMultiSelector';
import { usePrepareData } from './SearchLicenseUserActionHooks';
import './SearchLicenseUserAction.scss';

const SearchLicenseUserAction = ({ onFilterChange, filterValue, isMultiple, className, popperClassName, disabled }) => {
  const { transformUser, loading } = usePrepareData();

  const handleMultiSelectorApply = (value) => {
    if (value && filterValue && value.key === filterValue) {
      return;
    }
    onFilterChange(value);
  };

  return (
    <FilterableMultiSelector
      popperClassName={popperClassName}
      className={`search-user-action ${className}`}
      key='filter-selector-user'
      onApply={(value) => {
        handleMultiSelectorApply(value);
      }}
      label='Please select user'
      initFilterKeyValues={Array.isArray(filterValue) ? filterValue : filterValue && [filterValue]}
      options={transformUser}
      filterable
      isMultiple={isMultiple}
      subTitle='Filter User'
      disabled={disabled || transformUser.length <= 1}
      loading={loading}
    />
  );
};

SearchLicenseUserAction.defaultProps = {
  onFilterChange: () => { },
  filterValue: null,
  isMultiple: false,
  className: '',
  popperClassName: '',
  disabled: false,
};

SearchLicenseUserAction.propTypes = {
  onFilterChange: PropTypes.func,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isMultiple: PropTypes.bool,
  className: PropTypes.string,
  popperClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchLicenseUserAction;

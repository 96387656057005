import { Button, TextField } from '@mui/material';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import {
  useControlCreateUpdateDialog,
  useAddExternalLink,
} from './ExternalLinkHooks';
import './CreateExternalLink.scss';

const CreateExternalLink = () => {
  const {
    openCreateUpdateDialog,
    openDialog,
    closeDialog,
  } = useControlCreateUpdateDialog();

  const {
    externalLink,
    onExternalLinkChange,
    onExternalLinkLabelChange,
    addExternalLink,
    saving,
  } = useAddExternalLink(openCreateUpdateDialog, closeDialog);

  return (
    <>
      <div className='create-external-link'>
        <Button
          className='btn-primary create-button'
          autoFocus
          onClick={openDialog}
          data-test='create-external-link-action'
        >
          Create External Link
        </Button>
      </div>
      {openCreateUpdateDialog ? (
        <CustomDialog
          className='create-external-link-dialog'
          openDialog={openCreateUpdateDialog}
          onClose={closeDialog}
          title='Create an External Link'
          content={(
            <div className='dialog-content'>
              <TextField
                onChange={onExternalLinkLabelChange}
                label='Label'
                fullWidth
                value={externalLink.label}
                inputProps={{
                  maxLength: 255,
                }}
                data-test='create-external-link-label-text-field'
                variant='standard'
              />
              <br /><br />
              <TextField
                onChange={onExternalLinkChange}
                label='External Link'
                fullWidth
                value={externalLink.url}
                error={externalLink.error.isError}
                helperText={externalLink.error.helperText}
                inputProps={{
                  maxLength: 255,
                }}
                data-test='create-external-link-text-field'
                variant='standard'
              />
            </div>
          )}
          displayCloseIcon
          actions={[
            {
              title: 'Save',
              onClick: addExternalLink,
              color: 'primary',
              className: 'btn-primary',
              disabled: externalLink.url === '' || externalLink.error.isError,
              loading: saving,
            },
            {
              title: 'Close',
              onClick: closeDialog,
              color: 'primary',
              className: 'btn-gray-background',
              disabled: saving,
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default CreateExternalLink;

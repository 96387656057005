import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import './UserVerifyForm.scss';

const MIN_PASSWORD_LENGTH = 4;
const validateLength = (text) => text && text.length >= MIN_PASSWORD_LENGTH;
const validatePassword = (password, confirmPassword) => {
  const isLenghValid = validateLength(password) && validateLength(confirmPassword);
  if (!isLenghValid) {
    return false;
  }

  return password === confirmPassword;
};

// eslint-disable-next-line no-unused-vars
const UserVerifyForm = ({ onSubmit, loading }) => {
  const [formValue, setFormValue] = useState({
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleOnTextChange = (e) => {
    const { name, value } = e.target;

    const updatedFormValue = {
      ...formValue,
      [name]: value,
    };
    setFormValue(updatedFormValue);

    const isLenghValid = validateLength(value);
    if (!isLenghValid) {
      setError({
        ...error,
        [name]: `Password cannot be less than ${MIN_PASSWORD_LENGTH} characters`,
      });
      return;
    }

    if (updatedFormValue.password && updatedFormValue.confirmPassword && updatedFormValue.password !== updatedFormValue.confirmPassword) {
      setError({
        ...error,
        confirmPassword: 'Password and Confirm Password do not match.',
      });
      return;
    }
    setError({
      ...error,
      password: '',
      confirmPassword: '',
    });
  };

  const handleOnNextClick = (e) => {
    e.preventDefault();
    onSubmit(formValue.password);
  };

  return (
    <form className='user-verify-form' onSubmit={handleOnNextClick} autoComplete='off'>
      <div className='content'>
        <TextField
          className='text-form password-field'
          value={formValue.password}
          label='Password'
          inputProps={{
            maxLength: 255,
          }}
          name='password'
          type='password'
          onChange={handleOnTextChange}
          error={Boolean(error.password)}
          helperText={error.password}
        />
        <TextField
          className='text-form confirm-password-field'
          value={formValue.confirmPassword}
          label='Confirm Password'
          inputProps={{
            maxLength: 255,
          }}
          name='confirmPassword'
          type='password'
          onChange={handleOnTextChange}
          error={Boolean(error.confirmPassword)}
          helperText={error.confirmPassword}
        />
      </div>
      <div className='actions'>
        <ButtonWithSpinner
          className='btn-primary'
          label='Verify'
          fullWidth
          disabled={!validatePassword(formValue.password, formValue.confirmPassword)}
          loading={loading}
          onClick={handleOnNextClick}
          type='submit'
        />
      </div>
    </form>
  );
};

UserVerifyForm.defaultProps = {
  onSubmit: () => { },
  loading: false,
};

UserVerifyForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default UserVerifyForm;

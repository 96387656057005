import { createTheme } from '@mui/material';
import './styles/fonts.scss';

const FONT = {
  styleOverrides: {
    root: {
      fontFamily: 'Rubik',
    },
  },
};
const materialTheme = createTheme({
  typography: {
    fontFamily: ['Rubik'],
  },
  components: {
    MuiButton: {
      FONT,
    },
    MuiCard: {
      FONT,
    },
  },
});

export default materialTheme;

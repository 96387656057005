import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const month = new Date().getMonth();
const octoberMonth = 9;

const COLORS = month === octoberMonth ? ['#B25A00'] : ['#570e7f', '#fc41bb'];
const BACKGROUND_GRADIENT_INTENSITY = 20;

const RetrofutureTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    flags: [],
    spritesPlay: [],
    tweens: [],
    timeouts: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  const addBuilding = (type, positionX, positionY, scaleX, scaleY, animationSpeed = 0) => {
    const building = new PIXI.AnimatedSprite(app.sheet.animations[type]);
    building.scale.y = scaleY;
    building.scale.x = scaleX;
    building.x = positionX;
    building.y = positionY;
    if (animationSpeed > 0) {
      building.animationSpeed = animationSpeed;
      building.play();
      app.spritesPlay.push(building);
    }
    app.floatingIslandContainer.addChild(building);
  };

  const addAnimated = (type, positionX, positionY, scaleX, scaleY, typeTwo, wait) => {
    if (!app.sheet.animations[type]) {
      return;
    }
    const animated = new PIXI.AnimatedSprite(app.sheet.animations[type]);
    animated.scale.y = scaleY;
    animated.scale.x = scaleX;
    animated.x = positionX;
    animated.y = positionY;
    app.floatingIslandContainer.addChild(animated);

    if (typeTwo === 'monster') {
      animated.animationSpeed = 0.3;
      animated.play();
      app.spritesPlay.push(animated);
    }

    if (typeTwo === 'car') {
      const carTween = new TWEEN.Tween(animated)
        .delay(wait)
        .to({ x: (positionX - 1250), y: (positionY - 400) }, 3500)
        .easing(TWEEN.Easing.Linear.None)
        .repeat(Infinity);
      carTween.start();
      app.tweens.push(carTween);
    }
    if (typeTwo === 'reverseCar') {
      const reverseCarTween = new TWEEN.Tween(animated)
        .delay(wait)
        .to({ x: (positionX + 1200), y: (positionY + 400) }, 3500)
        .easing(TWEEN.Easing.Linear.None)
        .repeat(Infinity);
      reverseCarTween.start();
      app.tweens.push(reverseCarTween);
    }
    if (typeTwo === 'witch') {
      animated.animationSpeed = 0.35;
      animated.play();
      app.spritesPlay.push(animated);
      const witchTween = new TWEEN.Tween(animated)
        .delay(wait)
        .to({ x: (positionX - 1700), y: (positionY - 400) }, 6000)
        .easing(TWEEN.Easing.Linear.None)
        .repeat(Infinity);
      witchTween.start();
      app.tweens.push(witchTween);
    }
    if (typeTwo === 'witchNear') {
      animated.animationSpeed = 0.35;
      animated.play();
      app.spritesPlay.push(animated);

      const witchNearTween = new TWEEN.Tween(animated)
        .delay(wait)
        .to({ x: (positionX - 1700), y: (positionY - 400) }, 10500)
        .easing(TWEEN.Easing.Linear.None)
        .repeat(Infinity);
      witchNearTween.start();
      app.tweens.push(witchNearTween);
    }
  };

  const addRandomBackground = () => {
    const rand = Math.floor(Math.random() * 4) + 1;

    switch (rand) {
      case 1:
        addBuilding('hotelsign', 385, 310, 0.3, 0.3);
        addBuilding('side_building', 500, 510, 0.5, 0.5);
        addBuilding('rectangular_building', 680, 810, 0.6, 0.6);
        addBuilding('squarePink_building', 620, 810, 0.4, 0.4);
        addBuilding('rectangular_building', 200, 410, 0.3, 0.3);
        addBuilding('side_building', 272, 610, 0.3, 0.3);
        addBuilding('squarePink_building', 100, 610, 0.3, 0.3);
        addBuilding('squarePink_building', 200, 830, 0.4, 0.4);

        addBuilding('billboard', 80, 510, 0.05, 0.05, 0.65);
        addBuilding('billboard', 60, 502, 0.05, 0.05, 0.65);
        addBuilding('billboard', 60, 510, 0.05, 0.05, 0.65);
        addBuilding('billboard', 80, 518, 0.05, 0.05, 0.65);

        addAnimated('billboard', -100, 110, 0.02, 0.02, 'reverseCar', 0);
        addAnimated('billboard', 1200, 610, 0.02, 0.02, 'car', 500);
        addAnimated('billboard', -200, 210, 0.02, 0.02, 'reverseCar', 500);
        addAnimated('billboard', -300, 310, 0.02, 0.02, 'reverseCar', 300);

        addBuilding('penthouse_building', 860, 460, 0.6, 0.6);
        addBuilding('square_building', 300, 810, 0.5, 0.5);

        addAnimated('monster', 500, 250, 0.7, 1, 'monster', 0);
        addAnimated('witch', 1100, 410, 0.5, 0.5, 'witchNear', 0);

        break;
      case 2:
        addBuilding('rectangular_building', 825, 410, 0.3, 0.3);
        addBuilding('squarePink_building', 415, 560, 0.3, 0.3);
        addBuilding('side_building', 900, 510, 0.3, 0.3);
        addBuilding('side_building', 900, 810, 0.5, 0.5);
        addAnimated('billboard', 1100, 510, 0.02, 0.02, 'car', 0);
        addAnimated('billboard', 1200, 610, 0.02, 0.02, 'car', 500);

        addBuilding('squarePink_building', 650, 610, 0.3, 0.3);
        addAnimated('billboard', -100, 110, 0.02, 0.02, 'reverseCar', 0);
        addBuilding('weird_building', 790, 710, 0.5, 0.5);
        addBuilding('rectangular_building', 515, 410, 0.3, 0.3);
        addBuilding('billboard', 490, 300, 0.5, 0.5, 0.65);
        addBuilding('squarePink_building', 450, 610, 0.2, 0.2);

        addAnimated('witch', 1100, 410, 0.1, 0.1, 'witch', 10000);

        addAnimated('billboard', -200, 210, 0.02, 0.02, 'reverseCar', 500);
        addBuilding('billboard', 100, 550, 0.25, 0.25, 0.65);
        addBuilding('billboard', 180, 584, 0.25, 0.25, 0.65);
        addBuilding('square_building', 600, 810, 0.5, 0.5);
        addAnimated('monster', 600, 560, 0.5, 0.5, 'monster', 0);

        addBuilding('penthouse_building', 260, 460, 0.6, 0.6);

        break;
      case 3:
        addBuilding('rectangular_building', 550, 310, 0.4, 0.4);
        addBuilding('side_building', 690, 410, 0.4, 0.4);
        addBuilding('squarePink_building', 660, 560, 0.35, 0.35);
        addBuilding('square_building', 450, 590, 0.25, 0.25);
        addBuilding('penthouse_building', 360, 360, 0.4, 0.4);
        addBuilding('side_building', 550, 710, 0.4, 0.4);
        addBuilding('square_building', 900, 810, 1, 1);

        addAnimated('witch', 1100, 410, 0.07, 0.07, 'witch', 10000);

        addAnimated('billboard', 1100, 510, 0.02, 0.02, 'car', 500);
        addAnimated('billboard', 1200, 610, 0.02, 0.02, 'car', 0);
        addAnimated('billboard', -100, 110, 0.02, 0.02, 'reverseCar', 0);
        addBuilding('rectangular_building', 200, 410, 0.6, 0.6);
        addBuilding('side_building', 400, 810, 0.5, 0.5);
        addBuilding('rectangular_building', 700, 910, 0.8, 0.8);
        addAnimated('billboard', -200, 210, 0.02, 0.02, 'reverseCar', 500);
        addAnimated('billboard', -300, 310, 0.02, 0.02, 'reverseCar', 300);
        addBuilding('rectangular_building', 100, 710, 0.6, 0.6);

        addAnimated('monster', 700, 468, 0.7, 1, 'monster', 0);
        break;
      case 4:
      default:
        addBuilding('penthouse_building', 400, 410, 0.2, 0.2);
        addBuilding('rectangular_building', 550, 310, 0.4, 0.4);
        addBuilding('squarePink_building', 660, 560, 0.35, 0.35);
        addBuilding('square_building', 450, 590, 0.25, 0.25);
        addBuilding('side_building', 550, 710, 0.4, 0.4);
        addBuilding('side_building', 875, 510, 0.5, 0.5);
        addBuilding('side_building', 950, 710, 0.4, 0.4);

        addAnimated('witch', 1100, 410, 0.1, 0.1, 'witch', 12000);

        addAnimated('billboard', 1100, 510, 0.02, 0.02, 'car', 500);
        addAnimated('billboard', 1200, 610, 0.02, 0.02, 'car', 0);
        addAnimated('billboard', -100, 110, 0.02, 0.02, 'reverseCar', 0);
        addBuilding('rectangular_building', 200, 410, 0.6, 0.6);
        addBuilding('side_building', 400, 810, 0.5, 0.5);

        addBuilding('rectangular_building', 700, 910, 0.8, 0.8);
        addAnimated('billboard', -200, 210, 0.02, 0.02, 'reverseCar', 500);
        addAnimated('billboard', -300, 310, 0.02, 0.02, 'reverseCar', 300);
        addBuilding('rectangular_building', 100, 710, 0.6, 0.6);

        addAnimated('monster', 700, 460, 0.7, 0.7, 'monster', 500);
        break;
    }
  };

  function weatherBegin() {
    const snowflake = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    snowflake.scale.x = 0.5;
    snowflake.scale.y = 0.5;

    const container = new PIXI.Container();
    container.addChild(snowflake);
    container.x = Math.random() * app.canvas.view.width;
    const duration = Math.random() * 1000 + 1200;

    const snowFlakeTween = new TWEEN.Tween(snowflake)
      .delay(15)
      .to({ x: 15, alpha: 0.2 }, duration)
      .easing(TWEEN.Easing.Linear.None)
      .chain(new TWEEN.Tween(snowflake)
        .delay(15)
        .to({ x: 0, alpha: 1 }, duration)
        .onComplete(() => {
          snowFlakeTween.start();
        }));
    snowFlakeTween.start();
    app.tweens.push(snowFlakeTween);

    const snowFlakeYTween = new TWEEN.Tween(snowflake)
      .to({ y: app.canvas.view.height + 500 }, 7000)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        app.floatingIslandContainer.removeChild(container);
      });

    snowFlakeYTween.start();
    app.tweens.push(snowFlakeYTween);

    app.floatingIslandContainer.addChild(container);
  }
  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas } = ThemeUtil.initCanvasWithBackground(
        canvas,
        COLORS,
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick);
      const progressBar = {
        barBackground: 'progressbar_background_retrofuture',
        progressBar: 'progressbar_retrofuture',
        progressAvatar: 'retrofuture_avatar',
        dotType: 'circle',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );
      app.barBackground.y += 10;
      if (innerLessonsCompleted === 0) {
        app.progressAvatar.x += 10;
      }
      ThemeUtil.addFloatableObjects(app, 3000, false);
      addRandomBackground();
      app.interval = setInterval(weatherBegin, 1);
    }

    return () => {
      ThemeUtil.stopFloatableObjects(app);
      clearInterval(app.interval);
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

RetrofutureTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

RetrofutureTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default RetrofutureTheme;

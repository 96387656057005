import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography } from '@mui/material';
import SearchAndSortableTable from '../../../../../components/table/SearchAndSortableTable';
import {
  useGetSelectedTeacherTable,
  useHandleSearchDistrictSchoolSelector,
  validateMoveTeacherFormData as validator,
} from './MoveTeacherActionHooks';
import './MoveTeacherForm.scss';
import DistrictSchoolSelector from '../district-school-selector/DistrictSchoolSelector';

export const validateMoveTeacherFormData = validator;

const MoveTeacherForm = ({
  initData,
  teacherTableData,
  onDataChange,
}) => {
  const {
    tableConfig,
    tableData,
  } = useGetSelectedTeacherTable({
    teacherTableData,
  });

  const [data, setData] = useState({ ...initData });

  useEffect(() => {
    setData({ ...initData });
  }, [initData]);

  const {
    handleOnDistrictSchoolChange,
  } = useHandleSearchDistrictSchoolSelector({
    data,
    setData,
    onDataChange,
  });

  return (
    <div className='move-teacher-form'>
      <SearchAndSortableTable
        containerClassName='move-teacher-table'
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'userName',
        }}
        autoResetTableState
        filterable={false}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />

      <div className='form-row'>
        <Typography className='form-label'>
          Move Students
        </Typography>
        <Checkbox
          checked={data.shouldMoveStudent}
          onChange={(e) => {
            const updatedData = {
              ...data,
              shouldMoveStudent: e.target.checked,
            };
            setData(updatedData);
            onDataChange(updatedData, validateMoveTeacherFormData(updatedData));
          }}
          name='shouldMoveStudent'
          className='move-student-checkbox'
        />
      </div>

      <DistrictSchoolSelector
        onChange={handleOnDistrictSchoolChange}
        selectedDistrictId={data.district && data.district.key}
        selectedSchoolId={data.school && data.school.key}
      />
    </div>
  );
};

MoveTeacherForm.defaultProps = {
  initData: {
    shouldMoveStudent: false,
    schoolId: null,
  },
  teacherTableData: [],
  onDataChange: () => null,
};

MoveTeacherForm.propTypes = {
  initData: PropTypes.shape({
    shouldMoveStudent: PropTypes.bool,
    schoolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  teacherTableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attributes: PropTypes.shape({
      userName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
  onDataChange: PropTypes.func,
};

export default MoveTeacherForm;

import { useMemo } from 'react';
import { Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import './SimpleReinforcer.scss';
import { getRandomMessage } from '../../../../constants/PlayPreferenceConstant';

export default function SimpleReinforcer() {
  const message = useMemo(() => getRandomMessage(), []);
  return (
    <div className='simple-reinforcer-style'>
      <TaskAltIcon htmlColor='white' fill='white' />
      <Typography color='white' fontWeight={500}>{message}</Typography>
    </div>
  );
}

import { createContext, useContext } from 'react';
import MyClassRoomDomain from './MyClassRoomDomain';
import MyAssignmentsDomain from './MyAssignmentsDomain';

const domains = [
  new MyClassRoomDomain(true),
  new MyAssignmentsDomain(false),
];

/*-----------------------------------------------------------------------*/

let MyClassRoomContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  MyClassRoomContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useMyClassroom = () => useContext(MyClassRoomContextProvider);

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import LoginForm from './login-form/LoginForm';
import { login, loginWithRethink } from '../../services/LoginService';
import Logger from '../../utils/Logger';
import { Base64 } from '../../utils/Base64Decoder';
import { getUserLoginData, clearUserLoginData, saveUserLoginData } from '../../utils/VizzleSessionStorage';
import { clearAllDomainData } from '../../states/AppDomainProvider';
import { saveLessonResponseEventsInCache, usePrepareData } from './LoginHooks';
import ForgotPassword from './forgot-password/ForgotPassword';
import { destroyAccessibe } from '../../services/AccessibeService';
import LiveChatService from '../../services/LiveChatService';
import AppVersion from '../app-version/AppVersion';

const NETWORK_ISSUE_MESSAGE = 'Sorry, the query could not be performed. Please check your network connection.';
const INVALID_LICENSE_MESSAGE = 'It appears your license is no longer valid, please contact Vizzle to resolve this.';
const INCORRECT_LOGIN = 'Username / password combination is incorrect, try again!';

/**
 * Login container
 *
 * @param {*} props passed in from parent component
 */
export default function LoginContainer({
  forRethink,
}) {
  const [state, setState] = useState({
    loading: false,
    errorMessage: '',
    loginSuccess: false,
  });

  const [userLoginDataState, setUserLoginDataState] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });

  useEffect(() => {
    clearAllDomainData();
    destroyAccessibe();
    LiveChatService.resetLiveChatService();

    const userLoginData = getUserLoginData();
    if (userLoginData) {
      const userLoginDataObject = Base64.decodeObject(getUserLoginData());
      setUserLoginDataState({
        username: userLoginDataObject.username,
        rememberMe: userLoginDataObject.rememberMe,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  const {
    prepareData,
  } = usePrepareData();
  const onSubmit = async (data) => {
    if (state.loading) {
      return;
    }
    try {
      setState({
        ...state,
        loading: true,
        errorMessage: '',
      });
      let result = null;
      let targetUrl = null;
      if (forRethink) {
        const { search } = location.state || location;
        const params = new URLSearchParams(search);
        const accessToken = params.get('access_token');
        const rurl = params.get('rurl');

        result = await loginWithRethink(data.username, data.password, accessToken);
        if (rurl) {
          const redirect = new URL(rurl);
          targetUrl = redirect.pathname;
        }
      } else {
        result = await login(data.username, data.password);
      }

      if (result.success) {
        saveLessonResponseEventsInCache();
        if (data.rememberMe) {
          const encodedUserLoginData = Base64.encodeObject({
            username: data.username,
            rememberMe: data.rememberMe,
          });
          Logger.logWhenDebugModeIsOn({
            USER_DATA: encodedUserLoginData,
          });
          saveUserLoginData(encodedUserLoginData);
        } else {
          Logger.logWhenDebugModeIsOn('Remove user data session');
          clearUserLoginData();
        }
        window.scrollTo(0, 0);

        prepareData(result, targetUrl);
      } else {
        let msg = NETWORK_ISSUE_MESSAGE;
        if (result.message === 'Error: Invalid License') {
          msg = INVALID_LICENSE_MESSAGE;
        } else if (result.message === 'Error: Invalid Credentials') {
          msg = INCORRECT_LOGIN;
        } else if (result.message) {
          msg = result.message;
        }
        setState({
          ...state,
          loading: false,
          errorMessage: msg,
        });
      }
    } catch (error) {
      Logger.logError(error);
      setState({ ...state, loading: false, errorMessage: NETWORK_ISSUE_MESSAGE });
    }
  };

  const onValidate = (field, value, data) => {
    let isValid = false;

    if (data.username && data.password && value) {
      isValid = true;
    }

    return isValid;
  };

  const loginProps = {
    onSubmit,
    onValidate,
    loginSuccess: state.loginSuccess,
    loading: state.loading,
    errorMessage: state.errorMessage,
    userLoginData: userLoginDataState,
    forgotPasswordComponent: <ForgotPassword />,
    children: <AppVersion />,
    loginClever: true,
  };

  return (
    <LoginForm {...loginProps} />
  );
}

LoginContainer.defaultProps = {
  forRethink: false,
};

LoginContainer.propTypes = {
  forRethink: PropTypes.bool,
};

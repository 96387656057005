import { useEffect, useRef } from 'react';
import {
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  // ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../../AppConstants';
import { saveLessonPlaySnapshot, trackAnswer } from '../../../../services/LessonPlayService';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { isTeacher } from '../../../../utils/User';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';

export const ANSWER_TRACKING_ACTIVITIES = [
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
];

let waitingQueue = [];
let trackAnswerList = [];

const saveTrackingAnswer = ({ studentHistoryId, pageNumber, cardIndex, correctSubmission, lessonPagesData, currentPageIndex, once }) => {
  try {
    const currentLessonPage = lessonPagesData[currentPageIndex];
    if (once) {
      const answer = trackAnswerList.find((t) => t.pageNumber === currentPageIndex && t.cardIndex === cardIndex);
      if (answer) {
        return;
      }
    }

    trackAnswerList.push({ studentHistoryId, pageNumber, cardIndex, correctSubmission });

    if (!correctSubmission && !once) {
      return;
    }

    ObjectUtils.setTimeout(async () => {
      if (waitingQueue.length > 0) {
        for (let i = 0; i < waitingQueue.length; i++) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await waitingQueue[i];
          } catch (e) {
            Logger.logError(e);
          }
        }
        // waitingQueue = [];
      }
      let filteredTrackAnswerList = trackAnswerList;
      if ([ACTIVITY_TYPE_MATCHING_ACTIVITY, ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY].includes(currentLessonPage.activityType)) {
        filteredTrackAnswerList = trackAnswerList.filter((t) => t.pageNumber === currentPageIndex);
      } else {
        filteredTrackAnswerList = [trackAnswerList.find((t) => t.pageNumber === currentPageIndex && t.cardIndex === cardIndex)];
      }

      const promise = trackAnswer(filteredTrackAnswerList);
      waitingQueue.push(promise);
    });
  } catch (e) {
    Logger.logError(e);
  }
};

const updateLessonResponseTracking = ({
  responseIndex,
  lessonResponseTrackingRef,
  studentHistoryId,
  currentPageIndex,
  lessonPagesData,
  correctSubmission,
  once,
}) => {
  const currentPageData = lessonResponseTrackingRef.current[currentPageIndex];
  const currentLessonPage = lessonPagesData[currentPageIndex];
  const { responseCards: responseCardsTemp, answerCards } = currentLessonPage;
  const responseCards = responseCardsTemp || answerCards;
  if (!responseCards) {
    return;
  }
  const { cardIndex } = responseCards[responseIndex];

  if (!currentPageData) {
    // eslint-disable-next-line no-param-reassign
    lessonResponseTrackingRef.current = {
      ...lessonResponseTrackingRef.current,
      [currentPageIndex]: {
        [cardIndex]: {
          correctSubmission,
        },
      },
    };

    saveTrackingAnswer({
      studentHistoryId,
      pageNumber: currentPageIndex,
      cardIndex,
      correctSubmission,
      lessonPagesData,
      currentPageIndex,
      once,
    });
  } else {
    // const answer = currentPageData[cardIndex];
    // if (!answer) {
    // eslint-disable-next-line no-param-reassign
    lessonResponseTrackingRef.current = {
      ...lessonResponseTrackingRef.current,
      [currentPageIndex]: {
        ...lessonResponseTrackingRef.current[currentPageIndex],
        [cardIndex]: {
          correctSubmission,
        },
      },
    };

    saveTrackingAnswer({
      studentHistoryId,
      pageNumber: currentPageIndex,
      cardIndex,
      correctSubmission,
      lessonPagesData,
      currentPageIndex,
      once,
    });
    // }
  }
};

const shouldTrackAnswer = ({
  currentPageIndex,
  lessonPagesData,
  isPreviewMode,
}) => {
  const currentLessonPage = lessonPagesData[currentPageIndex];

  return (!isPreviewMode && ANSWER_TRACKING_ACTIVITIES.includes(currentLessonPage.activityType));
};

export const useHandleLessonResponseTracking = ({
  registeredLessonRef,
  studentHistoryId,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { initialPlayHistoryId,
    isPreviewMode,
    user: userPlayingLesson,
    lessonPagesData,
    lessonPagesDataSnapshot,
    isError,
  } = lessonPlayDomain.domainData;

  const lessonResponseTrackingRef = useRef({});

  const startStudentPlayHistory = async () => {
    const promise = saveLessonPlaySnapshot({ studentHistoryId, lessonPagesDataSnapshot });
    waitingQueue.push(promise);
  };

  // For internal use
  const studentHistoryIdRef = useRef(null);
  useEffect(() => {
    if (!studentHistoryId || isTeacher(userPlayingLesson)) {
      return;
    }

    waitingQueue = [];
    trackAnswerList = [];

    if (!registeredLessonRef.current || !userPlayingLesson || initialPlayHistoryId) {
      if (initialPlayHistoryId) {
        studentHistoryIdRef.current = initialPlayHistoryId;
      }
      if (!isError) {
        return;
      }
    }

    try {
      studentHistoryIdRef.current = studentHistoryId;
      startStudentPlayHistory();
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentHistoryId]);

  const handleLessonTrackingCorrectAnswerSubmitted = ({
    responseIndex,
  }) => {
    if (!userPlayingLesson.userType || isTeacher(userPlayingLesson)) {
      return;
    }

    const { currentPageIndex } = lessonPlayDomain.domainData;
    if (!shouldTrackAnswer({
      currentPageIndex,
      lessonPagesData,
      isPreviewMode,
    })) {
      return;
    }

    updateLessonResponseTracking({
      responseIndex,
      lessonResponseTrackingRef,
      studentHistoryId: studentHistoryIdRef.current,
      currentPageIndex,
      lessonPagesData,
      correctSubmission: true,
    });
  };

  const handleLessonTrackingInCorrectAnswerSubmitted = ({
    responseIndex,
    once,
  }) => {
    if (isTeacher(userPlayingLesson)) {
      return;
    }
    const { currentPageIndex } = lessonPlayDomain.domainData;
    if (!userPlayingLesson.userType || !shouldTrackAnswer({
      currentPageIndex,
      lessonPagesData,
      isPreviewMode,
    })) {
      return;
    }
    updateLessonResponseTracking({
      responseIndex,
      lessonResponseTrackingRef,
      studentHistoryId: studentHistoryIdRef.current,
      currentPageIndex,
      lessonPagesData,
      correctSubmission: false,
      once,
    });
  };

  return {
    handleLessonTrackingCorrectAnswerSubmitted,
    handleLessonTrackingInCorrectAnswerSubmitted,
  };
};

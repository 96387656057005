import PropTypes from 'prop-types';
import { Box, Typography, Card, CardMedia, CardActionArea, CardContent } from '@mui/material';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DefaultImage from '../../static/images/default_thumbnail.png';
import './SelectableCard.scss';

const SelectableCard = ({
  name,
  thumbnail,
  thumbnailAsColor,
  onClick,
  checked,
  index,
  onMediaClick,
}) => (
  <div className='selectable-card-div'>
    <Card className='settings-selectable-card' onClick={() => onClick(index)} data-test={`selectable-card-${name}`}>
      <CardActionArea className='settings-action-area'>
        <div className='overlay' />
        {thumbnailAsColor ? (
          // Use default image thumbnail for the consistency of with and height
          // Let the browser automatically calculate the height
          <Box sx={{ backgroundColor: thumbnail }}>
            <CardMedia
              component='img'
              image={DefaultImage}
              sx={{ opacity: 0 }}
              data-test={`selectable-card-img-${name}`}
            />
          </Box>
        ) : (
          <CardMedia
            component='img'
            image={thumbnail}
            data-test={`selectable-card-img-${name}`}
          />
        )}
        <CardContent className='settings-selectable-card-content'>
          <Typography component='p' className='settings-card-name' align='center' data-test={`selectable-card-text-${name}`}>
            {name}
          </Typography>
          {checked
            ? <CheckCircleIcon className='settings-selectable-card-overlay-icon settings-card-checked' data-test={`selectable-card-checked-${name}`} />
            : <RadioButtonUncheckedOutlinedIcon className='settings-selectable-card-overlay-icon settings-card-unchecked' data-test={`selectable-card-unchecked-${name}`} />}
          {onMediaClick
            ? (
              <PlayCircleFilledIcon
                className='settings-selectable-card-overlay-media'
                data-test={`selectable-card-play-action-${name}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onMediaClick();
                }}
              />
            )
            : null}
        </CardContent>
      </CardActionArea>
    </Card>
  </div>
);

SelectableCard.defaultProps = {
  name: '',
  thumbnail: '',
  thumbnailAsColor: false,
  checked: false,
  index: 0,
  onClick: () => { },
  onMediaClick: null,
};

SelectableCard.propTypes = {
  // eslint-disable-next-line
  name: PropTypes.string,
  thumbnail: PropTypes.string,
  thumbnailAsColor: PropTypes.bool,
  checked: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onMediaClick: PropTypes.func,
};

export default SelectableCard;

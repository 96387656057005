import { useRef, useState, useEffect } from 'react';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { getMasteryObjective, masteryNotificationViewed } from '../../../../services/MasteryService';
import { mapMasteryCriteriaIntoGroup } from '../../../../utils/MasterObjectiveMapping';
import { getAllLessonTagsForStudent } from '../../../../services/LessonTagService';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { UNAUTHORIZED } from '../../../../AppConstants';
import Logger from '../../../../utils/Logger';
import ObjectUtils from '../../../../utils/ObjectUtils';

export const useGetStudentMasteryObjective = () => {
  const { studentsDomain, uiDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { masterObjectiveData } = myStudentDomain.domainData;
  const [loading, setLoading] = useState(false);
  const currentStudent = students && students.find((s) => s.id === currentSelectedStudentId);

  useEffect(() => {
    const updateMasteryNotificationViewed = async () => {
      if (currentStudent && currentStudent.masteryNotificationUnread) {
        try {
          await masteryNotificationViewed(currentSelectedStudentId);
          // eslint-disable-next-line no-param-reassign
          currentStudent.masteryNotificationUnread = false;
          studentsDomain.updateStudentList(students);
        } catch (error) {
          if (error.response && UNAUTHORIZED !== error.response.status) {
            await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
          }
          Logger.logError({
            ERROR: error,
          });
        }
      }
    };

    const fetchStudentMasteryObjective = async () => {
      setLoading(true);
      uiDomain.showLoader(`Getting ${currentStudent.firstName} ${currentStudent.lastName}'s progress`, LOADER_TYPE.HASH_LOADER);
      const masterObjectivePromise = getMasteryObjective(currentSelectedStudentId);
      const lessonTagsPromise = getAllLessonTagsForStudent(currentSelectedStudentId);

      const [masterObjective, lessonTags] = await Promise.all([masterObjectivePromise, lessonTagsPromise]);
      if (masterObjective.data && masterObjective.data.length > 0) {
        const masterCriteriaObjectives = mapMasteryCriteriaIntoGroup(masterObjective.data);
        myStudentDomain.updateMasteryObjectivesAndLessonTags(masterCriteriaObjectives, masterObjective.included, lessonTags.data);

        // Need to update the notification after fetching goal and objective
        updateMasteryNotificationViewed();
      } else {
        myStudentDomain.updateMasteryObjectives([], []);
      }
      uiDomain.hideLoader();
      setLoading(false);
    };

    if (currentStudent) {
      fetchStudentMasteryObjective();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStudentId]);

  return {
    loading,
    masterObjectiveData,
  };
};

export const useReloadObjectiveData = ({
  objectiveCardListDetailRef,
}) => {
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { myStudentDomain } = useStudentContext();
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;

  const reloadObjectiveData = async () => {
    try {
      const currentObjective = objectiveCardListDetailRef.current.getCurrentOpenObjective();
      await callRemoteServiceWrapper(async () => {
        const masterObjective = await getMasteryObjective(currentSelectedStudentId);
        if (masterObjective.data && masterObjective.data.length > 0) {
          const masterCriteriaObjectives = mapMasteryCriteriaIntoGroup(masterObjective.data);
          await myStudentDomain.updateMasteryObjectives(masterCriteriaObjectives, masterObjective.included);
        } else {
          await myStudentDomain.updateMasteryObjectives([], []);
        }
        objectiveCardListDetailRef.current.closeObjectiveCardDetail();
        ObjectUtils.setTimeout(() => {
          const el = document.querySelector(`#goal-card-action-${currentObjective.id}`);
          if (el) {
            el.click();
          }
        }, 500);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };
  return reloadObjectiveData;
};

// hook functions for handling the container
export const useLessonDetail = () => {
  const lessonCardSlide = useRef(null);
  const [openLessonCardDetail, setOpenLessonCardDetail] = useState({
    open: false,
    selectedIndex: null,
    rowIndex: null,
    lessonList: null,
    included: null,
  });

  const handleOnViewDetailClick = (selectedIndex, rowIndex, lessonList, included) => {
    setOpenLessonCardDetail({
      open: true,
      selectedIndex,
      rowIndex,
      lessonList,
      included,
    });
  };

  const handleOnCloseDetail = () => {
    setOpenLessonCardDetail({
      open: false,
      selectedIndex: null,
      rowIndex: null,
      lessonList: null,
      included: null,
    });
  };

  return {
    lessonCardSlide,
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openLessonCardDetail,
    setOpenLessonCardDetail,
  };
};

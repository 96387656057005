import * as RV from './TextToSpeechServiceV1';
// eslint-disable-next-line import/no-cycle
import * as AZURE from './TextToSpeechServiceV2';

export const TTS_MODE_RV = 'rv';
export const TTS_MODE_AZURE = 'azure';

export const HIGHLIGHT_COLORS = [
  { key: 'Orange', value: '#ef7c22', name: 'Orange' },
  { key: 'Blue', value: '#509fd6', name: 'Blue' },
  { key: 'Green', value: '#78b543', name: 'Green' },
  { key: 'Yellow', value: '#f4f442', name: 'Yellow' },
];

export const TTS_DEFAULT_VALUE = {
  rate: 10,
  pitch: 10,
  volume: 10,
  voice: 0,
  highlightColor: HIGHLIGHT_COLORS[0].value,
  ttsMode: 'azure',
};

// export const getByMode = (toReturn) => {
//   if (ttsMode === TTS_MODE_AZURE) {
//     return AZURE[toReturn];
//   }
//   return RV[toReturn];
// };

// export const VOICE_TYPES = getByMode('VOICE_TYPES');

// export const HIGHLIGHT_COLORS = getByMode('HIGHLIGHT_COLORS');

// export const TTS_DEFAULT_VALUE = getByMode('TTS_DEFAULT_VALUE');

// export const playTextToSpeech = getByMode('playTextToSpeech');

// export const cancelTts = getByMode('cancelTts');

// export const readText = getByMode('readText');

// export const isTtsPlaying = getByMode('isTtsPlaying');

// export const reigsterTextToSpeechAutoPlay = getByMode('reigsterTextToSpeechAutoPlay');

export const reigsterTextToSpeechAutoPlay = () => {
  RV.reigsterTextToSpeechAutoPlay();
  AZURE.reigsterTextToSpeechAutoPlay();
};

export default class {
  constructor(ttsMode) {
    this.ttsAssets = TTS_MODE_AZURE === ttsMode ? AZURE : RV;
  }

  getTtsService() {
    return {
      VOICE_TYPES: this.ttsAssets.VOICE_TYPES,
      HIGHLIGHT_COLORS,
      TTS_DEFAULT_VALUE,
      playTextToSpeech: this.ttsAssets.playTextToSpeech,
      cancelTts: this.ttsAssets.cancelTts,
      readText: this.ttsAssets.readText,
      isTtsPlaying: this.ttsAssets.isTtsPlaying,
      reigsterTextToSpeechAutoPlay: this.ttsAssets.reigsterTextToSpeechAutoPlay,
    };
  }
}

import LogRocket from 'logrocket';

const fieldsToSenatize = ['username', 'firstname', 'lastname', 'email', 'emailaddress', 'stateidentifier', 'schoologyid', 'teachername'];

// const senatizeFields = (obj) => Object.keys(obj).reduce((acc, curr) => ({
//   ...acc,
//   [curr]: fieldsToSenatize.includes(curr.toLocaleLowerCase()) ? '*' : obj[curr],
// }), {});

const updateValueByFieldName = (obj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (fieldsToSenatize.includes(key.toLocaleLowerCase())) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = '*';
    } else if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((element) => updateValueByFieldName(element),
        );
      } else {
        updateValueByFieldName(obj[key]);
      }
    }
  }
};

LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
  network: {
    responseSanitizer: (response) => {
      try {
        const responseBody = typeof response.body === 'string' ? JSON.parse(response.body) : response.body;
        // if (responseBody !== null) {
        //   if (Array.isArray(responseBody)) {
        //     response.body = responseBody.map((obj) => senatizeFields(obj));
        //   } else {
        //     response.body = senatizeFields(responseBody);
        //   }
        // }
        updateValueByFieldName(responseBody);
        response.body = responseBody;
      } catch (e) { /* empty */ }

      return response;
    },
  },
});

export default {
  identifyUser: (user) => {
    LogRocket.identify(user.id, {
      name: user.id,
      email: user.id,

      // Add your own custom user variables here, ie:
      subscriptionType: 'pro',
    });
  },
};

import {
  Box,
  Card,
} from '@mui/material';
import PropTypes from 'prop-types';
import './LessonActivityNavigator.scss';
import { calculateCardThumbnailSize } from '../../../../utils/CardSizeCalculator';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import PageDataNavigator from '../../../../components/vizzle/lessons/lessonmaintenance/PageDataNavigator';
import { getActivityCardThumbnailByActivityType } from '../../../../utils/activitytype/ActivityTypesUtil';
import { ANSWER_TRACKING_ACTIVITIES } from '../lesson-activity-play/LessonResponseTrackingHooks';
import SubmittedAnswerIndicator from '../../activities/common/sumitted-answer-indicator/SubmittedAnswerIndicator';
import { ACTIVITY_TYPE_SORTING_ACTIVITY, ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2 } from '../../../../AppConstants';
import { flatten } from '../../../../utils/ArrayUtils';

const cardSize = calculateCardThumbnailSize();

const LessonActivityNavigator = ({
  onSelect,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { lessonPagesDataSnapshot, currentPageIndex } = lessonPlayDomain.domainData;

  const handleSelect = (index) => {
    onSelect(index);
  };

  const getSubmittedAnswerIndicator = ({
    pageData,
  }) => {
    if (ANSWER_TRACKING_ACTIVITIES.includes(pageData.activityType)) {
      let responseCards = null;

      if (pageData.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
        responseCards = flatten(pageData.categories.map((category) => (
          category.responseCards
        )));
      } else if ([ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2].includes(pageData.activityType)) {
        responseCards = pageData.answerCards;
      } else {
        responseCards = pageData.responseCards;
      }

      if (!responseCards || responseCards.length === 0) {
        return null;
      }

      const wrongAnswerIndex = responseCards.findIndex((r) => r.correctSubmission === false);
      if (wrongAnswerIndex >= 0) {
        return (<SubmittedAnswerIndicator correctSubmission={false} />);
      }

      const correctAnswerIndex = responseCards.findIndex((r) => r.correctSubmission === true);
      if (correctAnswerIndex >= 0) {
        return (<SubmittedAnswerIndicator correctSubmission />);
      }
    }
    return null;
  };

  return (
    <div className='lesson-activity-navigator-bar'>
      <PageDataNavigator elementId='lesson-activity-navigator-bar' numberOfCards={lessonPagesDataSnapshot.length} selectedCard={currentPageIndex}>
        <div
          className='lesson-page-data-container'
          id='lesson-activity-navigator-bar'
        >
          {lessonPagesDataSnapshot && lessonPagesDataSnapshot.length > 0 ? lessonPagesDataSnapshot.map((pageData, index) => (
            <Box key={`lesson-page-data-${index}`} className='lesson-page-data-card-wrapper' onMouseDown={() => { handleSelect(index); }}>
              <Box className='page-number'>
                {index + 1}
              </Box>
              <Card
                className={`lesson-page-data-card ${index === currentPageIndex ? 'lesson-page-data-card-selected' : ''} vizzle-lesson-card-thumbnail-${index}`}
                style={{ height: cardSize.height, width: cardSize.width }}
              >
                {getActivityCardThumbnailByActivityType({
                  pages: [pageData],
                })}
              </Card>
              {getSubmittedAnswerIndicator({ pageData })}
            </Box>
          )) : ''}
        </div>
      </PageDataNavigator>
    </div>
  );
};

export default LessonActivityNavigator;

LessonActivityNavigator.defaultProps = {
  onSelect: () => null,
};

LessonActivityNavigator.propTypes = {
  onSelect: PropTypes.func,
};

import BaseDomain from '../../../states/domains/BaseDomain';

const DISTRICTS_UPDATE_DISTRICTS = 'DISTRICTS_UPDATE_DISTRICTS';
const DISTRICTS_UPDATE_SELECTED_DISTRICTS = 'DISTRICTS_UPDATE_SELECTED_DISTRICTS';
const DISTRICTS_LOAD_DISTRICTS = 'DISTRICTS_LOAD_DISTRICTS';

const initialState = {
  districts: [],
  selectedDistrictIds: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case DISTRICTS_UPDATE_DISTRICTS:
      return {
        ...state,
        districts: action.payload.districts,
        loading: action.payload.isLoading,
      };
    case DISTRICTS_UPDATE_SELECTED_DISTRICTS:
      return {
        ...state,
        selectedDistrictIds: action.payload,
      };
    case DISTRICTS_LOAD_DISTRICTS:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'districtsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateDistricts(districts, isLoading = false) {
    return this.dispatch({
      type: DISTRICTS_UPDATE_DISTRICTS,
      payload: {
        districts,
        isLoading,
      },
    });
  }

  async updateSelectedDistricts(districtIds) {
    return this.dispatch({
      type: DISTRICTS_UPDATE_SELECTED_DISTRICTS,
      payload: districtIds,
    });
  }

  async updateLoadDistrictsIsLoading(isLoading) {
    return this.dispatch({
      type: DISTRICTS_LOAD_DISTRICTS,
      payload: isLoading,
    });
  }
}

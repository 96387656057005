import {
  LibraryBooks as LibraryBooksIcon,
  ImportContacts as ImportContactsIcon,
  Sort as SortIcon,
  ListAlt as ListAltIcon,
} from '@mui/icons-material';
import CurriculumLessons from '../../common/components/lessons/CurriculumLessons';
import GuidePdf from '../components/tabs/GuidePdf';
import GuideScopeAndSequence from '../components/tabs/GuideScopeAndSequence';
import LessonPlans from '../components/tabs/LessonPlans';

export const getTexasTabs = () => [
  {
    stepLabel: 'LESSONS ALIGNED TO SECTION',
    component: <CurriculumLessons instructionMessage='The second drop-down filters this Section&apos;s activities by Essence Statements/Assessment Areas.' />,
    icon: <LibraryBooksIcon />,
  },
  {
    stepLabel: 'SECTION GUIDE',
    component: <GuidePdf loadingMessage='Loading Section Guide' noPdfFoundMessage='No Section Guide available' />,
    icon: <ImportContactsIcon />,
  },
  {
    stepLabel: 'SCOPE AND SEQUENCE GUIDE',
    component: <GuideScopeAndSequence loadingMessage='Loading Scope and Sequence Guide' />,
    icon: <SortIcon />,
  },
];

export const getFloridaTabs = () => [
  {
    stepLabel: 'LESSONS ALIGNED TO SECTION',
    component: <CurriculumLessons instructionMessage='The second drop-down filters this Section&apos;s activities by Instructional Family.' />,
    icon: <LibraryBooksIcon />,
  },
  {
    stepLabel: 'INSTRUCTIONAL FRAMEWORKS',
    component: <GuidePdf loadingMessage='Loading Instructional Frameworks' noPdfFoundMessage='No Instructional Frameworks available' />,
    icon: <ImportContactsIcon />,
  },
  {
    stepLabel: 'LESSONS PLANS',
    component: <LessonPlans />,
    icon: <ListAltIcon />,
  },
];

import { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import { makeStyles } from '@mui/styles';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper,
} from '@mui/material';
import FillInTheBlankDialogEditorWithTextStyle from './fitb/FillInTheBlankDialogEditorWithTextStyle';
import TextLines from '../../../../../../components/vizzle/text/TextLines';
import { BLANK_SPACE } from '../../../../../../components/vizzle/page/fitb/FillInTheBlankUtils';

// const useStyles = makeStyles(() => ({
//   paper: {
//     height: '100%',
//     maxHeight: 'calc(100% - 10px);',
//     width: '1000px',
//     maxWidth: 'unset',
//   },
// }));

export default function AddFillInTheBlank({
  fitbList,
  onFitbAdd,
  onFitbUpdate,
  onFitbRemoved,
}) {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    data: undefined,
    isDirty: false,
    edittedRow: -1,
  });

  const handleOnDataChange = (newData) => {
    setState({
      ...state,
      data: newData,
      isDirty: true,
    });
  };

  return (
    <>
      <Box height='98%' width='100%' display='flex' flexDirection='column'>
        <Button
          variant='contained'
          fullWidth
          className='btn-primary vizzle-edit-pop-up-button'
          onClick={() => {
            setState({
              data: undefined,
              isDirty: false,
              edittedRow: -1,
            });
            setOpen(true);
          }}
        >
          Add new Fill in The Blank
        </Button>

        {fitbList && fitbList.length > 0 ? (
          <Box display='flex' mt='10px' flexDirection='column' overflow='auto'>
            {fitbList.map((f, index) => (
              <Box key={`fillin-the-blank-entry-${index}`} display='flex' mb='10px' alignItems='center'>
                <Paper style={{ flexGrow: 1, padding: '10px', marginRight: '5px', overflowX: 'auto' }}>
                  {f.question && f.question && (
                    <Box display='flex' alignItems='baseline'>
                      <TextLines textLines={f.question.preBlank} />
                      {BLANK_SPACE}
                      <TextLines textLines={f.question.postBlank} />
                    </Box>
                  )}
                </Paper>
                <Button
                  variant='contained'
                  className='btn-primary margin-right-small'
                  onClick={() => {
                    setState({
                      data: f,
                      isDirty: false,
                      edittedRow: index,
                    });
                    setOpen(true);
                  }}
                >
                  <EditIcon />
                </Button>
                <Button
                  variant='contained'
                  className='btn-danger'
                  onClick={() => {
                    onFitbRemoved(index);
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
              </Box>
            ))}
          </Box>
        ) : (
          <Box display='flex' width='100%' height='100%' textAlign='center' flexDirection='column' alignItems='center' justifyContent='center'>
            No Fill in the Blank.
            <br />
            Please Click add to add a new Question
          </Box>
        )}

      </Box>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        PaperProps={{
          sx: {
            height: '100%',
            maxHeight: 'calc(100% - 10px);',
            width: '1000px',
            maxWidth: 'unset',
          },
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>Fill in the Blank</DialogTitle>
        <DialogContent style={{ height: 'inherit' }}>
          <FillInTheBlankDialogEditorWithTextStyle data={state.data} onDataChange={handleOnDataChange} />
        </DialogContent>
        <DialogActions>
          <Button
            className='btn-primary'
            onClick={() => {
              if (state.edittedRow >= 0) {
                onFitbUpdate(state.data, state.edittedRow);
              } else {
                onFitbAdd(state.data);
              }

              setOpen(false);
            }}
            disabled={!state.isDirty}
          >
            Save
          </Button>
          <Button className='btn-danger' onClick={() => { setOpen(false); }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddFillInTheBlank.defaultProps = {
  fitbList: [],
  onFitbAdd: () => null,
  onFitbUpdate: () => null,
  onFitbRemoved: () => null,
};

AddFillInTheBlank.propTypes = {
  fitbList: PropTypes.arrayOf(PropTypes.shape({
    answers: PropTypes.array,
    question: PropTypes.shape({
      preBlank: PropTypes.any,
      postBlank: PropTypes.any,
    }),
  })),
  onFitbAdd: PropTypes.func,
  onFitbUpdate: PropTypes.func,
  onFitbRemoved: PropTypes.func,
};

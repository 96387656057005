import BlankActivityCard from '../../../components/vizzle/page/BlankActivityCard';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../../AppConstants';
import BookCardActivity from '../activities/book/BookCardActivity';
import MatchingActivity from '../activities/matching/MatchingActivity';
import SortingActivity from '../activities/sorting/SortingActivity';
import GamePlayActivity from '../activities/game/GamePlayActivity';
import SequencingActivity from '../activities/sequencing/SequencingActivity';
import CauseAndEffectActivity from '../activities/causeandeffect/CauseAndEffectActivity';
import FillInTheBlankPageActivity from '../activities/fitb/FillInTheBlankPageActivity';
import CategorizingActivity from '../activities/categorizing/CategorizingActivity';
import FlexibleMatchingActivity from '../activities/flexible-matching/FlexibleMatchingActivity';
import MultipleChoiceActivity from '../activities/multiple-choice/MultipleChoiceActivity';
import MultipleChoiceActivityV2 from '../activities/multiple-choice-v2/MultipleChoiceActivityV2';

export const getActivityCardPlay = ({
  page,
  isCurrentPage,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onInteractionOpen,
  onInstructionFinished,
  setLessonPageElementRef,
  index,
  onTriggerSwitchScan,
  isPreview,
  registeredLessonRef,
  isPlayable,
  studentHistoryId,
  freeformAnswerData,
}) => {
  if (page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA || page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
    let freeformAnswerDataComment = null;
    if (freeformAnswerData && freeformAnswerData.length > 0) {
      freeformAnswerDataComment = freeformAnswerData.find((ffad) => ffad.attributes.pageNumber === index + 1);
    }

    return (
      <BookCardActivity
        layoutConfig={page.layoutConfig}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onInteractionOpen={onInteractionOpen}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        registeredLessonRef={registeredLessonRef}
        isPlayable={isPlayable}
        isCurrentPage={isCurrentPage}
        studentHistoryId={studentHistoryId}
        onActivityFinished={onActivityFinished}
        freeformAnswerDataComment={freeformAnswerDataComment}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
    return (
      <MatchingActivity
        promptCards={page.promptCards}
        responseCards={page.responseCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        pageIndex={index}
        ref={(r) => { setLessonPageElementRef(r, index); }}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
    return (
      <SortingActivity
        instructions={page.instructions}
        promptCards={page.promptCards}
        responseCards={page.responseCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        onInstructionFinished={onInstructionFinished}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        pageIndex={index}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
    return (
      <GamePlayActivity
        data={page}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        onInteractionOpen={onInteractionOpen}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        isPreview={isPreview}
        pageIndex={index}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
    return (
      <SequencingActivity
        instructions={page.instructions}
        sequenceCards={page.sequenceCards}
        responseCards={page.responseCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        onInstructionFinished={onInstructionFinished}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        pageIndex={index}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY) {
    return (
      <CauseAndEffectActivity
        causeAndEffectCards={page.causeAndEffectCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        pageIndex={index}
      />
    );
  }

  if ([ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE, ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING].includes(page.activityType)) {
    return (
      <FillInTheBlankPageActivity
        cardConfig={page.cardConfig}
      />
    );
  }
  if (page.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY) {
    return (
      <CategorizingActivity
        instructions={page.instructions}
        promptCards={page.promptCards}
        responseCards={page.responseCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        pageIndex={index}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
    return (
      <FlexibleMatchingActivity
        questionKey={page.key}
        promptText={page.promptText}
        layout={page.layout}
        promptCards={page.promptCards}
        subText={page.subText}
        responseCards={page.responseCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        submitAnswerText={page.submitAnswerText}
        pageIndex={index}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
    return (
      <MultipleChoiceActivity
        questionCard={page.questionCard}
        answerCards={page.answerCards}
        singlePage={page.singlePage}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        pageIndex={index}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
      />
    );
  }

  if (page.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
    return (
      <MultipleChoiceActivityV2
        questionCard={page.questionCard}
        answerCards={page.answerCards}
        onCorrectAnswerSelected={onCorrectAnswerSelected}
        onIncorrectAnswerSelected={onIncorrectAnswerSelected}
        onActivityFinished={onActivityFinished}
        isCurrentPage={isCurrentPage}
        pageIndex={index}
        ref={(r) => { setLessonPageElementRef(r, index); }}
        onTriggerSwitchScan={onTriggerSwitchScan}
        promptText={page.promptText}
        subText={page.subText}
        singlePage={page.singlePage}
      />
    );
  }
  return <BlankActivityCard />;
};

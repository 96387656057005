import { useDomain } from '../../../../states/AppDomainProvider';

export const useUpdateLessonStartIndex = () => {
  const { userDomain } = useDomain();

  const nextAvailableStartIndex = (lessons) => {
    const index = lessons.findIndex((l) => (((l.feedbackLoopValue || l.singlePlay || l.preAssessment) && !l.played) || (!l.feedbackLoopValue && !l.singlePlay && !l.preAssessment)));
    return index;
  };

  const updateLessonStartIndex = async (isLessonComplete, lessonUuid, userProfile) => {
    if (isLessonComplete && userProfile && userProfile.subjects) {
      const { subjects } = userProfile;
      const playedSubjectId = subjects.findIndex((subject) => {
        const { lessons } = subject;
        return lessons.findIndex((lesson) => lesson.uuid === lessonUuid) >= 0;
      });
      if (playedSubjectId >= 0 && subjects[playedSubjectId].startIndex >= 0) {
        let startIndexToUse = subjects[playedSubjectId].startIndex;
        startIndexToUse++;
        const subject = subjects[playedSubjectId];
        const playedLesson = subjects[playedSubjectId].lessons[startIndexToUse];

        if (startIndexToUse >= subject.lessons.length || playedLesson.feedbackLoopValue || playedLesson.singlePlay) {
          // eslint-disable-next-line max-len
          const index = nextAvailableStartIndex(subject.lessons.slice(startIndexToUse));
          if (index < 0) {
            const indexFromBeginning = nextAvailableStartIndex(subject.lessons);
            startIndexToUse = Math.max(0, indexFromBeginning);
          } else {
            startIndexToUse = index + startIndexToUse;
          }
        }
        // it is completed when there is no next available lesson to play
        subjects[playedSubjectId].completed = nextAvailableStartIndex(subject.lessons.slice(subjects[playedSubjectId].startIndex + 1)) === -1;
        subjects[playedSubjectId].startIndex = startIndexToUse;
        await userDomain.updateUserProfile(userProfile);
      }
    }
  };

  return updateLessonStartIndex;
};

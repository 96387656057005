/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Fade,
} from '@mui/material';
import arrow from '../../../../../static/images/activities/game/arrow.png';
import spinnerArrow from '../../../../../static/images/activities/game/spinner.png';
import spinnerSound from '../../../../../static/sounds/activities/game/spinner.mp3';
import outlineTopRight from '../../../../../static/images/activities/game/outline_top_right.png';
import outlineTopLeft from '../../../../../static/images/activities/game/outline_top_left.png';
import outlineBottomRight from '../../../../../static/images/activities/game/outline_bottom_right.png';
import outlineBottomLeft from '../../../../../static/images/activities/game/outline_bottom_left.png';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import { playAudio } from '../../../media/AudioPlayer';
import GameActivityUtils from '../GameActivityUtils';
import { SPINNER_BOARD_NUMBER } from '../GameConstants';
import './WheelSpinner.scss';

const MAX_SPIN = 4;
const ROTATE_ROUND = 3;
const OUTLINES = [
  outlineTopRight,
  outlineBottomRight,
  outlineBottomLeft,
  outlineTopLeft,
];

const WheelSpinner = forwardRef(({
  playerState,
  spinner,
  numberOfgameCards,
  onSpinnerStart,
  onSpinnerEnd,
  showSpinner,
}, ref) => {
  const [rotateTo, setRotateTo] = useState(0);

  const spinnerArrowRefEl = useRef(null);
  const currentPlayer = playerState.players[playerState.currentPlayer] || {};
  useEffect(() => {
    if (!showSpinner) {
      setRotateTo(0);
    }
    // eslint-disable-next-line
  }, [showSpinner]);

  const handleOnClickPlayerTurn = async (e) => {
    e.stopPropagation();
    onSpinnerStart();

    const promise = playAudio(spinnerSound);
    const spinTo = Math.floor(Math.random() * MAX_SPIN) + 1;
    const numberOfMoves = currentPlayer.currentCardIndex + spinTo > numberOfgameCards - 1 ? numberOfgameCards - currentPlayer.currentCardIndex - 1 : spinTo;
    ObjectUtils.setTimeout(() => {
      spinnerArrowRefEl.current.classList.add('rotate-full');
      spinnerArrowRefEl.current.style.transform = `rotate(${(ROTATE_ROUND * 360) + (((numberOfMoves - 1) * 90) + 45)}deg)`;
    }, 0);
    ObjectUtils.setTimeout(() => {
      setRotateTo(numberOfMoves);
    }, 1500);
    try {
      await promise;
      onSpinnerEnd(numberOfMoves);
    } catch (ex) {
      // ignore
      onSpinnerEnd(numberOfMoves);
    }
  };

  const componentRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => (componentRef.current ? [componentRef.current] : []),
  }));

  const spinnerWheel = () => (
    <Fade timeout={1000} in>
      <Box className='spinning-board' data-test='spinning-board'>
        <img
          src={GameActivityUtils.getSpinnerBoard(spinner)}
          alt='Spinning Board'
        />
        <img
          className={`spinner-img rotate-${rotateTo}`}
          src={spinnerArrow}
          alt='Spinning Arrow'
          ref={spinnerArrowRefEl}
        />
        {
          rotateTo > 0 ? (
            <img
              className='board-outline'
              src={OUTLINES[rotateTo - 1]}
              alt='Spinning Board Outline'
            />
          ) : ''
        }
      </Box>
    </Fade>
  );

  const playerTurn = () => (
    <Fade timeout={1000} in>
      <Box
        className='player-turn'
        onClick={handleOnClickPlayerTurn}
        ref={componentRef}
        data-test='player-turn'
      >
        <div className='arrow-icon-container' data-test='arrow-icon-container'>
          <img
            className='arrow-icon'
            src={arrow}
            alt='Arrow'
          />
        </div>
        <div className='player-turn-icon' data-test='player-turn-icon'>
          <img
            src={currentPlayer.playerPieceImage}
            alt='Piece'
            style={{ maxHeight: `${numberOfgameCards <= 10 ? '4em' : ''}` }}
          />
        </div>
        <div
          className='player-turn-text'
          style={{ fontSize: `${numberOfgameCards <= 10 ? '1.2em' : '1.5em'}` }}
          data-test='player-turn-text'
        >
          It's your turn {currentPlayer.name}, click here to spin the wheel
        </div>

        <Box className='spinner-background'>
          {spinnerWheel()}
        </Box>
      </Box>
    </Fade>
  );

  return (
    <div className='wheel-spinner'>
      {
        showSpinner || !currentPlayer.playerPieceImage ? spinnerWheel() : playerTurn()
      }
    </div>
  );
});

WheelSpinner.defaultProps = {
  playerState: {},
  spinner: SPINNER_BOARD_NUMBER,
  numberOfgameCards: 20,
  onSpinnerStart: () => { },
  onSpinnerEnd: () => { },
  showSpinner: false,
};

WheelSpinner.propTypes = {
  playerState: PropTypes.shape({
    currentPlayer: PropTypes.number,
    players: PropTypes.array,
  }),
  spinner: PropTypes.number,
  numberOfgameCards: PropTypes.number,
  onSpinnerStart: PropTypes.func,
  onSpinnerEnd: PropTypes.func,
  showSpinner: PropTypes.bool,
};

export default WheelSpinner;

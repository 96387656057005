import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import TextToSpeech from '../../../settings/TextToSpeech';

const TextToSpeechSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Text to speech');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const onChangeCallback = (newValue) => {
    updateProfileSettingsOnChange({ tts: newValue });
  };

  return (
    <TextToSpeech
      profile={userProfile}
      onChangeCallback={(newValue) => onChangeCallback(newValue)}
    />
  );
};

export default TextToSpeechSettings;

import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextLines from '../../../text/TextLines';
import './InstructionDialog.scss';

export default function InstructionDialog({
  instructions,
  open,
  onClose,
  className,
}) {
  return open ? (
    <Dialog open={open} className={`instruction-dialog ${instructions.audio ? 'instruction-dialog-with-audio' : ''} ${className}`}>
      <IconButton
        aria-label='close instructions'
        className='close-button'
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className='content'>
        {
          instructions.text ? <TextLines textLines={instructions.text} /> : ''
        }
      </DialogContent>
    </Dialog>
  ) : null;
}

InstructionDialog.defaultProps = {
  instructions: {},
  open: false,
  onClose: () => {},
  className: '',
};

InstructionDialog.propTypes = {
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

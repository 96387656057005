import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
} from '@mui/material';
import LessonPlay from '../../../lessonplay/LessonPlay';
import { useTrackTeacherActivityPreview } from '../hooks/TeacherTrackingActivityHooks';

const LessonPreviewMenuItem = forwardRef(({ onClick, lesson, user, userProfile, terminologies, onLessonFinished }, ref) => {
  const [open, setOpen] = useState(false);

  useTrackTeacherActivityPreview(open, user, lesson);

  const handleOnClick = () => {
    onClick();
    setOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleOnClick} ref={ref}>Preview</MenuItem>
      {open && (
        <LessonPlay
          open={open}
          handleClose={() => { setOpen(false); }}
          lesson={lesson}
          isPreviewMode
          user={user}
          userProfile={userProfile}
          terminologies={terminologies}
          onLessonFinished={onLessonFinished}
        />
      )}
    </>
  );
});

LessonPreviewMenuItem.defaultProps = {
  lesson: {},
  onClick: () => { },
  user: null,
  userProfile: null,
  terminologies: {},
  onLessonFinished: () => { },
};
LessonPreviewMenuItem.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }),
  onClick: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object,
  onLessonFinished: PropTypes.func,
};

export default LessonPreviewMenuItem;

import { Fab, Tooltip } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import './BackToPreviousLink.scss';
import { useState } from 'react';

const BackToPreviousLink = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [goBack] = useState(() => {
    const { goBackTo } = location.state || location;
    return goBackTo;
  });

  if (!goBack) {
    return null;
  }
  return (
    <div className='back-previous-link'>
      <Tooltip title={goBack.label}>
        <Fab
          className='back-fab'
          aria-label={goBack.label}
          onClick={() => {
            navigate(
              goBack.pathname,
              {
                state: {
                  ...goBack.param,
                },
              },
            );
          }}
        >
          <KeyboardBackspaceIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default BackToPreviousLink;

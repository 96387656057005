import ObjectUtils from '../../../utils/ObjectUtils';
import { dataURLtoFile } from './VideoToImage';

const MAX_WIDTH = 960;
const MAX_HEIGHT = 540;

export const resizeImage = (imageFile) => new Promise((resolve) => {
  const { type: mimeType } = imageFile;
  const fileReader = new FileReader();
  fileReader.readAsDataURL(imageFile);
  fileReader.onload = async (fileReaderEvent) => {
    const imageAsBase64 = fileReaderEvent.target.result;
    const image = document.createElement('img');
    image.src = imageAsBase64;
    await ObjectUtils.delay(100);

    if (image.width <= MAX_WIDTH && image.height <= MAX_HEIGHT) {
      resolve(imageFile);
    }

    const diffHeight = image.height / MAX_HEIGHT;
    const diffWidth = image.width / MAX_WIDTH;

    const canvas = document.createElement('canvas');
    canvas.width = Math.min(MAX_WIDTH, image.width);
    canvas.height = Math.min(MAX_HEIGHT, image.height);
    if (diffHeight > 1 && diffHeight > diffWidth) {
      canvas.height = MAX_HEIGHT;
    } else if (diffWidth > 1) {
      canvas.width = MAX_WIDTH;
    }

    // adjust the image ratio
    if (image.height > image.width) {
      // eslint-disable-next-line operator-assignment
      canvas.width = (image.width / image.height) * canvas.height;
    } else if (image.height < image.width) {
      // eslint-disable-next-line operator-assignment
      canvas.height = (image.height / image.width) * canvas.width;
    } else if (image.width > MAX_WIDTH && image.height > MAX_HEIGHT && image.height === image.width) {
      canvas.height = MAX_HEIGHT;
      canvas.width = MAX_HEIGHT;
    }

    const context = canvas.getContext('2d', { alpha: false });
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    const resizedImageAsBase64 = canvas.toDataURL(mimeType);
    const newFile = dataURLtoFile(resizedImageAsBase64, imageFile.name);
    resolve(newFile);
  };
});

import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { standardLessonLevels } from '../../services/TeacherCurriculumDropdownValues';
import {
  useHandleLessonLevelDropdownValues,
  useHandleSearchButton,
  useHandleMatchingAssessmentAreaDropdownValues,
  useHandleVerticalAssessmentAreaDropdownValues,
  useGetMatchingAssessmentAreaLabel,
} from './CurriculumLessonsFilterBarHooks';
import './CurriculumLessonsFilterBar.scss';

const CurriculumLessonsFilterBar = ({
  instructionMessage,
}) => {
  const {
    standardLessonLevel,
    handleOnStandardLessonLevelChange,
  } = useHandleLessonLevelDropdownValues();

  const {
    selectedMatchingAssessmentArea,
    matchingAssessmentAreaStandards,
    handleOnMatchingAssessmentAreaChange,
  } = useHandleMatchingAssessmentAreaDropdownValues();

  const {
    selectedVerticalAssessmentArea,
    verticalAssessmentAreaStandards,
    handleOnVerticalAssessmentAreaChange,
  } = useHandleVerticalAssessmentAreaDropdownValues(selectedMatchingAssessmentArea);
  const {
    handleOnSearch,
  } = useHandleSearchButton(standardLessonLevel, selectedMatchingAssessmentArea, selectedVerticalAssessmentArea);

  const matchingAssessmentAreaLabel = useGetMatchingAssessmentAreaLabel();

  return (
    <div className='curriculum-lessons-search-bar'>
      <div className='search-area'>
        <TextField
          variant='outlined'
          className='level-search'
          value={standardLessonLevel || ''}
          onChange={handleOnStandardLessonLevelChange}
          select
          SelectProps={{ renderValue: (value) => (value && value.name ? value.name : 'Activity Level'), displayEmpty: true }}
        >
          {standardLessonLevels.map((c) => (
            <MenuItem key={c.key} value={c}>{c.name}</MenuItem>
          ))}
        </TextField>

        <TextField
          variant='outlined'
          className='matching-assessment-area-search'
          value={selectedMatchingAssessmentArea || ''}
          onChange={handleOnMatchingAssessmentAreaChange}
          select
          SelectProps={{ renderValue: (value) => (value && value.name ? value.name : matchingAssessmentAreaLabel), displayEmpty: true }}
          disabled={!matchingAssessmentAreaStandards || matchingAssessmentAreaStandards.length === 0}
        >
          {matchingAssessmentAreaStandards.map((matchingAssessmentArea) => (
            <MenuItem key={matchingAssessmentArea.key} value={matchingAssessmentArea}>
              {matchingAssessmentArea.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant='outlined'
          className='vertical-assessment-area-search'
          value={selectedVerticalAssessmentArea || ''}
          onChange={handleOnVerticalAssessmentAreaChange}
          select
          disabled={!selectedMatchingAssessmentArea}
          SelectProps={{ renderValue: (value) => (value && value.name ? value.name : 'Vertically Aligned Lessons'), displayEmpty: true }}
        >
          {verticalAssessmentAreaStandards.map((c) => (
            <MenuItem key={c.key} value={c}>{c.name}</MenuItem>
          ))}
        </TextField>

        <Button
          variant='contained'
          className='btn-primary search-button'
          onClick={handleOnSearch}
          startIcon={<FilterListIcon />}
          disabled={!standardLessonLevel && !selectedMatchingAssessmentArea}
        >
          Filter
        </Button>
      </div>
      <div className='instructions'>
        {instructionMessage}
        <br />
        The third drop-down finds additional vertically aligned lessons.
      </div>
    </div>
  );
};

CurriculumLessonsFilterBar.defaultProps = {
  instructionMessage: '',
};

CurriculumLessonsFilterBar.propTypes = {
  instructionMessage: PropTypes.string,
};

export default CurriculumLessonsFilterBar;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import './KeywordsEditor.scss';

export default function KeywordsEditor({
  keywords,
  onKeywordsUpdate,
  totalKeywordLimit,
  className,
  disabled,
}) {
  const shouldDisabled = totalKeywordLimit > 0 && keywords.join('').length >= totalKeywordLimit;

  const [keyworkText, setKeyworkText] = useState('');

  const handleOnKeywordTextChange = (e) => {
    e.stopPropagation();

    const currentKeywords = keywords.join('');
    const keywordsToUpdate = `${currentKeywords}${e.target.value}`;
    if (totalKeywordLimit > 0 && keywordsToUpdate.length >= totalKeywordLimit) {
      const remaining = totalKeywordLimit - currentKeywords.length;
      setKeyworkText(e.target.value.substring(0, remaining));
    } else {
      setKeyworkText(e.target.value);
    }
  };

  const handleOnKeywordAdd = (e) => {
    e.preventDefault();
    if (!keywords.includes(keyworkText)) {
      keywords.push(keyworkText);
      onKeywordsUpdate(keywords);
    }
    setKeyworkText('');
  };

  const handleOnKeywordDelete = (chipToDelete) => () => {
    const updatedKeyword = keywords.filter((k) => k !== chipToDelete);
    onKeywordsUpdate(updatedKeyword);
  };

  return (
    <div className={`keywords-editor ${className}`}>
      <form onSubmit={handleOnKeywordAdd} className='add-keyword'>
        <Typography variant='h5'>Keywords</Typography>
        <Box className='text-field'>
          <TextField
            fullWidth
            value={keyworkText}
            onChange={handleOnKeywordTextChange}
            disabled={shouldDisabled || disabled}
            placeholder={totalKeywordLimit > 0 ? `${totalKeywordLimit} characters total for keywords` : ''}
          />
        </Box>
        <Button
          color='primary'
          type='submit'
          className='btn-primary'
          disabled={!keyworkText || shouldDisabled || disabled}
        >
          ADD
        </Button>
      </form>

      <div className='keywords'>
        {keywords.map((data, index) => {
          let icon;
          return (
            <Chip
              className='chip'
              key={index}
              icon={icon}
              label={data}
              onDelete={handleOnKeywordDelete(data)}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
}

KeywordsEditor.defaultProps = {
  keywords: [],
  onKeywordsUpdate: () => null,
  totalKeywordLimit: null,
  className: '',
  disabled: false,
};

KeywordsEditor.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  onKeywordsUpdate: PropTypes.func,
  totalKeywordLimit: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

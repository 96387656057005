import { useEffect } from 'react';
import { trackTeacherActivity } from '../../../services/ActivityTrackingService';
import Logger from '../../../utils/Logger';
import { useTeacherCourses } from '../context/TeacherCourseContextProvider';

export const useTrackTeacherCourseActivity = (activity) => {
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria, hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;

  const {
    topicId,
  } = searchCriteria;

  useEffect(() => {
    if (hasPerformSearch && topicId && activity) {
      try {
        trackTeacherActivity({
          activity,
          topicId,
        });
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);
};

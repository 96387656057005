import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { initDomains } from './context/GroupPlayContextProvider';
import ExternalLinks from './external-links/ExternalLinks';
import LessonsGroupPlay from './lessons-group-play/LessonsGroupPlay';
import { useGetExternalLinks } from './GroupPlayHooks';
import './GroupPlay.scss';

const GroupPlay = () => {
  initDomains();
  const { loading } = useGetExternalLinks();
  if (loading) {
    return null;
  }
  return (
    <div className='group-play'>
      <Accordion defaultExpanded data-test='external-links-section'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>
            <strong>External Links</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExternalLinks />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded data-test='group-play-lessons-section'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>
            <strong>Activities</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LessonsGroupPlay />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GroupPlay;

import BaseDomain from '../../../../states/domains/BaseDomain';

const TEACHER_CURRICULUM_GUIDES_AND_LESSON_IS_LOADING = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_IS_LOADING';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSONS_DATA = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSONS_DATA';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_SELECTED_LESSON_IDS = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_SELECTED_LESSON_IDS';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSON_COURSES = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSON_COURSES';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_DATA = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_DATA';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_GROUP_EXPANDED = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_GROUP_EXPANDED';
const TEACHER_CURRICULUM_GUIDES_AND_LESSON_UPDATE_SEARCH_CRITERIA = 'TEACHER_CURRICULUM_GUIDES_AND_LESSON_UPDATE_SEARCH_CRITERIA';

const initialState = {
  isLoading: false,
  hasPerformSearched: false,
  guidePdfLink: null,
  searchCriteria: {},
  selectedLessonIds: [],
  lessonCourses: null,
  guideLessonData: {
    lessons: [],
    included: [],
  },
  groupsExpanded: {
    unitGuide: false,
    guideLessons: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSONS_DATA:
      return {
        ...state,
        searchCriteria: action.payload.searchCriteria,
        hasPerformSearched: action.payload.hasPerformSearched,
        isLoading: action.payload.isLoading,
        ...action.payload.searchResult,
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_DATA:
      return {
        ...state,
        guideLessonData: {
          ...state.guideLessonData,
          lessons: action.payload.lessons,
          included: action.payload.included,
        },
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_SELECTED_LESSON_IDS:
      return {
        ...state,
        selectedLessonIds: action.payload,
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSON_COURSES:
      return {
        ...state,
        lessonCourses: action.payload,
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_GROUP_EXPANDED:
      return {
        ...state,
        groupsExpanded: action.payload,
      };
    case TEACHER_CURRICULUM_GUIDES_AND_LESSON_UPDATE_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const domainName = 'teacherCurriculumLessonsAndGuidesDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateIsLoading(isLoading) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_IS_LOADING,
      payload: isLoading,
    });
  }

  /**
   * Update teacher curriculum data
   *
   * @returns Promise
   */
  async updateGuidesAndLessonsData(
    searchCriteria,
    hasPerformSearched,
    isLoading,
    {
      guidePdfLink,
      selectedLessonIds,
      guideLessonData,
    },
  ) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSONS_DATA,
      payload: {
        hasPerformSearched,
        searchCriteria,
        isLoading,
        searchResult: {
          guidePdfLink,
          selectedLessonIds,
          guideLessonData,
        },
      },
    });
  }

  async updateLessonData(lessons, included) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_DATA,
      payload: {
        lessons,
        included,
      },
    });
  }

  async updateSelectedLessonIds(selectedLessonIds) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_SELECTED_LESSON_IDS,
      payload: selectedLessonIds,
    });
  }

  async updateLessonCourses(courseData) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_LESSON_COURSES,
      payload: courseData,
    });
  }

  async updateGroupsExpanded(groupExpanded) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_GROUP_EXPANDED,
      payload: groupExpanded,
    });
  }

  async updateSearchCriteria(searchCriteria) {
    return this.dispatch({
      type: TEACHER_CURRICULUM_GUIDES_AND_LESSON_UPDATE_SEARCH_CRITERIA,
      payload: searchCriteria,
    });
  }
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import ResetPasswordConfirmDialog from './dialogs/ResetPasswordConfirmDialog';
import ResetPasswordFormDialog from './dialogs/ResetPasswordFormDialog';

export default function ResetPasswordAction({ onSaveNewPassword }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showResetFormDialog, setShowResetFormDialog] = useState(false);

  return (
    <>
      <ButtonWithSpinner label='Reset Password' className='btn-secondary' onClick={() => { setShowConfirmDialog(true); }} />
      <ResetPasswordConfirmDialog
        open={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
        onNext={() => {
          setShowConfirmDialog(false);
          setShowResetFormDialog(true);
        }}
      />
      {showResetFormDialog && (
        <ResetPasswordFormDialog
          open={showResetFormDialog}
          onClose={() => {
            setShowResetFormDialog(false);
          }}
          onConfirm={(password) => {
            setShowResetFormDialog(false);
            onSaveNewPassword(password);
          }}
        />
      )}
    </>
  );
}

ResetPasswordAction.defaultProps = {
  onSaveNewPassword: () => { },
};

ResetPasswordAction.propTypes = {
  onSaveNewPassword: PropTypes.func,
};

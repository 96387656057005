import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import {
  SECTION_IMAGE,
  SECTION_TEXT,
  SECTION_AUDIO,
  AREA_RESPONSE_CARDS,
  AREA_PROMPT_CARDS,
  AREA_SUB_TEXT,
  AREA_PROMPT_TEXT,
} from '../../../../../components/vizzle/page/common/CardEditorConstants';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useHandleMatchingQuestionKey } from '../../hooks/LessonMaintenanceHooks';
import {
  checkHasNon18FontSize,
  checkHasNonBoldFontWeight,
  checkHasNonJosefinSlab,
  checkHasNonSpecificFontSize,
  getExceedCharLimitMessage,
  getTotalCharsLength,
  useHandleTextEditorWarning,
} from '../../hooks/TextEditorWarningHooks';
import MultipleChoiceV2Editor from '../../../../../components/vizzle/page/multiple-choice-v2/MultipleChoiceV2Editor';

const initState = {
  selectedArea: AREA_PROMPT_CARDS,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
};

/**
 * Container for adding text, image, video and audio for Categorizing page
 */
export default function MultipleChoiceV2TextAndMedia() {
  const { lessonDataMaintenanceDomain, uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  // eslint-disable-next-line no-unused-vars
  const selectedPage = pages[currentSelectedPagesData];

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  useEffect(() => {
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedPagesData]);

  useEffect(() => {
    if (!selectedPage.questionCard.questionKey) {
      uiDomain.blockScreen(true);
    } else {
      uiDomain.blockScreen(false);
    }
    return () => {
      uiDomain.blockScreen(false);
    };
    // eslint-disable-next-line
  }, [selectedPage.questionCard.questionKey]);

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  // eslint-disable-next-line no-unused-vars
  const onAreaAndSectionSelected = (selectedArea, selectedSection, selectedCardIndex = 0) => {
    setCurrentSelectedData({
      selectedArea,
      selectedSection,
      selectedCardIndex,
    });
  };

  const handleMatchingQuestionKey = useHandleMatchingQuestionKey();
  const answerCards = selectedPage.answerCards.filter((card) => !card.hide);

  // eslint-disable-next-line no-unused-vars
  const handleOnQuestionKeyChange = async (questionCardKey) => {
    let hasChange = false;

    if (questionCardKey !== (selectedPage.questionCard.questionKey || '')) {
      hasChange = true;
    }

    if (!hasChange) {
      return;
    }

    selectedPage.questionCard.questionKey = questionCardKey;

    handleMatchingQuestionKey(selectedPage.questionCard.questionKey, 'answerKey', 'answerCards');
  };

  // eslint-disable-next-line no-unused-vars
  const onAnswerKeyChange = async (answerKeys) => {
    answerKeys.forEach((key, index) => {
      selectedPage.answerCards[index].answerKey = key;
    });
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const getText = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_PROMPT_TEXT:
        return selectedPage.promptText;
      case AREA_SUB_TEXT:
        return selectedPage.subText;
      case AREA_PROMPT_CARDS:
        return selectedPage.questionCard && selectedPage.questionCard.text;
      default:
        // eslint-disable-next-line max-len
        return currentSelectedData.selectedCardIndex <= selectedPage.answerCards.length - 1 ? selectedPage.answerCards[currentSelectedData.selectedCardIndex].text : null;
    }
  };

  const onTextApply = async (textLines) => {
    const newTextLines = textLines.map((e) => ({
      ...e,
    }));

    if ([AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(currentSelectedData.selectedArea)) {
      if (!selectedPage[currentSelectedData.selectedArea]) {
        selectedPage[currentSelectedData.selectedArea] = {};
      }
      selectedPage[currentSelectedData.selectedArea].lines = newTextLines;
    } else if ([AREA_PROMPT_CARDS].includes(currentSelectedData.selectedArea)) {
      if (!selectedPage.questionCard.text) {
        selectedPage.questionCard.text = {};
      }
      selectedPage.questionCard.text.lines = newTextLines;
    } else {
      const selectedCard = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
      if (!selectedCard.text) {
        selectedCard.text = {};
      }
      selectedCard.text.lines = newTextLines;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemoved = async () => {
    if ([AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(currentSelectedData.selectedArea)) {
      selectedPage[currentSelectedData.selectedArea] = null;
    } else if (AREA_PROMPT_CARDS === currentSelectedData.selectedArea) {
      selectedPage.questionCard.text = null;
    } else {
      selectedPage.answerCards[currentSelectedData.selectedCardIndex].text = null;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }

    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }

    if (currentSelectedData.selectedArea === AREA_PROMPT_TEXT) {
      const hasNon18FontSize = checkHasNon18FontSize(textLines);
      if (hasNon18FontSize) {
        closeTextEditorWarning();
        return;
      }
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 44;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_SUB_TEXT) {
      const hasNon22FontSize = checkHasNonSpecificFontSize(textLines, '22px');
      if (hasNon22FontSize) {
        closeTextEditorWarning();
        return;
      }
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 78;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      const hasNon18FontSize = checkHasNon18FontSize(textLines);
      if (hasNon18FontSize) {
        closeTextEditorWarning();
        return;
      }
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 52;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      if (answerCards.length === 4 || answerCards.length === 6) {
        const hasNon18FontSize = checkHasNon18FontSize(textLines);
        if (hasNon18FontSize) {
          closeTextEditorWarning();
          return;
        }

        const charLimit = answerCards.length === 4 ? 40 : 22;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    }
    closeTextEditorWarning();
  };

  useEffect(() => {
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      const text = selectedPage.questionCard && selectedPage.questionCard.text;
      onTextChange(text && text.lines);
    } else {
      if (!selectedPage.answerCards || selectedPage.answerCards.length === 0) {
        return;
      }
      const selectedCard = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
      const { text } = selectedCard;
      onTextChange(text && text.lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData]);

  // eslint-disable-next-line max-len
  const getImage = () => {
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      return selectedPage.questionCard && selectedPage.questionCard.image;
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      // eslint-disable-next-line max-len
      return currentSelectedData.selectedCardIndex <= selectedPage.answerCards.length - 1 ? selectedPage.answerCards[currentSelectedData.selectedCardIndex].image : null;
    } else {
      return '';
    }
  };

  const onImageAndVideoSelected = async (media) => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    } else {
      return;
    }

    cardObject.image = media.selectedImageUrl;
    cardObject.video = media.selectedVideoUrl;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const getAudio = () => {
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      return selectedPage.questionCard && selectedPage.questionCard.audio;
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      // eslint-disable-next-line max-len
      return currentSelectedData.selectedCardIndex <= selectedPage.answerCards.length - 1 ? selectedPage.answerCards[currentSelectedData.selectedCardIndex].audio : null;
    } else {
      return '';
    }
  };

  const handleOnAudioApply = async (audioData) => {
    let cardObject = null;
    if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      cardObject = selectedPage.questionCard;
    } else {
      cardObject = selectedPage.answerCards[currentSelectedData.selectedCardIndex];
    }

    cardObject.audio = audioData;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const handleOnRandomizeFieldChange = async (event) => {
    const updatedSelectedPage = {
      ...selectedPage,
      randomize: event.target.checked,
    };

    // move the correct answer to the left
    const updatedAnswerCards = [...selectedPage.answerCards];
    const correctAnswers = updatedAnswerCards.filter((a) => selectedPage.questionCard.questionKey === a.answerKey);
    const restAnswers = updatedAnswerCards.filter((a) => selectedPage.questionCard.questionKey !== a.answerKey);

    const reArrangedAnswers = [...correctAnswers, ...restAnswers].map((c, index) => {
      const newCard = { ...c };
      newCard.sequenceNum = index + 1;
      newCard.cardIndex = index;
      return newCard;
    });

    updatedSelectedPage.answerCards = reArrangedAnswers;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  const handleOnCardIndexChange = async (value) => {
    const updatedAnswerCards = [...selectedPage.answerCards];

    const card = updatedAnswerCards.find((a) => a.cardIndex === value.currentIndex);
    const cardToSwap = updatedAnswerCards.find((a) => a.cardIndex === value.newIndex);

    card.sequenceNum = value.newIndex + 1;
    card.cardIndex = value.newIndex;

    cardToSwap.sequenceNum = value.currentIndex + 1;
    cardToSwap.cardIndex = value.currentIndex;

    const updatedSelectedPage = {
      ...selectedPage,
      answerCards: JSON.parse(JSON.stringify(updatedAnswerCards)),
    };

    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <MultipleChoiceV2Editor
            selectedArea={currentSelectedData.selectedArea}
            selectedSection={currentSelectedData.selectedSection}
            selectedCardIndex={currentSelectedData.selectedCardIndex}
            onAreaAndSectionSelected={onAreaAndSectionSelected}
            questionKey={selectedPage.questionCard.questionKey}
            onQuestionKeyChange={handleOnQuestionKeyChange}
            questionCard={selectedPage.questionCard}
            answerCards={answerCards}
            onAnswerKeyChange={onAnswerKeyChange}
            promptText={selectedPage.promptText}
            subText={selectedPage.subText}
            randomize={selectedPage.randomize === null || selectedPage.randomize === undefined ? true : selectedPage.randomize}
            onRandomizeFieldChange={handleOnRandomizeFieldChange}
            onCardIndexChange={handleOnCardIndexChange}
          />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={getText()}
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemoved}
                  onTextChange={onTextChange}
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {(currentSelectedData.selectedSection === SECTION_IMAGE) && (
              <AddPhotoOrVideo
                image={getImage()}
                onSelect={onImageAndVideoSelected}
                onRemove={() => { onImageAndVideoSelected({}); }}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={getAudio()}
                onAudioApply={(audioData) => { handleOnAudioApply(audioData); }}
                onAudioRemoved={() => { handleOnAudioApply(null); }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

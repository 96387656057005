import { Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import './SimpleCelebration.scss';

export default function SimpleCelebration() {
  return (
    <div className='simple-celebration'>
      <TaskAltIcon htmlColor='white' fill='white' />
      <Typography color='white' fontWeight={500}>Activity Complete!</Typography>
    </div>
  );
}

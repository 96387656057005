/* eslint-disable no-param-reassign */
import { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import {
  TextField,
} from '@mui/material';
import CardAction from '../../../../../components/card/CardAction';
import { useDomain } from '../../../../../states/AppDomainProvider';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { updateLessonTagForStudent } from '../../../../../services/LessonTagService';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { UNAUTHORIZED } from '../../../../../AppConstants';
import Logger from '../../../../../utils/Logger';
import { sortObjectiveList } from '../../../../../utils/MasterObjectiveMapping';
import './EditGoalNameAction.scss';

/**
 * Edit goal name action container
 */
const EditGoalNameAction = ({ objective, rowIndex, moveCardIndexFunc }) => {
  const { attributes } = objective;
  const { myStudentDomain } = useStudentContext();
  const { masterObjectiveData, lessonTags } = myStudentDomain.domainData;
  const { studentsDomain, uiDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const [open, setOpen] = useState(false);
  const [objectiveName, setObjectiveName] = useState(attributes.name);
  const [saving, setSaving] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setObjectiveName(attributes.name);
    setSaving(false);
    setOpen(false);
  };

  const saveObjectiveName = async () => {
    try {
      setSaving(true);
      await updateLessonTagForStudent(currentSelectedStudentId, attributes.objectiveId, objectiveName);
      await uiDomain.showSnackbar(true, 'success', 3000, 'Goal name has been updated');
      attributes.name = objectiveName;
      attributes.updatedDate = new Date().toISOString();
      const newLessonTags = lessonTags.map((lessonTag) => {
        // eslint-disable-next-line eqeqeq
        if (lessonTag.id == attributes.objectiveId) {
          lessonTag.attributes.name = attributes.name;
        }
        return lessonTag;
      });
      handleClose();
      sortObjectiveList(masterObjectiveData.masterObjectives[rowIndex].objectives);
      if (moveCardIndexFunc) {
        moveCardIndexFunc(0);
      }
      myStudentDomain.updateMasteryObjectives([...masterObjectiveData.masterObjectives], masterObjectiveData.included);
      myStudentDomain.updateLessonTag([...newLessonTags]);
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
        setSaving(false);
      }
      Logger.logError({
        ERROR: error,
      });
    }
  };

  const content = (
    <div className='edit-goal-name-action-content'>
      <TextField
        className='input'
        onChange={(event) => { setObjectiveName(event.target.value); }}
        fullWidth
        defaultValue={objectiveName}
        inputProps={{
          maxLength: 255,
        }}
        variant='outlined'
      />
    </div>
  );

  return (
    <>
      <CardAction
        key='edit-objective-goal-name'
        className='edit-goal-name-action-edit-button'
        icon={<EditIcon />}
        actionHandler={() => {
          handleOpen();
        }}
        data-test='edit-goal-name-action'
      />
      {open ? (
        <CustomDialog
          key='share-link-custom-dialog'
          className='edit-goal-name-action'
          title='Edit G/O name'
          content={content}
          openDialog={open}
          onClose={handleClose}
          actions={[
            {
              title: 'OK',
              onClick: saveObjectiveName,
              color: 'primary',
              className: 'edit-goal-name-action-ok-button btn-primary',
              disabled: !objectiveName || saving,
              loading: saving,
            },
          ]}
        />
      ) : null}
    </>
  );
};

EditGoalNameAction.defaultProps = {
  objective: {
    attributes: {
      name: '',
    },
  },
  moveCardIndexFunc: null,
};

EditGoalNameAction.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
    relationships: PropTypes.object,
  }),
  rowIndex: PropTypes.number.isRequired,
  moveCardIndexFunc: PropTypes.func,
};
export default EditGoalNameAction;

import EditableSingleFieldForm from '../../../../../../components/form/EditableSingleFieldForm';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetTopic } from '../TeacherCourseLessonsHooks';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { updateTopic } from '../../../../../../services/CourseService';
import './EditCourseData.scss';

const EditTopicAction = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { courseId, unitId, topicId } = searchCriteria;
  const topic = useGetTopic(courseId, unitId, topicId);

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const handleSaveData = async (formData) => {
    const originalTopicName = topic.name;
    if (originalTopicName === formData) {
      return;
    }
    try {
      await callRemoteServiceWrapper(async () => {
        await updateTopic(courseId, unitId, topicId, formData);
        topic.name = formData;
        teacherCourseDomain.updateLessonCourses(lessonCourses);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `Topic ${originalTopicName} has been updated to ${topic.name}.`);
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return (
    <EditableSingleFieldForm
      formData={topic.name}
      handleSaveData={handleSaveData}
      label='Topic'
      placeholderLabel='Topic'
      className='edit-course-data-single-form'
      title='Topic'
    />
  );
};

export default EditTopicAction;

import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import './SortingResponse.scss';
import MediaPlayer from '../../../media/MediaPlayer';
import TextLines from '../../../text/TextLines';
import { getPageStyle } from '../../common/LessonPageUtil';

export default function SortingResponse({
  responseCard,
  responseCardId,
  disablePlayMedia,
  onClick,
}) {
  return (
    <Box
      id={responseCardId}
      boxShadow={2}
      className='sorting-response-card'
      onClick={onClick}
      style={getPageStyle(responseCard, true)}
    >
      {disablePlayMedia ? '' : (
        <MediaPlayer
          audioUrl={responseCard.audio}
          videoUrl={responseCard.video}
          parentElementId={responseCardId}
          className='response-media'
        />
      )}
      {responseCard.image ? <CardMedia image={responseCard.image} className='response-image' /> : ''}

      {responseCard.text ? (
        <div className='response-text'>
          <TextLines textLines={responseCard.text} />
        </div>
      ) : ''}
    </Box>
  );
}

SortingResponse.defaultProps = {
  responseCard: {},
  responseCardId: '',
  disablePlayMedia: false,
  onClick: () => {},
};

SortingResponse.propTypes = {
  responseCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  responseCardId: PropTypes.string,
  disablePlayMedia: PropTypes.bool,
  onClick: PropTypes.func,
};

export const flatten = (arr) => (
  arr.reduce((flat, toFlatten) => (
    flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
  ), [])
);

export const unique = (arr, attr) => {
  if (!arr || arr.length === 0) {
    return [];
  }

  return arr.map((e) => e[attr])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e]).map((e) => arr[e]);
};

export const uniqueByAttributes = (arr, attr) => {
  if (!arr || arr.length === 0) {
    return [];
  }

  return arr.map((e) => e.attributes[attr])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e]).map((e) => arr[e]);
};

export const sort = (arr, attr) => (
  arr.sort((a, b) => a[attr].toString().localeCompare(b[attr], undefined, { numeric: true, sensitivity: 'base' }))
);

export const sortByAttributes = (arr, attr) => (
  arr.sort((a, b) => a.attributes[attr].toString().localeCompare(b.attributes[attr], undefined, { numeric: true, sensitivity: 'base' }))
);

export const uniqueObject = (arr) => (
  [...new Map(arr.map((obj) => [JSON.stringify(obj), obj])).values()]
);

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export const shuffle = (unshuffled) => (
  unshuffled
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)
);

export const range = (min, max) => {
  const data = [];
  for (let i = min; i <= max; i++) {
    data.push(i);
  }
  return data;
};

export const isArrayEqual = (array1, array2) => (array1.length === array2.length) && array1.every((element, index) => element === array2[index]);

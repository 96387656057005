import { useEffect, useState } from 'react';
import { playAudio, stopAudio } from '../../../media/AudioPlayer';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import Logger from '../../../../../utils/Logger';

export const useOpenInstructionDialog = (instructions, isCurrentPage, cleanupFunc) => {
  const [openInstructions, setOpenInstructions] = useState({
    open: false,
    hasOpen: false,
  });

  useEffect(() => {
    const showInstruction = async () => {
      if (isCurrentPage && !openInstructions.hasOpen) {
        try {
          setOpenInstructions({
            open: true,
            hasOpen: true,
          });
          let closeDialogDelay = 3000;
          if (instructions.audio) {
            await playAudio(instructions.audio);
            closeDialogDelay = 700;
          }
          ObjectUtils.setTimeout(() => {
            setOpenInstructions({
              open: false,
              hasOpen: true,
            });
          }, closeDialogDelay);
        } catch (e) {
          Logger.logError(e);
        }
      }
    };

    // Got an error on iOS when putting showInstuction method inside a delay method.
    if (instructions && (instructions.text || instructions.audio)) {
      showInstruction();
    }

    return () => {
      if (cleanupFunc) {
        cleanupFunc();
      }
    };
    // eslint-disable-next-line
  }, [isCurrentPage]);

  const handleOnInstructionClosed = () => {
    stopAudio();
    setOpenInstructions({
      ...openInstructions,
      open: false,
    });
  };

  return {
    openInstructions,
    handleOnInstructionClosed,
  };
};

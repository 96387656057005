import { useDomain } from '../../../../states/AppDomainProvider';
import { updateUserProfile } from '../../../../services/UserProfileService';
import {
  useGetRemoteServiceWrapper,
} from '../../../hooks/RemoteServiceHooks';

const useSettingForm = (label) => {
  const { uiDomain, userDomain } = useDomain();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const updateProfileSettingsOnChange = async (newData, getTerminologiesFn) => {
    await callRemoteServiceWrapper(async () => {
      await updateUserProfile(user.id, newData);
      await uiDomain.showSnackbar(true, 'info', 3000, `${label} has been updated.`);
      if (getTerminologiesFn === undefined) {
        await userDomain.updateCurrentUser(user, { ...userProfile, ...newData }, terminologies);
      } else {
        const newTerminologies = await getTerminologiesFn();
        await userDomain.updateCurrentUser(user, { ...userProfile, ...newData }, newTerminologies);
      }
    });
  };

  return {
    loading,
    updateProfileSettingsOnChange,
  };
};

export default useSettingForm;

import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import CelebrationSettingForm from '../../../settings/celebrations/CelebrationSettingForm';
import SeasonalFilter from '../seasonal/SeasonalFilter';

const CelebrationSetting = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Celebrations');
  const { userDomain } = useDomain();
  const { userProfile, terminologies } = userDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange({
      ...newValue,
    });
  };

  return (
    <CelebrationSettingForm
      profile={userProfile}
      terminologies={terminologies}
      onChangeCallback={(newValue) => onChange(newValue)}
      SeasonalFilterComponent={SeasonalFilter}
    />
  );
};

export default CelebrationSetting;

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TextLines from '../../../text/TextLines';
import MediaPlayer from '../../../media/MediaPlayer';
import { getPageStyle } from '../../common/LessonPageUtil';
import './MatchingPrompts.scss';

/**
 * Matching prompts
 */
export default function MatchingPrompts({ promptCards, renderQuestionPlaceholder, forPrinting }) {
  const getCard = (card) => (
    <Card
      className='matching-component-card'
      style={getPageStyle(card)}
    >
      {
        card.image ? (
          <CardMedia
            className='matching-component-image'
            image={card.image}
            title='Matching'
          />
        ) : ''
      }
      {card.text
        ? (
          <CardContent className='matching-component-text'>

            <TextLines textLines={card.text} />

          </CardContent>
        ) : ''}
      {card.audio ? <PlayCircleFilledIcon className='matching-component-prompts-media-player-icon' data-test='media-player-icon' /> : ''}
    </Card>
  );

  const getPromptCardPlaceHolder = () => (
    <Card className='matching-component-prompts-area-question-placeholder'>
      {renderQuestionPlaceholder}
    </Card>
  );

  const getVideo = (video, image, index) => {
    if (!video) {
      return '';
    }
    const videoCardId = uuidv1();
    return (
      <Card className='matching-component-prompts-area-question-video' id={videoCardId}>
        <MediaPlayer
          videoUrl={video}
          parentElementId={videoCardId}
          className={`vizzle-matching-prompt-video-page-${index}-media-btn`}
          omitPlayIcon
          playsVideoInline
          videoPoster={image}
          forPrinting={forPrinting}
        />
      </Card>
    );
  };

  return (
    <div className='matching-component-prompts-container'>
      {
        promptCards.map((card, index) => {
          const cardId = uuidv1();
          return (
            <div className='matching-component-prompts-area' key={uuidv1()}>
              <Card
                className='matching-component-prompts-area-question-container'
                id={cardId}
                key={cardId}
                style={getPageStyle(card, true)}
              >
                <div
                  className='matching-component-prompts-area-question'
                >
                  {getCard(card)}
                </div>
                {getPromptCardPlaceHolder()}
                {
                  (card.audio)
                  && (
                    <MediaPlayer
                      audioUrl={card.audio}
                      parentElementId={cardId}
                      className={`vizzle-matching-prompt-audio-page-${index}-media-btn`}
                      omitPlayIcon
                    />
                  )
                }
              </Card>
              {getVideo(card.video, card.image, index)}
            </div>
          );
        })
      }
    </div>
  );
}

MatchingPrompts.defaultProps = {
  promptCards: [{}],
  renderQuestionPlaceholder: null,
  forPrinting: false,
};

MatchingPrompts.propTypes = {
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  renderQuestionPlaceholder: PropTypes.node,
  forPrinting: PropTypes.bool,
};

import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import CardActivity from '../common/card-activity/CardActivity';
import CategorizingActivityPrompt from './components/CategorizingActivityPrompt';
import {
  useCardRotationCache,
  useClickSubmitAnswer,
  useDragAndDropSubmitAnswer,
  useGenerateComponentIds,
  useGenerateRejectedAnswerRefs,
  useGetAvailableResponseKeys,
  useInitializeData,
  usePrepareViewCorrectSubmissions,
  useSwitchAccessSubmitAnswer,
} from './CategorizingActivityHooks';
import '../activities-common.scss';
import './CategorizingActivity.scss';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import CategorizingSelectedResponseCards from './components/CategorizingSelectedResponseCards';
import ObjectUtils from '../../../../utils/ObjectUtils';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

const CategorizingActivity = forwardRef(({
  instructions,
  promptCards,
  responseCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onTriggerSwitchScan,
  pageIndex,
}, ref) => {
  const allResponsesText = responseCards.findIndex((c) => Boolean(c.image)) < 0;

  const {
    id,
    componentId,
    droppableId,
  } = useGenerateComponentIds();

  const {
    cardRotationCache,
  } = useCardRotationCache({
    numberOfCards: responseCards.length,
    allResponsesText,
  });

  const {
    categorizingActivityRef,
    dragAndDropApiRef,
  } = useInitializeData();

  const availableResponseKeys = useGetAvailableResponseKeys(promptCards, responseCards);
  if (!availableResponseKeys || availableResponseKeys.length === 0) {
    onActivityFinished({ toNextPage: false });
  }
  const {
    rejectedAnswerPromptRefs,
    setRejectedAnswerPromptRefs,
    rejectedAnswerResponseRefs,
    setRejectedAnswerResponseRefs,
  } = useGenerateRejectedAnswerRefs();

  const {
    handleOnDragStart,
    handleOnDragEnd,
    selectedResponseCards,
    setSelectedResponseCards,
  } = useDragAndDropSubmitAnswer({
    categorizingActivityRef,
    promptCards,
    rejectedAnswerPromptRefs,
    availableResponseKeys,
    responseCards,
    rejectedAnswerResponseRefs,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
  });

  const {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  } = useClickSubmitAnswer({
    categorizingActivityRef,
    dragAndDropApiRef,
  });

  usePrepareViewCorrectSubmissions({
    setSelectedResponseCards,
    responseCards,
    promptCards,
    onActivityFinished,
    availableResponseKeys,
    pageIndex,
  });

  const isFinished = selectedResponseCards.length >= availableResponseKeys.length;
  const hasInstruction = instructions.text || instructions.image || instructions.audio;

  const pageInstructionRef = useRef(null);

  const categorizingPromptRefs = [];
  const setCategorizingPromptRefs = (r, index) => {
    if (r) {
      categorizingPromptRefs[index] = r;
    }
  };

  const categorizingResponseRefs = [];
  const setCategorizingResponseRefs = (r, index) => {
    if (r) {
      categorizingResponseRefs[index] = r;
    }
  };

  const {
    selectedPromptIndex,
    handlePromptSwitchAccessSelect,
    handleResponseSwitchAccessSelect,
  } = useSwitchAccessSubmitAnswer({
    categorizingPromptRefs,
    categorizingResponseRefs,
    onTriggerSwitchScan,
    dragAndDropApiRef,
  });

  useImperativeHandle(ref, () => ({
    skipAppBar: () => false,
    getEnableElements: async () => {
      if (isFinished) {
        return [];
      }

      await ObjectUtils.delay(50);
      const remainPrompts = categorizingPromptRefs
        .filter((_s, index) => index !== (selectedPromptIndex.current && selectedPromptIndex.current.index))
        .map((s) => s.getEnableElements());
      const remainingResponse = categorizingResponseRefs
        .filter((_s, index) => !categorizingResponseRefs.includes(index))
        .map((s) => s.getEnableElements());

      let result = [];
      if (pageInstructionRef && pageInstructionRef.current.getEnableElements) {
        result.push(pageInstructionRef.current.getEnableElements());
      }

      if (selectedPromptIndex.current !== null) {
        result = [
          ...result,
          ...remainingResponse,
          ...remainPrompts,
        ];
      } else {
        result = [
          ...result,
          ...remainPrompts,
          ...remainingResponse,
        ];
      }
      return result;
    },
  }));

  const isPromptTwoColumns = promptCards.length >= 7;

  return (
    <DragDropContext
      onDragStart={(handleOnDragStart)}
      onDragEnd={handleOnDragEnd}
      sensors={[(sensorApi) => {
        dragAndDropApiRef.current = sensorApi;
      }]}
      onBeforeCapture={(before) => {
        const resizedWidth = isPromptTwoColumns ? '150px' : null;
        LessonPlayActivityUtils.resizeDragCardWhenDragging(before, `categorizing-prompt-placeholder-${id}-${0}`, resizedWidth);
      }}
    >
      <div className='categorizing-activity-component' data-test='categorizing-activity' ref={categorizingActivityRef} id={componentId}>
        <TransparentBlocker show={isFinished} />
        <Droppable
          droppableId={droppableId}
          direction='horizontal'
          isDropDisabled
          isCombineEnabled
        >
          {(categorizingProvided) => (
            <div
              ref={categorizingProvided.innerRef}
              {...categorizingProvided.droppableProps}
              className='categorizing-prompt-draggable'
            >
              <div className='flexible-column-container'>
                {hasInstruction && (
                  <div data-test='instruction-container' className={`panel instruction-container ${instructions.image ? 'full-height' : ''}`}>
                    <CardActivity {...instructions} ref={pageInstructionRef} />
                  </div>
                )}
                <div className={`panel 
                responses-container 
                
                categorizing-response-card-${responseCards.length} 
                ${allResponsesText ? 'flatten-cards' : ''}
                ${hasInstruction ? '' : 'large-card'}`}
                >
                  {
                    responseCards.map((responseCard, index) => {
                      const cardId = `${id}-${index}`;
                      const responseKeyId = `categorizing-response-key-${cardId}`;
                      const responseId = `categorizing-response-${cardId}`;
                      const responseContainerId = `categorizing-response-container-${cardId}`;
                      const draggableId = `categorizing-drag-item-${cardId}`;
                      const { rndAngle, rndClock } = cardRotationCache[index];
                      const isResponseSelected = selectedResponseCards.includes(index);

                      const { bgColor, borderColor, correctSubmission, cardIndex, sequenceNum, thumbNailImage, ...rest } = responseCard;
                      return (
                        /*
                        * Note: The rotation of the component affect the logic to move the card.
                        * Add another div container to fix the issue of rotate.
                        * the x and y from getBoundingClientRect() must be calculated based upon
                        * the orignal x and y without rotation of the component.
                        */
                        <div id={responseContainerId} key={responseContainerId} className='response-card-container'>
                          <div
                            style={{
                              transform: `rotate(${rndClock === 1 ? '-' : ''}${rndAngle}deg)`,
                            }}
                            key={responseKeyId}
                            className={`
                          response-card-place-holder 
                          item-placeholder
                          `}
                            id={`${draggableId}-container`}
                          >
                            <Draggable
                              key={draggableId}
                              draggableId={draggableId}
                              index={index}
                              isDragDisabled={isFinished || selectedResponseCards.includes(index)}
                              shouldRespectForcePress={false}
                              timeForLongPress={0}
                            >
                              {(providedItem, snapshotItem) => (
                                <div
                                  data-test='categorizing-response-draggable-container'
                                  ref={providedItem.innerRef}
                                  {...providedItem.draggableProps}
                                  {...providedItem.dragHandleProps}
                                  id={draggableId}
                                  key={`categorizing-response-item-${cardId}`}
                                  className={`
                                categorizing-response-draggable-container
                                ${isResponseSelected || isFinished ? '' : 'categorizing-response-draggable'}
                                ${snapshotItem.isDragging ? 'categorizing-selected-component' : 'non-drag-items'}
                                `}
                                  style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                                >
                                  {!isResponseSelected && (
                                    <>
                                      <CardActivity
                                        {...rest}
                                        disabled={isFinished}
                                        id={responseId}
                                        onClick={isFinished ? undefined : (e, isTtsAbleToPlay) => {
                                          handleOnResponseCardClick(e, isTtsAbleToPlay, responseCard, responseId, responseContainerId, draggableId);
                                        }}
                                        ref={(r) => { setCategorizingResponseRefs(r, index); }}
                                        onSwitchAccessSelected={() => handleResponseSwitchAccessSelect(responseId, draggableId)}
                                      />
                                      <SubmittedAnswerIndicator correctSubmission={responseCard.correctSubmission} />
                                      <RejectedAnswerResponse ref={(r) => { setRejectedAnswerResponseRefs(r, index); }} />
                                    </>
                                  )}
                                </div>
                              )}

                            </Draggable>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>

              <div className='prompt-panels-container'>
                <div className={`flexible-column-container panel prompt-container ${isPromptTwoColumns ? 'two-columns' : ''}`}>
                  {promptCards.map((promptCard, index) => {
                    const promptId = `categorizing-prompt-${id}-${index}`;
                    const drappableKeyId = `categorizing-component-droppable-container-${id}-${index}`;
                    const promptPlaceholderId = `categorizing-prompt-placeholder-${id}-${index}`;
                    return (
                      <Droppable
                        key={drappableKeyId}
                        droppableId={drappableKeyId}
                        direction='horizontal'
                        index={index + 1}
                      >
                        {(provided, snapshot) => (
                          <div className={`prompt prompt-${promptCards.length}`} data-test={`categorizing-prompt-${index}`}>
                            <CategorizingActivityPrompt
                              promptCardId={promptId}
                              promptCard={promptCard}
                              onResponseCardsAreaClick={(e) => {
                                handleOnPromptCardClick(e, promptPlaceholderId);
                              }}
                              ref={(r) => { setCategorizingPromptRefs(r, index); }}
                              onPromptSwitchAccessSelected={() => { handlePromptSwitchAccessSelect(index, promptPlaceholderId); }}
                            >
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                id={promptPlaceholderId}
                                className={`
                                selected-response-cards 
                                ${snapshot.isDraggingOver ? 'categorizing-selected-component' : 'categorizing-component-response-card-non-drag-items'}
                                `}
                                data-test={`categorizing-submit-box-${index}`}
                              >
                                <CategorizingSelectedResponseCards
                                  id={id}
                                  totalPrompts={promptCards.length}
                                  promptKey={promptCard.key}
                                  selectedResponseCards={selectedResponseCards}
                                  responseCards={responseCards}
                                  fontScale={0.7}
                                />
                                <RejectedAnswerResponse ref={setRejectedAnswerPromptRefs} showIcon={false} />
                              </div>
                            </CategorizingActivityPrompt>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
              </div>
              {categorizingProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
});

export default CategorizingActivity;

CategorizingActivity.defaultProps = {
  instructions: {},
  promptCards: [],
  responseCards: [],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  onTriggerSwitchScan: () => { },
};

CategorizingActivity.propTypes = {
  instructions: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
  pageIndex: PropTypes.number.isRequired,
};

import PropTypes from 'prop-types';
import ClearLessonSelectionAction from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/ClearLessonSelectionAction';
import TeacherCurriculumAssignLessonsAction from './TeacherCurriculumAssignLessonsAction';
import { useTeacherCurriculum } from '../../../context/TeacherCurriculumContextProvider';
import './LessonsMultiSelectionActions.scss';
import LessonsAddToGroupPlay from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/LessonsAddToGroupPlay';

const LessonsMultiSelectionActions = ({
  onClearClick,
  selectedLessonIds,
  isAssignAutoTag,
}) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonData } = teacherCurriculumDomain.domainData;
  const { lessons } = lessonData;

  return (
    <div className='teacher-curriculum-lessons-multi-selection-actions'>
      <LessonsAddToGroupPlay
        lessons={lessons}
        selectedLessonIds={selectedLessonIds}
      />
      <TeacherCurriculumAssignLessonsAction
        lessons={lessons}
        selectedLessonIds={selectedLessonIds}
        key='teacher-curriculum-lessons-assign-to-others'
        isAssignAutoTag={isAssignAutoTag}
      />
      <ClearLessonSelectionAction
        className='clear-selection'
        onClearClick={onClearClick}
        selectedLessonIds={selectedLessonIds}
      />
    </div>
  );
};

LessonsMultiSelectionActions.defaultProps = {
  onClearClick: () => { },
  selectedLessonIds: [],
  isAssignAutoTag: true,
};

LessonsMultiSelectionActions.propTypes = {
  onClearClick: PropTypes.func,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  isAssignAutoTag: PropTypes.bool,
};

export default LessonsMultiSelectionActions;

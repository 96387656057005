import {
  Box,
  Toolbar,
} from '@mui/material';
import VizzleAppbar from '../../components/appbar/VizzleAppbar';
import StudentSelectorMenu from './menu/StudentSelectorMenu';
import UrlLinkList from '../../components/urllink/UrlLinkList';
import {
  URL_MY_CLASSROOM,
  URL_TEACHER_CURRICULUM,
  // URL_TEACHER_VERTICAL_CURRICULUM,
  URL_ADMIN,
  URL_LESSONS,
} from '../../routes/RouteConstants';
import { useDomain } from '../../states/AppDomainProvider';
import { isTexasTeacher, USER_COMPONENT_ACADEMIC, USER_COMPONENT_SKILL_SUITE } from '../../utils/User';
import TeacherLogoutDropdown from './menu/TeacherLogoutDropdown';
import TeacherCourseMenu from '../courses/components/TeacherCourseMenu';
import AppBarLogo from './menu-actions/app-bar-logo/AppBarLogo';
import './TeacherTopAppbar.scss';
import UrlLink from '../../components/urllink/UrlLink';
import TeacherCourseSkillsSuiteMenu from '../courses/components/TeacherCourseSkillsSuiteMenu';

const adminTileList = [{ title: 'Admin', url: URL_ADMIN }];
const classroomTile = [{ title: 'Classroom', url: URL_MY_CLASSROOM }];

const helpUrl = 'https://support.govizzle.com';

const TeacherTopAppbar = () => {
  const { userDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;
  const { components } = userProfile;
  const componentIds = (components || []).map((c) => String(c.identifier));

  const menuList = [];

  if (user.showCurriculum && isTexasTeacher(user)) {
    menuList.push({ title: `${user.state} Curriculum`, url: URL_TEACHER_CURRICULUM, dataTest: 'Curriculum' });
  } else if (user.showVerticalAlignment) {
    // menuList.push({ title: 'Vertical Alignment', url: URL_TEACHER_VERTICAL_CURRICULUM });
  }

  return (
    <VizzleAppbar appbarPosition='top'>
      <Toolbar className='teacher-top-app-bar'>
        <Box display='flex' flexGrow={1} alignItems='center'>
          <AppBarLogo />
          {user.userAdmin || user.userSuperAdmin ? <UrlLinkList config={adminTileList} /> : null}
          <UrlLinkList config={classroomTile} />
          <StudentSelectorMenu />
          <UrlLinkList config={menuList} />
          {(user.userModerator || user.unitCreator) && componentIds.includes(USER_COMPONENT_ACADEMIC) && <TeacherCourseMenu />}
          {(user.userModerator || user.unitCreator) && componentIds.includes(USER_COMPONENT_SKILL_SUITE) && <TeacherCourseSkillsSuiteMenu />}
          <UrlLinkList config={[{ title: 'Lessons', url: URL_LESSONS }]} />
          {/* <UrlLinkList config={[{ title: 'Search Library', url: URL_TEACHER }]} /> */}
          <UrlLink
            title='Help'
            onClick={
              () => {
                window.open(helpUrl, '_blank');
              }
            }
            dataTest='Help'
          >
            Help
          </UrlLink>
        </Box>
        <TeacherLogoutDropdown />
      </Toolbar>
    </VizzleAppbar>
  );
};

export default TeacherTopAppbar;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box, CardMedia,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './CategorizingActivityPrompt.scss';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';

const CategorizingActivityPrompt = forwardRef(({
  promptCard,
  promptCardId,
  children,
  onResponseCardsAreaClick,
  onPromptSwitchAccessSelected,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const prompRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => prompRef.current,
  }));

  useRegisterSwitchAccessSelectEvent(prompRef, () => {
    onPromptSwitchAccessSelected();
  });

  return (
    <Box
      key={promptCardId}
      id={promptCardId}
      boxShadow={2}
      className='categorizing-activity-prompt-card'
      data-skip-switch-access-click='true'
      ref={prompRef}
      data-test='categorizing-activity-prompt-card'
    >
      {((elementToPlayTts && elementToPlayTts.length > 0))
        && (
          <MediaPlayer
            className='media-action'
            parentElementId={promptCardId}
            omitPlayIcon
            textToRead={elementToPlayTts}
            tts={ttsConfig}
          />
        )}
      <div className='prompt-details'>
        {(elementToPlayTts && elementToPlayTts.length > 0) ? (
          <PlayCircleFilledIcon className='media-player-icon' data-test='media-player-icon' />
        ) : ''}

        {
          promptCard.image ? (
            <div className='prompt-image-container' data-test='prompt-image-container'>
              <CardMedia image={promptCard.image} className='prompt-image' />
            </div>
          ) : ''
        }

        <div className='prompt-text' data-test='prompt-text'>
          {promptCard.text ? (
            <TextLines
              textLines={promptCard.text}
              ref={setTextLineRef}
              componentKey={`text-line-${promptCardId}`}
            />
          )
            : ''}
        </div>
      </div>
      <div
        className='prompt-selected-response-cards'
        onClick={onResponseCardsAreaClick}
        data-test='prompt-selected-response-cards'
      >
        {children}
      </div>
    </Box>
  );
});

export default CategorizingActivityPrompt;

CategorizingActivityPrompt.defaultProps = {
  promptCard: {},
  children: null,
  onResponseCardsAreaClick: () => null,
  onPromptSwitchAccessSelected: () => null,
};

CategorizingActivityPrompt.propTypes = {
  promptCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  }),
  promptCardId: PropTypes.string.isRequired,
  children: PropTypes.node,
  onResponseCardsAreaClick: PropTypes.func,
  onPromptSwitchAccessSelected: PropTypes.func,
};

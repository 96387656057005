/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
} from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import './SwitchAccessDialog.scss';

export default function SwitchAccessDialog({
  open,
}) {
  const focusElement = useRef(null);

  return open ? (
    <Dialog
      open={open}
      className='switch-access-dialog'
      TransitionProps={{
        onEntered: () => {
          focusElement.current.focus();
        },
      }}
      data-test='switch-access-dialog'
    >
      <DialogContent className='content'>
        <TouchAppIcon className='icon' />
        <div className='title' data-test='switch-access-dialog-title'>
          Scan
        </div>
        <div className='messages' ref={focusElement} tabIndex='1' data-test='switch-access-dialog-message'>
          Press Spacebar Key/Switch to <br /> Start Scanning
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}

SwitchAccessDialog.defaultProps = {
  open: false,
};

SwitchAccessDialog.propTypes = {
  open: PropTypes.bool,
};

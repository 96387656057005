import { getSubjectId } from '../utils/LessonsMapping';
import vizzleAxios from './service';

export const getAllLessonTagsForStudent = async (userId) => {
  const url = `/users/${userId}/tags`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getAllLessonTagsForTeacher = async (userId) => {
  const url = `/users/${userId}/myListTags`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateLessonTagForStudent = async (userId, tagId, tagName) => {
  const url = `/users/${userId}/tags/${tagId}`;
  const result = await vizzleAxios.put(url, { data: { type: 'tags', attributes: { name: tagName } } });
  return result;
};

export const archiveObjective = async (studentId, objectiveId) => {
  const url = `/users/${studentId}/tags/${objectiveId}`;
  const result = await vizzleAxios.delete(url);
  return result;
};

export const unArchiveObjective = async (studentId, objectiveId) => {
  const url = `/users/${studentId}/tags/${objectiveId}?operation=unarchive`;
  const result = await vizzleAxios.post(url, {});
  return result;
};

export const removeTagFromLesson = async (userId, lessonId, tagId, tagUrl) => {
  const url = `/users/${userId}/lessons/${lessonId}/${tagUrl}/${tagId}`;
  const result = await vizzleAxios.delete(url);
  return result;
};

export const addExistingTagToLesson = async (userId, lessonId, tagId, tagUrl) => {
  const url = `/users/${userId}/lessons/${lessonId}/${tagUrl}?operation=assign`;
  const result = await vizzleAxios.post(url, { data: { type: tagUrl, id: tagId } });
  return result;
};

export const createAndAssignTag = async (userId, lessonId, tagName, tagUrl) => {
  const url = `/users/${userId}/lessons/${lessonId}/${tagUrl}?operation=createAndAssign`;
  const result = await vizzleAxios.post(url, { data: { type: tagUrl, attributes: { name: tagName } } });
  return result.data.data;
};

export const getTagsByUserIds = async (userIds, lessonIds) => {
  const lessonIdsString = lessonIds.map((l) => l.id);
  const url = `/tags/?filter[userIds]=${userIds}&filter[activeTagsOnly]=true&includeTagAssignmentsForLessonsWithIds=${lessonIdsString}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const assignAllStudents = async (studentIds, lessonIds, teacherId, lastAssignmentDate, singlePlay) => {
  let url = '/users/me/lessons?operation=add';
  if (lastAssignmentDate) {
    url = `${url}&latestDate=${lastAssignmentDate[lastAssignmentDate.length - 1]}`;
  }
  const userIds = [...studentIds];
  if (teacherId) {
    userIds.push(teacherId);
  }
  const result = await vizzleAxios.post(url, {
    userIds,
    lessonIds,
    singlePlay,
  });
  return result;
};

// eslint-disable-next-line no-unused-vars
export const assignAllStudentsForCourses = async (studentIds, isRange, dates, lessons, studentId) => {
  const payload = {
    userIds: studentIds,
  };

  if (isRange) {
    const [startDate, endDate] = dates;
    payload.startDate = startDate;
    payload.endDate = endDate;
  } else {
    payload.days = dates;
  }

  const lessonAssignments = [];

  lessons.forEach((l, index) => {
    const subjectId = getSubjectId(l);
    const lessonAssignment = lessonAssignments.find((la) => la.subjectId === subjectId);
    const lessonObject = {
      lId: l.id,
      status: 0,
      seq: index,
    };

    if (lessonAssignment) {
      lessonAssignment.lessonInfos.push(lessonObject);
    } else {
      const newLessonAssignment = {
        subjectId,
        lessonInfos: [lessonObject],
      };
      lessonAssignments.push(newLessonAssignment);
    }
  });

  payload.lessonAssignments = lessonAssignments;
  let url = '/users/lessons/days';
  if (studentId) {
    url = `${url}?fromStudent=${studentId}`;
  }
  const result = await vizzleAxios.post(url, payload);
  return result;
};

export const unassignAllStudents = async (studentIds, lessonIds) => {
  const url = '/users/me/lessons?operation=archive';
  const result = await vizzleAxios.post(url, {
    userIds: studentIds,
    lessonIds,
  });
  return result;
};

export const addTagsForStudentAssignments = async (lessonIds, tagRelationsips, objectiveType) => {
  let url = `/tags/?operation=createAndOrAssign&assignForLessonsWithIds=${lessonIds.join(',')}`;
  if (objectiveType) {
    url = `${url}&type=${objectiveType}`;
  }
  const result = await vizzleAxios.post(url, { data: tagRelationsips });
  return result;
};

import PropTypes from 'prop-types';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CardAction from '../../../../../../../../components/card/CardAction';

export default function BackAction({
  className,
  onClick,
}) {
  return (
    <CardAction
      icon={<KeyboardBackspaceIcon />}
      actionHandler={onClick}
      label='Go back'
      className={`btn-primary ${className}`}
      ariaLabel='Go back'
      title='Go back'
      data-test='go-back-action'
      disabled={false}
    />
  );
}

BackAction.defaultProps = {
  className: '',
  onClick: () => null,
};

BackAction.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import CardAction from '../../../../../../../../components/card/CardAction';
import ConfirmationDialog from '../../../../../../../../components/dialog/ConfirmationDialog';
import './DeleteFileAction.scss';

export default function DeleteFileAction({
  onConfirm,
  disabled,
  isDeleting,
  ariaLabel,
  className,
}) {
  const [state, setState] = useState({
    dialogOpen: false,
  });

  const handleClickOpen = () => {
    setState({
      ...state,
      dialogOpen: true,
    });
  };

  const handleOnClose = () => {
    setState({
      ...state,
      dialogOpen: false,
    });
  };

  return (
    <>
      <CardAction
        icon={<DeleteIcon />}
        actionHandler={() => { handleClickOpen(); }}
        label='Delete'
        className={`btn-danger delete-file-action ${className}`}
        ariaLabel={ariaLabel}
        title={ariaLabel}
        data-test='delete-file-action'
        disabled={disabled}
      />
      <ConfirmationDialog
        open={state.dialogOpen}
        title='Delete files'
        loading={isDeleting}
        contentText={(
          <Box display='flex' width='300px' flexDirection='column' justifyContent='center'>
            <Typography variant='body1'>
              Are you sure?
            </Typography>
            <br />
            <Typography variant='body1'>
              All selected files will be deleted.
            </Typography>
          </Box>
        )}
        confirmLabel='Yes'
        cancelLabel='Cancel'
        onConfirm={async () => {
          await onConfirm();
          handleOnClose();
        }}
        onCancel={() => { handleOnClose(); }}
        confirmClass='wide-button'
        cancelClass='wide-button'
      />
    </>
  );
}

DeleteFileAction.defaultProps = {
  className: '',
  onConfirm: () => null,
  disabled: true,
  isDeleting: false,
  ariaLabel: 'Delete',
};

DeleteFileAction.propTypes = {
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  disabled: PropTypes.bool,
  isDeleting: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

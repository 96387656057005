import { useState } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import ClockLoader from 'react-spinners/ClockLoader';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import CancelDialog from '../CancelDialog';
import '../../SearchBarAction.scss';
import { useGetTopic } from '../../../tabs/topic-lessons/TeacherCourseLessonsHooks';
import './GenerateLessonsDialog.scss';
import GenerateLessonsForm from './GenerateLessonsForm';
import ObjectUtils from '../../../../../../utils/ObjectUtils';
import { useGenerateLessons } from './GenerateLessonsDialogHooks';
import ConfirmationDialog from '../../../../../../components/dialog/ConfirmationDialog';
import { useHandleOnSearch } from '../../SearchActionHooks';
// import { TEACHER_COURSES_ROUTES } from '../../../TeacherCourseConstants';
// import { URL_COURSES } from '../../../../../../routes/RouteConstants';

const GenerateLessonsDialog = ({
  open,
  onClose,
  subjectId,
  courseId,
  unitId,
  topicId,
  lessonLevel,
}) => {
  // const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [topicTemplateValues, setTopicTemplateValues] = useState({});
  const topic = useGetTopic(courseId, unitId, topicId);

  const { handleOnSearch } = useHandleOnSearch();
  const {
    saving,
    generateLessons,
  } = useGenerateLessons({
    topicTemplateValues,
    onSaveSuccess: async () => {
      await handleOnSearch({
        searchCriteria: {
          subjectId,
          courseId,
          unitId,
          topicId,
          lessonLevel,
        },
      });
      onClose();
      // ObjectUtils.setTimeout(() => {
      //   navigate(`${URL_COURSES.replace(':subjectId', subjectId)}/${TEACHER_COURSES_ROUTES.LESSON_TEMPLATE_GENERATOR}`);
      // }, 500);
    },
  });

  const topicTemplateValuesHaveValue = !ObjectUtils.isEmpty(topicTemplateValues) && Object.values(topicTemplateValues).find((e) => e && e.numberOfCopies > 0);

  return (
    <>
      <CustomDialog
        className={`teacher-course-generate-lessons-dialog ${saving ? 'saving-container' : ''}`}
        openDialog={open}
        onClose={() => onClose()}
        displayCloseIcon={false}
        title={
          (
            <div className='dialog-title'>
              <Typography variant='h5'>{topic.name}</Typography>
              <Typography variant='h5'>Activity Templates</Typography>
            </div>
          )
        }
        content={(
          <>
            {saving && (
              <div className='generating-lesson-container'>
                <ClockLoader size='70' color='#3b94d1' />
                <Typography className='saving-text'>Generating Level {lessonLevel} Lessons</Typography>
              </div>
            )}
            {!saving && (
              <GenerateLessonsForm
                subjectId={subjectId}
                value={topicTemplateValues}
                onChange={(newValue) => { setTopicTemplateValues(newValue); }}
              />
            )}
          </>
        )}
        actions={[
          {
            title: 'Cancel',
            color: 'primary',
            className: 'btn-danger action-button wide-button left-button',
            onClick: () => { setOpenCancelDialog(true); },
            disabled: saving,
            loading: saving,
          },
          {
            title: `Generate level ${lessonLevel} lessons`,
            color: 'primary',
            className: 'btn-primary action-button generate-button',
            disabled: !topicTemplateValuesHaveValue || saving,
            onClick: () => {
              setOpenConfirmDialog(true);
            },
            loading: saving,
          },
        ]}
      />

      <CancelDialog
        open={openCancelDialog}
        title='Cancel generate lessons'
        onClose={() => { setOpenCancelDialog(false); }}
        onCancel={() => { onClose(); }}
      />

      {openConfirmDialog && (
        <ConfirmationDialog
          open={openConfirmDialog}
          title='Generate Lessons'
          contentText={`Are you sure you want to generate these chosen templates as Level ${lessonLevel} lessons?`}
          confirmLabel='Continue'
          cancelLabel='Go Back'
          onConfirm={() => {
            setOpenConfirmDialog(false);
            generateLessons({
              topicId,
              lessonLevel,
              topicTemplateValues,
            });
          }}
          onCancel={() => { setOpenConfirmDialog(false); }}
          confirmClass='wide-button'
          cancelClass='wide-button'
        />
      )}
    </>
  );
};

GenerateLessonsDialog.defaultProps = {
  subjectId: 0,
  open: false,
  onClose: () => null,
};

GenerateLessonsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lessonLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default GenerateLessonsDialog;

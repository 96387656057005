import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMyClassroom } from '../../../context/MyClassRoomContextProvider';

export const useIntitialData = (tabRef) => {
  const location = useLocation();
  const dateOption = location['date-option'];
  useEffect(() => {
    if (tabRef.current) {
      if (dateOption === 'thisWeek') {
        tabRef.current.toggleToThisWeek();
      } else if (dateOption === 'nextWeek') {
        tabRef.current.toggleToNexWeekk();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabRef]);
};

export const useMyAssignmentGetData = () => {
  const { myAssignmentsDomain } = useMyClassroom();
  const { filter } = myAssignmentsDomain.domainData;
  return {
    selectedSubject: filter.selectedSubject,
  };
};

export const useMyAssignmentDataSelector = () => {
  const { myAssignmentsDomain } = useMyClassroom();
  const { selectedDates } = myAssignmentsDomain.domainData;
  const handleOnSelectedDatesApply = (internalSelectedDates) => myAssignmentsDomain.updateSelectedDates(internalSelectedDates);

  return {
    handleOnSelectedDatesApply,
    selectedDates,
  };
};

export const useMyAssignmentLoadData = () => {
  const { myAssignmentsDomain } = useMyClassroom();
  const handleOnStartLoading = () => {
    myAssignmentsDomain.updateAssignedStudentsCoursesLoading(true);
  };

  const handleOnDataLoad = (data) => {
    myAssignmentsDomain.updateAssignedStudentsCourses(data, false);
  };

  return {
    handleOnStartLoading,
    handleOnDataLoad,
  };
};

import vizzleAxios from './service';

export const getExternalLinks = async (userId) => {
  const url = `/users/${userId}/externalLink`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const createExternalLink = async (userId, externalLink, label) => {
  const url = `/users/${userId}/externalLink`;
  const payload = {
    data: {
      type: 'externalLinks',
      attributes: {
        link: externalLink,
        label,
      },
    },
  };
  const result = await vizzleAxios.post(url, payload);
  return result.data;
};

export const updateExternalLink = async (userId, externalLinkId, externalLink, label) => {
  const url = `/users/${userId}/externalLink/${externalLinkId}`;
  const payload = {
    data: {
      type: 'externalLinks',
      attributes: {
        link: externalLink,
        label,
      },
    },
  };
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const deleteExternalLink = async (userId, externalLinkId) => {
  const url = `/users/${userId}/externalLink/${externalLinkId}`;
  const result = await vizzleAxios.delete(url);
  return result.data;
};

import {
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import './NoLessonPaper.scss';

/**
 * Paper card for display No lesson available.
 */
export default function NoLessonPaper({ message, className }) {
  return (
    <div className={`no-lesson-container ${className}`} data-test='no-lesson'>
      <Typography component='p' className='no-lesson-container-message' data-private>
        {message}
      </Typography>
    </div>
  );
}

NoLessonPaper.defaultProps = {
  message: 'No Activities Available',
  className: '',
};

NoLessonPaper.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import TextLines from '../../text/TextLines';
import MediaPlayer from '../../media/MediaPlayer';
import { getPageStyle } from '../common/LessonPageUtil';
import './BookCard.scss';

/**
 * Lesson activity for Book
 */
export default function BookCard({ layoutConfig, className }) {
  return (
    <div
      className={
        `book-card-component
      vizzle-book-card
      ${className}
      ${layoutConfig.length === 1 ? 'book-card-component-small' : 'book-card-component-big'}`
      }
    >
      {
        layoutConfig.map((c, index) => {
          const cardId = uuidv1();
          return (
            <Card
              id={cardId}
              key={cardId}
              className={`book-card book-card-padding vizzle-book-card-page-${index}`}
              style={getPageStyle(c, true)}
            >
              {
                c.image ? (
                  <CardMedia
                    className='book-card-image'
                    image={c.image}
                    title='Book'
                  />
                )
                  : ''
              }
              <CardContent className='book-card-text'>
                {c.text
                  ? (
                    <TextLines textLines={c.text} fontScale={0.8} />
                  ) : (
                    ''
                  )}
              </CardContent>
              {
                (c.audio || c.video)
                && (
                  <MediaPlayer
                    audioUrl={c.audio}
                    videoUrl={c.video}
                    parentElementId={cardId}
                    className={`vizzle-book-card-page-${index}-media-btn`}
                  />
                )
              }
            </Card>
          );
        })
      }
    </div>
  );
}

BookCard.defaultProps = {
  layoutConfig: [],
  className: '',
};

BookCard.propTypes = {
  className: PropTypes.string,
  layoutConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
};

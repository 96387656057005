import PropTypes from 'prop-types';
import { useTeacherCurriculum } from '../../../common/context/TeacherCurriculumContextProvider';
import PdfContainer from './PdfContainer';

const GuidePdf = ({
  loadingMessage,
  noPdfFoundMessage,
}) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { guidePdfLink } = teacherCurriculumDomain.domainData;

  return (
    <PdfContainer
      pdfKey={guidePdfLink}
      loadingMessage={loadingMessage}
      noPdfFoundMessage={noPdfFoundMessage}
    />
  );
};

GuidePdf.defaultProps = {
  loadingMessage: null,
  noPdfFoundMessage: null,
};

GuidePdf.propTypes = {
  loadingMessage: PropTypes.string,
  noPdfFoundMessage: PropTypes.string,
};

export default GuidePdf;

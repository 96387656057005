import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import './SelectedStudent.scss';
import { URL_MY_STUDENT } from '../../../../../routes/RouteConstants';

const SelectedStudent = ({ data, onClickedRemoveStudent }) => {
  const navigate = useNavigate();
  const handleOnStudentClick = (student) => {
    navigate(
      URL_MY_STUDENT,
      {
        state: {
          student,
        },
      },
    );
  };

  return (data ? (
    <Box
      data-test={`selected-student-${data.userName}`}
      display='flex'
      flexDirection='row'
      className='selected-student-box'
      justifyContent='space-between'
      onClick={() => handleOnStudentClick(data)}
    >
      <Box className='student-box'>
        <Typography gutterBottom variant='subtitle1' component='p' className='selected-student-subtitle' data-private>
          {`${data.firstName} ${data.lastName}`}
        </Typography>
        <Typography gutterBottom variant='subtitle1' component='p' className='selected-student-subtitle' data-private>
          {`${data.userName}`}
        </Typography>
      </Box>
      <Box data-test={`selected-student-${data.userName}-cancel-action`}>
        <CancelIcon className='selected-student-cancel-icon' onClick={(e) => { e.stopPropagation(); onClickedRemoveStudent(data); }} />
      </Box>
    </Box>
  ) : null);
};

SelectedStudent.propTypes = {
  data: PropTypes.shape({
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onClickedRemoveStudent: PropTypes.func.isRequired,
};
export default SelectedStudent;

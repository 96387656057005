import { useEffect } from 'react';
import { TRACKING_STATUS, trackTeacherActivity } from '../../../../services/ActivityTrackingService';
import Logger from '../../../../utils/Logger';
import { isTeacher } from '../../../../utils/User';

export const useTrackTeacherActivityPreview = (open, user, lesson) => {
  useEffect(() => {
    if (open && isTeacher(user) && lesson) {
      try {
        trackTeacherActivity({
          activity: TRACKING_STATUS.PREVIEW,
          lessonId: lesson.id,
        });
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
};

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import ThumbnailCard from '../common/ThumbnailCard';
import { layoutDropdownValues } from './FlexibleMatchingConstants';
import './FlexibleMatchingLayout.scss';
import './FlexibleMatchingThumbnail.scss';
import { defaultSubmitText } from '../../../../utils/activitytype/ActivityTypeInitialData';

const MAX_RESPONSE_IN_SMALL_MODE = 2;

/**
 * Container for Flexible Matching layout
 */
export default function FlexibleMatchingThumbnail({
  promptText,
  subText,
  layout,
  promptCards,
  responseCards,
  isSmallThumbnail,
  submitAnswerText,
}) {
  const id = useMemo(() => uuidv1(), []);
  return (
    <div className={`flexi-matching-layout flexi-matching-thumbnail ${isSmallThumbnail ? 'small' : ''}`}>
      <div className='prompt-area'>
        <ThumbnailCard
          id={`flexi-matching-layout-prompt-text-${id}`}
          text={promptText}
          isSmallThumbnail={isSmallThumbnail}
          className='flexi-matching-prompt-text'
          isImageIncluded={false}
        />
        <div className={`prompt-cards-container 
           ${!isSmallThumbnail && layout ? layoutDropdownValues.find((l) => l.id === layout).className : ''}`}
        >
          {isSmallThumbnail && promptCards.length === 1 && (
            <ThumbnailCard
              id={`flexi-matching-layout-prompt-card-${id}`}
              key={`flexi-matching-layout-prompt-card-key-${id}`}
              text={promptCards[0].text}
              image={promptCards[0].image}
              isSmallThumbnail={isSmallThumbnail}
            />
          )}
          {isSmallThumbnail && promptCards.length > 1 && (
            <div>
              {promptCards.length} cards
            </div>
          )}
          {!isSmallThumbnail && (
            promptCards.map((p, index) => (
              <ThumbnailCard
                id={`flexi-matching-layout-prompt-card-${index}-${id}`}
                key={`flexi-matching-layout-prompt-card-key-${index}-${id}`}
                style={{ gridArea: `card-${index + 1}` }}
                text={p.text}
                image={p.image}
                isSmallThumbnail={isSmallThumbnail}
              />
            ))
          )}
        </div>
        <ThumbnailCard
          id={`flexi-matching-layout-sub-text-${id}`}
          text={subText}
          isSmallThumbnail={isSmallThumbnail}
          className='flexi-matching-sub-text'
          isImageIncluded={false}
        />
      </div>
      <div className='response-area'>
        <div className='submit-response-area'>
          <ThumbnailCard
            id={`flexi-matching-layout-submit-text-${id}`}
            text={submitAnswerText || defaultSubmitText}
            isSmallThumbnail={isSmallThumbnail}
            className='flexi-matching-submit-text'
            isImageIncluded={false}
          />
        </div>
        <div className='response-cards-container'>
          {isSmallThumbnail && (responseCards.slice(0, MAX_RESPONSE_IN_SMALL_MODE).map((p, index) => (
            <ThumbnailCard
              id={`flexi-matching-layout-response-card-${index}-${id}`}
              key={`flexi-matching-layout-response-card-key-${index}-${id}`}
              className='flexi-matching-response-card'
              text={p.text}
              image={p.image}
              isSmallThumbnail={isSmallThumbnail}
            />
          )))}

          {isSmallThumbnail && responseCards.length > MAX_RESPONSE_IN_SMALL_MODE && (
            <div className='response-more-card-text'>
              {responseCards.length - MAX_RESPONSE_IN_SMALL_MODE} more cards
            </div>
          )}

          {!isSmallThumbnail && (responseCards.map((p, index) => (
            <ThumbnailCard
              id={`flexi-matching-layout-response-card-${index}-${id}`}
              key={`flexi-matching-layout-response-card-key-${index}-${id}`}
              className='flexi-matching-response-card'
              text={p.text}
              image={p.image}
              isSmallThumbnail={isSmallThumbnail}
            />
          )))}
        </div>
      </div>
    </div>
  );
}

FlexibleMatchingThumbnail.defaultProps = {
  promptText: null,
  subText: null,
  isSmallThumbnail: false,
  layout: layoutDropdownValues[0].className,
  promptCards: [{}],
  responseCards: [{}, {}],
  submitAnswerText: null,
};

FlexibleMatchingThumbnail.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  promptText: PropTypes.object,
  subText: PropTypes.object,
  layout: PropTypes.string,
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  submitAnswerText: PropTypes.object,
};

import { useEffect, useState } from 'react';
import { searchTeacherCourseLessons } from '../../../../../../services/CourseService';
import { copyLessonMultiplePagesToAnotherLesson, getAuthoredLessons } from '../../../../../../services/LessonService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { flatten } from '../../../../../../utils/ArrayUtils';
import { mapLessonsIntoSubject } from '../../../../../../utils/LessonsMapping';
import Logger from '../../../../../../utils/Logger';
import { transformTeacherCourseLessons } from '../../../../../courses/components/search-bar-actions/SearchActionHooks';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { FILTER_STATUS_FOR_COPY } from '../../../../../myclassroom/components/authored-lessons/AuthoredLessonHooks';
import { filterSavablePagesData } from '../../LessonStepperSaveHelper';

export const defaultSelectedFilterStatus = FILTER_STATUS_FOR_COPY[2].value;

export const useHandleDialog = ({ handleOnTabChange }) => {
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
    handleOnTabChange(null, 0);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return {
    open,
    handleOnClose,
    openDialog,
  };
};

export const useHandleTab = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleOnTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return {
    tabValue,
    handleOnTabChange,
  };
};

export const usePrepareSearchLessonsData = ({ open }) => {
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { authoredLessonDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const loadSearchLessonsData = (pendingStatusValue, searchCriteria) => {
    try {
      callRemoteServiceWrapper(async () => {
        if ([FILTER_STATUS_FOR_COPY[0].value, FILTER_STATUS_FOR_COPY[1].value].includes(pendingStatusValue)) {
          const result = await getAuthoredLessons(pendingStatusValue);
          const subjectListWithLessons = mapLessonsIntoSubject(result, user);
          authoredLessonDomain.updateSearchLessonsData({
            lessons: subjectListWithLessons,
            included: result.included,
          });
        } else if ([FILTER_STATUS_FOR_COPY[2].value].includes(pendingStatusValue)) {
          if (
            !searchCriteria
            || Boolean(!searchCriteria.subjectId)
            || Boolean(!searchCriteria.courseId)
            || Boolean(!searchCriteria.lessonLevel)
            || Boolean(!searchCriteria.unitId)
            || Boolean(!searchCriteria.topicId)
          ) {
            return;
          }

          const promises = [];
          promises.push(searchTeacherCourseLessons({
            ...searchCriteria,
            autoGeneratedOnly: true,
          }));

          if (user.userModerator) {
            promises.push(searchTeacherCourseLessons(searchCriteria));
          }

          const promiseResolve = await Promise.all(promises);
          const autoGeneratedOnlyResult = promiseResolve[0];
          const autoGeneratedLessons = {
            lessons: [{
              subject: 'Auto Generated Lessons',
              lessons: autoGeneratedOnlyResult.data,
            }],
            included: [],
          };

          let lessonsToUpdate = autoGeneratedLessons;
          if (promiseResolve.length > 1) {
            const teacherCourseLessonsResult = promiseResolve[1];
            lessonsToUpdate = transformTeacherCourseLessons({ result: teacherCourseLessonsResult, user });
            lessonsToUpdate.lessons.push(autoGeneratedLessons.lessons[0]);
          }
          authoredLessonDomain.updateSearchLessonsData({
            lessons: lessonsToUpdate.lessons,
            included: lessonsToUpdate.included,
          });

          // const lessonsToUpdate = transformTeacherCourseLessons({ result: teacherCourseLessonsResult, user });
          // lessonsToUpdate.lessons.push({ subject: 'Auto Generated Lessons', lessons: autoGeneratedOnlyResult.data });
          // authoredLessonDomain.updateSearchLessonsData({
          //   lessons: lessonsToUpdate.lessons,
          //   included: lessonsToUpdate.included,
          // });
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (open) {
      loadSearchLessonsData(defaultSelectedFilterStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    loading,
    loadSearchLessonsData,
  };
};

export const useHandleLessonSelect = ({
  open,
}) => {
  const [selectedLessonId, setSelectedLessonId] = useState();

  useEffect(() => {
    setSelectedLessonId('');
  }, [open]);

  const handleOnLessonSelect = (lesson) => {
    setSelectedLessonId(lesson.id === selectedLessonId ? '' : lesson.id);
  };

  return {
    selectedLessonId,
    handleOnLessonSelect,
  };
};

export const useCopyPageToAnotherLesson = ({
  selectedLessonId,
  handleOnClose,
  selectedLessonPageIndexes,
}) => {
  const { uiDomain, authoredLessonDomain, lessonDataMaintenanceDomain } = useDomain();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const copyPageToAnotherLesson = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const { lessons } = authoredLessonDomain.domainData.searchLessonsData;
        const lesson = flatten(lessons
          .map((l) => l.lessons))
          .find((l) => l.id === selectedLessonId);

        const { pagesData } = lessonDataMaintenanceDomain.domainData;
        const pageData = selectedLessonPageIndexes.map((s) => pagesData.pages[s]);
        const savablePagesData = filterSavablePagesData({ pages: [pageData] });
        await copyLessonMultiplePagesToAnotherLesson({
          pages: savablePagesData.pages[0],
          toLesson: lesson,
        });
        await uiDomain.showSnackbar(true, 'success', 5000, `Copy ${selectedLessonPageIndexes.length} page${selectedLessonPageIndexes.length > 1 ? 's' : ''} to lesson ${lesson.attributes.name}`);
      });
      handleOnClose();
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    copyPageToAnotherLesson,
    saving: loading,
  };
};

export const useHandleLessonPagesSelection = ({
  currentIndex,
  open,
}) => {
  const [selectedLessonPageIndexes, setSelectedLessonPageIndexes] = useState([currentIndex]);
  useEffect(() => {
    if (open) {
      setSelectedLessonPageIndexes([currentIndex]);
    }
  }, [currentIndex, open]);

  const handleOnSelectePage = (values) => {
    setSelectedLessonPageIndexes(values);
  };

  return {
    selectedLessonPageIndexes,
    handleOnSelectePage,
  };
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import LessonAssignmentOrdering from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/educationplan/forms/lessonsassignments/LessonAssignmentOrdering';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { orderLessons } from '../../../../../../services/CourseService';
import './ReOrderLessonsAction.scss';

const ReOrderLessonsAction = ({ rowIndex }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonData } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;

  const getLessonData = () => {
    if (lessonData && lessonData.lessons[rowIndex] && lessonData.lessons[rowIndex].lessons) {
      return lessonData.lessons[rowIndex].lessons;
    }
    return [];
  };
  const [lessonOrdering, setLessonOrdering] = useState(() => getLessonData());

  useEffect(() => {
    if (openDialog) {
      setLessonOrdering(getLessonData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const checkHasChanged = () => {
    let result = false;
    lessonData.lessons[0].lessons.forEach((l, index) => {
      result = result || l.id !== lessonOrdering[index].id;
    });
    return result;
  };

  const { uiDomain } = useDomain();
  const {
    loading: saving,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnSave = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const lessonsPayload = lessonOrdering.map((l, index) => ({
          lessonId: l.id,
          sequenceNum: index + 1,
        }));
        await orderLessons({ lessonOrders: lessonsPayload }, searchCriteria.topicId);

        lessonData.lessons[0].lessons = lessonOrdering;
        await teacherCourseDomain.updateLessonListData(lessonData);
        await uiDomain.showSnackbar(true, 'info', 3000, 'Lessons courses have been updated.');
        setOpenDialog(false);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return (
    <>
      <Button
        variant='contained'
        className='btn-primary reorder-lesson-action'
        onClick={() => setOpenDialog(true)}
        startIcon={<EditIcon />}
        disabled={!lessonData || !lessonData.lessons[0] || !lessonData.lessons[0].lessons || !lessonData.lessons[0].lessons.length === 0}
      >
        SET ACTIVITY ORDER
      </Button>
      {openDialog && (
        <CustomDialog
          className='teacher-course-set-lesson-order-dialog'
          openDialog={openDialog}
          onClose={() => setOpenDialog(false)}
          displayCloseIcon={false}
          title='Drag and Drop to Order Lessons'
          content={(
            <div className='dialog-content'>
              <LessonAssignmentOrdering
                lessons={lessonOrdering}
                onDragEnd={(result) => {
                  setLessonOrdering(result);
                }}
              />
            </div>
          )}
          actions={[
            {
              title: 'Cancel',
              color: 'primary',
              className: 'btn-danger action-button wide-button left-button',
              onClick: () => { setOpenDialog(false); },
              disabled: saving,
              loading: saving,
            },
            {
              title: 'Save',
              color: 'primary',
              className: 'btn-primary wide-button action-button',
              disabled: !checkHasChanged() || saving,
              onClick: () => { handleOnSave(); },
              loading: saving,
            },
          ]}
        />
      )}
    </>
  );
};

ReOrderLessonsAction.propTypes = {
  rowIndex: PropTypes.number.isRequired,
};

export default ReOrderLessonsAction;

import {
  Button,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import LoopIcon from '@mui/icons-material/Loop';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA, ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA, ACTIVITY_TYPE_GAME_ACTIVITY } from '../../../../../AppConstants';
import FullScreenDialogbox from '../../../../../components/dialog/FullScreenDialogbox';
import { hideAccessibeChatIcon } from '../../../../../services/AccessibeService';
import { analyticsAnswerFreeForm } from '../../../../../services/LessonPlayService';
import LiveChatService from '../../../../../services/LiveChatService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import LessonActivityPlay from '../../../../lessonplay/components/lesson-activity-play/LessonActivityPlay';
import { useGetFeedbackChat, useHandleChatWidget } from '../../../../lessonplay/components/lesson-activity-play/LessonPlayFeedbackChatHooks';
import LessonLoader from '../../../../lessonplay/components/LessonLoader';
import { useLessonPlay } from '../../../../lessonplay/context/LessonPlayContextProvider';
import { findFfrPage, getAllFfrPage } from '../../../../lessons/lessonmaintenance/hooks/LessonMaintenanceHooks';
import { UNDER_REVIEW_ID } from '../../../components/tracking/actions/LessonTrackingConstants';
import UpdateReviewStatusAction from './freeform-answers/actions/UpdateReviewStatusAction';
import ViewAnswerAction from './freeform-answers/actions/ViewAnswerAction';
import './ViewStudentAnswersAction.scss';
import { usePrepareSnapshotLessonData } from './ViewStudentAnswersActionHooks';
import ViewStudentAnswersNotification from './ViewStudentAnswersNotification';
import ChatWidget from '../../../../../components/chat/ChatWidget';

const BOOK_ACTIVITY = [ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA, ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA];

const ViewStudentAnswersAction = ({
  detailedReportData,
  onUpdateReviewStatusSuccess,
  studentId,
  feedbackChat,
  onChatWidgetOpen,
  showIcons,
  onLessonExit,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    loading,
    getSnapshotLessonData,
    lessonPagesData,
    resetData,
    currentPageIndex,
    answerFreeFormData,
    refreshAnswerFreeFormData,
  } = usePrepareSnapshotLessonData({
    detailedReportData,
    setOpenDialog,
  });

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const {
    getFeedbackChat,
  } = useGetFeedbackChat({ studentHistoryId: detailedReportData.id, studentId, lessonId: detailedReportData.lessonId });

  const {
    chatOpen,
    handleOnChatOpen,
    dataRecords,
    handleOnChatSubmit,
    showBadge,
  } = useHandleChatWidget({ onChatWidgetOpen, studentHistoryId: detailedReportData.id });

  const handleOnOpen = () => {
    // Don't need any error handling for this call, just some analytics tracking
    try {
      analyticsAnswerFreeForm({
        studentId,
        studentHistoryId: detailedReportData.id,
      });
    } catch (e) {
      Logger.logError(e);
    }
    hideAccessibeChatIcon(true);
    LiveChatService.hideLiveChatIcon();
    setOpenDialog(true);
    getSnapshotLessonData();
    getFeedbackChat({
      showChatWidget: false,
      fetchData: true,
      studentId,
      lessonId: detailedReportData.lessonId,
      hideWidgetWhenNoFeedbackChat: false,
      teacherId: user.id,
    });
  };

  const handleOnClose = () => {
    hideAccessibeChatIcon(false);
    LiveChatService.showLiveChatIcon();
    setOpenDialog(false);
    resetData();
    onLessonExit();
  };

  const { lessonPlayDomain } = useLessonPlay();
  const { lessonPagesDataSnapshot } = lessonPlayDomain.domainData;

  const { parentAnswerFreeFormData, answerFreeFormData: answerFreeForm } = answerFreeFormData;

  let answerFreeFormDataOnPage = (answerFreeForm || []).find((a) => a.attributes.pageNumber - 1 === currentPageIndex);
  if (!answerFreeFormDataOnPage) {
    answerFreeFormDataOnPage = {
      attributes: {
        comments: '',
        studentHistoryId: detailedReportData.id,
      },
    };
  }

  const parentAnswerFreeFormDataOnPage = (parentAnswerFreeFormData || []).find((a) => a.attributes.pageNumber - 1 === currentPageIndex);

  const renderContent = () => {
    if (loading) {
      return <LessonLoader />;
    }
    if (lessonPagesData && lessonPagesData.length > 0) {
      const lessonPageData = lessonPagesDataSnapshot[currentPageIndex];
      const isFfrPage = findFfrPage({ pages: [lessonPageData] });
      const allFfrPages = getAllFfrPage({ pages: [lessonPageData] });
      return (
        <LessonActivityPlay
          showBottomLessonNavigator
          onLessonFinished={() => {
            handleOnClose();
          }}
          createAdditionalComponents={
            () => (
              <>
                {feedbackChat
                  && (
                    <ChatWidget
                      open={chatOpen}
                      onOpen={handleOnChatOpen}
                      title='Chat'
                      dataRecords={dataRecords}
                      onSubmit={handleOnChatSubmit}
                      showBadge={showBadge}
                      buttonMode
                      defaultOpen
                    />
                  )}
                {isFfrPage && detailedReportData.reviewStatus === UNDER_REVIEW_ID && (
                  <UpdateReviewStatusAction
                    answerLesson={detailedReportData}
                    onStatusUpdateSucccess={(newStatus) => {
                      onUpdateReviewStatusSuccess(newStatus);
                    }}
                    answerFreeFormData={answerFreeForm}
                    allFfrPages={allFfrPages}
                  />
                )}
                {
                  (isFfrPage) && (
                    <ViewAnswerAction
                      answerLesson={{ id: detailedReportData.lessonId }}
                      parentAnswerFreeFormData={parentAnswerFreeFormDataOnPage}
                      answerPageData={answerFreeFormDataOnPage}
                      pageNumber={currentPageIndex + 1}
                      lessonPageData={lessonPageData}
                      onSaveSuccess={() => {
                        refreshAnswerFreeFormData();
                      }}
                      readOnly={isFfrPage && detailedReportData.reviewStatus !== UNDER_REVIEW_ID}
                    />
                  )
                }
              </>
            )
          }
          onChatWidgetOpen={onChatWidgetOpen}
        />
      );
    }

    return null;
  };

  const getNotification = () => {
    const currentPage = lessonPagesData[currentPageIndex];
    if (!currentPage) {
      return null;
    }
    if (BOOK_ACTIVITY.includes(currentPage.activityType)) {
      const hasHotspots = currentPage.layoutConfig.findIndex((b) => b.hotspots && b.hotspots.length > 0) >= 0;
      if (hasHotspots) {
        return <ViewStudentAnswersNotification message='Hotspot Answer Review not yet available' />;
      }
    } else if (currentPage.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
      return <ViewStudentAnswersNotification message='Game Answer Review not yet available' />;
    }
    return null;
  };

  return (
    <>
      <Button
        className='view-student-answers-action'
        variant='text'
        data-test='view-student-answers-action'
        onClick={handleOnOpen}
      >
        {detailedReportData.lessonName}
        {showIcons && (
          <div className='view-student-answers-action-chat-icon'>
            {detailedReportData.feedbackLoop && (
              <LoopIcon className='icon' />
            )}
            {detailedReportData.chatNotification && (
              <ChatIcon className='icon' />
            )}
          </div>
        )}
      </Button>
      {openDialog && (
        <FullScreenDialogbox
          className='view-student-answers-lesson-play-dialog'
          open={openDialog}
          handleClose={handleOnClose}
          noTitle
        >
          {renderContent()}
          {getNotification()}
        </FullScreenDialogbox>
      )}
    </>
  );
};

ViewStudentAnswersAction.defaultProps = {
  onUpdateReviewStatusSuccess: () => null,
  studentId: undefined,
  feedbackChat: false,
  onChatWidgetOpen: () => null,
  showIcons: true,
  onLessonExit: () => null,
};

ViewStudentAnswersAction.propTypes = {
  detailedReportData: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    chatNotification: PropTypes.bool,
    originalStudentHistoryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    lessonName: PropTypes.string,
    reviewStatus: PropTypes.number,
    lessonId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    feedbackLoop: PropTypes.bool,
  }).isRequired,
  onUpdateReviewStatusSuccess: PropTypes.func,
  studentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  feedbackChat: PropTypes.bool,
  onChatWidgetOpen: PropTypes.func,
  showIcons: PropTypes.bool,
  onLessonExit: PropTypes.func,
};

export default ViewStudentAnswersAction;

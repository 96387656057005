import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import {
  AREA_INSTRUCTIONS,
  AREA_PROMPT_CARDS,
  AREA_RESPONSE_CARDS,
  SECTION_IMAGE,
  SECTION_TEXT,
  SECTION_AUDIO,
} from '../../../../../components/vizzle/page/common/CardEditorConstants';
import TextAndMediaFormatting from './editor/TextAndMediaFormatting';
import AddPhotoOrVideo from './editor/AddPhotoOrVideo';
import AddAudio from './editor/AddAudio';
import { useDomain } from '../../../../../states/AppDomainProvider';
import CategorizingEditor from '../../../../../components/vizzle/page/categorizing/CategorizingEditor';
import {
  createCategorizingResponse,
  getCategorizing,
  availableCategorizingKeys,
} from '../../../../../utils/activitytype/ActivityTypeInitialData';
import { useHandleNumberOfCardChanges } from '../../hooks/LessonMaintenanceHooks';
import {
  checkHasNon18FontSize,
  checkHasNonBoldFontWeight,
  checkHasNonJosefinSlab,
  getExceedCharLimitMessage,
  getTotalCharsLength,
  useHandleTextEditorWarning,
} from '../../hooks/TextEditorWarningHooks';

const initState = {
  selectedArea: AREA_INSTRUCTIONS,
  selectedSection: SECTION_IMAGE,
  selectedCardIndex: 0,
};

/**
 * Container for adding text, image, video and audio for Categorizing page
 */
export default function CategorizingTextAndMedia() {
  const { lessonDataMaintenanceDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const [currentSelectedData, setCurrentSelectedData] = useState({ ...initState });

  useEffect(() => {
    setCurrentSelectedData({ ...initState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedPagesData]);

  const {
    textEditorWarning,
    showTextEditorWarning,
    closeTextEditorWarning,
  } = useHandleTextEditorWarning();

  const onAreaAndSectionSelected = (selectedArea, selectedSection, selectedCardIndex = 0) => {
    setCurrentSelectedData({
      selectedArea,
      selectedSection,
      selectedCardIndex,
    });
  };

  const getText = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_INSTRUCTIONS:
        return selectedPage.instructions.text;
      default:
        // eslint-disable-next-line max-len
        return currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].text : null;
    }
  };

  const onTextApply = async (textLines) => {
    const newTextLines = textLines.map((e) => ({
      ...e,
    }));

    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      if (!selectedPage[currentSelectedData.selectedArea].text) {
        selectedPage[currentSelectedData.selectedArea].text = {};
      }
      selectedPage[currentSelectedData.selectedArea].text.lines = newTextLines;
    } else {
      const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
      if (!selectedCard.text) {
        selectedCard.text = {};
      }
      selectedCard.text.lines = newTextLines;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const onTextRemoved = async () => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      const card = {
        ...selectedPage[currentSelectedData.selectedArea],
        text: null,
      };
      selectedPage[currentSelectedData.selectedArea] = card;
    } else {
      selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].text = null;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    closeTextEditorWarning();
  };

  const onTextChange = (textLines) => {
    if (!user.userModerator && !user.unitCreator) {
      closeTextEditorWarning();
      return;
    }

    const hasNonJosefinSlab = checkHasNonJosefinSlab(textLines);
    if (hasNonJosefinSlab) {
      closeTextEditorWarning();
      return;
    }

    const hasNon18FontSize = checkHasNon18FontSize(textLines);
    if (hasNon18FontSize) {
      closeTextEditorWarning();
      return;
    }

    if (currentSelectedData.selectedArea === AREA_INSTRUCTIONS) {
      const charLimit = 94;
      const totalCharsLength = getTotalCharsLength(textLines);
      if (totalCharsLength > charLimit) {
        showTextEditorWarning(getExceedCharLimitMessage(charLimit));
        return;
      }
    } else if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS) {
      const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
      if (!hasNonBoldFontWeight) {
        const charLimit = 16;
        const totalCharsLength = getTotalCharsLength(textLines);
        if (totalCharsLength > charLimit) {
          showTextEditorWarning(getExceedCharLimitMessage(charLimit));
          return;
        }
      }
    } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS) {
      const charLimit = 14;
      const totalCharsLength = getTotalCharsLength(textLines);
      if (totalCharsLength > charLimit) {
        showTextEditorWarning(getExceedCharLimitMessage(charLimit));
        return;
      }
    }
    closeTextEditorWarning();

    // if (currentSelectedData.selectedArea === AREA_INSTRUCTIONS) {
    //   const lineLimit = 2;
    //   if (textLines && textLines.length > lineLimit) {
    //     showTextEditorWarning(getExceedLineLimit(lineLimit));
    //     return;
    //   }
    //   const charLimit = 47;
    //   const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //   if (exceedCharLimit) {
    //     showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //     return;
    //   }
    // } else if (currentSelectedData.selectedArea === AREA_PROMPT_CARDS && selectedPage[currentSelectedData.selectedArea].filter((card) => !card.hide).length === 8) {
    //   const hasNonBoldFontWeight = checkHasNonBoldFontWeight(textLines);
    //   if (!hasNonBoldFontWeight) {
    //     // Response card
    //     const lineLimit = 2;
    //     if (textLines && textLines.length > lineLimit) {
    //       showTextEditorWarning(getExceedLineLimit(lineLimit));
    //       return;
    //     }
    //     const charLimit = 8;
    //     const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //     if (exceedCharLimit) {
    //       showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //       return;
    //     }
    //   }
    // } else if (currentSelectedData.selectedArea === AREA_RESPONSE_CARDS && selectedPage[currentSelectedData.selectedArea].filter((card) => !card.hide).length === 4) {
    //   const lineLimit = 2;
    //   if (textLines && textLines.length > lineLimit) {
    //     showTextEditorWarning(getExceedLineLimit(lineLimit));
    //     return;
    //   }
    //   const charLimit = 7;
    //   const exceedCharLimit = checkCharsLimit(textLines, charLimit);
    //   if (exceedCharLimit) {
    //     showTextEditorWarning(getExceedCharLimitMessage(charLimit));
    //     return;
    //   }
    // }
    // closeTextEditorWarning();
  };

  useEffect(() => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      const { text } = selectedPage.instructions;
      onTextChange(text && text.lines);
    } else {
      const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
      if (!selectedCard) {
        return;
      }
      const { text } = selectedCard;
      onTextChange(text && text.lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedData, selectedPage]);

  const getImage = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_INSTRUCTIONS:
        return selectedPage.instructions.image;
      default:
        // eslint-disable-next-line max-len
        return currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].image : null;
    }
  };

  const onImageAndVideoSelected = async (media) => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      selectedPage[currentSelectedData.selectedArea].image = media.selectedImageUrl;
    } else {
      const selectedCard = selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex];
      selectedCard.image = media.selectedImageUrl;
      selectedCard.thumbNailImage = media.selectedImageUrl;
      selectedCard.video = media.selectedVideoUrl;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const getAudio = () => {
    switch (currentSelectedData.selectedArea) {
      case AREA_INSTRUCTIONS:
        return selectedPage.instructions.audio;
      default:
        // eslint-disable-next-line max-len
        return currentSelectedData.selectedCardIndex <= selectedPage[currentSelectedData.selectedArea].length - 1 ? selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].audio : null;
    }
  };

  const handleOnAudioApply = async (audioData) => {
    if (AREA_INSTRUCTIONS === currentSelectedData.selectedArea) {
      selectedPage[AREA_INSTRUCTIONS].audio = audioData;
    } else {
      selectedPage[currentSelectedData.selectedArea][currentSelectedData.selectedCardIndex].audio = audioData;
    }
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  const handleNumberOfCardChange = useHandleNumberOfCardChanges();

  const promptCards = selectedPage.promptCards.filter((card) => !card.hide);
  const promptCardKeys = promptCards.map((p) => p.key);
  const keys = [availableCategorizingKeys[0], ...availableCategorizingKeys.filter((a) => promptCardKeys.includes(a))];

  const promptCardsConfig = {
    promptCards,
    onNumberOfPromptCardChange: async (e) => {
      const { value } = e.target;
      await handleNumberOfCardChange({
        numberOfCard: value,
        currentNumberOfCards: promptCards.length,
        cardType: AREA_PROMPT_CARDS,
        templateData: getCategorizing(value).promptCards,
      });

      if (promptCards.length > value) {
        setCurrentSelectedData({
          ...currentSelectedData,
          selectedCardIndex: value - 1,
        });
      }

      if (value < promptCards.length) {
        selectedPage.responseCards.forEach((r) => {
          const prompts = selectedPage.promptCards.filter((card) => !card.hide).map((p) => p.key);
          const currentKeys = [availableCategorizingKeys[0], ...availableCategorizingKeys.filter((a) => prompts.includes(a))];

          if (!currentKeys.includes(r.key)) {
            // eslint-disable-next-line
            r.key = availableCategorizingKeys[0];
          }
          const newPageData = {
            ...selectedPage,
            [AREA_RESPONSE_CARDS]: [...selectedPage[AREA_RESPONSE_CARDS]],
          };
          lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
        });
      }
    },
  };

  const responseCards = selectedPage.responseCards.filter((card) => !card.hide);

  const responseCardsConfig = {
    responseCards,
    availableCategorizingKeys: keys,
    onNumberOfResponseCardChange: async (e) => {
      const { value } = e.target;
      await handleNumberOfCardChange({
        numberOfCard: value,
        currentNumberOfCards: responseCards.length,
        cardType: AREA_RESPONSE_CARDS,
        templateData: createCategorizingResponse(1, value),
      });
      if (responseCards.length > value) {
        setCurrentSelectedData({
          ...currentSelectedData,
          selectedCardIndex: value - 1,
        });
      }
    },
    onKeyChanged: async (e, cardIndex) => {
      e.stopPropagation();
      selectedPage.responseCards[cardIndex].key = e.target.value;
      await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
    },
  };

  return (
    <Box>
      <br />
      <Grid container spacing={3} wrap='nowrap' style={{ height: window.innerWidth <= 1024 ? '68vh' : '71vh' }}>
        <Grid item xs={7} className='panel-with-shadow margin-right-medium' style={{ height: 'inherit', padding: '12px' }}>
          <CategorizingEditor
            selectedArea={currentSelectedData.selectedArea}
            selectedSection={currentSelectedData.selectedSection}
            selectedCardIndex={currentSelectedData.selectedCardIndex}
            onAreaAndSectionSelected={onAreaAndSectionSelected}
            instructions={selectedPage.instructions}
            promptCardsConfig={promptCardsConfig}
            responseCardsConfig={responseCardsConfig}
          />
        </Grid>
        <Grid item xs={6} className='panel-with-shadow' style={{ height: 'inherit', padding: '12px' }}>
          <Box height='100%' className='center'>
            {currentSelectedData.selectedSection === SECTION_TEXT && (
              <Box height='100%'>
                <TextAndMediaFormatting
                  text={getText()}
                  onTextApply={onTextApply}
                  onTextRemoved={onTextRemoved}
                  onTextChange={onTextChange}
                  textEditorWarning={textEditorWarning}
                />
                <br />
              </Box>
            )}
            {(currentSelectedData.selectedSection === SECTION_IMAGE) && (
              <AddPhotoOrVideo
                image={getImage()}
                onSelect={onImageAndVideoSelected}
                onRemove={() => { onImageAndVideoSelected({}); }}
                isVideoIncluded={AREA_RESPONSE_CARDS === currentSelectedData.selectedArea}
              />
            )}
            {currentSelectedData.selectedSection === SECTION_AUDIO && (
              <AddAudio
                audio={getAudio()}
                onAudioApply={(audioData) => { handleOnAudioApply(audioData); }}
                onAudioRemoved={() => { handleOnAudioApply(null); }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

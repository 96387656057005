/* eslint-disable react/react-in-jsx-scope */
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAdmin } from '../../../context/AdminContextProvider';

const FilterStudentAction = () => {
  const { adminStudentsDomain } = useAdmin();
  const { filterActive } = adminStudentsDomain.domainData;
  const handleCheckboxChange = (e) => {
    adminStudentsDomain.updateFilterActive(e.target.checked);
  };

  return (
    <FormControlLabel
      className='filter-margin'
      control={
        (
          <Checkbox
            className='vizzle-checkbox'
            checked={filterActive}
            onChange={handleCheckboxChange}
            color='primary'
          />
        )
      }
      label='Active'
    />
  );
};

export default FilterStudentAction;

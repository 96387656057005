import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import { useGetCurrentStudent } from '../../../hooks/StudentHooks';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { useGetTableConfig, usePrepareReviewAndFeedback } from './ReviewAndFeedbackHook';
import './ReviewAndFeedback.scss';
import '../../common/actions/view-student-answers/ViewStudentTree.scss';
import { getReportDataToDisplay } from '../../common/actions/view-student-answers/ViewStudentAnswersUtils';

const ReviewAndFeedback = () => {
  const currentStudent = useGetCurrentStudent();
  const {
    loading,
    fetchReviewAndFeedbackData,
  } = usePrepareReviewAndFeedback(currentStudent);

  const { myStudentDomain } = useStudentContext();
  const tableConfig = useGetTableConfig({
    fetchReviewAndFeedbackData,
  });

  const {
    reviewAndFeedback,
  } = myStudentDomain.domainData;

  const reportDataToDisplay = getReportDataToDisplay(reviewAndFeedback.data);

  return (
    <div className='review-and-feedback'>
      <SearchAndSortableTable
        config={tableConfig}
        tableData={reportDataToDisplay}
        filterable={false}
        initTableState={{
          order: 'asc',
          orderBy: 'startTime',
        }}
        loading={loading}
        noDataMessage={reviewAndFeedback.data.length === 0 && !loading ? 'No data available' : ''}
        data-test='review-and-feedback-table'
      />

    </div>
  );
};

export default ReviewAndFeedback;

import { useEffect, useState } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { updateLessonStatus } from '../../../../../services/LessonService';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { APPROVED_FOR_COURSES, LESSON_STATUS_PENDING_NEXTGEN } from '../../../../../AppConstants';

/** 500 characters */
const DESCRIPTION_MAX_LENGTH = 500;

export const LESSON_STATUS = [
  {
    label: 'APPROVED',
    value: 'APPROVED',
  },
  {
    label: 'DENIED',
    value: 'DENIED',
  },
  {
    label: 'Approve for Courses',
    value: APPROVED_FOR_COURSES,
  },
];
export const useOnChangeHandler = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const [moderatorComments, setModeratorComments] = useState(lessonData.attributes.moderatorComments);
  const [approvalStatus, setApprovalStatus] = useState(lessonData.attributes.approvalStatus);

  const handleModeratorCommentsChanged = (event) => {
    const { value } = event.target;
    setModeratorComments(value.substring(0, DESCRIPTION_MAX_LENGTH));
  };

  const handleModeratorCommentsBlur = () => {
    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        moderatorComments,
      },
    };

    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData, false);
  };

  const handleStatusChange = (event) => {
    setApprovalStatus(event.target.value);
  };

  return {
    approvalStatus,
    moderatorComments,
    handleModeratorCommentsChanged,
    handleModeratorCommentsBlur,
    handleStatusChange,
  };
};

export const useHandleSubmit = ({
  setDisableSubmit,
  approvalStatus,
  moderatorComments,
}) => {
  const { lessonDataMaintenanceDomain, userDomain, uiDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const { user } = userDomain.domainData;

  const { loading, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  useEffect(
    () => () => {
      uiDomain.closeSnackbar();
    },
    // eslint-disable-next-line
    []
  );

  const handleOnSubmitClick = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        if (user.userModerator) {
          const lessonDataSave = lessonDataMaintenanceDomain.domainData.lessonData;
          const id = lessonDataSave.attributes.uuid;
          if (approvalStatus || moderatorComments) {
            let dataToSave = {
              status: approvalStatus,
              comments: moderatorComments,
            };
            if (approvalStatus === APPROVED_FOR_COURSES) {
              const {
                attributes: {
                  courseId,
                  unitId,
                  topicId,
                  days,
                  lessonType,
                },
              } = lessonData;
              const approvedCourseFormData = {
                courseId,
                unitId,
                topicId,
                days,
                lessonType,
              };
              dataToSave = {
                ...dataToSave,
                ...approvedCourseFormData,
              };
            }
            await updateLessonStatus(id, dataToSave);
            const updatedLessonData = {
              ...lessonData,
              attributes: {
                ...lessonData.attributes,
                approvalStatus,
                moderatorComments,
              },
            };
            await lessonDataMaintenanceDomain.updateLessonData(
              updatedLessonData,
              false,
            );
            setDisableSubmit(![LESSON_STATUS_PENDING_NEXTGEN, APPROVED_FOR_COURSES].includes(lessonData.attributes.approvalStatus));
            await uiDomain.showSnackbar(
              true,
              'info',
              3000,
              'The activity has been submitted successfully.',
            );
          }
        }
      });
    } catch (error) {
      Logger.logError({
        ERROR: error,
      });
    }
  };

  return {
    loading,
    handleOnSubmitClick,
  };
};

import PropTypes from 'prop-types';
import { CircularProgress, Paper, Typography } from '@mui/material';
import NumberWithChevronInput from '../../../../../../components/input/NumberWithChevronInput';
import './GenerateLessonsDialog.scss';
import { useGetTopicTemplate } from './GenerateLessonsDialogHooks';
import './GenerateLessonsForm.scss';

const GenerateLessonsForm = ({
  value,
  onChange,
  subjectId,
}) => {
  const {
    loading,
    topicTemplate,
  } = useGetTopicTemplate({
    subjectId,
  });

  return (
    <div className='generate-lessons-form' data-test='generate-lessons-form'>
      {loading && (
        <div className='loading-container'>
          <CircularProgress className='loading-icon' />
          <Typography>Loading templates</Typography>
        </div>
      )}
      {!loading && topicTemplate.length > 0 && (
        <div className='topic-template-container' data-test='topic-template-container'>
          {topicTemplate.map((template) => (
            <Paper elevation={5} className='template-lesson-container' key={`template-container-lesson-id-${template.lessonId}`} data-test={`template-container-lesson-id-${template.lessonId}`}>
              <div className='template-lesson-card' data-test={`template-lesson-id-${template.lessonId}`}>
                <Typography>{template.templateName}</Typography>
              </div>
              <NumberWithChevronInput
                value={value[template.lessonId] ? value[template.lessonId].numberOfCopies : 0}
                onChange={(newValue) => {
                  const updatedValue = {
                    ...value,
                    [template.lessonId]: {
                      numberOfCopies: newValue,
                      templateName: template.templateName,
                    },
                  };
                  onChange(updatedValue);
                }}
              />
            </Paper>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenerateLessonsForm;

GenerateLessonsForm.defaultProps = {
  value: {},
  onChange: () => null,
};

GenerateLessonsForm.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

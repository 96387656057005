import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@mui/material';
import './QuestionKey.scss';

/**
 * Sub-component for displaying Question Key in the Matching Editor
 */
export default function QuestionKey({ value, onKeyChange }) {
  const [textValue, setTextValue] = useState(value || '');
  useEffect(() => {
    if (value) {
      setTextValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    setTextValue(newValue);
  };

  const handleBlur = () => {
    onKeyChange(textValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onKeyChange(textValue);
    }
  };

  return (
    <div className='question-key-editor'>
      <TextField
        autoFocus={!textValue}
        placeholder='Question Key'
        className='question-key-text-field'
        value={textValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        margin='dense'
        variant='outlined'
      />
    </div>
  );
}

QuestionKey.defaultProps = {
  value: '',
  onKeyChange: () => {},
};

QuestionKey.propTypes = {
  value: PropTypes.string,
  onKeyChange: PropTypes.func,
};

import {
  reinforcerObject,
} from './ReinforcerV2Hooks';

export default {
  playReinforcer: (reinforceId) => {
    reinforcerObject.setReinforceState({
      open: true,
      reinforceId,
    });
  },
  playRandomReinforcer: (userReinforcers = []) => {
    if (!userReinforcers || userReinforcers.length === 0) {
      return Promise.resolve();
    }
    const reinforcer = userReinforcers[Math.floor(Math.random() * userReinforcers.length)];
    const promise = new Promise((resolve, reject) => {
      reinforcerObject.setReinforceState({
        open: true,
        reinforceId: reinforcer.id,
        promise: {
          resolve,
          reject,
        },
      });
    });
    return promise;
  },
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { CSV_FILE_TYPE, useHandleFileUpload } from '../ImportsActionHooks';
import './ImportUsersAction.scss';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import CustomTooltip from '../../../../../../components/card/CustomTooltip';
import '../../../buttons/Buttons.scss';
import ImportUsersResult from './ImportUsersResult';

const ImportUsersAction = ({
  buttonLabel,
  id,
  ariaLabel,
  uploadServiceFunction,
  uploadingMessage,
  onUploadSuccess,
  disabled,
  additionalMessage,
}) => {
  const [dialogData, setDialogData] = useState({
    open: false,
    data: {},
  });

  const {
    handleOnFileSelect,
  } = useHandleFileUpload({
    uploadServiceFunction,
    setDialogData,
    uploadingMessage,
    onUploadSuccess,
  });

  return (
    <>
      <input
        accept={CSV_FILE_TYPE}
        className='import-users-action-input'
        id={id}
        type='file'
        onChange={handleOnFileSelect}
        value=''
        disabled={disabled}
      />
      <label htmlFor={id}>
        <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
          <Button
            variant='contained'
            className='btn-primary admin-action-button'
            startIcon={<AttachmentIcon />}
            component='span'
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </CustomTooltip>
      </label>
      {dialogData.open && (
        <CustomDialog
          openDialog={dialogData.open}
          className='import-licenses-action-dialog'
          onClose={() => {
            setDialogData({
              open: false,
              data: {},
            });
          }}
          content={(
            <ImportUsersResult
              result={dialogData.data.result}
              failures={dialogData.data.failures}
              updates={dialogData.data.updates}
              additionalMessage={additionalMessage}
            />
          )}
          displayCloseIcon
          actions={[{
            onClick: () => {
              setDialogData({
                open: false,
                data: {},
              });
            },
            className: 'btn-primary action-button',
            title: 'Ok',
          },
          ]}
        />
      )}
    </>
  );
};

ImportUsersAction.defaultProps = {
  buttonLabel: 'Import',
  ariaLabel: '',
  onUploadSuccess: () => null,
  disabled: true,
  additionalMessage: '',
};

ImportUsersAction.propTypes = {
  buttonLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  uploadServiceFunction: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  uploadingMessage: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  additionalMessage: PropTypes.string,
};

export default ImportUsersAction;

import PropTypes from 'prop-types';
import ConfirmationDialog from '../../../../../../../components/dialog/ConfirmationDialog';

const AllowCustomResponseConfirmation = ({
  open,
  onConfirm,
  onClose,
}) => (
  <ConfirmationDialog
    open={open}
    title='Allow custom response'
    contentText='Are you sure you want to change to Custom Student Response?'
    confirmLabel='Yes'
    cancelLabel='No'
    onConfirm={onConfirm}
    onCancel={onClose}
  />
);

AllowCustomResponseConfirmation.defaultProps = {
  open: false,
  onConfirm: () => null,
  onClose: () => null,
};

AllowCustomResponseConfirmation.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default AllowCustomResponseConfirmation;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';

/** 500 characters */
const DESCRIPTION_MAX_LENGTH = 500;

/**
 * Container for Lesson Description in Lesson Save screen
 *
 * @export
 */
export default function LessonDescriptionEditor({
  rows,
  rowsMax,
}) {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;
  const [description, setDescription] = useState(lessonData.attributes.description);

  const handleDescriptionChanged = (event) => {
    const { value } = event.target;
    setDescription(value.substring(0, DESCRIPTION_MAX_LENGTH));
  };

  const handleDescriptionBlur = () => {
    if (description === lessonData.attributes.description) {
      return;
    }
    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        description,
      },
    };

    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  return (
    <Box width='100%' data-test='lesson-description-editor'>
      <Typography variant='h6'>
        Description
      </Typography>
      <TextField
        width='10px'
        variant='outlined'
        onChange={handleDescriptionChanged}
        onBlur={handleDescriptionBlur}
        multiline
        minRows={rows}
        maxRows={rowsMax}
        fullWidth
        value={description || ''}
        className='vizzle-lesson-save-description-text-field'
      />
    </Box>
  );
}

LessonDescriptionEditor.defaultProps = {
  rows: 6,
  rowsMax: 6,
};

LessonDescriptionEditor.propTypes = {
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
};

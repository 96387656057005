import { addNewUnit } from '../../../../services/CourseService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../context/TeacherCourseContextProvider';

export const useAddNewUnit = ({
  courseId,
  setOpenDialog,
  setOpenAddTopicDialog,
}) => {
  const {
    loading: saving,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const { teacherCourseDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;

  const handleOnSave = async (unitName) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const unit = await addNewUnit(courseId, unitName);
        setOpenDialog(false);
        uiDomain.showSnackbar(true, 'info', 3000, `A new unit '${unitName}' is successfully added.`);
        const course = lessonCourses.find((lc) => lc.id === courseId);
        course.units.push(unit);
        teacherCourseDomain.updateLessonCourses(lessonCourses);
        setOpenAddTopicDialog({
          open: true,
          unitId: unit.id,
        });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saving,
    handleOnSave,
  };
};

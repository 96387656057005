import { useEffect } from 'react';
import { useTeacherCurriculum } from '../../context/TeacherCurriculumContextProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { getLessonByUuids } from '../../../../../services/LessonService';
import Logger from '../../../../../utils/Logger';

export const useLessonSelect = (teacherCurriculumDomain) => {
  // const { teacherCurriculumDomain } = useTeacherCurriculum();

  useEffect(() => async () => {
    await teacherCurriculumDomain.updateSelectedLessonIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnLessonSelect = async (lesson) => {
    const { selectedLessonIds } = teacherCurriculumDomain.domainData;
    const matchedLessonId = selectedLessonIds.findIndex((selectedId) => selectedId === lesson.id);
    let updatedSelectedIds = [...selectedLessonIds];
    if (matchedLessonId >= 0) {
      updatedSelectedIds = selectedLessonIds.filter((selectedId) => selectedId !== lesson.id);
    } else {
      updatedSelectedIds.push(lesson.id);
    }

    await teacherCurriculumDomain.updateSelectedLessonIds(updatedSelectedIds);
  };

  const handleOnLessonClear = async () => {
    await teacherCurriculumDomain.updateSelectedLessonIds([]);
  };

  return {
    handleOnLessonSelect,
    handleOnLessonClear,
  };
};

export const useReloadLessonsByUuids = (teacherCurriculumDomain) => {
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  // const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { lessonData } = teacherCurriculumDomain.domainData;

  const reloadLessonsByUuids = async (lessons) => {
    try {
      const uuids = lessons.map((l) => l.attributes.uuid);
      await callRemoteServiceWrapper(async () => {
        const result = await getLessonByUuids(uuids);
        const searchResultLessons = result.data;
        searchResultLessons.data.forEach((lesson) => {
          const matched = lessonData.lessons.find((l) => l.id === lesson.id);
          matched.meta = lesson.meta;
          matched.relationships = lesson.relationships;
        });

        const updatedIncluded = [...lessonData.included];
        searchResultLessons.included.forEach((i) => {
          const matched = lessonData.included.find((lessonDataIncluded) => lessonDataIncluded.id === i.id && lessonDataIncluded.type === i.type);
          if (!matched) {
            updatedIncluded.push(i);
          }
        });
        lessonData.included = updatedIncluded;
        teacherCurriculumDomain.updateLessonData([...lessonData.lessons], [...lessonData.included]);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return {
    reloadLessonsByUuids,
  };
};

export const useGetNoLessonsMessage = ({
  noLessonMessage,
}) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { filterCriteria: {
    standardLessonLevel,
    matchingAssessmentArea,
    verticalAssessmentArea,
  } } = teacherCurriculumDomain.domainData;

  if (!standardLessonLevel && !matchingAssessmentArea && !verticalAssessmentArea) {
    return {
      isFiltered: false,
      noLessonsMessage: (<span>{noLessonMessage}<br />We are working on getting this to you in the near future!</span>),
    };
  }
  return {
    isFiltered: true,
    noLessonsMessage: 'No Activities found',
  };
};

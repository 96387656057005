import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Box,
  Card,
  Typography,
} from '@mui/material';
import './MatchingLayoutSelector.scss';

export default function MatchingLayoutSelector({ responseCards }) {
  const createresponseCard = () => (
    responseCards.map((responseCard, index) => (
      <Card key={uuidv1()} className='matching-layout-selector-response matching-layout-selector-background' style={{ width: `${(100 / responseCards.length) - 1}%` }}>
        <p className='matching-layout-selector-text matching-layout-selector-response-text'>
          Answer &nbsp;
          <br />
          <br />
          {index + 1}
        </p>
      </Card>
    ))
  );

  return (
    <Box className='matching-layout-selector vizzle-matching-layout-selector'>
      <Card className='matching-layout-selector-question matching-layout-selector-background'>
        <Typography className='matching-layout-selector-text'>
          Question
        </Typography>
      </Card>
      <Box className='matching-layout-selector-responses'>
        {createresponseCard()}
      </Box>
    </Box>
  );
}

MatchingLayoutSelector.defaultProps = {
  responseCards: [{}],
};

MatchingLayoutSelector.propTypes = {
  responseCards: PropTypes.arrayOf(PropTypes.shape({
  })),
};

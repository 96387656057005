import { useState } from 'react';
import PropTypes from 'prop-types';
import BrowseImageAndVideoUploader from '../../../../../lessons/lessonmaintenance/steps/media/editor/BrowseImageAndVideoUploader';
import './FreeFormResponseImageSelector.scss';
import { sort } from '../../../../../../utils/ArrayUtils';

const FreeFormResponseImageSelector = ({
  ffImages,
  ffImageCount,
  onFfImageChange,
  isPreviewMode,
  user,
}) => {
  const [imageList, setImageList] = useState(() => {
    const sortFfImages = sort(ffImages || [], 'index');
    return sortFfImages;
  });
  const getImageUploaders = () => {
    const result = [];

    const getImage = (i) => {
      if (imageList) {
        const image = imageList.find((f) => f && f.index === i);
        return image && image.url;
      }
      return null;
    };

    for (let i = 0; i < ffImageCount; i++) {
      const image = getImage(i);
      result.push(
        <BrowseImageAndVideoUploader
          key={`image-uploader-${i}`}
          onSelect={(data) => {
            const { selectedImageUrl: imageUrl } = data;
            const updatedList = JSON.parse(JSON.stringify(imageList));
            if (!updatedList[i]) {
              updatedList[i] = {
                index: i,
              };
            }
            updatedList[i].url = imageUrl;
            setImageList([...updatedList]);
            onFfImageChange(updatedList);
          }}
          disabledRemove={!image}
          onRemove={() => {
            const updatedList = imageList.filter((im) => im && im.index !== i);
            if (updatedList.length === 0) {
              setImageList([]);
              onFfImageChange(null);
            } else {
              setImageList([...updatedList]);
              onFfImageChange(updatedList);
            }
          }}
          isInlineRemoveButton
          className='image-selector'
          isVideoIncluded={false}
          autoUploadWhenSelectImage={!isPreviewMode}
          imageUrl={image}
          user={user}
        />,
      );
    }

    return result;
  };

  return (
    <div className='freeform-response-image-selector'>
      {getImageUploaders(ffImageCount)}
    </div>
  );
};

FreeFormResponseImageSelector.defaultProps = {
  ffImages: [],
  ffImageCount: 2,
  onFfImageChange: () => null,
  isPreviewMode: true,
  user: {},
};

FreeFormResponseImageSelector.propTypes = {
  ffImages: PropTypes.array,
  ffImageCount: PropTypes.number,
  onFfImageChange: PropTypes.func,
  isPreviewMode: PropTypes.bool,
  user: PropTypes.any,
};

export default FreeFormResponseImageSelector;

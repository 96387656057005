import {
  Typography,
} from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';
import SettingTextFieldForm from './form/SettingTextFieldForm';

const ChangePassword = () => {
  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  return (
    <>
      <Typography variant='h6'>
        This will change the password for&nbsp;
        {students.length}
        &nbsp;Students
      </Typography>
      <br />
      <SettingTextFieldForm
        label='New Password'
        attributeNameToSave='password'
        minLength={4}
        subLabel='Password must be at least 4 characters'
      />
    </>
  );
};

export default ChangePassword;

import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import CustomDialog from '../../../../../../components/dialog/CustomDialog';
import './StudentAssignmentDialog.scss';
import StudentAssignment from './StudentAssignment';
import DateUtils from '../../../../../../utils/DateUtils';
import { dateFormatyyyyMMdd } from '../../../../../../AppConstants';

const StudentAssignmentDialog = ({
  open,
  data,
  onClose,
  onTableActionSuccess,
  loading,
}) => (
  <CustomDialog
    openDialog={open}
    className='assignment-overview-student-assignment-dialog'
    onClose={onClose}
    title={(
      <div className='title'>
        <Typography variant='h5' gutterBottom>
          Assignments For
        </Typography>
        <Typography variant='h4' gutterBottom>
          {DateUtils.convertDateString(data.date, dateFormatyyyyMMdd, 'iii, LLLL d')}
        </Typography>
        <Divider />
      </div>
    )}
    content={(
      <StudentAssignment loading={loading} data={data} onTableActionSuccess={onTableActionSuccess} />
    )}
    displayCloseIcon
  />
);

StudentAssignmentDialog.defaultProps = {
  open: false,
  onClose: () => null,
  onTableActionSuccess: () => null,
  loading: false,
};

StudentAssignmentDialog.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    assignmentStatus: PropTypes.number,
    assignedStudentCourses: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTableActionSuccess: PropTypes.func,
  loading: PropTypes.bool,
};

export default StudentAssignmentDialog;

import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { addToGroupPlay, removeFromGroupPlay } from '../../../../services/GroupPlayService';
import { useDomain } from '../../../../states/AppDomainProvider';
import Logger from '../../../../utils/Logger';

export const useAddRemoveFromGroupPlay = (lesson, onSaveSuccess) => {
  const { loading: saving, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  const addLessonToGroupPlay = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await addToGroupPlay(user.id, [lesson.id]);
        // eslint-disable-next-line no-param-reassign
        lesson.meta.groupPlay = true;
        onSaveSuccess(lesson);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, 'The activity is added to the Group Instruction succesfully.');
    } catch (e) {
      Logger.logError(e);
    }
  };

  const removeLessonFromGroupPlay = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await removeFromGroupPlay(user.id, [lesson.id]);
        // eslint-disable-next-line no-param-reassign
        lesson.meta.groupPlay = false;
      });
      onSaveSuccess(lesson);
      await uiDomain.showSnackbar(true, 'success', 3000, 'The activity is removed from the Group Instruction succesfully.');
    } catch (e) {
      // let's not tell the user when the auto-save fails
      Logger.logError(e);
    }
  };

  return {
    saving,
    addLessonToGroupPlay,
    removeLessonFromGroupPlay,
  };
};

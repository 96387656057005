import BaseDomain from '../../../states/domains/BaseDomain';

const SWITCH_ACCESS_UPDATE_DIALOG_SHOWING = 'SWITCH_ACCESS_UPDATE_DIALOG_SHOWING';

const initialState = {
  hasDialogShown: false,
  isDialogShowing: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SWITCH_ACCESS_UPDATE_DIALOG_SHOWING:
      return {
        ...state,
        hasDialogShown: action.payload.hasDialogShown,
        isDialogShowing: action.payload.isDialogShowing,
      };
    default:
      return state;
  }
};

const domainName = 'switchAccessDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class SwitchAccessDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateShowDialog(isDialogShowing, hasDialogShown = false) {
    return this.dispatch({
      type: SWITCH_ACCESS_UPDATE_DIALOG_SHOWING,
      payload: {
        isDialogShowing,
        hasDialogShown,
      },
    });
  }
}

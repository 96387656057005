/* APP ID of Book layout */
export const ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA = 'BuildABookSingle';
export const ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA = 'BuildABookFacing';
export const ACTIVITY_TYPE_MATCHING_ACTIVITY = 'MatchingActivity';
export const ACTIVITY_TYPE_SORTING_ACTIVITY = 'SortingActivity';
export const ACTIVITY_TYPE_GAME_ACTIVITY = 'GameActivity';
export const ACTIVITY_TYPE_SEQUENCING_ACTIVITY = 'SequencingActivity';
export const ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY = 'CauseAndEffect';
export const ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE = 'FillInTheBlankSingle';
export const ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING = 'FillInTheBlankFacing';
export const ACTIVITY_TYPE_CATEGORIZING_ACTIVITY = 'CategorizingActivity';
export const ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY = 'FlexiMatchingActivity';
export const ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY = 'MultipleChoiceActivity';
export const ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2 = 'MultipleChoiceActivityV2';

export const DEFAULT_TEXT_FORMAT = {
  fontSize: 16,
  fontName: 'Quattrocento',
  bold: false,
  italic: false,
  fontColor: '#000000',
  indent: 'left',
};

/* Unauthorized http code */
export const UNAUTHORIZED = 401;

/* Lesson status */
export const LESSON_STATUS_PENDING_NEXTGEN = 'PENDING_NEXTGEN';
export const LESSON_STATUS_UNDER_REVIEW = 'UNDER_REVIEW';
export const APPROVED_FOR_COURSES = 'APPROVED_FOR_COURSES';

/* Matching layout */
export const MAX_ANSWER_CARDS = 5;
export const DEFAULT_NUMBER_OF_ANSWER_CARDS = 3;

/* Cause and Effect layout */
export const MAX_SETS_OF_CAUSES_AND_EFFECTS = 4;
export const DEFAULT_NUMBER_OF_CAUSES_AND_EFFECTS = 4;

export const dateFormatMMddyyyy = 'MM/dd/yyyy';
export const dateFormatyyyyMMdd = 'yyyy-MM-dd';

export const timeFormatHHmm = 'HH:mm';
export const timeFormatHHmmAmPm = 'hh:mm a';
export const fullDateTimeFormatHHmm = 'MM/dd/yyyy hh:mm a';

import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import './AssignStudentFromListAction.scss';
import { useAssignStudent, useDialogControl, useGetStudents } from './AssignStudentFromListActionHooks';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useDomain } from '../../../../../states/AppDomainProvider';
import StudentList from './StudentList';
// import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
// import { teacherNonSharingStudentTooltipText } from '../../../../../texts/TooltipText';

const AssignStudentFromListAction = ({ listStudents }) => {
  const {
    showDialog,
    handleOnAssignButtonClick,
    closeDialog,
  } = useDialogControl();

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const {
    assignStudent,
  } = useAssignStudent();

  const nonSelectedStudents = useGetStudents({ listStudents });

  const getContent = () => {
    if (user.shareStudents) {
      return <StudentList listStudents={nonSelectedStudents} onAdd={assignStudent} />;
    } else {
      return null;
    }
  };

  const shouldEnable = user.userLeadTeacher;
  return (
    <>
      <Button
        data-test='add-student-action'
        variant='contained'
        onClick={handleOnAssignButtonClick}
        className='btn-primary margin-left-medium'
        startIcon={<AssignmentIndIcon />}
        disabled={!shouldEnable}
      >
        ADD STUDENT
      </Button>
      {/* {!user.shareStudents && (
        <TooltipWithIndicator className='tooltip' {...teacherNonSharingStudentTooltipText} />
      )} */}

      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          onClose={closeDialog}
          title='Add Student'
          content={getContent()}
          displayCloseIcon
          className='assign-student-dialog-container'
        />
      )}
    </>
  );
};

AssignStudentFromListAction.defaultProps = {
  listStudents: [],
};

AssignStudentFromListAction.propTypes = {
  listStudents: PropTypes.arrayOf(PropTypes.any),
};

export default AssignStudentFromListAction;

import { useEffect, useRef, useState } from 'react';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import GenericButton from '../../../components/buttons/GenericButton';
import { usePrepareData, useUpdatePermissions } from './AdminActionHooks';
import PermissionForm from './forms/PermissionForm';

const PermissionsAction = () => {
  const [open, setOpen] = useState(false);

  const handleOnCancel = () => {
    setOpen(false);
  };

  const permissionDataRef = useRef(null);

  const {
    loading,
    updatePermissions,
  } = useUpdatePermissions({
    setOpen,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading,
    }, {
      onClick: () => {
        updatePermissions(permissionDataRef.current);
      },
      className: 'btn-primary action-button',
      title: 'Save',
      loading,
    },
    ];
    return buttons;
  };

  const {
    selectedAdminIds,
    selectedAdminsData,
  } = usePrepareData();

  let labelTitle = 'Update Permission';
  const permissionData = {
    adminDataAccess: false,
    adminSchoolsAccess: false,
    adminTeachersAccess: false,
    adminStudentsAccess: false,
    adminSearchAccess: false,
    adminAddUsersAccess: false,
  };

  if (selectedAdminsData.length === 1) {
    const selectedAdmin = selectedAdminsData[0];
    const { attributes: {
      firstName,
      lastName,
      adminDataAccess,
      adminSchoolsAccess,
      adminTeachersAccess,
      adminStudentsAccess,
      adminSearchAccess,
      adminAddUsersAccess,
    } } = selectedAdmin;
    labelTitle = `Update Permissions for ${firstName} ${lastName}`;

    permissionData.adminDataAccess = adminDataAccess;
    permissionData.adminSchoolsAccess = adminSchoolsAccess;
    permissionData.adminTeachersAccess = adminTeachersAccess;
    permissionData.adminStudentsAccess = adminStudentsAccess;
    permissionData.adminSearchAccess = adminSearchAccess;
    permissionData.adminAddUsersAccess = adminAddUsersAccess;
  } else if (selectedAdminsData.length > 1) {
    labelTitle = `Update Permission for ${selectedAdminsData.length} users`;
  }

  useEffect(() => {
    if (open) {
      permissionDataRef.current = permissionData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <GenericButton
        ariaLabel={labelTitle}
        onClick={() => { setOpen(true); }}
        disabled={!selectedAdminIds || selectedAdminIds.length <= 0}
        icon={GroupWorkIcon}
        label='Permission'
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='admin-permission-action-dialog'
          onClose={handleOnCancel}
          title={labelTitle}
          content={(
            <PermissionForm
              permissionData={permissionData}
              onDataChange={(updatedData) => {
                permissionDataRef.current = updatedData;
              }}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

export default PermissionsAction;

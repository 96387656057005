import {
  Box, Typography,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import ChooseNumberOfCards from '../media/editor/matching/ChooseNumberOfCards';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useHandleNumberOfCardChanges } from '../../hooks/LessonMaintenanceHooks';
import MultipleChoiceV2Thumbnail from '../../../../../components/vizzle/page/multiple-choice-v2/MultipleChoiceV2Thumbnail';
import VizzleSwitch from '../../../../../components/switchs/VizzleSwitch';

const answerCardsConfig = {
  label: 'Choose Number of Response Cards',
  min: 1,
  max: 6,
  step: 1,
  marks:
    [
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
    ],
};

const createCards = (startNum, endNum) => {
  const result = [];
  for (let i = startNum; i <= endNum; i++) {
    result.push({ sequenceNum: i, key: null, cardIndex: i });
  }
  return result;
};

export default function MultipleChoiceV2Layout() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const handleNumberOfCardChange = useHandleNumberOfCardChanges();
  const answerCards = selectedPage.answerCards.filter((card) => !card.hide);

  const handleOnSliderChange = async (event, value) => {
    event.stopPropagation();
    await handleNumberOfCardChange({
      numberOfCard: value,
      currentNumberOfCards: answerCards.length,
      cardType: 'answerCards',
      templateData: createCards(1, answerCardsConfig.max),
    });
  };

  const props = {
    config: [
      {
        component: (
          <MultipleChoiceV2Thumbnail
            questionCard={{}}
            answerCards={answerCards.map(() => ({}))}
            singlePage={selectedPage.singlePage}
          />
        ),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '42vw' : '32vw',
    height: '48vh',
  };

  const handleSwitchChange = (event) => {
    const updatedSelectedPage = {
      ...selectedPage,
      singlePage: event.target.checked,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  return (
    <Box>
      <br />
      <Box display='flex' mt='10px'>
        <br />
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-matching-layout'
        />

        <Box width='30vw' ml='15px'>
          <Box display='flex' height='100%' flexDirection='column' justifyContent='center'>
            <ChooseNumberOfCards
              onSliderChange={handleOnSliderChange}
              value={
                selectedPage && selectedPage.answerCards ? selectedPage.answerCards.filter((card) => (
                  !card.hide
                )).length : null
              }
              {...answerCardsConfig}
              data-test='response-cards-selector'
            />
            <br /><br />
            <Box mt='15px' data-test='multiple-choice-v2-prompt-toggle'>
              <Typography variant='h6'>Single Page or Side by Side</Typography>
              <VizzleSwitch
                checked={selectedPage.singlePage}
                labelWhenSelected='Single Page'
                labelWhenUnselected='Side by Side'
                onChange={handleSwitchChange}
                className='vizzle-peding-lesson-switch'
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import SimpleTable from '../../../../../../components/table/SimpleTable';
import './ImportUsersResult.scss';

const summaryTableConfig = [
  {
    title: 'Processed',
    align: 'right',
    dataField: 'processed',
    width: '23%',
  },
  {
    title: 'Successful',
    align: 'right',
    dataField: 'succeeded',
    width: '23%',
  },
  {
    title: 'Failed',
    align: 'right',
    dataField: 'failed',
    width: '23%',
  },
  {
    title: 'Username updated',
    align: 'right',
    dataField: 'usernameUpdated',
    width: '30%',
  },
];

const failursTableConfig = [
  {
    title: 'Row',
    align: 'right',
    dataField: 'row',
    width: '5%',
  },
  {
    title: 'Data',
    align: 'left',
    dataField: 'data',
    width: '45%',
  },
  {
    title: 'Reason',
    align: 'left',
    dataField: 'reason',
    width: '50%',
  },
];

const updatesTableConfig = [
  {
    title: 'Row',
    align: 'right',
    dataField: 'row',
    width: '5%',
  },
  {
    title: 'Data',
    align: 'left',
    dataField: 'data',
    width: '95%',
  },
];

const ImportUsersResult = ({
  result,
  failures,
  updates,
  additionalMessage,
}) => (
  <div className='import-user-result'>
    <SimpleTable
      config={summaryTableConfig}
      tableData={[result]}
      header='Summary'
      tableClassName='summary-table'
    />
    {failures && failures.length > 0 && (
      <div className='detail-table-container'>
        <SimpleTable
          config={failursTableConfig}
          tableData={failures}
          header='Failures'
          tableClassName='failure-table'
        />
      </div>
    )}

    {updates && updates.length > 0 && (
      <div className='detail-table-container'>
        <SimpleTable
          config={updatesTableConfig}
          tableData={updates}
          header='Updates'
          tableClassName='detail-table'
        />
      </div>
    )}

    <Typography variant='subtitle2' className='additional-message'>
      {additionalMessage}
    </Typography>
  </div>
);

ImportUsersResult.defaultProps = {
  result: {
    processed: 0,
    succeeded: 0,
    failed: 0,
    usernameUpdated: 0,
  },
  failures: [],
  updates: [],
  additionalMessage: '',
};

ImportUsersResult.propTypes = {
  result: PropTypes.shape({
    processed: PropTypes.number,
    succeeded: PropTypes.number,
    failed: PropTypes.number,
    usernameUpdated: PropTypes.number,
  }),
  failures: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.number,
    data: PropTypes.string,
    reason: PropTypes.string,
  })),
  updates: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.number,
    data: PropTypes.string,
  })),
  additionalMessage: PropTypes.string,
};

export default ImportUsersResult;

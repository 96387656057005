import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useGetAssignedStudentCourses } from '../AssignedStudentCoursesHooks';
import MultipleDateSelector from '../../../../components/date/MultipleDateSelector';

export default function TeacherCourseAssignmentForDaysAction({
  selectedDates,
  onSelectedDatesApply,
  onStartLoading,
  subjectId,
  onDataLoad,
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
}) {
  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    selectedDates,
    onStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
  });

  const [internalSelectedDates, setInternalSelectedDates] = useState(selectedDates);

  useEffect(() => {
    setInternalSelectedDates(selectedDates);
  }, [selectedDates]);

  return (
    <Box display='flex' alignItems='center' justifyContent='flex-start'>
      <MultipleDateSelector
        selectedDates={internalSelectedDates}
        onSelectedDatesChange={(dates) => {
          setInternalSelectedDates(dates);
        }}
        onApply={async () => {
          await onSelectedDatesApply(internalSelectedDates);
          getAssignedStudentData(internalSelectedDates);
        }}
        highlightToday={false}
        showText={false}
      />
    </Box>
  );
}

TeacherCourseAssignmentForDaysAction.defaultProps = {
  selectedDates: {},
  onSelectedDatesApply: () => null,
  onStartLoading: () => null,
  subjectId: null,
  onDataLoad: () => null,
  assignedStudentCourses: [],
  filterCriteriaAssignedStudentCourses: {},
};

TeacherCourseAssignmentForDaysAction.propTypes = {
  selectedDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onSelectedDatesApply: PropTypes.func,
  onStartLoading: PropTypes.func,
  subjectId: PropTypes.number,
  onDataLoad: PropTypes.func,
  assignedStudentCourses: PropTypes.arrayOf(PropTypes.any),
  filterCriteriaAssignedStudentCourses: PropTypes.any,
};

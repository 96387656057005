import { useState } from 'react';
import PropTypes from 'prop-types';
import CelebrationList from './CelebrationList';
import './CelebrationSettingForm.scss';
import { CELEBRATION_STYLE_KEY } from '../../../constants/PlayPreferenceConstant';
import CelebrationStyleSetting from '../../mysettings/settings/celebrations/CelebrationStyleSetting';
import SimpleCelebration from './simple-celebration/SimpleCelebration';
import SoundEffectSetting from '../sound-effect-setting/SoundEffectSetting';
// import SeasonalFilter from '../../mystudent/components/settings/seasonal/SeasonalFilter';

export default function CelebrationSettingForm({ profile, terminologies, onChangeCallback, SeasonalFilterComponent }) {
  const [internalCelebrationStyle, setInternalCelebrationStyle] = useState(profile.celebrationStyle);
  const showCelebrationDetail = internalCelebrationStyle === CELEBRATION_STYLE_KEY.interactive;

  return (
    <div className='celebration-setting-form' data-test='celebration-setting-form'>
      <div className='celebration-style-container'>
        <CelebrationStyleSetting
          profile={profile}
          onChangeCallback={(newValue) => {
            setInternalCelebrationStyle(newValue);
            onChangeCallback({ celebrationStyle: newValue });
          }}
        />

        {[CELEBRATION_STYLE_KEY.interactive, CELEBRATION_STYLE_KEY.simple].includes(internalCelebrationStyle) && (
          <SoundEffectSetting
            profile={profile}
            onChangeCallback={(newValue) => onChangeCallback({ celebrationSound: newValue })}
            attributeName='celebrationSound'
          />
        )}
        {[CELEBRATION_STYLE_KEY.interactive].includes(internalCelebrationStyle) && (
          // <SeasonalFilter />
          <SeasonalFilterComponent />
        )}
      </div>
      {!showCelebrationDetail && internalCelebrationStyle === CELEBRATION_STYLE_KEY.simple && (
        <SimpleCelebration />
      )}
      {showCelebrationDetail && (
        <CelebrationList
          profile={profile}
          terminologies={terminologies}
          onChangeCallback={(newValue) => onChangeCallback({ celebrations: newValue })}
        />
      )}
    </div>
  );
}

CelebrationSettingForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  terminologies: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  SeasonalFilterComponent: PropTypes.func.isRequired,
};

/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { addFreeFormComment, updateFreeFormComment } from '../../../../../../../../services/AnswerFreeFormService';
import Logger from '../../../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../../../hooks/RemoteServiceHooks';
import { useGetCurrentStudent } from '../../../../../../../hooks/StudentHooks';

export const useHandleCommentField = ({
  initComments,
}) => {
  const [comments, setComments] = useState(initComments || '');

  const handleOnCommentsChange = (e) => {
    setComments(e.target.value);
  };

  return {
    comments,
    handleOnCommentsChange,
  };
};

export const useAddComment = ({ answerLesson, answerPageData, pageNumber, onSaveSuccess, openSnackbar, onSnackbarClose }) => {
  const currentStudent = useGetCurrentStudent();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const addComment = async ({
    comments,
  }) => {
    await callRemoteServiceWrapper(async () => {
      try {
        await addFreeFormComment({
          studentId: currentStudent.id,
          lessonId: answerLesson.id,
          comments,
          pageNumber,
          cardNumber: 2,
          studentHistoryId: answerPageData.attributes.studentHistoryId,
        });
        await onSaveSuccess();
        const message = `Comments on this page for ${currentStudent.firstName} ${currentStudent.lastName} is added.`;
        openSnackbar({
          message,
          onClose: onSnackbarClose,
          variant: 'success',
          open: true,
          autoCloseIn: 3000,
        });
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    });
  };

  return {
    isSaving: loading,
    addComment,
  };
};

export const useUpdateComment = ({ answerPageData, onSaveSuccess, openSnackbar, onSnackbarClose }) => {
  const currentStudent = useGetCurrentStudent();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const updateComment = async ({
    comments,
  }) => {
    await callRemoteServiceWrapper(async () => {
      try {
        await updateFreeFormComment({
          studentId: currentStudent.id,
          freeFormAnswerId: answerPageData.id,
          studentHistoryId: answerPageData.attributes.studentHistoryId,
          comments,
        });
        await onSaveSuccess();
        const message = `Comments on this page for ${currentStudent.firstName} ${currentStudent.lastName} is updated.`;
        openSnackbar({
          message,
          onClose: onSnackbarClose,
          variant: 'success',
          open: true,
          autoCloseIn: 3000,
        });
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    });
  };

  return {
    isSaving: loading,
    updateComment,
  };
};

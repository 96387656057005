import { useNavigate } from 'react-router-dom';
import { useDomain } from '../../states/AppDomainProvider';

export const useUserLink = ({
  pathName,
  goBackToPathName,
  label,
}) => {
  const navigate = useNavigate();

  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;
  const goToMyStudent = (data) => {
    const student = students.find((s) => s.id.toString() === data.id);
    navigate(
      pathName.startsWith('/') ? pathName : `/${pathName}`,
      {
        state: {
          student,
          goBackTo: {
            label,
            pathname: goBackToPathName,
          },
        },
      },
    );
  };

  return goToMyStudent;
};

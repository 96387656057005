import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CustomTooltip from '../../../../../../components/card/CustomTooltip';
import '../../../../components/buttons/Buttons.scss';

const ExportData = ({
  ariaLabel,
}) => (
  <CustomTooltip title={ariaLabel} aria-label={ariaLabel}>
    <span>
      <Button
        variant='contained'
        className='btn-primary admin-action-button margin-top'
        startIcon={<GetAppIcon />}
      >
        Export
      </Button>
    </span>
  </CustomTooltip>
);

ExportData.defaultProps = {
  ariaLabel: '',
};

ExportData.propTypes = {
  ariaLabel: PropTypes.string,
};

export default ExportData;

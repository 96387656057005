import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import './CardAction.scss';
import CustomTooltip from './CustomTooltip';

/**
 * Action of the lesson card
 *
 * @param {*} prop
 * - icon the icon to display on the lesson card
 * - label to display on the lesson card
 * - actionHandler action handler
 * - additional class
 */
export default function CardAction({
  icon,
  label,
  actionHandler,
  className,
  title,
  ariaLabel,
  disabled,
  ...rest
}) {
  const buttonAction = label ? <Button onClick={actionHandler} className={`card-action ${className}`} size='small' variant='contained' startIcon={icon} disabled={disabled} {...rest}>{label}</Button>
    : (
      cloneElement(
        icon,
        {
          onClick: (e) => { e.stopPropagation(); actionHandler(e); },
          className: `card-action icon-hover ${className}`,
          disabled,
          ...rest,
        },
      )
    );
  return (
    <CustomTooltip className='action-button-tooltip' title={title} aria-label={ariaLabel}>
      <>
        {buttonAction}
        {disabled && <span />}
      </>
    </CustomTooltip>
  );
}

CardAction.defaultProps = {
  icon: null,
  className: '',
  label: null,
  title: '',
  ariaLabel: '',
  disabled: false,
};

CardAction.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  actionHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

import {
  Box,
} from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import { shuffle, flatten, sort } from '../ArrayUtils';
import BookCard from '../../components/vizzle/page/book/BookCard';
import Matching from '../../components/vizzle/page/matching/Matching';
import Sorting from '../../components/vizzle/page/sorting/Sorting';
import GameActivity from '../../components/vizzle/page/game/GameActivity';
import Sequencing from '../../components/vizzle/page/sequencing/Sequencing';
import BookCardThumbnail from '../../components/vizzle/page/book/BookCardThumbnail';
import MatchingThumbnail from '../../components/vizzle/page/matching/MatchingThumbnail';
import SortingCardThumbnail from '../../components/vizzle/page/sorting/SortingCardThumbnail';
import GameThumbnail from '../../components/vizzle/page/game/GameThumbnail';
import SequencingThumbnail from '../../components/vizzle/page/sequencing/SequencingThumbnail';
import BlankActivityCard from '../../components/vizzle/page/BlankActivityCard';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
  ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
  ACTIVITY_TYPE_CATEGORIZING_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY,
  ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2,
} from '../../AppConstants';
import { availableSortingKeys, defaultSubmitText, KEY_NA } from './ActivityTypeInitialData';
import { getActivityCardPlay } from '../../containers/lessonplay/utils/LessonActivityPlayUtils';
import CauseAndEffectThumbnail from '../../components/vizzle/page/causeandeffect/CauseAndEffectThumbnail';
import CauseAndEffect from '../../components/vizzle/page/causeandeffect/CauseAndEffect';
import FillInTheBlankPageThumbnail from '../../components/vizzle/page/fitb/page/FillInTheBlankPageThumbnail';
import CategorizingCardThumbnail from '../../components/vizzle/page/categorizing/CategorizingCardThumbnail';
import CategorizingPrint from '../../components/vizzle/page/categorizing/print/CategorizingPrint';
import FlexibleMatchingThumbnail from '../../components/vizzle/page/flexible-matching/FlexibleMatchingThumbnail';
import FlexibleMatchingPrint from '../../components/vizzle/page/flexible-matching/print/FlexibleMatchingPrint';
import MultipleChoiceThumbnail from '../../components/vizzle/page/multiple-choice/MultipleChoiceThumbnail';
import MultipleChoiceActivityPrint from '../../components/vizzle/page/multiple-choice/MultipleChoiceActivityPrint';
import MultipleChoiceV2Thumbnail from '../../components/vizzle/page/multiple-choice-v2/MultipleChoiceV2Thumbnail';
import MultipleChoiceActivityV2Print from '../../components/vizzle/page/multiple-choice-v2/MultipleChoiceActivityV2Print';

/**
 * A helper method to get component for showing in thumbnail mode from pages data
 *
 * @export
 * @param {*} pagesData lesson pages data
 * @returns list of components corresponding to the lesson pages data
 */
export function getActivityCardThumbnailByActivityType(pagesData, mode) {
  if (!pagesData.pages) {
    return [];
  }
  let pageDataList = [];
  pageDataList = pagesData.pages.map((data) => {
    if (!data) {
      return <BlankActivityCard key={uuidv1()} />;
    }
    if (data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA || data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
      const layoutConfig = data.cards.filter((card) => (
        !card.hide
      )).map((card, index) => (
        {
          id: `${data.activityType}-${index}`,
          text: card.text,
          image: card.image,
          bgColor: card.bgColor,
          borderColor: card.borderColor,
        }
      ));
      return (
        <BookCardThumbnail key={uuidv1()} layoutConfig={layoutConfig} mode={mode} />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
      const responseCards = data.responseCards.filter((card) => (
        !card.hide
      ));
      return (
        <MatchingThumbnail key={uuidv1()} promptCards={data.promptCards} responseCards={responseCards} />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
      const promptCardsConfig = flatten(data.categories.map((category) => (
        category.promptCards
      )));

      promptCardsConfig.forEach((p, index) => {
        if (p.sequenceNum === null || p.sequenceNum === undefined) {
          // eslint-disable-next-line no-param-reassign
          p.sequenceNum = index;
          // eslint-disable-next-line no-param-reassign
          p.key = availableSortingKeys[index + 1];
        }
      });

      const responseCardsConfig = flatten(data.categories.map((category) => (
        category.responseCards
      )));

      responseCardsConfig.forEach((r, index) => {
        if (r.sequenceNum === null || r.sequenceNum === undefined) {
          // eslint-disable-next-line no-param-reassign
          r.sequenceNum = index;
        }
      });

      return (
        <SortingCardThumbnail
          key={uuidv1()}
          promptCardsConfig={promptCardsConfig}
          responseCardsConfig={responseCardsConfig}
          isSmallThumbnail
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
      const gameCards = data.gameCards.filter((card) => !card.disabled);

      return (
        <GameThumbnail
          key={uuidv1()}
          gameCards={gameCards}
          isSmallThumbnail
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
      const { sequenceCards, responseCards } = data;
      return (
        <SequencingThumbnail
          key={uuidv1()}
          sequenceCards={sequenceCards}
          responseCards={responseCards}
          isSmallThumbnail
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY) {
      const { effectCards, causeCards } = data;
      return (
        <CauseAndEffectThumbnail
          key={uuidv1()}
          causeCards={causeCards.filter((e) => !e.hide)}
          effectCards={effectCards.filter((e) => !e.hide)}
          isSmallThumbnail
        />
      );
    }
    if ([ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE, ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING].includes(data.activityType)) {
      const config = data.cards.filter((card) => (
        !card.hide
      ));

      return (
        <FillInTheBlankPageThumbnail
          key={uuidv1()}
          cardConfig={config}
          isSmallThumbnail
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY) {
      const { instructions, promptCards, responseCards } = data;
      return (
        <CategorizingCardThumbnail
          key={uuidv1()}
          instructionConfig={instructions}
          promptCardsConfig={promptCards.filter((c) => !c.hide)}
          responseCardsConfig={responseCards.filter((c) => !c.hide)}
          isSmallThumbnail
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
      const { layout, promptText, subText, promptCards, responseCards, submitAnswerText } = data;
      return (
        <FlexibleMatchingThumbnail
          key={uuidv1()}
          layout={layout}
          promptCards={promptCards.filter((c) => !c.hide)}
          responseCards={responseCards.filter((c) => !c.hide)}
          promptText={promptText}
          subText={subText}
          isSmallThumbnail
          submitAnswerText={submitAnswerText || defaultSubmitText}
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
      const { questionCard, answerCards, singlePage } = data;
      return (
        <MultipleChoiceThumbnail
          key={uuidv1()}
          questionCard={questionCard}
          answerCards={answerCards.filter((c) => !c.hide)}
          singlePage={singlePage}
        />
      );
    }
    if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
      const { questionCard, answerCards, submitAnswerText, singlePage } = data;
      return (
        <MultipleChoiceV2Thumbnail
          key={uuidv1()}
          questionCard={questionCard}
          answerCards={answerCards.filter((c) => !c.hide)}
          submitAnswerText={submitAnswerText}
          isSmallThumbnail
          singlePage={singlePage}
        />
      );
    }
    // Other activity type goes here
    return <BlankActivityCard key={uuidv1()} />;
  });
  return pageDataList;
}

/**
 * A helper method to transform pagesData into activities component props
 *
 * @export
 * @param {*} pagesData lesson pages data
 * @returns list of data for components corresponding to the lesson pages data
 */
export const getActivityCardPreviewDataByActivityType = (pagesData, shuffleResponse = true) => (
  pagesData.pages.map((data) => {
    if (!data) {
      return {};
    }
    if (data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA || data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
      const layoutConfig = data.cards.filter((card) => (
        !card.hide
      )).map((card, index) => (
        {
          id: `${data.activityType}-${index}`,
          text: card.text,
          image: card.image,
          video: card.video,
          audio: card.audio,
          bgColor: card.bgColor,
          borderColor: card.borderColor,
          hotspots: card.hotspots,
          allowCustomResponse: card.allowCustomResponse,
          customResponseOption: card.customResponseOption,
          ffImageCount: card.ffImageCount,
          ffImages: card.ffImages,
          freetextInstructions: card.freetextInstructions,
          drawingImage: card.drawingImage,
        }
      ));
      return (
        {
          activityType: data.activityType,
          layoutConfig,
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
      const responseCards = [];
      for (let i = 0; i < data.responseCards.length; i++) {
        const card = data.responseCards[i];
        if (card.hide) {
          // eslint-disable-next-line no-continue
          continue;
        }
        card.correctAnswer = card.answerKey === null ? card.correctAnswer : data.promptCards.findIndex((p) => p.questionKey === card.answerKey) >= 0;
        responseCards.push(card);
      }

      return (
        {
          activityType: data.activityType,
          promptCards: data.promptCards,
          responseCards: shuffleResponse ? [...shuffle(responseCards)] : [...responseCards],
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
      const promptCards = flatten(data.categories.map((category) => (
        category.promptCards
      )));

      promptCards.forEach((p, index) => {
        if (p.sequenceNum === null || p.sequenceNum === undefined) {
          // eslint-disable-next-line no-param-reassign
          p.sequenceNum = index;
          // eslint-disable-next-line no-param-reassign
          p.key = availableSortingKeys[index + 1];
        }
      });

      const responseCards = flatten(data.categories.map((category, i) => (
        category.responseCards.map((r) => ({
          ...r,
          key: r.key || availableSortingKeys[i + 1],
        }))
      ))).filter((card) => (
        card.key !== availableSortingKeys[0]
      ));

      const instructionsText = data.instructions.text;
      if (instructionsText && instructionsText.defaultText) {
        data.instructions.text.lines.forEach((line) => {
          // eslint-disable-next-line no-param-reassign
          line.color = '#000000';
          line.elements.forEach((e) => {
            e.color = '#000000';
          });
        });
      }
      return (
        {
          activityType: data.activityType,
          instructions: data.instructions,
          promptCards,
          responseCards: shuffleResponse ? shuffle(responseCards) : responseCards,
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
      const gameCards = data.gameCards.filter((card) => !card.disabled);
      return {
        ...data,
        gameCards,
      };
    }

    if (data.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
      const responseCards = data.responseCards.filter((card) => card.answerKey !== KEY_NA);
      const responseCardKeys = responseCards.map((card) => card.answerKey);
      const sequenceCards = data.sequenceCards.filter((sequenceCard) => (
        responseCardKeys.includes(sequenceCard.key)
      ));
      const instructionsText = data.instructions.text;
      if (instructionsText && instructionsText.defaultText) {
        data.instructions.text.lines.forEach((line) => {
          // eslint-disable-next-line no-param-reassign
          line.color = '#000000';
          line.elements.forEach((e) => {
            e.color = '#000000';
          });
        });
      }
      return (
        {
          activityType: data.activityType,
          instructions: data.instructions,
          sequenceCards,
          responseCards: shuffleResponse ? shuffle(responseCards) : responseCards,
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY) {
      const causeCards = data.causeCards.filter((card) => (
        !card.hide
      )).map((card) => ({
        ...card,
        isCauseCard: true,
      }));

      const effectCards = data.effectCards.filter((card) => (
        !card.hide
      )).map((card) => ({
        ...card,
        isCauseCard: false,
      }));

      const causeAndEffectCards = [
        ...causeCards,
        ...effectCards,
      ];

      return (
        {
          activityType: data.activityType,
          causeAndEffectCards: shuffleResponse ? shuffle(causeAndEffectCards) : causeAndEffectCards,
        }
      );
    }

    if ([ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE, ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING].includes(data.activityType)) {
      const cardConfig = data.cards.filter((card) => (
        !card.hide
      )).map((card) => (
        {
          card: {
            image: card.image,
            audio: card.audio,
            video: card.video,
          },
          fitbList: card.fitbList,
        }
      ));
      return ({
        activityType: data.activityType,
        cardConfig,
      });
    }
    if (data.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY) {
      const promptCards = data.promptCards.filter((card) => (
        !card.hide
      ));
      const responseCards = data.responseCards.filter((card) => (
        !card.hide
      ));

      return (
        {
          activityType: data.activityType,
          promptCards,
          responseCards: shuffleResponse ? shuffle(responseCards) : responseCards,
          instructions: data.instructions,
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
      const promptCards = data.promptCards.filter((card) => (
        !card.hide
      ));
      const responseCards = data.responseCards.filter((card) => (
        !card.hide
      ));
      return (
        {
          activityType: data.activityType,
          promptCards,
          responseCards: shuffleResponse ? shuffle(responseCards) : responseCards,
          key: data.key,
          promptText: data.promptText,
          layout: data.layout,
          subText: data.subText,
          submitAnswerText: data.submitAnswerText,
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
      const answerCards = [];
      for (let i = 0; i < data.answerCards.length; i++) {
        const card = data.answerCards[i];
        if (card.hide) {
          // eslint-disable-next-line no-continue
          continue;
        }
        answerCards.push(card);
      }

      return (
        {
          ...data,
          answerCards: shuffleResponse ? [...shuffle(answerCards)] : [...answerCards],
        }
      );
    }

    if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
      const answerCards = [];
      for (let i = 0; i < data.answerCards.length; i++) {
        const card = data.answerCards[i];
        if (card.hide) {
          // eslint-disable-next-line no-continue
          continue;
        }
        answerCards.push(card);
      }

      let answerCardsToDisplay = [...answerCards];
      if (shuffleResponse && data.randomize) {
        answerCardsToDisplay = [...shuffle(answerCards)];
      } else {
        answerCardsToDisplay = [...sort(answerCards, 'sequenceNum')];
      }
      return (
        {
          ...data,
          answerCards: answerCardsToDisplay,
        }
      );
    }
    return {};
  })
);

export function getActivityCardForPrinting(data, pageIndex, currentIndex, forPrinting) {
  if (data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA || data.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA) {
    return (
      <BookCard key={`book-card-activity-preview-container-${pageIndex}`} layoutConfig={data.layoutConfig} />
    );
  }

  if (data.activityType === ACTIVITY_TYPE_MATCHING_ACTIVITY) {
    return (
      <Box key={`matching-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <Matching
          key={`matching-preview-lesson-container-${pageIndex}`}
          promptCards={data.promptCards}
          responseCards={data.responseCards}
          pageIndex={pageIndex}
          forPrinting={forPrinting}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
    return (
      <Box key={`matching-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <Sorting
          key={`sorting-preview-lesson-container-${pageIndex}`}
          pageIndex={pageIndex}
          instructions={data.instructions}
          promptCards={data.promptCards}
          responseCards={data.responseCards}
          currentIndex={currentIndex}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_GAME_ACTIVITY) {
    return (
      <Box key={`game-preview-box-container-${pageIndex}`} width='95%' height='100%' display='flex'>
        <GameActivity
          data={data}
          pageIndex={pageIndex}
          currentIndex={currentIndex}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
    return (
      <Box key={`sequencing-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <Sequencing
          key={`sequencing-preview-lesson-container-${pageIndex}`}
          pageIndex={pageIndex}
          instructions={data.instructions}
          sequenceCards={data.sequenceCards}
          responseCards={data.responseCards}
          currentIndex={currentIndex}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_CAUSE_AND_EFFECT_ACTIVITY) {
    return (
      <Box key={`cause-and-effect-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <CauseAndEffect
          key={`cause-and-effect-lesson-container-${pageIndex}`}
          causeAndEffectCards={data.causeAndEffectCards}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_CATEGORIZING_ACTIVITY) {
    return (
      <Box key={`categorizing-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <CategorizingPrint
          instructions={data.instructions}
          promptCards={data.promptCards}
          responseCards={data.responseCards}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY) {
    return (
      <Box key={`categorizing-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <FlexibleMatchingPrint
          promptText={data.promptText}
          layout={data.layout}
          promptCards={data.promptCards}
          subText={data.subText}
          responseCards={data.responseCards}
          submitAnswerText={data.submitAnswerText}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY) {
    return (
      <Box key={`multiple-choice-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <MultipleChoiceActivityPrint
          questionCard={data.questionCard}
          answerCards={data.answerCards}
          singlePage={data.singlePage}
        />
      </Box>
    );
  }

  if (data.activityType === ACTIVITY_TYPE_MULTIPLE_CHOICE_ACTIVITY_V2) {
    return (
      <Box key={`multiple-choice-v2-preview-box-container-${pageIndex}`} width='99%' height='100%' display='flex'>
        <MultipleChoiceActivityV2Print
          questionCard={data.questionCard}
          answerCards={data.answerCards}
          promptText={data.promptText}
          subText={data.subText}
          singlePage={data.singlePage}
        />
      </Box>
    );
  }
  return <BlankActivityCard key={`blank-activity-preview-container-${pageIndex}`} />;
}

/**
 * A helper method to get component for showing in preview mode from pages data
 *
 * @export
 * @param {*} pagesData lesson pages data
 * @returns list of components corresponding to the lesson pages data
 */
export function getActivityCardPreviewByActivityType({ activitiyCardData, currentIndex, onCorrectAnswerSelected }) {
  if (!activitiyCardData || activitiyCardData.length === 0) {
    return [];
  }

  return activitiyCardData.map((data, pageIndex) => (
    <Box width='100%' display='flex' justifyContent='center' height='100%' key={`lesson-preview-${pageIndex}`}>
      {
        getActivityCardPlay({
          page: data,
          isCurrentPage: pageIndex === currentIndex,
          onCorrectAnswerSelected,
          onIncorrectAnswerSelected: () => { },
          onActivityFinished: () => { },
          onInteractionOpen: () => { },
          onInstructionFinished: () => { },
          setLessonPageElementRef: () => { },
          index: pageIndex,
          onTriggerSwitchScan: () => { },
          isPreview: true,
        })
      }
    </Box>
  ));
}

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Divider,
  Typography,
} from '@mui/material';
import './FillInTheBlankPreview.scss';
import { BLANK_SPACE, splitQuestionText, getFitbTextLine } from './FillInTheBlankUtils';

const fillInTheBlankExamples = [
  'I like to eat ___ for breakfast.',
  'Which animal barks?___',
  '___ is my first name.',
];

const FillInTheBlankPreview = ({ question }) => {
  const parts = splitQuestionText(question);
  const keyToUse = useMemo(() => uuidv1(), []);

  return (
    <div className='fill-in-the-blank-preview'>
      <div className='example'>
        <Typography variant='h6'>
          Example Questions
        </Typography>
        {fillInTheBlankExamples.map((example, index) => (
          <div className='example-text' key={`example-text-${index}`}>{example}</div>
        ))}
      </div>
      <Divider style={{ height: 8 }} />
      <div className='preview'>
        <Typography variant='h6'>
          Your Question
        </Typography>
        <div className='preview-question'>
          <div className='pre-blank'>
            {getFitbTextLine(parts.preBlank, `pre-blank-${keyToUse}`)}
          </div>
          {BLANK_SPACE}
          <div className='post-blank'>
            {getFitbTextLine(parts.postBlank, `post-blank-${keyToUse}`)}
          </div>
        </div>
      </div>
    </div>
  );
};

FillInTheBlankPreview.defaultProps = {
  question: '',
};

FillInTheBlankPreview.propTypes = {
  question: PropTypes.string,
};

export default FillInTheBlankPreview;

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import './MultipleChoiceV2Layout.scss';
import './MultipleChoiceV2Editor.scss';
import CardEditor from '../common/card-editor/CardEditor';
import {
  AREA_PROMPT_CARDS,
  AREA_PROMPT_TEXT,
  AREA_PROMPT_TEXT_CARD,
  AREA_RESPONSE_CARDS,
  AREA_SUB_TEXT,
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_TEXT,
} from '../common/CardEditorConstants';
import MatchingQuestionKey from '../common/question-key/MatchingQuestionKey';
import MatchingAnswerKey from '../common/question-key/MatchingAnswerKey';
import VizzleSwitch from '../../../switchs/VizzleSwitch';
import AnswerKeyMoveIndicator from './AnswerKeyMoveIndicator';

const generateMoveCardOptions = ({ currentIndex, answerCards }) => {
  const answerCardLength = answerCards.length;
  const options = [];
  for (let i = 0; i < answerCardLength; i++) {
    options.push({
      id: i,
      name: i + 1,
      disabled: i === currentIndex,
    });
  }

  return options;
};

export default function MultipleChoiceV2Editor({
  selectedArea,
  selectedSection,
  selectedCardIndex,
  onAreaAndSectionSelected,
  questionKey,
  onQuestionKeyChange,
  questionCard,
  answerCards,
  onAnswerKeyChange,
  promptText,
  subText,
  randomize,
  onRandomizeFieldChange,
  onCardIndexChange,
}) {
  const id = useMemo(() => uuidv1(), []);

  const handleonAnswerKeyChange = (index, newKey) => {
    const responseCardKeys = answerCards.map(((c) => c.answerKey));
    responseCardKeys[index] = newKey;
    onAnswerKeyChange(responseCardKeys);
  };

  return (
    <div data-test='multiple-choice-v2-editor' className='multiple-choice-v2-layout multiple-choice-v2-editor-component'>
      <Stack flexDirection='row' alignItems='center' gap='30px'>
        <MatchingQuestionKey
          questionKey={questionKey}
          onKeyChange={onQuestionKeyChange}
          className='multiple-choice-v2-question-key'
        />
        <Stack flexDirection='column' justifyItems='center' data-test='multiple-randomize-toggle'>
          <Typography>Specify Answer Order</Typography>
          <VizzleSwitch
            checked={randomize}
            labelWhenSelected='OFF'
            labelWhenUnselected='ON'
            onChange={onRandomizeFieldChange}
            className='vizzle-peding-lesson-switch'
          />
        </Stack>
      </Stack>
      <Tabs
        value={[AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(selectedArea) ? AREA_PROMPT_TEXT_CARD : selectedArea}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_event, newValue) => {
          let area = newValue;
          if (newValue === AREA_PROMPT_TEXT_CARD) {
            area = AREA_PROMPT_TEXT;
          }
          onAreaAndSectionSelected(area, SECTION_TEXT);
        }}
      >
        <Tab label='Prompt Text' value={AREA_PROMPT_TEXT_CARD} data-test='prompt-text-tab' />
        <Tab label='Question' value={AREA_PROMPT_CARDS} data-test='prompt-tab' />
        <Tab label='Answers' value={AREA_RESPONSE_CARDS} data-test='response-cards-tab' />
      </Tabs>
      <div className='editor-content-container'>
        {[AREA_PROMPT_TEXT, AREA_SUB_TEXT].includes(selectedArea) && (
          <div className='prompt-text-container'>
            <Typography>Prompt Text</Typography>
            <CardEditor
              className='multiple-choice-v2-prompt-text'
              config={{
                text: promptText,
              }}
              isSelected={selectedArea === AREA_PROMPT_TEXT}
              selectedSection={selectedSection}
              onTextSelected={() => {
                onAreaAndSectionSelected(AREA_PROMPT_TEXT, SECTION_TEXT, 0);
              }}
              isVideoIncluded={false}
              isImageIncluded={false}
              isAudioIncluded={false}
              data-test='prompt-text-editor'
            />
            <Typography className='sub-text-prompt-text'>Sub Text</Typography>
            <CardEditor
              className='multiple-choice-v2-sub-text'
              config={{
                text: subText,
              }}
              isSelected={selectedArea === AREA_SUB_TEXT}
              selectedSection={selectedSection}
              onTextSelected={() => {
                onAreaAndSectionSelected(AREA_SUB_TEXT, SECTION_TEXT, 0);
              }}
              isVideoIncluded={false}
              isImageIncluded={false}
              isAudioIncluded={false}
              data-test='sub-text-editor'
            />
          </div>
        )}
        {[AREA_PROMPT_CARDS].includes(selectedArea) && (
          <div className='prompt-area' data-test='prompt-cards-container'>
            <div className='prompt-cards-container'>
              <CardEditor
                className='multiple-choice-v2-prompt-card'
                config={{
                  text: questionCard.text,
                  image: questionCard.image,
                  video: questionCard.video,
                  audio: questionCard.audio,
                }}
                isSelected={selectedArea === AREA_PROMPT_CARDS}
                selectedSection={selectedSection}
                onTextSelected={() => {
                  onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_TEXT);
                }}
                onImageSelected={() => {
                  onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_IMAGE);
                }}
                onAudioSelected={() => {
                  onAreaAndSectionSelected(AREA_PROMPT_CARDS, SECTION_AUDIO);
                }}
              />
            </div>
          </div>
        )}
        {[AREA_RESPONSE_CARDS].includes(selectedArea) && (
          <div className='response-cards-container' data-test='response-cards-container'>
            {answerCards.map((card, index) => (
              <CardEditor
                key={`multiple-choice-v2-layout-edit-response-card-key-${index}-${id}-${card.key}`}
                className='multiple-choice-v2-response-card'
                config={{
                  text: card.text,
                  image: card.image,
                  video: card.video,
                  audio: card.audio,
                }}
                isSelected={selectedArea === AREA_RESPONSE_CARDS && selectedCardIndex === index}
                selectedSection={selectedSection}
                onTextSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_TEXT, index);
                }}
                onImageSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_IMAGE, index);
                }}
                onAudioSelected={() => {
                  onAreaAndSectionSelected(AREA_RESPONSE_CARDS, SECTION_AUDIO, index);
                }}
              >
                <div className='answer-card-content-container'>
                  <MatchingAnswerKey
                    value={card.answerKey}
                    onKeyChange={(value) => { handleonAnswerKeyChange(index, value); }}
                    disabled={questionKey === card.answerKey}
                    isCorrect={questionKey === card.answerKey}
                    data-test={`answer-key-${index}`}
                  />
                  {!randomize && (
                    <div className='answer-key-move-indicator-container'>
                      {/* <div className='card-index'>{getMultipleChoicePrefix(index)}</div> */}
                      <AnswerKeyMoveIndicator
                        onChange={(e) => {
                          const data = {
                            currentIndex: card.cardIndex,
                            newIndex: Number(e.target.value),
                          };
                          onCardIndexChange(data);
                        }}
                        value={card.cardIndex}
                        label='Position'
                        dropdownValues={generateMoveCardOptions({ currentIndex: card.cardIndex, answerCards })}
                      />

                    </div>
                  )}
                </div>
              </CardEditor>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

MultipleChoiceV2Editor.defaultProps = {
  selectedArea: AREA_PROMPT_TEXT,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
  onAreaAndSectionSelected: () => null,
  questionKey: null,
  onQuestionKeyChange: () => null,
  questionCard: {},
  answerCards: [{}, {}],
  onAnswerKeyChange: () => null,
  promptText: null,
  subText: null,
  randomize: false,
  onRandomizeFieldChange: () => null,
  onCardIndexChange: () => null,
};

MultipleChoiceV2Editor.propTypes = {
  selectedArea: PropTypes.oneOf([AREA_PROMPT_TEXT, AREA_SUB_TEXT, AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS]),
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  selectedCardIndex: PropTypes.number,
  onAreaAndSectionSelected: PropTypes.func,
  questionKey: PropTypes.string,
  onQuestionKeyChange: PropTypes.func,
  questionCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
  })),
  onAnswerKeyChange: PropTypes.func,
  promptText: PropTypes.object,
  subText: PropTypes.object,
  randomize: PropTypes.bool,
  onRandomizeFieldChange: PropTypes.func,
  onCardIndexChange: PropTypes.func,
};

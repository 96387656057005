import { useState } from 'react';
import PropTypes from 'prop-types';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CardAction from '../../../../components/card/CardAction';
import './ArchiveOrUnArchiveLessonAction.scss';
import { useArchiveUnarchiveLesson } from './TeacherCoursesActionHooks';
import ConfirmationDialog from '../../../../components/dialog/ConfirmationDialog';

export default function ArchiveOrUnArchiveLessonAction({
  courseAssignmentId,
  student,
  date,
  lesson,
  onArchiveSuccess,
}) {
  const [openDialog, setOpenDialog] = useState();

  const icon = lesson.meta.archived ? <UnarchiveIcon /> : <ArchiveIcon />;
  const {
    updateLesson,
  } = useArchiveUnarchiveLesson({
    courseAssignmentId,
    student,
    date,
    onArchiveSuccess,
  });

  return (
    <>
      <CardAction
        icon={icon}
        actionHandler={() => { setOpenDialog(true); }}
        className={`archive-unarchive-lesson-action ${lesson.meta.archived ? 'archived' : ''}`}
        title='Archive'
        ariaLabel='Archive'
      />
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title='Archive Lesson'
          contentText={`Are you sure you want to archive and delete this activity on all assigned dates for ${student.userName}?`}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={() => { setOpenDialog(false); updateLesson(lesson); }}
          onCancel={() => { setOpenDialog(false); }}
        />
      ) : null}
    </>
  );
}

ArchiveOrUnArchiveLessonAction.defaultProps = {
  onArchiveSuccess: () => null,
};

ArchiveOrUnArchiveLessonAction.propTypes = {
  courseAssignmentId: PropTypes.string.isRequired,
  student: PropTypes.shape({
    userName: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    meta: PropTypes.object,
    attributes: PropTypes.object,
    relationships: PropTypes.object,
  }).isRequired,
  onArchiveSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import {
  CircularProgress,
} from '@mui/material';
import './OverlayMaskLoader.scss';

export default function OverlayMaskLoader({ show, blockedScreen, loadingMessage }) {
  return (
    show ? (
      <div className='overlay-mask-loader-component'>
        {blockedScreen && (
          <div className='overlay-mask-loader' />
        )}

        {loadingMessage
          ? (
            <div className='overlay-mask-loading-message'>
              <CircularProgress className='overlay-mask-loading-icon' size={30} />
              <div className='overlay-mask-loading-text' data-private>{loadingMessage}</div>
            </div>
          ) : ''}
      </div>
    ) : ''
  );
}

OverlayMaskLoader.defaultProps = {
  show: false,
  loadingMessage: null,
  blockedScreen: true,
};

OverlayMaskLoader.propTypes = {
  show: PropTypes.bool,
  loadingMessage: PropTypes.string,
  blockedScreen: PropTypes.bool,
};

export const MY_CLASSROOM_ROUTES = {
  STUDENT_MANAGEMENT: 'student-management',
  GROUP_INSTRUCTIONS: 'group-instruction',
  CLASS_REPORT: 'class-report',
  STUDENT_GROUPS: 'student-groups',
  STUDENT_SETTINGS: 'student-settings',
  ASSIGNMENT_OVERVIEW: 'assignment-overview',
  AUTHORED_LESSONS: 'authored-lessons',
  MY_LESSONS: 'my-lessons',
};

export const AREA_INSTRUCTIONS = 'instructions';
export const AREA_PROMPT_TEXT_CARD = 'promptTextCards';
export const AREA_PROMPT_CARDS = 'promptCards';
export const AREA_RESPONSE_CARDS = 'responseCards';
export const AREA_PROMPT_TEXT = 'promptText';
export const AREA_SUB_TEXT = 'subText';
export const AREA_SUBMIT_ANSWER_TEXT = 'submitAnswerText';

export const SECTION_IMAGE = 'image';
export const SECTION_TEXT = 'text';
export const SECTION_AUDIO = 'audio';
export const SECTION_NONE = 'none';

export const SECTION_ANSWER_KEY = 'answer_key';
export const ADD_FITB = 'fitb';

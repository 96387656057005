import DropdownInput from '../../../../../components/selector/DropdownInput';
import './AuthoredLessonCourseFilter.scss';
import { useFetchAuthoredFromService } from '../AuthoredLessonHooks';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useLoadLessonsWhenEnter, usePrepareLessonCourseData } from './AuthoredLessonCourseFilterHook';
import { getTopicDropdownOptions, getUnitDropdownOptions } from '../../../../courses/components/TeacherCourseSearchBarHooks';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';
import { useAuthoredLessonsDomain } from '../stores/AuthoredLessonsDomainProvider';

const AuthoredLessonCourseFilter = () => {
  const { authoredLessonDomain } = useDomain();
  const { authoredLessonsSearchCriteriaDomain } = useAuthoredLessonsDomain();
  const { lessonCourses } = authoredLessonDomain.domainData;
  const { selectedPendingStatus, courseId, unitId, topicId } = authoredLessonsSearchCriteriaDomain.domainData;
  const {
    fetchAuthoredFromService,
  } = useFetchAuthoredFromService();

  const {
    loading,
  } = usePrepareLessonCourseData();

  useLoadLessonsWhenEnter({
    fetchAuthoredFromService,
  });
  const unitDropdown = getUnitDropdownOptions({ courseId, lessonCourses });
  const topicDropdown = getTopicDropdownOptions({ courseId, unitId, lessonCourses });

  return (
    <div className='authored-lessons-filter-lesson-course'>
      <div className='dropdown-selector'>
        <DropdownInput
          inlineLabel='Course'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || loading
          }
          dropdownValues={lessonCourses}
          name='courseId'
          value={courseId || ''}
          onChange={async (e) => {
            await Promise.all([
              authoredLessonsSearchCriteriaDomain.updateCourseId(e.target.value),
              authoredLessonsSearchCriteriaDomain.updateUnitId(null),
              authoredLessonsSearchCriteriaDomain.updateTopicId(null),
            ]);
          }}
          loading={loading}
          data-test='course-selector'
        />
      </div>
      <div className='dropdown-selector'>
        <DropdownInput
          inlineLabel='Unit'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || loading
            || Boolean(!courseId)
          }
          dropdownValues={unitDropdown}
          name='unitId'
          value={unitId || ''}
          onChange={async (e) => {
            await Promise.all([
              authoredLessonsSearchCriteriaDomain.updateUnitId(e.target.value),
              authoredLessonsSearchCriteriaDomain.updateTopicId(null),
            ]);
          }}
          loading={loading}
          data-test='unit-selector'
        />
      </div>
      <div className='dropdown-selector'>
        <DropdownInput
          inlineLabel='Topic'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || loading
            || Boolean(!unitId)
          }
          dropdownValues={topicDropdown}
          name='topicId'
          value={topicId || ''}
          onChange={async (e) => {
            await Promise.all([
              authoredLessonsSearchCriteriaDomain.updateTopicId(e.target.value),
            ]);
          }}
          loading={loading}
          data-test='topic-selector'
        />
      </div>

      <ButtonWithSpinner
        variant='contained'
        className='btn-primary search-button'
        data-test='authored-lessons-search-action'
        label='Search'
        disabled={
          Boolean(!courseId)
          || Boolean(!unitId)
          || Boolean(!topicId)
        }
        onClick={() => {
          fetchAuthoredFromService(selectedPendingStatus, courseId, topicId);
        }}
      />
    </div>
  );
};

export default AuthoredLessonCourseFilter;

import {
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getLessonGradeBand } from '../../../../../services/LessonStandardService';
import Logger from '../../../../../utils/Logger';
import { getSubjectObjectBySubjectName } from '../../../../../services/SubjectService';

/**
 * Container for Subject selection in Lesson Save screen
 *
 * @export
 */
export default function LessonGradeLevelSelector() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const handleLevelSelected = (event) => {
    Logger.logWhenDebugModeIsOn({
      GRADE_LEVEL: event.target.value,
    });

    const updatedLessonData = {
      ...lessonData,
      attributes: {
        ...lessonData.attributes,
        rawGradeLevel: event.target.value,
      },
    };

    lessonDataMaintenanceDomain.updateLessonData(updatedLessonData);
  };

  // eslint-disable-next-line prefer-destructuring
  const subjectId = getSubjectObjectBySubjectName(lessonData.attributes.subjects[0] || '').subjectId;

  return (
    <Box width='100%'>
      <Box display='flex'>
        <Typography variant='h6'>
          Grade Band
        </Typography>
      </Box>
      <TextField
        select
        value={lessonData.attributes.rawGradeLevel || ''}
        onChange={handleLevelSelected}
        variant='outlined'
        placeholder='Subject'
        fullWidth
        className='vizzle-lesson-grade-band-selector dropdown-small-padding'
      >
        {getLessonGradeBand(subjectId).map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAdmin } from '../../../context/AdminContextProvider';
import GenericButton from '../../../components/buttons/GenericButton';
import { useGetStudentName, useDeactivateStudents } from './StudentActionHooks';
import CustomDialog from '../../../../../components/dialog/CustomDialog';

const DeactivateStudentAction = ({ onStudentDeactivateSuccess }) => {
  const [open, setOpen] = useState(false);
  const { adminStudentsDomain } = useAdmin();
  const { selectedStudentIds } = adminStudentsDomain.domainData;
  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    getStudentName,
  } = useGetStudentName();

  const {
    deactivateStudents,
    loading,
  } = useDeactivateStudents({
    setOpen,
    onStudentDeactivateSuccess,
  });

  const confirmActions = [{
    onClick: handleOnCancel,
    className: 'btn-danger margin-right',
    title: 'No',
    loading,
  }, {
    onClick: () => {
      deactivateStudents();
    },
    className: 'btn-primary action-button',
    title: 'Yes',
    loading,
  },
  ];

  return (
    <>
      <GenericButton
        ariaLabel={`Deactivate Student${selectedStudentIds && selectedStudentIds.length > 1 ? 's' : ''}`}
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedStudentIds || selectedStudentIds.length === 0}
        icon={RemoveCircleIcon}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          onClose={handleOnCancel}
          title={`Deactivate Student${selectedStudentIds.length > 1 ? 's' : ''}`}
          content={(
            <div data-private>
              Are you sure you want to deactivate {selectedStudentIds.length === 1 ? getStudentName(selectedStudentIds[0]) : 'the selected students'}?
            </div>
          )}
          displayCloseIcon
          actions={confirmActions}
        />
      )}
    </>
  );
};

DeactivateStudentAction.defaultProps = {
  onStudentDeactivateSuccess: () => null,
};

DeactivateStudentAction.propTypes = {
  onStudentDeactivateSuccess: PropTypes.func,
};

export default DeactivateStudentAction;

import EditableSingleFieldForm from '../../../../../../components/form/EditableSingleFieldForm';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetUnit } from '../TeacherCourseLessonsHooks';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { updateUnit } from '../../../../../../services/CourseService';
import './EditCourseData.scss';
import UnitActiveAction from './UnitActiveAction';

const EditUnitAction = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { courseId, unitId } = searchCriteria;
  const unit = useGetUnit(courseId, unitId);

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const handleSaveData = async (formData) => {
    const originalUnitName = unit.name;
    if (originalUnitName === formData) {
      return;
    }
    try {
      await callRemoteServiceWrapper(async () => {
        await updateUnit(courseId, unitId, {
          name: formData,
          active: unit.active,
        });
        unit.name = formData;
        teacherCourseDomain.updateLessonCourses(lessonCourses);
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `Unit ${originalUnitName} has been updated to ${unit.name}.`);
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return (
    <EditableSingleFieldForm
      formData={unit.name}
      handleSaveData={handleSaveData}
      label='Unit'
      placeholderLabel='Unit'
      className='edit-course-data-single-form'
      title='Unit'
    >
      <UnitActiveAction />
    </EditableSingleFieldForm>
  );
};

export default EditUnitAction;

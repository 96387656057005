import { useRef, useState } from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useAdmin } from '../../../context/AdminContextProvider';
import GenericButton from '../../../components/buttons/GenericButton';
import './TeacherSchoolRosterAccessAction.scss';
import TeacherSchoolRosterSchoolSelector from './forms/TeacherSchoolRosterSchoolSelector';
import { useUpdateTeacherSchoolsAccess } from './TeacherActionHooks';

const TeachersSchoolRosterAccessAction = () => {
  const [open, setOpen] = useState(false);
  const dataRef = useRef([]);

  const { adminTeachersDomain, adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedTeacherIds } = adminTeachersDomain.domainData;

  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    saving,
    updateTeacherShoolsAccess,
  } = useUpdateTeacherSchoolsAccess({
    setOpen,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading: saving,
    }, {
      onClick: () => {
        updateTeacherShoolsAccess([], dataRef.current);
      },
      className: 'btn-primary action-button',
      title: 'Save',
      loading: saving,
    },
    ];
    return buttons;
  };

  return (
    <>
      <GenericButton
        ariaLabel='Give Access to Other School Rosters'
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedTeacherIds || selectedTeacherIds.length < 1}
        icon={LocationCityIcon}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='teacher-school-roster-access-dialog'
          onClose={handleOnCancel}
          title='School Roster Shared Access'
          content={(
            <TeacherSchoolRosterSchoolSelector
              initSelectedSchoolIds={[]}
              disabledSchoolIds={[selectedSchoolId]}
              onDataChange={(updatedData) => { dataRef.current = updatedData; }}
              sortBySelectedIds
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

export default TeachersSchoolRosterAccessAction;

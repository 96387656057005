import { useState } from 'react';
import PropTypes from 'prop-types';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useAdmin } from '../../../context/AdminContextProvider';
import GenericButton from '../../../components/buttons/GenericButton';
import { useGetTeacherName, useReactivateTeachers } from './TeacherActionHooks';
import CustomDialog from '../../../../../components/dialog/CustomDialog';

const ReactivateTeacherAction = ({ onTeacherReactivateSuccess }) => {
  const [open, setOpen] = useState(false);
  const { adminTeachersDomain } = useAdmin();
  const { selectedTeacherIds } = adminTeachersDomain.domainData;
  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    getTeacherName,
  } = useGetTeacherName();

  const {
    reactivateTeachers,
    loading,
  } = useReactivateTeachers({
    setOpen,
    onTeacherReactivateSuccess,
  });

  const confirmActions = [{
    onClick: handleOnCancel,
    className: 'btn-danger margin-right',
    title: 'No',
    loading,
  }, {
    onClick: () => {
      reactivateTeachers();
    },
    className: 'btn-primary action-button',
    title: 'Yes',
    loading,
  },
  ];

  return (
    <>
      <GenericButton
        ariaLabel={`Reactivate Teacher${selectedTeacherIds.length > 1 ? 's' : ''}`}
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedTeacherIds || selectedTeacherIds.length === 0}
        icon={PostAddIcon}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          onClose={handleOnCancel}
          title={`Reactivate Teacher${selectedTeacherIds.length > 1 ? 's' : ''}`}
          content={(
            <div data-private>
              Are you sure you want to reactivate {selectedTeacherIds.length === 1 ? getTeacherName(selectedTeacherIds[0]) : 'the selected teachers'}?
            </div>
          )}
          displayCloseIcon
          actions={confirmActions}
        />
      )}
    </>
  );
};

ReactivateTeacherAction.defaultProps = {
  onTeacherReactivateSuccess: () => null,
};

ReactivateTeacherAction.propTypes = {
  onTeacherReactivateSuccess: PropTypes.func,
};

export default ReactivateTeacherAction;

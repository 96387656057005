import PropTypes from 'prop-types';
import { MenuItem, Select, Tab, Tabs } from '@mui/material';
import './CategorizingEditor.scss';
import {
  AREA_INSTRUCTIONS,
  AREA_PROMPT_CARDS,
  AREA_RESPONSE_CARDS,
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_TEXT,
} from '../common/CardEditorConstants';
import PageInstructionEditor from './editor/PageInstructionEditor';
import CategorizingCardsEditor from './editor/CategorizingCardsEditor';

const NUMBER_OF_PROMPTS = 8;
const NUMBER_OF_RESPONSES = 8;

const getOptionCards = (num) => {
  const result = [];
  for (let i = 1; i <= num; i++) {
    result.push({
      value: i,
      label: i,
    });
  }
  return result;
};

const promptOptions = getOptionCards(NUMBER_OF_PROMPTS);
const responseOptions = getOptionCards(NUMBER_OF_RESPONSES);

const promptCardKey = (card, index) => (
  <div className='categorizing-prompt-card-key' data-test={`categorizing-prompt-card-key-${index}`} key={card.key}>{card.key}</div>
);
export default function CategorizingEditor({
  selectedArea,
  selectedSection,
  selectedCardIndex,
  onAreaAndSectionSelected,
  instructions,
  promptCardsConfig,
  responseCardsConfig,
}) {
  const handleOnResponseCardKey = (e, field, cardIndex) => {
    if (selectedSection.selectedCardIndex === cardIndex) {
      return;
    }
    onAreaAndSectionSelected(field, SECTION_IMAGE, cardIndex);
  };

  const responseCardKey = (card, cardIndex) => (
    responseCardsConfig.availableCategorizingKeys
      ? (
        <>
          <Select
            value={card.key || ''}
            onChange={(e) => { responseCardsConfig.onKeyChanged(e, cardIndex); }}
            onOpen={(e) => handleOnResponseCardKey(e, AREA_RESPONSE_CARDS, cardIndex)}
            variant='outlined'
            placeholder='Key'
            fullWidth
            className='response-card-key'
            data-test={`response-card-key-${cardIndex}`}
          >
            {responseCardsConfig.availableCategorizingKeys.map((key, keyIndex) => (
              <MenuItem
                className='categorizing-response-key'
                key={`categorizing-editor-response-key-selector-${cardIndex}-${keyIndex}`}
                value={key}
              >
                {key}
              </MenuItem>
            ))}
          </Select>
          {
            responseCardsConfig.availableCategorizingKeys[0] === card.key ? <div className='response-key-overlay' /> : ''
          }
        </>
      ) : ''
  );

  return (
    <div className='categorizing-editor' data-test='categorizing-editor'>
      <Tabs
        value={selectedArea}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_event, newValue) => {
          onAreaAndSectionSelected(newValue, SECTION_TEXT);
        }}
      >
        <Tab label='Instruction' value={AREA_INSTRUCTIONS} data-test='instruction' />
        <Tab label='Categories' value={AREA_PROMPT_CARDS} data-test='categories' />
        <Tab label='Answers' value={AREA_RESPONSE_CARDS} data-test='answers' />
      </Tabs>
      <div className='editor-content-container'>
        {selectedArea === AREA_INSTRUCTIONS && (
          <PageInstructionEditor
            isSelected
            selectedSection={selectedSection}
            onSectionSelected={(newSection) => {
              onAreaAndSectionSelected(AREA_INSTRUCTIONS, newSection);
            }}
            instructions={instructions}
          />
        )}
        {selectedArea === AREA_PROMPT_CARDS && (
          <CategorizingCardsEditor
            isSelected
            selectedSection={selectedSection}
            selectedCardIndex={selectedCardIndex}
            onSectionSelected={(newSection, index) => {
              onAreaAndSectionSelected(AREA_PROMPT_CARDS, newSection, index);
            }}
            onNumberOfCardsChange={promptCardsConfig.onNumberOfPromptCardChange}
            cards={promptCardsConfig.promptCards}
            options={promptOptions}
            title='Number of Categories'
            createCardChildrenFuncton={promptCardKey}
            isAudioIncluded={false}
            isVideoIncluded={false}
            data-test='number-of-categories'
          />
        )}
        {selectedArea === AREA_RESPONSE_CARDS && (
          <CategorizingCardsEditor
            className='response-cards-container'
            isSelected
            selectedSection={selectedSection}
            selectedCardIndex={selectedCardIndex}
            onSectionSelected={(newSection, index) => {
              onAreaAndSectionSelected(AREA_RESPONSE_CARDS, newSection, index);
            }}
            onNumberOfCardsChange={responseCardsConfig.onNumberOfResponseCardChange}
            cards={responseCardsConfig.responseCards}
            options={responseOptions}
            title='Number of Answers'
            createCardChildrenFuncton={responseCardKey}
            data-test='number-of-answers'
          />
        )}
      </div>
    </div>
  );
}

CategorizingEditor.defaultProps = {
  selectedArea: AREA_INSTRUCTIONS,
  selectedSection: SECTION_TEXT,
  selectedCardIndex: 0,
  onAreaAndSectionSelected: () => null,
  instructions: {},
  promptCardsConfig: {
    promptCards: [],
    onNumberOfPromptCardChange: () => null,
  },
  responseCardsConfig: {
    responseCards: [],
    onNumberOfResponseCardChange: () => null,
    availableCategorizingKeys: [],
    onKeyChanged: () => null,
  },
};

CategorizingEditor.propTypes = {
  selectedArea: PropTypes.oneOf([AREA_INSTRUCTIONS, AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS]),
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  selectedCardIndex: PropTypes.number,
  onAreaAndSectionSelected: PropTypes.func,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    autio: PropTypes.string,
  }),
  promptCardsConfig: PropTypes.shape({
    promptCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      video: PropTypes.string,
    })),
    onNumberOfPromptCardChange: PropTypes.func,
  }),
  responseCardsConfig: PropTypes.shape({
    responseCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      video: PropTypes.string,
    })),
    onNumberOfResponseCardChange: PropTypes.func,
    availableCategorizingKeys: PropTypes.arrayOf(PropTypes.string),
    onKeyChanged: PropTypes.func,
  }),
};

import { Slide } from '@mui/material';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import VizzleSnackbar from '../../../components/snackbar/VizzleSnackbar';
import {
  STATE,
  useControlResetPassword,
  useHandleSnackbar,
} from './ResetPasswordHooks';
import ResetPasswordForm from './ResetPasswordForm';
import './ResetPassword.scss';
import ResetPasswordSuccess from './ResetPasswordSuccess';

const ResetPassword = () => {
  const {
    state,
    loading,
    handleOnResetPassword,
  } = useControlResetPassword();

  const {
    snackbar,
    onSnackbarClose,
  } = useHandleSnackbar();

  return (
    <>
      <Slide
        direction={state.slide.direction}
        timeout={600}
        in={state.slide.in}
        mountOnEnter
        unmountOnExit
      >
        <div className='reset-password'>
          <div className='vizzle-logo-container'>
            <img src={VizzleLogo} alt='Vizzle Logo' />
          </div>
          <div className='title'>
            Reset Password
          </div>
          <div className='reset-password-content'>
            {state.form === STATE.RESET_FORM && (
              <ResetPasswordForm loading={loading} onSubmit={handleOnResetPassword} />
            )}
            {state.form === STATE.RESET_SUCCESS && (
              <ResetPasswordSuccess />
            )}
          </div>
        </div>
      </Slide>
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </>
  );
};

export default ResetPassword;

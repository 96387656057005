import { useState } from 'react';
import { eachDayOfInterval, isWeekend } from 'date-fns';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import DateUtils from '../../../../../../utils/DateUtils';
import { useMyClassroom } from '../../../../context/MyClassRoomContextProvider';
import { ASSIGNMNET_STATUS } from '../AssignmentOverviewConstant';

export const useGetAssignmentsInWeek = () => {
  const { myAssignmentsDomain } = useMyClassroom();
  const { assignedStudentCourses, selectedDates, assignedStudentCoursesLoading } = myAssignmentsDomain.domainData;

  const { studentsDomain } = useDomain();
  const { students } = studentsDomain.domainData;

  let dates = selectedDates.dates ? [...selectedDates.dates] : [];
  if (selectedDates.isDateRange && selectedDates.dates && selectedDates.dates.length === 2) {
    const [start, end] = selectedDates.dates;
    dates = eachDayOfInterval({
      start: DateUtils.parseDateInMMddYYYY(start),
      end: DateUtils.parseDateInMMddYYYY(end),
    });
    dates = dates.filter((d) => !isWeekend(d)).map((d) => DateUtils.convertDateToYYYYMMdd(d));
  }

  const assignedStudentCoursesCardData = dates.map((date) => {
    const assignedStudentCoursesInDate = assignedStudentCourses.filter((a) => a.date === date);

    const studentIds = assignedStudentCoursesInDate.map((a) => a.userId);
    const uniqueIds = [...new Set(studentIds)];

    let assignmentStatus = ASSIGNMNET_STATUS.noAssignment;
    if (students.length > 0) {
      if (uniqueIds.length === students.length) {
        assignmentStatus = ASSIGNMNET_STATUS.allStudentAssigned;
      } else if (uniqueIds.length > 0 && uniqueIds.length < students.length) {
        assignmentStatus = ASSIGNMNET_STATUS.someStudentsAssigned;
      }
    }

    return {
      date,
      assignedStudentCourses: assignedStudentCoursesInDate,
      assignmentStatus,
    };
  });

  return {
    assignedStudentCoursesLoading,
    assignedStudentCoursesCardData,
  };
};

export const useHandleViewAssignStudentCourses = () => {
  const [assignmnetDialog, setAssignmnetDialog] = useState({
    open: false,
    data: undefined,
  });

  const openAssignmentDialog = (data) => {
    setAssignmnetDialog({
      open: true,
      data,
    });
  };

  const closeAssignmentDialog = () => {
    setAssignmnetDialog({
      open: false,
      data: undefined,
    });
  };

  return {
    assignmnetDialog,
    openAssignmentDialog,
    closeAssignmentDialog,
  };
};

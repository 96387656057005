import { useEffect, useState } from 'react';
import { useDomain } from '../../../states/AppDomainProvider';
import LessonSteppers from './LessonSteppers';
import SelectableLoader, { LOADER_TYPE } from '../../../components/loaders/SelectableLoader';
import ObjectUtils from '../../../utils/ObjectUtils';
import { getAuthoringProfile } from '../../../services/UserProfileService';
import useInitilizeDataForAuthoring from './useInitilizeDataForAuthoring';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import Logger from '../../../utils/Logger';
import VizzleMainContainer from '../../vizzle/VizzleMainContainer';

/**
 * Container component for creating a new lesson
 */
export default function LessonCreateContainer(props) {
  const { lessonDataMaintenanceDomain, uiDomain, authoringProfileDomain } = useDomain();
  const [isReady, setIsReady] = useState();
  useInitilizeDataForAuthoring();

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const prepareData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await lessonDataMaintenanceDomain.resetDomainData();
        const authoringProfile = await getAuthoringProfile();
        if (authoringProfile.data && authoringProfile.data.id) {
          authoringProfileDomain.updateAuthoringProfile(authoringProfile.data);
        }
        ObjectUtils.setTimeout(() => {
          setIsReady(true);
        }, 1500);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    prepareData();

    // Cleanup the state
    return () => {
      lessonDataMaintenanceDomain.resetDomainData();
      uiDomain.closeSnackbar();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isReady
      ? <LessonSteppers {...props} /> : (
        <VizzleMainContainer
          style={{ height: '100vh' }}
        >
          <div>
            <SelectableLoader open loaderType={LOADER_TYPE.RING_LOADER} message='Preparing data' />
          </div>
        </VizzleMainContainer>
      )
  );
}

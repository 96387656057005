import PropTypes from 'prop-types';
import SortingResponse from './SortingResponse';
import './SelectedResponseCards.scss';

export default function SelectedResponseCards({
  pageIndex,
  promptKey,
  responseCards,
  selectedResponseCards,
}) {
  const matchedResponseCards = [];
  selectedResponseCards.forEach((cardIndex) => {
    if (responseCards[cardIndex].key === promptKey) {
      matchedResponseCards.push(responseCards[cardIndex]);
    }
  });

  return (
    <div className='selected-response-cards-component'>
      {matchedResponseCards.map((card, cardIndex) => (
        <div
          key={`selected-response-display-${pageIndex}-${cardIndex}`}
          className={`${matchedResponseCards.length < 7 ? 'selected-response-display' : 'selected-response-display-small'} `}
        >
          <SortingResponse responseCard={card} disablePlayMedia />
        </div>
      ))}
    </div>
  );
}

SelectedResponseCards.defaultProps = {
  pageIndex: 0,
  promptKey: null,
  responseCards: [],
  selectedResponseCards: [],
};

SelectedResponseCards.propTypes = {
  pageIndex: PropTypes.number,
  promptKey: PropTypes.string,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  selectedResponseCards: PropTypes.arrayOf(PropTypes.number),
};

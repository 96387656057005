import PropTypes from 'prop-types';
import {
  CircularProgress,
} from '@mui/material';
import './PdfContainer.scss';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useCheckPdf } from '../../../../hooks/CheckPdfLinkHooks';

const PdfContainer = ({
  pdfKey,
  loadingMessage,
  noPdfFoundMessage,
  className,
}) => {
  const {
    loading,
    signedPdfUrl,
  } = useCheckPdf(pdfKey);

  if (loading) {
    return (
      <div className='pdf-container-loading'>
        <CircularProgress size={40} />
        <div className='loading-message'>
          {loadingMessage}
        </div>
      </div>
    );
  }

  if (!signedPdfUrl) {
    return (
      <NoLessonPaper message={noPdfFoundMessage} />
    );
  }

  return (
    <object className={`pdf-container ${className}`} data={signedPdfUrl} type='application/pdf'>
      <iframe title='Guide PDF' src={signedPdfUrl} />
    </object>
  );
};

PdfContainer.defaultProps = {
  pdfKey: null,
  loadingMessage: null,
  noPdfFoundMessage: 'No pdf found.',
  className: '',
};

PdfContainer.propTypes = {
  pdfKey: PropTypes.string,
  loadingMessage: PropTypes.string,
  noPdfFoundMessage: PropTypes.string,
  className: PropTypes.string,
};

export default PdfContainer;

import BaseDomain from '../../../states/domains/BaseDomain';

const MY_STUDENTS_UPDATE_MASTERY_OBJECTIVES = 'MY_STUDENTS_UPDATE_MASTERY_OBJECTIVES';
const MY_STUDENTS_UPDATE_MASTERY_AND_LESSON_TAGS_OBJECTIVES = 'MY_STUDENTS_UPDATE_MASTERY_AND_LESSON_TAGS_OBJECTIVES';
const MY_STUDENTS_UPDATE_LESSONS_LIST = 'MY_STUDENTS_UPDATE_LESSONS_LIST';
const MY_STUDENTS_UPDATE_LESSONS_LIST_AND_LESSON_TAGS = 'MY_STUDENTS_UPDATE_LESSONS_LIST_AND_LESSON_TAGS';
const MY_STUDENTS_UPDATE_LESSONS_LIST_AND_SELECTION = 'MY_STUDENTS_UPDATE_LESSONS_LIST_AND_SELECTION';
const MY_STUDENTS_UPDATE_OBJECTIVE_FILTER_VALUE = 'MY_STUDENTS_UPDATE_OBJECTIVE_FILTER_VALUE';
const MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS = 'MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS';
const MY_STUDENTS_UPDATE_LESSON_FILTER_VALUE = 'MY_STUDENTS_UPDATE_LESSON_FILTER_VALUE';
const MY_STUDENTS_UPDATE_LESSON_TAGS = 'MY_STUDENTS_UPDATE_LESSON_TAGS';
const MY_STUDENTS_UPDATE_SELECTED_PROFILE = 'MY_STUDENTS_UPDATE_SELECTED_PROFILE';
const MY_STUDENTS_FETCHGING_SELECTED_PROFILE = 'MY_STUDENTS_FETCHGING_SELECTED_PROFILE';
const MY_STUDENTS_UPDATE_DETAILED_REPORT = 'MY_STUDENTS_UPDATE_DETAILED_REPORT';
const MY_STUDENTS_GET_TERMINOLOGIES = 'MY_STUDENTS_GET_TERMINOLOGIES';
const MY_STUDENTS_CUSTOM_UPDATE = 'MY_STUDENTS_CUSTOM_UPDATES';
const MY_STUDENTS_SELECTED_DATES = 'MY_STUDENTS_SELECTED_DATES';
const MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING = 'MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING';
const MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES = 'MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES';
const MY_STUDENTS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES = 'MY_STUDENTS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES';
const MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER = 'MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER';
const MY_STUDENTS_UPDATE_REVIEW_AND_FEEDBACK = 'MY_STUDENTS_UPDATE_REVIEW_AND_FEEDBACK';

export const defaultFilterCriteriaAssignedStudentCourses = {
  studentIds: null,
  lessonIds: null,
};

const initialState = {
  masterObjectiveData: {
    masterObjectives: [],
    included: [],
    objectiveFilterValue: {},
  },
  lessonData: {
    lessons: [],
    included: [],
    lessonFilterValue: {
      keyword: '',
    },
    selectedLessonIds: [],
    filter: {
      selectedSubject: null,
      isExpiredAssignment: false,
    },
  },
  trackingDetailedReport: {
    criteria: {},
    reportData: [],
    summaryData: {},
  },
  lessonTags: [],
  selectedStudentProfile: {},
  loadingStudent: false,
  terminologies: {},
  selectedDates: {},
  assignedStudentCoursesLoading: false,
  filterCriteriaAssignedStudentCourses: JSON.parse(JSON.stringify(defaultFilterCriteriaAssignedStudentCourses)),
  assignedStudentCourses: [],
  reviewAndFeedback: {
    data: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case MY_STUDENTS_UPDATE_MASTERY_OBJECTIVES:
      return {
        ...state,
        masterObjectiveData: {
          ...state.masterObjectiveData,
          ...action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_MASTERY_AND_LESSON_TAGS_OBJECTIVES:
      return {
        ...state,
        masterObjectiveData: {
          ...state.masterObjectiveData,
          ...action.payload.masterObjectiveData,
        },
        lessonTags: [
          ...action.payload.lessonTags,
        ],
      };
    case MY_STUDENTS_UPDATE_OBJECTIVE_FILTER_VALUE:
      return {
        ...state,
        masterObjectiveData: {
          ...state.masterObjectiveData,
          objectiveFilterValue: action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_LESSONS_LIST:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_LESSONS_LIST_AND_LESSON_TAGS:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload.lessonData,
        },
        lessonTags: [
          ...action.payload.lessonTags,
        ],
      };
    case MY_STUDENTS_UPDATE_LESSONS_LIST_AND_SELECTION:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          ...action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_LESSON_FILTER_VALUE:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          lessonFilterValue: action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          filter: action.payload.filter,
          selectedLessonIds: action.payload.selectedLessonIds,
        },
      };
    case MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS:
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          selectedLessonIds: action.payload,
        },
      };
    case MY_STUDENTS_UPDATE_LESSON_TAGS:
      return {
        ...state,
        lessonTags: action.payload,
      };
    case MY_STUDENTS_UPDATE_SELECTED_PROFILE:
      return {
        ...state,
        selectedStudentProfile: action.payload,
        loadingStudent: false,
      };
    case MY_STUDENTS_FETCHGING_SELECTED_PROFILE:
      return {
        ...state,
        loadingStudent: action.payload,
      };
    case MY_STUDENTS_UPDATE_DETAILED_REPORT:
      return {
        ...state,
        trackingDetailedReport: {
          ...action.payload,
        },
      };
    case MY_STUDENTS_GET_TERMINOLOGIES:
      return {
        ...state,
        terminologies: action.payload,
      };
    case MY_STUDENTS_CUSTOM_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case MY_STUDENTS_SELECTED_DATES:
      return {
        ...state,
        selectedDates: action.payload,
      };
    case MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING:
      return {
        ...state,
        assignedStudentCoursesLoading: action.payload,
      };
    case MY_STUDENTS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        filterCriteriaAssignedStudentCourses: action.payload,
      };
    case MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES:
      return {
        ...state,
        assignedStudentCourses: action.payload.assignedStudentCourses,
        assignedStudentCoursesLoading: action.payload.assignedStudentCoursesLoading,
      };
    case MY_STUDENTS_UPDATE_REVIEW_AND_FEEDBACK:
      return {
        ...state,
        reviewAndFeedback: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'myStudentDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateMasteryObjectives(objectives, included) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_MASTERY_OBJECTIVES,
      payload: {
        masterObjectives: objectives,
        included,
      },
    });
  }

  async updateMasteryObjectivesAndLessonTags(objectives, included, lessonTags) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_MASTERY_AND_LESSON_TAGS_OBJECTIVES,
      payload: {
        masterObjectiveData: {
          masterObjectives: objectives,
          included,
        },
        lessonTags,
      },
    });
  }

  async updateObjectiveFilterValue(objectiveFilterValue) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_OBJECTIVE_FILTER_VALUE,
      payload: objectiveFilterValue,
    });
  }

  /**
   * Update student's lessons
   *
   * @returns Promise
   */
  async updateLessons(lessons, included, filter) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_LESSONS_LIST,
      payload: {
        lessons,
        included,
        filter,
      },
    });
  }

  async updateLessonsAndTags(lessons, included, lessonTags, filter, selectedLessonIds = []) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_LESSONS_LIST_AND_LESSON_TAGS,
      payload: {
        lessonData: {
          lessons,
          included,
          filter,
          selectedLessonIds,
        },
        lessonTags,
      },
    });
  }

  /**
   * Update student's lessons and selected lessons
   *
   * @returns Promise
   */
  async updateLessonsAndSelectedLessonIds(lessons, included, selectedLessonIds) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_LESSONS_LIST_AND_SELECTION,
      payload: {
        lessons,
        included,
        selectedLessonIds,
      },
    });
  }

  /**
  * Update lesson filter value
  *
  * @returns Promise
  */
  async updateLessonFilterValue(lessonFilterValue) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_LESSON_FILTER_VALUE,
      payload: lessonFilterValue,
    });
  }

  async updateSelectedLessonIdsAndFilter(selectedLessonIds, filter) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS_AND_FILTER,
      payload: {
        filter,
        selectedLessonIds,
      },
    });
  }

  /**
  * Update lesson filter value
  *
  * @returns Promise
  */
  async updateSelectedLessonIds(selectedLessonIds) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_SELECTED_LESSON_IDS,
      payload: selectedLessonIds,
    });
  }

  async updateLessonTag(lessonTags) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_LESSON_TAGS,
      payload: lessonTags,
    });
  }

  /**
   *  Update selected student profile
   */
  async updateSelectedStudentProfile(selectdStudentProfile) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_SELECTED_PROFILE,
      payload: selectdStudentProfile,
    });
  }

  async isFetchingSelectedStudentProfile(isFetching) {
    return this.dispatch({
      type: MY_STUDENTS_FETCHGING_SELECTED_PROFILE,
      payload: isFetching,
    });
  }

  async updateDetailedReport(criteria, reportData, summaryData) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_DETAILED_REPORT,
      payload: {
        criteria,
        reportData,
        summaryData,
      },
    });
  }

  async getTerminologies(terminologies) {
    return this.dispatch({
      type: MY_STUDENTS_GET_TERMINOLOGIES,
      payload: terminologies,
    });
  }

  async customUpdate(data) {
    return this.dispatch({
      type: MY_STUDENTS_CUSTOM_UPDATE,
      payload: data,
    });
  }

  async updateSelectedDates(selectedDates) {
    return this.dispatch({
      type: MY_STUDENTS_SELECTED_DATES,
      payload: selectedDates,
    });
  }

  async updateAssignedStudentsCoursesLoading(loading) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES_LOADING,
      payload: loading,
    });
  }

  async updateFilterCriteriaAssignedStudentsCourses(filterCriteriaAssignedStudentCourses) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_FILTER_CRITERIA_ASSIGNED_STUDENT_COURSES,
      payload: filterCriteriaAssignedStudentCourses,
    });
  }

  async updateAssignedStudentsCourses(assignedStudentCourses, assignedStudentCoursesLoading) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_ASSIGNED_STUDENT_COURSES,
      payload: {
        assignedStudentCourses,
        assignedStudentCoursesLoading,
      },
    });
  }

  async updateReviewAndFeedback(data) {
    return this.dispatch({
      type: MY_STUDENTS_UPDATE_REVIEW_AND_FEEDBACK,
      payload: data,
    });
  }
}

import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../../../components/loaders/LoadingSpinner';
import LessonCardListDetail from '../../../../../../components/vizzle/cardlistdetail/lesson/LessonCardListDetail';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { calculateCardSize } from '../../../../../../utils/CardSizeCalculator';
import SearchLessonsFilter from './filter/SearchLessonsFilter';
import './SearchLessonList.scss';
import { getFilterdLessons, useHandleFilter } from './SearchLessonListHooks';

const standardCardSize = calculateCardSize();
const cardSize = {
  width: standardCardSize.width * 0.94,
  slidesToShow: standardCardSize.slidesToShow,
  slidesToScroll: standardCardSize.slidesToScroll,
};

const SearchLessonList = ({
  onLessonSelect,
  selectedLessonId,
  loadSearchLessonsData,
  loading,
}) => {
  const { userDomain, authoredLessonDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;
  const { searchLessonsData } = authoredLessonDomain.domainData;

  const {
    filterValue,
    handleOnFilterUpdate,
    handleOnSearchFormSubmit,
  } = useHandleFilter({
    loadSearchLessonsData,
  });

  const filteredLessons = getFilterdLessons({
    lessons: searchLessonsData.lessons,
    filterValue,
  });

  return (
    <div className='copy-lesson-search-lesson-list' data-test='copy-lesson-search-lesson-list'>
      <SearchLessonsFilter
        filterValue={filterValue}
        onFilterUpdate={handleOnFilterUpdate}
        onSearchFormSubmit={handleOnSearchFormSubmit}
      />
      <div className='lesson-list-container'>
        {loading ? (
          <div className='loading-spinner-container'>
            <LoadingSpinner message='Loading lessons' />
          </div>
        ) : (
          <LessonCardListDetail
            lessonData={{
              lessons: filteredLessons,
              included: searchLessonsData.included,
            }}
            cardSize={cardSize}
            noFilter
            multiSelectFilterConfig={[]}
            noLessonMessage='No Activities'
            currentUser={user}
            currentUserProfle={userProfile}
            displayFastNavigator
            selectedLessonIds={[selectedLessonId]}
            onLessonSelect={onLessonSelect}
          />
        )}
      </div>
    </div>
  );
};

SearchLessonList.defaultProps = {
  onLessonSelect: () => null,
  selectedLessonId: '',
  loadSearchLessonsData: () => null,
  loading: true,
};

SearchLessonList.propTypes = {
  onLessonSelect: PropTypes.func,
  selectedLessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadSearchLessonsData: PropTypes.func,
  loading: PropTypes.bool,
};

export default SearchLessonList;

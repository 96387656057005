import { useNavigate } from 'react-router-dom';
import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileUploader from '../../../../file-uploader/FileUploader';
import './ExtensionActivitiesFileUpload.scss';
import DeleteFileAction from './actions/DeleteFileAction';
import ExtensionActivitesUploadFileMessage from './ExtensionActivitesUploadFileMessage';
import RenameFileAction from './actions/RenameFileAction';
import SelectAllAction from './actions/SelectAllAction';
import { EXTENSION_ACTIVITIES_ROUTES } from '../../ExtensionActivitiesConstants';
import { useDeleteFiles, useHandleFileSelection, useHandleFilesUpload } from './ExtensionActivitiesFileUploadHooks';
import LoadingSpinner from '../../../../../../../components/loaders/LoadingSpinner';
import { useTeacherCourses } from '../../../../../context/TeacherCourseContextProvider';
import { useGetExtensionActivities } from '../ExtensionActivitiesHooks';
import { sort } from '../../../../../../../utils/ArrayUtils';
import ViewFilesAction from './actions/ViewFilesAction';

const ExtensionActivitiesFileUpload = () => {
  const { extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities, selectedActivityIds } = extensionActivitiesDomain.domainData;
  const { activities } = extensionActivities;

  const navigate = useNavigate();

  const { loading } = useGetExtensionActivities();
  const {
    handleFilesUpload,
  } = useHandleFilesUpload();

  const {
    handleFileSelection,
  } = useHandleFileSelection();

  const {
    disabled,
    areaLabel,
    onConfirm,
    isDeleting,
  } = useDeleteFiles();
  return (
    <div className='blue-gradient extension-activities-file-upload'>
      <div className='menu-bar'>
        <Typography className='title' variant='h5'>Extension Activities</Typography>
        <FileUploader
          id='pdf-uploader-teacher-course-lesson-plans'
          buttonLabel='Upload'
          buttonClassName='btn-primary extensition-activities-action'
          isMultiple
          uploadServiceFunction={handleFilesUpload}
          fileAccept='.pdf'
        />
        <ViewFilesAction
          className='extensition-activities-action'
          areaLabel='View files'
          onClick={() => {
            navigate(EXTENSION_ACTIVITIES_ROUTES.VIEW_FILES);
          }}
        />
        <DeleteFileAction
          className='extensition-activities-action'
          disabled={disabled}
          areaLabel={areaLabel}
          onConfirm={onConfirm}
          isDeleting={isDeleting}
        />
        <RenameFileAction
          className='extensition-activities-action'
          onClick={() => {
            navigate(EXTENSION_ACTIVITIES_ROUTES.RENAME_FILE.replace(':fileId', selectedActivityIds[0]));
          }}
          disabled={!selectedActivityIds || selectedActivityIds.length !== 1}
        />
        <SelectAllAction className='select-all-action extensition-activities-action' />
      </div>

      <div className='body-container' data-test='body-container'>
        {loading && (
          <div className='loading-container'>
            <LoadingSpinner message='Loading Extension Activities' />
          </div>
        )}
        {!loading && activities && activities.length > 0
          ? (
            <Grid container spacing={2} mt={1} rowSpacing={5} data-test='file-list'>
              {sort(activities, 'name').map((activity) => (
                <Grid
                  item
                  xs={4}
                  xl={3}
                  key={`file-id-${activity.id}`}
                  data-test='file-container'
                >
                  <CardActionArea
                    data-test='file-action-area'
                    onClick={() => handleFileSelection(activity)}
                    className={selectedActivityIds.includes(activity.id) ? 'vizzle-selected-component-medium' : ''}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      padding={2}
                      boxSizing='border-box'
                    >
                      <PictureAsPdfIcon fontSize='large' />
                      <Typography data-test={`file-${activity.id}`} className='file-name'>{activity.name}</Typography>
                    </Box>
                  </CardActionArea>
                </Grid>
              ))}
            </Grid>
          ) : (!loading && <ExtensionActivitesUploadFileMessage />)}
      </div>
    </div>
  );
};

export default ExtensionActivitiesFileUpload;

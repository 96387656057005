import { useId } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
} from '@mui/material';
import { getPageStyle } from '../common/LessonPageUtil';
import './MultipleChoiceThumbnail.scss';
import ThumbnailCard from '../common/ThumbnailCard';
import { getMultipleChoicePrefix } from './MultipleChoiceUtils';

export default function MultipleChoiceThumbnail({ questionCard, answerCards, singlePage }) {
  const id = useId();

  const getCard = ({ card, thumbailClass, isImageIncluded, alwaysShowText }) => (
    <Card className={thumbailClass} style={getPageStyle(card, true)}>
      <ThumbnailCard
        key={`multiple-matching-card-key-${id}`}
        text={card.text}
        image={card.image}
        isImageIncluded={isImageIncluded}
        isSmallThumbnail
        alwaysShowText={alwaysShowText}
      />
    </Card>
  );

  return (
    <Box className={`multiple-choice-component ${singlePage ? 'single-page' : 'side-by-side'} multiple-choice-thumbnail`}>
      <Box className='multiple-choice-component-question'>
        <Box>
          {getCard({ card: questionCard, alwaysShowText: true })}
        </Box>
      </Box>
      <Box className='multiple-choice-component-responses'>
        {singlePage ? (
          <div className='multiple-choice-single-page-text'>
            {answerCards.length} answer{answerCards.length > 1 ? 's' : ''}
          </div>
        ) : answerCards.map((card, index) => {
          const key = `multiple-choice-answer-key-${index}-${card.answerKey}`;
          return (
            <Box key={key} position='relative' display='flex' alignItems='center'>
              <div className='prefix'>
                {getMultipleChoicePrefix(index)}
              </div>
              {getCard({
                card,
                thumbailClass: 'multiple-choice-component-response',
                isImageIncluded: false,
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

MultipleChoiceThumbnail.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}, {}, {}, {}],
  singlePage: true,
};

MultipleChoiceThumbnail.propTypes = {
  questionCard: PropTypes.shape({
    questionKey: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  singlePage: PropTypes.bool,
};

import PropTypes from 'prop-types';
import CardAction from '../../../../../../../../components/card/CardAction';
import { useTeacherCourses } from '../../../../../../context/TeacherCourseContextProvider';

export default function SelectAllAction({
  className,
}) {
  const { extensionActivitiesDomain } = useTeacherCourses();
  const { extensionActivities } = extensionActivitiesDomain.domainData;
  const { activities } = extensionActivities;

  return (
    <CardAction
      actionHandler={() => {
        const selectedIds = activities.map((a) => a.id);
        extensionActivitiesDomain.updateSelectedExtensionActivityIds(selectedIds);
      }}
      label='Select All'
      className={`btn-primary ${className}`}
      ariaLabel='Select all'
      title='Select all'
      data-test='select-all-action'
    />
  );
}
SelectAllAction.defaultProps = {
  className: '',
};

SelectAllAction.propTypes = {
  className: PropTypes.string,
};

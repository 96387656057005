import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@mui/material';
// import {
//   Assessment as AssessmentIcon,
//   Settings as SettingsIcon,
//   People as PeopleIcon,
//   VideogameAsset as VideogameAssetIcon,
//   Assignment as AssignmentIcon,
//   Edit as EditIcon,
//   LibraryBooks as LibraryBooksIcon,
//   Groups as GroupsIcon,
// } from '@mui/icons-material';
import { initDomains } from './context/MyClassRoomContextProvider';
import VizzleMainContainer from '../vizzle/VizzleMainContainer';
import TeacherTopAppbar from '../appbar/TeacherTopAppbar';
import ClassroomReport from './components/report/ClassroomReport';
import StudentsSettings from './components/students-settings/StudentsSettings';
import AssignmentOverview from './components/assignment-overview/AssignmentOverview';
import StudentManagement from './components/student-management/StudentManagement';
import GroupPlay from './components/group-play/GroupPlay';
import AuthoredLessons from './components/authored-lessons/AuthoredLessons';
import MyLessons from './components/my-lessons/MyLessons';
import { useGetStudents } from '../hooks/StudentHooks';
import './MyClassRoom.scss';
import '../../components/steppers/TabStyle.scss';
import { URL_MY_CLASSROOM } from '../../routes/RouteConstants';
import { MY_CLASSROOM_ROUTES } from './MyClassRoomConstant';
import StudentGroups from './components/student-groups/StudentGroups';

const MyClassRoom = () => {
  initDomains();
  useGetStudents();

  const myClassroomSteps = [
    {
      label: 'Student Management',
      link: MY_CLASSROOM_ROUTES.STUDENT_MANAGEMENT,
      // icon: <PeopleIcon />,
      element: <StudentManagement />,
    },
    {
      label: 'Student Groups',
      link: MY_CLASSROOM_ROUTES.STUDENT_GROUPS,
      // icon: <GroupsIcon />,
      element: <StudentGroups />,
    },
    {
      label: 'Group Instruction',
      link: MY_CLASSROOM_ROUTES.GROUP_INSTRUCTIONS,
      // icon: <VideogameAssetIcon />,
      element: <GroupPlay />,
    },
    {
      label: 'Class Report',
      link: MY_CLASSROOM_ROUTES.CLASS_REPORT,
      // icon: <AssessmentIcon />,
      element: <ClassroomReport />,
    },
    {
      label: 'Student Settings',
      link: MY_CLASSROOM_ROUTES.STUDENT_SETTINGS,
      // icon: <SettingsIcon />,
      element: <StudentsSettings />,
    },
    {
      label: 'Assignment Overview',
      link: MY_CLASSROOM_ROUTES.ASSIGNMENT_OVERVIEW,
      // icon: <AssignmentIcon />,
      element: <AssignmentOverview />,
    },
    {
      label: 'Authoring',
      link: MY_CLASSROOM_ROUTES.AUTHORED_LESSONS,
      // icon: <EditIcon />,
      element: <AuthoredLessons />,
    },
    {
      label: 'My Resources',
      link: MY_CLASSROOM_ROUTES.MY_LESSONS,
      // icon: <LibraryBooksIcon />,
      element: <MyLessons />,
    },
  ];

  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname && location.pathname.split('/');
    if (path && path.length >= 3) {
      const subPath = path[2];
      const index = myClassroomSteps.findIndex((am) => am.link === subPath);
      setTabValue(index >= 0 ? index : 0);
    } else {
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleTabChange = (_event, newValue) => {
    navigate(`${URL_MY_CLASSROOM}/${myClassroomSteps[newValue].link}`);
  };

  return (
    <VizzleMainContainer className='main-with-background-container flex-box'>
      <div className='my-classroom'>
        <TeacherTopAppbar />
        <AppBar position='static' color='default' className='vizzle-tab-menu-style'>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='fullWidth'
            scrollButtons='auto'
            indicatorColor='primary'
            textColor='primary'
            aria-label='My Classroom Tabs'
          >
            {myClassroomSteps.map((menu, index) => (
              <Tab
                key={`my-classroom-menu-${index}`}
                label={menu.label}
                icon={menu.icon}
                data-test={`classroom-tab-${menu.link}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <div className='content'>
          <Routes>
            {myClassroomSteps.map((menu, index) => (
              <Route
                key={`my-classroom-route-${index}`}
                path={`${menu.link}`}
                element={menu.element}
              />
            ))}
            <Route
              path='*'
              element={<Navigate to={`${URL_MY_CLASSROOM}/${myClassroomSteps[0].link}`} />}
            />
          </Routes>
        </div>
      </div>
    </VizzleMainContainer>
  );
};

export default MyClassRoom;

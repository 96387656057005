import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './MatchingAnswerKey.scss';

/**
 * Sub-component for displaying Answer Key in the Matching Editor
 */
export default function MatchingAnswerKey({
  value,
  onKeyChange,
  isCorrect,
  disabled,
  ...props
}) {
  const [textValue, setTextValue] = useState(value || '');

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const handleChange = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    setTextValue(newValue);
  };

  const handleBlur = () => {
    onKeyChange(textValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onKeyChange(textValue);
    }
  };

  return (
    <div {...props} className='matching-editor-answer-key'>
      <div className='answer-key-editor'>
        {isCorrect ? <CheckCircleIcon className='answer-key-card-status-correct' /> : ''}

        <TextField
          placeholder='Answer Key'
          className='answer-key-text-field'
          value={textValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          margin='dense'
          variant='outlined'
          disabled={disabled}
          data-test='key-textfield'
        />
      </div>
    </div>
  );
}

MatchingAnswerKey.defaultProps = {
  value: '',
  onKeyChange: () => { },
  isCorrect: false,
  disabled: false,
};

MatchingAnswerKey.propTypes = {
  value: PropTypes.string,
  onKeyChange: PropTypes.func,
  isCorrect: PropTypes.bool,
  disabled: PropTypes.bool,
};

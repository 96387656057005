import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import StudentGroupForm, { studentGroupFormValidator } from '../forms/StudentGroupForm';
import { useHandleStudentGroupActionForm, useHandleUpdateStudentGroup } from './StudentGroupActionHooks';
import './StudentGroupAction.scss';
import CardAction from '../../../../../components/card/CardAction';

const UpdateStudentGroupAction = ({
  group,
  onStudentGroupUpdated,
}) => {
  const [open, setOpen] = useState(false);
  const {
    studentGroupData,
    handleStudentGroupFormChange,
    setStudentGroupData,
    resetForm,
  } = useHandleStudentGroupActionForm();

  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    saving,
    updateExistingStudentGroup,
  } = useHandleUpdateStudentGroup({
    onSaveSuccess: () => {
      handleOnCancel();
      onStudentGroupUpdated();
    },
  });
  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right action-button',
      title: 'Cancel',
      loading: saving,
    }, {
      onClick: () => {
        updateExistingStudentGroup(group.id, {
          name: studentGroupData.name,
          students: studentGroupData.students,
        });
      },
      className: 'btn-primary action-button',
      title: 'Save',
      disabled: !studentGroupFormValidator(studentGroupData),
      loading: saving,
    },
    ];
    return buttons;
  };

  useEffect(() => {
    if (open) {
      setStudentGroupData({
        ...group,
        students: group.students.map((s) => s.toString()),
      });
    }
    return (() => {
      resetForm();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <CardAction
        icon={<DriveFileRenameOutlineIcon fontSize='small' className='update-icon' />}
        actionHandler={() => { setOpen(true); }}
        className='update-student-group-action'
        title='Update Student Group'
        ariaLabel='Update Student Group'
        data-test={`update-student-group-action-${group.id}`}
      />
      {open && (
        <CustomDialog
          className='update-student-group-dialog'
          openDialog={open}
          onClose={handleOnCancel}
          title='Update Student Group'
          content={(
            <StudentGroupForm
              studentGroupName={studentGroupData.name}
              selectedStudentIds={studentGroupData.students}
              onDataChange={handleStudentGroupFormChange}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

UpdateStudentGroupAction.defaultProps = {
  onStudentGroupUpdated: () => null,
};

UpdateStudentGroupAction.propTypes = {
  group: PropTypes.object.isRequired,
  onStudentGroupUpdated: PropTypes.func,
};

export default UpdateStudentGroupAction;

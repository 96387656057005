import { Navigate, Route, Routes } from 'react-router-dom';
import { EXTENSION_ACTIVITIES_ROUTES } from '../ExtensionActivitiesConstants';
import ExtensionActiviteisViewFiles from './upload/ExtensionActiviteisViewFiles';
import ExtensionActivitiesFileUpload from './upload/ExtensionActivitiesFileUpload';
import ExtensionActivitiesRenameFile from './upload/ExtensionActivitiesRenameFile';

const ExtensionActivitiesFileUploadMain = () => (
  <Routes>
    <Route
      path={EXTENSION_ACTIVITIES_ROUTES.FILE_UPLOAD}
      element={<ExtensionActivitiesFileUpload />}
    />
    <Route
      path={EXTENSION_ACTIVITIES_ROUTES.RENAME_FILE}
      element={<ExtensionActivitiesRenameFile />}
    />
    <Route
      path={EXTENSION_ACTIVITIES_ROUTES.VIEW_FILES}
      element={<ExtensionActiviteisViewFiles />}
    />
    <Route
      path='*'
      element={<Navigate to='' />}
    />
  </Routes>
);
export default ExtensionActivitiesFileUploadMain;

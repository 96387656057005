/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import RemoveFromGroupPlay from './RemoveFromGroupPlay';
import AddToGroupPlay from './AddToGroupPlay';

/**
 * Share Link Lesson Action Container
 */
export default function AddRemoveFromGroupPlay({
  lesson,
  className,
  onSaveSuccess,
}) {
  const { meta: { groupPlay } } = lesson;

  if (groupPlay) {
    return (
      <RemoveFromGroupPlay
        lesson={lesson}
        className={className}
        onSaveSuccess={onSaveSuccess}
      />
    );
  }

  return (
    <AddToGroupPlay
      lesson={lesson}
      className={className}
      onSaveSuccess={onSaveSuccess}
    />
  );
}

AddRemoveFromGroupPlay.defaultProps = {
  onSaveSuccess: () => { },
  className: '',
};

AddRemoveFromGroupPlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
  onSaveSuccess: PropTypes.func,
};

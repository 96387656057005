export const CARD_MODEL = 'CardModel';
export const MATCHING_QUIZ = 'MatchingQuiz';
export const FILL_IN_THE_BLANK = 'FillInTheBlank';

export const TILE_TYPE_STANDARD = 1;
export const TILE_TYPE_SPIN_AGAIN = 2;
export const TILE_TYPE_LOSE_A_TURN = 3;
export const TILE_TYPE_FORWARD = 4;
export const TILE_TYPE_BACKWARD_2_SPACES = 5;

export const SPINNER_BOARD_NUMBER = 1;
export const SPINNER_BOARD_COLOR_FRIENDLY = 2;
export const SPINNER_BOARD_COLOR_PRIMARY = 3;
export const SPINNER_BOARD_COLOR_PASTEL = 4;
export const SPINNER_BOARD_COLOR_OLDER = 5;

const SPINNER_BOARD_COLOR_FRIENDLY_COLORS = [
  '#FCBA63',
  '#92C854',
  '#0094CC',
  '#DE7787',
];

export const SPINNER_BOARD_PRIMARY_COLORS = [
  '#F7DF00',
  '#3A7C00',
  '#004D93',
  '#AA0000',
];

export const SPINNER_BOARD_COLOR_PASTEL_COLORS = [
  '#FFF066',
  '#E48898',
  '#B06FA7',
  '#FCBA63',
];

export const SPINNER_BOARD_COLOR_OLDER_COLORS = [
  '#333333',
  '#3A7C00',
  '#870202',
  '#004D93',
];

export const SPINNER_COLOR_MAP = {
  [SPINNER_BOARD_COLOR_FRIENDLY]: SPINNER_BOARD_COLOR_FRIENDLY_COLORS,
  [SPINNER_BOARD_COLOR_PRIMARY]: SPINNER_BOARD_PRIMARY_COLORS,
  [SPINNER_BOARD_COLOR_PASTEL]: SPINNER_BOARD_COLOR_PASTEL_COLORS,
  [SPINNER_BOARD_COLOR_OLDER]: SPINNER_BOARD_COLOR_OLDER_COLORS,
};

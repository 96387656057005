import { useState, useEffect } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useMyClassroom } from '../../context/MyClassRoomContextProvider';
import { getClassReport } from '../../../../services/ReportService';
import { UNAUTHORIZED } from '../../../../AppConstants';
import Logger from '../../../../utils/Logger';
import DateUtils from '../../../../utils/DateUtils';

export const ALL_SELECTOR = {
  id: 0,
  value: 0,
  name: 'All',
};

const endDate = new Date();
endDate.setHours(0);
endDate.setMinutes(0);
endDate.setSeconds(0);
endDate.setMilliseconds(0);

const startDate = new Date();
startDate.setDate(endDate.getDate() - 7);
startDate.setHours(0);
startDate.setMinutes(0);
startDate.setSeconds(0);
startDate.setMilliseconds(0);

export const useClassRoomReport = () => {
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;

  const { myClassroomDomain } = useMyClassroom();
  const { classRoomReport } = myClassroomDomain.domainData;
  const { tableData } = classRoomReport;
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(tableData.length > 0);

  const [selectedCriteria, setSelectedCriteria] = useState({
    startDate,
    endDate,
  });

  const [validDates, setValidDates] = useState(true);

  const loadClassReport = async (selectedParamCriteria) => {
    setLoading(true);
    setHasLoaded(true);

    const searchCriteria = {
      teacherId: user.id,
      startDate: DateUtils.convertDateToYYYYMMdd(selectedParamCriteria.startDate),
      endDate: DateUtils.convertDateToYYYYMMdd(selectedParamCriteria.endDate),
    };

    // did not select ALL
    if (!selectedParamCriteria.subjects.includes(ALL_SELECTOR.value)) {
      searchCriteria.subjects = selectedParamCriteria.subjects.join(',');
    }
    try {
      const result = await getClassReport(searchCriteria);
      const data = result.map((r) => {
        const { id, attributes } = r;
        return {
          id,
          ...attributes,
        };
      });
      myClassroomDomain.updateClassRoomReport({
        selectedCriteria: {
          ...selectedCriteria,
          startDate: selectedParamCriteria.startDate,
          endDate: selectedParamCriteria.endDate,
        },
        tableData: data,
      });
    } catch (e) {
      if (e.response && UNAUTHORIZED !== e.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, e.response.data);
      }
      Logger.logError({
        ERROR: e,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const sDate = classRoomReport.selectedCriteria.startDate;
    const eDate = classRoomReport.selectedCriteria.endDate;
    const searchSubjects = classRoomReport.selectedCriteria.subjects || [ALL_SELECTOR.value];
    /**
     * The data from the session storage is converted to string.
     * When we want to use it again, we need to convert it to date object
     */
    if (sDate && typeof sDate === 'string') {
      setSelectedCriteria({
        startDate: new Date(sDate) || startDate,
        endDate: new Date(eDate) || endDate,
        subjects: searchSubjects,
      });
    } else {
      const startDateSearch = sDate || startDate;
      const endDateSearch = eDate || endDate;
      setSelectedCriteria({
        startDate: startDateSearch,
        endDate: endDateSearch,
        subjects: searchSubjects,
      });
      loadClassReport({ startDate: startDateSearch, endDate: endDateSearch, subjects: searchSubjects });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDateChange = (date, field, valid) => {
    setSelectedCriteria({
      ...selectedCriteria,
      [field]: date,
    });
    setValidDates(valid);
  };

  const handleOnSelectorChange = (value, field, allValue) => {
    const oldValue = selectedCriteria[field];
    const difference = value.filter((x) => !oldValue.includes(x));

    if (difference.includes(allValue) || value.length === 0) {
      setSelectedCriteria({
        ...selectedCriteria,
        [field]: [allValue],
      });
    } else {
      setSelectedCriteria({
        ...selectedCriteria,
        [field]: value.filter((v) => v !== allValue),
      });
    }
  };

  const handleOnSubmitButtonClick = () => {
    loadClassReport(selectedCriteria);
  };

  return {
    loading,
    hasLoaded,
    tableData,
    selectedCriteria,
    validDates,
    handleOnDateChange,
    handleOnSelectorChange,
    handleOnSubmitButtonClick,
  };
};

export const useTableStateManagement = () => {
  const { myClassroomDomain } = useMyClassroom();
  const { classRoomReport } = myClassroomDomain.domainData;
  const { tableState } = classRoomReport;

  const handleOnTableStateChange = (newTableState) => {
    myClassroomDomain.updateClassRoomTableState(newTableState);
  };

  return {
    tableState,
    handleOnTableStateChange,
  };
};

export const createRenderer = (selected, list) => {
  const selectedValues = selected.map((s) => (
    list.find((subject) => subject.value === s)
  ));
  return selectedValues.map((s) => s && s.name).join(', ');
};

// export const subjects = [
//   { ...ALL_SELECTOR },
//   { id: SUBJECT_CONSTANTS.math.subjectId, value: SUBJECT_CONSTANTS.math.subjectId, name: SUBJECT_CONSTANTS.math.name },
//   { id: SUBJECT_CONSTANTS.social.subjectId, value: SUBJECT_CONSTANTS.social.subjectId, name: SUBJECT_CONSTANTS.social.name },
//   { id: SUBJECT_CONSTANTS.science.subjectId, value: SUBJECT_CONSTANTS.science.subjectId, name: SUBJECT_CONSTANTS.science.name },
//   { id: SUBJECT_CONSTANTS.speech.subjectId, value: SUBJECT_CONSTANTS.speech.subjectId, name: SUBJECT_CONSTANTS.speech.name },
//   { id: SUBJECT_CONSTANTS.english.subjectId, value: SUBJECT_CONSTANTS.english.subjectId, name: SUBJECT_CONSTANTS.english.name },
//   { id: SUBJECT_CONSTANTS.arts.subjectId, value: SUBJECT_CONSTANTS.arts.subjectId, name: SUBJECT_CONSTANTS.arts.name },
//   { id: SUBJECT_CONSTANTS.life.subjectId, value: SUBJECT_CONSTANTS.life.subjectId, name: SUBJECT_CONSTANTS.life.name },
//   { id: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.transitionalSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.transitionalSkills.name },
//   { id: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, value: NON_ACADEMIC_SUBJECTS.socialSkills.subjectId, name: NON_ACADEMIC_SUBJECTS.socialSkills.name },
// ];

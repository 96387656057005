import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CardAction from '../../../components/card/CardAction';
import CustomDialog from '../../../components/dialog/CustomDialog';
import ObjectUtils from '../../../utils/ObjectUtils';
import Logger from '../../../utils/Logger';
import { copyLesson } from '../../../services/LessonService';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { createLessonObject } from '../../../utils/LessonsMapping';
import './CopyToEditAction.scss';

const getLessonIsCopying = () => (
  {
    title: 'Copying the lesson',
    content: (
      <div className='content-message'>
        <FileCopyIcon className='icon' />
        Please wait while the Activity is being copied.
      </div>
    ),
  }
);

const getLessonIsCopyied = () => (
  {
    title: 'Copying the lesson',
    content: (
      <div className='content-message'>
        <FileCopyIcon className='icon' />
        Copy Complete!
        <br />
        The copied activity is available under &apos;My Resources&apos; for editing
      </div>
    ),
  }
);

const COPY_STATE_INIT = 0;
const COPY_STATE_COPYING = 1;
const COPY_STATE_FINISHED = 2;

/**
 * Copy to edit Lesson Action
 */
export default function CopyToEditAction({ currentUser, lesson, onCopySuccess, fromStudentId }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [copyState, setCopyState] = useState(COPY_STATE_INIT);
  const [lessonName, setLessonName] = useState(lesson.attributes.name);

  useEffect(() => {
    setLessonName(lesson.attributes.name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const handleOnOk = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        setCopyState(COPY_STATE_COPYING);
        const coppiedLesson = await copyLesson(lessonName, lesson.attributes.uuid, fromStudentId);
        // To let the user see the message the lesson is being copied a bit longer
        ObjectUtils.setTimeout(async () => {
          await setCopyState(COPY_STATE_FINISHED);
          onCopySuccess(createLessonObject(coppiedLesson.data.data, currentUser));
        }, 1000);
      });
    } catch (error) {
      setCopyState(COPY_STATE_INIT);
      Logger.logError({
        ERROR: error,
      });
    }
  };

  const handleOnClose = () => {
    setCopyState(COPY_STATE_INIT);
    setOpenDialog(false);
  };

  let content = {};
  const actions = [
    {
      title: 'Close',
      onClick: handleOnClose,
      color: 'primary',
      className: 'btn-gray-background',
      disabled: copyState === COPY_STATE_COPYING,
    },
  ];

  if (copyState === COPY_STATE_INIT) {
    content = {
      title: 'Enter a new title for your lesson',
      content: (
        <div className='dialog-content'>
          <TextField
            onChange={(e) => { setLessonName(e.target.value); }}
            label='Activity Title'
            fullWidth
            value={lessonName}
            inputProps={{
              maxLength: 255,
            }}
          />
        </div>
      ),
    };
    actions.unshift(
      {
        title: 'Ok',
        onClick: handleOnOk,
        color: 'primary',
        className: 'btn-primary',
        disabled: !lessonName || lessonName.length === 0,
      },
    );
  } else if (copyState === COPY_STATE_COPYING) {
    content = getLessonIsCopying();
    actions.unshift(
      {
        title: 'Ok',
        color: 'primary',
        className: 'btn-primary',
        loading: true,
        disabled: true,
      },
    );
  } else if (copyState === COPY_STATE_FINISHED) {
    content = getLessonIsCopyied();
  }

  return (
    <>
      <CardAction
        icon={<FileCopyIcon />}
        label='COPY TO EDIT'
        actionHandler={() => { setOpenDialog(true); }}
        className='copy-to-edit-action btn-primary'
        data-test='copy-to-edit-action'
      />
      {openDialog ? (
        <CustomDialog
          className='copy-to-edit-dialog'
          openDialog={openDialog}
          onClose={handleOnClose}
          title={content.title}
          content={content.content}
          displayCloseIcon={copyState !== COPY_STATE_COPYING}
          actions={actions}
        />
      ) : null}

    </>
  );
}

CopyToEditAction.defaultProps = {
  fromStudentId: null,
  onCopySuccess: () => { },
};

CopyToEditAction.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  fromStudentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onCopySuccess: PropTypes.func,
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const COLORS_NAME = {
  '#000407': 'blue',
  '#0D2B20': 'green',
  '#280D0D': 'red',
  '#0F0601': 'orange',
  '#140A21': 'purple',
  '#FCF6CD': 'day',
};

const BACKGROUND_GRADIENT_INTENSITY = 20;

const QUATERBACK_DELAY = 1000;
const SCALE_Y = 0.91;

const FootballTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);
  const app = {
    canvas: null,
    flags: [],
    spritesPlay: [],
    tweens: [],
    timeouts: [],
    footballer: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  const addFootballField = () => {
    if (app.colorName === 'day') {
      app.football = new PIXI.AnimatedSprite(app.sheet.animations.fullStadiumLightOut);
    } else {
      app.football = new PIXI.AnimatedSprite(app.sheet.animations.fullfootballstadium);
    }

    app.football.scale.x = 1;
    app.football.scale.y = SCALE_Y;
    app.footballBounds = app.football.getBounds();
    app.football.x = (app.floatingIslandBounds.width / 2) - (app.footballBounds.width / 2) + 140;
    app.football.y = 20;
    app.floatingIslandContainer.addChild(app.football);
  };

  const addFans = () => {
    app.fans = new PIXI.AnimatedSprite(app.sheet.animations.fans);
    app.fanBounds = app.fans.getBounds();
    app.fans.x = app.football.x + app.fanBounds.width / 2 - 10;
    if (app.colorName === 'day') {
      app.fans.x = app.football.x + app.fanBounds.width / 2 - 40;
    }
    app.fans.y = app.football.y + app.fanBounds.height - 20;
    app.fans.scale.y = SCALE_Y;
    app.fans.animationSpeed = 0.3;
    app.fans.play();
    app.spritesPlay.push(app.fans);
    app.floatingIslandContainer.addChild(app.fans);
  };

  const qtrBackAnimation = () => {
    app.qtrBack.play();

    const qtrBackX = app.qtrBack.x;
    const qtrBackY = app.qtrBack.y;

    const ballX = app.ball.x;
    const ballY = app.ball.y;

    const receiveX = app.receiver.x;
    const receiveY = app.receiver.y;

    const qutrBackTween5 = new TWEEN.Tween(app.qtrBack)
      .to({ x: qtrBackX, y: qtrBackY }, 200)
      .onComplete(() => {
        app.qtrBack.gotoAndPlay(0);
        app.qutrBackTween.start();
        app.ballTween.start();
        app.receiverTween.start();
      });

    const qutrBackTween4 = new TWEEN.Tween(app.qtrBack)
      .to({ x: qtrBackX + 5, y: qtrBackY - 5 }, 200)
      .chain(qutrBackTween5);

    const qutrBackTween3 = new TWEEN.Tween(app.qtrBack)
      .to({ x: qtrBackX + 10, y: qtrBackY }, 200)
      .chain(qutrBackTween4);

    const qutrBackTween2 = new TWEEN.Tween(app.qtrBack)
      .to({ x: qtrBackX + 15, y: qtrBackY - 5 }, 200)
      .chain(qutrBackTween3);

    app.qutrBackTween = new TWEEN.Tween(app.qtrBack)
      .to({ x: qtrBackX + 20 }, 200)
      .easing(TWEEN.Easing.Linear.None)
      .delay(QUATERBACK_DELAY)
      .onStart(() => {
        app.qtrBack.gotoAndStop(0);
      })
      .chain(qutrBackTween2);
    app.tweens.push(app.qutrBackTween);

    const ballTween3 = new TWEEN.Tween(app.ball)
      .to({ x: ballX + 315, y: ballY }, 400);

    const ballTween2 = new TWEEN.Tween(app.ball)
      .to({ x: ballX + 215 }, 400)
      .chain(ballTween3);

    app.ballTween = new TWEEN.Tween(app.ball)
      .to({ x: ballX + 115, y: ballY - 30 }, 400)
      .easing(TWEEN.Easing.Linear.None)
      .delay(QUATERBACK_DELAY / 3)
      .chain(ballTween2);
    app.tweens.push(app.ballTween);

    const receiverTween5 = new TWEEN.Tween(app.receiver)
      .to({ x: receiveX + 170, y: receiveY - 5 }, 200)
      .onComplete(() => {
        app.receiver.scale.x = -1;
      });

    const receiverTween4 = new TWEEN.Tween(app.receiver)
      .to({ x: receiveX + 120, y: receiveY }, 200)
      .chain(receiverTween5);

    const receiverTween3 = new TWEEN.Tween(app.receiver)
      .to({ x: receiveX + 80, y: receiveY - 5 }, 200)
      .chain(receiverTween4);

    const receiverTween2 = new TWEEN.Tween(app.receiver)
      .to({ x: receiveX + 50, y: receiveY }, 200)
      .chain(receiverTween3);

    app.receiverTween = new TWEEN.Tween(app.receiver)
      .to({ x: receiveX + 30, y: receiveY - 5 }, 200)
      .onStart(() => {
        app.receiver.scale.x = 1;
      })
      .easing(TWEEN.Easing.Linear.None)
      .delay(QUATERBACK_DELAY / 3)
      .chain(receiverTween2);
    app.tweens.push(app.receiverTween);

    app.qutrBackTween.start();
    app.ballTween.start();
    app.receiverTween.start();
  };

  const animation = () => {
    app.footballer.forEach((f, i) => {
      const fX = f.x;

      const offset = i % 2 === 0 ? 5 : -5;

      const footballerTween = new TWEEN.Tween(f)
        .to({ x: fX + offset }, 300)
        .easing(TWEEN.Easing.Linear.None)
        .chain(new TWEEN.Tween(f)
          .to({ x: fX }, 300)
          .onComplete(() => {
            footballerTween.start();
          }));
      footballerTween.start();
      app.tweens.push(footballerTween);
      return footballerTween;
    });
  };

  const weatherBegin = () => {
    const snowflake = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    const container = new PIXI.Container();
    container.addChild(snowflake);
    container.x = Math.random() * app.canvas.view.width;

    const duration = (Math.random() * 1000) + 1500;

    const snowFlakeTween = new TWEEN.Tween(snowflake)
      .delay(15)
      .to({ x: 15, alpha: 0.8 }, duration)
      .easing(TWEEN.Easing.Linear.None)
      .chain(new TWEEN.Tween(snowflake)
        .delay(15)
        .to({ x: 0, alpha: 1 }, duration)
        .onComplete(() => {
          snowFlakeTween.start();
        }));
    snowFlakeTween.start();
    app.tweens.push(snowFlakeTween);

    const snowFlakeYTween = new TWEEN.Tween(snowflake)
      .to({ y: app.canvas.view.height + 500 }, 10000)
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        app.floatingIslandContainer.removeChild(container);
      });

    snowFlakeYTween.start();
    app.tweens.push(snowFlakeYTween);
    app.floatingIslandContainer.addChild(container);
  };

  const addFootballers = () => {
    for (let i = 0; i < 5; i++) {
      app.footballer[i] = new PIXI.AnimatedSprite(app.sheet.animations.footballers);
      app.footballerBounds = app.footballer[i].getBounds();
      app.footballer[i].x = app.football.x + (i * 50) + 190;
      app.footballer[i].y = app.football.y + app.footballBounds.height + (i * 20) - 120;

      app.floatingIslandContainer.addChild(app.footballer[i]);
    }

    app.footballer[0].x = 495;
    app.footballer[1].x = 395;
    app.footballer[2].x = 467;
    app.footballer[3].x = 475;
    app.footballer[4].x = 575;

    const footballerRef = app.footballer[3];

    app.receiver = new PIXI.AnimatedSprite(app.sheet.animations.footballer2);
    app.receiver.x = footballerRef.x + 60;
    app.receiver.y = footballerRef.y - 5;
    app.floatingIslandContainer.addChild(app.receiver);

    app.ball = new PIXI.AnimatedSprite(app.sheet.animations.miniFootball);
    app.ball.x = app.receiver.x - 170;
    app.ball.y = footballerRef.y;
    app.floatingIslandContainer.addChild(app.ball);

    app.qtrBack = new PIXI.AnimatedSprite(app.sheet.animations.quarterback);
    app.qtrBounds = app.qtrBack.getBounds();
    app.qtrBack.x = app.ball.x - 15;
    app.qtrBack.y = app.ball.y;
    app.qtrBack.animationSpeed = 0.3;
    app.spritesPlay.push(app.qtrBack);
    app.floatingIslandContainer.addChild(app.qtrBack);

    qtrBackAnimation();

    animation();
  };

  const showRandomScene = () => {
    addFootballField();
    addFans();
    addFootballers();

    app.interval = setInterval(weatherBegin, 30);
  };

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas, selectedColor } = ThemeUtil.initCanvasWithBackground(
        canvas,
        Object.keys(COLORS_NAME),
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      app.colorName = COLORS_NAME[selectedColor];

      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick);
      const progressBar = {
        barBackground: 'progressbar_background_field',
        progressBar: 'progressbar_football',
        progressAvatar: 'helmet',
        dotType: 'circle',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );

      ThemeUtil.addFloatableObjects(app, 4000, true);

      const padding = 25;

      app.floatingIsland.y += (padding);
      app.floatingIslandHighlight.scale.x = 1.008;
      app.floatingIslandHighlight.y = app.floatingIsland.y + 10;
      showRandomScene();
    }

    return () => {
      clearInterval(app.interval);
      ThemeUtil.stopFloatableObjects(app);
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='activity-canvas'>
      reward goes here
    </canvas>
  );
};

FootballTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

FootballTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default FootballTheme;

import { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import UpdateButton from '../../../components/buttons/UpdateButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useAdmin } from '../../../context/AdminContextProvider';
import UpdateStudentPanel from './forms/update-student/UpdateStudentPanel';
import './UpdateStudentAction.scss';
import { useGetDistrictAndSchool, useGetUserDataById } from '../../../hooks/AdminUtilityHooks';
import { transformStudentDataToStudentFormObject, useUpdateStudent } from './StudentActionHooks';
import { validateStudentData, validateUserNameLength } from './forms/StudentFormHook';

const UpdateStudentAction = forwardRef(({ onStudentUpdateSuccess }, ref) => {
  const [open, setOpen] = useState(false);
  const [isDataValid, setIsDataValid] = useState(false);

  const { adminStudentsDomain } = useAdmin();
  const { selectedStudentIds } = adminStudentsDomain.domainData;

  // const studentFormDataRef = useRef(null);

  const [studentFormDataState, setStudentFormDataState] = useState(null);

  useImperativeHandle(ref, () => ({
    openUpdateStudent: () => {
      setOpen(true);
    },
  }));

  const {
    getUserData,
    loading: studentDataLoading,
    userData: studentDataObject,
  } = useGetUserDataById();

  const {
    selectedDistrict,
    selectedSchool,
  } = useGetDistrictAndSchool();

  useEffect(() => {
    if (studentDataObject.data && studentDataObject.data.attributes) {
      const studentFormData = transformStudentDataToStudentFormObject(studentDataObject);
      const newState = studentFormData;
      newState.district = selectedDistrict;
      newState.school = selectedSchool;
      setStudentFormDataState(newState);
      const isValid = validateStudentData(studentDataObject) && validateUserNameLength(studentFormData.userName);
      setIsDataValid(isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentDataObject]);

  useEffect(() => {
    if (open) {
      getUserData(selectedStudentIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOnCancel = () => {
    setOpen(false);
    setStudentFormDataState(null);
  };

  const {
    updateExistingStudent,
    loading: saving,
  } = useUpdateStudent({
    closeDialog: handleOnCancel,
    onStudentUpdateSuccess,
  });

  const confirmActions = () => {
    const buttons = [
      {
        onClick: handleOnCancel,
        className: 'btn-danger margin-right',
        title: 'Cancel',
        loading: saving,
      },
      {
        onClick: () => {
          updateExistingStudent(studentDataObject, studentFormDataState);
        },
        className: 'btn-primary action-button',
        title: 'Save',
        disabled: !isDataValid,
        loading: saving,
      },
    ];
    return buttons;
  };

  const handleOnStudentDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    // studentFormDataRef.current = updatedData;
    setStudentFormDataState(updatedData);
  };

  return (
    <>
      <UpdateButton
        ariaLabel='Update Student'
        onClick={() => {
          setOpen(true);
        }}
        disabled={selectedStudentIds && selectedStudentIds.length === 0}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='admin-student-update-student-dialog'
          onClose={handleOnCancel}
          title='Update Student'
          content={(
            <UpdateStudentPanel
              studentFormData={studentFormDataState}
              loading={studentDataLoading}
              onStudentFormDataChange={handleOnStudentDataChange}
              studentDataObject={studentDataObject}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
});

UpdateStudentAction.defaultProps = {
  onStudentUpdateSuccess: () => null,
};

UpdateStudentAction.propTypes = {
  onStudentUpdateSuccess: PropTypes.func,
};

export default UpdateStudentAction;

import PropTypes from 'prop-types';
import CardActivity from '../../common/card-activity/CardActivity';
import './CategorizingSelectedResponseCards.scss';
import { DELEMETER } from '../../../../../components/vizzle/text/TextLinesUtils';
import SubmittedAnswerIndicator from '../../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

export default function CategorizingSelectedResponseCards({
  id,
  totalPrompts,
  promptKey,
  responseCards,
  selectedResponseCards,
  fontScale,
}) {
  const matchedResponseCards = [];
  selectedResponseCards.forEach((cardIndex) => {
    if (responseCards[cardIndex].key === promptKey) {
      matchedResponseCards.push(responseCards[cardIndex]);
    }
  });

  const allResponsesText = matchedResponseCards.findIndex((c) => {
    const hasImage = Boolean(c.image);
    let hasMathEquation = false;
    if (c.text && c.text.lines) {
      hasMathEquation = c.text.lines.reduce((tlTotal, v) => tlTotal || v.elements.reduce((total, e) => total || e.text.indexOf(DELEMETER) >= 0, false),
        false);
    }

    return hasImage || !hasMathEquation;
  }) < 0;

  return (
    <div
      data-test='categorizing-selected-activity-response-cards'
      className={`categorizing-selected-activity-response-cards-component
      ${totalPrompts >= 6 ? 'no-wrap' : ''} ${allResponsesText ? 'container-flatten-cards' : ''}`}
    >
      {matchedResponseCards.map((card, cardIndex) => (
        <div
          key={`selected-response-display-${id}-${cardIndex}`}
          className={`selected-response-display 
          row-height-by-prompt row-height-by-answers-length-${matchedResponseCards.length} 
          ${allResponsesText ? 'flatten-cards' : ''} 
          ${totalPrompts >= 6 ? 'small' : ''}`}
        >
          <CardActivity {...card} disabled fontScale={fontScale} />
          {/** always display the indicator for correct answer */}
          <SubmittedAnswerIndicator correctSubmission={card.correctSubmission || ((card.correctSubmission === undefined || card.correctSubmission === null) && card.key === promptKey)} />
        </div>
      ))}
    </div>
  );
}

CategorizingSelectedResponseCards.defaultProps = {
  id: 0,
  totalPrompts: 1,
  promptKey: null,
  responseCards: [],
  selectedResponseCards: [],
  fontScale: null,
};

CategorizingSelectedResponseCards.propTypes = {
  id: PropTypes.string,
  totalPrompts: PropTypes.number,
  promptKey: PropTypes.string,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  selectedResponseCards: PropTypes.arrayOf(PropTypes.number),
  fontScale: PropTypes.number,
};

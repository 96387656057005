import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import MediaPlayer from '../../../media/MediaPlayer';
import TextLines from '../../../text/TextLines';
import './SequencingPrompt.scss';

export default function SequencingPrompt({
  sequenceCard,
  sequenceCardId,
}) {
  return (
    <Card
      className='sequencing-prompt'
      id={sequenceCardId}
      key={sequenceCardId}
    >
      <Card className='prompt-content'>
        {
          sequenceCard.image ? (
            <CardMedia
              className='sequencing-component-image'
              image={sequenceCard.image}
              title='Sequencing'
            />
          ) : null
        }
        {sequenceCard.text
          ? (
            <CardContent className={`sequencing-component-text ${sequenceCard.image ? 'text-with-image' : ''}`}>
              <TextLines textLines={sequenceCard.text} />
            </CardContent>
          ) : null}
      </Card>
      <MediaPlayer
        audioUrl={sequenceCard.audio}
        videoUrl={sequenceCard.video}
        parentElementId={sequenceCardId}
        className='sequence-media'
      />
    </Card>
  );
}

SequencingPrompt.defaultProps = {
  sequenceCard: {},
};

SequencingPrompt.propTypes = {
  sequenceCardId: PropTypes.string.isRequired,
  sequenceCard: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
};

import PropTypes from 'prop-types';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import { flatten, unique } from '../../../../utils/ArrayUtils';
import './UnarchiveSelectedLessons.scss';

const UnarchiveSelectedLessons = ({
  lessons,
  archiveLessonFunction,
  isArchiving,
  selectedLessonIds,
}) => {
  const flattenLessons = flatten(lessons.map((subjectWithLessons) => subjectWithLessons.lessons));
  const archivedLessons = flattenLessons.filter((lesson) => lesson.meta.archived);

  const handleOnClick = async () => {
    const archivedSelectedLessons = unique(archivedLessons.filter((lesson) => selectedLessonIds.includes(lesson.id)), 'id');
    await archiveLessonFunction(archivedSelectedLessons, false);
  };

  return (
    <ButtonWithSpinner
      label='Unarchive'
      className='btn-primary unarchive-selected-lessons-action'
      disabled={archivedLessons.length === 0 || selectedLessonIds.length === 0 || isArchiving}
      loading={isArchiving}
      onClick={handleOnClick}
      startIcon={<UnarchiveIcon />}
      data-test='unarchive-selected-lessons-action'
    />
  );
};

UnarchiveSelectedLessons.defaultProps = {
  lessons: [],
  archiveLessonFunction: () => { },
  isArchiving: false,
  selectedLessonIds: [],
};

UnarchiveSelectedLessons.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  archiveLessonFunction: PropTypes.func,
  isArchiving: PropTypes.bool,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
};

export default UnarchiveSelectedLessons;

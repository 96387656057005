import { useEffect, useMemo, useState } from 'react';
import { useTeacherCurriculum } from '../../context/TeacherCurriculumContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import {
  searchCurriculumLessons,
} from '../../../../../services/CurriculumService';
import { isTexasTeacher } from '../../../../../utils/User';
import Logger from '../../../../../utils/Logger';
import {
  sort,
} from '../../../../../utils/ArrayUtils';

export const useHandleLessonLevelDropdownValues = () => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { filterCriteria } = teacherCurriculumDomain.domainData;

  const [standardLessonLevel, setStandardLessonLevel] = useState(filterCriteria.standardLessonLevel || '');

  const handleOnStandardLessonLevelChange = (e) => {
    setStandardLessonLevel(e.target.value);
  };

  useEffect(() => {
    setStandardLessonLevel(filterCriteria.standardLessonLevel || '');
  }, [filterCriteria]);

  return {
    standardLessonLevel,
    handleOnStandardLessonLevelChange,
  };
};

export const useGetMatchingAssessmentAreaLabel = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  if (isTexasTeacher(user)) {
    return 'Essence Statements/Assessment Areas';
  }
  return 'Instructional Family';
};

export const useHandleMatchingAssessmentAreaDropdownValues = () => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { searchCriteria, filterCriteria, lessonData: { meta: { matchingAssessmentAreas } } } = teacherCurriculumDomain.domainData;
  const [selectedMatchingAssessmentArea, setSelectedMatchingAssessmentArea] = useState(filterCriteria.essenceStatement || '');

  const sortedMatchingAssessmentAreaStandards = useMemo(() => {
    const matchingAssessmentAreaStandards = [];
    matchingAssessmentAreas.forEach((matchingAssessmentArea) => {
      matchingAssessmentArea.standardCodes.forEach((standardCode) => {
        const matchedStandard = matchingAssessmentAreaStandards.find((value) => (
          standardCode.standardCode === value.standardCode.standardCode
          && standardCode.essenceStatement === value.standardCode.essenceStatement
        ));

        if (matchedStandard) {
          // Matched
          matchedStandard.matchingAssessmentAreaIdList.push(matchingAssessmentArea.id);
        } else {
          // Not matched
          const idName = `${standardCode.standardCode} - ${standardCode.essenceStatement}`;
          matchingAssessmentAreaStandards.push({
            key: idName,
            name: idName,
            standardCode,
            matchingAssessmentAreaIdList: [matchingAssessmentArea.id],
          });
        }
      });
    });
    setSelectedMatchingAssessmentArea('');
    return sort(matchingAssessmentAreaStandards, 'key');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingAssessmentAreas]);

  useEffect(() => {
    if (filterCriteria.matchingAssessmentArea) {
      const matched = sortedMatchingAssessmentAreaStandards.find((m) => m.key === filterCriteria.matchingAssessmentArea.key);
      setSelectedMatchingAssessmentArea(matched || '');
    } else {
      setSelectedMatchingAssessmentArea('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  const handleOnMatchingAssessmentAreaChange = (e) => {
    setSelectedMatchingAssessmentArea(e.target.value);
  };

  return {
    selectedMatchingAssessmentArea,
    matchingAssessmentAreaStandards: sortedMatchingAssessmentAreaStandards,
    handleOnMatchingAssessmentAreaChange,
  };
};

export const useHandleVerticalAssessmentAreaDropdownValues = (selectedMatchingAssessmentArea) => {
  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { filterCriteria, lessonData: { meta: { verticalAssessmentAreas } } } = teacherCurriculumDomain.domainData;
  const [selectedVerticalAssessmentArea, setSelectedVerticalAssessmentArea] = useState(filterCriteria.essenceStatement || '');

  const sortedVerticalAssessmentAreaStandards = useMemo(() => {
    const secondaryVerticalAssessmentAreaStandards = [];
    const primaryVerticalAssessmentAreaStardards = [];
    verticalAssessmentAreas.forEach((verticalAssessmentArea) => {
      if (selectedMatchingAssessmentArea && selectedMatchingAssessmentArea.matchingAssessmentAreaIdList.includes(verticalAssessmentArea.id)) {
        verticalAssessmentArea.standardCodes.forEach((standardCode) => {
          const matchedStandard = [
            ...primaryVerticalAssessmentAreaStardards,
            ...secondaryVerticalAssessmentAreaStandards,
          ].find((value) => (
            value.id !== null
            && standardCode.standardCode === value.standardCode.standardCode
            && standardCode.essenceStatement === value.standardCode.essenceStatement
          ));

          // Add to the list when it is not in the list
          if (!matchedStandard) {
            const idName = `${standardCode.standardCode} - ${standardCode.essenceStatement}`;
            const value = {
              key: idName,
              name: idName,
              standardCode,
            };
            if (idName.substring(0, 1).toLowerCase() === 'k' || idName.substring(0, 3).toLowerCase() === 'pre') {
              primaryVerticalAssessmentAreaStardards.push(value);
            } else {
              secondaryVerticalAssessmentAreaStandards.push(value);
            }
          }
        });
      }
    });

    const primaryResult = sort(primaryVerticalAssessmentAreaStardards, 'key');
    const secondaryResult = sort(secondaryVerticalAssessmentAreaStandards, 'key');
    const result = [
      ...primaryResult,
      ...secondaryResult,
    ];
    const noneSelected = '(None Selected)';
    result.unshift({
      key: noneSelected,
      name: noneSelected,
    });

    if (filterCriteria.verticalAssessmentArea) {
      const matched = result.find((m) => m.key === filterCriteria.verticalAssessmentArea.key);
      setSelectedVerticalAssessmentArea(matched || '');
    } else {
      setSelectedVerticalAssessmentArea('');
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatchingAssessmentArea, verticalAssessmentAreas]);

  const handleOnVerticalAssessmentAreaChange = (e) => {
    setSelectedVerticalAssessmentArea(e.target.value);
  };

  return {
    selectedVerticalAssessmentArea,
    verticalAssessmentAreaStandards: sortedVerticalAssessmentAreaStandards,
    handleOnVerticalAssessmentAreaChange,
  };
};

export const useHandleSearchButton = (standardLessonLevel, matchingAssessmentArea, verticalAssessmentArea) => {
  const { userDomain, uiDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const { searchCriteria, lessonData } = teacherCurriculumDomain.domainData;
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnSearch = async () => {
    try {
      uiDomain.showLoader('Fetching curriculum lessons', LOADER_TYPE.GRID_LOADER);
      await callRemoteServiceWrapper(async () => {
        const filterOptionCriteria = {
          standardLessonLevel: standardLessonLevel && standardLessonLevel.value,
        };
        if (matchingAssessmentArea || verticalAssessmentArea) {
          const standardCodeToUse = verticalAssessmentArea && verticalAssessmentArea.standardCode ? verticalAssessmentArea.standardCode : matchingAssessmentArea.standardCode;
          filterOptionCriteria.essenceStatement = standardCodeToUse.essenceStatement;
          filterOptionCriteria.standardCode = standardCodeToUse.standardCode;
        }

        const curriculumLessons = await searchCurriculumLessons(userProfile.searchNode, searchCriteria, filterOptionCriteria);
        await teacherCurriculumDomain.updateFilteredLessonData({
          standardLessonLevel,
          matchingAssessmentArea,
          verticalAssessmentArea,
        },
        {
          lessons: curriculumLessons.data,
          included: curriculumLessons.included,
          meta: lessonData.meta,
        },
        []);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    uiDomain.hideLoader();
  };

  return {
    handleOnSearch,
  };
};

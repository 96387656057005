import { createContext, useContext } from 'react';
import MyLessonsDomain from './MyLessonsDomain';

const domains = [
  new MyLessonsDomain(),
];

/*-----------------------------------------------------------------------*/

let MyLessonsContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  MyLessonsContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useMyLessonsContext = () => useContext(MyLessonsContextProvider);

import { useState, useEffect } from 'react';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';

export const options = [
  { key: 'TX', name: 'Texas', value: 'TX' },
  { key: 'FL', name: 'Florida', value: 'FL' },
  { key: 'CA', name: 'California', value: 'CA' },
  { key: 'LA', name: 'Louisiana', value: 'LA' },
  { key: 'CCSS', name: 'Common Core State', value: 'CCSS' },
];

export const useHandleStateSelector = () => {
  const [state, setState] = useState(null);
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;

  const handleOnStateChange = (e) => {
    setState(e.target.value);
  };

  useEffect(() => {
    setState(null);
  }, [searchCriteria]);
  return {
    state,
    handleOnStateChange,
  };
};

export const getUrlByState = (user, selectedUnit, state) => {
  if (!user.userModerator) {
    return selectedUnit.guideLink;
  }

  if (selectedUnit && selectedUnit.stateGuides && state) {
    const pdfUrl = selectedUnit.stateGuides.find((sg) => sg.state === state);
    if (!pdfUrl) {
      return '';
    }
    return pdfUrl.guideLink;
  }

  return selectedUnit.guideLink;
};

import { useEffect, useState } from 'react';
import {
  Fab, Fade, Tooltip, Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  useNavigate,
} from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import './TeacherActivityReportTeachers.scss';
import SearchAndSortableTable from '../../../../../components/table/SearchAndSortableTable';
import { useAdmin } from '../../../context/AdminContextProvider';
import { URL_ACTIVITY_REPORT } from '../AdminDataConstants';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import CustomDialog from '../../../../../components/dialog/CustomDialog';

const studentTableConfig = [
  {
    title: 'Student Name',
    align: 'left',
    dataField: 'name',
    width: '100%',
  },
];

const TeacherActivityReportTeachers = () => {
  const [studentsTable, setStudentsTable] = useState({
    open: false,
    students: [],
  });

  const navigate = useNavigate();
  const { adminDataDomain } = useAdmin();
  const {
    isActivityReportLoading,
    activityReports,
    searchCriteria,
    selectedTeacherId,
  } = adminDataDomain.domainData;

  useEffect(() => {
    const { teachersLoggingIn, teachersWithoutLogins } = searchCriteria;
    if ((!teachersLoggingIn && teachersWithoutLogins) || selectedTeacherId) {
      navigate(URL_ACTIVITY_REPORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityReports]);

  let tableData = [];
  if (activityReports && activityReports.attributes && activityReports.attributes.teachersByActivity && activityReports.attributes.teachersByActivity.teacherSummary) {
    tableData = activityReports.attributes.teachersByActivity.teacherSummary.map((t) => {
      const { id, firstName, lastName } = t;
      return (
        {
          ...t,
          id,
          name: `${firstName} ${lastName}`,
        }
      );
    });
  }

  const tableConfig = [
    {
      title: 'Teacher Name',
      align: 'left',
      dataField: 'name',
      width: '35%',
    },
    {
      title: 'Plays Per Subject',
      align: 'left',
      dataField: 'subjectName',
      width: '40%',
      noSort: true,
      dataRenderer: (data) => {
        const { id, lessonPlays } = data;
        if (lessonPlays) {
          return (
            <div key={`teacher-id-${id}`} className='student-subject-report-container'>
              {
                lessonPlays.map((s) => {
                  const uuid = uuidv1();
                  return (
                    <div key={`teacher-id-${id}-subject-${uuid}`} className='student-subject-report'>
                      <div>{s.subjectName}</div>
                      <div>{s.count}</div>
                    </div>
                  );
                })
              }

            </div>
          );
        }
        return 'None';
      },
    },
    {
      title: 'Rostered Students',
      align: 'left',
      dataField: 'activeStudents',
      width: '30%',
      noSort: true,
      dataRenderer: (data) => {
        const { students } = data;
        if (students && students.length > 0) {
          return (
            <Typography
              className='student-list'
              onClick={() => {
                setStudentsTable({
                  open: true,
                  students,
                });
              }}
            >{students.length}
            </Typography>
          );
        }
        return '0';
      },
    },
  ];

  return (
    <>
      <Fade timeout={1000} in>
        <div className='teacher-activity-report-teachers' data-test='teacher-activity-report-teachers'>
          <Tooltip title='Back'>
            <Fab
              className='back-fab'
              aria-label='Back'
              onClick={() => {
                navigate(URL_ACTIVITY_REPORT);
              }}
            >
              <KeyboardBackspaceIcon />
            </Fab>
          </Tooltip>
          {!isActivityReportLoading && tableData.length === 0 ? (
            <NoLessonPaper message='No Teachers' className='no-data' />
          ) : (
            <SearchAndSortableTable
              config={tableConfig}
              tableData={tableData}
              initTableState={{
                order: 'desc',
                orderBy: 'name',
              }}
              searchPlaceholder='Filter by Teacher Name'
              searchField='name'
              dataId='id'
              loading={isActivityReportLoading}
              autoResetTableState={false}
              containerClassName='teacher-activity-report-teachers-table'
            />
          )}
        </div>
      </Fade>
      {studentsTable.open && (
        <CustomDialog
          openDialog={studentsTable.open}
          className='teacher-activity-report-student-table-dialog'
          onClose={() => {
            setStudentsTable({
              open: false,
              students: [],
            });
          }}
          content={(
            <SearchAndSortableTable
              config={studentTableConfig}
              tableData={studentsTable.students.map((s) => ({
                id: s.id,
                name: `${s.firstName} ${s.lastName}`,
              }))}
              initTableState={{
                order: 'desc',
                orderBy: 'name',
              }}
              searchPlaceholder='Filter by Student Name'
              searchField='name'
              dataId='id'
              loading={isActivityReportLoading}
              autoResetTableState={false}
              containerClassName='teacher-activity-report-teachers-table'
            />
          )}
          displayCloseIcon
          actions={[{
            onClick: () => {
              setStudentsTable({
                open: false,
                data: {},
              });
            },
            className: 'btn-primary action-button',
            title: 'Close',
          },
          ]}
        />
      )}
    </>
  );
};

export default TeacherActivityReportTeachers;

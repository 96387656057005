import { useEffect } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

const useOffButtonForm = (label, serviceFunction) => {
  const { uiDomain } = useDomain();
  const { loading, callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { studentsDomain } = useDomain();

  const handleOnClick = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const { students } = studentsDomain.domainData;
        if (!students || students.length === 0) {
          return;
        }
        const studentsList = {
          studentIds: students.map((s) => s.id),
        };
        await serviceFunction(studentsList);
        await uiDomain.showSnackbar(true, 'info', 3000, `${label} have been disabled`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(
    () => {
      // Cleanup the state
      const { snackbar } = uiDomain.domainData;
      if (snackbar.open) {
        uiDomain.closeSnackbar();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    loading,
    handleOnClick,
  };
};

export default useOffButtonForm;

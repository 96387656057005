import { useEffect, useRef, useState } from 'react';
import { TOOLBAR_ACTIONS_ID, TOOLBAR_NEXT_ACTION_ID } from '../../../../components/navigator/LessonNavigator';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';

export const useInitializeData = () => {
  const questionCardRef = useRef(null);
  const multipleChoicesResponseRefs = [];
  const setMultipleChoiceResponseRefs = (r, index) => {
    if (r) {
      multipleChoicesResponseRefs[index] = r;
    }
  };

  const submitAnswerActionRef = useRef(null);
  return {
    questionCardRef,
    multipleChoicesResponseRefs,
    setMultipleChoiceResponseRefs,
    submitAnswerActionRef,
  };
};

export const useHandleMultipleChoiceSelect = ({
  onTriggerSwitchScan,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleSelecteValue = (value) => {
    LessonPlayActivityUtils.stopMedias();
    setSelectedValue(value);
    if (selectedValue === '' && value >= 0) {
      onTriggerSwitchScan({ continueIndex: true });
    }
  };

  return {
    selectedValue,
    handleSelecteValue,
  };
};

export const useHandleSubmitButton = ({
  questionCard,
  answerCards,
  selectedValue,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleOnSubmitButtonClick = async () => {
    LessonPlayActivityUtils.stopMedias();
    const answerCard = answerCards[selectedValue];
    if (answerCard.answerKey === questionCard.questionKey) {
      await onCorrectAnswerSelected({ insertResponse: true, responseIndex: Number(selectedValue), once: true, isFinished: true, showReinforcer: true });
    } else {
      onIncorrectAnswerSelected(Number(selectedValue), true, true);
    }
    setIsSubmitted(true);
    onActivityFinished({ toNextPage: true });
  };

  return {
    isSubmitted,
    handleOnSubmitButtonClick,
    setIsSubmitted,
  };
};

export const useShowSubmitValueButton = ({
  isCurrentPage,
  isSubmitted,
  pageIndex,
}) => {
  const [showSubmitValueButton, setShowSubmitValueButton] = useState(false);

  const { lessonPlayDomain } = useLessonPlay();
  const { currentPageIndex, isPreviewMode } = lessonPlayDomain.domainData;

  useEffect(() => {
    const nextAction = document.getElementById(TOOLBAR_NEXT_ACTION_ID);
    const toolbarAction = document.getElementById(TOOLBAR_ACTIONS_ID);
    if (isCurrentPage && pageIndex === currentPageIndex && toolbarAction) {
      if (isSubmitted) {
        ObjectUtils.setTimeout(() => {
          if (nextAction) {
            nextAction.classList.remove('hide');
            nextAction.classList.add('show');
          }
          if (toolbarAction) {
            toolbarAction.classList.add('hide');
          }
          setShowSubmitValueButton(false);
        }, 500);
      } else if (isPreviewMode) {
        ObjectUtils.setTimeout(() => {
          if (nextAction) {
            nextAction.classList.remove('hide');
            nextAction.classList.add('show');
          }
          if (toolbarAction) {
            toolbarAction.classList.remove('hide');
          }
          setShowSubmitValueButton(true);
        }, 500);
      } else {
        ObjectUtils.setTimeout(() => {
          if (nextAction) {
            nextAction.classList.add('hide');
            nextAction.classList.remove('show');
          }
          if (toolbarAction) {
            toolbarAction.classList.remove('hide');
          }
          setShowSubmitValueButton(true);
        }, 500);
      }
    } else {
      if (nextAction) {
        nextAction.classList.remove('hide');
        nextAction.classList.add('show');
      }
      if (toolbarAction) {
        toolbarAction.classList.add('hide');
      }
      setShowSubmitValueButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, isSubmitted]);

  return {
    showSubmitValueButton,
    setShowSubmitValueButton,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  answerCards,
  onActivityFinished,
  pageIndex,
  setShowSubmitValueButton,
  setIsSubmitted,
  handleSelecteValue,
  isCurrentPage,
}) => {
  useEffect(() => {
    if (pageIndex < 0) {
      return;
    }

    if (isCurrentPage) {
      return;
    }
    const result = answerCards.findIndex((r) => (r.correctSubmission !== null && r.correctSubmission !== undefined));
    if (result >= 0) {
      handleSelecteValue(result);
      setShowSubmitValueButton(false);
      setIsSubmitted(true);
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

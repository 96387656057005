import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './NewVizzleIframe.scss';
import { useDomain } from '../../../states/AppDomainProvider';
import { getUserToken, saveTokenToMemory } from '../../../utils/VizzleSessionStorage';
import { dispatchSessionTimeoutEvent } from '../../../services/VizzleEvents';
import LogoutConfirmation from '../../../containers/logout/LogoutConfirmation';
import { useLogout } from '../../../containers/logout/LogoutHooks';
import { logout } from '../../../services/LoginService';
import LiveChatService from '../../../services/LiveChatService';
import { registerMedia } from '../../../services/MediaRegistrationService';

const NewVizzleIframe = forwardRef(({
  path,
  payload,
}, ref) => {
  const { userDomain, studentsDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;
  const { students } = studentsDomain.domainData;
  const navigate = useNavigate();

  const iframeRef = useRef();

  useImperativeHandle(ref, () => ({
    postMessageToIFrame: (message, data) => {
      iframeRef.current?.contentWindow?.postMessage({
        event: message,
        data,
      }, '*');
    },
  }));

  const {
    logoutDialog,
    openLogoutDialog,
    closeLogoutDialog,
  } = useLogout();

  useEffect(() => {
    const register = (e) => {
      const { data } = e;
      if (data.event === 'page-ready') {
        iframeRef.current?.contentWindow?.postMessage({
          event: 'initialization',
          data: {
            path,
            payload,
            userToken: getUserToken(),
            userInfo: {
              user,
              userProfile,
              terminologies,
              students,
            },
          },
        }, '*');
      } else if (data.event === 'update-token') {
        saveTokenToMemory(data.data.token);
      } else if (data.event === 'token-expired') {
        dispatchSessionTimeoutEvent('Your session expired please log in again.');
      } else if (data.event === 'hide-toolbar') {
        const toolbar = document.querySelector('.vizzle-app-bar-position-component');
        if (toolbar) {
          toolbar.style.display = 'none';
        }
      } else if (data.event === 'show-toolbar') {
        const toolbar = document.querySelector('.vizzle-app-bar-position-component');
        if (toolbar) {
          toolbar.style.display = null;
        }
      } else if (data.event === 'update-user-profile') {
        userDomain.updateUserProfile(data.data);
      } else if (data.event === 'update-terminologies') {
        userDomain.updateTerminologies(data.data);
      } else if (data.event === 'logout') {
        if (data.data.showDialog) {
          openLogoutDialog();
        } else {
          logout();
        }
      } else if (data.event === 'show-chat-icon') {
        const { show } = data.data;
        if (show) {
          LiveChatService.showLiveChatIcon();
        } else {
          LiveChatService.hideLiveChatIcon();
        }
      } else if (data.event === 'register-media') {
        registerMedia();
      } else if (data.event === 'redirect-to') {
        navigate(data.data.path);
      }
    };
    window.addEventListener('message', register);

    return () => {
      window.removeEventListener('message', register);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <iframe
        ref={iframeRef}
        className='new-vizzle-iframe'
        data-test='new-vizzle-iframe'
        src={`${process.env.REACT_APP_VIZZLE_REACT}/internal-login`}
        title='Vizzle iframe'
      />
      <LogoutConfirmation
        open={logoutDialog}
        onClose={closeLogoutDialog}
      />
    </>
  );
});

NewVizzleIframe.defaultProps = {
  payload: {},
};

NewVizzleIframe.propTypes = {
  path: PropTypes.string.isRequired,
  payload: PropTypes.any,
};

export default NewVizzleIframe;

import PropTypes from 'prop-types';
import AssignLessonActionWithDateSelectionV2 from '../../../../lessons/lessonactions/lesson-multi-selection-actions/AssignLessonActionWithDateSelectionV2';
import { useFetchMyLessons, useHandleTeacherCourseAssigment } from '../MyLessonsHook';
import { useGetAssignedStudentCourses } from '../../../../lessons/assigned-student-courses/AssignedStudentCoursesHooks';

export default function MyLessonsAssignLessonsAction({
  lessons,
  selectedLessonIds,
  selectedDates,
}) {
  const {
    fetchMyLessons,
  } = useFetchMyLessons();

  const propsArg = useHandleTeacherCourseAssigment();

  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading: null,
    subjectId: propsArg.subjectId,
    onDataLoad: propsArg.onDataLoad,
    assignedStudentCourses: propsArg.assignedStudentCourses,
    filterCriteriaAssignedStudentCourses: propsArg.filterCriteriaAssignedStudentCourses,
  });

  return (
    <AssignLessonActionWithDateSelectionV2
      lessons={lessons}
      selectedLessonIds={selectedLessonIds}
      showAddToMyList={false}
      reloadDataFunc={async () => {
        fetchMyLessons(false, selectedLessonIds);
        getAssignedStudentData(selectedDates);
      }}
    />
  );
}

MyLessonsAssignLessonsAction.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  selectedDates: {},
};

MyLessonsAssignLessonsAction.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  selectedDates: PropTypes.object,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import SearchableMultiGroupSelector from '../../../../../../components/selector/SearchableMultiGroupSelector';
import { getVizzleLibrary, updateUsedMedia } from '../../../../../../services/MediaService';
import Logger from '../../../../../../utils/Logger';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_LIMIT,
  getImageUrlForImage,
  hasMoreRecord,
} from './SearchableMultiGroupHelper';

const searchVizzleMediaInitState = {
  mediaSelectedType: MEDIA_TYPE_IMAGE,
  mediaLoading: false,
  currentSelectMediaIndex: null,
  hasSearched: false,
};

const searchVizzleMediaDataInitState = {
  searchText: '',
  images: {
    nextOffset: 0,
    imagesData: [],
    imagesMeta: {},
    hasMoreRecords: false,
  },
};

const RECORDS_TO_FETCH = 100;
/**
 * Container for selecting media from vizzle library
 *
 * @export
 */
export default function SearchVizzleLibrary({
  openFileDialog,
  onFileDialogClose,
  onSelect,
}) {
  // State of the vizzle search media
  const [searchVizzleMedia, setSearchVizzleMedia] = useState({ ...searchVizzleMediaInitState });

  // State of the vizzle search media dialog
  const [searchVizzleMediaData, setSearchVizzleMediaData] = useState({ ...searchVizzleMediaDataInitState });

  // Recursively get images until it reaches MEDIA_LIMIT
  const getImages = async (searchText = '', offset, dataList = [], dataListCount = 0) => {
    const getImagesPromise = await getVizzleLibrary(searchText, RECORDS_TO_FETCH, offset);
    const imageData = getImagesPromise.data.data || [];
    const filteredImageData = imageData;
    const concatedList = dataList.concat(filteredImageData);
    const count = dataListCount + filteredImageData.length;
    const nextOffset = Math.min(getImagesPromise.data.meta.offset + RECORDS_TO_FETCH, getImagesPromise.data.meta.totalCount);
    if (hasMoreRecord(getImagesPromise.data.meta) && count < MEDIA_LIMIT) {
      return getImages(searchText, nextOffset, concatedList, count);
    } else {
      return {
        data: concatedList,
        meta: getImagesPromise.data.meta,
        nextOffset,
        hasMoreRecords: hasMoreRecord(getImagesPromise.data.meta),
      };
    }
  };

  const getMedia = async (searchText = '') => {
    try {
      setSearchVizzleMedia({
        ...searchVizzleMedia,
        mediaLoading: true,
      });

      const getImagesPromise = await getImages(searchText, 0);
      setSearchVizzleMediaData({
        searchText,
        images: {
          nextOffset: getImagesPromise.nextOffset,
          imagesData: getImageUrlForImage(getImagesPromise.data),
          imagesMeta: getImagesPromise.meta,
          hasMoreRecords: getImagesPromise.hasMoreRecords,
        },
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    } finally {
      setSearchVizzleMedia({
        ...searchVizzleMedia,
        mediaLoading: false,
        hasSearched: true,
      });
    }
  };

  const onSearch = (searchText) => {
    if (!searchText) {
      // State of the vizzle search media
      setSearchVizzleMedia({ ...searchVizzleMediaInitState });
      setSearchVizzleMediaData({ ...searchVizzleMediaDataInitState });
    } else {
      getMedia(searchText);
    }
  };

  const mediaSelectorProps = {
    open: openFileDialog,
    handleClose: onFileDialogClose,
    title: 'Search Vizzle Library',
    groupType: [
      {
        type: MEDIA_TYPE_IMAGE,
        title: 'Image',
      },
    ],
    groupSelectedValue: MEDIA_TYPE_IMAGE,
    className: 'vizzle-search-library-dialog',
    isLoading: searchVizzleMedia.mediaLoading,
    dataList: searchVizzleMediaData.images.imagesData,
    onSearch,
    currentSelectedIndex: searchVizzleMedia.currentSelectMediaIndex,
    onDataSelected: (selectedIndex) => {
      setSearchVizzleMedia({
        ...searchVizzleMedia,
        currentSelectMediaIndex: selectedIndex,
      });
    },
    onSelect: async () => {
      const selectedMediaId = searchVizzleMediaData.images.imagesData[searchVizzleMedia.currentSelectMediaIndex].id;
      const selectedImage = searchVizzleMediaData.images.imagesData[searchVizzleMedia.currentSelectMediaIndex].imageUrl;
      updateUsedMedia(selectedMediaId);
      await onSelect({
        selectedImageUrl: selectedImage,
        selectedVideoUrl: null,
      });
      setSearchVizzleMedia({
        ...searchVizzleMedia,
        currentSelectMediaIndex: null,
      });
    },
    onLoadMore: async () => {
      if (!searchVizzleMediaData.images.hasMoreRecords) {
        return;
      }

      const imagePromiseResolve = await getImages(
        searchVizzleMediaData.searchText,
        searchVizzleMediaData.images.nextOffset,
        searchVizzleMediaData.images.imagesData,
      );

      setSearchVizzleMediaData({
        ...searchVizzleMediaData,
        images: {
          nextOffset: imagePromiseResolve.nextOffset,
          imagesData: getImageUrlForImage(imagePromiseResolve.data),
          imagesMeta: imagePromiseResolve.meta,
          hasMoreRecords: imagePromiseResolve.hasMoreRecords,
        },
      });
    },
    hasMoreRecords: searchVizzleMediaData.images.hasMoreRecords,
    initMessage: 'Please enter the search key to start searching.',
    showInitMessage: !searchVizzleMedia.hasSearched,
  };

  return (
    <SearchableMultiGroupSelector {...mediaSelectorProps} data-test='vizzle-media-dialog' />
  );
}

SearchVizzleLibrary.defaultProps = {
  openFileDialog: false,
  onFileDialogClose: () => { },
  onSelect: () => { },
};

SearchVizzleLibrary.propTypes = {
  openFileDialog: PropTypes.bool,
  onFileDialogClose: PropTypes.func,
  onSelect: PropTypes.func,
};

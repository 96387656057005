import { useState } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { isTexasTeacher } from '../../../../utils/User';
import {
  getGuidePdfLinkForTexasUser,
  getGuidePdfLinkForFloridaUser,
  getScopeAndSequenceLink,
  getLessonPlans,
  searchCurriculumLessons,
} from '../../../../services/CurriculumService';
import { useTeacherCurriculum } from '../../common/context/TeacherCurriculumContextProvider';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import Logger from '../../../../utils/Logger';
import { createLessonObject } from '../../../../utils/LessonsMapping';
import { CURRICULUM_PROVIDER_FLORIDA, CURRICULUM_PROVIDER_TEXAS } from '../../common/TeacherCurriculumConstants';

export const useHandleDropdownValues = () => {
  const [values, setValue] = useState({
    subject: '',
    grade: '',
    unit: '',
  });

  const handleOnSubjectChange = (e) => {
    const selectedValue = e.target.value;
    const updatedValue = {
      ...values,
      [e.target.name]: selectedValue,
    };
    if (values.subject.noUnits) {
      updatedValue.unit = '';
    }
    setValue(updatedValue);
  };

  const handleOnGradeChange = (e) => {
    const selectedValue = e.target.value;
    const updatedValue = {
      ...values,
      [e.target.name]: selectedValue,
    };

    if (values.grade.customUnit !== e.target.value.customUnit) {
      updatedValue.unit = '';
    }
    setValue(updatedValue);
  };

  const handleOnChange = (e) => {
    const updatedValue = {
      ...values,
      [e.target.name]: e.target.value,
    };
    setValue(updatedValue);
  };

  return {
    values,
    handleOnSubjectChange,
    handleOnGradeChange,
    handleOnChange,
  };
};

const createCriteriaObject = (selectedSubject, selectedGrade, selectedUnit, curriculumProvider) => ({
  subject: selectedSubject,
  grade: selectedGrade,
  unit: selectedUnit,
  curriculumProvider,
});

export const useHandleSearchButton = (values) => {
  const { subject, grade, unit } = values;
  const { userDomain, uiDomain } = useDomain();
  const { user, userProfile } = userDomain.domainData;

  const { teacherCurriculumDomain } = useTeacherCurriculum();
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  let allMandatoryFieldsSelected = subject && grade;
  if (subject && !subject.noUnits) {
    allMandatoryFieldsSelected = allMandatoryFieldsSelected && unit;
  }

  const searchTexasCurriculum = async () => {
    try {
      uiDomain.showLoader('Fetching curriculum lessons', LOADER_TYPE.GRID_LOADER);
      await callRemoteServiceWrapper(async () => {
        const guidePdfLink = getGuidePdfLinkForTexasUser(subject.name, grade.value, unit.value);
        const scopeAndSequenceLink = getScopeAndSequenceLink(subject.name, grade.value, unit.value);

        const searchCriteria = createCriteriaObject(subject, grade, unit, CURRICULUM_PROVIDER_TEXAS);
        const curriculumLessons = await searchCurriculumLessons(userProfile.searchNode, searchCriteria);

        const mappedLessons = curriculumLessons.data.map((l) => {
          const lesson = createLessonObject(l, user);
          return lesson;
        });

        await teacherCurriculumDomain.updateCurriculumData(searchCriteria, true,
          {
            guidePdfLink,
            scopeAndSequenceLink,
            lessonPlans: null,
            lessonData: {
              lessons: mappedLessons,
              included: curriculumLessons.included,
              meta: curriculumLessons.meta,
            },
            filterCriteria: {},
            selectedLessonIds: [],
          });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    uiDomain.hideLoader();
  };

  const searchFloridaCurriculum = async () => {
    try {
      uiDomain.showLoader('Fetching curriculum lessons', LOADER_TYPE.GRID_LOADER);
      await callRemoteServiceWrapper(async () => {
        const guidePdfLink = getGuidePdfLinkForFloridaUser(subject.name, grade.value, unit.value);
        const lessonPlansPromise = getLessonPlans(subject.name, grade.value);
        const searchCriteria = createCriteriaObject(subject, grade, unit, CURRICULUM_PROVIDER_FLORIDA);
        const searchCurriculumLessonsPromise = searchCurriculumLessons(userProfile.searchNode, searchCriteria);
        const [lessonPlans, curriculumLessons] = await Promise.all([lessonPlansPromise, searchCurriculumLessonsPromise]);

        const lessonPlanData = [];
        lessonPlans.data.forEach((content) => {
          if (content.attributes.s3Url.endsWith('.pdf')) {
            lessonPlanData.push({
              pdfLink: content.attributes.s3Url,
              lessonPlanName: content.attributes.s3Url.split('/').pop(),
            });
          }
        });
        await teacherCurriculumDomain.updateCurriculumData(searchCriteria, true,
          {
            guidePdfLink,
            scopeAndSequenceLink: null,
            lessonPlans: lessonPlanData,
            lessonData: {
              lessons: curriculumLessons.data,
              included: curriculumLessons.included,
              meta: curriculumLessons.meta,
            },
            filterCriteria: {},
            selectedLessonIds: [],
          });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
    uiDomain.hideLoader();
  };

  const handleOnSearch = () => {
    if (isTexasTeacher(user)) {
      searchTexasCurriculum();
    } else {
      searchFloridaCurriculum();
    }
  };

  return {
    allMandatoryFieldsSelected,
    handleOnSearch,
  };
};

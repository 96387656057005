import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CardAction from '../../../../../../../../components/card/CardAction';

export default function RenameFileAction({
  className,
  onClick,
  disabled,
}) {
  return (
    <CardAction
      icon={<EditIcon />}
      actionHandler={onClick}
      label='Rename'
      className={`btn-primary ${className}`}
      ariaLabel='Rename file'
      title='Rename file'
      data-test='rename-file-action'
      disabled={disabled}
    />
  );
}

RenameFileAction.defaultProps = {
  className: '',
  onClick: () => null,
  disabled: true,
};

RenameFileAction.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

import PropTypes from 'prop-types';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import './NotificationDialog.scss';

/**
 * Confirmation dialog component.
 * Dialogs inform users about a task and can contain critical information, require decisions
 */
export default function NotificationDialog({
  open,
  title,
  Icon,
  message,
  confirmLabel,
  onClose,
  ...rest
}) {
  if (!open) {
    return '';
  }
  return (
    <Dialog
      {...rest}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='notification-dialog'
      transitionDuration={800}
    >
      {
        title
          ? (
            <DialogTitle>
              <Typography className='notification-dialog-title' data-test='notification-dialog-title'>
                {title}
              </Typography>
            </DialogTitle>
          ) : ''
      }
      <DialogContent>
        {Icon
          ? <Icon className='notification-dialog-icon' /> : ''}
        <DialogContentText className='notification-dialog-text'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' className='btn-primary notification-dialog-button' data-test='notification-dialog-button'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NotificationDialog.defaultProps = {
  open: false,
  title: null,
  Icon: MoodBadIcon,
  message: '',
  confirmLabel: 'OK',
  onClose: () => { },
};

NotificationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  // eslint-disable-next-line
  Icon: PropTypes.object,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  onClose: PropTypes.func,
};

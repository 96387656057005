import ButtonWithSpinner from '../../../components/buttons/ButtonWithSpinner';
import { logout } from '../../../services/LoginService';
import './UserVerifySuccess.scss';

const UserVerifySuccess = () => (
  <div className='user-verify-success'>
    <div className='message'>
      User verification successful
    </div>
    <div className='actions'>
      <ButtonWithSpinner
        className='btn-primary'
        label='Return to Login Page'
        fullWidth
        onClick={() => {
          logout(false);
        }}
      />
    </div>
  </div>
);

export default UserVerifySuccess;

import { useState, useEffect } from 'react';
import {
  ButtonBase,
  Fade,
  Paper,
} from '@mui/material';
import { useDomain } from '../../../../states/AppDomainProvider';
import StudentObjectives from './StudentObjectives';
import DetailedReport from './DetailedReport';
import BackToIcon from '../../../../static/images/Back_To.png';
import GoToToIcon from '../../../../static/images/Go_To.png';
import './StudentTracking.scss';
import { CHURNZERO_CONSTANT } from '../../../../constants/ChurnZeroConstant';
import ChurnZeroService from '../../../../services/ChurnZeroService';

const StudentTracking = () => {
  const [tab, setTab] = useState(0);
  const { studentsDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { currentSelectedStudentId } = studentsDomain.domainData;

  useEffect(() => {
    setTab(0);
  }, [currentSelectedStudentId]);

  useEffect(() => {
    ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'View student data', user);
  }, [user]);

  return (
    <div className='student-tracking'>
      <Fade in={tab === 0}>
        <div className={`tab ${tab === 0 ? '' : 'hide'}`}>
          <StudentObjectives
            filterChildren={(
              <ButtonBase
                onClick={() => {
                  setTab(1);
                }}
                data-test='detailed-report'
              >
                <img
                  src={GoToToIcon}
                  alt='Detailed Report'
                  className='icon-button'
                />
              </ButtonBase>
            )}
          />
        </div>
      </Fade>
      {tab === 1 ? (
        <Fade in>
          <div className={`tab ${tab === 1 ? '' : 'hide'}`}>
            <Paper className='back-to-overview-top-bar'>
              <ButtonBase
                onClick={() => {
                  setTab(0);
                }}
                data-test='back-to-overview-report'
              >
                <img
                  src={BackToIcon}
                  alt='Back to Overview'
                  className='icon-button'
                />
              </ButtonBase>
            </Paper>
            <DetailedReport />
          </div>
        </Fade>
      ) : null}

    </div>
  );
};

export default StudentTracking;

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
// Need to import the loader this way, otherwise it fails on Linux machine
import HashLoader from 'react-spinners/HashLoader';
import GridLoader from 'react-spinners/GridLoader';
import RingLoader from 'react-spinners/RingLoader';
import RiseLoader from 'react-spinners/RiseLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import DotLoader from 'react-spinners/DotLoader';
import './SelectableLoader.scss';

/**
 * Loader type of the spinner
 */
export const LOADER_TYPE = {
  HASH_LOADER: 'HASH_LOADER',
  GRID_LOADER: 'GRID_LOADER',
  RING_LOADER: 'RING_LOADER',
  RISE_LOADER: 'RISE_LOADER',
  BOUNCE_LOADER: 'BOUNCE_LOADER',
  DOT_LOADER: 'DOT_LOADER',
};

/**
 * A loading spinner component.
 * The consumer can select spinner type by providing the props 'loaderType'.
 * By default, it will be grid loader style.
 *
 * @export
 * @param props of {
 *  message - message to display
 *  loaderType - loader type
 *  open - show / hide loader
 * }
 */
export default function SelectableLoader({ open, message, loaderType }) {
  const color = '#3b94d1';

  const getLoader = () => {
    if (!open) {
      return '';
    }

    switch (loaderType) {
      case LOADER_TYPE.HASH_LOADER:
        return <HashLoader size={90} color={color} />;
      case LOADER_TYPE.GRID_LOADER:
        return <GridLoader size={22} color={color} />;
      case LOADER_TYPE.RING_LOADER:
        return <RingLoader size={90} color={color} />;
      case LOADER_TYPE.RISE_LOADER:
        return <div className='rise-loader'><RiseLoader size={18} color={color} margin={3} /></div>;
      case LOADER_TYPE.BOUNCE_LOADER:
        return <BounceLoader size={70} color={color} />;
      case LOADER_TYPE.DOT_LOADER:
        return <DotLoader size={70} color={color} />;
      default:
        return <GridLoader size={22} color={color} />;
    }
  };

  return (
    <Dialog open={open} className='loader-dialog' transitionDuration={1000}>
      <div className={`loader-container ${open ? '' : 'loader-container-no-padding'}`}>
        {getLoader()}

        {message
          ? (
            <div className='loader-message' data-private>
              {message}
            </div>
          )
          : ''}
      </div>
    </Dialog>
  );
}

SelectableLoader.defaultProps = {
  message: '',
  loaderType: LOADER_TYPE.GRID_LOADER,
  open: false,
};

SelectableLoader.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  loaderType: PropTypes.string,
  open: PropTypes.bool,
};

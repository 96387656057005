import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import SortableTable from './SortableTable';
import CsvTableExport from '../export/CsvTableExport';
import PdfTableExport from '../export/PdfTableExport';
import './ReportTable.scss';

const ReportLoader = () => (
  <div className='report-loader' data-test='report-loader'>
    <CircularProgress />
    <p>Loading ...</p>
  </div>
);

const ReportTable = ({
  className,
  fileName,
  title,
  config,
  tableData,
  loading,
  header,
  customPrintPdf,
  onTableStateChange,
  initTableState,
  ...rest
}) => {
  const [sortedTableData, setSortedTableData] = useState(tableData);

  useEffect(() => {
    setSortedTableData(tableData);
  }, [tableData]);

  return (
    loading ? <ReportLoader /> : (
      <div {...rest} className={`${className} report-table`}>
        {header ? (
          <div className='header'>
            <Typography variant='h6'>
              {header}
            </Typography>
            <Divider />
          </div>
        ) : null}
        <div className='export-toolbar'>
          <CsvTableExport
            config={config}
            tableData={sortedTableData}
            filename={`${fileName}.csv`}
          />

          <PdfTableExport
            filename={`${fileName}.pdf`}
            title={title}
            config={config}
            tableData={sortedTableData}
            customPrint={customPrintPdf}
          />
        </div>
        <SortableTable
          config={config}
          tableData={tableData}
          onTableSort={(sortedData) => { setSortedTableData(sortedData); }}
          onTableStateChange={onTableStateChange}
          initTableState={initTableState}
        />
      </div>
    ));
};

ReportTable.defaultProps = {
  className: '',
  tableData: [],
  config: [],
  fileName: [],
  title: '',
  loading: false,
  header: null,
  customPrintPdf: null,
  onTableStateChange: () => { },
  initTableState: undefined,
};

ReportTable.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tableData: PropTypes.array,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    align: PropTypes.string,
    dataField: PropTypes.string,
    dataRenderer: PropTypes.func,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  fileName: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.string,
  customPrintPdf: PropTypes.func,
  onTableStateChange: PropTypes.func,
  initTableState: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
};

export default ReportTable;

/* eslint-disable react/jsx-boolean-value */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CardAction from '../../../components/card/CardAction';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useDomain } from '../../../states/AppDomainProvider';
import './ShareLinkLessonAction.scss';
import LiveChatService from '../../../services/LiveChatService';
import ObjectUtils from '../../../utils/ObjectUtils';

/**
 * Share Link Lesson Action Container
 */
export default function ShareLinkLessonAction({ lesson, className }) {
  const { uiDomain, userDomain } = useDomain();
  const { user } = userDomain.domainData;
  const [open, setOpen] = useState(false);
  const areaToCopy = useRef(null);

  const googleShareLinkRef = useRef(null);

  const link = `${window.location.origin}/lesson/${lesson.attributes.uuid}`;

  const [sharedLink, setSharedLink] = useState(link);
  const [checkboxValues, setCheckboxValue] = useState({
    requiredLogin: false,
    schoolLink: false,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const copyToClipboard = async () => {
    areaToCopy.current.select();
    document.execCommand('copy');
    await uiDomain.showSnackbar(true, 'info', 3000, 'The url has been copied to the clipboard');
  };

  const handleCheckboxRequiedLoginChange = (event) => {
    if (event.target.checked) {
      setCheckboxValue({
        requiredLogin: true,
        schoolLink: false,
      });
      setSharedLink(`${link}?auth=Y`);
    } else {
      setSharedLink(link);
      setCheckboxValue({
        ...checkboxValues,
        requiredLogin: false,
      });
    }
  };

  const handleCheckboxSchoolLinkChange = (event) => {
    if (event.target.checked) {
      setCheckboxValue({
        requiredLogin: false,
        schoolLink: true,
      });
      setSharedLink(`${process.env.REACT_APP_API_URL}/users/lti_launch/${lesson.attributes.uuid}`);
    } else {
      setSharedLink(link);
      setCheckboxValue({
        ...checkboxValues,
        schoolLink: false,
      });
    }
  };

  const content = (
    <div className='content'>
      <div className='inputs'>
        <input ref={areaToCopy} className='input-link-to-share' data-test='lesson-link' value={sharedLink} readOnly={true} />
        <div>
          <FormControlLabel
            control={
              (
                <Checkbox
                  onChange={handleCheckboxRequiedLoginChange}
                  name='requiredLogin'
                  color='primary'
                  checked={checkboxValues.requiredLogin}
                  data-test='required-login-checkbox'
                />
              )
            }
            label='Require Student Login'
          />
        </div>
        {user.schoologyFlag && (
          <div>
            <FormControlLabel
              control={
                (
                  <Checkbox
                    onChange={handleCheckboxSchoolLinkChange}
                    name='schoolLink'
                    color='primary'
                    checked={checkboxValues.schoolLink}
                    data-test='schoology-checkbox'
                  />
                )
              }
              label='Schoology Link'
            />
          </div>
        )}

      </div>
      <div className='action-container'>
        <IconButton aria-label='close' className='copy-icon-button' onClick={copyToClipboard} data-test='copy-link-action'>
          <FileCopyIcon />
        </IconButton>
        <div ref={googleShareLinkRef} className='g-sharetoclassroom share-to-classroom' data-test='google-share-to-classroom-action' data-size='32' data-url={`${link}?auth=Y`} />
      </div>
    </div>
  );

  return ([
    <CardAction
      key='share-link-card-action'
      icon={<ShareIcon />}
      label='SHARE LINK'
      actionHandler={() => {
        handleOpen();
      }}
      className={`btn-gray-background ${className}`}
      data-test='share-link-action'
    />,
    open ? (
      <CustomDialog
        key='share-link-custom-dialog'
        className='share-link-dialog'
        title='Share Link'
        content={content}
        openDialog={open}
        onClose={() => { setOpen(false); }}
        onEntered={() => {
          LiveChatService.hideLiveChatIcon();
          if (googleShareLinkRef.current) {
            // eslint-disable-next-line no-undef
            gapi.sharetoclassroom.render(
              googleShareLinkRef.current,
              {
                size: '50',
                theme: 'classic',
                url: `${link}?auth=Y`,
              },
            );
          }
        }}
        onExisting={() => {
          ObjectUtils.setTimeout(() => {
            LiveChatService.hideLiveChatIcon();
          });
        }}
      />
    ) : null,
  ]
  );
}

ShareLinkLessonAction.defaultProps = {
  className: '',
};

ShareLinkLessonAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  className: PropTypes.string,
};

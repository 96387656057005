import { useState } from 'react';
import PropTypes from 'prop-types';
import DropdownInput from '../../../../../../../../../../components/selector/DropdownInput';
import { FONT_SIZE_LIST } from '../../../../../../../../../../constants/TextEditorConstants';
import { useApplyFontStyle } from '../../ApplyTextStyleActionHooks';
import EntryContainer from '../EntryContainer';
import './Form.scss';

const fontSizeListOptions = FONT_SIZE_LIST.map((s) => ({
  id: s,
  name: s,
}));

export default function FontSizeForm({ applyToActivities }) {
  const [value, setValue] = useState('');

  const {
    applyFontStyle,
  } = useApplyFontStyle({
    applyToActivities,
  });
  return (
    <EntryContainer
      data-test='font-size-form'
      label='Font Size'
      onApply={async () => {
        await applyFontStyle([{
          fieldName: 'fontSize',
          value: `${value}px`,
        }]);
        setValue('');
      }}
      disabled={!value}
    >
      <DropdownInput
        dropdownValues={fontSizeListOptions}
        name='font'
        value={value}
        className='text-selector-dropdown-small'
        onChange={async (e) => {
          setValue(e.target.value);
        }}
      />
    </EntryContainer>
  );
}

FontSizeForm.propTypes = {
  applyToActivities: PropTypes.string.isRequired,
};

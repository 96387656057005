import BaseDomain from './BaseDomain';

const CURRENT_USER_UPDATE_USER_AND_PROFILE_ACTION = 'CURRENT_USER_UPDATE_USER_AND_PROFILE_ACTION';
const CURRENT_USER_UPDATE_PROFILE_ACTION = 'CURRENT_USER_UPDATE_PROFILE_ACTION';
const CURRENT_USER_UPDATE_SWITCH_TO_NEW_VIZZLE = 'CURRENT_USER_UPDATE_SWITCH_TO_NEW_VIZZLE';
const CURRENT_USER_UPDATE_TERMINOLOGIES_ACTION = 'CURRENT_USER_UPDATE_TERMINOLOGIES_ACTION';

const reducer = (state, action) => {
  switch (action.type) {
    case CURRENT_USER_UPDATE_USER_AND_PROFILE_ACTION:
      return {
        ...state,
        user: action.payload.user,
        userProfile: action.payload.userProfile,
        terminologies: action.payload.terminologies,
      };
    case CURRENT_USER_UPDATE_PROFILE_ACTION:
      return {
        ...state,
        userProfile: action.payload,
      };
    case CURRENT_USER_UPDATE_SWITCH_TO_NEW_VIZZLE:
      return {
        ...state,
        switchToNewVizzle: action.payload,
      };
    case CURRENT_USER_UPDATE_TERMINOLOGIES_ACTION:
      return {
        ...state,
        terminologies: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  user: {},
  userProfile: {},
  terminologies: {},
  switchToNewVizzle: false,
};

const domainName = 'userDomain';

/**
 * Domain class for user management domain
 *
 * @export
 * @class UserDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update user domain information
   *
   * @param {*} user
   * @returns
   */
  async updateCurrentUser(user, userProfile, terminologies) {
    return this.dispatch({
      type: CURRENT_USER_UPDATE_USER_AND_PROFILE_ACTION,
      payload: {
        user,
        userProfile,
        terminologies,
      },
    });
  }

  async updateUserProfile(userProfile) {
    return this.dispatch({
      type: CURRENT_USER_UPDATE_PROFILE_ACTION,
      payload: userProfile,
    });
  }

  async updateSwitchToNewVizzle(switchToNewVizzle) {
    return this.dispatch({
      type: CURRENT_USER_UPDATE_SWITCH_TO_NEW_VIZZLE,
      payload: switchToNewVizzle,
    });
  }

  async updateTerminologies(terminologies) {
    return this.dispatch({
      type: CURRENT_USER_UPDATE_TERMINOLOGIES_ACTION,
      payload: terminologies,
    });
  }
}

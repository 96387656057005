import { useState } from 'react';
import { addNewTopic } from '../../../../../../services/CourseService';
// import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import ObjectUtils from '../../../../../../utils/ObjectUtils';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useGetLessonCourseData } from '../../../TeacherCourseSearchBarHooks';

export const useHandleTopic = () => {
  // const [addedTopicNames, setAddedTopicNames] = useState([]);
  const [topicName, setTopicName] = useState('');

  const handleOnTopicChange = (e) => {
    setTopicName(e.target.value);
  };

  return {
    topicName,
    handleOnTopicChange,
  };
};

export const useSaveTopics = ({
  onSaveSuccess,
  courseId,
  unitId,
}) => {
  const {
    loading: saving,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  // const { uiDomain } = useDomain();

  const { getCourseData } = useGetLessonCourseData();
  const handleOnSave = async (addedTopicName) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const newTopics = await addNewTopic(courseId, unitId, addedTopicName);
        await getCourseData();
        // await uiDomain.showSnackbar(true, 'info', 3000, `New topic${addedTopicNames.length === 1 ? ' is' : 's are'} successfully added.`);
        ObjectUtils.setTimeout(async () => {
          await onSaveSuccess(newTopics);
        });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saving,
    handleOnSave,
  };
};

import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GameCellCard from './GameCellCard';
import GameActivityPlayerSetup from './setup/GameActivityPlayerSetup';
import GameInteractionPopup from './gameplay/GameInteractionPopup';
import PlayerPieces from './gameplay/PlayerPieces';
import WheelSpinner from './gameplay/WheelSpinner';
import SpecialtyCardPopup from './gameplay/SpecialtyCardPopup';
import MediaPlayer, { removeMediaPlayingClass } from '../../media/MediaPlayer';
import { playerPieceItems } from './setup/PlayerPieceData';
import GameActivityUtils from './GameActivityUtils';
import { playAudio } from '../../media/AudioPlayer';
import {
  TILE_TYPE_STANDARD,
  TILE_TYPE_LOSE_A_TURN,
  TILE_TYPE_FORWARD,
  TILE_TYPE_BACKWARD_2_SPACES,
} from './GameConstants';
import './GameCards.scss';
import './GameActivity.scss';

const moveToCardIndex = [];

const GameActivity = ({
  data,
  pageIndex,
  currentIndex,
}) => {
  const [playerSetup, setPlayerSetup] = useState({
    open: false,
    players: [],
    showSpinner: false,
  });

  const [landingInteractionPopup, setLandingInteractionPopup] = useState({
    open: false,
    landingInteraction: null,
    onClose: null,
  });

  const [specialtyCardPopup, setSpecialtyCardPopup] = useState({
    open: false,
    gameSpaceType: null,
  });

  const { gameCards } = data;

  const blockerRefEl = useRef(null);

  useEffect(() => {
    if (currentIndex === pageIndex && playerSetup.players.length === 0) {
      setPlayerSetup({
        ...playerSetup,
        open: true,
        showSpinner: false,
      });
    }
    // eslint-disable-next-line
  }, [currentIndex]);

  const handleOnPlayClick = (e, players) => {
    e.stopPropagation();
    const updatedPlayers = players.map((p, index) => ({
      ...p,
      currentCardIndex: 0,
      playerPieceImage: playerPieceItems.find((i) => i.value === p.selectedPiece).image,
      elementId: `player-${pageIndex}-${index}`,
    }));

    // initialization of game
    setPlayerSetup({
      ...playerSetup,
      open: false,
      players: updatedPlayers,
      currentPlayer: 0,
    });
  };

  const handleOnSpinnerStart = () => {
    setPlayerSetup({
      ...playerSetup,
      showSpinner: true,
    });
    removeMediaPlayingClass();
    blockerRefEl.current.classList.add('blocker-no-state-change-show');
  };

  const handleOnSpinnerEnd = (spinnerIndex, useIndex) => {
    const numberOfMoves = useIndex ? spinnerIndex : GameActivityUtils.getNumberOfMove(spinnerIndex, data, playerSetup);
    blockerRefEl.current.classList.remove('blocker-no-state-change-show');
    const newState = {
      ...playerSetup,
    };
    const newPlayersState = [...newState.players];
    const { currentCardIndex } = newPlayersState[playerSetup.currentPlayer];
    moveToCardIndex[pageIndex] = Math.max(currentCardIndex + numberOfMoves, 0);
    if (data.moveToCardIndicator) {
      document.getElementById(`game-card-${pageIndex}-${moveToCardIndex[pageIndex]}`).classList.add('highlight--blink');
    }
    const mediaElement = document.getElementById(`vizzle-game-card-page-${pageIndex}-${moveToCardIndex[pageIndex]}-media`);
    if (mediaElement) {
      mediaElement.classList.add('disable-cell');
    }
  };

  const handleOnCardClick = async (e, index) => {
    e.stopPropagation();
    if (index !== moveToCardIndex[pageIndex]) {
      return;
    }

    document.getElementById(`game-card-${pageIndex}-${moveToCardIndex[pageIndex]}`).classList.remove('highlight--blink');
    const mediaElement = document.getElementById(`vizzle-game-card-page-${pageIndex}-${moveToCardIndex[pageIndex]}-media`);
    if (mediaElement) {
      mediaElement.classList.remove('disable-cell');
    }
    const newState = {
      ...playerSetup,
    };
    const result = GameActivityUtils.movePlayerPieceWithAnimation(
      playerSetup.players[playerSetup.currentPlayer],
      moveToCardIndex[pageIndex],
      pageIndex,
    );

    const success = await result.promise;
    if (success) {
      if (data.landingAudio) {
        try {
          await playAudio(data.landingAudio);
        } catch (ex) {
          // ignore
        }
      }

      const { landingInteraction, gameSpaceType } = data.gameCards[moveToCardIndex[pageIndex]];
      const newPlayersState = [...newState.players];
      const newPlayerState = newPlayersState[playerSetup.currentPlayer];
      newPlayerState.currentCardIndex = moveToCardIndex[pageIndex];
      if (TILE_TYPE_LOSE_A_TURN === gameSpaceType) {
        newPlayerState.loseATurn = true;
      }
      const nextPlayerState = GameActivityUtils.getNextPlayerState(playerSetup, data.gameCards.length, gameSpaceType);

      if (TILE_TYPE_STANDARD === data.gameCards[moveToCardIndex[pageIndex]].gameSpaceType) {
        const landingInteractionObj = data.gameCards[moveToCardIndex[pageIndex]].landingInteraction;
        if (landingInteractionObj && (landingInteractionObj.questionCard || (landingInteractionObj.question && landingInteractionObj.question.question))) {
          setLandingInteractionPopup({
            open: true,
            landingInteraction,
            onClose: () => {
              setPlayerSetup({
                ...newState,
                ...nextPlayerState,
                showSpinner: false,
              });
            },
          });
        } else {
          setPlayerSetup({
            ...newState,
            ...nextPlayerState,
            showSpinner: false,
          });
        }
      } else {
        setSpecialtyCardPopup({
          open: true,
          gameSpaceType: data.gameCards[moveToCardIndex[pageIndex]].gameSpaceType,
          onCloseCallback: async () => {
            if (TILE_TYPE_FORWARD === data.gameCards[moveToCardIndex[pageIndex]].gameSpaceType) {
              moveToCardIndex[pageIndex]++;
              handleOnSpinnerEnd(1, true);
            } else if (TILE_TYPE_BACKWARD_2_SPACES === data.gameCards[moveToCardIndex[pageIndex]].gameSpaceType) {
              moveToCardIndex[pageIndex] = Math.max(0, moveToCardIndex[pageIndex] - 2);
              handleOnSpinnerEnd(-2, true);
            } else {
              setPlayerSetup({
                ...newState,
                ...nextPlayerState,
                showSpinner: false,
              });
            }
          },
        });
      }
    }
  };

  const handleOnClose = () => {
    if (landingInteractionPopup.onClose) {
      landingInteractionPopup.onClose();
    }
    setLandingInteractionPopup({
      open: false,
      landingInteraction: null,
      onClose: null,
    });
  };

  const handleOnSpecialtyCardClose = () => {
    setSpecialtyCardPopup(false);
    if (specialtyCardPopup && specialtyCardPopup.onCloseCallback) {
      specialtyCardPopup.onCloseCallback();
    }
  };

  return (
    <div className='game-card-grid-preview' data-test='game-activity'>
      <div className={`game-grid game-card-layout game-card-layout-${gameCards.length}`}>
        {gameCards.map((cell, index) => (
          <div
            key={`game-card-grid-card-${pageIndex}-${index}`}
            className='card-container'
            style={{ gridArea: `card${index}` }}
          >
            <GameCellCard
              pageIndex={pageIndex}
              cardIndex={index}
              spinner={data.spinner}
              text={cell.text}
              image={cell.image}
              video={cell.video}
              audio={cell.audio}
              borderColor={cell.borderColor}
              bgColor={cell.bgColor}
              gameSpaceType={cell.gameSpaceType}
              onCardClick={handleOnCardClick}
            />
            <PlayerPieces
              containerIndex={index}
              players={playerSetup.players}
              currentPlayer={playerSetup.currentPlayer}
              iconHeight={`${32 / gameCards.length}em`}
              pageIndex={pageIndex}
            />
            {
              (cell.audio || cell.video)
              && (
                <MediaPlayer
                  id={`vizzle-game-card-page-${pageIndex}-${index}-media`}
                  audioUrl={cell.audio}
                  videoUrl={cell.video}
                  parentElementId={`vizzle-game-card-page-${pageIndex}-${index}-media`}
                  highlightIcon
                  className={`small-icon vizzle-game-card-page-${pageIndex}-${index}-media`}
                />
              )
            }
          </div>
        ))}
        <div className='wheel-spinner-container'>
          {playerSetup.players.length > 0 ? (
            <WheelSpinner
              playerState={playerSetup}
              spinner={data.spinner}
              numberOfgameCards={data.gameCards.length}
              onSpinnerStart={handleOnSpinnerStart}
              onSpinnerEnd={handleOnSpinnerEnd}
              showSpinner={playerSetup.showSpinner}
            />
          ) : ''}

        </div>
        <div ref={blockerRefEl} className='blocker-no-state-change' />
      </div>
      <GameActivityPlayerSetup
        open={playerSetup.open}
        onSetupFinish={handleOnPlayClick}
      />
      <GameInteractionPopup
        open={landingInteractionPopup.open}
        onClose={handleOnClose}
        landingInteraction={landingInteractionPopup.landingInteraction}
      />
      <SpecialtyCardPopup
        open={specialtyCardPopup.open}
        onClose={handleOnSpecialtyCardClose}
        gameSpaceType={specialtyCardPopup.gameSpaceType}
        spinner={data.spinner}
      />
    </div>
  );
};

GameActivity.defaultProps = {
  data: {
    spinner: 1,
    landingAudio: null,
    gameCards: [],
  },
};

GameActivity.propTypes = {
  data: PropTypes.shape({
    spinner: PropTypes.number,
    landingAudio: PropTypes.string,
    moveToCardIndicator: PropTypes.bool,
    gameCards: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.object,
      image: PropTypes.string,
      video: PropTypes.string,
      audio: PropTypes.string,
      gameSpaceType: PropTypes.number,
      landingInteraction: PropTypes.shape({
        type: PropTypes.string,
        questionCard: PropTypes.object,
        answerCards: PropTypes.array,
      }),
    })),
  }),
  pageIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default GameActivity;

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import './ExtensionActivitiesAction.scss';
import { useDownloadFileAsBlob } from '../../../../../../../hooks/DownloadFileAsBlob';
import ObjectUtils from '../../../../../../../../utils/ObjectUtils';

export default function PrintFileAction({ activity }) {
  const iframeRef = useRef(null);
  const { loading, downloadFileToDataBlob } = useDownloadFileAsBlob();
  const [fileURL, setFileUrl] = useState();
  return (
    <>
      <Button
        className='extension-activities-view-action'
        startIcon={loading ? <CircularProgress size={25} /> : <PrintIcon />}
        variant='text'
        disabled={loading}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!fileURL) {
            const blob = await downloadFileToDataBlob(activity.url);
            const blobContent = new Blob([blob], { type: blob.type });
            const url = URL.createObjectURL(blobContent);
            setFileUrl(url);
          } else {
            iframeRef.current.contentWindow.print();
          }
        }}
        data-test={`extension-activity-print-${activity.id}`}
      >
        Print
      </Button>
      {fileURL ? (
        <iframe
          ref={iframeRef}
          style={{ display: 'none' }}
          title='Extension activity'
          src={fileURL}
          onLoad={() => {
            ObjectUtils.setTimeout(() => {
              iframeRef.current.contentWindow.print();
            }, 500);
          }}
        />
      ) : null}
    </>
  );
}

PrintFileAction.propTypes = {
  activity: PropTypes.object.isRequired,
};

import { useState, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import ClearTextIcon from '@mui/icons-material/Close';
import DataNotFoundIcon from '@mui/icons-material/SentimentDissatisfied';
import SearchIcon from '@mui/icons-material/Search';
import IconWithMessagePaper from '../paper/IconWithMessagePaper';
import './SearchableMultiGroupSelector.scss';
import ButtonWithSpinner from '../buttons/ButtonWithSpinner';

/**
 * Searchable multi group selector component.
 *
 * @param {*} param
 */
export default function SearchableMultiGroupSelector({
  open,
  handleClose,
  title,
  groupType,
  groupSelectedValue,
  onGroupSelectionChange,
  isLoading,
  dataList,
  onSearch,
  onDataSelected,
  currentSelectedIndex,
  onSelect,
  onLoadMore,
  hasMoreRecords,
  className,
  initMessage,
  showInitMessage,
  ...rest
}) {
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const dialogContentRefEl = useRef(null);

  const buildGroupTypeRadio = () => {
    if (!groupType || groupType.length === 1) {
      return '';
    }

    const handleChange = (event) => {
      onGroupSelectionChange(event.target.value);
      setIsLoadingMore(false);
      dialogContentRefEl.current.scrollTo(0, 0);
    };

    return (
      <RadioGroup
        className='searchable-group-selector-radio-group vizzle-radio-type'
        value={groupSelectedValue}
        onChange={handleChange}
        data-test='searchable-group-selector-radio-group'
      >
        {groupType.map((m, index) => (
          <FormControlLabel
            key={`searchable-group-selector-${m.type}`}
            value={m.type}
            control={<Radio color='secondary' />}
            label={m.title}
            className={`vizzle-radio-option-${index}`}
          />
        ))}
      </RadioGroup>
    );
  };

  const onSearchChange = (e) => {
    e.stopPropagation();
    setSearchValue(e.target.value);
  };

  const clearSearchTextAndStartSearch = (e) => {
    e.stopPropagation();
    setSearchValue('');
    onSearch('');
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      dialogContentRefEl.current.scrollTo(0, 0);
      onSearch(searchValue);
    }
  };

  const handleOnSearchClick = (e) => {
    dialogContentRefEl.current.scrollTo(0, 0);
    e.preventDefault();
    onSearch(searchValue);
  };

  const handleOnCardSelected = (e, selectedIndex) => {
    e.preventDefault();
    if (currentSelectedIndex !== selectedIndex) {
      onDataSelected(selectedIndex);
    }
  };

  const handledOnSelectButtonClick = (e) => {
    e.preventDefault();
    onSelect();
    handleClose();
  };

  const handleOnScroll = async (e) => {
    const listElement = e.target;
    if (!isLoadingMore && listElement.scrollTop + listElement.clientHeight >= listElement.scrollHeight) {
      if (hasMoreRecords) {
        setIsLoadingMore(true);
        await onLoadMore();
        dialogContentRefEl.current.scrollBy(0, -20);
        setIsLoadingMore(false);
      }
    }
  };

  const handleOnLoadMoreClick = async () => {
    setIsLoadingMore(true);
    await onLoadMore();
    setIsLoadingMore(false);
  };

  const displayList = () => {
    if (!dataList || dataList.length === 0) {
      const selectedGroupType = groupType.find((type) => (
        type.type === groupSelectedValue
      ));

      if (showInitMessage) {
        return <IconWithMessagePaper Icon={SearchIcon} message={initMessage} />;
      } else {
        const message = `Sorry, we could not find the ${selectedGroupType.title}s.`;
        return <IconWithMessagePaper Icon={DataNotFoundIcon} message={message} />;
      }
    }
    return (
      <div className='searchable-group-selector-card-list'>
        {dataList.map((data, index) => (
          <Card key={uuidv1()} className={`searchable-group-selector-card ${currentSelectedIndex === index ? 'searchable-group-selector-card-selected' : ''} vizzle-card-${groupType}-${index}`}>
            <CardActionArea
              className='searchable-group-selector-card-action-area'
              onClick={(e) => { handleOnCardSelected(e, index); }}
            >
              <CardContent className='searchable-group-selector-card-action-area-content'>
                {
                  cloneElement(data.component, {
                    className: 'searchable-group-selector-card-image',
                  })
                }
                <div className='searchable-group-selector-card-description'>
                  {data.description}
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <Dialog
      {...rest}
      className={`searchable-group-selector ${className}`}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        handleClose(e, reason);
      }}
      fullWidth
      maxWidth='md'
    >
      <Toolbar className='searchable-group-selector-toolbar'>
        {title ? (
          <Typography variant='h6' className='searchable-group-selector-title'>
            {title}
          </Typography>
        ) : ''}

        {buildGroupTypeRadio()}
        <Paper className='searchable-group-selector-search'>
          <InputBase
            fullWidth
            className='searchable-group-selector-search-textfield vizzle-searchable-group-selector-text-field'
            placeholder='Type here to start searching'
            inputProps={{ 'aria-label': 'search' }}
            autoFocus
            value={searchValue}
            onChange={onSearchChange}
            onKeyPress={handleOnKeyPress}
            data-test='searchable-group-selector-search-textfield'
          />
          {searchValue ? (
            <IconButton
              aria-label='clear search text'
              onClick={clearSearchTextAndStartSearch}
              className='vizzle-searchable-group-selector-clear-text-btn'
            >
              <ClearTextIcon />
            </IconButton>
          ) : ''}

          <Divider className='searchable-group-selector-search-divider' />
          <IconButton
            aria-label='search'
            onClick={handleOnSearchClick}
            className='vizzle-searchable-group-selector-search-btn'
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Toolbar>
      <DialogContent onScroll={handleOnScroll} ref={dialogContentRefEl} className='searchable-group-selector-dialog-content'>
        <div
          data-test='searchable-group-selector-content'
          className={`searchable-group-selector-content ${hasMoreRecords ? 'searchable-group-selector-fake-scroll-bar' : ''}`}
        >
          {isLoading
            ? <CircularProgress className='searchable-group-selector-loading' size={40} />
            : displayList()}

          {/* {isLoadingMore
            ? (
              <div className='searchable-group-selector-loading-more'>
                <CircularProgress className='searchable-group-selector-loading-more-icon' size={20} />
                <Typography className='searchable-group-selector-loading-more-text'>Loading ...</Typography>
              </div>
            ) : ''} */}
        </div>
        <div className='load-more-container'>
          {hasMoreRecords ? (
            <ButtonWithSpinner
              label='Load More'
              className='load-more-action'
              loading={isLoading || isLoadingMore}
              onClick={handleOnLoadMoreClick}
              data-test='load-more-action'
            />
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={currentSelectedIndex === null}
          onClick={handledOnSelectButtonClick}
          className='btn-gray-background vizzle-searchable-group-select-btn'
          data-test='searchable-group-select-action'
        >
          Select
        </Button>
        <Button
          onClick={handleClose}
          className='btn-gray-background vizzle-searchable-group-selector-close-btn'
          data-test='searchable-group-close-action'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SearchableMultiGroupSelector.defaultProps = {
  open: false,
  handleClose: () => { },
  title: '',
  onGroupSelectionChange: () => { },
  isLoading: false,
  dataList: [],
  onSearch: () => { },
  onDataSelected: () => { },
  currentSelectedIndex: null,
  onSelect: () => { },
  onLoadMore: () => { },
  hasMoreRecords: false,
  className: '',
  initMessage: null,
  showInitMessage: false,
};

SearchableMultiGroupSelector.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  groupType: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  groupSelectedValue: PropTypes.string.isRequired,
  onGroupSelectionChange: PropTypes.func,
  isLoading: PropTypes.bool,
  dataList: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.node,
    description: PropTypes.string,
  })),
  onSearch: PropTypes.func,
  onDataSelected: PropTypes.func,
  currentSelectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  onLoadMore: PropTypes.func,
  hasMoreRecords: PropTypes.bool,
  className: PropTypes.string,
  initMessage: PropTypes.string,
  showInitMessage: PropTypes.bool,
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TWEEN from '@tweenjs/tween.js';
import * as PIXI from 'pixi.js';
import ThemeUtil from '../ThemeUtil';

const COLORS = ['#1c177a', '#c8e5fc'];
const BACKGROUND_GRADIENT_INTENSITY = 20;

const FLAG_COUNT = 9;
const ROOF_COLORS = ['blue', 'green', 'orange', 'purple', 'red'];

const CastleTheme = ({
  themeData,
  onClick,
  pixiLoader,
  lessonsCompleted,
  lessonsLength,
}) => {
  const canvas = useRef(null);

  const app = {
    canvas: null,
    flags: [],
    spritesPlay: [],
    tweens: [],
    timeouts: [],
  };

  const {
    innerLessonsCompleted,
    innerLessonsLength,
  } = ThemeUtil.getLessonData(lessonsCompleted, lessonsLength);

  const chooseRandomColor = () => {
    const rand = Math.floor(Math.random() * ROOF_COLORS.length) + 1;
    app.color = ROOF_COLORS[rand - 1];
  };

  const addStructure = () => {
    const offsetYPos = 30;
    app.structure = new PIXI.AnimatedSprite(app.sheet.animations.structure);
    app.structureBounds = app.structure.getBounds();
    app.structure.x = app.floatingIsland.x - app.structureBounds.width / 2;
    app.structure.y = app.floatingIsland.y - app.floatingIslandBounds.height / 1.6 - offsetYPos;
    app.floatingIslandContainer.addChild(app.structure);
  };

  const addTurretWindows = (turretBody, windowPlacement) => {
    const turretBodyBounds = turretBody.getBounds();
    let padding = 0;
    const paddingToAdd = 20;
    const offsetFromTop = (30 * turretBody.scale.y);
    const windowsToAdd = Math.floor(5 * turretBody.scale.y);
    let skipping = false;
    let offsetXPos = 0;

    for (let i = 0; i < windowsToAdd; i++) {
      if (windowPlacement === 'staggerAndSkip') {
        if (skipping) {
          offsetXPos = offsetXPos ? (offsetXPos * -1) : -10;
        }
        skipping = !skipping;
      } else if (windowPlacement === 'stagger') {
        offsetXPos = offsetXPos ? (offsetXPos * -1) : -10;
      } else if (windowPlacement === 'skip') {
        skipping = !skipping;
      }

      if (!skipping) {
        const turretWindow = new PIXI.AnimatedSprite(app.sheet.animations.turret_window);
        const turretWindowBounds = turretWindow.getBounds();
        turretWindow.x = turretBody.x + turretBodyBounds.width / 2 + offsetXPos;
        turretWindow.y = turretBody.y + (turretWindowBounds.height * (i + 1)) + padding + offsetFromTop;
        app.floatingIslandContainer.addChild(turretWindow);
        padding += paddingToAdd;
      }
    }
  };

  const addDrawbridge = () => {
    app.bridge = new PIXI.AnimatedSprite(app.sheet.animations.bridgeUp);
    app.bridgeBounds = app.bridge.getBounds();
    app.bridge.x = app.structureBounds.width + 12;
    app.bridge.y = app.structure.y + 46;
    app.floatingIslandContainer.addChild(app.bridge);
  };

  const addFlag = (body) => {
    const container = new PIXI.Container();
    container.scale.x = 0.7;
    container.scale.y = 0.7;

    for (let i = 0; i <= FLAG_COUNT; i++) {
      const flagObj = {};
      flagObj.container = container;
      const flag = new PIXI.AnimatedSprite(app.sheet.animations[`flag${i}_${app.color}`]);
      flag.visible = i === 0;
      container.addChild(flag);

      const bodyBounds = body.getBounds();
      const flagBounds = flag.getBounds();
      container.x = body.x + bodyBounds.width / 2 - 1;
      container.y = body.y - flagBounds.height / 2;
      app.floatingIslandContainer.addChildAt(container, 2);
      flagObj.index = 0;
      app.flags.push(flagObj);
    }
  };

  const addTurretRoof = (turretBody, scaleY) => {
    const turretBodyBounds = turretBody.getBounds();
    const turretRoof = new PIXI.AnimatedSprite(app.sheet.animations[`turret_roof_${app.color}`]);
    turretRoof.scale.x = turretBody.scale.x;
    turretRoof.scale.y = scaleY;
    const turretRoofBounds = turretRoof.getBounds();
    turretRoof.x = turretBody.x + (turretBodyBounds.width / 2 - turretRoofBounds.width / 2);
    turretRoof.y = turretBody.y - turretRoofBounds.height + (5 * scaleY);
    app.floatingIslandContainer.addChild(turretRoof);

    addFlag(turretRoof);
  };

  function addTurret(alignment, windowPlacement, showTurretRoof, scaleX, scaleY, scaleRoofY, offsetXPos) {
    const turretBody = new PIXI.AnimatedSprite(app.sheet.animations.turret_body);
    app.showTurretRoof = String(showTurretRoof) !== 'undefined' ? showTurretRoof : true;
    turretBody.scale.x = scaleX || 1;
    turretBody.scale.y = scaleY || 1;
    // eslint-disable-next-line no-param-reassign
    scaleRoofY = scaleRoofY || 1;
    // eslint-disable-next-line no-param-reassign
    offsetXPos = offsetXPos || 0;

    const turretBodyBounds = turretBody.getBounds();

    if (alignment === 'center') {
      turretBody.x = app.structure.x + (app.structureBounds.width / 2 - turretBodyBounds.width / 2);
    }
    if (alignment === 'centerLeft') {
      turretBody.x = app.structure.x + app.structureBounds.width / 2 - app.structureBounds.width / 4 + offsetXPos;
    }
    if (alignment === 'centerRight') {
      turretBody.x = app.structure.x + app.structureBounds.width / 2 + app.structureBounds.width / 4 - turretBodyBounds.width - offsetXPos;
    } else if (alignment === 'left') {
      turretBody.x = app.structure.x + offsetXPos;
    } else if (alignment === 'right') {
      turretBody.x = app.structure.x + app.structureBounds.width - turretBodyBounds.width - offsetXPos;
    }

    turretBody.y = app.structure.y - turretBodyBounds.height;
    app.floatingIslandContainer.addChild(turretBody);

    const turretTop = new PIXI.AnimatedSprite(app.sheet.animations.turret_top);
    const turretTopBounds = turretTop.getBounds();
    turretTop.scale.x = scaleX;
    turretTop.x = turretBody.x;
    turretTop.y = turretBody.y - turretTopBounds.height / 2 + 3;
    app.floatingIslandContainer.addChild(turretTop);

    if (windowPlacement !== 'none') {
      addTurretWindows(turretBody, windowPlacement);
    }

    if (showTurretRoof) {
      addTurretRoof(turretBody, scaleRoofY);
    }
  }

  const addTurrets = () => {
    const rand = Math.floor(Math.random() * 5) + 1;
    switch (rand) {
      case 1:
        addTurret('center', 'stagger', true, 0.8, 0.7, 0.5);
        addTurret('left', 'default', true, 0.6, 0.5, 0.6, 30);
        addTurret('right', 'default', true, 0.6, 0.5, 0.6, 30);
        break;
      case 2:
        addTurret('center', 'stagger', false, 0.6, 0.4, 1.2);
        addTurret('left', 'default', false, 0.6, 0.4, 1, 30);
        addTurret('right', 'default', false, 0.6, 0.4, 1, 30);
        addTurret('centerLeft', '', true, 0.6, 0.6, 0.6);
        addTurret('centerRight', '', true, 0.6, 0.6, 0.6);
        break;
      case 3:
        addTurret('left', '', false, 0.7, 0.2, 0.5, 20);
        addTurret('right', '', false, 0.7, 0.2, 0.5, 20);
        addTurret('centerLeft', 'none', false, 1, 0.4, 0.5, -20);
        addTurret('centerRight', 'none', false, 1, 0.4, 0.5, -20);
        addTurret('center', 'stagger', true, 1, 0.6, 0.6);
        break;
      case 4:
        addTurret('left', 'none', true, 0.4, 0.4, 0.4, 20);
        addTurret('right', 'none', true, 0.4, 0.4, 0.4, 20);
        addTurret('left', 'none', true, 0.5, 0.6, 0.6, 61);
        addTurret('right', 'none', true, 0.5, 0.6, 0.6, 61);
        addTurret('centerLeft', '', true, 0.6, 0.7, 0.6);
        addTurret('centerRight', '', true, 0.6, 0.7, 0.6);
        break;
      case 5:
      default:
        addTurret('centerLeft', 'default', false, 0.8, 0.4, 1, -40);
        addTurret('centerRight', 'default', false, 0.8, 0.4, 1, -40);
        addTurret('left', '', true, 0.6, 0.6, 0.6, 10);
        addTurret('right', '', true, 0.6, 0.6, 0.6, 10);
        addTurret('center', '', true, 0.6, 0.6, 0.6);
        break;
    }
  };

  const addTree = (position) => {
    const tree = new PIXI.AnimatedSprite(app.sheet.animations.tree);
    tree.scale.x = 0.6;
    tree.scale.y = 0.6;
    app.treeBounds = tree.getBounds();

    switch (position) {
      case 'left0':
        tree.scale.x = 0.3;
        tree.scale.y = 0.3;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x - 20;
        tree.y = (app.structure.y + app.structureBounds.height) - app.treeBounds.height - 8;
        app.floatingIslandContainer.addChildAt(tree, 1);
        break;
      case 'left1':
        tree.scale.x = 0.4;
        tree.scaleY = 0.4;
        tree.x = app.structure.x - 90;
        tree.y = app.structure.y + 40;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'left2':
        tree.scale.x = 0.5;
        tree.scale.y = 0.5;
        tree.x = app.structure.x - 60;
        tree.y = app.structure.y + 0;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'left3':
        tree.scale.x = 0.55;
        tree.scale.y = 0.55;
        tree.x = app.structure.x - 120;
        tree.y = app.structure.y + 5;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'left4':
        tree.scale.x = 0.6;
        tree.scale.y = 0.6;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x - 100;
        tree.y = app.structure.y - 10;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'left5':
        tree.scale.x = 0.65;
        tree.scale.y = 0.65;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x - 158;
        tree.y = app.structure.y - 5;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'leftCastle':
        tree.scale.x = 0.4;
        tree.scaleY = 0.4;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + 60;
        tree.y = app.structure.y + 55;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'right0':
        tree.scale.x = 0.3;
        tree.scale.y = 0.3;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width - app.treeBounds.width / 3;
        tree.y = (app.structure.y + app.structureBounds.height) - app.treeBounds.height - 20;
        app.floatingIslandContainer.addChildAt(tree, 1);
        break;
      case 'right1':
        tree.scale.x = 0.4;
        tree.scale.y = 0.4;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width + 3;
        tree.y = app.structure.y + 10;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'right2':
        tree.scale.x = 0.5;
        tree.scale.y = 0.5;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width + 37;
        tree.y = app.structure.y + 10;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'right3':
        tree.scale.x = 0.55;
        tree.scaleY = 0.55;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width + 80;
        tree.y = app.structure.y + 20;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'right4':
        tree.scaleX = 0.6;
        tree.scaleY = 0.6;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width;
        tree.y = app.structure.y + 5;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'right5':
        tree.scale.x = 0.65;
        tree.scale.y = 0.65;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width + 85;
        tree.y = app.structure.y + 10;
        app.floatingIslandContainer.addChild(tree);
        break;
      case 'rightCastle':
        tree.scale.x = 0.35;
        tree.scale.y = 0.35;
        app.treeBounds = tree.getBounds();
        tree.x = app.structure.x + app.structureBounds.width - 20;
        tree.y = app.structure.y + 50;
        app.floatingIslandContainer.addChild(tree);
        break;
      default:
        break;
    }
  };

  const showWoods = (position, type) => {
    for (let i = 0; i <= 5; i++) {
      if (type === 'random') {
        const rand = Math.floor(Math.random() * (2 - 1 + 1) + 1);
        if (rand === 1) {
          addTree(position + i);
        }
      } else {
        addTree(position + i);
      }
    }
  };

  const showRandomTrees = () => {
    const treesScenes = [1, 2, 3, 4, 5, 6, 7];
    const rand = Math.floor(Math.random() * treesScenes.length) + 1;
    const lastSelectedTreeScene = treesScenes[rand - 1];
    switch (lastSelectedTreeScene) {
      case 1:
        // no woods
        addTree('right4');
        break;
      case 2:
        showWoods('left');
        showWoods('right');
        addTree('leftCastle');
        addTree('rightCastle');
        break;
      case 3:
        addTree('left0');
        addTree('left5');
        addTree('leftCastle');
        addTree('right0');
        addTree('right3');
        break;
      case 4:
        addTree('right0');
        addTree('right5');
        addTree('rightCastle');
        addTree('leftCastle');
        addTree('left0');
        addTree('left3');
        break;
      case 5:
        addTree('right0');
        addTree('right2');
        addTree('left4');
        break;
      case 6:
        addTree('left0');
        addTree('left2');
        addTree('right4');
        break;
      case 7:
      default:
        showWoods('left', 'random');
        showWoods('right', 'random');
        addTree('leftCastle');
        addTree('rightCastle');
        break;
    }
  };

  const addStructureRoof = () => {
    const offsetYPos = 10;
    const structureRoof = new PIXI.AnimatedSprite(app.sheet.animations[`structure_roof_${app.color}`]);
    structureRoof.x = app.structure.x;
    structureRoof.y = app.structure.y - offsetYPos;
    app.floatingIslandContainer.addChild(structureRoof);
  };

  const updatePony = () => {
    app.pony.scale.x = 0.5;
    app.pony.scale.y = 0.5;
    app.bridge.alpha = 0;
    const drawBrideTimeout = setTimeout(() => {
      clearTimeout(drawBrideTimeout);
      app.drawBridge.play();
    }, 2000);
    app.timeouts.push(drawBrideTimeout);

    const ponyY = app.pony.y;
    const ponyX = app.pony.x;
    const ponyRundownY = app.pony.y + 75;

    app.pBack.y = ponyRundownY;

    const ponyReturnScaleTween = new TWEEN.Tween(app.pBack.scale)
      .to({ x: 0.2, y: 0.2 }, 500);

    const ponyReturnFadeTween = new TWEEN.Tween(app.pBack)
      .to({ alpha: 0 }, 500)
      .onComplete(() => {
        app.drawBridge.alpha = 0;
        app.bridgeReturn.alpha = 1;
        app.bridgeReturn.play();
        app.pony.y = ponyY;
        app.pBack.scale.x = 0.5;
        app.pBack.scale.y = 0.5;

        const replayTimeout = setTimeout(() => {
          clearTimeout(replayTimeout);
          app.drawBridge.alpha = 1;
          app.bridgeReturn.alpha = 0;
          updatePony();
        }, 3000);
        app.timeouts.push(replayTimeout);
      });

    app.tweens.push(ponyReturnScaleTween);
    app.tweens.push(ponyReturnFadeTween);

    const ponyReturnTween = new TWEEN.Tween(app.pBack)
      .onStart(() => {
        app.pRun.alpha = 0;
        app.pBack.alpha = 1;
        app.pBack.play();
      })
      .to({ y: ponyY }, 2000)
      .onComplete(() => {
        ponyReturnScaleTween.start();
        ponyReturnFadeTween.start();
      });

    const ponyRunBackToTheBridgeTween = new TWEEN.Tween(app.pRun)
      .onStart(() => {
        app.pRun.scale.x = 0.5;
      })
      .to({ x: ponyX }, 3000)
      .chain(ponyReturnTween);

    const ponyRunBackTween = new TWEEN.Tween(app.pRun)
      .onStart(() => {
        app.pRun.scale.x = -0.5;
      })
      .to({ x: ponyX - 240 }, 5000)
      .chain(ponyRunBackToTheBridgeTween);

    const ponyRunTween = new TWEEN.Tween(app.pRun)
      .onStart(() => {
        app.pRun.y = ponyRundownY;
        app.pRun.alpha = 1;
        app.pony.alpha = 0;
        app.pRun.play();
      })
      .to({ x: ponyX + 180 }, 2000)
      .chain(ponyRunBackTween);

    const ponyTween = new TWEEN.Tween(app.pony)
      .to({ y: ponyRundownY, alpha: 1 }, 2000)
      .delay(3000)
      .chain(ponyRunTween)
      .easing(TWEEN.Easing.Linear.None);

    app.tweens.push(ponyTween);
    ponyTween.start();
  };

  function addPony() {
    app.drawBridge = new PIXI.AnimatedSprite(app.sheet.animations.drawBridge);
    app.drawBridge.x = app.bridge.x;
    app.drawBridge.y = app.bridge.y;
    app.drawBridge.animationSpeed = 0.1;
    app.drawBridge.loop = false;
    app.floatingIslandContainer.addChild(app.drawBridge);
    app.spritesPlay.push(app.drawBridge);

    app.pony = new PIXI.AnimatedSprite(app.sheet.animations.pony);
    app.ponyBounds = app.pony.getBounds();
    app.pony.anchor.set(0.5, 0);
    app.pony.x = app.bridge.x + app.bridgeBounds.width / 2;
    app.pony.y = app.bridge.y + (app.bridgeBounds.height / 2) - app.ponyBounds.height / 2;
    app.pony.scale.x = 0.5;
    app.pony.scale.y = 0.5;
    app.pony.alpha = 0;
    app.floatingIslandContainer.addChild(app.pony);
    app.pony.animationSpeed = 0.1;
    app.spritesPlay.push(app.pony);

    app.pRun = new PIXI.AnimatedSprite(app.sheet.animations.pRun);
    app.pRun.anchor.set(0.5, 0);
    app.pRun.x = app.pony.x;
    app.pRun.y = app.pony.y;
    app.pRun.scale.x = 0.5;
    app.pRun.scale.y = 0.5;
    app.pRun.alpha = 0;
    app.floatingIslandContainer.addChild(app.pRun);
    app.pRun.animationSpeed = 0.1;
    app.spritesPlay.push(app.pRun);

    app.pBack = new PIXI.AnimatedSprite(app.sheet.animations.pBack);
    app.pBack.anchor.set(0.5, 0);
    app.pBack.x = app.pony.x;
    app.pBack.y = app.pony.y;
    app.pBack.scale.x = 0.5;
    app.pBack.scale.y = 0.5;
    app.pBack.alpha = 0;
    app.floatingIslandContainer.addChild(app.pBack);
    app.pBack.animationSpeed = 0.1;
    app.spritesPlay.push(app.pBack);

    app.bridgeReturn = new PIXI.AnimatedSprite(app.sheet.animations.bridgeReturn);
    app.bridgeReturn.x = app.bridge.x;
    app.bridgeReturn.y = app.bridge.y;
    app.bridgeReturn.alpha = 0;
    app.bridgeReturn.loop = false;
    app.floatingIslandContainer.addChild(app.bridgeReturn);
    app.bridgeReturn.animationSpeed = 0.1;
    app.spritesPlay.push(app.bridgeReturn);

    app.pony.play();
    updatePony();
  }

  function weatherBegin() {
    const snowflake = new PIXI.AnimatedSprite(app.sheet.animations.weather);
    const container = new PIXI.Container();
    container.addChild(snowflake);
    container.x = Math.random() * app.canvas.view.width;
    container.y = -50;
    const duration = Math.random() * 1000 + 1000;

    const weather8Tween = new TWEEN.Tween(snowflake)
      .to({ x: snowflake.x + 25, alpha: 1 }, duration)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather7Tween = new TWEEN.Tween(snowflake)
      .to({ x: 0, alpha: 1 }, duration)
      .chain(weather8Tween)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather6Tween = new TWEEN.Tween(snowflake)
      .to({ x: snowflake.x + 25, alpha: 1 }, duration)
      .chain(weather7Tween)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather5Tween = new TWEEN.Tween(snowflake)
      .to({ x: 0, alpha: 1 }, duration)
      .chain(weather6Tween)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather4Tween = new TWEEN.Tween(snowflake)
      .to({ x: snowflake.x + 25, alpha: 1 }, duration)
      .chain(weather5Tween)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather3Tween = new TWEEN.Tween(snowflake)
      .to({ x: 0, alpha: 1 }, duration)
      .chain(weather4Tween)
      .easing(TWEEN.Easing.Quadratic.InOut);

    const weather2Tween = new TWEEN.Tween(snowflake)
      .to({ x: snowflake.x + 25, alpha: 1 }, duration)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .chain(weather3Tween);

    app.weather1Tween = new TWEEN.Tween(snowflake)
      .to({ x: 0, alpha: 1 }, duration)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .chain(weather2Tween);
    app.tweens.push(app.weather1Tween);
    app.weather1Tween.start();

    app.floatingIslandContainer.addChild(container);

    const weatherContainerTween = new TWEEN.Tween(container)
      .to({ y: app.canvas.view.height + 100 }, 10000)
      .onComplete(() => {
        app.floatingIslandContainer.removeChild(container);
      })
      .easing(TWEEN.Easing.Quadratic.InOut);
    app.tweens.push(weatherContainerTween);
    weatherContainerTween.start();
  }

  const addWeather = () => {
    if (!app.sheet.animations.weather) {
      return;
    }
    app.interval = setInterval(weatherBegin, 200);
  };

  const showRandomScene = () => {
    chooseRandomColor();
    addStructure();
    addDrawbridge();
    addTurrets();
    addStructureRoof();
    if (innerLessonsCompleted >= 1) { addPony(); }
    showRandomTrees();
    addWeather();
  };

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('switchAccessScan', () => {
        app.floatingIslandHighlight.visible = true;
      });

      canvas.current.addEventListener('switchAccessLeaveElement', () => {
        app.floatingIslandHighlight.visible = false;
      });

      const { canvas: appCanvas } = ThemeUtil.initCanvasWithBackground(
        canvas,
        COLORS,
        BACKGROUND_GRADIENT_INTENSITY,
      );
      app.canvas = appCanvas;
      ThemeUtil.initWrapper(app, themeData, pixiLoader, onClick);
      const progressBar = {
        barBackground: 'progressbar_background_wood',
        progressBar: 'progressbar_rainbow',
        progressAvatar: 'unicorn',
        dotType: 'diamond',
      };
      ThemeUtil.addProgressBar(
        app,
        progressBar,
        innerLessonsLength,
        innerLessonsCompleted,
      );

      ThemeUtil.addFloatableObjects(app, 3000, false);
      showRandomScene();
    }

    return () => {
      clearInterval(app.interval);
      ThemeUtil.stopFloatableObjects(app);
      app.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      app.spritesPlay.forEach((p) => {
        p.stop();
        p.destroy();
      });
      app.tweens.forEach((t) => {
        t.stopChainedTweens();
        t.stop();
      });
      app.stopFloatingIslandTween();
      app.canvas.destroy(true, {
        children: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return (
    <canvas ref={canvas} id='theme-canvas'>
      reward goes here
    </canvas>
  );
};

CastleTheme.defaultProps = {
  onClick: () => { },
  lessonsCompleted: null,
  lessonsLength: null,
};

CastleTheme.propTypes = {
  themeData: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  pixiLoader: PropTypes.object.isRequired,
  lessonsCompleted: PropTypes.number,
  lessonsLength: PropTypes.number,
};

export default CastleTheme;

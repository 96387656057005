import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import NumberOfPlayersSlider from './NumberOfPlayersSlider';
import PlayerSetup from './PlayerSetup';
import { useScreenReader } from '../../../../../screen-reader/ScreenReaderHooks';
import { useRegisterEvent } from '../../../../../switch-access/SwitchAccessHooks';
import './GamePlayActivityPlayerSetup.scss';

const MIN_PLAYERS = 1;
const MAX_PLAYERS = 6;

const GamePlayActivityPlayerSetup = forwardRef(({
  open,
  onSetupFinish,
}, ref) => {
  const initPlayers = [];
  for (let i = 0; i < MAX_PLAYERS; i++) {
    initPlayers.push({
      name: `Student ${i + 1}`,
      selectedPiece: i === 0 ? 0 : '',
      hide: i + 1 > MIN_PLAYERS,
    });
  }
  const [players, setPlayers] = useState([...initPlayers]);

  const handleOnSliderChange = (event, value) => {
    event.stopPropagation();
    const updatedPlayers = players.map((p, index) => ({
      ...p,
      hide: index + 1 > value,
      selectedPiece: index + 1 > value ? '' : p.selectedPiece,
    }));
    setPlayers(updatedPlayers);
  };

  const handleOnDataChange = (index, prop, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][prop] = value instanceof String && value.length > 20 ? value.substring(0, 20) : value;
    setPlayers(updatedPlayers);
  };

  const activePlayers = players.filter((p) => (!p.hide));

  const handleOnPlayButtonClick = (e) => {
    onSetupFinish(e, activePlayers);
  };

  const playButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      if (open) {
        return [playButtonRef.current];
      }
      return null;
    },
  }));

  const eventClenupFuncRef = useRef(null);
  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  const {
    registerSwitchEvent,
  } = useRegisterEvent();

  const handleOnEnter = () => {
    eventClenupFuncRef.current = registerSwitchEvent('switchAccessScan', playButtonRef, () => {
      readWhenScreenReaderIsOn(playButtonRef.current);
    });
  };

  return (
    <Dialog
      open={open}
      transitionDuration={100}
      className='game-activity-play-setup-dialog'
      maxWidth='md'
      TransitionProps={{
        onEntered: handleOnEnter,
      }}
      data-test='game-activity-play-setup-dialog'
    >
      <DialogContent
        className='dialog-content'
      >
        <div className='number-of-players-text' data-test='number-of-players-text'>
          Number of players:&nbsp;
          {activePlayers.length}
        </div>
        <div className='number-of-players-slider' data-test='number-of-players-slider'>
          <NumberOfPlayersSlider
            min={MIN_PLAYERS}
            max={MAX_PLAYERS}
            valueLabelDisplay='off'
            onChange={handleOnSliderChange}
            value={activePlayers.length}
          />
        </div>
        <div className='players-setup' data-test='players-setup'>
          {
            activePlayers.map((p, index) => (
              <PlayerSetup
                key={`game-activity-player-${index}`}
                name={p.name}
                selectedPiece={p.selectedPiece}
                onDataChange={(prop, value) => { handleOnDataChange(index, prop, value); }}
                excludePieces={
                  activePlayers
                    .filter((activePlayer, aIndex) => aIndex !== index && (activePlayer.selectedPiece || activePlayer.selectedPiece === 0))
                    .map((activePlayer) => activePlayer.selectedPiece)
                }
              />
            ))
          }
        </div>
      </DialogContent>
      <DialogActions className='vizzle-dialog-player-setup-actions actions'>
        <Button
          ref={playButtonRef}
          data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
          alt='Play'
          color='primary'
          className='btn-primary vizzle-dialog-player-setup-actions-play play-button'
          onClick={handleOnPlayButtonClick}
          disabled={
            activePlayers.findIndex((activePlayer) => (
              activePlayer.name === null
              || activePlayer.name === undefined
              || activePlayer.name.trim().length === 0
              || activePlayer.selectedPiece === null
              || activePlayer.selectedPiece === undefined
              || (!activePlayer.selectedPiece && activePlayer.selectedPiece !== 0)
            )) >= 0
          }
          data-test='play-button'
        >
          PLAY
        </Button>
      </DialogActions>
    </Dialog>
  );
});

GamePlayActivityPlayerSetup.defaultProps = {
  open: false,
  onSetupFinish: () => { },
};

GamePlayActivityPlayerSetup.propTypes = {
  open: PropTypes.bool,
  onSetupFinish: PropTypes.func,
};

export default GamePlayActivityPlayerSetup;

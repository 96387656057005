import { useState, useMemo, useRef, useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { useShowDropRejection } from '../common/rejected-answer-response/RejectedAnswerResponse';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import {
  MATCHING_ACTIVITY_DRAG_AND_DROP,
  MATCHING_ACTIVITY_ONE_CLICK,
  MATCHING_ACTIVITY_TWO_CLICK,
} from '../../../../constants/SettingConstant';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
import Logger from '../../../../utils/Logger';

export const useGenerateComponentIds = () => {
  const componentIds = useMemo(() => {
    const id = uuidv1();
    const prompCardDropableId = `promp-placeholder-${id}`;
    return {
      id,
      prompCardDropableId,
    };
  }, []);

  return componentIds;
};

export const useInitializeData = ({ initialCorrectAnswer, initialIncorrectAnswers }) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile, singleAnswer } = lessonPlayDomain.domainData;
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(initialCorrectAnswer);
  const [selectedWrongResponses, setSelectedWrongResponses] = useState(initialIncorrectAnswers);

  const [isDraggable, setIsDraggable] = useState(userProfile.interactionType === MATCHING_ACTIVITY_DRAG_AND_DROP);

  useEffect(() => {
    const timeout = ObjectUtils.setTimeout(() => {
      LessonPlayActivityUtils.stopVideos();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {
    selectedResponseIndex,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    isDraggable,
    setIsDraggable,
    dragAndDropApiRef: useRef(null),
    matchingActivityRef: useRef(null),
    singleAnswer,
  };
};

const submitAnswer = async ({
  responseCard,
  index,
  setSelectedResponseIndex,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  selectedWrongResponses,
  setSelectedWrongResponses,
  showDropRejection,
  rejectedAnswerResponseRef,
  singleAnswer,
}) => {
  // The single answer lesson can submit the answer only once and move to the next activity
  if (singleAnswer) {
    if (responseCard.correctAnswer) {
      setSelectedResponseIndex(index);
      await onCorrectAnswerSelected({ insertResponse: true, responseIndex: index, once: false, isFinished: true, showReinforcer: true });
      onActivityFinished();
    } else {
      await showDropRejection(rejectedAnswerResponseRef.current);
      setSelectedWrongResponses([
        ...selectedWrongResponses,
        index,
      ]);
      await onIncorrectAnswerSelected(index, true, true);
      onActivityFinished({ toNextPage: true, alwaysGoToNext: true });
    }
    return;
  }

  if (responseCard.correctAnswer) {
    setSelectedResponseIndex(index);
    await onCorrectAnswerSelected({ insertResponse: true, responseIndex: index, once: false, isFinished: true, showReinforcer: true });
    onActivityFinished();
  } else {
    await showDropRejection(rejectedAnswerResponseRef.current);
    setSelectedWrongResponses([
      ...selectedWrongResponses,
      index,
    ]);
    onIncorrectAnswerSelected(index);
  }
};

export const useDragAndDropSubmitAnswer = ({
  matchingActivityRef,
  prompCardDropableId,
  responseCards,
  rejectedAnswerResponseRef,
  setSelectedResponseIndex,
  selectedWrongResponses,
  setSelectedWrongResponses,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;

  const showDropRejection = useShowDropRejection();

  const handleOnDragStart = (initial) => {
    const draggingItem = document.getElementById(initial.draggableId);
    if (draggingItem) {
      draggingItem.classList.add('matching-high-z-index');
    }

    LessonPlayActivityUtils.stopMedias();
    LessonPlayActivityUtils.disableComponent(matchingActivityRef, true);
  };

  const handleOnDragEnd = async (result) => {
    try {
      const elList = document.getElementsByClassName('matching-high-z-index');
      if (elList.length > 0) {
        [].forEach.call(elList, (el) => {
          el.classList.remove('matching-high-z-index');
        });
      }

      const { source, destination } = result;
      if (!source || !destination) {
        LessonPlayActivityUtils.disableComponent(matchingActivityRef, false);
        return;
      }

      const { index } = source;
      const { droppableId } = destination;
      if (prompCardDropableId === droppableId) {
        await submitAnswer({
          responseCard: responseCards[index],
          index,
          setSelectedResponseIndex,
          onCorrectAnswerSelected,
          onIncorrectAnswerSelected,
          onActivityFinished,
          selectedWrongResponses,
          setSelectedWrongResponses,
          showDropRejection,
          rejectedAnswerResponseRef,
          singleAnswer,
        });
      }
    } catch (e) {
      Logger.logError(e);
    } finally {
      LessonPlayActivityUtils.disableComponent(matchingActivityRef, false);
    }
  };

  return {
    handleOnDragStart,
    handleOnDragEnd,
  };
};

export const useTwoClickSubmitAnswer = ({
  matchingPromptRef,
  prompCardDropableId,
  dragAndDropApiRef,
  setIsDraggable,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const currentSelectedIndex = useRef(null);

  let handleOnPromptCardClick = null;
  let handleOnResponseCardClick = null;

  if (MATCHING_ACTIVITY_TWO_CLICK === userProfile.interactionType) {
    handleOnPromptCardClick = () => {
      if (currentSelectedIndex.current) {
        setIsDraggable(true);
        LessonPlayActivityUtils.stopMedias();
        matchingPromptRef.current.elementRef.classList.remove('prompt-selected-card');

        const dragAndDropApi = dragAndDropApiRef.current;
        const triggerDragAndDrop = async () => {
          await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, currentSelectedIndex.current.cardId, currentSelectedIndex.current.draggableId, prompCardDropableId);
          setIsDraggable(false);
          currentSelectedIndex.current = null;
        };

        ObjectUtils.setTimeout(() => {
          triggerDragAndDrop();
        });
      }
    };

    handleOnResponseCardClick = (_e, isTtsAbleToPlay, responseCard, cardId, responseCardDraggableId) => {
      const videoPlaying = document.querySelector('.video-player-dialog');
      if (videoPlaying) {
        return;
      }

      if (currentSelectedIndex.current && currentSelectedIndex.current.cardId === cardId) {
        LessonPlayActivityUtils.stopMedias();
        matchingPromptRef.current.elementRef.classList.remove('prompt-selected-card');
        document.getElementById(currentSelectedIndex.current.cardId).classList.remove('matching-selected-card');
        currentSelectedIndex.current = null;
      } else {
        if (!responseCard.audio && !responseCard.video && !isTtsAbleToPlay) {
          LessonPlayActivityUtils.stopMedias();
        }

        if (currentSelectedIndex.current) {
          matchingPromptRef.current.elementRef.classList.remove('prompt-selected-card');
          document.getElementById(currentSelectedIndex.current.cardId).classList.remove('matching-selected-card');
        }
        currentSelectedIndex.current = {
          cardId,
          draggableId: responseCardDraggableId,
        };
        matchingPromptRef.current.elementRef.classList.add('prompt-selected-card');
        document.getElementById(cardId).classList.add('matching-selected-card');
      }
    };
  }

  return {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  };
};

export const useOneClickSubmitAnswer = ({
  prompCardDropableId,
  dragAndDropApiRef,
  setIsDraggable,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  let handleOnResponseCardClick = null;

  if (MATCHING_ACTIVITY_ONE_CLICK === userProfile.interactionType) {
    handleOnResponseCardClick = (e, _isTtsAbleToPlay, _responseCard, cardId, responseCardDraggableId) => {
      // Do not submit the answer when clicking the dialog backdrop
      if (e.target.classList.contains('MuiDialog-container')) {
        return;
      }
      const { isMediaPlaying } = lessonPlayDomain.domainData;
      if (isMediaPlaying) {
        return;
      }
      LessonPlayActivityUtils.stopMedias();
      setIsDraggable(true);
      LessonPlayActivityUtils.stopMedias();

      const dragAndDropApi = dragAndDropApiRef.current;
      const triggerDragAndDrop = async () => {
        await LessonPlayActivityUtils.drapAndDropAnimation(dragAndDropApi, cardId, responseCardDraggableId, prompCardDropableId);
        setIsDraggable(false);
      };

      ObjectUtils.setTimeout(() => {
        triggerDragAndDrop();
      });
    };
  }

  return {
    handleOnResponseCardClick,
  };
};

const CARD_MOVE_DELAY = 1200;
export const useAutoPlay = ({
  matchingActivityRef,
  selectedResponseIndex,
  matchingPromptRef,
  matchingResponseRefs,
  isCurrentPage,
  responseCards,
}) => {
  const alreadyPlayed = useRef(false);
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;
  const isAutoPlay = MATCHING_ACTIVITY_ONE_CLICK === userProfile.interactionType;

  const [internalIsMediaPlaying, setInternalIsMediaPlaying] = useState(isAutoPlay);
  const internalIsMediaPlayingRef = useRef(isAutoPlay);

  const [responseCardsAnimation, setResponseCardsAnimation] = useState(() => {
    const animation = {
      timeout: 0,
      responseCardIn: responseCards.map(() => true),
    };

    if (isAutoPlay && selectedResponseIndex < 0) {
      animation.timeout = CARD_MOVE_DELAY;
      animation.responseCardIn = [];
    }
    return animation;
  });

  const playResponseMedia = (matchingResponseRef, timeoutList = []) => (
    new Promise((resolve) => {
      try {
        const timeout = ObjectUtils.setTimeout(async () => {
          await matchingResponseRef.playMedia();
          resolve();
        }, CARD_MOVE_DELAY + 500);
        timeoutList.push(timeout);
      } catch (e) {
        Logger.logError(e);
        resolve();
      }
    })
  );

  let timeoutList = [];

  const playMedia = async () => {
    timeoutList = [];
    LessonPlayActivityUtils.disableComponent(matchingActivityRef, true);
    await lessonPlayDomain.updateIsMediaPlaying(true);
    await ObjectUtils.delay(700);
    try {
      if (matchingPromptRef.current) {
        const result = await matchingPromptRef.current.playAudio();
        if (result && result.isCancel) {
          return;
        }
      }
    } catch (e) {
      Logger.logWarn(e);
    }

    try {
      if (matchingPromptRef.current) {
        await matchingPromptRef.current.playVideo();
      }
    } catch (e) {
      Logger.logWarn(e);
    }

    await ObjectUtils.delay(200);

    for (let i = 0; i < matchingResponseRefs.length; i++) {
      setResponseCardsAnimation((previousState) => {
        // eslint-disable-next-line no-param-reassign
        previousState.responseCardIn[i] = true;
        return { ...previousState };
      });

      const matchingResponseRef = matchingResponseRefs[i];
      if (matchingResponseRef) {
        // eslint-disable-next-line no-await-in-loop
        await playResponseMedia(matchingResponseRef, timeoutList);
      }
    }
    alreadyPlayed.current = true;
    await lessonPlayDomain.updateIsMediaPlaying(false);
    internalIsMediaPlayingRef.current = false;
    setInternalIsMediaPlaying(false);
    LessonPlayActivityUtils.disableComponent(matchingActivityRef, false);
  };

  useEffect(() => {
    if (isAutoPlay && isCurrentPage && selectedResponseIndex < 0 && !alreadyPlayed.current) {
      // Wait a bit before start playing
      internalIsMediaPlayingRef.current = true;
      setInternalIsMediaPlaying(true);
      ObjectUtils.setTimeout(() => {
        playMedia();
      }, 100);
    }

    return (async () => {
      await lessonPlayDomain.updateIsMediaPlaying(false);
      timeoutList.forEach((t) => clearTimeout(t));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPage]);

  const replayCards = () => {
    alreadyPlayed.current = false;
    internalIsMediaPlayingRef.current = true;
    setInternalIsMediaPlaying(true);
    setResponseCardsAnimation({
      ...responseCardsAnimation,
      responseCardIn: [],
    });
    ObjectUtils.setTimeout(() => {
      playMedia();
    }, CARD_MOVE_DELAY + 200);
  };

  return {
    internalIsMediaPlaying,
    internalIsMediaPlayingRef,
    isAutoPlay,
    responseCardsAnimation,
    replayCards,
  };
};

export const usePrepareViewCorrectSubmissions = ({
  initialCorrectAnswer,
  setSelectedResponseIndex,
  setSelectedWrongResponses,
  responseCards,
  onActivityFinished,
  pageIndex,
}) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { singleAnswer } = lessonPlayDomain.domainData;

  useEffect(() => {
    if (pageIndex < 0) {
      return;
    }
    setSelectedResponseIndex(() => {
      const result = responseCards.findIndex((r) => (r.correctSubmission !== null && r.correctSubmission));
      const correctAnswer = result >= 0 ? result : initialCorrectAnswer;
      if (correctAnswer >= 0) {
        onActivityFinished({ toNextPage: false, pageIndex });
      }
      return correctAnswer;
    });

    const incorrectAnswers = [];
    responseCards.forEach((r, index) => {
      const result = r.correctSubmission !== null && r.correctSubmission === false;
      if (result) {
        incorrectAnswers.push(index);
      }
    });
    setSelectedWrongResponses(incorrectAnswers);
    if (singleAnswer && incorrectAnswers.length > 0) {
      onActivityFinished({ toNextPage: false, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

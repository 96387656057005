import { useState } from 'react';
import {
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import AddTopicDialog from './dialog/topic/AddTopicDialog';
import GenerateLessonsDialog from './dialog/generate-lessons/GenerateLessonsDialog';

const AddTopicAction = ({
  subjectId,
  courseId,
  unitId,
  lessonLevel,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [openGenerateLessonDialog, setOpenGenerateLessonDialog] = useState({
    open: false,
    topicId: null,
  });

  return (
    <>
      <Button
        variant='contained'
        className='btn-green-background add-button'
        onClick={() => { setOpenDialog(true); }}
        disabled={!courseId || !lessonLevel}
        startIcon={<EditIcon />}
        data-test='add-topic-action'
      >
        Add Topic
      </Button>

      {openDialog && (
        <AddTopicDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          onSaveSuccess={(newTopics) => {
            setOpenDialog(false);
            setOpenGenerateLessonDialog({
              open: true,
              topicId: newTopics.id,
            });
          }}
          courseId={courseId}
          unitId={unitId}
          lessonLevel={lessonLevel}
        />
      )}

      {openGenerateLessonDialog.open && (
        <GenerateLessonsDialog
          open={openGenerateLessonDialog.open}
          onClose={() => {
            setOpenGenerateLessonDialog({
              open: false,
            });
          }}
          subjectId={subjectId}
          courseId={courseId}
          unitId={unitId}
          topicId={openGenerateLessonDialog.topicId}
          lessonLevel={lessonLevel}
        />
      )}

    </>
  );
};

AddTopicAction.defaultProps = {
  lessonLevel: 1,
  subjectId: 0,
};

AddTopicAction.propTypes = {
  subjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lessonLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AddTopicAction;

import BaseDomain from '../../../../../states/domains/BaseDomain';

const AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_SELECTED_PENDING_STATUS = 'AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_SELECTED_PENDING_STATUS';
const AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_FILTER_TEXT = 'AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_FILTER_TEXT';
const AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_COURSE_ID = 'AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_COURSE_ID';
const AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_TOPIC_ID = 'AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_TOPIC_ID';
const AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_UNIT_ID = 'AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_UNIT_ID';

const initialState = {
  selectedPendingStatus: null,
  courseId: null,
  topicId: null,
  unitId: null,
  filteredText: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_SELECTED_PENDING_STATUS:
      return {
        ...state,
        selectedPendingStatus: action.payload.selectedPendingStatus,
      };
    case AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_FILTER_TEXT:
      return {
        ...state,
        filteredText: action.payload,
      };
    case AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_COURSE_ID:
      return {
        ...state,
        courseId: action.payload,
      };
    case AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_TOPIC_ID:
      return {
        ...state,
        topicId: action.payload,
      };
    case AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_UNIT_ID:
      return {
        ...state,
        unitId: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'authoredLessonsSearchCriteriaDomain';
/**
 * Domain class for Authored Lessons
 *
 * @export
 * @class Authored Lesson Domain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update filter text
   * @param filteredText
   */
  async updateFilterText(filteredText) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_FILTER_TEXT,
      payload: filteredText,
    });
  }

  /**
   * Update pending flag
   */
  async updateAuthoredLessonPendingStatus(selectedPendingStatus) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_SELECTED_PENDING_STATUS,
      payload: { selectedPendingStatus },
    });
  }

  /**
     * Update Course Id
     * @param courseId
     */
  async updateCourseId(courseId) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_COURSE_ID,
      payload: courseId,
    });
  }

  async updateTopicId(topicId) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_TOPIC_ID,
      payload: topicId,
    });
  }

  async updateUnitId(unitId) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_CRITERIAUPDATE_UNIT_ID,
      payload: unitId,
    });
  }
}

import { useState, useEffect } from 'react';
import {
  Box,
  Fade,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import { loginWithOidc } from '../../../services/LoginService';
import { usePrepareData } from '../LoginHooks';

const loadingMessage = 'Loading, please wait';
const errorMessage = 'An error occurred. Please contact your admin.';
const redirectStateString = '#state=redirectTo%3d';

export default function LoginRethinkSignInOidc() {
  const [showError, setShowError] = useState(false);
  const { hash } = useLocation();
  const {
    prepareData,
  } = usePrepareData();

  useEffect(() => {
    const login = async () => {
      const values = hash.split('code=');
      if (values && values.length >= 2) {
        const authorizationCode = values[1];
        const result = await loginWithOidc(authorizationCode);
        let redirectTo = null;
        const stateData = values[0];
        if (stateData.startsWith(redirectStateString)) {
          redirectTo = values[0].replace(redirectStateString, '').slice(0, -1).replaceAll('%2f', '/');
        }
        prepareData(result, redirectTo);
      } else {
        setShowError(true);
      }
    };

    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box display='flex' flexDirection='column' width='100vw' height='100vh'>
      <Box mx='auto' mt='120px'>
        <img src={VizzleLogo} alt='Vizzle Logo' width='250px' />
      </Box>
      {showError ? (
        <Fade timeout={1500} in>
          <Box display='flex' flexDirection='column' mt='80px' mx='auto'>
            <Typography variant='h5' gutterBottom>
              {errorMessage}
            </Typography>
          </Box>
        </Fade>
      ) : (
        <Box mt='50px' mx='auto'>
          <Box width='100px' height='100px' mx='auto'>
            <PacmanLoader size={50} color='#3b94d1' css={{ margin: 'auto' }} />
          </Box>
          <Box mt='20px'>
            <Typography variant='h5' gutterBottom>
              {loadingMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
} from '@mui/material';
import BookCard from '../../book/BookCard';
import Matching from '../../matching/Matching';
import FillInTheBlank from '../../fitb/FillInTheBlank';
import BlankActivityCard from '../../BlankActivityCard';
import {
  CARD_MODEL,
  MATCHING_QUIZ,
  FILL_IN_THE_BLANK,
} from '../GameConstants';
import { shuffle } from '../../../../../utils/ArrayUtils';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import './GameInteractionPopup.scss';

const GameInteractionPopup = ({
  open,
  onClose,
  landingInteraction,
}) => {
  const handleOnCorrectAnswerSelected = () => {
    ObjectUtils.setTimeout(() => {
      onClose();
    }, 2000);
  };

  const getComponentByInteractionType = () => {
    if (CARD_MODEL === landingInteraction.type) {
      return (
        <BookCard
          className='book-card-popup'
          layoutConfig={[{
            text: landingInteraction.questionCard.text,
            image: landingInteraction.questionCard.image,
            video: landingInteraction.questionCard.video,
            audio: landingInteraction.questionCard.audio,
          }]}
        />
      );
    } else if (MATCHING_QUIZ === landingInteraction.type) {
      const responseCards = landingInteraction.answerCards.map((card) => ({
        ...card,
        correctAnswer: landingInteraction.questionCard.key === card.answerKey,
      }));
      const shuffledResponseCards = shuffle(responseCards);
      return (
        <Matching
          promptCards={[landingInteraction.questionCard]}
          responseCards={shuffledResponseCards}
          pageIndex={99999}
          onCorrectAnswerSelected={handleOnCorrectAnswerSelected}
        />
      );
    } else if (FILL_IN_THE_BLANK === landingInteraction.type) {
      return (
        <FillInTheBlank
          question={landingInteraction.question}
          answers={landingInteraction.answers}
          onFinish={handleOnCorrectAnswerSelected}
        />
      );
    }

    return <BlankActivityCard />;
  };

  return (open ? (
    <Dialog
      fullWidth
      maxWidth={MATCHING_QUIZ === landingInteraction.type ? 'xl' : 'md'}
      open={open}
      PaperProps={{ sx: { height: '100%' } }}
      className='game-interaction-popup'
      disableEscapeKeyDown
      data-test='game-interaction-popup'
    >
      <DialogContent style={{ height: 'inherit' }} className='content'>
        <Box className='close-icon' onClick={onClose} data-test='close-icon'>
          <CloseIcon />
        </Box>
        <Box className='content-component' data-test='content-component'>
          {getComponentByInteractionType()}
        </Box>
      </DialogContent>
    </Dialog>
  ) : '');
};

GameInteractionPopup.defaultProps = {
  open: false,
  onClose: () => { },
  landingInteraction: null,
};

GameInteractionPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  landingInteraction: PropTypes.shape({
    type: PropTypes.string,
    questionCard: PropTypes.object,
    answerCards: PropTypes.array,
    question: PropTypes.shape({
      question: PropTypes.string,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default GameInteractionPopup;

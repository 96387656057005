import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './StudentCard.scss';

const StudentCard = ({ columnIndex, rowIndex, data, style, onAdd, columnCount }) => {
  const renderStudent = useMemo(() => () => {
    const index = (columnCount * rowIndex) + columnIndex;
    const student = data[index];
    if (student) {
      return (
        <Box data-test={`assign-student-student-card-${student.attributes.userName}`} className='existing-student-item' style={style} onClick={() => onAdd(student)}>
          <Box display='flex' flexDirection='row'>
            <Box data-test={`assign-student-student-card-${student.attributes.userName}-add-student-action`} className='add-student-item-icon-box'>
              <AddCircleIcon className='add-student-item-icon' />
            </Box>
            <Box className='search-existing-student-box'>
              <div className='search-existing-student-title' data-private>
                {`${student.attributes.firstName} ${student.attributes.lastName}`}
              </div>
              <Typography gutterBottom variant='subtitle1' component='p' className='search-existing-student-subtitle' data-private>
                {student.attributes.userName}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnIndex, rowIndex, data]);
  return renderStudent();
};

StudentCard.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
};
export default StudentCard;

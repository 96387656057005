import { reviewNotificationViewed, updateLessonReviewStatus } from '../../../../../../../services/AnswerFreeFormService';
import Logger from '../../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../../hooks/RemoteServiceHooks';
import { useGetCurrentStudent } from '../../../../../../hooks/StudentHooks';
import { useLessonPlay } from '../../../../../../lessonplay/context/LessonPlayContextProvider';
import { REVIEW_FINISHED_ID, REVIEW_STATUSES, UNDER_REVISION_ID } from '../../../../../components/tracking/actions/LessonTrackingConstants';

export const useUpdateReviewStatus = ({ setOpen, onStatusUpdateSucccess, openSnackbar, onSnackbarClose }) => {
  const currentStudent = useGetCurrentStudent();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { lessonPlayDomain } = useLessonPlay();

  const updateReviewStatus = async ({
    answerLessonData,
  }) => {
    await callRemoteServiceWrapper(async () => {
      try {
        const { id, reviewStatus, lessonId, originalStudentHistoryId } = answerLessonData;
        const dataToSave = {
          lessonId,
          reviewStatus,
          studentHistoryId: id,
          originalStudentHistoryId,
        };

        const promises = [];
        const updateLessonReviewStatusPromise = updateLessonReviewStatus(currentStudent.id, dataToSave);
        promises.push(updateLessonReviewStatusPromise);

        if ([UNDER_REVISION_ID, REVIEW_FINISHED_ID].includes(reviewStatus)) {
          promises.push(reviewNotificationViewed({ studentId: currentStudent.id }));
        }
        await Promise.all(promises);

        await onStatusUpdateSucccess(reviewStatus);
        await lessonPlayDomain.updateLesson(answerLessonData);
        const reviewStatusData = REVIEW_STATUSES.find((r) => r.id === answerLessonData.reviewStatus);
        const message = `Review status on this page is updated to ${reviewStatusData.name}.`;
        openSnackbar({
          message,
          onClose: onSnackbarClose,
          variant: 'success',
          open: true,
          autoCloseIn: 3000,
        });
        setOpen(false);
        // uiDomain.showSnackbar(true, 'success', 3000, message);
      } catch (e) {
        Logger.logWhenDebugModeIsOn(e);
      }
    });
  };

  return {
    isSaving: loading,
    updateReviewStatus,
  };
};

import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import UpdateButton from '../../../components/buttons/UpdateButton';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import LicenseFormForMultiple from './forms/LicenseFormForMultiple';
import { useUpdateMultipleLicenses, usePrepareData } from './LicenseActionHooks';
import DateUtils from '../../../../../utils/DateUtils';

const currentDatePlus30Days = DateUtils.get30DaysFromCurrentDate();

const UpdateMultipleLicensesAction = ({
  onLicensesUpdateSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const { loading, updateLicensesData } = useUpdateMultipleLicenses({
    setOpen,
    onLicensesUpdateSuccess,
  });

  const { selectedLicenseIds } = usePrepareData();
  const [isDataValid, setIsDataValid] = useState(true);

  const licenseData = {
    active: true,
    quantity: 7,
    type: 'PAID',
    expirationDate: currentDatePlus30Days,
  };

  const data = useRef(licenseData);

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleOnDataChange = (updatedData, isValid) => {
    setIsDataValid(isValid);
    data.current = updatedData;
  };

  const confirmActions = () => {
    const buttons = [
      {
        onClick: handleOnCancel,
        className: 'btn-danger margin-right',
        title: 'Cancel',
        loading,
      },
      {
        onClick: () => {
          updateLicensesData(data.current);
        },
        className: 'btn-primary action-button',
        title: 'Save',
        disabled: !isDataValid,
        loading,
      },
    ];
    return buttons;
  };

  return (
    <>
      <UpdateButton
        ariaLabel='Update Licenses'
        onClick={() => {
          setOpen(true);
        }}
        disabled={selectedLicenseIds.length < 1}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='update-license-dialog'
          onClose={handleOnCancel}
          title='Update Licenses'
          content={(
            <LicenseFormForMultiple
              licenseData={licenseData}
              onDataChange={handleOnDataChange}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

UpdateMultipleLicensesAction.defaultProps = {
  onLicensesUpdateSuccess: () => null,
};

UpdateMultipleLicensesAction.propTypes = {
  onLicensesUpdateSuccess: PropTypes.func,
};

export default UpdateMultipleLicensesAction;

/* eslint-disable react/no-danger */
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  EditorState,
  ContentState,
  convertToRaw,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './TopicUnitGuideEditor.scss';
import { FONT_FAMILY_LIST } from '../../../../../../constants/TextEditorConstants';
import TextEditor from '../../../../../../components/text/TextEditor';

const TopicUnitGuideEditor = forwardRef(({
  title,
  headerClassName,
  unitGuideSectionData,
  onChange,
  ...props
}, ref) => {
  const [state, setState] = useState({});

  const onEditorStateChange = async (editorStateChange) => {
    setState({
      editorState: editorStateChange,
    });
    const htmlText = draftToHtml(convertToRaw(state.editorState.getCurrentContent()));
    onChange(htmlText);
  };

  const updateContent = (content) => {
    const contentBlock = htmlToDraft(DOMPurify.sanitize(content));
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setState({
      editorState,
    });
  };

  useEffect(() => {
    updateContent(unitGuideSectionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    setContent: (content) => {
      updateContent(content);
    },
  }));

  return (
    <div className='topic-unit-guide-editor' {...props}>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={headerClassName}
          expandIcon={<ExpandMoreIcon />}
          data-test='topic-unit-guide-title'
        >
          <Typography><strong>{title}</strong></Typography>
        </AccordionSummary>
        <AccordionDetails data-test='topic-unit-guide-body' className='topic-unit-guide-body'>
          <Paper elevation={3} className='topic-unit-guide-editor-container' data-test='topic-unit-guide-editor-container'>
            <TextEditor
              editorState={state.editorState}
              onEditorStateChange={onEditorStateChange}
              fontFamilyList={FONT_FAMILY_LIST}
              includeInlineOption
              includeTextAlign
              showApplyButton={false}
              showRemoveTextButton={false}
              stripPastedStyles={false}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

TopicUnitGuideEditor.defaultProps = {
  title: '',
  headerClassName: '',
  unitGuideSectionData: '',
  onChange: () => null,
};

TopicUnitGuideEditor.propTypes = {
  title: PropTypes.string,
  headerClassName: PropTypes.string,
  unitGuideSectionData: PropTypes.string,
  onChange: PropTypes.func,
};

export default TopicUnitGuideEditor;

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { updateUnit } from '../../../../../../services/CourseService';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import Logger from '../../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetUnit } from '../TeacherCourseLessonsHooks';
import './UnitActiveAction.scss';

const UnitActiveAction = () => {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { courseId, unitId } = searchCriteria;
  const unit = useGetUnit(courseId, unitId);

  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const handleSaveData = async (_e, newValue) => {
    try {
      await callRemoteServiceWrapper(async () => {
        unit.active = newValue;
        await teacherCourseDomain.updateLessonCourses([...lessonCourses]);
        await updateUnit(courseId, unitId, {
          name: unit.name,
          active: newValue,
        });
      });
      await uiDomain.showSnackbar(true, 'success', 3000, `Unit status has been updated to ${newValue ? 'Active' : 'Inactive'}.`);
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return (
    <div className='unit-active-action'>
      <ToggleButtonGroup size='small' onChange={handleSaveData} value={unit.active} exclusive>
        <ToggleButton value>
          Active
        </ToggleButton>
        <ToggleButton value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default UnitActiveAction;

export const layoutDropdownValues = [
  {
    id: '1',
    name: '1 Card',
    cardValue: 1,
    className: 'flexi-matching-one-card',
  },
  {
    id: '3-1-2',
    name: '3 Cards (1, 2)',
    cardValue: 3,
    className: 'flexi-matching-three-cards-1',
  },
  {
    id: '3-2-1',
    name: '3 Cards (2, 1)',
    cardValue: 3,
    className: 'flexi-matching-three-cards-2',
  },
  {
    id: '4',
    name: '4 Cards',
    cardValue: 4,
    className: 'flexi-matching-four-cards',
  },
];

import FlatLessonCardListDetail from '../../../../../components/vizzle/cardlistdetail/lesson/FlatLessonCardListDetail';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { createLessonCardAction } from './actions/LessonCardActionBuilder';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/LessonCardDetailActionBuilder';
import LessonsGroupPlayFilter from './LessonsGroupPlayFilter';
import { useGetLessonGroupPlay } from './LessonsGroupPlayHooks';
import './LessonsGroupPlay.scss';

const LessonsGroupPlay = () => {
  const { userDomain } = useDomain();
  const { user, userProfile, terminologies } = userDomain.domainData;

  const filteredLessonData = useGetLessonGroupPlay();

  return (
    <div className='lessons-group-play'>
      <LessonsGroupPlayFilter />
      <FlatLessonCardListDetail
        lessonData={filteredLessonData}
        noLessonMessage='No Activities'
        currentUser={user}
        currentUserProfle={userProfile}
        createCardAction={(lesson) => createLessonCardAction(lesson, user, userProfile, terminologies)}
        createCardDetailAction={(lesson, currentUser, rowIndex, closeFunction) => createLessonCardDetailAction(lesson, currentUser, userProfile, closeFunction, terminologies)}
        createLessonDetailAction={(lesson) => createLessonCardDetailonPlanelAction(lesson, user, userProfile, terminologies)}
      />
    </div>
  );
};

export default LessonsGroupPlay;

import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import { dateFormatMMddyyyy } from '../../../../../AppConstants';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { getEmailLessonPlanForStudent } from '../../../../../services/LessonService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useGetCurrentStudent } from '../../../../hooks/StudentHooks';
import './EmailLessonPlanAction.scss';

const minDate = new Date();
minDate.setHours(0);
minDate.setMinutes(0);
minDate.setSeconds(0);
minDate.setMilliseconds(0);

const dateFormat = dateFormatMMddyyyy.toUpperCase();
export default function EmailLessonPlanAction() {
  const [selectedDates, setSelectedDates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();
  const currentStudent = useGetCurrentStudent();

  const handleOnClick = () => {
    setOpenDialog(true);
  };

  const handleOnDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (openDialog) {
      setSelectedDates([]);
    }
  }, [openDialog]);

  const handleOnConfirm = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const start = selectedDates[0].format(dateFormat);
        const end = selectedDates[1].format(dateFormat);
        await getEmailLessonPlanForStudent(currentStudent.id, start, end);
        handleOnDialogClose();
        await uiDomain.showSnackbar(
          true,
          'success',
          10000,
          <div>
            The assignment calendar for {currentStudent.firstName} {currentStudent.lastName} will be emailed to you in the next 5-10 mins.
            <br />
            Any assignments made today will not show up in the email.
          </div>,
        );
        setOpenDialog(false);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  return (
    <>
      <Button
        variant='contained'
        className='btn-primary'
        onClick={handleOnClick}
        data-test='email-lesson-plan-action'
      >
        Email Assignment Calendar
      </Button>
      {openDialog && (
        <CustomDialog
          openDialog={openDialog}
          className='import-licenses-action-dialog'
          onClose={handleOnDialogClose}
          title='Email Assignment Calendar'
          content={(
            <div>
              <Calendar
                range
                animation
                value={selectedDates}
                format={dateFormat}
                minDate={minDate}
                className='email-lesson-plan-calendar'
                onChange={(dates) => {
                  setSelectedDates(dates);
                }}
                data-test='date-input-picker-component'
              />
            </div>
          )}
          displayCloseIcon
          actions={[{
            onClick: handleOnConfirm,
            className: 'btn-primary action-button',
            title: 'Ok',
            loading,
            disabled: loading || !selectedDates || selectedDates.length < 2,
          },
          ]}
        />
      )}
    </>
  );
}

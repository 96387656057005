import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import AssignedStudentCourses from './AssignedStudentCourses';
import TeacherAssignedStudentCourseFilter from './TeacherAssignedStudentCourseFilter';
import TeacherAssignedStudentCoursePanelTab from './TeacherAssignedStudentCoursePanelTab';
import './TeacherAssignedStudentCoursePanel.scss';
import { FULL_SORTED_SUBJECT_CONSTANTS } from '../../../constants/LessonConstant';

const TeacherAssignedStudentCoursePanel = forwardRef(({
  selectedDates,
  onSelectedDatesApply,
  onStartLoading,
  subjectId,
  onDataLoad,
  assignedStudentCourses,
  filterCriteriaAssignedStudentCourses,
  loading,
  onFilterCriteriaAssignedStudentsCoursesUpdate,
  canArchive,
  titleChildren,
  currentStudent,
  onTableActionSuccess,
  canLessonDeleteFunction,
}, ref) => {
  const assignStudentCourseRef = useRef(null);

  useImperativeHandle(ref, () => ({
    refreshData: () => {
      if (assignStudentCourseRef.current) {
        assignStudentCourseRef.current.refreshData();
      }
    },
  }));

  const subject = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === subjectId);

  return (
    <div className='teacher-assigned-student-courses-panel'>
      <div className='blue-gradient-small' />
      <div className='assignment-calendar-title-container'>
        <Typography className='assignment-calendar-title' variant='h2'>
          {subject && subject.shortName} Assignment Calendar for
        </Typography>

        <TeacherAssignedStudentCourseFilter
          assignedStudentCourses={assignedStudentCourses}
          filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
          onFilterCriteriaAssignedStudentsCoursesUpdate={onFilterCriteriaAssignedStudentsCoursesUpdate}
          currentStudent={currentStudent}
          subjectId={subjectId}
        />
        {titleChildren}
      </div>

      <div className='teacher-assigned-student-courses-container'>
        <TeacherAssignedStudentCoursePanelTab
          selectedDates={selectedDates}
          onSelectedDatesApply={onSelectedDatesApply}
          onStartLoading={onStartLoading}
          subjectId={subjectId}
          onDataLoad={onDataLoad}
          assignedStudentCourses={assignedStudentCourses}
          filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
        />
        <AssignedStudentCourses
          ref={assignStudentCourseRef}
          loading={loading}
          selectedDates={selectedDates}
          onStartLoading={onStartLoading}
          subjectId={subjectId}
          onDataLoad={onDataLoad}
          assignedStudentCourses={assignedStudentCourses}
          filterCriteriaAssignedStudentCourses={filterCriteriaAssignedStudentCourses}
          canArchive={canArchive}
          onTableActionSuccess={onTableActionSuccess}
          canLessonDeleteFunction={canLessonDeleteFunction}
        />
      </div>
    </div>
  );
});

export default TeacherAssignedStudentCoursePanel;

TeacherAssignedStudentCoursePanel.defaultProps = {
  selectedDates: {},
  onSelectedDatesApply: () => null,
  onStartLoading: () => null,
  subjectId: null,
  onDataLoad: () => null,
  assignedStudentCourses: [],
  filterCriteriaAssignedStudentCourses: {},
  loading: false,
  onFilterCriteriaAssignedStudentsCoursesUpdate: () => null,
  canArchive: true,
  titleChildren: null,
  currentStudent: null,
  onTableActionSuccess: () => null,
  canLessonDeleteFunction: () => true,
};

TeacherAssignedStudentCoursePanel.propTypes = {
  selectedDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onSelectedDatesApply: PropTypes.func,
  onStartLoading: PropTypes.func,
  subjectId: PropTypes.number,
  onDataLoad: PropTypes.func,
  assignedStudentCourses: PropTypes.arrayOf(PropTypes.any),
  filterCriteriaAssignedStudentCourses: PropTypes.any,
  loading: PropTypes.bool,
  onFilterCriteriaAssignedStudentsCoursesUpdate: PropTypes.func,
  canArchive: PropTypes.bool,
  titleChildren: PropTypes.node,
  currentStudent: PropTypes.any,
  onTableActionSuccess: PropTypes.func,
  canLessonDeleteFunction: PropTypes.func,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import ConfirmationDialog from '../../../../../../components/dialog/ConfirmationDialog';
import { useGetRemoteServiceWrapper } from '../../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { archiveUnarchiveAssignCoursesLesson } from '../../../../../../services/CourseService';
import Logger from '../../../../../../utils/Logger';

const DeleteAllAssignmentAction = ({
  lesson,
  subjectId,
  student,
  onDeleteSuccess,
  onClick,
}) => {
  const [openDialog, setOpenDialog] = useState();

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const handleOnConfirm = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        await archiveUnarchiveAssignCoursesLesson(student.id, -1, lesson.id, subjectId, true);
        await onDeleteSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Activity deleted for ${student.firstName} ${student.lastName}`);
        setOpenDialog(false);
      });
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    }
  };

  if (lesson.meta.archived) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={() => { onClick(); setOpenDialog(true); }}>Delete All Assignments</MenuItem>
      {openDialog ? (
        <ConfirmationDialog
          open={openDialog}
          title='Delete All Assignments'
          contentText={`Delete all assignments for this activity for ${student.firstName} ${student.lastName}`}
          confirmLabel='Ok'
          cancelLabel='Cancel'
          onConfirm={handleOnConfirm}
          onCancel={() => { setOpenDialog(false); }}
        />
      ) : null}

    </>
  );
};

DeleteAllAssignmentAction.defaultProps = {
  lesson: {},
  onDeleteSuccess: () => null,
  onClick: () => null,
};

DeleteAllAssignmentAction.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }),
  subjectId: PropTypes.number.isRequired,
  student: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onDeleteSuccess: PropTypes.func,
  onClick: PropTypes.func,
};

export default DeleteAllAssignmentAction;

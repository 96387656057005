import PropTypes from 'prop-types';
import AssignLessonActionWithDateSelectionV2 from '../../../../../lessons/lessonactions/lesson-multi-selection-actions/AssignLessonActionWithDateSelectionV2';
import { useDomain } from '../../../../../../states/AppDomainProvider';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';
import { useGetTopicDropdownList, useGetUnitDropdownList } from '../../../TeacherCourseSearchBarHooks';
import { useGetAssignedStudentCourses } from '../../../../../lessons/assigned-student-courses/AssignedStudentCoursesHooks';
import { useHandleTeacherCourseAssigment } from '../TeacherCourseLessonsHooks';
import ChurnZeroService from '../../../../../../services/ChurnZeroService';
import { CHURNZERO_CONSTANT } from '../../../../../../constants/ChurnZeroConstant';

export default function TeacherCourseAssignLessonsAction({
  lessons,
  selectedLessonIds,
  selectedDates,
}) {
  const { teacherCourseDomain, teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { lessonCourses } = teacherCourseDomain.domainData;
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const course = ((lessonCourses || []).find((l) => l.id === searchCriteria.courseId) || {}).name;

  const unitList = useGetUnitDropdownList(searchCriteria.courseId);
  const unit = (unitList.find((l) => l.id === searchCriteria.unitId) || {}).name;

  const topicList = useGetTopicDropdownList(searchCriteria.courseId, searchCriteria.unitId);
  const topic = (topicList.find((l) => l.id === searchCriteria.topicId) || {}).name;

  const tags = `${course}, ${unit}, ${topic}, Level ${searchCriteria.lessonLevel}`;

  const propsArg = useHandleTeacherCourseAssigment();

  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading: null,
    subjectId: propsArg.subjectId,
    onDataLoad: propsArg.onDataLoad,
    assignedStudentCourses: propsArg.assignedStudentCourses,
    filterCriteriaAssignedStudentCourses: propsArg.filterCriteriaAssignedStudentCourses,
  });

  ChurnZeroService.trackEvent(CHURNZERO_CONSTANT.USAGE, 'Assign Activity - Course', user);

  return (
    <AssignLessonActionWithDateSelectionV2
      lessons={lessons}
      selectedLessonIds={selectedLessonIds}
      showAddToMyList
      reloadDataFunc={async () => {
        getAssignedStudentData(selectedDates);
      }}
      tagNameForAutoSaving={[tags]}
      objectiveType='COURSES'
    />
  );
}

TeacherCourseAssignLessonsAction.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  selectedDates: {},
};

TeacherCourseAssignLessonsAction.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  selectedDates: PropTypes.object,
};

import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import ThumbnailCard from '../common/ThumbnailCard';
import { getPageStyle } from '../common/LessonPageUtil';
import './CategorizingCardThumbnail.scss';

const DISPLAY_LIMIT = 2;
export default function CategorizingCardThumbnail({ instructionConfig, promptCardsConfig, responseCardsConfig, isSmallThumbnail }) {
  const createCard = (className, cards, isImageIncluded) => (
    cards.map((c) => {
      const caredId = uuidv1();
      return (
        <ThumbnailCard
          id={caredId}
          key={caredId}
          image={c.image}
          text={c.text}
          isSmallThumbnail={isSmallThumbnail}
          className={className}
          style={getPageStyle(c, true)}
          isImageIncluded={isImageIncluded}
        />
      );
    })
  );

  const getCards = (cardConfig, className, cardContinerClassName, isImageIncluded) => (
    cardConfig.length > DISPLAY_LIMIT ? (
      <div className='display-partial'>
        <div className={cardContinerClassName}>
          {createCard(className, cardConfig.slice(0, DISPLAY_LIMIT), isImageIncluded)}
        </div>
        <div className='display-partial-text'>
          {cardConfig.length - DISPLAY_LIMIT} more card{cardConfig.length - DISPLAY_LIMIT > 1 ? 's' : ''}
        </div>
      </div>
    ) : createCard(className, cardConfig, isImageIncluded)
  );

  return (
    <div className='catorizing-card-thumbnail'>
      <div className='catorizing-card-responses-container'>
        {!instructionConfig || (!instructionConfig.text && !instructionConfig.image)
          ? (
            <div className='instruction-thumbnail instruction'>
              Instruction
            </div>
          )
          : (
            <ThumbnailCard
              id={uuidv1()}
              image={instructionConfig.image}
              text={instructionConfig.text}
              isSmallThumbnail={isSmallThumbnail}
              className='instruction-thumbnail'
            />
          )}
        <div className={`catorizing-card-responses ${isSmallThumbnail ? 'small' : ''}`}>
          {getCards(responseCardsConfig, 'catorizing-card-response', 'display-partial-response')}
        </div>
      </div>
      <div className='catorizing-card-prompts'>
        {getCards(promptCardsConfig, 'catorizing-card-prompt', 'display-partial-prompt', false)}
      </div>
    </div>
  );
}

CategorizingCardThumbnail.defaultProps = {
  instructionConfig: {},
  promptCardsConfig: [],
  responseCardsConfig: [],
  isSmallThumbnail: false,
};

CategorizingCardThumbnail.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  instructionConfig: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  }),
  promptCardsConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  responseCardsConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ExtensionActivitiesViewFile.scss';
import DownloadFileAction from './actions/DownloadFileAction';
import PrintFileAction from './actions/PrintFileAction';
import LoadingSpinner from '../../../../../../../components/loaders/LoadingSpinner';

export default function ExtensionActivitiesViewFile({
  activity,
}) {
  const [expanded, setExpanded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      className='extension-activity-view-file'
      onChange={() => {
        setExpanded((previousState) => {
          const newState = !previousState;
          if (newState) {
            setShowLoading(true);
          }
          return newState;
        });
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={activity.name}
        className='title-container'
      >
        <div className='title' data-test={`extension-activity-title-${activity.id}`}>
          <Typography>{activity.name}</Typography>
        </div>
        <div className='actions' data-test={`extension-activity-title-actions-${activity.id}`}>
          <PrintFileAction activity={activity} />
          <DownloadFileAction activity={activity} />
        </div>
      </AccordionSummary>
      <AccordionDetails className='detail-body'>
        {showLoading && (
          <div className='loading-container'>
            <LoadingSpinner message={`Loading ${activity.name}`} />
          </div>
        )}
        {expanded && (
          <object className='file-container' data={activity.url} type='application/pdf'>
            <iframe
              data-test={`extension-activity-iframe-container-${activity.id}`}
              onLoad={() => {
                setShowLoading(false);
              }}
              title='Extension activity'
              src={activity.url}
            />
          </object>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

ExtensionActivitiesViewFile.propTypes = {
  activity: PropTypes.object.isRequired,
};

/* eslint-disable no-unused-vars */
import vizzleAxios from './service';

export const getFeedbackChatData = async ({ studentHistoryId, teacherId, studentId, lessonId }) => {
  const url = `/feedbackChat/${studentHistoryId}`;
  const result = await vizzleAxios.get(url, {
    params: {
      teacherId,
      studentId,
      lessonId,
    },
  });
  return result.data;
};

export const saveFeedbackChatData = async (payload) => {
  const url = '/feedbackChat';
  const result = await vizzleAxios.post(url, payload);
  return result;
};

export const saveFeedbackViewed = async ({ studentHistoryId, studentId, lessonId }) => {
  const url = `/feedbackChat/${studentHistoryId}`;
  const result = await vizzleAxios.put(url, {
    viewed: true,
    studentId,
    lessonId,
  });
  return result;
};

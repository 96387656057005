import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { Card, CardMedia } from '@mui/material';
import MediaPlayer from '../../../../components/vizzle/media/MediaPlayer';
import './FillInTheBlankPageComponent.scss';
import FillInTheBlankActivityWithTextStyle from './FillInTheBlankActivityWithTextStyle';

/**
 * Lesson activity for Book
 */
const FillInTheBlankPageComponent = ({
  cardConfig,
}) => {
  const componentId = useMemo(() => uuidv1(), []);

  const {
    card,
    fitbList,
  } = cardConfig;

  return (
    <div
      key={componentId}
      className='fitb-page-component card-padding vizzle-fitb-page-activity'
    >
      <Card
        id={componentId}
        key={componentId}
        className='fitb-page-activity-component'
      >
        {
          card.image && (
            <CardMedia
              className='fitb-page-activity-image'
              image={card.image}
              title='Fill in the Blank'
            />
          )
        }
        {
          (card.audio || card.video)
          && (
            <MediaPlayer
              audioUrl={card.audio}
              videoUrl={card.video}
              parentElementId={componentId}
              className={`media-component vizzle-fitb-page-${componentId}-media-btn`}
            />
          )
        }
        {fitbList && fitbList.length > 0 && (
          <div className='fitb-list-container'>
            {
              fitbList.map((fitb, index) => (
                <FillInTheBlankActivityWithTextStyle
                  className='fillin-the-blank-page-line'
                  key={`fillin-the-blank-entry-${index}`}
                  question={fitb.question}
                  answers={fitb.answers}
                  autoFocus={false}
                />
              ))
            }

          </div>
        )}
      </Card>
    </div>
  );
};

export default FillInTheBlankPageComponent;

FillInTheBlankPageComponent.defaultProps = {
  cardConfig: {
    card: {
      audio: null,
      image: null,
      video: null,
    },
    fitbList: [],
  },
};

FillInTheBlankPageComponent.propTypes = {
  cardConfig: PropTypes.shape({
    card: PropTypes.shape({
      audio: PropTypes.string,
      image: PropTypes.string,
      video: PropTypes.string,
    }),
    fitbList: PropTypes.arrayOf(PropTypes.shape({
      answers: PropTypes.array,
      question: PropTypes.shape({
        preBlank: PropTypes.any,
        postBlank: PropTypes.any,
      }),
    })),
  }),
};

import { v1 as uuidv1 } from 'uuid';
import vizzleAxios from './service';
import ObjectUtils from '../utils/ObjectUtils';

export const searchTeacherCourseLessons = async (searchCriteria = {}) => {
  const timestamp = Date.now().toString();
  let url = `/courses/lessons?nocache=${timestamp}`;
  url = ObjectUtils.appendQueryString(searchCriteria.courseId, 'filter[courseId]', url);
  url = ObjectUtils.appendQueryString(searchCriteria.unitId, 'filter[unitId]', url);
  url = ObjectUtils.appendQueryString(searchCriteria.topicId, 'filter[topicId]', url);
  url = ObjectUtils.appendQueryString(searchCriteria.lessonLevel, 'filter[level]', url);
  url = ObjectUtils.appendQueryString(searchCriteria.autoGeneratedOnly, 'filter[autoGeneratedOnly]', url);
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getTeacherCourseStandards = async (searchCriteria = {}) => {
  const { courseId, unitId } = searchCriteria;
  const url = `/courses/${courseId}/unit/${unitId}/standards`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getLessonCourses = async (primaryOnly) => {
  let url = '/courses';
  if (primaryOnly !== undefined) {
    url = `${url}?primary_only=${primaryOnly}`;
  }
  const result = await vizzleAxios.get(url);
  return result;
};

export const updateLessonCourse = async ({
  courseId,
  active,
}) => {
  const url = `/courses/${courseId}`;
  const result = await vizzleAxios.put(url, {
    active,
  });
  return result;
};

export const getAssignmentsStudentCourses = async (selectedDates, subject) => {
  let queryParam = `days=${selectedDates.dates.join(',')}`;

  if (selectedDates.isDateRange) {
    const [startDate, endDate] = selectedDates.dates;
    if (startDate && endDate) {
      queryParam = `startDate=${startDate}&endDate=${endDate}`;
    }
  }

  if (subject) {
    queryParam = `${queryParam}&subject=${subject}`;
  }

  const url = `/users/assignments/daily?${queryParam}`;
  const result = await vizzleAxios.get(url);
  const { data } = result;
  return data;
};

export const archiveUnarchiveAssignCoursesLesson = async (studentId, courseAssignmentId, lessonId, subjectId, archivedFlag) => {
  const operation = archivedFlag ? 'archive' : 'unarchive';
  const url = `users/assignments/${studentId}/${courseAssignmentId}/lessons/${lessonId}/${subjectId}?operation=${operation}`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const deleteSelectedLessonAssignment = async (studentId, subjectId, lessonIds) => {
  const url = `/users/assignments/${studentId}/lessons/${subjectId}?lessonIds=${lessonIds.join(',')}`;
  const result = await vizzleAxios.delete(url);
  return result;
};

export const removeLessonAssignCoursesLesson = async (studentId, courseAssignmentId, lessonId, subjectId) => {
  const url = `users/assignments/${studentId}/${courseAssignmentId}/lessons/${lessonId}/${subjectId}?operation=remove`;
  const result = await vizzleAxios.put(url);
  return result;
};

export const addNewUnit = async (courseId, unitName) => {
  const url = `/courses/${courseId}/unit`;
  const result = await vizzleAxios.post(url, {
    name: unitName,
  });
  const { data } = result;
  return data;
};

export const addNewTopics = async (courseId, unitId, topics) => {
  const url = `/courses/${courseId}/unit/${unitId}/topics`;
  const payload = topics.map((t) => ({
    name: t,
  }));
  const result = await vizzleAxios.post(url, { topics: payload });
  return result;
};

export const addNewTopic = async (courseId, unitId, topicString) => {
  const url = `/courses/${courseId}/unit/${unitId}/topic`;
  const result = await vizzleAxios.post(url, { name: topicString });
  return result.data;
};

export const updateUnit = async (courseId, unitId, data) => {
  const url = `/courses/${courseId}/unit/${unitId}`;
  const result = await vizzleAxios.put(url, data);
  return result;
};

export const updateTopic = async (courseId, unitId, topicId, topicName) => {
  const url = `/courses/${courseId}/unit/${unitId}/topic/${topicId}`;
  const result = await vizzleAxios.put(url, {
    name: topicName,
  });
  return result;
};

export const uploadLessonPlans = async ({
  file,
  courseId,
  unitId,
  topicId,
  topicName,
}) => {
  if (!file) {
    return Promise.resolve();
  }

  const data = new FormData();
  data.append('file', file);

  const url = `${process.env.REACT_APP_MEDIA_API_URL}/media/upload/key?key=lesson_plans/${uuidv1()}.pdf`;

  const result = await vizzleAxios({
    method: 'PUT',
    url,
    data,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  const resultData = result.data.data;
  const urlForUpdate = `/courses/${courseId}/unit/${unitId}/topic/${topicId}`;

  const fileUrl = resultData.attributes.fileName;
  await vizzleAxios.put(urlForUpdate, {
    name: topicName,
    guideLink: fileUrl,
  });

  return fileUrl;
};

export const updateLessonPlan = async ({
  courseId,
  unitId,
  topicId,
  guideLink,
}) => {
  const url = `/courses/${courseId}/unit/${unitId}/topic/${topicId}/guide`;
  await vizzleAxios.put(url, { guideLink });
};

export const orderLessons = async (lessonOrders, topicId) => {
  const url = `/courses/lessons/order/${topicId}`;
  await vizzleAxios.put(url, lessonOrders);
};

export const getTopicTemplate = async () => {
  const url = '/topictemplate';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const saveGenerateLessons = async (payload) => {
  const url = '/lessons/generate';
  const result = await vizzleAxios.post(url, payload);
  return result.data;
};

// @deprecated
// export const getLessonGenerationLevel = async (courseId, unitId, topicId) => {
//   const url = `/courses/${courseId}/unit/${unitId}/topic/${topicId}/lessons/info`;
//   const result = await vizzleAxios.get(url);
//   return result.data;
// };

export const getNumberOfLessonsCanBeGenerated = async (topicId, lessonLevel) => {
  const url = `/topics/${topicId}/generate/${lessonLevel}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateGenerateUnitGuideFromTopics = async ({ courseId, unitId, generateUnitGuideFromTopics }) => {
  const url = `/courses/${courseId}/unit/${unitId}/guide/flag`;
  const result = await vizzleAxios.put(url, {
    generateUnitGuideFromTopics,
  });
  return result.data;
};

export const getUnitGuide = async ({ courseId, unitId }) => {
  const url = `/courses/${courseId}/unit/${unitId}/guide`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const saveUnitGuide = async (topicId, unitGuideData) => {
  const url = `/topicguide/${topicId}`;
  const result = await vizzleAxios.put(url, unitGuideData);
  return result.data;
};

import { useEffect } from 'react';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { getStudentLessons } from '../../../../services/LessonService';
import { createLessonObject, sortLessonAttributes } from '../../../../utils/LessonsMapping';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { getAllLessonTagsForStudent } from '../../../../services/LessonTagService';
import { FULL_SORTED_SUBJECT_CONSTANTS, SUBJECT_CONSTANTS } from '../../../../constants/LessonConstant';
import { useGetCurrentStudent } from '../../../hooks/StudentHooks';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import Logger from '../../../../utils/Logger';
import { flatten } from '../../../../utils/ArrayUtils';
import { USER_COMPONENT_SKILL_SUITE } from '../../../../utils/User';

export const findMtssLesson = (lesson, allLessonsFromProfile) => {
  const matchedLesson = allLessonsFromProfile.find((al) => al.id.toString() === lesson.id.toString() && al.mtss > 0);
  return matchedLesson;
};

export const useGetAllLessonsFromProfile = () => {
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;
  let allLessonsFromProfile = [];
  if (selectedStudentProfile.subjects) {
    allLessonsFromProfile = flatten(selectedStudentProfile.subjects.map((s) => s.lessons));
  }
  return allLessonsFromProfile;
};

export const useFetchStudentLesson = (currentStudent) => {
  const { studentsDomain, uiDomain, userDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { user, userProfile } = userDomain.domainData;
  const { components } = userProfile;

  const { myStudentDomain } = useStudentContext();

  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const fetchStudentLesson = async (showLoader, selectedLessonIds = []) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const defaultSubject = components.length === 1 && String(components[0].identifier) === USER_COMPONENT_SKILL_SUITE ? SUBJECT_CONSTANTS.life.subjectId : SUBJECT_CONSTANTS.english.subjectId;
        const { filter: { selectedSubject } } = myStudentDomain.domainData.lessonData;
        const subjectToUse = selectedSubject || defaultSubject;

        if (showLoader) {
          const sn = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === subjectToUse);

          uiDomain.showLoader(
            <div>
              Getting {currentStudent.firstName} {currentStudent.lastName}&apos;s activities with <br />
              {sn.name}
            </div>,
            LOADER_TYPE.HASH_LOADER,
          );
        }

        const lessonDataPromise = getStudentLessons(currentSelectedStudentId, subjectToUse);
        const lessonTagsPromise = getAllLessonTagsForStudent(currentSelectedStudentId);
        const [lessonData, lessonTags] = await Promise.all([lessonDataPromise, lessonTagsPromise]);

        if (lessonData.data && lessonData.data.length > 0) {
          // student lessons does not need front-end sorting
          const subjectListWithLessons = [{
            lessons: sortLessonAttributes(lessonData.data, 'lastAssignedDate', 'meta')
              .map((lesson) => createLessonObject(lesson, user)),
          }];

          await myStudentDomain.updateLessonsAndTags(
            subjectListWithLessons,
            lessonData.included,
            lessonTags.data,
            { selectedSubject: subjectToUse, isExpiredAssignment: false },
            selectedLessonIds,
          );
        } else {
          await myStudentDomain.updateLessons([], lessonData.included, { selectedSubject: subjectToUse, isExpiredAssignment: false });
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    } finally {
      if (showLoader) {
        uiDomain.hideLoader();
      }
    }
  };

  return {
    loading,
    fetchStudentLesson,
  };
};

export const usePrepareStudentLesson = (currentStudent) => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();

  const {
    fetchStudentLesson,
    loading,
  } = useFetchStudentLesson(currentStudent);

  useEffect(() => {
    const loadData = async () => {
      await fetchStudentLesson(true);
      const { filterCriteriaAssignedStudentCourses } = myStudentDomain.domainData;
      await myStudentDomain.updateFilterCriteriaAssignedStudentsCourses({
        ...filterCriteriaAssignedStudentCourses,
        studentIds: [currentSelectedStudentId],
      });
    };

    if (currentStudent) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStudentId]);

  const { selectedSubject } = myStudentDomain.domainData.lessonData.filter;
  const sn = FULL_SORTED_SUBJECT_CONSTANTS.find((s) => s.subjectId === selectedSubject);
  const currentSelectedSubjectName = sn ? sn.name : '';

  return {
    loading,
    fetchStudentLesson,
    currentSelectedSubjectName,
  };
};

export const useGetActiveGoals = () => {
  const { myStudentDomain } = useStudentContext();
  const { lessonTags } = myStudentDomain.domainData;
  const activeGoals = lessonTags.filter((tag) => (!tag.meta.archived));
  return activeGoals;
};

export const useGetFilterOptions = ({
  activeGoals,
}) => {
  const { myStudentDomain } = useStudentContext();
  const { lessonTags } = myStudentDomain.domainData;

  const goalsOptions = lessonTags ? activeGoals.map((tag) => ({ key: tag.id, name: tag.attributes.name, value: tag.id })) : [];
  if (activeGoals && activeGoals.length > 0) {
    goalsOptions.unshift({
      key: 'no-tag',
      name: 'Activities without Goals/Objectives',
      value: 'empty-tag',
    });
  }
  const multiSelectFilterConfig = [
    {
      label: 'Goals/Objectives',
      subTitle: 'Filter Goals and Objectives',
      options: goalsOptions,
      className: 'lesson-tags-filter',
      keyName: 'lessonTags',
      getValue: (lesson) => (
        lesson
          && lesson.relationships
          && lesson.relationships.tags
          && lesson.relationships.tags.data.length > 0 ? lesson.relationships.tags.data.map((t) => t.id) : ['empty-tag']),
    },
  ];

  return multiSelectFilterConfig;
};

export const myStudentTags = 'tags';
export const useHandleLessonTag = () => {
  const { myStudentDomain } = useStudentContext();
  const { lessonData, lessonTags } = myStudentDomain.domainData;

  const getLessonTagIds = (lesson) => (lesson && lesson.relationships && lesson.relationships.tags ? lesson.relationships.tags.data.map((t) => t.id) : []);
  const handleOnTagDelete = (deletedTag, lesson) => {
    const updatedTags = lesson.relationships.tags.data.filter((t) => t.id !== deletedTag.id);
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.tags.data = updatedTags;
    myStudentDomain.updateLessons([...lessonData.lessons], lessonData.included, lessonData.filter);
  };

  const fillInRelationObject = (lesson) => {
    if (!lesson.relationships) {
      // eslint-disable-next-line no-param-reassign
      lesson.relationships = {};
    }
    if (!lesson.relationships.tags) {
      // eslint-disable-next-line no-param-reassign
      lesson.relationships.tags = { data: [] };
    }
  };

  const handleOnTagSelect = (selectedTag, lesson) => {
    fillInRelationObject(lesson);
    const tags = lesson.relationships.tags.data;
    tags.push({ type: myStudentTags, id: selectedTag.id });
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.tags.data = tags;
    myStudentDomain.updateLessons([...lessonData.lessons], lessonData.included, lessonData.filter);
  };

  const handleOnTagAdd = async (newTag, lesson) => {
    fillInRelationObject(lesson);
    const tags = lesson.relationships.tags.data;
    tags.push({ type: myStudentTags, id: newTag.id });
    // eslint-disable-next-line no-param-reassign
    lesson.relationships.tags.data = tags;
    await myStudentDomain.updateLessonTag([newTag, ...lessonTags]);
    const included = lessonData.included || [];
    await myStudentDomain.updateLessons([...lessonData.lessons], [...included, newTag], lessonData.filter);
  };

  return {
    getLessonTagIds,
    handleOnTagDelete,
    handleOnTagSelect,
    handleOnTagAdd,
  };
};

export const useLessonSelect = (
  {
    allLessonsFromProfile,
  },
) => {
  const { myStudentDomain } = useStudentContext();

  const handleOnLessonSelect = async (lesson) => {
    const matchedLesson = findMtssLesson(lesson, allLessonsFromProfile);
    if (matchedLesson) {
      return;
    }
    const { lessonData } = myStudentDomain.domainData;
    const { selectedLessonIds } = lessonData;
    const matchedLessonId = selectedLessonIds.findIndex((selectedId) => selectedId === lesson.id);
    let updatedSelectedIds = [...selectedLessonIds];
    if (matchedLessonId >= 0) {
      updatedSelectedIds = selectedLessonIds.filter((selectedId) => selectedId !== lesson.id);
    } else {
      updatedSelectedIds.push(lesson.id);
    }

    await myStudentDomain.updateSelectedLessonIds(updatedSelectedIds);
  };

  const handleOnLessonClear = async () => {
    await myStudentDomain.updateSelectedLessonIds([]);
  };

  return {
    handleOnLessonSelect,
    handleOnLessonClear,
  };
};

export const useHandleTeacherCourseAssigment = () => {
  const { myStudentDomain } = useStudentContext();
  const {
    selectedDates,
    lessonData,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
  } = myStudentDomain.domainData;

  const handleOnSelectedDatesApply = (internalSelectedDates) => myStudentDomain.updateSelectedDates(internalSelectedDates);
  const handleOnStartLoading = () => myStudentDomain.updateAssignedStudentsCoursesLoading(true);

  const subjectId = lessonData.filter.selectedSubject;
  const onDataLoad = (data) => myStudentDomain.updateAssignedStudentsCourses(data);

  const handleOnFilterCriteriaAssignedStudentsCoursesUpdate = (updatedFilter) => myStudentDomain.updateFilterCriteriaAssignedStudentsCourses(updatedFilter);

  return {
    selectedDates,
    handleOnSelectedDatesApply,
    handleOnStartLoading,
    subjectId,
    onDataLoad,
    assignedStudentCourses,
    filterCriteriaAssignedStudentCourses,
    assignedStudentCoursesLoading,
    handleOnFilterCriteriaAssignedStudentsCoursesUpdate,
  };
};

export const useGetFilteredLessons = () => {
  const { myStudentDomain } = useStudentContext();
  const { lessonData } = myStudentDomain.domainData;
  const { filter } = lessonData;
  const currentStudent = useGetCurrentStudent();
  const filteredLessonData = JSON.parse(JSON.stringify(lessonData));

  if (lessonData.lessons[0] && lessonData.lessons[0].lessons && lessonData.lessons[0].lessons.length > 0) {
    filteredLessonData.lessons[0].subject = filter.isExpiredAssignment ? `${currentStudent.firstName}'s Previously Assigned` : `${currentStudent.firstName}'s Assigned Activities`;
    filteredLessonData.lessons[0].lessons = lessonData.lessons[0].lessons.filter((l) => l.meta.archived === filter.isExpiredAssignment);
  }
  return {
    filteredLessonData,
  };
};

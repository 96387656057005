import PlayLessonAction from '../../../../lessons/lessonactions/PlayLessonAction';
import ShareLinkLessonAction from '../../../../lessons/lessonactions/ShareLinkLessonAction';
import DropdownAction from '../../../../lessons/lessonactions/dropdown/DropdownAction';
import StudentAssignLessonAction from './StudentAssignLessonAction';
import CopyToEditAction from '../../../../lessons/lessonactions/CopyToEditAction';
import PrintLessonMenuItem from '../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import LessonPreviewMenuItem from '../../../../lessons/lessonactions/dropdown/LessonPreviewMenuItem';
import { isMobile } from '../../../../../utils/DeviceUtils';
import PlayLessonDetailAction from '../../../../lessons/lessonactions/PlayLessonDetailAction';
import MyStudentAddRemoveFromGroupPlay from './MyStudentAddRemoveFromGroupPlay';
import DeleteAllAssignmentAction from './dropdown/DeleteAllAssignmentAction';

/**
 * Lesson card detail action
 */
// eslint-disable-next-line import/prefer-default-export
export const createLessonCardDetailAction = ({ lesson, currentUser, selectedStudentProfile, terminologies, selectedDates, refreshStudentLessonAndAssignedLessons, subjectId }) => {
  const actions = [];

  actions.push(
    (<PlayLessonAction
      label='PLAY'
      lesson={lesson}
      key={`student-lesson-play-${lesson.id}`}
      user={currentUser}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`student-lesson-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<MyStudentAddRemoveFromGroupPlay
      lesson={lesson}
      key={`student-lesson-add-remove-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    <LessonPreviewMenuItem
      key={`student-lesson-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      userProfile={selectedStudentProfile}
      terminologies={terminologies}
    />,
  ];

  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`student-lesson-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={selectedStudentProfile}
      />
    ));
  }

  dropdownActions.push((
    <DeleteAllAssignmentAction
      key={`student-lesson-dropdown-delete-all-${lesson.id}`}
      lesson={lesson}
      subjectId={subjectId}
      student={currentUser}
      onDeleteSuccess={() => { refreshStudentLessonAndAssignedLessons(); }}
    />
  ));

  actions.push(
    (
      <DropdownAction
        key={`student-lesson-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  actions.push(
    (
      <CopyToEditAction
        lesson={lesson}
        currentUser={currentUser}
        key={`student-lesson-copy-to-edit-${lesson.id}`}
        fromStudentId={currentUser.id}
      />
    ),
  );

  actions.push(
    (
      <StudentAssignLessonAction
        lessons={[lesson]}
        selectedLessonIds={[lesson.id]}
        key={`student-lesson-assign-to-others-${lesson.id}`}
        currentStudent={currentUser}
        selectedDates={selectedDates}
      />
    ),
  );

  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, currentUser, userProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`student-lesson-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      userProfile={userProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
} from '@mui/material';
import './FillInTheBlankPreview.scss';
import { BLANK_SPACE } from './FillInTheBlankUtils';
import TextLines from '../../text/TextLines';

const fillInTheBlankExamples = [
  'I like to eat ___ for breakfast.',
  'Which animal barks?___',
  '___ is my first name.',
];

const FillInTheBlankPreviewWithTextStyle = ({ data }) => {
  const { question: { preBlank, postBlank } } = data;

  return (
    <div className='fill-in-the-blank-preview'>
      <div className='example'>
        <Typography variant='h6'>
          Example Questions
        </Typography>
        {fillInTheBlankExamples.map((example, index) => (
          <div className='example-text' key={`example-text-${index}`}>{example}</div>
        ))}
      </div>
      <Divider style={{ height: 8 }} />
      <div className='preview'>
        <Typography variant='h6'>
          Your Question
        </Typography>
        <div className='preview-question'>
          <div className='pre-blank'>
            <TextLines textLines={preBlank} />
          </div>
          {BLANK_SPACE}
          <div className='post-blank'>
            <TextLines textLines={postBlank} />
          </div>
        </div>
      </div>
    </div>
  );
};

FillInTheBlankPreviewWithTextStyle.defaultProps = {
  data: {
    question: {
      preBlank: {
        lines: [],
      },
      postBlank: {
        lines: [],
      },
    },
    answers: [],
  },
};

FillInTheBlankPreviewWithTextStyle.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.shape({
      preBlank: PropTypes.any,
      postBlank: PropTypes.any,
    }),
    answers: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default FillInTheBlankPreviewWithTextStyle;

import vizzleAxios from './service';

export const addToGroupPlay = async (userId, lessonIds) => {
  const url = `/users/${userId}/lessons/groupPlay?operation=add`;
  const payload = { lessonIds };
  const result = await vizzleAxios.post(url, payload);
  return result.data;
};

export const removeFromGroupPlay = async (userId, lessonIds) => {
  const url = `/users/${userId}/lessons/groupPlay?operation=remove`;
  const payload = { lessonIds };
  const result = await vizzleAxios.post(url, payload);
  return result.data;
};

export const getLessonsGroupPlay = async (userId) => {
  const url = `/users/${userId}/lessons/groupPlay`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

import { useState, useRef } from 'react';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { deleteMyStudent, getListOfStudents } from '../../../../../services/StudentService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

export const useSelectStudentList = () => {
  const { studentsDomain, uiDomain, userDomain } = useDomain();
  const { students } = studentsDomain.domainData;
  const { user } = userDomain.domainData;
  const selectedStudent = useRef(null);

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const handleOnRemovedStudent = async () => {
    const fullName = `${selectedStudent.current.firstName} ${selectedStudent.current.lastName}`;
    uiDomain.showLoader(`Removing ${fullName}`, LOADER_TYPE.HASH_LOADER);

    try {
      await callRemoteServiceWrapper(async () => {
        await deleteMyStudent(selectedStudent.current.id);
        const studentList = await getListOfStudents(user.id);
        await studentsDomain.updateStudentList(studentList.students);
      });
      await uiDomain.hideLoader();
      await uiDomain.showSnackbar(true, 'info', 3000, `Student ${fullName} is removed `);
    } catch (e) {
      await uiDomain.hideLoader();
    }
    selectedStudent.current = null;
  };

  const onConfirmDialogClosed = () => {
    selectedStudent.current = null;
  };

  return {
    selectedStudent,
    students,
    handleOnRemovedStudent,
    onConfirmDialogClosed,
  };
};

export const useConfirmDialog = () => {
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  return {
    displayConfirmDialog,
    setDisplayConfirmDialog,
  };
};

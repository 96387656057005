import PropTypes from 'prop-types';
import AddRemoveFromGroupPlay from '../../../../../lessons/lessonactions/add-remove-from-group-play/AddRemoveFromGroupPlay';
import { useGroupPlay } from '../../context/GroupPlayContextProvider';
import Logger from '../../../../../../utils/Logger';

export default function GroupPlayAddRemoveFromGroupPlay({
  lesson,
  onSuccess,
}) {
  const { groupPlayDomain } = useGroupPlay();
  const { lessonData } = groupPlayDomain.domainData;

  return (
    <AddRemoveFromGroupPlay
      lesson={lesson}
      onSaveSuccess={async (updatedLesson) => {
        Logger.logWhenDebugModeIsOn({
          UPDATED_LESSON: updatedLesson,
        });
        const { lessons } = lessonData;
        const updatedLessons = lessons.filter((l) => l.id !== lesson.id);

        const updatedLessonData = {
          ...lessonData,
          lessons: updatedLessons,
        };
        onSuccess();
        await groupPlayDomain.updateLessonGroupPlay(updatedLessonData);
      }}
    />
  );
}

GroupPlayAddRemoveFromGroupPlay.defaultProps = {
  onSuccess: () => {},
};

GroupPlayAddRemoveFromGroupPlay.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    attributes: PropTypes.object,
  }).isRequired,
  onSuccess: PropTypes.func,
};

import PropTypes from 'prop-types';
import ArchiveIcon from '@mui/icons-material/Archive';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import { flatten, unique } from '../../../../utils/ArrayUtils';
import './ArchiveSelectedLessons.scss';

const ArchiveSelectedLessons = ({
  lessons,
  archiveLessonFunction,
  isArchiving,
  selectedLessonIds,
}) => {
  const flattenLessons = unique(flatten(lessons.map((subjectWithLessons) => subjectWithLessons.lessons)), 'id');
  const activeLessons = flattenLessons.filter((lesson) => !lesson.meta.archived);

  const handleOnClick = async () => {
    const activeSelectedLessons = activeLessons.filter((lesson) => selectedLessonIds.includes(lesson.id));
    await archiveLessonFunction(activeSelectedLessons, true);
  };

  return (
    <ButtonWithSpinner
      label='Archive'
      className='btn-primary archive-selected-lessons-action'
      disabled={activeLessons.length === 0 || selectedLessonIds.length === 0 || isArchiving}
      loading={isArchiving}
      onClick={handleOnClick}
      startIcon={<ArchiveIcon />}
      data-test='archive-selected-lessons-action'
    />
  );
};

ArchiveSelectedLessons.defaultProps = {
  lessons: [],
  archiveLessonFunction: () => { },
  isArchiving: false,
  selectedLessonIds: [],
};

ArchiveSelectedLessons.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  archiveLessonFunction: PropTypes.func,
  isArchiving: PropTypes.bool,
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
};

export default ArchiveSelectedLessons;

import { useEffect } from 'react';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { useAdmin } from '../context/AdminContextProvider';
import { getUsersAndLicensesBySchoolId } from '../../../services/AdminService';
import Logger from '../../../utils/Logger';

export const useGetUsersAndLicensesData = ({
  onDataLoadSuccess,
} = {}) => {
  const { adminDomain } = useAdmin();

  const { isLoading } = adminDomain.domainData;
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const getUsersAndLicensesData = async (schoolId) => {
    const selectedSchoolId = adminDomain.domainData.selectedSchoolId || schoolId;
    if (!selectedSchoolId) {
      return;
    }
    try {
      await callRemoteServiceWrapper(async () => {
        await adminDomain.updateIsDataLoading(true);
        const resultData = await getUsersAndLicensesBySchoolId(selectedSchoolId);
        if (resultData) {
          await adminDomain.updateUsersAndLicensesData(resultData, false);
        } else {
          await adminDomain.updateTeacherData({}, false);
        }
        if (onDataLoadSuccess) {
          onDataLoadSuccess();
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    getUsersAndLicensesData,
    isLoading,
  };
};

export const useHandleDistrictFilter = () => {
  const { adminDomain, districtsDomain } = useAdmin();
  const { selectedDistrictId } = adminDomain.domainData;

  const handleOnDistrictChange = (district) => {
    if (!district || !district.key) {
      return;
    }
    adminDomain.updateSelectedDistrictIdAndSchoolId(district.key, null);
  };

  const { districts } = districtsDomain.domainData;

  useEffect(() => {
    if (districts && districts.length === 1) {
      const district = districts[0];
      adminDomain.updateSelectedDistrictId(district.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts]);

  return {
    selectedDistrictId,
    handleOnDistrictChange,
  };
};

export const useHandleSchoolFilter = ({
  onDataLoadSuccess,
}) => {
  const {
    adminDomain,
  } = useAdmin();

  const {
    getUsersAndLicensesData,
  } = useGetUsersAndLicensesData({
    onDataLoadSuccess,
  });

  const { schoolsBySelectedDistrictId, selectedSchoolId } = adminDomain.domainData;

  const handleOnSchoolsLoaded = async (schools) => {
    await adminDomain.updateSchoolsBySelectedDistrictId(schools);
    if (schools && schools.length === 1) {
      const school = schools[0];
      adminDomain.updateSelectedSchoolId(school.id);
      getUsersAndLicensesData(school.id);
    }
  };

  const handleOnFilterSchoolChange = async (school) => {
    if (!school || !school.key) {
      return;
    }
    await adminDomain.updateSelectedSchoolId(school.key);
    getUsersAndLicensesData(school.key);
  };

  return {
    schoolsData: schoolsBySelectedDistrictId,
    handleOnSchoolsLoaded,
    selectedSchoolId,
    handleOnFilterSchoolChange,
  };
};

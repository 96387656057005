import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Typography, IconButton } from '@mui/material';
import './ChatPanel.scss';

export default function ChatPanel({
  title,
  onSubmit,
  dataRecords,
  displayCloseIcon,
  onClose,
}) {
  const contentRef = useRef();
  const [text, setText] = useState('');

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [dataRecords]);

  const handleOnTextChange = (e) => {
    setText(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!text || text.trim().length === 0) {
      return;
    }
    onSubmit(text);
    setText('');
  };

  return (
    <form
      className='chat-panel'
      data-private
      onSubmit={handleOnSubmit}
    >
      <div className='title'>
        <Typography variant='h6'>{title}</Typography>
        {displayCloseIcon ? <IconButton data-test='custom-dialog-close-icon' aria-label='close' className='custom-dialog-icon-button' onClick={onClose}><CloseIcon /></IconButton> : ''}
      </div>
      <div className='content' ref={contentRef}>
        {dataRecords.map((d, index) => (
          <div
            className={`chat-record ${d.position}`}
            key={`chat-record${index}`}
          >
            {d.text}
            {Boolean(d.date) && (
              <div className='chat-footer'>
                {d.date}
              </div>
            )}
            {d.sender && (
              <div className='chat-footer'>
                Sent by: {d.sender}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='input-text-panel'>
        <TextField
          autoFocus
          value={text}
          onChange={handleOnTextChange}
          fullWidth
          placeholder='Write a message ...'
          className='input-text'
          autoComplete='off'
          inputProps={{
            maxLength: 255,
          }}
        />
        <IconButton
          aria-label='Send'
          component='div'
          className='submit-button'
          onClick={handleOnSubmit}
        >
          <SendIcon />
        </IconButton>
      </div>
    </form>
  );
}

ChatPanel.defaultProps = {
  title: '',
  onSubmit: () => null,
  dataRecords: [],
  displayCloseIcon: false,
  onClose: () => null,
};

ChatPanel.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  dataRecords: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.oneOf(['left', 'right']),
    text: PropTypes.string,
    date: PropTypes.string,
    sender: PropTypes.string,
  })),
  displayCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
};

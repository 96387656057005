import PropTypes from 'prop-types';
import {
  SECTION_AUDIO,
  SECTION_IMAGE,
  SECTION_TEXT,
} from '../../common/CardEditorConstants';
import CardEditor from '../../common/card-editor/CardEditor';
import './PageInstructionEditor.scss';

export default function PageInstructionEditor({
  selectedSection,
  onSectionSelected,
  instructions,
  isSelected,
}) {
  return (
    <div className='page-instruction-editor'>
      <CardEditor
        config={{
          text: instructions.text,
          image: instructions.image,
          video: instructions.video,
          audio: instructions.audio,
        }}
        isSelected={isSelected}
        selectedSection={selectedSection}
        onImageSelected={() => {
          onSectionSelected(SECTION_IMAGE);
        }}
        onTextSelected={() => {
          onSectionSelected(SECTION_TEXT);
        }}
        onAudioSelected={() => {
          onSectionSelected(SECTION_AUDIO);
        }}
        isVideoIncluded={false}
      />
    </div>
  );
}

PageInstructionEditor.defaultProps = {
  isSelected: false,
  selectedSection: SECTION_TEXT,
  onSectionSelected: () => null,
  instructions: {},
};

PageInstructionEditor.propTypes = {
  isSelected: PropTypes.bool,
  selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
  onSectionSelected: PropTypes.func,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
};

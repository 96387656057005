import './StudentGroups.scss';
import { initDomains } from './context/StudentGroupsContextProvider';
import { usePrepareStudentGroups, usePrepareStudentGroupTable } from './StudentGroupsHooks';
import SearchAndSortableTable from '../../../../components/table/SearchAndSortableTable';
import AddStudentGroupAction from './actions/AddStudentGroupAction';

const StudentGroups = () => {
  initDomains();
  const {
    loading,
    getStudentGroupsData,
  } = usePrepareStudentGroups();

  const {
    tableConfig,
    tableData,
  } = usePrepareStudentGroupTable({
    getStudentGroupsData,
  });
  return (
    <div className='student-groups'>
      <SearchAndSortableTable
        containerClassName='student-groups-table-container'
        config={tableConfig}
        tableData={tableData}
        initTableState={{
          order: 'desc',
          orderBy: 'name',
        }}
        searchPlaceholder='Filter by Group Name'
        searchField='name'
        toolbarActions={[
          <AddStudentGroupAction
            key='add-student-group-action'
            onStudentGroupAdded={() => {
              getStudentGroupsData();
            }}
          />,
        ]}
        dataId='id'
        noResultMessage='No result founds.'
        noDataMessage='No Student group.'
        loading={loading}
        autoResetTableState={false}
      />
    </div>
  );
};

export default StudentGroups;

import {
  Box,
  Typography,
} from '@mui/material';
import BookCardThumbnail from '../../../../../components/vizzle/page/book/BookCardThumbnail';
import CardSelector from '../../../../../components/selector/CardSelector';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import {
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
} from '../../../../../AppConstants';

const booklayoutOnePageConfig = [{}];

const booklayoutTwoPagesConfig = [{}, {}];

const bookLayoutOnePageComponent = <BookCardThumbnail layoutConfig={booklayoutOnePageConfig} />;
const bookLayouTwoPageComponent = <BookCardThumbnail layoutConfig={booklayoutTwoPagesConfig} />;

/**
 * Container for Build A Book layout
 */
export default function BookLayoutContainer() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const config = [
    {
      component: bookLayoutOnePageComponent,
      dataValue: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
      onClick: (dataValue, index) => {
        Logger.logWhenDebugModeIsOn({
          SELECTED_ACTIVITY: {
            dataValue,
            index,
          },
        });

        let newPageData = {
          ...selectedPage,
          activityType: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
          cards: [{}],
          images: [''],
        };

        if (selectedPage && ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA === selectedPage.activityType) {
          newPageData = {
            ...selectedPage,
            activityType: ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
            cards: [
              selectedPage.cards[0],
              {
                ...selectedPage.cards[1],
                hide: true,
              },
            ],
            images: [selectedPage.images[0]],
          };
        }
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
      },
    },
    {
      component: bookLayouTwoPageComponent,
      dataValue: ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
      onClick: (dataValue, index) => {
        Logger.logWhenDebugModeIsOn({
          SELECTED_ACTIVITY: {
            dataValue,
            index,
          },
        });
        let newPageData = {
          ...selectedPage,
          activityType: ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
          cards: [{}, {}],
          images: ['', ''],
        };
        if (selectedPage && ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA === selectedPage.activityType) {
          let secondCard = {};
          let secondImage = '';
          if (selectedPage.cards.length > 1) {
            const { hide, ...rest } = selectedPage.cards[1];
            secondCard = { ...rest };
            secondImage = selectedPage.cards[1].image;
          }
          newPageData = {
            ...selectedPage,
            activityType: ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
            cards: [selectedPage.cards[0], secondCard],
            images: [selectedPage.images[0], secondImage],
          };
        }
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
      },
    },
  ];

  const props = {
    config,
    width: window.innerWidth <= 1024 ? '65vw' : '50vw',
    height: '39vh',
  };

  const selectedIndex = config.findIndex((c) => {
    const activityType = selectedPage ? selectedPage.activityType : null;
    return c.dataValue === activityType;
  });

  return (
    <Box>
      <br />
      <br />
      <Typography variant='h5'>Choose Single Page or Facing Pages</Typography>
      <br />
      <CardSelector
        {...props}
        selectedIndex={selectedIndex}
        disabled={currentSelectedPagesData < 0 || currentSelectedPagesData === null}
        className='vizzle-book-layout'
        data-test='book-layout'
      />
    </Box>
  );
}

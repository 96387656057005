import { useEffect } from 'react';
import {
  getExternalLinks,
} from '../../../../services/ExternalLinkService';
import {
  getLessonsGroupPlay,
} from '../../../../services/GroupPlayService';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useGroupPlay } from './context/GroupPlayContextProvider';
import Logger from '../../../../utils/Logger';

export const useGetExternalLinks = () => {
  const { userDomain, uiDomain } = useDomain();
  const { user } = userDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const { groupPlayDomain } = useGroupPlay();

  useEffect(() => {
    const prepareData = async () => {
      uiDomain.showLoader('Fetching external links', LOADER_TYPE.RING_LOADER);
      await callRemoteServiceWrapper(async () => {
        try {
          await callRemoteServiceWrapper(async () => {
            const exterinalLinksPromise = getExternalLinks(user.id);
            const lessonsGroupPlayPromise = getLessonsGroupPlay(user.id);
            const [exterinalLinksData, lessonsGroupPlayData] = await Promise.all([exterinalLinksPromise, lessonsGroupPlayPromise]);
            await groupPlayDomain.updateGroupPlayExternalLinksAndLessonGroupPlay(
              exterinalLinksData.data || [],
              {
                lessons: lessonsGroupPlayData.data || [],
                included: lessonsGroupPlayData.included || [],
              },
            );
          });
        } catch (e) {
          Logger.logError({
            ERROR: e,
          });
        }
      });
      uiDomain.hideLoader();
    };
    prepareData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
  };
};

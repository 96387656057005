import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import './NotificationBlocker.scss';

/**
 * A component for blocking user interaction on the screen
 */
export default function NotificationBlocker({
  show,
  Icon,
  loaderIcon,
  message,
}) {
  return show ? (
    <Dialog open={show} className='notification-blocker' transitionDuration={1000}>
      <DialogContent className='notification-blocker-content'>
        {Icon ? (
          <div className='notification-blocker-icon-container'>
            <Icon className='notification-blocker-icon' />
          </div>
        ) : ''}

        {loaderIcon ? (
          <div className='notification-blocker-icon-container'>
            {loaderIcon}
          </div>
        ) : ''}

        <Typography variant='h6'>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  ) : '';
}

NotificationBlocker.defaultProps = {
  show: false,
  Icon: null,
  loaderIcon: null,
};

NotificationBlocker.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool,
  // eslint-disable-next-line
  Icon: PropTypes.object,
  // eslint-disable-next-line
  loaderIcon: PropTypes.object,
};

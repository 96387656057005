import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
} from '@mui/material';
// import TextLines from '../../text/TextLines';
import InstructionEditor from '../common/instruction/InstructionEditor';
import {
  AREA_INSTRUCTIONS,
  AREA_SEQUENCE_CARDS,
  AREA_RESPONSE_CARDS,
  SECTION_IMAGE,
  SECTION_TEXT,
  SECTION_AUDIO,
  SECTION_ANSWER_KEY,
} from './SequencingConstants';
import './SequencingEditor.scss';
import CardEditor from '../common/card-editor/CardEditor';

export default function SequencingEditor({
  instructionsConfig,
  sequenceCardsConfig,
  responseCardsConfig,
  selectedSection,
}) {
  const createCard = (key, field, config, decoratorFunc) => (
    config[field].map((c, cardIndex) => (
      <CardEditor
        key={`${key}-${cardIndex}`}
        // className={className}
        isSelected={
          selectedSection.selectedArea === field
          && selectedSection.selectedCardIndex === cardIndex
        }
        config={{
          text: c.text,
          image: c.image,
          video: c.video,
          audio: c.audio,
        }}
        selectedSection={selectedSection.selectedSection}
        onImageSelected={(e) => {
          config.onSelected(e, field, SECTION_IMAGE, cardIndex);
        }}
        onTextSelected={(e) => {
          config.onSelected(e, field, SECTION_TEXT, cardIndex);
        }}
        onAudioSelected={(e) => {
          config.onSelected(e, field, SECTION_AUDIO, cardIndex);
        }}
        data-test={`card-editor-${key}-${cardIndex}`}
      >
        {decoratorFunc ? decoratorFunc(c, cardIndex, field, config) : ''}
      </CardEditor>
    ))
  );

  const sequenceCardKey = (card) => (
    <div className='sequencing-card-key'>{card.key}</div>
  );

  const handleOnResponseCardKey = (e, field, cardIndex, config) => {
    if (selectedSection.selectedCardIndex === cardIndex) {
      return;
    }
    config.onSelected(e, field, SECTION_ANSWER_KEY, cardIndex);
  };

  const responseCardKey = (card, cardIndex, field, config) => {
    if (!responseCardsConfig.getAvailableKeys) {
      return null;
    }
    const availableKeys = responseCardsConfig.getAvailableKeys(card);
    return (
      availableKeys
        ? (
          <>
            <Select
              value={card.answerKey || ''}
              onChange={(e) => { responseCardsConfig.onKeyChanged(e, cardIndex); }}
              onOpen={(e) => handleOnResponseCardKey(e, field, cardIndex, config)}
              variant='outlined'
              placeholder='Key'
              fullWidth
              className='response-card-key'
              data-test='response-card-menu'
            >
              {availableKeys.map((key, keyIndex) => (
                <MenuItem
                  className='sorting-response-key'
                  key={`sorting-editor-response-key-selector-${cardIndex}-${keyIndex}`}
                  value={key}
                >
                  {key}
                </MenuItem>
              ))}
            </Select>
            {
              availableKeys[0] === card.answerKey ? <div className='response-key-overlay' /> : ''
            }
          </>
        ) : ''
    );
  };

  return (
    <div data-test='sequencing-card-editor' className='sequencing-card-editor'>
      <div className='insruction-area'>
        <InstructionEditor
          instructionsConfig={instructionsConfig}
          selectedSection={selectedSection}
          className='sequencing-card-editor-instruction'
        />
      </div>
      <div className='cards'>
        <div className='sequencing-card-editor-sequencing-cards-column'>
          <div>
            Place Cards
          </div>
          <div className='sequencing-cards-container'>
            {createCard(
              'sequencing-cards',
              AREA_SEQUENCE_CARDS,
              sequenceCardsConfig,
              sequenceCardKey,
            )}
          </div>
        </div>
        <div className='sequencing-card-editor-sequencing-cards-column' data-test='sequence-card-section'>
          <div>
            Sequence Cards
          </div>
          <div className='sequencing-cards-container'>
            {createCard(
              'sequencing-response-cards',
              AREA_RESPONSE_CARDS,
              responseCardsConfig,
              responseCardKey,
            )}
          </div>
        </div>
      </div>

    </div>
  );
}

SequencingEditor.defaultProps = {
  instructionsConfig: {
    onSelected: () => { },
  },
  sequenceCardsConfig: {
    sequenceCards: [],
    onSelected: () => { },
  },
  responseCardsConfig: {
    reponseCards: [],
    getAvailableKeys: () => ([]),
    onKeyChanged: () => { },
    onSelected: () => { },
  },
  selectedSection: {
    selectedArea: AREA_INSTRUCTIONS,
    selectedCardIndex: 0,
    selectedSection: SECTION_AUDIO,
  },
};

SequencingEditor.propTypes = {
  instructionsConfig: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
    onSelected: PropTypes.func,
  }),
  sequenceCardsConfig: PropTypes.shape({
    sequenceCards: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      sequenceNum: PropTypes.number,
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    })),
    onSelected: PropTypes.func,
  }),
  responseCardsConfig: PropTypes.shape({
    responseCards: PropTypes.arrayOf(PropTypes.shape({
      answerKey: PropTypes.string,
      sequenceNum: PropTypes.number,
      text: PropTypes.object,
      image: PropTypes.string,
      audio: PropTypes.string,
      video: PropTypes.string,
    })),
    onKeyChanged: PropTypes.func,
    getAvailableKeys: PropTypes.func,
    onSelected: PropTypes.func,
  }),
  selectedSection: PropTypes.shape({
    selectedArea: PropTypes.oneOf([AREA_INSTRUCTIONS, AREA_SEQUENCE_CARDS, AREA_RESPONSE_CARDS]),
    selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO, SECTION_ANSWER_KEY]),
    selectedCardIndex: PropTypes.number,
  }),
};

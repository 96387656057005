import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import getTextToSpeechSettingsForm from '../../components/vizzle/tts/TextToSpeechSettingsForm';
import ToggleForm from '../../components/toggle/ToggleForm';
import NotificationDialog from '../../components/dialog/NotificationDialog';

const warningToSwitchOffMessage = 'Text to Speech Settings cannot be changed because JAWS® or other Screen Reader is currently ON.';

const TextToSpeech = ({ profile, onChangeCallback }) => {
  const [ttsConfig, setTtsConfig] = useState(profile.tts);
  const [toggle, setToggle] = useState(profile.tts.enabled);
  const isTtsEnabled = profile.tts.enabled;
  const [warningShow, setWarningShow] = useState(false);

  const TextToSpeechSettingsForm = getTextToSpeechSettingsForm(profile.tts.ttsMode);

  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(isTtsEnabled);
    setTtsConfig(profile.tts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id, profile.tts.enabled]);
  const handleTTSOnchange = (field, newValue) => {
    if (newValue === null) return;
    const newTts = {
      ...profile.tts,
      enabled: toggle,
      [field]: newValue,
    };
    setTtsConfig(newTts);
    onChangeCallback(newTts);
  };

  const handleTTSToggleOnChange = (event, newValue) => {
    if (newValue === null) return;
    if (profile.screenReaderOn) {
      setWarningShow(true);
      return;
    }
    setToggle(newValue);
    const tmpProfile = profile;
    tmpProfile.tts = {
      ...tmpProfile.tts,
      enabled: newValue,
    };
    onChangeCallback(tmpProfile.tts);
  };

  const warningDialog = {
    open: warningShow,
    onClose: () => {
      setWarningShow(false);
    },
    confirmLabel: 'OK',
    message: warningToSwitchOffMessage,
    title: 'Text to Speech',
  };

  return (
    <>
      <ToggleForm value={toggle} handleOnChange={handleTTSToggleOnChange} data-test='tts-toggle' />
      {toggle === true ? (
        <TextToSpeechSettingsForm
          ttsConfig={ttsConfig}
          onChange={handleTTSOnchange}
        />
      ) : null}
      <NotificationDialog
        open={warningDialog.open}
        onClose={warningDialog.onClose}
        title={warningDialog.title}
        confirmLabel={warningDialog.confirmLabel}
        message={warningDialog.message}
        Icon={HighlightOffIcon}
      />
    </>
  );
};

TextToSpeech.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default TextToSpeech;

import useSettingForm from '../form/useSettingForm';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import CelebrationSettingForm from '../../../../settings/celebrations/CelebrationSettingForm';
import SeasonalFilter from '../seasonal/SeasonalFilter';

const CelebrationSetting = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Celebrations');
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile, terminologies } = myStudentDomain.domainData;

  const onChange = (newValue) => {
    updateProfileSettingsOnChange(currentSelectedStudentId, {
      ...newValue,
    });
  };

  return (
    <CelebrationSettingForm
      profile={selectedStudentProfile}
      terminologies={terminologies}
      onChangeCallback={(newValue) => onChange(newValue)}
      SeasonalFilterComponent={SeasonalFilter}
    />
  );
};

export default CelebrationSetting;

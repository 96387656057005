import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
} from '@mui/material';
import {
  usePrepareLessonCourseData,
  useHandleFormChange,
  useGetUnitDropdownList,
  useGetTopicDropdownList,
  useGetCourseDropdown,
} from './TeacherCourseSearchBarHooks';
import DropdownInput from '../../../components/selector/DropdownInput';
import { getLessonLevels } from '../../../services/LessonStandardService';
import './TeacherCourseSearchBar.scss';
import SearchAction from './search-bar-actions/SearchAction';
import AddUnitAction from './search-bar-actions/AddUnitAction';
import AddTopicAction from './search-bar-actions/AddTopicAction';
import { useDomain } from '../../../states/AppDomainProvider';
import { useTeacherCourses } from '../context/TeacherCourseContextProvider';

const courseLabel = 'Grade';
const unitLabel = 'Unit';
const topicLabel = 'Topic';
const levelLabel = 'Level';

const TeacherCourseSearchBar = () => {
  const { subjectId } = useParams();
  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { searchCriteria } = teacherCourseSearchCriteriaDomain.domainData;

  const {
    lessonCourseLoading,
    lessonCourses,
  } = usePrepareLessonCourseData();

  const courseDrowdown = useGetCourseDropdown(subjectId);

  const {
    localSearchCriteria,
    handleOnCourseDataChange,
    handleOnLessonLevelChange,
  } = useHandleFormChange({
    courseDrowdown,
  });

  const {
    courseId,
    unitId,
    topicId,
    lessonLevel,
  } = localSearchCriteria;

  const unitDropdown = useGetUnitDropdownList(courseId);

  const topicDropdown = useGetTopicDropdownList(courseId, unitId);

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const getAddButton = () => {
    if (!user.userModerator) {
      return null;
    }
    if (courseId && unitId) {
      return (
        <AddTopicAction
          subjectId={Number(subjectId)}
          courseId={localSearchCriteria.courseId}
          unitId={localSearchCriteria.unitId}
          lessonLevel={1}
        />
      );
    }

    return (
      <AddUnitAction subjectId={Number(subjectId)} courseId={localSearchCriteria.courseId} />
    );
  };

  return (
    <Paper className='teacher-course-search-bar' elevation={4}>
      <div className='search-bar'>
        <Box className='search-container'>
          <DropdownInput
            label='GRADES'
            labelVariant='subtitle1'
            SelectProps={{
              renderValue: (value) => {
                if (!value) {
                  return courseLabel;
                }
                const course = (lessonCourses || []).find((l) => l.id === value);
                return course ? course.grade : courseLabel;
              },
              displayEmpty: true,
            }}
            disabled={
              !lessonCourses
              || lessonCourseLoading
            }
            dropdownValues={courseDrowdown}
            name='courseId'
            value={courseId || ''}
            onChange={(e) => handleOnCourseDataChange(e, { unitId: '', topicId: '' })}
            loading={lessonCourseLoading}
            data-test='course-selector'
          />
        </Box>
        <Box className='search-container'>
          <DropdownInput
            label='LEVEL'
            labelVariant='subtitle1'
            SelectProps={{ renderValue: (value) => (value || levelLabel), displayEmpty: true }}
            dropdownValues={getLessonLevels()}
            name='lessonLevel'
            value={lessonLevel || ''}
            onChange={handleOnLessonLevelChange}
            loading={lessonCourseLoading}
            data-test='level-selector'
          />
        </Box>
        <Box className='search-container'>
          <DropdownInput
            label='UNIT'
            labelVariant='subtitle1'
            SelectProps={{
              renderValue: (value) => {
                if (!value) {
                  return unitLabel;
                }
                const unit = unitDropdown.find((l) => l.id === value);
                return unit ? unit.name : unitLabel;
              },
              displayEmpty: true,
            }}
            disabled={
              !lessonCourses
              || lessonCourseLoading
              || courseId === null
              || courseId === undefined
              || courseId === ''
            }
            dropdownValues={unitDropdown}
            name='unitId'
            value={unitId || ''}
            onChange={(e) => handleOnCourseDataChange(e, { topicId: '' })}
            loading={lessonCourseLoading}
            data-test='unit-selector'
          />
        </Box>
        <Box className='search-container'>
          <DropdownInput
            label='TOPIC'
            labelVariant='subtitle1'
            SelectProps={{
              renderValue: (value) => {
                if (!value) {
                  return topicLabel;
                }
                const topic = topicDropdown.find((l) => l.id === value);
                return topic ? topic.name : topicLabel;
              },
              displayEmpty: true,
            }}
            disabled={
              !lessonCourses
              || lessonCourseLoading
              || unitId === null
              || unitId === undefined
              || unitId === ''
            }
            dropdownValues={topicDropdown}
            name='topicId'
            value={topicId || ''}
            onChange={(e) => handleOnCourseDataChange(e)}
            loading={lessonCourseLoading}
            data-test='topic-selector'
          />
        </Box>
        <Box className={`actions-container ${user.userModerator ? '' : 'center'}`}>
          <SearchAction
            searchCriteria={{
              ...searchCriteria,
              ...localSearchCriteria,
            }}
          />

          {getAddButton()}
        </Box>
      </div>
    </Paper>
  );
};

export default TeacherCourseSearchBar;

import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import UpdateTeacherForm from './update-teacher/UpdateTeacherForm';
import StudentTableSelector from './update-teacher/StudentTableSelector';
import { useAdmin } from '../../../../context/AdminContextProvider';
import { getDataFromIncluded } from '../../../../hooks/AdminUtilityHooks';
import { unique } from '../../../../../../utils/ArrayUtils';
import './UpdateTeacherPanel.scss';

const UpdateTeacherPanel = ({
  loading,
  teacherFormData,
  teacherDataObject,
  teacherSelectedStudentIds,
  onTeacherFormDataChange,
  onStudentSelect,
}) => {
  const { adminDomain } = useAdmin();
  const { usersAndLicensesData } = adminDomain.domainData;

  const studentListFromTeacherData = getDataFromIncluded(
    usersAndLicensesData
    && usersAndLicensesData.data
    && usersAndLicensesData.data.relationships
      ? usersAndLicensesData.data.relationships.students.data : [],
    usersAndLicensesData.included || [],
  );
  const studentListFromTeacherDataObject = getDataFromIncluded(
    teacherDataObject && teacherDataObject.data.relationships
      ? teacherDataObject.data.relationships.students.data
      : [], teacherDataObject.included || [],
  );

  const teacherStudentSelectorTableData = [
    ...studentListFromTeacherData,
    ...studentListFromTeacherDataObject,
  ].map((st) => ({
    id: st.id,
    username: st.attributes.userName,
    firstName: st.attributes.firstName,
    lastName: st.attributes.lastName,
    isActive: st.attributes.isActive,
  }));

  return (
    <div className='update-admin-teacher-panel'>
      {
        loading ? (
          <div className='loading-teacher-data'>
            <CircularProgress className='loading-icon' />
            Loading
          </div>
        ) : (
          <>
            <UpdateTeacherForm
              teacherFormData={teacherFormData}
              onDataChange={onTeacherFormDataChange}
            />
            <StudentTableSelector
              selectedStudentIds={teacherSelectedStudentIds}
              teacherStudentSelectorTableData={unique(teacherStudentSelectorTableData, 'id')}
              onDataChange={onStudentSelect}
            />
          </>
        )
      }
    </div>
  );
};

UpdateTeacherPanel.defaultProps = {
  loading: false,
  onTeacherFormDataChange: () => null,
  teacherDataObject: {
    data: {
      relationships: {
        students: [],
      },
    },
  },
  teacherFormData: {},
  teacherSelectedStudentIds: [],
  onStudentSelect: () => null,
};

UpdateTeacherPanel.propTypes = {
  loading: PropTypes.bool,
  teacherDataObject: PropTypes.shape({
    data: PropTypes.shape({
      relationships: PropTypes.shape({
        students: PropTypes.shape({
          data: PropTypes.array,
        }),
      }),
    }),
    included: PropTypes.array,
  }),
  teacherFormData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    isUserDistrictAdmin: PropTypes.bool,
    schoolId: PropTypes.number,
    adminCommunication: PropTypes.bool,
  }),
  onTeacherFormDataChange: PropTypes.func,
  teacherSelectedStudentIds: PropTypes.array,
  onStudentSelect: PropTypes.func,
};

export default UpdateTeacherPanel;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import LessonStepperExit from './LessonStepperExit';
import { useDomain } from '../../../../states/AppDomainProvider';
import {
  createNewLesson,
  updateLesson,
  permanentlySaveLessonPageData,
  saveLessonPageDataToTempTable,
  saveFeedbackLoopValue,
} from '../../../../services/LessonService';
import {
  LESSON_STATUS_PENDING_NEXTGEN,
  UNAUTHORIZED,
} from '../../../../AppConstants';
import Timer from '../../../../utils/Timer';
import Logger from '../../../../utils/Logger';
import {
  filterSavablePagesData,
  getLessonStandardObject,
  getSaveSubjectFunction,
  validateDataBeforeSaving,
} from './LessonStepperSaveHelper';
import { useGetLessonStandardsByUser } from '../useInitilizeDataForAuthoring';

// Defind Auto save timeout
const autoSaveInSeconds = process.env.REACT_APP_AUTOSAVE_IN_SECONDS || 60;

/**
 * Container for saving lesson data and lesson page data
 * @param children
 */
export default function LessonStepperSave({
  showSaveButton,
  smallButtons,
  pauseAutoSave,
  ...props
}) {
  const [timer, setTimer] = useState();
  const [loading, setLoading] = useState(false);
  const {
    lessonDataMaintenanceDomain,
    uiDomain,
    authoredLessonDomain,
    userDomain,
  } = useDomain();
  const { user } = userDomain.domainData;
  const lessonDataGlobal = lessonDataMaintenanceDomain.domainData.lessonData;

  const lessonStandards = useGetLessonStandardsByUser();

  /**
   * Update relationship and include object
   * @param {*} relationships
   * @param {*} included
   */
  const updateRelationshipsAndInclude = async (relationships, included) => {
    const { lessonData } = lessonDataMaintenanceDomain.domainData;
    lessonData.relationships = { ...relationships };
    await lessonDataMaintenanceDomain.updateLessonData(lessonData);

    const { includedData } = authoredLessonDomain.domainData;

    if (included) {
      const dataToCopy = includedData || [];
      const includedToUpdate = [...dataToCopy];
      included.forEach((i) => {
        const data = includedData.find((icd) => (
          icd.type === i.type && icd.id === i.id
        ));
        if (!data) {
          includedToUpdate.push(i);
        }
      });
      await authoredLessonDomain.updatedIncludedData(includedToUpdate);
    }
  };

  /**
   * Perform lesson save process
   *
   * @param {*} saveLessonPageDataFunction either save to the temp table or the permanent table
   */
  const save = async (saveLessonPageDataFunction) => {
    let saveSuccessMessage = '';
    const { lessonData, pagesData } = lessonDataMaintenanceDomain.domainData;

    const result = validateDataBeforeSaving(pagesData);
    if (!result.success) {
      throw new Error(`The activity cannot be saved: ${result.message}`);
    }
    let lessonId = lessonData.id;
    let lessonUuid = lessonData.attributes.uuid;

    // Get standards object
    const standards = getLessonStandardObject(lessonData, lessonStandards);

    if (!lessonId) {
      if (!lessonData.attributes.appId) {
        return 'The activity is not automatically saved as app id is not selected.';
      }

      // Create lesson
      const dataToAdd = {
        title: lessonData.attributes.name || 'Untitled',
        description: lessonData.attributes.description,
        appId: lessonData.attributes.appId,
        userName: user.userName,
        standards,
        lessonLevel: lessonData.attributes.lessonLevel,
      };
      const resultSave = await createNewLesson(dataToAdd);
      lessonId = resultSave.data.data.id;
      lessonUuid = resultSave.data.data.attributes.uuid;
      // Update relationships object and included data
      const updateRelationshipsAndIncludePromise = updateRelationshipsAndInclude(resultSave.data.data.relationships, resultSave.data.included);

      // Save lesson page data
      const pagesDataToSave = filterSavablePagesData(pagesData);
      const saveLessonPagePromise = saveLessonPageDataFunction(lessonUuid, pagesDataToSave);

      const subjectToSave = lessonData.attributes.subjects[0];
      if (subjectToSave && subjectToSave.length > 0) {
        resultSave.data.data.attributes.subjects = [subjectToSave];
      }

      // Save subject
      const saveSubjectPromise = getSaveSubjectFunction(subjectToSave, lessonUuid, lessonData.changeSubjectFrom);

      const saveFeedbackLoopValuePromise = saveFeedbackLoopValue({ lessonUuid, toggleValue: lessonData.attributes.feedbackLoopValue });

      await Promise.all([updateRelationshipsAndIncludePromise, saveLessonPagePromise, saveSubjectPromise, saveFeedbackLoopValuePromise]);
      const preatedData = {
        ...lessonData,
        attributes: {
          ...resultSave.data.data.attributes,
          ...lessonData.attributes,
          name: resultSave.data.data.attributes.name,
        },
        id: lessonId,
        changeSubjectFrom: subjectToSave,
      };
      await lessonDataMaintenanceDomain.prepareDataForUpdate(preatedData, pagesData, lessonDataMaintenanceDomain.domainData.currentSelectedPagesData);
      saveSuccessMessage = 'The activity has been created successfully.';
    } else {
      // Update lesson data

      const dataToSave = {
        title: lessonData.attributes.name,
        description: lessonData.attributes.description,
        appId: lessonData.attributes.appId,
        reviewerNotes: lessonData.attributes.reviewerNotes,
        standards,
        lessonKeywords: lessonData.attributes.lessonKeywords,
        lessonLevel: lessonData.attributes.lessonLevel,
        gradeLevel: lessonData.attributes.rawGradeLevel,
      };

      const updateLessonPromise = updateLesson(lessonUuid, dataToSave);

      // Save page data
      const pagesDataToSave = filterSavablePagesData(pagesData);
      const savePageDataPromise = saveLessonPageDataFunction(lessonUuid, pagesDataToSave, lessonId);

      // Save subject
      const subjectToSave = lessonData.attributes.subjects[0];
      const saveSubjectPromise = getSaveSubjectFunction(subjectToSave, lessonUuid, lessonData.changeSubjectFrom);
      const saveFeedbackLoopValuePromise = saveFeedbackLoopValue({ lessonUuid: lessonData.attributes.uuid, toggleValue: lessonData.attributes.feedbackLoopValue });
      const [updateLessonResolve] = await Promise.all([updateLessonPromise, savePageDataPromise, saveSubjectPromise, saveFeedbackLoopValuePromise]);

      lessonData.changeSubjectFrom = subjectToSave;
      // Update relationships object and included data
      await updateRelationshipsAndInclude(updateLessonResolve.data.data.relationships, updateLessonResolve.data.included);
      saveSuccessMessage = 'The activity has been updated successfully.';
    }

    return saveSuccessMessage;
  };

  /**
   * The handler when the save button is clicked
   */
  const onSaveHandler = async () => {
    try {
      Logger.logWhenDebugModeIsOn({
        SAVE_DATA: {
          title: lessonDataGlobal.attributes.name,
          appId: lessonDataGlobal.attributes.appId,
        },
      });
      setLoading(true);
      const saveSuccessMessage = await save(permanentlySaveLessonPageData);
      Logger.logWhenDebugModeIsOn('Reset auto Save timer');
      if (timer) {
        timer.reset();
      }
      await lessonDataMaintenanceDomain.updateIsDirtyFlag(false);
      await uiDomain.showSnackbar(true, 'info', 3000, saveSuccessMessage);
      setLoading(false);
    } catch (error) {
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
      } else if (error.message) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.message);
      }
      Logger.logError({
        ERROR: error,
      });
      setLoading(false);
    }
  };

  /**
   * Save the lesson data to the temp table
   */
  const saveDataToTempTable = async () => {
    try {
      const message = await save(saveLessonPageDataToTempTable);
      Logger.logWhenDebugModeIsOn(`Auto save: ${message}`);
    } catch (error) {
      // let's not tell the user when the auto-save fails
      Logger.logError({
        ERROR: error,
        MESSAGE: error,
      });
    }
  };

  useEffect(() => {
    if (timer) {
      if (pauseAutoSave) {
        Logger.logWhenDebugModeIsOn('Temporary stop auto save timer');
        timer.stop();
      } else {
        timer.start();
        Logger.logWhenDebugModeIsOn('Start auto Save timer');
      }
    }

    // eslint-disable-next-line
  }, [pauseAutoSave]);

  useEffect(() => {
    const timerObject = new Timer(() => {
      saveDataToTempTable();
    }, autoSaveInSeconds * 1000);
    setTimer(timerObject);
    // Start save timer when entering the screen
    Logger.logWhenDebugModeIsOn('Start auto Save timer');
    timerObject.start();

    return () => {
      uiDomain.closeSnackbar();
      Logger.logWhenDebugModeIsOn('Stop auto Save timer');
      timerObject.stop();
    };

    // eslint-disable-next-line
  }, []);

  const buttonSize = smallButtons ? '130px' : '180px';
  const marginRight = smallButtons ? '10px' : '20px';

  let disabled = !lessonDataGlobal.attributes || !lessonDataGlobal.attributes.appId;
  disabled = disabled || (user.userModerator && lessonDataGlobal.attributes.approvalStatus === LESSON_STATUS_PENDING_NEXTGEN && !lessonDataGlobal.attributes.lessonLevel);

  return (
    <Box display='flex'>
      <Box zIndex={1} pb='10px' display='flex' ml='auto' mr='15px' mt='25px'>
        {showSaveButton ? (
          <Box width={buttonSize} mr={marginRight}>
            <ButtonWithSpinner
              disabled={disabled}
              label='Save'
              onClick={onSaveHandler}
              loading={loading}
              fullWidth
              className='vizzle-save-button'
              data-test='lesson-save-action'
            />
          </Box>
        ) : ''}
        <Box width={buttonSize}>
          <LessonStepperExit {...props} onExitConfirm={onSaveHandler} />
        </Box>
      </Box>
    </Box>
  );
}

LessonStepperSave.defaultProps = {
  showSaveButton: true,
  pauseAutoSave: false,
  smallButtons: false,
};

LessonStepperSave.propTypes = {
  showSaveButton: PropTypes.bool,
  pauseAutoSave: PropTypes.bool,
  smallButtons: PropTypes.bool,
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { getPageStyle } from '../../../../../components/vizzle/page/common/LessonPageUtil';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './SortingActivityPrompt.scss';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';

const SortingActivityPrompt = forwardRef(({
  promptCard,
  promptCardId,
  isRightAlignLayout,
  children,
  onResponseCardsAreaClick,
  onPromptSwitchAccessSelected,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const prompRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => prompRef.current,
  }));

  useRegisterSwitchAccessSelectEvent(prompRef, () => {
    onPromptSwitchAccessSelected();
  });

  return (
    <Box
      key={promptCardId}
      id={promptCardId}
      boxShadow={2}
      className={`sorting-activity-prompt-card ${isRightAlignLayout ? 'reverse-layout' : ''}`}
      style={getPageStyle(promptCard, true)}
      data-skip-switch-access-click='true'
      ref={prompRef}
    >
      <MediaPlayer
        className='media-action'
        audioUrl={promptCard.audio}
        videoUrl={promptCard.video}
        parentElementId={promptCardId}
        omitPlayIcon
        textToRead={elementToPlayTts}
        tts={ttsConfig}
      />
      <div className='prompt-details'>
        {promptCard.audio || promptCard.video || (elementToPlayTts && elementToPlayTts.length > 0) ? (
          <PlayCircleFilledIcon className='media-player-icon' data-test='media-player-icon' />
        ) : ''}

        {
          promptCard.image ? (
            <div className='prompt-image-container' data-test='prompt-image-container'>
              <CardMedia image={promptCard.image} className='prompt-image' />
            </div>
          ) : ''
        }

        <div className='prompt-text' data-test='prompt-text-container'>
          {promptCard.text ? (
            <TextLines
              textLines={promptCard.text}
              ref={setTextLineRef}
              componentKey={`text-line-${promptCardId}`}
            />
          )
            : ''}
        </div>
      </div>
      <div
        className='prompt-selected-response-cards'
        onClick={onResponseCardsAreaClick}
      >
        {children}
      </div>
    </Box>
  );
});

export default SortingActivityPrompt;

SortingActivityPrompt.defaultProps = {
  promptCard: {},
  isRightAlignLayout: false,
  children: null,
  onResponseCardsAreaClick: () => { },
  onPromptSwitchAccessSelected: () => { },
};

SortingActivityPrompt.propTypes = {
  promptCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  promptCardId: PropTypes.string.isRequired,
  isRightAlignLayout: PropTypes.bool,
  children: PropTypes.node,
  onResponseCardsAreaClick: PropTypes.func,
  onPromptSwitchAccessSelected: PropTypes.func,
};

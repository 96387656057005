import { getExportStudentData } from '../../../../../services/AdminService';
import ExportData from '../../../components/actions/export/ExportData';
import { useAdmin } from '../../../context/AdminContextProvider';

const ExportStudentsAction = () => {
  const { schoolsDomain } = useAdmin();
  const { selectedSchoolIds } = schoolsDomain.domainData;
  return (
    <ExportData
      getDataFunction={async () => getExportStudentData(selectedSchoolIds)}
      ariaLabel='Export Students'
      buttonLabel='Export Students'
      disabled={!selectedSchoolIds || selectedSchoolIds.length === 0}
      exportFileName='students-data-report.xlsx'
      exportingMessage='Exporting Students Data'
    />
  );
};

export default ExportStudentsAction;

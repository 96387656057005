import { useState } from 'react';
import { addStudentGroup, deleteStudentGroup, updateStudentGroup } from '../../../../../services/StudentService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

const initStudentGroupActionData = {
  name: '',
  students: [],
};

export const useHandleStudentGroupActionForm = () => {
  const [studentGroupData, setStudentGroupData] = useState(JSON.parse(JSON.stringify(initStudentGroupActionData)));
  const handleStudentGroupFormChange = (value) => {
    setStudentGroupData((previousState) => ({
      ...previousState,
      [value.name]: value.value,
    }));
  };
  const resetForm = () => {
    setStudentGroupData(JSON.parse(JSON.stringify(initStudentGroupActionData)));
  };

  return {
    studentGroupData,
    setStudentGroupData,
    handleStudentGroupFormChange,
    resetForm,
  };
};

export const useHandleAddStudentGroup = ({
  onSaveSuccess,
}) => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const addNewStudentGroup = async (studentGroup) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await addStudentGroup(studentGroup);
        onSaveSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Student Group ${studentGroup.name} is added.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saving: loading,
    addNewStudentGroup,
  };
};

export const useHandleDeleteStudentGroup = ({
  onStudentGroupDeleted,
}) => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const deleteNewStudentGroup = async (group) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await deleteStudentGroup(group.id);
        onStudentGroupDeleted();
        await uiDomain.showSnackbar(true, 'success', 3000, `Student Group ${group.name} is deleted.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    deleting: loading,
    deleteNewStudentGroup,
  };
};

export const useHandleUpdateStudentGroup = ({
  onSaveSuccess,
}) => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();
  const { uiDomain } = useDomain();

  const updateExistingStudentGroup = async (groupId, studentGroup) => {
    try {
      await callRemoteServiceWrapper(async () => {
        await updateStudentGroup({ groupId, data: studentGroup });
        onSaveSuccess();
        await uiDomain.showSnackbar(true, 'success', 3000, `Student Group ${studentGroup.name} is updated.`);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    saving: loading,
    updateExistingStudentGroup,
  };
};

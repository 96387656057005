import { useRef } from 'react';
import PropTypes from 'prop-types';
import ObjectiveCardListDetail from '../../../../components/vizzle/cardlistdetail/objective/ObjectiveCardListDetail';
import ObjectiveCardGettingStarted from '../../../../components/vizzle/cardlistdetail/objective/ObjectiveCardGettingStarted';
import CardDetailContainer from '../../../../components/vizzle/cardlistdetail/CardDetailContainer';
import LessonDetail from '../../../../components/vizzle/cardlistdetail/lesson/LessonDetail';
import EditMasterySetAndConsecutiveLessonAction from './actions/EditMasterySetAndConsecutiveLessonAction';
import EditGoalNameAction from './actions/EditGoalNameAction';
import { useDomain } from '../../../../states/AppDomainProvider';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { isTexasTeacher } from '../../../../utils/User';
import {
  useGetStudentMasteryObjective,
  useLessonDetail,
  useReloadObjectiveData,
} from './StudentObjectivesHooks';
import { createObjectiveDetailActions } from './actions/ObjectiveDetailActionBuilder';
import { createLessonCardDetailAction, createLessonCardDetailonPlanelAction } from './actions/lessons/LessonCardDetailActionBuilder';
import './StudentObjectives.scss';

const videoLinkForTx = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/Texas/Assign%20and%20Track%20TX.mp4`;
const videoLinkForNonTx = `${process.env.REACT_APP_ASSET_URL}/in-app-help-videos/Non-Texas/Assign%20and%20Track.mp4`;

const StudentObjectives = ({ filterChildren }) => {
  const { studentsDomain, userDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;
  const { user, userProfile } = userDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile, terminologies } = myStudentDomain.domainData;
  const currentStudent = students && students.find((s) => s.id === currentSelectedStudentId);
  const objectiveCardListDetailRef = useRef(null);
  const {
    loading,
    masterObjectiveData,
  } = useGetStudentMasteryObjective(currentStudent);

  const {
    lessonCardSlide,
    handleOnViewDetailClick,
    handleOnCloseDetail,
    openLessonCardDetail,
  } = useLessonDetail();

  const reloadObjectiveData = useReloadObjectiveData({
    objectiveCardListDetailRef,
  });

  if (loading) {
    return null;
  }

  if (!masterObjectiveData || !masterObjectiveData.masterObjectives || masterObjectiveData.masterObjectives.length === 0) {
    const videoLink = isTexasTeacher(user) ? videoLinkForTx : videoLinkForNonTx;

    return (
      <ObjectiveCardGettingStarted
        student={currentStudent}
        videoLink={videoLink}
        filterChildren={filterChildren}
      />
    );
  }

  const { lessonTags } = myStudentDomain.domainData;

  const multiSelectFilterConfig = [
    {
      label: 'Goals/Objectives',
      subTitle: 'Filter Goals and Objectives',
      options: lessonTags ? lessonTags.map((tag) => ({ key: tag.id, name: tag.attributes.name, value: tag.id, hidden: tag.meta.archived })) : [],
      className: 'objective-tags-filter',
      keyName: 'objectiveTags',
      getValue: (objective) => (objective ? objective.attributes.objectiveId.toString() : null),
    },
  ];

  const handleOnFilterChange = async (filterValue) => {
    await myStudentDomain.updateObjectiveFilterValue(filterValue);
  };

  const lessonDetail = () => (
    openLessonCardDetail && openLessonCardDetail.open ? (
      <CardDetailContainer
        ref={lessonCardSlide}
        open={openLessonCardDetail.open}
        selectedIndex={openLessonCardDetail.selectedIndex}
        onClose={handleOnCloseDetail}
        dataList={openLessonCardDetail.lessonList}
        createCardFunction={(lesson) => (
          <LessonDetail
            key={`lesson-play-detail-${lesson.id}`}
            lesson={lesson}
            currentUser={currentStudent}
            currentUserProfle={{
              ...selectedStudentProfile,
              standardScopes: [
                ...userProfile.standardScopes,
              ],
            }}
            included={openLessonCardDetail.included}
            actions={createLessonCardDetailAction(
              lesson,
              currentStudent,
              selectedStudentProfile,
              handleOnCloseDetail,
              reloadObjectiveData,
              terminologies,
            )}
            lessonDetailActions={createLessonCardDetailonPlanelAction(lesson, currentStudent, reloadObjectiveData, selectedStudentProfile, terminologies)}
          />
        )}
      />
    ) : null
  );

  return (
    <>
      <ObjectiveCardListDetail
        ref={objectiveCardListDetailRef}
        masterObjectiveData={masterObjectiveData}
        multiSelectFilterConfig={multiSelectFilterConfig}
        onFilterChange={handleOnFilterChange}
        currentUser={currentStudent}
        filterChildren={filterChildren}
        editGoalNameAction={(objective, rowIndex, moveCardIndexFunc) => (<EditGoalNameAction objective={objective} rowIndex={rowIndex} moveCardIndexFunc={moveCardIndexFunc} />)}
        createCardDetailAction={(
          objective,
          rowIndex,
          closeDetailFunc,
          included,
        ) => (createObjectiveDetailActions(objective, rowIndex, closeDetailFunc, included, currentStudent))}
        editMasterySetAndConsecutiveLessonAction={(objective, rowIndex, moveCardIndexFunc, moveToProgress) => (
          <EditMasterySetAndConsecutiveLessonAction
            objective={objective}
            rowIndex={rowIndex}
            moveCardIndexFunc={moveCardIndexFunc}
            moveToProgress={moveToProgress}
          />
        )}
        onLessonSelect={(lesson, index, lessonList, included) => {
          handleOnViewDetailClick(index, 0, lessonList, included);
        }}
      />
      {lessonDetail()}
    </>
  );
};

StudentObjectives.defaultProps = {
  filterChildren: null,
};

StudentObjectives.propTypes = {
  filterChildren: PropTypes.node,
};
export default StudentObjectives;

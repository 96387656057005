import { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormControlLabel, Paper, Portal, Radio, RadioGroup } from '@mui/material';
import CardActivity from '../common/card-activity/CardActivity';
import { useHandleMultipleChoiceSelect, useHandleSubmitButton, useInitializeData, usePrepareViewCorrectSubmissions, useShowSubmitValueButton } from './MultipleChoiceActivityHooks';
import { TOOLBAR_ACTIONS_ID } from '../../../../components/navigator/LessonNavigator';
import { getMultipleChoicePrefix } from '../../../../components/vizzle/page/multiple-choice/MultipleChoiceUtils';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import ObjectUtils from '../../../../utils/ObjectUtils';
import './MultipleChoiceActivity.scss';
import '../../../../components/vizzle/page/multiple-choice/MultipleChoiceComponent.scss';

/**
 * Lesson activity for Multiple Choices
 */
const MultipleChoiceActivity = forwardRef(({
  questionCard,
  answerCards,
  singlePage,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  isCurrentPage,
  pageIndex,
  onTriggerSwitchScan,
}, ref) => {
  const {
    selectedValue,
    handleSelecteValue,
  } = useHandleMultipleChoiceSelect({
    onTriggerSwitchScan,
  });

  const {
    isSubmitted,
    handleOnSubmitButtonClick,
    setIsSubmitted,
  } = useHandleSubmitButton({
    questionCard,
    answerCards,
    selectedValue,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
  });

  const {
    showSubmitValueButton,
    setShowSubmitValueButton,
  } = useShowSubmitValueButton({ isCurrentPage, isSubmitted });

  usePrepareViewCorrectSubmissions({
    answerCards,
    onActivityFinished,
    pageIndex,
    setShowSubmitValueButton,
    setIsSubmitted,
    handleSelecteValue,
    isCurrentPage,
  });

  const {
    questionCardRef,
    multipleChoicesResponseRefs,
    setMultipleChoiceResponseRefs,
    submitAnswerActionRef,
  } = useInitializeData();

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(100);
      if (isSubmitted) {
        return [];
      } else {
        const responseRefs = multipleChoicesResponseRefs;
        const result = [
          questionCardRef.current.getEnableElements(),
          ...responseRefs,
        ];

        if (submitAnswerActionRef.current && !submitAnswerActionRef.current.disabled) {
          result.push(submitAnswerActionRef.current);
        }
        return result;
      }
    },
  }));

  return (
    <>
      <div className={`multiple-choice-component ${singlePage ? 'single-page' : 'side-by-side'} multiple-choice-activity`}>
        <TransparentBlocker show={isSubmitted} />
        <div className='multiple-choice-component-question'>
          <CardActivity
            className='multiple-choice-component-question-card'
            text={questionCard.text}
            image={questionCard.image}
            video={questionCard.video}
            audio={questionCard.audio}
            ref={questionCardRef}
            data-test='multiple-choice-question'
          />
        </div>
        <FormControl
          className='multiple-choice-component-responses-form'
        >
          <RadioGroup
            name={`multiple-choice-${pageIndex}`}
            className='multiple-choice-component-responses vizzle-radio'
            value={selectedValue}
          >
            {answerCards.map((card, index) => {
              const key = `multiple-choice-answer-key-${pageIndex}-${index}-${card.answerKey}`;
              return (
                <Paper
                  key={key}
                  className='multiple-choice-component-response-container'
                  ref={(r) => { setMultipleChoiceResponseRefs(r, index); }}
                  onClick={() => {
                    handleSelecteValue(index);
                  }}
                  elevation={3}
                  style={{ overflow: 'auto' }}
                >
                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={(
                      <div className='prefix'>
                        {getMultipleChoicePrefix(index)}.
                      </div>
                    )}
                    labelPlacement='start'
                  />
                  <div
                    className='multiple-choice-component-response-container'
                  >
                    <CardActivity
                      className='multiple-choice-component-response'
                      text={card.text}
                      data-test={key}
                    />
                  </div>
                </Paper>
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>

      {showSubmitValueButton && (
        <Portal container={document.getElementById(TOOLBAR_ACTIONS_ID)}>
          <Button
            onClick={handleOnSubmitButtonClick}
            data-test={`multiple-choice-page-${pageIndex}-submit-action`}
            className='multiple-choice-submit-answer-action btn-green-background'
            disabled={selectedValue === null || selectedValue === undefined || selectedValue === ''}
            ref={submitAnswerActionRef}
          >
            SUBMIT ANSWER
          </Button>
        </Portal>
      )}

    </>
  );
});

export default MultipleChoiceActivity;

MultipleChoiceActivity.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}, {}, {}, {}],
  singlePage: false,
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  isCurrentPage: false,
  pageIndex: -1,
  onTriggerSwitchScan: () => null,
};

MultipleChoiceActivity.propTypes = {
  questionCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    answerKey: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  singlePage: PropTypes.bool,
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  isCurrentPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  onTriggerSwitchScan: PropTypes.func,
};

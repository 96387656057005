import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { getTextArray } from '../../../../../../components/vizzle/text/TextLinesUtils';
import MathEquationTextToSpeechEditorElement from './MathEquationTextToSpeechEditorElement';
import './MathEquationTextToSpeechEditor.scss';

export default function MathEquationTextToSpeechEditor({ text, onChange, ttsText }) {
  const textArray = getTextArray(text);
  const equations = textArray.filter((t) => !t.isText);

  const handleOnChange = (value, equationText) => {
    onChange(equationText, value);
  };

  if (!equations || equations.length === 0) {
    return null;
  }
  return (
    <div className='math-equation-text-to-speech-editor'>
      <Typography variant='h5'>Text to speech</Typography>
      {equations.map((equation, index) => {
        const mathLiveKey = `math-equation-text-element-${index}`;
        return (
          <MathEquationTextToSpeechEditorElement
            key={mathLiveKey}
            equationText={equation.text}
            ttsText={ttsText[equation.text]}
            onChange={(value) => { handleOnChange(value, equation.text); }}
          />
        );
      })}
    </div>
  );
}

MathEquationTextToSpeechEditor.defaultProps = {
  text: '',
  onChange: () => { },
  ttsText: {},
};

MathEquationTextToSpeechEditor.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  ttsText: PropTypes.object,
};

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import UrlLink from './UrlLink';
import './UrlLinkList.scss';

/**
 * The Url Link list component.
 * To easily customize anchor elements with theme colors and typography styles.
 *
 */
export default function UrlLinkList({ className, config }) {
  const navigate = useNavigate();
  return (
    <div className={`url-link-list ${className}`} data-test='url-link-list'>
      {
        config.map((c) => (
          <UrlLink
            title={c.title}
            url={c.url}
            subUrls={c.subUrls}
            key={uuidv1()}
            onClick={() => { if (c.url) navigate(c.url); }}
            dataTest={c.dataTest}
          >
            {c.title}
          </UrlLink>
        ))
      }
    </div>
  );
}

UrlLinkList.defaultProps = {
  className: '',
  config: [],
};

UrlLinkList.propTypes = {
  className: PropTypes.string,
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    url: PropTypes.string,
    subUrls: PropTypes.arrayOf(PropTypes.string),
    dataTest: PropTypes.string,
  })),
};

import { useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { Slide } from '@mui/material';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
// eslint-disable-next-line import/no-cycle
import BookCardComponent from './BookCardComponent';
import './BookCardActivity.scss';
import { flatten } from '../../../../utils/ArrayUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
import FreeTextResponseActivity from '../common/free-text-response/FreeTextResponseActivity';
import Logger from '../../../../utils/Logger';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useAutoPlay, useInitializeData } from './BookCardActivityHook';
import ReplayBookActivityAction from './ReplayBookActivityAction';
import { useOpenInstructionDialog } from '../common/instructions-dialog/ActivityInstructionHooks';
import InstructionDialog from '../../../../components/vizzle/page/common/instruction/InstructionDialog';

const getTextLineInstructionString = (text) => ({
  text: {
    defaultText: true,
    lines: [
      {
        color: '#333333',
        fontFamily: 'Quattrocento',
        fontSize: '25px',
        textAlign: 'center',
        elements: [
          {
            color: '#333333',
            fontFamily: 'Quattrocento',
            fontStyle: '25px',
            fontWeight: 'normal',
            text: 'Your teacher has commented....',
          },
        ],
      },
      {
        color: '#333333',
        fontFamily: 'Quattrocento',
        fontSize: '18px',
        textAlign: 'left',
        elements: [
          {
            color: '#333333',
            fontFamily: 'Quattrocento',
            fontStyle: 'normal',
            fontWeight: 'normal',
            text,
          },
        ],
      },
    ],
  },
});

/**
 * Lesson activity for Book
 */
const BookCardActivity = forwardRef(({
  layoutConfig,
  className,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onInteractionOpen,
  registeredLessonRef,
  isCurrentPage,
  studentHistoryId,
  onActivityFinished,
  freeformAnswerDataComment,
}, ref) => {
  const originalConfig = useRef(JSON.parse(JSON.stringify(layoutConfig)));

  const { lessonPlayDomain } = useLessonPlay();
  const { lessonPagesData, isPlayable, userProfile } = lessonPlayDomain.domainData;

  const componentId = useMemo(() => uuidv1(), []);

  const bookCardRefs = useRef([]);

  const setBookCardRefs = (r, index) => {
    if (r) {
      bookCardRefs.current[index] = r;
    }
  };

  const {
    bookActivityRef,
    replayButtonRef,
  } = useInitializeData();

  const {
    internalIsMediaPlaying,
    internalIsMediaPlayingRef,
    isAutoPlay,
    bookCardsAnimation,
    replayCards,
  } = useAutoPlay({
    isCurrentPage,
    bookActivityRef,
    bookCardRefs,
    layoutConfig,
  });

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(50);
      if (internalIsMediaPlayingRef.current) {
        return [];
      }

      const elements = flatten(bookCardRefs.current.map((b) => (b.getEnableElements())));

      const popup = elements.find((e) => e.interaction);
      if (popup && popup.interaction) {
        await ObjectUtils.delay(50);
        return popup.interactionRef.getEnableElements();
      } else {
        if (replayButtonRef.current && replayButtonRef.current.getEnableElements()) {
          elements.push(replayButtonRef.current.getEnableElements());
        }
        return elements;
      }
    },
  }));

  const instructions = getTextLineInstructionString(freeformAnswerDataComment && freeformAnswerDataComment.attributes.comments);
  const {
    openInstructions,
    handleOnInstructionClosed,
  } = useOpenInstructionDialog({
    instructions,
    isCurrentPage: isCurrentPage && freeformAnswerDataComment,
    closeDelay: 1000000,
  });

  return (
    <>
      <div
        key={componentId}
        className={
          `book-card-activity
      vizzle-book-card-activity
      ${className}`
        }
        data-test='book-card-activity-container'
        ref={bookActivityRef}
      >
        {isAutoPlay && !internalIsMediaPlaying && (
          <div className='replay-button-container'>
            <ReplayBookActivityAction onClick={replayCards} disabled={internalIsMediaPlaying} ref={replayButtonRef} />
          </div>
        )}
        <div className={`book-card-activity-container ${layoutConfig.length === 1 ? 'book-card-activity-small' : 'book-card-activity-big'}`}>
          {
            layoutConfig.map((c, index) => (
              c.allowCustomResponse ? (
                <Slide key={`bookcard-activity${componentId}-${index}`} direction='left' in={bookCardsAnimation.bookCardIn[index]} timeout={bookCardsAnimation.timeout}>
                  <div
                    className={`slider-container ${layoutConfig.length === 1 ? 'one-page' : 'two-page'}`}
                  >
                    <FreeTextResponseActivity
                      registeredLessonRef={registeredLessonRef}
                      className='bookcard-free-text'
                      responseCardId={`bookcard-activity${componentId}-${index}`}
                      responseCard={c}
                      originalResponseCard={originalConfig.current[index]}
                      cardNumber={index}
                      onChange={(cardValue) => {
                        Logger.logWhenDebugModeIsOn(index, cardValue);
                        lessonPlayDomain.updateLessonPageData({ lessonPagesData });
                      }}
                      onClearAll={(e) => {
                        e.stopPropagation();
                        // eslint-disable-next-line no-param-reassign
                        c.text = null;

                        // eslint-disable-next-line no-param-reassign
                        c.image = originalConfig.current[index].image;

                        // eslint-disable-next-line no-param-reassign
                        c.drawingImage = null;
                        lessonPlayDomain.updateLessonPageData({
                          lessonPagesData: [
                            ...lessonPagesData,
                          ],
                        });
                      }}
                      onCorrectAnswerSelected={onCorrectAnswerSelected}
                      ref={(r) => setBookCardRefs(r, index)}
                      studentHistoryId={studentHistoryId}
                      onActivityFinished={onActivityFinished}
                    />
                  </div>
                </Slide>
              ) : (
                <Slide key={`bookcard-activity${componentId}-${index}`} direction='left' in={bookCardsAnimation.bookCardIn[index]} timeout={bookCardsAnimation.timeout}>
                  <div
                    className={`slider-container ${layoutConfig.length === 1 ? 'one-page' : 'two-page'}`}
                  >
                    <BookCardComponent
                      cardIndex={index}
                      config={c}
                      onCorrectAnswerSelected={onCorrectAnswerSelected}
                      onIncorrectAnswerSelected={onIncorrectAnswerSelected}
                      onInteractionOpen={onInteractionOpen}
                      ref={(r) => setBookCardRefs(r, index)}
                      isPlayable={isPlayable}
                      userProfile={userProfile}
                    />
                  </div>
                </Slide>
              )

            ))
          }
        </div>
      </div>
      <InstructionDialog
        open={openInstructions.open}
        instructions={instructions}
        onClose={handleOnInstructionClosed}
      />
    </>
  );
});

export default BookCardActivity;

BookCardActivity.defaultProps = {
  layoutConfig: [],
  className: '',
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onInteractionOpen: () => { },
  registeredLessonRef: null,
  isCurrentPage: false,
  studentHistoryId: null,
  onActivityFinished: () => null,
  freeformAnswerDataComment: null,
};

BookCardActivity.propTypes = {
  className: PropTypes.string,
  layoutConfig: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    video: PropTypes.string,
    audio: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onInteractionOpen: PropTypes.func,
  registeredLessonRef: PropTypes.any,
  isCurrentPage: PropTypes.bool,
  studentHistoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onActivityFinished: PropTypes.func,
  freeformAnswerDataComment: PropTypes.object,
};

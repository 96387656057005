import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { calculateCardThumbnailSize } from '../../../../utils/CardSizeCalculator';
import Timer from '../../../../utils/Timer';
import './PageDataNavigator.scss';

/**
 * PageDataNavigator component for lesson page data list
 * The primary purpose of this component is to delegate the navigation logic
 * out of ContainerLessonPageDataList.
 *
 * @export
 * @param {*} {
 *   children,
 *   elementId,
 *   numberOfCards,
 *   selectedCard,
 * }
 * @returns
 */
export default function PageDataNavigator({
  children,
  elementId,
  numberOfCards,
  selectedCard,
}) {
  const cardSize = calculateCardThumbnailSize();

  const getCardData = () => {
    const scroll = document.getElementById(elementId);
    const cardElementSize = cardSize.width + 12; // offset of margin;
    const cardsPerPage = Math.floor(scroll.clientWidth / cardElementSize);
    const pageSize = cardsPerPage * cardElementSize;
    const currentPage = Math.floor((scroll.scrollLeft + scroll.offsetLeft) / pageSize);
    return {
      cardElementSize,
      cardsPerPage,
      currentPage,
      pageSize,
    };
  };

  /** constant for scroll bar move */
  const moveSpeed = 70;

  const [disabledIconButtons, setDisabledIconButtons] = useState({
    nextButton: false,
    previousButton: false,
  });

  /**
   * Enable / diable the next and previous buttons
   * @param newPosition new position
   * @param isLastPage whether or not the new position is the last page
   */
  const disableIconButtons = (newPosition, isLastPage) => {
    const previousButton = newPosition === 0;
    const nextButton = isLastPage;
    setDisabledIconButtons({
      previousButton,
      nextButton,
    });
  };

  const scrollToPosition = (scroll, position) => {
    /* Since ios does not support behavior: 'smooth', we need to implement smooth scroll manually
     * use the function below when ios one day supports it.
     *
     * scroll.scroll({
     *   left: position,
     *   behavior: 'smooth',
     * });
    */

    let currentPosition = scroll.scrollLeft;
    // When moving forward, the direction should be negative. On the other hand, when moving backward
    // the direction should be negative
    const direction = currentPosition > position ? moveSpeed * -1 : moveSpeed;

    const timerObject = new Timer(() => {
      currentPosition += direction;
      if (direction > 0 && currentPosition > position) {
        currentPosition = position;
        timerObject.stop();
      } else if (direction < 0 && currentPosition < position) {
        currentPosition = position;
        timerObject.stop();
      }
      scroll.scroll({
        left: currentPosition,
      });
    }, 20);

    timerObject.start();
  };

  const handleSelectPrevious = () => {
    const scroll = document.getElementById(elementId);
    const cardData = getCardData();
    const currentPage = Math.ceil((scroll.scrollLeft) / scroll.clientWidth);

    let newPosition = (currentPage * cardData.pageSize) - cardData.pageSize;
    newPosition = newPosition > 0 ? newPosition : 0;
    scrollToPosition(scroll, newPosition);
  };

  const handleSelectNext = () => {
    const scroll = document.getElementById(elementId);
    const cardData = getCardData();
    const nextPage = cardData.currentPage + 1;
    const cardMoved = nextPage * cardData.cardsPerPage;
    const remainingCards = scroll.childElementCount - cardMoved;
    let newPosition = (cardData.currentPage * cardData.pageSize) + (cardData.pageSize);

    /** Check whether or not the new position is the last page */
    if (remainingCards < cardData.cardsPerPage) {
      newPosition = scroll.scrollWidth - cardData.pageSize;
    }
    if (newPosition < 0) {
      newPosition = 0;
    }
    scrollToPosition(scroll, newPosition);
  };

  const handleOnscroll = () => {
    const scroll = document.getElementById(elementId);
    if (!scroll) {
      return;
    }
    const isLast = scroll.offsetWidth + scroll.scrollLeft === scroll.scrollWidth;
    disableIconButtons(scroll.scrollLeft, isLast);
  };

  const scrollToSelectedCard = () => {
    const scroll = document.getElementById(elementId);
    const cardData = getCardData();
    const page = Math.floor(selectedCard / cardData.cardsPerPage);
    let newPosition = page * cardData.cardElementSize * cardData.cardsPerPage;
    let isLastPage = false;

    /** Check whether or not the new position is the last page */
    const remainCard = scroll.childElementCount - cardData.cardsPerPage;
    if (remainCard > 0 && scroll.childElementCount - cardData.cardsPerPage <= selectedCard) {
      newPosition = scroll.scrollWidth - (cardData.cardsPerPage * cardData.cardElementSize);
      isLastPage = true;
    }

    if (remainCard <= 0) {
      isLastPage = true;
    }
    if (newPosition < 0) {
      newPosition = 0;
    }
    disableIconButtons(newPosition, isLastPage);
    scrollToPosition(scroll, newPosition);
  };

  useEffect(() => {
    /**
     * Register onscroll event to update the navigator buttons when the user moves the cards
     * or swipe the list on ipad
     */
    const scroll = document.getElementById(elementId);
    scroll.onscroll = handleOnscroll;

    // Remove the event listener when the component is destroyed.
    return () => {
      scroll.removeEventListener('scroll', handleOnscroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToSelectedCard();

    // eslint-disable-next-line
  }, [numberOfCards, selectedCard]);

  return (
    <div className='page-data-navigator'>

      <IconButton
        data-test='lesson-page-data-previous'
        disabled={disabledIconButtons.previousButton}
        className='lesson-page-data-previous vizzle-page-data-navigator-previous'
        onClick={() => handleSelectPrevious()}
      >
        <PlayCircleFilledIcon />
      </IconButton>
      {children}
      <IconButton
        data-test='lesson-page-data-next'
        disabled={disabledIconButtons.nextButton}
        className='lesson-page-data-next-button vizzle-page-data-navigator-next'
        onClick={() => handleSelectNext()}
      >
        <PlayCircleFilledIcon />
      </IconButton>
    </div>
  );
}

PageDataNavigator.defaultProps = {
  children: <div />,
  numberOfCards: 0,
  selectedCard: 0,
};

PageDataNavigator.propTypes = {
  children: PropTypes.node,
  elementId: PropTypes.string.isRequired,
  numberOfCards: PropTypes.number,
  selectedCard: PropTypes.number,
};

import { logout } from '../../services/LoginService';
import { useDomain } from '../../states/AppDomainProvider';

export const useHandleSessionExpired = () => {
  const { userDomain, uiDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const {
    notificationDialog,
  } = uiDomain.domainData;

  const { b2cId } = userProfile;
  const isB2cId = Boolean(b2cId);

  const onNotificationDialogClose = async () => {
    await uiDomain.showNotificationDialog(false);
    if (notificationDialog.toLogin) {
      // Move back to the login screen
      if (isB2cId) {
        logout();
      } else {
        window.location.href = '/';
      }
    }
  };

  return {
    notificationDialog,
    onNotificationDialogClose,
  };
};

import { useState } from 'react';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { mapMasteryCriteriaIntoGroup } from '../../../../../utils/MasterObjectiveMapping';
import { archiveObjective, unArchiveObjective, getAllLessonTagsForStudent } from '../../../../../services/LessonTagService';
import Logger from '../../../../../utils/Logger';
import { UNAUTHORIZED } from '../../../../../AppConstants';
import { deleteSelectedLessonAssignment } from '../../../../../services/CourseService';

export const useArchiveUnarchiveObjective = (objective, rowIndex, onSaveSuccess) => {
  const { studentsDomain, uiDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { masterObjectiveData } = myStudentDomain.domainData;
  const { included } = masterObjectiveData;

  const [archiveUnarchiveLessonDialog, setArchiveUnarchiveLessonDialog] = useState({
    showDialog: false,
    lessons: [],
  });

  const archivedObjective = async (archiveFlag, deleteLessons = true) => {
    try {
      setArchiveUnarchiveLessonDialog({
        showDialog: false,
        lessons: [],
      });
      const { objectiveId } = objective.attributes;

      const promiseList = [];
      if (archiveFlag) {
        const archiveObjectivePromise = archiveObjective(currentSelectedStudentId, objectiveId);
        promiseList.push(archiveObjectivePromise);

        if (deleteLessons) {
          const lessonIds = objective.relationships.lessons.data.map((l) => l.id);
          if (lessonIds && lessonIds.length > 0) {
            // Pass -2 for the subject id as we dont know from the objective detail which subject it should be.
            const deleteSelectedLessonAssignmentPromise = deleteSelectedLessonAssignment(currentSelectedStudentId, -2, lessonIds);
            promiseList.push(deleteSelectedLessonAssignmentPromise);
          }
        }
      } else {
        const unArchiveObjectivePromise = unArchiveObjective(currentSelectedStudentId, objectiveId);
        promiseList.push(unArchiveObjectivePromise);
      }

      await Promise.all(promiseList);

      // eslint-disable-next-line no-param-reassign
      objective.meta.archived = archiveFlag;
      const { masterObjectives } = masterObjectiveData;

      // Remove the objective from the current row
      const updatedMasterObjectives = masterObjectives[rowIndex].objectives.filter((o) => o.id !== objective.id);
      masterObjectives[rowIndex].objectives = updatedMasterObjectives;

      // Add the objective to the new row
      const mappedRow = mapMasteryCriteriaIntoGroup([objective]);
      const targetRow = mappedRow.find((row) => row.objectives.length > 0);
      const rowToAdd = masterObjectives.find((r) => r.objectiveRowTitle === targetRow.objectiveRowTitle);
      rowToAdd.objectives.unshift(objective);

      // Update lesson tags
      const lessonTags = await getAllLessonTagsForStudent(currentSelectedStudentId);
      let updatedLessonTags = lessonTags.data;
      if (archiveFlag) {
        updatedLessonTags = [
          ...lessonTags.data,
          {
            id: objective.attributes.objectiveId.toString(),
            type: 'tags',
            attributes: { ...objective.attributes },
            meta: { ...objective.meta },
          },
        ];
      }
      myStudentDomain.updateLessonTag(updatedLessonTags);

      onSaveSuccess();
      await myStudentDomain.updateMasteryObjectives([...masterObjectives], [...masterObjectiveData.included]);
      await uiDomain.showSnackbar(true, 'success', 3000, `Objective ${objective.attributes.name} has been ${archiveFlag ? 'archived' : 'unarchived'}.`);
    } catch (error) {
      Logger.logError({
        ERROR: error,
      });
      if (error.response && UNAUTHORIZED !== error.response.status) {
        await uiDomain.showSnackbar(true, 'error', 300000, error.response.data);
      }
    }
  };

  const tryArchive = async (archiveFlag) => {
    const { lessons } = objective.relationships;
    if (lessons && lessons.data) {
      const lessonsWithDetail = lessons.data.map((lesson) => (
        included.find((i) => i.type === lesson.type && i.id === lesson.id)
      ));
      // When archiving, check if there are unarchived lessons and vice versa
      const lessonsCount = lessonsWithDetail.filter((l) => l.meta.archived === !archiveFlag);
      if (lessonsCount && lessonsCount.length > 0) {
        // show dialog
        setArchiveUnarchiveLessonDialog({
          showDialog: true,
          lessons: lessonsCount,
        });
      } else {
        // No Activities, perform archive / unarchive
        archivedObjective(archiveFlag);
      }
    } else {
      archivedObjective(archiveFlag);
    }
  };

  return {
    tryArchive,
    archivedObjective,
    archiveUnarchiveLessonDialog,
    setArchiveUnarchiveLessonDialog,
  };
};

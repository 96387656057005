import { TreeView } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import isAfter from 'date-fns/isAfter';
import DateUtils from '../../../../../utils/DateUtils';
import ViewStudentAnswersAction from './ViewStudentAnswersAction';

const getViewStudentAnswersAction = ({
  key,
  data,
  reviewFeedbackData = [],
  currentSelectedStudentId,
  onUpdateReviewStatusSuccess,
  feedbackChat,
  onChatWidgetOpen,
  showIcons,
  onLessonExit,
}) => (
  <ViewStudentAnswersAction
    key={key}
    detailedReportData={data}
    studentId={currentSelectedStudentId}
    onUpdateReviewStatusSuccess={(newReviewStatus) => {
      const reportData = reviewFeedbackData.find((rd) => rd.id === data.id);
      reportData.reviewStatus = newReviewStatus;
      // reportData.chatNotification = false;
      // reportData.feedbackLoop = false;
      // myStudentDomain.updateDetailedReport(trackingDetailedReport.criteria, trackingDetailedReport.reportData, trackingDetailedReport.summaryData);
      if (onUpdateReviewStatusSuccess) {
        onUpdateReviewStatusSuccess(reviewFeedbackData);
      }
    }}
    feedbackChat={feedbackChat}
    onChatWidgetOpen={onChatWidgetOpen}
    showIcons={showIcons}
    onLessonExit={onLessonExit}
  />
);

const reviewAnswerDate = DateUtils.convertMMddYYYYToDate(process.env.REACT_APP_REVIEW_ANSWERS_DATE);

const sortByStartTime = (arr) => (
  arr.sort((a, b) => {
    if (!a.attributes || !a.attributes.startTime) {
      return -1;
    }
    if (!b.attributes || !b.attributes.startTime) {
      return -1;
    }
    return a.attributes.startTime.toString().localeCompare(b.attributes.startTime);
  })
);

export const renderStudentViewAnswerAction = (
  {
    data,
    reviewFeedbackData = [],
    currentSelectedStudentId,
    onUpdateReviewStatusSuccess,
    feedbackChat = false,
    onChatWidgetOpen = () => null,
    showIcons,
    onLessonExit = () => null,
  },
) => {
  const playDate = DateUtils.convertYYYYMMddToDate(data.playDate);
  const isPlayDateAfterReviewDate = isAfter(playDate, reviewAnswerDate);

  const childrenData = reviewFeedbackData.filter((rd) => String(rd.originalStudentHistoryId) === String(data.id)) || [];
  const sortedChildrenData = sortByStartTime(childrenData);

  if (isPlayDateAfterReviewDate && (!childrenData || childrenData.length === 0)) {
    return (getViewStudentAnswersAction({
      key: `detailed-report-view-answers-${data.id}`,
      data,
      reviewFeedbackData,
      currentSelectedStudentId,
      onUpdateReviewStatusSuccess,
      feedbackChat,
      onChatWidgetOpen,
      showIcons,
      onLessonExit,
    }));
  }

  const sortedChildrenDataIndex = sortedChildrenData ? sortedChildrenData.length : 0;
  return (
    isPlayDateAfterReviewDate
      ? (
        <TreeView
          aria-label='Lessonplay'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
          data-test='revision-tree-view'
        >
          {getViewStudentAnswersAction({
            key: `detailed-report-view-answers-${data.id}`,
            data,
            reviewFeedbackData,
            currentSelectedStudentId,
            onUpdateReviewStatusSuccess,
            feedbackChat,
            onChatWidgetOpen,
            showIcons,
            onLessonExit,
          })}
          <TreeItem nodeId={data.id} label='Revisions' className='subsequence-play-tree-view'>
            {sortedChildrenData.map((cd, index) => (
              <div className='tree-view-entry' key={`detailed-report-children-view-answers-container-${data.id}-${cd.id}-${index}`}>
                <div className='title'>({sortedChildrenDataIndex - index})</div>
                {
                  getViewStudentAnswersAction({
                    key: `detailed-report-children-view-answers-${data.id}-${cd.id}-${index}`,
                    data: cd,
                    reviewFeedbackData,
                    currentSelectedStudentId,
                    onUpdateReviewStatusSuccess,
                    feedbackChat,
                    onChatWidgetOpen,
                    showIcons,
                    onLessonExit,
                  })
                }
              </div>
            ))}
          </TreeItem>
        </TreeView>
      )
      : data.lessonName
  );
};

export const getReportDataToDisplay = (reportData) => reportData.filter((r) => !r.originalStudentHistoryId || String(r.originalStudentHistoryId) === String(r.id));

// import DateUtils from '../utils/DateUtils';
import DateUtils from '../utils/DateUtils';
import ObjectUtils from '../utils/ObjectUtils';
import { exportDataAsFile, uploadFile } from './FileService';
import vizzleAxios from './service';

/**
 * To fetch list of all available districts in the system
 * @returns
 */
export const getAllDistricts = async () => {
  const url = '/districts';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const addDistrict = async (district) => {
  const url = '/districts';
  await vizzleAxios.post(url, district);
};

export const updateDistrict = async (district) => {
  const { id, ...rest } = district;
  const url = `/districts/${id}`;
  await vizzleAxios.put(url, rest);
};

export const districtsExpirationLicense = async (districtIds, licenseExpirationDate) => {
  const url = '/districts/expiration/license';
  await vizzleAxios.put(url, {
    districtIds,
    licenseExpirationDate,
  });
};

export const districtsBatchUpload = async (file) => {
  const url = '/districts/batch';
  return uploadFile(url, file);
};

export const getSchoolsByDistrictId = async (districtId) => {
  const url = `/districts/${districtId}/schools`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getSchoolsByDistrictIds = async (districtIds) => {
  const url = `/districts/schools?filter[districts]=${districtIds.join(',')}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const addSchool = async (school) => {
  const url = '/schools';
  const result = await vizzleAxios.post(url, school);
  return result.data;
};

export const updateSchool = async (schoolId, school) => {
  const url = `/schools/${schoolId}`;
  await vizzleAxios.put(url, school);
};

export const getExportStudentData = (schoolIds) => {
  const url = `/reporting/users/export?type=Student&schoolIds=${schoolIds.join(',')}`;
  return exportDataAsFile(url);
};

export const getExportTeahertData = (schoolIds) => {
  const url = `/reporting/users/export?type=Teacher&schoolIds=${schoolIds.join(',')}`;
  return exportDataAsFile(url);
};

export const schoolBatchUpload = (districtId, file) => {
  const url = `/schools/${districtId}/batch`;
  return uploadFile(url, file);
};

const getActivityParamString = (withActivity, withoutActivity, teacherId) => {
  if (teacherId) {
    return teacherId;
  }

  let hasActivity = null;
  if ((withActivity && withoutActivity) || (!withActivity && !withoutActivity)) {
    hasActivity = 'all';
  } else if (withActivity) {
    hasActivity = 'with-activities-only';
  } else if (withoutActivity) {
    hasActivity = 'without-activities-only';
  }
  return hasActivity;
};

export const contactAccountManager = async (accountManagerEmail, message) => {
  const url = `/users/email/accountManager?email=${accountManagerEmail}&message=${message}`;
  await vizzleAxios.post(url, {});
};

const createCriteriaUrlString = (baseUrl, criteria, selectedDates, selectedTeacherId) => {
  const {
    teachersLoggingIn,
    teachersWithoutLogins,
  } = criteria;
  let url = baseUrl;
  const hasActivity = getActivityParamString(teachersLoggingIn, teachersWithoutLogins, selectedTeacherId);
  url = `${url}?filter[teacher]=${hasActivity}`;
  url = ObjectUtils.appendQueryString(criteria.districtIds && criteria.districtIds.join(','), 'filter[vizzleDistrict]', url);
  url = ObjectUtils.appendQueryString(criteria.schoolIds && criteria.schoolIds.join(','), 'filter[district]', url);
  url = ObjectUtils.appendQueryString(criteria.licenseTypeIds && criteria.licenseTypeIds.join(','), 'filter[licenseType]', url);
  url = ObjectUtils.appendQueryString(DateUtils.convertMMddYYYYToYYYYMMdd(selectedDates.startDate), 'filter[startDate]', url);
  url = ObjectUtils.appendQueryString(DateUtils.convertMMddYYYYToYYYYMMdd(selectedDates.endDate), 'filter[endDate]', url);

  return url;
};

export const getDistrictTeachers = async (criteria, selectedDates) => {
  const baseUrl = `${process.env.REACT_APP_REPORTING_API_URL}/users/me/districtTeachers`;
  const url = createCriteriaUrlString(baseUrl, criteria, selectedDates);
  const result = await vizzleAxios.get(url);
  const { data } = result;
  return data.data;
};

export const getActivityReports = async (criteria, selectedDates, selectedTeacherId) => {
  const baseUrl = `${process.env.REACT_APP_REPORTING_API_URL}/reporting/activityReports`;
  const url = createCriteriaUrlString(baseUrl, criteria, selectedDates, selectedTeacherId);
  const result = await vizzleAxios.get(url);
  const { data } = result;
  return data.data;
};

export const getExportDataForAdmin = ({
  selectedTeacherId,
  districtIds,
  schoolIds,
  licenseTypeIds,
  selectedDates,
}) => {
  let url = `${process.env.REACT_APP_REPORTING_API_URL}/reporting/districtDataExport?`;
  url = `${url}filter[teacher]=${selectedTeacherId || 'all'}`;
  url = ObjectUtils.appendQueryString(districtIds && districtIds.join(','), 'filter[vizzleDistrict]', url);
  url = ObjectUtils.appendQueryString(schoolIds && schoolIds.join(','), 'filter[district]', url);
  url = ObjectUtils.appendQueryString(licenseTypeIds && licenseTypeIds.join(','), 'filter[licenseType]', url);
  url = ObjectUtils.appendQueryString(DateUtils.convertMMddYYYYToYYYYMMdd(selectedDates.startDate), 'filter[startDate]', url);
  url = ObjectUtils.appendQueryString(DateUtils.convertMMddYYYYToYYYYMMdd(selectedDates.endDate), 'filter[endDate]', url);
  return exportDataAsFile(url);
};

export const getAdminReportUrl = async () => {
  const url = '/user/rethink-reporting';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getUsersAndLicensesBySchoolId = async (schoolId) => {
  const url = `/districts/${schoolId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const addLicense = async (license) => {
  const url = '/licenses';
  const result = await vizzleAxios.post(url, license);
  return result.data;
};

export const updateLicense = async (licenseId, license) => {
  const url = `/licenses/${licenseId}`;
  const result = await vizzleAxios.put(url, license);
  return result.data;
};

export const updateMultipleLicenses = async (licensesData) => {
  const url = '/licenses/batch';
  const result = await vizzleAxios.put(url, licensesData);
  return result.data;
};

export const getAdmins = async () => {
  const url = '/users/admins';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateAdminsAccess = async (userIds, permissionsData) => {
  const url = '/users/admin/access';
  const payload = {
    addUsers: permissionsData.adminAddUsersAccess,
    dataTab: permissionsData.adminDataAccess,
    schoolsTab: permissionsData.adminSchoolsAccess,
    searchTab: permissionsData.adminSearchAccess,
    studentsTab: permissionsData.adminStudentsAccess,
    teachersTab: permissionsData.adminTeachersAccess,
    userIds,
  };
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const getDistrictAdminAccess = async (userId) => {
  const url = `/users/${userId}/districts/access`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateDistrictAdminSchoolAccess = async (userIds, schoolIds) => {
  if (!schoolIds || schoolIds.length === 0) {
    return {};
  }
  const url = '/users/districts/access';
  const payload = {
    schoolIds,
    userIds,
  };
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const removeDistrictAdminSchoolAccess = async (userId, schoolIds) => {
  if (!schoolIds || schoolIds.length === 0) {
    return {};
  }
  const url = `/users/${userId}/districts/access?schoolIds=${schoolIds.join(',')}`;
  const result = await vizzleAxios.delete(url);
  return result.data;
};

export const searchDataByCriteria = async (type, {
  username,
  firstName,
  lastName,
}) => {
  let url = `users/search?type=${type}`;
  url = ObjectUtils.appendQueryString(username, 'userName', url);
  url = ObjectUtils.appendQueryString(firstName, 'firstName', url);
  url = ObjectUtils.appendQueryString(lastName, 'lastName', url);

  const result = await vizzleAxios.get(url);
  return result.data;
};

export const moveTeachers = async (moveStudent, schoolId, teacherIds) => {
  const url = `/users/move?move_students=${moveStudent}`;
  const payload = {
    userIds: teacherIds,
    schoolId,
  };
  await vizzleAxios.put(url, payload);
};

export const moveStudents = async (schoolId, studentIds) => {
  const url = '/users/move';
  const payload = {
    userIds: studentIds,
    schoolId,
  };
  await vizzleAxios.put(url, payload);
};

export const addStudent = async (newStudent) => {
  const url = '/users?disableAutomaticallyAssignCreatedStudent=true&from_admin=true';
  const result = await vizzleAxios.post(url, newStudent);
  return result.data;
};

export const getUserDataById = async (id) => {
  const url = `/users/${id}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateStudent = async (studentData, addToTeacher) => {
  let url = `/users/${studentData.data.id}?from_admin=true`;
  if (addToTeacher && addToTeacher.length > 0) {
    url = `${url}&add_to_teacher=${addToTeacher}`;
  }
  const result = await vizzleAxios.put(url, studentData);
  return result.data;
};

export const addTeacher = async (teacher) => {
  const url = `/users?from_admin=true&source=${process.env.REACT_APP_CREATE_TEACHER_PARAM}`;
  const result = await vizzleAxios.post(url, teacher);
  return result.data;
};

export const updateTeachers = async (teacherData, moveStudent) => {
  let url = `/users/${teacherData.data.id}?from_admin=true`;
  if (moveStudent) {
    url = `${url}&move_students=true`;
  }
  const result = await vizzleAxios.put(url, teacherData);
  return result.data;
};

export const removeStudents = async (payload) => {
  const url = '/users/status';
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const makeUsersActive = async (userIds, schoolId, activeFlag) => {
  const url = '/users/status';
  const payload = {
    userIds,
    schoolId,
    status: activeFlag ? 'Active' : 'Inactive',
  };
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const resetTeachersPassword = async (userIds) => {
  const url = `/users/reset/pwd?source=${process.env.REACT_APP_CREATE_TEACHER_PARAM}`;
  const result = await vizzleAxios.put(url, { userIds });
  return result.data;
};

export const getTeacherSchoolAccess = async (userId) => {
  const url = `/users/${userId}/school/access`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const teacherUpdateSchools = async (userIds, schoolIds) => {
  if (!userIds || userIds.length === 0 || !schoolIds || schoolIds.length === 0) {
    return null;
  }
  const url = '/users/school/access';
  const payload = {
    userIds,
    schoolIds,
  };
  const result = await vizzleAxios.put(url, payload);
  return result.data;
};

export const removeTeacherSchoolAccess = async (userId, schoolIds) => {
  if (!userId || !schoolIds || schoolIds.length === 0) {
    return null;
  }

  const url = `/users/${userId}/school/access?schoolIds=${schoolIds.join(',')}`;
  const result = await vizzleAxios.delete(url);
  return result.data;
};

export const teachersBatchUpload = async (schoolId, file) => {
  const url = `/users?type=Teacher&district=${schoolId}&source=${process.env.REACT_APP_CREATE_TEACHER_PARAM}`;
  return uploadFile(url, file);
};

export const studentsBatchUpload = async (districtId, file) => {
  const url = `/users?type=Student&district=${districtId}`;
  return uploadFile(url, file);
};

export const getExternalUsers = async (schoolId) => {
  const url = `/eui/${schoolId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const moveExternalUsers = async (userId, schoolId) => {
  const url = `/eui/${userId}`;
  const result = await vizzleAxios.put(url, {
    schoolId,
  });
  return result.data;
};

export const getSaleForceInfo = async (districtId) => {
  const url = `/districts/${districtId}/sfinfo`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const updateSaleForceInfo = async (districtId, data) => {
  const url = `/districts/${districtId}/sfinfo`;
  const result = await vizzleAxios.put(url, data);
  return result.data;
};

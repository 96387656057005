import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Fade,
} from '@mui/material';
import arrow from '../../../../../../static/images/activities/game/arrow.png';
import './PlayerTurn.scss';
import { useScreenReader } from '../../../../../screen-reader/ScreenReaderHooks';
import { useRegisterSwitchAccessScanEvent } from '../../../../../switch-access/SwitchAccessHooks';

const PlayerTurn = forwardRef(({
  currentPlayer,
  handleOnClickPlayerTurn,
  numberOfgameCards,
  background,
}, ref) => {
  const componentRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElement: () => (componentRef.current ? componentRef.current : null),
  }));

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  useRegisterSwitchAccessScanEvent(componentRef, () => {
    readWhenScreenReaderIsOn(componentRef.current);
  });

  const playerTurnText = `It's your turn ${currentPlayer.name}, click here to spin the wheel`;
  return (
    <Fade timeout={1000} in>
      <Box
        className='player-turn'
        onClick={handleOnClickPlayerTurn}
        ref={componentRef}
        alt={playerTurnText}
        data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
        data-test='player-turn'
      >
        <div className='arrow-icon-container' data-test='arrow-icon-container'>
          <img
            className='arrow-icon'
            src={arrow}
            alt='Arrow'
          />
        </div>
        <div className='player-turn-icon' data-test='player-turn-icon'>
          <img
            src={currentPlayer.playerPieceImage}
            alt='Piece'
            style={{ maxHeight: `${numberOfgameCards <= 10 ? '4em' : ''}` }}
          />
        </div>
        <div
          className='player-turn-text'
          data-test='player-turn-text'
          style={{ fontSize: `${numberOfgameCards <= 10 ? '1.2em' : '1.5em'}` }}
        >
          {playerTurnText}
        </div>

        <Box className='spinner-background' data-test='spinner-background'>
          {background}
        </Box>
      </Box>
    </Fade>
  );
});

PlayerTurn.defaultProps = {
  background: null,
};

PlayerTurn.propTypes = {
  currentPlayer: PropTypes.shape({
    name: PropTypes.string,
    playerPieceImage: PropTypes.string,
  }).isRequired,
  numberOfgameCards: PropTypes.number.isRequired,
  handleOnClickPlayerTurn: PropTypes.func.isRequired,
  background: PropTypes.node,
};

export default PlayerTurn;

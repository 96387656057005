import { useState } from 'react';
import {
  // Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import UrlLink from '../../../components/urllink/UrlLink';
import { URL_COURSES } from '../../../routes/RouteConstants';
import './TeacherCourseMenu.scss';
import { /* NON_ACADEMIC_SUBJECTS, */SUBJECT_CONSTANTS } from '../../../constants/LessonConstant';
import { TEACHER_COURSES_ROUTES } from './TeacherCourseConstants';
// import { useDomain } from '../../../states/AppDomainProvider';

export const subjectList = [
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
];

// export const nonAcademicSubjectList = [
//   NON_ACADEMIC_SUBJECTS.transitionalSkills,
//   NON_ACADEMIC_SUBJECTS.socialSkills,
//   SUBJECT_CONSTANTS.life,
// ];

const TeacherCourseMenu = () => {
  // const { userDomain } = useDomain();
  // const { user } = userDomain.domainData;
  // const includeNonAcademicSubject = user.userModerator || user.unitCreator;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { subjectId: currentSelectedSubjectId } = useParams();

  const handleOnSubjectClick = (subjectId) => {
    navigate(`${URL_COURSES.replace(':subjectId', subjectId)}/${TEACHER_COURSES_ROUTES.LESSONS}`);
    handleClose();
  };

  return (
    <div className='course-subject-selector-menu'>
      <UrlLink
        onClick={handleClickListItem}
        title={(
          <div className='title'>
            <div className='course-subject-title'>
              Academic Curriculum
            </div>
            <ExpandMoreIcon className='expand-more' />
          </div>
        )}
        url={URL_COURSES.replace('/:subjectId', '')}
        data-test='Courses'
        dataTest='Courses'
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {subjectList.map((subject) => (
          <MenuItem
            key={`course-subject-menu${subject.subjectId}`}
            onClick={() => handleOnSubjectClick(subject.subjectId)}
            selected={subject.subjectId === currentSelectedSubjectId}
            className='course-subject-menu-item'
            data-test={`course-subject-${subject.subjectId}`}
          >
            {subject.name}
          </MenuItem>
        ))}

        {/* {includeNonAcademicSubject && (
          <>
            <Divider variant='middle' />

            {nonAcademicSubjectList.map((subject) => (
              <MenuItem
                key={`course-subject-menu${subject.subjectId}`}
                onClick={() => handleOnSubjectClick(subject.subjectId)}
                selected={subject.subjectId === currentSelectedSubjectId}
                className='course-subject-menu-item'
                data-test={`course-subject-${subject.subjectId}`}
              >
                {subject.name}
              </MenuItem>
            ))}
          </>
        )} */}
      </Menu>
    </div>
  );
};

export default TeacherCourseMenu;

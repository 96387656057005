import { useEffect } from 'react';
import {
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { getInitialDataForGame } from '../../../../../utils/activitytype/ActivityTypeInitialData';
import CardSelector from '../../../../../components/selector/CardSelector';
import GameThumbnail from '../../../../../components/vizzle/page/game/GameThumbnail';
import VizzleSwitch from '../../../../../components/switchs/VizzleSwitch';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';

const spinnerTypeSelectorValues = [
  {
    value: 1,
    label: 'Numbers',
  },
  {
    value: 3,
    label: 'Colors',
  },
];

const spinnerTypeValue = spinnerTypeSelectorValues.map((v) => v.value);
/**
 * Container for Game activity layout
 */
export default function GameActivityLayoutContainer() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  useEffect(() => {
    if (!spinnerTypeValue.includes(selectedPage.spinner)) {
      const updatedSelectedPage = {
        ...selectedPage,
        spinner: spinnerTypeSelectorValues[0].value,
      };
      lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
    }
  // eslint-disable-next-line
  }, []);

  const props = {
    config: [
      {
        component: (
          <GameThumbnail gameCards={getInitialDataForGame(20).gameCards} />
        ),
        dataValue: 0,
        onClick: (dataValue, index) => {
          Logger.logWhenDebugModeIsOn({
            DATA: dataValue,
            INDEX: index,
          });
        },
      },
    ],
    width: window.innerWidth <= 1024 ? '49vw' : '42vw',
    height: '54vh',
  };

  const handleSpinnerSelected = (event) => {
    Logger.logWhenDebugModeIsOn({
      SPINNER: event.target.value,
    });
    const updatedSelectedPage = {
      ...selectedPage,
      spinner: event.target.value,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  const handleSwitchChange = (event) => {
    const updatedSelectedPage = {
      ...selectedPage,
      moveToCardIndicator: event.target.checked,
    };
    lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, updatedSelectedPage);
  };

  return (
    <Box>
      <Box display='flex' mt='15px'>
        <CardSelector
          {...props}
          selectedIndex={0}
          className='vizzle-matching-layout'
        />

        <Box display='flex' width='20vw' flexDirection='column'>
          <Box ml='15px' data-test='game-spinner-type'>
            <Typography variant='h6'>Spinner Type</Typography>
            <TextField
              select
              value={selectedPage.spinner}
              variant='outlined'
              fullWidth
              className='vizzle-lesson-game-activity-spinner-type-selector'
              onChange={handleSpinnerSelected}
            >
              {spinnerTypeSelectorValues.map((element, index) => (
                <MenuItem key={`vizzle-lesson-game-activity-spinner-type-selector-${index}`} value={element.value}>
                  {element.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box mt='15px' ml='15px' data-test='game-prompt-toggle'>
            <Typography variant='h6'>Prompt to move Game Piece?</Typography>
            <VizzleSwitch
              checked={selectedPage.moveToCardIndicator}
              labelWhenSelected='Yes'
              labelWhenUnselected='No'
              onChange={handleSwitchChange}
              className='vizzle-peding-lesson-switch'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

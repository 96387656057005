import {
  Route,
} from 'react-router-dom';
import SetupTwoFactorAuthentication from '../../containers/login/mfa/SetupTwoFactorAuthentication';
import EnterTwoFactorAuthentication from '../../containers/login/mfa/EnterTwoFactorAuthentication';
import TermsOfUse from '../../containers/login/tos/TermsOfUse';
import { PrivateRoute } from '../PrivateRoute';
import { TwoFactorPrivateRoute } from '../TwoFactorPrivateRoute';

import {
  URL_SETUP_TWO_FACTOR_AUTH,
  URL_ENTER_TWO_FACTOR_AUTH,
  URL_TERMS_OF_USE,
} from '../RouteConstants';

const getMfaRoutes = () => [
  { path: URL_SETUP_TWO_FACTOR_AUTH, element: <SetupTwoFactorAuthentication /> },
  { path: URL_ENTER_TWO_FACTOR_AUTH, element: <EnterTwoFactorAuthentication /> },
  { path: URL_TERMS_OF_USE, element: <TermsOfUse /> },
];

export const generateMfaRoutes = (availableRoutes, user) => {
  const routes = getMfaRoutes();
  const mfaRoutes = routes.map((route) => (<Route key={`route-${route.path}`} path={route.path} element={<PrivateRoute {...route} />} />));
  const InternalRoute = process.env.REACT_APP_SKIP_MFA === 'true' ? PrivateRoute : TwoFactorPrivateRoute;
  const appRoutes = availableRoutes.filter((a) => a.path !== URL_TERMS_OF_USE).map((route) => (
    <Route key={`route-${route.path}`} path={route.path} element={<InternalRoute element={route.element} user={user} />} />));
  return [
    ...mfaRoutes,
    ...appRoutes,
  ];
};
export default getMfaRoutes;

import { useDomain } from '../../../../states/AppDomainProvider';
import { archiveLessons } from '../../../../services/LessonService';
import { useStudentContext } from '../../../mystudent/context/MyStudentContextProvider';
import { useMyLessonsContext } from '../../../myclassroom/components/my-lessons/context/MyLessonsContextProvider';
import {
  useGetRemoteServiceWrapper,
} from '../../../hooks/RemoteServiceHooks';

const getSuccessfulMessage = (lessons, archiveFlag, customSuccessMessage) => {
  const defaultMessage = lessons.length === 1
    ? `Activity ${lessons[0].id} has been ${archiveFlag ? 'archived' : 'unarchived'}.`
    : `${lessons.length} activities have been ${archiveFlag ? 'archived' : 'unarchived'}.`;
  const successMessage = customSuccessMessage || defaultMessage;
  return successMessage;
};

export const useArchiveLessonInObjectiveForStudent = () => {
  const { uiDomain, studentsDomain } = useDomain();
  const { myStudentDomain } = useStudentContext();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { masterObjectiveData } = myStudentDomain.domainData;

  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const archiveLesson = async (lessons, archiveFlag, customSuccessMessage) => {
    await callRemoteServiceWrapper(async () => {
      const lessonIds = lessons.map((l) => l.id);
      await archiveLessons(lessonIds, archiveFlag, currentSelectedStudentId);

      const lessonsToUpdate = lessons.map((l) => masterObjectiveData.included.find((i) => (
        i.type === l.type && i.id === l.id
      )));

      lessonsToUpdate.forEach((l) => {
        // eslint-disable-next-line no-param-reassign
        l.meta.archived = archiveFlag;
        if (!archiveFlag) {
          // eslint-disable-next-line no-param-reassign
          l.meta.playable = true;
        }
      });
      const successMessage = getSuccessfulMessage(lessons, archiveFlag, customSuccessMessage);
      await uiDomain.showSnackbar(true, 'success', 3000, successMessage);
    });

    // Should be done in the last step in background
    myStudentDomain.updateMasteryObjectives([...masterObjectiveData.masterObjectives], [...masterObjectiveData.included]);
  };

  return {
    archiveLesson,
    isArchiving: loading,
  };
};

export const useArchiveLessonForStudent = () => {
  const { uiDomain, studentsDomain } = useDomain();
  const { myStudentDomain } = useStudentContext();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { lessonData } = myStudentDomain.domainData;

  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const archiveLesson = async (lessons, archiveFlag, customSuccessMessage) => {
    await callRemoteServiceWrapper(async () => {
      const lessonIds = lessons.map((l) => l.id);
      await archiveLessons(lessonIds, archiveFlag, currentSelectedStudentId);

      lessons.forEach((l) => {
        // eslint-disable-next-line no-param-reassign
        l.meta.archived = archiveFlag;
        if (!archiveFlag) {
          // eslint-disable-next-line no-param-reassign
          l.meta.playable = true;
        }
      });
      const successMessage = getSuccessfulMessage(lessons, archiveFlag, customSuccessMessage);
      await uiDomain.showSnackbar(true, 'success', 3000, successMessage);
    });

    // Should be done in the last step in background
    myStudentDomain.updateLessonsAndSelectedLessonIds([...lessonData.lessons], lessonData.included, []);
  };

  return {
    archiveLesson,
    isArchiving: loading,
  };
};

export const useArchiveLessonForTeacher = () => {
  const { uiDomain, userDomain } = useDomain();
  const { myLessonsDomain } = useMyLessonsContext();
  const { user } = userDomain.domainData;
  const { lessonData } = myLessonsDomain.domainData;

  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const archiveLesson = async (lessons, archiveFlag, customSuccessMessage) => {
    await callRemoteServiceWrapper(async () => {
      const lessonIds = lessons.map((l) => l.id);
      await archiveLessons(lessonIds, archiveFlag, user.id);

      lessons.forEach((l) => {
        // eslint-disable-next-line no-param-reassign
        l.meta.archived = archiveFlag;
      });

      const successMessage = getSuccessfulMessage(lessons, archiveFlag, customSuccessMessage);
      await uiDomain.showSnackbar(true, 'success', 3000, successMessage);
    });

    // Should be done in the last step in background
    myLessonsDomain.updateLessonsAndSelectedLessonIds([...lessonData.lessons], lessonData.included, []);
  };

  return {
    archiveLesson,
    isArchiving: loading,
  };
};

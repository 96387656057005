import BaseDomain from './BaseDomain';

const AUTHORED_LESSONS_UPDATE_LESSONS_ACTION = 'AUTHORED_LESSONS_UPDATE_LESSONS_ACTION';
const AUTHORED_LESSONS_UPDATE_INCLUDED_ACTION = 'AUTHORED_LESSONS_UPDATE_INCLUDED_ACTION';
const AUTHORED_LESSONS_UPDATE_LOADED_FLAG = 'AUTHORED_LESSONS_UPDATE_LOADED_FLAG';
const AUTHORED_LESSONS_UPDATE_LESSON_COURSES = 'AUTHORED_LESSONS_UPDATE_LESSON_COURSES';
const AUTHORED_LESSONS_SEARCH_LESSONS_DATA = 'AUTHORED_LESSONS_SEARCH_LESSONS_DATA';

const initialState = {
  authoredLessons: [],
  includedData: [],
  lessonCourses: [],
  isLoadedFlag: false,
  searchLessonsData: {},
  selectedPendingStatus: null,
  courseId: null,
  topicId: null,
  unitId: null,
  filteredText: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case AUTHORED_LESSONS_UPDATE_LESSONS_ACTION:
      return {
        ...state,
        authoredLessons: action.payload.authoredLessons,
        includedData: action.payload.includedData,
      };
    case AUTHORED_LESSONS_UPDATE_INCLUDED_ACTION:
      return {
        ...state,
        includedData: action.payload,
      };
    case AUTHORED_LESSONS_UPDATE_LESSON_COURSES:
      return {
        ...state,
        lessonCourses: action.payload,
      };
    case AUTHORED_LESSONS_UPDATE_LOADED_FLAG:
      return {
        ...state,
        isLoadedFlag: action.payload.isLoadedFlag,
      };
    case AUTHORED_LESSONS_SEARCH_LESSONS_DATA:
      return {
        ...state,
        searchLessonsData: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'authoredLessonDomain';
/**
 * Domain class for Authored Lessons
 *
 * @export
 * @class Authored Lesson Domain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  /**
   * Update authored lessons
   * @param authoredLessons to update
   * @param includedData additional data for lessons
   */
  async updateAuthoredLessons(authoredLessons, includedData = []) {
    return this.dispatch({
      type: AUTHORED_LESSONS_UPDATE_LESSONS_ACTION,
      payload: { authoredLessons, includedData },
    });
  }

  /**
   * Update included data
   *
   * @param {*} includedData
   */
  async updatedIncludedData(includedData = []) {
    return this.dispatch({
      type: AUTHORED_LESSONS_UPDATE_INCLUDED_ACTION,
      payload: includedData,
    });
  }

  /**
   * Update loaded flag
   * @param isLoadedFlag whether or not the data has been loaded once
   */
  async updatteLoadedFlag(isLoadedFlag) {
    return this.dispatch({
      type: AUTHORED_LESSONS_UPDATE_LOADED_FLAG,
      payload: { isLoadedFlag },
    });
  }

  async updateLessonCourses(lessonCourses) {
    return this.dispatch({
      type: AUTHORED_LESSONS_UPDATE_LESSON_COURSES,
      payload: lessonCourses,
    });
  }

  async updateSearchLessonsData(searchLessonsData) {
    return this.dispatch({
      type: AUTHORED_LESSONS_SEARCH_LESSONS_DATA,
      payload: searchLessonsData,
    });
  }
}

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import { ThemeProvider } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import DefaultImage from '../../static/images/default_thumbnail.png';
import './LessonCard.scss';
import ObjectUtils from '../../utils/ObjectUtils';
import materialTheme from '../../materialUITheme';

const cardRatio = 1.24;
const imageRatio = 0.4;

/**
 * Lesson card for displaying a lesson
 *
 * @param {*} prop
 * lessonData - data to display on the card
 * width - the width of the card
 * actions - array of action on the card. The shape of the object is
 * {
 *  icon: PropTypes.node - the icon of the action to display on the card,
 *  action: PropTypes.func - action handler of the action,
 * }
 */
export default function LessonCard({ lessonData, width, actions, isHighligthed, cardLabels, showLessonDescription, showLessonBadges }) {
  const cardHeight = width * cardRatio;
  const imageHeight = imageRatio * cardHeight;
  const { attributes } = lessonData;

  return (
    <ThemeProvider theme={materialTheme}>
      <Card
        theme={materialTheme}
        data-lessonuuid={lessonData.attributes.uuid}
        className={`lesson-card-container ${isHighligthed ? 'highlighted' : ''}`}
        style={{ width, height: cardHeight }}
        data-test={`lesson-card-${lessonData.attributes.uuid}`}
      >
        <CardActionArea className='lesson-card-container-action-area'>
          {lessonData.attributes.hasVideo && lessonData.attributes.videoUrl ? (
            <div className='lesson-card-video' style={{ height: imageHeight }}>
              <video src={`${lessonData.attributes.videoUrl}#t=0.1`} type='video/mp4'>
                <track kind='captions' src={`${lessonData.attributes.videoUrl}#t=0.1`} />
              </video>
            </div>
          ) : (
            <CardMedia
              style={{ height: imageHeight }}
              image={attributes.thumbnailUrl ? attributes.thumbnailUrl : DefaultImage}
              title={attributes.name}
              data-test='lesson-card-media'
            />
          )}
          <CardContent className='lesson-card-content'>
            {!lessonData.specialtyContent || ObjectUtils.isEmpty(lessonData.specialtyContent) ? (
              ''
            ) : (
              <img
                className='lesson-card-specialty-icon'
                src={lessonData.specialtyContent.cardImage}
                alt='Specialty Logo'
                data-test='lesson-card-specialty-icon'
              />
            )}
            <div className='arrow-up'>&nbsp;</div>
            <Typography gutterBottom variant='h5' component='h2' className='lesson-card-title' data-test='lesson-card-title'>
              {attributes.name}
            </Typography>
            {showLessonDescription && <p className='lesson-card-description'>{attributes.description}</p>}
          </CardContent>
        </CardActionArea>
        {showLessonBadges && attributes.subjects && attributes.subjects.length > 0 ? (
          <div className='badges'>
            <div className='badge'>{attributes.subjects.map((subject) => (subject === 'English Language Arts' ? 'ELA' : subject))}</div>
          </div>
        ) : (
          ''
        )}

        <div className='lesson-labels'>{cardLabels.map((label) => label)}</div>

        <div className='lesson-action-overlay'>{actions.map((action) => action)}</div>
      </Card>
    </ThemeProvider>
  );
}

LessonCard.defaultProps = {
  width: 205,
  actions: [],
  isHighligthed: false,
  cardLabels: [],
  showLessonDescription: true,
  showLessonBadges: true,
};

LessonCard.propTypes = {
  lessonData: PropTypes.shape({
    attributes: PropTypes.object,
    specialtyContent: PropTypes.object,
  }).isRequired,
  width: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.node),
  isHighligthed: PropTypes.bool,
  cardLabels: PropTypes.arrayOf(PropTypes.node),
  showLessonDescription: PropTypes.bool,
  showLessonBadges: PropTypes.bool,
};

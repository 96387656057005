import {
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import {
  useHandleOnSearch, useRefreshDataWhenEnter,
} from './SearchActionHooks';

const SearchAction = ({
  searchCriteria,
}) => {
  const {
    courseId,
    unitId,
    topicId,
    lessonLevel,
  } = searchCriteria;

  const {
    handleOnSearch,
  } = useHandleOnSearch();

  useRefreshDataWhenEnter({
    handleOnSearch,
    searchCriteria,
  });
  return (
    <Button
      variant='contained'
      className='btn-primary search-button'
      onClick={() => { handleOnSearch({ searchCriteria }); }}
      disabled={!courseId || !unitId || !topicId || !lessonLevel}
      startIcon={<SearchIcon />}
      data-test='search-action'
    >
      Search
    </Button>
  );
};

SearchAction.propTypes = {
  searchCriteria: PropTypes.shape({
    courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lessonLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default SearchAction;

import PropTypes from 'prop-types';
import { Box, FormControl, Paper, TextField } from '@mui/material';
import './ScoreAnswerForm.scss';
import PrintCard from '../../../../../../../../components/vizzle/page/common/print/PrintCard';
import ButtonWithSpinner from '../../../../../../../../components/buttons/ButtonWithSpinner';
import { useAddComment, useUpdateComment, useHandleCommentField } from './ScoreAnswerFormHooks';
import FfImageList from '../../../../../../../lessonplay/activities/common/free-text-response/components/FfImageList';
import { sort } from '../../../../../../../../utils/ArrayUtils';
import VizzleSnackbar from '../../../../../../../../components/snackbar/VizzleSnackbar';
import { useHandleSnackbar } from './FreeFormAnswerHooks';

const ScoreAnswerForm = ({
  answerLesson,
  answerPageData,
  parentAnswerFreeFormData,
  pageNumber,
  backgroundImage,
  onSaveSuccess,
  readOnly,
  onClose,
}) => {
  const { attributes: answerPageDataAttributes } = answerPageData;
  const {
    comments,
    handleOnCommentsChange,
  } = useHandleCommentField({
    initComments: answerPageDataAttributes && answerPageDataAttributes.comments,
  });

  const {
    snackbar,
    onSnackbarClose,
    openSnackbar,
  } = useHandleSnackbar();

  const {
    isSaving,
    updateComment,
  } = useUpdateComment({
    answerPageData,
    onSaveSuccess,
    onSnackbarClose,
    openSnackbar,
  });

  const {
    isSaving: isAddSaving,
    addComment,
  } = useAddComment({
    answerLesson,
    answerPageData,
    pageNumber,
    onSaveSuccess,
    onSnackbarClose,
    openSnackbar,
  });

  const hasFreeformAnswer = answerPageDataAttributes.answer && (answerPageDataAttributes.answer.image || answerPageDataAttributes.answer.text);

  const getCardContent = () => {
    if (!hasFreeformAnswer) {
      return null;
    }

    if (answerPageDataAttributes.answer.ffImages && answerPageDataAttributes.answer.ffImages.length > 0) {
      const sortFfImages = sort(answerPageDataAttributes.answer.ffImages, 'index').map((i) => i && i.url);
      return (
        <Box
          width='100%'
          height='100%'
        >
          <FfImageList imageList={sortFfImages} />
        </Box>
      );
    }

    return (
      <PrintCard
        className='answer-card-container'
        image={answerPageDataAttributes.answer.image ? backgroundImage : null}
        innerImage={answerPageDataAttributes.answer.image}
        text={answerPageDataAttributes.answer.text}
        fontScale={1}
      />
    );
  };

  const parentComments = parentAnswerFreeFormData && parentAnswerFreeFormData.attributes && parentAnswerFreeFormData.attributes.comments;

  return (
    <div className={`score-answer-form ${!hasFreeformAnswer ? 'no-answer' : ''}`} data-test='score-answer-form'>
      <div className='score-answer-container'>
        <div className='score-answer-form-answer-paper-container' data-test='score-answer-form-answer-paper-container'>
          <Paper className='score-answer-form-answer-paper' elevation={3} data-test='score-answer-form-answer-paper'>
            <div className='answer-card-comment-container'>
              <FormControl variant='outlined' className='form-container'>
                <TextField
                  multiline
                  minRows={15}
                  maxRows={25}
                  label='Comments'
                  value={comments}
                  name='message'
                  onChange={handleOnCommentsChange}
                  autoFocus
                  className='message-field message-field-edit'
                  data-private='redact'
                  InputProps={{
                    readOnly,
                    'data-private': 'redact',
                  }}
                  data-test='comments-text-area'
                />

                {!readOnly && parentAnswerFreeFormData && parentAnswerFreeFormData.id && (
                  <TextField
                    multiline
                    minRows={15}
                    maxRows={25}
                    label='Most Recent Comments'
                    value={parentComments}
                    className='message-field'
                    data-private='redact'
                    InputProps={{
                      readOnly: true,
                      'data-private': 'redact',
                    }}
                    data-test='most-recent-comments-text-area'
                  />
                )}
                {readOnly ? (
                  <ButtonWithSpinner
                    className='btn-danger comment-apply-button'
                    onClick={() => {
                      onClose();
                    }}
                    label='Close'
                    data-test='close-review-action'
                  />
                ) : (
                  <ButtonWithSpinner
                    onClick={() => {
                      if (answerPageData.id) {
                        updateComment({ comments });
                      } else {
                        addComment({ comments });
                      }
                    }}
                    loading={isSaving || isAddSaving}
                    className='btn-primary comment-apply-button'
                    disabled={isSaving || isAddSaving || !comments}
                    label='Apply'
                    data-test='apply-review-action'
                  />
                )}

              </FormControl>
              {getCardContent()}
            </div>
          </Paper>
        </div>
      </div>
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </div>
  );
};

ScoreAnswerForm.defaultProps = {
  answerLesson: { attributes: {} },
  answerPageData: { attributes: {} },
  parentAnswerFreeFormData: { attributes: {} },
  pageNumber: 1,
  backgroundImage: null,
  onSaveSuccess: () => null,
  readOnly: false,
  onClose: () => null,
};

ScoreAnswerForm.propTypes = {
  answerLesson: PropTypes.any,
  answerPageData: PropTypes.any,
  parentAnswerFreeFormData: PropTypes.any,
  pageNumber: PropTypes.number,
  backgroundImage: PropTypes.string,
  onSaveSuccess: PropTypes.func,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ScoreAnswerForm;

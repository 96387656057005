import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import './ActivityInstructionDialog.scss';

export default function ActivityInstructionDialog({
  id,
  instructions,
  open,
  onClose,
  audioPlaying,
}) {
  return open ? (
    <Dialog open={open} data-test='activity-instruction-dialog' className={`activity-instruction-dialog ${audioPlaying ? 'activity-instruction-dialog-with-audio' : ''}`}>
      <IconButton
        aria-label='close instructions'
        className='close-button'
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className='content'>
        {
          instructions.text ? <TextLines componentKey={id} textLines={instructions.text} /> : ''
        }
      </DialogContent>
    </Dialog>
  ) : null;
}

ActivityInstructionDialog.defaultProps = {
  id: null,
  instructions: {},
  open: false,
  onClose: () => {},
  audioPlaying: false,
};

ActivityInstructionDialog.propTypes = {
  id: PropTypes.string,
  instructions: PropTypes.shape({
    text: PropTypes.object,
    audio: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  audioPlaying: PropTypes.bool,
};

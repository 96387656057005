import BaseDomain from '../../../states/domains/BaseDomain';

const STUDENTS_UPDATE_SELECTED_STUDENTS_IDS = 'STUDENTS_UPDATE_SELECTED_STUDENTS_IDS';
const STUDENTS_UPDATE_STUDENTS_ACTIVE = 'STUDENTS_UPDATE_STUDENTS_ACTIVE';

const initialState = {
  selectedStudentIds: [],
  filterActive: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case STUDENTS_UPDATE_SELECTED_STUDENTS_IDS:
      return {
        ...state,
        selectedStudentIds: action.payload,
      };
    case STUDENTS_UPDATE_STUDENTS_ACTIVE:
      return {
        ...state,
        filterActive: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'adminStudentsDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSelectedStudentIds(studentIds) {
    return this.dispatch({
      type: STUDENTS_UPDATE_SELECTED_STUDENTS_IDS,
      payload: studentIds,
    });
  }

  async updateFilterActive(isActive) {
    return this.dispatch({
      type: STUDENTS_UPDATE_STUDENTS_ACTIVE,
      payload: isActive,
    });
  }
}

/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import BookmarkLessonAction from '../../../../../lessons/lessonactions/BookmarkLessonAction';
import { useTeacherCourses } from '../../../../context/TeacherCourseContextProvider';

/**
 * Share Link Lesson Action Container
 */
export default function TeacherCourseLessonBookmarkAction({ lesson, className, showLabel }) {
  const { teacherCourseDomain } = useTeacherCourses();
  const {
    lessonData,
  } = teacherCourseDomain.domainData;
  return (
    <BookmarkLessonAction
      lesson={lesson}
      showLabel={showLabel}
      className={className}
      onSaveSuccess={() => {
        // eslint-disable-next-line no-param-reassign
        lesson.meta.assigned = true;
        // eslint-disable-next-line no-param-reassign
        lesson.meta.archived = false;
        teacherCourseDomain.updateLessonsData([...lessonData.lessons], lessonData.included);
      }}
    />
  );
}

TeacherCourseLessonBookmarkAction.defaultProps = {
  showLabel: false,
  className: '',
};

TeacherCourseLessonBookmarkAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
};

import PropTypes from 'prop-types';
import ArchiveUnarchiveObjectiveDialog from './dialog/ArchiveUnarchiveObjectiveDialog';
import { useArchiveUnarchiveObjective } from './ObjectiveDetailActionHooks';
import './ObjectiveDetailAction.scss';
import ButtonWithSpinner from '../../../../../components/buttons/ButtonWithSpinner';

const ArchiveObjectiveAction = ({
  objective,
  rowIndex,
  onSaveSuccess,
}) => {
  const {
    archivedObjective,
    tryArchive,
    archiveUnarchiveLessonDialog,
    setArchiveUnarchiveLessonDialog,
  } = useArchiveUnarchiveObjective(objective, rowIndex, onSaveSuccess);

  return (
    <>
      <ButtonWithSpinner
        className='objective-detail-action btn-secondary'
        variant='contained'
        color='primary'
        onClick={() => { tryArchive(true); }}
        label={(
          <>
            Archive
            <br />
            Goal/Obj
          </>
        )}
      />
      {archiveUnarchiveLessonDialog.showDialog ? (
        <ArchiveUnarchiveObjectiveDialog
          open={archiveUnarchiveLessonDialog.showDialog}
          message={(
            <div>
              This goal/objective will be archived.
              <br />
              Do you also want to delete all assignments for associated lessons?
            </div>
          )}
          onYesClick={() => { archivedObjective(true); }}
          onNoClick={() => { archivedObjective(true, false); }}
          onClose={() => { setArchiveUnarchiveLessonDialog({ showDialog: false }); }}
        />
      ) : null}
    </>
  );
};

ArchiveObjectiveAction.defaultProps = {
  onSaveSuccess: () => { },
};

ArchiveObjectiveAction.propTypes = {
  objective: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }).isRequired,
  onSaveSuccess: PropTypes.func,
  rowIndex: PropTypes.number.isRequired,
};

export default ArchiveObjectiveAction;

import { useEffect } from 'react';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import { useDomain } from '../../states/AppDomainProvider';
import { createSessionTimeoutEventListener } from '../../services/VizzleEvents';
import Logger from '../../utils/Logger';

/**
 * Vizzle Event listener
 * To listen the vizzle events from VizzleEvents and
 * perform domain update depending upon the listener and domain data.
 *
 * @export
 * @returns
 */
export default function VizzleEventListenerContainer() {
  const { uiDomain } = useDomain();

  // Example
  useEffect(() => {
    /** Listener when the user session expires */
    createSessionTimeoutEventListener(() => {
      Logger.logWhenDebugModeIsOn('User session has expired');
      uiDomain.showNotificationDialog(true, 'Session Expired', MoodBadIcon, 'Your session has expired. Please login again.', 'Ok');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div />
  );
}

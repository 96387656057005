import vizzleAxios from './service';

/**
 * Get Authoring profile of the user
 */
export const getAuthoringProfile = async () => {
  const url = '/users/authoring_profile';
  const result = await vizzleAxios.get(url);
  return result.data;
};

/**
 * Update Authoring profile of the user
 */
export const updateAuthoringProfile = async (profile) => {
  const url = '/users/authoring_profile';
  const result = await vizzleAxios.put(url, { data: profile });
  return result;
};

/** Dedice the server node to connect to */
const createSearchNode = () => {
  const nodes = [
    '_primary_first',
    '_replica_first',
  ];
  const randomItem = nodes[Math.floor(Math.random() * nodes.length)];
  return randomItem;
};

/**
 * Get user profie data
 * @param {id} id of the user
 */
export const getUserProfile = async (id) => {
  const url = `/user/profile/${id}`;
  const result = await vizzleAxios.get(url);
  result.data.searchNode = createSearchNode();
  return result.data;
};

/**
 * Update class profile for all students
 * @param {profile} profile to update
 */
export const updateClassProfile = async (profile) => {
  const url = '/user/profile/batch/students';
  const result = await vizzleAxios.post(url, profile);
  return result;
};

export const disableAllReinforcers = async (studentIds) => {
  const url = '/users/reinforcers';
  const result = await vizzleAxios.post(url, studentIds);
  return result;
};

export const disableAllCelebrations = async (studentIds) => {
  const url = '/users/celebrations';
  const result = await vizzleAxios.post(url, studentIds);
  return result;
};

/**
 *  Update user profile data
 */
export const updateUserProfile = async (id, userProfile) => {
  const url = `/user/profile/${id}`;
  const result = await vizzleAxios.post(url, userProfile);
  return result;
};

export const getTerminologies = async (userId) => {
  const url = `/terminologies?targetUserId=${userId}&mode=lottie`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const acceptTermsOfUse = async () => {
  const url = '/user/acceptance_of_terms_of_user';
  const result = await vizzleAxios.post(url, {});
  return result;
};

export const getTerminologiesWithNoUser = async () => {
  const url = '/terminologies?mode=lottie';
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getAppVersion = async () => {
  const url = '/terminologies/version';
  const result = await vizzleAxios.get(url);
  return result.data;
};

import { useId } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup } from '@mui/material';
import './MultipleChoiceComponent.scss';
import './MultipleChoiceActivityPrint.scss';
import { getMultipleChoicePrefix } from './MultipleChoiceUtils';
import PrintCard from '../common/print/PrintCard';

/**
 * Lesson activity for Multiple Choices
 */
const MultipleChoiceActivityPrint = ({
  questionCard,
  answerCards,
  singlePage,
}) => {
  const id = useId();
  return (
    <Paper className={`multiple-choice-component ${singlePage ? 'single-page' : 'side-by-side'} multiple-choice-activity-print`}>
      <div className='multiple-choice-component-question'>
        <PrintCard
          className='multiple-choice-component-question-card'
          text={questionCard.text}
          image={questionCard.image}
          video={questionCard.video}
          audio={questionCard.audio}
          data-test='multiple-choice-question'
        />
      </div>
      <FormControl
        className='multiple-choice-component-responses-form'
      >
        <RadioGroup
          name={`multiple-choice-${id}`}
          className='multiple-choice-component-responses vizzle-radio'
          defaultValue=''
        >
          {answerCards.map((card, index) => {
            const key = `multiple-choice-answer-key-${id}-${index}-${card.answerKey}`;
            return (
              <Paper
                key={key}
                className='multiple-choice-component-response-container'
                elevation={2}
              >
                <div className='prefix'>
                  {getMultipleChoicePrefix(index)}.
                </div>
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={(
                    <div className='multiple-choice-component-response-container'>
                      <PrintCard
                        className='multiple-choice-component-response'
                        text={card.text}
                        data-test={key}
                      />
                    </div>
                  )}
                />
              </Paper>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default MultipleChoiceActivityPrint;

MultipleChoiceActivityPrint.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}, {}, {}, {}],
  singlePage: false,
};

MultipleChoiceActivityPrint.propTypes = {
  questionCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    questionKey: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    answerKey: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  singlePage: PropTypes.bool,
};

import { Button } from '@mui/material';
import { useState } from 'react';
import CustomDialog from '../../../../../../../../components/dialog/CustomDialog';
import './ApplyTextStyleAction.scss';
import FormContainer from './components/FormContainer';

export default function ApplyTextStyleAction() {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOnClick = () => {
    setOpenDialog(true);
  };

  const handleOnDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button
        variant='contained'
        className='btn-primary apply-text-style-action'
        onClick={handleOnClick}
        data-test='email-lesson-plan-action'
      >
        Apply style in Lesson
      </Button>
      {openDialog && (
        <CustomDialog
          openDialog={openDialog}
          className='apply-text-style-action-dialog'
          onClose={handleOnDialogClose}
          title='Apply style to all pages in the lesson'
          content={(
            <div className='content'>
              <FormContainer />
            </div>
          )}
          displayCloseIcon
        />
      )}
    </>
  );
}

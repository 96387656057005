import { useEffect } from 'react';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { getLessonCourses } from '../../../../../services/CourseService';
import { sort } from '../../../../../utils/ArrayUtils';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { FILTER_STATUS } from '../AuthoredLessonHooks';
import { useAuthoredLessonsDomain } from '../stores/AuthoredLessonsDomainProvider';

export const usePrepareLessonCourseData = () => {
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const { authoredLessonDomain } = useDomain();
  const { lessonCourses } = authoredLessonDomain.domainData;

  const getCourseData = () => {
    try {
      callRemoteServiceWrapper(async () => {
        const result = await getLessonCourses();
        const sortedResult = sort(result.data, 'name');
        authoredLessonDomain.updateLessonCourses(sortedResult);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    if (!lessonCourses || lessonCourses.length === 0) {
      getCourseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
  };
};

export const useLoadLessonsWhenEnter = ({
  fetchAuthoredFromService,
}) => {
  const { authoredLessonsSearchCriteriaDomain } = useAuthoredLessonsDomain();
  const { selectedPendingStatus, courseId, unitId, topicId } = authoredLessonsSearchCriteriaDomain.domainData;

  useEffect(() => {
    if (selectedPendingStatus === FILTER_STATUS[2].value && courseId && unitId && topicId) {
      fetchAuthoredFromService(selectedPendingStatus, courseId, topicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

import { useEffect } from 'react';
import { getStudentReport } from '../../../../services/ReportService';
import { useDomain } from '../../../../states/AppDomainProvider';
import { sort } from '../../../../utils/ArrayUtils';
import DateUtils from '../../../../utils/DateUtils';
import Logger from '../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../hooks/RemoteServiceHooks';
import { useStudentContext } from '../../context/MyStudentContextProvider';
import { renderStudentViewAnswerAction } from '../../common/actions/view-student-answers/ViewStudentAnswersUtils';

const startDate = new Date();
startDate.setDate(startDate.getDate() - 14);
const endDate = new Date();

export const useGetTableConfig = ({ fetchReviewAndFeedbackData }) => {
  const { myStudentDomain } = useStudentContext();
  const {
    reviewAndFeedback,
    selectedStudentProfile,
  } = myStudentDomain.domainData;

  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId, students } = studentsDomain.domainData;

  /** Table configuration */
  const tableConfig = [
    {
      title: 'Activity Name',
      align: 'left',
      dataField: 'lessonName',
      width: '22%',
      dataRenderer: (data) => renderStudentViewAnswerAction({
        data,
        reviewFeedbackData: reviewAndFeedback.data,
        currentSelectedStudentId,
        onUpdateReviewStatusSuccess: () => {
          if (currentSelectedStudentId) {
            const currentStudent = students.find((s) => s.id === currentSelectedStudentId);
            currentStudent.reviewNotificationUnread = false;
          }
        }, // no need anymore as the table is refresh after exiting the lesson view
        feedbackChat: selectedStudentProfile.feedbackChat,
        onChatWidgetOpen: () => {
          // eslint-disable-next-line no-param-reassign
          data.chatNotification = false;
          if (currentSelectedStudentId) {
            const currentStudent = students.find((s) => s.id === currentSelectedStudentId);
            currentStudent.chatNotificationUnread = false;
            currentStudent.reviewNotificationUnread = false;
          }
        },
        showIcons: true,
        onLessonExit: () => {
          fetchReviewAndFeedbackData();
        },
      }),
    },
    {
      title: 'Start Time',
      align: 'center',
      dataField: 'startTime',
      width: '16%',
      dataRenderer: (data) => (
        data.startTime ? DateUtils.convertDateToFullDateTimeFormatHHmm(new Date(data.startTime)) : 'N/A'
      ),
    },
  ];

  return tableConfig;
};

export const useFetchReviewAndFeedback = (currentStudent) => {
  const { myStudentDomain } = useStudentContext();
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const fetchReviewAndFeedbackData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const searchCriteria = {
          studentId: currentStudent.id,
          startDate: DateUtils.convertDateToYYYYMMdd(startDate),
          endDate: DateUtils.convertDateToYYYYMMdd(endDate),
          reviewOnly: true,
        };

        const result = await getStudentReport(searchCriteria);
        const { data, meta } = result;
        const tableData = data.map((d) => {
          const { attributes } = d;
          const transformedAttributed = {
            ...attributes,
            totalTimeInMinutes: Number(attributes.totalTimeInMinutes),
            note: attributes.note || '',
          };
          return ({
            id: d.id,
            type: d.type,
            ...transformedAttributed,
          });
        });
        const sortedTableData = sort(tableData, 'startTime');

        await myStudentDomain.updateReviewAndFeedback({
          data: sortedTableData,
          meta,
        });
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    loading,
    fetchReviewAndFeedbackData,
  };
};

export const usePrepareReviewAndFeedback = (currentStudent) => {
  const { studentsDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const {
    fetchReviewAndFeedbackData,
    loading,
  } = useFetchReviewAndFeedback(currentStudent);

  useEffect(() => {
    const loadData = async () => {
      await fetchReviewAndFeedbackData(true);
    };

    if (currentStudent) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedStudentId]);

  return {
    loading,
    fetchReviewAndFeedbackData,
  };
};

import BaseDomain from '../../../states/domains/BaseDomain';

const SEARCH_TEACHER_UPDATE_CRITERIA_AND_DATA = 'SEARCH_TEACHER_UPDATE_CRITERIA_AND_DATA';
const SEARCH_TEACHER_UPDATE_CRITERIA = 'SEARCH_TEACHER_UPDATE_CRITERIA';
const SEARCH_TEACHER_UPDATE_SELECTED_TEACHER_IDS = 'SEARCH_TEACHER_UPDATE_SELECTED_TEACHER_IDS';

const initialState = {
  criteria: {
    username: '',
    firstName: '',
    lastName: '',
  },
  teacherList: {
    data: [],
    included: [],
  },
  selectedTeacherIds: [],
  hasLoaded: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SEARCH_TEACHER_UPDATE_CRITERIA_AND_DATA:
      return {
        ...state,
        criteria: action.payload.criteria,
        teacherList: action.payload.teacherList,
        hasLoaded: action.payload.hasLoaded,
      };
    case SEARCH_TEACHER_UPDATE_CRITERIA:
      return {
        ...state,
        criteria: action.payload,
      };
    case SEARCH_TEACHER_UPDATE_SELECTED_TEACHER_IDS:
      return {
        ...state,
        selectedTeacherIds: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'searchTeacherDomain';

/**
 * Domain class for search teacher in admin
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateCriteriaAndTeacherList(criteria, teacherList, hasLoaded = true) {
    return this.dispatch({
      type: SEARCH_TEACHER_UPDATE_CRITERIA_AND_DATA,
      payload: {
        criteria,
        teacherList,
        hasLoaded,
      },
    });
  }

  async updateCriteria(criteria) {
    return this.dispatch({
      type: SEARCH_TEACHER_UPDATE_CRITERIA,
      payload: criteria,
    });
  }

  async updateSelectedTeacherIds(teacherIds) {
    return this.dispatch({
      type: SEARCH_TEACHER_UPDATE_SELECTED_TEACHER_IDS,
      payload: teacherIds,
    });
  }
}

import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { useState } from 'react';
import { useApplyFontStyle } from '../../ApplyTextStyleActionHooks';
import EntryContainer from '../EntryContainer';
import './Form.scss';

export default function TextAlignmentForm({ applyToActivities }) {
  const [alignment, setAlignment] = useState('');

  const handleChange = (_event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const {
    applyFontStyle,
  } = useApplyFontStyle({
    applyToActivities,
  });
  return (
    <EntryContainer
      data-test='font-alignment-form'
      label='Text Alignment'
      onApply={async () => {
        await applyFontStyle([{
          fieldName: 'textAlign',
          value: alignment,
          isLineLevel: true,
        }]);
        setAlignment('');
      }}
      disabled={!alignment}
    >
      <ToggleButtonGroup size='medium' value={alignment} exclusive onChange={handleChange}>
        <ToggleButton value='left'>
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton value='center'>
          <FormatAlignCenterIcon />
        </ToggleButton>
        <ToggleButton value='right'>
          <FormatAlignRightIcon />
        </ToggleButton>
        <ToggleButton value='justify'>
          <FormatAlignJustifyIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </EntryContainer>
  );
}

TextAlignmentForm.propTypes = {
  applyToActivities: PropTypes.string.isRequired,
};

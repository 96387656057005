import BaseDomain from '../../../states/domains/BaseDomain';

const EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID = 'EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID';
const EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID = 'EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID';
const EXTERNAL_USERS_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID = 'EXTERNAL_USERS_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID';
const EXTERNAL_USERS_UPDATE_SELECTED_SCHOOL_ID = 'EXTERNAL_USERS_UPDATE_SELECTED_SCHOOL_ID';
const EXTERNAL_USERS_IS_DATA_LOADING = 'EXTERNAL_USERS_IS_DATA_LOADING';
const EXTERNAL_USERS_UPDATE_EXTERNAL_USER_DATA = 'EXTERNAL_USERS_UPDATE_EXTERNAL_USER_DATA';
const EXTERNAL_USERS_UPDATE_SELECTED_EXTERNAL_USER_IDS = 'EXTERNAL_USERS_UPDATE_SELECTED_EXTERNAL_USER_IDS';

const initialState = {
  selectedDistrictId: null,
  schoolsBySelectedDistrictId: [],
  selectedSchoolId: null,
  externalUsersData: {
    data: [],
  },
  selectedExternalUserIds: [],
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID:
      return {
        ...state,
        selectedDistrictId: action.payload,
      };
    case EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID:
      return {
        ...state,
        selectedDistrictId: action.payload.selectedDistrictId,
        selectedSchoolId: action.payload.selectedSchoolId,
      };
    case EXTERNAL_USERS_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID:
      return {
        ...state,
        schoolsBySelectedDistrictId: action.payload,
      };
    case EXTERNAL_USERS_UPDATE_SELECTED_SCHOOL_ID:
      return {
        ...state,
        selectedSchoolId: action.payload,
      };
    case EXTERNAL_USERS_IS_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case EXTERNAL_USERS_UPDATE_EXTERNAL_USER_DATA:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        externalUsersData: action.payload.externalUsersData,
      };
    case EXTERNAL_USERS_UPDATE_SELECTED_EXTERNAL_USER_IDS:
      return {
        ...state,
        selectedExternalUserIds: action.payload,
      };
    default:
      return state;
  }
};

const domainName = 'externalUsersDomain';

/**
 * Domain class for general user interface domain
 *
 * @export
 * @class UiDomain
 * @extends {BaseDomain}
 */
export default class extends BaseDomain {
  constructor(saveToLocalStorage) {
    super(domainName, initialState, reducer, saveToLocalStorage);
  }

  async updateSelectedDistrictId(selectedDistrictId) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID,
      payload: selectedDistrictId,
    });
  }

  async updateSelectedDistrictIdAndSchoolId(selectedDistrictId, selectedSchoolId) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_SELECTED_DISTRICT_ID_AND_SCHOOL_ID,
      payload: {
        selectedDistrictId,
        selectedSchoolId,
      },
    });
  }

  async updateSchoolsBySelectedDistrictId(schools) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_SCHOOLS_BY_SELECTED_DISTRICT_ID,
      payload: schools,
    });
  }

  async updateSelectedSchoolId(selectedSchoolId) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_SELECTED_SCHOOL_ID,
      payload: selectedSchoolId,
    });
  }

  async updateIsDataLoading(isLoading) {
    return this.dispatch({
      type: EXTERNAL_USERS_IS_DATA_LOADING,
      payload: isLoading,
    });
  }

  async updateExternalUsersData(externalUsersData, isLoading = false) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_EXTERNAL_USER_DATA,
      payload: {
        externalUsersData,
        isLoading,
      },
    });
  }

  async updateSelectedExternalUserIds(selectedExternalUserIds) {
    return this.dispatch({
      type: EXTERNAL_USERS_UPDATE_SELECTED_EXTERNAL_USER_IDS,
      payload: selectedExternalUserIds,
    });
  }
}

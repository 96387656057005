import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchableMultiGroupSelector from '../../../../../../components/selector/SearchableMultiGroupSelector';
import { getUserAssets, updateUsedMedia } from '../../../../../../services/MediaService';
import Logger from '../../../../../../utils/Logger';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  MEDIA_LIMIT,
  getImageUrlForImage,
  hasMoreRecord,
  getImageUrlForVideo,
} from './SearchableMultiGroupHelper';

/**
 * Container for selecting media from recently used images and videos
 *
 * @export
 */
export default function RecentlyUsedImageAndVideo({
  openFileDialog,
  onFileDialogClose,
  onSelect,
  isVideoIncluded,
}) {
  // State of the recently used media
  const [recentlyUsedMedia, setRecentlyUsedMedia] = useState({
    mediaSelectedType: MEDIA_TYPE_IMAGE,
    mediaLoading: false,
    currentSelectMediaIndex: null,
  });

  // State of the recently used media dialog
  const [recentlyUsedMediaData, setRecentlyUsedMediaData] = useState(() => {
    const data = {
      searchText: '',
      recentlyUsedImage: {
        nextOffset: 0,
        imagesData: [],
        imagesMeta: {},
        hasMoreRecords: true,
      },
    };
    if (isVideoIncluded) {
      return {
        ...data,
        recentlyUsedVideo: {
          nextOffset: 0,
          videosData: [],
          videosMeta: {},
          hasMoreRecords: true,
        },
      };
    }
    return data;
  });

  const getImagesAndVideos = async (searchText = '') => {
    try {
      setRecentlyUsedMedia({
        ...recentlyUsedMedia,
        mediaLoading: true,
      });

      const promises = [];
      promises.push(getUserAssets(searchText, MEDIA_LIMIT, 0, `${MEDIA_TYPE_IMAGE}`));
      if (isVideoIncluded) {
        promises.push(getUserAssets(searchText, MEDIA_LIMIT, 0, `${MEDIA_TYPE_VIDEO}`));
      }

      const [imagePromiseResolve, videoPromiseResolve] = await Promise.all(promises);
      let recentlyUsedMediaResult = {
        searchText,
        recentlyUsedImage: {
          nextOffset: MEDIA_LIMIT,
          imagesData: getImageUrlForImage(imagePromiseResolve.data.data),
          imagesMeta: imagePromiseResolve.data.meta,
          hasMoreRecords: hasMoreRecord(imagePromiseResolve.data.meta),
        },
      };

      if (isVideoIncluded) {
        recentlyUsedMediaResult = {
          ...recentlyUsedMediaResult,
          recentlyUsedVideo: {
            nextOffset: MEDIA_LIMIT,
            videosData: getImageUrlForVideo(videoPromiseResolve.data.data),
            videosMeta: videoPromiseResolve.data.meta,
            hasMoreRecords: hasMoreRecord(imagePromiseResolve.data.meta),
          },
        };
      }

      setRecentlyUsedMediaData(recentlyUsedMediaResult);
    } catch (e) {
      Logger.logError({
        ERROR: e,
      });
    } finally {
      setRecentlyUsedMedia({
        ...recentlyUsedMedia,
        mediaLoading: false,
      });
    }
  };

  useEffect(() => {
    if (openFileDialog) {
      getImagesAndVideos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFileDialog]);

  const getMediaList = () => {
    switch (recentlyUsedMedia.mediaSelectedType) {
      case MEDIA_TYPE_IMAGE:
        return recentlyUsedMediaData.recentlyUsedImage.imagesData;

      case MEDIA_TYPE_VIDEO:
        return recentlyUsedMediaData.recentlyUsedVideo.videosData;

      default:
        return [];
    }
  };

  const onSearch = (searchText) => {
    getImagesAndVideos(searchText);
  };

  const groupType = [
    {
      type: MEDIA_TYPE_IMAGE,
      title: 'Image',
    },
  ];

  if (isVideoIncluded) {
    groupType.push({
      type: MEDIA_TYPE_VIDEO,
      title: 'Video',
    });
  }
  const mediaSelectorProps = {
    open: openFileDialog,
    handleClose: onFileDialogClose,
    title: 'Recently Used',
    groupType,
    groupSelectedValue: recentlyUsedMedia.mediaSelectedType,
    onGroupSelectionChange: (value) => {
      setRecentlyUsedMedia({
        ...recentlyUsedMedia,
        mediaSelectedType: value,
        currentSelectMediaIndex: null,
      });
    },
    isLoading: recentlyUsedMedia.mediaLoading,
    dataList: getMediaList(),
    onSearch,
    currentSelectedIndex: recentlyUsedMedia.currentSelectMediaIndex,
    onDataSelected: (selectedIndex) => {
      setRecentlyUsedMedia({
        ...recentlyUsedMedia,
        currentSelectMediaIndex: selectedIndex,
      });
    },
    onSelect: async () => {
      let selectedImage = null;
      let selectedVideo = null;
      let selectedMediaId = null;
      if (recentlyUsedMedia.mediaSelectedType === MEDIA_TYPE_IMAGE) {
        selectedMediaId = recentlyUsedMediaData.recentlyUsedImage.imagesData[recentlyUsedMedia.currentSelectMediaIndex].id;
        selectedImage = recentlyUsedMediaData.recentlyUsedImage.imagesData[recentlyUsedMedia.currentSelectMediaIndex].imageUrl;
        selectedVideo = null;
      } else if (recentlyUsedMedia.mediaSelectedType === MEDIA_TYPE_VIDEO) {
        const video = recentlyUsedMediaData.recentlyUsedVideo.videosData[recentlyUsedMedia.currentSelectMediaIndex];
        selectedMediaId = video.id;
        selectedImage = video.imageUrl;
        selectedVideo = video.attributes.s3Url;
      }
      updateUsedMedia(selectedMediaId);
      await onSelect({
        selectedImageUrl: selectedImage,
        selectedVideoUrl: selectedVideo,
      });
      setRecentlyUsedMedia({
        ...recentlyUsedMedia,
        currentSelectMediaIndex: null,
      });
    },
    onLoadMore: async () => {
      if (recentlyUsedMedia.mediaSelectedType === MEDIA_TYPE_IMAGE) {
        if (!recentlyUsedMediaData.recentlyUsedImage.hasMoreRecords) {
          return;
        }
        const imagePromiseResolve = await getUserAssets(
          recentlyUsedMediaData.searchText,
          MEDIA_LIMIT,
          recentlyUsedMediaData.recentlyUsedImage.nextOffset,
          `${MEDIA_TYPE_IMAGE}`,
        );
        setRecentlyUsedMediaData({
          ...recentlyUsedMediaData,
          recentlyUsedImage: {
            nextOffset: Math.min(imagePromiseResolve.data.meta.offset + MEDIA_LIMIT, imagePromiseResolve.data.meta.totalCount),
            imagesData: recentlyUsedMediaData.recentlyUsedImage.imagesData.concat(getImageUrlForImage(imagePromiseResolve.data.data)),
            imagesMeta: imagePromiseResolve.data.meta,
            hasMoreRecords: hasMoreRecord(imagePromiseResolve.data.meta),
          },
        });
      } else if (recentlyUsedMedia.mediaSelectedType === MEDIA_TYPE_VIDEO) {
        if (!recentlyUsedMediaData.recentlyUsedVideo.hasMoreRecords) {
          return;
        }
        const videoPromiseResolve = await getUserAssets(
          recentlyUsedMediaData.searchText,
          MEDIA_LIMIT,
          recentlyUsedMediaData.recentlyUsedVideo.nextOffset,
          `${MEDIA_TYPE_VIDEO}`,
        );
        setRecentlyUsedMediaData({
          ...recentlyUsedMediaData,
          recentlyUsedVideo: {
            nextOffset: Math.min(videoPromiseResolve.data.meta.offset + MEDIA_LIMIT, videoPromiseResolve.data.meta.totalCount),
            videosData: recentlyUsedMediaData.recentlyUsedVideo.videosData.concat(getImageUrlForVideo(videoPromiseResolve.data.data)),
            videosMeta: videoPromiseResolve.data.meta,
            hasMoreRecords: hasMoreRecord(videoPromiseResolve.data.meta),
          },
        });
      }
    },
    hasMoreRecords: recentlyUsedMedia.mediaSelectedType === MEDIA_TYPE_IMAGE
      ? recentlyUsedMediaData.recentlyUsedImage.hasMoreRecords
      : recentlyUsedMediaData.recentlyUsedVideo.hasMoreRecords,

    className: 'vizzle-recently-used-dialog',
  };

  return (
    <SearchableMultiGroupSelector {...mediaSelectorProps} data-test='recently-used-image-and-video-dialog' />
  );
}

RecentlyUsedImageAndVideo.defaultProps = {
  openFileDialog: false,
  onFileDialogClose: () => { },
  onSelect: () => { },
  isVideoIncluded: true,
};

RecentlyUsedImageAndVideo.propTypes = {
  openFileDialog: PropTypes.bool,
  onFileDialogClose: PropTypes.func,
  onSelect: PropTypes.func,
  isVideoIncluded: PropTypes.bool,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
} from '@mui/material';
import FilterableMultiSelector from '../../../selector/FilterableMultiSelector';
import './ObjectiveFilter.scss';

const ObjectiveFilter = ({
  onFilterChange,
  filterValue,
  multiSelectFilterConfig,
  children,
}) => {
  const [state, setState] = useState({ ...filterValue });

  const setStateAndCallOnchange = (newState) => {
    setState(newState);
    onFilterChange(newState);
  };

  const handleMultiSelectorApply = (keyName, value) => {
    setStateAndCallOnchange({ ...state, [keyName]: value });
  };

  const handleOnClearFilterClick = () => {
    setStateAndCallOnchange({
      archived: false,
      keyword: '',
    });
  };

  return (
    <Paper className='objective-filter'>
      {multiSelectFilterConfig ? (
        multiSelectFilterConfig.map((config, index) => {
          const { keyName, getValue, ...rest } = config;
          const initFilterValue = state[keyName];
          const initFilterKeyValues = initFilterValue ? initFilterValue.map((v) => v.key) : [];

          return (
            <FilterableMultiSelector
              key={`filter-selector-${config.label}-${index}`}
              onApply={(value) => { handleMultiSelectorApply(keyName, value); }}
              initFilterKeyValues={initFilterKeyValues}
              {...rest}
            />
          );
        })
      ) : null}
      <Button
        className='clear-filter-button'
        onClick={handleOnClearFilterClick}
      >
        Clear Filters
      </Button>
      {children}
    </Paper>
  );
};

ObjectiveFilter.defaultProps = {
  onFilterChange: () => { },
  filterValue: {},
  multiSelectFilterConfig: [],
  children: null,
};

ObjectiveFilter.propTypes = {
  onFilterChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filterValue: PropTypes.object,
  multiSelectFilterConfig: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    subTitle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.any,
      name: PropTypes.any,
      value: PropTypes.any,
    })),
    className: PropTypes.string,
    keyName: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    filterable: PropTypes.bool,
  })),
  children: PropTypes.node,
};

export default ObjectiveFilter;

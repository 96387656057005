import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '../../../../third-party/react-beautiful-dnd.min';
import { layoutDropdownValues } from '../../../../components/vizzle/page/flexible-matching/FlexibleMatchingConstants';
import CardActivity from '../common/card-activity/CardActivity';
import {
  useDragAndDropSubmitAnswer,
  useGenerateComponentIds,
  useGenerateRejectedAnswerRefs,
  useInitializeData,
  useInitializeRefs,
  usePrepareViewCorrectSubmissions,
  useSwitchAccessSubmitAnswer,
  useTwoClickSubmitAnswer,
} from './FlexibleMatchingActivityHooks';
import { isCardEmpty } from '../../../../utils/activitytype/ActivityTypeEmptyChecker';
import '../../../../components/vizzle/page/flexible-matching/FlexibleMatchingLayout.scss';
import './FlexibleMatchingActivity.scss';
import '../activities-common.scss';
import { getDroppingAnimateClass } from '../../../../components/vizzle/page/matching/MatchingUtil';
import LessonPlayActivityUtils from '../utils/LessonPlayActivityUtils';
import RejectedAnswerResponse from '../common/rejected-answer-response/RejectedAnswerResponse';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import FlexibleMatchingActivityPrompt from './component/FlexibleMatchingActivityPrompt';
import ObjectUtils from '../../../../utils/ObjectUtils';
import FlexibleMatchingUtils from '../../../../components/vizzle/page/flexible-matching/FlexibleMatchingUtils';
import SubmittedAnswerIndicator from '../common/sumitted-answer-indicator/SubmittedAnswerIndicator';

const FlexibleMatchingActivity = forwardRef(({
  questionKey,
  promptText,
  layout,
  promptCards,
  subText,
  responseCards,
  onCorrectAnswerSelected,
  onIncorrectAnswerSelected,
  onActivityFinished,
  onTriggerSwitchScan,
  submitAnswerText,
  pageIndex,
}, ref) => {
  const promptTextRef = useRef(null);
  const submitAnswerTextRef = useRef(null);
  const subTextRef = useRef(null);

  const {
    componentId,
    prompCardDropableId,
  } = useGenerateComponentIds();

  const {
    rejectedAnswerPromptRef,
  } = useGenerateRejectedAnswerRefs();

  const {
    flexibleMatchingActivityRef,
    dragAndDropApiRef,
    flexibleMatchingPromptRefs,
    setFlexibleMatchingPromptRefs,
    flexibleMatchingResponseRefs,
    setFlexibleMatchingResponseRefs,
  } = useInitializeRefs();

  const {
    selectedResponseIndex,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    isDraggable,
    setIsDraggable,
    singleAnswer,
  } = useInitializeData();

  const {
    handleOnDragStart,
    handleOnDragEnd,
  } = useDragAndDropSubmitAnswer({
    flexibleMatchingActivityRef,
    questionKey,
    prompCardDropableId,
    responseCards,
    rejectedAnswerPromptRef,
    setSelectedResponseIndex,
    selectedWrongResponses,
    setSelectedWrongResponses,
    onCorrectAnswerSelected,
    onIncorrectAnswerSelected,
    onActivityFinished,
  });

  const {
    handleOnPromptCardClick,
    handleOnResponseCardClick,
  } = useTwoClickSubmitAnswer({
    flexibleMatchingActivityRef,
    prompCardDropableId,
    dragAndDropApiRef,
    setIsDraggable,
    onTriggerSwitchScan,
  });

  usePrepareViewCorrectSubmissions({
    setSelectedResponseIndex,
    setSelectedWrongResponses,
    responseCards,
    onActivityFinished,
    pageIndex,
  });

  const {
    handleResponseSwitchAccessSelect,
  } = useSwitchAccessSubmitAnswer({
    dragAndDropApiRef,
    prompCardDropableId,
    setIsDraggable,
    onActivityFinished,
  });

  const isFinished = (singleAnswer && (selectedResponseIndex >= 0 || (selectedWrongResponses && selectedWrongResponses.length > 0)))
    || selectedResponseIndex >= 0;

  const promptWithValueIndex = promptCards.findIndex((p) => !isCardEmpty(p));

  const isPromptAreaEmpty = promptWithValueIndex < 0 && !promptText && !subText;

  useImperativeHandle(ref, () => ({
    getEnableElements: async () => {
      await ObjectUtils.delay(100);
      if (isFinished) {
        return [];
      }

      let result = [];
      if (promptTextRef && promptTextRef.current) {
        result.push(promptTextRef.current.getEnableElements());
      }

      result = [
        ...result,
        ...flexibleMatchingPromptRefs.map((p) => p.getEnableElements()),
      ];

      if (subTextRef && subTextRef.current) {
        result.push(subTextRef.current.getEnableElements());
      }

      if (submitAnswerTextRef && submitAnswerTextRef.current) {
        result.push(submitAnswerTextRef.current.getEnableElements());
      }

      result = [
        ...result,
        ...flexibleMatchingResponseRefs.filter((_r, index) => !selectedWrongResponses.includes(index)).map((r) => r.getEnableElements()),
      ];
      return result;
    },
  }));

  const notEmptyPromptCards = promptCards.filter((p) => !isCardEmpty(p));
  return (
    <DragDropContext
      onDragStart={(handleOnDragStart)}
      onDragEnd={handleOnDragEnd}
      sensors={[(sensorApi) => {
        dragAndDropApiRef.current = sensorApi;
      }]}
    >
      <div
        className={`flexible-matching-activity-component flexi-matching-layout ${isPromptAreaEmpty ? 'no-prompt-area' : ''} ${notEmptyPromptCards.length === 0 ? 'no-prompt-cards' : ''}`}
        ref={flexibleMatchingActivityRef}
        id={componentId}
        data-test='flexible-matching-activity'
      >
        <TransparentBlocker show={isFinished} />
        {!isPromptAreaEmpty && (
          <div className='prompt-area'>
            {promptText && (
              <CardActivity data-test='flexi-matching-prompt' className='flexi-matching-prompt-text' text={promptText} ref={promptTextRef} fontScale={0.95} />
            )}

            <div data-test='prompt-cards-container' className={`prompt-cards-container ${FlexibleMatchingUtils.getFlexibleMatchingLayoutClass(layout, promptCards)}`}>
              {
                notEmptyPromptCards.map((p, index) => {
                  const isEmpty = isCardEmpty(p);
                  if (isEmpty) {
                    return (
                      <div key={`${componentId}-prompt-${index}`} style={{ gridArea: `card-${index + 1}` }} />
                    );
                  }
                  return (
                    <CardActivity
                      key={`${componentId}-prompt-${index}`}
                      className='flexi-matching-prompt-card'
                      style={{ gridArea: `card-${index + 1}` }}
                      text={p.text}
                      image={p.image}
                      video={p.video}
                      audio={p.audio}
                      ref={(r) => { setFlexibleMatchingPromptRefs(r, index); }}
                      fontScale={0.95}
                      data-test={`question-${index}`}
                    />
                  );
                })
              }
            </div>

            {subText && (
              <CardActivity data-test='flexi-matching-sub' className='flexi-matching-sub-text' text={subText} ref={subTextRef} fontScale={0.95} />
            )}
          </div>
        )}

        <Droppable droppableId={`fleximatching-component-draggable-container-${componentId}`} direction='horizontal' isDropDisabled>
          {(providedMain) => (
            <div
              ref={providedMain.innerRef}
              {...providedMain.droppableProps}
              className='response-area'
              data-test='response-area'
            >
              {selectedResponseIndex >= 0 ? (
                <div data-test='submit-response-area' className='submit-response-area selected-response-container'>
                  <CardActivity
                    text={responseCards[selectedResponseIndex].text}
                    image={responseCards[selectedResponseIndex].image}
                    video={responseCards[selectedResponseIndex].video}
                    audio={responseCards[selectedResponseIndex].audio}
                    className='selected-response-card'
                    fontScale={0.95}
                    data-test='selected-response-card'
                  >
                    <SubmittedAnswerIndicator correctSubmission={responseCards[selectedResponseIndex].correctSubmission} />
                  </CardActivity>
                </div>
              ) : (
                <Droppable droppableId={prompCardDropableId} direction='horizontal'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      id={prompCardDropableId}
                      className={`submit-response-area place-holder-container
                        ${snapshot.isDraggingOver ? 'vizzle-selected-component' : ''}
                      `}
                      data-test='submit-response-area'
                    >
                      <FlexibleMatchingActivityPrompt ref={submitAnswerTextRef} onClick={handleOnPromptCardClick} submitAnswerText={submitAnswerText} />
                      <RejectedAnswerResponse ref={rejectedAnswerPromptRef} />
                    </div>
                  )}
                </Droppable>
              )}

              <div className='response-cards-container'>
                {responseCards.map((card, index) => {
                  const cardId = `${componentId}-${index}`;
                  const responseId = `flexi-matching-response-${cardId}`;
                  const responseContainerId = `flexi-matching-response-container-${cardId}`;
                  const draggableId = `flexi-matching-drag-item-${cardId}`;

                  return (
                    <div
                      className='response-card-container item-placeholder flexi-matching-response-card'
                      id={responseContainerId}
                      key={responseContainerId}
                      data-test={`response-card-${index}`}
                    >
                      {index !== selectedResponseIndex && (
                        <Draggable
                          key={draggableId}
                          draggableId={draggableId}
                          index={index}
                          shouldRespectForcePress={false}
                          timeForLongPress={0}
                          isDragDisabled={
                            !isDraggable
                            || (selectedResponseIndex >= 0 || selectedWrongResponses.findIndex((s) => (s === index)) >= 0)
                          }
                        >
                          {(providedItem, snapshotItem) => (
                            <div
                              data-test={`flexi-matching-response-item-${index}`}
                              ref={providedItem.innerRef}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                              id={draggableId}
                              key={`flexi-matching-response-item-${cardId}`}
                              className={`
                                flexi-matching-component-response-card-container
                                ${snapshotItem.isDragging ? 'vizzle-selected-component' : 'non-drag-items'} 
                                ${getDroppingAnimateClass(snapshotItem.isDropAnimating, snapshotItem.isDragging, card.key === questionKey)}
                                ${selectedWrongResponses.findIndex((s) => (s === index)) >= 0 ? 'flexi-matching-component-disabled flexi-matching-component-fading' : ''}
                                `}
                              style={LessonPlayActivityUtils.getDropStyle(providedItem.draggableProps.style, snapshotItem)}
                            >
                              <CardActivity
                                id={responseId}
                                text={card.text}
                                image={card.image}
                                video={card.video}
                                audio={card.audio}
                                onClick={(e, isTtsAbleToPlay) => handleOnResponseCardClick && handleOnResponseCardClick(e, isTtsAbleToPlay, card, responseContainerId, draggableId)}
                                ref={(r) => { setFlexibleMatchingResponseRefs(r, index); }}
                                onSwitchAccessSelected={() => handleResponseSwitchAccessSelect(responseId, draggableId)}
                                fontScale={0.95}
                              />
                              <SubmittedAnswerIndicator correctSubmission={card.correctSubmission} />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
});

export default FlexibleMatchingActivity;

FlexibleMatchingActivity.defaultProps = {
  promptText: null,
  layout: layoutDropdownValues[0].className,
  promptCards: [{}],
  subText: null,
  responseCards: [{}, {}],
  onCorrectAnswerSelected: () => { },
  onIncorrectAnswerSelected: () => { },
  onActivityFinished: () => { },
  onTriggerSwitchScan: () => { },
  submitAnswerText: null,
};

FlexibleMatchingActivity.propTypes = {
  questionKey: PropTypes.string.isRequired,
  promptText: PropTypes.object,
  layout: PropTypes.string,
  promptCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  subText: PropTypes.object,
  responseCards: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    correctSubmission: PropTypes.bool,
  })),
  onCorrectAnswerSelected: PropTypes.func,
  onIncorrectAnswerSelected: PropTypes.func,
  onActivityFinished: PropTypes.func,
  onTriggerSwitchScan: PropTypes.func,
  submitAnswerText: PropTypes.object,
  pageIndex: PropTypes.number.isRequired,
};

import PropTypes from 'prop-types';
import CustomDialog from '../../../components/dialog/CustomDialog';
import './EmailSentNotification.scss';

const EmailSentNotification = ({ onClose, openDialog }) => (
  <CustomDialog
    className='email-sent-notification-dialog'
    openDialog={openDialog}
    onClose={onClose}
    title={(
      <div className='title'>
        Email Sent
      </div>
    )}
    content={(
      <div className='content'>
        <div className='message'>
          <p>
            An email has been sent to you with a link to reset your password.
          </p>
          <p>
            If you don&apos;t see it, please check your spam folder.
          </p>
        </div>
      </div>
    )}
    actions={[
      {
        title: 'Return to Login Page',
        onClick: onClose,
        className: 'btn-primary close-button',
      },
    ]}
    displayCloseIcon
  />
);

EmailSentNotification.defaultProps = {
  onClose: () => { },
  openDialog: false,
};

EmailSentNotification.propTypes = {
  onClose: PropTypes.func,
  openDialog: PropTypes.bool,
};

export default EmailSentNotification;

import { useState, useEffect } from 'react';
import SelectorForm from './form/SelectorForm';
import { TTS_OPTIONS } from '../../../../constants/PlayPreferenceConstant';
import useSettingForm from './form/useSettingForm';
import getTextToSpeechSettingsForm from '../../../../components/vizzle/tts/TextToSpeechSettingsForm';
import { TTS_DEFAULT_VALUE } from '../../../../services/tts/TextToSpeechService';
import { useDomain } from '../../../../states/AppDomainProvider';

const TextToSpeechSettings = () => {
  const {
    loading,
    value,
    handleOnChange,
    handleOnClick,
  } = useSettingForm('Text to speech');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;
  const [ttsConfig, setTtsConfig] = useState({ ...TTS_DEFAULT_VALUE });

  const TextToSpeechSettingsForm = getTextToSpeechSettingsForm(userProfile.tts.ttsMode);
  useEffect(() => {
    if (value) {
      setTtsConfig({ ...TTS_DEFAULT_VALUE });
    } else {
      setTtsConfig({ ttsConfig });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnApplyClick = () => {
    let dataToSave = {
      enabled: value,
    };
    if (value) {
      dataToSave = {
        enabled: value,
        ...ttsConfig,
      };
    }
    handleOnClick({ tts: dataToSave });
  };

  return (
    <>
      <SelectorForm
        label='Enable/Disable TTS'
        options={TTS_OPTIONS}
        loading={loading}
        value={value}
        handleOnChange={handleOnChange}
        handleOnClick={handleOnApplyClick}
      />
      {value === true ? (
        <TextToSpeechSettingsForm
          ttsConfig={ttsConfig}
          onChange={(field, newValue) => {
            setTtsConfig({
              ...ttsConfig,
              [field]: newValue,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default TextToSpeechSettings;

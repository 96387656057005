import { MenuItem } from '@mui/material';
import RethinkEdLogo from '../../../../../static/images/RethinkEd_Logo.png';
import './MenuItem.scss';

const url = process.env.REACT_APP_RETHINK_SSO_URL;

function EduMenuItem() {
  return (
    <MenuItem
      data-test='rethink-ed-action'
      onClick={() => {
        window.open(url, '_self');
      }}
      className='rethink-menu-item'
    >
      <img className='rethink-logo' src={RethinkEdLogo} alt='RethinkEd Logo' />
    </MenuItem>
  );
}

export default EduMenuItem;

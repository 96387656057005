import { CircularProgress, Slide } from '@mui/material';
import VizzleLogo from '../../../vizzle_NoTag.svg';
import VizzleSnackbar from '../../../components/snackbar/VizzleSnackbar';
import './UserVerify.scss';
import { STATE, useControlVerifyUser, useHandleSnackbar, useValidateVerificationCode } from './UserVerifyHooks';
import UserVerifySuccess from './UserVerifySuccess';
import UserVerifyForm from './UserVerifyForm';
import UserVerifyInvalidToken from './UserVerifyInvalidToken';

const UserVerify = () => {
  const {
    loading: loadingToken,
    user,
    error,
  } = useValidateVerificationCode();

  const {
    state,
    loading,
    handleOnVerifyUser,
  } = useControlVerifyUser({
    user,
  });

  const {
    snackbar,
    onSnackbarClose,
  } = useHandleSnackbar();

  return (
    <>
      <Slide
        direction={state.slide.direction}
        timeout={600}
        in={state.slide.in}
        mountOnEnter
        unmountOnExit
      >
        <div className='verify-user'>
          <div className='vizzle-logo-container'>
            <img src={VizzleLogo} alt='Vizzle Logo' />
          </div>
          <div className='title'>
            Verify Account - Set Your Vizzle Password
          </div>
          {loadingToken && (
            <div className='loading-container'>
              <CircularProgress size={40} />
              <div className='loading-message'>
                Verifying the token
              </div>
            </div>
          )}
          {!loadingToken && user && (
            <div className='verify-user-content'>
              {state.form === STATE.VERIFY_FORM && (
                <UserVerifyForm loading={loading} onSubmit={handleOnVerifyUser} />
              )}
              {state.form === STATE.VERIFY_SUCCESS && (
                <UserVerifySuccess />
              )}
            </div>
          )}

          {!loadingToken && error && (
            <UserVerifyInvalidToken errorMessage={error} />
          )}
        </div>
      </Slide>
      <VizzleSnackbar {...snackbar} onClose={onSnackbarClose} />
    </>
  );
};

export default UserVerify;

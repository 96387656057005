import CreateStudentAction from './actions/CreateStudentAction';
import PrintStudents from './actions/PrintStudents';
import SelectedStudentList from './selectedstudents/SelectedStudentList';
import AssignStudentFromListAction from './studentlist/AssignStudentFromListAction';
import './StudentManagement.scss';
import { usePrepareData } from './StudentManagementHook';
import NoLessonPaper from '../../../../components/paper/NoLessonPaper';
import { useDomain } from '../../../../states/AppDomainProvider';

const StudentManagement = () => {
  const {
    listStudents,
    getAllByTypeStudent,
  } = usePrepareData();

  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const message = user.userAdmin ? 'Please use the Admin Console to add students to a roster'
    : 'If you are unable to create or add existing students to your roster, please contact your admin or use LiveChat to request access.';
  return (
    <div className='student-management-container'>
      <NoLessonPaper className='contact-label' message={message} />
      <div className='action-container'>
        {((user.districtType && user.districtType.toLowerCase() === 'trial') || (user.canCreateStudent)) && (
          <CreateStudentAction listStudents={listStudents} onStudentCreated={getAllByTypeStudent} />
        )}

        <AssignStudentFromListAction listStudents={listStudents} />
        <PrintStudents />
      </div>
      <div className='student-list'>
        <SelectedStudentList />
      </div>
    </div>
  );
};

export default StudentManagement;

export const TEACHER_COURSES_ROUTES = {
  LESSONS: 'lessons',
  TOPIC_ASSESSMENT: 'topic-assessment',
  LESSON_PLANS: 'lesson-plans',
  UNIT_GUIDE: 'unit-guide',
  UNIT_ASSESSMENT: 'unit-assessment',
  STANDARDS: 'standards',
  TOPIC_STANDARDS: 'topic-standards',
  LESSON_TEMPLATE_GENERATOR: 'lesson-template-generator',
  TOPIC_INFO: 'topic-info',
  EXTENSION_ACTIVITIES: 'extension-activities/*',
};

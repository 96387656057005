import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import {
  Button,
} from '@mui/material';
import './ButtonList.scss';

const alignClass = {
  vertical: 'button-list-component-vertical',
  horizental: 'button-list-component-horizental',
};

/**
 * Create list of buttons with actions
 *
 * @param {*} props of
 * config - to create list of buttons
 * align - alignment of the buttons. It can be 'vertical' or 'horizental'
 *
 * Example
 * config: [{
 *    label: 'ADD PAGE',
 *    onClick: () => { console.log('add page'); },
 *  },
 *  {
 *    label: 'COPY PAGE',
 *    onClick: () => { console.log('copy page'); },
 *  }]
 * align: vertical
 */
export default function ButtonList({
  config,
  align,
  className,
  componentKey,
}) {
  const handleOnClick = (index) => {
    config[index].onClick();
  };

  return (
    <div className={`button-list-component ${alignClass[align]} ${className}`}>
      {config.map((c, index) => (
        <Button
          key={`${componentKey}-${index}` || uuidv1()}
          disabled={c.disabled}
          onClick={() => handleOnClick(index)}
          variant='contained'
          className={`btn-primary vizzle-button-list-${index}`}
          data-test={`${componentKey}-${c.label}`}
        >
          {c.renderIcon ? c.renderIcon() : ''}
          {c.label}
        </Button>
      ))}
    </div>
  );
}

ButtonList.defaultProps = {
  align: 'vertical',
  className: '',
  componentKey: '',
};

ButtonList.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    renderIcon: PropTypes.func,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  })).isRequired,
  // eslint-disable-next-line
  align: PropTypes.oneOf(['vertical', 'horizental']),
  className: PropTypes.string,
  componentKey: PropTypes.string,
};

import useSettingForm from '../form/useSettingForm';
import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { useStudentContext } from '../../../context/MyStudentContextProvider';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getTerminologies } from '../../../../../services/UserProfileService';
import Seasonal from '../../../../settings/seasonal/Seasonal';

const SeasonalFilter = () => {
  const { updateProfileSettingsOnChange, loading } = useSettingForm('Seasonal Filter');
  const { studentsDomain, uiDomain } = useDomain();
  const { currentSelectedStudentId } = studentsDomain.domainData;
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile } = myStudentDomain.domainData;

  const refreshTerminologies = async () => {
    uiDomain.showLoader('Refreshing terminologies', LOADER_TYPE.HASH_LOADER);
    const result = await getTerminologies(currentSelectedStudentId);
    myStudentDomain.getTerminologies(result);
    uiDomain.hideLoader();
  };

  const onChange = async (newValue) => {
    await updateProfileSettingsOnChange(currentSelectedStudentId, {
      filter: newValue,
    });
    uiDomain.hideLoader();
    refreshTerminologies();
  };

  return (
    <Seasonal
      profile={selectedStudentProfile}
      onChangeCallback={(newValue) => onChange(newValue)}
      loading={loading}
    />
  );
};

export default SeasonalFilter;

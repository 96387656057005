import { useRef, useEffect, useState } from 'react';
import { useDebounce } from '../../utils/ReactUtil';

export const useHandleFilter = () => {
  const [keyword, setKeyword] = useState();

  const handleTextChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleClearKeywordClick = () => {
    setKeyword('');
  };

  return {
    keyword,
    handleTextChange,
    handleClearKeywordClick,
  };
};

export const usePerformFilter = ({
  keyword,
  searchField,
  searchFunction,
  tableData,
}) => {
  const [internalTableData, setInternalTableData] = useState([
    ...tableData,
  ]);

  const debouncedKeywordValue = useDebounce(keyword, 600);
  useEffect(() => {
    if (keyword && (searchField || searchFunction)) {
      const filteredValue = tableData.filter((data) => {
        if (searchFunction) {
          return searchFunction(debouncedKeywordValue, data);
        } else {
          const fieldValue = data[searchField];
          return fieldValue && fieldValue.toLowerCase().indexOf(debouncedKeywordValue.toLowerCase()) >= 0;
        }
      });
      setInternalTableData(filteredValue);
    } else {
      setInternalTableData([...tableData]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywordValue, tableData]);

  return {
    internalTableData,
  };
};

export const useHandleTableAction = ({
  internalTableData,
  dataId,
  selectedRowIds,
  onSelectedRowIds,
}) => {
  const lastShiftSelectedRowId = useRef(null);

  const clearSelection = onSelectedRowIds ? () => {
    onSelectedRowIds([]);
    lastShiftSelectedRowId.current = null;
  } : null;

  const resetLastShiftSelectedRowId = () => {
    lastShiftSelectedRowId.current = 0;
  };

  useEffect(() => {
    if (onSelectedRowIds) {
      clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalTableData]);

  const handleSingleClick = onSelectedRowIds ? (e, rowIndex, selectedDataId, currentData) => {
    if (selectedRowIds.length === 1 && selectedRowIds.includes(selectedDataId)) {
      clearSelection();
    } else if (e.ctrlKey || e.metaKey) {
      if (selectedRowIds.includes(selectedDataId)) {
        const newSelectedRowIds = selectedRowIds.filter((id) => id !== selectedDataId);
        onSelectedRowIds(newSelectedRowIds);
      } else {
        const newSelectedRowIds = [
          ...selectedRowIds,
          selectedDataId,
        ];
        onSelectedRowIds(newSelectedRowIds);
      }
      lastShiftSelectedRowId.current = rowIndex;
    } else if (e.shiftKey) {
      if (selectedRowIds.length === 0) {
        lastShiftSelectedRowId.current = 0;
      }

      const minIndex = Math.min(lastShiftSelectedRowId.current, rowIndex);
      const maxIndex = Math.max(lastShiftSelectedRowId.current, rowIndex);
      const selectedIds = [];
      for (let i = minIndex; i <= maxIndex; i++) {
        selectedIds.push(currentData[i][dataId]);
      }
      onSelectedRowIds(selectedIds);
    } else {
      onSelectedRowIds([selectedDataId]);
      lastShiftSelectedRowId.current = rowIndex;
    }
  } : null;

  const handleDoubleClicks = onSelectedRowIds ? (_e, selectedDataId) => {
    onSelectedRowIds([selectedDataId], { doubleClicks: true });
  } : null;

  return {
    selectedRowIds,
    handleSingleClick,
    handleDoubleClicks,
    clearSelection,
    resetLastShiftSelectedRowId,
  };
};

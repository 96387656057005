import { createContext, useContext } from 'react';
import SwitchAccessDomain from './SwitchAccessDomain';
import SwitchAccessElementDomain from './SwitchAccessElementDomain';

const domains = [
  new SwitchAccessDomain(true), // Save the data to the user session to be used accross the appplication
  new SwitchAccessElementDomain(false),
];

/*-----------------------------------------------------------------------*/

let SwitchAccessContextProvider;
const result = {};
export const initSwitchAccessDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  SwitchAccessContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useSwitchAccess = () => useContext(SwitchAccessContextProvider);

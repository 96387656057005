import PropTypes from 'prop-types';
import AssignLessonActionWithDateSelectionV2 from '../../../../lessons/lessonactions/lesson-multi-selection-actions/AssignLessonActionWithDateSelectionV2';
import { useFetchStudentLesson, useHandleTeacherCourseAssigment } from '../StudentLessonsHooks';
import { useGetAssignedStudentCourses } from '../../../../lessons/assigned-student-courses/AssignedStudentCoursesHooks';

export default function StudentAssignLessonAction({
  lessons,
  selectedLessonIds,
  currentStudent,
  selectedDates,
}) {
  const {
    fetchStudentLesson,
  } = useFetchStudentLesson(currentStudent);

  const propsArg = useHandleTeacherCourseAssigment();

  const {
    getAssignedStudentData,
  } = useGetAssignedStudentCourses({
    onStartLoading: null,
    subjectId: propsArg.subjectId,
    onDataLoad: propsArg.onDataLoad,
    assignedStudentCourses: propsArg.assignedStudentCourses,
    filterCriteriaAssignedStudentCourses: propsArg.filterCriteriaAssignedStudentCourses,
  });

  return (
    <AssignLessonActionWithDateSelectionV2
      lessons={lessons}
      buttonLabel='Edit Assignments'
      selectedLessonIds={selectedLessonIds}
      currentStudent={currentStudent}
      showAddToMyList
      reloadDataFunc={() => {
        fetchStudentLesson(false, selectedLessonIds);
        getAssignedStudentData(selectedDates);
      }}
    />
  );
}

StudentAssignLessonAction.defaultProps = {
  lessons: [],
  selectedLessonIds: [],
  currentStudent: null,
  selectedDates: {},
};

StudentAssignLessonAction.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      attributes: PropTypes.object,
      relationships: PropTypes.object,
      specialtyContent: PropTypes.object,
    }),
  ),
  selectedLessonIds: PropTypes.arrayOf(PropTypes.string),
  currentStudent: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  selectedDates: PropTypes.object,
};

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import ThumbnailCard from '../common/ThumbnailCard';
import './MultipleChoiceV2Layout.scss';
import './MultipleChoiceV2Thumbnail.scss';

// const MAX_RESPONSE_IN_SMALL_MODE = 4;

/**
 * Container for Flexible Matching layout
 */
export default function MultipleChoiceV2Thumbnail({
  questionCard,
  answerCards,
  isSmallThumbnail,
  singlePage,
}) {
  const maxResponseInSmallMode = singlePage ? 2 : 4;

  const id = useMemo(() => uuidv1(), []);
  return (
    <div
      className={`multiple-choice-v2-layout multiple-choice-v2-thumbnail
      ${isSmallThumbnail ? 'small' : ''}
      ${singlePage ? 'single-page' : 'side-by-side'}
      `}
    >
      <div className='prompt-area'>
        <div className='prompt-cards-container'>
          <ThumbnailCard
            id={`multiple-choice-v2-layout-prompt-card-${id}`}
            key={`multiple-choice-v2-layout-prompt-card-key-${id}`}
            text={questionCard.text}
            image={questionCard.image}
            isSmallThumbnail={isSmallThumbnail}
            alwaysShowText
          />
        </div>
      </div>
      <div className='response-area'>
        <div className={`response-cards-container ${singlePage && answerCards.length >= 2 ? 'three-columns' : ''}`}>
          {isSmallThumbnail && (answerCards.slice(0, maxResponseInSmallMode).map((p, index) => (
            <ThumbnailCard
              id={`multiple-choice-v2-layout-response-card-${index}-${id}`}
              key={`multiple-choice-v2-layout-response-card-key-${index}-${id}`}
              className='multiple-choice-v2-response-card'
              text={p.text}
              image={p.image}
              isSmallThumbnail={isSmallThumbnail}
            />
          )))}

          {isSmallThumbnail && answerCards.length > maxResponseInSmallMode && (
            <div className='response-more-card-text'>
              {answerCards.length - maxResponseInSmallMode} more cards
            </div>
          )}

          {!isSmallThumbnail && (answerCards.map((p, index) => (
            <ThumbnailCard
              id={`multiple-choice-v2-layout-response-card-${index}-${id}`}
              key={`multiple-choice-v2-layout-response-card-key-${index}-${id}`}
              className='multiple-choice-v2-response-card'
              text={p.text}
              image={p.image}
              isSmallThumbnail={isSmallThumbnail}
            />
          )))}
        </div>
      </div>
    </div>
  );
}

MultipleChoiceV2Thumbnail.defaultProps = {
  isSmallThumbnail: false,
  questionCard: {},
  answerCards: [{}, {}],
  singlePage: true,
};

MultipleChoiceV2Thumbnail.propTypes = {
  isSmallThumbnail: PropTypes.bool,
  questionCard: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
  })),
  singlePage: PropTypes.bool,
};

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
} from '@mui/material';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import DateFromToForm from '../../../../components/form/DateFromToForm';
import MultiSelector from '../../../../components/selector/MultiSelector';
import ReportTable from '../../../../components/table/ReportTable';
import SimpleTable from '../../../../components/table/SimpleTable';
import { dateFormatMMddyyyy } from '../../../../AppConstants';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { useGetCurrentStudent } from '../../../hooks/StudentHooks';
import {
  useDetailedReportFilter,
  useSubmitCriteria,
  useGetTeachersForStudent,
  useGetLessonTags,
  useCustomExportPdf,
  createRenderer,
  useGetReportTableConfig,
  summaryTableConfig,
  ALL_SELECTOR,
} from './DetailedReportHooks';
import './DetailedReport.scss';
import '../../common/actions/view-student-answers/ViewStudentTree.scss';
import { useGetSubjectsByUserComponents } from '../../../hooks/SubjectsByUsersHook';

const DetailedReport = () => {
  const subjects = useGetSubjectsByUserComponents();
  subjects.unshift(ALL_SELECTOR);

  const {
    selectedCriteria,
    validDates,
    handleOnDataChange,
    handleOnSelectorChange,
  } = useDetailedReportFilter();

  const {
    loading,
    handleOnSubmitButtonClick,
    reportDataToDisplay,
    summaryData,
    hasLoaded,
  } = useSubmitCriteria();

  const {
    teacherSelectorList,
  } = useGetTeachersForStudent();

  const {
    lessonTagsList,
  } = useGetLessonTags();

  const currentStudent = useGetCurrentStudent();

  const tableConfig = useGetReportTableConfig();

  const studentName = currentStudent && `${currentStudent.firstName} ${currentStudent.lastName}`;

  const customPrintPdf = useCustomExportPdf(lessonTagsList);

  const displaySummaryTable = () => (
    <div className='summary-table-container'>
      {!ObjectUtils.isEmpty(summaryData) && !loading ? (
        <div data-test='summary-table'>
          <SimpleTable
            config={summaryTableConfig}
            tableData={[summaryData]}
            header='Summary'
            tableClassName='summary-table'
          />
          <div className='table-footer-text' data-test='summary-footer-text'>
            Summary only includes activities with more than 0 questions
          </div>
        </div>
      ) : null}
    </div>
  );

  const displayTable = () => {
    if (hasLoaded && reportDataToDisplay && reportDataToDisplay.length === 0 && !loading) {
      return (
        <div className='search-text' data-test='no-result-found'>No Results Found</div>
      );
    } else if (hasLoaded || loading) {
      return (
        <ReportTable
          config={tableConfig}
          tableData={reportDataToDisplay}
          fileName={`VizZle NextGen - Report for ${studentName}`}
          title={`Report for ${studentName}`}
          loading={loading}
          className='student-report-table'
          header='Detail'
          customPrintPdf={(defaultComponents, doc) => customPrintPdf(defaultComponents, doc, currentStudent)}
          data-test='student-report-table'
        />
      );
    } else {
      return (
        <div className='search-text'>
          Please perform a search to retrieve data for {currentStudent.firstName}
        </div>
      );
    }
  };

  return (
    <div className='detailed-report'>
      <div className='criteria'>
        <DateFromToForm
          startDate={selectedCriteria.startDate}
          endDate={selectedCriteria.endDate}
          dateFormat={dateFormatMMddyyyy}
          onChange={handleOnDataChange}
          displayTime
          startTime={selectedCriteria.startTime}
          endTime={selectedCriteria.endTime}
        >
          <ButtonWithSpinner
            className='submit-button'
            label='Submit'
            loading={loading}
            onClick={() => handleOnSubmitButtonClick(selectedCriteria)}
            disabled={!validDates}
            data-test='submit-button'
          />

          <div className='place-holder-filled' />

          <div className='additional-criteria'>
            <div className='checkbox-filter-container'>
              <FormControlLabel
                className='lesson-filter-checkbox'
                data-test='only-complete-lesson-checkbox-form'
                control={(
                  <Checkbox
                    name='showCompletedLessonsOnly'
                    color='primary'
                    checked={selectedCriteria.showCompletedLessonsOnly}
                    onChange={(e) => { handleOnDataChange(e.target.checked, 'showCompletedLessonsOnly'); }}
                    data-test='only-complete-lesson-checkbox'
                  />
                )}
                label='Show only completed activities'
              />
              {/* <FormControlLabel
                className='lesson-filter-checkbox'
                data-test='only-feedback-loop-form'
                control={(
                  <Checkbox
                    name='feedbackLoopOnly'
                    color='primary'
                    checked={selectedCriteria.feedbackLoopOnly}
                    onChange={(e) => { handleOnDataChange(e.target.checked, 'feedbackLoopOnly'); }}
                    data-test='only-feedback-loop-checkbox'
                  />
                )}
                label='Show lessons waiting for feedback'
              /> */}
            </div>
            <FormControl className='criteria-container' data-test='subject-menu'>
              <InputLabel htmlFor='detailed-report-subject'>Subject</InputLabel>
              <MultiSelector
                inputProps={{
                  name: 'subject-title',
                  id: 'detailed-report-subject',
                }}
                className='criterial-selector'
                selectorItems={subjects}
                onChange={(event) => { handleOnSelectorChange(event.target.value, 'subjects', subjects[0].value); }}
                values={selectedCriteria.subjects}
                renderValue={(selected) => createRenderer(selected, subjects)}
                variant='filled'
              />
            </FormControl>
            <FormControl className='criteria-container' data-test='logged-in-user-menu'>
              <InputLabel htmlFor='detailed-report-logged-in-user'>Logged-in User</InputLabel>
              <MultiSelector
                inputProps={{
                  name: 'logged-in-user-title',
                  id: 'detailed-report-logged-in-user',
                  'data-private': 'redact',
                }}
                className='criterial-selector'
                selectorItems={teacherSelectorList}
                onChange={(event) => { handleOnSelectorChange(event.target.value, 'teachers', teacherSelectorList[0].value); }}
                values={selectedCriteria.teachers}
                renderValue={(selected) => createRenderer(selected, teacherSelectorList)}
              />
            </FormControl>
            <FormControl className='criteria-container' data-test='goals-objectives-menu'>
              <InputLabel htmlFor='detailed-report-goal-objectives'>Goals/Objectives</InputLabel>
              <MultiSelector
                inputProps={{
                  name: 'goal-objectives-title',
                  id: 'detailed-report-goal-objectives',
                }}
                className='criterial-selector'
                selectorItems={lessonTagsList}
                onChange={(event) => { handleOnSelectorChange(event.target.value, 'objectives', lessonTagsList[0].value); }}
                values={selectedCriteria.objectives}
                renderValue={(selected) => createRenderer(selected, lessonTagsList)}
              />
            </FormControl>
          </div>
        </DateFromToForm>
      </div>
      <div className='result-tables'>
        {displaySummaryTable()}
        {displayTable()}
      </div>
    </div>
  );
};

export default DetailedReport;

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useLessonPlay } from '../../../context/LessonPlayContextProvider';
import ObjectUtils from '../../../../../utils/ObjectUtils';
import './RejectedAnswerResponse.scss';

/**
 * Sub-component for displaying Reject answer animation
 */
const RejectedAnswerResponse = forwardRef(({ noBorder, showIcon }, ref) => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;

  return userProfile.prompt && (
    <div data-test='rejected-answer-response' ref={ref} className={`rejected-answer-response ${noBorder ? '' : 'rejected-answer-response-border'}`}>
      {showIcon && (
        <div className='rejected-answer-icon' data-test='rejected-answer-icon'>
          X
        </div>
      )}
    </div>
  );
});

export const useShowDropRejection = () => {
  const { lessonPlayDomain } = useLessonPlay();
  const { userProfile } = lessonPlayDomain.domainData;

  return userProfile.prompt ? (rejectedAnswerComponent) => new Promise((resolve) => {
    if (!rejectedAnswerComponent) {
      resolve();
      return;
    }
    ObjectUtils.setTimeout(() => {
      rejectedAnswerComponent.classList.remove('rejected-answer-response-hide');
      rejectedAnswerComponent.classList.add('rejected-answer-response-show');
      ObjectUtils.setTimeout(() => {
        rejectedAnswerComponent.classList.remove('rejected-answer-response-show');
        rejectedAnswerComponent.classList.add('rejected-answer-response-hide');
      }, 200);
    }, 200);
    ObjectUtils.setTimeout(() => {
      resolve();
    }, 1200);
  }) : () => { };
};

export default RejectedAnswerResponse;

RejectedAnswerResponse.defaultProps = {
  noBorder: false,
  showIcon: true,
};

RejectedAnswerResponse.propTypes = {
  noBorder: PropTypes.bool,
  showIcon: PropTypes.bool,
};

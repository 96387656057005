import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './FlexibleMatchingActivityPrompt.scss';
import { Box } from '@mui/material';
import CardActivity from '../../common/card-activity/CardActivity';
import { defaultSubmitText } from '../../../../../utils/activitytype/ActivityTypeInitialData';

const FlexibleMatchingActivityPrompt = forwardRef(({
  onClick,
  submitAnswerText,
}, ref) => (
  <Box
    className='flexible-matching-activity-prompt'
  >
    <CardActivity
      text={submitAnswerText || defaultSubmitText}
      className='flexible-matching-activity-prompt-card'
      ref={ref}
      onClick={onClick}
      fontScale={0.9}
    />
  </Box>
));

export default FlexibleMatchingActivityPrompt;

FlexibleMatchingActivityPrompt.defaultProps = {
  onClick: () => null,
  submitAnswerText: null,
};

FlexibleMatchingActivityPrompt.propTypes = {
  onClick: PropTypes.func,
  submitAnswerText: PropTypes.object,
};

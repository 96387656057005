import { LOADER_TYPE } from '../../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../../states/AppDomainProvider';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';

export const CSV_FILE_TYPE = '.csv';

export const useHandleFileUpload = ({
  uploadServiceFunction,
  setDialogData,
  uploadingMessage,
  onUploadSuccess,
}) => {
  const { uiDomain } = useDomain();
  const {
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file.name.endsWith(CSV_FILE_TYPE)) {
      uiDomain.showSnackbar(true, 'error', 5000, `Please select a *${CSV_FILE_TYPE} file.`);
      return;
    }

    try {
      await callRemoteServiceWrapper(async () => {
        await uiDomain.showLoader(uploadingMessage, LOADER_TYPE.BOUNCE_LOADER);
        const result = await uploadServiceFunction(file);
        await onUploadSuccess();
        await uiDomain.hideLoader();
        setDialogData({
          open: true,
          data: result,
        });
      });
    } catch (error) {
      Logger.logWhenDebugModeIsOn(error);
      await uiDomain.hideLoader();
    }
  };

  return {
    handleOnFileSelect,
  };
};

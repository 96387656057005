import {
  Box,
} from '@mui/material';
import CardSelector from '../../../../../components/selector/CardSelector';
import Logger from '../../../../../utils/Logger';
import FillInTheBlankPageThumbnail from '../../../../../components/vizzle/page/fitb/page/FillInTheBlankPageThumbnail';
import { ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING, ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE } from '../../../../../AppConstants';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getInitialDataForFillInTheBlankData } from '../../../../../utils/activitytype/ActivityTypeInitialData';

const fitbOnePageConfig = [getInitialDataForFillInTheBlankData()];
const fitbTwoPagesConfig = [getInitialDataForFillInTheBlankData(), getInitialDataForFillInTheBlankData()];

const fitbLayoutOnePageComponent = <FillInTheBlankPageThumbnail cardConfig={fitbOnePageConfig} />;
const fitbLayouTwoPageComponent = <FillInTheBlankPageThumbnail cardConfig={fitbTwoPagesConfig} />;

/**
 * Container for Fill in the Blank layout
 */
export default function FillInTheBlankLayout() {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { pagesData, currentSelectedPagesData } = lessonDataMaintenanceDomain.domainData;
  const selectedPage = pagesData.pages[currentSelectedPagesData];

  const config = [
    {
      component: fitbLayoutOnePageComponent,
      dataValue: ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
      onClick: (dataValue, index) => {
        Logger.logWhenDebugModeIsOn({
          SELECTED_ACTIVITY: {
            dataValue,
            index,
          },
        });

        let newPageData = {
          ...selectedPage,
          activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
          cards: [getInitialDataForFillInTheBlankData()],
        };

        if (selectedPage && ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING === selectedPage.activityType) {
          newPageData = {
            ...selectedPage,
            activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE,
            cards: [
              selectedPage.cards[0],
              {
                ...selectedPage.cards[1],
                hide: true,
              },
            ],
          };
        }
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
      },
    },
    {
      component: fitbLayouTwoPageComponent,
      dataValue: ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
      onClick: (dataValue, index) => {
        Logger.logWhenDebugModeIsOn({
          SELECTED_ACTIVITY: {
            dataValue,
            index,
          },
        });
        let newPageData = {
          ...selectedPage,
          activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
          cards: [getInitialDataForFillInTheBlankData(), getInitialDataForFillInTheBlankData()],
        };
        if (selectedPage && ACTIVITY_TYPE_FILL_IN_THE_BLANK_SINGLE === selectedPage.activityType) {
          let secondCard = getInitialDataForFillInTheBlankData();
          if (selectedPage.cards.length > 1) {
            const { hide, ...rest } = selectedPage.cards[1];
            secondCard = { ...rest };
          }
          newPageData = {
            ...selectedPage,
            activityType: ACTIVITY_TYPE_FILL_IN_THE_BLANK_FACING,
            cards: [selectedPage.cards[0], secondCard],
          };
        }
        lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
      },
    },
  ];

  const props = {
    config,
    width: window.innerWidth <= 1024 ? '65vw' : '50vw',
    height: '50vh',
  };

  const selectedIndex = config.findIndex((c) => {
    const activityType = selectedPage ? selectedPage.activityType : null;
    return c.dataValue === activityType;
  });

  return (
    <Box>
      <br />
      <br />
      <CardSelector
        {...props}
        selectedIndex={selectedIndex}
        className='vizzle-fill-in-the-blank-layout'
      />
    </Box>
  );
}

import PropTypes from 'prop-types';
import './UpdateReviewStatusForm.scss';
import DropdownInput from '../../../../../../../../components/selector/DropdownInput';
import { REVIEW_STATUSES } from '../../../../../../components/tracking/actions/LessonTrackingConstants';

const UpdateReviewStatusForm = ({
  answerLesson,
  onDataChange,
  answerFreeFormData,
}) => {
  let hasCommented = false;
  if (answerFreeFormData && answerFreeFormData.length > 0) {
    hasCommented = answerFreeFormData.findIndex((a) => a.attributes.comments) >= 0;
  }

  const reviewStatus = JSON.parse(JSON.stringify(REVIEW_STATUSES));
  reviewStatus[1].disabled = !hasCommented;

  return (
    <div className='update-review-status-form' data-test='update-review-status-form'>
      <div className='update-review-status-form-container'>
        <DropdownInput
          dropdownValues={reviewStatus}
          name='reviewStatus'
          value={answerLesson.reviewStatus >= 0 ? answerLesson.reviewStatus : ''}
          className='subject-selector-dropdown'
          onChange={async (e) => {
            const updatedAnswerLesson = JSON.parse(JSON.stringify(answerLesson));
            updatedAnswerLesson.reviewStatus = e.target.value;
            onDataChange(updatedAnswerLesson);
          }}
          data-test='review-status-options'
        />
      </div>
    </div>
  );
};

UpdateReviewStatusForm.defaultProps = {
  answerLesson: { attributes: {} },
  onDataChange: () => null,
  answerFreeFormData: [],
};

UpdateReviewStatusForm.propTypes = {
  answerLesson: PropTypes.any,
  onDataChange: PropTypes.func,
  answerFreeFormData: PropTypes.array,
};

export default UpdateReviewStatusForm;

import {
  addLicense,
  updateLicense,
  updateMultipleLicenses,
} from '../../../../../services/AdminService';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { useAdmin } from '../../../context/AdminContextProvider';
import DateUtils from '../../../../../utils/DateUtils';

export const usePrepareData = () => {
  const { adminDomain, licensesDomain } = useAdmin();
  const { selectedSchoolId, usersAndLicensesData } = adminDomain.domainData;
  const { selectedLicenseIds } = licensesDomain.domainData;

  let selectedLicenseData = null;
  if (selectedLicenseIds.length === 1 && usersAndLicensesData && usersAndLicensesData.data && usersAndLicensesData.data.relationships && usersAndLicensesData.data.relationships.teachers) {
    const selectedId = selectedLicenseIds[0];

    const selectedLicenseDataId = usersAndLicensesData.data.relationships.licenses.data.find((item) => item.id === selectedId);
    if (selectedLicenseDataId) {
      const selectedLicenseDataObject = usersAndLicensesData.included.find((i) => i.type === selectedLicenseDataId.type && i.id === selectedLicenseDataId.id);
      if (selectedLicenseDataObject) {
        selectedLicenseData = {
          id: selectedLicenseDataObject.id,
          teacherId: selectedLicenseDataObject.relationships.assignedTeacher.data.id,
          active: selectedLicenseDataObject.attributes.active || false,
          quantity: selectedLicenseDataObject.attributes.quantity,
          type: selectedLicenseDataObject.attributes.type,
          startDate: DateUtils.convertYYYYMMddToDate(selectedLicenseDataObject.attributes.startDate),
          expirationDate: DateUtils.convertYYYYMMddToDate(selectedLicenseDataObject.attributes.expirationDate),
        };
      }
    }
  }

  return {
    selectedLicenseData,
    selectedSchoolId,
    selectedLicenseIds,
  };
};

const convertLicenseDataToRequest = (license, selectedSchoolId) => {
  const request = {
    data: {
      attributes: {
        quantity: license.quantity,
        type: license.type,
        active: license.active,
        startDate: DateUtils.convertDateToYYYYMMdd(license.startDate),
        expirationDate: DateUtils.convertDateToYYYYMMdd(license.expirationDate),
      },
      type: 'licenses',
      relationships: {
        school: {
          data: {
            type: 'districts',
            id: selectedSchoolId,
          },
        },
        assignedTeacher: {
          data: {
            type: 'users',
            id: license.teacherId,
          },
        },
      },
    },
  };

  return request;
};

export const useUpdateLicenseData = ({ setOpen, onLicenseUpdateSuccess }) => {
  const { adminDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const updateLicenseData = async (license) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const request = convertLicenseDataToRequest(license, selectedSchoolId);

        await updateLicense(license.id, request);
        await onLicenseUpdateSuccess();
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, 'License is updated.');
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    updateLicenseData,
    loading,
  };
};

export const useAddLicenseData = ({ setOpen, onLicenseAddSuccess }) => {
  const { adminDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { selectedSchoolId } = adminDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const addLicenseData = async (license) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const request = convertLicenseDataToRequest(license, selectedSchoolId);

        await addLicense(request);
        await onLicenseAddSuccess();
        setOpen(false);
        await uiDomain.showSnackbar(true, 'success', 3000, 'License is added.');
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    addLicenseData,
    loading,
  };
};

export const useUpdateMultipleLicenses = ({ onLicensesUpdateSuccess }) => {
  const { licensesDomain } = useAdmin();
  const { uiDomain } = useDomain();
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();

  const updateLicensesData = async (licenseData) => {
    try {
      await callRemoteServiceWrapper(async () => {
        const { selectedLicenseIds } = licensesDomain.domainData;
        const licenseDataPayload = {
          quantity: licenseData.quantity,
          active: licenseData.active,
          type: licenseData.type,
          expirationDate: DateUtils.convertDateToYYYYMMdd(licenseData.expirationDate),
          licenseIds: selectedLicenseIds,
        };
        await updateMultipleLicenses(licenseDataPayload);
        await uiDomain.showSnackbar(true, 'success', 3000, 'License is updated.');
        await onLicensesUpdateSuccess();
        /* Does not need to setOpen(false) as the component is hidden after the table data is reloaded */
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  return {
    updateLicensesData,
    loading,
  };
};

import { useEffect, useRef, useState } from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CustomDialog from '../../../../../components/dialog/CustomDialog';
import { useAdmin } from '../../../context/AdminContextProvider';
import GenericButton from '../../../components/buttons/GenericButton';
import TeacherSchoolRosterSchoolSelector from './forms/TeacherSchoolRosterSchoolSelector';
import { useGetTeacherSchoolAccess, useUpdateTeacherSchoolsAccess } from './TeacherActionHooks';
import './TeacherSchoolRosterAccessAction.scss';

const TeacherSchoolRosterAccessAction = () => {
  const [open, setOpen] = useState(false);
  const dataRef = useRef();

  const { adminTeachersDomain, adminDomain } = useAdmin();
  const { selectedSchoolId } = adminDomain.domainData;
  const { selectedTeacherIds } = adminTeachersDomain.domainData;

  const handleOnCancel = () => {
    setOpen(false);
  };

  const {
    getSelectdTeacherSchoolAccessTeachers,
    loading,
    selectedSchoolIds,
  } = useGetTeacherSchoolAccess();

  useEffect(() => {
    dataRef.current = selectedSchoolIds;
  }, [selectedSchoolIds]);

  useEffect(() => {
    if (open) {
      getSelectdTeacherSchoolAccessTeachers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    saving,
    updateTeacherShoolsAccess,
  } = useUpdateTeacherSchoolsAccess({
    setOpen,
  });

  const confirmActions = () => {
    const buttons = [{
      onClick: handleOnCancel,
      className: 'btn-danger margin-right',
      title: 'Cancel',
      loading: saving,
    }, {
      onClick: () => {
        updateTeacherShoolsAccess(selectedSchoolIds, dataRef.current);
      },
      className: 'btn-primary action-button',
      title: 'Save',
      loading: saving,
    },
    ];
    return buttons;
  };

  return (
    <>
      <GenericButton
        ariaLabel='Give Access to Other School Rosters'
        onClick={() => {
          setOpen(true);
        }}
        disabled={!selectedTeacherIds || selectedTeacherIds.length === 0}
        icon={LocationCityIcon}
      />
      {open && (
        <CustomDialog
          openDialog={open}
          className='teacher-school-roster-access-dialog'
          onClose={handleOnCancel}
          title='School Roster Shared Access'
          content={(
            <TeacherSchoolRosterSchoolSelector
              initSelectedSchoolIds={selectedSchoolIds}
              disabledSchoolIds={[selectedSchoolId]}
              onDataChange={(updatedData) => { dataRef.current = updatedData; }}
              sortBySelectedIds
              loading={loading}
            />
          )}
          displayCloseIcon
          actions={confirmActions()}
        />
      )}
    </>
  );
};

export default TeacherSchoolRosterAccessAction;

/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */

import Logger from '../../utils/Logger';
import ObjectUtils from '../../utils/ObjectUtils';

/* Regular expression for number x number */
const MULTIPLY_REGEXP = /[(]*[-]*\d+[ ]*(x|X)[ ]*[(]*[-]*\d+[)]*([ ]*(x|X)[ ]*[-]*\d+)*[)]*/;
const PLUS_REGEXP = /[0-9a-zA-Z]+([ ])*\+([ ])*[0-9a-zA-Z]+(([ ])*\+([ ])*[0-9a-zA-Z]+)*/g;
const MINUS_DASH_REGEXP = /[a-zA-Z]+([ ])*-([ ])*[a-zA-Z]+(([ ])*-([ ])*[a-zA-Z]+)*/g;
const MINUS_REGEXP = /[0-9a-zA-Z]+([ ])*-([ ])*[0-9a-zA-Z]+(([ ])*-([ ])*[0-9a-zA-Z]+)*/g;
const MINUS_ONE_DIGIT_REGEXP = /-[ ]([ ])*\d/;
const NEGATIVE_REGEXP = /([ ]|\n)*-\d/;
const NEGATIVE_IN_EQUATION_REGEXP = /([ ]|\n)*-\d/g;
const BLANG_SPACE_REGEXP = /(=|\n)([ ])*___*([ ]|\n)*/;
const BLANK_REGEXP = /__*([ ]*__*)/;
const CONSECUTIVE_DASH_REGEXP = /--+/;
const CONSECUTIVE_DOTS_REGEXP = /\.\.+/;
const QUESTION_MARK_REGEXT = /\\?/;
// eslint-disable-next-line no-useless-escape
const EXCLAMATION_MARK_REGEXT = /\!/;
const ABSOLUTE_VALUE = /\|[ ]*-?\d+\|/g;
const PIPES = /\|\|/g;

/* A functon to convert
  * string of number x number to number times number
  * so that tts could read out 'times' instead of 'by'
  */
const replaceString = (regResult, regToReplace, newWord, result) => {
  for (let i = 0; i < regResult.length; i++) {
    let match = regResult[i];
    if (match) {
      match = match.replace(/\+/g, '\\+');
      let newSentence = match.replace(regToReplace, newWord);
      newSentence = newSentence.replace(/\\/g, '');
      const regex = new RegExp(match, 'g');
      result = result.replace(regex, newSentence);
    }
  }
  return result;
};

const checkAndConvertText = (text) => {
  let result = text;
  let test = text.match(MULTIPLY_REGEXP);

  if (test) {
    result = replaceString(test, /(x|X)/g, 'times', result);
    test = result.match(NEGATIVE_IN_EQUATION_REGEXP);
    if (test) {
      result = replaceString(test, /-/g, ' negative ', result);
    }
  }

  test = result.match(BLANG_SPACE_REGEXP);
  if (test) {
    result = result.replace(/_+/g, ' ');
  }

  test = result.match(BLANK_REGEXP);
  if (test) {
    result = result.replace(/_+/g, 'blank');
  }

  test = result.match(CONSECUTIVE_DASH_REGEXP);
  if (test) {
    result = result.replace(/--+/, ' ');
  }

  test = result.match(CONSECUTIVE_DOTS_REGEXP);
  if (test) {
    result = result.replace(/\.\.+/, ' ');
  }

  test = result.match(QUESTION_MARK_REGEXT);
  if (test) {
    result = result.replace(/\?/, ' ');
  }

  test = result.match(EXCLAMATION_MARK_REGEXT);
  if (test) {
    // eslint-disable-next-line no-useless-escape
    result = result.replace(/\!/g, ' ');
  }

  test = result.match(ABSOLUTE_VALUE);
  if (test) {
    for (let i = 0; i < test.length; i++) {
      const el = test[i];
      let newText = el.replace(/\|/g, '');
      newText = newText.replace('-', 'negative ');
      newText = `absolute ${newText} `;
      result = result.replace(el, newText);
    }
  }

  test = result.match(PIPES);
  if (test) {
    result = result.replace(/\|\|/g, ' pipes ');
  }

  test = result.match(PLUS_REGEXP);
  if (test) {
    result = replaceString(test, /\+/g, ' plus ', result);
  }

  test = result.match(MINUS_DASH_REGEXP);
  if (test) {
    result = replaceString(test, /-/g, ' ', result);
  }

  test = result.match(MINUS_REGEXP) || result.match(MINUS_ONE_DIGIT_REGEXP);
  if (test) {
    if (test[0].indexOf(' ') === -1) {
      result = replaceString(test, /-/g, '', result);
    } else {
      result = replaceString(test, /-/g, ' minus ', result);
    }
  }

  test = result.match(NEGATIVE_REGEXP);
  if (test) {
    result = replaceString(test, /-/g, ' negative ', result);
  }

  if (result) {
    return result;
  }
  return text;
};

/* Get all available voice types */
export const VOICE_TYPES = [
  { key: 0, value: 0, name: 'US English Female' },
  { key: 1, value: 1, name: 'UK English Female' },
  { key: 2, value: 2, name: 'UK English Male' },
];

// export const HIGHLIGHT_COLORS = [
//   { key: 'Orange', value: '#ef7c22', name: 'Orange' },
//   { key: 'Blue', value: '#509fd6', name: 'Blue' },
//   { key: 'Green', value: '#78b543', name: 'Green' },
//   { key: 'Yellow', value: '#f4f442', name: 'Yellow' },
// ];

// export const TTS_DEFAULT_VALUE = {
//   rate: 10,
//   pitch: 10,
//   volume: 10,
//   voice: 0,
//   highlightColor: HIGHLIGHT_COLORS[0].value,
// };

const cleanupText = (text) => {
  text = text.replace(/<br>/gi, ' ');
  text = text.replace(/ \./gi, ' ');
  return text;
};

const cleanupClass = () => {
  const elements = document.querySelectorAll('.tts-playing');
  if (elements && elements.length > 0) {
    elements.forEach((e) => {
      e.style.boxShadow = '';
      e.classList.remove('tts-playing');
    });
  }
};

const waitFor = (ms) => new Promise((resolve) => {
  const t = setTimeout(() => {
    clearTimeout(t);
    resolve();
  }, ms);
});

const WAIT_TIME = 5000;

const cancelLoading = () => {
  /* Only call responsiveVoice.cancel() does not work.
   It needs to cancel the loading process of the audio file in case it makes a service call to the
   RV server.
  */
  if (responsiveVoice.fallback_audio) {
    responsiveVoice.fallback_audio.removeAttribute('src');
    responsiveVoice.fallback_audio.load();
  }
};

const playText = (
  el,
  voiceType = VOICE_TYPES[0].name,
  highlightColor,
  config,
) => {
  if (!el) {
    return Promise.resolve();
  }
  try {
    const text = el.innerText;
    const cleanText = cleanupText(text);
    const convertedText = checkAndConvertText(cleanText);

    return new Promise((resolve, reject) => {
      // If the RV cannot start within 5 secs then resolve it immediately
      const startTimeout = ObjectUtils.setTimeout(async () => {
        if (convertedText.trim().length === 0) {
          return;
        }
        Logger.logWhenDebugModeIsOn(`Cannot start tts within ${WAIT_TIME / 1000} seconds.`);
        clearTimeout(startTimeout);
        cleanupClass();

        responsiveVoice.cancel();
        cancelLoading();

        await waitFor(100);
        resolve();
      }, WAIT_TIME + 50);

      const responsiveVoiceParams = {
        onstart: () => {
          if (responsiveVoice.isPlaying()) {
            clearTimeout(startTimeout);
          }
          cleanupClass();
          el.style.setProperty('box-shadow', `0px 0px 8px 8px ${highlightColor}`, 'important');
          el.classList.add('tts-playing');
        },
        onend: () => {
          clearTimeout(startTimeout);
          cleanupClass();
          resolve();
        },
        onerror: () => {
          clearTimeout(startTimeout);
          /**
           * NOTE for Responsive voice
           * DO NOT implment the onpause listener and execute resolve, reject as
           * when calling resonsiveVoice.pause it actually calls pause and cancel inside the implmentation of
           * ResponsiveVoice. So onerror and onpause will be called
           */
          cleanupClass();
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            isCancel: true,
          });
        },
        ...config,
      };
      if (convertedText.trim().length === 0) {
        clearTimeout(startTimeout);
        resolve();
      } else {
        try {
          // eslint-disable-next-line no-console
          console.log(`Voice type: ${voiceType}`);
          responsiveVoice.speak(convertedText, voiceType, responsiveVoiceParams);
          const cancelTimeout = () => {
            Logger.logWhenDebugModeIsOn('Start fallback audio');
            clearTimeout(startTimeout);
            responsiveVoice.fallback_audio.removeEventListener('loadeddata', cancelTimeout);
          };

          if (responsiveVoice.fallback_audio) {
            responsiveVoice.fallback_audio.addEventListener('loadeddata', cancelTimeout, { once: true });
            const handleOnError = () => {
              // Handle when the file cannot be retrived.
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                isCancel: true,
              });
            };
            responsiveVoice.fallback_audio.addEventListener('error', handleOnError, { once: true });
          }
        } catch (e) {
          Logger.logError(e);
          resolve();
        }
      }
    });
  } catch (e) {
    Logger.logError(e);
    return Promise.resolve();
  }
};

/**
 * Synthesis list of texts and speak the text.
 * @param {*} list of element to speak the text. this should be an array
 * @param {*} config configuration with the shape of
 * {
 *    rate: number,
 *    pitch: number,
 *    volume: number,
 *    voice: number,
 *    highlightColor: string of hex color,
 * }
 */
export const playTextToSpeech = async (elList = [], config) => (
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      if (responsiveVoice.isPlaying()) {
        responsiveVoice.pause();
      }
      responsiveVoice.cancel();
      cancelLoading();
      await waitFor(100);
      const { rate, pitch, volume, voice, highlightColor } = config;

      const voiceType = VOICE_TYPES.find((v) => v.value === voice);
      const responsiveConfig = {
        rate: rate / 10,
        pitch: pitch / 10,
        volume: volume / 10,
      };

      for (let index = 0; index < elList.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await playText(elList[index], voiceType.name, highlightColor, responsiveConfig);
      }
      resolve();
    } catch (e) {
      reject(e);
    }
  }));

export const cancelTts = () => {
  responsiveVoice.cancel();
  cancelLoading();
  const ttsPlaying = document.querySelectorAll('.tts-playing');
  if (ttsPlaying && ttsPlaying.length > 0) {
    ttsPlaying.forEach((el) => {
      el.style.boxShadow = '';
      el.classList.remove('tts-playing');
    });
  }
};

export const readText = (textToRead, config) => {
  if (!config.enabled) {
    return;
  }
  responsiveVoice.cancel();
  cancelLoading();
  const { rate, pitch, volume, voice } = config;
  const voiceType = VOICE_TYPES.find((v) => v.value === voice);
  const responsiveConfig = {
    rate: rate / 10,
    pitch: pitch / 10,
    volume: volume / 10,
  };
  responsiveVoice.speak(textToRead, voiceType.name, responsiveConfig);
};

export const isTtsPlaying = () => responsiveVoice.isPlaying();

export const reigsterTextToSpeechAutoPlay = () => {
  responsiveVoice.enableWindowClickHook();
  const voiceType = VOICE_TYPES[0];
  responsiveVoice.speak('', voiceType.name);

  // eslint-disable-next-line no-console
  // console.log(`is voice support: ${responsiveVoice.voiceSupport()}`);
  // eslint-disable-next-line no-console
  if (window && window.speechSynthesis) {
    // eslint-disable-next-line no-console
    // console.log(window.speechSynthesis.getVoices());

    if (responsiveVoice.is_android) {
      responsiveVoice.fallbackMode = false;
      responsiveVoice.forcedFallbackMode = false;
      responsiveVoice.init();
    }
  }
};

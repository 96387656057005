import { createContext, useContext } from 'react';
import ExtensitionActivitiesDomain from './ExtensitionActivitiesDomain';
import TeacherCourseDomain from './TeacherCourseDomain';
import TeacherCourseSearchCriteriaDomain from './TeacherCourseSearchCriteriaDomain';

const domains = [
  new TeacherCourseDomain(),
  new TeacherCourseSearchCriteriaDomain(true),
  new ExtensitionActivitiesDomain(),
];

/*-----------------------------------------------------------------------*/

let TeacherCourseContextProvider;
const result = {};
export const initDomains = () => {
  domains.forEach((element) => {
    element.init();
    result[element.domainName] = element;
  });
  TeacherCourseContextProvider = createContext(result);
};

/** Get application domains fromt the context */
export const useTeacherCourses = () => useContext(TeacherCourseContextProvider);

import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { Button, Paper } from '@mui/material';
import LoadingSpinner from '../../../../../components/loaders/LoadingSpinner';
import { useGetUnitGuideData } from '../../unit-guide/UnitGuidePdfGeneratorHooks';
import './UnitGuidePdfGenerator.scss';
import NoLessonPaper from '../../../../../components/paper/NoLessonPaper';
import { useGetCourse } from '../topic-lessons/TeacherCourseLessonsHooks';
import UnitGuidePage from './unit-guide-pdf/UnitGuidePage';

const UnitGuidePdfGenerator = ({ courseId, unitId }) => {
  const {
    unitGuideData,
    loading,
  } = useGetUnitGuideData({ courseId, unitId });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforePrint: () => {
      window.history.pushState({}, '', 'Ⓒ RethinkEd. All rights reserved');
    },
    onAfterPrint: () => {
      window.history.back();
    },
    content: () => componentRef.current,
  });

  const course = useGetCourse(courseId);

  if (loading) {
    return (
      <div className='unit-guide-pdf-generator loading-container' data-test='loading-container'>
        <LoadingSpinner message='Loading Unit Guide' />
      </div>
    );
  }

  if (!unitGuideData || !unitGuideData.unitGuideInfo || unitGuideData.unitGuideInfo.length === 0) {
    return <NoLessonPaper message='No Unit Guide' />;
  }

  return (
    <div className='unit-guide-pdf-generator unit-guide-pdf-container'>
      <Button className='btn-primary' data-test='unit-guide-export-pdf' onClick={() => { handlePrint(); }}>PDF</Button>
      <Paper elevation={3} className='generated-unit-paper-container'>
        <UnitGuidePage ref={componentRef} unitGuideData={unitGuideData} course={course} />
      </Paper>
      <br />
    </div>
  );
};

UnitGuidePdfGenerator.defaultProps = {
  courseId: null,
  unitId: null,
};

UnitGuidePdfGenerator.propTypes = {
  courseId: PropTypes.number,
  unitId: PropTypes.number,
};

export default UnitGuidePdfGenerator;

export const INITIAL_PLAY_ID = 0;
export const UNDER_REVIEW_ID = 1;
export const UNDER_REVISION_ID = 2;
export const REVIEW_FINISHED_ID = 3;

export const REVIEW_STATUSES = [
  {
    id: UNDER_REVIEW_ID,
    name: 'Under Review',
  },
  {
    id: UNDER_REVISION_ID,
    name: 'Under Revision',
  },
  {
    id: REVIEW_FINISHED_ID,
    name: 'Review Finished',
  },
];

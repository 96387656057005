import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl } from '@mui/material';
import { REINFORCER_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';
import DropdownInput from '../../../../components/selector/DropdownInput';
import './ReinforcerStyleSetting.scss';

const reinforcerOptions = [
  {
    id: REINFORCER_STYLE_KEY.animated, name: 'Animated',
  },
  {
    id: REINFORCER_STYLE_KEY.simple, name: 'Simple',
  },
  {
    id: REINFORCER_STYLE_KEY.none, name: 'None',
  },
];

export default function ReinforcerStyleSetting({ profile, onChangeCallback }) {
  const [reinforcerStyle, setReinforcerStyle] = useState(profile.reinforcerStyle);
  return (
    <Box display='flex' className='reinforcer-style-settings' data-test='reinforcer-style-settings-container'>
      <FormControl variant='outlined'>
        <DropdownInput
          dropdownValues={reinforcerOptions}
          label='Select Style'
          labelVariant='h6'
          value={reinforcerStyle}
          onChange={(e) => {
            setReinforcerStyle(e.target.value);
            onChangeCallback(e.target.value);
          }}
          className='reinforcer-style-dropdown-select'
          data-test='reinforcer-style-settings-selector'
        />
      </FormControl>
    </Box>
  );
}

ReinforcerStyleSetting.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

/* URL name section */
export const URL_LOGIN = '/login';
export const URL_LOGIN_RETHINK_SIGNIN_OIDC = '/signin-oidc';
export const URL_VIZZLE_LOGIN = 'vizzle-login';
export const URL_LANDING_LOGIN = '/landing-login';
export const URL_LOGIN_WITH_VIZZLE_TOKEN = '/login-vizzle-token';
export const URL_RESET_PASSWORD = '/reset-password/:reset_code';
export const URL_USER_VERIFY = '/users/verify/:verfyCode';
export const URL_LOGIN_RETHINK = '/login-rethink';
export const URL_LOGIN_FROM_RETHINK_WITH_ACCESS_TOKEN = 'rethink-sso/authenticate';
export const URL_LOGIN_FROM_RETHINK_MAPP = 'rethink-sso/map';
export const URL_LOGIN_WITH_CLEVER_CODE = '/clever-login';
export const URL_LOGIN_WITH_CLASS_LINK = '/classlink-login';

export const URL_LESSON = '/lesson/:lessonUuid';
export const URL_LESSON_FINISHED = '/lesson-finished';

/* Terms of use */
export const URL_TERMS_OF_USE = '/terms-of-use';

/* Landing urls */
export const URL_LANDING_STUDENT = '/landing-student';

/* Authoring urls */
// export const URL_AUTHORED_LESSONS = '/authoredlessons';
export const URL_LESSON_UPDATE = '/lessons/:lessonUuid/update/';
export const URL_LESSON_CREATE = '/lessons/create';

/* Teacher urls */
export const URL_TEACHER = '/teacher';
export const URL_MY_CLASSROOM = '/myclass';
export const URL_MY_STUDENT = '/mystudent';
export const URL_LESSONS = '/lessons';
// export const URL_MY_LESSONS = '/mylessons';
export const URL_MY_SETTINGS = '/mysettings';
export const URL_TEACHER_CURRICULUM = '/teacher-curriculum';
export const URL_TEACHER_VERTICAL_CURRICULUM = '/teacher-vertical-curriculum';
export const URL_COURSES = '/courses/:subjectId';
export const URL_SKILLS_SUITE = '/skill-suite/:subjectId';
export const URL_GETTING_STARTED = '/getting-started';

/* Student urls */
export const URL_LESSON_SELECTION = '/lesson-selection/:lessonTag';

/* Super admin urls */
export const URL_SETUP_TWO_FACTOR_AUTH = '/setup-two-factor-auth';
export const URL_ENTER_TWO_FACTOR_AUTH = '/enter-two-factor-auth';

/* Admin urls */
export const URL_ADMIN = '/admin';

export const TEACHER_LANDING = URL_MY_CLASSROOM;
export const STUDENT_LANDING = URL_LANDING_STUDENT;

import { useDomain } from '../../../../../states/AppDomainProvider';
import { useTeacherCourses } from '../../../context/TeacherCourseContextProvider';
import TeacherCourseInstruction from '../TeacherCourseInstruction';
import ExtensionActivitiesFileUploadMain from './components/ExtensionActivitiesFileUploadMain';
import ExtensionActivitiesFileList from './components/ExtensionActivitiesFileList';

const ExtensionActivities = () => {
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const { teacherCourseSearchCriteriaDomain } = useTeacherCourses();
  const { hasPerformSearch } = teacherCourseSearchCriteriaDomain.domainData;

  if (!hasPerformSearch) {
    return (<TeacherCourseInstruction />);
  }

  if (user.userModerator) {
    return <ExtensionActivitiesFileUploadMain />;
  }

  return <ExtensionActivitiesFileList />;
};

export default ExtensionActivities;

import PropTypes from 'prop-types';
import {
  Box,
  Card,
} from '@mui/material';
import CardEditor from '../common/card-editor/CardEditor';
import { AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS, SECTION_AUDIO, SECTION_IMAGE, SECTION_TEXT } from '../common/CardEditorConstants';
import './MultipleChoiceComponent.scss';
import './MultipleChoiceEditor.scss';
import MatchingQuestionKey from '../common/question-key/MatchingQuestionKey';
import MatchingAnswerKey from '../common/question-key/MatchingAnswerKey';

export default function MultipleChoiceEditor({
  selectedSection,
  onCardSelected,
  questionCard,
  answerCards,
  onQuestionKeyChange,
  onAnswerKeyChange,
  singlePage,
}) {
  const handleOnCardSelected = (area, cardIndex, section) => {
    const params = {
      selectedArea: area,
      selectedCardIndex: cardIndex,
      selectedSection: section,
    };
    onCardSelected(params);
  };

  const getCardEditor = (card, area, cardIndex, onlyText, className) => (
    <CardEditor
      className={className}
      isSelected={selectedSection.selectedArea === area
        && selectedSection.selectedCardIndex === cardIndex}
      config={{
        text: card.text,
        image: card.image,
        video: card.video,
        audio: card.audio,
      }}
      selectedSection={selectedSection.selectedSection}
      onImageSelected={() => {
        handleOnCardSelected(area, cardIndex, SECTION_IMAGE);
      }}
      onTextSelected={() => {
        handleOnCardSelected(area, cardIndex, SECTION_TEXT);
      }}
      onAudioSelected={() => {
        handleOnCardSelected(area, cardIndex, SECTION_AUDIO);
      }}
      isImageIncluded={!onlyText}
      isAudioIncluded={!onlyText}
      isVideoIncluded={!onlyText}
    />
  );

  const handleQuestionKeyChange = (newKey) => {
    onQuestionKeyChange({
      ...questionCard,
      questionKey: newKey,
    });
  };

  const handleonAnswerKeyChange = (index, newKey) => {
    const responseCardKeys = answerCards.map(((c) => c.answerKey));
    responseCardKeys[index] = newKey;
    onAnswerKeyChange(responseCardKeys);
  };

  return (
    <Box className={`multiple-choice-component ${singlePage ? 'single-page' : 'side-by-side'} multiple-choice-editor`}>
      <Card className='multiple-choice-component-question multiple-choice-component-background'>
        <MatchingQuestionKey questionKey={questionCard.questionKey} onKeyChange={(value) => { handleQuestionKeyChange(value); }} />
        {getCardEditor(questionCard,
          AREA_PROMPT_CARDS,
          0)}
      </Card>
      <Box className='multiple-choice-component-responses'>
        {answerCards.map((card, index) => {
          const key = `multiple-choice-answer-key-${index}-${card.answerKey}`;
          return (
            <Box key={key} position='relative' display='flex' alignItems='center'>
              <MatchingAnswerKey
                value={card.answerKey}
                onKeyChange={(value) => { handleonAnswerKeyChange(index, value); }}
                disabled={index === 0}
                isCorrect={card.answerKey && questionCard.questionKey === card.answerKey}
              />
              {getCardEditor(
                card,
                AREA_RESPONSE_CARDS,
                index,
                true,
                'multiple-choice-component-response',
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

MultipleChoiceEditor.defaultProps = {
  questionCard: {},
  answerCards: [{}, {}, {}, {}, {}],
  selectedSection: {
    selectedArea: AREA_PROMPT_CARDS,
    selectedCardIndex: 0,
    selectedSection: SECTION_AUDIO,
  },
  onCardSelected: () => null,
  onQuestionKeyChange: () => null,
  onAnswerKeyChange: () => null,
  singlePage: true,
};

MultipleChoiceEditor.propTypes = {
  questionCard: PropTypes.shape({
    questionKey: PropTypes.string,
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  answerCards: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  })),
  selectedSection: PropTypes.shape({
    selectedArea: PropTypes.oneOf([AREA_PROMPT_CARDS, AREA_RESPONSE_CARDS]),
    selectedSection: PropTypes.oneOf([SECTION_IMAGE, SECTION_TEXT, SECTION_AUDIO]),
    selectedCardIndex: PropTypes.number,
  }),

  onCardSelected: PropTypes.func,
  onQuestionKeyChange: PropTypes.func,
  onAnswerKeyChange: PropTypes.func,
  singlePage: PropTypes.bool,
};

import { useState } from 'react';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import { resetPassword } from '../../../services/LoginService';
import Logger from '../../../utils/Logger';
import { useDomain } from '../../../states/AppDomainProvider';
import { EMAIL_REGEXP } from '../../../constants/Regex';

export const DIALOG_STATE = {
  CLOSE: 0,
  RESET_PASSWORD: 1,
  EMAIL_SENT: 2,
  STUDENT: 3,
};

export const useDialogControl = () => {
  const [state, setState] = useState(0);
  const {
    loading,
    callRemoteServiceWrapper,
  } = useGetRemoteServiceWrapper();

  const handleOnForgotPasswordClick = () => {
    setState(DIALOG_STATE.RESET_PASSWORD);
  };

  const handleOnClose = () => {
    if (loading) {
      return;
    }
    setState(DIALOG_STATE.CLOSE);
  };

  const handleNextSubmit = async (value) => {
    const result = EMAIL_REGEXP.test(value);
    if (result) {
      try {
        await callRemoteServiceWrapper(async () => {
          await resetPassword(value, window.location.origin);
        });
        setState(DIALOG_STATE.EMAIL_SENT);
      } catch (e) {
        Logger.logError(e);
      }
    } else {
      setState(DIALOG_STATE.STUDENT);
    }
  };

  const handleStudentBack = () => {
    setState(DIALOG_STATE.RESET_PASSWORD);
  };

  return {
    state,
    handleOnForgotPasswordClick,
    handleOnClose,
    handleNextSubmit,
    handleStudentBack,
    loading,
  };
};

export const useHandleSnackbar = () => {
  const { uiDomain } = useDomain();
  const {
    snackbar,
  } = uiDomain.domainData;

  const onSnackbarClose = () => {
    uiDomain.closeSnackbar();
  };

  return {
    snackbar,
    onSnackbarClose,
  };
};

import { useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, Typography,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import usePrintLesson from '../../../hooks/PrintHook';
import { LOADER_TYPE } from '../../../../components/loaders/SelectableLoader';
import { useDomain } from '../../../../states/AppDomainProvider';
import LessonPageDataPrint from '../../../../components/vizzle/print/LessonPageDataPrint';
import './PrintLessonMenuItem.scss';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { isSafari } from '../../../../utils/DeviceUtils';

const loadImage = (src) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => {
    resolve();
  };
  image.onerror = () => {
    reject();
  };
  image.src = src;
});

const PrintLessonMenuItem = forwardRef(({
  lesson, currentUserProfile, onClick, onAfterPrint,
}, ref) => {
  const { uiDomain } = useDomain();
  const printContainerRef = useRef();
  const componentRef = useRef();
  const imagesList = useRef([]);

  const { getLessonDataPage } = usePrintLesson();
  const [renderComponent, setRenderComponent] = useState(null);

  const waitImage = async () => {
    try {
      await Promise.all(imagesList.current);
    } catch (_e) {
      // ignore error
    }

    // Fix for safari. image.onload is not triggered.
    if (isSafari()) {
      await ObjectUtils.delay(Math.max(imagesList.current * 260, 3500));
    }
    uiDomain.hideLoader();
    return Promise.resolve();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: waitImage,
    removeAfterPrint: true,
    onAfterPrint: () => {
      printContainerRef.current.innerHTML = '';
      onAfterPrint();
    },
  });

  const handlePrintClicked = async () => {
    uiDomain.showLoader((
      <div className='print-waiting-message'>
        Preparing lesson
        <Typography fontWeight={500}>{lesson.attributes.name}</Typography>
        for printing
      </div>
    ), LOADER_TYPE.HASH_LOADER);
    let component = renderComponent;
    if (component === null) {
      const result = await getLessonDataPage(lesson, currentUserProfile);
      component = (
        <LessonPageDataPrint
          pageData={result}
          ref={componentRef}
        />
      );
      setRenderComponent(component);
    }

    ObjectUtils.setTimeout(() => {
      if (componentRef.current !== null) {
        const imageEls = componentRef.current.querySelectorAll('div[class$="-image"]');
        if (imageEls && imageEls.length > 0) {
          imagesList.current = [];
          imageEls.forEach((child) => {
            const src = child.style.backgroundImage.slice(4, -1).replace(/"/g, '');
            if (src && src.trim().length > 0) {
              imagesList.current.push(loadImage(src));
            }
          });
        }
      }
      handlePrint();
    }, 100);
  };

  return (
    <>
      <MenuItem ref={ref} onClick={() => { onClick(); handlePrintClicked(); }}>Print</MenuItem>
      <div ref={printContainerRef} style={{ position: 'fixed', left: '-100000px', top: '-100000px' }}>
        {renderComponent}
      </div>
    </>
  );
});

PrintLessonMenuItem.defaultProps = {
  lesson: {},
  onClick: () => { },
  onAfterPrint: () => { },
};
PrintLessonMenuItem.propTypes = {
  currentUserProfile: PropTypes.shape({
    maxMatchingChoices: PropTypes.number,
  }).isRequired,
  lesson: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    meta: PropTypes.object,
  }),
  onClick: PropTypes.func,
  onAfterPrint: PropTypes.func,
};

export default PrintLessonMenuItem;

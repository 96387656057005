import { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardMedia,
} from '@mui/material';
import TextLines from '../../../../../components/vizzle/text/TextLines';
import MediaPlayer from '../../../../../components/vizzle/media/MediaPlayer';
import { useTtsPlay } from '../../../utils/UseTtsPlay';
import './CauseAndEffectActivityCard.scss';
import { useDetermineClickOrTouchEventForDnd } from '../../../../hooks/DndClickOrTouchHooks';
import { useRegisterSwitchAccessSelectEvent } from '../../../../switch-access/SwitchAccessHooks';

const CauseAndEffectActivityCard = forwardRef(({
  card,
  cardId,
  disablePlayMedia,
  onClick,
  oSwitchAccessSelect,
  children,
}, ref) => {
  const {
    ttsConfig,
    setTextLineRef,
    elementToPlayTts,
  } = useTtsPlay();

  const cardRef = useRef(null);
  useImperativeHandle(ref, () => ({
    getEnableElements: () => cardRef.current,
  }));

  const handleEvent = useDetermineClickOrTouchEventForDnd(
    (e) => {
      if (onClick) {
        onClick(e, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
      }
    },
    true,
  );

  useRegisterSwitchAccessSelectEvent(cardRef, () => {
    oSwitchAccessSelect(null, ttsConfig.enabled && elementToPlayTts && elementToPlayTts.length > 0);
  });

  return (
    <Box
      id={cardId}
      key={cardId}
      boxShadow={2}
      className='cause-and-effect-activity-card'
      ref={cardRef}
      {...handleEvent}
      data-skip-switch-access-click='true'
    >
      {disablePlayMedia ? '' : (card.audio || card.video || (elementToPlayTts && elementToPlayTts.length > 0))
        && (
          <MediaPlayer
            audioUrl={card.audio}
            videoUrl={card.video}
            parentElementId={cardId}
            className='cause-and-effect-card-media'
            textToRead={elementToPlayTts}
            tts={ttsConfig}
            forDndElement
          />
        )}
      {card.image ? <CardMedia image={card.image} className='cause-and-effect-card-image' /> : ''}

      {card.text ? (
        <div className={`cause-and-effect-card-text ${card.image ? 'text-with-image' : ''}`}>
          <TextLines
            textLines={card.text}
            ref={setTextLineRef}
            componentKey={`text-line-${cardId}`}
          />
        </div>
      ) : ''}

      {children}
    </Box>
  );
});

export default CauseAndEffectActivityCard;

CauseAndEffectActivityCard.defaultProps = {
  card: {},
  cardId: '',
  disablePlayMedia: false,
  onClick: () => { },
  oSwitchAccessSelect: () => { },
  children: null,
};

CauseAndEffectActivityCard.propTypes = {
  card: PropTypes.shape({
    text: PropTypes.object,
    image: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
  }),
  cardId: PropTypes.string,
  disablePlayMedia: PropTypes.bool,
  onClick: PropTypes.func,
  oSwitchAccessSelect: PropTypes.func,
  children: PropTypes.node,
};

import PropTypes from 'prop-types';
import ButtonWithSpinner from '../../../../../../../../components/buttons/ButtonWithSpinner';
import DropdownInput from '../../../../../../../../components/selector/DropdownInput';
import { NON_ACADEMIC_SUBJECTS, SUBJECT_CONSTANTS } from '../../../../../../../../constants/LessonConstant';
import { getLessonLevels } from '../../../../../../../../services/LessonStandardService';
import { getCourseDropdownOptions, getUnitDropdownOptions, getTopicDropdownOptions } from '../../../../../../../courses/components/TeacherCourseSearchBarHooks';
import './TeacherCourseFilter.scss';
import { useGetLessonCourseData, useHandleFormChange } from './TeacherCourseFilterHooks';

export const subjectList = [
  SUBJECT_CONSTANTS.math,
  SUBJECT_CONSTANTS.science,
  SUBJECT_CONSTANTS.social,
  SUBJECT_CONSTANTS.english,
  SUBJECT_CONSTANTS.life,
  NON_ACADEMIC_SUBJECTS.transitionalSkills,
  NON_ACADEMIC_SUBJECTS.socialSkills,
];

const subjectOptions = subjectList.map((s) => ({
  id: s.subjectId,
  name: s.name,
}));

export default function TeacherCourseFilter({
  onSearchSubmit,
}) {
  const {
    localSearchCriteria,
    handleSubjectChange,
    handleOnCourseDataChange,
    handleOnLessonLevelChange,
  } = useHandleFormChange();

  const { loading: lessonCourseLoading, lessonCourses } = useGetLessonCourseData();

  const courseDropdown = getCourseDropdownOptions({ subjectId: localSearchCriteria.subjectId, lessonCourses });
  const unitDropdown = getUnitDropdownOptions({ courseId: localSearchCriteria.courseId, lessonCourses });
  const topicDropdown = getTopicDropdownOptions({ courseId: localSearchCriteria.courseId, unitId: localSearchCriteria.unitId, lessonCourses });
  return (
    <div className='teacher-course-filter' data-test='teacher-course-filter'>
      <div className='search-container subject'>
        <DropdownInput
          dropdownValues={subjectOptions}
          name='subjectId'
          value={localSearchCriteria.subjectId}
          onChange={handleSubjectChange}
          labelVariant='subtitle1'
          inlineLabel='Subject'
          data-test='subject-options'
        />
      </div>

      <div className='search-container grade'>
        <DropdownInput
          inlineLabel='Grade'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || lessonCourseLoading
            || !localSearchCriteria.subjectId
          }
          dropdownValues={courseDropdown}
          name='courseId'
          value={localSearchCriteria.courseId || ''}
          onChange={(e) => handleOnCourseDataChange(e, { unitId: '', topicId: '' })}
          loading={lessonCourseLoading}
          data-test='course-selector'
        />
      </div>

      <div className='search-container level'>
        <DropdownInput
          inlineLabel='Level'
          labelVariant='subtitle1'
          dropdownValues={getLessonLevels()}
          SelectProps={{ renderValue: (value) => (value || ''), displayEmpty: true }}
          name='lessonLevel'
          value={localSearchCriteria.lessonLevel || ''}
          onChange={handleOnLessonLevelChange}
          loading={lessonCourseLoading}
          data-test='level-selector'
        />
      </div>
      <div className='search-container unit'>
        <DropdownInput
          inlineLabel='Unit'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || lessonCourseLoading
            || Boolean(!localSearchCriteria.courseId)
          }
          dropdownValues={unitDropdown}
          name='unitId'
          value={localSearchCriteria.unitId || ''}
          onChange={(e) => handleOnCourseDataChange(e, { topicId: '' })}
          loading={lessonCourseLoading}
          data-test='unit-selector'
        />
      </div>
      <div className='search-container topic'>
        <DropdownInput
          inlineLabel='Topic'
          labelVariant='subtitle1'
          disabled={
            !lessonCourses
            || lessonCourseLoading
            || Boolean(!localSearchCriteria.unitId)
          }
          dropdownValues={topicDropdown}
          name='topicId'
          value={localSearchCriteria.topicId || ''}
          onChange={(e) => handleOnCourseDataChange(e)}
          loading={lessonCourseLoading}
          data-test='topic-selector'
        />
      </div>

      <ButtonWithSpinner
        variant='contained'
        className='btn-primary'
        data-test='course-filter-search-action'
        label='Search'
        disabled={
          Boolean(!localSearchCriteria.subjectId)
          || Boolean(!localSearchCriteria.courseId)
          || Boolean(!localSearchCriteria.lessonLevel)
          || Boolean(!localSearchCriteria.unitId)
          || Boolean(!localSearchCriteria.topicId)
        }
        onClick={() => {
          onSearchSubmit(localSearchCriteria);
        }}
      />
    </div>
  );
}

TeacherCourseFilter.defaultProps = {
  onSearchSubmit: () => null,
};

TeacherCourseFilter.propTypes = {
  onSearchSubmit: PropTypes.func,
};

import vizzleAxios from './service';

/**
 * Get list of students by teacher id
 *
 * @param userId user id
 *
 */
export const getListOfStudents = async (teacherId) => {
  const timestamp = Date.now().toString();
  const url = `/user/students/${teacherId}?q=${timestamp}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getTeachersForStudent = async (studentId) => {
  const url = `/users/${studentId}/teachers`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const getListByTypeStudent = async () => {
  const timestamp = Date.now().toString();
  const url = `/users/?type=Student&q=${timestamp}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const deleteMyStudent = async (studentId) => {
  const url = `/users/me/students/${studentId}`;
  const result = await vizzleAxios.delete(url);
  return result;
};

export const addMyStudent = async (studentId) => {
  const url = '/users/me/students';
  const result = await vizzleAxios.post(url, { data: { type: 'users', id: studentId } });
  return result;
};

export const createStudent = async (student) => {
  const url = '/users';
  const data = {
    data: {
      type: 'users',
      attributes: {
        firstName: student.firstName,
        lastName: student.lastName,
        userName: student.userName,
        // externalId: student.externalId,
        password: student.password,
        schoologyId: student.schoologyId,
        stateIdentifier: student.stateIdentifier,
        type: 'Student',
      },
    },
  };
  const result = await vizzleAxios.post(url, data);
  return result;
};

export const getStudentLessonsSummary = async () => {
  const url = '/users/lessons/summary/current';
  const result = await vizzleAxios.get(url);
  return result;
};

export const studentViewName = async (studentId) => {
  const url = `/users/${studentId}/view/name`;
  const result = await vizzleAxios.get(url);
  return result;
};

export const getStudentGroups = async () => {
  const url = '/groups/students';
  const result = await vizzleAxios.get(url);
  return result;
};

export const addStudentGroup = async (data) => {
  const url = '/groups/students';
  const result = await vizzleAxios.post(url, data);
  return result;
};

export const deleteStudentGroup = async (groupId) => {
  const url = `/groups/students/${groupId}`;
  const result = await vizzleAxios.delete(url);
  return result;
};

export const updateStudentGroup = async ({ groupId, data }) => {
  const url = `/groups/students/${groupId}`;
  const result = await vizzleAxios.put(url, data);
  return result;
};

import ShareLinkLessonAction from '../../../../../lessons/lessonactions/ShareLinkLessonAction';
import DropdownAction from '../../../../../lessons/lessonactions/dropdown/DropdownAction';
import PrintLessonMenuItem from '../../../../../lessons/lessonactions/dropdown/PrintLessonMenuItem';
import { isMobile } from '../../../../../../utils/DeviceUtils';
import PlayLessonDetailAction from '../../../../../lessons/lessonactions/PlayLessonDetailAction';
import CopyToEditAction from '../../../../../lessons/lessonactions/CopyToEditAction';
import TeacherCourseAssignLessonsAction from './TeacherCourseAssignLessonsAction';
import TeacherCourseAddRemoveFromGroupPlay from './TeacherCourseAddRemoveFromGroupPlay';
import PreviewLessonAction from '../../../../../lessons/lessonactions/PreviewLessonAction';
import TeacherCourseLessonBookmarkAction from './TeacherCourseLessonBookmarkAction';
import LessonPlayMenuItem from '../../../../../lessons/lessonactions/dropdown/LessonPlayMenuItem';

/**
 * Lesson card detail action
 */
export const createLessonCardDetailAction = (lesson, currentUser, userProfile, terminologies, selectedDates) => {
  const actions = [];

  actions.push(
    (<PreviewLessonAction
      label='Preview'
      lesson={lesson}
      key={`teacher-course-lesson-preview-${lesson.id}`}
      user={currentUser}
      userProfile={userProfile}
      terminologies={terminologies}
    />),
  );

  actions.push(
    (<TeacherCourseLessonBookmarkAction
      lesson={lesson}
      showLabel
      key={`teacher-course-lesson-bookmark-${lesson.id}`}
    />),
  );

  actions.push(
    (<ShareLinkLessonAction
      lesson={lesson}
      key={`teacher-course-lesson-share-link-${lesson.id}`}
    />),
  );

  actions.push(
    (<TeacherCourseAddRemoveFromGroupPlay
      lesson={lesson}
      key={`teacher-course-lesson-add-remove-from-group-play-${lesson.id}`}
    />),
  );

  const dropdownActions = [
    (<LessonPlayMenuItem
      key={`teacher-course-dropdown-preview-${lesson.id}`}
      lesson={lesson}
      user={currentUser}
      userProfile={userProfile}
      terminologies={terminologies}
    />),
  ];

  if (!isMobile()) {
    dropdownActions.push((
      <PrintLessonMenuItem
        key={`teacher-course-dropdown-print-${lesson.id}`}
        lesson={lesson}
        currentUserProfile={userProfile}
      />
    ));
  }

  actions.push(
    (
      <DropdownAction
        key={`teacher-course-dropdown-${lesson.id}`}
        menuItems={dropdownActions}
      />
    ),
  );

  actions.push(
    (
      <CopyToEditAction
        lesson={lesson}
        currentUser={currentUser}
        key={`teacher-curriculum-copy-to-edit-${lesson.id}`}
        fromStudentId={currentUser.id}
      />
    ),
  );

  actions.push(
    (
      <TeacherCourseAssignLessonsAction
        lessons={[lesson]}
        selectedLessonIds={[lesson.id]}
        key={`teacher-curriculum-assign-to-others-${lesson.id}`}
        selectedDates={selectedDates}
      />
    ),
  );

  return actions;
};

export const createLessonCardDetailonPlanelAction = (lesson, user, userProfile, terminologies) => {
  const actions = [
    <PlayLessonDetailAction
      key={`teacher-course-play-lesson-on-detail-panel-${lesson.id}`}
      lesson={lesson}
      user={user}
      userProfile={userProfile}
      terminologies={terminologies}
    />,
  ];
  return actions;
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleForm from '../../../components/toggle/ToggleForm';

const ExitLesson = ({ profile, onChangeCallback }) => {
  const [toggle, setToggle] = useState(profile.exitLesson);
  const isExitLesson = profile.exitLesson;
  // When mounting and changing a profile.
  useEffect(() => {
    setToggle(isExitLesson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleExitLessonOnchange = (field, newValue) => {
    if (newValue === null) return;
    setToggle(newValue);
    onChangeCallback(newValue);
  };

  return (
    <ToggleForm value={toggle} handleOnChange={handleExitLessonOnchange} />
  );
};

ExitLesson.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};

export default ExitLesson;

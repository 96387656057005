import useSettingForm from '../form/useSettingForm';
import { useDomain } from '../../../../states/AppDomainProvider';
import SwitchKeyboard from '../../../settings/SwitchKeyboard';

const SwitchKeyboardSettings = () => {
  const { updateProfileSettingsOnChange } = useSettingForm('Switch/Keyboard Accessibility');
  const { userDomain } = useDomain();
  const { userProfile } = userDomain.domainData;

  const onConfirm = () => {
    updateProfileSettingsOnChange({
      switchAccessibility: 1,
      interactionType: 1,
    });
  };

  const onChange = (newValue) => {
    updateProfileSettingsOnChange({
      switchAccessibility: newValue,
    });
  };

  return (
    <SwitchKeyboard
      profile={userProfile}
      onConfirmCallback={onConfirm}
      onChangeCallback={(newValue) => onChange(newValue)}
    />
  );
};

export default SwitchKeyboardSettings;

import { useState } from 'react';
import PropTypes from 'prop-types';
import ColorPickerModal from '../../../../../../../../../../components/color/ColorPickerModal';
import { useApplyFontStyle } from '../../ApplyTextStyleActionHooks';
import EntryContainer from '../EntryContainer';
import './Form.scss';

export default function FontColorForm({ applyToActivities }) {
  const [color, setColor] = useState('');

  const {
    applyFontStyle,
  } = useApplyFontStyle({
    applyToActivities,
  });
  return (
    <EntryContainer
      data-test='font-color-form'
      label='Font Color'
      onApply={async () => {
        await applyFontStyle([{
          fieldName: 'color',
          value: color,
        }]);
        setColor('');
      }}
      disabled={!color}
    >
      <ColorPickerModal
        color={color}
        onApply={(newColor) => {
          setColor(newColor);
        }}
      />
    </EntryContainer>
  );
}

FontColorForm.propTypes = {
  applyToActivities: PropTypes.string.isRequired,
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
} from '@mui/material';
import VideoPlayer from './VideoPlayer';

export default function WatchQuickVideo({
  videoUrl,
}) {
  const [openVideo, setOpenVideo] = useState(false);

  if (!videoUrl) {
    return null;
  }
  return (
    <>
      <Box alignSelf='center'>
        <ButtonBase
          onClick={() => { setOpenVideo(true); }}
          className='btn-link'
        >
          Watch a Quick Help Video
        </ButtonBase>
      </Box>
      <VideoPlayer
        videoUrl={videoUrl}
        open={openVideo}
        handleClose={() => { setOpenVideo(false); }}
      />
    </>
  );
}

WatchQuickVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

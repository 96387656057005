import { useState } from 'react';
import { MATCHING_ACTIVITY_DRAG_AND_DROP } from '../../../../../constants/SettingConstant';
import { hideAccessibeChatIcon } from '../../../../../services/AccessibeService';
import { getAnswerFreeForm, getLessonPlaySnapshot } from '../../../../../services/LessonPlayService';
import { getLessonPagesData } from '../../../../../services/LessonService';
// import { getLessonPagesData } from '../../../../../services/LessonService';
import LiveChatService from '../../../../../services/LiveChatService';
import { useDomain } from '../../../../../states/AppDomainProvider';
import { getActivityCardPreviewDataByActivityType } from '../../../../../utils/activitytype/ActivityTypesUtil';
import Logger from '../../../../../utils/Logger';
import { useGetRemoteServiceWrapper } from '../../../../hooks/RemoteServiceHooks';
import { initDomains, useLessonPlay } from '../../../../lessonplay/context/LessonPlayContextProvider';
import { preloadImages } from '../../../../lessonplay/lesson-play-by-environment/LessonPlayHook';
import { useStudentContext } from '../../../context/MyStudentContextProvider';

export const usePrepareSnapshotLessonData = ({ detailedReportData, setOpenDialog }) => {
  initDomains();
  const [answerFreeFormData, setAnswerFreeFormData] = useState({
    parentAnswerFreeFormData: [],
    answerFreeFormData: [],
  });
  const { lessonPlayDomain } = useLessonPlay();
  const { myStudentDomain } = useStudentContext();
  const { selectedStudentProfile, terminologies } = myStudentDomain.domainData;
  // const currentStudent = useGetCurrentStudent();
  const { userDomain } = useDomain();
  const { user } = userDomain.domainData;

  const copiedUserProfile = JSON.parse(JSON.stringify(selectedStudentProfile));
  copiedUserProfile.celebrations = [];
  copiedUserProfile.accessibeOn = false;
  if (!copiedUserProfile.tts) {
    copiedUserProfile.tts = {};
  }
  copiedUserProfile.tts.enabled = false;
  copiedUserProfile.interactionType = MATCHING_ACTIVITY_DRAG_AND_DROP;
  copiedUserProfile.exitLesson = true;

  const { lessonPagesData, currentPageIndex } = lessonPlayDomain.domainData;
  const { callRemoteServiceWrapper, loading } = useGetRemoteServiceWrapper();
  const getSnapshotLessonData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        const promises = [];
        const studentHistoryId = detailedReportData.id;
        const answerFreeFormPromise = getAnswerFreeForm({ studentHistoryId });
        promises.push(answerFreeFormPromise);

        const { parentStudentHistoryId } = detailedReportData;
        if (parentStudentHistoryId) {
          const answerFreeFormParentHistoryPromise = getAnswerFreeForm({ studentHistoryId: parentStudentHistoryId });
          promises.push(answerFreeFormParentHistoryPromise);
        }

        const [
          answerFreeForm,
          answerFreeFormParentHistoryData,
        ] = await Promise.all(promises);

        let lessonPlaySnapshotData;
        try {
          lessonPlaySnapshotData = await getLessonPlaySnapshot({ studentHistoryId });
        } catch (e) {
          Logger.logWarn('Cannot get lesson snapshot, retrieve the lesson play from the service', e);
        }

        if (!lessonPlaySnapshotData) {
          lessonPlaySnapshotData = await getLessonPagesData(detailedReportData.lessonUuid, selectedStudentProfile.maxMatchingChoices, selectedStudentProfile.switchAccessibility);
        }

        setAnswerFreeFormData({
          parentAnswerFreeFormData: answerFreeFormParentHistoryData && answerFreeFormParentHistoryData.data,
          answerFreeFormData: answerFreeForm.data,
        });

        await lessonPlayDomain.updateLessonPagesData({
          lesson: {
            lessonId: detailedReportData.lessonId,
          },
          lessonPagesData: getActivityCardPreviewDataByActivityType(lessonPlaySnapshotData, false),
          user,
          userProfile: copiedUserProfile,
          terminologies,
          isPreviewMode: true,
          isPlayable: false,
          lessonPagesDataSnapshot: lessonPlaySnapshotData.pages,
          currentPageIndex: 0,
          studentHistoryId,
        });
        await preloadImages(lessonPlaySnapshotData);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
      hideAccessibeChatIcon(false);
      LiveChatService.showLiveChatIcon();
      setOpenDialog(false);
    }
  };

  const refreshAnswerFreeFormData = async () => {
    const promises = [];
    const { id, parentStudentHistoryId } = detailedReportData;
    const answerFreeFormPromise = getAnswerFreeForm({ studentHistoryId: id });
    promises.push(answerFreeFormPromise);
    if (parentStudentHistoryId) {
      const answerFreeFormParentHistoryPromise = getAnswerFreeForm({ studentHistoryId: parentStudentHistoryId });
      promises.push(answerFreeFormParentHistoryPromise);
    }

    const [
      answerFreeForm,
      answerFreeFormParentHistoryData,
    ] = await Promise.all(promises);
    setAnswerFreeFormData({
      parentAnswerFreeFormData: answerFreeFormParentHistoryData && answerFreeFormParentHistoryData.data,
      answerFreeFormData: answerFreeForm.data,
    });
  };

  const resetData = () => {
    lessonPlayDomain.resetDomainData();
  };

  return {
    loading,
    getSnapshotLessonData,
    lessonPagesData,
    resetData,
    currentPageIndex,
    answerFreeFormData,
    refreshAnswerFreeFormData,
  };
};

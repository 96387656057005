import { useEffect } from 'react';

export const SHOW_FINISH_TIME = 35000;

export default {
  getResourceKey: (celebrationId, manifestId) => `${celebrationId}-${manifestId}`,
  setupAutoFinish: (onFinish) => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      onFinish();
    }, SHOW_FINISH_TIME);
    return timeout;
  },
};

export const useSetupTimeout = (canvas, onFinish) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      onFinish();
    }, SHOW_FINISH_TIME);

    return () => {
      clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);
};

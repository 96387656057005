import { useDomain } from '../../../../states/AppDomainProvider';
import { flatten, shuffle, uniqueObject } from '../../../../utils/ArrayUtils';
import { isResponseCardEmpty } from '../../../../utils/activitytype/ActivityTypeEmptyChecker';
import { ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA } from '../../../../AppConstants';

export const useHandleNumberOfCardChanges = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const handleNumberOfCardChange = async ({
    numberOfCard,
    currentNumberOfCards,
    cardType,
    templateData,
  }) => {
    if (currentNumberOfCards > numberOfCard) {
      for (let i = numberOfCard; i < currentNumberOfCards; i++) {
        selectedPage[cardType][i].hide = true;
      }
    } else if (currentNumberOfCards < numberOfCard) {
      for (let i = 0; i < numberOfCard; i++) {
        if (!selectedPage[cardType][i]) {
          selectedPage[cardType][i] = templateData[i];
        }
        selectedPage[cardType][i].hide = false;
      }
    }

    const newPageData = {
      ...selectedPage,
      [cardType]: [...selectedPage[cardType]],
    };
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, newPageData);
  };

  return handleNumberOfCardChange;
};

export const useHandleMatchingQuestionKey = () => {
  const { lessonDataMaintenanceDomain } = useDomain();
  const { currentSelectedPagesData, pagesData } = lessonDataMaintenanceDomain.domainData;
  const { pages } = pagesData;
  const selectedPage = pages[currentSelectedPagesData];

  const handleMatchingQuestionKey = async (questionKey, answerKeyName = 'answerKey', responseCardsName = 'responseCards') => {
    const otherMatchingPages = pages
      .filter((p, index) => (index !== currentSelectedPagesData && p.activityType === selectedPage.activityType));

    if (otherMatchingPages && otherMatchingPages.length > 0
      && questionKey) {
      let answerCardPool = shuffle(
        flatten(otherMatchingPages
          .map((p) => (p[responseCardsName])))
          .filter((card) => (
            !card.hide
            && !isResponseCardEmpty(card)
            && questionKey !== card[answerKeyName]
          )),
      );

      // Remove the unwanted attributes
      answerCardPool = answerCardPool.map((c) => {
        const { correctAnswer, ...rest } = c;
        return rest;
      });

      // Remove duplicated objects
      answerCardPool = uniqueObject(answerCardPool);
      // pre-populate the response cards
      const responseCardsLength = selectedPage[responseCardsName].filter((c) => !c.hide).length;
      // Do not copy the first answer card
      for (let i = 1; i < responseCardsLength; i++) {
        const answerCard = answerCardPool[i - 1];
        if (isResponseCardEmpty(selectedPage[responseCardsName][i]) && answerCard) {
          const dataToCopy = JSON.parse(JSON.stringify(answerCard));
          selectedPage[responseCardsName][i] = dataToCopy;
        }
      }
    }

    // This answer key of the first response must the same as question key
    // eslint-disable-next-line prefer-destructuring
    selectedPage[responseCardsName][0][answerKeyName] = questionKey;
    await lessonDataMaintenanceDomain.updateLessonPageData(currentSelectedPagesData, selectedPage);
  };

  return handleMatchingQuestionKey;
};

export const getAllFfrPage = (pagesData) => {
  const buildABookFacingPages = pagesData.pages.filter((page) => page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA);
  // eslint-disable-next-line prefer-const
  let allFfrPages = [];
  if (buildABookFacingPages && buildABookFacingPages.length > 0) {
    const result = buildABookFacingPages.filter((bookPage) => bookPage.cards.filter((card) => card.allowCustomResponse));
    if (result) {
      allFfrPages = result;
    }
  }
  return allFfrPages;
};

export const findFfrPage = (pagesData) => {
  const buildABookFacingPages = pagesData.pages.filter((page) => page.activityType === ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA);
  // eslint-disable-next-line prefer-const
  let hasFfr = false;
  if (buildABookFacingPages && buildABookFacingPages.length > 0) {
    const result = buildABookFacingPages.find((bookPage) => bookPage.cards.find((card) => card.allowCustomResponse));
    if (result) {
      hasFfr = true;
    }
  }
  return hasFfr;
};

export const useFindFfrPage = () => {
  const {
    lessonDataMaintenanceDomain,
  } = useDomain();
  const { pagesData } = lessonDataMaintenanceDomain.domainData;

  const hasFfr = findFfrPage(pagesData);

  return {
    hasFfr,
  };
};

export const useShouldFeedbackLoopDisable = () => {
  const {
    lessonDataMaintenanceDomain,
  } = useDomain();

  const getShouldFeedbackLoopDisable = () => {
    const { lessonData, updateMode } = lessonDataMaintenanceDomain.domainData;
    const isDisabled = updateMode && !lessonData.attributes.feedbackLoopEnabled;
    return isDisabled;
  };

  return {
    getShouldFeedbackLoopDisable,
  };
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import LessonSteppers from './LessonSteppers';
import { useDomain } from '../../../states/AppDomainProvider';
import {
  getLessonByUuid,
  getLessonPagesData,
  getLessonPageDataFromTempTable,
  permanentlySaveLessonPageData,
  lockLessonForEditing,
  unlockLessonForEditing,
} from '../../../services/LessonService';
import Logger from '../../../utils/Logger';
import ObjectUtils from '../../../utils/ObjectUtils';
import SelectableLoader, { LOADER_TYPE } from '../../../components/loaders/SelectableLoader';
import { flatten } from '../../../utils/ArrayUtils';
import { getAuthoringProfile } from '../../../services/UserProfileService';
import useInitilizeDataForAuthoring from './useInitilizeDataForAuthoring';
import { useGetRemoteServiceWrapper } from '../../hooks/RemoteServiceHooks';
import VizzleMainContainer from '../../vizzle/VizzleMainContainer';

/**
 * Container component for updating the selected lesson
 * The selected lesson is passed in from the url parameter
 */
export default function LessonUpdateContainer(props) {
  const [isReady, setIsReady] = useState();
  const [openDialog, setOpenDialog] = useState();
  const [lessonPageDataForSave, setLessonPageDataForSave] = useState({
    pagesData: null,
    pagesDataFromTemp: null,
  });
  const [selectedLessonData, setSelectedLessonData] = useState();
  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  const {
    authoredLessonDomain,
    lessonDataMaintenanceDomain,
    uiDomain,
    authoringProfileDomain,
    userDomain,
  } = useDomain();
  const { authoredLessons } = authoredLessonDomain.domainData;
  const { lessonUuid } = useParams();
  const { lessonData } = lessonDataMaintenanceDomain.domainData;

  const { user } = userDomain.domainData;

  useInitilizeDataForAuthoring();
  const prepareData = async () => {
    try {
      await callRemoteServiceWrapper(async () => {
        // Grab the data from the memory storage
        Logger.logWhenDebugModeIsOn({
          SELECTED_LESSON_ID: lessonUuid,
        });
        let selectedLesson = lessonData;
        if ((!selectedLesson.attributes.uuid) && authoredLessons && authoredLessons.length > 0) {
          const lessons = flatten(authoredLessons.map((subjectWithLessons) => (
            subjectWithLessons.lessons
          )));

          selectedLesson = lessons.find((lesson) => (
            lesson.attributes.uuid === lessonUuid
          ));
        }

        const lessonById = await getLessonByUuid(lessonUuid);
        selectedLesson = lessonById.data.data;
        await authoredLessonDomain.updatedIncludedData(lessonById.data.included || []);

        selectedLesson = {
          ...selectedLesson,
          changeSubjectFrom: selectedLesson.attributes.subjects[0],
        };

        const pagesData = await getLessonPagesData(selectedLesson.attributes.uuid);
        await lessonDataMaintenanceDomain.prepareDataForUpdate(selectedLesson, { pages: pagesData.pages }, 0, true);
        setIsReady(true);
        setSelectedLessonData(selectedLesson);
        const pagesDataFromTempResponse = getLessonPageDataFromTempTable(selectedLesson.attributes.uuid);
        const authoringProfilePromise = getAuthoringProfile();
        const [authoringProfile] = await Promise.all([authoringProfilePromise, pagesDataFromTempResponse]);
        if (authoringProfile.data && authoringProfile.data.id) {
          authoringProfileDomain.updateAuthoringProfile(authoringProfile.data);
        }
        const pagesDataFromTempPromise = await pagesDataFromTempResponse;
        const pagesDataFromTemp = pagesDataFromTempPromise.data;
        if (ObjectUtils.isEmpty(pagesDataFromTemp) || pagesDataFromTemp === '') {
          Logger.logWhenDebugModeIsOn('The data is up to date');
        } else {
          setLessonPageDataForSave({
            lessonUuid: selectedLesson.attributes.uuid,
            pagesData,
            pagesDataFromTemp,
          });
          setOpenDialog(true);
        }
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  useEffect(() => {
    prepareData();

    // lock lesson when the moderator enter the screen
    if (user.userModerator) {
      lockLessonForEditing(lessonUuid);
    }

    // Cleanup the state
    return () => {
      lessonDataMaintenanceDomain.resetDomainData();
      uiDomain.closeSnackbar();
      if (user.userModerator) {
        unlockLessonForEditing(lessonUuid);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveLessonPageData = async (_lessonUuid, lessonPageDataToSave) => {
    try {
      await callRemoteServiceWrapper(async () => {
        setOpenDialog(false);
        await permanentlySaveLessonPageData(_lessonUuid, lessonPageDataToSave, selectedLessonData.id);
        await lessonDataMaintenanceDomain.prepareDataForUpdate(selectedLessonData, lessonPageDataToSave, 0, true);
        setIsReady(true);
      });
    } catch (e) {
      Logger.logWhenDebugModeIsOn(e);
    }
  };

  const onConfirm = () => {
    Logger.logWhenDebugModeIsOn({
      UPDATE_LESSON_DATA_TO_NEW_VERSION: lessonPageDataForSave.pagesDataFromTemp,
    });
    saveLessonPageData(lessonPageDataForSave.lessonUuid, lessonPageDataForSave.pagesDataFromTemp);
  };

  const onCancel = () => {
    Logger.logWhenDebugModeIsOn({
      USE_CURRENT_LESSON_DATA: lessonPageDataForSave.pagesData,
    });
    saveLessonPageData(lessonPageDataForSave.lessonUuid, lessonPageDataForSave.pagesData);
  };

  if (isReady) {
    return (
      <div>
        <LessonSteppers {...props} />
        <ConfirmationDialog
          open={openDialog}
          title='There were changes made since your last save'
          contentText='Would you like to apply these changes?'
          confirmLabel='Yes'
          cancelLabel='No'
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </div>
    );
  }
  return (
    <VizzleMainContainer
      style={{ height: '100vh' }}
    >
      <div>
        <SelectableLoader
          open
          loaderType={LOADER_TYPE.RING_LOADER}
          message='Fetching your activity data'
        />
      </div>
    </VizzleMainContainer>
  );
}
